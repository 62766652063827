export const affiliations = [
  {
    "id": "2101067073",
    "name": "中国科学院",
    "abbr": "CAS",
    "region": "中国大陆",
    "author": [
      [
        2010,
        50
      ],
      [
        2011,
        126
      ],
      [
        2012,
        184
      ],
      [
        2013,
        255
      ],
      [
        2014,
        332
      ],
      [
        2015,
        412
      ],
      [
        2016,
        606
      ],
      [
        2017,
        889
      ],
      [
        2018,
        1329
      ],
      [
        2019,
        1582
      ]
    ],
    "paper": [
      [
        2010,
        13
      ],
      [
        2011,
        41
      ],
      [
        2012,
        65
      ],
      [
        2013,
        75
      ],
      [
        2014,
        97
      ],
      [
        2015,
        135
      ],
      [
        2016,
        173
      ],
      [
        2017,
        258
      ],
      [
        2018,
        380
      ],
      [
        2019,
        463
      ]
    ],
    "cite": [
      [
        2010,
        685
      ],
      [
        2011,
        3714
      ],
      [
        2012,
        5323
      ],
      [
        2013,
        6028
      ],
      [
        2014,
        7714
      ],
      [
        2015,
        11262
      ],
      [
        2016,
        13282
      ],
      [
        2017,
        15621
      ],
      [
        2018,
        17237
      ],
      [
        2019,
        17297
      ]
    ]
  },
  {
    "id": "2100751172",
    "name": "清华大学",
    "abbr": "THU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        27
      ],
      [
        2011,
        71
      ],
      [
        2012,
        112
      ],
      [
        2013,
        162
      ],
      [
        2014,
        212
      ],
      [
        2015,
        278
      ],
      [
        2016,
        392
      ],
      [
        2017,
        616
      ],
      [
        2018,
        1048
      ],
      [
        2019,
        1196
      ]
    ],
    "paper": [
      [
        2010,
        8
      ],
      [
        2011,
        16
      ],
      [
        2012,
        27
      ],
      [
        2013,
        45
      ],
      [
        2014,
        67
      ],
      [
        2015,
        96
      ],
      [
        2016,
        133
      ],
      [
        2017,
        204
      ],
      [
        2018,
        322
      ],
      [
        2019,
        372
      ]
    ],
    "cite": [
      [
        2010,
        651
      ],
      [
        2011,
        3025
      ],
      [
        2012,
        3713
      ],
      [
        2013,
        5281
      ],
      [
        2014,
        6661
      ],
      [
        2015,
        8814
      ],
      [
        2016,
        11134
      ],
      [
        2017,
        17666
      ],
      [
        2018,
        19774
      ],
      [
        2019,
        19820
      ]
    ]
  },
  {
    "id": "2103048394",
    "name": "北京大学",
    "abbr": "PKU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        35
      ],
      [
        2011,
        63
      ],
      [
        2012,
        79
      ],
      [
        2013,
        106
      ],
      [
        2014,
        124
      ],
      [
        2015,
        162
      ],
      [
        2016,
        200
      ],
      [
        2017,
        274
      ],
      [
        2018,
        547
      ],
      [
        2019,
        647
      ]
    ],
    "paper": [
      [
        2010,
        8
      ],
      [
        2011,
        18
      ],
      [
        2012,
        26
      ],
      [
        2013,
        39
      ],
      [
        2014,
        51
      ],
      [
        2015,
        62
      ],
      [
        2016,
        78
      ],
      [
        2017,
        106
      ],
      [
        2018,
        168
      ],
      [
        2019,
        196
      ]
    ],
    "cite": [
      [
        2010,
        302
      ],
      [
        2011,
        706
      ],
      [
        2012,
        977
      ],
      [
        2013,
        1614
      ],
      [
        2014,
        2324
      ],
      [
        2015,
        2947
      ],
      [
        2016,
        3763
      ],
      [
        2017,
        4627
      ],
      [
        2018,
        5725
      ],
      [
        2019,
        5733
      ]
    ]
  },
  {
    "id": "2104852976",
    "name": "中国科技大学",
    "abbr": "USTC",
    "region": "中国大陆",
    "author": [
      [
        2010,
        5
      ],
      [
        2011,
        11
      ],
      [
        2012,
        34
      ],
      [
        2013,
        49
      ],
      [
        2014,
        69
      ],
      [
        2015,
        104
      ],
      [
        2016,
        151
      ],
      [
        2017,
        296
      ],
      [
        2018,
        495
      ],
      [
        2019,
        568
      ]
    ],
    "paper": [
      [
        2010,
        2
      ],
      [
        2011,
        3
      ],
      [
        2012,
        8
      ],
      [
        2013,
        15
      ],
      [
        2014,
        25
      ],
      [
        2015,
        35
      ],
      [
        2016,
        57
      ],
      [
        2017,
        89
      ],
      [
        2018,
        139
      ],
      [
        2019,
        164
      ]
    ],
    "cite": [
      [
        2010,
        54
      ],
      [
        2011,
        172
      ],
      [
        2012,
        831
      ],
      [
        2013,
        1641
      ],
      [
        2014,
        2573
      ],
      [
        2015,
        2996
      ],
      [
        2016,
        21340
      ],
      [
        2017,
        22376
      ],
      [
        2018,
        22919
      ],
      [
        2019,
        22936
      ]
    ]
  },
  {
    "id": "2103723062",
    "name": "上海交通大学",
    "abbr": "SJTU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        22
      ],
      [
        2011,
        29
      ],
      [
        2012,
        38
      ],
      [
        2013,
        44
      ],
      [
        2014,
        122
      ],
      [
        2015,
        181
      ],
      [
        2016,
        215
      ],
      [
        2017,
        250
      ],
      [
        2018,
        660
      ],
      [
        2019,
        730
      ]
    ],
    "paper": [
      [
        2010,
        7
      ],
      [
        2011,
        12
      ],
      [
        2012,
        15
      ],
      [
        2013,
        18
      ],
      [
        2014,
        26
      ],
      [
        2015,
        47
      ],
      [
        2016,
        62
      ],
      [
        2017,
        80
      ],
      [
        2018,
        142
      ],
      [
        2019,
        160
      ]
    ],
    "cite": [
      [
        2010,
        403
      ],
      [
        2011,
        677
      ],
      [
        2012,
        739
      ],
      [
        2013,
        878
      ],
      [
        2014,
        1424
      ],
      [
        2015,
        3300
      ],
      [
        2016,
        4196
      ],
      [
        2017,
        4663
      ],
      [
        2018,
        5362
      ],
      [
        2019,
        5380
      ]
    ]
  },
  {
    "id": "2102381148",
    "name": "中山大学",
    "abbr": "SYSU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        3
      ],
      [
        2011,
        15
      ],
      [
        2012,
        26
      ],
      [
        2013,
        41
      ],
      [
        2014,
        46
      ],
      [
        2015,
        67
      ],
      [
        2016,
        91
      ],
      [
        2017,
        213
      ],
      [
        2018,
        467
      ],
      [
        2019,
        525
      ]
    ],
    "paper": [
      [
        2010,
        1
      ],
      [
        2011,
        5
      ],
      [
        2012,
        10
      ],
      [
        2013,
        17
      ],
      [
        2014,
        20
      ],
      [
        2015,
        30
      ],
      [
        2016,
        45
      ],
      [
        2017,
        72
      ],
      [
        2018,
        109
      ],
      [
        2019,
        136
      ]
    ],
    "cite": [
      [
        2010,
        9
      ],
      [
        2011,
        559
      ],
      [
        2012,
        795
      ],
      [
        2013,
        1041
      ],
      [
        2014,
        1146
      ],
      [
        2015,
        1751
      ],
      [
        2016,
        2685
      ],
      [
        2017,
        4014
      ],
      [
        2018,
        4407
      ],
      [
        2019,
        4430
      ]
    ]
  },
  {
    "id": "2101552222",
    "name": "浙江大学",
    "abbr": "ZJU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        27
      ],
      [
        2011,
        34
      ],
      [
        2012,
        67
      ],
      [
        2013,
        148
      ],
      [
        2014,
        209
      ],
      [
        2015,
        235
      ],
      [
        2016,
        352
      ],
      [
        2017,
        418
      ],
      [
        2018,
        613
      ],
      [
        2019,
        738
      ]
    ],
    "paper": [
      [
        2010,
        6
      ],
      [
        2011,
        10
      ],
      [
        2012,
        21
      ],
      [
        2013,
        33
      ],
      [
        2014,
        43
      ],
      [
        2015,
        54
      ],
      [
        2016,
        62
      ],
      [
        2017,
        74
      ],
      [
        2018,
        97
      ],
      [
        2019,
        126
      ]
    ],
    "cite": [
      [
        2010,
        141
      ],
      [
        2011,
        243
      ],
      [
        2012,
        623
      ],
      [
        2013,
        1587
      ],
      [
        2014,
        2432
      ],
      [
        2015,
        2888
      ],
      [
        2016,
        3398
      ],
      [
        2017,
        4027
      ],
      [
        2018,
        4298
      ],
      [
        2019,
        4339
      ]
    ]
  },
  {
    "id": "2102848858",
    "name": "北京航空航天大学",
    "abbr": "BUAA",
    "region": "中国大陆",
    "author": [
      [
        2010,
        22
      ],
      [
        2011,
        30
      ],
      [
        2012,
        41
      ],
      [
        2013,
        55
      ],
      [
        2014,
        76
      ],
      [
        2015,
        102
      ],
      [
        2016,
        120
      ],
      [
        2017,
        200
      ],
      [
        2018,
        502
      ],
      [
        2019,
        574
      ]
    ],
    "paper": [
      [
        2010,
        6
      ],
      [
        2011,
        10
      ],
      [
        2012,
        13
      ],
      [
        2013,
        17
      ],
      [
        2014,
        23
      ],
      [
        2015,
        34
      ],
      [
        2016,
        39
      ],
      [
        2017,
        63
      ],
      [
        2018,
        96
      ],
      [
        2019,
        118
      ]
    ],
    "cite": [
      [
        2010,
        159
      ],
      [
        2011,
        258
      ],
      [
        2012,
        324
      ],
      [
        2013,
        464
      ],
      [
        2014,
        609
      ],
      [
        2015,
        819
      ],
      [
        2016,
        914
      ],
      [
        2017,
        1373
      ],
      [
        2018,
        1815
      ],
      [
        2019,
        1828
      ]
    ]
  },
  {
    "id": "2102163663",
    "name": "哈尔滨工业大学",
    "abbr": "HIT",
    "region": "中国大陆",
    "author": [
      [
        2010,
        22
      ],
      [
        2011,
        34
      ],
      [
        2012,
        37
      ],
      [
        2013,
        47
      ],
      [
        2014,
        106
      ],
      [
        2015,
        122
      ],
      [
        2016,
        255
      ],
      [
        2017,
        279
      ],
      [
        2018,
        520
      ],
      [
        2019,
        549
      ]
    ],
    "paper": [
      [
        2010,
        6
      ],
      [
        2011,
        10
      ],
      [
        2012,
        12
      ],
      [
        2013,
        17
      ],
      [
        2014,
        21
      ],
      [
        2015,
        27
      ],
      [
        2016,
        41
      ],
      [
        2017,
        54
      ],
      [
        2018,
        91
      ],
      [
        2019,
        102
      ]
    ],
    "cite": [
      [
        2010,
        293
      ],
      [
        2011,
        380
      ],
      [
        2012,
        430
      ],
      [
        2013,
        722
      ],
      [
        2014,
        1462
      ],
      [
        2015,
        1811
      ],
      [
        2016,
        2622
      ],
      [
        2017,
        3024
      ],
      [
        2018,
        3491
      ],
      [
        2019,
        3501
      ]
    ]
  },
  {
    "id": "2104235635",
    "name": "大连理工大学",
    "abbr": "DUT",
    "region": "中国大陆",
    "author": [
      [
        2010,
        4
      ],
      [
        2011,
        12
      ],
      [
        2012,
        21
      ],
      [
        2013,
        31
      ],
      [
        2014,
        35
      ],
      [
        2015,
        45
      ],
      [
        2016,
        205
      ],
      [
        2017,
        287
      ],
      [
        2018,
        421
      ],
      [
        2019,
        450
      ]
    ],
    "paper": [
      [
        2010,
        1
      ],
      [
        2011,
        3
      ],
      [
        2012,
        7
      ],
      [
        2013,
        13
      ],
      [
        2014,
        18
      ],
      [
        2015,
        24
      ],
      [
        2016,
        38
      ],
      [
        2017,
        61
      ],
      [
        2018,
        88
      ],
      [
        2019,
        99
      ]
    ],
    "cite": [
      [
        2010,
        30
      ],
      [
        2011,
        488
      ],
      [
        2012,
        2138
      ],
      [
        2013,
        4059
      ],
      [
        2014,
        4417
      ],
      [
        2015,
        5479
      ],
      [
        2016,
        6445
      ],
      [
        2017,
        7076
      ],
      [
        2018,
        7510
      ],
      [
        2019,
        7519
      ]
    ]
  },
  {
    "id": "2100453429",
    "name": "华中科技大学",
    "abbr": "HUST",
    "region": "中国大陆",
    "author": [
      [
        2010,
        13
      ],
      [
        2011,
        29
      ],
      [
        2012,
        36
      ],
      [
        2013,
        37
      ],
      [
        2014,
        43
      ],
      [
        2015,
        55
      ],
      [
        2016,
        71
      ],
      [
        2017,
        118
      ],
      [
        2018,
        197
      ],
      [
        2019,
        291
      ]
    ],
    "paper": [
      [
        2010,
        4
      ],
      [
        2011,
        12
      ],
      [
        2012,
        16
      ],
      [
        2013,
        17
      ],
      [
        2014,
        21
      ],
      [
        2015,
        25
      ],
      [
        2016,
        33
      ],
      [
        2017,
        49
      ],
      [
        2018,
        78
      ],
      [
        2019,
        98
      ]
    ],
    "cite": [
      [
        2010,
        138
      ],
      [
        2011,
        373
      ],
      [
        2012,
        851
      ],
      [
        2013,
        951
      ],
      [
        2014,
        1158
      ],
      [
        2015,
        1629
      ],
      [
        2016,
        2001
      ],
      [
        2017,
        2708
      ],
      [
        2018,
        3321
      ],
      [
        2019,
        3329
      ]
    ]
  },
  {
    "id": "2101117560",
    "name": "西安交通大学",
    "abbr": "XJTU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        3
      ],
      [
        2011,
        10
      ],
      [
        2012,
        14
      ],
      [
        2013,
        37
      ],
      [
        2014,
        54
      ],
      [
        2015,
        79
      ],
      [
        2016,
        237
      ],
      [
        2017,
        265
      ],
      [
        2018,
        318
      ],
      [
        2019,
        344
      ]
    ],
    "paper": [
      [
        2010,
        1
      ],
      [
        2011,
        4
      ],
      [
        2012,
        6
      ],
      [
        2013,
        17
      ],
      [
        2014,
        23
      ],
      [
        2015,
        35
      ],
      [
        2016,
        47
      ],
      [
        2017,
        64
      ],
      [
        2018,
        87
      ],
      [
        2019,
        96
      ]
    ],
    "cite": [
      [
        2010,
        80
      ],
      [
        2011,
        196
      ],
      [
        2012,
        339
      ],
      [
        2013,
        1607
      ],
      [
        2014,
        3151
      ],
      [
        2015,
        7421
      ],
      [
        2016,
        25921
      ],
      [
        2017,
        26677
      ],
      [
        2018,
        27365
      ],
      [
        2019,
        27377
      ]
    ]
  },
  {
    "id": "2104846471",
    "name": "北京理工大学",
    "abbr": "BIT",
    "region": "中国大陆",
    "author": [
      [
        2010,
        10
      ],
      [
        2011,
        30
      ],
      [
        2012,
        40
      ],
      [
        2013,
        47
      ],
      [
        2014,
        50
      ],
      [
        2015,
        62
      ],
      [
        2016,
        75
      ],
      [
        2017,
        96
      ],
      [
        2018,
        154
      ],
      [
        2019,
        189
      ]
    ],
    "paper": [
      [
        2010,
        2
      ],
      [
        2011,
        8
      ],
      [
        2012,
        12
      ],
      [
        2013,
        17
      ],
      [
        2014,
        20
      ],
      [
        2015,
        26
      ],
      [
        2016,
        32
      ],
      [
        2017,
        42
      ],
      [
        2018,
        59
      ],
      [
        2019,
        74
      ]
    ],
    "cite": [
      [
        2010,
        53
      ],
      [
        2011,
        311
      ],
      [
        2012,
        461
      ],
      [
        2013,
        690
      ],
      [
        2014,
        769
      ],
      [
        2015,
        1054
      ],
      [
        2016,
        1289
      ],
      [
        2017,
        1515
      ],
      [
        2018,
        1739
      ],
      [
        2019,
        1763
      ]
    ]
  },
  {
    "id": "2103293601",
    "name": "西北工业大学",
    "abbr": "NPU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        12
      ],
      [
        2011,
        23
      ],
      [
        2012,
        31
      ],
      [
        2013,
        31
      ],
      [
        2014,
        37
      ],
      [
        2015,
        66
      ],
      [
        2016,
        83
      ],
      [
        2017,
        100
      ],
      [
        2018,
        230
      ],
      [
        2019,
        299
      ]
    ],
    "paper": [
      [
        2010,
        3
      ],
      [
        2011,
        7
      ],
      [
        2012,
        10
      ],
      [
        2013,
        10
      ],
      [
        2014,
        12
      ],
      [
        2015,
        22
      ],
      [
        2016,
        29
      ],
      [
        2017,
        40
      ],
      [
        2018,
        52
      ],
      [
        2019,
        71
      ]
    ],
    "cite": [
      [
        2010,
        106
      ],
      [
        2011,
        263
      ],
      [
        2012,
        644
      ],
      [
        2013,
        644
      ],
      [
        2014,
        675
      ],
      [
        2015,
        1221
      ],
      [
        2016,
        1368
      ],
      [
        2017,
        2044
      ],
      [
        2018,
        2130
      ],
      [
        2019,
        2137
      ]
    ]
  },
  {
    "id": "2103229542",
    "name": "复旦大学",
    "abbr": "FUDAN",
    "region": "中国大陆",
    "author": [
      [
        2010,
        16
      ],
      [
        2011,
        25
      ],
      [
        2012,
        37
      ],
      [
        2013,
        40
      ],
      [
        2014,
        44
      ],
      [
        2015,
        50
      ],
      [
        2016,
        57
      ],
      [
        2017,
        88
      ],
      [
        2018,
        166
      ],
      [
        2019,
        179
      ]
    ],
    "paper": [
      [
        2010,
        3
      ],
      [
        2011,
        6
      ],
      [
        2012,
        12
      ],
      [
        2013,
        14
      ],
      [
        2014,
        17
      ],
      [
        2015,
        19
      ],
      [
        2016,
        24
      ],
      [
        2017,
        40
      ],
      [
        2018,
        65
      ],
      [
        2019,
        69
      ]
    ],
    "cite": [
      [
        2010,
        127
      ],
      [
        2011,
        233
      ],
      [
        2012,
        1216
      ],
      [
        2013,
        1306
      ],
      [
        2014,
        1367
      ],
      [
        2015,
        1460
      ],
      [
        2016,
        1649
      ],
      [
        2017,
        2352
      ],
      [
        2018,
        2688
      ],
      [
        2019,
        2688
      ]
    ]
  },
  {
    "id": "2100348776",
    "name": "西安电子科技大学",
    "abbr": "XIDIAN",
    "region": "中国大陆",
    "author": [
      [
        2010,
        0
      ],
      [
        2011,
        12
      ],
      [
        2012,
        16
      ],
      [
        2013,
        23
      ],
      [
        2014,
        37
      ],
      [
        2015,
        56
      ],
      [
        2016,
        69
      ],
      [
        2017,
        92
      ],
      [
        2018,
        335
      ],
      [
        2019,
        367
      ]
    ],
    "paper": [
      [
        2010,
        0
      ],
      [
        2011,
        5
      ],
      [
        2012,
        8
      ],
      [
        2013,
        11
      ],
      [
        2014,
        16
      ],
      [
        2015,
        24
      ],
      [
        2016,
        30
      ],
      [
        2017,
        41
      ],
      [
        2018,
        56
      ],
      [
        2019,
        66
      ]
    ],
    "cite": [
      [
        2010,
        0
      ],
      [
        2011,
        2236
      ],
      [
        2012,
        2482
      ],
      [
        2013,
        2659
      ],
      [
        2014,
        3253
      ],
      [
        2015,
        3557
      ],
      [
        2016,
        3824
      ],
      [
        2017,
        4021
      ],
      [
        2018,
        4204
      ],
      [
        2019,
        4210
      ]
    ]
  },
  {
    "id": "2104872700",
    "name": "南京理工大学",
    "abbr": "NUST",
    "region": "中国大陆",
    "author": [
      [
        2010,
        0
      ],
      [
        2011,
        4
      ],
      [
        2012,
        9
      ],
      [
        2013,
        30
      ],
      [
        2014,
        31
      ],
      [
        2015,
        51
      ],
      [
        2016,
        52
      ],
      [
        2017,
        88
      ],
      [
        2018,
        233
      ],
      [
        2019,
        263
      ]
    ],
    "paper": [
      [
        2010,
        0
      ],
      [
        2011,
        1
      ],
      [
        2012,
        2
      ],
      [
        2013,
        8
      ],
      [
        2014,
        10
      ],
      [
        2015,
        16
      ],
      [
        2016,
        17
      ],
      [
        2017,
        35
      ],
      [
        2018,
        53
      ],
      [
        2019,
        61
      ]
    ],
    "cite": [
      [
        2010,
        0
      ],
      [
        2011,
        419
      ],
      [
        2012,
        424
      ],
      [
        2013,
        819
      ],
      [
        2014,
        949
      ],
      [
        2015,
        1147
      ],
      [
        2016,
        1181
      ],
      [
        2017,
        1793
      ],
      [
        2018,
        1982
      ],
      [
        2019,
        1985
      ]
    ]
  },
  {
    "id": "2101930586",
    "name": "南京大学",
    "abbr": "NJU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        4
      ],
      [
        2011,
        9
      ],
      [
        2012,
        14
      ],
      [
        2013,
        20
      ],
      [
        2014,
        34
      ],
      [
        2015,
        56
      ],
      [
        2016,
        60
      ],
      [
        2017,
        80
      ],
      [
        2018,
        119
      ],
      [
        2019,
        153
      ]
    ],
    "paper": [
      [
        2010,
        1
      ],
      [
        2011,
        2
      ],
      [
        2012,
        3
      ],
      [
        2013,
        4
      ],
      [
        2014,
        10
      ],
      [
        2015,
        16
      ],
      [
        2016,
        18
      ],
      [
        2017,
        29
      ],
      [
        2018,
        41
      ],
      [
        2019,
        51
      ]
    ],
    "cite": [
      [
        2010,
        10
      ],
      [
        2011,
        273
      ],
      [
        2012,
        373
      ],
      [
        2013,
        391
      ],
      [
        2014,
        546
      ],
      [
        2015,
        716
      ],
      [
        2016,
        779
      ],
      [
        2017,
        1354
      ],
      [
        2018,
        1420
      ],
      [
        2019,
        1425
      ]
    ]
  },
  {
    "id": "2101621751",
    "name": "北京邮电大学",
    "abbr": "BUPT",
    "region": "中国大陆",
    "author": [
      [
        2010,
        0
      ],
      [
        2011,
        5
      ],
      [
        2012,
        9
      ],
      [
        2013,
        16
      ],
      [
        2014,
        16
      ],
      [
        2015,
        29
      ],
      [
        2016,
        53
      ],
      [
        2017,
        169
      ],
      [
        2018,
        385
      ],
      [
        2019,
        415
      ]
    ],
    "paper": [
      [
        2010,
        0
      ],
      [
        2011,
        1
      ],
      [
        2012,
        2
      ],
      [
        2013,
        4
      ],
      [
        2014,
        5
      ],
      [
        2015,
        9
      ],
      [
        2016,
        18
      ],
      [
        2017,
        24
      ],
      [
        2018,
        38
      ],
      [
        2019,
        50
      ]
    ],
    "cite": [
      [
        2010,
        0
      ],
      [
        2011,
        14
      ],
      [
        2012,
        48
      ],
      [
        2013,
        171
      ],
      [
        2014,
        176
      ],
      [
        2015,
        374
      ],
      [
        2016,
        593
      ],
      [
        2017,
        716
      ],
      [
        2018,
        923
      ],
      [
        2019,
        932
      ]
    ]
  },
  {
    "id": "2101465915",
    "name": "厦门大学",
    "abbr": "XMU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        0
      ],
      [
        2011,
        9
      ],
      [
        2012,
        9
      ],
      [
        2013,
        14
      ],
      [
        2014,
        24
      ],
      [
        2015,
        48
      ],
      [
        2016,
        56
      ],
      [
        2017,
        71
      ],
      [
        2018,
        231
      ],
      [
        2019,
        262
      ]
    ],
    "paper": [
      [
        2010,
        0
      ],
      [
        2011,
        2
      ],
      [
        2012,
        2
      ],
      [
        2013,
        3
      ],
      [
        2014,
        5
      ],
      [
        2015,
        12
      ],
      [
        2016,
        16
      ],
      [
        2017,
        21
      ],
      [
        2018,
        39
      ],
      [
        2019,
        49
      ]
    ],
    "cite": [
      [
        2010,
        0
      ],
      [
        2011,
        53
      ],
      [
        2012,
        53
      ],
      [
        2013,
        112
      ],
      [
        2014,
        245
      ],
      [
        2015,
        333
      ],
      [
        2016,
        386
      ],
      [
        2017,
        696
      ],
      [
        2018,
        904
      ],
      [
        2019,
        917
      ]
    ]
  },
  {
    "id": "2102416410",
    "name": "天津大学",
    "abbr": "TJU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        2
      ],
      [
        2011,
        2
      ],
      [
        2012,
        17
      ],
      [
        2013,
        28
      ],
      [
        2014,
        38
      ],
      [
        2015,
        50
      ],
      [
        2016,
        59
      ],
      [
        2017,
        75
      ],
      [
        2018,
        405
      ],
      [
        2019,
        431
      ]
    ],
    "paper": [
      [
        2010,
        1
      ],
      [
        2011,
        1
      ],
      [
        2012,
        5
      ],
      [
        2013,
        9
      ],
      [
        2014,
        13
      ],
      [
        2015,
        18
      ],
      [
        2016,
        23
      ],
      [
        2017,
        29
      ],
      [
        2018,
        40
      ],
      [
        2019,
        49
      ]
    ],
    "cite": [
      [
        2010,
        19
      ],
      [
        2011,
        19
      ],
      [
        2012,
        224
      ],
      [
        2013,
        313
      ],
      [
        2014,
        452
      ],
      [
        2015,
        641
      ],
      [
        2016,
        866
      ],
      [
        2017,
        987
      ],
      [
        2018,
        1093
      ],
      [
        2019,
        1095
      ]
    ]
  },
  {
    "id": "2103486486",
    "name": "华南理工大学",
    "abbr": "SCUT",
    "region": "中国大陆",
    "author": [
      [
        2010,
        4
      ],
      [
        2011,
        11
      ],
      [
        2012,
        15
      ],
      [
        2013,
        15
      ],
      [
        2014,
        17
      ],
      [
        2015,
        20
      ],
      [
        2016,
        32
      ],
      [
        2017,
        63
      ],
      [
        2018,
        383
      ],
      [
        2019,
        418
      ]
    ],
    "paper": [
      [
        2010,
        1
      ],
      [
        2011,
        3
      ],
      [
        2012,
        4
      ],
      [
        2013,
        4
      ],
      [
        2014,
        5
      ],
      [
        2015,
        8
      ],
      [
        2016,
        13
      ],
      [
        2017,
        23
      ],
      [
        2018,
        37
      ],
      [
        2019,
        47
      ]
    ],
    "cite": [
      [
        2010,
        105
      ],
      [
        2011,
        169
      ],
      [
        2012,
        181
      ],
      [
        2013,
        181
      ],
      [
        2014,
        217
      ],
      [
        2015,
        363
      ],
      [
        2016,
        447
      ],
      [
        2017,
        624
      ],
      [
        2018,
        816
      ],
      [
        2019,
        819
      ]
    ]
  },
  {
    "id": "2103191953",
    "name": "国防科技大学",
    "abbr": "NUDT",
    "region": "中国大陆",
    "author": [
      [
        2010,
        3
      ],
      [
        2011,
        7
      ],
      [
        2012,
        10
      ],
      [
        2013,
        15
      ],
      [
        2014,
        20
      ],
      [
        2015,
        30
      ],
      [
        2016,
        44
      ],
      [
        2017,
        72
      ],
      [
        2018,
        267
      ],
      [
        2019,
        288
      ]
    ],
    "paper": [
      [
        2010,
        1
      ],
      [
        2011,
        2
      ],
      [
        2012,
        3
      ],
      [
        2013,
        4
      ],
      [
        2014,
        5
      ],
      [
        2015,
        7
      ],
      [
        2016,
        12
      ],
      [
        2017,
        18
      ],
      [
        2018,
        37
      ],
      [
        2019,
        43
      ]
    ],
    "cite": [
      [
        2010,
        46
      ],
      [
        2011,
        94
      ],
      [
        2012,
        123
      ],
      [
        2013,
        151
      ],
      [
        2014,
        153
      ],
      [
        2015,
        160
      ],
      [
        2016,
        229
      ],
      [
        2017,
        302
      ],
      [
        2018,
        473
      ],
      [
        2019,
        476
      ]
    ]
  },
  {
    "id": "2101979065",
    "name": "上海科技大学",
    "abbr": "SHANGHAITECH",
    "region": "中国大陆",
    "author": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        0
      ],
      [
        2014,
        9
      ],
      [
        2015,
        13
      ],
      [
        2016,
        17
      ],
      [
        2017,
        61
      ],
      [
        2018,
        108
      ],
      [
        2019,
        120
      ]
    ],
    "paper": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        0
      ],
      [
        2014,
        3
      ],
      [
        2015,
        4
      ],
      [
        2016,
        6
      ],
      [
        2017,
        13
      ],
      [
        2018,
        33
      ],
      [
        2019,
        39
      ]
    ],
    "cite": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        0
      ],
      [
        2014,
        69
      ],
      [
        2015,
        89
      ],
      [
        2016,
        313
      ],
      [
        2017,
        409
      ],
      [
        2018,
        557
      ],
      [
        2019,
        557
      ]
    ]
  },
  {
    "id": "2102914960",
    "name": "武汉大学",
    "abbr": "WHU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        0
      ],
      [
        2014,
        0
      ],
      [
        2015,
        14
      ],
      [
        2016,
        25
      ],
      [
        2017,
        36
      ],
      [
        2018,
        85
      ],
      [
        2019,
        133
      ]
    ],
    "paper": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        0
      ],
      [
        2014,
        0
      ],
      [
        2015,
        2
      ],
      [
        2016,
        4
      ],
      [
        2017,
        7
      ],
      [
        2018,
        23
      ],
      [
        2019,
        36
      ]
    ],
    "cite": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        0
      ],
      [
        2014,
        0
      ],
      [
        2015,
        77
      ],
      [
        2016,
        101
      ],
      [
        2017,
        169
      ],
      [
        2018,
        331
      ],
      [
        2019,
        333
      ]
    ]
  },
  {
    "id": "2104598311",
    "name": "南开大学",
    "abbr": "NKU",
    "region": "中国大陆",
    "author": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        6
      ],
      [
        2014,
        10
      ],
      [
        2015,
        14
      ],
      [
        2016,
        23
      ],
      [
        2017,
        48
      ],
      [
        2018,
        75
      ],
      [
        2019,
        92
      ]
    ],
    "paper": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        1
      ],
      [
        2014,
        2
      ],
      [
        2015,
        3
      ],
      [
        2016,
        5
      ],
      [
        2017,
        15
      ],
      [
        2018,
        27
      ],
      [
        2019,
        35
      ]
    ],
    "cite": [
      [
        2010,
        0
      ],
      [
        2011,
        0
      ],
      [
        2012,
        0
      ],
      [
        2013,
        538
      ],
      [
        2014,
        1190
      ],
      [
        2015,
        1302
      ],
      [
        2016,
        1319
      ],
      [
        2017,
        1850
      ],
      [
        2018,
        1982
      ],
      [
        2019,
        2013
      ]
    ]
  }
];