import React, {useEffect, useRef, useState} from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import {Empty, List} from 'antd';

import PatentItem from './PatentItem';
import {data} from './visual_patents';

import './patents.less';


function VisualComposition() {

  const canvasRef = useRef(null);
  const chartInstance = useRef(null);
  const [entity, setEntity] = useState('');
  const [l1, setL1] = useState('');

  const dimensions = [];

  const ds = Object.values(data.reduce((acc, cur, idx) => {
    const entity = cur.entity;
    const field = cur.l1;
    if (!dimensions.includes(field)) {
      dimensions.push(field);
    }
    if (acc.hasOwnProperty(entity)) {
       if (acc[entity].hasOwnProperty(field)) {
         acc[entity][field] += 1;
       } else {
         acc[entity][field] = 1;
       }
    } else {
       acc[entity] = {_entity: entity, [field]: 1}
    }
    return acc;
  }, {}));

  dimensions.unshift('_entity');

  const [config, setConfig] = useState({
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: {type: 'shadow'},
      textStyle: {fontSize: 11, lineHeight: 11},
      formatter: function (params) {
        const markers = params.reduce((acc, cur) => {
          acc[cur.seriesName] = cur.marker;
          return acc;
        }, {});
        const texts = Object.entries(params[0].data)
          .filter(item => !item[0].startsWith('_') && markers.hasOwnProperty(item[0]))
          .sort((a, b) => b[1] - a[1])
          .map(item => `<div>${markers[item[0]]} ${item[0]}: ${item[1]}</div>`)
          .join('');
        return texts;
      }
    },
    dataset: {dimensions: dimensions, source: ds},
    legend: {icon: 'rect', bottom: '10px', left: '20px', right: '20px', textStyle: {fontSize: 11}},
    grid: {left: '40px', right: '40px', bottom: '120px', containLabel: true},
    xAxis: {type: 'value'},
    yAxis: {type: 'category'},
    series: Array(dimensions.length - 1).fill({type: 'bar', stack: '总量', barWidth: 40, label: {normal: {show: false, position: 'insideRight'}}})
  });

  useEffect(() => {
    const renderChart = () => {
      const renderedInstance = echarts.getInstanceByDom(canvasRef.current);
      chartInstance.current = renderedInstance ? renderedInstance : echarts.init(canvasRef.current);
      chartInstance.current.setOption(config);
      chartInstance.current.on('click', 'series.bar', function (params) {
        setEntity(params.name);
        setL1(params.seriesName)
      });
    };
    renderChart();
  }, [canvasRef, config]);

  useEffect(() => {
    return () => {
      chartInstance.current && chartInstance.current.dispose();
    }
  }, []);

  const patents = entity === '' || l1 === '' ? [] :
    data.filter(item => item.entity === entity && item.l1 === l1).sort((a, b) => b.apd.localeCompare(b.apd));

  return (
    <>
      <div className={'wrapper'}>
        <div className={'main'}>
          <div className={'canvas'} ref={canvasRef}/>
          <div className={'content'}>
            <div className={'title'}>Composition Analysis</div>
            {
              patents.length === 0 ?
                <div className={'empty'}>
                  <Empty description={'点击图表浏览专利'}/>
                </div>
                :
                <div className={'patents'}>
                  <List
                    size="small"
                    bordered={false}
                    dataSource={patents}
                    header={patents.length > 0 && <div className={'patents_header'}>{`${entity} > ${l1} > ${patents.length}件专利`}</div>}
                    pagination={{
                      size: 'small',
                      position: 'both',
                      hideOnSinglePage: false,
                      pageSize: 10,
                    }}
                    renderItem={patent => <List.Item><PatentItem patent={patent}/></List.Item>}
                  />
                </div>
            }
          </div>
        </div>
      </div>
      <div className={'slogan'}>
        <div className={'logo'}></div>
        TIPLAB · INSIGHT SERIAL · COMPUTATIONAL VISION
      </div>
    </>
  );
}

export default VisualComposition;
