import React, {useEffect, useRef} from 'react';
import * as d3 from 'd3';

import {affiliations} from './affiliations';

import './papers.less';


function Trends(props) {

  const canvasRef = useRef(null);

  useEffect(() => {
    const startYear = 2010;
    const endYear = 2019;

    const width = canvasRef.current.scrollWidth;
    const height = canvasRef.current.scrollHeight;

    const hMargin = 80;
    const vMargin = 80;

    const plotWidth = width - hMargin * 2;
    const plotHeight = height - vMargin * 2;

    const xScale = d3.scaleSqrt().domain([0, 1800]).range([0, width - hMargin * 2]);
    const yScale = d3.scaleSqrt().domain([0, 28000]).range([height - vMargin * 2, 0]);
    const radiusScale = d3.scaleSqrt().domain([0, 500]).range([1, 40]);
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Functions

    const x = d => d.author;
    const y = d => d.cite;
    const radius = d => d.paper;
    const color = d => d.name;
    const key = d => d.name;

    const dotPosition = elem => {
      elem.attr('cx', d => xScale(x(d)))
        .attr('cy', d => yScale(y(d)))
        .attr('r', d => radiusScale(radius(d)));
    };

    // const textPosition = elem => {
    //   elem.attr('x', d => xScale(x(d)) + radiusScale(radius(d)) + 3)
    //     .attr('y', d => yScale(y(d)) + 4);
    // };

    const order = (a, b) => radius(b) - radius(a);

    const bisect = d3.bisector(d => d[0]);

    const interpolateData = (data, year) => data.map(item => (
      {
        name: item.name,
        region: item.region,
        author: interpolateValues(item.author, year),
        paper: interpolateValues(item.paper, year),
        cite: interpolateValues(item.cite, year)
      }
    ));

    const interpolateValues = (values, year) => {
      const i = bisect.left(values, year, 0, values.length - 1);
      const a = values[i];
      if (i > 0) {
        const b = values[i - 1];
        const t = (year - a[0]) / (b[0] - a[0]);
        return a[1] * (1 - t) + b[1] * t;
      }
      return a[1];
    };

    // Process

    const svg = d3.select(canvasRef.current)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      // .style('border', '1px solid red')
      .append('g')
      .attr('class', 'plot')
      .attr('width', plotWidth)
      .attr('height', plotHeight)
      .attr('transform', `translate(${hMargin}, ${vMargin})`);

    const xAxis = d3.axisBottom(xScale).ticks(10, d3.format(',d'));
    const yAxis = d3.axisLeft(yScale);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${plotHeight})`)
      .call(xAxis);

    svg.append('g')
      .attr('class', 'y axis')
      .call(yAxis);

    svg.append('text')
      .attr('class', 'x label')
      .attr('text-anchor', 'end')
      .attr('x', plotWidth)
      .attr('y', plotHeight - 6)
      .text('累积研究者');

    svg.append('text')
      .attr('class', 'y label')
      .attr('text-anchor', 'end')
      .attr('y', 6)
      .attr('dy', '.75em')
      .attr('transform', 'rotate(-90)')
      .text('累积引用数');

    // Add the year label; the value is set on transition.
    const label = svg.append('text')
      .attr('class', 'year label')
      .attr('text-anchor', 'end')
      .attr('x', plotWidth)
      .attr('y', plotHeight - 24)
      .text(2010);

    const box = label.node().getBBox();

    const data = interpolateData(affiliations, startYear);

    const dot = svg.append('g')
      .attr('class', 'dots')
      .selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .style('fill', d => colorScale(color(d)))
      .call(dotPosition)
      .sort(order);

    // const text = svg.append('g')
    //   .attr('class', 'texts')
    //   .selectAll('.txt')
    //   .data(data)
    //   .enter()
    //   .append('text')
    //   .attr('class', 'text')
    //   .text(d => d.name)
    //   .call(textPosition)
    //   .sort(order);

    dot.append('title').text(d => d.name);

    const displayYear = (year) => {
      const data = interpolateData(affiliations, year);
      dot.data(data, key).call(dotPosition).sort(order);
      // text.data(data, key).call(textPosition).sort(order);
      label.text(Math.round(year));
    };

    const overlay = svg.append('rect')
      .attr('class', 'overlay_resize')
      .attr('x', box.x)
      .attr('y', box.y)
      .attr('width', box.width)
      .attr('height', box.height);

    const enableInteraction = () => {
      const yearScale = d3.scaleLinear()
        .domain([startYear, endYear])
        .range([box.x + 10, box.x + box.width - 10])
        .clamp(true);

      // Cancel the current transition, if any.
      svg.transition().duration(0);

      const mouseover = () => {
        label.classed('active', true);
      };

      const mouseout = () => {
        label.classed('active', false);
      };

      const mousemove = () => {
        displayYear(yearScale.invert(d3.mouse(label.node())[0]));
      };

      overlay
        .on('mouseover', mouseover)
        .on('mouseout', mouseout)
        .on('mousemove', mousemove)
        .on('touchmove', mousemove);
    };

    overlay.on('mouseover', enableInteraction);

    // Tween

    const tweenYear = () => {
      const year = d3.interpolateNumber(startYear, endYear);
      return t => {
        displayYear(year(t));
      };
    };

    const trans = d3.transition()
      .duration(30000)
      .ease(d3.easeLinear);

    svg.transition(trans)
      .tween('year', tweenYear);

  }, [canvasRef]);

  return (
    <>
      <div className={'wrapper'}>
        <div className={'main'}>
          <div className={'canvas'} ref={canvasRef}/>
          <div className={'content'}>
            <div className={'title'}>Trends</div>
          </div>
        </div>
      </div>
      <div className={'slogan'}>
        <div className={'logo'}></div>
        TIPLAB · INSIGHT SERIAL · COMPUTATIONAL VISION
      </div>
    </>
  );

}

export default Trends;
