import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {Empty, InputNumber, List, Select} from 'antd';

import {data} from './papers';

import './papers.less';


function Network(props) {

  const canvasRef = useRef(null);
  const svgRef = useRef(null);

  const [affiliation, setAffiliation] = useState('');
  const [venues, setVenues] = useState(['CVPR', 'ICCV', 'ECCV']);
  const [threshold, setThreshold] = useState(5);
  const [ds, setDs] = useState({nodes: [], edges: []});
  const [authorId, setAuthorId] = useState('');

  useEffect(() => {
    const nodeMap = {};
    const edgeMap = {};

    for (let paper of data.repo) {

      // 独立
      if (paper.affiliation.length !== 1 || affiliation !== `${paper.affiliation[0].id}`) {
        continue;
      }

      if (!venues.includes(paper.venue.name)) {
        continue;
      }

      const authors = paper.author.sort((a, b) => a.id - b.id);

      for (let author of authors) {
        const key = `${author.id}`;
        if (nodeMap.hasOwnProperty(key)) {
          nodeMap[key].count = nodeMap[key].count + 1;
        } else {
          nodeMap[key] = {id: key, name: author.name, count: 1, year: paper.venue.year};
        }
      }

      for (let i = 0; i < authors.length - 1; i++) {
        for (let j = i + 1; j < authors.length; j++) {
          const sourceId = `${authors[i].id}`;
          const targetId = `${authors[j].id}`;
          const key = `${sourceId}_${targetId}`;
          if (edgeMap.hasOwnProperty(key)) {
            edgeMap[key].count = edgeMap[key].count + 1;
          } else {
            edgeMap[key] = {id: key, source: sourceId, target: targetId, count: 1, year: paper.venue.year};
          }
        }
      }

    }

    setDs({
      nodes: Object.values(nodeMap),
      edges: Object.values(edgeMap),
    });

  }, [affiliation, venues]);

  useEffect(() => {

    const width = canvasRef.current.scrollWidth;
    const height = canvasRef.current.scrollHeight;

    const hMargin = 0;
    const vMargin = 0;

    const plotWidth = width - hMargin * 2;
    const plotHeight = height - vMargin * 2;

    const radiusScale = d3.scaleSqrt().domain([1, 100]).range([5, 120]);
    const colorScale = d3.scaleSequential(d3.interpolateGreens).domain([-20, 20]);

    const color = d => colorScale(d.count);

    if (svgRef.current === null) {
      svgRef.current = d3.select(canvasRef.current)
        .append('svg')
        // .style('border', '1px solid red')
        .attr('width', plotWidth)
        .attr('height', plotHeight);

      svgRef.current.append('g')
        .attr('class', 'evolution')
        .attr('width', plotWidth)
        .attr('height', plotHeight);

      svgRef.current.select('g').append('g').attr('class', 'edges');
      svgRef.current.select('g').append('g').attr('class', 'nodes');
    }

    const graph = svgRef.current.select('g');

    const ticked = () => {
      graph.selectAll('.edge').attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      graph.selectAll('.node').attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });
    };

      const force = d3.forceSimulation(ds.nodes)
        .force('charge', d3.forceManyBody().strength(-75))
        .force('collide', d3.forceCollide().radius(d => radiusScale(d.count) + 10))
        .force('link', d3.forceLink(ds.edges).id(d => d.id).distance(30).strength(0.7))
        .force('x', d3.forceX(plotWidth / 2))
        .force('y', d3.forceY(plotHeight / 2))
        .force('center', d3.forceCenter().x(plotWidth / 2).y(plotHeight / 2))
        .on('tick', ticked);

      // Edges

      const edgeGroups = graph.select('.edges')
        .selectAll('.edge')
        .data(ds.edges, d => d.id);

      edgeGroups.exit().remove();

      edgeGroups
        .enter()
        .append('line')
        .attr('class', 'edge')
        .attr('stroke', '#e1e1e1')
        .attr('stroke-width', '1px');

      // Nodes

      let nodeGroups = graph.select('.nodes')
        .selectAll('g')
        .data(ds.nodes, d => d.id);

      nodeGroups.exit().remove();

      const node = nodeGroups.enter()
        .append('g')
        .attr('class', 'node')
        .on("click", d => { setAuthorId(d.id) });

      const circles = node.append('circle');
      circles.append('title').text(d => d.name);

      node.append('text')
        .attr('text-anchor', 'middle')
        .attr('x', 0)
        .attr('y', 3)
        .attr('font', 'Roboto')
        .attr('fill', '#000000');

      nodeGroups = node.merge(nodeGroups);

      nodeGroups.select('circle')
        .attr('r', d => radiusScale(d.count))
        .attr('fill', d => d.id === authorId ? '#f81c2d' : color(d));

      nodeGroups.select('text')
        .text(d => d.count >= threshold || d.id === authorId ? d.name : '');

      const dragstarted = d => {
        d3.event.sourceEvent.stopPropagation();
        if (!d3.event.active) force.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      };

      const dragged = d => {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      };

      const dragended = d => {
        if (!d3.event.active) force.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      };

      node.call(
        d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );

    // Zoom
    
    svgRef.current.call(
      d3.zoom()
        .scaleExtent([-Infinity, Infinity])
        .translateExtent([[0, 0], [plotWidth, plotHeight]])
        .extent([[0, 0], [plotWidth, plotHeight]])
        .on('zoom', () => graph.attr('transform', d3.event.transform))
    );

    // Tween

    // const tweenYear = () => {
    //   const year = d3.interpolateNumber(startYear, endYear);
    //   return t => {
    //     displayYear(year(t));
    //   };
    // };
    //
    // const trans = d3.transition()
    //   .duration(30000)
    //   .ease(d3.easeLinear);
    //
    // graph.transition(trans)
    //   .tween('year', tweenYear);

  }, [ds, threshold, authorId]);

  // 独立
  const papers = authorId === '' ? [] : data.repo.filter(paper => paper.affiliation.length === 1 && affiliation === `${paper.affiliation[0].id}` &&
    venues.includes(paper.venue.name) && paper.author.some(author => `${author.id}` === authorId)
  ).sort((a, b) => b.venue.year - a.venue.year);

  // const papers = authorId === '' ? [] : data.repo.filter(paper => paper.affiliation.some(affiliation => affiliations.includes(`${affiliation.id}`)) &&
  //   venues.includes(paper.venue.name) && paper.author.some(author => `${author.id}` === authorId)
  // ).sort((a, b) => b.venue.year - a.venue.year);

  return (
    <>
      <div className={'wrapper'}>
        <div className={'main'}>
          <div className={'canvas'} ref={canvasRef}/>
          <div className={'content'}>
            <div className={'title'}>Network</div>
            <div className={'filters'}>
              <div className={'filter'}>
                <div className={'filter_label'}>研究机构</div>
                <div className={'filter_ctrl'}>
                  <Select value={affiliation} size={'small'} onChange={setAffiliation} style={{width: '100%'}}>
                    <Select.Option value="2101067073">中国科学院</Select.Option>
                    <Select.Option value="2100751172">清华大学</Select.Option>
                    <Select.Option value="2103048394">北京大学</Select.Option>
                    <Select.Option value="2104852976">中国科技大学</Select.Option>
                    <Select.Option value="2103723062">上海交通大学</Select.Option>
                    <Select.Option value="2102381148">中山大学</Select.Option>
                    <Select.Option value="2101552222">浙江大学</Select.Option>
                    <Select.Option value="2102848858">北京航空航天大学</Select.Option>
                    <Select.Option value="2102163663">哈尔滨工业大学</Select.Option>
                    <Select.Option value="2104235635">大连理工大学</Select.Option>
                    <Select.Option value="2100453429">华中科技大学</Select.Option>
                    <Select.Option value="2101117560">西安交通大学</Select.Option>
                    <Select.Option value="2104846471">北京理工大学</Select.Option>
                    <Select.Option value="2103293601">西北工业大学</Select.Option>
                    <Select.Option value="2103229542">复旦大学</Select.Option>
                    <Select.Option value="2100348776">西安电子科技大学</Select.Option>
                    <Select.Option value="2104872700">南京理工大学</Select.Option>
                    <Select.Option value="2101930586">南京大学</Select.Option>
                    <Select.Option value="2101621751">北京邮电大学</Select.Option>
                    <Select.Option value="2101465915">厦门大学</Select.Option>
                    <Select.Option value="2102416410">天津大学</Select.Option>
                    <Select.Option value="2103486486">华南理工大学</Select.Option>
                    <Select.Option value="2103191953">国防科技大学</Select.Option>
                    <Select.Option value="2101979065">上海科技大学</Select.Option>
                    <Select.Option value="2102914960">武汉大学</Select.Option>
                    <Select.Option value="2104598311">南开大学</Select.Option>
                  </Select>
                </div>
              </div>
              <div className={'filter'}>
                <div className={'filter_label'}>会议</div>
                <div className={'filter_ctrl'}>
                  <Select mode={'multiple'} value={venues} size={'small'} onChange={setVenues} style={{width: '100%'}}>
                    <Select.Option value="CVPR">CVPR</Select.Option>
                    <Select.Option value="ICCV">ICCV</Select.Option>
                    <Select.Option value="ECCV">ECCV</Select.Option>
                  </Select>
                </div>
              </div>
              <div className={'filter'}>
                <div className={'filter_label'}>显示阈值</div>
                <div className={'filter_ctrl'}>
                  <InputNumber min={1} max={100} value={threshold} size={'small'} onChange={setThreshold}/>
                </div>
              </div>
            </div>
            {
              papers.length === 0 ?
                <div className={'empty'}>
                  <Empty description={'点击作者浏览论文'}/>
                </div>
                :
                <div className={'papers'}>
                  <List
                    size="small"
                    bordered={false}
                    dataSource={papers}
                    pagination={{
                      size: 'small',
                      position: 'both',
                      hideOnSinglePage: false,
                      pageSize: 5,
                      showTotal: (total, range) => `共${total}篇论文`,
                    }}
                    renderItem={paper => (
                      <List.Item>
                        <div className={'paper'}>
                          <div className={'paper_title'}>
                            {paper.source_url ? <a href={paper.source_url} target={'_blank'}>{paper.title}</a> : paper.title}
                          </div>
                          <div className={'paper_venue'}>{`${paper.venue.name} ${paper.venue.year}`}</div>
                          <div className={'paper_author'}>
                            {
                              paper.author.map(author => (
                                <span key={`${author.id}`} className={`${author.id}` === authorId ? 'link active' : 'link'} onClick={() => {
                                  setAuthorId(`${author.id}`)
                                }}>
                            {author.name}
                          </span>
                              ))
                            }
                          </div>
                          <div className={'paper_affiliation'}>
                            {
                              paper.affiliation.map(affiliation => (
                                <span key={`${affiliation.id}`} className={affiliation === `${affiliation.id}` ? 'active' : ''}>
                            {affiliation.name}
                          </span>
                              ))
                            }
                          </div>
                          <div className={'paper_field'}>
                            {
                              paper.field.map(field => (
                                <span key={`${field.id}`}>
                            {field.name}
                          </span>
                              ))
                            }
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
            }
          </div>
        </div>
      </div>
      <div className={'slogan'}>
        <div className={'logo'}></div>
        TIPLAB · INSIGHT SERIAL · COMPUTATIONAL VISION
      </div>
    </>
  );

}

export default Network;
