export const data = [
  {
    "entity": "京东",
    "pun": "CN110309826A",
    "apn": "CN201810255879.2",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "商品信息录入方法和装置、计算机可读存储介质",
    "inv": [
      "张银心"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN105551044B",
    "apn": "CN201510941073.5",
    "apd": "2015-12-16",
    "apy": "2015",
    "tit": "一种图片对比方法和装置",
    "inv": [
      "高立松"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN103631948B",
    "apn": "CN201310674046.7",
    "apd": "2013-12-11",
    "apy": "2013",
    "tit": "命名实体的识别方法",
    "inv": [
      "张永成",
      "罗欢",
      "何泉昊",
      "张喜",
      "姜文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN105045909B",
    "apn": "CN201510490393.3",
    "apd": "2015-08-11",
    "apy": "2015",
    "tit": "从文本中识别商品名称的方法和装置",
    "inv": [
      "刘佳",
      "石东旭"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN104331395B",
    "apn": "CN201410586116.8",
    "apd": "2014-10-28",
    "apy": "2014",
    "tit": "从文本中识别中文商品名称的方法和装置",
    "inv": [
      "岳兴明"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN107729489A",
    "apn": "CN201710966609.8",
    "apd": "2017-10-17",
    "apy": "2017",
    "tit": "广告文本识别方法和装置",
    "inv": [
      "李树海"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN103617415A",
    "apn": "CN201310582905.X",
    "apd": "2013-11-19",
    "apy": "2013",
    "tit": "一种自动识别发票的装置和方法",
    "inv": [
      "刘浪"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108984777A",
    "apn": "CN201810824060.3",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "客户服务方法、装置和计算机可读存储介质",
    "inv": [
      "胡晓"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "特定场景",
    "l2": "智能客服",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN109272043A",
    "apn": "CN201811112547.5",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "用于光学字符识别的训练数据生成方法、系统和电子设备",
    "inv": [
      "郭新然",
      "刘永强",
      "桂晨光",
      "邓超",
      "王超"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "训练模型",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN107741933A",
    "apn": "CN201610643211.6",
    "apd": "2016-08-08",
    "apy": "2016",
    "tit": "用于检测文本的方法和装置",
    "inv": [
      "潘小光"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108288064A",
    "apn": "CN201710012538.8",
    "apd": "2017-01-09",
    "apy": "2017",
    "tit": "用于生成图片的方法和装置",
    "inv": [
      "陈标龙",
      "王永亮",
      "王青泽"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "训练模型",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN109255355A",
    "apn": "CN201810523425.9",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "图像处理方法、装置、终端、电子设备及计算机可读介质",
    "inv": [
      "边同昭",
      "王彪",
      "李星毅"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN109978747A",
    "apn": "CN201711449540.8",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "用于审核图片的方法及装置",
    "inv": [
      "张涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108009546A",
    "apn": "CN201610970753.4",
    "apd": "2016-10-28",
    "apy": "2016",
    "tit": "信息识别方法和装置",
    "inv": [
      "温铸清"
    ],
    "app": [
      "北京京东振世信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN109117814A",
    "apn": "CN201810983676.5",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及介质",
    "inv": [
      "韩月",
      "刘永强",
      "桂晨光",
      "邓超",
      "王超"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108596168A",
    "apn": "CN201810362577.5",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "用于识别图像中字符的方法、装置及介质",
    "inv": [
      "杨朔",
      "龚杰",
      "孙俊",
      "李伟"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108932774A",
    "apn": "CN201810641278.5",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "信息检测方法和装置",
    "inv": [
      "安耀祖",
      "龚顺强",
      "郁昌存"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN110334346A",
    "apn": "CN201910560227.4",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "一种PDF文件的信息抽取方法和装置",
    "inv": [
      "郑宇宇"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN109784407A",
    "apn": "CN201910043827.3",
    "apd": "2019-01-17",
    "apy": "2019",
    "tit": "确定表字段的类型的方法和装置",
    "inv": [
      "范叶亮",
      "马云龙",
      "卢周"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "训练模型",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN109784332A",
    "apn": "CN201910043826.9",
    "apd": "2019-01-17",
    "apy": "2019",
    "tit": "一种文档图像倾斜检测的方法和装置",
    "inv": [
      "刘永强"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN109214386A",
    "apn": "CN201811072105.2",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "用于生成图像识别模型的方法和装置",
    "inv": [
      "郁昌存",
      "王德鑫",
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "训练模型",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108932754A",
    "apn": "CN201810639160.9",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "基于人工检票界面图像的检票方法、装置、介质及电子设备",
    "inv": [
      "郁昌存",
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108961592A",
    "apn": "CN201810646278.4",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "快递包装提取方法、系统、存储介质及电子设备",
    "inv": [
      "韩璐懿"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "交通/物流",
    "l2": "物流",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN108805128A",
    "apn": "CN201710312140.6",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "一种字符分割方法和装置",
    "inv": [
      "李俊玲"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "京东",
    "pun": "CN107767193A",
    "apn": "CN201610709243.1",
    "apd": "2016-08-23",
    "apy": "2016",
    "tit": "一种管理发票的方法",
    "inv": [
      "马文军"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN100428265C",
    "apn": "CN200610118618.3",
    "apd": "2006-11-22",
    "apy": "2006",
    "tit": "一种用带数码相机的手机实现名片扫描的方法",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101335801B",
    "apn": "CN200810037226.3",
    "apd": "2008-05-09",
    "apy": "2008",
    "tit": "一种结合相机和光学定位的扫描方法",
    "inv": [
      "陈飒"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101281592A",
    "apn": "CN200810037227.8",
    "apd": "2008-05-09",
    "apy": "2008",
    "tit": "一种基于相机和文字识别技术的文字摘抄方法",
    "inv": [
      "陈飒"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101373403B",
    "apn": "CN200810042489.3",
    "apd": "2008-09-04",
    "apy": "2008",
    "tit": "一种通过名片识别技术在地址簿中自动生成并添加图标的方法",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101639933B",
    "apn": "CN200910054970.9",
    "apd": "2009-07-16",
    "apy": "2009",
    "tit": "图像旋转校正方法及系统、电子设备",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101609365B",
    "apn": "CN200910055150.1",
    "apd": "2009-07-21",
    "apy": "2009",
    "tit": "字符输入方法及系统、电子设备及其键盘",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101620595A",
    "apn": "CN200910056258.2",
    "apd": "2009-08-11",
    "apy": "2009",
    "tit": "电子设备的文本翻译方法及系统",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101620614A",
    "apn": "CN200910056257.8",
    "apd": "2009-08-11",
    "apy": "2009",
    "tit": "利用电子设备查字典的方法及系统",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101634795B",
    "apn": "CN200910056762.2",
    "apd": "2009-08-20",
    "apy": "2009",
    "tit": "相机自动聚焦方法及系统",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101634796A",
    "apn": "CN200910056763.7",
    "apd": "2009-08-20",
    "apy": "2009",
    "tit": "相机自动变焦方法及系统",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101639760A",
    "apn": "CN200910194681.9",
    "apd": "2009-08-27",
    "apy": "2009",
    "tit": "联系信息输入方法及系统",
    "inv": [
      "朱林"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101807293B",
    "apn": "CN201010129600.X",
    "apd": "2010-03-19",
    "apy": "2010",
    "tit": "调整图像四边形框检测结果的方法",
    "inv": [
      "丁霄鹏",
      "王巍",
      "李平新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101807273A",
    "apn": "CN201010132847.7",
    "apd": "2010-03-25",
    "apy": "2010",
    "tit": "提取信用卡短信中的消费信息进行财务管理的方法及系统",
    "inv": [
      "张彬",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101895606A",
    "apn": "CN201010220440.X",
    "apd": "2010-07-06",
    "apy": "2010",
    "tit": "识别联系信息后通知对方的方法及系统",
    "inv": [
      "罗希平",
      "陈青山"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101895570A",
    "apn": "CN201010220332.2",
    "apd": "2010-07-06",
    "apy": "2010",
    "tit": "输入联系信息后通过网络通知对方的方法及系统",
    "inv": [
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101882227B",
    "apn": "CN201010225606.7",
    "apd": "2010-07-13",
    "apy": "2010",
    "tit": "基于图像匹配和网络查询的识别方法及系统",
    "inv": [
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101916258B",
    "apn": "CN201010225609.0",
    "apd": "2010-07-13",
    "apy": "2010",
    "tit": "通过网络查询自动校正识别结果的方法及系统",
    "inv": [
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101902414A",
    "apn": "CN201010231725.3",
    "apd": "2010-07-20",
    "apy": "2010",
    "tit": "建立联系后通过网络通知好友的方法及系统",
    "inv": [
      "龙腾"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101901266A",
    "apn": "CN201010243979.7",
    "apd": "2010-08-03",
    "apy": "2010",
    "tit": "输入联系信息后自动在网络上发布消息的方法及系统",
    "inv": [
      "镇立新",
      "陈青山",
      "龙腾",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101895557A",
    "apn": "CN201010243963.6",
    "apd": "2010-08-03",
    "apy": "2010",
    "tit": "以网络方式管理并自动更新联系信息的方法及系统",
    "inv": [
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101916271A",
    "apn": "CN201010249909.2",
    "apd": "2010-08-10",
    "apy": "2010",
    "tit": "自动获取联系人头像的方法及系统",
    "inv": [
      "镇立新",
      "陈青山",
      "龙腾",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102006565A",
    "apn": "CN201010264054.0",
    "apd": "2010-08-24",
    "apy": "2010",
    "tit": "识别名片后将本人的社交账号发送给名片主人的方法",
    "inv": [
      "陈青山",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101945057B",
    "apn": "CN201010271280.1",
    "apd": "2010-08-31",
    "apy": "2010",
    "tit": "输入联系信息后通过网络获取联系人提供的信息的方法",
    "inv": [
      "镇立新",
      "龙腾",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101977216A",
    "apn": "CN201010512060.3",
    "apd": "2010-10-19",
    "apy": "2010",
    "tit": "一种基于地理位置信息建立联系人群组的方法",
    "inv": [
      "陈青山"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101996320B",
    "apn": "CN201010548188.5",
    "apd": "2010-11-17",
    "apy": "2010",
    "tit": "自动提取即时通讯账号并做出相应操作的方法",
    "inv": [
      "镇立新",
      "罗希平",
      "陈青山",
      "李仕益"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101996253A",
    "apn": "CN201010548158.4",
    "apd": "2010-11-17",
    "apy": "2010",
    "tit": "自动提取地址并搜索地图的方法",
    "inv": [
      "镇立新",
      "罗希平",
      "陈青山",
      "曹超阳"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101968865B",
    "apn": "CN201010548326.X",
    "apd": "2010-11-17",
    "apy": "2010",
    "tit": "在电子日历中添加提醒事件的方法",
    "inv": [
      "镇立新",
      "罗希平",
      "陈青山",
      "曹超阳"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "事件管理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101976265A",
    "apn": "CN201010548204.0",
    "apd": "2010-11-17",
    "apy": "2010",
    "tit": "自动判断电话号码并添加联系人的方法",
    "inv": [
      "镇立新",
      "罗希平",
      "陈青山",
      "李仕益"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102065078A",
    "apn": "CN201010550508.0",
    "apd": "2010-11-18",
    "apy": "2010",
    "tit": "通过网络获取联系人提供的更多信息的方法",
    "inv": [
      "陈青山",
      "罗希平",
      "龙腾"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101976333A",
    "apn": "CN201010549537.5",
    "apd": "2010-11-18",
    "apy": "2010",
    "tit": "自动区分第一代和第二代身份证的方法",
    "inv": [
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101976271A",
    "apn": "CN201010570693.X",
    "apd": "2010-11-19",
    "apy": "2010",
    "tit": "自动提取网址并自动打开网页的方法",
    "inv": [
      "镇立新",
      "罗希平",
      "陈青山",
      "曹超阳"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102006552A",
    "apn": "CN201010553575.8",
    "apd": "2010-11-22",
    "apy": "2010",
    "tit": "基于地理位置信息建立联系人群组的方法",
    "inv": [
      "龙腾"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101980156A",
    "apn": "CN201010553571.X",
    "apd": "2010-11-22",
    "apy": "2010",
    "tit": "自动提取电子邮件地址并新建电子邮件的方法",
    "inv": [
      "镇立新",
      "罗希平",
      "陈青山",
      "李仕益"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102074001B",
    "apn": "CN201010558966.9",
    "apd": "2010-11-25",
    "apy": "2010",
    "tit": "一种文本图像的拼接方法及系统",
    "inv": [
      "黄灿",
      "龙腾",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101976449B",
    "apn": "CN201010558868.5",
    "apd": "2010-11-25",
    "apy": "2010",
    "tit": "拍摄多幅文本图像并拼接的方法",
    "inv": [
      "龙腾",
      "黄灿",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102013094B",
    "apn": "CN201010558948.0",
    "apd": "2010-11-25",
    "apy": "2010",
    "tit": "提高文本图像清晰度的方法及系统",
    "inv": [
      "黄灿",
      "龙腾",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102012629B",
    "apn": "CN201010558888.2",
    "apd": "2010-11-25",
    "apy": "2010",
    "tit": "用于文本图像拼接的拍摄方法",
    "inv": [
      "黄灿",
      "龙腾",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102201053B",
    "apn": "CN201010583973.4",
    "apd": "2010-12-10",
    "apy": "2010",
    "tit": "一种文本图像的切边方法",
    "inv": [
      "龙腾",
      "张彬",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN101986335A",
    "apn": "CN201010582729.6",
    "apd": "2010-12-10",
    "apy": "2010",
    "tit": "获取联系人提供的联系信息的方法",
    "inv": [
      "陈青山",
      "罗希平",
      "龙腾"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102158519A",
    "apn": "CN201010587868.8",
    "apd": "2010-12-14",
    "apy": "2010",
    "tit": "传递联系信息的方法及系统",
    "inv": [
      "陈青山",
      "罗希平",
      "李仕益"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102158817A",
    "apn": "CN201010587560.3",
    "apd": "2010-12-14",
    "apy": "2010",
    "tit": "通过网络获取他人联系信息的方法及系统",
    "inv": [
      "罗希平",
      "陈青山"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102231188A",
    "apn": "CN201110187289.9",
    "apd": "2011-07-05",
    "apy": "2011",
    "tit": "结合文字识别和图像匹配的名片识别方法",
    "inv": [
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102270296A",
    "apn": "CN201110186950.4",
    "apd": "2011-07-05",
    "apy": "2011",
    "tit": "结合文字识别和图像匹配交换名片信息的方法",
    "inv": [
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102393847B",
    "apn": "CN201110187278.0",
    "apd": "2011-07-05",
    "apy": "2011",
    "tit": "判断联系人列表中是否存在欲添加名片的方法",
    "inv": [
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102333278B",
    "apn": "CN201110214524.7",
    "apd": "2011-07-29",
    "apy": "2011",
    "tit": "结合蓝牙技术和蓝牙设备地址的电子名片传递方法及系统",
    "inv": [
      "龙腾",
      "罗希平",
      "陈青山",
      "镇立新",
      "曹璐"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102333279B",
    "apn": "CN201110214846.1",
    "apd": "2011-07-29",
    "apy": "2011",
    "tit": "一种结合蓝牙无线技术的电子名片传递方法及系统",
    "inv": [
      "龙腾",
      "罗希平",
      "陈青山",
      "镇立新",
      "曹璐"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102332003A",
    "apn": "CN201110214523.2",
    "apd": "2011-07-29",
    "apy": "2011",
    "tit": "结合地理位置信息的电子名片传递方法及系统",
    "inv": [
      "潘子杰",
      "曹璐",
      "汤惟琛",
      "洪谦"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102333276A",
    "apn": "CN201110214508.8",
    "apd": "2011-07-29",
    "apy": "2011",
    "tit": "结合地理位置信息和验证信息的电子名片传递方法及系统",
    "inv": [
      "镇立新",
      "潘子杰",
      "曹璐",
      "汤惟琛",
      "洪谦"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102333277B",
    "apn": "CN201110214509.2",
    "apd": "2011-07-29",
    "apy": "2011",
    "tit": "结合地理位置信息和蓝牙技术的电子名片传递方法及系统",
    "inv": [
      "罗希平",
      "陈青山",
      "镇立新",
      "龙腾",
      "曹璐"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102314607B",
    "apn": "CN201110226374.1",
    "apd": "2011-08-09",
    "apy": "2011",
    "tit": "图像检测方法",
    "inv": [
      "镇立新",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102324044A",
    "apn": "CN201110268181.2",
    "apd": "2011-09-09",
    "apy": "2011",
    "tit": "卡片信息获取方法及系统",
    "inv": [
      "罗希平",
      "龙腾"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102306196A",
    "apn": "CN201110283518.7",
    "apd": "2011-09-22",
    "apy": "2011",
    "tit": "自动填写注册信息的方法及系统",
    "inv": [
      "潘子杰",
      "曹超阳",
      "镇立新",
      "陈青山",
      "龙腾"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102354365A",
    "apn": "CN201110285360.7",
    "apd": "2011-09-23",
    "apy": "2011",
    "tit": "准确识别图像的识别方法及系统",
    "inv": [
      "镇立新",
      "陈飒",
      "陈青山",
      "龙腾",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102354366A",
    "apn": "CN201110285410.1",
    "apd": "2011-09-23",
    "apy": "2011",
    "tit": "基于网络来识别图像的方法及系统",
    "inv": [
      "罗希平",
      "镇立新",
      "陈飒",
      "陈青山",
      "龙腾"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102497327B",
    "apn": "CN201110376644.7",
    "apd": "2011-11-23",
    "apy": "2011",
    "tit": "一种利用电子邮件发送及接收名片信息的方法",
    "inv": [
      "曹璐",
      "潘子杰",
      "汤惟琛",
      "洪谦",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102496010A",
    "apn": "CN201110410198.7",
    "apd": "2011-12-09",
    "apy": "2011",
    "tit": "一种结合预览图像和拍摄图像的名片识别方法",
    "inv": [
      "龙腾",
      "罗希平",
      "镇立新",
      "陈青山"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102521384B",
    "apn": "CN201110433644.6",
    "apd": "2011-12-21",
    "apy": "2011",
    "tit": "一种利用网络查询名片传递历史信息的方法",
    "inv": [
      "镇立新",
      "陈青山",
      "龙腾",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102932403A",
    "apn": "CN201210316575.5",
    "apd": "2012-08-30",
    "apy": "2012",
    "tit": "自动将名片中的联系信息同步至移动设备的方法及系统",
    "inv": [
      "陈青山",
      "镇立新",
      "徐杰"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102929848A",
    "apn": "CN201210427772.4",
    "apd": "2012-10-31",
    "apy": "2012",
    "tit": "PDF文档的生成方法及其生成系统",
    "inv": [
      "沈东辉",
      "罗希平",
      "陈青山",
      "曹璐"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102982100A",
    "apn": "CN201210436505.3",
    "apd": "2012-11-05",
    "apy": "2012",
    "tit": "一种名片电子化的方法",
    "inv": [
      "曹璐",
      "潘子杰",
      "陈青山",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102930267B",
    "apn": "CN201210461924.2",
    "apd": "2012-11-16",
    "apy": "2012",
    "tit": "卡片扫描图像的切分方法",
    "inv": [
      "郭丰俊"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102945377B",
    "apn": "CN201210496657.2",
    "apd": "2012-11-28",
    "apy": "2012",
    "tit": "获取纸质笔记本中内容的方法及装置",
    "inv": [
      "陈青山"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103020619B",
    "apn": "CN201210517167.6",
    "apd": "2012-12-05",
    "apy": "2012",
    "tit": "一种自动切分电子化笔记本中手写条目的方法",
    "inv": [
      "陈青山",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103034842A",
    "apn": "CN201210518107.6",
    "apd": "2012-12-05",
    "apy": "2012",
    "tit": "一种方便电子化的专业笔记本及其电子缩略图显示方法",
    "inv": [
      "曹璐",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102982160B",
    "apn": "CN201210516663.X",
    "apd": "2012-12-05",
    "apy": "2012",
    "tit": "方便电子化的专业笔记本及其电子化文档的自动分类方法",
    "inv": [
      "曹璐",
      "陈青山",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103020812B",
    "apn": "CN201210518250.5",
    "apd": "2012-12-05",
    "apy": "2012",
    "tit": "方便电子化的专业笔记本及其添加到电子日历中的方法",
    "inv": [
      "曹璐",
      "镇立新",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102938063B",
    "apn": "CN201210516674.8",
    "apd": "2012-12-05",
    "apy": "2012",
    "tit": "一种方便电子化的专业笔记本及其电子化方法",
    "inv": [
      "曹璐"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN102938061A",
    "apn": "CN201210517166.1",
    "apd": "2012-12-05",
    "apy": "2012",
    "tit": "方便电子化的专业笔记本及其页码自动识别方法",
    "inv": [
      "曹璐",
      "镇立新",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103106634A",
    "apn": "CN201210575553.0",
    "apd": "2012-12-26",
    "apy": "2012",
    "tit": "银行卡个人信息保护方法及系统",
    "inv": [
      "陈飒"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103136660A",
    "apn": "CN201310080115.1",
    "apd": "2013-03-13",
    "apy": "2013",
    "tit": "扩展名片信息的方法及系统",
    "inv": [
      "镇立新",
      "龙腾",
      "陈青山",
      "潘子杰",
      "曹超阳"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103237165A",
    "apn": "CN201310080162.6",
    "apd": "2013-03-13",
    "apy": "2013",
    "tit": "实时查看名片扩展信息的方法及电子设备",
    "inv": [
      "镇立新",
      "龙腾",
      "陈青山",
      "潘子杰",
      "曹超阳"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103235946A",
    "apn": "CN201310120043.9",
    "apd": "2013-04-08",
    "apy": "2013",
    "tit": "人工识别名片信息的防泄密处理方法",
    "inv": [
      "张彬",
      "曹超阳",
      "李平新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103399695B",
    "apn": "CN201310330784.X",
    "apd": "2013-08-01",
    "apy": "2013",
    "tit": "用于智能无线通信终端的四边形边框识别方法及装置",
    "inv": [
      "龙腾",
      "王跃",
      "沈东辉",
      "李平新",
      "胡明"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103400387B",
    "apn": "CN201310338246.5",
    "apd": "2013-08-06",
    "apy": "2013",
    "tit": "在图像中线段吸附的方法及装置，构造多边形的方法及装置",
    "inv": [
      "龙腾",
      "王跃",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103399696B",
    "apn": "CN201310357997.1",
    "apd": "2013-08-16",
    "apy": "2013",
    "tit": "直线/线段吸附的方法及装置，构造多边形的方法及装置",
    "inv": [
      "龙腾",
      "王跃",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103475764A",
    "apn": "CN201310365218.2",
    "apd": "2013-08-21",
    "apy": "2013",
    "tit": "输入联系人的方法和装置,联系人的分类方法和装置",
    "inv": [
      "曹璐",
      "李平新",
      "丁凯",
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103500337B",
    "apn": "CN201310462071.9",
    "apd": "2013-09-30",
    "apy": "2013",
    "tit": "用于智能无线通信终端的识别四边形边框的方法及装置",
    "inv": [
      "陈青山",
      "沈东辉",
      "占大志"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103514297A",
    "apn": "CN201310483813.6",
    "apd": "2013-10-16",
    "apy": "2013",
    "tit": "文本增加批注数据的方法及装置,查询方法及装置",
    "inv": [
      "镇立新"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103533133B",
    "apn": "CN201310483829.7",
    "apd": "2013-10-16",
    "apy": "2013",
    "tit": "地理位置记忆并找回的方法以及无线通信终端",
    "inv": [
      "郭丰俊"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "商业/商务",
    "l2": "事件管理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103699527A",
    "apn": "CN201310707608.3",
    "apd": "2013-12-20",
    "apy": "2013",
    "tit": "图像翻译系统及图像翻译方法",
    "inv": [
      "丁凯"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN103714327B",
    "apn": "CN201310746613.5",
    "apd": "2013-12-30",
    "apy": "2013",
    "tit": "一种图像方向校正方法及系统",
    "inv": [
      "郭丰俊",
      "罗希平",
      "王晓立"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN104020847A",
    "apn": "CN201410203404.0",
    "apd": "2014-05-15",
    "apy": "2014",
    "tit": "字符输入方法及装置",
    "inv": [
      "镇立新",
      "陈青山",
      "罗希平"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN104408427A",
    "apn": "CN201410709428.3",
    "apd": "2014-12-01",
    "apy": "2014",
    "tit": "图像四边形识别的方法和装置",
    "inv": [
      "陈鹏渊"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN104484397A",
    "apn": "CN201410773460.8",
    "apd": "2014-12-16",
    "apy": "2014",
    "tit": "图像文档自动排序方法及装置",
    "inv": [
      "沈东辉"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "合合信息",
    "pun": "CN104462044A",
    "apn": "CN201410773904.8",
    "apd": "2014-12-16",
    "apy": "2014",
    "tit": "表格图像识别编辑方法及装置",
    "inv": [
      "沈东辉",
      "陈鹏渊",
      "郑显军"
    ],
    "app": [
      "上海合合信息科技发展有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN106796647B",
    "apn": "CN201480081759.5",
    "apd": "2014-09-05",
    "apy": "2014",
    "tit": "场景文本检测系统和方法",
    "inv": [
      "汤晓鸥",
      "黄韡林",
      "乔宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN107636691A",
    "apn": "CN201580080720.6",
    "apd": "2015-06-12",
    "apy": "2015",
    "tit": "用于识别图像中的文本的方法和设备",
    "inv": [
      "汤晓鸥",
      "黄伟林",
      "乔宇",
      "吕健勤",
      "贺盼"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN106295629B",
    "apn": "CN201610561355.7",
    "apd": "2016-07-15",
    "apy": "2016",
    "tit": "结构化文本检测方法和系统",
    "inv": [
      "向东来",
      "夏炎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN106446899A",
    "apn": "CN201610842572.3",
    "apd": "2016-09-22",
    "apy": "2016",
    "tit": "文字检测方法和装置、及文字检测训练方法和装置",
    "inv": [
      "向东来",
      "郭强",
      "夏炎",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108229285A",
    "apn": "CN201710393229.X",
    "apd": "2017-05-27",
    "apy": "2017",
    "tit": "物体分类方法、物体分类器的训练方法、装置和电子设备",
    "inv": [
      "汤晓鸥",
      "陈恺",
      "宋航",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108228686A",
    "apn": "CN201710453664.7",
    "apd": "2017-06-15",
    "apy": "2017",
    "tit": "用于实现图文匹配的方法、装置和电子设备",
    "inv": [
      "李爽",
      "肖桐",
      "李鸿升",
      "杨巍",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "WO2018010657A1",
    "apn": "PCT/CN2017/092586",
    "apd": "2017-07-12",
    "apy": "2017",
    "tit": "结构化文本检测方法和系统、计算设备",
    "inv": [
      "向东来",
      "夏炎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "WO2018054326A1",
    "apn": "PCT/CN2017/102679",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "文字检测方法和装置、及文字检测训练方法和装置",
    "inv": [
      "向东来",
      "郭强",
      "夏炎",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108230332A",
    "apn": "CN201711039709.2",
    "apd": "2017-10-30",
    "apy": "2017",
    "tit": "文字图像的处理方法和装置、电子设备、计算机存储介质",
    "inv": [
      "张杰",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108229299A",
    "apn": "CN201711050768.X",
    "apd": "2017-10-31",
    "apy": "2017",
    "tit": "证件的识别方法和装置、电子设备、计算机存储介质",
    "inv": [
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108229303A",
    "apn": "CN201711126372.9",
    "apd": "2017-11-14",
    "apy": "2017",
    "tit": "检测识别和检测识别网络的训练方法及装置、设备、介质",
    "inv": [
      "刘学博",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108229469A",
    "apn": "CN201711173890.6",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "文字的识别方法、装置、存储介质、程序产品和电子设备",
    "inv": [
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108228757A",
    "apn": "CN201711402436.3",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "图像搜索方法和装置、电子设备、存储介质、程序",
    "inv": [
      "张韵璇",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108229470A",
    "apn": "CN201711407535.0",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "文字图像处理方法、装置、设备及存储介质",
    "inv": [
      "王权",
      "梁鼎",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108564084A",
    "apn": "CN201810435318.0",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "文字检测方法、装置、终端及存储介质",
    "inv": [
      "王赢绪",
      "刘学博",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN108717543A",
    "apn": "CN201810457155.6",
    "apd": "2018-05-14",
    "apy": "2018",
    "tit": "一种发票识别方法及装置、计算机存储介质",
    "inv": [
      "郑迪昕",
      "梁鼎",
      "刘学博"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN109284750A",
    "apn": "CN201810923451.0",
    "apd": "2018-08-14",
    "apy": "2018",
    "tit": "票据识别方法及装置、电子设备及存储介质",
    "inv": [
      "陈子萍",
      "刘学博",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "WO2019119966A1",
    "apn": "PCT/CN2018/112093",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "文字图像处理方法、装置、设备及存储介质",
    "inv": [
      "王权",
      "梁鼎",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN109615006A",
    "apn": "CN201811504102.1",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "文字识别方法及装置、电子设备和存储介质",
    "inv": [
      "尹天舒",
      "刘学博"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN109670458A",
    "apn": "CN201811573454.2",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种车牌识别方法及装置",
    "inv": [
      "刘学博"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN109858432A",
    "apn": "CN201910081909.7",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "一种检测图像中文字信息的方法及装置、计算机设备",
    "inv": [
      "杨武魁",
      "刘学博"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN110321895A",
    "apn": "CN201910362419.4",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "证件识别方法和装置、电子设备、计算机可读存储介质",
    "inv": [
      "郑迪昕",
      "刘学博"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN110473259A",
    "apn": "CN201910701860.0",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "位姿确定方法及装置、电子设备和存储介质",
    "inv": [
      "朱铖恺",
      "冯岩",
      "武伟",
      "闫俊杰",
      "林思睿"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商汤",
    "pun": "CN110458164A",
    "apn": "CN201910724473.9",
    "apd": "2019-08-07",
    "apy": "2019",
    "tit": "图像处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "许有疆",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110503105A",
    "apn": "CN201910821315.5",
    "apd": "2019-09-02",
    "apy": "2019",
    "tit": "字符识别方法、训练数据获取方法、装置和介质",
    "inv": [
      "周以晴",
      "侯绍东",
      "熊玉竹"
    ],
    "app": [
      "苏州美能华智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110532563A",
    "apn": "CN201910821973.4",
    "apd": "2019-09-02",
    "apy": "2019",
    "tit": "文本中关键段落的检测方法及装置",
    "inv": [
      "熊玉竹",
      "周以晴",
      "侯绍东"
    ],
    "app": [
      "苏州美能华智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110532968A",
    "apn": "CN201910821308.5",
    "apd": "2019-09-02",
    "apy": "2019",
    "tit": "表格识别方法、装置和存储介质",
    "inv": [
      "侯绍东",
      "周以晴",
      "熊玉竹"
    ],
    "app": [
      "苏州美能华智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110415460A",
    "apn": "CN201910785590.6",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "一种自助票据处理终端及其票据处理方法",
    "inv": [
      "兰建荣",
      "赵勇",
      "张可可"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110415459A",
    "apn": "CN201910785589.3",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "一种防卡纸自助票据处理终端及其票据处理方法",
    "inv": [
      "吴文彬",
      "张可可",
      "兰建荣",
      "赵勇"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110427793A",
    "apn": "CN201910706173.8",
    "apd": "2019-08-01",
    "apy": "2019",
    "tit": "一种基于深度学习的条码检测方法及其系统",
    "inv": [
      "林玉玲",
      "庄国金",
      "陈文传"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110188336A",
    "apn": "CN201910447187.2",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "一种基于OA申请单生成报销单的方法和装置",
    "inv": [
      "郑伟敏",
      "吴鹏京",
      "黄商清"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "商业/商务",
    "l2": "账单/订单",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110119441A",
    "apn": "CN201910404781.3",
    "apd": "2019-05-16",
    "apy": "2019",
    "tit": "基于汉字结构的文字点选验证码识别与填入方法",
    "inv": [
      "吴胜杰",
      "林炳松",
      "陈成乐"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110059695A",
    "apn": "CN201910328657.3",
    "apd": "2019-04-23",
    "apy": "2019",
    "tit": "一种基于垂直投影的字符分割方法及终端",
    "inv": [
      "庄国金",
      "陈文传",
      "杜保发"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110059705A",
    "apn": "CN201910323742.0",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "一种基于建模的OCR识别结果判决方法和设备",
    "inv": [
      "郝占龙",
      "庄国金",
      "陈文传",
      "杜保发",
      "林玉玲",
      "吴建杭",
      "方恒凯"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110020655A",
    "apn": "CN201910316926.4",
    "apd": "2019-04-19",
    "apy": "2019",
    "tit": "一种基于二值化的字符去噪方法及终端",
    "inv": [
      "庄国金",
      "郝占龙",
      "杜保发",
      "陈文传",
      "吴建杭",
      "林玉玲",
      "方恒凯"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110020640A",
    "apn": "CN201910316776.7",
    "apd": "2019-04-19",
    "apy": "2019",
    "tit": "一种纠正身份证信息的方法及终端",
    "inv": [
      "庄国金",
      "林玉玲",
      "陈文传",
      "郝占龙",
      "杜保发",
      "吴建杭",
      "方恒凯"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110059692A",
    "apn": "CN201910302994.5",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "一种识别企业所属行业的方法及终端",
    "inv": [
      "邱涛",
      "吴胜杰",
      "翁安栋"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109977907A",
    "apn": "CN201910270270.7",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "基于票据识别的费用报销方法及其系统",
    "inv": [
      "李辉",
      "吴建杭",
      "林波",
      "黄星根",
      "陈文传",
      "黄静"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109977910A",
    "apn": "CN201910273966.5",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "基于彩色线段的票据快速定位方法及其系统",
    "inv": [
      "吴建杭",
      "李辉",
      "林波",
      "黄星根",
      "陈文传",
      "方恒凯",
      "郝占龙",
      "林玉玲",
      "庄国金"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109978878A",
    "apn": "CN201910272013.7",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "基于LSD的彩色图像线段检测方法及其系统",
    "inv": [
      "吴建杭",
      "陈文传",
      "杜保发",
      "方恒凯",
      "郝占龙",
      "林玉玲",
      "庄国金"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN110008960A",
    "apn": "CN201910247364.2",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "一种检测字符片段完整性的方法及终端",
    "inv": [
      "郝占龙",
      "林玉玲",
      "陈文传",
      "杜保发",
      "庄国金"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109948135A",
    "apn": "CN201910232082.5",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "一种基于表格特征归一化图像的方法及设备",
    "inv": [
      "吴建杭",
      "陈文传",
      "杜保发",
      "方恒凯",
      "郝占龙",
      "林玉玲",
      "庄国金"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109919125A",
    "apn": "CN201910209140.2",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "基于票据识别的差旅行程还原方法及其系统",
    "inv": [
      "黄商清",
      "柯清河",
      "黄桃发",
      "蔡声煌",
      "尤长乐",
      "黄静"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109948620A",
    "apn": "CN201910208497.9",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "一种字符分割方法及终端",
    "inv": [
      "庄国金",
      "郝占龙",
      "陈文传",
      "杜保发",
      "林玉玲",
      "吴建杭",
      "方恒凯"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109871847A",
    "apn": "CN201910189723.3",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "一种OCR识别方法及终端",
    "inv": [
      "郝占龙",
      "庄国金",
      "陈文传",
      "杜保发",
      "林玉玲"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109919076A",
    "apn": "CN201910160850.0",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "基于深度学习的确认OCR识别结果可靠性的方法及介质",
    "inv": [
      "郝占龙",
      "林玉玲",
      "陈文传",
      "杜保发"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109902737A",
    "apn": "CN201910138092.2",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "一种票据分类方法及终端",
    "inv": [
      "林良平",
      "周文贵",
      "谢学礼",
      "吴友益"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109800761A",
    "apn": "CN201910074335.0",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "基于深度学习模型创建纸质文档结构化数据的方法和终端",
    "inv": [
      "陈文传",
      "郝占龙",
      "林玉玲"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109800751A",
    "apn": "CN201910074353.9",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "一种基于构建深度学习网络的票据识别方法及终端",
    "inv": [
      "周异",
      "陈凯",
      "何建华",
      "韦建",
      "杜保发",
      "周曲"
    ],
    "app": [
      "上海深杳智能科技有限公司",
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109816118A",
    "apn": "CN201910074243.2",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "一种基于深度学习模型的创建结构化文档的方法及终端",
    "inv": [
      "黄征",
      "陈凯",
      "周曲",
      "周异",
      "何建华"
    ],
    "app": [
      "上海深杳智能科技有限公司",
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109840492A",
    "apn": "CN201910075453.3",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "基于深度学习网络的文档识别方法和终端",
    "inv": [
      "郝占龙",
      "林玉玲",
      "陈文传"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109784342A",
    "apn": "CN201910067240.6",
    "apd": "2019-01-24",
    "apy": "2019",
    "tit": "一种基于深度学习模型的OCR识别方法及终端",
    "inv": [
      "林玉玲",
      "郝占龙",
      "陈文传",
      "吴建杭",
      "庄国金",
      "方恒凯"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109656733A",
    "apn": "CN201811615258.7",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "智能调度多OCR识别引擎的方法及设备",
    "inv": [
      "陈辉鑫",
      "周文贵"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109635729A",
    "apn": "CN201811515989.4",
    "apd": "2018-12-12",
    "apy": "2018",
    "tit": "一种表格识别方法及终端",
    "inv": [
      "郭国强",
      "周文贵"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109636557A",
    "apn": "CN201811512335.6",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "一种基于票据识别的智能分类记账方法及设备",
    "inv": [
      "沈超来",
      "连航",
      "陈耀才"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109558844A",
    "apn": "CN201811457329.5",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "基于图像归一化提升自定义模板识别率的方法及设备",
    "inv": [
      "吴建杭",
      "陈文传",
      "杜保发",
      "周文贵"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109558846A",
    "apn": "CN201811457461.6",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "基于OCR模板归一化提升自定义模板识别率方法及设备",
    "inv": [
      "吴建杭",
      "庄国金",
      "杜保发",
      "周文贵",
      "陈文传"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109543614A",
    "apn": "CN201811401631.9",
    "apd": "2018-11-22",
    "apy": "2018",
    "tit": "一种全文本差异比对方法及设备",
    "inv": [
      "阎嘉骏",
      "郑伟敏",
      "庄国金",
      "方恒凯"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "商业/商务",
    "l2": "合同",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109271951A",
    "apn": "CN201811142579.X",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "一种提升记账审核效率的方法及系统",
    "inv": [
      "连航",
      "粱昌成",
      "杨惠端",
      "罗春吉"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109360086A",
    "apn": "CN201811142578.5",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "基于银行对账单数据自动补全账务数据的方法及设备",
    "inv": [
      "粱昌成",
      "连航",
      "杨惠端"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109389109A",
    "apn": "CN201811058433.7",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "一种OCR全文本识别正确率的自动化测试方法及设备",
    "inv": [
      "周文贵",
      "黄文英",
      "郭国强",
      "卢敏"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN109408807A",
    "apn": "CN201811057758.3",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "OCR识别正确率的自动化测试方法及测试设备",
    "inv": [
      "周文贵",
      "黄文英",
      "郭国强",
      "卢敏"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN107437085A",
    "apn": "CN201710674304.X",
    "apd": "2017-08-09",
    "apy": "2017",
    "tit": "一种提升OCR识别率的方法、装置及可读存储介质",
    "inv": [
      "吴建杭",
      "陈文传",
      "杨思超"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN107301414A",
    "apn": "CN201710484646.5",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "一种自然场景图像中的中文定位、分割和识别方法",
    "inv": [
      "陈凯",
      "韦建",
      "何建华",
      "周异",
      "黄征",
      "杜保发",
      "周文贵",
      "查宏远"
    ],
    "app": [
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN104182750B",
    "apn": "CN201410334436.4",
    "apd": "2014-07-14",
    "apy": "2014",
    "tit": "一种在自然场景图像中基于极值连通域的中文检测方法",
    "inv": [
      "陈凯",
      "周异",
      "周曲",
      "任逍航"
    ],
    "app": [
      "上海深杳智能科技有限公司",
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "商集网络",
    "pun": "CN103093228B",
    "apn": "CN201310017804.8",
    "apd": "2013-01-17",
    "apy": "2013",
    "tit": "一种在自然场景图像中基于连通域的中文检测方法",
    "inv": [
      "周异",
      "陈凯",
      "徐奕",
      "徐勇",
      "陈庆明"
    ],
    "app": [
      "上海深杳智能科技有限公司",
      "厦门商集网络科技有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110324794A",
    "apn": "CN201910540254.5",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "失物招领的方法、装置、服务器及可读存储介质",
    "inv": [
      "陈威"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110363097A",
    "apn": "CN201910540956.3",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "基于财务报表的企业画像生成方法和装置",
    "inv": [
      "王盼"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110348804A",
    "apn": "CN201910530985.1",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "基于AI的订单录入方法、装置、计算机设备及存储介质",
    "inv": [
      "蔡东艳"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "账单/订单",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110363667A",
    "apn": "CN201910530984.7",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "基于AI的订单融资处理方法、装置、计算机设备及存储介质",
    "inv": [
      "蔡东艳"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "账单/订单",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110363195A",
    "apn": "CN201910525845.5",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "图形验证码识别方法、装置、可读存储介质及终端设备",
    "inv": [
      "刘丽珍"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110363194A",
    "apn": "CN201910522451.4",
    "apd": "2019-06-17",
    "apy": "2019",
    "tit": "基于NLP的智能阅卷方法、装置、设备及存储介质",
    "inv": [
      "夏新"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110334702A",
    "apn": "CN201910463920.X",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "基于配置平台的数据传输方法、装置和计算机设备",
    "inv": [
      "唐奥强"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110351332A",
    "apn": "CN201910463109.1",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "动态调整时间阈值的方法、装置、计算机设备和存储介质",
    "inv": [
      "唐奥强"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110334586A",
    "apn": "CN201910429460.9",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "一种汽车识别方法、装置、计算机系统及可读存储介质",
    "inv": [
      "李国安"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110222692A",
    "apn": "CN201910423149.3",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "一种合同校验方法及相关设备",
    "inv": [
      "梅锦振华"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110222193A",
    "apn": "CN201910423062.6",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "扫描文字修正方法、装置、计算机设备和存储介质",
    "inv": [
      "许剑勇"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110232328A",
    "apn": "CN201910428124.2",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "一种征信报告解析方法、装置及计算机可读存储介质",
    "inv": [
      "吴汉宏",
      "侯凯"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110245576A",
    "apn": "CN201910426697.1",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "OCR识别准确率的检测方法、装置、设备及存储介质",
    "inv": [
      "谭莉"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110245571A",
    "apn": "CN201910418711.3",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "合同签字审核方法、装置、计算机设备和存储介质",
    "inv": [
      "范彬彬"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110264288A",
    "apn": "CN201910420380.7",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "基于信息识别技术的数据处理方法及相关装置",
    "inv": [
      "张美苑"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110135429A",
    "apn": "CN201910312227.2",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "扫描文字分段方法、装置、计算机设备和存储介质",
    "inv": [
      "许剑勇"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110135264A",
    "apn": "CN201910303918.6",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "数据录入方法、装置、计算机设备以及存储介质",
    "inv": [
      "张杰"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110175899A",
    "apn": "CN201910300303.8",
    "apd": "2019-04-15",
    "apy": "2019",
    "tit": "一种查验发票的方法、装置、计算机设备及可读存储介质",
    "inv": [
      "夏良超"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110119991A",
    "apn": "CN201910294783.1",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "基于机器学习的医疗赔付审核方法、装置及存储介质",
    "inv": [
      "贺健"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110134973A",
    "apn": "CN201910295229.5",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "基于人工智能的视频字幕实时翻译方法、介质和设备",
    "inv": [
      "张誉怀"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110147981A",
    "apn": "CN201910293568.X",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "基于文本分析的合同风险审核方法、装置及终端设备",
    "inv": [
      "夏新"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110032658A",
    "apn": "CN201910208496.4",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "基于图像分析的文本匹配方法、装置、设备及存储介质",
    "inv": [
      "成明"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "聊天/社交",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109977936A",
    "apn": "CN201910199017.7",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "纸质调查问卷统计方法、装置、介质和计算机设备",
    "inv": [
      "张誉怀"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110060611A",
    "apn": "CN201910198349.3",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "智能互动导游方法、装置及存储介质、服务器",
    "inv": [
      "张誉怀"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "特定场景",
    "l2": "景点识别",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110059559A",
    "apn": "CN201910198318.8",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "OCR识别文件的处理方法及其电子设备",
    "inv": [
      "刘丽珍",
      "吕小立"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN110070081A",
    "apn": "CN201910189500.7",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "自动信息录入方法、装置、存储介质及电子设备",
    "inv": [
      "张仁娟"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109840699A",
    "apn": "CN201910061858.1",
    "apd": "2019-01-23",
    "apy": "2019",
    "tit": "合格投资者线上评估方法、装置、计算机设备和存储介质",
    "inv": [
      "程勇",
      "刘继武",
      "常明华"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109934213A",
    "apn": "CN201910061799.8",
    "apd": "2019-01-23",
    "apy": "2019",
    "tit": "票据影像识别方法、装置、计算机设备和存储介质",
    "inv": [
      "郭锦宏"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109858584A",
    "apn": "CN201910048304.8",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "基于人工智能的辅助执法方法、装置、设备及存储介质",
    "inv": [
      "莫丽果"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109829692A",
    "apn": "CN201910043290.0",
    "apd": "2019-01-17",
    "apy": "2019",
    "tit": "基于人工智能的合同审理方法、装置、设备及存储介质",
    "inv": [
      "李浩"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109697665A",
    "apn": "CN201811537292.7",
    "apd": "2018-12-15",
    "apy": "2018",
    "tit": "基于人工智能的贷款审核方法、装置、设备及介质",
    "inv": [
      "付美蓉"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109767238A",
    "apn": "CN201811537629.4",
    "apd": "2018-12-15",
    "apy": "2018",
    "tit": "基于图像识别的产品甄别方法、装置、设备及存储介质",
    "inv": [
      "谭莉"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109753965A",
    "apn": "CN201811535209.2",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "一种基于深度学习的凭证识别方法和装置、计算机设备",
    "inv": [
      "刘劲柏",
      "徐佳良"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109784833A",
    "apn": "CN201811530368.3",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "一种收入报表的生成方法及设备",
    "inv": [
      "娄宇"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109800639A",
    "apn": "CN201811535346.6",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "基于大数据的保险审核方法及服务器",
    "inv": [
      "张誉怀"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109815976A",
    "apn": "CN201811536330.7",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "一种证件信息识别方法、装置及设备",
    "inv": [
      "郑如刚"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109815199A",
    "apn": "CN201811534446.7",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "一种图片压缩算法的选取方法及装置",
    "inv": [
      "张起"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109831572A",
    "apn": "CN201811533874.8",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "聊天图片控制方法、装置、计算机设备及存储介质",
    "inv": [
      "胡大兵"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "聊天/社交",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109740417A",
    "apn": "CN201811389293.1",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "发票类型识别方法、装置、存储介质和计算机设备",
    "inv": [
      "刘劲柏",
      "徐佳良"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109740040A",
    "apn": "CN201811389036.8",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "验证码识别方法、装置、存储介质和计算机设备",
    "inv": [
      "徐佳良",
      "刘劲柏"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109635628A",
    "apn": "CN201811237144.3",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "身份证信息验证方法、装置、设备及计算机可读存储介质",
    "inv": [
      "唐晟"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109637000A",
    "apn": "CN201811236992.2",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "发票检验方法及装置、存储介质、电子终端",
    "inv": [
      "龚春燕",
      "程学峰"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109492644A",
    "apn": "CN201811203355.5",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种习题图像的匹配识别方法及终端设备",
    "inv": [
      "王树军"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109635807A",
    "apn": "CN201811207882.3",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "信息录入方法、装置、设备及计算机可读存储介质",
    "inv": [
      "吴静平"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109462689A",
    "apn": "CN201811163455.X",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "语音播报方法及装置、电子装置及计算机可读存储介质",
    "inv": [
      "高立志"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "特定场景",
    "l2": "截屏识文",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109543507A",
    "apn": "CN201811151546.1",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "身份认证方法、装置、终端设备及存储介质",
    "inv": [
      "张振兴"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109446905A",
    "apn": "CN201811118119.3",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "电子签名审核方法、装置、计算机设备和存储介质",
    "inv": [
      "臧磊",
      "傅婧",
      "郭鹏程"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109670497A",
    "apn": "CN201811123300.3",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "图片上传方法、装置、设备及可读存储介质",
    "inv": [
      "叶培青",
      "史光华"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "WO2019153739A1",
    "apn": "PCT/CN2018/105173",
    "apd": "2018-09-12",
    "apy": "2018",
    "tit": "基于人脸识别的身份认证方法、装置、设备和存储介质",
    "inv": [
      "瞿康敏",
      "洪光宝"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109284693A",
    "apn": "CN201811012065.2",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "基于字迹关键点的金融行为预测方法、装置、电子设备",
    "inv": [
      "解笑",
      "徐国强",
      "邱寒"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109726388A",
    "apn": "CN201810429650.6",
    "apd": "2018-05-07",
    "apy": "2018",
    "tit": "PDF文件解析方法、装置、设备及计算机可读存储介质",
    "inv": [
      "夏良超",
      "王盼"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109410026A",
    "apn": "CN201810131476.7",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "基于人脸识别的身份认证方法、装置、设备和存储介质",
    "inv": [
      "瞿康敏",
      "洪光宝"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN109409349A",
    "apn": "CN201810105007.8",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "信贷证件鉴别方法、装置、终端及计算机可读存储介质",
    "inv": [
      "臧磊",
      "傅婧",
      "郭鹏程"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "壹账通",
    "pun": "CN107835173A",
    "apn": "CN201711085997.5",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "一种远程业务办理方法、存储介质和服务器",
    "inv": [
      "陈建明"
    ],
    "app": [
      "深圳壹账通智能科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107645522A",
    "apn": "CN201610579280.5",
    "apd": "2016-07-21",
    "apy": "2016",
    "tit": "信息录入的方法、服务器及录单终端",
    "inv": [
      "张习强"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108228320A",
    "apn": "CN201611154450.1",
    "apd": "2016-12-14",
    "apy": "2016",
    "tit": "任务分配的方法和装置",
    "inv": [
      "祁家庆",
      "喻红",
      "杨将",
      "王建波"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN106980605B",
    "apn": "CN201710087566.6",
    "apd": "2017-02-17",
    "apy": "2017",
    "tit": "合同生成方法和装置",
    "inv": [
      "陈林",
      "张国辉"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107689006A",
    "apn": "CN201710149822.X",
    "apd": "2017-03-13",
    "apy": "2017",
    "tit": "理赔账单识别方法和装置",
    "inv": [
      "蔡宁",
      "米艺",
      "方晓",
      "仇一",
      "薛燕"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107783950A",
    "apn": "CN201710233864.1",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "药品说明书处理方法及装置",
    "inv": [
      "夏雷"
    ],
    "app": [
      "平安医疗健康管理股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107220648B",
    "apn": "CN201710233613.3",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "理赔单据的字符识别方法及服务器",
    "inv": [
      "金飞虎",
      "薛燕",
      "米艺",
      "李欢欢",
      "仇一"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107688805A",
    "apn": "CN201710614422.1",
    "apd": "2017-07-25",
    "apy": "2017",
    "tit": "根据影像文件在录单模板中定位的方法、装置及相关设备",
    "inv": [
      "金飞虎",
      "邵正铂",
      "陈杰",
      "高雪",
      "马向东",
      "丁杰",
      "张捷"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107688789A",
    "apn": "CN201710776354.9",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "文档图表抽取方法、电子设备及计算机可读存储介质",
    "inv": [
      "王鸿滨",
      "王晓伟",
      "汪伟",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107689070A",
    "apn": "CN201710775380.X",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "图表数据结构化提取方法、电子设备及计算机可读存储介质",
    "inv": [
      "王鸿滨",
      "汪伟",
      "王晓伟",
      "王智",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107688788A",
    "apn": "CN201710776352.X",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "文档图表抽取方法、电子设备及计算机可读存储介质",
    "inv": [
      "王鸿滨",
      "王晓伟",
      "汪伟",
      "苏晓明",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107622266B",
    "apn": "CN201710858350.5",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "一种OCR识别的处理方法、存储介质和服务器",
    "inv": [
      "杨晓刚"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107783953A",
    "apn": "CN201710865143.2",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "信息录入方法及终端设备",
    "inv": [
      "张远",
      "杨宇奇"
    ],
    "app": [
      "平安普惠企业管理有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107798070A",
    "apn": "CN201710884932.0",
    "apd": "2017-09-26",
    "apy": "2017",
    "tit": "一种网页数据获取方法及终端设备",
    "inv": [
      "徐成"
    ],
    "app": [
      "平安普惠企业管理有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107766809A",
    "apn": "CN201710929629.8",
    "apd": "2017-10-09",
    "apy": "2017",
    "tit": "电子装置、票据信息识别方法和计算机可读存储介质",
    "inv": [
      "田野",
      "刘鹏",
      "王健宗",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107808124B",
    "apn": "CN201710929637.2",
    "apd": "2017-10-09",
    "apy": "2017",
    "tit": "电子装置、医疗文本实体命名的识别方法及存储介质",
    "inv": [
      "赵清源",
      "吕梓燊",
      "韦邕",
      "徐亮",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107798299A",
    "apn": "CN201710930679.8",
    "apd": "2017-10-09",
    "apy": "2017",
    "tit": "票据信息识别方法、电子装置及可读存储介质",
    "inv": [
      "王健宗",
      "韩茂琨",
      "刘鹏",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107832676A",
    "apn": "CN201710975407.X",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "表格信息换行识别方法、电子设备及计算机可读存储介质",
    "inv": [
      "王鸿滨",
      "王晓伟",
      "汪伟",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107818075A",
    "apn": "CN201710962303.5",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "表格信息结构化提取方法、电子设备及计算机可读存储介质",
    "inv": [
      "苏晓明",
      "汪伟",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107844468A",
    "apn": "CN201710959704.5",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "表格信息跨页识别方法、电子设备及计算机可读存储介质",
    "inv": [
      "苏晓明",
      "罗傲雪",
      "汪伟",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107909340A",
    "apn": "CN201711087760.0",
    "apd": "2017-11-08",
    "apy": "2017",
    "tit": "简历筛选方法、电子装置及可读存储介质",
    "inv": [
      "吴雅诗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN107862303B",
    "apn": "CN201711240787.9",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "表格类图像的信息识别方法、电子装置及可读存储介质",
    "inv": [
      "王健宗",
      "王威",
      "刘鹏",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108346106A",
    "apn": "CN201810155306.2",
    "apd": "2018-02-23",
    "apy": "2018",
    "tit": "账单录入方法、系统、光学字符识别服务器和存储介质",
    "inv": [
      "李响"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "账单/订单",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108399161A",
    "apn": "CN201810183371.6",
    "apd": "2018-03-06",
    "apy": "2018",
    "tit": "广告图片鉴定方法、电子装置及可读存储介质",
    "inv": [
      "宋杰",
      "郑佳",
      "赵骏"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108491870A",
    "apn": "CN201810208740.2",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "电子装置、物理印章识别方法和计算机可读存储介质",
    "inv": [
      "孟君",
      "曲风龙",
      "江琳",
      "邓佳鹏",
      "梁大祥"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108446621A",
    "apn": "CN201810208586.9",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "票据识别方法、服务器及计算机可读存储介质",
    "inv": [
      "田野",
      "刘鹏",
      "王健宗",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108830133A",
    "apn": "CN201810343634.5",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "合同影像图片的识别方法、电子装置及可读存储介质",
    "inv": [
      "郑佳"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108664897A",
    "apn": "CN201810351126.1",
    "apd": "2018-04-18",
    "apy": "2018",
    "tit": "票据识别方法、装置及存储介质",
    "inv": [
      "李佳琳",
      "刘鹏",
      "赵怡",
      "王健宗",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108573473A",
    "apn": "CN201810391961.8",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "图片旋转方法、装置、计算机设备及存储介质",
    "inv": [
      "刘真"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108764239A",
    "apn": "CN201810549699.5",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "发票验伪方法、装置、计算机设备及存储介质",
    "inv": [
      "潘庚生"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108960062A",
    "apn": "CN201810557203.9",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "校正发票图像的方法、装置、计算机设备和存储介质",
    "inv": [
      "王威",
      "王健宗",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109002756A",
    "apn": "CN201810564691.6",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写汉字图像识别方法、装置、计算机设备及存储介质",
    "inv": [
      "高梁梁",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108921031A",
    "apn": "CN201810564106.2",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "汉字模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "吴启",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108985151A",
    "apn": "CN201810564091.X",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "孙强",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109063720A",
    "apn": "CN201810564731.7",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写字训练样本获取方法、装置、计算机设备及存储介质",
    "inv": [
      "吴启",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108985324A",
    "apn": "CN201810564721.3",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写字训练样本获取方法、装置、设备及介质",
    "inv": [
      "高梁梁",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109063706A",
    "apn": "CN201810564723.2",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "文字模型训练方法、文字识别方法、装置、设备及介质",
    "inv": [
      "吴启",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108985442A",
    "apn": "CN201810564639.0",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "高梁梁",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108764195A",
    "apn": "CN201810564062.3",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "黄春岑",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109034280A",
    "apn": "CN201810563499.5",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "吴启",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109086653A",
    "apn": "CN201810564050.0",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "黄春岑",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109034279A",
    "apn": "CN201810563480.0",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "黄春岑",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108710866A",
    "apn": "CN201810563512.7",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "汉字模型训练方法、汉字识别方法、装置、设备及介质",
    "inv": [
      "吴启",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109002461A",
    "apn": "CN201810564059.1",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、文本识别方法、装置、设备及介质",
    "inv": [
      "孙强",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN108985297A",
    "apn": "CN201810564693.5",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练、手写图像识别方法、装置、设备及介质",
    "inv": [
      "高梁梁",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109086652A",
    "apn": "CN201810563511.2",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写字模型训练方法、汉字识别方法、装置、设备及介质",
    "inv": [
      "吴启",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109086651A",
    "apn": "CN201810563507.6",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、手写字识别方法、装置、设备及介质",
    "inv": [
      "黄春岑",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109086654A",
    "apn": "CN201810564063.8",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "手写模型训练方法、文本识别方法、装置、设备及介质",
    "inv": [
      "孙强",
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109035041A",
    "apn": "CN201810876532.X",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "电子装置、车险智能理赔方法及存储介质",
    "inv": [
      "张发友",
      "陈宗阳"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109255300A",
    "apn": "CN201810921331.7",
    "apd": "2018-08-14",
    "apy": "2018",
    "tit": "票据信息提取方法、装置、计算机设备及存储介质",
    "inv": [
      "邢欣来"
    ],
    "app": [
      "中国平安财产保险股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109255627A",
    "apn": "CN201810952665.0",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "房产信息校验方法、终端设备及计算机可读存储介质",
    "inv": [
      "杜立",
      "黄俊凯",
      "黄晓霞"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109344831A",
    "apn": "CN201810963099.3",
    "apd": "2018-08-22",
    "apy": "2018",
    "tit": "一种数据表识别方法、装置及终端设备",
    "inv": [
      "李亚宁"
    ],
    "app": [
      "中国平安人寿保险股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109241904A",
    "apn": "CN201811008806.X",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "文字识别模型训练、文字识别方法、装置、设备及介质",
    "inv": [
      "黄春岑"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109447074A",
    "apn": "CN201811020612.1",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "一种车牌识别方法及终端设备",
    "inv": [
      "孙健"
    ],
    "app": [
      "中国平安人寿保险股份有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109522898A",
    "apn": "CN201811084479.6",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "手写样本图片标注方法、装置、计算机设备及存储介质",
    "inv": [
      "金晨",
      "刘克亮"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109492657A",
    "apn": "CN201811085666.6",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "手写样本数字化方法、装置、计算机设备及存储介质",
    "inv": [
      "金晨",
      "刘克亮"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109493400A",
    "apn": "CN201811084481.3",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "手写样本生成方法、装置、计算机设备及存储介质",
    "inv": [
      "金晨",
      "刘克亮"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109522975A",
    "apn": "CN201811084484.7",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "手写样本生成方法、装置、计算机设备及存储介质",
    "inv": [
      "金晨",
      "刘克亮"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109446900A",
    "apn": "CN201811106159.6",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "证件真伪验证方法、装置、计算机设备及存储介质",
    "inv": [
      "黄锦伦"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109492143A",
    "apn": "CN201811106590.0",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "图像数据处理方法、装置、计算机设备及存储介质",
    "inv": [
      "黄锦伦"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109447117A",
    "apn": "CN201811119253.5",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "双层车牌识别方法、装置、计算机设备及存储介质",
    "inv": [
      "雷晨雨"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109492642A",
    "apn": "CN201811113599.4",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "车牌识别方法、装置、计算机设备及存储介质",
    "inv": [
      "雷晨雨"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109472262A",
    "apn": "CN201811113577.8",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "车牌识别方法、装置、计算机设备及存储介质",
    "inv": [
      "雷晨雨"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109388935A",
    "apn": "CN201811151533.4",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "单证验证方法及装置、电子设备及可读存储介质",
    "inv": [
      "陈琳"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109492643A",
    "apn": "CN201811182144.8",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "基于OCR的证件识别方法、装置、计算机设备及存储介质",
    "inv": [
      "黄泽浩"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109492533A",
    "apn": "CN201811185762.8",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "利用图像识别技术验证学历的方法、装置和计算机设备",
    "inv": [
      "李毅"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109635627A",
    "apn": "CN201811236301.9",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "图片信息提取方法、装置、计算机设备及存储介质",
    "inv": [
      "邢欣来"
    ],
    "app": [
      "中国平安财产保险股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109657738A",
    "apn": "CN201811254944.6",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "字符识别方法、装置、设备及存储介质",
    "inv": [
      "周罡",
      "王彬"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109635633A",
    "apn": "CN201811260786.5",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "电子装置、票据识别方法及存储介质",
    "inv": [
      "郑佳"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109635818A",
    "apn": "CN201811256366.X",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "印章防伪检验方法、装置及计算机可读存储介质",
    "inv": [
      "孟君",
      "江琳",
      "何英"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109492559A",
    "apn": "CN201811265630.6",
    "apd": "2018-10-27",
    "apy": "2018",
    "tit": "基于图像识别的资格审查方法、服务器及存储介质",
    "inv": [
      "袁刚"
    ],
    "app": [
      "平安医疗健康管理股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109685059A",
    "apn": "CN201811309895.1",
    "apd": "2018-11-06",
    "apy": "2018",
    "tit": "文字图像标注方法、装置及计算机可读存储介质",
    "inv": [
      "肖玉宾",
      "喻红"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109685050A",
    "apn": "CN201811339691.2",
    "apd": "2018-11-12",
    "apy": "2018",
    "tit": "文字识别方法、装置、设备及存储介质",
    "inv": [
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109685100A",
    "apn": "CN201811341729.X",
    "apd": "2018-11-12",
    "apy": "2018",
    "tit": "字符识别方法、服务器及计算机可读存储介质",
    "inv": [
      "许洋",
      "王健宗",
      "肖京"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109711396A",
    "apn": "CN201811342303.6",
    "apd": "2018-11-12",
    "apy": "2018",
    "tit": "OCR训练样本的生成方法、装置、设备及可读存储介质",
    "inv": [
      "高梁梁"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109726712A",
    "apn": "CN201811347763.8",
    "apd": "2018-11-13",
    "apy": "2018",
    "tit": "文字识别方法、装置及存储介质、服务器",
    "inv": [
      "黄锦伦"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109583445A",
    "apn": "CN201811416986.5",
    "apd": "2018-11-26",
    "apy": "2018",
    "tit": "文字图像校正处理方法、装置、设备及存储介质",
    "inv": [
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109670500A",
    "apn": "CN201811451778.9",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "一种文字区域获取方法、装置、存储介质及终端设备",
    "inv": [
      "黄泽浩",
      "王满"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109800784A",
    "apn": "CN201811512895.1",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "基于神经网络的合同核对方法及装置",
    "inv": [
      "张瑞光",
      "冯心",
      "刘文慧",
      "刘胜强",
      "李玉莲",
      "张浩",
      "马蕊"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109508713A",
    "apn": "CN201811518644.4",
    "apd": "2018-12-12",
    "apy": "2018",
    "tit": "图片获取方法、装置、终端和存储介质",
    "inv": [
      "黄锦伦",
      "宋欢儿"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109840469A",
    "apn": "CN201811530442.1",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "基于图像识别的保单录入方法和相关装置",
    "inv": [
      "罗柏发"
    ],
    "app": [
      "中国平安财产保险股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109615536A",
    "apn": "CN201811524325.4",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "基于OCR技术的保险理赔方法、装置、设备及可读存储介质",
    "inv": [
      "李云峰"
    ],
    "app": [
      "平安医疗健康管理股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109783781A",
    "apn": "CN201811531347.3",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "基于图像识别的保单录入方法和相关装置",
    "inv": [
      "罗柏发"
    ],
    "app": [
      "中国平安财产保险股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109784339A",
    "apn": "CN201811527303.3",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "图片识别测试方法、装置、计算机设备及存储介质",
    "inv": [
      "胡小伟"
    ],
    "app": [
      "平安普惠企业管理有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109670788A",
    "apn": "CN201811529288.6",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "基于数据分析的医保审核方法、装置、设备和存储介质",
    "inv": [
      "李云峰"
    ],
    "app": [
      "平安医疗健康管理股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109815792A",
    "apn": "CN201811527289.7",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "图片文件识别方法、装置、计算机设备及存储介质",
    "inv": [
      "陈文军"
    ],
    "app": [
      "平安普惠企业管理有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109800747A",
    "apn": "CN201811532906.2",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "医疗发票识别方法、用户设备、存储介质及装置",
    "inv": [
      "付舒婷"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109740135A",
    "apn": "CN201811554839.4",
    "apd": "2018-12-19",
    "apy": "2018",
    "tit": "图表生成方法及装置、电子设备和存储介质",
    "inv": [
      "孙志平"
    ],
    "app": [
      "平安普惠企业管理有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109766778A",
    "apn": "CN201811555100.5",
    "apd": "2018-12-19",
    "apy": "2018",
    "tit": "基于OCR技术的发票信息录入方法、装置、设备及存储介质",
    "inv": [
      "董亚荣",
      "陈方园"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109710907A",
    "apn": "CN201811561250.7",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "一种电子文档的生成方法及设备",
    "inv": [
      "黄泽浩",
      "宋欢儿"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109766898A",
    "apn": "CN201811599852.1",
    "apd": "2018-12-26",
    "apy": "2018",
    "tit": "图像文字识别方法、装置、计算机设备及存储介质",
    "inv": [
      "周罡",
      "梅雨"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109726710A",
    "apn": "CN201811611674.X",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "发票信息采集方法、电子装置及可读存储介质",
    "inv": [
      "董小磊"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109800320A",
    "apn": "CN201910015231.2",
    "apd": "2019-01-04",
    "apy": "2019",
    "tit": "一种图像处理方法、设备及计算机可读存储介质",
    "inv": [
      "许洋",
      "刘鹏",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109840524A",
    "apn": "CN201910008330.8",
    "apd": "2019-01-04",
    "apy": "2019",
    "tit": "文字的类型识别方法、装置、设备及存储介质",
    "inv": [
      "吴启"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109829457A",
    "apn": "CN201910015284.4",
    "apd": "2019-01-04",
    "apy": "2019",
    "tit": "一种图像数据处理方法、设备及计算机可读存储介质",
    "inv": [
      "许洋",
      "刘鹏",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109858475A",
    "apn": "CN201910014273.4",
    "apd": "2019-01-08",
    "apy": "2019",
    "tit": "图片文字定位方法、装置、介质和计算机设备",
    "inv": [
      "黄泽浩",
      "王满"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109871521A",
    "apn": "CN201910017061.1",
    "apd": "2019-01-08",
    "apy": "2019",
    "tit": "一种电子文档的生成方法及设备",
    "inv": [
      "黄泽浩",
      "宋欢儿"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109871845A",
    "apn": "CN201910023382.2",
    "apd": "2019-01-10",
    "apy": "2019",
    "tit": "证件图像提取方法及终端设备",
    "inv": [
      "黄锦伦",
      "熊冬根"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109872784A",
    "apn": "CN201910033406.2",
    "apd": "2019-01-14",
    "apy": "2019",
    "tit": "智能病历生成方法、装置、计算机设备以及存储介质",
    "inv": [
      "赵超",
      "贾玲玲"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109977750A",
    "apn": "CN201910035427.8",
    "apd": "2019-01-15",
    "apy": "2019",
    "tit": "印章真伪校验方法、装置及计算机可读存储介质",
    "inv": [
      "孟君",
      "江琳"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109920509A",
    "apn": "CN201910042928.9",
    "apd": "2019-01-17",
    "apy": "2019",
    "tit": "药品信息识别方法、装置、计算机设备和存储介质",
    "inv": [
      "赵超",
      "金志敏"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109919014A",
    "apn": "CN201910078744.8",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "OCR识别方法及其电子设备",
    "inv": [
      "许洋",
      "刘鹏",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109902724A",
    "apn": "CN201910100425.2",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "基于支持向量机的文字识别方法、装置和计算机设备",
    "inv": [
      "郑佳"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109948440A",
    "apn": "CN201910115443.8",
    "apd": "2019-02-13",
    "apy": "2019",
    "tit": "表格图像解析方法、装置、计算机设备及存储介质",
    "inv": [
      "刘克亮"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109961008A",
    "apn": "CN201910115364.7",
    "apd": "2019-02-13",
    "apy": "2019",
    "tit": "基于文字定位识别的表格解析方法、介质及计算机设备",
    "inv": [
      "周罡",
      "卢波"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110008944A",
    "apn": "CN201910127136.1",
    "apd": "2019-02-20",
    "apy": "2019",
    "tit": "基于模板匹配的OCR识别方法及装置、存储介质",
    "inv": [
      "高梁梁"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109993057A",
    "apn": "CN201910137315.3",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "语义识别方法、装置、设备及计算机可读存储介质",
    "inv": [
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN109977935A",
    "apn": "CN201910146626.6",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "一种文本识别方法及装置",
    "inv": [
      "许洋",
      "刘鹏",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110097329A",
    "apn": "CN201910205659.3",
    "apd": "2019-03-16",
    "apy": "2019",
    "tit": "信息审核方法、装置、设备及计算机可读存储介质",
    "inv": [
      "黄诗睿"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110060317A",
    "apn": "CN201910202282.6",
    "apd": "2019-03-16",
    "apy": "2019",
    "tit": "海报自动配置方法、设备、存储介质及装置",
    "inv": [
      "陈吉",
      "程早早",
      "姜雪莉",
      "万姿君",
      "秦萌",
      "卢芸",
      "杨言",
      "赵珮羽"
    ],
    "app": [
      "平安城市建设科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110059687A",
    "apn": "CN201910207427.1",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "图片信息识别方法、装置、计算机设备和存储介质",
    "inv": [
      "孙强",
      "陆凯杰"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110059688A",
    "apn": "CN201910207650.6",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "图片信息识别方法、装置、计算机设备和存储介质",
    "inv": [
      "孙强",
      "陆凯杰"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110163202A",
    "apn": "CN201910264868.5",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "文字区域的定位方法、装置、终端设备及介质",
    "inv": [
      "黄泽浩",
      "王满"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110119681A",
    "apn": "CN201910270014.8",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "一种文本行提取方法及装置、电子设备",
    "inv": [
      "韩茂琨",
      "回艳菲",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110163204A",
    "apn": "CN201910298474.1",
    "apd": "2019-04-15",
    "apy": "2019",
    "tit": "基于图像识别的商家监管方法、装置及存储介质",
    "inv": [
      "张菊梅",
      "王文广",
      "徐七平",
      "姜亮",
      "孙林峰"
    ],
    "app": [
      "平安国际智慧城市科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110197177A",
    "apn": "CN201910324978.6",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "提取视频字幕的方法、装置、计算机设备及存储介质",
    "inv": [
      "肖玉宾",
      "喻红"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110210299A",
    "apn": "CN201910342237.0",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "语音训练数据生成方法、装置、设备及可读存储介质",
    "inv": [
      "彭捷"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110210581A",
    "apn": "CN201910349751.7",
    "apd": "2019-04-28",
    "apy": "2019",
    "tit": "一种手写文本识别方法及装置、电子设备",
    "inv": [
      "韩茂琨",
      "回艳菲",
      "陈玥蓉",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110245557A",
    "apn": "CN201910374785.1",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "图片处理方法、装置、计算机设备及存储介质",
    "inv": [
      "高梁梁",
      "王彬"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110321788A",
    "apn": "CN201910415398.8",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "训练数据处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "周罡"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110245572A",
    "apn": "CN201910419206.0",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "区域内容识别方法、装置、计算机设备和存储介质",
    "inv": [
      "许剑勇"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110334585A",
    "apn": "CN201910427255.9",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "表格识别方法、装置、计算机设备和存储介质",
    "inv": [
      "高梁梁",
      "孙双双"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110321470A",
    "apn": "CN201910433867.9",
    "apd": "2019-05-23",
    "apy": "2019",
    "tit": "文档处理方法、装置、计算机设备和存储介质",
    "inv": [
      "高梁梁",
      "孙双双"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110334596A",
    "apn": "CN201910462355.5",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "发票图片汇总方法、电子装置及可读存储介质",
    "inv": [
      "林政飞",
      "孙猛"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110348294A",
    "apn": "CN201910462305.7",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "PDF文档中图表的定位方法、装置及计算机设备",
    "inv": [
      "刘克亮"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110263239A",
    "apn": "CN201910469612.8",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "一种发票识别的方法、装置、存储介质及计算机设备",
    "inv": [
      "周晓凤"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110390508A",
    "apn": "CN201910496887.0",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "基于OCR创建日程方法、装置及存储介质",
    "inv": [
      "钱根双"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "事件管理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110378217A",
    "apn": "CN201910510434.9",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "基于光学字符识别的申请验证方法、装置及电子设备",
    "inv": [
      "秦勇"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "事件管理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110363092A",
    "apn": "CN201910529585.9",
    "apd": "2019-06-17",
    "apy": "2019",
    "tit": "柱状图识别方法、装置、设备及计算机可读存储介质",
    "inv": [
      "孙强"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110443269A",
    "apn": "CN201910520715.2",
    "apd": "2019-06-17",
    "apy": "2019",
    "tit": "一种文档比对方法及装置",
    "inv": [
      "赵逸湘"
    ],
    "app": [
      "平安信托有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110443270A",
    "apn": "CN201910527236.3",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "图表定位方法、装置、计算机设备及计算机可读存储介质",
    "inv": [
      "吴启",
      "卢波"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110348436A",
    "apn": "CN201910529202.8",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "对图像中的文本信息进行识别的方法及相关设备",
    "inv": [
      "张克明"
    ],
    "app": [
      "平安普惠企业管理有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110378248A",
    "apn": "CN201910566118.3",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "饼状图识别方法、装置、设备及计算机可读存储介质",
    "inv": [
      "周罡",
      "王彬"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110414518A",
    "apn": "CN201910561370.5",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "网址识别方法、装置、计算机设备和存储介质",
    "inv": [
      "王建华",
      "何四燕",
      "金志敏"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110458184A",
    "apn": "CN201910558319.9",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "光学字符识别辅助方法、装置、计算机设备及存储介质",
    "inv": [
      "郭俊雄",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110390269A",
    "apn": "CN201910560432.0",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "PDF文档表格提取方法、装置、设备及计算机可读存储介质",
    "inv": [
      "刘克亮",
      "卢波"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110414520A",
    "apn": "CN201910574434.5",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "通用字符识别方法、装置、计算机设备和存储介质",
    "inv": [
      "王健宗",
      "闫旭",
      "王威",
      "韩茂琨"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110443239A",
    "apn": "CN201910572252.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "文字图像的识别方法及其装置",
    "inv": [
      "王健宗",
      "闫旭",
      "王威",
      "韩茂琨"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110503089A",
    "apn": "CN201910593212.8",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "基于众包技术的OCR识别模型训练方法、装置以及计算机设备",
    "inv": [
      "许洋",
      "刘鹏",
      "王健宗"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110443140A",
    "apn": "CN201910606491.7",
    "apd": "2019-07-05",
    "apy": "2019",
    "tit": "文本定位的方法、装置、计算机设备及存储介质",
    "inv": [
      "苏智辉",
      "孙强"
    ],
    "app": [
      "平安科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "平安",
    "pun": "CN110516663A",
    "apn": "CN201910634078.1",
    "apd": "2019-07-15",
    "apy": "2019",
    "tit": "OCR识别准确率的测试方法、装置、计算机设备及存储介质",
    "inv": [
      "李丽珊"
    ],
    "app": [
      "平安普惠企业管理有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN109766881A",
    "apn": "CN201811436591.1",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "一种竖向文本图像的字符识别方法和装置",
    "inv": [
      "李健",
      "高大帅",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN109147002A",
    "apn": "CN201810678966.9",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "李健",
      "高大帅",
      "伍更新"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "图像预处理",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN109034159A",
    "apn": "CN201810523909.3",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "图像信息提取方法和装置",
    "inv": [
      "伍更新",
      "高大帅",
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "图像切分",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN108664973A",
    "apn": "CN201810266320.X",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "文本处理方法和装置",
    "inv": [
      "伍更新",
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN108427950A",
    "apn": "CN201810102229.4",
    "apd": "2018-02-01",
    "apy": "2018",
    "tit": "一种文字行检测方法及装置",
    "inv": [
      "高大帅",
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN107220639A",
    "apn": "CN201710245168.2",
    "apd": "2017-04-14",
    "apy": "2017",
    "tit": "OCR识别结果的纠正方法和装置",
    "inv": [
      "李健",
      "徐亮",
      "伍更新",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN107169485A",
    "apn": "CN201710194222.5",
    "apd": "2017-03-28",
    "apy": "2017",
    "tit": "一种数学公式识别方法和装置",
    "inv": [
      "曹骥",
      "王富田",
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "图像预处理",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN106960206A",
    "apn": "CN201710069428.5",
    "apd": "2017-02-08",
    "apy": "2017",
    "tit": "字符识别方法和字符识别系统",
    "inv": [
      "曹骥",
      "李健",
      "王富田",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN104361312B",
    "apn": "CN201410549721.8",
    "apd": "2014-10-16",
    "apy": "2014",
    "tit": "一种对图像进行字符识别的方法和装置",
    "inv": [
      "李健",
      "兰志才",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN104239853B",
    "apn": "CN201410429608.6",
    "apd": "2014-08-27",
    "apy": "2014",
    "tit": "一种图像的处理方法和装置",
    "inv": [
      "李健",
      "兰志才",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN104239909B",
    "apn": "CN201410392614.9",
    "apd": "2014-08-11",
    "apy": "2014",
    "tit": "一种图像的识别方法和装置",
    "inv": [
      "李健",
      "兰志才",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN104063705B",
    "apn": "CN201410247878.5",
    "apd": "2014-06-05",
    "apy": "2014",
    "tit": "一种笔迹特征提取的方法和装置",
    "inv": [
      "曹骥",
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN102750552B",
    "apn": "CN201210205916.1",
    "apd": "2012-06-18",
    "apy": "2012",
    "tit": "一种手写识别方法、系统及手写识别终端",
    "inv": [
      "李健",
      "郑晓明",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN102508598B",
    "apn": "CN201110303577.6",
    "apd": "2011-10-09",
    "apy": "2011",
    "tit": "一种字符笔画渐变消隐方法及装置",
    "inv": [
      "李健",
      "李晨曦",
      "闫锡刚",
      "郑晓明",
      "刘畅",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN102243708B",
    "apn": "CN201110180053.2",
    "apd": "2011-06-29",
    "apy": "2011",
    "tit": "一种手写识别方法、系统及手写识别终端",
    "inv": [
      "郑晓明",
      "李健"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN101901348B",
    "apn": "CN201010221884.5",
    "apd": "2010-06-29",
    "apy": "2010",
    "tit": "一种基于归一化的手写识别方法和识别装置",
    "inv": [
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN101893960B",
    "apn": "CN201010221048.7",
    "apd": "2010-06-28",
    "apy": "2010",
    "tit": "一种基于方向向量的文字识别方法和识别装置",
    "inv": [
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "捷通华声",
    "pun": "CN101901075B",
    "apn": "CN201010219460.5",
    "apd": "2010-06-25",
    "apy": "2010",
    "tit": "一种点密度非线性归一化文字识别方法和装置",
    "inv": [
      "李健",
      "张连毅",
      "武卫东"
    ],
    "app": [
      "北京捷通华声科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105518712A",
    "apn": "CN201580000345.X",
    "apd": "2015-05-28",
    "apy": "2015",
    "tit": "基于字符识别的关键词通知方法及设备、计算机程序产品",
    "inv": [
      "周舒畅",
      "周昕宇",
      "吴育昕",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "WO2016187888A1",
    "apn": "PCT/CN2015/080127",
    "apd": "2015-05-28",
    "apy": "2015",
    "tit": "基于字符识别的关键词通知方法及设备、计算机程序产品",
    "inv": [
      "周舒畅",
      "周昕宇",
      "吴育昕",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京小孔科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105518678B",
    "apn": "CN201580000313.X",
    "apd": "2015-06-29",
    "apy": "2015",
    "tit": "搜索方法、搜索装置和用户设备",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "吴育昕"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "WO2017000109A1",
    "apn": "PCT/CN2015/082628",
    "apd": "2015-06-29",
    "apy": "2015",
    "tit": "搜索方法、搜索装置、用户设备和计算机程序产品",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "吴育昕"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京小孔科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105096244B",
    "apn": "CN201510381884.4",
    "apd": "2015-07-02",
    "apy": "2015",
    "tit": "图像变换方法及其装置以及图像识别方法及其装置",
    "inv": [
      "周舒畅",
      "姚聪",
      "巩运青"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105096239B",
    "apn": "CN201510382287.3",
    "apd": "2015-07-02",
    "apy": "2015",
    "tit": "图像配准方法及其装置以及图像拼接方法及其装置",
    "inv": [
      "周舒畅",
      "姚聪",
      "朱睿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN104966109B",
    "apn": "CN201510425315.5",
    "apd": "2015-07-17",
    "apy": "2015",
    "tit": "医疗化验单图像分类方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "吴育昕"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105046289B",
    "apn": "CN201510484879.6",
    "apd": "2015-08-07",
    "apy": "2015",
    "tit": "一种文字域类型识别方法和文字域类型识别系统",
    "inv": [
      "朱睿",
      "张弛",
      "吴家楠",
      "周舒畅",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105139041A",
    "apn": "CN201510520119.6",
    "apd": "2015-08-21",
    "apy": "2015",
    "tit": "基于图像的语种识别方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "徐梓哲",
      "印奇"
    ],
    "app": [
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105117740A",
    "apn": "CN201510520116.2",
    "apd": "2015-08-21",
    "apy": "2015",
    "tit": "字体识别方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105574513B",
    "apn": "CN201510970839.2",
    "apd": "2015-12-22",
    "apy": "2015",
    "tit": "文字检测方法和装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105448103B",
    "apn": "CN201510984703.7",
    "apd": "2015-12-24",
    "apy": "2015",
    "tit": "车辆套牌检测方法与系统",
    "inv": [
      "茅佳源",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN105809164B",
    "apn": "CN201610140181.7",
    "apd": "2016-03-11",
    "apy": "2016",
    "tit": "文字识别方法和装置",
    "inv": [
      "张逸玮",
      "张弛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN106067019A",
    "apn": "CN201610366232.8",
    "apd": "2016-05-27",
    "apy": "2016",
    "tit": "针对图像进行文字识别的方法及装置",
    "inv": [
      "周舒畅",
      "姚聪",
      "温和",
      "何蔚然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN106156794A",
    "apn": "CN201610509781.6",
    "apd": "2016-07-01",
    "apy": "2016",
    "tit": "基于文字风格识别的文字识别方法及装置",
    "inv": [
      "马力克",
      "闫学灿",
      "周舒畅",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN106250755A",
    "apn": "CN201610608009.X",
    "apd": "2016-07-28",
    "apy": "2016",
    "tit": "用于生成验证码的方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "何蔚然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN106326888A",
    "apn": "CN201610675805.5",
    "apd": "2016-08-16",
    "apy": "2016",
    "tit": "图像识别方法和装置",
    "inv": [
      "周舒畅",
      "孙霏",
      "姚聪",
      "敖翔",
      "周昕宇",
      "何蔚然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN106778525A",
    "apn": "CN201611060622.9",
    "apd": "2016-11-25",
    "apy": "2016",
    "tit": "身份认证方法和装置",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108573203A",
    "apn": "CN201710218218.8",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "身份认证方法和装置及存储介质",
    "inv": [
      "范浩强",
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108629259A",
    "apn": "CN201710218219.2",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "身份认证方法和装置及存储介质",
    "inv": [
      "敖翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108573202A",
    "apn": "CN201710218217.3",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "身份认证方法、装置和系统以及终端、服务器和存储介质",
    "inv": [
      "敖翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108734052A",
    "apn": "CN201710241094.5",
    "apd": "2017-04-13",
    "apy": "2017",
    "tit": "文字检测方法、装置和系统",
    "inv": [
      "周昕宇",
      "姚聪",
      "周舒畅",
      "何蔚然"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108875929A",
    "apn": "CN201710347758.6",
    "apd": "2017-05-17",
    "apy": "2017",
    "tit": "字形编码生成方法及设备、字形编码、存储介质",
    "inv": [
      "何蔚然",
      "周舒畅",
      "周昕宇",
      "梁嘉骏"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN206892885U",
    "apn": "CN201720825938.6",
    "apd": "2017-07-10",
    "apy": "2017",
    "tit": "身份证读取装置",
    "inv": [
      "何国勋"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN207264409U",
    "apn": "CN201721339780.8",
    "apd": "2017-10-17",
    "apy": "2017",
    "tit": "人证核验装置",
    "inv": [
      "何国勋"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108875495A",
    "apn": "CN201710965338.4",
    "apd": "2017-10-17",
    "apy": "2017",
    "tit": "人证核验装置及人证核验方法",
    "inv": [
      "何国勋"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108875723A",
    "apn": "CN201810005939.5",
    "apd": "2018-01-03",
    "apy": "2018",
    "tit": "对象检测方法、装置和系统及存储介质",
    "inv": [
      "吕鹏原",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN108805131A",
    "apn": "CN201810499664.5",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "文本行检测方法、装置及系统",
    "inv": [
      "贺欣",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109033261A",
    "apn": "CN201810741616.2",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "图像处理方法、装置、处理设备及其存储介质",
    "inv": [
      "顾泽琪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109063702A",
    "apn": "CN201810899533.6",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "车牌识别方法、装置、设备及存储介质",
    "inv": [
      "邵帅",
      "史雨轩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109117846A",
    "apn": "CN201810960626.5",
    "apd": "2018-08-22",
    "apy": "2018",
    "tit": "一种图像处理方法、装置、电子设备和计算机可读介质",
    "inv": [
      "万昭祎",
      "张健",
      "廖明辉",
      "姚聪",
      "谢锋明",
      "梁嘉骏",
      "吕鹏原",
      "白翔"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109242796A",
    "apn": "CN201811037146.8",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "文字图像处理方法、装置、电子设备和计算机存储介质",
    "inv": [
      "黄哲威",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109284593A",
    "apn": "CN201811039736.4",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "身份验证方法、装置及电子设备",
    "inv": [
      "滕飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109271935A",
    "apn": "CN201811084413.7",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "物品与电子标签的配对方法、装置及系统",
    "inv": [
      "娄超",
      "吕亚骏",
      "魏秀参"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109492638A",
    "apn": "CN201811323981.8",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "文本检测方法、装置及电子设备",
    "inv": [
      "谢恩泽",
      "邵帅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109656563A",
    "apn": "CN201811436242.X",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "代码审查方法、装置、系统和存储介质",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "特定场景",
    "l2": "代码审核",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109886077A",
    "apn": "CN201811625431.1",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "图像识别方法、装置、计算机设备和存储介质",
    "inv": [
      "张健",
      "万昭祎",
      "贺欣"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109766885A",
    "apn": "CN201811647657.1",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种文字检测方法、装置、电子设备及存储介质",
    "inv": [
      "谢锋明",
      "万昭祎",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109829437A",
    "apn": "CN201910106716.2",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "图像处理方法、文本识别方法、装置和电子系统",
    "inv": [
      "贺欣",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN109710371A",
    "apn": "CN201910135199.1",
    "apd": "2019-02-20",
    "apy": "2019",
    "tit": "字体调节方法、装置及系统",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN110136225A",
    "apn": "CN201910252659.9",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "生成书面印刷数据的样本的方法、装置及计算机存储介质",
    "inv": [
      "张婕蕾",
      "曹志敏",
      "贺欣",
      "刘毅博",
      "谢锋明",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN110071833A",
    "apn": "CN201910322454.3",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "人证核验设备配置方法、系统、计算机设备和存储介质",
    "inv": [
      "何国勋",
      "吴清秀",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN110210480A",
    "apn": "CN201910488332.1",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "文字识别方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "万昭祎",
      "刘毅博",
      "谢锋明",
      "姚聪",
      "杨沐"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN110428504A",
    "apn": "CN201910627626.8",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "文本图像合成方法、装置、计算机设备和存储介质",
    "inv": [
      "廖明辉",
      "何明航",
      "龙上邦",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "旷视",
    "pun": "CN110427852A",
    "apn": "CN201910672543.0",
    "apd": "2019-07-24",
    "apy": "2019",
    "tit": "文字识别方法、装置、计算机设备和存储介质",
    "inv": [
      "龙上邦",
      "关玉烁",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN110298338A",
    "apn": "CN201910538341.7",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "一种文档图像分类方法及装置",
    "inv": [
      "朱军民",
      "王勇",
      "康铁钢"
    ],
    "app": [
      "北京易道博识科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN110245656A",
    "apn": "CN201910388914.2",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "一种票据运营管理方法及其系统",
    "inv": [
      "李春雷",
      "周文杰",
      "夏小彬"
    ],
    "app": [
      "上海果藤互联网金融信息服务有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN109948510A",
    "apn": "CN201910194679.5",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "一种文档图像实例分割方法及装置",
    "inv": [
      "朱军民",
      "王勇",
      "康铁刚"
    ],
    "app": [
      "北京易道博识科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN109886974A",
    "apn": "CN201910078831.3",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "一种印章去除方法",
    "inv": [
      "朱军民",
      "王勇",
      "康铁刚"
    ],
    "app": [
      "北京易道博识科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN109658334A",
    "apn": "CN201811550790.5",
    "apd": "2018-12-18",
    "apy": "2018",
    "tit": "一种古籍图像拼接方法及装置",
    "inv": [
      "朱军民",
      "康铁刚"
    ],
    "app": [
      "北京易道博识科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN108805715A",
    "apn": "CN201810591658.2",
    "apd": "2018-06-11",
    "apy": "2018",
    "tit": "一种票据运营风险管控方法及其系统",
    "inv": [
      "李春雷",
      "周文杰"
    ],
    "app": [
      "上海果藤互联网金融信息服务有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN108564064A",
    "apn": "CN201810402553.8",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "一种基于视觉高效OCR识别算法",
    "inv": [
      "欧阳德寿"
    ],
    "app": [
      "北京宙心科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN107403356A",
    "apn": "CN201710549277.3",
    "apd": "2017-07-07",
    "apy": "2017",
    "tit": "一种票据运营系统",
    "inv": [
      "李春雷",
      "周文杰"
    ],
    "app": [
      "上海果藤互联网金融信息服务有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "易道博识",
    "pun": "CN106295529A",
    "apn": "CN201610619935.7",
    "apd": "2016-08-01",
    "apy": "2016",
    "tit": "一种移动端的表格识别方法及系统",
    "inv": [
      "朱军民",
      "康铁刚"
    ],
    "app": [
      "北京易道博识科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN110032938A",
    "apn": "CN201910185902.X",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "一种藏文识别方法、装置及电子设备",
    "inv": [
      "尼玛扎西",
      "韦秋华",
      "刘正珍",
      "拥措",
      "洛桑嘎登"
    ],
    "app": [
      "北京汉王数字科技有限公司",
      "西藏大学"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN109858468A",
    "apn": "CN201910161725.1",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "一种表格线识别方法及装置",
    "inv": [
      "张娜",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN109919037A",
    "apn": "CN201910105748.0",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "一种文本定位方法及装置、文本识别方法及装置",
    "inv": [
      "刘正珍",
      "黄威"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN109977762A",
    "apn": "CN201910105737.2",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "一种文本定位方法及装置、文本识别方法及装置",
    "inv": [
      "刘正珍",
      "黄威"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN109657666A",
    "apn": "CN201811413393.3",
    "apd": "2018-11-23",
    "apy": "2018",
    "tit": "一种圆形印章识别方法及装置",
    "inv": [
      "王亮"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "其他",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN103699890B",
    "apn": "CN201210365732.1",
    "apd": "2012-09-27",
    "apy": "2012",
    "tit": "一种扫描输入装置及扫描输入方法",
    "inv": [
      "刘迎建",
      "刘正珍",
      "张娜",
      "李玉梅",
      "万鑫",
      "闫江震"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "WO2012163112A1",
    "apn": "PCT/CN2012/071518",
    "apd": "2012-02-23",
    "apy": "2012",
    "tit": "跳帧扫描识别装置和方法",
    "inv": [
      "LI, YONGBIN"
    ],
    "app": [
      "HANWANG TECHNOLOGY CO., LTD.",
      "LI, YONGBIN"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN103294665A",
    "apn": "CN201210042989.3",
    "apd": "2012-02-22",
    "apy": "2012",
    "tit": "电子阅读器文本翻译的方法及电子阅读器",
    "inv": [
      "丁丽"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN103257810B",
    "apn": "CN201210038052.9",
    "apd": "2012-02-17",
    "apy": "2012",
    "tit": "手写数学公式识别方法及装置",
    "inv": [
      "郭育生",
      "韦秋华",
      "毕芳",
      "邹明福"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102360427A",
    "apn": "CN201110294841.4",
    "apd": "2011-09-30",
    "apy": "2011",
    "tit": "可调节扫描模式的文字采集方法和扫描装置",
    "inv": [
      "信强",
      "梁义海",
      "郭峰"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN103019397B",
    "apn": "CN201110279794.6",
    "apd": "2011-09-20",
    "apy": "2011",
    "tit": "一种多语言混合的文本行输入方法及装置",
    "inv": [
      "陈旭",
      "郭育生",
      "毕芳",
      "邹明福"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102982326B",
    "apn": "CN201110258960.4",
    "apd": "2011-09-02",
    "apy": "2011",
    "tit": "文字处理方法、装置和电子翻译笔",
    "inv": [
      "刘昌平",
      "刘正珍",
      "仝慧媛"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102968407B",
    "apn": "CN201110256474.9",
    "apd": "2011-08-31",
    "apy": "2011",
    "tit": "双层PDF文件的构造方法及装置",
    "inv": [
      "王晓健"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102915440A",
    "apn": "CN201110221556.X",
    "apd": "2011-08-03",
    "apy": "2011",
    "tit": "一种字符切分的方法及装置",
    "inv": [
      "万鑫"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102831418B",
    "apn": "CN201110163213.2",
    "apd": "2011-06-17",
    "apy": "2011",
    "tit": "手写体汉字倾斜矫正方法及装置",
    "inv": [
      "邹明福"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102208039B",
    "apn": "CN201110147047.7",
    "apd": "2011-06-01",
    "apy": "2011",
    "tit": "一种多语言混合手写文本行的识别方法及装置",
    "inv": [
      "郭育生"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102324027B",
    "apn": "CN201110141320.5",
    "apd": "2011-05-27",
    "apy": "2011",
    "tit": "扫描识别装置和方法",
    "inv": [
      "李永彬"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102779276B",
    "apn": "CN201110117972.5",
    "apd": "2011-05-09",
    "apy": "2011",
    "tit": "文本图像识别方法和装置",
    "inv": [
      "刘正珍",
      "万鑫",
      "李玉梅"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102122347B",
    "apn": "CN201110080923.9",
    "apd": "2011-03-31",
    "apy": "2011",
    "tit": "判别图像中文本颜色极性的方法及装置",
    "inv": [
      "黄磊",
      "王修飞",
      "刘昌平"
    ],
    "app": [
      "北京汉王智远科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102592124B",
    "apn": "CN201110007011.9",
    "apd": "2011-01-13",
    "apy": "2011",
    "tit": "文本图像的几何校正方法、装置和双目立体视觉系统",
    "inv": [
      "林锦梅"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102043948A",
    "apn": "CN201010606223.4",
    "apd": "2010-12-24",
    "apy": "2010",
    "tit": "一种视频文本分割方法",
    "inv": [
      "黄磊",
      "王修飞"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102542279A",
    "apn": "CN201010605775.3",
    "apd": "2010-12-23",
    "apy": "2010",
    "tit": "维哈柯文文本图像的行提取方法及装置",
    "inv": [
      "李洪峰"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102063619B",
    "apn": "CN201010568411.2",
    "apd": "2010-11-30",
    "apy": "2010",
    "tit": "文字行提取方法和装置",
    "inv": [
      "李永彬"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102063621B",
    "apn": "CN201010568404.2",
    "apd": "2010-11-30",
    "apy": "2010",
    "tit": "文字行几何畸变校正方法和装置",
    "inv": [
      "李永彬"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102456138B",
    "apn": "CN201010530207.1",
    "apd": "2010-11-03",
    "apy": "2010",
    "tit": "印刷体阿拉伯字符预处理方法和装置",
    "inv": [
      "王琛",
      "刘正珍",
      "钮兴昱"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102446275B",
    "apn": "CN201010502010.7",
    "apd": "2010-09-30",
    "apy": "2010",
    "tit": "阿拉伯文字符的识别方法和装置",
    "inv": [
      "王琛",
      "刘正珍",
      "钮兴昱"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102446274B",
    "apn": "CN201010299203.7",
    "apd": "2010-09-30",
    "apy": "2010",
    "tit": "带有下划线的文本图像预处理方法和装置",
    "inv": [
      "万鑫",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102385700B",
    "apn": "CN201010268489.2",
    "apd": "2010-09-01",
    "apy": "2010",
    "tit": "脱机手写识别的方法及装置",
    "inv": [
      "王琛"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102375980B",
    "apn": "CN201010261137.4",
    "apd": "2010-08-24",
    "apy": "2010",
    "tit": "图像处理方法及装置",
    "inv": [
      "王晓健"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102314608A",
    "apn": "CN201010223258.X",
    "apd": "2010-06-30",
    "apy": "2010",
    "tit": "文字图像中行提取的方法和装置",
    "inv": [
      "万鑫",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102262614A",
    "apn": "CN201010195279.5",
    "apd": "2010-05-31",
    "apy": "2010",
    "tit": "纵向校对方法和装置",
    "inv": [
      "王琛",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102262619A",
    "apn": "CN201010195292.0",
    "apd": "2010-05-31",
    "apy": "2010",
    "tit": "文档的文字提取方法和装置",
    "inv": [
      "楼永植",
      "陈峻峰"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102236638B",
    "apn": "CN201010160729.7",
    "apd": "2010-04-26",
    "apy": "2010",
    "tit": "西文单词中字符大小写的修正方法和装置",
    "inv": [
      "万鑫",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102201051A",
    "apn": "CN201010133231.1",
    "apd": "2010-03-25",
    "apy": "2010",
    "tit": "文字摘录装置、方法和系统",
    "inv": [
      "刘昌平",
      "梁义海"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102169542B",
    "apn": "CN201010114226.6",
    "apd": "2010-02-25",
    "apy": "2010",
    "tit": "文字识别中粘连字符的切分方法和装置",
    "inv": [
      "王琛"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN102063611B",
    "apn": "CN201010034478.8",
    "apd": "2010-01-21",
    "apy": "2010",
    "tit": "一种文字输入方法和系统",
    "inv": [
      "刘迎建",
      "刘正珍",
      "李永彬",
      "宋柏君",
      "梁义海"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101901333B",
    "apn": "CN200910085536.7",
    "apd": "2009-05-25",
    "apy": "2009",
    "tit": "文本图像中切分词的方法及使用该方法的识别装置",
    "inv": [
      "王琛",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101882215B",
    "apn": "CN200910084862.6",
    "apd": "2009-05-25",
    "apy": "2009",
    "tit": "判断文本区域排版方向的方法",
    "inv": [
      "李永彬"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101833648B",
    "apn": "CN200910079865.0",
    "apd": "2009-03-13",
    "apy": "2009",
    "tit": "文本图像的校正方法",
    "inv": [
      "万鑫",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101807255B",
    "apn": "CN200910077745.7",
    "apd": "2009-02-16",
    "apy": "2009",
    "tit": "一种字母大小写判断方法",
    "inv": [
      "肖潇",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101782896B",
    "apn": "CN200910076809.1",
    "apd": "2009-01-21",
    "apy": "2009",
    "tit": "结合OCR技术的PDF文字提取方法",
    "inv": [
      "江世盛",
      "刘强"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101770575B",
    "apn": "CN200810246629.9",
    "apd": "2008-12-31",
    "apy": "2008",
    "tit": "名片图像倾斜角度的测量方法和装置",
    "inv": [
      "李永彬",
      "朱军民"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101770569A",
    "apn": "CN200810246630.1",
    "apd": "2008-12-31",
    "apy": "2008",
    "tit": "基于OCR的菜名识别方法",
    "inv": [
      "朱军民"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "特定场景",
    "l2": "菜谱识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101751568B",
    "apn": "CN200810239544.8",
    "apd": "2008-12-12",
    "apy": "2008",
    "tit": "证件号码定位和识别方法",
    "inv": [
      "肖潇",
      "刘正珍"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101751567B",
    "apn": "CN200810239540.X",
    "apd": "2008-12-12",
    "apy": "2008",
    "tit": "快速文本识别方法",
    "inv": [
      "万鑫",
      "刘正珍",
      "朱军民"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101751121B",
    "apn": "CN200810239537.8",
    "apd": "2008-12-12",
    "apy": "2008",
    "tit": "基于OCR的无线扫描输入装置和方法",
    "inv": [
      "朱军民"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101739558A",
    "apn": "CN200810227272.X",
    "apd": "2008-11-26",
    "apy": "2008",
    "tit": "便携式名片摄取装置",
    "inv": [
      "刘迎建",
      "陈勇",
      "牛磊",
      "张军"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101599124B",
    "apn": "CN200810114319.1",
    "apd": "2008-06-03",
    "apy": "2008",
    "tit": "一种从视频图像中分割字符的方法和装置",
    "inv": [
      "黄磊",
      "刘昌平",
      "程豪"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101593276B",
    "apn": "CN200810113659.2",
    "apd": "2008-05-29",
    "apy": "2008",
    "tit": "一种视频OCR图文分离方法及系统",
    "inv": [
      "禹晶",
      "黄磊",
      "刘昌平"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "汉王",
    "pun": "CN101441713B",
    "apn": "CN200710177673.4",
    "apd": "2007-11-19",
    "apy": "2007",
    "tit": "一种PDF文件的光学字符识别方法及装置",
    "inv": [
      "刘迎建",
      "刘昌平",
      "江世盛",
      "丁迎",
      "刘强"
    ],
    "app": [
      "汉王科技股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN110134935A",
    "apn": "CN201810128376.9",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "一种提取字形特征的方法、装置及设备",
    "inv": [
      "童毅轩",
      "张永伟",
      "董滨",
      "姜珊珊",
      "张佳师"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN109635801A",
    "apn": "CN201710929630.0",
    "apd": "2017-10-09",
    "apy": "2017",
    "tit": "光学字符识别后处理的方法、装置及计算机可读存储介质",
    "inv": [
      "熊怀欣"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN107403179A",
    "apn": "CN201610341801.3",
    "apd": "2016-05-20",
    "apy": "2016",
    "tit": "一种物品包装信息的注册方法及装置",
    "inv": [
      "庞勃",
      "王刚",
      "王千",
      "诸加丹"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN107403128A",
    "apn": "CN201610342208.0",
    "apd": "2016-05-20",
    "apy": "2016",
    "tit": "一种物品识别方法及装置",
    "inv": [
      "庞勃",
      "王刚",
      "王千",
      "诸加丹"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN107038440A",
    "apn": "CN201610080494.8",
    "apd": "2016-02-04",
    "apy": "2016",
    "tit": "图像倾斜校正装置、图像处理系统以及图像倾斜校正方法",
    "inv": [
      "张子盛"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN106557714B",
    "apn": "CN201510621702.6",
    "apd": "2015-09-25",
    "apy": "2015",
    "tit": "条形码定位方法、装置和系统，条形码读取方法和装置，图像处理装置",
    "inv": [
      "顾明",
      "陈剑",
      "张毅强",
      "李浩"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN106557796B",
    "apn": "CN201510616508.9",
    "apd": "2015-09-24",
    "apy": "2015",
    "tit": "条形码的校验位的确定方法及装置、条形码读取方法及装置、图像处理装置",
    "inv": [
      "顾明",
      "陈剑",
      "张毅强",
      "李浩"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN106548107B",
    "apn": "CN201510612652.5",
    "apd": "2015-09-23",
    "apy": "2015",
    "tit": "条形码定位装置和条形码定位方法、以及条形码读取装置和条形码读取方法",
    "inv": [
      "曹永刚",
      "王怡芳",
      "赵红川"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN106446732B",
    "apn": "CN201510496347.4",
    "apd": "2015-08-13",
    "apy": "2015",
    "tit": "一种条形码的识别方法、装置及设备",
    "inv": [
      "庞勃",
      "范圣印",
      "王鑫",
      "王千",
      "王刚"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN104636740A",
    "apn": "CN201410616204.8",
    "apd": "2014-11-05",
    "apy": "2014",
    "tit": "图像处理系统和图像处理方法",
    "inv": [
      "岛崎武嗣"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN105447426B",
    "apn": "CN201410334509.X",
    "apd": "2014-07-14",
    "apy": "2014",
    "tit": "判定装置、文档图像管理系统以及判定方法",
    "inv": [
      "曹永刚",
      "黄盛杰",
      "赵红川",
      "王怡芳"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN105335395B",
    "apn": "CN201410334212.3",
    "apd": "2014-07-14",
    "apy": "2014",
    "tit": "文档图像管理装置以及文档图像管理系统",
    "inv": [
      "李浩"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN104050211A",
    "apn": "CN201410077003.5",
    "apd": "2014-03-04",
    "apy": "2014",
    "tit": "文档处理装置和文档处理方法",
    "inv": [
      "大黑庆久"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN104038663B",
    "apn": "CN201410074762.6",
    "apd": "2014-03-03",
    "apy": "2014",
    "tit": "分布式扫描系统中的设备管理",
    "inv": [
      "森蒂尔·K·塞尔瓦拉杰",
      "洪江",
      "新村健治"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN104036162B",
    "apn": "CN201410074566.9",
    "apd": "2014-03-03",
    "apy": "2014",
    "tit": "一种扫描装置",
    "inv": [
      "森蒂尔·K·塞尔瓦拉杰",
      "洪江",
      "新村健治"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN104036163B",
    "apn": "CN201410074688.8",
    "apd": "2014-03-03",
    "apy": "2014",
    "tit": "分布式扫描系统中的权限管理",
    "inv": [
      "森蒂尔·K·塞尔瓦拉杰",
      "洪江",
      "新村健治"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN104636700B",
    "apn": "CN201310573945.8",
    "apd": "2013-11-15",
    "apy": "2013",
    "tit": "文档图像管理装置以及文档图像管理系统",
    "inv": [
      "李浩",
      "欧阳亮",
      "艾桂观",
      "王冕",
      "宋雷"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "文化/生活",
    "l2": "条形码",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN103198069A",
    "apn": "CN201210003480.8",
    "apd": "2012-01-06",
    "apy": "2012",
    "tit": "抽取关系型表格的方法和装置",
    "inv": [
      "孙军",
      "谢宣松",
      "姜珊珊",
      "郑继川"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN102402573B",
    "apn": "CN201110268920.8",
    "apd": "2011-09-13",
    "apy": "2011",
    "tit": "表格处理装置和表格处理方法",
    "inv": [
      "山合敏文"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN102402576A",
    "apn": "CN201110273992.1",
    "apd": "2011-09-07",
    "apy": "2011",
    "tit": "信息处理设备、信息处理方法及计算机程序产品",
    "inv": [
      "大黑庆久"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN102194123B",
    "apn": "CN201010121993.X",
    "apd": "2010-03-11",
    "apy": "2010",
    "tit": "表格模板定义方法和装置",
    "inv": [
      "蒋焰",
      "张睿",
      "山合敏文",
      "大黑庆久"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN102081736B",
    "apn": "CN200910249848.7",
    "apd": "2009-11-27",
    "apy": "2009",
    "tit": "从可移植电子文档中提取字符外接矩形的设备和方法",
    "inv": [
      "杜成",
      "徐文晖",
      "长谷川史裕",
      "井上浩一"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN102081594B",
    "apn": "CN200910249849.1",
    "apd": "2009-11-27",
    "apy": "2009",
    "tit": "从可移植电子文档中提取字符外接矩形的设备和方法",
    "inv": [
      "徐文晖",
      "杜成",
      "长谷川史裕",
      "井上浩一"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "理光",
    "pun": "CN101621601B",
    "apn": "CN200910149192.1",
    "apd": "2009-06-26",
    "apy": "2009",
    "tit": "图像形成设备和图像形成方法",
    "inv": [
      "山川慎二"
    ],
    "app": [
      "株式会社理光"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN102012934A",
    "apn": "CN201010574563.3",
    "apd": "2010-11-30",
    "apy": "2010",
    "tit": "图片搜索方法及搜索系统",
    "inv": [
      "文林福"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103577818B",
    "apn": "CN201210279370.4",
    "apd": "2012-08-07",
    "apy": "2012",
    "tit": "一种图像文字识别的方法和装置",
    "inv": [
      "韩钧宇",
      "丁二锐",
      "吴中勤",
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN102855480A",
    "apn": "CN201210279368.7",
    "apd": "2012-08-07",
    "apy": "2012",
    "tit": "一种图像文字识别方法和装置",
    "inv": [
      "韩钧宇",
      "丁二锐",
      "吴中勤",
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN102831200A",
    "apn": "CN201210279367.2",
    "apd": "2012-08-07",
    "apy": "2012",
    "tit": "一种基于图像文字识别的商品推送方法和装置",
    "inv": [
      "韩钧宇",
      "丁二锐",
      "吴中勤",
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103593643B",
    "apn": "CN201210293317.X",
    "apd": "2012-08-16",
    "apy": "2012",
    "tit": "一种图像识别的方法及系统",
    "inv": [
      "刘俊启"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103679168B",
    "apn": "CN201210317019.X",
    "apd": "2012-08-30",
    "apy": "2012",
    "tit": "文字区域检测方法及装置",
    "inv": [
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN102930262B",
    "apn": "CN201210350550.7",
    "apd": "2012-09-19",
    "apy": "2012",
    "tit": "一种从图像中提取文字行的方法及装置",
    "inv": [
      "韩钧宇",
      "刘经拓",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103020184B",
    "apn": "CN201210501037.3",
    "apd": "2012-11-29",
    "apy": "2012",
    "tit": "使用拍摄图像获取搜索结果的方法和系统",
    "inv": [
      "巫国忠"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103093217A",
    "apn": "CN201310003574.X",
    "apd": "2013-01-06",
    "apy": "2013",
    "tit": "一种交互式的图像文字识别方法及装置",
    "inv": [
      "丁二锐",
      "韩钧宇",
      "吴中勤"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104134064B",
    "apn": "CN201310159518.5",
    "apd": "2013-05-02",
    "apy": "2013",
    "tit": "文字识别方法和装置",
    "inv": [
      "黎君",
      "金远"
    ],
    "app": [
      "百度国际科技(深圳)有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104143093B",
    "apn": "CN201310173147.6",
    "apd": "2013-05-10",
    "apy": "2013",
    "tit": "文字识别方法和装置",
    "inv": [
      "缪定凡",
      "金远",
      "黎君"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103257954B",
    "apn": "CN201310222804.1",
    "apd": "2013-06-05",
    "apy": "2013",
    "tit": "古籍中文字的校对方法、系统及校对服务器",
    "inv": [
      "詹君"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104239313B",
    "apn": "CN201310231443.7",
    "apd": "2013-06-09",
    "apy": "2013",
    "tit": "基于移动终端的屏幕中显示文字的搜索方法与移动终端",
    "inv": [
      "朱振宇",
      "秦银"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103365988A",
    "apn": "CN201310282530.5",
    "apd": "2013-07-05",
    "apy": "2013",
    "tit": "对移动终端的图片文字朗读的方法、装置和移动终端",
    "inv": [
      "秦银",
      "朱振宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "特定场景",
    "l2": "截屏识文",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103632388A",
    "apn": "CN201310703723.3",
    "apd": "2013-12-19",
    "apy": "2013",
    "tit": "图像语义标注的方法、装置和客户端",
    "inv": [
      "潘屹峰",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103714055B",
    "apn": "CN201310746535.9",
    "apd": "2013-12-30",
    "apy": "2013",
    "tit": "从图片中自动提取双语词典的方法及装置",
    "inv": [
      "王海峰",
      "马艳军"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103679165B",
    "apn": "CN201310752624.4",
    "apd": "2013-12-31",
    "apy": "2013",
    "tit": "OCR字符识别方法及系统",
    "inv": [
      "王海峰",
      "和为"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103914546B",
    "apn": "CN201410140455.3",
    "apd": "2014-04-09",
    "apy": "2014",
    "tit": "数据更新方法及其装置",
    "inv": [
      "张文波",
      "王开华"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN103971126B",
    "apn": "CN201410199373.6",
    "apd": "2014-05-12",
    "apy": "2014",
    "tit": "一种交通标志识别方法和装置",
    "inv": [
      "艾锐",
      "郎咸朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104461545B",
    "apn": "CN201410773743.2",
    "apd": "2014-12-12",
    "apy": "2014",
    "tit": "将移动终端中内容提供至用户的方法及装置",
    "inv": [
      "罗航"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "特定场景",
    "l2": "截屏识文",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104598937B",
    "apn": "CN201510033229.X",
    "apd": "2015-01-22",
    "apy": "2015",
    "tit": "文字信息的识别方法和装置",
    "inv": [
      "李瑾",
      "徐翔"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104573675B",
    "apn": "CN201510047554.1",
    "apd": "2015-01-29",
    "apy": "2015",
    "tit": "作业图像的展示方法和装置",
    "inv": [
      "梁爽"
    ],
    "app": [
      "作业帮教育科技(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104636741B",
    "apn": "CN201510064672.3",
    "apd": "2015-02-06",
    "apy": "2015",
    "tit": "公式识别方法和装置",
    "inv": [
      "吴仑",
      "王岩",
      "梁爽",
      "陈恭明",
      "邹静"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN104750791A",
    "apn": "CN201510109003.3",
    "apd": "2015-03-12",
    "apy": "2015",
    "tit": "一种图像检索方法及装置",
    "inv": [
      "梁爽",
      "刘惠庭",
      "施鹏"
    ],
    "app": [
      "作业帮教育科技(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "WO2016107125A1",
    "apn": "PCT/CN2015/083394",
    "apd": "2015-07-06",
    "apy": "2015",
    "tit": "信息搜索方法及装置",
    "inv": [
      "QIN SHOUKE",
      "ZHANG ZEMING",
      "HAN YOU",
      "CHEN ZHIYANG",
      "CHENG XIAOHUA",
      "XU PEIZHI",
      "MA XIAOLIN",
      "WEN SHILEI",
      "CHEN SHIJIA",
      "LI XUBIN",
      "JIANG YAN"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN105095446A",
    "apn": "CN201510441121.4",
    "apd": "2015-07-24",
    "apy": "2015",
    "tit": "药品的搜索处理方法、服务器及终端设备",
    "inv": [
      "孙舒",
      "张娜",
      "王珍",
      "郄丽晨",
      "戴蕾",
      "陈凯",
      "杨文博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "特定场景",
    "l2": "药品识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN105260381A",
    "apn": "CN201510569886.6",
    "apd": "2015-09-08",
    "apy": "2015",
    "tit": "基于图像识别的数据维护方法和装置",
    "inv": [
      "魏丽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN105260733A",
    "apn": "CN201510579670.8",
    "apd": "2015-09-11",
    "apy": "2015",
    "tit": "用于处理图像信息的方法和装置",
    "inv": [
      "韩钧宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN105184289B",
    "apn": "CN201510651869.7",
    "apd": "2015-10-10",
    "apy": "2015",
    "tit": "字符识别方法和装置",
    "inv": [
      "谢术富",
      "韩钧宇",
      "肖航"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "WO2017041365A1",
    "apn": "PCT/CN2015/096127",
    "apd": "2015-12-01",
    "apy": "2015",
    "tit": "用于处理图像信息的方法和装置",
    "inv": [
      "韩钧宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN105653733A",
    "apn": "CN201610109004.2",
    "apd": "2016-02-26",
    "apy": "2016",
    "tit": "搜索方法和装置",
    "inv": [
      "沈强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107203572A",
    "apn": "CN201610158425.4",
    "apd": "2016-03-18",
    "apy": "2016",
    "tit": "一种图片搜索的方法及装置",
    "inv": [
      "刘照卿"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN106407976B",
    "apn": "CN201610772101.X",
    "apd": "2016-08-30",
    "apy": "2016",
    "tit": "图像字符识别模型生成和竖列字符图像识别方法和装置",
    "inv": [
      "谢术富",
      "肖航"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107784301A",
    "apn": "CN201610794963.2",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "用于识别图像中文字区域的方法和装置",
    "inv": [
      "陈鑫",
      "高建忠",
      "雷成军",
      "吴冬雪",
      "杨琳琳",
      "程涛远"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN106708940A",
    "apn": "CN201611042535.0",
    "apd": "2016-11-11",
    "apy": "2016",
    "tit": "用于处理图片的方法和装置",
    "inv": [
      "李雪城",
      "徐英博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "WO2018040342A1",
    "apn": "PCT/CN2016/108419",
    "apd": "2016-12-02",
    "apy": "2016",
    "tit": "用于识别图像中文字区域的方法和装置",
    "inv": [
      "CHEN Xin",
      "GAO Jianzhong",
      "LEI Chengjun",
      "WU Dongxue",
      "YANG Linlin",
      "CHENG Taoyuan"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN106845470A",
    "apn": "CN201710089061.3",
    "apd": "2017-02-20",
    "apy": "2017",
    "tit": "地图数据采集方法和装置",
    "inv": [
      "李静",
      "张红飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107679074A",
    "apn": "CN201710742645.6",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "一种图片生成方法与设备",
    "inv": [
      "谢术富",
      "刘珊珊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107609057A",
    "apn": "CN201710743642.4",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "一种获取商标图像的文字数据的方法与装置",
    "inv": [
      "谢术富",
      "杜宇宁",
      "李广",
      "刘珊珊",
      "李晨霞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107609056A",
    "apn": "CN201710743444.8",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "一种基于图片识别的问答处理方法与设备",
    "inv": [
      "吴志全"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "特定场景",
    "l2": "智能客服",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107633219A",
    "apn": "CN201710811919.2",
    "apd": "2017-09-11",
    "apy": "2017",
    "tit": "一体化光学字符识别方法和系统",
    "inv": [
      "许天涵",
      "张发恩",
      "周恺",
      "王倩",
      "刘昆",
      "肖远昊",
      "徐东泽",
      "孙家元",
      "刘岚"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107832765A",
    "apn": "CN201710823997.4",
    "apd": "2017-09-13",
    "apy": "2017",
    "tit": "对包括文字内容和图像内容的图片识别",
    "inv": [
      "邓玥琳",
      "高光明",
      "刘辉",
      "丁飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107590252A",
    "apn": "CN201710847084.6",
    "apd": "2017-09-19",
    "apy": "2017",
    "tit": "用于信息交互的方法及装置",
    "inv": [
      "田晓丽",
      "方高林",
      "顾晓光",
      "米雪",
      "孙珂",
      "丁鑫哲",
      "孙瑞盈"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN107944448A",
    "apn": "CN201710910372.1",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "一种图像异步编辑方法及装置",
    "inv": [
      "马丽芬",
      "孟浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110019906A",
    "apn": "CN201711176106.7",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "用于显示信息的方法和装置",
    "inv": [
      "王德夫",
      "刘鹏飞",
      "张钰",
      "魏苓",
      "高志昌",
      "马酩",
      "王文韬",
      "郑讯",
      "刘超",
      "狄涛",
      "张高"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108124191B",
    "apn": "CN201711406873.2",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "一种视频审核方法、装置及服务器",
    "inv": [
      "丁圣龙",
      "王璇",
      "尉明望",
      "郁陈焙"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108182184A",
    "apn": "CN201711449311.6",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "图片文字翻译方法、应用及计算机设备",
    "inv": [
      "王晓辉",
      "张蕾",
      "奚佳芸"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108182183A",
    "apn": "CN201711447783.8",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "图片文字翻译方法、应用及计算机设备",
    "inv": [
      "王晓辉",
      "张蕾",
      "奚佳芸"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108182432A",
    "apn": "CN201711458159.8",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "信息处理方法和装置",
    "inv": [
      "刘晓波",
      "曹徐平",
      "吴家林",
      "吕继根",
      "邵英杰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108304835A",
    "apn": "CN201810090633.4",
    "apd": "2018-01-30",
    "apy": "2018",
    "tit": "文字检测方法和装置",
    "inv": [
      "刘家铭",
      "章成全",
      "韩钧宇",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108304815A",
    "apn": "CN201810128506.9",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "一种数据获取方法、装置、服务器及存储介质",
    "inv": [
      "马立"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108345882A",
    "apn": "CN201810142437.7",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "用于图像识别的方法、装置、设备和计算机可读存储介质",
    "inv": [
      "向宇波",
      "袁运筹",
      "王天天",
      "陈帆",
      "李琦",
      "陈涛涛",
      "张红光",
      "刘建夏",
      "娄双双"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108256591A",
    "apn": "CN201810159604.9",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "葛高飞",
      "王小艳",
      "李磊",
      "刘昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108470290A",
    "apn": "CN201810265582.4",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "广告检测方法、装置及服务器",
    "inv": [
      "李敬医",
      "赖慎禄",
      "缪雨佳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108549643A",
    "apn": "CN201810305392.0",
    "apd": "2018-04-08",
    "apy": "2018",
    "tit": "翻译处理方法和装置",
    "inv": [
      "王晓辉",
      "张蕾",
      "奚佳芸"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108536686A",
    "apn": "CN201810320872.4",
    "apd": "2018-04-11",
    "apy": "2018",
    "tit": "一种图片翻译方法、装置、终端及存储介质",
    "inv": [
      "王晓辉",
      "张蕾",
      "奚佳芸"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108667915A",
    "apn": "CN201810370728.1",
    "apd": "2018-04-24",
    "apy": "2018",
    "tit": "信息推送系统、方法和装置",
    "inv": [
      "李晓鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110414496A",
    "apn": "CN201810386017.3",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "相似字识别方法、装置、计算机设备及存储介质",
    "inv": [
      "余淼",
      "刘晓波",
      "郑杰鹏",
      "吴家林",
      "邵英杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108734165A",
    "apn": "CN201810427967.6",
    "apd": "2018-05-07",
    "apy": "2018",
    "tit": "基于人工智能的书法质量检测方法、装置和电子设备",
    "inv": [
      "胡建华"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "WO2019056821A1",
    "apn": "PCT/CN2018/092870",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "用于信息交互的方法及装置",
    "inv": [
      "田晓丽",
      "方高林",
      "顾晓光",
      "米雪",
      "孙珂",
      "丁鑫哲",
      "孙瑞盈"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN108985289A",
    "apn": "CN201810791080.5",
    "apd": "2018-07-18",
    "apy": "2018",
    "tit": "乱码检测方法及装置",
    "inv": [
      "项金鑫",
      "尹飞",
      "薛大伟",
      "邢潘红",
      "魏晨辉",
      "张婷",
      "柏馨",
      "刘盼盼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN109117843A",
    "apn": "CN201810866733.1",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "字符遮挡检测方法及装置",
    "inv": [
      "刘盼盼",
      "尹飞",
      "项金鑫",
      "柏馨",
      "张婷",
      "薛大伟",
      "邢潘红",
      "魏晨辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN109241523A",
    "apn": "CN201810907161.7",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "变体作弊字段的识别方法、装置及设备",
    "inv": [
      "陈玉焓"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN109308465A",
    "apn": "CN201811076344.5",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "表格线检测方法、装置、设备及计算机可读介质",
    "inv": [
      "杨静远",
      "毕研广",
      "韩钧宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN109543690A",
    "apn": "CN201811428961.7",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "用于提取信息的方法和装置",
    "inv": [
      "吕明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN109784330A",
    "apn": "CN201910003495.6",
    "apd": "2019-01-03",
    "apy": "2019",
    "tit": "招牌内容识别方法、装置及设备",
    "inv": [
      "刘慧",
      "吴云鹏",
      "王洪志"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN109948507A",
    "apn": "CN201910192283.7",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "用于检测表格的方法和装置",
    "inv": [
      "王磊",
      "刘建夏",
      "向宇波",
      "张红光",
      "林海涛",
      "宋乐侃",
      "沈俊宇",
      "张文康",
      "袁运筹",
      "王天天",
      "喻友平",
      "吴甜"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110147786A",
    "apn": "CN201910290330.1",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于检测图像中的文本区域的方法、装置、设备以及介质",
    "inv": [
      "章成全",
      "黄祖明",
      "恩孟一",
      "韩钧宇",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110032969A",
    "apn": "CN201910289292.8",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于检测图像中的文本区域的方法、装置、设备以及介质",
    "inv": [
      "章成全",
      "黄祖明",
      "恩孟一",
      "韩钧宇",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110147724A",
    "apn": "CN201910289541.3",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于检测视频中的文本区域的方法、装置、设备以及介质",
    "inv": [
      "章成全",
      "李轩",
      "冯浩城",
      "倪子涵",
      "韩钧宇",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110162757A",
    "apn": "CN201910353932.7",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "一种表格结构提取方法及系统",
    "inv": [
      "李超",
      "刘国翌",
      "张家栋"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110070085A",
    "apn": "CN201910359065.8",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "车牌识别方法和装置",
    "inv": [
      "杨静远",
      "孙逸鹏",
      "韩钧宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110188755A",
    "apn": "CN201910464140.7",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "一种图像识别的方法、装置和计算机可读存储介质",
    "inv": [
      "钦夏孟",
      "陈露露",
      "韩钧宇",
      "朱胜贤"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110378346A",
    "apn": "CN201910515396.6",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "建立文字识别模型的方法、装置、设备和计算机存储介质",
    "inv": [
      "姚锟",
      "孙逸鹏",
      "黎健成",
      "韩钧宇",
      "刘经拓",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110287854A",
    "apn": "CN201910539220.4",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "表格的提取方法、装置、计算机设备和存储介质",
    "inv": [
      "李超",
      "刘国翌",
      "张家栋"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110287951A",
    "apn": "CN201910546270.5",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "一种文字识别的方法及装置",
    "inv": [
      "许海伦",
      "李轩",
      "刘家铭",
      "刘珊珊",
      "刘涛",
      "韩钧宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110263793A",
    "apn": "CN201910555581.8",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "物品标签识别方法及装置",
    "inv": [
      "彭明浩"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110309824A",
    "apn": "CN201910593977.1",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "文字检测方法、装置以及终端",
    "inv": [
      "章成全"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110458011A",
    "apn": "CN201910602608.4",
    "apd": "2019-07-05",
    "apy": "2019",
    "tit": "端到端的文字识别方法及装置、计算机设备及可读介质",
    "inv": [
      "孙逸鹏",
      "章成全",
      "黄祖明",
      "刘家铭",
      "韩钧宇",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110458193A",
    "apn": "CN201910610019.0",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "垃圾分类处理方法、装置及系统、计算机设备与可读介质",
    "inv": [
      "冯博豪"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110490191A",
    "apn": "CN201910640953.7",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "端到端模型的训练方法与系统、及中文识别方法与系统",
    "inv": [
      "孙逸鹏",
      "刘家铭",
      "韩钧宇",
      "丁二锐",
      "刘经拓"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110413740A",
    "apn": "CN201910721082.1",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "化学表达式的查询方法、装置、电子设备及存储介质",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "百度",
    "pun": "CN110458918A",
    "apn": "CN201910757082.7",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "章成全"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN110084229A",
    "apn": "CN201910228663.1",
    "apd": "2019-03-25",
    "apy": "2019",
    "tit": "一种印章检测方法、装置、设备及可读存储介质",
    "inv": [
      "谢名亮",
      "殷兵",
      "柳林",
      "胡金水",
      "崔瑞莲"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109815954A",
    "apn": "CN201910097717.5",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "增值税发票图像的方向校正方法、装置、设备及存储介质",
    "inv": [
      "田中贺",
      "刘聪",
      "殷兵"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109657629A",
    "apn": "CN201811582999.X",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "一种文本行提取方法及装置",
    "inv": [
      "常欢",
      "崔瑞莲",
      "胡金水",
      "殷兵",
      "刘聪"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109614944A",
    "apn": "CN201811542193.8",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "一种数学公式识别方法、装置、设备及可读存储介质",
    "inv": [
      "李飞吾",
      "胡金水",
      "殷兵",
      "刘聪"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109858325A",
    "apn": "CN201811513646.4",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "一种表格检测方法和装置",
    "inv": [
      "吴爱红",
      "胡雨隆",
      "殷兵",
      "胡金水"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109635718A",
    "apn": "CN201811502085.8",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "一种文本区域划分方法、装置、设备及存储介质",
    "inv": [
      "潘鹏举",
      "何春江",
      "王根"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109635798A",
    "apn": "CN201811487626.4",
    "apd": "2018-12-06",
    "apy": "2018",
    "tit": "一种信息提取方法及装置",
    "inv": [
      "潘鹏举",
      "何春江",
      "王根"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109670035A",
    "apn": "CN201811466899.0",
    "apd": "2018-12-03",
    "apy": "2018",
    "tit": "一种文本摘要生成方法",
    "inv": [
      "陈翰卿",
      "宋时德",
      "胡加学"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109522862A",
    "apn": "CN201811435746.X",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "一种文档修正方法、装置、设备及可读存储介质",
    "inv": [
      "张玮玮",
      "黄梓峰",
      "严亚路",
      "林欢欢",
      "余昳超",
      "秦冬生"
    ],
    "app": [
      "西安讯飞超脑信息科技有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109614628A",
    "apn": "CN201811368184.1",
    "apd": "2018-11-16",
    "apy": "2018",
    "tit": "一种基于智能硬件的翻译方法与翻译系统",
    "inv": [
      "陈源凯",
      "王朝鑫",
      "陈家盛"
    ],
    "app": [
      "广州市讯飞樽鸿信息技术有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109460557A",
    "apn": "CN201811000192.0",
    "apd": "2018-08-30",
    "apy": "2018",
    "tit": "一种翻译机的控制方法",
    "inv": [
      "郎列衡"
    ],
    "app": [
      "山东讯飞淘云贸易有限公司"
    ],
    "l1": "硬件",
    "l2": "硬件",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109145904A",
    "apn": "CN201810972547.6",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "一种字符识别方法及装置",
    "inv": [
      "王光新",
      "谭昶",
      "昌玮",
      "查洪钰",
      "李国玉",
      "苏增亮",
      "姚佳",
      "杨阳",
      "陶王根",
      "黄庭尧"
    ],
    "app": [
      "讯飞智元信息科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN109165829A",
    "apn": "CN201810906846.X",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "一种铁路值班人员交接班系统及交接班方法",
    "inv": [
      "单洪政",
      "肖军"
    ],
    "app": [
      "佳讯飞鸿(北京)智能科技研究院有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "WO2018223857A1",
    "apn": "PCT/CN2018/088505",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "文本行识别方法及系统",
    "inv": [
      "吴爱红",
      "胡雨隆",
      "胡金水",
      "竺博",
      "魏思"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN108509960A",
    "apn": "CN201810361308.7",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "一种文本朝向检测方法及装置",
    "inv": [
      "吴爱红",
      "胡金水",
      "竺博"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN108536655A",
    "apn": "CN201711414780.4",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "一种基于手持智能终端的场景化朗读音频制作方法及系统",
    "inv": [
      "孙仉茂",
      "纪德财"
    ],
    "app": [
      "广州市讯飞樽鸿信息技术有限公司"
    ],
    "l1": "特定场景",
    "l2": "截屏识文",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107943780A",
    "apn": "CN201711365896.3",
    "apd": "2017-12-18",
    "apy": "2017",
    "tit": "版面分栏方法及装置",
    "inv": [
      "胡雨隆",
      "胡金水"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN108021900A",
    "apn": "CN201711365898.2",
    "apd": "2017-12-18",
    "apy": "2017",
    "tit": "版面分栏方法及装置",
    "inv": [
      "胡雨隆",
      "胡金水"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107832756A",
    "apn": "CN201711002755.5",
    "apd": "2017-10-24",
    "apy": "2017",
    "tit": "快递单信息提取方法及装置、存储介质、电子设备",
    "inv": [
      "谢名亮",
      "柳林",
      "刘海波",
      "胡良义",
      "王建社",
      "殷兵",
      "张程风",
      "崔瑞莲",
      "韩球",
      "朱仁兵"
    ],
    "app": [
      "讯飞智元信息科技有限公司"
    ],
    "l1": "交通/物流",
    "l2": "物流",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107729936A",
    "apn": "CN201710947823.9",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "一种改错题自动评阅方法及系统",
    "inv": [
      "胡阳",
      "何春江",
      "戴文娟"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107180239A",
    "apn": "CN201710433560.X",
    "apd": "2017-06-09",
    "apy": "2017",
    "tit": "文本行识别方法及系统",
    "inv": [
      "吴爱红",
      "胡雨隆",
      "胡金水",
      "竺博",
      "魏思"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN106503634A",
    "apn": "CN201610887988.7",
    "apd": "2016-10-11",
    "apy": "2016",
    "tit": "一种图像对齐方法及装置",
    "inv": [
      "张银田",
      "胡雨隆",
      "胡金水"
    ],
    "app": [
      "讯飞智元信息科技有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107622271A",
    "apn": "CN201610563053.3",
    "apd": "2016-07-15",
    "apy": "2016",
    "tit": "手写文本行提取方法及系统",
    "inv": [
      "胡雨隆",
      "胡金水",
      "竺博",
      "魏思"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107341487A",
    "apn": "CN201610290893.7",
    "apd": "2016-04-28",
    "apy": "2016",
    "tit": "一种涂抹字符的检测方法及系统",
    "inv": [
      "吴爱红",
      "戴文娟",
      "竺博",
      "魏思",
      "胡郁",
      "刘庆峰"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107301164A",
    "apn": "CN201610237548.7",
    "apd": "2016-04-14",
    "apy": "2016",
    "tit": "数学公式的语义解析方法及装置",
    "inv": [
      "刘青文",
      "张丹",
      "邓晓栋",
      "胡国平",
      "胡郁",
      "刘庆峰"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN107301411A",
    "apn": "CN201610237543.4",
    "apd": "2016-04-14",
    "apy": "2016",
    "tit": "数学公式识别方法及装置",
    "inv": [
      "刘青文",
      "张丹",
      "邓晓栋",
      "胡国平",
      "胡郁",
      "刘庆峰"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "SG11201701719QA",
    "apn": "SG11201701719Q",
    "apd": "2015-08-11",
    "apy": "2015",
    "tit": "Intelligent scoring method and system for text objective question",
    "inv": [
      "HU, YULONG",
      "ZHANG, YINTIAN",
      "ZHU, BO",
      "WEI, SI",
      "HU, GUOPING",
      "HU, YU",
      "LIU, QINGFENG"
    ],
    "app": [
      "IFLYTEK CO., LTD."
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "WO2016041423A1",
    "apn": "PCT/CN2015/086585",
    "apd": "2015-08-11",
    "apy": "2015",
    "tit": "用于文字性客观题的智能阅卷方法及系统",
    "inv": [
      "胡雨隆",
      "张银田",
      "竺博",
      "魏思",
      "胡国平",
      "胡郁",
      "刘庆峰"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "科大讯飞",
    "pun": "CN104598910A",
    "apn": "CN201510023250.1",
    "apd": "2015-01-16",
    "apy": "2015",
    "tit": "基于梯度方向匹配算法的智能电视台标识别方法及系统",
    "inv": [
      "江海洋",
      "雷琴辉",
      "魏岩",
      "庄纪军",
      "胡安洲"
    ],
    "app": [
      "科大讯飞股份有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN102880613A",
    "apn": "CN201110197350.8",
    "apd": "2011-07-14",
    "apy": "2011",
    "tit": "色情图片的识别方法及其设备",
    "inv": [
      "王涛伟",
      "张立明"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN102915437A",
    "apn": "CN201110219912.4",
    "apd": "2011-08-02",
    "apy": "2011",
    "tit": "文本信息识别方法及系统",
    "inv": [
      "张富春"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "其他",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN103390155B",
    "apn": "CN201210146093.X",
    "apd": "2012-05-11",
    "apy": "2012",
    "tit": "一种图文识别方法及图文识别装置",
    "inv": [
      "陈波",
      "王冬",
      "黄橙蓝",
      "饶丰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN103425980B",
    "apn": "CN201210153810.1",
    "apd": "2012-05-17",
    "apy": "2012",
    "tit": "一种图片中文字区域的获取方法及装置",
    "inv": [
      "王晖",
      "谢晓境"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN102831106A",
    "apn": "CN201210307666.2",
    "apd": "2012-08-27",
    "apy": "2012",
    "tit": "一种移动终端的电子文档生成方法及移动终端",
    "inv": [
      "吴仕祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN103678286B",
    "apn": "CN201210339864.7",
    "apd": "2012-09-14",
    "apy": "2012",
    "tit": "一种翻译方法和系统",
    "inv": [
      "万璐",
      "高飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN103942212B",
    "apn": "CN201310021505.1",
    "apd": "2013-01-21",
    "apy": "2013",
    "tit": "一种用户界面的文字检测方法及装置",
    "inv": [
      "宁海燕",
      "丁如敏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104077582A",
    "apn": "CN201310097950.6",
    "apd": "2013-03-25",
    "apy": "2013",
    "tit": "访问互联网的方法、装置及移动终端",
    "inv": [
      "王智培",
      "胡博",
      "唐廷勇",
      "刘人方",
      "周睿奕",
      "王曦",
      "谢郑凯",
      "贺柏森",
      "黄英",
      "李未",
      "冯诚",
      "张凯",
      "杨惠姣",
      "刘玉磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104182419A",
    "apn": "CN201310201705.5",
    "apd": "2013-05-27",
    "apy": "2013",
    "tit": "对图片中的文字信息进行处理的方法及装置",
    "inv": [
      "张锦铭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104252475B",
    "apn": "CN201310262610.4",
    "apd": "2013-06-27",
    "apy": "2013",
    "tit": "定位图片中文本信息的方法及装置",
    "inv": [
      "张锦铭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104281850A",
    "apn": "CN201310286487.X",
    "apd": "2013-07-09",
    "apy": "2013",
    "tit": "一种文字区域识别方法和装置",
    "inv": [
      "张佳",
      "丁如敏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104298982B",
    "apn": "CN201310297948.3",
    "apd": "2013-07-16",
    "apy": "2013",
    "tit": "一种文字识别方法及装置",
    "inv": [
      "梅树起",
      "陈泳君",
      "刘伯兴"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104573616A",
    "apn": "CN201310521601.2",
    "apd": "2013-10-29",
    "apy": "2013",
    "tit": "一种信息识别方法、相关装置及系统",
    "inv": [
      "李茂材"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN105279152B",
    "apn": "CN201410289042.1",
    "apd": "2014-06-24",
    "apy": "2014",
    "tit": "一种取词翻译的方法和装置",
    "inv": [
      "王智培",
      "杨惠姣",
      "张凯",
      "贺柏森",
      "黄英",
      "李未",
      "冯诚",
      "王曦",
      "青欣",
      "陈单枝",
      "胡飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110188365A",
    "apn": "CN201910450604.9",
    "apd": "2014-06-24",
    "apy": "2014",
    "tit": "一种取词翻译的方法和装置",
    "inv": [
      "王智培",
      "杨惠姣",
      "张凯",
      "贺柏森",
      "黄英",
      "李未",
      "冯诚",
      "王曦",
      "青欣",
      "陈单枝",
      "胡飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2015000433A1",
    "apn": "PCT/CN2014/081648",
    "apd": "2014-07-04",
    "apy": "2014",
    "tit": "一种多媒体查找方法、终端、服务器及系统",
    "inv": [
      "LI SHENYUAN"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN105320851A",
    "apn": "CN201410385234.2",
    "apd": "2014-08-05",
    "apy": "2014",
    "tit": "网页安全性检测方法及装置",
    "inv": [
      "柴凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104268512B",
    "apn": "CN201410475608.X",
    "apd": "2014-09-17",
    "apy": "2014",
    "tit": "基于光学字符识别的图像中字符识别方法及装置",
    "inv": [
      "李德斌",
      "王巨宏",
      "许勇",
      "全琦",
      "黄志斌",
      "杨大威",
      "谭志鹏",
      "吴现",
      "杨言",
      "胡事民",
      "徐昆"
    ],
    "app": [
      "清华大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "TWI522933B",
    "apn": "TW103135417",
    "apd": "2014-10-13",
    "apy": "2014",
    "tit": "一种讯息识别方法、相关装置及其系统",
    "inv": [
      "李茂材"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN104463105B",
    "apn": "CN201410663502.2",
    "apd": "2014-11-19",
    "apy": "2014",
    "tit": "路牌识别方法及装置",
    "inv": [
      "徐昆",
      "邓海峰",
      "马里千",
      "高磊"
    ],
    "app": [
      "清华大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "交通/物流",
    "l2": "交通",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN105046254A",
    "apn": "CN201510422663.7",
    "apd": "2015-07-17",
    "apy": "2015",
    "tit": "字符识别方法及装置",
    "inv": [
      "王红法",
      "周龙沙",
      "张小鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN106709490A",
    "apn": "CN201510467750.4",
    "apd": "2015-07-31",
    "apy": "2015",
    "tit": "一种字符识别方法和装置",
    "inv": [
      "王红法"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN106886774A",
    "apn": "CN201510954358.2",
    "apd": "2015-12-16",
    "apy": "2015",
    "tit": "识别身份证信息的方法和装置",
    "inv": [
      "倪辉",
      "张睿欣",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN106940799A",
    "apn": "CN201610004431.4",
    "apd": "2016-01-05",
    "apy": "2016",
    "tit": "文本图像处理方法和装置",
    "inv": [
      "周龙沙",
      "王红法"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN107180039A",
    "apn": "CN201610133793.3",
    "apd": "2016-03-09",
    "apy": "2016",
    "tit": "一种基于图片的文字信息识别方法及装置",
    "inv": [
      "邝野"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN107305630A",
    "apn": "CN201610260552.5",
    "apd": "2016-04-25",
    "apy": "2016",
    "tit": "文本序列识别方法和装置",
    "inv": [
      "陈智能"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院自动化研究所"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN106056114B",
    "apn": "CN201610347295.9",
    "apd": "2016-05-24",
    "apy": "2016",
    "tit": "名片内容识别方法和装置",
    "inv": [
      "叶浩",
      "张睿欣",
      "郭晓威",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN107688803A",
    "apn": "CN201610641373.6",
    "apd": "2016-08-05",
    "apy": "2016",
    "tit": "字符识别中识别结果的校验方法和装置",
    "inv": [
      "韩盛",
      "王红法",
      "周龙沙",
      "宋辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN107688807B",
    "apn": "CN201610639485.8",
    "apd": "2016-08-05",
    "apy": "2016",
    "tit": "图像处理方法及图像处理装置",
    "inv": [
      "周龙沙",
      "王红法"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN106529529B",
    "apn": "CN201610928665.8",
    "apd": "2016-10-31",
    "apy": "2016",
    "tit": "一种视频字幕识别方法及系统",
    "inv": [
      "王星星"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108171104A",
    "apn": "CN201611124294.4",
    "apd": "2016-12-08",
    "apy": "2016",
    "tit": "一种文字检测方法及装置",
    "inv": [
      "文亚飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2017118356A1",
    "apn": "PCT/CN2016/113843",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "文本图像处理方法和装置",
    "inv": [
      "ZHOU Longsha",
      "WANG Hongfa"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN106874906A",
    "apn": "CN201710031170.X",
    "apd": "2017-01-17",
    "apy": "2017",
    "tit": "一种图片的二值化方法、装置及终端",
    "inv": [
      "刘银松",
      "郭安泰"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108399405A",
    "apn": "CN201710067058.1",
    "apd": "2017-02-07",
    "apy": "2017",
    "tit": "营业执照识别方法和装置",
    "inv": [
      "张睿欣",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108334805A",
    "apn": "CN201710134711.1",
    "apd": "2017-03-08",
    "apy": "2017",
    "tit": "检测文档阅读顺序的方法和装置",
    "inv": [
      "朱传聪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2017202232A1",
    "apn": "PCT/CN2017/084625",
    "apd": "2017-05-17",
    "apy": "2017",
    "tit": "名片内容识别方法、电子设备和存储介质",
    "inv": [
      "YE Hao",
      "ZHANG Ruixin",
      "GUO Xiaowei",
      "HUANG Feiyue"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2018024243A1",
    "apn": "PCT/CN2017/095992",
    "apd": "2017-08-04",
    "apy": "2017",
    "tit": "字符识别中识别结果的校验方法和装置",
    "inv": [
      "HAN Sheng",
      "WANG Hongfa",
      "ZHOU Longsha",
      "SONG Hui"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109389115A",
    "apn": "CN201710687380.4",
    "apd": "2017-08-11",
    "apy": "2017",
    "tit": "文本识别方法、装置、存储介质和计算机设备",
    "inv": [
      "刘银松"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108304839A",
    "apn": "CN201710770911.6",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "一种图像数据处理方法以及装置",
    "inv": [
      "占克有"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108304840A",
    "apn": "CN201710772037.X",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "一种图像数据处理方法以及装置",
    "inv": [
      "占克有"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108304761A",
    "apn": "CN201710874973.1",
    "apd": "2017-09-25",
    "apy": "2017",
    "tit": "文本检测方法、装置、存储介质和计算机设备",
    "inv": [
      "刘铭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN107862315B",
    "apn": "CN201711064954.9",
    "apd": "2017-11-02",
    "apy": "2017",
    "tit": "字幕提取方法、视频搜索方法、字幕分享方法及装置",
    "inv": [
      "王星星"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109753953A",
    "apn": "CN201711071099.4",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "图像中定位文本的方法、装置、电子设备和存储介质",
    "inv": [
      "熊昆",
      "陈建业"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN107886082A",
    "apn": "CN201711190154.1",
    "apd": "2017-11-24",
    "apy": "2017",
    "tit": "图像中数学公式检测方法、装置、计算机设备及存储介质",
    "inv": [
      "黄鹏程"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108304763A",
    "apn": "CN201711250518.0",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "思维导图识别方法、装置、存储介质和计算机设备",
    "inv": [
      "刘阳",
      "罗干",
      "胡贾",
      "蓝东飞",
      "洪栩栩",
      "郭佳伟",
      "胡娟",
      "樊昱伯",
      "杨晓俊",
      "陆莹",
      "袁琳",
      "王巨宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2018103608A1",
    "apn": "PCT/CN2017/114473",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "一种文字检测方法、装置及存储介质",
    "inv": [
      "文亚飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108288078A",
    "apn": "CN201711285566.3",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "一种图像中字符识别方法、装置和介质",
    "inv": [
      "麻文华",
      "江修才",
      "刘海龙",
      "刘博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2018133717A1",
    "apn": "PCT/CN2018/072047",
    "apd": "2018-01-10",
    "apy": "2018",
    "tit": "图片的二值化方法、装置及终端",
    "inv": [
      "刘银松",
      "郭安泰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2018161764A1",
    "apn": "PCT/CN2018/075626",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "检测文档阅读顺序的方法、计算机设备和存储介质",
    "inv": [
      "朱传聪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108520254A",
    "apn": "CN201810172653.6",
    "apd": "2018-03-01",
    "apy": "2018",
    "tit": "一种基于格式化图像的文本检测方法、装置以及相关设备",
    "inv": [
      "余三思",
      "王斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108446698A",
    "apn": "CN201810213160.2",
    "apd": "2018-03-15",
    "apy": "2018",
    "tit": "在图像中检测文本的方法、装置、介质及电子设备",
    "inv": [
      "李玉梅",
      "杨学行"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110263779A",
    "apn": "CN201810225220.2",
    "apd": "2018-03-19",
    "apy": "2018",
    "tit": "文本区域检测方法及装置、文本检测方法、计算机可读介质",
    "inv": [
      "刘铭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108734089A",
    "apn": "CN201810285135.5",
    "apd": "2018-04-02",
    "apy": "2018",
    "tit": "识别图片文件中表格内容的方法、装置、设备及存储介质",
    "inv": [
      "王磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108596180A",
    "apn": "CN201810312473.3",
    "apd": "2018-04-09",
    "apy": "2018",
    "tit": "图像中的参数识别、参数识别模型的训练方法及装置",
    "inv": [
      "黄盈",
      "荆彦青"
    ],
    "app": [
      "深圳市腾讯网络信息技术有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108681729A",
    "apn": "CN201810431074.9",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "文本图像矫正方法、装置、存储介质及设备",
    "inv": [
      "袁同根"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110503084A",
    "apn": "CN201810483316.9",
    "apd": "2018-05-18",
    "apy": "2018",
    "tit": "一种图像中的文字区域识别方法和装置",
    "inv": [
      "白帆"
    ],
    "app": [
      "广州腾讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108830186A",
    "apn": "CN201810524889.1",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "文本图像的内容提取方法、装置、设备及存储介质",
    "inv": [
      "周超",
      "沈小勇",
      "马子扬",
      "王功华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110163192A",
    "apn": "CN201810524523.4",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "字符识别方法、装置及可读介质",
    "inv": [
      "蒋力",
      "刘易"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "上海交通大学"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN108777806A",
    "apn": "CN201810542908.3",
    "apd": "2018-05-30",
    "apy": "2018",
    "tit": "一种用户身份识别方法、装置和存储介质",
    "inv": [
      "张云"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110163051A",
    "apn": "CN201810857110.8",
    "apd": "2018-07-31",
    "apy": "2018",
    "tit": "文本提取方法、装置及存储介质",
    "inv": [
      "李洋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "视频",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109214385A",
    "apn": "CN201810929320.3",
    "apd": "2018-08-15",
    "apy": "2018",
    "tit": "数据采集方法、数据采集装置及存储介质",
    "inv": [
      "黄鹏程"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109325494A",
    "apn": "CN201810980841.1",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "图片处理方法、任务数据处理方法和装置",
    "inv": [
      "辛遥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109343920A",
    "apn": "CN201811053229.6",
    "apd": "2018-09-10",
    "apy": "2018",
    "tit": "一种图像处理方法及其装置、设备和存储介质",
    "inv": [
      "李旭冬",
      "黄盈",
      "周大军",
      "张力柯",
      "丁熠"
    ],
    "app": [
      "深圳市腾讯网络信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "WO2019057169A1",
    "apn": "PCT/CN2018/107032",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "文本检测方法、存储介质和计算机设备",
    "inv": [
      "刘铭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110163198A",
    "apn": "CN201811130207.5",
    "apd": "2018-09-27",
    "apy": "2018",
    "tit": "一种表格识别重建方法、装置和存储介质",
    "inv": [
      "柯戈扬",
      "林汉权"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表格",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109271967A",
    "apn": "CN201811202558.2",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "图像中文本的识别方法及装置、电子设备、存储介质",
    "inv": [
      "刘铭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109886330A",
    "apn": "CN201910120290.6",
    "apd": "2019-02-18",
    "apy": "2019",
    "tit": "文本检测方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "田倬韬",
      "李睿宇",
      "贾佳亚",
      "戴宇荣",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109933756A",
    "apn": "CN201910224228.1",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "基于OCR的图像转档方法、装置、设备及可读存储介质",
    "inv": [
      "陈星耀",
      "黄灿芦",
      "胡文灿",
      "陈贻东",
      "林汉权",
      "黄飞",
      "柯戈扬",
      "杨志权"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110059685A",
    "apn": "CN201910344670.8",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "文字区域检测方法、装置及存储介质",
    "inv": [
      "吕鹏原",
      "贾佳亚",
      "戴宇荣",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN109977956A",
    "apn": "CN201910356240.8",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "一种图像处理方法、装置、电子设备以及存储介质",
    "inv": [
      "徐培",
      "黄珊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110163121A",
    "apn": "CN201910360905.2",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机设备及存储介质",
    "inv": [
      "缪畅宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110321892A",
    "apn": "CN201910482362.1",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "一种图片筛选方法、装置及电子设备",
    "inv": [
      "殷飞",
      "王瑞琪",
      "刘成林",
      "刘龙",
      "薛涛",
      "李玉梅"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院自动化研究所"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110378249A",
    "apn": "CN201910568629.9",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "文本图像倾斜角度识别方法、装置及设备",
    "inv": [
      "唐景群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110378338A",
    "apn": "CN201910625593.3",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "一种文本识别方法、装置、电子设备和存储介质",
    "inv": [
      "王斌",
      "王世卿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110378287A",
    "apn": "CN201910652827.3",
    "apd": "2019-07-19",
    "apy": "2019",
    "tit": "文档方向识别方法、装置及存储介质",
    "inv": [
      "龚星",
      "李斌",
      "郭双双",
      "周易"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110377914A",
    "apn": "CN201910677203.7",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "字符识别方法、装置及存储介质",
    "inv": [
      "李原野",
      "季成晖",
      "卢俊之"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110378318A",
    "apn": "CN201910696236.6",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "文字识别方法、装置、计算机设备及存储介质",
    "inv": [
      "李原野",
      "季成晖",
      "卢俊之"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "腾讯",
    "pun": "CN110489578A",
    "apn": "CN201910740483.1",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "图片处理方法、装置及计算机设备",
    "inv": [
      "王泽一",
      "谭悦伟",
      "文孝木",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化/生活",
    "l2": "聊天/社交",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN102622568B",
    "apn": "CN201110031195.2",
    "apd": "2011-01-28",
    "apy": "2011",
    "tit": "订单文件中订单标识信息的识别方法、系统及装置",
    "inv": [
      "陈强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "账单/订单",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN102682007A",
    "apn": "CN201110059265.5",
    "apd": "2011-03-11",
    "apy": "2011",
    "tit": "创建图像数据库的方法和装置",
    "inv": [
      "王永攀",
      "谢丁杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN102902947B",
    "apn": "CN201110212078.6",
    "apd": "2011-07-27",
    "apy": "2011",
    "tit": "一种图像识别显示方法、装置及用户设备",
    "inv": [
      "蔡景助"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103455816B",
    "apn": "CN201210169811.5",
    "apd": "2012-05-28",
    "apy": "2012",
    "tit": "一种笔画宽度提取方法、装置及一种文字识别方法、系统",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103577817B",
    "apn": "CN201210258883.7",
    "apd": "2012-07-24",
    "apy": "2012",
    "tit": "表单识别方法与装置",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表单",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103577475B",
    "apn": "CN201210276320.0",
    "apd": "2012-08-03",
    "apy": "2012",
    "tit": "一种图片自动化分类方法、图片处理方法及其装置",
    "inv": [
      "贾梦雷",
      "王永攀",
      "郑琪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103593666B",
    "apn": "CN201210287521.0",
    "apd": "2012-08-13",
    "apy": "2012",
    "tit": "一种图像识别方法、过滤方法及相关装置",
    "inv": [
      "贾梦雷",
      "王永攀",
      "郑琪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103593642A",
    "apn": "CN201210293138.6",
    "apd": "2012-08-16",
    "apy": "2012",
    "tit": "一种卡信息获取方法和系统",
    "inv": [
      "楚汝峰",
      "张伦",
      "张春晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103632159B",
    "apn": "CN201210303844.4",
    "apd": "2012-08-23",
    "apy": "2012",
    "tit": "训练分类器、图像中文字区域检测的方法及系统",
    "inv": [
      "邓宇",
      "陈艳琴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103678976A",
    "apn": "CN201210348277.4",
    "apd": "2012-09-18",
    "apy": "2012",
    "tit": "一种通过手写输入设备认证身份的方法和手写输入设备",
    "inv": [
      "栗志果"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103679164A",
    "apn": "CN201210360774.6",
    "apd": "2012-09-21",
    "apy": "2012",
    "tit": "一种基于移动终端的标志识别处理方法和系统",
    "inv": [
      "张伦",
      "楚汝峰",
      "张春晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103729638B",
    "apn": "CN201210386977.2",
    "apd": "2012-10-12",
    "apy": "2012",
    "tit": "一种文字区域识别中的文字行排列分析方法和装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103810488B",
    "apn": "CN201210448102.0",
    "apd": "2012-11-09",
    "apy": "2012",
    "tit": "图像特征提取方法、图像搜索方法、服务器、终端及系统",
    "inv": [
      "段曼妮",
      "王从德",
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN103839060B",
    "apn": "CN201210486972.7",
    "apd": "2012-11-26",
    "apy": "2012",
    "tit": "一种单字区域的合并方法和装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107122778A",
    "apn": "CN201710112654.7",
    "apd": "2012-11-26",
    "apy": "2012",
    "tit": "一种单字区域的合并方法和装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104281831B",
    "apn": "CN201310274847.4",
    "apd": "2013-07-02",
    "apy": "2013",
    "tit": "一种笔迹验证的方法和装置",
    "inv": [
      "曹恺"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104281626B",
    "apn": "CN201310293720.7",
    "apd": "2013-07-12",
    "apy": "2013",
    "tit": "基于图片化处理的网页展示方法及网页展示装置",
    "inv": [
      "陈俊文",
      "潘俊俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104346370B",
    "apn": "CN201310328673.5",
    "apd": "2013-07-31",
    "apy": "2013",
    "tit": "图像搜索、获取图像文本信息的方法及装置",
    "inv": [
      "刘瑞涛",
      "张洪明",
      "茹新峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104346609B",
    "apn": "CN201310331468.4",
    "apd": "2013-08-01",
    "apy": "2013",
    "tit": "一种识别印刷品上字符的方法及装置",
    "inv": [
      "侯放"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104517094A",
    "apn": "CN201310461377.2",
    "apd": "2013-09-30",
    "apy": "2013",
    "tit": "一种身份验证方法和身份验证装置",
    "inv": [
      "曹恺",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104679538A",
    "apn": "CN201310626184.8",
    "apd": "2013-11-28",
    "apy": "2013",
    "tit": "一种利用笔迹来执行操作的方法和客户端",
    "inv": [
      "曹恺",
      "张洁",
      "张小栋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104750663B",
    "apn": "CN201310737443.4",
    "apd": "2013-12-27",
    "apy": "2013",
    "tit": "页面中文本乱码的识别方法及装置",
    "inv": [
      "丁世远"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104899586B",
    "apn": "CN201410075157.0",
    "apd": "2014-03-03",
    "apy": "2014",
    "tit": "对图像中包含的文字内容进行识别方法及装置",
    "inv": [
      "陈宇",
      "张洪明",
      "茹新峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104933068A",
    "apn": "CN201410103798.2",
    "apd": "2014-03-19",
    "apy": "2014",
    "tit": "一种信息搜索的方法和装置",
    "inv": [
      "杜志军",
      "张宇",
      "王卫星",
      "秦剑"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104951429A",
    "apn": "CN201410117009.0",
    "apd": "2014-03-26",
    "apy": "2014",
    "tit": "版式电子文档的页眉页脚识别方法及装置",
    "inv": [
      "吴运俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "文档整理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104951741A",
    "apn": "CN201410127438.6",
    "apd": "2014-03-31",
    "apy": "2014",
    "tit": "一种文字识别方法及装置",
    "inv": [
      "张宇",
      "杜志军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN104978576B",
    "apn": "CN201410131536.7",
    "apd": "2014-04-02",
    "apy": "2014",
    "tit": "一种文字识别方法及装置",
    "inv": [
      "杜志军",
      "张宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105095826B",
    "apn": "CN201410156083.3",
    "apd": "2014-04-17",
    "apy": "2014",
    "tit": "一种文字识别方法及装置",
    "inv": [
      "张宇",
      "杜志军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105095900A",
    "apn": "CN201410183623.7",
    "apd": "2014-05-04",
    "apy": "2014",
    "tit": "一种提取标准卡片中特定信息的方法和装置",
    "inv": [
      "周士奇",
      "楚汝锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105095272B",
    "apn": "CN201410198155.0",
    "apd": "2014-05-12",
    "apy": "2014",
    "tit": "基于图像识别的问答处理方法、装置及系统",
    "inv": [
      "阮征"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "特定场景",
    "l2": "智能客服",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105095842B",
    "apn": "CN201410219239.8",
    "apd": "2014-05-22",
    "apy": "2014",
    "tit": "一种单据的信息识别的方法和装置",
    "inv": [
      "冯照临",
      "刘中胜"
    ],
    "app": [
      "口碑控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105303189B",
    "apn": "CN201410367807.9",
    "apd": "2014-07-29",
    "apy": "2014",
    "tit": "一种用于检测预定区域中特定标识图像的方法及装置",
    "inv": [
      "程刚",
      "楚汝峰",
      "张伦"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105335689A",
    "apn": "CN201410384373.3",
    "apd": "2014-08-06",
    "apy": "2014",
    "tit": "字符识别方法和装置",
    "inv": [
      "张宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105447006B",
    "apn": "CN201410389892.9",
    "apd": "2014-08-08",
    "apy": "2014",
    "tit": "一种图片选择方法及其装置",
    "inv": [
      "郭家宏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "图片检索",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105450411B",
    "apn": "CN201410398375.8",
    "apd": "2014-08-14",
    "apy": "2014",
    "tit": "利用卡片特征进行身份验证的方法、装置及系统",
    "inv": [
      "金伟安",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105354480B",
    "apn": "CN201410411703.3",
    "apd": "2014-08-20",
    "apy": "2014",
    "tit": "验证码图片生成方法及装置",
    "inv": [
      "姜俊",
      "王兴勇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105447708A",
    "apn": "CN201410433045.8",
    "apd": "2014-08-28",
    "apy": "2014",
    "tit": "一种信息提供方法及装置",
    "inv": [
      "杜志军",
      "张宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105447433A",
    "apn": "CN201410440783.5",
    "apd": "2014-09-01",
    "apy": "2014",
    "tit": "一种注册身份的方法及装置",
    "inv": [
      "宋雪梅",
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105404624A",
    "apn": "CN201410441700.4",
    "apd": "2014-09-01",
    "apy": "2014",
    "tit": "一种汉字识别方法、装置及终端",
    "inv": [
      "吴尚智"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105528610B",
    "apn": "CN201410519982.5",
    "apd": "2014-09-30",
    "apy": "2014",
    "tit": "文字识别方法和装置",
    "inv": [
      "杜志军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105574038B",
    "apn": "CN201410549089.7",
    "apd": "2014-10-16",
    "apy": "2014",
    "tit": "基于反识别渲染的文本内容识别率测试方法及装置",
    "inv": [
      "张翔"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105631393A",
    "apn": "CN201410638602.X",
    "apd": "2014-11-06",
    "apy": "2014",
    "tit": "信息识别方法及装置",
    "inv": [
      "张宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105678126A",
    "apn": "CN201410670575.4",
    "apd": "2014-11-20",
    "apy": "2014",
    "tit": "笔迹处理方法和装置",
    "inv": [
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105701391B",
    "apn": "CN201410709628.9",
    "apd": "2014-11-27",
    "apy": "2014",
    "tit": "动态验证码图片生成方法及装置",
    "inv": [
      "姚俊旻"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105740876B",
    "apn": "CN201410758179.7",
    "apd": "2014-12-10",
    "apy": "2014",
    "tit": "一种图像预处理方法及装置",
    "inv": [
      "丁威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105868590B",
    "apn": "CN201510025759.X",
    "apd": "2015-01-19",
    "apy": "2015",
    "tit": "一种笔迹数据处理方法和装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "手写/笔迹",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105868758A",
    "apn": "CN201510030520.1",
    "apd": "2015-01-21",
    "apy": "2015",
    "tit": "图像中文本区域检测方法、装置及电子设备",
    "inv": [
      "陈益如",
      "何源",
      "何梦超",
      "童志军",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105868759A",
    "apn": "CN201510031629.7",
    "apd": "2015-01-22",
    "apy": "2015",
    "tit": "分割图像字符的方法及装置",
    "inv": [
      "王楠",
      "杜志军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2015120772A1",
    "apn": "PCT/CN2015/072115",
    "apd": "2015-02-02",
    "apy": "2015",
    "tit": "一种计算商品图像牛皮癣分值的方法和装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司",
      "郑琪",
      "王永攀"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105988991B",
    "apn": "CN201510088920.8",
    "apd": "2015-02-26",
    "apy": "2015",
    "tit": "一种姓氏所属语种的识别方法、装置及服务器",
    "inv": [
      "顾思源"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN105988989B",
    "apn": "CN201510088804.6",
    "apd": "2015-02-26",
    "apy": "2015",
    "tit": "一种汉语姓氏的识别方法、装置及服务器",
    "inv": [
      "顾思源"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106157348A",
    "apn": "CN201510146211.0",
    "apd": "2015-03-30",
    "apy": "2015",
    "tit": "生成验证码图片的方法及装置、校验验证码的方法及装置",
    "inv": [
      "庞磊",
      "杜志军",
      "王楠"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106202086A",
    "apn": "CN201510221512.5",
    "apd": "2015-05-04",
    "apy": "2015",
    "tit": "一种图片处理、获取方法、装置及系统",
    "inv": [
      "刘奎龙",
      "李波",
      "黄凯明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106204690A",
    "apn": "CN201510222313.6",
    "apd": "2015-05-04",
    "apy": "2015",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "刘奎龙",
      "李波",
      "黄凯明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "广告",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106295627A",
    "apn": "CN201510239889.3",
    "apd": "2015-05-12",
    "apy": "2015",
    "tit": "用于识别文字牛皮癣图片的方法及装置",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106294527A",
    "apn": "CN201510363624.4",
    "apd": "2015-06-26",
    "apy": "2015",
    "tit": "一种信息推荐方法和设备",
    "inv": [
      "屈琳雅"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2016015548A1",
    "apn": "PCT/CN2015/083277",
    "apd": "2015-07-03",
    "apy": "2015",
    "tit": "一种用于检测预定区域中特定标识图像的方法及装置",
    "inv": [
      "程刚",
      "楚汝峰",
      "张伦"
    ],
    "app": [
      "阿里巴巴集团控股有限公司",
      "程刚",
      "楚汝峰",
      "张伦"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106339661A",
    "apn": "CN201510425043.9",
    "apd": "2015-07-17",
    "apy": "2015",
    "tit": "一种用于检测图像中文本区域的方法及装置",
    "inv": [
      "刘彬",
      "刘扬",
      "孙佰贵",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106445908B",
    "apn": "CN201510484785.9",
    "apd": "2015-08-07",
    "apy": "2015",
    "tit": "文本识别方法和装置",
    "inv": [
      "王彦锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2016034066A1",
    "apn": "PCT/CN2015/088212",
    "apd": "2015-08-27",
    "apy": "2015",
    "tit": "一种汉字识别方法、装置及终端",
    "inv": [
      "吴尚智"
    ],
    "app": [
      "阿里巴巴集团控股有限公司",
      "吴尚智"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106529380A",
    "apn": "CN201510587818.2",
    "apd": "2015-09-15",
    "apy": "2015",
    "tit": "图像的识别方法及装置",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106528581B",
    "apn": "CN201510587677.4",
    "apd": "2015-09-15",
    "apy": "2015",
    "tit": "文本检测方法及装置",
    "inv": [
      "王彦锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106569790A",
    "apn": "CN201510657983.0",
    "apd": "2015-10-13",
    "apy": "2015",
    "tit": "卡片模板生成方法、服务提供方法、信息提供方法及装置",
    "inv": [
      "章向明",
      "刘春龙",
      "杨如婷",
      "曾文昌",
      "陈皓",
      "王功宇",
      "姚涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "卡片/名片",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106598937B",
    "apn": "CN201510672933.X",
    "apd": "2015-10-16",
    "apy": "2015",
    "tit": "用于文本的语种识别方法、装置和电子设备",
    "inv": [
      "蒋宏飞",
      "骆卫华",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106708798A",
    "apn": "CN201510784681.X",
    "apd": "2015-11-16",
    "apy": "2015",
    "tit": "一种字符串切分方法及装置",
    "inv": [
      "肖荣"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106845323A",
    "apn": "CN201510883300.3",
    "apd": "2015-12-03",
    "apy": "2015",
    "tit": "一种打标数据的收集方法、装置以及证件识别系统",
    "inv": [
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106845880A",
    "apn": "CN201510882629.8",
    "apd": "2015-12-03",
    "apy": "2015",
    "tit": "运单号归属的识别方法及装置",
    "inv": [
      "王秋子"
    ],
    "app": [
      "菜鸟智能物流控股有限公司"
    ],
    "l1": "交通/物流",
    "l2": "物流",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107133622A",
    "apn": "CN201610112463.6",
    "apd": "2016-02-29",
    "apy": "2016",
    "tit": "一种单词的分割方法和装置",
    "inv": [
      "周文猛",
      "程孟力",
      "毛旭东",
      "施兴",
      "褚崴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107229932A",
    "apn": "CN201610179262.8",
    "apd": "2016-03-25",
    "apy": "2016",
    "tit": "一种图像文本的识别方法和装置",
    "inv": [
      "毛旭东",
      "施兴",
      "褚崴",
      "程孟力",
      "周文猛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107305696A",
    "apn": "CN201610257327.6",
    "apd": "2016-04-22",
    "apy": "2016",
    "tit": "一种图像生成方法及装置",
    "inv": [
      "万朝新"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "验证码",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107516096A",
    "apn": "CN201610425396.3",
    "apd": "2016-06-15",
    "apy": "2016",
    "tit": "一种字符识别方法及装置",
    "inv": [
      "周文猛",
      "施兴",
      "褚崴",
      "程孟力",
      "毛旭东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107590491A",
    "apn": "CN201610533632.3",
    "apd": "2016-07-07",
    "apy": "2016",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN106909270A",
    "apn": "CN201610576551.1",
    "apd": "2016-07-20",
    "apy": "2016",
    "tit": "聊天数据输入方法、装置及通讯终端",
    "inv": [
      "金雯彬"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "聊天/社交",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107657251A",
    "apn": "CN201610599557.0",
    "apd": "2016-07-26",
    "apy": "2016",
    "tit": "确定身份证件显示表面的设备及方法，图像识别方法",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107766853A",
    "apn": "CN201610675750.8",
    "apd": "2016-08-16",
    "apy": "2016",
    "tit": "一种图像的文本信息的生成、显示方法及电子设备",
    "inv": [
      "赵小伟",
      "孙佰贵",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107766349A",
    "apn": "CN201610675834.1",
    "apd": "2016-08-16",
    "apy": "2016",
    "tit": "一种生成文本的方法、装置、设备及客户端",
    "inv": [
      "赵小伟",
      "孙佰贵",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107784316A",
    "apn": "CN201610744133.9",
    "apd": "2016-08-26",
    "apy": "2016",
    "tit": "一种图像识别方法、装置、系统和计算设备",
    "inv": [
      "曹恩华",
      "张帆",
      "易东",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "文化教育",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2017148265A1",
    "apn": "PCT/CN2017/073718",
    "apd": "2017-02-16",
    "apy": "2017",
    "tit": "一种单词的分割方法和装置",
    "inv": [
      "周文猛",
      "程孟力",
      "毛旭东",
      "施兴",
      "褚崴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像切分",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN108470172A",
    "apn": "CN201710099305.6",
    "apd": "2017-02-23",
    "apy": "2017",
    "tit": "一种文本信息识别方法及装置",
    "inv": [
      "陈凯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN108573255A",
    "apn": "CN201710146424.2",
    "apd": "2017-03-13",
    "apy": "2017",
    "tit": "文字合成图像的识别方法及装置、图像识别方法",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2017162069A1",
    "apn": "PCT/CN2017/076548",
    "apd": "2017-03-14",
    "apy": "2017",
    "tit": "一种图像文本的识别方法和装置",
    "inv": [
      "毛旭东",
      "施兴",
      "褚崴",
      "程孟力",
      "周文猛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "版面分析",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN108875743A",
    "apn": "CN201710337521.X",
    "apd": "2017-05-15",
    "apy": "2017",
    "tit": "一种文本识别方法及装置",
    "inv": [
      "王凯",
      "毛仁歆"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109213859A",
    "apn": "CN201710549655.8",
    "apd": "2017-07-07",
    "apy": "2017",
    "tit": "一种文本检测方法、装置及系统",
    "inv": [
      "汤佳宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109299798A",
    "apn": "CN201710614359.1",
    "apd": "2017-07-25",
    "apy": "2017",
    "tit": "行程信息的处理方法、装置及电子设备",
    "inv": [
      "李超"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107451106A",
    "apn": "CN201710618374.3",
    "apd": "2017-07-26",
    "apy": "2017",
    "tit": "文本纠正方法及装置、电子设备",
    "inv": [
      "陈永环"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "识别后处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107610091A",
    "apn": "CN201710640784.8",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "车险图像处理方法、装置、服务器及系统",
    "inv": [
      "侯金龙",
      "章海涛",
      "郭昕",
      "徐娟",
      "王剑",
      "程远",
      "程丹妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109426759A",
    "apn": "CN201710722477.4",
    "apd": "2017-08-22",
    "apy": "2017",
    "tit": "物品的可视化文件归档的方法、装置和电子设备",
    "inv": [
      "郑丹丹",
      "吴燕萍",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107679457A",
    "apn": "CN201710795160.3",
    "apd": "2017-09-06",
    "apy": "2017",
    "tit": "用户身份校验方法及装置",
    "inv": [
      "徐崴",
      "郑丹丹",
      "李静",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107742096A",
    "apn": "CN201710885003.1",
    "apd": "2017-09-26",
    "apy": "2017",
    "tit": "获取图表特征信息的方法及装置、电子设备、存储介质",
    "inv": [
      "章伟星"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表单",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110019790A",
    "apn": "CN201710927247.1",
    "apd": "2017-10-09",
    "apy": "2017",
    "tit": "文本识别、文本监控、数据对象识别、数据处理方法",
    "inv": [
      "王慧琼"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109657673A",
    "apn": "CN201710943489.X",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "图像识别方法和终端",
    "inv": [
      "薛琴",
      "王炎",
      "郭山",
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107729847A",
    "apn": "CN201710985755.5",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107944339A",
    "apn": "CN201710986508.7",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN107832679A",
    "apn": "CN201710984690.2",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109753971A",
    "apn": "CN201711078947.4",
    "apd": "2017-11-06",
    "apy": "2017",
    "tit": "扭曲文字行的矫正方法及装置、字符识别方法及装置",
    "inv": [
      "程孟力",
      "施兴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN108133207A",
    "apn": "CN201711192125.9",
    "apd": "2017-11-24",
    "apy": "2017",
    "tit": "辅助物品的图像合规的方法、装置和电子设备",
    "inv": [
      "徐崴",
      "郑丹丹",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN108153468A",
    "apn": "CN201711338701.6",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "图片处理方法及装置",
    "inv": [
      "郭一贤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109961068A",
    "apn": "CN201711432567.6",
    "apd": "2017-12-26",
    "apy": "2017",
    "tit": "图像识别、训练、搜索方法和装置及设备、介质",
    "inv": [
      "曹恩华",
      "易东",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "模型训练",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN108510310A",
    "apn": "CN201810164678.1",
    "apd": "2018-02-27",
    "apy": "2018",
    "tit": "租赁方法及装置",
    "inv": [
      "陈枫",
      "郭玉锋",
      "李瓛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110390641A",
    "apn": "CN201810339029.0",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "图像脱敏方法、电子设备及存储介质",
    "inv": [
      "曹柯",
      "邱俊",
      "李伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110390332A",
    "apn": "CN201810344756.6",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种类别确定方法、装置及设备",
    "inv": [
      "梁奇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "票据/发票",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109087368A",
    "apn": "CN201810614057.9",
    "apd": "2018-06-14",
    "apy": "2018",
    "tit": "一种字符串图形化方法和装置",
    "inv": [
      "游永胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109033224A",
    "apn": "CN201810713229.8",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "一种风险文本识别方法和装置",
    "inv": [
      "周书恒",
      "祝慧佳",
      "赵智源"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "聊天/社交",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109255356A",
    "apn": "CN201810818770.5",
    "apd": "2018-07-24",
    "apy": "2018",
    "tit": "一种文字识别方法、装置及计算机可读存储介质",
    "inv": [
      "魏袁新"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109240687A",
    "apn": "CN201810863629.7",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "图形界面描述文件的生成方法及装置",
    "inv": [
      "贺三元",
      "黄建锋",
      "张桀宁",
      "钱毅",
      "刘国良",
      "钱鸿强"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109117228A",
    "apn": "CN201810863639.0",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "图形界面的生成方法及装置",
    "inv": [
      "贺三元",
      "黄建锋",
      "张桀宁",
      "钱毅",
      "刘国良",
      "钱鸿强"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2019076114A1",
    "apn": "PCT/CN2018/099915",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司",
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2019076115A1",
    "apn": "PCT/CN2018/099933",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司",
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109325414A",
    "apn": "CN201810947885.4",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "证件信息的提取方法、装置和文本信息的提取方法",
    "inv": [
      "邱怡琳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109242473A",
    "apn": "CN201811010240.4",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "一种支付方法、装置及电子设备",
    "inv": [
      "杨磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109598185A",
    "apn": "CN201811027649.7",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "图像识别翻译方法、装置、设备及可读存储介质",
    "inv": [
      "徐崴",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109389038A",
    "apn": "CN201811025620.5",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "一种信息的检测方法、装置及设备",
    "inv": [
      "郑丹丹"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109271910A",
    "apn": "CN201811025938.3",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "一种文字识别、文字翻译方法和装置",
    "inv": [
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "语种识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "WO2019100814A1",
    "apn": "PCT/CN2018/104925",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "辅助物品的图像合规的方法、装置和电子设备",
    "inv": [
      "徐崴",
      "郑丹丹",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109325489A",
    "apn": "CN201811075410.7",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "图像的识别方法及装置、存储介质、电子装置",
    "inv": [
      "贺三元",
      "黄建锋",
      "罗晗璐",
      "张桀宁"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109493285A",
    "apn": "CN201811086909.8",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "基于众包的图像处理方法、装置、服务器及存储介质",
    "inv": [
      "任望",
      "韩冬",
      "张云龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109447086A",
    "apn": "CN201811095558.7",
    "apd": "2018-09-19",
    "apy": "2018",
    "tit": "一种图片文字颜色的提取方法及装置",
    "inv": [
      "贺三元",
      "张桀宁",
      "黄建锋",
      "罗晗璐"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109284712A",
    "apn": "CN201811099695.8",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "商品信息的配置方法及装置",
    "inv": [
      "蒋尉",
      "罗旋",
      "郑元拢",
      "刘盛兰"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "商业/商务",
    "l2": "商品识别",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110032503A",
    "apn": "CN201811307223.7",
    "apd": "2018-11-05",
    "apy": "2018",
    "tit": "基于UI自动化和OCR的数据处理系统、方法、设备和装置",
    "inv": [
      "张炳淑",
      "王京",
      "孙宏全",
      "崔再喜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "特定场景",
    "l2": "截屏识文",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109542562A",
    "apn": "CN201811333886.6",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "界面图片的识别方法及装置",
    "inv": [
      "贺三元"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109598138A",
    "apn": "CN201811361542.6",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "图片中敏感信息识别、掩盖和感知是否查看的方法及装置",
    "inv": [
      "吉亚霖",
      "成强",
      "贾茜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110032920A",
    "apn": "CN201811425887.3",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "文字识别匹配方法、设备和装置",
    "inv": [
      "曹绍升",
      "赵婷婷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110046085A",
    "apn": "CN201811468430.0",
    "apd": "2018-12-03",
    "apy": "2018",
    "tit": "识别终端设备上显示的应用程序控件的方法及装置",
    "inv": [
      "黄伟东",
      "李佳楠",
      "孙震",
      "陈忻",
      "张新琛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110019817A",
    "apn": "CN201811473997.7",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "一种视频中文字信息的检测方法、装置及电子设备",
    "inv": [
      "曹绍升",
      "孙晓军",
      "周俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "敏感信息",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110008664A",
    "apn": "CN201811473775.5",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "认证信息采集、开户方法、装置及电子设备",
    "inv": [
      "管维刚",
      "钱佳景"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110008807A",
    "apn": "CN201811567291.7",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "一种合同内容识别模型的训练方法、装置及设备",
    "inv": [
      "张林江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110008809A",
    "apn": "CN201910006706.1",
    "apd": "2019-01-04",
    "apy": "2019",
    "tit": "表格数据的获取方法、装置和服务器",
    "inv": [
      "张林江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "图表/表单",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110020424A",
    "apn": "CN201910006732.4",
    "apd": "2019-01-04",
    "apy": "2019",
    "tit": "合同信息的提取方法、装置和文本信息的提取方法",
    "inv": [
      "余红",
      "张林江",
      "游紫微",
      "梁山雪",
      "胡伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "合同/印章",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109934218A",
    "apn": "CN201910059361.6",
    "apd": "2019-01-22",
    "apy": "2019",
    "tit": "一种针对物流单图像的识别方法及装置",
    "inv": [
      "陈新华"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "交通/物流",
    "l2": "物流",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN109903172A",
    "apn": "CN201910097463.7",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "理赔信息提取方法和装置、电子设备",
    "inv": [
      "吴博坤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110008943A",
    "apn": "CN201910110413.8",
    "apd": "2019-02-11",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、一种计算设备及存储介质",
    "inv": [
      "徐崴",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110020645A",
    "apn": "CN201910110414.2",
    "apd": "2019-02-11",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、一种计算设备及存储介质",
    "inv": [
      "徐崴",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110046621A",
    "apn": "CN201910122471.2",
    "apd": "2019-02-19",
    "apy": "2019",
    "tit": "证件识别方法及装置",
    "inv": [
      "周志"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/文档",
    "l2": "证件",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110059563A",
    "apn": "CN201910208026.8",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "一种文本处理方法和装置",
    "inv": [
      "余红"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110020615A",
    "apn": "CN201910214785.5",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "对图片进行文字提取及内容识别的方法和系统",
    "inv": [
      "陈知己"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110163203A",
    "apn": "CN201910282238.0",
    "apd": "2019-04-09",
    "apy": "2019",
    "tit": "字符识别方法、装置、存储介质及计算机设备",
    "inv": [
      "贺三元"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "特征提取及匹配",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110175609A",
    "apn": "CN201910322717.0",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "界面元素检测方法、装置及设备",
    "inv": [
      "孙震",
      "陈忻",
      "黄伟东",
      "张新琛",
      "任皓天"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化/生活",
    "l2": "网页/界面",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110334590A",
    "apn": "CN201910441193.7",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "图像采集引导方法以及装置",
    "inv": [
      "葛官法",
      "朱世艾",
      "刘修岑"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110309821A",
    "apn": "CN201910442034.9",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "图像采集检测方法以及装置",
    "inv": [
      "葛官法",
      "朱世艾",
      "刘修岑"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110349033A",
    "apn": "CN201910464468.9",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "项目案件初审方法以及装置",
    "inv": [
      "胡越",
      "陈景东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "商业/商务",
    "l2": "保险",
    "tech": ""
  },
  {
    "entity": "阿里",
    "pun": "CN110399872A",
    "apn": "CN201910539227.6",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "图像处理方法以及装置",
    "inv": [
      "梁山雪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像预处理",
    "tech": ""
  }
];
