import React from 'react';

import './patents.less';

function PatentItem(props) {

  const {patent} = props;

  return (
    <div className={'patent'}>
      <div className={'patent_tit'}>
        <a href={`http://bift.vinhouse.com/pdf/${patent.pun}.pdf`} target={'_blank'}>{patent.tit}</a>
      </div>
      <div className={'patent_info'}>
        {`${patent.apd} ${patent.apn}`}
      </div>
      <div className={'patent_l1'}>
        {patent.l1}
      </div>
      <div className={'patent_inv'}>
        { patent.inv.map((inv, idx) => <span key={`inv_${idx}`}>{inv}</span>) }
      </div>
      <div className={'patent_app'}>
        { patent.app.map((app, idx) => <span key={`app_${idx}`}>{app}</span>) }
      </div>
    </div>
  );
}

export default PatentItem;
