import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';

import {data} from './visual_patents';

import './patents.less';
import {Empty, List, Select} from 'antd';
import PatentItem from './PatentItem';


function VisualEvolution(props) {

  const canvasRef = useRef(null);
  const svgRef = useRef(null);
  const [entity, setEntity] = useState('');
  const [tag, setTag] = useState('');
  const [apy, setApy] = useState(0);

  const entities = {};
  const startYear = 2010;
  const endYear = 2019;

  const ds = Object.values(data.reduce((acc, cur, idx) => {
    if (cur.apy < startYear) {
      return acc;
    }

    if (entities.hasOwnProperty(cur.entity)) {
      entities[cur.entity].count += 1;
    } else {
      entities[cur.entity] = {entity: cur.entity, count: 1};
    }
    const key = `${cur.entity}_${cur.apy}_${cur.l1}`;
    if (acc.hasOwnProperty(key)) {
      acc[key].count += 1;
    } else {
      acc[key] = {entity: cur.entity, apy: cur.apy, l1: cur.l1, count: 1}
    }
    return acc;
  }, {}));

  useEffect(() => {

    const width = canvasRef.current.scrollWidth;
    const height = canvasRef.current.scrollHeight;

    const hMargin = 0;
    const vMargin = 0;

    const plotWidth = width - hMargin * 2;
    const plotHeight = height - vMargin * 2;

    if (svgRef.current === null) {
      svgRef.current = d3.select(canvasRef.current)
        .append('svg')
        // .style('border', '1px solid red')
        .attr('width', plotWidth)
        .attr('height', plotHeight);

      svgRef.current.append('g')
        .attr('class', 'plot')
        .attr('width', plotWidth)
        .attr('height', plotHeight);
    }

    d3.selectAll('.plot > *').remove();

    const graph = svgRef.current.select('.plot');
    const nodesGroup = graph.append('g').attr('class', 'nodes');

    // Process

    if (entity === '') {
      return;
    }

    let year = startYear;

    const entityDS = ds.filter(d => d.entity === entity && d.apy >= startYear);

    const radiusScale = d3.scaleSqrt().domain([1, 200]).range([10, 100]);
    // const colorScale = d3.scaleSequential(d3.interpolatePiYG).domain([2, 0]);
    const colorScale = d3.scaleSequential(d3.interpolatePiYG).domain([2, 1]);

    const getNodes = year => Object.values(
      entityDS.filter(d => d.apy <= year).reduce((acc, cur) => {
        if (acc.hasOwnProperty(cur.l1)) {
          acc[cur.l1].count += cur.count;
        } else {
          acc[cur.l1] = {l1: cur.l1, count: cur.count};
        }
        return acc;
      }, {})
    );

    let nodes = getNodes(year);

    const label = graph.append('text')
      .attr('class', 'year label')
      .attr('text-anchor', 'end')
      .attr('x', plotWidth - 20)
      .attr('y', plotHeight - 20)
      .text(year);

    graph.append('text')
      .attr('class', 'company label')
      .attr('text-anchor', 'end')
      .attr('x', plotWidth - 400)
      .attr('y', plotHeight - 30)
      .text(entity);

    // Force

    const ticked = () => {
      graph.selectAll('.node').attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });
    };

    const force = d3.forceSimulation(nodes)
      .force('charge', d3.forceManyBody().strength(50))
      .force('collide', d3.forceCollide().radius(d => radiusScale(d.count) + 10))
      .force('x', d3.forceX(plotWidth / 2))
      .force('y', d3.forceY(plotHeight / 2))
      .force('center', d3.forceCenter().x(plotWidth / 2).y(plotHeight / 2))
      .on('tick', ticked);

    // Nodes

    const displayYear = (year) => {
      label.text(year);

      let nodesPrev = [...nodes];
      nodes = getNodes(year);

      const group = nodesGroup.selectAll('g').data(nodes, d => d.l1);

      // Exit

      group.exit().remove();

      // Enter

      const enterGroup = group.enter();

      const node = enterGroup.append('g')
        .attr('class', 'node')
        .attr('transform', `translate(${plotWidth / 2}, ${plotHeight / 2})`);

      node.append('circle')
        .attr('r', d => radiusScale(d.count))
        .attr('fill', '#CC0000')
        .attr('opacity', .8);

      node.append('text')
        .attr('text-anchor', 'middle')
        .attr('x', 0)
        .attr('y', 3)
        .attr('font', 'Roboto')
        .attr('fill', '#000000')
        .text(d => `${d.l1}: ${d.count}`);

      // Update

      group.select('circle')
        .transition(2000)
        .ease(d3.easeQuad)
        .attr('r', d => radiusScale(d.count))
        .attr('fill', d => colorScale(d.count / nodesPrev.find(item => item.l1 === d.l1).count));

      group.select('text')
        .transition(2000)
        .ease(d3.easeQuad)
        .text(d => `${d.l1}: ${d.count}`);

      // Merge

      node.merge(group)
        .on('click', d => {
          setApy(year);
          setTag(d.l1);
        });

      const dragstarted = d => {
        d3.event.sourceEvent.stopPropagation();
        if (!d3.event.active) force.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      };

      const dragged = d => {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      };

      const dragended = d => {
        if (!d3.event.active) force.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      };

      node.call(
        d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );

      force.nodes(nodes);
      force.alpha(0.3).restart();
    };

    displayYear(year);

    const t = d3.interval(function (elapsed) {
      year += 1;
      if (year > endYear) {
        t.stop();
        return;
      }
      displayYear(year);
    }, 3000);

    return () => {
      t.stop()
    }

  }, [entity]);

  const patents = entity === '' || tag === '' || apy === 0 ? [] :
    data.filter(item => item.entity === entity && item.apy <= apy && item.apy >= startYear && item.l1 === tag).sort((a, b) => b.apd.localeCompare(b.apd));;

  return (
    <>
      <div className={'wrapper'}>
        <div className={'main'}>
          <div className={'canvas'} ref={canvasRef}/>
          <div className={'content'}>
            <div className={'title'}>Evolution</div>
            <div className={'filters'}>
              <div className={'filter'}>
                <div className={'filter_label'}>公司</div>
                <div className={'filter_ctrl'}>
                  <Select value={entity} size={'small'} placeholder={'请选择公司'} onChange={setEntity} style={{width: '100%'}}>
                    {
                      Object.values(entities).sort((a, b) => b.count - a.count).map(item => <Select.Option key={item.entity} value={item.entity}>{item.entity}</Select.Option>)
                    }
                  </Select>
                </div>
              </div>
            </div>
            {
              patents.length === 0 ?
                <div className={'empty'}>
                  <Empty description={'点击图表浏览专利'}/>
                </div>
                :
                <div className={'patents'}>
                  <List
                    size="small"
                    bordered={false}
                    dataSource={patents}
                    header={patents.length > 0 && <div className={'patents_header'}>{`${entity} > ${startYear}-${apy} > ${tag} > ${patents.length}件专利`}</div>}
                    pagination={{
                      size: 'small',
                      position: 'both',
                      hideOnSinglePage: false,
                      pageSize: 10,
                    }}
                    renderItem={patent => <List.Item><PatentItem patent={patent}/></List.Item>}
                  />
                </div>
            }
          </div>
        </div>
      </div>
      <div className={'slogan'}>
        <div className={'logo'}></div>
        TIPLAB · INSIGHT SERIAL · COMPUTATIONAL VISION
      </div>
    </>
  );

}

export default VisualEvolution;
