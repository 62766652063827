import React from 'react';
import {Card, Col, Row} from 'antd';
import {NavLink} from 'react-router-dom';

import './index.less';


function Index(props) {

  return (
    <div className={'index_wrapper'}>
      <div className={'index_main'}>
        <Row gutter={15} style={{margin: '20px 0'}}>
          <Col span={6}>
            <NavLink to={'/patent/visual/composition/'} target={'_blank'}>
              <Card hoverable cover={<img alt="Visual · Composition" src="/static/patent_visual_composition.png"/>}>
                <Card.Meta title="Visual · Composition" description="图像理解 · 场景构成"/>
              </Card>
            </NavLink>
          </Col>
          <Col span={6}>
            <NavLink to={'/patent/visual/evolution/'} target={'_blank'}>
              <Card hoverable cover={<img alt="Visual · Evolution" src="/static/patent_visual_evolution.png"/>}>
                <Card.Meta title="Visual · Evolution" description="图像理解 · 场景发展"/>
              </Card>
            </NavLink>
          </Col>
          <Col span={6}>
            <NavLink to={'/patent/visual/matrix/'} target={'_blank'}>
              <Card hoverable cover={<img alt="Visual · Inventor Matrix" src="/static/patent_visual_matrix.png"/>}>
                <Card.Meta title="Visual · Inventor Matrix" description="图像理解 · 发明人聚合"/>
              </Card>
            </NavLink>
          </Col>
          <Col span={6}>
            <NavLink to={'/paper/trends/'} target={'_blank'}>
              <Card hoverable cover={<img alt="Paper · Trends" src="/static/paper_trends.png"/>}>
                <Card.Meta title="Paper · Trends" description="论文趋势发展"/>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row gutter={15} style={{margin: '20px 0'}}>
          <Col span={6}>
            <NavLink to={'/patent/ocr/composition/'} target={'_blank'}>
              <Card hoverable cover={<img alt="OCR · Composition" src="/static/patent_ocr_composition.png"/>}>
                <Card.Meta title="OCR · Composition" description="OCR · 场景构成"/>
              </Card>
            </NavLink>
          </Col>
          <Col span={6}>
            <NavLink to={'/patent/ocr/evolution/'} target={'_blank'}>
              <Card hoverable cover={<img alt="OCR · Evolution" src="/static/patent_ocr_evolution.png"/>}>
                <Card.Meta title="OCR · Evolution" description="OCR · 场景发展"/>
              </Card>
            </NavLink>
          </Col>
          <Col span={6}>
            <NavLink to={'/patent/ocr/matrix/'} target={'_blank'}>
              <Card hoverable cover={<img alt="OCR · Inventor Matrix" src="/static/patent_ocr_matrix.png"/>}>
                <Card.Meta title="OCR · Inventor Matrix" description="OCR · 发明人聚合"/>
              </Card>
            </NavLink>
          </Col>
          <Col span={6}>
            <NavLink to={'/paper/network/'} target={'_blank'}>
              <Card hoverable cover={<img alt="Paper · Author Network" src="/static/paper_network.png"/>}>
                <Card.Meta title="Paper · Author Network" description="论文研究人员网络"/>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row gutter={20} style={{margin: '40px 0'}}>
          <Col span={24} style={{textAlign: 'center'}}>
            <span id="busuanzi_container_site_pv">本站总访问量：<span id="busuanzi_value_site_pv"></span>次</span>
          </Col>
        </Row>
      </div>
      <div className={'slogan'}>
        <div className={'logo'}></div>
        TIPLAB · INSIGHT SERIAL · COMPUTATIONAL VISION
      </div>
    </div>
  );
}

export default Index;
