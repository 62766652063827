import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {Empty, InputNumber, List, Select} from 'antd';

import {data} from './ocr_patents';

import './patents.less';
import PatentItem from './PatentItem';


function OCRMatrix(props) {

  const canvasRef = useRef(null);
  const svgRef = useRef(null);

  const [entity, setEntity] = useState('');
  const [tags, setTags] = useState([]);
  const [threshold, setThreshold] = useState(10);
  const [ds, setDs] = useState({nodes: [], edges: []});
  const [inv, setInv] = useState('');

  const entityMap = {};
  const l1Map = {};

  data.forEach(item => {
    if (entityMap.hasOwnProperty(item.entity)) {
      entityMap[item.entity].count += 1;
    } else {
      entityMap[item.entity] = {entity: item.entity, count: 1};
    }

    if (l1Map.hasOwnProperty(item.l1)) {
      l1Map[item.l1].count += 1;
    } else {
      l1Map[item.l1] = {l1: item.l1, count: 1};
    }
  });


  const entities = Object.values(entityMap).sort((a, b) => b.count - a.count);
  const l1s = Object.values(l1Map).sort((a, b) => b.count - a.count);


  useEffect(() => {
    if (entity === '') {
      setDs({nodes: [], edges: []});
    }

    const nodeMap = {};
    const edgeMap = {};

    const patents = data.filter(item => item.entity === entity && (tags.length === 0 || tags.includes(item.l1)));

    for (let patent of patents) {

      const invs = patent.inv.sort();

      for (let inv of invs) {
        if (nodeMap.hasOwnProperty(inv)) {
          nodeMap[inv].count = nodeMap[inv].count + 1;
        } else {
          nodeMap[inv] = {inv, count: 1};
        }
      }

      for (let i = 0; i < invs.length - 1; i++) {
        for (let j = i + 1; j < invs.length; j++) {
          const sourceId = invs[i];
          const targetId = invs[j];
          const key = `${sourceId}_${targetId}`;
          if (edgeMap.hasOwnProperty(key)) {
            edgeMap[key].count = edgeMap[key].count + 1;
          } else {
            edgeMap[key] = {source: sourceId, target: targetId, count: 1};
          }
        }
      }
    }

    setDs({
      nodes: Object.values(nodeMap),
      edges: Object.values(edgeMap),
    });

  }, [entity, tags]);

  useEffect(() => {

    const width = canvasRef.current.scrollWidth;
    const height = canvasRef.current.scrollHeight;

    const hMargin = 0;
    const vMargin = 0;

    const plotWidth = width - hMargin * 2;
    const plotHeight = height - vMargin * 2;

    const radiusScale = d3.scaleSqrt().domain([1, 100]).range([5, 120]);
    const colorScale = d3.scaleSequential(d3.interpolateGreens).domain([-20, 20]);

    const color = d => colorScale(d.count);

    if (svgRef.current === null) {
      svgRef.current = d3.select(canvasRef.current)
        .append('svg')
        // .style('border', '1px solid red')
        .attr('width', plotWidth)
        .attr('height', plotHeight);

      svgRef.current.append('g')
        .attr('class', 'evolution')
        .attr('width', plotWidth)
        .attr('height', plotHeight);

      svgRef.current.select('g').append('g').attr('class', 'edges');
      svgRef.current.select('g').append('g').attr('class', 'nodes');
    }

    const graph = svgRef.current.select('g');

    const ticked = () => {
      graph.selectAll('.edge').attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      graph.selectAll('.node').attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });
    };

    const force = d3.forceSimulation(ds.nodes)
      .force('charge', d3.forceManyBody().strength(-100))
      .force('collide', d3.forceCollide().radius(d => radiusScale(d.count) + 10))
      .force('link', d3.forceLink(ds.edges).id(d => d.inv).distance(80).strength(0.7))
      .force('x', d3.forceX(plotWidth / 2))
      .force('y', d3.forceY(plotHeight / 2))
      .force('center', d3.forceCenter().x(plotWidth / 2).y(plotHeight / 2))
      .on('tick', ticked);

    // Edges

    const edgeGroups = graph.select('.edges')
      .selectAll('.edge')
      .data(ds.edges, d => d.id);

    edgeGroups.exit().remove();

    edgeGroups
      .enter()
      .append('line')
      .attr('class', 'edge')
      .attr('stroke', '#e1e1e1')
      .attr('stroke-width', '1px');

    // Nodes

    let nodeGroups = graph.select('.nodes')
      .selectAll('g')
      .data(ds.nodes, d => d.id);

    nodeGroups.exit().remove();

    const node = nodeGroups.enter()
      .append('g')
      .attr('class', 'node')
      .on('click', d => {
        setInv(d.inv)
      });

    const circles = node.append('circle');
    circles.append('title').text(d => d.inv);

    node.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', 0)
      .attr('y', 3)
      .attr('font', 'Roboto')
      .attr('fill', '#000000');

    nodeGroups = node.merge(nodeGroups);

    nodeGroups.select('circle')
      .attr('r', d => radiusScale(d.count))
      .attr('fill', d => d.inv === inv ? '#f81c2d' : color(d));

    nodeGroups.select('text')
      .text(d => d.count >= threshold || d.inv === inv ? d.inv : '');

    const dragstarted = d => {
      d3.event.sourceEvent.stopPropagation();
      if (!d3.event.active) force.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = d => {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    };

    const dragended = d => {
      if (!d3.event.active) force.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    };

    node.call(
      d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

    // Zoom

    svgRef.current.call(
      d3.zoom()
        .scaleExtent([-Infinity, Infinity])
        .translateExtent([[0, 0], [plotWidth, plotHeight]])
        .extent([[0, 0], [plotWidth, plotHeight]])
        .on('zoom', () => graph.attr('transform', d3.event.transform))
    );

  }, [ds, threshold, inv]);

  const patents = entity === '' || inv === '' ? [] :
    data.filter(item => item.entity === entity && item.inv.includes(inv) && (tags.length === 0 || tags.includes(item.l1))).sort((a, b) => b.apd.localeCompare(b.apd));

  return (
    <>
      <div className={'wrapper'}>
        <div className={'main'}>
          <div className={'canvas'} ref={canvasRef}/>
          <div className={'content'}>
            <div className={'title'}>Network</div>
            <div className={'filters'}>
              <div className={'filter'}>
                <div className={'filter_label'}>公司</div>
                <div className={'filter_ctrl'}>
                  <Select value={entity} size={'small'} onChange={setEntity} style={{width: '100%'}}>
                    {
                      entities.map(item => <Select.Option key={item.entity} value={item.entity}>{item.entity}</Select.Option>)
                    }
                  </Select>
                </div>
              </div>
              <div className={'filter'}>
                <div className={'filter_label'}>分类</div>
                <div className={'filter_ctrl'}>
                  <Select mode={'multiple'} value={tags} size={'small'} onChange={setTags} style={{width: '100%'}}>
                    {
                      l1s.map(item => <Select.Option key={item.l1} value={item.l1}>{item.l1}</Select.Option>)
                    }
                  </Select>
                </div>
              </div>
              <div className={'filter'}>
                <div className={'filter_label'}>显示阈值</div>
                <div className={'filter_ctrl'}>
                  <InputNumber min={1} max={100} value={threshold} size={'small'} onChange={setThreshold}/>
                </div>
              </div>
            </div>
            {
              patents.length === 0 ?
                <div className={'empty'}>
                  <Empty description={'点击图表浏览专利'}/>
                </div>
                :
                <div className={'patents'}>
                  <List
                    size="small"
                    bordered={false}
                    dataSource={patents}
                    header={patents.length > 0 && <div className={'patents_header'}>{`${entity} > ${tags.join(', ')} > ${inv} > ${patents.length}件专利`}</div>}
                    pagination={{
                      size: 'small',
                      position: 'both',
                      hideOnSinglePage: false,
                      pageSize: 10,
                    }}
                    renderItem={patent => <List.Item><PatentItem patent={patent}/></List.Item>}
                  />
                </div>
            }
          </div>
        </div>
      </div>
      <div className={'slogan'}>
        <div className={'logo'}></div>
        TIPLAB · INSIGHT SERIAL · COMPUTATIONAL VISION
      </div>
    </>
  );

}

export default OCRMatrix;
