export const data = [
  {
    "entity": "云从",
    "pun": "CN109409274A",
    "apn": "CN201811214776.8",
    "apd": "2018-10-18",
    "apy": "2018",
    "tit": "一种基于人脸三维重建和人脸对齐的人脸图像变换方法",
    "inv": [
      "周曦",
      "姚鹏飞"
    ],
    "app": [
      "广州云从人工智能技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "云从",
    "pun": "CN110310164A",
    "apn": "CN201910695419.6",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "图像处理系统、方法、平台、机器可读介质及设备",
    "inv": [
      "周曦",
      "姚志强",
      "温泉",
      "万珺",
      "游宇",
      "陈江豪",
      "侯朝能",
      "李可",
      "李红毅",
      "王金文",
      "何春节",
      "周东霞",
      "吴晓方"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109271950A",
    "apn": "CN201811139901.3",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "一种基于手机前视摄像头的人脸活体检测方法",
    "inv": [
      "周曦",
      "周牧"
    ],
    "app": [
      "广州云从人工智能技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109492538A",
    "apn": "CN201811208422.2",
    "apd": "2018-10-17",
    "apy": "2018",
    "tit": "基于人脸识别技术的智能登机系统、方法及可读存储介质",
    "inv": [
      "周曦",
      "陈江豪"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109376694A",
    "apn": "CN201811403495.7",
    "apd": "2018-11-23",
    "apy": "2018",
    "tit": "一种基于图像处理的实时人脸活体检测方法",
    "inv": [
      "周曦",
      "焦宾"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN109345480A",
    "apn": "CN201811139087.5",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "一种基于图像修复模型的人脸自动去痘方法",
    "inv": [
      "周曦",
      "刘伸展"
    ],
    "app": [
      "广州云从人工智能技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "云从",
    "pun": "CN109509142A",
    "apn": "CN201811268768.1",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "一种人脸变老图像处理方法、系统、可读存储介质及设备",
    "inv": [
      "周曦",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "云从",
    "pun": "CN110175530A",
    "apn": "CN201910358650.6",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "一种基于人脸的图像评分方法及系统",
    "inv": [
      "周曦",
      "姚志强",
      "李夏风",
      "谭涛",
      "李继伟",
      "温浩",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "云从",
    "pun": "CN110363187A",
    "apn": "CN201910806194.7",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "一种人脸识别方法、装置、机器可读介质及设备",
    "inv": [
      "周曦",
      "姚志强",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "上海云从汇临人工智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109360138A",
    "apn": "CN201811215198.X",
    "apd": "2018-10-18",
    "apy": "2018",
    "tit": "一种基于人脸识别技术的智能航班导航方法",
    "inv": [
      "周曦",
      "温浩",
      "陈江豪",
      "石君",
      "陈兰",
      "万珺"
    ],
    "app": [
      "广州云从人工智能技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109472227A",
    "apn": "CN201811268758.8",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "一种跨终端人脸识别验证方法及系统",
    "inv": [
      "周曦",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN110059637A",
    "apn": "CN201910321853.8",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "一种人脸对齐的检测方法及装置",
    "inv": [
      "周曦",
      "姚志强",
      "李夏风",
      "谭涛",
      "李继伟",
      "朱鹏"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN109508656A",
    "apn": "CN201811269810.1",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "一种舞蹈评级自动判别方法、系统及计算机可读存储介质",
    "inv": [
      "周曦",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109344765A",
    "apn": "CN201811139060.6",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "一种针对连锁门店入店人员分析的智能分析方法",
    "inv": [
      "周曦",
      "温浩",
      "陈江豪",
      "石君",
      "陈兰",
      "万珺"
    ],
    "app": [
      "广州云从人工智能技术有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109493297A",
    "apn": "CN201811295423.5",
    "apd": "2018-11-01",
    "apy": "2018",
    "tit": "低质量人脸图像增强方法、系统、设备及存储介质",
    "inv": [
      "周曦",
      "周亚飞"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "云从",
    "pun": "CN109377614A",
    "apn": "CN201811268725.3",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "一种人脸门禁识别方法、系统、计算机存储介质及设备",
    "inv": [
      "周曦",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109902759A",
    "apn": "CN201910186100.0",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "图片集描述方法及装置",
    "inv": [
      "周曦",
      "姚志强",
      "谭涛",
      "周翔",
      "李夏风",
      "吴媛",
      "汤文洁",
      "吴大为"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN110414424A",
    "apn": "CN201910680353.3",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "数据处理系统、方法、平台、机器可读介质及设备",
    "inv": [
      "周曦",
      "姚志强",
      "万珺",
      "谢科",
      "陈江豪"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109285205A",
    "apn": "CN201811269832.8",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "一种人脸互换方法、系统、计算机可读存储介质及设备",
    "inv": [
      "周曦",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "云从",
    "pun": "CN109816582A",
    "apn": "CN201910082110.X",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "区控安防方法及装置",
    "inv": [
      "周曦",
      "姚志强",
      "谭涛",
      "周翔",
      "李夏风",
      "陈昊"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "云从",
    "pun": "CN106127170B",
    "apn": "CN201610504128.0",
    "apd": "2016-07-01",
    "apy": "2016",
    "tit": "一种融合关键特征点的训练方法、识别方法及系统",
    "inv": [
      "周曦",
      "李继伟"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN109558535A",
    "apn": "CN201811306703.1",
    "apd": "2018-11-05",
    "apy": "2018",
    "tit": "基于人脸识别的个性化推送物品的方法及系统",
    "inv": [
      "周曦",
      "郭祥龙",
      "吴媛"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109325467A",
    "apn": "CN201811215199.4",
    "apd": "2018-10-18",
    "apy": "2018",
    "tit": "一种基于视频检测结果的车辆跟踪方法",
    "inv": [
      "周曦",
      "柯再丹"
    ],
    "app": [
      "广州云从人工智能技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN109389098A",
    "apn": "CN201811292142.4",
    "apd": "2018-11-01",
    "apy": "2018",
    "tit": "一种基于唇语识别的验证方法以及系统",
    "inv": [
      "周曦",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109697435A",
    "apn": "CN201910012764.5",
    "apd": "2019-01-07",
    "apy": "2019",
    "tit": "人流量监测方法、装置、存储介质及设备",
    "inv": [
      "周曦",
      "姚志强",
      "周翔",
      "李夏凤",
      "李继伟",
      "张庭"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN110084935A",
    "apn": "CN201910342903.0",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "出入预警方法及装置",
    "inv": [
      "周曦",
      "姚志强",
      "曾柯婷",
      "李夏风",
      "谭涛",
      "李继伟"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN110046606A",
    "apn": "CN201910342921.9",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "预警方法及装置",
    "inv": [
      "周曦",
      "姚志强",
      "谭涛",
      "周翔",
      "李夏风",
      "曾柯婷"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "云从",
    "pun": "CN106203454B",
    "apn": "CN201610587650.X",
    "apd": "2016-07-25",
    "apy": "2016",
    "tit": "证件版式分析的方法及装置",
    "inv": [
      "周曦",
      "周亚飞"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN109508657A",
    "apn": "CN201811269882.6",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "人群聚集分析方法、系统、计算机可读存储介质及设备",
    "inv": [
      "周曦",
      "吴媛",
      "吴大为"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN109493350A",
    "apn": "CN201811333344.9",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "人像分割方法及装置",
    "inv": [
      "周曦",
      "蒋红亮"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "云从",
    "pun": "CN108537191A",
    "apn": "CN201810342278.5",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种基于结构光摄像头的三维人脸识别方法",
    "inv": [
      "冯瑶",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "云从",
    "pun": "CN107657248A",
    "apn": "CN201711016920.2",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "一种基于人脸识别认证的Android红外双目活体检测",
    "inv": [
      "陈腾",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN108985134A",
    "apn": "CN201710404541.4",
    "apd": "2017-06-01",
    "apy": "2017",
    "tit": "基于双目摄像机的人脸活体检测及刷脸交易方法及系统",
    "inv": [
      "周曦",
      "焦宾"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN108921926A",
    "apn": "CN201810707690.2",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "一种基于单张图像的端到端三维人脸重建方法",
    "inv": [
      "冯瑶",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "云从",
    "pun": "CN108564041A",
    "apn": "CN201810342618.4",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种基于RGBD相机的人脸检测和修复方法",
    "inv": [
      "程子翰",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN108932458A",
    "apn": "CN201710375131.1",
    "apd": "2017-05-24",
    "apy": "2017",
    "tit": "恢复眼镜遮挡区域的人脸重建方法及装置",
    "inv": [
      "周曦",
      "许梅芳"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN108664858A",
    "apn": "CN201710207475.1",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "一种安检系统及基于人脸识别的旅客身份验证方法和装置",
    "inv": [
      "周曦",
      "姚志强"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107784724A",
    "apn": "CN201711011073.0",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "一种基于人脸识别的安全管理方法",
    "inv": [
      "陈腾",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107591116A",
    "apn": "CN201711016910.9",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "一种基于人脸识别分析的智能广告机及其工作方法",
    "inv": [
      "陈腾",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107845168A",
    "apn": "CN201711016909.6",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "一种基于人脸识别认证的VIP识别方法",
    "inv": [
      "陈腾",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN104361328B",
    "apn": "CN201410677837.X",
    "apd": "2014-11-21",
    "apy": "2014",
    "tit": "一种基于自适应多列深度模型的人脸图像正规化方法",
    "inv": [
      "刘艳飞",
      "周祥东",
      "周曦"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "云从",
    "pun": "CN105404861B",
    "apn": "CN201510779157.3",
    "apd": "2015-11-13",
    "apy": "2015",
    "tit": "人脸关键特征点检测模型的训练、检测方法及系统",
    "inv": [
      "邵枭虎",
      "周祥东",
      "石宇",
      "周曦"
    ],
    "app": [
      "中国科学院重庆绿色智能技术研究院",
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN108810485A",
    "apn": "CN201810705912.7",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "一种监控系统工作方法",
    "inv": [
      "刘友",
      "虞海青",
      "周曦"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN108664859A",
    "apn": "CN201710209064.6",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "指静脉识别方法及系统",
    "inv": [
      "周曦",
      "周细文"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "云从",
    "pun": "CN108932731A",
    "apn": "CN201710375095.9",
    "apd": "2017-05-24",
    "apy": "2017",
    "tit": "基于先验信息的目标跟踪方法及系统",
    "inv": [
      "周曦",
      "李远钱"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN107169405A",
    "apn": "CN201710160685.X",
    "apd": "2017-03-17",
    "apy": "2017",
    "tit": "基于双目摄像机活体识别的方法及装置",
    "inv": [
      "周曦",
      "程子翰"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107122709A",
    "apn": "CN201710161527.6",
    "apd": "2017-03-17",
    "apy": "2017",
    "tit": "活体检测方法及装置",
    "inv": [
      "周曦",
      "周超"
    ],
    "app": [
      "上海云从企业发展有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN104834900B",
    "apn": "CN201510178898.6",
    "apd": "2015-04-15",
    "apy": "2015",
    "tit": "一种联合声像信号进行活体检测的方法和系统",
    "inv": [
      "李继伟"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107393220A",
    "apn": "CN201710762165.6",
    "apd": "2017-08-30",
    "apy": "2017",
    "tit": "基于人脸识别的银行自助取款终端及取款方法",
    "inv": [
      "夏祥红",
      "吕佳美",
      "张兴旺",
      "周曦"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107480626A",
    "apn": "CN201710675378.5",
    "apd": "2017-08-09",
    "apy": "2017",
    "tit": "一种采用人脸识别的人口普查监控的方法",
    "inv": [
      "周曦",
      "张强"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107491652A",
    "apn": "CN201710762147.8",
    "apd": "2017-08-30",
    "apy": "2017",
    "tit": "一种基于人脸识别的人脸异常特征数据分析方法",
    "inv": [
      "侯华成",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107480625A",
    "apn": "CN201710675129.6",
    "apd": "2017-08-09",
    "apy": "2017",
    "tit": "一种基于人脸识别的身份鉴别方法",
    "inv": [
      "周曦",
      "张昌文"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "云从",
    "pun": "CN107508803A",
    "apn": "CN201710675355.4",
    "apd": "2017-08-09",
    "apy": "2017",
    "tit": "一种用于网络游戏安全验证的人脸识别方法",
    "inv": [
      "周曦",
      "陈昊"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107396051A",
    "apn": "CN201710675161.4",
    "apd": "2017-08-09",
    "apy": "2017",
    "tit": "一种采用无人机进行人脸识别监控的方法",
    "inv": [
      "周曦",
      "龚强"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN104504055B",
    "apn": "CN201410798968.3",
    "apd": "2014-12-19",
    "apy": "2014",
    "tit": "基于图像相似度的商品相似计算方法及商品推荐系统",
    "inv": [
      "姚志强"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "云从",
    "pun": "CN104463172B",
    "apn": "CN201410750504.5",
    "apd": "2014-12-09",
    "apy": "2014",
    "tit": "基于人脸特征点形状驱动深度模型的人脸特征提取方法",
    "inv": [
      "刘艳飞",
      "程诚",
      "周祥东",
      "周曦"
    ],
    "app": [
      "重庆中科云从科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "云从",
    "pun": "CN107491767A",
    "apn": "CN201710773878.2",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "端到端的无约束人脸关键点检测方法",
    "inv": [
      "肖春林",
      "周曦"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "云从",
    "pun": "CN105096528B",
    "apn": "CN201510475351.2",
    "apd": "2015-08-05",
    "apy": "2015",
    "tit": "一种疲劳驾驶检测方法及系统",
    "inv": [
      "许梅芳"
    ],
    "app": [
      "广州云从信息科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110458173A",
    "apn": "CN201910760684.8",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "用于生成物品颜色值的方法和装置",
    "inv": [
      "毛伟",
      "裴积全",
      "吕军"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110298894A",
    "apn": "CN201910582915.0",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图像处理方法、装置、系统及介质",
    "inv": [
      "曹晨宇",
      "陈孟飞",
      "张尧"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN110297935A",
    "apn": "CN201910579095.X",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图像检索方法、装置、介质及电子设备",
    "inv": [
      "麻晓珍",
      "王永亮",
      "安山"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN110309774A",
    "apn": "CN201910579429.3",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "虹膜图像分割方法、装置、存储介质及电子设备",
    "inv": [
      "孔爱祥"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN110310175A",
    "apn": "CN201910558391.1",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "用于移动增强现实的系统和方法",
    "inv": [
      "吴宇章",
      "徐毅",
      "苗丹",
      "周辉"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "京东美国科技公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110276260A",
    "apn": "CN201910429812.0",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "一种基于深度摄像头的商品检测方法",
    "inv": [
      "颜成钢",
      "陈靖文",
      "刘武",
      "刘鑫晨",
      "孙垚棋",
      "张继勇",
      "张勇东"
    ],
    "app": [
      "杭州电子科技大学",
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110097520A",
    "apn": "CN201910375766.0",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "王耀华",
      "刘志伟",
      "陈宇",
      "刘巍"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110046600A",
    "apn": "CN201910331939.9",
    "apd": "2019-04-24",
    "apy": "2019",
    "tit": "用于人体检测的方法和装置",
    "inv": [
      "鲍慊",
      "刘武",
      "梅涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110163829A",
    "apn": "CN201910315692.1",
    "apd": "2019-04-19",
    "apy": "2019",
    "tit": "图像生成方法、装置和计算机可读存储介质",
    "inv": [
      "孙旭",
      "董玉新",
      "刘巍",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109934850A",
    "apn": "CN201910217589.3",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "运动目标计数的方法、装置和系统",
    "inv": [
      "黄志标",
      "安山",
      "陈宇"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110009800A",
    "apn": "CN201910193593.0",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "一种识别方法和设备",
    "inv": [
      "徐卓然",
      "刘旭"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109919094A",
    "apn": "CN201910173725.3",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "图像处理方法、装置、系统、计算机可读存储介质",
    "inv": [
      "孔爱祥"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN109711360A",
    "apn": "CN201811630397.7",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "售货机风险控制方法、装置和控制系统",
    "inv": [
      "刘朋樟",
      "徐福来",
      "张屹峰",
      "刘巍",
      "陈宇"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109711472A",
    "apn": "CN201811628622.3",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "训练数据生成方法和装置",
    "inv": [
      "张屹峰",
      "周梦迪",
      "刘朋樟",
      "刘巍",
      "陈宇"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109727275A",
    "apn": "CN201811630396.2",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "目标检测方法、装置、系统和计算机可读存储介质",
    "inv": [
      "李姣",
      "刘朋樟",
      "刘通"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109711359A",
    "apn": "CN201811630378.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "售货机控制方法、装置和计算机可读存储介质",
    "inv": [
      "刘朋樟",
      "刘巍",
      "陈宇"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109711473A",
    "apn": "CN201811628625.7",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "物品识别方法、设备和系统",
    "inv": [
      "刘朋樟",
      "张屹峰",
      "刘巍",
      "陈宇"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109754009A",
    "apn": "CN201811630337.5",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "物品识别方法、装置、售货系统和存储介质",
    "inv": [
      "张屹峰",
      "刘朋樟",
      "刘巍",
      "陈宇",
      "周梦迪"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109711475A",
    "apn": "CN201811630398.1",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "识别物品的方法、装置、售货设备及计算机可读存储介质",
    "inv": [
      "刘朋樟",
      "张屹峰",
      "刘巍",
      "陈宇"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697609A",
    "apn": "CN201811630322.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "售货方法和售货装置",
    "inv": [
      "任晞蓝"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109389747A",
    "apn": "CN201811628559.3",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "售货装置和售货方法",
    "inv": [
      "刘自银",
      "刘朋樟",
      "张屹峰",
      "陈宇",
      "刘巍",
      "安山",
      "姜博",
      "乌日尼",
      "郭景昊",
      "张夏杰"
    ],
    "app": [
      "北京沃东天骏信息技术有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109727288A",
    "apn": "CN201811633004.8",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "用于单目同时定位与地图构建的系统和方法",
    "inv": [
      "徐毅",
      "杨尚林",
      "吴宇章",
      "周辉"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "京东美国科技公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109618961A",
    "apn": "CN201811515215.1",
    "apd": "2018-12-12",
    "apy": "2018",
    "tit": "一种家畜的智能投喂系统和方法",
    "inv": [
      "罗扬",
      "李磊鑫"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "工农业",
    "l2": "作物/农畜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109544252A",
    "apn": "CN201811483294.2",
    "apd": "2018-12-06",
    "apy": "2018",
    "tit": "播放方法、系统以及相关设备",
    "inv": [
      "鲍慊",
      "刘武",
      "梅涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110413869A",
    "apn": "CN201811477459.5",
    "apd": "2018-12-05",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "左鑫孟",
      "李旭峰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109583368A",
    "apn": "CN201811438467.9",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "特征比对方法及其系统、计算机系统及计算机可读介质",
    "inv": [
      "孔爱祥"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN110378361A",
    "apn": "CN201811402692.7",
    "apd": "2018-11-23",
    "apy": "2018",
    "tit": "一种用于密集拿取物品检测的方法和装置",
    "inv": [
      "李艺"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109636828A",
    "apn": "CN201811386399.6",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "基于视频图像的物体跟踪方法及装置",
    "inv": [
      "车广富",
      "安山",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109598743A",
    "apn": "CN201811386432.5",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "行人目标跟踪方法、装置及设备",
    "inv": [
      "车广富",
      "董玉新",
      "安山"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109214806A",
    "apn": "CN201811380178.8",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "自助结算方法、装置以及存储介质",
    "inv": [
      "石海林",
      "赵何",
      "刘武",
      "梅涛",
      "周伯文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109508681A",
    "apn": "CN201811380813.2",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "生成人体关键点检测模型的方法和装置",
    "inv": [
      "鲍慊",
      "刘武",
      "梅涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110428297A",
    "apn": "CN201811381978.1",
    "apd": "2018-11-19",
    "apy": "2018",
    "tit": "商品推荐系统、方法、电子设备、存储介质",
    "inv": [
      "张开涛",
      "纪鸿焘"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110363054A",
    "apn": "CN201811367261.1",
    "apd": "2018-11-16",
    "apy": "2018",
    "tit": "道路标识线识别方法、装置和系统",
    "inv": [
      "杨恒"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109409322A",
    "apn": "CN201811329008.7",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "活体检测方法、装置及人脸识别方法和人脸检测系统",
    "inv": [
      "王耀华",
      "刘志伟",
      "陈宇",
      "刘巍",
      "殷向阳"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109459119A",
    "apn": "CN201811210433.4",
    "apd": "2018-10-17",
    "apy": "2018",
    "tit": "一种体重测量方法、设备和计算机可读存储介质",
    "inv": [
      "王博",
      "李春华"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "工农业",
    "l2": "作物/农畜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109460713A",
    "apn": "CN201811200395.4",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "动物分娩的识别方法、装置和设备",
    "inv": [
      "陈奕名"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "工农业",
    "l2": "作物/农畜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109409994A",
    "apn": "CN201811195470.2",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "模拟用户穿戴服装饰品的方法、装置和系统",
    "inv": [
      "潘宗涛",
      "王德鑫"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109325538A",
    "apn": "CN201811143927.5",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "目标检测方法、装置和计算机可读存储介质",
    "inv": [
      "叶韵",
      "吕晓磊"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109189970A",
    "apn": "CN201811096782.8",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "图片相似度比对方法和装置",
    "inv": [
      "周佩明",
      "汪振华",
      "李旭峰",
      "梅涛",
      "周伯文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN109446408A",
    "apn": "CN201811092742.6",
    "apd": "2018-09-19",
    "apy": "2018",
    "tit": "检索相似数据的方法、装置、设备及计算机可读存储介质",
    "inv": [
      "安山",
      "黄志标",
      "车广富",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN109191261A",
    "apn": "CN201811093790.7",
    "apd": "2018-09-19",
    "apy": "2018",
    "tit": "一种商品推荐方法和系统",
    "inv": [
      "张凯"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109214386A",
    "apn": "CN201811072105.2",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "用于生成图像识别模型的方法和装置",
    "inv": [
      "郁昌存",
      "王德鑫",
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109271999A",
    "apn": "CN201811035885.3",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "图像的处理方法、装置和计算机可读存储介质",
    "inv": [
      "潘滢炜",
      "姚霆",
      "梅涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109144655A",
    "apn": "CN201811035391.5",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "图像动态展示的方法、装置、系统及介质",
    "inv": [
      "李东华"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110375752A",
    "apn": "CN201810996044.2",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "一种生成导航点的方法和装置",
    "inv": [
      "郎元辉",
      "朱恒斌"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109040781A",
    "apn": "CN201810983669.5",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "视频认证方法、装置、系统、电子设备及可读介质",
    "inv": [
      "张全志",
      "杨业飞",
      "刘春伟",
      "孙海波"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "工农业",
    "l2": "作物/农畜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110378942A",
    "apn": "CN201810968195.7",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "基于双目相机的障碍物标识方法、系统、设备和存储介质",
    "inv": [
      "李政",
      "李雨倩",
      "刘懿"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110378176A",
    "apn": "CN201810969003.4",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "基于双目相机的物体标识方法、系统、设备和存储介质",
    "inv": [
      "李政",
      "李雨倩",
      "刘懿"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109213862A",
    "apn": "CN201810950784.2",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "物体识别方法和装置、计算机可读存储介质",
    "inv": [
      "白亚龙",
      "梅涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109145816A",
    "apn": "CN201810953349.5",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "商品识别方法和系统",
    "inv": [
      "王耀华",
      "刘巍",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109254655A",
    "apn": "CN201810951531.7",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "用于物品展示的装置和方法",
    "inv": [
      "赵涛涛"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110378175A",
    "apn": "CN201810934682.1",
    "apd": "2018-08-16",
    "apy": "2018",
    "tit": "道路边沿的识别方法和装置",
    "inv": [
      "邸永昌"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110378174A",
    "apn": "CN201810909428.6",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "道路线提取方法和装置",
    "inv": [
      "刘冬冬",
      "赫桂望"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108989679A",
    "apn": "CN201810862795.5",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "一种合影拍照的方法和装置",
    "inv": [
      "马娜"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN110378939A",
    "apn": "CN201810843794.6",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "图像编码、识别、跟踪的方法、系统、设备和存储介质",
    "inv": [
      "李政",
      "李雨倩",
      "刘懿"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110019915A",
    "apn": "CN201810822464.9",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "检测图片的方法、装置和计算机可读存储介质",
    "inv": [
      "程晓旭"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN109034973A",
    "apn": "CN201810822977.X",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "商品推荐方法、装置、系统及计算机可读存储介质",
    "inv": [
      "石海林",
      "梅涛",
      "周伯文",
      "赵何",
      "龚书"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108921105A",
    "apn": "CN201810733440.6",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "识别目标数量的方法、装置及计算机可读存储介质",
    "inv": [
      "刘明",
      "王怀庆",
      "付靖玲"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "工农业",
    "l2": "作物/农畜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110378359A",
    "apn": "CN201810738255.6",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "一种图像识别方法和装置",
    "inv": [
      "李艳丽",
      "刘冬冬",
      "赫桂望",
      "蔡金华"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN108932330A",
    "apn": "CN201810729470.X",
    "apd": "2018-07-05",
    "apy": "2018",
    "tit": "一种数据显示方法和装置",
    "inv": [
      "杜学甫"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108960412A",
    "apn": "CN201810692204.4",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "图像识别方法、装置及计算机可读存储介质",
    "inv": [
      "左鑫孟",
      "刘晓",
      "李旭峰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108875664A",
    "apn": "CN201810676304.8",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "选购商品的识别方法、装置以及售货机",
    "inv": [
      "徐卓然",
      "邵岭",
      "刘旭"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108874141A",
    "apn": "CN201810661716.4",
    "apd": "2018-06-25",
    "apy": "2018",
    "tit": "一种体感浏览方法和装置",
    "inv": [
      "赵涛涛"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108932774A",
    "apn": "CN201810641278.5",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "信息检测方法和装置",
    "inv": [
      "安耀祖",
      "龚顺强",
      "郁昌存"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108961592A",
    "apn": "CN201810646278.4",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "快递包装提取方法、系统、存储介质及电子设备",
    "inv": [
      "韩璐懿"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108932754A",
    "apn": "CN201810639160.9",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "基于人工检票界面图像的检票方法、装置、介质及电子设备",
    "inv": [
      "郁昌存",
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108961493A",
    "apn": "CN201810639791.0",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "通关检验装置及方法、存储介质及电子终端",
    "inv": [
      "马彦军",
      "张艳武",
      "郁昌存"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108875638A",
    "apn": "CN201810634640.6",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "人脸匹配测试方法和、装置和系统",
    "inv": [
      "翟超"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108875393A",
    "apn": "CN201810597385.2",
    "apd": "2018-06-11",
    "apy": "2018",
    "tit": "客户端数据被截屏的安全实现方法、装置及介质",
    "inv": [
      "尚晋",
      "胡小锋",
      "秦亚洲"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108898067A",
    "apn": "CN201810574016.1",
    "apd": "2018-06-06",
    "apy": "2018",
    "tit": "确定人和物关联度的方法、装置及计算机可读存储介质",
    "inv": [
      "石海龙",
      "张伟华",
      "吴江旭",
      "李凡",
      "胡淼枫",
      "曲志勇",
      "张洪光",
      "雍兴辉",
      "武跃峰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108846724A",
    "apn": "CN201810573614.7",
    "apd": "2018-06-06",
    "apy": "2018",
    "tit": "数据分析方法和系统",
    "inv": [
      "石海林",
      "梅涛",
      "周伯文",
      "赵何",
      "龚书"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108876526A",
    "apn": "CN201810573612.8",
    "apd": "2018-06-06",
    "apy": "2018",
    "tit": "商品推荐方法、装置和计算机可读存储介质",
    "inv": [
      "石海林",
      "梅涛",
      "周伯文",
      "赵何",
      "龚书"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108876995A",
    "apn": "CN201810586995.2",
    "apd": "2018-06-06",
    "apy": "2018",
    "tit": "用于控制闸机开启的方法和装置",
    "inv": [
      "路慧敏",
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108805071A",
    "apn": "CN201810572967.5",
    "apd": "2018-06-06",
    "apy": "2018",
    "tit": "身份核验方法及装置、电子设备、存储介质",
    "inv": [
      "王德鑫",
      "路慧敏",
      "龚顺强"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108921033A",
    "apn": "CN201810565862.7",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "人脸图片比对方法、装置、介质及电子设备",
    "inv": [
      "安耀祖",
      "郁昌存"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108776786A",
    "apn": "CN201810564222.4",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "用于生成用户真伪识别模型的方法和装置",
    "inv": [
      "赵晨旭",
      "王泽正",
      "程郑鑫",
      "吕军"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108776787A",
    "apn": "CN201810565733.8",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备、存储介质",
    "inv": [
      "杨硕"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110516516A",
    "apn": "CN201810494804.X",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "机器人姿态测量方法及装置、电子设备、存储介质",
    "inv": [
      "黄玉玺",
      "吴迪",
      "李雨倩",
      "董秋伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108805849A",
    "apn": "CN201810495787.1",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "图像融合方法、装置、介质及电子设备",
    "inv": [
      "张尧",
      "薛鹏",
      "许鹏"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110516511A",
    "apn": "CN201810487497.2",
    "apd": "2018-05-21",
    "apy": "2018",
    "tit": "用于处理信息的方法和装置",
    "inv": [
      "刘江",
      "潘晖",
      "杨家博"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110516684A",
    "apn": "CN201810488482.8",
    "apd": "2018-05-21",
    "apy": "2018",
    "tit": "用于确定无人机姿态信息的方法和装置",
    "inv": [
      "门春雷",
      "刘艳光",
      "巴航",
      "张文凯",
      "徐进",
      "韩微",
      "郝尚荣",
      "郑行",
      "陈明轩"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110517296A",
    "apn": "CN201810487689.3",
    "apd": "2018-05-21",
    "apy": "2018",
    "tit": "目标跟踪方法、装置、存储介质及电子设备",
    "inv": [
      "门春雷",
      "刘艳光",
      "巴航",
      "张文凯",
      "徐进",
      "韩微",
      "郝尚荣",
      "郑行",
      "陈明轩"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110517209A",
    "apn": "CN201810487451.0",
    "apd": "2018-05-21",
    "apy": "2018",
    "tit": "数据处理方法、装置、系统以及计算机可读存储介质",
    "inv": [
      "杨恒"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN108664948A",
    "apn": "CN201810489758.4",
    "apd": "2018-05-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "林嘉",
      "刘偲",
      "任乐健",
      "任广辉",
      "朱德发",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108648061A",
    "apn": "CN201810479980.6",
    "apd": "2018-05-18",
    "apy": "2018",
    "tit": "图像生成方法和装置",
    "inv": [
      "刘斌",
      "张开涛",
      "崔菁",
      "张弛",
      "费程",
      "杨嗦"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110472460A",
    "apn": "CN201810453319.8",
    "apd": "2018-05-11",
    "apy": "2018",
    "tit": "人脸图像处理方法及装置",
    "inv": [
      "张洪光",
      "张伟华",
      "吴江旭",
      "彭刚林",
      "武跃峰",
      "雍兴辉",
      "李凡"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108830780A",
    "apn": "CN201810437299.5",
    "apd": "2018-05-09",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备、存储介质",
    "inv": [
      "张尧",
      "薛鹏",
      "许鹏"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN110473263A",
    "apn": "CN201810440499.6",
    "apd": "2018-05-09",
    "apy": "2018",
    "tit": "图片的审核方法、装置和系统",
    "inv": [
      "闫创",
      "任莉强",
      "沈俊杰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "质量评价"
  },
  {
    "entity": "京东",
    "pun": "CN110443098A",
    "apn": "CN201810416995.8",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "身份验证系统、方法、装置和计算机可读存储介质",
    "inv": [
      "潘晖",
      "胡馨文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN110443946A",
    "apn": "CN201810411845.8",
    "apd": "2018-05-03",
    "apy": "2018",
    "tit": "售货机、物品种类的识别方法和装置",
    "inv": [
      "徐卓然",
      "刘旭",
      "林金表",
      "危磊",
      "李耀波",
      "白宇",
      "万保成",
      "蒋士淼",
      "刘伟峰",
      "黄建璁"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108595011A",
    "apn": "CN201810412577.1",
    "apd": "2018-05-03",
    "apy": "2018",
    "tit": "信息展示方法、装置、存储介质及电子设备",
    "inv": [
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110443263A",
    "apn": "CN201810409332.3",
    "apd": "2018-05-02",
    "apy": "2018",
    "tit": "闭环检测方法和装置",
    "inv": [
      "门春雷",
      "刘艳光",
      "巴航",
      "张文凯",
      "徐进",
      "韩微",
      "郝尚荣",
      "郑行",
      "陈明轩"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110444102A",
    "apn": "CN201810408970.3",
    "apd": "2018-05-02",
    "apy": "2018",
    "tit": "地图构建方法、装置和无人驾驶设备",
    "inv": [
      "门春雷",
      "刘艳光",
      "巴航",
      "张文凯",
      "徐进",
      "韩微",
      "郝尚荣",
      "郑行",
      "陈明轩"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110443824A",
    "apn": "CN201810410251.5",
    "apd": "2018-05-02",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "胡馨文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108629306A",
    "apn": "CN201810402486.X",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "人体姿态识别方法及装置、电子设备、存储介质",
    "inv": [
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108615073A",
    "apn": "CN201810404702.4",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "图像处理方法及装置、计算机可读存储介质、电子设备",
    "inv": [
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN108596120A",
    "apn": "CN201810397400.9",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "一种基于深度学习的目标检测方法和装置",
    "inv": [
      "董玉新",
      "刘巍",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108629824A",
    "apn": "CN201810402024.8",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "图像生成方法、装置、电子设备及计算机可读介质",
    "inv": [
      "赵涛涛",
      "张尧",
      "崔继群"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN108446675A",
    "apn": "CN201810402002.1",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "面部图像识别方法、装置电子设备及计算机可读介质",
    "inv": [
      "安耀祖",
      "郁昌存"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108596110A",
    "apn": "CN201810387331.3",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "图像识别方法及装置、电子设备、存储介质",
    "inv": [
      "王启立"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110414294A",
    "apn": "CN201810383810.8",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "行人重识别方法和装置",
    "inv": [
      "吴江旭",
      "张伟华",
      "张洪光",
      "彭刚林",
      "孔磊锋",
      "武跃峰",
      "雍兴辉",
      "李凡"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108509941A",
    "apn": "CN201810361555.7",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "情绪信息生成方法和装置",
    "inv": [
      "施皓",
      "张亚军"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108594999A",
    "apn": "CN201810359797.2",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "用于全景图像展示系统的控制方法和装置",
    "inv": [
      "赵涛涛"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110390570A",
    "apn": "CN201810344938.3",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "图片展示方法及装置、电子设备、存储介质",
    "inv": [
      "李卫锋"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110363790A",
    "apn": "CN201810318127.6",
    "apd": "2018-04-11",
    "apy": "2018",
    "tit": "目标追踪方法、装置和计算机可读存储介质",
    "inv": [
      "张伟华",
      "吴江旭",
      "李凡",
      "雍兴辉",
      "张洪光",
      "彭刚林",
      "胡淼枫",
      "曲志勇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108629823A",
    "apn": "CN201810315637.8",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "多视角图像的生成方法和装置",
    "inv": [
      "周鸣",
      "孙旭",
      "谢璐",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110363554A",
    "apn": "CN201810313396.3",
    "apd": "2018-04-09",
    "apy": "2018",
    "tit": "图像素材的质量评估方法、装置及计算机可读存储介质",
    "inv": [
      "温亿明",
      "马兴国",
      "王义超"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "质量评价"
  },
  {
    "entity": "京东",
    "pun": "CN110347035A",
    "apn": "CN201810307086.0",
    "apd": "2018-04-08",
    "apy": "2018",
    "tit": "自主跟踪方法及装置、电子设备、存储介质",
    "inv": [
      "门春雷",
      "刘艳光",
      "张文凯",
      "陈明轩",
      "郝尚荣",
      "郑行",
      "徐进",
      "韩微"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110348272A",
    "apn": "CN201810296959.2",
    "apd": "2018-04-03",
    "apy": "2018",
    "tit": "动态人脸识别的方法、装置、系统和介质",
    "inv": [
      "武兆杰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108520532A",
    "apn": "CN201810296899.4",
    "apd": "2018-04-03",
    "apy": "2018",
    "tit": "识别视频中物体运动方向的方法及装置",
    "inv": [
      "车广富",
      "安山",
      "黄志标",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110349165A",
    "apn": "CN201810290778.9",
    "apd": "2018-04-03",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及计算机可读介质",
    "inv": [
      "毛伟",
      "刘享军",
      "杨超"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN108495089A",
    "apn": "CN201810282728.6",
    "apd": "2018-04-02",
    "apy": "2018",
    "tit": "车辆监控方法、装置、系统以及计算机可读存储介质",
    "inv": [
      "马洪萍"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110349263A",
    "apn": "CN201810283823.8",
    "apd": "2018-04-02",
    "apy": "2018",
    "tit": "一种智能的物品包装方法和装置",
    "inv": [
      "杨文祥",
      "王振辉",
      "李宝杰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110288608A",
    "apn": "CN201810225888.7",
    "apd": "2018-03-19",
    "apy": "2018",
    "tit": "作物行中心线提取方法和装置",
    "inv": [
      "徐璟雪"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108345692A",
    "apn": "CN201810218016.8",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "一种自动问答方法和系统",
    "inv": [
      "高毅"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110246224A",
    "apn": "CN201810190139.5",
    "apd": "2018-03-08",
    "apy": "2018",
    "tit": "网格模型的表面去噪方法及系统",
    "inv": [
      "沈伯伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110231832A",
    "apn": "CN201810179257.6",
    "apd": "2018-03-05",
    "apy": "2018",
    "tit": "用于无人机的避障方法和避障装置",
    "inv": [
      "陈明轩"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108335408A",
    "apn": "CN201810175167.X",
    "apd": "2018-03-02",
    "apy": "2018",
    "tit": "用于自动售货机的物品识别方法、装置、系统及存储介质",
    "inv": [
      "张爱喜",
      "冯亦军",
      "殷向阳",
      "谭志羽",
      "王永杰",
      "张屹峰",
      "陈建业",
      "刘巍",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108389316A",
    "apn": "CN201810174847.X",
    "apd": "2018-03-02",
    "apy": "2018",
    "tit": "自动售货方法、装置和计算机可读存储介质",
    "inv": [
      "谭志羽",
      "张屹峰",
      "王永杰",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108389315A",
    "apn": "CN201810174634.7",
    "apd": "2018-03-02",
    "apy": "2018",
    "tit": "物品识别方法和装置以及计算机可读存储介质",
    "inv": [
      "张爱喜",
      "刘巍",
      "谭志羽",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108198052A",
    "apn": "CN201810174871.3",
    "apd": "2018-03-02",
    "apy": "2018",
    "tit": "用户选购商品识别方法、装置以及智能货架系统",
    "inv": [
      "刘巍",
      "陈宇",
      "刘强",
      "翁志",
      "冯亦军",
      "张钟毓"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110196601A",
    "apn": "CN201810161048.9",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "无人机控制方法、装置、系统和计算机可读存储介质",
    "inv": [
      "门春雷",
      "刘艳光",
      "张文凯",
      "陈明轩",
      "郝尚荣",
      "郑行",
      "徐进",
      "韩微"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110163625A",
    "apn": "CN201810149010.X",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "物品信息处理方法、系统、装置和计算机可读存储介质",
    "inv": [
      "崔国强",
      "向彪",
      "宋红伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108388858A",
    "apn": "CN201810142363.7",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "虹膜防伪方法与装置",
    "inv": [
      "孔爱祥"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110209977A",
    "apn": "CN201810141338.7",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "用于生成网页的方法和装置",
    "inv": [
      "王少星",
      "巫耀恒"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108388851A",
    "apn": "CN201810131969.0",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "信息统计方法、装置、存储介质及电子设备",
    "inv": [
      "施皓",
      "陈孟飞",
      "张亚军",
      "刘东强",
      "肖小兵"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108304816A",
    "apn": "CN201810131810.9",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "身份识别方法、装置、存储介质及电子设备",
    "inv": [
      "许志维",
      "安耀祖"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110136265A",
    "apn": "CN201810104551.0",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "商品展示方法、装置、终端设备及零售系统",
    "inv": [
      "杨海涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110136270A",
    "apn": "CN201810104961.5",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "制作容妆数据的方法和装置",
    "inv": [
      "王强"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN110110189A",
    "apn": "CN201810102618.7",
    "apd": "2018-02-01",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "汪雷"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN108364209A",
    "apn": "CN201810100678.5",
    "apd": "2018-02-01",
    "apy": "2018",
    "tit": "商品信息的展示方法、装置、介质及电子设备",
    "inv": [
      "赵涛涛"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110110257A",
    "apn": "CN201810088574.7",
    "apd": "2018-01-30",
    "apy": "2018",
    "tit": "数据处理方法及其系统、计算机系统及计算机可读介质",
    "inv": [
      "张尚志",
      "易朋",
      "王江洪"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110084257A",
    "apn": "CN201810077920.1",
    "apd": "2018-01-26",
    "apy": "2018",
    "tit": "用于检测目标的方法和装置",
    "inv": [
      "周鸣",
      "邹宇",
      "曾祥云",
      "关煜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110084657A",
    "apn": "CN201810073022.9",
    "apd": "2018-01-25",
    "apy": "2018",
    "tit": "一种推荐服饰的方法和装置",
    "inv": [
      "张伟华",
      "孔磊锋",
      "吴江旭",
      "李凡",
      "张洪光",
      "彭刚林"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110069961A",
    "apn": "CN201810067225.7",
    "apd": "2018-01-24",
    "apy": "2018",
    "tit": "一种物体检测方法和装置",
    "inv": [
      "林金表",
      "白宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110059520A",
    "apn": "CN201810047991.7",
    "apd": "2018-01-18",
    "apy": "2018",
    "tit": "虹膜特征提取的方法、装置及虹膜识别系统",
    "inv": [
      "孔爱祥"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110033291A",
    "apn": "CN201810031040.0",
    "apd": "2018-01-12",
    "apy": "2018",
    "tit": "信息对象推送方法、装置和系统",
    "inv": [
      "张尧",
      "崔海洋"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110033420A",
    "apn": "CN201810029791.9",
    "apd": "2018-01-12",
    "apy": "2018",
    "tit": "一种图像融合的方法和装置",
    "inv": [
      "张尧"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110032916A",
    "apn": "CN201810031047.2",
    "apd": "2018-01-12",
    "apy": "2018",
    "tit": "一种检测目标物体的方法和装置",
    "inv": [
      "吴江旭",
      "张洪光",
      "张伟华",
      "孔磊锋",
      "彭刚林",
      "李凡"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN110020983A",
    "apn": "CN201810021430.X",
    "apd": "2018-01-10",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "巫耀恒",
      "王少星"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110020579A",
    "apn": "CN201810033359.7",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "行人重识别方法及装置、存储介质和电子设备",
    "inv": [
      "吴江旭",
      "谭傅伦"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108171500A",
    "apn": "CN201810014453.8",
    "apd": "2018-01-08",
    "apy": "2018",
    "tit": "无人店支付信息处理方法和系统、计算机可读程序介质",
    "inv": [
      "张野",
      "张凯"
    ],
    "app": [
      "京东数字科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN109870983A",
    "apn": "CN201810014627.0",
    "apd": "2018-01-08",
    "apy": "2018",
    "tit": "处理托盘堆垛图像的方法、装置及用于仓储拣货的系统",
    "inv": [
      "李天剑",
      "李鹏成",
      "黄民",
      "陈猛",
      "刘诗晗",
      "李宝杰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京信息科技大学"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109993760A",
    "apn": "CN201711475409.9",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "一种图片的边缘检测方法和装置",
    "inv": [
      "刘享军"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN109993749A",
    "apn": "CN201711477652.4",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "提取目标图像的方法和装置",
    "inv": [
      "张南",
      "许鹏",
      "陈洪涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN109992007A",
    "apn": "CN201711469699.6",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "飞行器送货方法、控制装置、飞行器及配送系统",
    "inv": [
      "门春雷",
      "刘艳光",
      "张文凯",
      "陈明轩",
      "郝尚荣",
      "郑行",
      "徐进"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109993767A",
    "apn": "CN201711471070.5",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "图像处理方法和系统",
    "inv": [
      "郑凯杰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109977741A",
    "apn": "CN201711471179.9",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "人脸识别方法、装置、系统及介质",
    "inv": [
      "冯玉娜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109967359A",
    "apn": "CN201711454791.5",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "用于拣选物品的方法和装置",
    "inv": [
      "王祥龙",
      "姜雪原",
      "李显菲",
      "陈英"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109978747A",
    "apn": "CN201711449540.8",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "用于审核图片的方法及装置",
    "inv": [
      "张涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109960987A",
    "apn": "CN201711428805.6",
    "apd": "2017-12-25",
    "apy": "2017",
    "tit": "对象检测方法和系统",
    "inv": [
      "王吉星",
      "侯会满",
      "李伟进"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109960970A",
    "apn": "CN201711405393.4",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "基于ASM算法的人脸识别方法、系统、设备和存储介质",
    "inv": [
      "冯玉娜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109949332A",
    "apn": "CN201711386959.3",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "黄志标",
      "安山",
      "刘偲",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN109934834A",
    "apn": "CN201711380168.X",
    "apd": "2017-12-19",
    "apy": "2017",
    "tit": "图像轮廓提取方法和系统",
    "inv": [
      "冯玉娜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109933092A",
    "apn": "CN201711364355.9",
    "apd": "2017-12-18",
    "apy": "2017",
    "tit": "飞行器避障方法、装置和飞行器",
    "inv": [
      "门春雷"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108204711A",
    "apn": "CN201711360542.X",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "用于电子设备的方法、系统和电子设备",
    "inv": [
      "魏雪茹",
      "袁晓春",
      "赖南华",
      "崔丽娜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109934242A",
    "apn": "CN201711347132.1",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "图片识别方法和装置",
    "inv": [
      "吴江旭",
      "张伟华",
      "张洪光",
      "孔磊锋",
      "彭刚林",
      "李凡"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109934045A",
    "apn": "CN201711346003.0",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "行人检测方法和装置",
    "inv": [
      "彭刚林",
      "吴江旭",
      "李盛慧",
      "张伟华"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109961501A",
    "apn": "CN201711337773.9",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "用于建立三维立体模型的方法和装置",
    "inv": [
      "张伟华",
      "吴江旭",
      "李凡",
      "彭刚林",
      "张洪光",
      "孔磊锋",
      "曲志勇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109902543A",
    "apn": "CN201711306051.7",
    "apd": "2017-12-11",
    "apy": "2017",
    "tit": "目标运动轨迹估计方法、装置和目标跟踪系统",
    "inv": [
      "门春雷"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109901988A",
    "apn": "CN201711307879.4",
    "apd": "2017-12-11",
    "apy": "2017",
    "tit": "一种用于自动化测试的页面元素定位方法和装置",
    "inv": [
      "张慧"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110020080A",
    "apn": "CN201711303509.3",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "信息处理方法和系统",
    "inv": [
      "何林艳"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109902531A",
    "apn": "CN201711282114.X",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "用户管理方法、装置、介质和电子设备",
    "inv": [
      "张野",
      "王飞腾"
    ],
    "app": [
      "北京京东金融科技控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109872277A",
    "apn": "CN201711260738.1",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "信息处理方法和装置",
    "inv": [
      "侯会满"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109840535A",
    "apn": "CN201711228122.6",
    "apd": "2017-11-29",
    "apy": "2017",
    "tit": "实现地形分类的方法和装置",
    "inv": [
      "张慧"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109816595A",
    "apn": "CN201711155921.5",
    "apd": "2017-11-20",
    "apy": "2017",
    "tit": "图像处理方法和装置",
    "inv": [
      "吕晓磊",
      "刘享军",
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109801354A",
    "apn": "CN201711146103.9",
    "apd": "2017-11-17",
    "apy": "2017",
    "tit": "全景处理方法和装置",
    "inv": [
      "胡三波",
      "黄修勇",
      "程启帆",
      "王卓",
      "胡义勇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109784129A",
    "apn": "CN201711122667.9",
    "apd": "2017-11-14",
    "apy": "2017",
    "tit": "信息输出方法和装置",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109767278A",
    "apn": "CN201711096374.8",
    "apd": "2017-11-09",
    "apy": "2017",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109767150A",
    "apn": "CN201711096716.6",
    "apd": "2017-11-09",
    "apy": "2017",
    "tit": "信息推送方法和装置",
    "inv": [
      "万昭良"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109753983A",
    "apn": "CN201711084085.6",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "图像分类方法、装置和计算机可读存储介质",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109747655A",
    "apn": "CN201711082002.X",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "用于自动驾驶车辆的驾驶指令生成方法和装置",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109753985A",
    "apn": "CN201711084117.2",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "视频分类方法及装置",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109754412A",
    "apn": "CN201711084132.7",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "目标跟踪方法、目标跟踪装置及计算机可读存储介质",
    "inv": [
      "门春雷"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109753984A",
    "apn": "CN201711084116.8",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "视频分类方法、装置和计算机可读存储介质",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110069728A",
    "apn": "CN201711037437.2",
    "apd": "2017-10-30",
    "apy": "2017",
    "tit": "用于展示图片的方法及装置",
    "inv": [
      "伍利兵"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109726615A",
    "apn": "CN201711036002.6",
    "apd": "2017-10-30",
    "apy": "2017",
    "tit": "一种道路边界的识别方法和装置",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109710054A",
    "apn": "CN201711014969.4",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "用于头戴式显示设备的虚拟物体呈现方法和装置",
    "inv": [
      "李俊涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN109711227A",
    "apn": "CN201711007761.X",
    "apd": "2017-10-25",
    "apy": "2017",
    "tit": "交通灯识别方法、交通灯识别装置及计算机可读存储介质",
    "inv": [
      "刘丹"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697499A",
    "apn": "CN201711002265.5",
    "apd": "2017-10-24",
    "apy": "2017",
    "tit": "行人流量漏斗生成方法及装置、存储介质、电子设备",
    "inv": [
      "车广富",
      "陈宇",
      "安山",
      "刘强",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697724A",
    "apn": "CN201711003830.X",
    "apd": "2017-10-24",
    "apy": "2017",
    "tit": "视频图像分割方法及装置、存储介质、电子设备",
    "inv": [
      "安山",
      "朱兆琪",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109697481A",
    "apn": "CN201710995931.3",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "用于确定物品标签的方法和装置",
    "inv": [
      "刘晓",
      "谭志羽",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697689A",
    "apn": "CN201710994493.9",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "存储介质、电子设备、视频合成方法及装置",
    "inv": [
      "车广富",
      "安山",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109697451A",
    "apn": "CN201710994492.4",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "相似图像聚类方法及装置、存储介质、电子设备",
    "inv": [
      "黄志标",
      "安山",
      "陈宇",
      "貟雯婷",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN109697722A",
    "apn": "CN201710994495.8",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "用于生成三分图的方法及装置",
    "inv": [
      "安山",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN109697445A",
    "apn": "CN201710995040.8",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "图片处理方法和装置",
    "inv": [
      "谭志羽",
      "陈宇",
      "刘晓",
      "张屹峰",
      "刘强",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109698910A",
    "apn": "CN201710996008.1",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "高动态范围图片的生成方法及装置、电子设备、存储介质",
    "inv": [
      "安山",
      "陈宇",
      "车广富",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109697622A",
    "apn": "CN201710995075.1",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "基于智能店铺的信息生成方法和装置",
    "inv": [
      "刘巍",
      "陈宇",
      "王耀华",
      "刘强",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697623A",
    "apn": "CN201710995704.0",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "刘巍",
      "陈宇",
      "董玉新",
      "刘强",
      "丁岩",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697393A",
    "apn": "CN201710996063.0",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "人物跟踪方法、装置、电子装置及计算机可读介质",
    "inv": [
      "周佩明",
      "叶韵",
      "张爱喜",
      "武军晖",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697583A",
    "apn": "CN201710994933.0",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "信息生成方法及装置",
    "inv": [
      "刘晓",
      "陈宇",
      "谢大斌",
      "谭志羽",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697786A",
    "apn": "CN201710993770.4",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "用于售货机的操作方法和操作装置",
    "inv": [
      "张钟毓",
      "陈宇",
      "刘魏",
      "刘强",
      "谢大斌",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697389A",
    "apn": "CN201710994597.X",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "身份识别方法与装置",
    "inv": [
      "张爱喜",
      "叶韵",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697390A",
    "apn": "CN201710995274.2",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "行人检测方法、装置、介质及电子设备",
    "inv": [
      "武军晖",
      "叶韵",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697801A",
    "apn": "CN201710994598.4",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "自助结算设备、方法、装置、介质及电子设备",
    "inv": [
      "周鸣",
      "陈宇",
      "王永杰",
      "冯亦军",
      "刘强",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697388A",
    "apn": "CN201710994568.3",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "人脸识别方法和装置",
    "inv": [
      "刘巍",
      "陈宇",
      "刘强",
      "殷向阳",
      "王耀华",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697391A",
    "apn": "CN201710996027.4",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "封闭场所内人物再识别的方法、系统及终端设备",
    "inv": [
      "周佩明",
      "陈宇",
      "叶韵",
      "张爱喜",
      "武军晖",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109697387A",
    "apn": "CN201710994357.X",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "运动方向预测方法、装置、电子设备及存储介质",
    "inv": [
      "黄志标",
      "安山",
      "麻晓珍",
      "车广富",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109697610A",
    "apn": "CN201710996016.6",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "订单操作方法和装置",
    "inv": [
      "刘强",
      "周鸣",
      "李世爽",
      "张钟毓",
      "殷向阳",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107766492A",
    "apn": "CN201710970829.8",
    "apd": "2017-10-18",
    "apy": "2017",
    "tit": "一种图像搜索的方法和装置",
    "inv": [
      "李耀强",
      "王春明",
      "要忠伟",
      "朱文斌"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN109684573A",
    "apn": "CN201710971535.7",
    "apd": "2017-10-18",
    "apy": "2017",
    "tit": "目标图片显示方法及装置、存储介质、电子设备",
    "inv": [
      "凌刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107798344A",
    "apn": "CN201710963258.5",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "图像识别方法及装置、计算机可读介质",
    "inv": [
      "朱德伟",
      "李树前",
      "李伟奇",
      "稂顾"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107845116A",
    "apn": "CN201710960042.3",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "生成平面图像的压缩编码的方法和装置",
    "inv": [
      "汪振华",
      "陈宇",
      "赵士超",
      "麻晓珍",
      "安山",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110019905A",
    "apn": "CN201710950506.2",
    "apd": "2017-10-13",
    "apy": "2017",
    "tit": "信息输出方法和装置",
    "inv": [
      "李耀强",
      "王春明",
      "要忠伟",
      "朱文斌"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN107832338A",
    "apn": "CN201710946537.0",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "一种识别核心产品词的方法和系统",
    "inv": [
      "马超义"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109657522A",
    "apn": "CN201710933873.1",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "检测可行驶区域的方法和装置",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN109657523A",
    "apn": "CN201710934657.9",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "一种可行驶区域检测方法和装置",
    "inv": [
      "张立成"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN107729931A",
    "apn": "CN201710930673.0",
    "apd": "2017-10-09",
    "apy": "2017",
    "tit": "图片评分方法和装置",
    "inv": [
      "陆韬"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "质量评价"
  },
  {
    "entity": "京东",
    "pun": "CN107642935A",
    "apn": "CN201710911779.6",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "智能冰箱及基于智能冰箱的拍摄的方法",
    "inv": [
      "邬栋",
      "何祎鑫",
      "曾祥云",
      "李鲁"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109559343A",
    "apn": "CN201710889225.0",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "用于容器的图像处理方法和装置",
    "inv": [
      "周鸣",
      "邹宇",
      "曾祥云",
      "关煜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107597600B",
    "apn": "CN201710881676.X",
    "apd": "2017-09-26",
    "apy": "2017",
    "tit": "分拣系统和分拣方法",
    "inv": [
      "刘敏",
      "章根云"
    ],
    "app": [
      "北京京东振世信息技术有限公司"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN107621947A",
    "apn": "CN201710867562.X",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "信息显示系统、方法和装置",
    "inv": [
      "魏军龙",
      "沈剑"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107632751A",
    "apn": "CN201710866519.1",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "信息显示方法和装置",
    "inv": [
      "魏军龙",
      "沈剑",
      "冯松浩"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107608650A",
    "apn": "CN201710844319.6",
    "apd": "2017-09-18",
    "apy": "2017",
    "tit": "图形的展示方法、系统及电子设备",
    "inv": [
      "莫文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107578052A",
    "apn": "CN201710839393.9",
    "apd": "2017-09-15",
    "apy": "2017",
    "tit": "货品处理方法及系统",
    "inv": [
      "吴立薪",
      "吕晶晶"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN110069727A",
    "apn": "CN201710839358.7",
    "apd": "2017-09-15",
    "apy": "2017",
    "tit": "图片处理的方法和装置",
    "inv": [
      "王凯"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109474826A",
    "apn": "CN201710804711.8",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "图片压缩方法、装置、电子设备及存储介质",
    "inv": [
      "杨丽敏",
      "刘诏",
      "薛韬"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109472740A",
    "apn": "CN201710804941.4",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "基于Web前端生成图形的方法和装置",
    "inv": [
      "梅继爽"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN110019865A",
    "apn": "CN201710804971.5",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "海量图片处理方法、装置、电子设备及存储介质",
    "inv": [
      "杨丽敏",
      "刘诏",
      "薛韬"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109472824A",
    "apn": "CN201710801864.7",
    "apd": "2017-09-07",
    "apy": "2017",
    "tit": "物品位置变化检测方法及装置、存储介质、电子设备",
    "inv": [
      "黄志标",
      "安山",
      "车广富",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109471682A",
    "apn": "CN201710799082.4",
    "apd": "2017-09-07",
    "apy": "2017",
    "tit": "用于设置图片热区的方法和装置",
    "inv": [
      "徐俊"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107562845A",
    "apn": "CN201710747528.9",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "推送方法、系统及电子设备",
    "inv": [
      "王玉",
      "李满天",
      "吴傲寒",
      "徐吉兴"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107390994A",
    "apn": "CN201710636694.1",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "界面呈现方法和装置",
    "inv": [
      "冯义华"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109309612A",
    "apn": "CN201710625168.5",
    "apd": "2017-07-27",
    "apy": "2017",
    "tit": "邮件内容生成方法及装置、存储介质和电子设备",
    "inv": [
      "杨再举"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN109272526A",
    "apn": "CN201710597498.8",
    "apd": "2017-07-17",
    "apy": "2017",
    "tit": "图像处理方法、系统及电子设备",
    "inv": [
      "安山",
      "车广富",
      "麻晓珍",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN109214979A",
    "apn": "CN201710537524.8",
    "apd": "2017-07-04",
    "apy": "2017",
    "tit": "用于在全景视频中融合对象的方法和装置",
    "inv": [
      "何进萍",
      "赵刚",
      "李文博",
      "彭碧"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107330470A",
    "apn": "CN201710537368.5",
    "apd": "2017-07-04",
    "apy": "2017",
    "tit": "识别图片的方法和装置",
    "inv": [
      "强晶晶"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107293098A",
    "apn": "CN201710468491.6",
    "apd": "2017-06-20",
    "apy": "2017",
    "tit": "过期提醒的方法、装置、终端和计算机可读介质",
    "inv": [
      "李双全",
      "沈剑"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN109145681A",
    "apn": "CN201710457547.8",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "用于判断目标旋转方向的方法及装置",
    "inv": [
      "车广富",
      "安山",
      "麻晓珍",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107133221A",
    "apn": "CN201710431842.6",
    "apd": "2017-06-09",
    "apy": "2017",
    "tit": "信息审核方法、装置、计算机可读介质和电子设备",
    "inv": [
      "张之硕"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "质量评价"
  },
  {
    "entity": "京东",
    "pun": "CN109003225A",
    "apn": "CN201710397039.5",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "一种多宫格图片处理方法和装置以及一种电子设备",
    "inv": [
      "李冬峰",
      "刘荣明"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107220652A",
    "apn": "CN201710400582.6",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "用于处理图片的方法和装置",
    "inv": [
      "叶韵",
      "张爱喜",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107330715A",
    "apn": "CN201710400244.2",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "选择图片广告素材的方法和装置",
    "inv": [
      "张波",
      "王玉",
      "李满天"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "质量评价"
  },
  {
    "entity": "京东",
    "pun": "CN107169468A",
    "apn": "CN201710398783.7",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "用于控制车辆的方法和装置",
    "inv": [
      "王帅强",
      "张潮",
      "张连川",
      "李政"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108932621A",
    "apn": "CN201710395315.4",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "安全性验证方法和装置",
    "inv": [
      "朱德伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108965574A",
    "apn": "CN201710382785.7",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "图像处理方法和装置",
    "inv": [
      "叶志彬"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107147827A",
    "apn": "CN201710356591.X",
    "apd": "2017-05-19",
    "apy": "2017",
    "tit": "图像采集的方法和装置",
    "inv": [
      "邬栋",
      "何祎鑫",
      "张诚",
      "李鲁"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN106956266A",
    "apn": "CN201710341099.5",
    "apd": "2017-05-16",
    "apy": "2017",
    "tit": "机器人控制方法、装置和机器人",
    "inv": [
      "宋鹏",
      "于宗靖",
      "张潮",
      "牟广森"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107122806A",
    "apn": "CN201710341942.X",
    "apd": "2017-05-16",
    "apy": "2017",
    "tit": "一种敏感图像识别方法及装置",
    "inv": [
      "叶韵",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107123142A",
    "apn": "CN201710321322.X",
    "apd": "2017-05-09",
    "apy": "2017",
    "tit": "位姿估计方法和装置",
    "inv": [
      "孙志明",
      "张潮",
      "李雨倩",
      "吴迪",
      "樊晨",
      "李政",
      "贾士伟",
      "李祎翔",
      "张连川",
      "刘新月"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108876925A",
    "apn": "CN201710320055.4",
    "apd": "2017-05-09",
    "apy": "2017",
    "tit": "虚拟现实场景处理方法和装置",
    "inv": [
      "何进萍",
      "赵刚",
      "李文博"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN108804476A",
    "apn": "CN201710311538.8",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "图像搜索结果的排序方法、装置、电子设备和存储介质",
    "inv": [
      "李旭峰"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN108805123A",
    "apn": "CN201710311735.X",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "一种时限提醒的装置、方法和系统",
    "inv": [
      "何祎鑫",
      "郑磊",
      "曾祥云",
      "关煜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107146245A",
    "apn": "CN201710312028.2",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "图像匹配方法和装置",
    "inv": [
      "孙祖鑫"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN108804475A",
    "apn": "CN201710311228.6",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "搜索颜色相似图片的方法和装置",
    "inv": [
      "安山",
      "陈宇",
      "麻晓珍",
      "车广富"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN108804466A",
    "apn": "CN201710307530.4",
    "apd": "2017-05-04",
    "apy": "2017",
    "tit": "服饰商品在线搭配的方法和装置",
    "inv": [
      "黄海锋",
      "周洲",
      "周园"
    ],
    "app": [
      "北京京东世纪贸易有限公司",
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107123127A",
    "apn": "CN201710287405.1",
    "apd": "2017-04-27",
    "apy": "2017",
    "tit": "一种图像主体提取方法及装置",
    "inv": [
      "汪振华",
      "陈宇",
      "麻晓珍"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN107066609A",
    "apn": "CN201710298744.X",
    "apd": "2017-04-27",
    "apy": "2017",
    "tit": "图片显示方法和装置",
    "inv": [
      "吴迪",
      "王琦",
      "王孝满",
      "郝思远",
      "高睿",
      "石凌宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN108734185A",
    "apn": "CN201710252662.1",
    "apd": "2017-04-18",
    "apy": "2017",
    "tit": "图像校验方法和装置",
    "inv": [
      "武军晖",
      "叶韵",
      "陈宇",
      "王磊",
      "曾锐南",
      "郑永胜",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108694719A",
    "apn": "CN201710217139.5",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "图像输出方法和装置",
    "inv": [
      "安山",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN108626959A",
    "apn": "CN201710158725.7",
    "apd": "2017-03-16",
    "apy": "2017",
    "tit": "信息管理方法、系统、智能冷藏设备和智能家居系统",
    "inv": [
      "关煜",
      "郑磊",
      "曾祥云",
      "何祎鑫"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108626960A",
    "apn": "CN201710158743.5",
    "apd": "2017-03-16",
    "apy": "2017",
    "tit": "信息推送方法、系统、智能冷藏设备和智能家居系统",
    "inv": [
      "关煜",
      "郑磊",
      "曾祥云",
      "何祎鑫"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108629232A",
    "apn": "CN201710158742.0",
    "apd": "2017-03-16",
    "apy": "2017",
    "tit": "信息推送方法、系统、智能冷藏设备和智能家居系统",
    "inv": [
      "关煜",
      "郑磊",
      "曾祥云",
      "何祎鑫"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108629224B",
    "apn": "CN201710152564.0",
    "apd": "2017-03-15",
    "apy": "2017",
    "tit": "信息呈现方法和装置",
    "inv": [
      "李川",
      "游正朋"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108629815A",
    "apn": "CN201710152538.8",
    "apd": "2017-03-15",
    "apy": "2017",
    "tit": "图像生成方法和装置",
    "inv": [
      "李川"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN108573192A",
    "apn": "CN201710136264.3",
    "apd": "2017-03-09",
    "apy": "2017",
    "tit": "匹配人脸的眼镜试戴方法和装置",
    "inv": [
      "彭明超",
      "赵紫星",
      "汤鹏飞",
      "王远斌",
      "白铖"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN106886599A",
    "apn": "CN201710111646.0",
    "apd": "2017-02-28",
    "apy": "2017",
    "tit": "图像检索方法以及装置",
    "inv": [
      "安山",
      "陈宇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN106909901A",
    "apn": "CN201710112435.9",
    "apd": "2017-02-28",
    "apy": "2017",
    "tit": "从图像中检测物体的方法及装置",
    "inv": [
      "刘晓",
      "谭志羽",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN106846122A",
    "apn": "CN201710089504.9",
    "apd": "2017-02-20",
    "apy": "2017",
    "tit": "商品数据处理方法和装置",
    "inv": [
      "葛彦昊",
      "刘巍",
      "陈宇",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108399514A",
    "apn": "CN201710069658.1",
    "apd": "2017-02-08",
    "apy": "2017",
    "tit": "可监控新鲜度的生鲜配送提示方法和装置",
    "inv": [
      "石重"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108397974A",
    "apn": "CN201710064517.0",
    "apd": "2017-02-04",
    "apy": "2017",
    "tit": "食品管理方法及装置",
    "inv": [
      "魏军龙",
      "沈剑",
      "冯松浩",
      "李双全"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108346294A",
    "apn": "CN201710054133.0",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "车辆识别系统、方法和装置",
    "inv": [
      "何国兴"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108347451A",
    "apn": "CN201710049548.9",
    "apd": "2017-01-23",
    "apy": "2017",
    "tit": "图片处理系统、方法和装置",
    "inv": [
      "张之硕",
      "乔明月"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN108345891A",
    "apn": "CN201710048396.0",
    "apd": "2017-01-23",
    "apy": "2017",
    "tit": "图书轮廓提取方法和装置",
    "inv": [
      "叶韵",
      "武军晖",
      "陈宇",
      "王磊",
      "曾锐南",
      "郑永胜",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN108346156A",
    "apn": "CN201710045890.1",
    "apd": "2017-01-22",
    "apy": "2017",
    "tit": "用户身材参数获取方法和装置",
    "inv": [
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN106897751A",
    "apn": "CN201710033865.1",
    "apd": "2017-01-16",
    "apy": "2017",
    "tit": "基于AR技术的人工辅助方法、装置、系统和上位机",
    "inv": [
      "胡金星"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN108288064A",
    "apn": "CN201710012538.8",
    "apd": "2017-01-09",
    "apy": "2017",
    "tit": "用于生成图片的方法和装置",
    "inv": [
      "陈标龙",
      "王永亮",
      "王青泽"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN108282600A",
    "apn": "CN201710011252.8",
    "apd": "2017-01-06",
    "apy": "2017",
    "tit": "冰箱内部图像捕获装置以及冰箱",
    "inv": [
      "何祎鑫",
      "李鲁",
      "邬栋",
      "张诚",
      "曾祥云"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108151432A",
    "apn": "CN201611101681.6",
    "apd": "2016-12-02",
    "apy": "2016",
    "tit": "智能冷藏设备及其方法",
    "inv": [
      "关煜",
      "郑磊",
      "陈逸风",
      "何祎鑫",
      "曾祥云"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN108073934A",
    "apn": "CN201611020242.2",
    "apd": "2016-11-17",
    "apy": "2016",
    "tit": "近重复图像检测方法与装置",
    "inv": [
      "安山",
      "陈宇",
      "黄志标",
      "汪振华",
      "麻晓珍",
      "翁志"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN108072228A",
    "apn": "CN201610983519.5",
    "apd": "2016-11-08",
    "apy": "2016",
    "tit": "智能冷藏设备的信息处理方法和装置",
    "inv": [
      "关煜",
      "郑磊",
      "陈逸风",
      "何祎鑫",
      "曾祥云"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN106552772B",
    "apn": "CN201610940584.X",
    "apd": "2016-10-25",
    "apy": "2016",
    "tit": "视觉识别系统和使用所述视觉识别系统的分类分拣系统",
    "inv": [
      "宋国库",
      "于宗靖"
    ],
    "app": [
      "天津京东深拓机器人科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘/取货",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN107957985A",
    "apn": "CN201610906145.7",
    "apd": "2016-10-18",
    "apy": "2016",
    "tit": "版式文档显示方法以及装置",
    "inv": [
      "莫文",
      "徐宏伟",
      "毕磊",
      "李平",
      "张喜亮"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN106228568A",
    "apn": "CN201610887396.5",
    "apd": "2016-10-11",
    "apy": "2016",
    "tit": "模糊图片检测方法和装置",
    "inv": [
      "安山"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN107870703A",
    "apn": "CN201610849878.1",
    "apd": "2016-09-26",
    "apy": "2016",
    "tit": "图片全屏展示的方法、系统及终端设备",
    "inv": [
      "莫文",
      "徐宏伟",
      "毕磊",
      "李平"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN106484248A",
    "apn": "CN201610832310.9",
    "apd": "2016-09-19",
    "apy": "2016",
    "tit": "电子书的阅读处理方法和装置",
    "inv": [
      "莫文",
      "徐宏伟",
      "罗浩源",
      "毕磊"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "电子书",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107798572A",
    "apn": "CN201610808866.4",
    "apd": "2016-09-07",
    "apy": "2016",
    "tit": "用于智能冰箱的购物触发方法",
    "inv": [
      "关煜",
      "陈逸风",
      "郑磊"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107796168A",
    "apn": "CN201610808751.5",
    "apd": "2016-09-07",
    "apy": "2016",
    "tit": "智能冰箱的食品信息管理方法",
    "inv": [
      "关煜",
      "陈逸风",
      "郑磊"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN106204235A",
    "apn": "CN201610567015.5",
    "apd": "2016-07-19",
    "apy": "2016",
    "tit": "物品显示方法、装置和系统",
    "inv": [
      "杨鹏"
    ],
    "app": [
      "北京京东振世信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107545221A",
    "apn": "CN201610495768.X",
    "apd": "2016-06-28",
    "apy": "2016",
    "tit": "婴儿踢被识别方法、系统及装置",
    "inv": [
      "李俊涛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105843972A",
    "apn": "CN201610420986.7",
    "apd": "2016-06-13",
    "apy": "2016",
    "tit": "产品属性信息的比对方法及装置",
    "inv": [
      "安山",
      "陈宇",
      "黄志标"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN107450716A",
    "apn": "CN201610374962.2",
    "apd": "2016-05-31",
    "apy": "2016",
    "tit": "屏幕显示控制方法以及控制装置",
    "inv": [
      "朱德伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN107437236A",
    "apn": "CN201610366102.4",
    "apd": "2016-05-27",
    "apy": "2016",
    "tit": "一种图片模糊处理方法及装置",
    "inv": [
      "冯伟平"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN106055219B",
    "apn": "CN201610354995.0",
    "apd": "2016-05-26",
    "apy": "2016",
    "tit": "提高用户阅读电子书体验的方法、装置及系统",
    "inv": [
      "张喜亮",
      "熊健南",
      "莫文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "电子书",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN107346237A",
    "apn": "CN201610289385.7",
    "apd": "2016-05-04",
    "apy": "2016",
    "tit": "一种基于iOS系统的多宫格图片处理方法及装置",
    "inv": [
      "李冬峰",
      "王仲戬",
      "刘愉",
      "胡东奇"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN105844248A",
    "apn": "CN201610184874.6",
    "apd": "2016-03-29",
    "apy": "2016",
    "tit": "人脸检测方法和装置",
    "inv": [
      "车天博",
      "林熙东"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105608234A",
    "apn": "CN201610159289.0",
    "apd": "2016-03-18",
    "apy": "2016",
    "tit": "图像检索方法和装置",
    "inv": [
      "梅尚健"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN105823778A",
    "apn": "CN201610137288.6",
    "apd": "2016-03-10",
    "apy": "2016",
    "tit": "物品识别方法、装置及系统",
    "inv": [
      "关煜",
      "何祎鑫",
      "潘麟鑫",
      "王超"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105827954B",
    "apn": "CN201610136800.5",
    "apd": "2016-03-10",
    "apy": "2016",
    "tit": "用于容器的图像获取装置及图像处理系统",
    "inv": [
      "关煜",
      "何祎鑫",
      "潘麟鑫",
      "王超"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105809135A",
    "apn": "CN201610134599.7",
    "apd": "2016-03-09",
    "apy": "2016",
    "tit": "智能冷藏设备和监控智能冷藏设备的方法",
    "inv": [
      "关煜",
      "郑磊",
      "何祎鑫",
      "潘麟鑫",
      "张红美"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105809134A",
    "apn": "CN201610133486.5",
    "apd": "2016-03-09",
    "apy": "2016",
    "tit": "智能冷藏设备和监控智能冷藏设备的方法",
    "inv": [
      "关煜",
      "郑磊",
      "何祎鑫",
      "潘麟鑫",
      "张红美"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105677001A",
    "apn": "CN201610133722.3",
    "apd": "2016-03-09",
    "apy": "2016",
    "tit": "用于智能冷藏设备的信息处理方法和装置",
    "inv": [
      "关煜",
      "郑磊",
      "何祎鑫",
      "潘麟鑫",
      "张红美"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105758108B",
    "apn": "CN201610133589.1",
    "apd": "2016-03-09",
    "apy": "2016",
    "tit": "用于智能冷藏设备的信息反馈方法和装置",
    "inv": [
      "关煜",
      "郑磊",
      "何祎鑫",
      "潘麟鑫",
      "张红美"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105554040B",
    "apn": "CN201610105297.7",
    "apd": "2016-02-25",
    "apy": "2016",
    "tit": "远程视频监控方法和系统",
    "inv": [
      "朱德伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105787322B",
    "apn": "CN201610069036.4",
    "apd": "2016-02-01",
    "apy": "2016",
    "tit": "指纹识别的方法及装置、移动终端",
    "inv": [
      "朱德伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105551044B",
    "apn": "CN201510941073.5",
    "apd": "2015-12-16",
    "apy": "2015",
    "tit": "一种图片对比方法和装置",
    "inv": [
      "高立松"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "京东",
    "pun": "CN105469506A",
    "apn": "CN201510901319.6",
    "apd": "2015-12-09",
    "apy": "2015",
    "tit": "用于基于人脸识别实现产品提取的方法和装置",
    "inv": [
      "马飞"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105528701A",
    "apn": "CN201510903333.X",
    "apd": "2015-12-09",
    "apy": "2015",
    "tit": "用于基于人脸识别实现产品提取的方法和设备",
    "inv": [
      "马飞"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105335526A",
    "apn": "CN201510885462.0",
    "apd": "2015-12-04",
    "apy": "2015",
    "tit": "一种图片加载方法及装置",
    "inv": [
      "朱德伟"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN105511717B",
    "apn": "CN201510856945.8",
    "apd": "2015-11-30",
    "apy": "2015",
    "tit": "页面显示方法和页面显示装置",
    "inv": [
      "莫文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN105426347A",
    "apn": "CN201510733137.2",
    "apd": "2015-10-29",
    "apy": "2015",
    "tit": "提供对象信息的方法、显示终端和服务器",
    "inv": [
      "莫文"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN105159465B",
    "apn": "CN201510671738.5",
    "apd": "2015-10-16",
    "apy": "2015",
    "tit": "用户阅读理解度监测方法及系统",
    "inv": [
      "钟颖"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105159451B",
    "apn": "CN201510530316.6",
    "apd": "2015-08-26",
    "apy": "2015",
    "tit": "一种数字阅读的翻页方法和装置",
    "inv": [
      "钟颖"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105184231B",
    "apn": "CN201510501737.6",
    "apd": "2015-08-14",
    "apy": "2015",
    "tit": "一种储物空间的图像采集系统及方法",
    "inv": [
      "关煜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN105095435A",
    "apn": "CN201510436176.6",
    "apd": "2015-07-23",
    "apy": "2015",
    "tit": "一种图像高维特征的相似比较方法及装置",
    "inv": [
      "林熙东",
      "牟川"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN104991959B",
    "apn": "CN201510433542.2",
    "apd": "2015-07-21",
    "apy": "2015",
    "tit": "一种基于内容检索相同或相似图像的方法与系统",
    "inv": [
      "布如国",
      "牟川",
      "邢志峰",
      "林熙东"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN104881798A",
    "apn": "CN201510303163.1",
    "apd": "2015-06-05",
    "apy": "2015",
    "tit": "基于商品图像特征的个性化搜索装置及方法",
    "inv": [
      "布如国",
      "牟川"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN104867125B",
    "apn": "CN201510304165.2",
    "apd": "2015-06-04",
    "apy": "2015",
    "tit": "获取图像的方法以及装置",
    "inv": [
      "景亮",
      "石东",
      "殷俊"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104966087B",
    "apn": "CN201510296011.3",
    "apd": "2015-06-02",
    "apy": "2015",
    "tit": "图像SIFT条纹特征滤除方法以及装置",
    "inv": [
      "杨德坤",
      "郑宸",
      "贺炜"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104869316B",
    "apn": "CN201510289784.9",
    "apd": "2015-05-29",
    "apy": "2015",
    "tit": "一种多目标的摄像方法及装置",
    "inv": [
      "石东旭",
      "景亮"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104699769B",
    "apn": "CN201510091354.6",
    "apd": "2015-02-28",
    "apy": "2015",
    "tit": "基于表情识别的交互方法和执行该方法的设备",
    "inv": [
      "张斯聪",
      "刘旭"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN104573131B",
    "apn": "CN201510082754.0",
    "apd": "2015-02-15",
    "apy": "2015",
    "tit": "生成商品详情页面数据的方法和终端装置",
    "inv": [
      "陈鹏飞",
      "裴建东",
      "杨凯",
      "周凌",
      "高飞",
      "赵迅雷",
      "黄自立",
      "黎源",
      "王卫娇",
      "苟建军",
      "游媛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104598802A",
    "apn": "CN201510078781.0",
    "apd": "2015-02-13",
    "apy": "2015",
    "tit": "一种基于拼图的校验方法和系统",
    "inv": [
      "周伟",
      "刘冬"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "京东",
    "pun": "CN104616158B",
    "apn": "CN201510076550.6",
    "apd": "2015-02-13",
    "apy": "2015",
    "tit": "生成商品详情页面的方法和装置",
    "inv": [
      "裴建东",
      "杨凯",
      "周凌",
      "高飞",
      "赵迅雷",
      "黄自立",
      "黎源",
      "王卫姣",
      "苟建军",
      "游媛"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104536656B",
    "apn": "CN201410782448.3",
    "apd": "2014-12-16",
    "apy": "2014",
    "tit": "用于生成智能设备操作详情页面的方法和系统",
    "inv": [
      "刘志刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104392222A",
    "apn": "CN201410728496.4",
    "apd": "2014-12-03",
    "apy": "2014",
    "tit": "一种用于无人值守终端的消费留证方法",
    "inv": [
      "商春鹏"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN104407696B",
    "apn": "CN201410619473.X",
    "apd": "2014-11-06",
    "apy": "2014",
    "tit": "移动设备的虚拟球模拟及控制的方法",
    "inv": [
      "张斯聪"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN104217350B",
    "apn": "CN201410270449.X",
    "apd": "2014-06-17",
    "apy": "2014",
    "tit": "实现虚拟试戴的方法和装置",
    "inv": [
      "张斯聪"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN104008501B",
    "apn": "CN201410264313.8",
    "apd": "2014-06-13",
    "apy": "2014",
    "tit": "一种获取衣服图像属性点的方法和装置",
    "inv": [
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN104036543B",
    "apn": "CN201410230809.3",
    "apd": "2014-05-28",
    "apy": "2014",
    "tit": "旋转式虚拟衣架",
    "inv": [
      "张晓东",
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN103927724B",
    "apn": "CN201410180830.7",
    "apd": "2014-04-30",
    "apy": "2014",
    "tit": "一种处理上衣图像的方法和装置",
    "inv": [
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN103905643A",
    "apn": "CN201410116971.2",
    "apd": "2014-03-26",
    "apy": "2014",
    "tit": "一种单一背景图片手机适配方法及系统",
    "inv": [
      "赖钧龙",
      "毛锐",
      "余欢"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN103810687B",
    "apn": "CN201410073290.2",
    "apd": "2014-02-28",
    "apy": "2014",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "张斯聪",
      "邱鹏"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104867026A",
    "apn": "CN201410060050.9",
    "apd": "2014-02-21",
    "apy": "2014",
    "tit": "提供商品图像的方法和系统以及输出商品图像的终端装置",
    "inv": [
      "侯会满"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN104835043A",
    "apn": "CN201410049361.5",
    "apd": "2014-02-12",
    "apy": "2014",
    "tit": "一种呈现商品的图像的方法和装置",
    "inv": [
      "邱鹏",
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN103729430B",
    "apn": "CN201310731576.0",
    "apd": "2013-12-26",
    "apy": "2013",
    "tit": "生成图像文件的方法和装置",
    "inv": [
      "孙延好"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN103617638B",
    "apn": "CN201310653333.X",
    "apd": "2013-12-05",
    "apy": "2013",
    "tit": "图像处理的方法及装置",
    "inv": [
      "张斯聪",
      "邱鹏"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN103578108B",
    "apn": "CN201310549158.X",
    "apd": "2013-11-07",
    "apy": "2013",
    "tit": "一种图像背景的去除方法",
    "inv": [
      "王波"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN103559690B",
    "apn": "CN201310541007.X",
    "apd": "2013-11-05",
    "apy": "2013",
    "tit": "一种实现图像边沿平滑的方法",
    "inv": [
      "王波"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN103440580B",
    "apn": "CN201310378649.2",
    "apd": "2013-08-27",
    "apy": "2013",
    "tit": "一种提供虚拟试衣的衣物图像的方法和装置",
    "inv": [
      "张晓东",
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN103456032B",
    "apn": "CN201310361579.X",
    "apd": "2013-08-19",
    "apy": "2013",
    "tit": "一种处理虚拟试衣模特图像的方法和装置",
    "inv": [
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN103489107B",
    "apn": "CN201310359012.9",
    "apd": "2013-08-16",
    "apy": "2013",
    "tit": "一种制作虚拟试衣模特图像的方法和装置",
    "inv": [
      "赵刚"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "京东",
    "pun": "CN103399900B",
    "apn": "CN201310316446.0",
    "apd": "2013-07-25",
    "apy": "2013",
    "tit": "基于位置服务的图片推荐方法",
    "inv": [
      "陈超"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN103268338B",
    "apn": "CN201310181762.1",
    "apd": "2013-05-16",
    "apy": "2013",
    "tit": "一种提供图像的方法和服务器装置以及终端装置",
    "inv": [
      "甘永洲",
      "井振刚",
      "侯会满",
      "熊宇红",
      "靳简明"
    ],
    "app": [
      "北京京东尚科信息技术有限公司",
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN103116876B",
    "apn": "CN201310057747.6",
    "apd": "2013-02-22",
    "apy": "2013",
    "tit": "一种图像去雾的方法和装置",
    "inv": [
      "林祥"
    ],
    "app": [
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN103903249B",
    "apn": "CN201210581470.2",
    "apd": "2012-12-27",
    "apy": "2012",
    "tit": "图像匹配系统及方法",
    "inv": [
      "李林冲"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN103020172B",
    "apn": "CN201210492104.X",
    "apd": "2012-11-28",
    "apy": "2012",
    "tit": "一种利用视频信息搜索物品的方法和装置",
    "inv": [
      "王亚卿"
    ],
    "app": [
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN103581110A",
    "apn": "CN201210251823.2",
    "apd": "2012-07-19",
    "apy": "2012",
    "tit": "面部识别登录购物系统和方法",
    "inv": [
      "张旭"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN102779157B",
    "apn": "CN201210183184.0",
    "apd": "2012-06-06",
    "apy": "2012",
    "tit": "搜索图像的方法和装置",
    "inv": [
      "甘永洲",
      "井振刚",
      "邓正平"
    ],
    "app": [
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN102810160A",
    "apn": "CN201210185208.6",
    "apd": "2012-06-06",
    "apy": "2012",
    "tit": "一种搜索图像的方法与装置",
    "inv": [
      "甘永洲",
      "井振刚",
      "邓正平"
    ],
    "app": [
      "北京京东世纪贸易有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "京东",
    "pun": "CN102750535B",
    "apn": "CN201210094365.6",
    "apd": "2012-04-01",
    "apy": "2012",
    "tit": "自动提取图像前景的方法和系统",
    "inv": [
      "甘永洲"
    ],
    "app": [
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "京东",
    "pun": "CN102402765B",
    "apn": "CN201110447859.3",
    "apd": "2011-12-27",
    "apy": "2011",
    "tit": "基于用户表情分析的电子商务推荐方法",
    "inv": [
      "韩军"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "京东",
    "pun": "CN102075569A",
    "apn": "CN201010612751.0",
    "apd": "2010-12-15",
    "apy": "2010",
    "tit": "上传用于内容发布的多个图片的方法和装置",
    "inv": [
      "李翔",
      "李乐伟",
      "于国梁",
      "张伟",
      "孙蔚"
    ],
    "app": [
      "北京京东世纪贸易有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "依图",
    "pun": "CN103488973B",
    "apn": "CN201310416016.6",
    "apd": "2013-09-12",
    "apy": "2013",
    "tit": "基于图像的车辆品牌识别方法和系统",
    "inv": [
      "朱珑",
      "林晨曦",
      "陈远浩",
      "戎术",
      "周元剑"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN103634118B",
    "apn": "CN201310671661.2",
    "apd": "2013-12-12",
    "apy": "2013",
    "tit": "基于证卡和复合生物特征识别的生存认证方法",
    "inv": [
      "王继春",
      "孙涛"
    ],
    "app": [
      "神思依图(北京)科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN104298967B",
    "apn": "CN201410482824.7",
    "apd": "2014-09-19",
    "apy": "2014",
    "tit": "一种基于视觉特征的车辆图像比对方法",
    "inv": [
      "朱珑",
      "陈远浩"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN104239531B",
    "apn": "CN201410483587.6",
    "apd": "2014-09-19",
    "apy": "2014",
    "tit": "一种基于局部视觉特征的精确比对方法",
    "inv": [
      "朱珑",
      "陈远浩"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN104680133B",
    "apn": "CN201510058281.0",
    "apd": "2015-02-04",
    "apy": "2015",
    "tit": "一种违规车让人行为实时检测方法",
    "inv": [
      "朱珑",
      "陈远浩"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN104680793B",
    "apn": "CN201510058285.9",
    "apd": "2015-02-04",
    "apy": "2015",
    "tit": "一种大货车违章上高架报警方法",
    "inv": [
      "朱珑",
      "胡地雷",
      "范可佳"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN104680787B",
    "apn": "CN201510058449.8",
    "apd": "2015-02-04",
    "apy": "2015",
    "tit": "一种道路拥堵检测方法",
    "inv": [
      "朱珑",
      "陈远浩"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN104683765B",
    "apn": "CN201510058446.4",
    "apd": "2015-02-04",
    "apy": "2015",
    "tit": "一种基于移动物体侦测的视频浓缩方法",
    "inv": [
      "朱珑",
      "陈远浩"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN104658254B",
    "apn": "CN201510102547.7",
    "apd": "2015-03-09",
    "apy": "2015",
    "tit": "一种交通视频的摩托车检测方法",
    "inv": [
      "陈远浩"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN104715614B",
    "apn": "CN201510102368.3",
    "apd": "2015-03-09",
    "apy": "2015",
    "tit": "一种嫌疑套牌车捕获方法",
    "inv": [
      "庄唯",
      "李响"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN104657724A",
    "apn": "CN201510108408.5",
    "apd": "2015-03-12",
    "apy": "2015",
    "tit": "一种交通视频行人检测方法",
    "inv": [
      "陈远浩",
      "蔡允岚"
    ],
    "app": [
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN104794465B",
    "apn": "CN201510243779.4",
    "apd": "2015-05-13",
    "apy": "2015",
    "tit": "一种基于姿态信息的活体检测方法",
    "inv": [
      "陈远浩"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN104794464B",
    "apn": "CN201510243778.X",
    "apd": "2015-05-13",
    "apy": "2015",
    "tit": "一种基于相对属性的活体检测方法",
    "inv": [
      "陈远浩"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN105069408B",
    "apn": "CN201510442174.8",
    "apd": "2015-07-24",
    "apy": "2015",
    "tit": "一种复杂场景下基于人脸识别的视频人像跟踪方法",
    "inv": [
      "张至先"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN105046227B",
    "apn": "CN201510442187.5",
    "apd": "2015-07-24",
    "apy": "2015",
    "tit": "一种针对人像视频系统的关键帧获取方法",
    "inv": [
      "陈远浩"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN106372601A",
    "apn": "CN201610790411.4",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "一种基于红外可见双目图像的活体检测方法及装置",
    "inv": [
      "陈远浩"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN106355154A",
    "apn": "CN201610793181.7",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "一种监控视频中检测频繁过人的方法",
    "inv": [
      "俞梦洁"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN107909581B",
    "apn": "CN201711070729.6",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "CT影像的肺叶段分割方法、装置、系统、存储介质及设备",
    "inv": [
      "郑永升",
      "戎术"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109584252A",
    "apn": "CN201811505228.0",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "基于深度学习的CT影像的肺叶段分割方法、装置",
    "inv": [
      "郑永升",
      "戎术"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109636811A",
    "apn": "CN201811506463.X",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "CT影像的肺叶段分割的整合方法、装置",
    "inv": [
      "郑永升",
      "戎术"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109615636A",
    "apn": "CN201811505220.4",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "CT影像的肺叶段分割中的血管树构造方法、装置",
    "inv": [
      "郑永升",
      "戎术"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN108056786A",
    "apn": "CN201711296094.1",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "一种基于深度学习的骨龄检测方法和装置",
    "inv": [
      "傅君芬",
      "倪浩",
      "赖灿",
      "郑永升",
      "俞刚"
    ],
    "app": [
      "浙江大学",
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN107977647A",
    "apn": "CN201711385814.1",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "一种适合公安实战的人脸识别算法评测方法",
    "inv": [
      "俞梦洁",
      "陈远浩",
      "庄唯",
      "胡雷地"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "苏州市公安局"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108648178A",
    "apn": "CN201810345297.3",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种影像结节检测的方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108629803A",
    "apn": "CN201810344657.8",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种结节倍增时间的确定方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108648179A",
    "apn": "CN201810345483.7",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种分析肺结节的方法及装置",
    "inv": [
      "丁泽震",
      "杨忠程",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108564044A",
    "apn": "CN201810344226.1",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种确定肺结节密度的方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108629764A",
    "apn": "CN201810345323.2",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "一种确定肺结节良恶性的方法及装置",
    "inv": [
      "丁泽震",
      "杨忠程",
      "魏子昆"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108717707A",
    "apn": "CN201810419012.6",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "一种结节匹配方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108717700A",
    "apn": "CN201810419492.6",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "一种检测结节长短径长度的方法及装置",
    "inv": [
      "魏子昆",
      "华铱炜",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108888294A",
    "apn": "CN201810419975.6",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "一种检测颈部透明带宽度的方法及装置",
    "inv": [
      "魏子昆",
      "华铱炜",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN108877925A",
    "apn": "CN201810420019.X",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "一种肺炎病原的确定方法及装置",
    "inv": [
      "魏子昆",
      "丁泽震",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN108648192A",
    "apn": "CN201810474907.X",
    "apd": "2018-05-17",
    "apy": "2018",
    "tit": "一种检测结节的方法及装置",
    "inv": [
      "魏子昆",
      "丁泽震",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN108765407A",
    "apn": "CN201810552094.1",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "一种人像图片质量判定方法及装置",
    "inv": [
      "张志齐"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "质量评价"
  },
  {
    "entity": "依图",
    "pun": "CN108921023A",
    "apn": "CN201810552092.2",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "一种确定低质量人像数据的方法及装置",
    "inv": [
      "张志齐"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "武汉依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "质量评价"
  },
  {
    "entity": "依图",
    "pun": "CN109033940B",
    "apn": "CN201810565420.2",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "一种图像识别方法、装置、计算设备及存储介质",
    "inv": [
      "陈华官"
    ],
    "app": [
      "上海依图智安人工智能有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109087261A",
    "apn": "CN201810874734.0",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "基于非受限采集场景的人脸矫正方法",
    "inv": [
      "张志先",
      "陈远浩",
      "孔屹平",
      "曹汉卿"
    ],
    "app": [
      "上海依图网络科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "依图",
    "pun": "CN109272002A",
    "apn": "CN201811161328.6",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "一种骨龄片的分类方法及装置",
    "inv": [
      "魏子昆",
      "华铱炜",
      "王琦"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109146879A",
    "apn": "CN201811163210.7",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "一种检测骨龄的方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "王琦"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109255786A",
    "apn": "CN201811163233.8",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "一种检测骨龄的方法及装置",
    "inv": [
      "魏子昆",
      "王琦",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109285154A",
    "apn": "CN201811161771.3",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "一种检测骨龄的方法及装置",
    "inv": [
      "魏子昆",
      "华铱炜",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109377484A",
    "apn": "CN201811163000.8",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "一种检测骨龄的方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109461144A",
    "apn": "CN201811202995.4",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种乳腺影像识别的方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109447088A",
    "apn": "CN201811201698.8",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种乳腺影像识别的方法及装置",
    "inv": [
      "魏子昆",
      "华铱炜",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109363699A",
    "apn": "CN201811203383.7",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种乳腺影像病灶识别的方法及装置",
    "inv": [
      "魏子昆",
      "丁泽震",
      "蔡嘉楠"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109363697A",
    "apn": "CN201811201699.2",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种乳腺影像病灶识别的方法及装置",
    "inv": [
      "魏子昆",
      "华铱炜",
      "蔡嘉楠"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109363698A",
    "apn": "CN201811202695.6",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种乳腺影像征象识别的方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109447065A",
    "apn": "CN201811202692.2",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种乳腺影像识别的方法及装置",
    "inv": [
      "魏子昆",
      "杨忠程",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109447963A",
    "apn": "CN201811227491.8",
    "apd": "2018-10-22",
    "apy": "2018",
    "tit": "一种脑部影像识别的方法及装置",
    "inv": [
      "鲁文斌",
      "魏子昆",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109509177A",
    "apn": "CN201811227495.6",
    "apd": "2018-10-22",
    "apy": "2018",
    "tit": "一种脑部影像识别的方法及装置",
    "inv": [
      "鲁文斌",
      "魏子昆",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109685787A",
    "apn": "CN201811572365.6",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "CT影像的肺叶段分割中的输出方法、装置",
    "inv": [
      "郑永升",
      "戎术"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109523546A",
    "apn": "CN201811570841.0",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种肺结节分析的方法及装置",
    "inv": [
      "倪浩",
      "郑永升",
      "石磊",
      "魏子昆",
      "翁静思",
      "张波",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109711315A",
    "apn": "CN201811570213.2",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种肺结节分析的方法及装置",
    "inv": [
      "王成弟",
      "李为民",
      "倪浩",
      "刘丹",
      "白红利",
      "邵俊",
      "任鹏伟",
      "李亚伦",
      "杨澜",
      "蒋宇婷",
      "余何",
      "郑永升",
      "方骢",
      "张波",
      "吴福乐",
      "华岳军",
      "曾颖异",
      "魏子昆"
    ],
    "app": [
      "四川大学华西医院",
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109523547A",
    "apn": "CN201811570843.X",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种影像结节检出的方法及装置",
    "inv": [
      "李为民",
      "刘丹",
      "倪浩",
      "王成弟",
      "白红利",
      "任鹏伟",
      "杨澜",
      "邵俊",
      "金晶",
      "甘芸翠",
      "鲜京宏",
      "郑永升",
      "方骢",
      "石磊",
      "杨忠程",
      "翁静思",
      "王虹鉴",
      "魏子昆"
    ],
    "app": [
      "四川大学华西医院",
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109801262A",
    "apn": "CN201811570204.3",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种肺结节分析的方法及装置",
    "inv": [
      "倪浩",
      "郑永升",
      "石磊",
      "魏子昆",
      "翁静思",
      "张波",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109801264A",
    "apn": "CN201811574653.5",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种肺结节分析的方法及装置",
    "inv": [
      "倪浩",
      "郑永升",
      "石磊",
      "魏子昆",
      "翁静思",
      "张波",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109801263A",
    "apn": "CN201811572275.7",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种肺结节分析的方法及装置",
    "inv": [
      "倪浩",
      "郑永升",
      "石磊",
      "魏子昆",
      "翁静思",
      "张波",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN109710780A",
    "apn": "CN201811626008.3",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109726674A",
    "apn": "CN201811620062.7",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种人脸识别方法及装置",
    "inv": [
      "陈双乐",
      "吕昊",
      "杨熹",
      "刘天树",
      "吴梓铭"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109558861A",
    "apn": "CN201811621478.0",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种确定路人轨迹的方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109800664A",
    "apn": "CN201811621540.6",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种确定路人轨迹的方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109815829A",
    "apn": "CN201811622813.9",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种确定路人轨迹的方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109784218A",
    "apn": "CN201811622452.8",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109784221A",
    "apn": "CN201811624188.1",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种监控方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109800329A",
    "apn": "CN201811629818.4",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种监控方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109784217A",
    "apn": "CN201811622441.X",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种监控方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109783663A",
    "apn": "CN201811621050.6",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109784220A",
    "apn": "CN201811624175.4",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种确定路人轨迹的方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109800669A",
    "apn": "CN201811624047.X",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "武汉依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800673A",
    "apn": "CN201811626737.9",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109815369A",
    "apn": "CN201811626066.6",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800668A",
    "apn": "CN201811624007.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109815370A",
    "apn": "CN201811626082.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800672A",
    "apn": "CN201811626344.8",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800666A",
    "apn": "CN201811622468.9",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800318A",
    "apn": "CN201811621536.X",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "武汉依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800674A",
    "apn": "CN201811626770.1",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109783664A",
    "apn": "CN201811624021.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109783685A",
    "apn": "CN201811621480.8",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种查询方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "武汉依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109740003A",
    "apn": "CN201811620219.6",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "武汉依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109783672A",
    "apn": "CN201811620204.X",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800322A",
    "apn": "CN201811626006.4",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种监控方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109857891A",
    "apn": "CN201811621479.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种查询方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "武汉依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109740004A",
    "apn": "CN201811624049.9",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "梁晓涛"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800675A",
    "apn": "CN201811641312.5",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种确定人脸对象的识别图像的方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109815841A",
    "apn": "CN201811643380.5",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中的人脸筛选方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109508703A",
    "apn": "CN201811643552.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中的人脸确定方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109726689A",
    "apn": "CN201811643292.5",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种归档方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109815840A",
    "apn": "CN201811641258.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种确定识别信息的方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109785960A",
    "apn": "CN201811644295.0",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种判别哮喘的方法及装置",
    "inv": [
      "倪浩",
      "郑永升",
      "石磊",
      "印宏坤",
      "颜泽鑫"
    ],
    "app": [
      "上海依智医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109740518A",
    "apn": "CN201811643551.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中对象的确定方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109800684A",
    "apn": "CN201811648088.2",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中对象的确定方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109740525A",
    "apn": "CN201811648118.X",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中对象的确定方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "依图",
    "pun": "CN109784238A",
    "apn": "CN201811641315.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种确定待识别对象的方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "北京依图网络科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109800680A",
    "apn": "CN201811646178.8",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种确定视频中的对象的方法及装置",
    "inv": [
      "俞梦洁",
      "杨博文"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "深圳依图信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109800685A",
    "apn": "CN201811648094.8",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中对象的确定方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "福建依图网络科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109740517A",
    "apn": "CN201811643419.3",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种确定待识别对象的方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109800679A",
    "apn": "CN201811643460.0",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种确定待识别对象的属性信息的方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109800678A",
    "apn": "CN201811643457.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中对象的属性确定方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109815842A",
    "apn": "CN201811643440.3",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种确定待识别对象的属性信息的方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN109614956A",
    "apn": "CN201811648086.3",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种视频中对象的识别方法及装置",
    "inv": [
      "万一木",
      "徐珺"
    ],
    "app": [
      "上海依图网络科技有限公司",
      "成都依图网络科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN109948429A",
    "apn": "CN201910081593.1",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "图像分析方法、装置、电子设备及计算机可读介质",
    "inv": [
      "倪浩",
      "郑永升",
      "石磊",
      "沈庆",
      "印宏坤",
      "杨俊"
    ],
    "app": [
      "上海依智医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110046627A",
    "apn": "CN201910271316.7",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "一种乳腺影像识别的方法及装置",
    "inv": [
      "魏子昆",
      "华铱炜",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110135271A",
    "apn": "CN201910316081.9",
    "apd": "2019-04-19",
    "apy": "2019",
    "tit": "一种细胞分类方法及装置",
    "inv": [
      "郑永升",
      "倪浩",
      "石磊",
      "沈庆",
      "印宏坤",
      "杨俊"
    ],
    "app": [
      "上海依智医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110335235A",
    "apn": "CN201910355512.2",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "心脏医学图像的处理装置、处理系统和介质",
    "inv": [
      "郑永升",
      "倪浩",
      "石磊",
      "印宏坤",
      "杨俊",
      "沈庆"
    ],
    "app": [
      "上海依智医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110335236A",
    "apn": "CN201910355517.5",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "心脏医学图像的处理方法、处理装置、处理系统和介质",
    "inv": [
      "石磊",
      "倪浩",
      "郑永升",
      "印宏坤",
      "杨俊",
      "沈庆"
    ],
    "app": [
      "上海依智医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110197713A",
    "apn": "CN201910388788.0",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "一种医疗影像的处理方法、装置、设备和介质",
    "inv": [
      "石磊",
      "倪浩",
      "郑永升",
      "庞琳",
      "史晶",
      "乔丽华"
    ],
    "app": [
      "上海依智医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110296506A",
    "apn": "CN201910509833.3",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "一种楼宇空调控制的方法及装置",
    "inv": [
      "杨晓艳",
      "张振杰",
      "周健"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN110390671A",
    "apn": "CN201910620175.5",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "一种乳腺钙化检出的方法及装置",
    "inv": [
      "石磊",
      "倪浩",
      "郑永升",
      "魏子昆",
      "蔡嘉楠"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110399913A",
    "apn": "CN201910631668.9",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "一种医学图像中拍摄部位的分类方法及装置",
    "inv": [
      "石磊",
      "倪浩",
      "郑永升",
      "曹一迪",
      "魏子昆",
      "王烨",
      "丁泽震",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110400295A",
    "apn": "CN201910654974.4",
    "apd": "2019-07-19",
    "apy": "2019",
    "tit": "一种肺结节相对位置确定的方法及装置",
    "inv": [
      "石磊",
      "倪浩",
      "郑永升",
      "魏子昆",
      "华铱炜",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN110400299A",
    "apn": "CN201910667643.4",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "一种肺部胸腔积液检出的方法及装置",
    "inv": [
      "石磊",
      "郑永升",
      "魏子昆",
      "丁泽震",
      "吴艺超"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110400302A",
    "apn": "CN201910677747.3",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "一种确定、显示乳房图像中病灶信息的方法及装置",
    "inv": [
      "石磊",
      "倪浩",
      "郑永升",
      "魏子昆",
      "蔡嘉楠",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN110400303A",
    "apn": "CN201910678444.3",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "一种确定、显示乳房图像中病灶的方法及装置",
    "inv": [
      "石磊",
      "倪浩",
      "郑永升",
      "魏子昆",
      "蔡嘉楠",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN110516715A",
    "apn": "CN201910717262.2",
    "apd": "2019-08-05",
    "apy": "2019",
    "tit": "一种手骨分类方法及装置",
    "inv": [
      "石磊",
      "郑永升",
      "魏子昆",
      "王琦",
      "杨忠程"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110517766A",
    "apn": "CN201910736591.1",
    "apd": "2019-08-09",
    "apy": "2019",
    "tit": "识别脑萎缩的方法及装置",
    "inv": [
      "倪浩",
      "郑永升",
      "石磊",
      "徐梦迪",
      "陈思杰"
    ],
    "app": [
      "上海依智医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN110517234A",
    "apn": "CN201910760746.5",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "特征骨异常检测方法及装置",
    "inv": [
      "郑永升",
      "石磊",
      "魏子昆",
      "王琦",
      "丁泽震"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110490891A",
    "apn": "CN201910784419.3",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "分割图像中关注对象的方法、设备和计算机可读存储介质",
    "inv": [
      "郑永升",
      "石磊",
      "曹一迪",
      "魏子昆",
      "蔡嘉楠"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "依图",
    "pun": "CN110503957A",
    "apn": "CN201910818956.5",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "一种基于图像去噪的语音识别方法及装置",
    "inv": [
      "李索恒",
      "汪俊",
      "郑达",
      "张志齐"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "依图",
    "pun": "CN110517295A",
    "apn": "CN201910818913.7",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "一种结合语音识别的实时人脸轨迹跟踪方法及装置",
    "inv": [
      "汪俊",
      "李索恒",
      "张志齐"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN110516755A",
    "apn": "CN201910818930.0",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "一种结合语音识别的身体轨迹实时跟踪方法及装置",
    "inv": [
      "汪俊",
      "李索恒",
      "张志齐"
    ],
    "app": [
      "上海依图信息技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "依图",
    "pun": "CN110473235A",
    "apn": "CN201910867347.9",
    "apd": "2019-09-12",
    "apy": "2019",
    "tit": "一种对齐两侧乳房图像的方法及装置",
    "inv": [
      "石磊",
      "魏子昆",
      "蔡嘉楠",
      "华铱炜"
    ],
    "app": [
      "杭州依图医疗技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN102013020B",
    "apn": "CN200910173702.9",
    "apd": "2009-09-08",
    "apy": "2009",
    "tit": "用于合成人脸图像的方法和系统",
    "inv": [
      "王晓刚",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN103080979B",
    "apn": "CN201080068894.8",
    "apd": "2010-09-03",
    "apy": "2010",
    "tit": "从照片合成肖像素描的系统和方法",
    "inv": [
      "王晓刚",
      "汤晓鸥",
      "张伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN105981050B",
    "apn": "CN201380081289.8",
    "apd": "2013-11-30",
    "apy": "2013",
    "tit": "用于从人脸图像的数据提取人脸特征的方法和系统",
    "inv": [
      "汤晓鸥",
      "朱臻垚",
      "罗平",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105849747B",
    "apn": "CN201380081288.3",
    "apd": "2013-11-30",
    "apy": "2013",
    "tit": "用于人脸图像识别的方法和系统",
    "inv": [
      "汤晓鸥",
      "孙祎",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105814582A",
    "apn": "CN201380081310.4",
    "apd": "2013-11-30",
    "apy": "2013",
    "tit": "用于识别人脸的方法和系统",
    "inv": [
      "汤晓鸥",
      "陆超超",
      "赵德丽"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN105849720B",
    "apn": "CN201380081290.0",
    "apd": "2013-11-30",
    "apy": "2013",
    "tit": "视觉语义复合网络以及用于形成该网络的方法",
    "inv": [
      "汤晓鸥",
      "邱石",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN106462724B",
    "apn": "CN201480077597.8",
    "apd": "2014-04-11",
    "apy": "2014",
    "tit": "基于规范化图像校验面部图像的方法和系统",
    "inv": [
      "汤晓鸥",
      "朱臻垚",
      "罗平",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN106358444B",
    "apn": "CN201480077117.8",
    "apd": "2014-04-11",
    "apy": "2014",
    "tit": "用于面部验证的方法和系统",
    "inv": [
      "汤晓鸥",
      "孙祎",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106415594A",
    "apn": "CN201480079316.2",
    "apd": "2014-06-16",
    "apy": "2014",
    "tit": "用于面部验证的方法和系统",
    "inv": [
      "汤晓鸥",
      "孙祎",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106663186B",
    "apn": "CN201480080815.3",
    "apd": "2014-07-28",
    "apy": "2014",
    "tit": "用于脸部识别的方法和系统",
    "inv": [
      "汤晓鸥",
      "王晓刚",
      "朱臻垚",
      "罗平"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106796716B",
    "apn": "CN201480082564.2",
    "apd": "2014-08-08",
    "apy": "2014",
    "tit": "用于为低分辨率图像提供超分辨率的设备和方法",
    "inv": [
      "汤晓鸥",
      "董超",
      "吕健勤",
      "何凯明"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN107004136B",
    "apn": "CN201480082760.X",
    "apd": "2014-08-20",
    "apy": "2014",
    "tit": "用于估计人脸图像的人脸关键点的方法和系统",
    "inv": [
      "汤晓鸥",
      "朱施展",
      "李诚",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106575367B",
    "apn": "CN201480081241.1",
    "apd": "2014-08-21",
    "apy": "2014",
    "tit": "用于基于多任务的人脸关键点检测的方法和系统",
    "inv": [
      "汤晓鸥",
      "张展鹏",
      "罗平",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106687993B",
    "apn": "CN201480081756.1",
    "apd": "2014-09-03",
    "apy": "2014",
    "tit": "用于图像数据分类的设备和方法",
    "inv": [
      "汤晓鸥",
      "杨硕",
      "罗平",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106688011B",
    "apn": "CN201480081846.0",
    "apd": "2014-09-10",
    "apy": "2014",
    "tit": "用于多类别物体检测的方法和系统",
    "inv": [
      "汤晓鸥",
      "欧阳万里",
      "曾星宇",
      "邱石",
      "吕健勤",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106937532B",
    "apn": "CN201480083106.0",
    "apd": "2014-11-07",
    "apy": "2014",
    "tit": "用于检测真正用户的系统和方法",
    "inv": [
      "汤晓鸥",
      "许德唯",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107004115B",
    "apn": "CN201480083717.5",
    "apd": "2014-12-03",
    "apy": "2014",
    "tit": "用于人脸识别的方法和系统",
    "inv": [
      "汤晓鸥",
      "孙祎",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107004142B",
    "apn": "CN201480083906.2",
    "apd": "2014-12-10",
    "apy": "2014",
    "tit": "用于图像分类的方法和系统",
    "inv": [
      "王晓刚",
      "李鸿升",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107004116B",
    "apn": "CN201480083724.5",
    "apd": "2014-12-12",
    "apy": "2014",
    "tit": "用于预测脸部属性的方法和设备",
    "inv": [
      "汤晓鸥",
      "刘子纬",
      "罗平",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107003834B",
    "apn": "CN201480083931.0",
    "apd": "2014-12-15",
    "apy": "2014",
    "tit": "行人检测设备和方法",
    "inv": [
      "汤晓鸥",
      "田永龙",
      "罗平",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN105844206A",
    "apn": "CN201510020554.2",
    "apd": "2015-01-15",
    "apy": "2015",
    "tit": "身份认证方法及设备",
    "inv": [
      "马堃",
      "李诚",
      "郝景山",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107209864B",
    "apn": "CN201580074278.6",
    "apd": "2015-01-27",
    "apy": "2015",
    "tit": "人脸识别方法和装置",
    "inv": [
      "汤晓鸥",
      "王晓刚",
      "孙祎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107251053B",
    "apn": "CN201580075726.4",
    "apd": "2015-02-13",
    "apy": "2015",
    "tit": "一种降低有损压缩图像的压缩失真的方法及装置",
    "inv": [
      "汤晓鸥",
      "董超",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107430693A",
    "apn": "CN201580077195.2",
    "apd": "2015-03-13",
    "apy": "2015",
    "tit": "用于车辆分类和验证的设备和系统",
    "inv": [
      "汤晓鸥",
      "杨林杰",
      "罗平",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108140243A",
    "apn": "CN201580077259.9",
    "apd": "2015-03-18",
    "apy": "2015",
    "tit": "从双目成像系统的3D手部姿势恢复",
    "inv": [
      "汤晓鸥",
      "钱晨",
      "许德唯",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN106156702A",
    "apn": "CN201510151766.4",
    "apd": "2015-04-01",
    "apy": "2015",
    "tit": "身份认证方法及设备",
    "inv": [
      "马堃",
      "李诚",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN107851192A",
    "apn": "CN201580079964.2",
    "apd": "2015-05-13",
    "apy": "2015",
    "tit": "用于检测人脸部分及人脸的设备和方法",
    "inv": [
      "汤晓鸥",
      "杨硕",
      "罗平",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107624189A",
    "apn": "CN201580080145.X",
    "apd": "2015-05-18",
    "apy": "2015",
    "tit": "用于生成预测模型的方法和设备",
    "inv": [
      "王晓刚",
      "张聪",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107615295A",
    "apn": "CN201580080396.8",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "用于定位面部图像的面部关键特征的设备和方法",
    "inv": [
      "汤晓鸥",
      "朱施展",
      "李诚",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107636678A",
    "apn": "CN201580080731.4",
    "apd": "2015-06-29",
    "apy": "2015",
    "tit": "用于预测图像样本的属性的方法和设备",
    "inv": [
      "汤晓鸥",
      "黄琛",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN107735795A",
    "apn": "CN201580081408.9",
    "apd": "2015-07-02",
    "apy": "2015",
    "tit": "用于社会关系识别的方法和系统",
    "inv": [
      "汤晓鸥",
      "张展鹏",
      "罗平",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107851174A",
    "apn": "CN201580081584.2",
    "apd": "2015-07-08",
    "apy": "2015",
    "tit": "图像语义标注的设备和方法",
    "inv": [
      "汤晓鸥",
      "刘子纬",
      "李晓潇",
      "罗平",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108027972A",
    "apn": "CN201580082259.8",
    "apd": "2015-07-30",
    "apy": "2015",
    "tit": "用于对象跟踪的系统和方法",
    "inv": [
      "王晓刚",
      "王立君",
      "欧阳万里",
      "卢湖川"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106557726A",
    "apn": "CN201510624209.X",
    "apd": "2015-09-25",
    "apy": "2015",
    "tit": "一种带静默式活体检测的人脸身份认证系统及其方法",
    "inv": [
      "张伟",
      "旷章辉",
      "李诚",
      "彭义刚",
      "吴立威"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106557723A",
    "apn": "CN201510622765.3",
    "apd": "2015-09-25",
    "apy": "2015",
    "tit": "一种带交互式活体检测的人脸身份认证系统及其方法",
    "inv": [
      "张伟",
      "旷章辉",
      "李诚",
      "彭义刚",
      "吴立威"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105184278B",
    "apn": "CN201510639824.8",
    "apd": "2015-09-30",
    "apy": "2015",
    "tit": "一种人脸检测方法和装置",
    "inv": [
      "刘文志",
      "李嘉斌",
      "毛宁元"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108351971A",
    "apn": "CN201580084335.9",
    "apd": "2015-10-12",
    "apy": "2015",
    "tit": "对标记有属性的对象进行聚类的方法和系统",
    "inv": [
      "王晓刚",
      "欧阳万里",
      "李弘扬",
      "曾星宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN105139040B",
    "apn": "CN201510657674.3",
    "apd": "2015-10-13",
    "apy": "2015",
    "tit": "一种排队状态信息检测方法及其系统",
    "inv": [
      "黄展鹏",
      "张伟",
      "王子彬",
      "汤晓鸥"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106599764A",
    "apn": "CN201510683059.X",
    "apd": "2015-10-20",
    "apy": "2015",
    "tit": "基于唇形特征的活体判断方法及设备",
    "inv": [
      "彭义刚",
      "李诚",
      "吴立威",
      "张伟",
      "旷章辉"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106599765A",
    "apn": "CN201510685214.1",
    "apd": "2015-10-20",
    "apy": "2015",
    "tit": "基于对象连续发音的视-音频判断活体的方法及系统",
    "inv": [
      "李诚",
      "彭义刚",
      "吴立威",
      "张伟",
      "旷章辉"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108496174A",
    "apn": "CN201580085498.9",
    "apd": "2015-10-28",
    "apy": "2015",
    "tit": "用于面部识别的方法和系统",
    "inv": [
      "孙祎",
      "王晓刚",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司",
      "深圳市商汤科技有限公司",
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105335722A",
    "apn": "CN201510728999.6",
    "apd": "2015-10-30",
    "apy": "2015",
    "tit": "一种基于深度图像信息的检测系统及方法",
    "inv": [
      "张伟",
      "吴子豪",
      "汤晓鸥"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105426827B",
    "apn": "CN201510756011.7",
    "apd": "2015-11-09",
    "apy": "2015",
    "tit": "活体验证方法、装置和系统",
    "inv": [
      "郭亨凯",
      "彭义刚",
      "李诚",
      "吴立威"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105354902B",
    "apn": "CN201510757989.5",
    "apd": "2015-11-10",
    "apy": "2015",
    "tit": "一种基于人脸识别的安保管理方法及系统",
    "inv": [
      "刘祖希",
      "王子彬",
      "张伟",
      "陈朝军",
      "刘亮",
      "肖伟华",
      "马堃",
      "金啸",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108475339A",
    "apn": "CN201580085497.4",
    "apd": "2015-11-13",
    "apy": "2015",
    "tit": "用于对图像中的对象分类的方法和系统",
    "inv": [
      "王晓刚",
      "曾星宇",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105447458B",
    "apn": "CN201510791068.0",
    "apd": "2015-11-17",
    "apy": "2015",
    "tit": "一种大规模人群视频分析系统和方法",
    "inv": [
      "彭彬绪",
      "张帆",
      "杨延生",
      "佘忠华",
      "张广程",
      "郝景山"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108701206A",
    "apn": "CN201580085696.5",
    "apd": "2015-11-20",
    "apy": "2015",
    "tit": "用于面部对准的系统和方法",
    "inv": [
      "汤晓鸥",
      "朱施展",
      "李诚",
      "吕健勤"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN105426850A",
    "apn": "CN201510819092.0",
    "apd": "2015-11-23",
    "apy": "2015",
    "tit": "一种基于人脸识别的关联信息推送设备及方法",
    "inv": [
      "张广程",
      "罗予晨",
      "曹强",
      "刘祖希",
      "于志兴",
      "周璐璐",
      "向许波",
      "张果琲",
      "马堃"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105260731A",
    "apn": "CN201510828738.1",
    "apd": "2015-11-25",
    "apy": "2015",
    "tit": "一种基于光脉冲的人脸活体检测系统及方法",
    "inv": [
      "张伟",
      "吴子豪",
      "汤晓鸥"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105488478A",
    "apn": "CN201510872357.3",
    "apd": "2015-12-02",
    "apy": "2015",
    "tit": "一种人脸识别系统和方法",
    "inv": [
      "梁伯均",
      "李庆林",
      "张伟",
      "黄展鹏",
      "王晶",
      "苏哲昆",
      "许金涛",
      "张帅",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105404877A",
    "apn": "CN201510900079.8",
    "apd": "2015-12-08",
    "apy": "2015",
    "tit": "基于深度学习和多任务学习的人脸属性预测方法及装置",
    "inv": [
      "张伟",
      "旷章辉"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108475415A",
    "apn": "CN201580085452.7",
    "apd": "2015-12-21",
    "apy": "2015",
    "tit": "用于图像处理的方法和系统",
    "inv": [
      "汤晓鸥",
      "董超",
      "许德唯",
      "吕健勤"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN105354565A",
    "apn": "CN201510980543.9",
    "apd": "2015-12-23",
    "apy": "2015",
    "tit": "基于全卷积网络人脸五官定位与判别的方法及系统",
    "inv": [
      "石建萍",
      "梁继",
      "隋凌志"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN105447529A",
    "apn": "CN201511023091.1",
    "apd": "2015-12-30",
    "apy": "2015",
    "tit": "一种服饰检测及其属性值识别的方法和系统",
    "inv": [
      "邱石",
      "郑宝文",
      "王亭午",
      "夏炎",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105718050A",
    "apn": "CN201610027071.X",
    "apd": "2016-01-14",
    "apy": "2016",
    "tit": "一种实时的人脸交互方法及其系统",
    "inv": [
      "左冬冬",
      "郭玉京",
      "马堃",
      "王贵杰",
      "徐立"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN105719248B",
    "apn": "CN201610025392.6",
    "apd": "2016-01-14",
    "apy": "2016",
    "tit": "一种实时的人脸变形方法及其系统",
    "inv": [
      "郭玉京",
      "左冬冬",
      "马堃",
      "王贵杰",
      "徐立"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108496185A",
    "apn": "CN201680079308.7",
    "apd": "2016-01-18",
    "apy": "2016",
    "tit": "用于对象检测的系统和方法",
    "inv": [
      "王晓刚",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108604304A",
    "apn": "CN201680079452.0",
    "apd": "2016-01-20",
    "apy": "2016",
    "tit": "用于使来自源域的用于对象表示的深度模型适应于目标域的方法和系统",
    "inv": [
      "汤晓鸥",
      "张展鹏",
      "罗平",
      "吕健勤"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106997452A",
    "apn": "CN201610051911.6",
    "apd": "2016-01-26",
    "apy": "2016",
    "tit": "活体验证方法及装置",
    "inv": [
      "吴立威",
      "彭义刚",
      "罗梓鑫",
      "曹旭东",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108701210A",
    "apn": "CN201680080165.1",
    "apd": "2016-02-02",
    "apy": "2016",
    "tit": "用于CNN网络适配和对象在线追踪的方法和系统",
    "inv": [
      "王晓刚",
      "王立君",
      "欧阳万里",
      "卢湖川"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN105787440A",
    "apn": "CN201610089315.7",
    "apd": "2016-02-17",
    "apy": "2016",
    "tit": "一种基于人脸特征和步态特征的安保管理方法及系统",
    "inv": [
      "刘祖希",
      "王子彬",
      "张伟",
      "陈朝军",
      "刘亮",
      "肖伟华",
      "马堃",
      "金啸",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN105844234A",
    "apn": "CN201610162454.8",
    "apd": "2016-03-21",
    "apy": "2016",
    "tit": "一种基于头肩检测的人数统计的方法及设备",
    "inv": [
      "卢宇",
      "许金涛",
      "许晨雪",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN105868693A",
    "apn": "CN201610162452.9",
    "apd": "2016-03-21",
    "apy": "2016",
    "tit": "身份认证方法及系统",
    "inv": [
      "马堃",
      "曹旭东",
      "徐立",
      "杨帆"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108885683A",
    "apn": "CN201680083830.2",
    "apd": "2016-03-28",
    "apy": "2016",
    "tit": "用于位姿估计的方法和系统",
    "inv": [
      "王晓刚",
      "初晓",
      "欧阳万里",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107239785A",
    "apn": "CN201610186302.1",
    "apd": "2016-03-29",
    "apy": "2016",
    "tit": "一种在智能设备中对图片进行分类的方法和系统",
    "inv": [
      "胡杰",
      "孙刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108885684A",
    "apn": "CN201680084129.2",
    "apd": "2016-03-30",
    "apy": "2016",
    "tit": "用于检测视频中的对象的方法和系统",
    "inv": [
      "王晓刚",
      "康恺",
      "李鸿升",
      "闫俊杰",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107292904B",
    "apn": "CN201610196293.4",
    "apd": "2016-03-31",
    "apy": "2016",
    "tit": "一种基于深度图像的手掌跟踪方法和系统",
    "inv": [
      "刘鹏鹏",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109074472A",
    "apn": "CN201680084297.1",
    "apd": "2016-04-06",
    "apy": "2016",
    "tit": "用于人物识别的方法和系统",
    "inv": [
      "王晓刚",
      "肖桐",
      "李爽"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109074473A",
    "apn": "CN201680084445.X",
    "apd": "2016-04-11",
    "apy": "2016",
    "tit": "用于对象跟踪的方法和系统",
    "inv": [
      "王晓刚",
      "邵婧",
      "吕健勤",
      "康恺"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109313795A",
    "apn": "CN201680084409.3",
    "apd": "2016-04-11",
    "apy": "2016",
    "tit": "用于超分辨率处理的方法和设备",
    "inv": [
      "汤晓鸥",
      "朱施展",
      "李诚",
      "吕健勤"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109074499A",
    "apn": "CN201680084316.0",
    "apd": "2016-04-12",
    "apy": "2016",
    "tit": "用于对象重识别的方法和系统",
    "inv": [
      "王晓刚",
      "肖桐",
      "李鸿升",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107305622A",
    "apn": "CN201610237424.9",
    "apd": "2016-04-15",
    "apy": "2016",
    "tit": "一种人脸五官识别方法、装置及系统",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN105976369B",
    "apn": "CN201610285223.6",
    "apd": "2016-05-03",
    "apy": "2016",
    "tit": "一种检测像素点到边缘距离的方法及系统",
    "inv": [
      "郭玉京",
      "马堃"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN105913507B",
    "apn": "CN201610290659.4",
    "apd": "2016-05-03",
    "apy": "2016",
    "tit": "一种考勤方法及系统",
    "inv": [
      "马堃",
      "马丽",
      "孙林",
      "吴瑕",
      "霍秋亮"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN107368769A",
    "apn": "CN201610308082.5",
    "apd": "2016-05-11",
    "apy": "2016",
    "tit": "人脸活体检测方法、装置及电子设备",
    "inv": [
      "吴立威",
      "罗梓鑫",
      "彭义刚",
      "曹旭东"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107480575A",
    "apn": "CN201610399405.6",
    "apd": "2016-06-07",
    "apy": "2016",
    "tit": "模型的训练方法、跨年龄人脸识别方法和对应的装置",
    "inv": [
      "李志锋",
      "乔宇",
      "温研东"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN106097353B",
    "apn": "CN201610425391.0",
    "apd": "2016-06-15",
    "apy": "2016",
    "tit": "基于多层次局部区域融合的物体分割方法及装置、计算设备",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN105894046B",
    "apn": "CN201610430066.3",
    "apd": "2016-06-16",
    "apy": "2016",
    "tit": "卷积神经网络训练及图像处理的方法和系统、计算机设备",
    "inv": [
      "汤晓鸥",
      "黄琛",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107516102A",
    "apn": "CN201610431109.X",
    "apd": "2016-06-16",
    "apy": "2016",
    "tit": "图像数据分类与建立分类模型方法、装置及系统",
    "inv": [
      "朱望江",
      "胡杰",
      "孙刚",
      "曹旭东"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106127208A",
    "apn": "CN201610430103.0",
    "apd": "2016-06-16",
    "apy": "2016",
    "tit": "对图像中的多个对象进行分类的方法和系统、计算机系统",
    "inv": [
      "王晓刚",
      "李弘扬",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106127120B",
    "apn": "CN201610431259.0",
    "apd": "2016-06-16",
    "apy": "2016",
    "tit": "姿势估计方法和装置、计算机系统",
    "inv": [
      "王晓刚",
      "杨巍",
      "欧阳万里",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106127232A",
    "apn": "CN201610430106.4",
    "apd": "2016-06-16",
    "apy": "2016",
    "tit": "卷积神经网络训练方法和系统、对象分类方法和分类器",
    "inv": [
      "汤晓鸥",
      "黄琛",
      "李亦宁",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106096670B",
    "apn": "CN201610439342.2",
    "apd": "2016-06-17",
    "apy": "2016",
    "tit": "级联卷积神经网络训练和图像检测方法、装置及系统",
    "inv": [
      "秦红伟",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107516103A",
    "apn": "CN201610438796.8",
    "apd": "2016-06-17",
    "apy": "2016",
    "tit": "一种影像分类方法和系统",
    "inv": [
      "石建萍",
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107516061A",
    "apn": "CN201610438777.5",
    "apd": "2016-06-17",
    "apy": "2016",
    "tit": "一种影像分类方法和系统",
    "inv": [
      "石建萍",
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106126579A",
    "apn": "CN201610440636.7",
    "apd": "2016-06-17",
    "apy": "2016",
    "tit": "物体识别方法和装置、数据处理装置和终端设备",
    "inv": [
      "汤晓鸥",
      "刘子纬",
      "罗平",
      "邱石",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106202329B",
    "apn": "CN201610515995.4",
    "apd": "2016-07-01",
    "apy": "2016",
    "tit": "样本数据处理、数据识别方法和装置、计算机设备",
    "inv": [
      "汤晓鸥",
      "黄琛",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106204948A",
    "apn": "CN201610544020.4",
    "apd": "2016-07-11",
    "apy": "2016",
    "tit": "储物柜管理方法及储物柜管理装置",
    "inv": [
      "叶燕罡",
      "黄展鹏",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN107622274A",
    "apn": "CN201610562498.X",
    "apd": "2016-07-15",
    "apy": "2016",
    "tit": "用于图像处理的神经网络训练方法、装置以及计算机设备",
    "inv": [
      "汤晓鸥",
      "邓煜彬",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107644251A",
    "apn": "CN201610586596.7",
    "apd": "2016-07-22",
    "apy": "2016",
    "tit": "神经元激活方法、装置和系统以及对象分类方法和系统",
    "inv": [
      "李帅",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106228188A",
    "apn": "CN201610586139.8",
    "apd": "2016-07-22",
    "apy": "2016",
    "tit": "聚类方法、装置及电子设备",
    "inv": [
      "潘薪宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN106254456A",
    "apn": "CN201610631401.6",
    "apd": "2016-08-03",
    "apy": "2016",
    "tit": "一种数据同步方法和数据同步系统",
    "inv": [
      "林俊郯",
      "王晶",
      "王贝珊",
      "苏哲昆",
      "贾思博",
      "张广程",
      "李庆林"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN106295547A",
    "apn": "CN201610638207.0",
    "apd": "2016-08-05",
    "apy": "2016",
    "tit": "一种图像比对方法及图像比对装置",
    "inv": [
      "贾思博",
      "陈治华",
      "陈宇恒",
      "王晶",
      "林俊郯",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107341435A",
    "apn": "CN201610697502.3",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "视频图像的处理方法、装置和终端设备",
    "inv": [
      "栾青",
      "彭义刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107341434A",
    "apn": "CN201610697472.6",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "视频图像的处理方法、装置和终端设备",
    "inv": [
      "栾青",
      "张伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107343211B",
    "apn": "CN201610694625.1",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "视频图像处理方法、装置和终端设备",
    "inv": [
      "王雷",
      "栾青",
      "许亲亲"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107341805B",
    "apn": "CN201610694814.9",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "图像前背景分割及网络模型训练、图像处理方法和装置",
    "inv": [
      "石建萍",
      "栾青"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN107341436B",
    "apn": "CN201610707579.4",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "手势检测网络训练、手势检测及控制方法、系统及终端",
    "inv": [
      "钱晨",
      "栾青",
      "刘文韬"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107347125A",
    "apn": "CN201610694601.6",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "视频图像的处理方法、装置和终端设备",
    "inv": [
      "栾青",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107347166A",
    "apn": "CN201610697316.X",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "视频图像的处理方法、装置和终端设备",
    "inv": [
      "栾青",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN107368182A",
    "apn": "CN201610696340.1",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "手势检测网络训练、手势检测、手势控制方法及装置",
    "inv": [
      "李全全",
      "闫俊杰",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107340852A",
    "apn": "CN201610694510.2",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "手势控制方法、装置和终端设备",
    "inv": [
      "栾青",
      "钱晨",
      "刘文韬"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106355603B",
    "apn": "CN201610752578.1",
    "apd": "2016-08-29",
    "apy": "2016",
    "tit": "人体跟踪方法和人体跟踪装置",
    "inv": [
      "卢宇",
      "梁伯均",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106340047B",
    "apn": "CN201610809489.6",
    "apd": "2016-09-07",
    "apy": "2016",
    "tit": "图像生成方法、装置和系统",
    "inv": [
      "武智融",
      "林达华",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN106407991A",
    "apn": "CN201610825966.8",
    "apd": "2016-09-14",
    "apy": "2016",
    "tit": "图像属性识别方法和系统以及相关网络训练方法和系统",
    "inv": [
      "汤晓鸥",
      "石武",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106548449A",
    "apn": "CN201610829371.X",
    "apd": "2016-09-18",
    "apy": "2016",
    "tit": "生成超分辨率深度图的方法、装置及系统",
    "inv": [
      "汤晓鸥",
      "许德唯",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN106529527A",
    "apn": "CN201610848961.7",
    "apd": "2016-09-23",
    "apy": "2016",
    "tit": "物体检测方法和装置、数据处理装置和电子设备",
    "inv": [
      "曾星宇",
      "欧阳万里",
      "杨斌",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106530305B",
    "apn": "CN201610848794.6",
    "apd": "2016-09-23",
    "apy": "2016",
    "tit": "语义分割模型训练和图像分割方法及装置、计算设备",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107871314A",
    "apn": "CN201610846341.X",
    "apd": "2016-09-23",
    "apy": "2016",
    "tit": "一种敏感图像鉴别方法和装置",
    "inv": [
      "范宏伟",
      "陈振方",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106548192B",
    "apn": "CN201610849620.1",
    "apd": "2016-09-23",
    "apy": "2016",
    "tit": "基于神经网络的图像处理方法、装置和电子设备",
    "inv": [
      "王晓刚",
      "王哲",
      "李鸿升",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106529565B",
    "apn": "CN201610849633.9",
    "apd": "2016-09-23",
    "apy": "2016",
    "tit": "目标识别模型训练和目标识别方法及装置、计算设备",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106570453A",
    "apn": "CN201610876667.7",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "用于行人检测的方法、装置和系统",
    "inv": [
      "汤晓鸥",
      "田永龙",
      "罗平",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106548179B",
    "apn": "CN201610868345.8",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "物体和服饰关键点的检测方法、装置和电子设备",
    "inv": [
      "汤晓鸥",
      "刘子纬",
      "颜思捷",
      "罗平",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106407946A",
    "apn": "CN201610867834.1",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "跨线计数方法和深度神经网络训练方法、装置和电子设备",
    "inv": [
      "王晓刚",
      "赵倬毅",
      "李鸿升",
      "赵瑞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106446844A",
    "apn": "CN201610865522.7",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "姿势估计方法和装置、计算机系统",
    "inv": [
      "王晓刚",
      "初晓",
      "欧阳万里",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106504266B",
    "apn": "CN201610868343.9",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "行走行为的预测方法和装置、数据处理装置和电子设备",
    "inv": [
      "伊帅",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106296950A",
    "apn": "CN201610874430.5",
    "apd": "2016-09-30",
    "apy": "2016",
    "tit": "一种基于人脸识别的门禁系统",
    "inv": [
      "肖伟华",
      "向许波",
      "江炎勇",
      "秦少明",
      "佘中华",
      "马堃"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN106446855A",
    "apn": "CN201610874395.7",
    "apd": "2016-09-30",
    "apy": "2016",
    "tit": "一种实名认证装置",
    "inv": [
      "向许波",
      "佘中华",
      "张忠福",
      "秦少明",
      "王贵杰",
      "徐秒然",
      "马堃"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106557778A",
    "apn": "CN201610918213.1",
    "apd": "2016-10-20",
    "apy": "2016",
    "tit": "通用物体检测方法和装置、数据处理装置和终端设备",
    "inv": [
      "李步宇",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106980811A",
    "apn": "CN201610921132.7",
    "apd": "2016-10-21",
    "apy": "2016",
    "tit": "人脸表情识别方法和人脸表情识别装置",
    "inv": [
      "金啸",
      "胡晨晨",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106980866A",
    "apn": "CN201610940572.7",
    "apd": "2016-10-24",
    "apy": "2016",
    "tit": "一种鞋类检索方法",
    "inv": [
      "陈海峰",
      "邱石",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN106530340B",
    "apn": "CN201610939923.2",
    "apd": "2016-10-24",
    "apy": "2016",
    "tit": "一种指定物体跟踪方法",
    "inv": [
      "马政",
      "刘俊",
      "卢宇",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106599789B",
    "apn": "CN201611030170.X",
    "apd": "2016-11-15",
    "apy": "2016",
    "tit": "视频类别识别方法和装置、数据处理装置和电子设备",
    "inv": [
      "汤晓鸥",
      "王利民",
      "熊元骏",
      "王喆",
      "乔宇",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108073864A",
    "apn": "CN201611013117.9",
    "apd": "2016-11-15",
    "apy": "2016",
    "tit": "目标对象检测方法、装置及系统和神经网络结构",
    "inv": [
      "康恺",
      "李鸿升",
      "欧阳万里",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108122234A",
    "apn": "CN201611073607.8",
    "apd": "2016-11-29",
    "apy": "2016",
    "tit": "卷积神经网络训练及视频处理方法、装置和电子设备",
    "inv": [
      "罗方浩",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108133220A",
    "apn": "CN201611080382.9",
    "apd": "2016-11-30",
    "apy": "2016",
    "tit": "模型训练、关键点定位及图像处理方法、系统及电子设备",
    "inv": [
      "王晋玮",
      "钱晨",
      "栾青"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108154153A",
    "apn": "CN201611097543.5",
    "apd": "2016-12-02",
    "apy": "2016",
    "tit": "场景分析方法和系统、电子设备",
    "inv": [
      "石建萍",
      "赵恒爽"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108154222A",
    "apn": "CN201611097445.1",
    "apd": "2016-12-02",
    "apy": "2016",
    "tit": "深度神经网络训练方法和系统、电子设备",
    "inv": [
      "石建萍",
      "赵恒爽"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108229509A",
    "apn": "CN201611168161.7",
    "apd": "2016-12-16",
    "apy": "2016",
    "tit": "用于识别物体类别的方法及装置、电子设备",
    "inv": [
      "李步宇",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108205816A",
    "apn": "CN201611178714.7",
    "apd": "2016-12-19",
    "apy": "2016",
    "tit": "图像渲染方法、装置和系统",
    "inv": [
      "汤晓鸥",
      "朱施展",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229261A",
    "apn": "CN201611198657.9",
    "apd": "2016-12-22",
    "apy": "2016",
    "tit": "遥感图像检测方法、装置和计算机设备",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108205802A",
    "apn": "CN201611213478.8",
    "apd": "2016-12-23",
    "apy": "2016",
    "tit": "深度神经网络模型训练、图片处理方法及装置和设备",
    "inv": [
      "任思捷",
      "徐立",
      "严琼",
      "孙文秀",
      "戴宇荣",
      "张熠"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108076391A",
    "apn": "CN201611206683.1",
    "apd": "2016-12-23",
    "apy": "2016",
    "tit": "用于直播场景的图像处理方法、装置和电子设备",
    "inv": [
      "张帆"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229488A",
    "apn": "CN201611227629.5",
    "apd": "2016-12-27",
    "apy": "2016",
    "tit": "用于检测物体关键点的方法、装置及电子设备",
    "inv": [
      "方治炜",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108076128A",
    "apn": "CN201611235485.8",
    "apd": "2016-12-28",
    "apy": "2016",
    "tit": "用户属性提取方法、装置和电子设备",
    "inv": [
      "张帆",
      "彭彬绪",
      "陈楷佳"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229514A",
    "apn": "CN201611244381.3",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "物体检测方法、装置和电子设备",
    "inv": [
      "王昌宝",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229515A",
    "apn": "CN201611245154.2",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "高光谱图像的对象分类方法和装置、电子设备",
    "inv": [
      "石建萍",
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229267A",
    "apn": "CN201611246395.9",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "对象属性检测、神经网络训练、区域检测方法和装置",
    "inv": [
      "邵婧",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108076387A",
    "apn": "CN201611244365.4",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "业务对象推送方法及装置、电子设备",
    "inv": [
      "彭彬绪",
      "张帆",
      "陈楷佳",
      "孔令云",
      "付杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230376A",
    "apn": "CN201611264368.4",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "遥感图像处理方法、装置和电子设备",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108230281A",
    "apn": "CN201611264388.1",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "遥感图像处理方法、装置和电子设备",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229516A",
    "apn": "CN201611254726.3",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "用于解译遥感图像的卷积神经网络训练方法、装置和设备",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229489A",
    "apn": "CN201611261431.9",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "关键点预测、网络训练、图像处理方法、装置及电子设备",
    "inv": [
      "刘宇",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229269A",
    "apn": "CN201611268022.1",
    "apd": "2016-12-31",
    "apy": "2016",
    "tit": "人脸检测方法、装置和电子设备",
    "inv": [
      "金啸",
      "胡晨晨",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229268A",
    "apn": "CN201611268009.6",
    "apd": "2016-12-31",
    "apy": "2016",
    "tit": "表情识别及卷积神经网络模型训练方法、装置和电子设备",
    "inv": [
      "金啸",
      "胡晨晨",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229270A",
    "apn": "CN201710021556.2",
    "apd": "2017-01-12",
    "apy": "2017",
    "tit": "用于从遥感图像中识别道路的方法、装置和电子设备",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108073669A",
    "apn": "CN201710023182.8",
    "apd": "2017-01-12",
    "apy": "2017",
    "tit": "业务对象展示方法、装置和电子设备",
    "inv": [
      "彭彬绪",
      "陈楷佳",
      "张帆",
      "付杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229271A",
    "apn": "CN201710051443.7",
    "apd": "2017-01-23",
    "apy": "2017",
    "tit": "解译遥感图像的方法、装置和电子设备",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229467A",
    "apn": "CN201710050588.5",
    "apd": "2017-01-23",
    "apy": "2017",
    "tit": "解译遥感图像的方法、装置和电子设备",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229517A",
    "apn": "CN201710054725.2",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "神经网络训练和高光谱图像解译方法、装置及电子设备",
    "inv": [
      "李聪",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230252A",
    "apn": "CN201710060426.X",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "图像处理方法、装置以及电子设备",
    "inv": [
      "刘建博",
      "严琼",
      "鲍旭",
      "王子彬"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108076359A",
    "apn": "CN201710060424.0",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "业务对象的展示方法、装置和电子设备",
    "inv": [
      "周舒岩"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230352A",
    "apn": "CN201710059806.1",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "目标对象的检测方法、装置和电子设备",
    "inv": [
      "余锋伟",
      "李文博",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108076373A",
    "apn": "CN201710079414.1",
    "apd": "2017-02-14",
    "apy": "2017",
    "tit": "视频图像的处理方法、装置和电子设备",
    "inv": [
      "周舒岩"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229518A",
    "apn": "CN201710081102.4",
    "apd": "2017-02-15",
    "apy": "2017",
    "tit": "基于语句的图像检测方法、装置和系统",
    "inv": [
      "李爽",
      "肖桐",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108229519A",
    "apn": "CN201710087002.2",
    "apd": "2017-02-17",
    "apy": "2017",
    "tit": "图像分类的方法、装置及系统",
    "inv": [
      "王晓刚",
      "朱烽",
      "李鸿升",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229520A",
    "apn": "CN201710093230.0",
    "apd": "2017-02-21",
    "apy": "2017",
    "tit": "从图片中检测物体的方法和装置",
    "inv": [
      "欧阳万里",
      "王晓刚",
      "朱欣",
      "王坤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106897742A",
    "apn": "CN201710093583.0",
    "apd": "2017-02-21",
    "apy": "2017",
    "tit": "用于检测视频中物体的方法、装置和电子设备",
    "inv": [
      "康恺",
      "李鸿升",
      "肖桐",
      "欧阳万里",
      "闫俊杰",
      "刘希慧",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229674A",
    "apn": "CN201710094195.4",
    "apd": "2017-02-21",
    "apy": "2017",
    "tit": "聚类用神经网络的训练方法和装置、聚类方法和装置",
    "inv": [
      "何悦",
      "李诚",
      "曹凯迪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108229272A",
    "apn": "CN201710100507.8",
    "apd": "2017-02-23",
    "apy": "2017",
    "tit": "视觉关系检测方法和装置及视觉关系检测训练方法和装置",
    "inv": [
      "李怡康",
      "欧阳万里",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229455A",
    "apn": "CN201710100676.1",
    "apd": "2017-02-23",
    "apy": "2017",
    "tit": "物体检测方法、神经网络的训练方法、装置和电子设备",
    "inv": [
      "李弘扬",
      "刘宇",
      "欧阳万里",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229490A",
    "apn": "CN201710100498.2",
    "apd": "2017-02-23",
    "apy": "2017",
    "tit": "关键点检测方法、神经网络训练方法、装置和电子设备",
    "inv": [
      "王晓刚",
      "初晓",
      "杨巍",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229521A",
    "apn": "CN201710100506.3",
    "apd": "2017-02-23",
    "apy": "2017",
    "tit": "对象识别网络的训练方法、装置、系统及其应用",
    "inv": [
      "肖桐",
      "李爽",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229273A",
    "apn": "CN201710108551.3",
    "apd": "2017-02-27",
    "apy": "2017",
    "tit": "多层神经网络模型训练、道路特征识别的方法和装置",
    "inv": [
      "石建萍",
      "吴冲若"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229491A",
    "apn": "CN201710113099.X",
    "apd": "2017-02-28",
    "apy": "2017",
    "tit": "从图片中检测物体关系的方法、装置和设备",
    "inv": [
      "汤晓鸥",
      "戴勃",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229274A",
    "apn": "CN201710113166.8",
    "apd": "2017-02-28",
    "apy": "2017",
    "tit": "多层神经网络模型训练、道路特征识别的方法和装置",
    "inv": [
      "石建萍",
      "吴冲若"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230353A",
    "apn": "CN201710124025.6",
    "apd": "2017-03-03",
    "apy": "2017",
    "tit": "目标跟踪方法、系统及电子设备",
    "inv": [
      "余锋伟",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229522A",
    "apn": "CN201710131137.4",
    "apd": "2017-03-07",
    "apy": "2017",
    "tit": "神经网络的训练方法、属性检测方法、装置及电子设备",
    "inv": [
      "武伟",
      "李博",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108234821A",
    "apn": "CN201710131577.X",
    "apd": "2017-03-07",
    "apy": "2017",
    "tit": "检测视频中的动作的方法、装置和系统",
    "inv": [
      "熊元骏",
      "赵岳",
      "王利民",
      "林达华",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN106934397A",
    "apn": "CN201710145253.1",
    "apd": "2017-03-13",
    "apy": "2017",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229675A",
    "apn": "CN201710160611.6",
    "apd": "2017-03-17",
    "apy": "2017",
    "tit": "神经网络的训练方法、物体检测方法、装置和电子设备",
    "inv": [
      "王宇龙",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230383A",
    "apn": "CN201710198505.7",
    "apd": "2017-03-29",
    "apy": "2017",
    "tit": "手部三维数据确定方法、装置及电子设备",
    "inv": [
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108229492A",
    "apn": "CN201710195256.6",
    "apd": "2017-03-29",
    "apy": "2017",
    "tit": "提取特征的方法、装置及系统",
    "inv": [
      "伊帅",
      "赵海宇",
      "田茂清",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230346A",
    "apn": "CN201710202773.1",
    "apd": "2017-03-30",
    "apy": "2017",
    "tit": "用于分割图像语义特征的方法和装置、电子设备",
    "inv": [
      "李晓潇",
      "刘子纬",
      "罗平",
      "吕健勤",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230291A",
    "apn": "CN201710201585.7",
    "apd": "2017-03-30",
    "apy": "2017",
    "tit": "物体识别系统训练方法、物体识别方法、装置和电子设备",
    "inv": [
      "周彧聪",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108074126A",
    "apn": "CN201710202916.9",
    "apd": "2017-03-30",
    "apy": "2017",
    "tit": "一种业务统计方法、装置和电子设备",
    "inv": [
      "彭彬绪",
      "张帆",
      "陈楷佳"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229276A",
    "apn": "CN201710208527.7",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "神经网络训练及图像处理方法、装置和电子设备",
    "inv": [
      "王晋玮",
      "马栩杰",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229277A",
    "apn": "CN201710208528.1",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "手势识别、控制及神经网络训练方法、装置及电子设备",
    "inv": [
      "王权",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229493A",
    "apn": "CN201710229657.9",
    "apd": "2017-04-10",
    "apy": "2017",
    "tit": "对象验证方法、装置和电子设备",
    "inv": [
      "旷章辉",
      "范宏伟",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230292A",
    "apn": "CN201710233770.4",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "物体检测方法和神经网络的训练方法、装置及电子设备",
    "inv": [
      "郝泽锟",
      "秦红伟",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229523A",
    "apn": "CN201710239189.3",
    "apd": "2017-04-13",
    "apy": "2017",
    "tit": "图像检测、神经网络训练方法、装置和电子设备",
    "inv": [
      "任思捷",
      "陈晓濠",
      "刘建博",
      "孙文秀",
      "严琼",
      "徐立",
      "戴宇荣"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229278A",
    "apn": "CN201710244395.3",
    "apd": "2017-04-14",
    "apy": "2017",
    "tit": "人脸图像处理方法、装置和电子设备",
    "inv": [
      "严琼",
      "张展鹏",
      "韦天瀚",
      "鲍旭",
      "王贵杰",
      "刘建博",
      "王灿彬",
      "戴立根",
      "巫雯卉"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229279A",
    "apn": "CN201710247289.0",
    "apd": "2017-04-14",
    "apy": "2017",
    "tit": "人脸图像处理方法、装置和电子设备",
    "inv": [
      "鲍旭",
      "王贵杰",
      "刘建博",
      "王灿彬",
      "刘程浩",
      "廖挺",
      "戴立根",
      "曾文良"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229280A",
    "apn": "CN201710263004.2",
    "apd": "2017-04-20",
    "apy": "2017",
    "tit": "时域动作检测方法和系统、电子设备、计算机存储介质",
    "inv": [
      "汤晓鸥",
      "熊元骏",
      "赵岳",
      "王利民",
      "武智融",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229281A",
    "apn": "CN201710277489.0",
    "apd": "2017-04-25",
    "apy": "2017",
    "tit": "神经网络的生成方法和人脸检测方法、装置及电子设备",
    "inv": [
      "杨硕",
      "熊元骏",
      "吕健勤",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108205684A",
    "apn": "CN201710278676.0",
    "apd": "2017-04-25",
    "apy": "2017",
    "tit": "图像消歧方法、装置、存储介质和电子设备",
    "inv": [
      "汤晓鸥",
      "李亦宁",
      "黄琛",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229282A",
    "apn": "CN201710311329.3",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "关键点检测方法、装置、存储介质及电子设备",
    "inv": [
      "张展鹏",
      "孙书洋",
      "张伟"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230253A",
    "apn": "CN201710327522.6",
    "apd": "2017-05-08",
    "apy": "2017",
    "tit": "图像恢复方法、装置、电子设备和计算机存储介质",
    "inv": [
      "汤晓鸥",
      "余可",
      "施煜锴",
      "董超",
      "吕健勤",
      "林倞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108230233A",
    "apn": "CN201710343304.1",
    "apd": "2017-05-16",
    "apy": "2017",
    "tit": "数据增强、处理方法和装置、电子设备和计算机存储介质",
    "inv": [
      "钱晨",
      "孙刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230354A",
    "apn": "CN201710352230.8",
    "apd": "2017-05-18",
    "apy": "2017",
    "tit": "目标跟踪、网络训练方法、装置、电子设备和存储介质",
    "inv": [
      "任思捷",
      "陈晓濠",
      "刘建博",
      "于志洋",
      "孙文秀",
      "严琼",
      "张瑞",
      "戴宇荣"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229676A",
    "apn": "CN201710351624.1",
    "apd": "2017-05-18",
    "apy": "2017",
    "tit": "神经网络模型的训练方法和装置、电子设备和计算机存储介质",
    "inv": [
      "钱晨",
      "胡杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230234B",
    "apn": "CN201710358741.0",
    "apd": "2017-05-19",
    "apy": "2017",
    "tit": "图像虚化处理方法、装置、存储介质及电子设备",
    "inv": [
      "严琼"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108234858A",
    "apn": "CN201710359299.3",
    "apd": "2017-05-19",
    "apy": "2017",
    "tit": "图像虚化处理方法、装置、存储介质及电子设备",
    "inv": [
      "严琼",
      "孙文秀"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229284A",
    "apn": "CN201710384723.X",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "视线追踪及训练方法和装置、系统、电子设备和存储介质",
    "inv": [
      "钱晨",
      "王飞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108228684A",
    "apn": "CN201710384721.0",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "聚类模型的训练方法、装置、电子设备和计算机存储介质",
    "inv": [
      "曹凯迪",
      "何悦",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108229285A",
    "apn": "CN201710393229.X",
    "apd": "2017-05-27",
    "apy": "2017",
    "tit": "物体分类方法、物体分类器的训练方法、装置和电子设备",
    "inv": [
      "汤晓鸥",
      "陈恺",
      "宋航",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230293A",
    "apn": "CN201710405232.9",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "确定人脸图像质量的方法和装置、电子设备和计算机存储介质",
    "inv": [
      "徐丽飞",
      "于晨笛",
      "刘文志"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN108229287A",
    "apn": "CN201710405291.6",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "图像识别方法和装置、电子设备和计算机存储介质",
    "inv": [
      "李怡康",
      "欧阳万里",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229525A",
    "apn": "CN201710401484.4",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "神经网络训练及图像处理方法、装置、电子设备和存储介质",
    "inv": [
      "孙文秀",
      "杨慧",
      "戴宇荣",
      "严琼",
      "任思捷"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108230395A",
    "apn": "CN201710448540.X",
    "apd": "2017-06-14",
    "apy": "2017",
    "tit": "双视角图像校准及图像处理方法、装置、存储介质和电子设备",
    "inv": [
      "孙文秀"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230294A",
    "apn": "CN201710448251.X",
    "apd": "2017-06-14",
    "apy": "2017",
    "tit": "图像检测方法、装置、电子设备和存储介质",
    "inv": [
      "王哲",
      "石建萍",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230355A",
    "apn": "CN201710448051.4",
    "apd": "2017-06-14",
    "apy": "2017",
    "tit": "目标跟踪及神经网络训练方法、装置、存储介质和电子设备",
    "inv": [
      "王晓刚",
      "储琪",
      "欧阳万里",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108228686A",
    "apn": "CN201710453664.7",
    "apd": "2017-06-15",
    "apy": "2017",
    "tit": "用于实现图文匹配的方法、装置和电子设备",
    "inv": [
      "李爽",
      "肖桐",
      "李鸿升",
      "杨巍",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229526A",
    "apn": "CN201710458606.3",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "网络训练、图像处理方法、装置、存储介质和电子设备",
    "inv": [
      "邓煜彬",
      "吕健勤",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229494A",
    "apn": "CN201710457590.4",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "网络训练方法、处理方法、装置、存储介质和电子设备",
    "inv": [
      "郝泽锟",
      "秦红伟",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230082A",
    "apn": "CN201710458081.3",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "搭配服饰的推荐方法和装置、电子设备、存储介质",
    "inv": [
      "郑宝文",
      "邱石",
      "陈益民",
      "林倞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229288A",
    "apn": "CN201710487723.2",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "神经网络训练及衣服颜色检测方法、装置、存储介质、电子设备",
    "inv": [
      "邵婧",
      "刘希慧",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229495A",
    "apn": "CN201710487247.4",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "目标对象检测方法、装置、电子设备和存储介质",
    "inv": [
      "刘宇",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230390A",
    "apn": "CN201710488351.5",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "训练方法、关键点检测方法、装置、存储介质和电子设备",
    "inv": [
      "李步宇",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229289A",
    "apn": "CN201710500550.3",
    "apd": "2017-06-27",
    "apy": "2017",
    "tit": "目标检索方法、装置和电子设备",
    "inv": [
      "田茂清",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108229468A",
    "apn": "CN201710507778.5",
    "apd": "2017-06-28",
    "apy": "2017",
    "tit": "车辆外观特征识别及车辆检索方法、装置、存储介质、电子设备",
    "inv": [
      "伊帅",
      "王重道",
      "唐路明",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229527A",
    "apn": "CN201710530371.4",
    "apd": "2017-06-29",
    "apy": "2017",
    "tit": "训练及视频分析方法和装置、电子设备、存储介质、程序",
    "inv": [
      "汤晓鸥",
      "黄青虬",
      "熊宇",
      "熊元骏",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108090108A",
    "apn": "CN201710515851.3",
    "apd": "2017-06-29",
    "apy": "2017",
    "tit": "信息处理方法、装置、电子设备和存储介质",
    "inv": [
      "张帆",
      "彭彬绪",
      "陈楷佳"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108229478A",
    "apn": "CN201710520469.1",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "图像语义分割及训练方法和装置、电子设备、存储介质和程序",
    "inv": [
      "汤晓鸥",
      "王广润",
      "罗平",
      "林倞",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229496A",
    "apn": "CN201710562574.1",
    "apd": "2017-07-11",
    "apy": "2017",
    "tit": "服饰关键点的检测方法和装置、电子设备、存储介质和程序",
    "inv": [
      "颜思捷",
      "刘子纬",
      "罗平",
      "王晓刚",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108205803A",
    "apn": "CN201710591856.4",
    "apd": "2017-07-19",
    "apy": "2017",
    "tit": "图像处理方法、神经网络模型的训练方法及装置",
    "inv": [
      "黄明杨",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108230491A",
    "apn": "CN201710594921.9",
    "apd": "2017-07-20",
    "apy": "2017",
    "tit": "门禁控制方法和装置、系统、电子设备、程序和介质",
    "inv": [
      "易成名",
      "于晨笛",
      "刘文志"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229290A",
    "apn": "CN201710619408.0",
    "apd": "2017-07-26",
    "apy": "2017",
    "tit": "视频物体分割方法和装置、电子设备、存储介质和程序",
    "inv": [
      "李晓潇",
      "齐元凯",
      "王哲",
      "陈恺",
      "刘子纬",
      "石建萍",
      "罗平",
      "吕健勤",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108229291A",
    "apn": "CN201710631674.5",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "特征点检测、网络训练方法、装置、电子设备和存储介质",
    "inv": [
      "王晋玮",
      "马栩杰",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229292A",
    "apn": "CN201710633604.3",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "目标识别方法、装置、存储介质和电子设备",
    "inv": [
      "沈岩涛",
      "肖桐",
      "李鸿升",
      "伊帅",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229497A",
    "apn": "CN201710632941.0",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "图像处理方法、装置、存储介质、计算机程序和电子设备",
    "inv": [
      "杨巍",
      "欧阳万里",
      "李爽",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230235A",
    "apn": "CN201710633687.6",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "一种视差图生成系统、方法及存储介质",
    "inv": [
      "庞家昊",
      "孙文秀",
      "任思捷",
      "严琼",
      "杨成熙"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108229479A",
    "apn": "CN201710648545.7",
    "apd": "2017-08-01",
    "apy": "2017",
    "tit": "语义分割模型的训练方法和装置、电子设备、存储介质",
    "inv": [
      "詹晓航",
      "刘子纬",
      "罗平",
      "吕健勤",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229293A",
    "apn": "CN201710677556.8",
    "apd": "2017-08-09",
    "apy": "2017",
    "tit": "人脸图像处理方法、装置和电子设备",
    "inv": [
      "钱晨",
      "王权",
      "王飞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109803583A",
    "apn": "CN201780053499.4",
    "apd": "2017-08-10",
    "apy": "2017",
    "tit": "驾驶状态监控方法、装置和电子设备",
    "inv": [
      "王飞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN107481192A",
    "apn": "CN201710686519.3",
    "apd": "2017-08-11",
    "apy": "2017",
    "tit": "图像处理方法、装置、存储介质、计算机程序和电子设备",
    "inv": [
      "许德唯",
      "吕健勤",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229528A",
    "apn": "CN201710706068.5",
    "apd": "2017-08-16",
    "apy": "2017",
    "tit": "聚类模型训练方法和装置、电子设备、计算机存储介质",
    "inv": [
      "王若晖",
      "林达华",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108229529A",
    "apn": "CN201710718044.1",
    "apd": "2017-08-18",
    "apy": "2017",
    "tit": "分类器集成分类方法和装置、电子设备、存储介质",
    "inv": [
      "李治中",
      "林达华",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108228696A",
    "apn": "CN201710774389.9",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "人脸图像检索方法和系统、拍摄装置、计算机存储介质",
    "inv": [
      "赖海斌",
      "毛宁元",
      "李清正",
      "刘文志"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108229120A",
    "apn": "CN201710802146.1",
    "apd": "2017-09-07",
    "apy": "2017",
    "tit": "人脸解锁及其信息注册方法和装置、设备、程序、介质",
    "inv": [
      "吴立威",
      "金啸",
      "秦红伟",
      "张瑞",
      "暴天鹏",
      "宋广录",
      "苏鑫",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108216229A",
    "apn": "CN201710807219.6",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "交通工具、道路线检测和驾驶控制方法及装置",
    "inv": [
      "郭颖迪",
      "张熠",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229294A",
    "apn": "CN201710805207.X",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "一种运动数据获取方法、装置、电子设备及存储介质",
    "inv": [
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230255A",
    "apn": "CN201710845809.8",
    "apd": "2017-09-19",
    "apy": "2017",
    "tit": "用于实现图像增强的方法、装置和电子设备",
    "inv": [
      "黄明杨",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108230421A",
    "apn": "CN201710848159.2",
    "apd": "2017-09-19",
    "apy": "2017",
    "tit": "一种道路图生成方法、装置、电子设备和计算机存储介质",
    "inv": [
      "程光亮",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108205677A",
    "apn": "CN201710861381.6",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "对象检测方法、装置、计算机程序、存储介质和电子设备",
    "inv": [
      "苏鑫",
      "秦红伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108234591A",
    "apn": "CN201710862046.8",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "基于身份验证装置的内容数据推荐方法、装置和存储介质",
    "inv": [
      "徐立",
      "徐秒然",
      "张忠福",
      "刘毅",
      "刁益帆"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108205580A",
    "apn": "CN201710891875.9",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "一种图像检索方法、装置及计算机可读存储介质",
    "inv": [
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108206941A",
    "apn": "CN201710890850.7",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "目标跟踪方法、系统、终端设备及存储介质",
    "inv": [
      "卢宇",
      "李俊",
      "邓佳丽",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229531A",
    "apn": "CN201710910369.X",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "对象特征处理方法、装置、存储介质和电子设备",
    "inv": [
      "赵海宇",
      "刘希慧",
      "邵静",
      "伊帅",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229297A",
    "apn": "CN201710928950.4",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "人脸识别方法和装置、电子设备、计算机存储介质",
    "inv": [
      "李蔚琳",
      "张广程",
      "罗予晨",
      "曹强"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN108230331A",
    "apn": "CN201710928746.2",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "图像处理方法和装置、电子设备、计算机存储介质",
    "inv": [
      "鲍旭",
      "林俊凡",
      "董超",
      "林娟",
      "王昊然",
      "戴立根",
      "赵富荣",
      "黄明杨",
      "李传俊",
      "林智能",
      "韩松芳",
      "刘文奇"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229296A",
    "apn": "CN201710927454.7",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "人脸皮肤属性识别方法和装置、电子设备、存储介质",
    "inv": [
      "罗思伟",
      "张展鹏",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229298A",
    "apn": "CN201710929741.1",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "神经网络的训练和人脸识别方法及装置、设备、存储介质",
    "inv": [
      "黄诗尧",
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108228700A",
    "apn": "CN201710919275.9",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "图像描述模型的训练方法、装置、电子设备及存储介质",
    "inv": [
      "戴勃",
      "林达华",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108205654A",
    "apn": "CN201710922613.4",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "一种基于视频的动作检测方法及装置",
    "inv": [
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230357A",
    "apn": "CN201711009826.4",
    "apd": "2017-10-25",
    "apy": "2017",
    "tit": "关键点检测方法、装置、存储介质、计算机程序和电子设备",
    "inv": [
      "史雨轩",
      "武伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230358A",
    "apn": "CN201711031418.9",
    "apd": "2017-10-27",
    "apy": "2017",
    "tit": "目标跟踪及神经网络训练方法、装置、存储介质、电子设备",
    "inv": [
      "李博",
      "武伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229532A",
    "apn": "CN201711042845.7",
    "apd": "2017-10-30",
    "apy": "2017",
    "tit": "图像识别方法、装置和电子设备",
    "inv": [
      "王飞",
      "黄诗尧",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230332A",
    "apn": "CN201711039709.2",
    "apd": "2017-10-30",
    "apy": "2017",
    "tit": "文字图像的处理方法和装置、电子设备、计算机存储介质",
    "inv": [
      "张杰",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229499A",
    "apn": "CN201711052744.8",
    "apd": "2017-10-30",
    "apy": "2017",
    "tit": "证件识别方法及装置、电子设备和存储介质",
    "inv": [
      "张瑞",
      "暴天鹏",
      "杨凯",
      "吴立威",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108228703A",
    "apn": "CN201711049294.7",
    "apd": "2017-10-31",
    "apy": "2017",
    "tit": "图像问答方法、装置、系统和存储介质",
    "inv": [
      "鲁盼",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229299A",
    "apn": "CN201711050768.X",
    "apd": "2017-10-31",
    "apy": "2017",
    "tit": "证件的识别方法和装置、电子设备、计算机存储介质",
    "inv": [
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229300A",
    "apn": "CN201711064631.X",
    "apd": "2017-11-02",
    "apy": "2017",
    "tit": "视频分类方法、装置、计算机可读存储介质和电子设备",
    "inv": [
      "孙书洋",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229301A",
    "apn": "CN201711071063.6",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "眼睑线检测方法、装置和电子设备",
    "inv": [
      "陈彦杰",
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108205655A",
    "apn": "CN201711084158.1",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "一种关键点预测方法、装置、电子设备及存储介质",
    "inv": [
      "杨涛",
      "颜深根"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229302A",
    "apn": "CN201711106178.4",
    "apd": "2017-11-10",
    "apy": "2017",
    "tit": "特征提取方法、装置、计算机程序、存储介质和电子设备",
    "inv": [
      "朱允全",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230359A",
    "apn": "CN201711110587.1",
    "apd": "2017-11-12",
    "apy": "2017",
    "tit": "目标检测方法和装置、训练方法、电子设备、程序和介质",
    "inv": [
      "李搏",
      "武伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108230257A",
    "apn": "CN201711131131.3",
    "apd": "2017-11-15",
    "apy": "2017",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "苏鑫",
      "秦红伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229650A",
    "apn": "CN201711132000.7",
    "apd": "2017-11-15",
    "apy": "2017",
    "tit": "卷积处理方法、装置及电子设备",
    "inv": [
      "钱晨",
      "胡晓林"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229306A",
    "apn": "CN201711166328.0",
    "apd": "2017-11-21",
    "apy": "2017",
    "tit": "服饰检测和神经网络训练的方法、装置、存储介质和设备",
    "inv": [
      "陈益民",
      "陈海峰",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229305A",
    "apn": "CN201711165979.8",
    "apd": "2017-11-21",
    "apy": "2017",
    "tit": "用于确定目标对象的外接框的方法、装置和电子设备",
    "inv": [
      "李步宇",
      "李全全",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229533A",
    "apn": "CN201711175542.2",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "图像处理方法、模型剪枝方法、装置及设备",
    "inv": [
      "伊帅",
      "张学森",
      "侯军",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229456A",
    "apn": "CN201711178551.7",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "目标跟踪方法和装置、电子设备、计算机存储介质",
    "inv": [
      "伊帅",
      "田茂清",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229307A",
    "apn": "CN201711175559.8",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "用于物体检测的方法、装置和设备",
    "inv": [
      "刘亮",
      "李全全",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108205685A",
    "apn": "CN201711172591.0",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "视频分类方法、视频分类装置及电子设备",
    "inv": [
      "孙书洋",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229308A",
    "apn": "CN201711181299.5",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "目标对象识别方法、装置、存储介质和电子设备",
    "inv": [
      "李七星",
      "余锋伟",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229679A",
    "apn": "CN201711183838.9",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "卷积神经网络去冗余方法及装置、电子设备和存储介质",
    "inv": [
      "杨成熙",
      "孙文秀",
      "庞家昊"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229313A",
    "apn": "CN201711218983.6",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "人脸识别方法和装置、电子设备和计算机程序及存储介质",
    "inv": [
      "曹凯迪",
      "荣禹",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229314A",
    "apn": "CN201711219178.5",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "目标人物的搜索方法、装置和电子设备",
    "inv": [
      "田茂清",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108230333A",
    "apn": "CN201711216159.7",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "图像处理方法、装置、计算机程序、存储介质和电子设备",
    "inv": [
      "任思捷",
      "贺高远",
      "刘建博",
      "陈晓濠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108230384A",
    "apn": "CN201711219299.X",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "图像深度计算方法、装置、存储介质和电子设备",
    "inv": [
      "任思捷",
      "陈晓濠",
      "孙文秀",
      "庞家昊",
      "严琼"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108229318A",
    "apn": "CN201711224479.7",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "手势识别和手势识别网络的训练方法及装置、设备、介质",
    "inv": [
      "杜天元",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229534A",
    "apn": "CN201711214145.1",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "神经网络模型迁移方法和系统、电子设备、程序和介质",
    "inv": [
      "邵婧",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229652A",
    "apn": "CN201711214166.3",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "神经网络模型迁移方法和系统、电子设备、程序和介质",
    "inv": [
      "邵婧",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229320A",
    "apn": "CN201711236422.9",
    "apd": "2017-11-29",
    "apy": "2017",
    "tit": "选帧方法和装置、电子设备、程序和介质",
    "inv": [
      "宋广录",
      "刘宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN108229322A",
    "apn": "CN201711243717.9",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "基于视频的人脸识别方法、装置、电子设备及存储介质",
    "inv": [
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229321A",
    "apn": "CN201711242159.4",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "人脸识别模型及其训练方法和装置、设备、程序和介质",
    "inv": [
      "姚泽荣",
      "窦浩轩",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229323A",
    "apn": "CN201711248455.5",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "布控方法和装置、电子设备、计算机存储介质",
    "inv": [
      "吴文熙",
      "王晶",
      "张帅",
      "刘祖希",
      "朱斌",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229324A",
    "apn": "CN201711248850.3",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "手势追踪方法和装置、电子设备、计算机存储介质",
    "inv": [
      "杜天元",
      "钱晨",
      "王权"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108227912A",
    "apn": "CN201711243734.2",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "设备控制方法和装置、电子设备、计算机存储介质",
    "inv": [
      "杜天元",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230817A",
    "apn": "CN201711242310.4",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "车辆驾驶模拟方法和装置、电子设备、系统、程序和介质",
    "inv": [
      "刘春晓",
      "马政",
      "张伟",
      "曾仕元"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229325A",
    "apn": "CN201711251760.X",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "人脸检测方法和系统、电子设备、程序和介质",
    "inv": [
      "吴立威",
      "暴天鹏",
      "赵晨旭"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229326A",
    "apn": "CN201711251762.9",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "人脸防伪检测方法和系统、电子设备、程序和介质",
    "inv": [
      "吴立威",
      "暴天鹏",
      "于萌",
      "车英慧",
      "赵晨旭"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229330A",
    "apn": "CN201711289347.2",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "人脸融合识别方法及装置、电子设备和存储介质",
    "inv": [
      "黄潇莹",
      "张丹丹",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229331A",
    "apn": "CN201711289396.6",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "人脸防伪检测方法和系统、电子设备、程序和介质",
    "inv": [
      "杨凯",
      "吴立威",
      "赵晨旭",
      "程郑鑫"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229328A",
    "apn": "CN201711288596.X",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "人脸防伪检测方法和系统、电子设备、程序和介质",
    "inv": [
      "吴立威",
      "程郑鑫",
      "赵晨旭"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229329A",
    "apn": "CN201711288597.4",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "人脸防伪检测方法和系统、电子设备、程序和介质",
    "inv": [
      "吴立威",
      "程郑鑫",
      "赵晨旭",
      "于萌",
      "车英慧"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229335A",
    "apn": "CN201711327139.7",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "关联人脸识别方法和装置、电子设备、存储介质、程序",
    "inv": [
      "李蔚琳",
      "张广程",
      "王晶",
      "张帅",
      "刘祖希",
      "朱斌"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108231190A",
    "apn": "CN201711326277.3",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "处理图像的方法和神经网络系统、设备、介质、程序",
    "inv": [
      "陈胜",
      "王哲",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108229336A",
    "apn": "CN201711329718.5",
    "apd": "2017-12-13",
    "apy": "2017",
    "tit": "视频识别及训练方法和装置、电子设备、程序和介质",
    "inv": [
      "何唐从睿",
      "秦红伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108734078A",
    "apn": "CN201711342642.X",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "图像处理方法、装置、电子设备、存储介质及程序",
    "inv": [
      "王权",
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229457A",
    "apn": "CN201711341981.6",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "证件的验证方法、装置、电子设备和存储介质",
    "inv": [
      "徐妙然",
      "张忠福",
      "佘忠华",
      "向许波",
      "马堃",
      "周文超"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109934058A",
    "apn": "CN201711352295.9",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "人脸图像处理方法、装置、电子设备、存储介质及程序",
    "inv": [
      "吴文岩",
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108228742A",
    "apn": "CN201711362840.2",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "人脸查重方法和装置、电子设备、介质、程序",
    "inv": [
      "黄潇莹",
      "张丹丹",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108205834A",
    "apn": "CN201711346645.0",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "门禁管理方法和门禁管理系统",
    "inv": [
      "黄湘",
      "张忠福",
      "曹莉",
      "刘杰",
      "星存田"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108205835A",
    "apn": "CN201711347579.9",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "门禁控制方法及门禁控制系统",
    "inv": [
      "徐妙然",
      "刘毅",
      "衷丛洪",
      "向许波",
      "陈朝军"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108230437A",
    "apn": "CN201711353866.0",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "场景重建方法和装置、电子设备、程序和介质",
    "inv": [
      "伊帅",
      "杨昆霖",
      "陈凯歌",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108229680A",
    "apn": "CN201711350902.8",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "神经网络系统、遥感图像识别方法、装置、设备及介质",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229341A",
    "apn": "CN201711354471.2",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "分类方法和装置、电子设备、计算机存储介质、程序",
    "inv": [
      "蒋正锴",
      "邵婧",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229343A",
    "apn": "CN201711367020.2",
    "apd": "2017-12-18",
    "apy": "2017",
    "tit": "目标对象关键点检测方法、深度学习神经网络及装置",
    "inv": [
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229344A",
    "apn": "CN201711376170.X",
    "apd": "2017-12-19",
    "apy": "2017",
    "tit": "图像处理方法和装置、电子设备、计算机程序和存储介质",
    "inv": [
      "田茂清",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108234865A",
    "apn": "CN201711387927.5",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "图像处理方法、装置、计算机可读存储介质和电子设备",
    "inv": [
      "毛翔宇",
      "严琼",
      "孙文秀",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108229353A",
    "apn": "CN201711399693.6",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "人体图像的分类方法和装置、电子设备、存储介质、程序",
    "inv": [
      "李玉洁",
      "旷章晖",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108228761A",
    "apn": "CN201711415215.X",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "支持区域自定义的图像检索方法和装置、设备、介质",
    "inv": [
      "吴文熙",
      "王晶",
      "张帅",
      "刘祖希",
      "朱斌",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108228757A",
    "apn": "CN201711402436.3",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "图像搜索方法和装置、电子设备、存储介质、程序",
    "inv": [
      "张韵璇",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109960974A",
    "apn": "CN201711408912.2",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "人脸关键点检测方法、装置、电子设备及存储介质",
    "inv": [
      "钱晨",
      "王权"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229355A",
    "apn": "CN201711407861.1",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "行为识别方法和装置、电子设备、计算机存储介质、程序",
    "inv": [
      "颜思捷",
      "熊元骏",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109960980A",
    "apn": "CN201711417801.8",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "动态手势识别方法及装置",
    "inv": [
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229545A",
    "apn": "CN201711405697.0",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "青光眼诊断的方法、装置及电子设备",
    "inv": [
      "张秀兰",
      "乔宇",
      "王哲",
      "李飞",
      "曲国祥",
      "钟华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229358A",
    "apn": "CN201711417549.0",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "索引建立方法和装置、电子设备、计算机存储介质、程序",
    "inv": [
      "李七星",
      "闫俊杰",
      "莫涛"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109960986A",
    "apn": "CN201711424986.5",
    "apd": "2017-12-25",
    "apy": "2017",
    "tit": "人脸姿态分析方法、装置、设备、存储介质以及程序",
    "inv": [
      "王权",
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108304775A",
    "apn": "CN201711436470.2",
    "apd": "2017-12-26",
    "apy": "2017",
    "tit": "遥感图像识别方法、装置、存储介质以及电子设备",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108564595A",
    "apn": "CN201711455920.2",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "图像跟踪方法和装置、电子设备、存储介质、程序",
    "inv": [
      "冯炜韬"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229363A",
    "apn": "CN201711455838.X",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "关键帧调度方法和装置、电子设备、程序和介质",
    "inv": [
      "石建萍",
      "李玉乐",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108235116A",
    "apn": "CN201711455916.6",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "特征传播方法和装置、电子设备、程序和介质",
    "inv": [
      "石建萍",
      "李玉乐",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229559A",
    "apn": "CN201711498336.5",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "服饰检测方法、装置、电子设备、程序和介质",
    "inv": [
      "陈益民",
      "陈海峰",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108227710A",
    "apn": "CN201711479690.3",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "自动驾驶控制方法和装置、电子设备、程序和介质",
    "inv": [
      "马政",
      "刘春晓",
      "张伟",
      "谢思锐",
      "曾仕元"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229556A",
    "apn": "CN201711481948.3",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "对象分类及模型训练方法、装置、介质和系统",
    "inv": [
      "张行程",
      "杨磊",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108268629A",
    "apn": "CN201810037246.4",
    "apd": "2018-01-15",
    "apy": "2018",
    "tit": "基于关键词的图像描述方法和装置、设备、介质、程序",
    "inv": [
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108228872A",
    "apn": "CN201810041797.8",
    "apd": "2018-01-16",
    "apy": "2018",
    "tit": "人脸图像去重方法和装置、电子设备、存储介质、程序",
    "inv": [
      "苗文健",
      "陈前",
      "石端广",
      "王洒",
      "吴春虎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108228871A",
    "apn": "CN201810041796.3",
    "apd": "2018-01-16",
    "apy": "2018",
    "tit": "人脸图像动态入库方法和装置、电子设备、介质、程序",
    "inv": [
      "苗文健",
      "陈前",
      "石端广",
      "王洒",
      "吴春虎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110059522A",
    "apn": "CN201810054352.3",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "人体轮廓关键点检测方法、图像处理方法、装置及设备",
    "inv": [
      "刘文韬",
      "钱晨",
      "许亲亲"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229418A",
    "apn": "CN201810055582.1",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "人体关键点检测方法和装置、电子设备、存储介质和程序",
    "inv": [
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108280455A",
    "apn": "CN201810055744.1",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "人体关键点检测方法和装置、电子设备、程序和介质",
    "inv": [
      "刘文韬",
      "钱晨",
      "陈洁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108280451A",
    "apn": "CN201810055743.7",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "语义分割及网络训练方法和装置、设备、介质、程序",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108259496A",
    "apn": "CN201810055429.9",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "特效程序文件包的生成及特效生成方法与装置、电子设备",
    "inv": [
      "许亲亲",
      "李展鹏"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108268200A",
    "apn": "CN201810060642.9",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "图像处理方法和装置、电子设备、计算机程序和存储介质",
    "inv": [
      "吴再"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108227931A",
    "apn": "CN201810064291.9",
    "apd": "2018-01-23",
    "apy": "2018",
    "tit": "用于控制虚拟人物的方法、设备、系统、程序和存储介质",
    "inv": [
      "吴再"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108230413A",
    "apn": "CN201810067500.5",
    "apd": "2018-01-23",
    "apy": "2018",
    "tit": "图像描述方法和装置、电子设备、计算机存储介质、程序",
    "inv": [
      "戴勃",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108229477A",
    "apn": "CN201810073370.6",
    "apd": "2018-01-25",
    "apy": "2018",
    "tit": "针对图像的视觉关联性识别方法、装置、设备及存储介质",
    "inv": [
      "殷国君",
      "邵婧",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108257144A",
    "apn": "CN201810073359.X",
    "apd": "2018-01-25",
    "apy": "2018",
    "tit": "基于神经网络的抠图方法、装置、设备、存储介质及程序",
    "inv": [
      "张佳维",
      "任思捷",
      "潘金山"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108229504A",
    "apn": "CN201810085628.4",
    "apd": "2018-01-29",
    "apy": "2018",
    "tit": "图像解析方法及装置",
    "inv": [
      "陈益民",
      "张伟",
      "林倞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108334602A",
    "apn": "CN201810097434.6",
    "apd": "2018-01-31",
    "apy": "2018",
    "tit": "数据标注方法和装置、电子设备、计算机存储介质、程序",
    "inv": [
      "王飞",
      "陈立人",
      "钱晨",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108335322A",
    "apn": "CN201810103195.0",
    "apd": "2018-02-01",
    "apy": "2018",
    "tit": "深度估计方法和装置、电子设备、程序和介质",
    "inv": [
      "罗越",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108460338A",
    "apn": "CN201810106089.8",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "人体姿态估计方法和装置、电子设备、存储介质、程序",
    "inv": [
      "杨巍",
      "欧阳万里",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108205820A",
    "apn": "CN201810107923.5",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "平面的重建方法、融合方法、装置、设备及存储介质",
    "inv": [
      "鲍虎军",
      "章国锋",
      "姜翰青",
      "项骁骏",
      "罗俊丹"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110120098A",
    "apn": "CN201810113142.7",
    "apd": "2018-02-05",
    "apy": "2018",
    "tit": "场景尺度估计及增强现实控制方法、装置和电子设备",
    "inv": [
      "鲍虎军",
      "章国锋",
      "王楠",
      "陈丹鹏"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110119189A",
    "apn": "CN201810114367.4",
    "apd": "2018-02-05",
    "apy": "2018",
    "tit": "SLAM系统的初始化、AR控制方法、装置和系统",
    "inv": [
      "鲍虎军",
      "章国锋",
      "王楠",
      "陈丹鹏"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110119649A",
    "apn": "CN201810114363.6",
    "apd": "2018-02-05",
    "apy": "2018",
    "tit": "电子设备状态跟踪方法、装置、电子设备及控制系统",
    "inv": [
      "鲍虎军",
      "章国锋",
      "王楠",
      "陈丹鹏"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108399654A",
    "apn": "CN201810118942.8",
    "apd": "2018-02-06",
    "apy": "2018",
    "tit": "描边特效程序文件包的生成及描边特效生成方法与装置",
    "inv": [
      "许亲亲",
      "李展鹏"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108280883A",
    "apn": "CN201810123074.2",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "变形特效程序文件包的生成及变形特效生成方法与装置",
    "inv": [
      "许亲亲",
      "岳大禹"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108388434A",
    "apn": "CN201810129969.7",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "特效程序文件包的生成及特效生成方法与装置、电子设备",
    "inv": [
      "许亲亲",
      "李展鹏"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108305281A",
    "apn": "CN201810136229.6",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "图像的校准方法、装置、存储介质、程序产品和电子设备",
    "inv": [
      "孙文秀",
      "肖瑞超",
      "庞家昊",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108304921A",
    "apn": "CN201810136118.5",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "卷积神经网络的训练方法及图像处理方法、装置",
    "inv": [
      "程光亮",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108335305A",
    "apn": "CN201810136371.0",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "图像分割方法和装置、电子设备、程序和介质",
    "inv": [
      "刘枢",
      "亓鲁",
      "秦海芳",
      "石建萍",
      "贾佳亚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108399379A",
    "apn": "CN201810136268.6",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "用于识别面部年龄的方法、装置和电子设备",
    "inv": [
      "张韵璇",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108460411A",
    "apn": "CN201810137044.7",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "实例分割方法和装置、电子设备、程序和介质",
    "inv": [
      "刘枢",
      "亓鲁",
      "秦海芳",
      "石建萍",
      "贾佳亚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108388859A",
    "apn": "CN201810143218.0",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "物体检测方法、网络训练方法、装置和计算机存储介质",
    "inv": [
      "岳晓宇",
      "旷章辉",
      "张兆阳",
      "陈振方",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108460413A",
    "apn": "CN201810145845.8",
    "apd": "2018-02-12",
    "apy": "2018",
    "tit": "图像处理方法、神经网络的训练方法、装置以及设备",
    "inv": [
      "苏鑫",
      "秦红伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108399381A",
    "apn": "CN201810145717.3",
    "apd": "2018-02-12",
    "apy": "2018",
    "tit": "行人再识别方法、装置、电子设备和存储介质",
    "inv": [
      "陈大鹏",
      "李鸿升",
      "肖桐",
      "伊帅",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108268863A",
    "apn": "CN201810150684.1",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "一种图像处理方法、装置和计算机存储介质",
    "inv": [
      "侯军",
      "李卓婉",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108399383A",
    "apn": "CN201810151946.6",
    "apd": "2018-02-14",
    "apy": "2018",
    "tit": "表情迁移方法、装置存储介质及程序",
    "inv": [
      "汪路超",
      "刘浩"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108305267A",
    "apn": "CN201810151947.0",
    "apd": "2018-02-14",
    "apy": "2018",
    "tit": "物体分割方法、装置、设备、存储介质及程序",
    "inv": [
      "李晓潇",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108256506A",
    "apn": "CN201810151829.X",
    "apd": "2018-02-14",
    "apy": "2018",
    "tit": "一种视频中物体检测方法及装置、计算机存储介质",
    "inv": [
      "陈恺",
      "汤晓鸥",
      "王佳琦",
      "杨硕",
      "张行程",
      "熊元骏",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108288051A",
    "apn": "CN201810152276.X",
    "apd": "2018-02-14",
    "apy": "2018",
    "tit": "行人再识别模型训练方法及装置、电子设备和存储介质",
    "inv": [
      "刘宇",
      "宋广录",
      "金啸",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108389224A",
    "apn": "CN201810159732.3",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "徐正天",
      "刘枢",
      "石建萍",
      "卢策吾"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108335306A",
    "apn": "CN201810168388.4",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "汤晓鸥",
      "王鑫涛",
      "余可",
      "董超",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108491763A",
    "apn": "CN201810171298.0",
    "apd": "2018-03-01",
    "apy": "2018",
    "tit": "三维场景识别网络的无监督训练方法、装置及存储介质",
    "inv": [
      "殷志超",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108416799A",
    "apn": "CN201810184967.8",
    "apd": "2018-03-06",
    "apy": "2018",
    "tit": "目标跟踪方法和装置、电子设备、程序、存储介质",
    "inv": [
      "刘绍辉",
      "秦红伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108460787A",
    "apn": "CN201810182797.X",
    "apd": "2018-03-06",
    "apy": "2018",
    "tit": "目标跟踪方法和装置、电子设备、程序、存储介质",
    "inv": [
      "刘绍辉",
      "秦红伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108229591A",
    "apn": "CN201810215337.2",
    "apd": "2018-03-15",
    "apy": "2018",
    "tit": "神经网络自适应训练方法和装置、设备、程序和存储介质",
    "inv": [
      "庞家昊",
      "孙文秀",
      "杨成熙",
      "任思捷",
      "肖瑞超",
      "林倞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108537135A",
    "apn": "CN201810220356.4",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "对象识别及对象识别网络的训练方法和装置、电子设备",
    "inv": [
      "王飞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108491872A",
    "apn": "CN201810220900.5",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "目标再识别方法和装置、电子设备、程序和存储介质",
    "inv": [
      "沈岩涛",
      "肖桐",
      "李鸿升",
      "陈大鹏",
      "伊帅",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108427927A",
    "apn": "CN201810220690.X",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "目标再识别方法和装置、电子设备、程序和存储介质",
    "inv": [
      "沈岩涛",
      "肖桐",
      "李鸿升",
      "伊帅",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108549927A",
    "apn": "CN201810219901.8",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "物体检测网络的训练方法和特征监督图生成方法",
    "inv": [
      "曾星宇",
      "杨朝晖"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108509876A",
    "apn": "CN201810220899.6",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "针对视频的物体检测方法、装置、设备、存储介质及程序",
    "inv": [
      "刘俊",
      "卢宇"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108416059A",
    "apn": "CN201810242761.6",
    "apd": "2018-03-22",
    "apy": "2018",
    "tit": "图像描述模型的训练方法和装置、设备、介质、程序",
    "inv": [
      "刘希慧",
      "李鸿升",
      "邵婧",
      "陈大鹏",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108416321A",
    "apn": "CN201810247961.0",
    "apd": "2018-03-23",
    "apy": "2018",
    "tit": "用于预测目标对象运动朝向的方法、车辆控制方法及装置",
    "inv": [
      "张树",
      "杨朝晖",
      "李佳蔓",
      "曾星宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110363787A",
    "apn": "CN201810254737.4",
    "apd": "2018-03-26",
    "apy": "2018",
    "tit": "信息获取方法和系统、电子设备、程序和介质",
    "inv": [
      "陈晨",
      "李展鹏",
      "荆锐"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108492302A",
    "apn": "CN201810254475.1",
    "apd": "2018-03-26",
    "apy": "2018",
    "tit": "神经层分割方法和装置、电子设备、存储介质、程序",
    "inv": [
      "王哲",
      "乔宇",
      "张秀兰",
      "曲国祥",
      "李飞",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108319937A",
    "apn": "CN201810267178.0",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "人脸检测方法及装置",
    "inv": [
      "吴春虎",
      "王洒",
      "杨亮",
      "钱晨",
      "王权",
      "王飞"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108509894A",
    "apn": "CN201810265189.5",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "人脸检测方法及装置",
    "inv": [
      "周舒岩",
      "封红霞",
      "蒲雪",
      "钱晨",
      "王飞",
      "王权"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108510435A",
    "apn": "CN201810266192.9",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "沈宇军",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108509907A",
    "apn": "CN201810278351.7",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "车灯检测方法、实现智能驾驶的方法、装置、介质及设备",
    "inv": [
      "刘诗男",
      "曾星宇",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108573038A",
    "apn": "CN201810300687.9",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "图像处理、身份验证方法、装置、电子设备和存储介质",
    "inv": [
      "梁鼎",
      "贾子洲"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108595927A",
    "apn": "CN201810301607.1",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "身份认证、解锁及支付方法、装置、存储介质、产品和设备",
    "inv": [
      "郑迪昕",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108805004A",
    "apn": "CN201810326589.2",
    "apd": "2018-04-12",
    "apy": "2018",
    "tit": "功能区域检测方法和装置、电子设备、存储介质、程序",
    "inv": [
      "张展鹏",
      "成慧",
      "蔡俊浩",
      "林倞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108765261A",
    "apn": "CN201810333717.6",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "图像变换方法和装置、电子设备、计算机存储介质、程序",
    "inv": [
      "孙若琪",
      "黄琛",
      "石建萍",
      "马利庄"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108564578A",
    "apn": "CN201810335254.7",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "病理诊断辅助方法、装置及系统",
    "inv": [
      "傅超",
      "公茂亮",
      "黄晓迪"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108734743A",
    "apn": "CN201810333094.2",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "用于标定摄像装置的方法、装置、介质及电子设备",
    "inv": [
      "周恩宇",
      "孙文秀",
      "张浩鑫"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108734099A",
    "apn": "CN201810339543.4",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "身份验证方法和装置、电子设备、计算机程序和存储介质",
    "inv": [
      "郑桂荣",
      "徐妙然",
      "肖丽萍",
      "张超惠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108805005A",
    "apn": "CN201810339526.0",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "身份验证方法和装置、电子设备、计算机程序和存储介质",
    "inv": [
      "郑桂荣",
      "徐妙然",
      "向许波",
      "衷丛洪"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108764033A",
    "apn": "CN201810349755.0",
    "apd": "2018-04-18",
    "apy": "2018",
    "tit": "身份验证方法和装置、电子设备、计算机程序和存储介质",
    "inv": [
      "马堃",
      "张忠福",
      "徐妙然",
      "周文超"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108596070A",
    "apn": "CN201810350902.6",
    "apd": "2018-04-18",
    "apy": "2018",
    "tit": "人物识别方法、装置、存储介质、程序产品和电子设备",
    "inv": [
      "汤晓鸥",
      "黄青虬",
      "熊宇",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108647581A",
    "apn": "CN201810351127.6",
    "apd": "2018-04-18",
    "apy": "2018",
    "tit": "信息处理方法、装置及存储介质",
    "inv": [
      "罗予晨",
      "曹强",
      "李蔚琳",
      "李江涛"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108563767A",
    "apn": "CN201810355179.0",
    "apd": "2018-04-19",
    "apy": "2018",
    "tit": "图像检索方法及装置",
    "inv": [
      "陈大鹏",
      "李鸿升",
      "伊帅",
      "田茂清"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108725440A",
    "apn": "CN201810360844.5",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "前向碰撞控制方法和装置、电子设备、程序和介质",
    "inv": [
      "刘文志",
      "于晨笛",
      "曾星宇",
      "伊帅",
      "佟源洋"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108520251A",
    "apn": "CN201810361436.1",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "关键点检测方法及装置、电子设备和存储介质",
    "inv": [
      "李全全",
      "王宇杰",
      "王昌宝",
      "黄秋宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108596093A",
    "apn": "CN201810373871.6",
    "apd": "2018-04-24",
    "apy": "2018",
    "tit": "人脸特征点的定位方法及装置",
    "inv": [
      "钱晨",
      "吴文岩"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108734830A",
    "apn": "CN201810378002.2",
    "apd": "2018-04-24",
    "apy": "2018",
    "tit": "门禁控制方法及系统",
    "inv": [
      "黄湘",
      "衷丛洪",
      "赵宏斌",
      "刘毅"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109937152A",
    "apn": "CN201880003399.5",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "驾驶状态监测方法和装置、驾驶员监控系统、车辆",
    "inv": [
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108830288A",
    "apn": "CN201810380453.X",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "图像处理方法、神经网络的训练方法、装置、设备及介质",
    "inv": [
      "韦祎",
      "秦红伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108648280A",
    "apn": "CN201810381366.6",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "虚拟角色驱动方法及装置、电子设备和存储介质",
    "inv": [
      "汪路超"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108648238A",
    "apn": "CN201810381365.1",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "虚拟角色驱动方法及装置、电子设备和存储介质",
    "inv": [
      "汪路超"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108550170A",
    "apn": "CN201810379644.4",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "虚拟角色驱动方法及装置",
    "inv": [
      "汪路超"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108681695A",
    "apn": "CN201810387535.7",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "视频动作识别方法及装置、电子设备和存储介质",
    "inv": [
      "赵岳",
      "熊元骏",
      "林达华",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110427542A",
    "apn": "CN201810385973.X",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "分类网络训练及数据标注方法和装置、设备、介质",
    "inv": [
      "林子义",
      "邵婧",
      "赵宇航",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108596328A",
    "apn": "CN201810388209.8",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "一种定点化方法及装置、计算机设备",
    "inv": [
      "曹雨",
      "王坤",
      "曾星宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108960036A",
    "apn": "CN201810395266.9",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "三维人体姿态预测方法、装置、介质及设备",
    "inv": [
      "汪旻",
      "陈曦鹏",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108764047A",
    "apn": "CN201810395944.1",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "群体情绪行为分析方法和装置、电子设备、介质、产品",
    "inv": [
      "夏俊",
      "戴娟",
      "卢宇",
      "栾青"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "教育",
    "l2": "状态监测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108830139A",
    "apn": "CN201810395949.4",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "人体关键点的深度前后关系预测方法、装置、介质及设备",
    "inv": [
      "汪旻",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108762250A",
    "apn": "CN201810395270.5",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "设备的控制方法和装置、设备、计算机程序和存储介质",
    "inv": [
      "刘春晓",
      "杨睿",
      "曾仕元",
      "张伟",
      "林倞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108594818A",
    "apn": "CN201810408927.7",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "智能驾驶控制方法、智能车载设备和系统",
    "inv": [
      "何安",
      "毛宁元",
      "李清正",
      "刘文志"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108568820A",
    "apn": "CN201810394664.9",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "机器人控制方法及装置、电子设备和存储介质",
    "inv": [
      "倪枫",
      "成慧"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "多屏互动",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108596116A",
    "apn": "CN201810394688.4",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "测距方法、智能控制方法及装置、电子设备和存储介质",
    "inv": [
      "佟源洋",
      "毛宁元",
      "刘文志"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108574807A",
    "apn": "CN201810401263.1",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "一种影像处理方法及相关设备",
    "inv": [
      "胡志强",
      "李嘉辉",
      "黄晓迪"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108564552A",
    "apn": "CN201810404553.1",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "图像去模糊的方法及装置",
    "inv": [
      "张佳维",
      "任思捷",
      "罗越"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108583571A",
    "apn": "CN201810403429.3",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "碰撞控制方法及装置、电子设备和存储介质",
    "inv": [
      "佟源洋",
      "毛宁元",
      "刘文志"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108549880A",
    "apn": "CN201810404555.0",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "碰撞控制方法及装置、电子设备和存储介质",
    "inv": [
      "佟源洋",
      "毛宁元",
      "刘文志"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108711180A",
    "apn": "CN201810411198.0",
    "apd": "2018-05-02",
    "apy": "2018",
    "tit": "美妆/换脸特效程序文件包的生成及美妆/换脸特效生成方法与装置",
    "inv": [
      "许亲亲",
      "杨瑞健"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110443363A",
    "apn": "CN201810418432.2",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "图像特征学习方法及装置",
    "inv": [
      "武智融",
      "熊元骏",
      "俞星星",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110443266A",
    "apn": "CN201810421005.X",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "对象预测方法及装置、电子设备和存储介质",
    "inv": [
      "徐旦",
      "欧阳万里",
      "王晓刚",
      "塞贝·尼库"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108734653A",
    "apn": "CN201810427162.1",
    "apd": "2018-05-07",
    "apy": "2018",
    "tit": "图像风格转换方法及装置",
    "inv": [
      "盛律",
      "林子义",
      "邵静"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108776834A",
    "apn": "CN201810428099.3",
    "apd": "2018-05-07",
    "apy": "2018",
    "tit": "系统增强学习方法和装置、电子设备、计算机存储介质",
    "inv": [
      "谢树钦",
      "陈梓天",
      "徐超",
      "卢策吾"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108615237A",
    "apn": "CN201810435102.4",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "一种肺部图像处理方法及图像处理设备",
    "inv": [
      "黄晓迪",
      "公茂亮"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108615236A",
    "apn": "CN201810432947.8",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "一种图像处理方法及电子设备",
    "inv": [
      "李嘉辉",
      "黄晓迪",
      "傅超",
      "公茂亮"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108629772A",
    "apn": "CN201810433533.7",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "图像处理方法及装置、计算机设备和计算机存储介质",
    "inv": [
      "黄晓迪",
      "李嘉辉",
      "傅超",
      "公茂亮"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108764069A",
    "apn": "CN201810444105.4",
    "apd": "2018-05-10",
    "apy": "2018",
    "tit": "活体检测方法及装置",
    "inv": [
      "杨凯",
      "暴天鹏",
      "张瑞",
      "吴立威"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108921008A",
    "apn": "CN201810456069.3",
    "apd": "2018-05-14",
    "apy": "2018",
    "tit": "人像识别方法、装置及电子设备",
    "inv": [
      "徐静",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108665963A",
    "apn": "CN201810462420.X",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "一种影像数据分析方法及相关设备",
    "inv": [
      "李嘉辉",
      "黄晓迪"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108665498A",
    "apn": "CN201810462277.4",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备和存储介质",
    "inv": [
      "黄明杨",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108665475A",
    "apn": "CN201810463674.3",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "神经网络训练、图像处理方法、装置、存储介质和电子设备",
    "inv": [
      "王嘉"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108734718A",
    "apn": "CN201810467235.X",
    "apd": "2018-05-16",
    "apy": "2018",
    "tit": "用于图像分割的处理方法、装置、产品、存储介质及设备",
    "inv": [
      "王嘉"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN108764091A",
    "apn": "CN201810481863.3",
    "apd": "2018-05-18",
    "apy": "2018",
    "tit": "活体检测方法及装置、电子设备和存储介质",
    "inv": [
      "张瑞",
      "杨凯",
      "暴天鹏",
      "吴立威"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108734003A",
    "apn": "CN201810495784.8",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "身份验证方法、装置、设备、存储介质及程序",
    "inv": [
      "陈朝军",
      "刘毅",
      "马堃",
      "刘杰",
      "徐妙然"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108875582A",
    "apn": "CN201810496634.9",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "身份验证方法、装置、设备、存储介质及程序",
    "inv": [
      "刘毅",
      "蒋文忠",
      "娄松亚",
      "左冬冬",
      "刘杰",
      "马堃"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108833359A",
    "apn": "CN201810496628.3",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "身份验证方法、装置、设备、存储介质及程序",
    "inv": [
      "徐妙然",
      "张忠福",
      "周文超",
      "肖丽萍"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108961209A",
    "apn": "CN201810498714.8",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "行人图像质量评价方法、电子设备及计算机可读介质",
    "inv": [
      "王华明",
      "徐静",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN108961327A",
    "apn": "CN201810496541.6",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "一种单目深度估计方法及其装置、设备和存储介质",
    "inv": [
      "郭晓阳",
      "李鸿升",
      "伊帅",
      "任思捷",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108932734A",
    "apn": "CN201810502947.0",
    "apd": "2018-05-23",
    "apy": "2018",
    "tit": "单目图像的深度恢复方法及装置、计算机设备",
    "inv": [
      "鲍虎军",
      "章国锋",
      "蒋沁宏",
      "石建萍"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108875787A",
    "apn": "CN201810500185.0",
    "apd": "2018-05-23",
    "apy": "2018",
    "tit": "一种图像识别方法及装置、计算机设备和存储介质",
    "inv": [
      "潘新钢",
      "石建萍",
      "罗平",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108961161A",
    "apn": "CN201810510757.3",
    "apd": "2018-05-24",
    "apy": "2018",
    "tit": "一种图像数据处理方法、装置和计算机存储介质",
    "inv": [
      "刘蓬博"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108846793A",
    "apn": "CN201810515119.0",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "基于图像风格转换模型的图像处理方法和终端设备",
    "inv": [
      "黄德亮",
      "朱烽",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108876707A",
    "apn": "CN201810515569.X",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "鸟瞰图生成及神经网络训练方法、装置、存储介质、设备",
    "inv": [
      "祝新革",
      "石建萍",
      "李鸿升",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108898557A",
    "apn": "CN201810541974.9",
    "apd": "2018-05-30",
    "apy": "2018",
    "tit": "图像恢复方法及装置、电子设备、计算机程序及存储介质",
    "inv": [
      "庞家昊",
      "曾进",
      "孙文秀",
      "肖瑞超"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108960283A",
    "apn": "CN201810540204.2",
    "apd": "2018-05-30",
    "apy": "2018",
    "tit": "分类任务增量处理方法及装置、电子设备和存储介质",
    "inv": [
      "侯赛辉",
      "潘薪宇",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108776983A",
    "apn": "CN201810551916.4",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "基于重建网络的人脸重建方法和装置、设备、介质、产品",
    "inv": [
      "张韵璇",
      "吴文岩",
      "李诚",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108875603A",
    "apn": "CN201810551908.X",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "基于车道线的智能驾驶控制方法和装置、电子设备",
    "inv": [
      "刘文志",
      "于晨笛",
      "程光亮",
      "朱海波"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108846924A",
    "apn": "CN201810556835.3",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "车辆及车门解锁控制方法、装置和车门解锁系统",
    "inv": [
      "黄晗",
      "黄程",
      "于晨笛",
      "刘文志"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108830784A",
    "apn": "CN201810553761.8",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "一种图像处理方法、装置和计算机存储介质",
    "inv": [
      "陈晨",
      "杨瑞健"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108830200A",
    "apn": "CN201810556482.7",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "一种图像处理方法、装置和计算机存储介质",
    "inv": [
      "刘文韬",
      "钱晨",
      "许亲亲",
      "陈晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108765274A",
    "apn": "CN201810556323.7",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "一种图像处理方法、装置和计算机存储介质",
    "inv": [
      "刘文韬",
      "钱晨",
      "许亲亲",
      "陈晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108830783A",
    "apn": "CN201810553047.9",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "一种图像处理方法、装置和计算机存储介质",
    "inv": [
      "刘文韬",
      "钱晨",
      "许亲亲",
      "陈晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108810320A",
    "apn": "CN201810558182.2",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "图像质量提升方法和装置",
    "inv": [
      "王露",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108668170A",
    "apn": "CN201810559284.6",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "图像信息处理方法及装置、存储介质",
    "inv": [
      "荆锐",
      "赵代平"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108876817A",
    "apn": "CN201810558528.9",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "交叉轨迹分析方法及装置、电子设备和存储介质",
    "inv": [
      "王晶",
      "张广程",
      "李蔚琳",
      "朱斌"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108897777A",
    "apn": "CN201810558523.6",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "目标对象追踪方法及装置、电子设备和存储介质",
    "inv": [
      "王晶",
      "张广程",
      "李蔚琳",
      "朱斌"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108905192A",
    "apn": "CN201810558086.8",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "信息处理方法及装置、存储介质",
    "inv": [
      "许亲亲",
      "栾青"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109040664A",
    "apn": "CN201810558607.X",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "视频流处理方法及装置、电子设备和存储介质",
    "inv": [
      "张垚",
      "习洋洋",
      "罗程",
      "吴俊楠",
      "温祖钦",
      "刘祖希",
      "张帅"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108829247A",
    "apn": "CN201810558371.X",
    "apd": "2018-06-01",
    "apy": "2018",
    "tit": "基于视线跟踪的交互方法及装置、计算机设备",
    "inv": [
      "许亲亲",
      "陶然"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109002757A",
    "apn": "CN201810565711.1",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "驾驶管理方法和系统、车载智能系统、电子设备、介质",
    "inv": [
      "孟德",
      "李轲",
      "于晨笛",
      "秦仁波"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108819900A",
    "apn": "CN201810565700.3",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "车辆控制方法和系统、车载智能系统、电子设备、介质",
    "inv": [
      "孟德",
      "李轲",
      "于晨笛",
      "秦仁波"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108921034A",
    "apn": "CN201810569921.8",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "人脸匹配方法及装置、存储介质",
    "inv": [
      "张帆",
      "彭彬绪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108897996A",
    "apn": "CN201810568455.1",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "标识信息关联方法及装置、电子设备及存储介质",
    "inv": [
      "张帆",
      "彭彬绪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108960892A",
    "apn": "CN201810568908.0",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "信息处理方法及装置、电子设备及存储介质",
    "inv": [
      "张帆",
      "彭彬绪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108921283A",
    "apn": "CN201810609601.0",
    "apd": "2018-06-13",
    "apy": "2018",
    "tit": "深度神经网络的归一化方法和装置、设备、存储介质",
    "inv": [
      "罗平",
      "吴凌云",
      "任家敏",
      "彭章琳",
      "张瑞茂",
      "王新江"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108986245A",
    "apn": "CN201810616924.2",
    "apd": "2018-06-14",
    "apy": "2018",
    "tit": "基于人脸识别的考勤方法及终端",
    "inv": [
      "蒋文忠",
      "肖伟华",
      "徐肇虎",
      "牛尧",
      "左冬冬",
      "黄王爵",
      "李百丁"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108985166A",
    "apn": "CN201810614355.8",
    "apd": "2018-06-14",
    "apy": "2018",
    "tit": "身份验证方法及装置、电子设备和存储介质",
    "inv": [
      "刘毅",
      "秦少明",
      "陈朝军",
      "徐妙然",
      "张超惠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108932760A",
    "apn": "CN201810616612.1",
    "apd": "2018-06-14",
    "apy": "2018",
    "tit": "基于人脸识别的考勤方法及终端",
    "inv": [
      "黄王爵",
      "徐肇虎",
      "左冬冬",
      "牛尧",
      "肖伟华",
      "李百丁",
      "蒋文忠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108846912A",
    "apn": "CN201810620166.1",
    "apd": "2018-06-14",
    "apy": "2018",
    "tit": "考勤方法、终端及服务器",
    "inv": [
      "左冬冬",
      "肖伟华",
      "徐肇虎",
      "黄王爵",
      "牛尧",
      "李百丁",
      "蒋文忠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108830221A",
    "apn": "CN201810623306.0",
    "apd": "2018-06-15",
    "apy": "2018",
    "tit": "图像的目标对象分割及训练方法和装置、设备、介质、产品",
    "inv": [
      "田超",
      "李聪",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109145150A",
    "apn": "CN201810621959.5",
    "apd": "2018-06-15",
    "apy": "2018",
    "tit": "目标匹配方法及装置、电子设备和存储介质",
    "inv": [
      "张瑞茂",
      "孙红斌",
      "罗平",
      "葛玉莹",
      "任宽泽",
      "林倞",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109145707A",
    "apn": "CN201810639810.X",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "王磊"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109145127A",
    "apn": "CN201810639814.8",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "王磊"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108986127A",
    "apn": "CN201810681220.3",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "图像分割神经网络的训练方法及图像分割方法、装置",
    "inv": [
      "王哲",
      "张文蔚",
      "曲国祥",
      "戴兴",
      "石建萍",
      "李飞",
      "何军军",
      "张秀兰",
      "乔宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109035373A",
    "apn": "CN201810690808.5",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "三维特效程序文件包的生成及三维特效生成方法与装置",
    "inv": [
      "许亲亲",
      "杨瑞健",
      "盛崇山"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108985190A",
    "apn": "CN201810689610.5",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "目标识别方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "沈岩涛",
      "李洪升",
      "伊帅",
      "王晓刚",
      "陈大鹏"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108986227A",
    "apn": "CN201810689571.9",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "粒子特效程序文件包的生成及粒子特效生成方法与装置",
    "inv": [
      "许亲亲",
      "杨瑞健",
      "盛崇山"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109101542A",
    "apn": "CN201810710439.1",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "图像识别结果输出方法及装置、电子设备和存储介质",
    "inv": [
      "黄潇莹",
      "张丹丹",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109300151A",
    "apn": "CN201810708146.X",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "图像处理方法和装置、电子设备",
    "inv": [
      "章国锋",
      "鲍虎军",
      "许龑",
      "石建萍"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN108881952A",
    "apn": "CN201810708738.1",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "视频生成方法及装置、电子设备和存储介质",
    "inv": [
      "郭家明",
      "盛律",
      "邵婧"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109240820A",
    "apn": "CN201810717692.X",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "图像处理任务的处理方法及装置、电子设备及存储介质",
    "inv": [
      "刘祖希",
      "陈宇恒",
      "张帅",
      "张垚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109101992A",
    "apn": "CN201810728913.3",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "图像匹配方法、装置和计算机可读存储介质",
    "inv": [
      "杨磊",
      "张行程",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109087238A",
    "apn": "CN201810724309.3",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "图像处理方法和装置、电子设备以及计算机可读存储介质",
    "inv": [
      "郝元桢",
      "黄明杨",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108924491A",
    "apn": "CN201810726676.7",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "视频流处理方法及装置、电子设备和存储介质",
    "inv": [
      "张帅",
      "陈万里",
      "孙栋梁",
      "刘祖希",
      "温祖钦",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109118616A",
    "apn": "CN201810749562.4",
    "apd": "2018-07-09",
    "apy": "2018",
    "tit": "门禁控制方法及门禁控制装置",
    "inv": [
      "黄湘",
      "佘忠华",
      "周文超",
      "肖丽萍"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109033264A",
    "apn": "CN201810744775.8",
    "apd": "2018-07-09",
    "apy": "2018",
    "tit": "视频分析方法及装置、电子设备和存储介质",
    "inv": [
      "王青",
      "张帅",
      "刘恒",
      "颜铭佳",
      "王娜"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108921225A",
    "apn": "CN201810753708.2",
    "apd": "2018-07-10",
    "apy": "2018",
    "tit": "一种图像处理方法及装置、计算机设备和存储介质",
    "inv": [
      "孙书洋",
      "庞江淼",
      "石建萍",
      "伊帅",
      "欧阳万里"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108921117A",
    "apn": "CN201810757714.5",
    "apd": "2018-07-11",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "刘庭皓",
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109063590A",
    "apn": "CN201810764140.4",
    "apd": "2018-07-12",
    "apy": "2018",
    "tit": "基于人脸识别的信息处理方法、装置及系统",
    "inv": [
      "时占",
      "黄唯",
      "苗文健",
      "周雪琪",
      "王磊",
      "石瑞广"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109034219A",
    "apn": "CN201810763572.3",
    "apd": "2018-07-12",
    "apy": "2018",
    "tit": "图像的多标签类别预测方法及装置、电子设备和存储介质",
    "inv": [
      "刘永成",
      "邵婧",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN108900788A",
    "apn": "CN201810765554.9",
    "apd": "2018-07-12",
    "apy": "2018",
    "tit": "视频生成方法、视频生成装置、电子装置及存储介质",
    "inv": [
      "杨策元",
      "王哲",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109165644A",
    "apn": "CN201810770381.X",
    "apd": "2018-07-13",
    "apy": "2018",
    "tit": "目标检测方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109241988A",
    "apn": "CN201810779116.8",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "特征提取方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "黄诗尧",
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109003270A",
    "apn": "CN201810814377.9",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "一种图像处理方法及电子设备",
    "inv": [
      "李嘉辉",
      "胡志强",
      "王文集",
      "姚雨馨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109189989A",
    "apn": "CN201810814079.X",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "一种视频描述方法及装置、计算机设备和存储介质",
    "inv": [
      "熊异雷",
      "戴勃",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109146769A",
    "apn": "CN201810819327.X",
    "apd": "2018-07-24",
    "apy": "2018",
    "tit": "图像处理方法及装置、图像处理设备及存储介质",
    "inv": [
      "石建萍",
      "黄明杨",
      "曲艺",
      "许经纬"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN108986891A",
    "apn": "CN201810818690.X",
    "apd": "2018-07-24",
    "apy": "2018",
    "tit": "医疗影像处理方法及装置、电子设备及存储介质",
    "inv": [
      "夏清",
      "高云河"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109191409A",
    "apn": "CN201810826566.8",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "图像处理、网络训练方法、装置、电子设备和存储介质",
    "inv": [
      "沈宇军",
      "罗平",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109191515A",
    "apn": "CN201810824486.9",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "一种图像视差估计方法及装置、存储介质",
    "inv": [
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109325972A",
    "apn": "CN201810829623.8",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "激光雷达稀疏深度图的处理方法、装置、设备及介质",
    "inv": [
      "范峻铭",
      "黄子煊",
      "周泊谷",
      "伊帅",
      "李鸿升"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109117055A",
    "apn": "CN201810837285.2",
    "apd": "2018-07-26",
    "apy": "2018",
    "tit": "智能终端及控制方法",
    "inv": [
      "倪枫"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109325141A",
    "apn": "CN201810836743.0",
    "apd": "2018-07-26",
    "apy": "2018",
    "tit": "图像检索方法及装置、电子设备和存储介质",
    "inv": [
      "汤晓鸥",
      "黄青虬",
      "刘文韬",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109191558A",
    "apn": "CN201810848283.3",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "图像打光方法和装置",
    "inv": [
      "苏锦海",
      "韩松芳",
      "严琼",
      "王昊然"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109165563A",
    "apn": "CN201810848366.2",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "行人再识别方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "陈大鹏",
      "李鸿升",
      "刘希慧",
      "邵静",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109255778A",
    "apn": "CN201810846173.3",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "图像处理方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109191512A",
    "apn": "CN201810847268.7",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "双目图像的深度估计方法及装置、设备、程序及介质",
    "inv": [
      "肖瑞超",
      "孙文秀",
      "杨成熙"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109035288A",
    "apn": "CN201810848300.3",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "一种图像处理方法及装置、设备和存储介质",
    "inv": [
      "陈晨",
      "陶然"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109035319A",
    "apn": "CN201810845040.4",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "单目图像深度估计方法及装置、设备、程序及存储介质",
    "inv": [
      "甘宇康",
      "许翔宇",
      "孙文秀",
      "林倞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109241835A",
    "apn": "CN201810842970.4",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "邵志文",
      "马利庄",
      "刘志磊",
      "蔡剑飞"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109190470A",
    "apn": "CN201810843200.1",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "行人重识别方法及装置",
    "inv": [
      "葛艺潇",
      "李卓婉",
      "赵海宇",
      "伊帅",
      "王晓刚",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109165523A",
    "apn": "CN201810852671.9",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "身份认证方法及系统、终端设备、服务器及存储介质",
    "inv": [
      "张帆",
      "李启铭"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN108900903A",
    "apn": "CN201810840172.8",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "视频处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄明杨",
      "郝元桢",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109165562A",
    "apn": "CN201810845656.1",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "神经网络的训练方法、横向控制方法、装置、设备及介质",
    "inv": [
      "侯跃南",
      "吕健勤",
      "马政",
      "刘春晓"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN108900904A",
    "apn": "CN201810840256.1",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "视频处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄明杨",
      "郝元桢",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109040837A",
    "apn": "CN201810842643.9",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "视频处理方法及装置、电子设备和存储介质",
    "inv": [
      "郝元桢",
      "黄明杨",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109034068A",
    "apn": "CN201810845512.6",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "视频处理方法及装置、电子设备和存储介质",
    "inv": [
      "郝元桢",
      "黄明杨",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109190720A",
    "apn": "CN201810849877.6",
    "apd": "2018-07-28",
    "apy": "2018",
    "tit": "智能体强化学习方法、装置、设备及介质",
    "inv": [
      "刘春晓",
      "薛洋",
      "张伟",
      "林倞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109263659A",
    "apn": "CN201810850399.0",
    "apd": "2018-07-28",
    "apy": "2018",
    "tit": "智能驾驶控制方法和装置、车辆、电子设备、介质、产品",
    "inv": [
      "毛宁元"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109159667A",
    "apn": "CN201810850398.6",
    "apd": "2018-07-28",
    "apy": "2018",
    "tit": "智能驾驶控制方法和装置、车辆、电子设备、介质、产品",
    "inv": [
      "毛宁元"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109325512A",
    "apn": "CN201810866704.5",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "图像分类方法及装置、电子设备、计算机程序及存储介质",
    "inv": [
      "丁明宇",
      "王哲",
      "石建萍",
      "卢志武"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109271545A",
    "apn": "CN201810873786.6",
    "apd": "2018-08-02",
    "apy": "2018",
    "tit": "一种特征检索方法及装置、存储介质和计算机设备",
    "inv": [
      "陈宇恒",
      "樊俊良"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109558781A",
    "apn": "CN201810870738.1",
    "apd": "2018-08-02",
    "apy": "2018",
    "tit": "一种多视角视频识别方法及装置、设备和存储介质",
    "inv": [
      "王东昂",
      "欧阳万里",
      "李文",
      "徐东"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109242882A",
    "apn": "CN201810885811.2",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "视觉跟踪方法、装置、介质及设备",
    "inv": [
      "钟钊",
      "杨子琛",
      "胡扬阳",
      "武伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109166130A",
    "apn": "CN201810885716.2",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "一种图像处理方法及图像处理装置",
    "inv": [
      "夏清"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109102501A",
    "apn": "CN201810888096.8",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "一种关节图像处理方法及图像处理设备",
    "inv": [
      "夏清",
      "谢帅宁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109145970A",
    "apn": "CN201810884844.5",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "基于图像的问答处理方法和装置、电子设备及存储介质",
    "inv": [
      "高鹏",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109299716A",
    "apn": "CN201810893155.0",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "神经网络的训练方法、图像分割方法、装置、设备及介质",
    "inv": [
      "祝新革",
      "周辉",
      "杨策元",
      "石建萍",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109344839A",
    "apn": "CN201810892869.X",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "图像处理方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "李弘扬",
      "郭晓阳",
      "戴勃",
      "欧阳万里",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109344840A",
    "apn": "CN201810893153.1",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "图像处理方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "赵恒爽",
      "张熠",
      "石建萍"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109271854A",
    "apn": "CN201810892836.5",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "基于视频处理方法及装置、视频设备及存储介质",
    "inv": [
      "周彧聪",
      "王诗瑶"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109068138A",
    "apn": "CN201810892284.8",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "视频图像的处理方法及装置、电子设备和存储介质",
    "inv": [
      "鲁国",
      "欧阳万里",
      "徐东",
      "张小云",
      "高志勇",
      "孙明庭"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109146786A",
    "apn": "CN201810893159.9",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "场景图生成方法及装置、电子设备和存储介质",
    "inv": [
      "李怡康",
      "欧阳万里",
      "周博磊",
      "石建萍",
      "张超",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109284673A",
    "apn": "CN201810893022.3",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "对象跟踪方法及装置、电子设备及存储介质",
    "inv": [
      "王强",
      "朱政",
      "李搏",
      "武伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109035261A",
    "apn": "CN201810903956.0",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "医疗影像处理方法及装置、电子设备及存储介质",
    "inv": [
      "夏清",
      "李晓川"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109359502A",
    "apn": "CN201810918699.8",
    "apd": "2018-08-13",
    "apy": "2018",
    "tit": "防伪检测方法和装置、电子设备、存储介质",
    "inv": [
      "张瑞",
      "吴立威",
      "梁明阳",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109255299A",
    "apn": "CN201810918697.9",
    "apd": "2018-08-13",
    "apy": "2018",
    "tit": "身份认证方法和装置、电子设备和存储介质",
    "inv": [
      "党亮亮",
      "黄攀",
      "陈鹏辉"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109308679A",
    "apn": "CN201810917979.7",
    "apd": "2018-08-13",
    "apy": "2018",
    "tit": "一种图像风格转换方及装置、设备、存储介质",
    "inv": [
      "贺高远",
      "柳一村",
      "陈晓濠",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109166107A",
    "apn": "CN201810916330.3",
    "apd": "2018-08-13",
    "apy": "2018",
    "tit": "一种医学图像分割方法及装置、电子设备和存储介质",
    "inv": [
      "王哲",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109271859A",
    "apn": "CN201810922364.3",
    "apd": "2018-08-14",
    "apy": "2018",
    "tit": "串并案方法和装置、电子设备、计算机存储介质",
    "inv": [
      "黄潇莹",
      "张丹丹",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109308686A",
    "apn": "CN201810935885.2",
    "apd": "2018-08-16",
    "apy": "2018",
    "tit": "一种鱼眼图像处理方法及装置、设备和存储介质",
    "inv": [
      "姚兴华",
      "曾星宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109285126A",
    "apn": "CN201810942614.X",
    "apd": "2018-08-17",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109224444A",
    "apn": "CN201810941344.0",
    "apd": "2018-08-17",
    "apy": "2018",
    "tit": "游戏人物识别方法、装置、存储介质和电子设备",
    "inv": [
      "朱允全",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109241875A",
    "apn": "CN201810949860.8",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "姿态检测方法及装置、电子设备和存储介质",
    "inv": [
      "汪旻",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109284681A",
    "apn": "CN201810950565.4",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "位姿检测方法及装置、电子设备和存储介质",
    "inv": [
      "汪旻",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109147368A",
    "apn": "CN201810961511.8",
    "apd": "2018-08-22",
    "apy": "2018",
    "tit": "基于车道线的智能驾驶控制方法装置与电子设备",
    "inv": [
      "程光亮",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109064504A",
    "apn": "CN201810976003.7",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "图像处理方法、装置和计算机存储介质",
    "inv": [
      "董超",
      "李嵩",
      "贺岳平",
      "王甜甜",
      "赵富荣",
      "周仪"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109118430A",
    "apn": "CN201810974986.0",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "超分辨率图像重建方法及装置、电子设备及存储介质",
    "inv": [
      "董超",
      "赵富荣",
      "李嵩",
      "贺岳平",
      "林娟",
      "王甜甜"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109144260A",
    "apn": "CN201810974244.8",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "动态动作检测方法、动态动作控制方法及装置",
    "inv": [
      "杜天元",
      "于晨笛",
      "杨雅迪"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109344703A",
    "apn": "CN201810973120.8",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "对象检测方法及装置、电子设备和存储介质",
    "inv": [
      "张垚",
      "李七星",
      "孙栋梁",
      "张帅"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109299120A",
    "apn": "CN201811014668.6",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "数据处理方法和装置、电子设备、计算机存储介质",
    "inv": [
      "左冬冬",
      "肖伟华",
      "徐肇虎",
      "黄王爵",
      "牛尧",
      "蒋文忠",
      "李百丁"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109359517A",
    "apn": "CN201811011751.8",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "图像识别方法和装置、电子设备、存储介质、程序产品",
    "inv": [
      "陈世哲",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109344832A",
    "apn": "CN201811019940.X",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "唐回峰",
      "周心池",
      "侯军",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109360633A",
    "apn": "CN201811028337.8",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "医疗影像处理方法及装置、处理设备及存储介质",
    "inv": [
      "杨爽",
      "李嘉辉",
      "胡志强"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109242845A",
    "apn": "CN201811033736.3",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "医疗影像处理方法及装置、电子设备及存储介质",
    "inv": [
      "王允直",
      "李嘉辉",
      "胡志强"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110019960A",
    "apn": "CN201811032017.X",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "数据处理方法及装置、电子设备和存储介质",
    "inv": [
      "颜铭佳",
      "张贵明",
      "朱斌",
      "季聪"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109409204A",
    "apn": "CN201811044838.5",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "防伪检测方法和装置、电子设备、存储介质",
    "inv": [
      "吴立威",
      "张瑞",
      "闫俊杰",
      "彭义刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109271915A",
    "apn": "CN201811044839.X",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "防伪检测方法和装置、电子设备、存储介质",
    "inv": [
      "吴立威",
      "张瑞",
      "闫俊杰",
      "彭义刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109146322A",
    "apn": "CN201811062659.4",
    "apd": "2018-09-12",
    "apy": "2018",
    "tit": "监控方法及装置和系统、电子设备以及存储介质",
    "inv": [
      "蔺琛皓",
      "周瑶",
      "卢宇",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109255784A",
    "apn": "CN201811068237.8",
    "apd": "2018-09-13",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "李嘉辉",
      "胡志强"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109410216A",
    "apn": "CN201811072731.1",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "一种缺血性脑卒中图像区域分割方法及装置",
    "inv": [
      "宋涛",
      "刘蓬博"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109242863A",
    "apn": "CN201811073025.9",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "一种缺血性脑卒中图像区域分割方法及装置",
    "inv": [
      "刘蓬博",
      "宋涛"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109345540A",
    "apn": "CN201811077358.9",
    "apd": "2018-09-15",
    "apy": "2018",
    "tit": "一种图像处理方法、电子设备及存储介质",
    "inv": [
      "李嘉辉",
      "胡志强"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109389129A",
    "apn": "CN201811077349.X",
    "apd": "2018-09-15",
    "apy": "2018",
    "tit": "一种图像处理方法、电子设备及存储介质",
    "inv": [
      "李嘉辉",
      "胡志强"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109359548A",
    "apn": "CN201811097371.0",
    "apd": "2018-09-19",
    "apy": "2018",
    "tit": "多人脸识别监控方法及装置、电子设备及存储介质",
    "inv": [
      "左冬冬",
      "徐肇虎",
      "黄王爵",
      "肖伟华",
      "李百丁",
      "蒋文忠",
      "牛尧"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109409382A",
    "apn": "CN201811108575.X",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "李诚",
      "钱晨",
      "吴文岩",
      "曹凯迪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109345522A",
    "apn": "CN201811116594.7",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "一种图像质量筛选方法及装置、设备和存储介质",
    "inv": [
      "唐回峰",
      "侯军",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109145139A",
    "apn": "CN201811116598.5",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "一种图像检索方法及装置、设备和存储介质",
    "inv": [
      "唐回峰",
      "侯军",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109446912A",
    "apn": "CN201811141270.9",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "人脸图像的处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄明杨",
      "付万增",
      "石建萍",
      "曲艺"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109389069A",
    "apn": "CN201811141352.3",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "注视点判断方法和装置、电子设备和计算机存储介质",
    "inv": [
      "刘庭皓",
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109389562A",
    "apn": "CN201811148733.4",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "图像修复方法及装置",
    "inv": [
      "李传俊",
      "严琼",
      "王昊然",
      "鲍旭",
      "戴立根"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109389076A",
    "apn": "CN201811152333.0",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "图像分割方法及装置",
    "inv": [
      "李传俊",
      "严琼",
      "王昊然",
      "王灿彬",
      "李佳桦"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109242092A",
    "apn": "CN201811149858.9",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "网络获取及图像处理方法和装置、电子设备、存储介质",
    "inv": [
      "林宸"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109410309A",
    "apn": "CN201811158296.4",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "重光照方法和装置、电子设备和计算机存储介质",
    "inv": [
      "戴立根"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109584146A",
    "apn": "CN201811199000.3",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "美颜处理方法和装置、电子设备和计算机存储介质",
    "inv": [
      "戴立根"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109584145A",
    "apn": "CN201811198152.1",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "卡通化方法和装置、电子设备和计算机存储介质",
    "inv": [
      "戴立根"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109492669A",
    "apn": "CN201811196876.2",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "图像描述方法及装置、电子设备和存储介质",
    "inv": [
      "戴勃",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109413510A",
    "apn": "CN201811224169.X",
    "apd": "2018-10-19",
    "apy": "2018",
    "tit": "视频摘要生成方法和装置、电子设备、计算机存储介质",
    "inv": [
      "冯俐铜",
      "肖达",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109348287A",
    "apn": "CN201811229502.6",
    "apd": "2018-10-22",
    "apy": "2018",
    "tit": "视频摘要生成方法、装置、存储介质和电子设备",
    "inv": [
      "冯俐铜",
      "旷章辉",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109543536A",
    "apn": "CN201811236557.X",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "图像标识方法及装置、电子设备和存储介质",
    "inv": [
      "蔡晓聪",
      "侯军",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109522937A",
    "apn": "CN201811237469.1",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "蔡晓聪",
      "侯军",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109543537A",
    "apn": "CN201811236872.2",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "重识别模型增量训练方法及装置、电子设备和存储介质",
    "inv": [
      "蔡晓聪",
      "侯军",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109543556A",
    "apn": "CN201811281715.3",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "动作识别方法、装置、介质及设备",
    "inv": [
      "赵岳",
      "熊元骏",
      "林达华",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109598191A",
    "apn": "CN201811237115.7",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "行人重识别残差网络训练方法及装置",
    "inv": [
      "蔡晓聪",
      "侯军",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109377446A",
    "apn": "CN201811253127.9",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "人脸图像的处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄明杨",
      "付万增",
      "石建萍",
      "曲艺"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109543544A",
    "apn": "CN201811250546.7",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "跨光谱图像匹配方法及装置、电子设备和存储介质",
    "inv": [
      "梁明阳",
      "吴立威",
      "郭晓阳"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109409288A",
    "apn": "CN201811252499.X",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备和存储介质",
    "inv": [
      "庞江淼"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109584168A",
    "apn": "CN201811250835.7",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "图像处理方法和装置、电子设备和计算机存储介质",
    "inv": [
      "黄明杨",
      "付万增",
      "石建萍",
      "曲艺"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109447931A",
    "apn": "CN201811264701.0",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "图像处理方法及装置",
    "inv": [
      "严琼",
      "陈梓琪",
      "柯章翰",
      "任思捷",
      "曾进"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109472738A",
    "apn": "CN201811258610.6",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "图像光照校正方法及装置、电子设备和存储介质",
    "inv": [
      "陈梓琪",
      "严琼",
      "曾进"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110431560A",
    "apn": "CN201880018948.6",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "目标人物的搜索方法和装置、设备、程序产品和介质",
    "inv": [
      "田茂清",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109376671A",
    "apn": "CN201811284370.7",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "图像处理方法、电子设备及计算机可读介质",
    "inv": [
      "黄明杨",
      "付万增",
      "石建萍",
      "曲艺"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109359618A",
    "apn": "CN201811281028.1",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "一种图像处理方法及其装置、设备和存储介质",
    "inv": [
      "黄明杨",
      "付万增",
      "石建萍",
      "曲艺"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109472753A",
    "apn": "CN201811278927.6",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "一种图像处理方法、装置、计算机设备和计算机存储介质",
    "inv": [
      "黄明杨",
      "付万增",
      "石建萍",
      "曲艺"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109325908A",
    "apn": "CN201811278366.X",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "荆锐"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109559272A",
    "apn": "CN201811278997.1",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "一种图像处理方法及装置、电子设备、存储介质",
    "inv": [
      "任思捷",
      "陈焜",
      "陈晓濠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109544716A",
    "apn": "CN201811287553.4",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "学生签到方法及装置、电子设备和存储介质",
    "inv": [
      "戴娟",
      "夏俊",
      "吴军"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109544560A",
    "apn": "CN201811287035.2",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "宋涛",
      "刘蓬博"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109568937A",
    "apn": "CN201811291440.1",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "游戏控制方法及装置、游戏终端及存储介质",
    "inv": [
      "许亲亲",
      "陶然"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109598198A",
    "apn": "CN201811288978.7",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "用于识别手势移动方向的方法、装置、介质、程序及设备",
    "inv": [
      "曾文良",
      "王贵杰",
      "王灿彬"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109543566A",
    "apn": "CN201811308022.9",
    "apd": "2018-11-05",
    "apy": "2018",
    "tit": "信息处理方法及装置、电子设备和存储介质",
    "inv": [
      "陈英震",
      "杨松"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109614867A",
    "apn": "CN201811329655.8",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "人体关键点检测方法和装置、电子设备、计算机存储介质",
    "inv": [
      "杨昆霖",
      "田茂清",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109635920A",
    "apn": "CN201811341293.4",
    "apd": "2018-11-12",
    "apy": "2018",
    "tit": "神经网络优化方法及装置、电子设备和存储介质",
    "inv": [
      "周东展",
      "周心池",
      "张学森",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109635142A",
    "apn": "CN201811359371.3",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "图像选择方法及装置、电子设备和存储介质",
    "inv": [
      "张学森",
      "伊帅",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109671051A",
    "apn": "CN201811359236.9",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "图像质量检测模型训练方法及装置、电子设备和存储介质",
    "inv": [
      "张学森",
      "伊帅",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN109615655A",
    "apn": "CN201811372233.9",
    "apd": "2018-11-16",
    "apy": "2018",
    "tit": "一种确定物体姿态的方法及装置、电子设备及计算机介质",
    "inv": [
      "李瑶鑫",
      "张展鹏",
      "成慧"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109614876A",
    "apn": "CN201811367869.4",
    "apd": "2018-11-16",
    "apy": "2018",
    "tit": "关键点检测方法及装置、电子设备和存储介质",
    "inv": [
      "杨昆霖",
      "田茂清",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109522843A",
    "apn": "CN201811368687.9",
    "apd": "2018-11-16",
    "apy": "2018",
    "tit": "一种多目标跟踪方法及装置、设备和存储介质",
    "inv": [
      "田茂清",
      "杨昆霖",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109558901A",
    "apn": "CN201811369552.4",
    "apd": "2018-11-16",
    "apy": "2018",
    "tit": "一种语义分割训练方法及装置、电子设备、存储介质",
    "inv": [
      "孙若琪",
      "祝新革",
      "黄琛",
      "吴冲若",
      "石建萍",
      "马利庄"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109559300A",
    "apn": "CN201811378299.9",
    "apd": "2018-11-19",
    "apy": "2018",
    "tit": "图像处理方法、电子设备及计算机可读存储介质",
    "inv": [
      "宋涛",
      "李想之"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109544748A",
    "apn": "CN201811395117.9",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "门禁管理方法及门禁控制终端、系统",
    "inv": [
      "佘忠华",
      "马堃",
      "庄南庆"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109391697A",
    "apn": "CN201811402413.7",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "信息推送方法、用户终端、服务器及门禁控制终端",
    "inv": [
      "佘忠华",
      "马堃",
      "庄南庆"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109584358A",
    "apn": "CN201811435701.2",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "一种三维人脸重建方法及装置、设备和存储介质",
    "inv": [
      "朴镜潭",
      "王权",
      "钱晨",
      "王灿彬",
      "李佳桦"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109376698A",
    "apn": "CN201811446394.8",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "人脸建模方法和装置、电子设备、存储介质、产品",
    "inv": [
      "朴镜潭",
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109598671A",
    "apn": "CN201811446401.4",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "图像生成方法、装置、设备及介质",
    "inv": [
      "沈宇军",
      "闫俊杰",
      "罗平"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109615593A",
    "apn": "CN201811444495.1",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "尹伟东",
      "刘子纬",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109635685A",
    "apn": "CN201811446588.8",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "目标对象3D检测方法、装置、介质及设备",
    "inv": [
      "史少帅",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109636890A",
    "apn": "CN201811446395.2",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "纹理融合方法和装置、电子设备、存储介质、产品",
    "inv": [
      "朴镜潭",
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109614613A",
    "apn": "CN201811459428.7",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "图像的描述语句定位方法及装置、电子设备和存储介质",
    "inv": [
      "刘希慧",
      "邵婧",
      "王子豪",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109635926A",
    "apn": "CN201811459423.4",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "用于神经网络的注意力特征获取方法、装置及存储介质",
    "inv": [
      "沈卓然",
      "张明远",
      "赵海宇",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109670420A",
    "apn": "CN201811472886.4",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "存储终端的控制方法和装置、存储终端、电子设备、介质",
    "inv": [
      "池剑文",
      "赵萃"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109598249A",
    "apn": "CN201811497170.X",
    "apd": "2018-12-07",
    "apy": "2018",
    "tit": "服饰检测方法和装置、电子设备、存储介质",
    "inv": [
      "张伟",
      "陈益民",
      "陈海峰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109614925A",
    "apn": "CN201811497137.7",
    "apd": "2018-12-07",
    "apy": "2018",
    "tit": "服饰属性识别方法和装置、电子设备、存储介质",
    "inv": [
      "张伟",
      "陈海峰",
      "陈益民"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109754389A",
    "apn": "CN201811500631.4",
    "apd": "2018-12-07",
    "apy": "2018",
    "tit": "一种病灶检测方法、装置及设备",
    "inv": [
      "黄锐",
      "高云河"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109597907A",
    "apn": "CN201811497168.2",
    "apd": "2018-12-07",
    "apy": "2018",
    "tit": "服饰管理方法和装置、电子设备、存储介质",
    "inv": [
      "张伟",
      "陈益民",
      "陈海峰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109584362A",
    "apn": "CN201811536471.9",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "三维模型构建方法及装置、电子设备和存储介质",
    "inv": [
      "朴镜潭",
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109670591A",
    "apn": "CN201811535420.4",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "一种神经网络的训练方法及图像匹配方法、装置",
    "inv": [
      "葛玉莹",
      "吴凌云",
      "张瑞茂",
      "罗平"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109658401A",
    "apn": "CN201811535357.4",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109658352A",
    "apn": "CN201811536144.3",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "图像信息的优化方法及装置、电子设备和存储介质",
    "inv": [
      "任思捷",
      "陈岩",
      "程璇晔",
      "孙文秀"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109741395A",
    "apn": "CN201811534455.6",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "双心室量化方法、装置、电子设备及存储介质",
    "inv": [
      "王文集",
      "胡志强",
      "李嘉辉",
      "闫桢楠"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109741379A",
    "apn": "CN201811559600.6",
    "apd": "2018-12-19",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109658572A",
    "apn": "CN201811574840.3",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "卢屹",
      "曹理",
      "洪春蕾"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109753152A",
    "apn": "CN201811571232.7",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "基于人体姿态的交互方法及装置、计算机设备",
    "inv": [
      "陈楷佳",
      "黄頔",
      "于志兴",
      "董亚魁",
      "陆雨柔",
      "张帆",
      "彭彬绪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109670458A",
    "apn": "CN201811573454.2",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "一种车牌识别方法及装置",
    "inv": [
      "刘学博"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109711546A",
    "apn": "CN201811573466.5",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "神经网络训练方法及装置、电子设备和存储介质",
    "inv": [
      "朱烽",
      "赵瑞",
      "陈大鹏",
      "张凯鹏"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109697734A",
    "apn": "CN201811591706.4",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "位姿估计方法及装置、电子设备和存储介质",
    "inv": [
      "周晓巍",
      "鲍虎军",
      "刘缘",
      "彭思达"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109522910A",
    "apn": "CN201811593614.X",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "关键点检测方法及装置、电子设备和存储介质",
    "inv": [
      "周晓巍",
      "鲍虎军",
      "彭思达",
      "刘缘"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109754414A",
    "apn": "CN201811614468.4",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109767460A",
    "apn": "CN201811612706.8",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109685709A",
    "apn": "CN201811625748.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种智能机器人的照明控制方法及装置",
    "inv": [
      "倪枫",
      "王婉秋"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109740659A",
    "apn": "CN201811618460.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种图像匹配方法及装置、电子设备、存储介质",
    "inv": [
      "周晓巍",
      "鲍虎军",
      "董峻廷",
      "蒋文"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109767446A",
    "apn": "CN201811624119.0",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种实例分割方法及装置、电子设备、存储介质",
    "inv": [
      "陈恺",
      "庞江淼",
      "王佳琦",
      "熊宇",
      "李晓潇",
      "孙书洋",
      "奉万森",
      "刘子玮",
      "石建萍",
      "欧阳万里",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109753920A",
    "apn": "CN201811637119.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种行人识别方法及装置",
    "inv": [
      "朱铖恺",
      "张寿奎",
      "武伟",
      "闫俊杰",
      "黄潇莹"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109740516A",
    "apn": "CN201811641756.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种用户识别方法、装置、电子设备及存储介质",
    "inv": [
      "胡扬阳",
      "朱铖恺",
      "武伟",
      "闫俊杰",
      "海逸姣"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109686426A",
    "apn": "CN201811639719.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "医疗影像处理方法及装置、电子设备及存储介质",
    "inv": [
      "刘蓬博",
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109801270A",
    "apn": "CN201811639702.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "锚点确定方法及装置、电子设备和存储介质",
    "inv": [
      "陈恺",
      "王佳琦",
      "杨硕",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109726683A",
    "apn": "CN201811635978.X",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "目标对象检测方法和装置、电子设备和存储介质",
    "inv": [
      "李搏",
      "武伟",
      "张方毅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109740752A",
    "apn": "CN201811646430.5",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "深度模型训练方法及装置、电子设备及存储介质",
    "inv": [
      "李嘉辉"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109740668A",
    "apn": "CN201811646736.0",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "深度模型训练方法及装置、电子设备及存储介质",
    "inv": [
      "李嘉辉"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109767470A",
    "apn": "CN201910012159.8",
    "apd": "2019-01-07",
    "apy": "2019",
    "tit": "一种跟踪系统初始化方法及终端设备",
    "inv": [
      "鲍虎军",
      "章国锋",
      "李津宇"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109784255A",
    "apn": "CN201910012682.0",
    "apd": "2019-01-07",
    "apy": "2019",
    "tit": "神经网络训练方法及装置以及识别方法及装置",
    "inv": [
      "王露",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109740557A",
    "apn": "CN201910023627.1",
    "apd": "2019-01-10",
    "apy": "2019",
    "tit": "对象检测方法及装置、电子设备和存储介质",
    "inv": [
      "陈楷佳",
      "彭彬绪",
      "袁玮",
      "陆雨柔",
      "张义保",
      "黄頔",
      "董亚魁",
      "于志兴"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109816701A",
    "apn": "CN201910045247.8",
    "apd": "2019-01-17",
    "apy": "2019",
    "tit": "一种目标跟踪方法及装置、存储介质",
    "inv": [
      "冯炜韬",
      "胡智昊",
      "武伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110060230A",
    "apn": "CN201910049152.3",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "三维场景分析方法、装置、介质及设备",
    "inv": [
      "潘柏文",
      "亚历克斯安东尼安",
      "奥德·奥利瓦",
      "周博磊"
    ],
    "app": [
      "商汤集团有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109800807A",
    "apn": "CN201910049144.9",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "分类网络的训练方法及分类方法和装置、电子设备",
    "inv": [
      "甘伟豪",
      "王意如"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109829863A",
    "apn": "CN201910060238.6",
    "apd": "2019-01-22",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "周尚辰",
      "张佳维",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109819229A",
    "apn": "CN201910060235.2",
    "apd": "2019-01-22",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "裘第",
      "庞家昊",
      "杨成熙",
      "孙文秀"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109685041A",
    "apn": "CN201910063728.1",
    "apd": "2019-01-23",
    "apy": "2019",
    "tit": "图像分析方法及装置、电子设备和存储介质",
    "inv": [
      "冯伟",
      "刘文韬",
      "李通",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109800865A",
    "apn": "CN201910068770.2",
    "apd": "2019-01-24",
    "apy": "2019",
    "tit": "神经网络生成及图像处理方法和装置、平台、电子设备",
    "inv": [
      "俞海宝",
      "温拓朴",
      "程光亮",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109871883A",
    "apn": "CN201910068817.5",
    "apd": "2019-01-24",
    "apy": "2019",
    "tit": "神经网络训练方法及装置、电子设备和存储介质",
    "inv": [
      "王哲",
      "王占宇",
      "曲国祥",
      "李飞",
      "袁野",
      "林顺潮",
      "张秀兰",
      "乔宇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109657100A",
    "apn": "CN201910075122.X",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "视频集锦生成方法及装置、电子设备及存储介质",
    "inv": [
      "卢宇",
      "范宏伟",
      "罗思伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109840917A",
    "apn": "CN201910086044.3",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "图像处理方法及装置、网络训练方法及装置",
    "inv": [
      "詹晓航",
      "潘新钢",
      "刘子纬",
      "林达华",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109784420A",
    "apn": "CN201910087398.X",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、计算机设备和存储介质",
    "inv": [
      "邵文琪",
      "孟天健",
      "张瑞茂",
      "罗平",
      "吴凌云"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109785322A",
    "apn": "CN201910099220.7",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "单眼人体姿态估计网络训练方法、图像处理方法和装置",
    "inv": [
      "钱晨",
      "林君仪",
      "刘文韬"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109840500A",
    "apn": "CN201910098332.0",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "一种三维人体姿态信息检测方法及装置",
    "inv": [
      "王鲁阳",
      "陈岩",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109829433A",
    "apn": "CN201910101153.8",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "人脸图像识别方法、装置、电子设备及存储介质",
    "inv": [
      "杨磊",
      "詹晓航",
      "陈大鹏",
      "闫俊杰",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109840890A",
    "apn": "CN201910100185.6",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "顾津锦",
      "芦瀚楠",
      "董超"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109816620A",
    "apn": "CN201910098569.9",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "顾津锦",
      "芦瀚楠",
      "董超"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109934107A",
    "apn": "CN201910095929.X",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备及存储介质",
    "inv": [
      "钱晨",
      "林君仪",
      "吴文岩",
      "王权",
      "钱湦钜"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109902723A",
    "apn": "CN201910099484.2",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法及装置",
    "inv": [
      "韩江帆",
      "董潇逸",
      "张瑞茂",
      "罗平",
      "张卫明",
      "俞能海",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109840501A",
    "apn": "CN201910098425.3",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、电子设备、存储介质",
    "inv": [
      "朱坚升",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109886875A",
    "apn": "CN201910098992.9",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像超分辨率重建方法及装置、存储介质",
    "inv": [
      "许翔宇",
      "马咏芮",
      "孙文秀"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109829435A",
    "apn": "CN201910101908.4",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "一种视频图像处理方法、装置及计算机可读介质",
    "inv": [
      "杨佳杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109800821A",
    "apn": "CN201910095785.8",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "训练神经网络的方法、图像处理方法、装置、设备和介质",
    "inv": [
      "彭宝云",
      "金啸"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109816611A",
    "apn": "CN201910099762.4",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "视频修复方法及装置、电子设备和存储介质",
    "inv": [
      "徐瑞",
      "李晓潇",
      "周博磊",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109858614A",
    "apn": "CN201910100328.3",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "神经网络训练方法及装置、电子设备和存储介质",
    "inv": [
      "李家起",
      "于志鹏"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109816039A",
    "apn": "CN201910099972.3",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "一种跨模态信息检索方法、装置和存储介质",
    "inv": [
      "王子豪",
      "刘希慧",
      "邵婧",
      "李鸿升",
      "盛律",
      "闫俊杰",
      "王晓刚"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109886326A",
    "apn": "CN201910109983.5",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "一种跨模态信息检索方法、装置和存储介质",
    "inv": [
      "王子豪",
      "邵婧",
      "李鸿升",
      "闫俊杰",
      "王晓刚",
      "盛律"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109766954A",
    "apn": "CN201910098735.5",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "一种目标对象处理方法、装置、电子设备及存储介质",
    "inv": [
      "韩世欣",
      "郭宇",
      "秦红伟",
      "赵钰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109829501A",
    "apn": "CN201910103611.1",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "庞江淼",
      "陈恺",
      "石建萍",
      "林达华",
      "欧阳万里",
      "冯华君"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109886172A",
    "apn": "CN201910105335.2",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "视频行为识别方法和装置、电子设备、存储介质、产品",
    "inv": [
      "张兆阳",
      "旷章辉",
      "冯俐铜",
      "罗平",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109816764A",
    "apn": "CN201910107428.9",
    "apd": "2019-02-02",
    "apy": "2019",
    "tit": "图像生成方法及装置、电子设备和存储介质",
    "inv": [
      "邱浩楠",
      "顾津锦",
      "任思捷",
      "韩晓光"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109800737A",
    "apn": "CN201910107458.X",
    "apd": "2019-02-02",
    "apy": "2019",
    "tit": "面部识别方法及装置、电子设备和存储介质",
    "inv": [
      "陈郑豪",
      "徐静",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109886891A",
    "apn": "CN201910117782.X",
    "apd": "2019-02-15",
    "apy": "2019",
    "tit": "一种图像复原方法及装置、电子设备、存储介质",
    "inv": [
      "余可",
      "王鑫涛",
      "董超",
      "汤晓鸥",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109886185A",
    "apn": "CN201910122654.4",
    "apd": "2019-02-18",
    "apy": "2019",
    "tit": "一种目标识别方法、装置、电子设备和计算机存储介质",
    "inv": [
      "陈大鹏",
      "赵瑞",
      "李岁缠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109887019A",
    "apn": "CN201910127860.4",
    "apd": "2019-02-19",
    "apy": "2019",
    "tit": "一种双目匹配方法及装置、设备和存储介质",
    "inv": [
      "郭晓阳",
      "杨凯",
      "杨武魁",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109819114A",
    "apn": "CN201910126612.8",
    "apd": "2019-02-20",
    "apy": "2019",
    "tit": "锁屏处理方法及装置、电子设备及存储介质",
    "inv": [
      "许雷",
      "彭彬绪",
      "张帆",
      "于志兴",
      "黄頔"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109815936A",
    "apn": "CN201910130040.0",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "一种目标对象分析方法及装置、计算机设备和存储介质",
    "inv": [
      "颜佺"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109859144A",
    "apn": "CN201910133416.3",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "吴佳飞",
      "洪名达"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109873823A",
    "apn": "CN201910133100.4",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "验证方法及装置、电子设备和存储介质",
    "inv": [
      "陈楷佳",
      "彭彬绪",
      "陆雨柔",
      "袁玮",
      "张义保",
      "黄頔",
      "董亚魁",
      "于志兴"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109816050A",
    "apn": "CN201910134640.4",
    "apd": "2019-02-23",
    "apy": "2019",
    "tit": "物体位姿估计方法及装置",
    "inv": [
      "周韬",
      "成慧"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109829920A",
    "apn": "CN201910138465.6",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "高云河",
      "黄锐"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109919300A",
    "apn": "CN201910138574.8",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "神经网络训练方法及装置以及图像处理方法及装置",
    "inv": [
      "金啸"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109902738A",
    "apn": "CN201910139007.4",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "网络模块和分配方法及装置、电子设备和存储介质",
    "inv": [
      "李艺",
      "旷章辉",
      "陈益民",
      "张伟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109886222A",
    "apn": "CN201910145024.9",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "人脸识别方法、神经网络训练方法、装置及电子设备",
    "inv": [
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109886343A",
    "apn": "CN201910141482.5",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "图像分类方法及装置、设备、存储介质",
    "inv": [
      "柯章翰",
      "严琼"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109903252A",
    "apn": "CN201910147448.9",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "曾进",
      "黄云沐",
      "佟彦锋",
      "严琼",
      "孙文秀"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109886407A",
    "apn": "CN201910146115.4",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "数据处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "王飞",
      "钱晨"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109889800A",
    "apn": "CN201910153438.6",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "图像增强方法和装置、电子设备、存储介质",
    "inv": [
      "钱国成",
      "顾津锦",
      "任思捷",
      "赵富荣",
      "林娟"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109903219A",
    "apn": "CN201910152336.2",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备、计算机可读存储介质",
    "inv": [
      "冯锐成",
      "董超",
      "乔宇"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110399767A",
    "apn": "CN201910152525.X",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "车内人员危险动作识别方法和装置、电子设备、存储介质",
    "inv": [
      "陈彦杰",
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109889693A",
    "apn": "CN201910149405.4",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "视频处理方法及装置、电子设备和存储介质",
    "inv": [
      "杨延生"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109919985A",
    "apn": "CN201910156556.2",
    "apd": "2019-03-01",
    "apy": "2019",
    "tit": "数据处理方法和装置、电子设备和计算机存储介质",
    "inv": [
      "杨武魁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109977792A",
    "apn": "CN201910161561.2",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "人脸特征压缩方法及装置",
    "inv": [
      "徐晓阳",
      "吴佳飞"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109934275A",
    "apn": "CN201910163668.0",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄德亮",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109949385A",
    "apn": "CN201910164658.9",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "一种图像自适应调整方法、设备、电子设备及存储介质",
    "inv": [
      "张黎玮"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109885407A",
    "apn": "CN201910164371.6",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "数据处理方法和装置、电子设备、存储介质",
    "inv": [
      "李秀红",
      "梁云",
      "颜深根",
      "张衡",
      "贾连成"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109949237A",
    "apn": "CN201910169460.X",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "图像处理方法及装置、图像设备及存储介质",
    "inv": [
      "苏柳"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109934766A",
    "apn": "CN201910169503.4",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "苏柳",
      "杨瑞健"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109948494A",
    "apn": "CN201910181078.0",
    "apd": "2019-03-11",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "周官保",
      "张贵明",
      "何秋彤"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109934949A",
    "apn": "CN201910185795.0",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "考勤方法及装置、设备、存储介质",
    "inv": [
      "吴佳飞",
      "丁永超",
      "汪文轩",
      "刘小峰",
      "金古",
      "张广程"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109934240A",
    "apn": "CN201910185331.X",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "特征更新方法及装置、电子设备和存储介质",
    "inv": [
      "马东宇",
      "吴一超",
      "赵瑞",
      "朱烽"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109977815A",
    "apn": "CN201910189151.9",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "图像质量评价方法及装置、电子设备、存储介质",
    "inv": [
      "丁永超",
      "吴佳飞"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN109978891A",
    "apn": "CN201910188856.9",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "孙鹏",
      "林培文",
      "程光亮",
      "石建萍"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109872297A",
    "apn": "CN201910197508.8",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "陆雨柔",
      "陈楷佳",
      "袁玮",
      "彭彬绪",
      "于志兴",
      "张义保",
      "黄頔",
      "董亚魁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN109947510A",
    "apn": "CN201910196544.2",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "一种界面推荐方法及装置、计算机设备",
    "inv": [
      "于志兴",
      "黄頔",
      "董亚魁",
      "陈楷佳",
      "张义保",
      "彭彬绪",
      "陆雨柔",
      "袁玮"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109800744A",
    "apn": "CN201910204426.1",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "图像聚类方法及装置、电子设备和存储介质",
    "inv": [
      "徐静",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN109920016A",
    "apn": "CN201910205219.8",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "图像生成方法及装置、电子设备和存储介质",
    "inv": [
      "刘睿",
      "刘宇",
      "龚新宇",
      "王晓刚",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109934183A",
    "apn": "CN201910205458.3",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "图像处理方法及装置、检测设备及存储介质",
    "inv": [
      "金晟",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109948526A",
    "apn": "CN201910205464.9",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "图像处理方法及装置、检测设备及存储介质",
    "inv": [
      "金晟",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN109978975A",
    "apn": "CN201910203604.9",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "一种动作的迁移方法及装置、计算机设备",
    "inv": [
      "韩蕊",
      "黄展鹏",
      "戴立根",
      "朱袁煊"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109919245A",
    "apn": "CN201910205457.9",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "深度学习模型训练方法及装置、训练设备及存储介质",
    "inv": [
      "金晟",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109934198A",
    "apn": "CN201910220321.5",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "人脸识别方法及装置",
    "inv": [
      "于志鹏"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109977847A",
    "apn": "CN201910222054.5",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "图像生成方法及装置、电子设备和存储介质",
    "inv": [
      "李亦宁",
      "黄琛",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109977860A",
    "apn": "CN201910228716.X",
    "apd": "2019-03-25",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "吴佳飞",
      "梁明亮"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN109977868A",
    "apn": "CN201910233527.1",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "图像渲染方法及装置、电子设备和存储介质",
    "inv": [
      "朱袁煊",
      "黄展鹏",
      "韩蕊",
      "戴立根"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109978759A",
    "apn": "CN201910238417.4",
    "apd": "2019-03-27",
    "apy": "2019",
    "tit": "图像处理方法及装置和图像生成网络的训练方法及装置",
    "inv": [
      "暴天鹏",
      "沈宇军",
      "吴立威",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110189397A",
    "apn": "CN201910251901.0",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、计算机设备和存储介质",
    "inv": [
      "荣禹",
      "刘子纬",
      "李诚",
      "曹凯迪",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109961102A",
    "apn": "CN201910253934.9",
    "apd": "2019-03-30",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "潘新钢",
      "罗平",
      "石建萍",
      "汤晓鸥"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN109978886A",
    "apn": "CN201910258038.1",
    "apd": "2019-04-01",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "夏清",
      "黄宁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110009090A",
    "apn": "CN201910262390.2",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "神经网络训练与图像处理方法及装置",
    "inv": [
      "曹钰杭",
      "陈恺",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110009675A",
    "apn": "CN201910267616.8",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "生成视差图的方法、装置、介质及设备",
    "inv": [
      "杨国润",
      "宋潇",
      "黄潮钦",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN109951740A",
    "apn": "CN201910266203.8",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "视频处理方法及装置、电子设备和存储介质",
    "inv": [
      "王飞",
      "栾青",
      "王磊",
      "孙贺然",
      "贾存迪",
      "李展鹏",
      "贾印琦",
      "李佳宁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110033005A",
    "apn": "CN201910277306.4",
    "apd": "2019-04-08",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄宁",
      "夏清"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110070175A",
    "apn": "CN201910294244.8",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "图像处理方法、模型训练方法及装置、电子设备",
    "inv": [
      "于志鹏",
      "郭秋杉",
      "吴一超",
      "梁鼎"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110060246A",
    "apn": "CN201910301720.4",
    "apd": "2019-04-15",
    "apy": "2019",
    "tit": "一种图像处理方法、设备及存储介质",
    "inv": [
      "黄晓迪",
      "李嘉辉",
      "陈文",
      "熊一能"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110060215A",
    "apn": "CN201910305047.1",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "赵富荣",
      "李乐仁瀚",
      "任思捷",
      "潘金山",
      "高常鑫",
      "桑农"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110060262A",
    "apn": "CN201910315130.7",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "一种图像分割方法及装置、电子设备和存储介质",
    "inv": [
      "赵培泽",
      "刘星龙",
      "黄宁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110070547A",
    "apn": "CN201910314311.8",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "陈文"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110047078A",
    "apn": "CN201910315190.9",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "崔鹤洁",
      "刘星龙",
      "黄宁"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110062164A",
    "apn": "CN201910325282.5",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "视频图像处理方法及装置",
    "inv": [
      "周尚辰",
      "张佳维",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110070046A",
    "apn": "CN201910329770.3",
    "apd": "2019-04-23",
    "apy": "2019",
    "tit": "人脸图像识别方法及装置、电子设备和存储介质",
    "inv": [
      "张潇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110070049A",
    "apn": "CN201910330767.3",
    "apd": "2019-04-23",
    "apy": "2019",
    "tit": "人脸图像识别方法及装置、电子设备和存储介质",
    "inv": [
      "张潇"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110111071A",
    "apn": "CN201910335418.0",
    "apd": "2019-04-24",
    "apy": "2019",
    "tit": "签到方法、装置、电子设备和计算机存储介质",
    "inv": [
      "唐任杰",
      "夏俊",
      "吴军",
      "戴娟"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110059653A",
    "apn": "CN201910335557.3",
    "apd": "2019-04-24",
    "apy": "2019",
    "tit": "一种数据收集方法及装置、电子设备、存储介质",
    "inv": [
      "唐任杰",
      "吴军",
      "夏俊",
      "戴娟"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110263613A",
    "apn": "CN201910340727.7",
    "apd": "2019-04-25",
    "apy": "2019",
    "tit": "监控视频处理方法及装置",
    "inv": [
      "李倩",
      "颜铭佳",
      "王晶",
      "李奕亮",
      "张广程"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110321778A",
    "apn": "CN201910344784.2",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "一种人脸图像处理方法、装置和存储介质",
    "inv": [
      "王飞",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110111289A",
    "apn": "CN201910351289.4",
    "apd": "2019-04-28",
    "apy": "2019",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "吴华栋",
      "张展鹏",
      "成慧",
      "杨凯"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110059666A",
    "apn": "CN201910353333.5",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "一种注意力检测方法及装置",
    "inv": [
      "王飞",
      "黄诗尧",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110136051A",
    "apn": "CN201910361904.X",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "一种图像处理方法、装置和计算机存储介质",
    "inv": [
      "李通",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110322002A",
    "apn": "CN201910363957.5",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "图像生成网络的训练及图像处理方法和装置、电子设备",
    "inv": [
      "张宇",
      "邹冬青",
      "任思捷",
      "姜哲",
      "陈晓濠"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110263619A",
    "apn": "CN201910362402.9",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "图像处理方法和装置、电子设备和计算机存储介质",
    "inv": [
      "仲爽",
      "明洋",
      "马伟",
      "周全",
      "赵瑞",
      "杨延生",
      "朱烽",
      "颜佺"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110111240A",
    "apn": "CN201910359227.8",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "一种基于强结构的图像处理方法、装置和存储介质",
    "inv": [
      "李通",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110070511A",
    "apn": "CN201910361208.9",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "图像处理方法和装置、电子设备及存储介质",
    "inv": [
      "汤晓鸥",
      "王鑫涛",
      "陈焯杰",
      "余可",
      "董超",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110210474A",
    "apn": "CN201910364565.0",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "目标检测方法及装置、设备及存储介质",
    "inv": [
      "苏锐",
      "欧阳万里",
      "周泸萍",
      "徐东"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110084309A",
    "apn": "CN201910364870.X",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "特征图放大方法、装置和设备及计算机可读存储介质",
    "inv": [
      "王佳琦",
      "陈恺",
      "徐瑞",
      "刘子纬",
      "吕健勤",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110060264A",
    "apn": "CN201910359870.0",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "神经网络训练方法、视频帧处理方法、装置及系统",
    "inv": [
      "丁明宇",
      "王哲",
      "周博磊",
      "石建萍"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110097506A",
    "apn": "CN201910377128.2",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "图像处理方法及装置、图像设备及存储介质",
    "inv": [
      "李通",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110111281A",
    "apn": "CN201910381154.2",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "苏柳"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110136052A",
    "apn": "CN201910380522.1",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "一种图像处理方法、装置和电子设备",
    "inv": [
      "李树槐",
      "李通",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110084766A",
    "apn": "CN201910380543.3",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "一种图像处理方法、装置和电子设备",
    "inv": [
      "李树槐",
      "李通",
      "刘文韬",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110119746A",
    "apn": "CN201910381801.X",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "一种特征识别方法及装置、计算机可读存储介质",
    "inv": [
      "陈卓",
      "吴一超"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110084775A",
    "apn": "CN201910385228.X",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "任思捷",
      "王州霞",
      "张佳维"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110110861A",
    "apn": "CN201910384551.5",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "确定模型超参数及模型训练的方法和装置、存储介质",
    "inv": [
      "林宸",
      "李楚鸣"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110163137A",
    "apn": "CN201910394460.X",
    "apd": "2019-05-13",
    "apy": "2019",
    "tit": "一种图像处理方法、装置和存储介质",
    "inv": [
      "付豪",
      "李江涛",
      "杨松",
      "李嘉宾",
      "胡幸杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110136153A",
    "apn": "CN201910400403.8",
    "apd": "2019-05-14",
    "apy": "2019",
    "tit": "一种图像处理方法、设备及存储介质",
    "inv": [
      "黄晓迪",
      "李嘉辉"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110119456A",
    "apn": "CN201910397239.X",
    "apd": "2019-05-14",
    "apy": "2019",
    "tit": "检索图像的方法及装置",
    "inv": [
      "李江涛",
      "钱能胜",
      "陈高岭",
      "欧光文",
      "曾咿人"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110134810A",
    "apn": "CN201910397237.0",
    "apd": "2019-05-14",
    "apy": "2019",
    "tit": "检索图像的方法及装置",
    "inv": [
      "李江涛",
      "钱能胜",
      "陈高岭",
      "欧光文",
      "曾咿人"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110111418A",
    "apn": "CN201910403884.8",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "创建脸部模型的方法、装置及电子设备",
    "inv": [
      "徐胜伟",
      "王权",
      "朴镜潭",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110111417A",
    "apn": "CN201910403882.9",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "三维局部人体模型的生成方法、装置及设备",
    "inv": [
      "陈呈举",
      "袁亚振",
      "盛崇山",
      "章国锋"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110111247A",
    "apn": "CN201910403876.3",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "人脸变形处理方法、装置及设备",
    "inv": [
      "陈呈举",
      "梁兴仑",
      "王韧志",
      "章国锋"
    ],
    "app": [
      "浙江商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110111246A",
    "apn": "CN201910403642.9",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "一种虚拟头像生成方法及装置、存储介质",
    "inv": [
      "刘庭皓",
      "赵立晨",
      "王权",
      "钱晨"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110120092A",
    "apn": "CN201910403291.1",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "一种三维头部数据获取方法、装置和电子设备",
    "inv": [
      "杨瑞健",
      "徐胜伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110175546A",
    "apn": "CN201910404653.9",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄垂碧",
      "王康",
      "陈宇恒",
      "莫涛",
      "金潇"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110120053A",
    "apn": "CN201910403878.2",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "脸部妆容处理方法、装置及设备",
    "inv": [
      "杨瑞健",
      "陶然",
      "许亲亲",
      "袁亚振",
      "梁兴仑"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110148212A",
    "apn": "CN201910412248.1",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "一种动作序列生成方法及装置、电子设备和存储介质",
    "inv": [
      "颜思捷",
      "李治中",
      "熊元骏",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110189309A",
    "apn": "CN201910425459.9",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "张靖阳",
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110210535A",
    "apn": "CN201910426010.4",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "神经网络训练方法及装置以及图像处理方法及装置",
    "inv": [
      "韩江帆",
      "罗平",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110188643A",
    "apn": "CN201910425626.X",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "一种信息显示方法及装置、存储介质",
    "inv": [
      "侯欣如",
      "栾青",
      "石盛传"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110163906A",
    "apn": "CN201910430700.7",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "点云数据处理方法、装置、电子设备及存储介质",
    "inv": [
      "毛佳庚",
      "王晓刚",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110210619A",
    "apn": "CN201910430085.X",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "神经网络的训练方法及装置、电子设备和存储介质",
    "inv": [
      "李润东",
      "王岩",
      "秦红伟"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110189249A",
    "apn": "CN201910441976.5",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "李承翰",
      "刘子纬",
      "吴凌云",
      "罗平"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110175572A",
    "apn": "CN201910452645.1",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "人脸图像处理方法及装置、电子设备及存储介质",
    "inv": [
      "李启铭",
      "王贵杰",
      "周舒岩",
      "梁鼎"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110232407A",
    "apn": "CN201910459415.8",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "图像处理方法和装置、电子设备和计算机存储介质",
    "inv": [
      "赵天翔",
      "邵婧",
      "贾旭",
      "王晓刚",
      "闫俊杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110197258A",
    "apn": "CN201910457280.1",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "神经网络搜索方法、图像处理方法及装置、设备和介质",
    "inv": [
      "次元政",
      "林宸",
      "武伟"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110210487A",
    "apn": "CN201910464355.9",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "一种图像分割方法及装置、电子设备和存储介质",
    "inv": [
      "宋涛",
      "朱洁茹"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110211134A",
    "apn": "CN201910464349.3",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "一种图像分割方法及装置、电子设备和存储介质",
    "inv": [
      "宋涛",
      "朱洁茹"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110031960A",
    "apn": "CN201910462387.5",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "自动调节方法及装置",
    "inv": [
      "牛临潇",
      "李诚"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110175231A",
    "apn": "CN201910468780.5",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "视觉问答方法、装置和设备",
    "inv": [
      "高鹏",
      "李鸿升",
      "王晓刚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110223279A",
    "apn": "CN201910473265.6",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "一种图像处理方法和装置、电子设备",
    "inv": [
      "陈奕志",
      "刘畅",
      "高云河",
      "赵亮"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110210560A",
    "apn": "CN201910472078.6",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "分类网络的增量训练方法、分类方法及装置、设备及介质",
    "inv": [
      "侯赛辉",
      "潘薪宇",
      "林达华",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110188829A",
    "apn": "CN201910472707.5",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "神经网络的训练方法、目标识别的方法及相关产品",
    "inv": [
      "葛艺潇",
      "陈大鹏",
      "沈岩涛",
      "王晓刚",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110210558A",
    "apn": "CN201910471332.0",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "评估神经网络性能的方法及装置",
    "inv": [
      "周东展",
      "周心池",
      "伊帅",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110210386A",
    "apn": "CN201910468450.6",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "用于动作迁移的视频生成方法及神经网络训练方法和装置",
    "inv": [
      "刘睿",
      "刘宇",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110210561A",
    "apn": "CN201910473459.6",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "神经网络的训练方法、目标检测方法及装置、存储介质",
    "inv": [
      "祝新革",
      "庞江淼",
      "杨策元",
      "石建萍",
      "林达华"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110188878A",
    "apn": "CN201910471323.1",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "神经网络搜索方法及装置",
    "inv": [
      "周心池",
      "周东展",
      "伊帅",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110232134A",
    "apn": "CN201910513043.2",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "数据更新方法、服务器及计算机存储介质",
    "inv": [
      "吴佳飞",
      "肖楚荣",
      "汪文轩",
      "金古",
      "刘小峰",
      "张广程"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110233897A",
    "apn": "CN201910517247.3",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "信息推送方法及装置",
    "inv": [
      "时占",
      "周雪琪",
      "王磊",
      "黄唯",
      "苗文健"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110245757A",
    "apn": "CN201910517720.8",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "一种图像样本的处理方法及装置、电子设备和存储介质",
    "inv": [
      "赵愉",
      "张靖阳",
      "宋涛"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110276292A",
    "apn": "CN201910533908.1",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "智能车运动控制方法及装置、设备和存储介质",
    "inv": [
      "张军伟"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110264455A",
    "apn": "CN201910533433.6",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "图像处理、神经网络训练方法及装置、存储介质",
    "inv": [
      "詹晓航",
      "潘新钢",
      "刘子纬",
      "林达华",
      "吕健勤"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110263521A",
    "apn": "CN201910550371.X",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "登入保护方法及装置、系统、电子设备和存储介质",
    "inv": [
      "方志军",
      "陈永春",
      "李均强",
      "马堃"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110263733A",
    "apn": "CN201910552360.5",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "图像处理方法、提名评估方法及相关装置",
    "inv": [
      "苏海昇",
      "王蒙蒙",
      "甘伟豪"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110276308A",
    "apn": "CN201910555717.5",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "图像处理方法及装置",
    "inv": [
      "吴佳飞",
      "徐天"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110287874A",
    "apn": "CN201910555741.9",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "目标追踪方法及装置、电子设备和存储介质",
    "inv": [
      "战赓",
      "庄博涵",
      "孙书洋",
      "欧阳万里"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110298298A",
    "apn": "CN201910563005.8",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "目标检测及目标检测网络的训练方法、装置及设备",
    "inv": [
      "李聪"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110287900A",
    "apn": "CN201910568579.4",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "验证方法和验证装置",
    "inv": [
      "梁鼎",
      "吴立威",
      "李启铭",
      "谢文彪",
      "高航",
      "陈婷婷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110287672A",
    "apn": "CN201910569133.3",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "验证方法及装置、电子设备和存储介质",
    "inv": [
      "梁鼎",
      "吴立威",
      "陈楠",
      "刘春秋",
      "李启铭"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110287671A",
    "apn": "CN201910568720.0",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "验证方法及装置、电子设备和存储介质",
    "inv": [
      "梁鼎",
      "吴立威",
      "王洒",
      "封红霞",
      "曲艺"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110310242A",
    "apn": "CN201910570013.5",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "一种图像去模糊方法及装置、存储介质",
    "inv": [
      "王道烨",
      "张佳维",
      "潘金山",
      "周尚辰",
      "赵富荣",
      "严琼",
      "任思捷"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110298310A",
    "apn": "CN201910575840.3",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "刘毅",
      "蒋文忠",
      "赵宏斌"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN110390031A",
    "apn": "CN201910578776.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "信息处理方法及装置、图像设备及存储介质",
    "inv": [
      "李晓通",
      "杨松",
      "陈高岭",
      "赵秦",
      "张寅艳"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110348347A",
    "apn": "CN201910580576.2",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "一种信息处理方法及装置、存储介质",
    "inv": [
      "阎旭阳",
      "干刚",
      "张恩龙",
      "李冠亮",
      "曾咿人"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110334231A",
    "apn": "CN201910577496.1",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "一种信息处理方法及装置、存储介质",
    "inv": [
      "李晓通",
      "曾咿人",
      "闫旭阳",
      "曾晓玲",
      "郭彤"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110321834A",
    "apn": "CN201910580492.9",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "一种身份确定方法及装置、存储介质",
    "inv": [
      "杨松",
      "李蔚琳",
      "周官保",
      "谭志颖",
      "王建军"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110276804A",
    "apn": "CN201910582544.6",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "数据处理方法及装置",
    "inv": [
      "周韬",
      "于行尧",
      "董至恺",
      "刘思成"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110335389A",
    "apn": "CN201910586845.6",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "车门解锁方法及装置、系统、车、电子设备和存储介质",
    "inv": [
      "李轲",
      "林琪钧"
    ],
    "app": [
      "上海商汤临港智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110363297A",
    "apn": "CN201910606138.9",
    "apd": "2019-07-05",
    "apy": "2019",
    "tit": "神经网络训练及图像处理方法、装置、设备和介质",
    "inv": [
      "俞海宝",
      "温拓朴",
      "孙建凯"
    ],
    "app": [
      "上海商汤临港智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110348572A",
    "apn": "CN201910616195.5",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "神经网络模型的处理方法及装置、电子设备、存储介质",
    "inv": [
      "张军伟",
      "李诚",
      "梁逸清"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110348524A",
    "apn": "CN201910635763.6",
    "apd": "2019-07-15",
    "apy": "2019",
    "tit": "一种人体关键点检测方法及装置、电子设备和存储介质",
    "inv": [
      "刘文韬",
      "郭玉京",
      "王勇望",
      "钱晨",
      "李佳桦"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110349655A",
    "apn": "CN201910636127.5",
    "apd": "2019-07-15",
    "apy": "2019",
    "tit": "一种图像调整方法及装置、电子设备和存储介质",
    "inv": [
      "张黎玮"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110363150A",
    "apn": "CN201910642110.0",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "数据更新方法及装置、电子设备和存储介质",
    "inv": [
      "赵宏斌",
      "蒋文忠",
      "刘毅"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110264398A",
    "apn": "CN201910641159.4",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "图像处理方法及装置",
    "inv": [
      "沈宇军",
      "顾津锦",
      "周博磊"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110378854A",
    "apn": "CN201910645990.7",
    "apd": "2019-07-17",
    "apy": "2019",
    "tit": "机器人图像增强方法及装置",
    "inv": [
      "牛临潇",
      "李诚"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110348418A",
    "apn": "CN201910646696.8",
    "apd": "2019-07-17",
    "apy": "2019",
    "tit": "目标跟踪方法及装置、智能移动设备和存储介质",
    "inv": [
      "张军伟"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110348537A",
    "apn": "CN201910652025.2",
    "apd": "2019-07-18",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "杨昆霖",
      "侯军",
      "蔡晓聪",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110378976A",
    "apn": "CN201910652028.6",
    "apd": "2019-07-18",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "杨昆霖",
      "颜鲲",
      "侯军",
      "蔡晓聪",
      "伊帅"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110414593A",
    "apn": "CN201910673283.9",
    "apd": "2019-07-24",
    "apy": "2019",
    "tit": "图像处理方法及装置、处理器、电子设备及存储介质",
    "inv": [
      "刘希慧",
      "殷国君",
      "邵婧",
      "王晓刚",
      "李鸿升"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110378312A",
    "apn": "CN201910681695.7",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "吴佳飞",
      "徐天",
      "刘小峰",
      "张广程"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN110427998A",
    "apn": "CN201910684497.6",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "模型训练、目标检测方法及装置、电子设备、存储介质",
    "inv": [
      "张军伟",
      "李诚",
      "梁逸清"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110458771A",
    "apn": "CN201910688775.5",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "李传俊",
      "严琼",
      "王甜甜",
      "王昊然"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110490878A",
    "apn": "CN201910690342.3",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "项磊",
      "吴宇",
      "赵亮",
      "高云河"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110415258A",
    "apn": "CN201910691280.8",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "毛翔宇",
      "严琼",
      "郑浩基"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "商汤",
    "pun": "CN110428362A",
    "apn": "CN201910688084.5",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "图像HDR转换方法及装置、存储介质",
    "inv": [
      "王甜甜",
      "严琼",
      "李传俊"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110443366A",
    "apn": "CN201910696271.8",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "神经网络的优化方法及装置、目标检测方法及装置",
    "inv": [
      "蔺琛皓",
      "许东奇",
      "卢宇",
      "王思雯",
      "张伟"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "商汤",
    "pun": "CN110458062A",
    "apn": "CN201910695535.8",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "人脸识别方法及装置、电子设备和存储介质",
    "inv": [
      "胡斯婷",
      "蒋文忠",
      "赵宏斌",
      "陈晨",
      "李均强"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110399849A",
    "apn": "CN201910694065.3",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "图像处理方法及装置、处理器、电子设备及存储介质",
    "inv": [
      "何悦",
      "张韵璇",
      "张四维",
      "李诚"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110442742A",
    "apn": "CN201910704558.0",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "检索图像的方法及装置、处理器、电子设备及存储介质",
    "inv": [
      "黄潇莹",
      "李蔚琳",
      "杨松",
      "李晓通",
      "付豪"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110442237A",
    "apn": "CN201910701501.5",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "表情模型生成方法及相关产品",
    "inv": [
      "朱袁煊",
      "黄展鹏",
      "韩蕊",
      "戴立根"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "商汤",
    "pun": "CN110399856A",
    "apn": "CN201910703071.0",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "特征提取网络训练方法、图像处理方法、装置及其设备",
    "inv": [
      "苏鹏",
      "王坤",
      "曾星宇"
    ],
    "app": [
      "上海商汤临港智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110414611A",
    "apn": "CN201910702994.4",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "图像分类方法及装置、特征提取网络训练方法及装置",
    "inv": [
      "邓晗",
      "张学森",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110458218A",
    "apn": "CN201910702266.3",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "图像分类方法及装置、分类网络训练方法及装置",
    "inv": [
      "邓晗",
      "张学森",
      "伊帅",
      "闫俊杰"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110473259A",
    "apn": "CN201910701860.0",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "位姿确定方法及装置、电子设备和存储介质",
    "inv": [
      "朱铖恺",
      "冯岩",
      "武伟",
      "闫俊杰",
      "林思睿"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110458226A",
    "apn": "CN201910730771.9",
    "apd": "2019-08-08",
    "apy": "2019",
    "tit": "图像标注方法及装置、电子设备和存储介质",
    "inv": [
      "陈亦鹏",
      "李诚"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110472681A",
    "apn": "CN201910735490.2",
    "apd": "2019-08-09",
    "apy": "2019",
    "tit": "基于知识蒸馏的神经网络训练方案和图像处理方案",
    "inv": [
      "彭宝云"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110418112A",
    "apn": "CN201910736109.4",
    "apd": "2019-08-09",
    "apy": "2019",
    "tit": "一种视频处理方法及装置、电子设备和存储介质",
    "inv": [
      "刘建成",
      "由光鑫",
      "辛彦哲",
      "屈秋竹"
    ],
    "app": [
      "上海商汤智能科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "商汤",
    "pun": "CN110458102A",
    "apn": "CN201910739381.8",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "一种人脸图像识别方法及装置、电子设备和存储介质",
    "inv": [
      "黄德亮",
      "朱烽",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110414630A",
    "apn": "CN201910741656.1",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "神经网络的训练方法、卷积计算的加速方法、装置及设备",
    "inv": [
      "龚睿昊",
      "余锋伟",
      "姜圣虎",
      "胡鹏",
      "刘祥龙"
    ],
    "app": [
      "上海商汤临港智能科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110458237A",
    "apn": "CN201910749750.1",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "语义识别方法和装置、电子设备及存储介质",
    "inv": [
      "杨策元",
      "沈宇军",
      "周博磊"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110443655A",
    "apn": "CN201910750560.1",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "信息处理方法、装置及设备",
    "inv": [
      "张明阳",
      "高雨亭"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110471963A",
    "apn": "CN201910751054.4",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "数据处理方法、装置及存储介质",
    "inv": [
      "陈琛",
      "高雨亭"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110458922A",
    "apn": "CN201910763285.7",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "图形渲染方法及相关产品",
    "inv": [
      "叶伟",
      "符修源",
      "李宇飞",
      "王子彬",
      "王贵杰"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "商汤",
    "pun": "CN110473016A",
    "apn": "CN201910750535.3",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "数据处理方法、装置及存储介质",
    "inv": [
      "丁文豪",
      "陈琛"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110502651A",
    "apn": "CN201910755628.5",
    "apd": "2019-08-15",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "黄垂碧",
      "莫涛",
      "杨川",
      "秦晨翀",
      "陈宇恒"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110503023A",
    "apn": "CN201910763887.2",
    "apd": "2019-08-19",
    "apy": "2019",
    "tit": "活体检测方法及装置、电子设备和存储介质",
    "inv": [
      "赵宏斌",
      "蒋文忠",
      "刘毅",
      "胡斯婷",
      "李均强"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110491135A",
    "apn": "CN201910768655.6",
    "apd": "2019-08-20",
    "apy": "2019",
    "tit": "检测违章停车的方法及相关装置",
    "inv": [
      "辛彦哲",
      "林国泽"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "商汤",
    "pun": "CN110472091A",
    "apn": "CN201910779555.3",
    "apd": "2019-08-22",
    "apy": "2019",
    "tit": "图像处理方法及装置、电子设备和存储介质",
    "inv": [
      "窦浩轩",
      "徐静",
      "李冠亮",
      "杨禹飞",
      "李敏妙"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110502653A",
    "apn": "CN201910785719.3",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "快递查询方法及服务器、终端",
    "inv": [
      "王艺然",
      "陈楷佳",
      "李刚",
      "李延存",
      "张义保"
    ],
    "app": [
      "北京市商汤科技开发有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "商汤",
    "pun": "CN110502659A",
    "apn": "CN201910782629.9",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "图像特征提取及网络的训练方法、装置和设备",
    "inv": [
      "李岁缠",
      "陈大鹏",
      "赵瑞"
    ],
    "app": [
      "深圳市商汤科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110418064A",
    "apn": "CN201910829143.6",
    "apd": "2019-09-03",
    "apy": "2019",
    "tit": "对焦方法、装置、电子设备及存储介质",
    "inv": [
      "张兴华",
      "蔚琛",
      "林倩霞"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110532981A",
    "apn": "CN201910828826.X",
    "apd": "2019-09-03",
    "apy": "2019",
    "tit": "人体关键点提取方法、装置、可读存储介质及设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110555798A",
    "apn": "CN201910792907.9",
    "apd": "2019-08-26",
    "apy": "2019",
    "tit": "图像变形方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "诸葛晶晶",
      "倪光耀",
      "张树鹏",
      "杨辉"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110475065A",
    "apn": "CN201910763721.0",
    "apd": "2019-08-19",
    "apy": "2019",
    "tit": "图像处理的方法、装置、电子设备及存储介质",
    "inv": [
      "张兴华",
      "王学智",
      "黎文学"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110458890A",
    "apn": "CN201910755278.2",
    "apd": "2019-08-15",
    "apy": "2019",
    "tit": "控制方法、装置、电子设备及存储介质",
    "inv": [
      "朱海舟",
      "方迟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110472074A",
    "apn": "CN201910755265.5",
    "apd": "2019-08-15",
    "apy": "2019",
    "tit": "多媒体文件的处理方法、装置、终端及存储介质",
    "inv": [
      "方迟",
      "朱海舟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110428468A",
    "apn": "CN201910740628.8",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "一种穿戴式显示设备的位置坐标生成系统以及方法",
    "inv": [
      "马苏",
      "方迟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110456957A",
    "apn": "CN201910735810.4",
    "apd": "2019-08-09",
    "apy": "2019",
    "tit": "显示交互方法、装置、设备、存储介质",
    "inv": [
      "马苏",
      "方迟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110555861A",
    "apn": "CN201910731885.5",
    "apd": "2019-08-09",
    "apy": "2019",
    "tit": "光流计算方法、装置及电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110555892A",
    "apn": "CN201910731909.7",
    "apd": "2019-08-09",
    "apy": "2019",
    "tit": "多角度图像生成方法、装置及电子设备",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110300325A",
    "apn": "CN201910723197.4",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "视频的处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "陈扬羽",
      "何轶",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110321454A",
    "apn": "CN201910722649.7",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "视频的处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "陈扬羽",
      "何轶",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110365973A",
    "apn": "CN201910722642.5",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "视频的检测方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "李根",
      "文林福",
      "卢永晨",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110413812A",
    "apn": "CN201910722628.5",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "神经网络模型的训练方法、装置、电子设备及存储介质",
    "inv": [
      "李根",
      "何轶",
      "李磊",
      "杨明敏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110458224A",
    "apn": "CN201910723195.5",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及计算机可读介质",
    "inv": [
      "陈扬羽",
      "何轶",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110418134A",
    "apn": "CN201910709133.9",
    "apd": "2019-08-01",
    "apy": "2019",
    "tit": "基于视频质量的视频编码方法、装置及电子设备",
    "inv": [
      "王诗淇",
      "李洋",
      "王悦"
    ],
    "app": [
      "字节跳动(香港)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110399933A",
    "apn": "CN201910704540.0",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "数据标注修正方法、装置、计算机可读介质及电子设备",
    "inv": [
      "李佩易",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110414514A",
    "apn": "CN201910702791.5",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "图像处理方法及装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378936A",
    "apn": "CN201910692802.6",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "光流计算方法、装置及电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110399848A",
    "apn": "CN201910692667.5",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "视频封面生成方法、装置及电子设备",
    "inv": [
      "黄凯",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110399847A",
    "apn": "CN201910692649.7",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "关键帧提取方法、装置及电子设备",
    "inv": [
      "高永强"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110415276A",
    "apn": "CN201910692666.0",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "运动信息计算方法、装置及电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110489693A",
    "apn": "CN201910666959.1",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "一种绘制三维图形的方法、装置、介质和电子设备",
    "inv": [
      "王明晓"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110390291A",
    "apn": "CN201910647749.8",
    "apd": "2019-07-18",
    "apy": "2019",
    "tit": "数据处理方法、装置及电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378410A",
    "apn": "CN201910640330.X",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "多标签场景分类方法、装置及电子设备",
    "inv": [
      "高永强"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110363136A",
    "apn": "CN201910630374.4",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "用于识别眼睛设定特征的方法、装置、电子设备、及介质",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378406A",
    "apn": "CN201910627791.3",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "图像情感分析方法、装置及电子设备",
    "inv": [
      "高永强"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110337027A",
    "apn": "CN201910622570.7",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "视频生成方法、装置及电子设备",
    "inv": [
      "高永强"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110363753A",
    "apn": "CN201910622579.8",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "图像质量评估方法、装置及电子设备",
    "inv": [
      "高永强"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110381391A",
    "apn": "CN201910622592.3",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "视频快速切片方法、装置及电子设备",
    "inv": [
      "高永强"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110381368A",
    "apn": "CN201910622565.6",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "视频封面生成方法、装置及电子设备",
    "inv": [
      "黄凯",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110324533A",
    "apn": "CN201910620706.0",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "处理图像的方法、装置、电子设备、及存储介质",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110349161A",
    "apn": "CN201910621621.4",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "图像分割方法、装置、电子设备、及存储介质",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110349108A",
    "apn": "CN201910623474.4",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "处理图像的方法、装置、电子设备、及存储介质",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110349107A",
    "apn": "CN201910620716.4",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "图像增强的方法、装置、电子设备、及存储介质",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110321863A",
    "apn": "CN201910615118.8",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "年龄识别方法及装置、存储介质",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110321865A",
    "apn": "CN201910615641.0",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "头部特效处理方法及装置、存储介质",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110334663A",
    "apn": "CN201910615158.2",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "基于图像的年龄识别方法及装置、存储介质与终端",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110335195A",
    "apn": "CN201910615246.2",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "车身颜色调整方法、装置、电子设备及存储介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110348440A",
    "apn": "CN201910614736.0",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "牌照检测方法、装置、电子设备及存储介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110348374A",
    "apn": "CN201910615250.9",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "车辆检测方法、装置、电子设备及存储介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110363132A",
    "apn": "CN201910615248.1",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "活体检测方法、装置、电子设备及存储介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110225400A",
    "apn": "CN201910611391.3",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "一种动作捕捉方法、装置、移动终端及存储介质",
    "inv": [
      "王光伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298413A",
    "apn": "CN201910611747.3",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "图像特征提取方法、装置、存储介质及电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110321958A",
    "apn": "CN201910611482.7",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "神经网络模型的训练方法、视频相似度确定方法",
    "inv": [
      "王鑫宇",
      "张永华"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110321858A",
    "apn": "CN201910611477.6",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "视频相似度确定方法、装置、电子设备及存储介质",
    "inv": [
      "王鑫宇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110321447A",
    "apn": "CN201910612100.2",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "重复图像的确定方法、装置、电子设备及存储介质",
    "inv": [
      "王鑫宇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110348367A",
    "apn": "CN201910611394.7",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "视频分类方法、视频处理方法、装置、移动终端及介质",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110348369A",
    "apn": "CN201910612133.7",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "一种视频场景分类方法、装置、移动终端及存储介质",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110347875A",
    "apn": "CN201910612129.0",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "一种视频场景分类方法、装置、移动终端及存储介质",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110362698A",
    "apn": "CN201910612131.8",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "一种图片信息生成方法、装置、移动终端及存储介质",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288625A",
    "apn": "CN201910598165.6",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298859A",
    "apn": "CN201910598163.7",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298851A",
    "apn": "CN201910600394.7",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "人体分割神经网络的训练方法及设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110310224A",
    "apn": "CN201910601008.6",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "光效渲染方法及装置",
    "inv": [
      "王光伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110310293A",
    "apn": "CN201910601007.1",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "人体图像分割方法及设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110334650A",
    "apn": "CN201910599369.1",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "物体检测方法、装置、电子设备及存储介质",
    "inv": [
      "淮静"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110334763A",
    "apn": "CN201910599358.3",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "模型数据文件生成、图像识别方法、装置、设备及介质",
    "inv": [
      "淮静"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110335334A",
    "apn": "CN201910600334.5",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "虚拟头像驱动显示方法、装置、电子设备及存储介质",
    "inv": [
      "淮静"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298327A",
    "apn": "CN201910594262.8",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "一种视觉特效处理方法及装置、存储介质与终端",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298405A",
    "apn": "CN201910594260.9",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "类别识别方法及装置、存储介质与终端",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298329A",
    "apn": "CN201910594664.8",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "表情程度预测模型获取方法及装置、存储介质与终端",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298326A",
    "apn": "CN201910594257.7",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "一种图像处理方法及装置、存储介质与终端",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110310318A",
    "apn": "CN201910594665.2",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "一种特效处理方法及装置、存储介质与终端",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110310299A",
    "apn": "CN201910592747.3",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "用于训练光流网络、以及处理图像的方法和装置",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110310251A",
    "apn": "CN201910593850.X",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288049A",
    "apn": "CN201910590266.9",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "用于生成图像识别模型的方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288523A",
    "apn": "CN201910588691.4",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "图像生成方法和装置",
    "inv": [
      "刘志超"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287161A",
    "apn": "CN201910590272.4",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287936A",
    "apn": "CN201910589714.3",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "图像检测方法、装置、设备及存储介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288705A",
    "apn": "CN201910588856.8",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "生成三维模型的方法和装置",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298786A",
    "apn": "CN201910589060.4",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "图像渲染方法、装置、设备及存储介质",
    "inv": [
      "范旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110363860A",
    "apn": "CN201910600113.8",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "3D模型重建方法、装置及电子设备",
    "inv": [
      "李佩易",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378948A",
    "apn": "CN201910591668.0",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "3D模型重建方法、装置及电子设备",
    "inv": [
      "李佩易",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378947A",
    "apn": "CN201910591285.3",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "3D模型重建方法、装置及电子设备",
    "inv": [
      "李佩易",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110390717A",
    "apn": "CN201910591658.7",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "3D模型重建方法、装置及电子设备",
    "inv": [
      "李佩易",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288532A",
    "apn": "CN201910585873.6",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "生成全身图像的方法、装置、设备及计算机可读存储介质",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298319A",
    "apn": "CN201910585567.2",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "图像合成方法和装置",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110264430A",
    "apn": "CN201910580699.6",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "视频美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110264431A",
    "apn": "CN201910580715.1",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "视频美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288552A",
    "apn": "CN201910580711.3",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "视频美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288553A",
    "apn": "CN201910580722.1",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "图像美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288519A",
    "apn": "CN201910580706.2",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "图像美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288520A",
    "apn": "CN201910580719.X",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "图像美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288521A",
    "apn": "CN201910580726.X",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "图像美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288551A",
    "apn": "CN201910580698.1",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "视频美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287350A",
    "apn": "CN201910580710.9",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "图像检索方法、装置及电子设备",
    "inv": [
      "周恺卉",
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288554A",
    "apn": "CN201910580723.6",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "视频美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110298785A",
    "apn": "CN201910580721.7",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "图像美化方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110211033A",
    "apn": "CN201910582940.9",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "人脸图像处理方法、装置、介质及电子设备",
    "inv": [
      "胡惠平"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288036A",
    "apn": "CN201910579554.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图像修复方法、装置及电子设备",
    "inv": [
      "周恺卉",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110286899A",
    "apn": "CN201910577177.0",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "应用程序显示界面的编辑方法及装置、存储介质",
    "inv": [
      "龙伟炜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288549A",
    "apn": "CN201910577436.X",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "视频修复方法、装置及电子设备",
    "inv": [
      "周恺卉",
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288037A",
    "apn": "CN201910579561.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "周恺卉",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110363717A",
    "apn": "CN201910582958.9",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "一种处理面部图像的方法、装置、介质和电子设备",
    "inv": [
      "黄承龙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110363697A",
    "apn": "CN201910571098.9",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "一种图像水印隐写方法、装置、介质和电子设备",
    "inv": [
      "李阳"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110363718A",
    "apn": "CN201910583758.5",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "人脸图像处理方法、装置、介质及电子设备",
    "inv": [
      "胡惠平"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378847A",
    "apn": "CN201910583757.0",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "人脸图像处理方法、装置、介质及电子设备",
    "inv": [
      "胡惠平"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378839A",
    "apn": "CN201910583756.6",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "人脸图像处理方法、装置、介质及电子设备",
    "inv": [
      "胡惠平"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110378846A",
    "apn": "CN201910582956.X",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "一种处理图像磨皮的方法、装置、介质和电子设备",
    "inv": [
      "黄承龙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110399802A",
    "apn": "CN201910582957.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "处理面部图像眼睛亮度的方法、装置、介质和电子设备",
    "inv": [
      "黄承龙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288547A",
    "apn": "CN201910569195.4",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "用于生成图像去噪模型的方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110267097A",
    "apn": "CN201910562955.9",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "基于分类特征的视频推送方法、装置及电子设备",
    "inv": [
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263742A",
    "apn": "CN201910558688.8",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "用于识别图像的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110264499A",
    "apn": "CN201910563225.0",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "基于人体关键点的交互位置控制方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263743A",
    "apn": "CN201910558852.5",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "用于识别图像的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110278447A",
    "apn": "CN201910563779.0",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "基于连续特征的视频推送方法、装置及电子设备",
    "inv": [
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287371A",
    "apn": "CN201910562971.8",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "端到端的视频推送方法、装置及电子设备",
    "inv": [
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287891A",
    "apn": "CN201910563241.X",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "基于人体关键点的手势控制方法、装置及电子设备",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110300329A",
    "apn": "CN201910563792.6",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "基于离散特征的视频推送方法、装置及电子设备",
    "inv": [
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110234005A",
    "apn": "CN201910547614.4",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "一种视频质量评测的方法、装置、介质和电子设备",
    "inv": [
      "张杨"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110245645A",
    "apn": "CN201910546302.1",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "人脸活体识别方法、装置、设备及存储介质",
    "inv": [
      "王旭",
      "陈胜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263255A",
    "apn": "CN201910544111.1",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "用户属性信息的获取方法、系统、服务器及存储介质",
    "inv": [
      "李昂"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263721A",
    "apn": "CN201910543805.3",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "车灯设置方法及设备",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263862A",
    "apn": "CN201910545012.5",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "信息的推送方法、装置、电子设备及可读存储介质",
    "inv": [
      "谢剑敏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263797A",
    "apn": "CN201910543687.6",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "骨架的关键点估计方法、装置、设备及可读存储介质",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263852A",
    "apn": "CN201910534557.6",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "数据处理方法、装置及电子设备",
    "inv": [
      "周鸣",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110223325A",
    "apn": "CN201910524644.3",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "对象跟踪方法、装置及设备",
    "inv": [
      "陈超"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110377192A",
    "apn": "CN201910527297.X",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "一种实现互动效果的方法、装置、介质和电子设备",
    "inv": [
      "俞亮"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110263918A",
    "apn": "CN201910524594.9",
    "apd": "2019-06-17",
    "apy": "2019",
    "tit": "训练卷积神经网络的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "郭亨凯"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110213573A",
    "apn": "CN201910517059.0",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "一种视频质量评估方法、装置和电子设备",
    "inv": [
      "廖懿婷",
      "孟胜彬",
      "李军林",
      "王悦"
    ],
    "app": [
      "北京字节跳动网络技术有限公司",
      "字节跳动有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110222829A",
    "apn": "CN201910507522.3",
    "apd": "2019-06-12",
    "apy": "2019",
    "tit": "基于卷积神经网络的特征提取方法、装置、设备及介质",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188782A",
    "apn": "CN201910503145.6",
    "apd": "2019-06-11",
    "apy": "2019",
    "tit": "图像相似性确定方法、装置、电子设备及可读存储介质",
    "inv": [
      "王鑫宇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110210501A",
    "apn": "CN201910503234.0",
    "apd": "2019-06-11",
    "apy": "2019",
    "tit": "虚拟对象生成方法、电子设备及计算机可读存储介质",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110222777A",
    "apn": "CN201910502375.0",
    "apd": "2019-06-11",
    "apy": "2019",
    "tit": "图像特征的处理方法、装置、电子设备及存储介质",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110189252A",
    "apn": "CN201910496745.4",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "生成平均脸图像的方法和装置",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110189279A",
    "apn": "CN201910498003.5",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "模型训练方法、装置、电子设备及存储介质",
    "inv": [
      "何轶",
      "李根",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110198473A",
    "apn": "CN201910498004.X",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "视频处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "何轶",
      "李根",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110209851A",
    "apn": "CN201910498011.X",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "模型训练方法、装置、电子设备及存储介质",
    "inv": [
      "何轶",
      "李根",
      "李磊",
      "周浩"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110222775A",
    "apn": "CN201910498629.6",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "何轶",
      "李根",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110084835A",
    "apn": "CN201910493426.8",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "用于处理视频的方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110191356A",
    "apn": "CN201910493540.0",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "视频审核方法、装置和电子设备",
    "inv": [
      "朱延东",
      "徐珍琦",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110276346A",
    "apn": "CN201910492786.6",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "目标区域识别模型训练方法、装置和计算机可读存储介质",
    "inv": [
      "卢永晨"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288691A",
    "apn": "CN201910495927.X",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "渲染图像的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "郭亨凯"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110209591A",
    "apn": "CN201910488162.7",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "图片查找方法、装置、电子设备及存储介质",
    "inv": [
      "陈斯"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "故障检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110276345A",
    "apn": "CN201910485046.X",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "卷积神经网络模型训练方法、装置和计算机可读存储介质",
    "inv": [
      "朱延东",
      "周恺卉",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287954A",
    "apn": "CN201910485054.4",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "目标区域确定模型的训练方法、装置和计算机可读存储介质",
    "inv": [
      "朱延东",
      "周恺卉",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287955A",
    "apn": "CN201910485063.3",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "目标区域确定模型训练方法、装置和计算机可读存储介质",
    "inv": [
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287950A",
    "apn": "CN201910485419.3",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "目标检测及目标检测模型的训练方法、装置和电子设备",
    "inv": [
      "卢永晨"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287817A",
    "apn": "CN201910485154.7",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "目标识别及目标识别模型的训练方法、装置和电子设备",
    "inv": [
      "朱延东",
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110288082A",
    "apn": "CN201910485052.5",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "卷积神经网络模型训练方法、装置和计算机可读存储介质",
    "inv": [
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287816A",
    "apn": "CN201910485040.2",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "车门动作检测方法、装置和计算机可读存储介质",
    "inv": [
      "朱延东",
      "白戈",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188833A",
    "apn": "CN201910480683.8",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "用于训练模型的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188719A",
    "apn": "CN201910480684.2",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "目标跟踪方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110189364A",
    "apn": "CN201910480692.7",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置，以及目标跟踪方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110210406A",
    "apn": "CN201910481001.5",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "用于拍摄图像的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110211030A",
    "apn": "CN201910482775.X",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "图像生成方法和装置",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110209849A",
    "apn": "CN201910482007.4",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "用于标注关键点的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110276355A",
    "apn": "CN201910480429.8",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "训练分类器的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "卢永晨"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110287810A",
    "apn": "CN201910479309.6",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "车门动作检测方法、装置和计算机可读存储介质",
    "inv": [
      "朱延东",
      "白戈",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188711A",
    "apn": "CN201910477387.2",
    "apd": "2019-06-03",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188712A",
    "apn": "CN201910477407.6",
    "apd": "2019-06-03",
    "apy": "2019",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188713A",
    "apn": "CN201910478276.3",
    "apd": "2019-06-03",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110197230A",
    "apn": "CN201910477386.8",
    "apd": "2019-06-03",
    "apy": "2019",
    "tit": "用于训练模型的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110221822A",
    "apn": "CN201910459429.X",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "特效的合并方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "孙俊涛"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110189274A",
    "apn": "CN201910451446.9",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "图像处理方法、装置和计算机可读存储介质",
    "inv": [
      "郑屹",
      "王璨"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110210553A",
    "apn": "CN201910453997.9",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "训练分类器的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110162670A",
    "apn": "CN201910447997.8",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "用于生成表情包的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110163171A",
    "apn": "CN201910447301.1",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "用于识别人脸属性的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110163170A",
    "apn": "CN201910447129.X",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "用于识别年龄的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110175565A",
    "apn": "CN201910448013.8",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "用于识别人物情感的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188660A",
    "apn": "CN201910448035.4",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "用于识别年龄的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110188652A",
    "apn": "CN201910441670.X",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "人脸图像的处理方法、装置、终端及存储介质",
    "inv": [
      "郑微",
      "刘昂"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110211195A",
    "apn": "CN201910428671.0",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "生成图像集合的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110209861A",
    "apn": "CN201910425089.9",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "李昊哲",
      "刘舒",
      "刘高"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110134805A",
    "apn": "CN201910419869.2",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "图片类评论数据的网络存储方法、装置、电子设备及介质",
    "inv": [
      "王岳晨"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "图像管理",
    "l2": "存储",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110222736A",
    "apn": "CN201910421813.0",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "训练分类器的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "卢永晨"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110110695A",
    "apn": "CN201910412448.7",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110110811A",
    "apn": "CN201910414089.9",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "用于训练模型的方法和装置、用于预测信息的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110110693A",
    "apn": "CN201910411344.4",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "用于识别人脸属性的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110119457A",
    "apn": "CN201910412440.0",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110119722A",
    "apn": "CN201910414613.2",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110136054A",
    "apn": "CN201910409754.5",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110189246A",
    "apn": "CN201910403860.2",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "图像风格化生成方法、装置及电子设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110197459A",
    "apn": "CN201910403850.9",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "图像风格化生成方法、装置及电子设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110211017A",
    "apn": "CN201910408167.4",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110210532A",
    "apn": "CN201910408169.3",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "背景色生成方法、装置及电子设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110222726A",
    "apn": "CN201910403859.X",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110222590A",
    "apn": "CN201910408172.5",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "图像差异判断方法、装置及电子设备",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110115839A",
    "apn": "CN201910398724.9",
    "apd": "2019-05-14",
    "apy": "2019",
    "tit": "用于生成图片的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110189394A",
    "apn": "CN201910396187.4",
    "apd": "2019-05-14",
    "apy": "2019",
    "tit": "口型生成方法、装置及电子设备",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070076A",
    "apn": "CN201910379575.1",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "用于选取训练用样本的方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110110666A",
    "apn": "CN201910379637.9",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "目标检测方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110222576A",
    "apn": "CN201910376799.7",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "拳击动作识别方法、装置和电子设备",
    "inv": [
      "陈超",
      "李琰"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110084317A",
    "apn": "CN201910372306.2",
    "apd": "2019-05-06",
    "apy": "2019",
    "tit": "用于识别图像的方法和装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110335237A",
    "apn": "CN201910371718.4",
    "apd": "2019-05-06",
    "apy": "2019",
    "tit": "用于生成模型的方法、装置和用于识别图像的方法、装置",
    "inv": [
      "陈奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110084306A",
    "apn": "CN201910360609.2",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "用于生成动态图像的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110097004A",
    "apn": "CN201910360022.1",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "面部表情识别方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110111241A",
    "apn": "CN201910360024.0",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "用于生成动态图像的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070551A",
    "apn": "CN201910357135.6",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "视频图像的渲染方法、装置和电子设备",
    "inv": [
      "罗国中"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070063A",
    "apn": "CN201910353500.6",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "目标对象的动作识别方法、装置和电子设备",
    "inv": [
      "罗国中"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110084204A",
    "apn": "CN201910357692.8",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "基于目标对象姿态的图像处理方法、装置和电子设备",
    "inv": [
      "罗国中",
      "郦橙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110047126A",
    "apn": "CN201910341736.8",
    "apd": "2019-04-25",
    "apy": "2019",
    "tit": "渲染图像的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110047124A",
    "apn": "CN201910331264.8",
    "apd": "2019-04-23",
    "apy": "2019",
    "tit": "渲染视频的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110097622A",
    "apn": "CN201910331282.6",
    "apd": "2019-04-23",
    "apy": "2019",
    "tit": "渲染图像的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109977925A",
    "apn": "CN201910324511.1",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "表情确定方法、装置及电子设备",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110033049A",
    "apn": "CN201910313765.3",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "用于生成模型、用于输出信息的方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110032978A",
    "apn": "CN201910312420.6",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "用于处理视频的方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110047102A",
    "apn": "CN201910314590.8",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "用于输出信息的方法、装置和系统",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110059748A",
    "apn": "CN201910314614.X",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "王旭",
      "陈胜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110059624A",
    "apn": "CN201910312194.1",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "用于检测活体的方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110059623A",
    "apn": "CN201910312192.2",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109889893A",
    "apn": "CN201910304462.5",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "视频处理方法、装置及设备",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109889892A",
    "apn": "CN201910302874.5",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "视频效果添加方法、装置、设备及存储介质",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110009059A",
    "apn": "CN201910304768.0",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "用于生成模型的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110008930A",
    "apn": "CN201910304749.8",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "用于识别动物面部状态的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110033423A",
    "apn": "CN201910302471.0",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "王光伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109993150A",
    "apn": "CN201910298933.6",
    "apd": "2019-04-15",
    "apy": "2019",
    "tit": "用于识别年龄的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110008926A",
    "apn": "CN201910299301.1",
    "apd": "2019-04-15",
    "apy": "2019",
    "tit": "用于识别年龄的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110046571A",
    "apn": "CN201910298941.0",
    "apd": "2019-04-15",
    "apy": "2019",
    "tit": "用于识别年龄的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110059739A",
    "apn": "CN201910292543.8",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "图像合成方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110062176A",
    "apn": "CN201910292676.5",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "生成视频的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110084154A",
    "apn": "CN201910292677.X",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "渲染图像的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110084290A",
    "apn": "CN201910292542.3",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "训练分类器的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109982130A",
    "apn": "CN201910289648.8",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "一种视频拍摄方法、装置、电子设备及存储介质",
    "inv": [
      "王俊豪"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109992187A",
    "apn": "CN201910289631.2",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "一种控制方法、装置、设备及存储介质",
    "inv": [
      "徐锐",
      "刘梓欣"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110044309A",
    "apn": "CN201910277119.6",
    "apd": "2019-04-08",
    "apy": "2019",
    "tit": "测量方法和装置",
    "inv": [
      "俞亮"
    ],
    "app": [
      "天津字节跳动科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109981989A",
    "apn": "CN201910274416.5",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "渲染图像的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110047122A",
    "apn": "CN201910274185.8",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "渲染图像的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110062157A",
    "apn": "CN201910269947.5",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "渲染图像的方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109981787A",
    "apn": "CN201910264543.7",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "用于展示信息的方法和装置",
    "inv": [
      "尹天久"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109992737A",
    "apn": "CN201910263886.1",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "第三方网页内容审核方法、装置及电子设备",
    "inv": [
      "钱宝坤"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110059596A",
    "apn": "CN201910266596.2",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "一种图像识别方法、装置、介质和电子设备",
    "inv": [
      "张钊",
      "杨萍",
      "邵珊珊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "多屏交互",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109976858A",
    "apn": "CN201910242341.2",
    "apd": "2019-03-28",
    "apy": "2019",
    "tit": "电子设备中应用程序界面的显示控制方法、装置及其设备",
    "inv": [
      "王俊豪"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110069641A",
    "apn": "CN201910235151.8",
    "apd": "2019-03-27",
    "apy": "2019",
    "tit": "图像处理方法、装置和电子设备",
    "inv": [
      "郭建强",
      "李琰"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109949248A",
    "apn": "CN201910233816.1",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "修改车辆在图像中的颜色的方法、装置、设备及介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109977937A",
    "apn": "CN201910234587.5",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "图像处理方法、装置及设备",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110035236A",
    "apn": "CN201910230223.X",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "图像处理方法、装置和电子设备",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110059576A",
    "apn": "CN201910230209.X",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "图片的筛选方法、装置和电子设备",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110060324A",
    "apn": "CN201910223515.0",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "图像渲染方法、装置及电子设备",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110069996A",
    "apn": "CN201910221098.6",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "头部动作识别方法、装置和电子设备",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110069997A",
    "apn": "CN201910222567.6",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "场景分类方法、装置及电子设备",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110035271A",
    "apn": "CN201910216551.4",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "保真图像生成方法、装置及电子设备",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109871834A",
    "apn": "CN201910211760.X",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "信息处理方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109934191A",
    "apn": "CN201910211759.7",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "信息处理方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109977839A",
    "apn": "CN201910211758.2",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "信息处理方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110047121A",
    "apn": "CN201910214900.9",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "端到端的动画生成方法、装置及电子设备",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110047119A",
    "apn": "CN201910214896.6",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "包含动态背景的动画生成方法、装置及电子设备",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110058685A",
    "apn": "CN201910215466.6",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "虚拟对象的显示方法、装置、电子设备和计算机可读存储介质",
    "inv": [
      "李润祥"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109902763A",
    "apn": "CN201910208611.8",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "用于生成特征图的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109919249A",
    "apn": "CN201910209379.X",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "用于生成特征图的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109948700A",
    "apn": "CN201910209390.6",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "用于生成特征图的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109948699A",
    "apn": "CN201910209386.X",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "用于生成特征图的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109862274A",
    "apn": "CN201910202292.X",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "带摄像功能的耳机、用于输出控制信号的方法和装置",
    "inv": [
      "冯瑞丰"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109919244A",
    "apn": "CN201910204897.2",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "用于生成场景识别模型的方法和装置",
    "inv": [
      "李伟健",
      "姜轩",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109947989A",
    "apn": "CN201910204127.8",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "用于处理视频的方法和装置",
    "inv": [
      "李伟健",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109961032A",
    "apn": "CN201910204092.8",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "用于生成分类模型的方法和装置",
    "inv": [
      "李伟健",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109902659A",
    "apn": "CN201910199277.4",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "用于处理人体图像的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070499A",
    "apn": "CN201910193207.8",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "图像处理方法、装置和计算机可读存储介质",
    "inv": [
      "周景锦"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070482A",
    "apn": "CN201910193170.9",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "图像处理方法、装置和计算机可读存储介质",
    "inv": [
      "庄幽文",
      "李琰"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109918530A",
    "apn": "CN201910159569.5",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "用于推送图像的方法和装置",
    "inv": [
      "杨成",
      "李静"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109919220A",
    "apn": "CN201910159477.7",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "用于生成视频的特征向量的方法和装置",
    "inv": [
      "杨成",
      "范仲悦",
      "何轶"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109934142A",
    "apn": "CN201910159596.2",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "用于生成视频的特征向量的方法和装置",
    "inv": [
      "杨成",
      "范仲悦"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070592A",
    "apn": "CN201910151002.3",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "特效包的生成方法、装置和硬件装置",
    "inv": [
      "沈言浩",
      "杨辉",
      "李小奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070496A",
    "apn": "CN201910151000.4",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "图像特效的生成方法、装置和硬件装置",
    "inv": [
      "沈言浩",
      "杨辉",
      "李小奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070495A",
    "apn": "CN201910124386.X",
    "apd": "2019-02-20",
    "apy": "2019",
    "tit": "图像的处理方法、装置和电子设备",
    "inv": [
      "周景锦"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109754464A",
    "apn": "CN201910099412.8",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109801210A",
    "apn": "CN201910099986.5",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "李应鹏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109816035A",
    "apn": "CN201910098134.4",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109816037A",
    "apn": "CN201910098641.8",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "提取图像的特征图的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109816036A",
    "apn": "CN201910098632.9",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109815964A",
    "apn": "CN201910098644.1",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "提取图像的特征图的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109816791A",
    "apn": "CN201910100632.8",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109816670A",
    "apn": "CN201910099310.6",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "用于生成图像分割模型的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109829520A",
    "apn": "CN201910109938.X",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109829431A",
    "apn": "CN201910099390.5",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109829432A",
    "apn": "CN201910099415.1",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109840528A",
    "apn": "CN201910098620.6",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "提取图像的特征图的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109858444A",
    "apn": "CN201910099356.8",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "人体关键点检测模型的训练方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109858445A",
    "apn": "CN201910099403.9",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "用于生成模型的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109871791A",
    "apn": "CN201910098146.7",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109871890A",
    "apn": "CN201910097773.9",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110069195A",
    "apn": "CN201910101346.3",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像拖拽变形方法和装置",
    "inv": [
      "倪光耀",
      "杨辉"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110069191A",
    "apn": "CN201910100528.9",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "基于终端的图像拖拽变形实现方法和装置",
    "inv": [
      "倪光耀",
      "杨辉"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070515A",
    "apn": "CN201910101359.0",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像合成方法、装置和计算机可读存储介质",
    "inv": [
      "郑微"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070479A",
    "apn": "CN201910100575.3",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像变形拖拽点定位方法和装置",
    "inv": [
      "倪光耀",
      "杨辉"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070585A",
    "apn": "CN201910101336.X",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "图像生成方法、装置和计算机可读存储介质",
    "inv": [
      "郑微"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109800732A",
    "apn": "CN201910092487.3",
    "apd": "2019-01-30",
    "apy": "2019",
    "tit": "用于生成漫画头像生成模型的方法和装置",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109800730A",
    "apn": "CN201910090200.3",
    "apd": "2019-01-30",
    "apy": "2019",
    "tit": "用于生成头像生成模型的方法和装置",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109816589A",
    "apn": "CN201910090075.6",
    "apd": "2019-01-30",
    "apy": "2019",
    "tit": "用于生成漫画风格转换模型的方法和装置",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109741388A",
    "apn": "CN201910084618.3",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于生成双目深度估计模型的方法和装置",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109784304A",
    "apn": "CN201910084636.1",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于标注牙齿图像的方法和装置",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109816023A",
    "apn": "CN201910084639.5",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于生成图片标签模型的方法和装置",
    "inv": [
      "李伟健",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109815365A",
    "apn": "CN201910084731.1",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于处理视频的方法和装置",
    "inv": [
      "李伟健",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109828741A",
    "apn": "CN201910086010.4",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于播放音频的方法和装置",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109842738A",
    "apn": "CN201910086008.7",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于拍摄图像的方法和装置",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109840059A",
    "apn": "CN201910086013.8",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于显示图像的方法和装置",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109872276A",
    "apn": "CN201910086001.5",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "用于生成图像超分辨率模型的方法和装置",
    "inv": [
      "李可",
      "姜宇宁",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110072047A",
    "apn": "CN201910073610.7",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "图像形变的控制方法、装置和硬件装置",
    "inv": [
      "沈言浩",
      "范旭",
      "倪光耀",
      "杨辉"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109739403A",
    "apn": "CN201811625713.1",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "用于处理信息的方法和装置",
    "inv": [
      "蒋奇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109819316A",
    "apn": "CN201811627792.X",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "处理视频中人脸贴纸的方法、装置、存储介质及电子设备",
    "inv": [
      "杜大鹏",
      "刘钊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109753910A",
    "apn": "CN201811615301.X",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "关键点提取方法、模型的训练方法、装置、介质及设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109618222B",
    "apn": "CN201811612133.9",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "一种拼接视频生成方法、装置、终端设备及存储介质",
    "inv": [
      "许冲",
      "黄日昇",
      "姜宇宁",
      "徐力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110070494A",
    "apn": "CN201811569874.3",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "图像处理方法、装置和电子设备",
    "inv": [
      "庄幽文"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110069974A",
    "apn": "CN201811569773.6",
    "apd": "2018-12-21",
    "apy": "2018",
    "tit": "高光图像处理方法、装置和电子设备",
    "inv": [
      "庄幽文"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109683710B",
    "apn": "CN201811565432.1",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "一种手掌法向量确定方法、装置、设备及存储介质",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109635767B",
    "apn": "CN201811564480.9",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "一种手掌法向模型的训练方法、装置、设备及存储介质",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109670444A",
    "apn": "CN201811553212.7",
    "apd": "2018-12-18",
    "apy": "2018",
    "tit": "姿态检测模型的生成、姿态检测方法、装置、设备及介质",
    "inv": [
      "王旭",
      "张惜今"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109684974A",
    "apn": "CN201811549518.5",
    "apd": "2018-12-18",
    "apy": "2018",
    "tit": "活体检测方法、装置、电子设备及存储介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109635757B",
    "apn": "CN201811548844.4",
    "apd": "2018-12-18",
    "apy": "2018",
    "tit": "活体检测方法、装置、电子设备及存储介质",
    "inv": [
      "王旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109753883A",
    "apn": "CN201811528202.8",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "视频定位方法、装置、存储介质和电子设备",
    "inv": [
      "李根"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109740442A",
    "apn": "CN201811519575.9",
    "apd": "2018-12-12",
    "apy": "2018",
    "tit": "定位方法、装置、存储介质及电子设备",
    "inv": [
      "周多友",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109753150A",
    "apn": "CN201811512146.9",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "人物动作控制方法、装置、存储介质和电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109583391A",
    "apn": "CN201811473824.5",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "关键点检测方法、装置、设备及可读介质",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109584276A",
    "apn": "CN201811475894.4",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "关键点检测方法、装置、设备及可读介质",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109635821A",
    "apn": "CN201811475889.3",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "图像区域的特征提取方法、装置、设备及可读介质",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109697446A",
    "apn": "CN201811475784.8",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "图像关键点提取方法、装置、可读存储介质及电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109711273A",
    "apn": "CN201811475792.2",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "图像关键点提取方法、装置、可读存储介质及电子设备",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109598304B",
    "apn": "CN201811474525.3",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "分类模型校准方法、装置、设备及可读介质",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109543068A",
    "apn": "CN201811455652.9",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "用于生成视频的评论信息的方法和装置",
    "inv": [
      "陈家泽",
      "李磊",
      "马维英",
      "黄海龙",
      "杜玉姣"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109584013A",
    "apn": "CN201811457980.2",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "生成物品描述信息的方法和装置",
    "inv": [
      "陈家泽",
      "李磊",
      "曾颖",
      "马维英"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109584012A",
    "apn": "CN201811455693.8",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "用于生成物品推送信息的方法和装置",
    "inv": [
      "陈家泽",
      "李磊",
      "曾颖",
      "马维英"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109348277A",
    "apn": "CN201811447972.X",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "运动像素视频特效添加方法、装置、终端设备及存储介质",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109462776A",
    "apn": "CN201811446874.4",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "一种视频特效添加方法、装置、终端设备及存储介质",
    "inv": [
      "祝豪",
      "李啸",
      "孟宇",
      "陈曼仪",
      "陈晔",
      "林晔"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109474850A",
    "apn": "CN201811446903.7",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "运动像素视频特效添加方法、装置、终端设备及存储介质",
    "inv": [
      "李华夏"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109495695A",
    "apn": "CN201811447952.2",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "运动物体视频特效添加方法、装置、终端设备及存储介质",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109525891A",
    "apn": "CN201811446855.1",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "多用户视频特效添加方法、装置、终端设备及存储介质",
    "inv": [
      "黄佳斌"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109600559A",
    "apn": "CN201811447969.8",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "一种视频特效添加方法、装置、终端设备及存储介质",
    "inv": [
      "黄佳斌",
      "唐堂",
      "孟宇",
      "陈曼仪",
      "陈晔",
      "林晔"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109618183B",
    "apn": "CN201811447962.6",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "一种视频特效添加方法、装置、终端设备及存储介质",
    "inv": [
      "唐堂",
      "孟宇",
      "陈曼仪",
      "林晔",
      "陈晔"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109584338A",
    "apn": "CN201811438153.9",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "图层处理方法、装置和电子设备",
    "inv": [
      "熊瑾"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109656554A",
    "apn": "CN201811425428.5",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "用户界面生成方法及装置",
    "inv": [
      "俞亮"
    ],
    "app": [
      "天津字节跳动科技有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109635923A",
    "apn": "CN201811384897.7",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "用于处理数据的方法和装置",
    "inv": [
      "李亦锬",
      "曹玮",
      "周浩",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109472264A",
    "apn": "CN201811332327.3",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "用于生成物体检测模型的方法和装置",
    "inv": [
      "刘阳"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109410253B",
    "apn": "CN201811313949.1",
    "apd": "2018-11-06",
    "apy": "2018",
    "tit": "用于生成信息的方法、装置、电子设备和计算机可读介质",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109492687A",
    "apn": "CN201811297769.9",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "用于处理信息的方法和装置",
    "inv": [
      "龙睿",
      "白宇"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109344908A",
    "apn": "CN201811273468.2",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "用于生成模型的方法和装置",
    "inv": [
      "袁泽寰",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109389096A",
    "apn": "CN201811273682.8",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "检测方法和装置",
    "inv": [
      "袁泽寰",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109446990A",
    "apn": "CN201811273478.6",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "袁泽寰",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109447156A",
    "apn": "CN201811273681.3",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "用于生成模型的方法和装置",
    "inv": [
      "袁泽寰",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109191514A",
    "apn": "CN201811236596.X",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "用于生成深度检测模型的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109345580A",
    "apn": "CN201811236252.9",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "卢艺帆"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109446199A",
    "apn": "CN201811220099.0",
    "apd": "2018-10-19",
    "apy": "2018",
    "tit": "单元格数据处理方法和装置",
    "inv": [
      "王镇佳",
      "刘晓东"
    ],
    "app": [
      "天津字节跳动科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109523564A",
    "apn": "CN201811223573.5",
    "apd": "2018-10-19",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "范旭"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109117831A",
    "apn": "CN201811160123.6",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "物体检测网络的训练方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109272050B",
    "apn": "CN201811159995.0",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109409517B",
    "apn": "CN201811162439.9",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "物体检测网络的训练方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109255337A",
    "apn": "CN201811149799.5",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "人脸关键点检测方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109308681A",
    "apn": "CN201811148709.0",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109389640A",
    "apn": "CN201811149818.4",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109389072A",
    "apn": "CN201811149827.3",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "数据处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109118456A",
    "apn": "CN201811124831.4",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109242801A",
    "apn": "CN201811126509.5",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109255767A",
    "apn": "CN201811125322.3",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109344762A",
    "apn": "CN201811126510.8",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109377508A",
    "apn": "CN201811126084.8",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "胡耀全"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109101956A",
    "apn": "CN201811109290.8",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109241934A",
    "apn": "CN201811110424.8",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109272543A",
    "apn": "CN201811107932.0",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "用于生成模型的方法和装置",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109325996A",
    "apn": "CN201811107371.4",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "郭冠军"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109344289A",
    "apn": "CN201811110667.1",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109308469B",
    "apn": "CN201811110674.1",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "吴兴龙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109241930A",
    "apn": "CN201811101399.7",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "用于处理眉部图像的方法和装置",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109255814A",
    "apn": "CN201811102337.8",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "华淼"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109344752B",
    "apn": "CN201811102339.7",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "用于处理嘴部图像的方法和装置",
    "inv": [
      "王诗吟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109241921A",
    "apn": "CN201811080613.5",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "用于检测人脸关键点的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109191453A",
    "apn": "CN201811075020.X",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "用于生成图像类别检测模型的方法和装置",
    "inv": [
      "肖梅峰",
      "徐珍琦",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109214343A",
    "apn": "CN201811075000.2",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "用于生成人脸关键点检测模型的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109271929A",
    "apn": "CN201811075036.0",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "检测方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109271089A",
    "apn": "CN201811076383.5",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "图片打码方法及装置",
    "inv": [
      "翟韬"
    ],
    "app": [
      "天津字节跳动科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109064494A",
    "apn": "CN201811068698.5",
    "apd": "2018-09-13",
    "apy": "2018",
    "tit": "视频漂浮纸片检测方法、装置和计算机可读存储介质",
    "inv": [
      "周多友",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109165301A",
    "apn": "CN201811069772.5",
    "apd": "2018-09-13",
    "apy": "2018",
    "tit": "视频封面选择方法、装置和计算机可读存储介质",
    "inv": [
      "黄凯",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109299734A",
    "apn": "CN201811065343.0",
    "apd": "2018-09-13",
    "apy": "2018",
    "tit": "侵权图片的识别方法、装置和计算机可读存储介质",
    "inv": [
      "周多友",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109242892B",
    "apn": "CN201811060837.X",
    "apd": "2018-09-12",
    "apy": "2018",
    "tit": "用于确定图像间的几何变换关系的方法和装置",
    "inv": [
      "陈扬羽",
      "何轶",
      "李磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109308490A",
    "apn": "CN201811042461.X",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109285105A",
    "apn": "CN201811033450.5",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "水印检测方法、装置、计算机设备和存储介质",
    "inv": [
      "周恺卉",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109145906A",
    "apn": "CN201811010092.6",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "目标对象的图像确定方法、装置、设备及存储介质",
    "inv": [
      "李根",
      "许世坤",
      "朱延东",
      "李磊",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109255037A",
    "apn": "CN201811015493.0",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "陈大伟",
      "刘宝"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109308456A",
    "apn": "CN201811010095.X",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "目标对象的信息确定方法、装置、设备及存储介质",
    "inv": [
      "李根",
      "许世坤",
      "朱延东",
      "李磊",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109126100A",
    "apn": "CN201810997758.5",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "控球率计算方法、装置、设备及存储介质",
    "inv": [
      "许世坤",
      "朱延东",
      "高思怡",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109145840A",
    "apn": "CN201810996637.9",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "视频场景分类方法、装置、设备及存储介质",
    "inv": [
      "李根",
      "许世坤",
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109063678A",
    "apn": "CN201810975866.2",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "脸部图像识别的方法、装置及存储介质",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109145828A",
    "apn": "CN201810973121.2",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "用于生成视频类别检测模型的方法和装置",
    "inv": [
      "李伟健",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109241890A",
    "apn": "CN201810975874.7",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "面部图像校正方法、装置及存储介质",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109191507B",
    "apn": "CN201810975740.5",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "三维人脸图像重建方法、装置和计算机可读存储介质",
    "inv": [
      "陈志兴"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108924627A",
    "apn": "CN201810969200.6",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "运动对象的位置分布显示方法、装置、设备及存储介质",
    "inv": [
      "许世坤",
      "朱延东",
      "徐珍琦",
      "高思怡",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109102530A",
    "apn": "CN201810956271.2",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "运动轨迹绘制方法、装置、设备和存储介质",
    "inv": [
      "许世坤",
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108960206A",
    "apn": "CN201810888882.8",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "视频帧处理方法和装置",
    "inv": [
      "吴兴龙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109063653A",
    "apn": "CN201810888877.7",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "吴兴龙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108882025B",
    "apn": "CN201810888878.1",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "视频帧处理方法和装置",
    "inv": [
      "吴兴龙",
      "罗国中"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108921138A",
    "apn": "CN201810876247.8",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108986023A",
    "apn": "CN201810875903.2",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108985257A",
    "apn": "CN201810877023.9",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109034085A",
    "apn": "CN201810876249.7",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109086719A",
    "apn": "CN201810875904.7",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于输出数据的方法和装置",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109101919A",
    "apn": "CN201810878615.2",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109102484A",
    "apn": "CN201810875203.3",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "刘志超"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109145783A",
    "apn": "CN201810877815.6",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109145781A",
    "apn": "CN201810875809.7",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "朱延东",
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109165571A",
    "apn": "CN201810879255.8",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于插入图像的方法和装置",
    "inv": [
      "邓涵",
      "刘志超"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109165570A",
    "apn": "CN201810878626.0",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "邓启力"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109087377B",
    "apn": "CN201810879994.7",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "邓涵"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109034069A",
    "apn": "CN201810846313.7",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109117758A",
    "apn": "CN201810843246.3",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "陈日伟"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108875704A",
    "apn": "CN201810785671.1",
    "apd": "2018-07-17",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "朱延东",
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109003223A",
    "apn": "CN201810767342.4",
    "apd": "2018-07-13",
    "apy": "2018",
    "tit": "图片处理方法和装置",
    "inv": [
      "喻冬东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108986031A",
    "apn": "CN201810764125.X",
    "apd": "2018-07-12",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机设备和存储介质",
    "inv": [
      "何茜",
      "张惜今"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108961189A",
    "apn": "CN201810758859.7",
    "apd": "2018-07-11",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机设备和存储介质",
    "inv": [
      "何茜"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109600558A",
    "apn": "CN201810758789.5",
    "apd": "2018-07-11",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "林木"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108876858A",
    "apn": "CN201810734682.7",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "徐珍琦",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108985208A",
    "apn": "CN201810734679.5",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "生成图像检测模型的方法和装置",
    "inv": [
      "徐珍琦",
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108986169A",
    "apn": "CN201810734681.2",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "徐珍琦",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109558779A",
    "apn": "CN201810734680.8",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "图像检测方法和装置",
    "inv": [
      "徐珍琦",
      "朱延东",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108898185A",
    "apn": "CN201810715194.1",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "用于生成图像识别模型的方法和装置",
    "inv": [
      "周恺卉",
      "周多友",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108898186A",
    "apn": "CN201810715195.6",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "用于提取图像的方法和装置",
    "inv": [
      "周恺卉",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108921792A",
    "apn": "CN201810714637.5",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "用于处理图片的方法和装置",
    "inv": [
      "徐珍琦",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108769803A",
    "apn": "CN201810701638.6",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "带边框视频的识别方法、裁剪方法、系统、设备及介质",
    "inv": [
      "李根",
      "李磊",
      "何轶",
      "李亦锬",
      "杨成"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108921838A",
    "apn": "CN201810694004.2",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "用于检测图像的方法和装置",
    "inv": [
      "吕伟斌",
      "罗立新"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108830928A",
    "apn": "CN201810688329.X",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "三维模型的映射方法、装置、终端设备和可读存储介质",
    "inv": [
      "刘阳"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108876713A",
    "apn": "CN201810688356.7",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "二维模板图像的映射方法、装置、终端设备和存储介质",
    "inv": [
      "刘阳"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108875667A",
    "apn": "CN201810678878.9",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "目标识别方法、装置、终端设备和存储介质",
    "inv": [
      "许世坤",
      "朱延东",
      "李根",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108898134A",
    "apn": "CN201810678896.7",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "号码识别方法、装置、终端设备和存储介质",
    "inv": [
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108960316A",
    "apn": "CN201810679114.1",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "用于生成模型的方法和装置",
    "inv": [
      "李伟健",
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109002842A",
    "apn": "CN201810680031.4",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "图像识别方法和装置",
    "inv": [
      "周恺卉",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108776954A",
    "apn": "CN201810668219.7",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "余林韵",
      "李磊",
      "尹海斌",
      "朱文佳",
      "姜东"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108921801A",
    "apn": "CN201810669838.8",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "余林韵",
      "李磊",
      "尹海斌",
      "姜东"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108960110A",
    "apn": "CN201810668216.3",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "李伟健",
      "许世坤",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108830329A",
    "apn": "CN201810649563.1",
    "apd": "2018-06-22",
    "apy": "2018",
    "tit": "图片处理方法和装置",
    "inv": [
      "徐珍琦",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108830235A",
    "apn": "CN201810644175.4",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "李伟健",
      "李映红",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108985178A",
    "apn": "CN201810643456.8",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "李伟健",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109002162A",
    "apn": "CN201810643987.7",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "场景切换方法、装置、终端和计算机存储介质",
    "inv": [
      "淮静",
      "郭亨凯",
      "陈尧"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109753199B",
    "apn": "CN201810644466.3",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "一种应用页面展示方法及移动终端",
    "inv": [
      "罗永浩",
      "鲁威",
      "陈桂斌",
      "苗华云"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108805091A",
    "apn": "CN201810617804.4",
    "apd": "2018-06-15",
    "apy": "2018",
    "tit": "用于生成模型的方法和装置",
    "inv": [
      "李伟健",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108830900A",
    "apn": "CN201810620519.8",
    "apd": "2018-06-15",
    "apy": "2018",
    "tit": "关键点的抖动处理方法和装置",
    "inv": [
      "刘阳"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108776970A",
    "apn": "CN201810601021.7",
    "apd": "2018-06-12",
    "apy": "2018",
    "tit": "图像处理方法和装置",
    "inv": [
      "许世坤"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108875611A",
    "apn": "CN201810570451.7",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "视频动作识别方法和装置",
    "inv": [
      "袁泽寰",
      "王长虎"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108805898A",
    "apn": "CN201810551722.4",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "视频图像处理方法和装置",
    "inv": [
      "吴兴龙"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108764143A",
    "apn": "CN201810532242.3",
    "apd": "2018-05-29",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机设备和存储介质",
    "inv": [
      "蒋宇东"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108805058A",
    "apn": "CN201810531141.4",
    "apd": "2018-05-29",
    "apy": "2018",
    "tit": "目标对象变化姿态识别方法、装置及计算机设备",
    "inv": [
      "蒋宇东"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108830782A",
    "apn": "CN201810532243.8",
    "apd": "2018-05-29",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机设备和存储介质",
    "inv": [
      "蒋宇东"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108629819B",
    "apn": "CN201810463453.6",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "图像染发处理方法和装置",
    "inv": [
      "张惜今"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109753145A",
    "apn": "CN201810449220.0",
    "apd": "2018-05-11",
    "apy": "2018",
    "tit": "一种过渡动画的展示方法和相关装置",
    "inv": [
      "李毅",
      "魏巍"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108536293A",
    "apn": "CN201810273850.7",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "人机交互系统、方法、计算机可读存储介质及交互装置",
    "inv": [
      "唐堂",
      "张勃",
      "蒋志鹏",
      "文林福"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN108874120A",
    "apn": "CN201810274498.9",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "人机交互系统、方法、计算机可读存储介质及交互装置",
    "inv": [
      "唐堂",
      "张勃",
      "蒋志鹏",
      "文林福"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110324659A",
    "apn": "CN201810271773.1",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "一种视频特征提取方法及装置",
    "inv": [
      "何轶",
      "李磊",
      "杨成",
      "李根",
      "李亦锬"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110321759A",
    "apn": "CN201810271774.6",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "一种视频特征提取方法及装置",
    "inv": [
      "何轶",
      "李磊",
      "杨成",
      "李根",
      "李亦锬"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN110324660A",
    "apn": "CN201810273706.3",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "一种重复视频的判断方法及装置",
    "inv": [
      "何轶",
      "李磊",
      "杨成",
      "李根",
      "李亦锬"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN107610045A",
    "apn": "CN201710854664.8",
    "apd": "2017-09-20",
    "apy": "2017",
    "tit": "鱼眼图片拼接中的亮度补偿方法、装置、设备及存储介质",
    "inv": [
      "王悦"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN109255752A",
    "apn": "CN201710575224.9",
    "apd": "2017-07-14",
    "apy": "2017",
    "tit": "图像自适应压缩方法、装置、终端及存储介质",
    "inv": [
      "洪拍敏",
      "黄灿",
      "张凯磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN106503693B",
    "apn": "CN201611059438.2",
    "apd": "2016-11-28",
    "apy": "2016",
    "tit": "视频封面的提供方法及装置",
    "inv": [
      "赵彦宾",
      "姜东",
      "洪定坤",
      "夏绪宏"
    ],
    "app": [
      "北京字节跳动科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "质量评价"
  },
  {
    "entity": "字节跳动",
    "pun": "CN106358029B",
    "apn": "CN201610905873.6",
    "apd": "2016-10-18",
    "apy": "2016",
    "tit": "一种视频图像处理方法和装置",
    "inv": [
      "贺坚强",
      "姜东",
      "洪定坤",
      "夏旭宏"
    ],
    "app": [
      "北京字节跳动科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN106383912B",
    "apn": "CN201610898345.2",
    "apd": "2016-10-14",
    "apy": "2016",
    "tit": "一种图片检索方法和装置",
    "inv": [
      "高钰舒",
      "张凯磊"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "字节跳动",
    "pun": "CN106161939A",
    "apn": "CN201610605036.1",
    "apd": "2016-07-27",
    "apy": "2016",
    "tit": "一种照片拍摄方法及终端",
    "inv": [
      "朱萧木",
      "汪天纬"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "字节跳动",
    "pun": "CN106228516B",
    "apn": "CN201610556899.4",
    "apd": "2016-07-14",
    "apy": "2016",
    "tit": "一种高自然度的实时美颜方法、装置",
    "inv": [
      "田毅"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN105139356B",
    "apn": "CN201510526281.9",
    "apd": "2015-08-25",
    "apy": "2015",
    "tit": "一种图像数据的毛玻璃效果处理方法和装置",
    "inv": [
      "方迟",
      "陈鸾",
      "鲁威"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN104808908B",
    "apn": "CN201510263361.X",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "一种显示界面处理方法及装置",
    "inv": [
      "方迟",
      "韩邦森"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像处理"
  },
  {
    "entity": "字节跳动",
    "pun": "CN104794462B",
    "apn": "CN201510235866.5",
    "apd": "2015-05-11",
    "apy": "2015",
    "tit": "一种人物图像处理方法及装置",
    "inv": [
      "耿达维",
      "王威",
      "王亮"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "字节跳动",
    "pun": "CN103927713B",
    "apn": "CN201410166475.8",
    "apd": "2014-04-23",
    "apy": "2014",
    "tit": "图片缩略图的获取方法及装置",
    "inv": [
      "罗永浩",
      "陈鸾"
    ],
    "app": [
      "北京字节跳动网络技术有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110516624A",
    "apn": "CN201910810496.1",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "林江煌",
      "曹继邦"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN110517278A",
    "apn": "CN201910724764.8",
    "apd": "2019-08-07",
    "apy": "2019",
    "tit": "图像分割和图像分割网络的训练方法、装置和计算机设备",
    "inv": [
      "陈文科",
      "姚聪",
      "陈益民"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN110443237A",
    "apn": "CN201910722575.7",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "证件识别方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "梁浩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110413815A",
    "apn": "CN201910684614.9",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "人像聚类清洗方法和装置",
    "inv": [
      "程皓"
    ],
    "app": [
      "北京旷视科技有限公司",
      "青岛旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110517180A",
    "apn": "CN201910673385.0",
    "apd": "2019-07-24",
    "apy": "2019",
    "tit": "基于高精度神经网络的图像处理方法、装置及电子设备",
    "inv": [
      "高源",
      "蔡子翔",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110505397A",
    "apn": "CN201910628988.9",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "相机选择的方法、装置及计算机存储介质",
    "inv": [
      "炊文伟",
      "宋扬",
      "那正平"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110348379A",
    "apn": "CN201910617484.7",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "一种公共交通工具中目标对象确定方法、装置、系统及存储介质",
    "inv": [
      "炊文伟",
      "宋扬",
      "陈雪松"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110135406A",
    "apn": "CN201910612549.9",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "图像识别方法、装置、计算机设备和存储介质",
    "inv": [
      "李栋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110348460A",
    "apn": "CN201910601151.5",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "一种基于角度的目标检测训练方法、目标检测方法及装置",
    "inv": [
      "汪伟",
      "贾澜鹏",
      "郭江涛",
      "何闵",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110287942A",
    "apn": "CN201910595412.7",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "年龄估计模型的训练方法、年龄估计方法以及对应的装置",
    "inv": [
      "章超",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110298312A",
    "apn": "CN201910578487.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "活体检测方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "李念",
      "卢江虎",
      "李晓彤",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110413826A",
    "apn": "CN201910569378.6",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "物品图像识别方法和设备、图像处理设备及介质",
    "inv": [
      "宋仁杰",
      "魏秀参"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110288015A",
    "apn": "CN201910544173.2",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "一种人像检索的处理方法和装置",
    "inv": [
      "刘瑞伟",
      "程皓"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110413822A",
    "apn": "CN201910532417.5",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "离线图像结构化分析方法、装置、系统和存储介质",
    "inv": [
      "杨龙飞",
      "宋扬",
      "付英波"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110263830A",
    "apn": "CN201910490363.0",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "图像处理方法、装置和系统及存储介质",
    "inv": [
      "丁峰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN110263680A",
    "apn": "CN201910477796.2",
    "apd": "2019-06-03",
    "apy": "2019",
    "tit": "图像处理方法、装置和系统及存储介质",
    "inv": [
      "卢龙飞",
      "王宁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN110188129A",
    "apn": "CN201910471968.5",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "人证核验终端的数据处理方法、装置、系统、设备及介质",
    "inv": [
      "蔡世杰",
      "宋扬",
      "王晖",
      "陈雪松"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN110163183A",
    "apn": "CN201910462703.9",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "目标检测算法的评估方法、装置、计算机设备和存储介质",
    "inv": [
      "赵航",
      "孙磊",
      "黄元臻"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110163155A",
    "apn": "CN201910436450.8",
    "apd": "2019-05-23",
    "apy": "2019",
    "tit": "人脸数据的处理方法、装置、电子设备及可读存储介质",
    "inv": [
      "郭志鹏",
      "王宁",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "存储",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110263641A",
    "apn": "CN201910413454.4",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "疲劳检测方法、装置及可读存储介质",
    "inv": [
      "赵姗",
      "刘东昊",
      "罗堃铭",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110288632A",
    "apn": "CN201910408168.9",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "一种图像处理方法、装置、终端及存储介质",
    "inv": [
      "高鹏远"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110287778A",
    "apn": "CN201910408170.6",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "一种图像的处理方法、装置、终端及存储介质",
    "inv": [
      "高鹏远",
      "刘子洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110263196A",
    "apn": "CN201910390657.6",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "图像检索方法、装置、电子设备及存储介质",
    "inv": [
      "胡本翼",
      "崔权",
      "宋仁杰"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN110245679A",
    "apn": "CN201910381241.8",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "图像聚类方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "张涛",
      "王帅",
      "陈亮",
      "江彦涛",
      "邵笑飞",
      "杨沐"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN110334576A",
    "apn": "CN201910359991.5",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "一种手部追踪方法及装置",
    "inv": [
      "孙晨",
      "陈文科",
      "高源",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110059657A",
    "apn": "CN201910340992.5",
    "apd": "2019-04-25",
    "apy": "2019",
    "tit": "档案处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "杨一"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110378189A",
    "apn": "CN201910325498.1",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "一种监控布置方法、装置、终端及存储介质",
    "inv": [
      "炊文伟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110222557A",
    "apn": "CN201910325155.5",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "路况的实时检测方法、装置、系统和存储介质",
    "inv": [
      "廖声洋",
      "吴文昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109981991A",
    "apn": "CN201910309053.4",
    "apd": "2019-04-17",
    "apy": "2019",
    "tit": "模型训练方法、图像处理方法、装置、介质和电子设备",
    "inv": [
      "巫奇豪",
      "刘家铭",
      "王珏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110207701A",
    "apn": "CN201910304181.X",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "室内导航的方法、装置、终端设备及计算机存储介质",
    "inv": [
      "罗凯辉",
      "宋扬",
      "付英波"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN110084157A",
    "apn": "CN201910295094.2",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "图像重识别的数据处理方法及装置",
    "inv": [
      "炊文伟",
      "那正平"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110008911A",
    "apn": "CN201910285762.3",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "廖声洋",
      "唐文斌",
      "吴文昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110047059A",
    "apn": "CN201910285778.4",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备及可读存储介质",
    "inv": [
      "马士川",
      "宋扬",
      "谭竣方",
      "苌帅",
      "顾亮"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109993143A",
    "apn": "CN201910285754.9",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "图像采集设备的安装方法、装置、电子设备及存储介质",
    "inv": [
      "鞠光辉"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110008907A",
    "apn": "CN201910281839.X",
    "apd": "2019-04-09",
    "apy": "2019",
    "tit": "一种年龄的估计方法、装置、电子设备和计算机可读介质",
    "inv": [
      "章超",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109978077A",
    "apn": "CN201910278161.X",
    "apd": "2019-04-08",
    "apy": "2019",
    "tit": "视觉识别方法、装置和系统及存储介质",
    "inv": [
      "金鑫",
      "魏秀参",
      "赵博睿",
      "谢烟平"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110008903A",
    "apn": "CN201910272435.4",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "人脸识别方法、装置、系统、存储介质和人脸支付方法",
    "inv": [
      "苌帅",
      "贾春光",
      "吕洪啸",
      "邢建辉",
      "谭竣方",
      "马士川",
      "邹颖"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110110597A",
    "apn": "CN201910263023.4",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "活体检测方法、装置及活体检测终端",
    "inv": [
      "牛逢泉"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109948734A",
    "apn": "CN201910260788.2",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "图像聚类方法、装置及电子设备",
    "inv": [
      "王帅",
      "张涛",
      "江彦涛",
      "陈亮",
      "邵笑飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN110059594A",
    "apn": "CN201910261185.4",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "一种环境感知自适应图像识别方法和装置",
    "inv": [
      "杨一",
      "宋扬",
      "陈雪松"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110135963A",
    "apn": "CN201910250477.8",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "基于人证核验的税务实名制系统及其信息录入方法",
    "inv": [
      "王晖",
      "宋扬",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109977262A",
    "apn": "CN201910231596.9",
    "apd": "2019-03-25",
    "apy": "2019",
    "tit": "从视频中获取候选片段的方法、装置及处理设备",
    "inv": [
      "卢江虎",
      "姚聪",
      "刘小龙",
      "孙宇超"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109919816A",
    "apn": "CN201910218680.7",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "基于数据分析的排课方法及装置、存储介质及电子设备",
    "inv": [
      "冯斐帆"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110084114A",
    "apn": "CN201910218585.7",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "一种静态人像的数据调度方法、装置、系统及存储介质",
    "inv": [
      "潘玉飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110008379A",
    "apn": "CN201910210485.X",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "监控图像处理方法及装置",
    "inv": [
      "炊文伟",
      "那正平"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109934182A",
    "apn": "CN201910204669.5",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "对象行为分析方法、装置、电子设备及计算机存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110062173A",
    "apn": "CN201910200117.7",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "图像处理器与图像处理方法、设备、存储介质及智能终端",
    "inv": [
      "胡鹏",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110084663A",
    "apn": "CN201910194853.6",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "一种物品推荐方法、装置、终端及存储介质",
    "inv": [
      "田林超",
      "张瑶"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110009003A",
    "apn": "CN201910193539.6",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "图像处理与图像比对模型的训练方法、装置和系统",
    "inv": [
      "杜佳慧"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN110060196A",
    "apn": "CN201910194700.1",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "图像处理方法及装置",
    "inv": [
      "梁喆",
      "李磊",
      "严寒"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109934858A",
    "apn": "CN201910190323.4",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "图像配准方法及装置",
    "inv": [
      "谢锋明",
      "刘毅博",
      "贺欣",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109934773A",
    "apn": "CN201910190341.2",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "一种图像处理方法、装置、电子设备和计算机可读介质",
    "inv": [
      "张祥雨",
      "胡学财"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109714623A",
    "apn": "CN201910186069.0",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "图像展示方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "何蔚然",
      "潘宇超",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110084267A",
    "apn": "CN201910186337.9",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "人像聚类方法、装置、电子设备及可读存储介质",
    "inv": [
      "王帅",
      "江彦涛",
      "张涛",
      "邵笑飞",
      "陈亮"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109948497A",
    "apn": "CN201910186133.5",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "一种物体检测方法、装置及电子设备",
    "inv": [
      "李作新",
      "俞刚",
      "袁野"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110060322A",
    "apn": "CN201910181534.1",
    "apd": "2019-03-11",
    "apy": "2019",
    "tit": "平面提取方法、装置、系统和存储介质",
    "inv": [
      "黄灿"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109948509A",
    "apn": "CN201910192929.1",
    "apd": "2019-03-11",
    "apy": "2019",
    "tit": "对象状态监测方法、装置及电子设备",
    "inv": [
      "刘帅成",
      "赵姗",
      "刘东昊"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109977804A",
    "apn": "CN201910178941.7",
    "apd": "2019-03-11",
    "apy": "2019",
    "tit": "宠物智能门的控制方法、装置、计算机设备和存储介质",
    "inv": [
      "任一聪",
      "李广",
      "李庆",
      "龙灏天"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110059547A",
    "apn": "CN201910176719.3",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "目标检测方法及装置",
    "inv": [
      "熊峰",
      "张弘楷",
      "李伯勋",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109949377A",
    "apn": "CN201910179362.4",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN110059548A",
    "apn": "CN201910176723.X",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "目标检测方法及装置",
    "inv": [
      "张弘楷",
      "熊峰",
      "李伯勋",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109978914A",
    "apn": "CN201910173368.0",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "人脸追踪方法及装置",
    "inv": [
      "卢龙飞",
      "王宁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110032933A",
    "apn": "CN201910172776.4",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种图像数据的获取方法、装置、终端及存储介质",
    "inv": [
      "梁喆",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109949386A",
    "apn": "CN201910172170.0",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种纹理图像生成方法及装置",
    "inv": [
      "赵铠枫",
      "李广",
      "刘致远"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109993067A",
    "apn": "CN201910171553.6",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "面部关键点提取方法、装置、计算机设备和存储介质",
    "inv": [
      "孙伟",
      "范浩强",
      "王春杰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110046546A",
    "apn": "CN201910165319.2",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "一种自适应视线追踪方法、装置、系统及存储介质",
    "inv": [
      "赵姗",
      "罗堃铭",
      "刘东昊",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN110008835A",
    "apn": "CN201910163152.6",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "视线预测方法、装置、系统和可读存储介质",
    "inv": [
      "罗堃铭",
      "刘东昊",
      "赵姗",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109947992A",
    "apn": "CN201910164343.4",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "检索管控方法、前端视频处理方法、管控控制方法及装置",
    "inv": [
      "崔鹏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN109795830A",
    "apn": "CN201910161770.7",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "自动定位物流托盘的方法及装置",
    "inv": [
      "陈亚南"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘、取货",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109979051A",
    "apn": "CN201910163692.4",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "用于核验用户身份的方法、装置和计算机可读存储介质",
    "inv": [
      "时彬斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109932713A",
    "apn": "CN201910161043.0",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "定位方法、装置、计算机设备、可读存储介质和机器人",
    "inv": [
      "王金戈",
      "李北辰",
      "刘骁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109886239A",
    "apn": "CN201910155307.1",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "人像聚类方法、装置及系统",
    "inv": [
      "陈亮",
      "王帅",
      "张涛",
      "江彦涛",
      "邵笑飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109949453A",
    "apn": "CN201910148481.3",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "一种犬类识别闸机及用于犬类识别闸机的犬类准入判断方法",
    "inv": [
      "任一聪",
      "李广",
      "龙灏天"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109919211A",
    "apn": "CN201910141905.3",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "商品识别方法、装置、系统和计算机可读介质",
    "inv": [
      "魏秀参"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109886223A",
    "apn": "CN201910145139.8",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "人脸识别方法、底库录入方法、装置及电子设备",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109858464A",
    "apn": "CN201910145138.3",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "底库数据处理方法、人脸识别方法、装置和电子设备",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109886209A",
    "apn": "CN201910137667.9",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "异常行为检测方法及装置、车载设备",
    "inv": [
      "陈远鹏",
      "王光甫",
      "李茹",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109829947A",
    "apn": "CN201910136465.2",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "位姿确定方法、托盘装载方法、装置、介质及电子设备",
    "inv": [
      "陈亚南"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘、取货",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109871814A",
    "apn": "CN201910140791.0",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "年龄的估计方法、装置、电子设备和计算机存储介质",
    "inv": [
      "章超",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109819238A",
    "apn": "CN201910136618.3",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "TOF图像采集模块的工作频率调节方法、装置和电子系统",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109886951A",
    "apn": "CN201910137122.8",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "视频处理方法、装置及电子设备",
    "inv": [
      "孙培钦"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109829506A",
    "apn": "CN201910124385.5",
    "apd": "2019-02-18",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备和计算机存储介质",
    "inv": [
      "金鑫",
      "魏秀参",
      "赵博睿",
      "谢烟平"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109948624A",
    "apn": "CN201910124316.4",
    "apd": "2019-02-18",
    "apy": "2019",
    "tit": "特征提取的方法、装置、电子设备和计算机存储介质",
    "inv": [
      "史桀绮"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109815770A",
    "apn": "CN201910102889.7",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "二维码检测方法、装置及系统",
    "inv": [
      "石娟峰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109815924A",
    "apn": "CN201910090163.6",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "表情识别方法、装置及系统",
    "inv": [
      "曾凡伟",
      "贾澜鹏",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109800873A",
    "apn": "CN201910087768.X",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "图像处理方法及装置",
    "inv": [
      "王远江",
      "王丽",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109816659A",
    "apn": "CN201910084083.X",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "图像分割方法、装置及系统",
    "inv": [
      "熊鹏飞",
      "李瀚超"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109816694A",
    "apn": "CN201910082670.5",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "目标跟踪方法、装置及电子设备",
    "inv": [
      "韦昭"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109948430A",
    "apn": "CN201910084084.4",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "特征数据共享方法、装置、系统、处理端及存储介质",
    "inv": [
      "王晖",
      "金恩久"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109740573A",
    "apn": "CN201910070118.4",
    "apd": "2019-01-24",
    "apy": "2019",
    "tit": "视频分析方法、装置、设备及服务器",
    "inv": [
      "胡晨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN109740571A",
    "apn": "CN201910061369.6",
    "apd": "2019-01-22",
    "apy": "2019",
    "tit": "图像采集的方法、图像处理的方法、装置和电子设备",
    "inv": [
      "赵振宇",
      "魏秀参"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109784323A",
    "apn": "CN201910057429.7",
    "apd": "2019-01-21",
    "apy": "2019",
    "tit": "图像识别的方法、装置、电子设备和计算机存储介质",
    "inv": [
      "陈晨",
      "丁予春"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109816009A",
    "apn": "CN201910051706.3",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "基于图卷积的多标签图像分类方法、装置及设备",
    "inv": [
      "魏秀参",
      "陈钊民"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109815881A",
    "apn": "CN201910051226.7",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "行为识别模型的训练方法、行为识别方法、装置及设备",
    "inv": [
      "李茹",
      "王光甫",
      "陈远鹏",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109740567A",
    "apn": "CN201910052240.9",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "关键点定位模型训练方法、定位方法、装置及设备",
    "inv": [
      "王春杰",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109783680A",
    "apn": "CN201910042459.0",
    "apd": "2019-01-16",
    "apy": "2019",
    "tit": "图像推送方法、图像获取方法、装置及图像处理系统",
    "inv": [
      "张敬毅",
      "周舒畅",
      "何蔚然"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN109785264A",
    "apn": "CN201910037989.6",
    "apd": "2019-01-15",
    "apy": "2019",
    "tit": "图像增强方法、装置及电子设备",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109766470A",
    "apn": "CN201910038019.8",
    "apd": "2019-01-15",
    "apy": "2019",
    "tit": "图像检索方法、装置及处理设备",
    "inv": [
      "炊文伟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109618100A",
    "apn": "CN201910037990.9",
    "apd": "2019-01-15",
    "apy": "2019",
    "tit": "现场拍摄图像的判断方法、装置及系统",
    "inv": [
      "刘海敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109727264A",
    "apn": "CN201910026276.X",
    "apd": "2019-01-10",
    "apy": "2019",
    "tit": "图像生成方法、神经网络的训练方法、装置和电子设备",
    "inv": [
      "杨磊",
      "魏秀参",
      "崔权"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109727193A",
    "apn": "CN201910026279.3",
    "apd": "2019-01-10",
    "apy": "2019",
    "tit": "图像虚化方法、装置及电子设备",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109693612A",
    "apn": "CN201910019973.2",
    "apd": "2019-01-09",
    "apy": "2019",
    "tit": "一种行车辅助方法、装置、车载终端和计算机可读介质",
    "inv": [
      "李一",
      "夏宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109801335A",
    "apn": "CN201910018559.X",
    "apd": "2019-01-08",
    "apy": "2019",
    "tit": "图像处理方法、装置、电子设备和计算机存储介质",
    "inv": [
      "陈亚南"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "物流",
    "l2": "托盘、取货",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109741144A",
    "apn": "CN201910009098.X",
    "apd": "2019-01-04",
    "apy": "2019",
    "tit": "商品核验方法、装置、主机及设备",
    "inv": [
      "魏秀参",
      "崔权",
      "杨磊"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109598943A",
    "apn": "CN201811654238.0",
    "apd": "2018-12-30",
    "apy": "2018",
    "tit": "车辆违章的监控方法、装置及系统",
    "inv": [
      "刘志康",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109379537A",
    "apn": "CN201811644906.1",
    "apd": "2018-12-30",
    "apy": "2018",
    "tit": "滑动变焦效果实现方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "邹启扬"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109740533A",
    "apn": "CN201811653854.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "遮挡比例确定方法、装置和电子系统",
    "inv": [
      "潘宇超",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109740534A",
    "apn": "CN201811654182.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "图像处理方法、装置及处理设备",
    "inv": [
      "陈文科",
      "姚聪",
      "孙晨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109741387A",
    "apn": "CN201811643376.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "立体匹配方法、装置、电子设备及存储介质",
    "inv": [
      "詹肇楷"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109573526A",
    "apn": "CN201811653855.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "搬运控制方法及搬运机器人",
    "inv": [
      "朱渴萌",
      "张志同",
      "黄庆淮",
      "王贺希格图",
      "闫祖政",
      "李卫军"
    ],
    "app": [
      "北京旷视机器人技术有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109495689A",
    "apn": "CN201811641102.6",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种拍摄方法、装置、电子设备及存储介质",
    "inv": [
      "王涛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109711357A",
    "apn": "CN201811629060.4",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种人脸识别方法及装置",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109740503A",
    "apn": "CN201811631784.2",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "人脸认证方法、图像底库录入方法、装置及处理设备",
    "inv": [
      "曹剑楠"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109670063A",
    "apn": "CN201811629057.2",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "图像数据处理方法、解析方法及设备",
    "inv": [
      "周舒畅",
      "何蔚然",
      "胡晨",
      "梁喆"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109886077A",
    "apn": "CN201811625431.1",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "图像识别方法、装置、计算机设备和存储介质",
    "inv": [
      "张健",
      "万昭祎",
      "贺欣"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109657681A",
    "apn": "CN201811623619.2",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "图片的标注方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "赵振宇",
      "魏秀参",
      "蔡宏基"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109727192A",
    "apn": "CN201811652785.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种图像处理的方法及装置",
    "inv": [
      "刘平",
      "聂旎"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109685741A",
    "apn": "CN201811628074.4",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种图像处理方法、装置及计算机存储介质",
    "inv": [
      "李连杰",
      "顾娟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109726759A",
    "apn": "CN201811621432.9",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "无人售货方法、装置、系统、电子设备及计算机可读介质",
    "inv": [
      "赵振宇",
      "魏秀参"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109543663A",
    "apn": "CN201811628364.9",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种犬只身份识别方法、装置、系统及存储介质",
    "inv": [
      "赵宇",
      "李广",
      "曾凯",
      "李庆",
      "陈旸"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109710789A",
    "apn": "CN201811623628.1",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "图像数据的检索方法、装置、电子设备及计算机存储介质",
    "inv": [
      "炊文伟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109829381A",
    "apn": "CN201811628347.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种犬只识别管理方法、装置、系统及存储介质",
    "inv": [
      "曾凯",
      "赵宇",
      "李庆",
      "陈旸",
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109829380A",
    "apn": "CN201811628345.6",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种狗脸特征点的检测方法、装置、系统及存储介质",
    "inv": [
      "李庆",
      "曾凯",
      "赵宇",
      "李广",
      "陈旸"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109858363A",
    "apn": "CN201811625868.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "一种狗鼻纹特征点的检测方法、装置、系统及存储介质",
    "inv": [
      "李庆",
      "曾凯",
      "赵宇",
      "李广",
      "陈旸"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109740499A",
    "apn": "CN201811622634.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "视频分割方法、视频动作识别方法、装置、设备及介质",
    "inv": [
      "卢江虎",
      "姚聪",
      "刘小龙",
      "孙宇超"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109711551A",
    "apn": "CN201811627454.6",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "数据处理方法及装置",
    "inv": [
      "王枫",
      "邵帅",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109815821A",
    "apn": "CN201811610324.1",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "一种人像牙齿修饰方法、装置、系统及存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109740491A",
    "apn": "CN201811611739.0",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "一种人眼视线识别方法、装置、系统及存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109657729A",
    "apn": "CN201811608178.9",
    "apd": "2018-12-26",
    "apy": "2018",
    "tit": "图像特征融合、特征图处理及姿态识别方法、装置及系统",
    "inv": [
      "彭琦翔",
      "王志成",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109711498A",
    "apn": "CN201811608180.6",
    "apd": "2018-12-26",
    "apy": "2018",
    "tit": "目标对象的行为预测方法、装置、处理设备及智能置物架",
    "inv": [
      "丁予春",
      "陈晨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109525786A",
    "apn": "CN201811605138.9",
    "apd": "2018-12-26",
    "apy": "2018",
    "tit": "视频处理方法、装置、终端设备及存储介质",
    "inv": [
      "詹肇楷"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109685015A",
    "apn": "CN201811596775.4",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "图像的处理方法、装置、电子设备和计算机存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109658360A",
    "apn": "CN201811599376.3",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "图像处理的方法、装置、电子设备和计算机存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109726756A",
    "apn": "CN201811599024.8",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "刘志康",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109685797A",
    "apn": "CN201811599022.9",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "骨骼点检测方法、装置、处理设备及存储介质",
    "inv": [
      "刘思遥"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109658481A",
    "apn": "CN201811584634.0",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "图像标注方法及装置、特征图生成方法及装置",
    "inv": [
      "李健昆",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109684991A",
    "apn": "CN201811568758.X",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "张志强",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109685204A",
    "apn": "CN201811584647.8",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "模型搜索方法及装置、图像处理方法及装置",
    "inv": [
      "郭梓超"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109670517A",
    "apn": "CN201811587447.8",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "目标检测方法、装置、电子设备和目标检测模型",
    "inv": [
      "马宁宁",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109670452A",
    "apn": "CN201811566552.3",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "人脸检测方法、装置、电子设备和人脸检测模型",
    "inv": [
      "李帮怀",
      "俞刚",
      "袁野"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109657627A",
    "apn": "CN201811578077.1",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "身份验证方法、装置及电子设备",
    "inv": [
      "王晖"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109658323A",
    "apn": "CN201811561328.5",
    "apd": "2018-12-19",
    "apy": "2018",
    "tit": "图像获取方法、装置、电子设备和计算机存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109670503A",
    "apn": "CN201811560244.X",
    "apd": "2018-12-19",
    "apy": "2018",
    "tit": "标识检测方法、装置和电子系统",
    "inv": [
      "邵帅",
      "史雨轩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109733978A",
    "apn": "CN201811566625.9",
    "apd": "2018-12-19",
    "apy": "2018",
    "tit": "自动门控制方法、装置、系统和存储介质",
    "inv": [
      "刘志康"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "电梯",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109685829A",
    "apn": "CN201811544931.2",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "基于图像的视线追踪方法、装置和电子设备",
    "inv": [
      "刘东昊",
      "罗堃铭",
      "赵姗",
      "刘帅成"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109671020A",
    "apn": "CN201811546051.9",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备和计算机存储介质",
    "inv": [
      "牛然森",
      "黄海斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109657678A",
    "apn": "CN201811547790.X",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "图像处理的方法、装置、电子设备和计算机存储介质",
    "inv": [
      "郑安林"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109635149A",
    "apn": "CN201811545375.0",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "人物搜索方法、装置及电子设备",
    "inv": [
      "炊文伟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109658331A",
    "apn": "CN201811540162.9",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "图像处理方法、装置、系统及计算机存储介质",
    "inv": [
      "刘思遥"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109658445A",
    "apn": "CN201811539138.3",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "网络训练方法、增量建图方法、定位方法、装置及设备",
    "inv": [
      "王金戈",
      "吴琅",
      "李北辰",
      "贺一家",
      "刘骁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109670438A",
    "apn": "CN201811533315.7",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "用于智能台灯的异常行为监控方法、装置、系统和存储介质",
    "inv": [
      "夏宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109583512A",
    "apn": "CN201811538967.X",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "图像处理方法、装置及系统",
    "inv": [
      "杨同"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109634703A",
    "apn": "CN201811526756.4",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "基于canvas标签的图像处理方法、装置、系统和存储介质",
    "inv": [
      "郭先越",
      "马兆海"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109670436A",
    "apn": "CN201811529056.0",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "车辆驾驶人员身份验证方法、装置及电子设备",
    "inv": [
      "夏宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109583509A",
    "apn": "CN201811523178.9",
    "apd": "2018-12-12",
    "apy": "2018",
    "tit": "数据生成方法、装置及电子设备",
    "inv": [
      "魏秀参",
      "宾言锐"
    ],
    "app": [
      "南京旷云科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109684951A",
    "apn": "CN201811522057.2",
    "apd": "2018-12-12",
    "apy": "2018",
    "tit": "人脸识别方法、底库录入方法、装置及电子设备",
    "inv": [
      "刘倩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109598251A",
    "apn": "CN201811509987.4",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "人证核验方法、装置、设备和系统及存储介质",
    "inv": [
      "王晖"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109618149A",
    "apn": "CN201811507490.9",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "设备成像质量的测试方法、装置和智能设备",
    "inv": [
      "孔沅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN109598250A",
    "apn": "CN201811506362.2",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "特征提取方法、装置、电子设备和计算机可读介质",
    "inv": [
      "杨一"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN109584153A",
    "apn": "CN201811491092.2",
    "apd": "2018-12-06",
    "apy": "2018",
    "tit": "修饰眼部的方法、装置和系统",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109670422A",
    "apn": "CN201811482469.8",
    "apd": "2018-12-05",
    "apy": "2018",
    "tit": "人脸检测信息显示方法、装置、设备及存储介质",
    "inv": [
      "李岩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109543635A",
    "apn": "CN201811443127.5",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "活体检测方法、装置、系统、解锁方法、终端及存储介质",
    "inv": [
      "张家康"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109522970A",
    "apn": "CN201811448812.7",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "图像分类方法、装置及系统",
    "inv": [
      "魏秀参",
      "宋恺涛"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109542230A",
    "apn": "CN201811440635.8",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "李广",
      "赵铠枫"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109543628A",
    "apn": "CN201811427783.6",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "一种人脸解锁、底库录入方法、装置和电子设备",
    "inv": [
      "任彦斌",
      "刘倩",
      "黄怡菲",
      "董浩",
      "丁岩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109584302A",
    "apn": "CN201811438130.8",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "相机位姿优化方法、装置、电子设备和计算机可读介质",
    "inv": [
      "史桀绮"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109584177A",
    "apn": "CN201811423815.5",
    "apd": "2018-11-26",
    "apy": "2018",
    "tit": "人脸修饰方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109284597A",
    "apn": "CN201811400961.6",
    "apd": "2018-11-22",
    "apy": "2018",
    "tit": "一种人脸解锁方法、装置、电子设备和计算机可读介质",
    "inv": [
      "刘倩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109544496A",
    "apn": "CN201811382077.4",
    "apd": "2018-11-19",
    "apy": "2018",
    "tit": "训练数据的生成方法、对象检测模型的训练方法和装置",
    "inv": [
      "金鑫",
      "魏秀参",
      "谢烟平",
      "赵博睿"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109543581A",
    "apn": "CN201811360714.8",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "图像处理方法、图像处理装置以及非易失性存储介质",
    "inv": [
      "任彦斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109509030A",
    "apn": "CN201811371743.4",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "销量预测方法及其模型的训练方法、装置和电子系统",
    "inv": [
      "楼虎彪",
      "樊聪",
      "杨越"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109657549A",
    "apn": "CN201811353182.5",
    "apd": "2018-11-14",
    "apy": "2018",
    "tit": "一种安全监测方法、装置、系统及计算机存储介质",
    "inv": [
      "司露露"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109448117A",
    "apn": "CN201811351512.7",
    "apd": "2018-11-13",
    "apy": "2018",
    "tit": "图像渲染方法、装置及电子设备",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109447031A",
    "apn": "CN201811342882.4",
    "apd": "2018-11-12",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及存储介质",
    "inv": [
      "刘思遥"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109274950A",
    "apn": "CN201811333022.4",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "王氚",
      "黄海斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109409962A",
    "apn": "CN201811328072.3",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备、计算机可读存储介质",
    "inv": [
      "李林泽",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109447025A",
    "apn": "CN201811328943.1",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "疲劳检测方法、装置、系统及计算机可读存储介质",
    "inv": [
      "谢建涛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109432779A",
    "apn": "CN201811327995.7",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "难度调整方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "李广",
      "赵铠枫"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109492576A",
    "apn": "CN201811321453.9",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "图像识别方法、装置及电子设备",
    "inv": [
      "熊峰",
      "张弘楷",
      "李伯勋",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109409319A",
    "apn": "CN201811323888.7",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "一种宠物图像美化方法、装置及其存储介质",
    "inv": [
      "曾凯",
      "赵宇",
      "李庆",
      "陈旸",
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109492755A",
    "apn": "CN201811318683.X",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "图像处理方法、图像处理装置和计算机可读存储介质",
    "inv": [
      "姚皓天",
      "吴家楠"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109274891A",
    "apn": "CN201811323983.7",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "一种图像处理方法、装置及其存储介质",
    "inv": [
      "曾凯",
      "赵宇",
      "李庆",
      "陈旸",
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109376791A",
    "apn": "CN201811309943.7",
    "apd": "2018-11-05",
    "apy": "2018",
    "tit": "深度算法精度计算方法、装置、电子设备、可读存储介质",
    "inv": [
      "吴雪平"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109493281A",
    "apn": "CN201811309942.2",
    "apd": "2018-11-05",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "王笑尘",
      "罗飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109447169A",
    "apn": "CN201811306459.9",
    "apd": "2018-11-02",
    "apy": "2018",
    "tit": "图像处理方法及其模型的训练方法、装置和电子系统",
    "inv": [
      "黎泽明",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109447006A",
    "apn": "CN201811297964.1",
    "apd": "2018-11-01",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及存储介质",
    "inv": [
      "郭一哲"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN109448090A",
    "apn": "CN201811298855.1",
    "apd": "2018-11-01",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "萧天孜",
      "王志成",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109167980A",
    "apn": "CN201811292637.7",
    "apd": "2018-11-01",
    "apy": "2018",
    "tit": "用于远距离高清晰度视频处理方法",
    "inv": [
      "刘艳伟"
    ],
    "app": [
      "苏州旷视智能科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109447000A",
    "apn": "CN201811288626.1",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "活体检测方法、污渍检测方法、电子设备及记录介质",
    "inv": [
      "张家康"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109211277A",
    "apn": "CN201811293867.5",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "视觉惯性里程计的状态确定方法、装置和电子设备",
    "inv": [
      "黄灿"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109271970A",
    "apn": "CN201811276843.9",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "人脸检测模型训练方法及装置",
    "inv": [
      "王剑锋",
      "李作新",
      "俞刚",
      "袁野"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109660509A",
    "apn": "CN201811267668.7",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "基于人脸识别的登录方法、装置、系统和存储介质",
    "inv": [
      "李岩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109376667A",
    "apn": "CN201811274871.7",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "目标检测方法、装置及电子设备",
    "inv": [
      "王远江",
      "袁野",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109145888A",
    "apn": "CN201811266654.3",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "人数统计方法、装置、系统、电子设备、存储介质",
    "inv": [
      "炊文伟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109214366A",
    "apn": "CN201811247895.3",
    "apd": "2018-10-24",
    "apy": "2018",
    "tit": "局部目标重识别方法、装置及系统",
    "inv": [
      "罗浩",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109410322A",
    "apn": "CN201811238655.7",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "三维对象建模方法、装置及电子设备",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109446364A",
    "apn": "CN201811237952.X",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "抓拍检索方法、图像处理方法、装置、设备及存储介质",
    "inv": [
      "炊文伟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109325548A",
    "apn": "CN201811245783.4",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "炊文伟"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109410163A",
    "apn": "CN201811236187.X",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "拍照推荐位置获取方法、装置、终端及计算机存储介质",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109447247A",
    "apn": "CN201811281755.8",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "获得卷积神经网络的方法及装置",
    "inv": [
      "郭梓超"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109167922A",
    "apn": "CN201811235036.2",
    "apd": "2018-10-22",
    "apy": "2018",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "任彦斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109447990A",
    "apn": "CN201811229270.4",
    "apd": "2018-10-22",
    "apy": "2018",
    "tit": "图像语义分割方法、装置、电子设备和计算机可读介质",
    "inv": [
      "郑安林"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109409277A",
    "apn": "CN201811220226.7",
    "apd": "2018-10-18",
    "apy": "2018",
    "tit": "手势识别方法、装置、智能终端及计算机存储介质",
    "inv": [
      "曹军",
      "谢建涛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109377551A",
    "apn": "CN201811207981.1",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "一种三维人脸重建方法、装置及其存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109410138A",
    "apn": "CN201811207299.2",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "修饰双下巴的方法、装置和系统",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109344789A",
    "apn": "CN201811202488.0",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "人脸跟踪方法及装置",
    "inv": [
      "鲍一平",
      "何琦",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109218619A",
    "apn": "CN201811194164.7",
    "apd": "2018-10-12",
    "apy": "2018",
    "tit": "图像获取方法、装置和系统",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109377445A",
    "apn": "CN201811195236.X",
    "apd": "2018-10-12",
    "apy": "2018",
    "tit": "模型训练方法、替换图像背景的方法、装置和电子系统",
    "inv": [
      "刘江宇",
      "范浩强",
      "蔡少凡"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109446942A",
    "apn": "CN201811195130.X",
    "apd": "2018-10-12",
    "apy": "2018",
    "tit": "目标跟踪方法、装置和系统",
    "inv": [
      "何琦",
      "鲍一平"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109409260A",
    "apn": "CN201811182522.2",
    "apd": "2018-10-10",
    "apy": "2018",
    "tit": "数据标注方法、装置、设备及存储介质",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109120854A",
    "apn": "CN201811176399.3",
    "apd": "2018-10-09",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109376618A",
    "apn": "CN201811156057.5",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "白雪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109360166A",
    "apn": "CN201811158834.X",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "一种图像处理方法、装置、电子设备和计算机可读介质",
    "inv": [
      "白雪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109389082A",
    "apn": "CN201811166331.7",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "视线采集方法、装置、系统、计算机可读存储介质",
    "inv": [
      "陈曦",
      "王塑"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109376743A",
    "apn": "CN201811145987.0",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "图像处理方法、装置、图像识别设备及储存介质",
    "inv": [
      "梁喆",
      "潘宇超",
      "张一山",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109242961A",
    "apn": "CN201811124137.2",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "一种脸部建模方法、装置、电子设备和计算机可读介质",
    "inv": [
      "乔梁",
      "陈可卿",
      "黄海斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109344757A",
    "apn": "CN201811116049.8",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "数据采集方法、装置及电子设备",
    "inv": [
      "王塑",
      "陈曦",
      "刘毅博"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109240504A",
    "apn": "CN201811116034.1",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "控制方法、模型训练方法、装置及电子设备",
    "inv": [
      "陈曦",
      "王塑"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108898567A",
    "apn": "CN201811104277.3",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "图像降噪方法、装置及系统",
    "inv": [
      "白雪",
      "李凯",
      "王珏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109242031A",
    "apn": "CN201811106206.7",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "姿势优化模型的训练方法、使用方法、装置及处理设备",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109353397A",
    "apn": "CN201811102674.7",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "商品管理方法、装置和系统及存储介质及购物车",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109353398A",
    "apn": "CN201811102672.8",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "商品识别方法、装置和系统及存储介质及购物车",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109325907A",
    "apn": "CN201811092473.3",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "图像美化处理方法、装置及系统",
    "inv": [
      "孙伟",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109242778A",
    "apn": "CN201811093013.2",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "一种图片标注控制方法、装置及其存储介质",
    "inv": [
      "乔非同",
      "李广",
      "刘致远",
      "龙灏天"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109325954A",
    "apn": "CN201811093012.8",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "图像分割方法、装置及电子设备",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109325437A",
    "apn": "CN201811085101.8",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "图像处理方法、装置和系统",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109325332A",
    "apn": "CN201811081524.2",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "人证核验方法、服务器、后台及系统",
    "inv": [
      "时彬斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109271027A",
    "apn": "CN201811080946.8",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "页面控制方法、装置及电子设备",
    "inv": [
      "乔非同",
      "李广",
      "刘致远",
      "龙灏天"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109242868A",
    "apn": "CN201811085280.5",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及储存介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109271935A",
    "apn": "CN201811084413.7",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "物品与电子标签的配对方法、装置及系统",
    "inv": [
      "娄超",
      "吕亚骏",
      "魏秀参"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109242025A",
    "apn": "CN201811079732.9",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "模型迭代修正方法、装置及系统",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109325945A",
    "apn": "CN201811071355.4",
    "apd": "2018-09-13",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及储存介质",
    "inv": [
      "李作新",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109298786A",
    "apn": "CN201811069243.5",
    "apd": "2018-09-13",
    "apy": "2018",
    "tit": "标注准确率评估方法及装置",
    "inv": [
      "乔非同",
      "李广",
      "刘致远",
      "龙灏天"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109035380A",
    "apn": "CN201811060479.2",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "基于三维重建的人脸修饰方法、装置、设备及存储介质",
    "inv": [
      "廖声洋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109145867A",
    "apn": "CN201811049731.X",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "人体姿态估计方法、装置、系统、电子设备、存储介质",
    "inv": [
      "刘瀛成",
      "林孟潇",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109255352A",
    "apn": "CN201811049034.4",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "目标检测方法、装置及系统",
    "inv": [
      "秦政",
      "黎泽明",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109284593A",
    "apn": "CN201811039736.4",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "身份验证方法、装置及电子设备",
    "inv": [
      "滕飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109285249A",
    "apn": "CN201811030338.6",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "一种人证核验系统及方法",
    "inv": [
      "丁敬峰",
      "宋灵建"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109298783A",
    "apn": "CN201811021970.4",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "基于表情识别的标注监控方法、装置及电子设备",
    "inv": [
      "龙灏天",
      "乔非同",
      "刘致远",
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109241907A",
    "apn": "CN201811024596.3",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "标注方法、装置及电子设备",
    "inv": [
      "刘致远",
      "乔非同",
      "龙灏天",
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109117824A",
    "apn": "CN201811019471.1",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "商品的管理方法、装置、电子设备及储存介质",
    "inv": [
      "白凯",
      "王凯",
      "刘晋宇",
      "李浩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109118454A",
    "apn": "CN201811009042.6",
    "apd": "2018-08-30",
    "apy": "2018",
    "tit": "图像数据处理方法、装置、系统、计算机可读存储介质",
    "inv": [
      "杜晓雷",
      "宋扬",
      "王爰",
      "余娴",
      "曹继邦",
      "刘瑞伟",
      "王晖",
      "炊文伟",
      "闫璐"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109241316A",
    "apn": "CN201811009043.0",
    "apd": "2018-08-30",
    "apy": "2018",
    "tit": "图像检索方法、装置、电子设备及存储介质",
    "inv": [
      "炊文伟",
      "宋扬",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109145844A",
    "apn": "CN201810999452.3",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "用于城市安防监控的档案管理方法、装置以及电子设备",
    "inv": [
      "江昊",
      "张华翼",
      "周而进"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109118292A",
    "apn": "CN201810999639.3",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "信息处理的方法、装置及设备",
    "inv": [
      "冯建威"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109241378A",
    "apn": "CN201811000898.7",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "档案建立方法、装置、设备及存储介质",
    "inv": [
      "江昊",
      "张华翼",
      "周而进"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN110019917A",
    "apn": "CN201810999636.X",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "商品检索方法、装置及电子设备",
    "inv": [
      "崔权",
      "谢烟平"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109241888A",
    "apn": "CN201810974385.X",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "神经网络训练与对象识别方法、装置和系统及存储介质",
    "inv": [
      "赵致辰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109241880A",
    "apn": "CN201810962141.X",
    "apd": "2018-08-22",
    "apy": "2018",
    "tit": "图像处理方法、图像处理装置、计算机可读存储介质",
    "inv": [
      "张诚",
      "黄鼎",
      "朱星宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109242789A",
    "apn": "CN201810954890.8",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "图像处理方法、图像处理装置及存储介质",
    "inv": [
      "刘帅成",
      "王珏",
      "白雪"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109087240A",
    "apn": "CN201810955579.5",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "图像处理方法、图像处理装置及存储介质",
    "inv": [
      "刘帅成",
      "王珏",
      "白雪"
    ],
    "app": [
      "成都旷视金智科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109191414A",
    "apn": "CN201810953624.3",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "一种图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "杨鹏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108965826A",
    "apn": "CN201810957851.3",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "监控方法、装置、处理设备及存储介质",
    "inv": [
      "杨一",
      "宋扬",
      "陈雪松"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109189676A",
    "apn": "CN201810952543.1",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "人脸解锁软件测试方法、装置及测试系统",
    "inv": [
      "李衍顺",
      "汪彧之"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109241877A",
    "apn": "CN201810953621.X",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "一种轨迹识别系统、方法、装置及其计算机存储介质",
    "inv": [
      "刘丹青"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109117888A",
    "apn": "CN201810951565.6",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "目标对象识别方法及其神经网络生成方法以及装置",
    "inv": [
      "廖星宇",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109299722A",
    "apn": "CN201810932282.7",
    "apd": "2018-08-16",
    "apy": "2018",
    "tit": "用于神经网络的特征图处理方法、装置和系统及存储介质",
    "inv": [
      "张祥雨",
      "马宁宁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN208476813U",
    "apn": "CN201821292101.0",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "工业零部件缺陷检测装置",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109117882A",
    "apn": "CN201810909891.0",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "获取用户轨迹的方法、装置、系统和存储介质",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108985263A",
    "apn": "CN201810896812.7",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "一种数据采集方法、装置、电子设备和计算机可读介质",
    "inv": [
      "张轩",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109063702A",
    "apn": "CN201810899533.6",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "车牌识别方法、装置、设备及存储介质",
    "inv": [
      "邵帅",
      "史雨轩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109002553A",
    "apn": "CN201810899150.9",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "头发模型的构建方法、装置、电子设备和计算机可读介质",
    "inv": [
      "孙伟",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109063776A",
    "apn": "CN201810893815.5",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "图像再识别网络训练方法、装置和图像再识别方法及装置",
    "inv": [
      "张弛",
      "张思朋",
      "金昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108985386A",
    "apn": "CN201810891677.7",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "获得图像处理模型的方法、图像处理方法及对应装置",
    "inv": [
      "穆皓远",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN109190484A",
    "apn": "CN201810888231.9",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "图像处理方法、装置和图像处理设备",
    "inv": [
      "曹志日",
      "杨沐",
      "陈可卿",
      "柳志贤",
      "高岩",
      "杨喆"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109117879A",
    "apn": "CN201810881947.6",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "图像分类方法、装置及系统",
    "inv": [
      "魏秀参",
      "王易木"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109147123A",
    "apn": "CN201810882570.6",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "门禁锁的解锁方法、装置、电子设备和计算机存储介质",
    "inv": [
      "孙伟",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109102331A",
    "apn": "CN201810882665.8",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "货物信息处理方法、服务器、系统、电子设备及存储介质",
    "inv": [
      "梁喆",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109034086A",
    "apn": "CN201810882627.2",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "车辆重识别方法、装置及系统",
    "inv": [
      "周啸辰",
      "金昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109101917A",
    "apn": "CN201810869954.4",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "用于行人重识别的标注方法、训练方法、装置及系统",
    "inv": [
      "张弛",
      "李嘉文"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109146969A",
    "apn": "CN201810869955.9",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "行人定位方法、装置及处理设备及其存储介质",
    "inv": [
      "张弛",
      "曹宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109040587A",
    "apn": "CN201810869953.X",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "抓拍处理方法、装置、抓拍机构、设备及存储介质",
    "inv": [
      "梁喆",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN109145777A",
    "apn": "CN201810868929.4",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "车辆重识别方法、装置及系统",
    "inv": [
      "周啸辰",
      "金昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109063768A",
    "apn": "CN201810860871.9",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "车辆重识别方法、装置及系统",
    "inv": [
      "周啸辰",
      "金昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109086873A",
    "apn": "CN201810870041.4",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "递归神经网络的训练方法、识别方法、装置及处理设备",
    "inv": [
      "张弛",
      "曹宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109145768A",
    "apn": "CN201810857091.9",
    "apd": "2018-07-31",
    "apy": "2018",
    "tit": "得到带人脸属性的人脸数据的方法及装置",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109117760A",
    "apn": "CN201810846412.5",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备和计算机可读介质",
    "inv": [
      "孙伟",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109145766A",
    "apn": "CN201810851349.4",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "模型训练方法、装置、识别方法、电子设备及存储介质",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108932660A",
    "apn": "CN201810837368.1",
    "apd": "2018-07-26",
    "apy": "2018",
    "tit": "一种商品使用效果模拟方法、装置及设备",
    "inv": [
      "顾泽琪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN109034055A",
    "apn": "CN201810820310.6",
    "apd": "2018-07-24",
    "apy": "2018",
    "tit": "肖像描绘方法、装置及电子设备",
    "inv": [
      "顾泽琪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109299646A",
    "apn": "CN201810817604.3",
    "apd": "2018-07-24",
    "apy": "2018",
    "tit": "人群异常事件检测方法、装置、系统和存储介质",
    "inv": [
      "徐子尧",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109101901A",
    "apn": "CN201810815078.7",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "人体动作识别及其神经网络生成方法、装置和电子设备",
    "inv": [
      "吴骞",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": 1,
    "apn": "CN201810815077.2",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "动作识别方法及其神经网络生成方法、装置和电子设备",
    "inv": [
      "张弛",
      "吴骞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109086705A",
    "apn": "CN201810817215.0",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及储存介质",
    "inv": [
      "张轩",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108961303A",
    "apn": "CN201810814632.X",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "一种图像处理方法、装置、电子设备和计算机可读介质",
    "inv": [
      "刘江宇",
      "王珏",
      "蔡少凡"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109101900A",
    "apn": "CN201810814684.7",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "一种对象分布信息的确定方法、装置和电子设备",
    "inv": [
      "刘丹青"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108960189A",
    "apn": "CN201810810555.0",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "图像再识别方法、装置及电子设备",
    "inv": [
      "魏秀参"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109299645A",
    "apn": "CN201810805465.2",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "用于视力保护提示的方法、装置、系统和存储介质",
    "inv": [
      "何益升"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109191802A",
    "apn": "CN201810805462.9",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "用于视力保护提示的方法、装置、系统和存储介质",
    "inv": [
      "何益升"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109063761A",
    "apn": "CN201810810454.3",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "扩散器脱落检测方法、装置及电子设备",
    "inv": [
      "黄海斌",
      "王珏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109086811A",
    "apn": "CN201810802045.9",
    "apd": "2018-07-19",
    "apy": "2018",
    "tit": "多标签图像分类方法、装置及电子设备",
    "inv": [
      "魏秀参",
      "陈钊民"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108985845A",
    "apn": "CN201810794848.4",
    "apd": "2018-07-18",
    "apy": "2018",
    "tit": "广告监控方法、装置及系统",
    "inv": [
      "万鑫",
      "龙俊洁",
      "李一杰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109241825A",
    "apn": "CN201810789325.0",
    "apd": "2018-07-18",
    "apy": "2018",
    "tit": "用于人群计数的数据集生成的方法及装置",
    "inv": [
      "张弛",
      "李嘉文"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN109146932A",
    "apn": "CN201810785868.5",
    "apd": "2018-07-17",
    "apy": "2018",
    "tit": "确定图像中目标点的世界坐标的方法、装置和系统",
    "inv": [
      "姚皓天",
      "吴家楠"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109146830A",
    "apn": "CN201810785130.9",
    "apd": "2018-07-17",
    "apy": "2018",
    "tit": "用于生成训练数据的方法、装置、系统和存储介质",
    "inv": [
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109034887A",
    "apn": "CN201810788752.7",
    "apd": "2018-07-17",
    "apy": "2018",
    "tit": "物品价格的调整方法、装置和系统",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109165992A",
    "apn": "CN201810779063.X",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "一种智能导购方法、装置、系统及计算机存储介质",
    "inv": [
      "梁喆",
      "周舒畅",
      "何蔚然",
      "朱雨",
      "李广"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109086690A",
    "apn": "CN201810776757.8",
    "apd": "2018-07-13",
    "apy": "2018",
    "tit": "图像特征提取方法、目标识别方法及对应装置",
    "inv": [
      "范星",
      "张轩",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108921876A",
    "apn": "CN201810753529.9",
    "apd": "2018-07-10",
    "apy": "2018",
    "tit": "视频处理方法、装置和系统及存储介质",
    "inv": [
      "周舒畅",
      "孙培钦"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN109033261A",
    "apn": "CN201810741616.2",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "图像处理方法、装置、处理设备及其存储介质",
    "inv": [
      "顾泽琪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN108961005A",
    "apn": "CN201810743489.X",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "信息推送方法、装置、电子设备及介质",
    "inv": [
      "白凯",
      "刘晋宇",
      "王凯",
      "李浩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109165666A",
    "apn": "CN201810735861.2",
    "apd": "2018-07-05",
    "apy": "2018",
    "tit": "多标签图像分类方法、装置、设备及存储介质",
    "inv": [
      "魏秀参",
      "刘威威"
    ],
    "app": [
      "南京旷云科技有限公司",
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108985443A",
    "apn": "CN201810728821.5",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "动作识别方法及其神经网络生成方法、装置和电子设备",
    "inv": [
      "张弛",
      "吴骞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875693A",
    "apn": "CN201810722616.8",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "一种图像处理方法、装置、电子设备及其存储介质",
    "inv": [
      "朱星宇",
      "黄鼎",
      "张诚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108898109A",
    "apn": "CN201810705659.5",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "物品关注度的确定方法、装置和系统",
    "inv": [
      "梁喆",
      "周舒畅",
      "张一山",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109084732A",
    "apn": "CN201810712784.9",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "定位与导航方法、装置及处理设备",
    "inv": [
      "卢泽",
      "刘骁",
      "童哲航"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN108898104A",
    "apn": "CN201810699572.1",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "一种物品识别方法、装置、系统及计算机存储介质",
    "inv": [
      "徐子尧",
      "李云锴"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876249A",
    "apn": "CN201810712759.0",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "物品监控系统及方法",
    "inv": [
      "张宏毅",
      "郑鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875676A",
    "apn": "CN201810695586.6",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "活体检测方法、装置及系统",
    "inv": [
      "卢江虎",
      "王鹏",
      "姚聪",
      "孙亚昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875688A",
    "apn": "CN201810706117.X",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "一种活体检测方法、装置、系统及存储介质",
    "inv": [
      "刘海敏",
      "龙俊洁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108921898A",
    "apn": "CN201810686678.8",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "摄像机位姿确定方法、装置、电子设备和计算机可读介质",
    "inv": [
      "朱尊杰",
      "刘骁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108921080A",
    "apn": "CN201810681293.2",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "图像识别方法、装置及电子设备",
    "inv": [
      "何益升"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109117714A",
    "apn": "CN201810682283.0",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "一种同行人员识别方法、装置、系统及计算机存储介质",
    "inv": [
      "炊文伟",
      "宋扬",
      "付英波"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875837A",
    "apn": "CN201810681815.9",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "用于测试目标识别设备的装置及方法",
    "inv": [
      "何国勋",
      "宋扬",
      "曹志敏",
      "陈雪松"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108932420A",
    "apn": "CN201810670311.7",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "人证核验装置、方法和系统以及证件解密装置和方法",
    "inv": [
      "何国勋",
      "宋扬",
      "曹志敏",
      "付英波"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109033973A",
    "apn": "CN201810675775.7",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "监控预警方法、装置及电子设备",
    "inv": [
      "杨一",
      "宋扬",
      "陈雪松"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108845574A",
    "apn": "CN201810675774.2",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "目标识别与追踪方法、装置、设备及介质",
    "inv": [
      "赵仲夏",
      "彭广平",
      "陶涛"
    ],
    "app": [
      "北京旷视机器人技术有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108960314A",
    "apn": "CN201810675642.X",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "基于难样本的训练方法、装置及电子设备",
    "inv": [
      "朱星宇",
      "黄鼎",
      "张诚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875835A",
    "apn": "CN201810668974.5",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "对象落脚点确定方法、装置、电子设备和计算机可读介质",
    "inv": [
      "炊文伟",
      "宋扬",
      "付英波"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108830251A",
    "apn": "CN201810667415.2",
    "apd": "2018-06-25",
    "apy": "2018",
    "tit": "信息关联方法、装置和系统",
    "inv": [
      "李云锴"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN109092719A",
    "apn": "CN201810663800.X",
    "apd": "2018-06-25",
    "apy": "2018",
    "tit": "用于货物分拣的人机交互系统",
    "inv": [
      "彭广平"
    ],
    "app": [
      "北京旷视机器人技术有限公司",
      "北京旷视科技有限公司"
    ],
    "l1": "物流",
    "l2": "无人配送",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108921070A",
    "apn": "CN201810654977.3",
    "apd": "2018-06-22",
    "apy": "2018",
    "tit": "图像处理方法、模型训练方法及对应装置",
    "inv": [
      "何益升"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108806153A",
    "apn": "CN201810647838.8",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "警情处理方法、装置及系统",
    "inv": [
      "闫璐",
      "宋扬",
      "那正平",
      "付英波"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875633A",
    "apn": "CN201810629426.1",
    "apd": "2018-06-19",
    "apy": "2018",
    "tit": "表情检测与表情驱动方法、装置和系统及存储介质",
    "inv": [
      "孙伟",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108810527A",
    "apn": "CN201810610595.0",
    "apd": "2018-06-13",
    "apy": "2018",
    "tit": "散射器异常检测方法、装置及电子设备",
    "inv": [
      "黄海斌",
      "王珏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108737750A",
    "apn": "CN201810583369.8",
    "apd": "2018-06-07",
    "apy": "2018",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "黄海斌",
      "巫奇豪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108765537A",
    "apn": "CN201810566077.3",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "一种图像的处理方法、装置、电子设备和计算机可读介质",
    "inv": [
      "乔梁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108875934A",
    "apn": "CN201810525605.0",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "一种神经网络的训练方法、装置、系统及存储介质",
    "inv": [
      "黄鼎",
      "张诚",
      "朱星宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108805047A",
    "apn": "CN201810517525.0",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "一种活体检测方法、装置、电子设备和计算机可读介质",
    "inv": [
      "王鹏",
      "卢江虎"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108764133A",
    "apn": "CN201810521462.6",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "图像识别方法、装置及系统",
    "inv": [
      "陈文科",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875341A",
    "apn": "CN201810510589.8",
    "apd": "2018-05-24",
    "apy": "2018",
    "tit": "一种人脸解锁方法、装置、系统及计算机存储介质",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876764A",
    "apn": "CN201810490256.3",
    "apd": "2018-05-21",
    "apy": "2018",
    "tit": "渲染图像获取方法、装置、系统和存储介质",
    "inv": [
      "马里千"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875746A",
    "apn": "CN201810472844.4",
    "apd": "2018-05-17",
    "apy": "2018",
    "tit": "一种车牌识别方法、装置、系统及存储介质",
    "inv": [
      "周舒畅",
      "马灵威"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108710847A",
    "apn": "CN201810465129.8",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "场景识别方法、装置及电子设备",
    "inv": [
      "张诚",
      "黄鼎",
      "朱星宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875574A",
    "apn": "CN201810450115.9",
    "apd": "2018-05-11",
    "apy": "2018",
    "tit": "行人检测误报结果的检测方法、装置、系统和存储介质",
    "inv": [
      "彭超",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108629325A",
    "apn": "CN201810453740.9",
    "apd": "2018-05-11",
    "apy": "2018",
    "tit": "物品位置的确定方法、装置及系统",
    "inv": [
      "刘丹青"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108694401A",
    "apn": "CN201810440205.X",
    "apd": "2018-05-09",
    "apy": "2018",
    "tit": "目标检测方法、装置及系统",
    "inv": [
      "杨同",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875778A",
    "apn": "CN201810421711.4",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "人脸聚类方法、装置、系统和存储介质",
    "inv": [
      "谢建涛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875338A",
    "apn": "CN201810418988.1",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "解锁方法、装置和系统及存储介质",
    "inv": [
      "孔沅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN208143390U",
    "apn": "CN201820640215.3",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "一种人脸抓拍机和系统",
    "inv": [
      "梁喆",
      "朱雨",
      "曹宇辉"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108596122A",
    "apn": "CN201810399171.4",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "一种身份验证方法、装置、身份验证机和计算机可读介质",
    "inv": [
      "郭志鹏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108596735A",
    "apn": "CN201810408782.0",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "信息推送方法、装置及系统",
    "inv": [
      "白凯",
      "王凯",
      "刘晋宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108596127A",
    "apn": "CN201810402303.4",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "一种指纹识别方法、身份验证方法及装置和身份核验机",
    "inv": [
      "郭志鹏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875556A",
    "apn": "CN201810381537.5",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "用于人证核验的方法、装置、系统及计算机存储介质",
    "inv": [
      "滕飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875553A",
    "apn": "CN201810379930.0",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "人证核验的方法、装置、系统及计算机存储介质",
    "inv": [
      "滕飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875549A",
    "apn": "CN201810362486.1",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "图像识别方法、装置、系统及计算机存储介质",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876896A",
    "apn": "CN201810339223.9",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "参数化人脸模型生成方法、装置、系统和存储介质",
    "inv": [
      "马里千"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN108875546A",
    "apn": "CN201810333445.X",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "人脸身份验证方法、系统及存储介质",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875545A",
    "apn": "CN201810326164.1",
    "apd": "2018-04-12",
    "apy": "2018",
    "tit": "确定人脸图像的光线状态的方法、装置、系统和存储介质",
    "inv": [
      "张华翼"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN108875544A",
    "apn": "CN201810322256.2",
    "apd": "2018-04-11",
    "apy": "2018",
    "tit": "人脸识别方法、装置、系统和存储介质",
    "inv": [
      "赵致辰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876976A",
    "apn": "CN201810321484.8",
    "apd": "2018-04-11",
    "apy": "2018",
    "tit": "卡片识别装置、图像采集装置、抓盗卡系统和方法",
    "inv": [
      "朱雨",
      "丁敬峰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875542A",
    "apn": "CN201810302067.9",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "一种人脸识别方法、装置、系统及计算机存储介质",
    "inv": [
      "张华翼"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108520229A",
    "apn": "CN201810299489.5",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "图像检测方法、装置、电子设备和计算机可读介质",
    "inv": [
      "王剑锋",
      "袁野",
      "李伯勋",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108876833A",
    "apn": "CN201810289413.4",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "图像处理方法、图像处理装置和计算机可读存储介质",
    "inv": [
      "黄海斌"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN108876836A",
    "apn": "CN201810289533.4",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "一种深度估计方法、装置、系统及计算机可读存储介质",
    "inv": [
      "李凯"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN108876835A",
    "apn": "CN201810262054.3",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "深度信息检测方法、装置和系统及存储介质",
    "inv": [
      "梁喆",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108334869A",
    "apn": "CN201810235595.7",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "人脸部件的选择、人脸识别方法和装置，以及电子设备",
    "inv": [
      "李栋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875540A",
    "apn": "CN201810202079.4",
    "apd": "2018-03-12",
    "apy": "2018",
    "tit": "图像处理方法、装置和系统及存储介质",
    "inv": [
      "周舒畅",
      "何蔚然",
      "杨文昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875539A",
    "apn": "CN201810192778.5",
    "apd": "2018-03-09",
    "apy": "2018",
    "tit": "表情匹配方法、装置和系统及存储介质",
    "inv": [
      "马里千"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN108304829A",
    "apn": "CN201810192792.5",
    "apd": "2018-03-08",
    "apy": "2018",
    "tit": "人脸识别方法、装置及系统",
    "inv": [
      "李栋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875537A",
    "apn": "CN201810166037.X",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "对象检测方法、装置和系统及存储介质",
    "inv": [
      "周舒畅",
      "杨弋",
      "孙培钦"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875932A",
    "apn": "CN201810161994.3",
    "apd": "2018-02-27",
    "apy": "2018",
    "tit": "图像识别方法、装置和系统及存储介质",
    "inv": [
      "周舒畅",
      "何蔚然",
      "谢广增"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108268869A",
    "apn": "CN201810151381.1",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "目标检测方法、装置及系统",
    "inv": [
      "梁喆",
      "曹宇辉",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108364267B",
    "apn": "CN201810151206.2",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "图像处理方法、装置及设备",
    "inv": [
      "周舒畅",
      "何蔚然",
      "倪成卓"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108288252A",
    "apn": "CN201810151216.6",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "图像批处理方法、装置及电子设备",
    "inv": [
      "梁喆",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108289177A",
    "apn": "CN201810151344.0",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "信息交互方法、装置及系统",
    "inv": [
      "周舒畅",
      "梁喆",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108229672A",
    "apn": "CN201810150568.X",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "一种深度神经网络及其处理方法、装置和设备",
    "inv": [
      "周舒畅",
      "梁喆",
      "杨弋"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108364061A",
    "apn": "CN201810151426.5",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "运算装置、运算执行设备及运算执行方法",
    "inv": [
      "周舒畅",
      "胡晨",
      "梁喆"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875535A",
    "apn": "CN201810118765.3",
    "apd": "2018-02-06",
    "apy": "2018",
    "tit": "图像检测方法、装置和系统及存储介质",
    "inv": [
      "周舒畅",
      "杨弋",
      "马灵威"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875534A",
    "apn": "CN201810111602.2",
    "apd": "2018-02-05",
    "apy": "2018",
    "tit": "人脸识别的方法、装置、系统及计算机存储介质",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875533A",
    "apn": "CN201810084836.2",
    "apd": "2018-01-29",
    "apy": "2018",
    "tit": "人脸识别的方法、装置、系统及计算机存储介质",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875530A",
    "apn": "CN201810031372.9",
    "apd": "2018-01-12",
    "apy": "2018",
    "tit": "活体识别方法、活体识别设备、电子设备以及存储介质",
    "inv": [
      "蔺皓宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875529A",
    "apn": "CN201810027958.8",
    "apd": "2018-01-11",
    "apy": "2018",
    "tit": "人脸空间定位方法、装置、系统及计算机存储介质",
    "inv": [
      "潘宇超",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875732A",
    "apn": "CN201810025313.0",
    "apd": "2018-01-11",
    "apy": "2018",
    "tit": "模型训练与实例分割方法、装置和系统及存储介质",
    "inv": [
      "程大治",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108197279A",
    "apn": "CN201810021425.9",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "攻击数据生成方法、装置、系统及计算机可读存储介质",
    "inv": [
      "蔺皓宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875528A",
    "apn": "CN201810016603.9",
    "apd": "2018-01-08",
    "apy": "2018",
    "tit": "一种人脸形状点定位方法及装置、存储介质",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875526A",
    "apn": "CN201810011466.X",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "视线检测的方法、装置、系统及计算机存储介质",
    "inv": [
      "李思宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875527A",
    "apn": "CN201810012112.7",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "行人识别系统、方法和监控系统",
    "inv": [
      "丁敬峰",
      "付运旭",
      "宋灵建",
      "余振兴"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875525A",
    "apn": "CN201810008835.X",
    "apd": "2018-01-04",
    "apy": "2018",
    "tit": "行为预测方法、装置、系统和存储介质",
    "inv": [
      "朱延俊",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875723A",
    "apn": "CN201810005939.5",
    "apd": "2018-01-03",
    "apy": "2018",
    "tit": "对象检测方法、装置和系统及存储介质",
    "inv": [
      "吕鹏原",
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875524A",
    "apn": "CN201810001860.5",
    "apd": "2018-01-02",
    "apy": "2018",
    "tit": "视线估计方法、装置、系统和存储介质",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108229474B",
    "apn": "CN201711499190.6",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "车牌识别方法、装置及电子设备",
    "inv": [
      "江振升"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875523A",
    "apn": "CN201711462845.2",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "人体关节点检测方法、装置、系统和存储介质",
    "inv": [
      "王志成",
      "陈逸伦",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108881710A",
    "apn": "CN201711457411.3",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "图像处理方法、装置和系统及存储介质",
    "inv": [
      "张辉"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875731A",
    "apn": "CN201711457414.7",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "目标识别方法、装置、系统及存储介质",
    "inv": [
      "刘毅博",
      "李一杰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876428A",
    "apn": "CN201711445769.4",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "线下广告投放效果的计算方法和装置",
    "inv": [
      "白凯",
      "王凯"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108124157A",
    "apn": "CN201711416505.6",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "信息交互方法、装置及系统",
    "inv": [
      "梁喆",
      "周舒畅",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875522A",
    "apn": "CN201711389683.4",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "人脸聚类方法、装置和系统及存储介质",
    "inv": [
      "杜航宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN108881896A",
    "apn": "CN201711394690.3",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "抓拍机的测试方法、系统以及存储介质",
    "inv": [
      "朱雨",
      "梁喆"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875520A",
    "apn": "CN201711386408.7",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "人脸形状点定位的方法、装置、系统及计算机存储介质",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875521A",
    "apn": "CN201711387654.4",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "人脸检测方法、装置、系统和存储介质",
    "inv": [
      "王剑锋",
      "袁野",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875519A",
    "apn": "CN201711372437.8",
    "apd": "2017-12-19",
    "apy": "2017",
    "tit": "对象检测方法、装置和系统及存储介质",
    "inv": [
      "梁喆"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875518A",
    "apn": "CN201711350087.5",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "图像处理与图像分类方法、装置和系统及存储介质",
    "inv": [
      "梁喆"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875517A",
    "apn": "CN201711349363.6",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "视频处理方法、装置和系统及存储介质",
    "inv": [
      "梁喆",
      "王天树",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN108876893A",
    "apn": "CN201711338060.4",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "三维人脸重建的方法、装置、系统及计算机存储介质",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN108875516A",
    "apn": "CN201711334155.9",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "人脸识别方法、装置、系统、存储介质和电子设备",
    "inv": [
      "叶赛尔"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876726A",
    "apn": "CN201711319056.3",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "图像处理的方法、装置、系统及计算机存储介质",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875515A",
    "apn": "CN201711309593.X",
    "apd": "2017-12-11",
    "apy": "2017",
    "tit": "人脸识别方法、装置、系统、存储介质和抓拍机",
    "inv": [
      "梁喆",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN108875514A",
    "apn": "CN201711294961.8",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "人脸认证方法和系统、以及认证设备和非易失性存储介质",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN107944420A",
    "apn": "CN201711290312.0",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "人脸图像的光照处理方法和装置",
    "inv": [
      "马里千"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875513A",
    "apn": "CN201711288090.9",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "图像对齐方法和装置、计算机可读存储介质",
    "inv": [
      "周而进"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875767A",
    "apn": "CN201711286091.X",
    "apd": "2017-12-07",
    "apy": "2017",
    "tit": "图像识别的方法、装置、系统及计算机存储介质",
    "inv": [
      "范星",
      "张轩"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875931A",
    "apn": "CN201711283718.6",
    "apd": "2017-12-06",
    "apy": "2017",
    "tit": "神经网络训练及图像处理方法、装置、系统",
    "inv": [
      "赵子健",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875512A",
    "apn": "CN201711269206.4",
    "apd": "2017-12-05",
    "apy": "2017",
    "tit": "人脸识别方法、装置、系统、存储介质和电子设备",
    "inv": [
      "叶赛尔"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875902A",
    "apn": "CN201711262814.2",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "神经网络训练方法及装置、车辆检测估计方法及装置、存储介质",
    "inv": [
      "王曼晨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107977628A",
    "apn": "CN201711257555.4",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "神经网络训练方法、人脸检测方法及人脸检测装置",
    "inv": [
      "袁野",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108881142A",
    "apn": "CN201711249167.1",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "用户身份验证方法、装置及车载系统",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107992739A",
    "apn": "CN201711248655.0",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "用户验证方法、装置及系统",
    "inv": [
      "司露露"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876847A",
    "apn": "CN201711243514.X",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "图像定位方法、装置、系统和存储介质",
    "inv": [
      "程大治",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876853A",
    "apn": "CN201711239597.5",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "图像定位方法、装置、系统和存储介质",
    "inv": [
      "程大治",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876759A",
    "apn": "CN201711239598.X",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "图像探测方法、装置、系统和存储介质",
    "inv": [
      "程大治",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875766A",
    "apn": "CN201711230536.2",
    "apd": "2017-11-29",
    "apy": "2017",
    "tit": "图像处理的方法、装置、系统及计算机存储介质",
    "inv": [
      "史桀绮"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108881707A",
    "apn": "CN201711219043.9",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "图像生成方法、装置、系统和存储介质",
    "inv": [
      "赵致辰"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875510A",
    "apn": "CN201711217793.2",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "图像处理的方法、装置、系统及计算机存储介质",
    "inv": [
      "张思朋",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107886330A",
    "apn": "CN201711220826.9",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "结算方法、装置及系统",
    "inv": [
      "丁予春",
      "刘丹青",
      "郑鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107895181A",
    "apn": "CN201711220617.4",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "电子标签的控制方法、装置及系统",
    "inv": [
      "丁予春",
      "刘丹青",
      "郑鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107918767B",
    "apn": "CN201711203671.8",
    "apd": "2017-11-27",
    "apy": "2017",
    "tit": "目标检测方法、装置、电子设备及计算机可读介质",
    "inv": [
      "徐子尧",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107909443A",
    "apn": "CN201711212912.5",
    "apd": "2017-11-27",
    "apy": "2017",
    "tit": "信息推送方法、装置及系统",
    "inv": [
      "白凯",
      "王凯"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875336A",
    "apn": "CN201711189527.3",
    "apd": "2017-11-24",
    "apy": "2017",
    "tit": "人脸认证以及录入人脸的方法、认证设备和系统",
    "inv": [
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN108875508A",
    "apn": "CN201711179923.8",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "活体检测算法更新方法、装置、客户端、服务器及系统",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876705A",
    "apn": "CN201711184257.7",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "图像合成的方法、装置及计算机存储介质",
    "inv": [
      "孙亚昊"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108876718A",
    "apn": "CN201711182841.9",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "图像融合的方法、装置及计算机存储介质",
    "inv": [
      "孙亚昊",
      "刘江宇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875509A",
    "apn": "CN201711185030.4",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "活体检测方法、装置和系统及存储介质",
    "inv": [
      "李弢"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875507A",
    "apn": "CN201711175162.9",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "行人跟踪方法、设备、系统和计算机可读存储介质",
    "inv": [
      "吴家楠",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107886079A",
    "apn": "CN201711177901.8",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "对象识别方法、装置及系统",
    "inv": [
      "宋晨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107944382B",
    "apn": "CN201711161387.9",
    "apd": "2017-11-20",
    "apy": "2017",
    "tit": "目标跟踪方法、装置及电子设备",
    "inv": [
      "李云锴"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875901A",
    "apn": "CN201711161464.0",
    "apd": "2017-11-20",
    "apy": "2017",
    "tit": "神经网络训练方法以及通用物体检测方法、装置和系统",
    "inv": [
      "肖特特"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875506A",
    "apn": "CN201711146381.4",
    "apd": "2017-11-17",
    "apy": "2017",
    "tit": "人脸形状点跟踪方法、装置和系统及存储介质",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875505A",
    "apn": "CN201711124587.7",
    "apd": "2017-11-14",
    "apy": "2017",
    "tit": "基于神经网络的行人再识别方法和装置",
    "inv": [
      "肖琦琦",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875765A",
    "apn": "CN201711135279.4",
    "apd": "2017-11-14",
    "apy": "2017",
    "tit": "扩充数据集的方法、装置、设备及计算机存储介质",
    "inv": [
      "张思朋"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875504A",
    "apn": "CN201711107369.2",
    "apd": "2017-11-10",
    "apy": "2017",
    "tit": "基于神经网络的图像检测方法和图像检测装置",
    "inv": [
      "林孟潇",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108875503A",
    "apn": "CN201711100139.3",
    "apd": "2017-11-09",
    "apy": "2017",
    "tit": "人脸检测方法、装置、系统、存储介质和抓拍机",
    "inv": [
      "梁喆",
      "周舒畅",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875502A",
    "apn": "CN201711089314.3",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "人脸识别方法和装置",
    "inv": [
      "周而进",
      "刘宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875499A",
    "apn": "CN201711078347.8",
    "apd": "2017-11-06",
    "apy": "2017",
    "tit": "人脸形状点和状态属性检测及增强现实方法和装置",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875501A",
    "apn": "CN201711080123.0",
    "apd": "2017-11-06",
    "apy": "2017",
    "tit": "人体属性识别方法、装置、系统及存储介质",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107766865A",
    "apn": "CN201711082008.7",
    "apd": "2017-11-06",
    "apy": "2017",
    "tit": "池化方法、物体检测方法、装置、系统及计算机可读介质",
    "inv": [
      "王志成",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875500A",
    "apn": "CN201711079102.7",
    "apd": "2017-11-06",
    "apy": "2017",
    "tit": "行人再识别方法、装置、系统及存储介质",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876864A",
    "apn": "CN201711075117.6",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "图像编码、解码方法、装置、电子设备及计算机可读介质",
    "inv": [
      "许展玮"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875498A",
    "apn": "CN201711073056.X",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "用于行人重识别的方法、装置及计算机存储介质",
    "inv": [
      "徐子扬",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107798685A",
    "apn": "CN201711076135.6",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "行人身高确定方法、装置及系统",
    "inv": [
      "张弛",
      "姚皓天"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875751A",
    "apn": "CN201711064141.X",
    "apd": "2017-11-02",
    "apy": "2017",
    "tit": "图像处理方法和装置、神经网络的训练方法、存储介质",
    "inv": [
      "彭超",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875900A",
    "apn": "CN201711063311.2",
    "apd": "2017-11-02",
    "apy": "2017",
    "tit": "视频图像处理方法和装置、神经网络训练方法、存储介质",
    "inv": [
      "彭超",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108876812A",
    "apn": "CN201711058846.0",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "用于视频中物体检测的图像处理方法、装置及设备",
    "inv": [
      "赵子健",
      "俞刚",
      "黎泽明"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876813A",
    "apn": "CN201711059848.1",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "用于视频中物体检测的图像处理方法、装置及设备",
    "inv": [
      "赵子健",
      "俞刚",
      "黎泽明"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107862814A",
    "apn": "CN201711060946.7",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "自动结算系统及自动结算方法",
    "inv": [
      "丁予春",
      "刘丹青",
      "郑鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875497A",
    "apn": "CN201711025141.9",
    "apd": "2017-10-27",
    "apy": "2017",
    "tit": "活体检测的方法、装置及计算机存储介质",
    "inv": [
      "孙伟",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107704890A",
    "apn": "CN201711057015.1",
    "apd": "2017-10-27",
    "apy": "2017",
    "tit": "一种四元组图像的生成方法和装置",
    "inv": [
      "孙逸潇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107784282A",
    "apn": "CN201711001804.3",
    "apd": "2017-10-24",
    "apy": "2017",
    "tit": "对象属性的识别方法、装置及系统",
    "inv": [
      "石娟峰",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107784281B",
    "apn": "CN201710998845.8",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "人脸检测方法、装置、设备及计算机可读介质",
    "inv": [
      "周舒畅",
      "梁喆",
      "黄哲威",
      "潘宇超"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875335A",
    "apn": "CN201710993345.5",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "人脸解锁与录入表情和表情动作的方法、以及认证设备和非易失性存储介质",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876791A",
    "apn": "CN201710992110.4",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "图像处理方法、装置和系统及存储介质",
    "inv": [
      "彭超",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108875496A",
    "apn": "CN201710986141.9",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "行人画像的生成及基于画像的行人识别",
    "inv": [
      "张弛",
      "石娟峰"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107704838A",
    "apn": "CN201710983033.6",
    "apd": "2017-10-19",
    "apy": "2017",
    "tit": "目标对象的属性识别方法及装置",
    "inv": [
      "肖琦琦",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107578453B",
    "apn": "CN201710974261.7",
    "apd": "2017-10-18",
    "apy": "2017",
    "tit": "压缩图像处理方法、装置、电子设备及计算机可读介质",
    "inv": [
      "周舒畅",
      "邹雨恒"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875494A",
    "apn": "CN201710964639.5",
    "apd": "2017-10-17",
    "apy": "2017",
    "tit": "视频结构化方法、装置、系统及存储介质",
    "inv": [
      "张弛",
      "徐子尧"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107786549B",
    "apn": "CN201710958076.9",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "音频文件的添加方法、装置、系统及计算机可读介质",
    "inv": [
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107644455A",
    "apn": "CN201710949855.2",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "人脸图像合成方法和装置",
    "inv": [
      "马里千",
      "高端"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875493A",
    "apn": "CN201710947714.7",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "人脸识别中相似度阈值的确定方法以及确定装置",
    "inv": [
      "唐康祺"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108881816A",
    "apn": "CN201710947712.8",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "录像文件的生成方法、装置及计算机存储介质",
    "inv": [
      "李晓明"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN108876804A",
    "apn": "CN201710947711.3",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "抠像模型训练和图像抠像方法、装置和系统及存储介质",
    "inv": [
      "彭超",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN109664889A",
    "apn": "CN201710948405.1",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "一种车辆控制方法、装置和系统以及存储介质",
    "inv": [
      "蓝静"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107679578A",
    "apn": "CN201710950017.7",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "目标识别算法的测试方法、装置及系统",
    "inv": [
      "王婷",
      "杜宇飞"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875491A",
    "apn": "CN201710942203.6",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "人脸解锁认证的数据更新方法、认证设备和系统以及非易失性存储介质",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875492A",
    "apn": "CN201710943217.X",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "人脸检测及关键点定位方法、装置、系统和存储介质",
    "inv": [
      "俞刚",
      "袁野"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875489A",
    "apn": "CN201710915035.1",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "人脸检测方法、装置、系统、存储介质和抓拍机",
    "inv": [
      "梁喆",
      "周舒畅",
      "张宇翔",
      "曹宇辉",
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875488A",
    "apn": "CN201710910866.X",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "对象跟踪方法、对象跟踪装置以及计算机可读存储介质",
    "inv": [
      "肖泰洪",
      "周舒畅",
      "潘宇超"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875487A",
    "apn": "CN201710906719.5",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "行人重识别网络的训练及基于其的行人重识别",
    "inv": [
      "罗浩",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875486A",
    "apn": "CN201710898022.8",
    "apd": "2017-09-28",
    "apy": "2017",
    "tit": "目标对象识别方法、装置、系统和计算机可读介质",
    "inv": [
      "林浩彬"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875333A",
    "apn": "CN201710865682.6",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "终端解锁方法、终端和计算机可读存储介质",
    "inv": [
      "黄怡菲"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875334A",
    "apn": "CN201710866590.X",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "人脸解锁方法、人脸解锁终端以及非易失性存储介质",
    "inv": [
      "黄怡菲"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875484A",
    "apn": "CN201710865672.2",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "用于移动终端的人脸解锁方法、装置和系统及存储介质",
    "inv": [
      "刘宇",
      "周而进"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN108875485A",
    "apn": "CN201710867203.4",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "一种底图录入方法、装置及系统",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN108875483A",
    "apn": "CN201710855131.1",
    "apd": "2017-09-20",
    "apy": "2017",
    "tit": "图像检测方法、装置、系统和计算机可读介质",
    "inv": [
      "肖特特"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876790A",
    "apn": "CN201710827797.6",
    "apd": "2017-09-14",
    "apy": "2017",
    "tit": "图像语义分割方法和装置、神经网络训练方法和装置",
    "inv": [
      "邹雨恒",
      "周舒畅",
      "周昕宇",
      "冯迭乔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108875482A",
    "apn": "CN201710827779.8",
    "apd": "2017-09-14",
    "apy": "2017",
    "tit": "物体检测方法和装置、神经网络训练方法和装置",
    "inv": [
      "何蔚然",
      "周舒畅",
      "冯迭乔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876504A",
    "apn": "CN201710817042.8",
    "apd": "2017-09-12",
    "apy": "2017",
    "tit": "一种无人售卖系统及其控制方法",
    "inv": [
      "宋晨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875481A",
    "apn": "CN201710770887.6",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "用于行人检测的方法、装置、系统及存储介质",
    "inv": [
      "林孟潇",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107389537A",
    "apn": "CN201710770303.5",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "一种新型多通道细胞计数仪及多通道细胞计数系统",
    "inv": [
      "许金泉"
    ],
    "app": [
      "嘉兴旷视医疗科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN207133167U",
    "apn": "CN201721125910.8",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "一种新型多通道细胞计数仪及多通道细胞计数系统",
    "inv": [
      "许金泉"
    ],
    "app": [
      "嘉兴旷视医疗科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875750A",
    "apn": "CN201710740825.0",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "物体检测方法、装置和系统及存储介质",
    "inv": [
      "王志成",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876758A",
    "apn": "CN201710700742.9",
    "apd": "2017-08-15",
    "apy": "2017",
    "tit": "人脸识别方法、装置及系统",
    "inv": [
      "唐康祺"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875479A",
    "apn": "CN201710697492.8",
    "apd": "2017-08-15",
    "apy": "2017",
    "tit": "人脸图像的获取方法及装置",
    "inv": [
      "袁野",
      "俞刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875480A",
    "apn": "CN201710698369.8",
    "apd": "2017-08-15",
    "apy": "2017",
    "tit": "一种人脸特征信息的追踪方法、装置及系统",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875478A",
    "apn": "CN201710692598.9",
    "apd": "2017-08-14",
    "apy": "2017",
    "tit": "人证合一核验方法、装置和系统及存储介质",
    "inv": [
      "叶赛尔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875477A",
    "apn": "CN201710692553.1",
    "apd": "2017-08-14",
    "apy": "2017",
    "tit": "曝光控制方法、装置和系统及存储介质",
    "inv": [
      "叶赛尔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN108881677A",
    "apn": "CN201710693337.9",
    "apd": "2017-08-14",
    "apy": "2017",
    "tit": "摄像装置和摄像方法",
    "inv": [
      "程凯"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108875476A",
    "apn": "CN201710655686.1",
    "apd": "2017-08-03",
    "apy": "2017",
    "tit": "自动近红外人脸注册与识别方法、装置和系统及存储介质",
    "inv": [
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875331A",
    "apn": "CN201710647863.1",
    "apd": "2017-08-01",
    "apy": "2017",
    "tit": "人脸解锁方法、装置和系统及存储介质",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875332A",
    "apn": "CN201710648849.3",
    "apd": "2017-08-01",
    "apy": "2017",
    "tit": "用于启动人脸解锁的方法、装置和系统及存储介质",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN207302245U",
    "apn": "CN201720917687.4",
    "apd": "2017-07-26",
    "apy": "2017",
    "tit": "餐饮结算设备",
    "inv": [
      "朱雨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108881813A",
    "apn": "CN201710596416.8",
    "apd": "2017-07-20",
    "apy": "2017",
    "tit": "一种视频数据处理方法及装置、监控系统",
    "inv": [
      "敖翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875474A",
    "apn": "CN201710586167.4",
    "apd": "2017-07-18",
    "apy": "2017",
    "tit": "评估人脸识别算法的方法、装置及计算机存储介质",
    "inv": [
      "王婷"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108874657A",
    "apn": "CN201710586177.8",
    "apd": "2017-07-18",
    "apy": "2017",
    "tit": "对人脸识别主机进行测试的方法、装置及计算机存储介质",
    "inv": [
      "王婷"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876427A",
    "apn": "CN201710587414.2",
    "apd": "2017-07-18",
    "apy": "2017",
    "tit": "商品促销的方法、装置、系统及计算机存储介质",
    "inv": [
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875764A",
    "apn": "CN201710566938.3",
    "apd": "2017-07-12",
    "apy": "2017",
    "tit": "模型训练方法、装置、系统和计算机可读介质",
    "inv": [
      "史桀绮"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876704A",
    "apn": "CN201710557034.4",
    "apd": "2017-07-10",
    "apy": "2017",
    "tit": "人脸图像变形的方法、装置及计算机存储介质",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108876426A",
    "apn": "CN201710539320.8",
    "apd": "2017-07-04",
    "apy": "2017",
    "tit": "数据管理方法、系统和计算设备以及非易失性存储介质",
    "inv": [
      "刘晋宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875473A",
    "apn": "CN201710518028.8",
    "apd": "2017-06-29",
    "apy": "2017",
    "tit": "活体验证方法、装置和系统及存储介质",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875472A",
    "apn": "CN201710494951.2",
    "apd": "2017-06-26",
    "apy": "2017",
    "tit": "图像采集装置及基于该图像采集装置的人脸身份验证方法",
    "inv": [
      "冯宝库"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875471A",
    "apn": "CN201710465626.3",
    "apd": "2017-06-19",
    "apy": "2017",
    "tit": "人脸图像底库注册的方法、装置及计算机存储介质",
    "inv": [
      "刘晋宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN108875470A",
    "apn": "CN201710465098.1",
    "apd": "2017-06-19",
    "apy": "2017",
    "tit": "对访客进行登记的方法、装置及计算机存储介质",
    "inv": [
      "郑韬"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875469A",
    "apn": "CN201710448942.X",
    "apd": "2017-06-14",
    "apy": "2017",
    "tit": "活体检测与身份认证的方法、装置及计算机存储介质",
    "inv": [
      "姚聪"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875468A",
    "apn": "CN201710439294.1",
    "apd": "2017-06-12",
    "apy": "2017",
    "tit": "活体检测方法、活体检测系统以及存储介质",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875467A",
    "apn": "CN201710413340.0",
    "apd": "2017-06-05",
    "apy": "2017",
    "tit": "活体检测的方法、装置及计算机存储介质",
    "inv": [
      "邹雨恒",
      "刘宇轩",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108881674A",
    "apn": "CN201710412898.7",
    "apd": "2017-06-05",
    "apy": "2017",
    "tit": "图像采集装置和图像处理方法",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108876276A",
    "apn": "CN201710406686.8",
    "apd": "2017-06-02",
    "apy": "2017",
    "tit": "基于人脸识别的自动考勤方法及装置",
    "inv": [
      "唐学智",
      "林园园"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875466A",
    "apn": "CN201710403439.2",
    "apd": "2017-06-01",
    "apy": "2017",
    "tit": "基于人脸识别的监控方法、监控系统与存储介质",
    "inv": [
      "杨强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108961149A",
    "apn": "CN201710390286.2",
    "apd": "2017-05-27",
    "apy": "2017",
    "tit": "图像处理方法、装置和系统及存储介质",
    "inv": [
      "周而进",
      "黄志翱",
      "刘研绎"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN108875465A",
    "apn": "CN201710383882.8",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "多目标跟踪方法、多目标跟踪装置以及非易失性存储介质",
    "inv": [
      "俞刚",
      "何奇正"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108932456A",
    "apn": "CN201710370126.1",
    "apd": "2017-05-23",
    "apy": "2017",
    "tit": "人脸识别方法、装置和系统及存储介质",
    "inv": [
      "何蔚然",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108960014A",
    "apn": "CN201710368776.2",
    "apd": "2017-05-23",
    "apy": "2017",
    "tit": "图像处理方法、装置和系统及存储介质",
    "inv": [
      "彭方玥",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108875763A",
    "apn": "CN201710348872.0",
    "apd": "2017-05-17",
    "apy": "2017",
    "tit": "目标检测方法和目标检测装置",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875456A",
    "apn": "CN201710336008.9",
    "apd": "2017-05-12",
    "apy": "2017",
    "tit": "目标检测方法、目标检测装置和计算机可读存储介质",
    "inv": [
      "张弛",
      "姚昊天",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108875452A",
    "apn": "CN201710329940.9",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "人脸识别方法、装置、系统和计算机可读介质",
    "inv": [
      "孙磊",
      "杜宇飞",
      "王靖易",
      "郑韬"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN108875453A",
    "apn": "CN201710330298.6",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "人脸图片底库注册的方法、装置及计算机存储介质",
    "inv": [
      "叶赛尔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106971178A",
    "apn": "CN201710330307.1",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "行人检测和再识别的方法及装置",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107180378A",
    "apn": "CN201710329923.5",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "商品关注度获得方法及装置",
    "inv": [
      "林乐"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108876968A",
    "apn": "CN201710325597.0",
    "apd": "2017-05-10",
    "apy": "2017",
    "tit": "人脸识别闸机及其防尾随方法",
    "inv": [
      "何国勋"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108804884A",
    "apn": "CN201710302159.2",
    "apd": "2017-05-02",
    "apy": "2017",
    "tit": "身份认证的方法、装置及计算机存储介质",
    "inv": [
      "敖翔",
      "李浩然"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107122727A",
    "apn": "CN201710261588.X",
    "apd": "2017-04-20",
    "apy": "2017",
    "tit": "用于脸部整形的方法、装置及系统",
    "inv": [
      "温驭臣"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108734057A",
    "apn": "CN201710253338.1",
    "apd": "2017-04-18",
    "apy": "2017",
    "tit": "活体检测的方法、装置及计算机存储介质",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108734712A",
    "apn": "CN201710253356.X",
    "apd": "2017-04-18",
    "apy": "2017",
    "tit": "背景分割的方法、装置及计算机存储介质",
    "inv": [
      "彭超",
      "俞刚",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN108694347A",
    "apn": "CN201710220229.X",
    "apd": "2017-04-06",
    "apy": "2017",
    "tit": "图像处理方法和装置",
    "inv": [
      "曹科垒",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108573202A",
    "apn": "CN201710218217.3",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "身份认证方法、装置和系统以及终端、服务器和存储介质",
    "inv": [
      "敖翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108629260A",
    "apn": "CN201710218512.9",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "活体验证方法和装置及存储介质",
    "inv": [
      "范浩强",
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108629259A",
    "apn": "CN201710218219.2",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "身份认证方法和装置及存储介质",
    "inv": [
      "敖翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108573203A",
    "apn": "CN201710218218.8",
    "apd": "2017-04-05",
    "apy": "2017",
    "tit": "身份认证方法和装置及存储介质",
    "inv": [
      "范浩强",
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108665421A",
    "apn": "CN201710208707.5",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "人脸图像高光分量去除装置和方法、存储介质产品",
    "inv": [
      "孙伟",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108573268A",
    "apn": "CN201710205238.1",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "图像识别方法和装置、图像处理方法和装置及存储介质",
    "inv": [
      "唐学智"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108629791A",
    "apn": "CN201710161404.2",
    "apd": "2017-03-17",
    "apy": "2017",
    "tit": "行人跟踪方法和装置及跨摄像头行人跟踪方法和装置",
    "inv": [
      "吴家楠",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106980658A",
    "apn": "CN201710154233.0",
    "apd": "2017-03-15",
    "apy": "2017",
    "tit": "视频标注方法及装置",
    "inv": [
      "毛智睿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106952303A",
    "apn": "CN201710138907.8",
    "apd": "2017-03-09",
    "apy": "2017",
    "tit": "车距检测方法、装置和系统",
    "inv": [
      "茅佳源",
      "肖特特"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN108509961A",
    "apn": "CN201710109988.9",
    "apd": "2017-02-27",
    "apy": "2017",
    "tit": "图像处理方法和装置",
    "inv": [
      "肖琦琦",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108509236A",
    "apn": "CN201710102566.9",
    "apd": "2017-02-24",
    "apy": "2017",
    "tit": "主题更换方法及装置、人脸识别系统主题更换方法及装置",
    "inv": [
      "唐学智",
      "林园园"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN106803083A",
    "apn": "CN201710064338.7",
    "apd": "2017-02-04",
    "apy": "2017",
    "tit": "行人检测的方法及装置",
    "inv": [
      "肖特特",
      "茅佳源"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106803284A",
    "apn": "CN201710020345.7",
    "apd": "2017-01-11",
    "apy": "2017",
    "tit": "构建脸部的三维图像的方法及装置",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN106650691A",
    "apn": "CN201611255057.1",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "图像处理方法和图像处理设备",
    "inv": [
      "庄永文",
      "梁喆",
      "曹宇辉",
      "张宇翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106529517B",
    "apn": "CN201611255019.6",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "图像处理方法和图像处理设备",
    "inv": [
      "曹宇辉",
      "梁喆",
      "张宇翔",
      "温和",
      "周舒畅",
      "周昕宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN108268815A",
    "apn": "CN201611254544.6",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "图像场景理解的方法及装置",
    "inv": [
      "彭超",
      "俞刚",
      "张祥雨"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN106803086A",
    "apn": "CN201611256748.3",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "辨别证件真实性的方法、装置及系统",
    "inv": [
      "周舒畅",
      "汪彧之"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106682187A",
    "apn": "CN201611247448.9",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "用于建立图像底库的方法及装置",
    "inv": [
      "刘金胜"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108256404A",
    "apn": "CN201611249204.4",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "行人检测方法和装置",
    "inv": [
      "俞刚",
      "彭雨翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106855952A",
    "apn": "CN201611244827.2",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "基于神经网络的计算方法及装置",
    "inv": [
      "梁喆",
      "张宇翔",
      "温和",
      "周昕宇",
      "周舒畅"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN106611216A",
    "apn": "CN201611244816.4",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "基于神经网络的计算方法及装置",
    "inv": [
      "周舒畅",
      "梁喆",
      "张宇翔",
      "温和",
      "周昕宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN106682620A",
    "apn": "CN201611235227.X",
    "apd": "2016-12-28",
    "apy": "2016",
    "tit": "人脸图像采集方法及装置",
    "inv": [
      "张辉",
      "张弛",
      "冯宝库"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN106650666A",
    "apn": "CN201611217455.4",
    "apd": "2016-12-26",
    "apy": "2016",
    "tit": "活体检测的方法及装置",
    "inv": [
      "程凯"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106650665A",
    "apn": "CN201611216574.8",
    "apd": "2016-12-26",
    "apy": "2016",
    "tit": "人脸跟踪方法及装置",
    "inv": [
      "卢宏刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106599872A",
    "apn": "CN201611204264.4",
    "apd": "2016-12-23",
    "apy": "2016",
    "tit": "用于验证活体人脸图像的方法和设备",
    "inv": [
      "郭天魁"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106845352A",
    "apn": "CN201611205712.2",
    "apd": "2016-12-23",
    "apy": "2016",
    "tit": "行人检测方法和装置",
    "inv": [
      "俞刚",
      "彭超"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106650662A",
    "apn": "CN201611191940.9",
    "apd": "2016-12-21",
    "apy": "2016",
    "tit": "目标对象遮挡检测方法及装置",
    "inv": [
      "周舒畅",
      "何蔚然",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106651877A",
    "apn": "CN201611186619.1",
    "apd": "2016-12-20",
    "apy": "2016",
    "tit": "实例分割方法及装置",
    "inv": [
      "兰石懿",
      "胡鹤翔",
      "姜宇宁"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN106529512B",
    "apn": "CN201611162606.0",
    "apd": "2016-12-15",
    "apy": "2016",
    "tit": "活体人脸验证方法及装置",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106778867A",
    "apn": "CN201611161693.8",
    "apd": "2016-12-15",
    "apy": "2016",
    "tit": "目标检测方法和装置、神经网络训练方法和装置",
    "inv": [
      "肖特特",
      "茅佳源"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN106529511A",
    "apn": "CN201611146151.3",
    "apd": "2016-12-13",
    "apy": "2016",
    "tit": "图像结构化方法及装置",
    "inv": [
      "张弛",
      "毛慧子"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106534151A",
    "apn": "CN201611077569.3",
    "apd": "2016-11-29",
    "apy": "2016",
    "tit": "用于播放视频流的方法及装置",
    "inv": [
      "沙烨锋"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106778525A",
    "apn": "CN201611060622.9",
    "apd": "2016-11-25",
    "apy": "2016",
    "tit": "身份认证方法和装置",
    "inv": [
      "陈可卿"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106600733A",
    "apn": "CN201611052827.2",
    "apd": "2016-11-24",
    "apy": "2016",
    "tit": "基于人脸识别的签到方法和系统",
    "inv": [
      "葛聪颖"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106780662A",
    "apn": "CN201611008893.X",
    "apd": "2016-11-16",
    "apy": "2016",
    "tit": "人脸图像生成方法、装置及设备",
    "inv": [
      "刘宇",
      "周而进"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN106780658A",
    "apn": "CN201611008892.5",
    "apd": "2016-11-16",
    "apy": "2016",
    "tit": "人脸特征添加方法、装置及设备",
    "inv": [
      "刘宇",
      "周而进"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN106529485A",
    "apn": "CN201611010103.1",
    "apd": "2016-11-16",
    "apy": "2016",
    "tit": "用于获取训练数据的方法及装置",
    "inv": [
      "肖特特",
      "茅佳源"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106446873A",
    "apn": "CN201610972629.1",
    "apd": "2016-11-03",
    "apy": "2016",
    "tit": "人脸检测方法及装置",
    "inv": [
      "卢宏刚"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN106599772A",
    "apn": "CN201610927708.0",
    "apd": "2016-10-31",
    "apy": "2016",
    "tit": "活体验证方法和装置及身份认证方法和装置",
    "inv": [
      "何涛",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN108009466A",
    "apn": "CN201610971349.9",
    "apd": "2016-10-28",
    "apy": "2016",
    "tit": "行人检测方法和装置",
    "inv": [
      "俞刚",
      "彭雨翔"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106649538A",
    "apn": "CN201610949759.3",
    "apd": "2016-10-26",
    "apy": "2016",
    "tit": "人脸搜索的方法及装置",
    "inv": [
      "沙烨锋"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN106331518A",
    "apn": "CN201610875461.2",
    "apd": "2016-09-30",
    "apy": "2016",
    "tit": "图像处理方法及装置和电子系统",
    "inv": [
      "冯宝库"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106454079A",
    "apn": "CN201610860499.2",
    "apd": "2016-09-28",
    "apy": "2016",
    "tit": "图像处理方法及装置和相机",
    "inv": [
      "冯宝库"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN106254782A",
    "apn": "CN201610862533.X",
    "apd": "2016-09-28",
    "apy": "2016",
    "tit": "图像处理方法及装置和相机",
    "inv": [
      "冯宝库"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN106651973A",
    "apn": "CN201610859311.2",
    "apd": "2016-09-28",
    "apy": "2016",
    "tit": "图像结构化方法及装置",
    "inv": [
      "张弛",
      "夏斐"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107844794A",
    "apn": "CN201610839173.1",
    "apd": "2016-09-21",
    "apy": "2016",
    "tit": "图像识别方法和装置",
    "inv": [
      "俞刚",
      "彭超"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106446816A",
    "apn": "CN201610827359.5",
    "apd": "2016-09-14",
    "apy": "2016",
    "tit": "人脸识别方法及装置",
    "inv": [
      "杜志强",
      "沙烨锋",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106447721A",
    "apn": "CN201610817703.2",
    "apd": "2016-09-12",
    "apy": "2016",
    "tit": "图像阴影检测方法和装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "何蔚然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106650743A",
    "apn": "CN201610818280.6",
    "apd": "2016-09-12",
    "apy": "2016",
    "tit": "图像强反光检测方法和装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "何蔚然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107808111A",
    "apn": "CN201610811709.9",
    "apd": "2016-09-08",
    "apy": "2016",
    "tit": "用于行人检测和姿态估计的方法和装置",
    "inv": [
      "俞刚",
      "彭雨翔",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106407914A",
    "apn": "CN201610798437.3",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "用于检测人脸的方法、装置和远程柜员机系统",
    "inv": [
      "邵猛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106385640A",
    "apn": "CN201610796645.X",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "视频标注方法及装置",
    "inv": [
      "薛宇飞",
      "张弛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106372606A",
    "apn": "CN201610798111.0",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "目标对象信息生成方法和单元、识别方法和单元及系统",
    "inv": [
      "程凯",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "旷视",
    "pun": "CN106469296A",
    "apn": "CN201610772864.4",
    "apd": "2016-08-30",
    "apy": "2016",
    "tit": "人脸识别方法、装置和门禁系统",
    "inv": [
      "郑韬",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN106354823A",
    "apn": "CN201610770048.X",
    "apd": "2016-08-30",
    "apy": "2016",
    "tit": "汇总人脸比对系统的操作数据的方法、装置及系统",
    "inv": [
      "杜志强",
      "沙烨锋",
      "罗铮",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106327546A",
    "apn": "CN201610721404.9",
    "apd": "2016-08-24",
    "apy": "2016",
    "tit": "人脸检测算法的测试方法和装置",
    "inv": [
      "孙磊",
      "罗铮",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106372572B",
    "apn": "CN201610698565.0",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "监控方法和装置",
    "inv": [
      "王峰",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106250863B",
    "apn": "CN201610648977.3",
    "apd": "2016-08-09",
    "apy": "2016",
    "tit": "对象追踪方法和装置",
    "inv": [
      "张弛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106326837B",
    "apn": "CN201610648861.X",
    "apd": "2016-08-09",
    "apy": "2016",
    "tit": "对象追踪方法和装置",
    "inv": [
      "张弛",
      "吴家楠",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106295678A",
    "apn": "CN201610605759.1",
    "apd": "2016-07-27",
    "apy": "2016",
    "tit": "神经网络训练与构建方法和装置以及目标检测方法和装置",
    "inv": [
      "余家辉",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN106228133A",
    "apn": "CN201610581186.3",
    "apd": "2016-07-21",
    "apy": "2016",
    "tit": "用户验证方法及装置",
    "inv": [
      "周而进",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106251338B",
    "apn": "CN201610578817.6",
    "apd": "2016-07-20",
    "apy": "2016",
    "tit": "目标完整性检测方法及装置",
    "inv": [
      "周舒畅",
      "姚聪",
      "周昕宇",
      "何蔚然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107644190A",
    "apn": "CN201610577109.0",
    "apd": "2016-07-20",
    "apy": "2016",
    "tit": "行人监控方法和装置",
    "inv": [
      "俞刚",
      "彭雨翔",
      "吕凯风",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106203376A",
    "apn": "CN201610571032.6",
    "apd": "2016-07-19",
    "apy": "2016",
    "tit": "人脸关键点定位方法及装置",
    "inv": [
      "黄志翱",
      "周而进",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107563257A",
    "apn": "CN201610509780.1",
    "apd": "2016-07-01",
    "apy": "2016",
    "tit": "视频理解方法及装置",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "陈牧歌",
      "彭雨翔",
      "吕凯风",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106203305A",
    "apn": "CN201610511947.8",
    "apd": "2016-06-30",
    "apy": "2016",
    "tit": "人脸活体检测方法和装置",
    "inv": [
      "范浩强",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106131405A",
    "apn": "CN201610511867.2",
    "apd": "2016-06-30",
    "apy": "2016",
    "tit": "图像处理方法和装置及用于其的图像采集系统",
    "inv": [
      "范浩强",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN107563256A",
    "apn": "CN201610509712.5",
    "apd": "2016-06-30",
    "apy": "2016",
    "tit": "辅助驾驶信息产生方法及装置、辅助驾驶系统",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "陈牧歌",
      "彭雨翔",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN107566781B",
    "apn": "CN201610513552.1",
    "apd": "2016-06-30",
    "apy": "2016",
    "tit": "视频监控方法和视频监控设备",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "陈牧歌",
      "彭雨翔",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105938552A",
    "apn": "CN201610498262.4",
    "apd": "2016-06-29",
    "apy": "2016",
    "tit": "底图自动更新的人脸识别方法及装置",
    "inv": [
      "沙烨锋",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN106096028A",
    "apn": "CN201610474603.4",
    "apd": "2016-06-24",
    "apy": "2016",
    "tit": "基于图像识别的文物索引方法及装置",
    "inv": [
      "余家辉",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "文物",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN107451510A",
    "apn": "CN201610371250.5",
    "apd": "2016-05-30",
    "apy": "2016",
    "tit": "活体检测方法和活体检测系统",
    "inv": [
      "范浩强",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN107451950A",
    "apn": "CN201610370020.7",
    "apd": "2016-05-30",
    "apy": "2016",
    "tit": "人脸图像生成方法、人脸识别模型训练方法及相应装置",
    "inv": [
      "鲁逸沁",
      "周而进",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN105872477B",
    "apn": "CN201610366216.9",
    "apd": "2016-05-27",
    "apy": "2016",
    "tit": "视频监控方法和视频监控系统",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "陈牧歌",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106055873A",
    "apn": "CN201610341997.6",
    "apd": "2016-05-20",
    "apy": "2016",
    "tit": "基于图像识别的健身辅助方法及装置",
    "inv": [
      "余家辉",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106033615B",
    "apn": "CN201610323389.2",
    "apd": "2016-05-16",
    "apy": "2016",
    "tit": "目标对象运动方向检测方法和装置",
    "inv": [
      "林乐",
      "俞刚",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105976400B",
    "apn": "CN201610306250.7",
    "apd": "2016-05-10",
    "apy": "2016",
    "tit": "基于神经网络模型的目标跟踪方法及装置",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "陈牧歌",
      "彭雨翔",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105933650A",
    "apn": "CN201610262764.7",
    "apd": "2016-04-25",
    "apy": "2016",
    "tit": "视频监控系统及方法",
    "inv": [
      "姜宇宁",
      "李百恩",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105930710B",
    "apn": "CN201610258600.7",
    "apd": "2016-04-22",
    "apy": "2016",
    "tit": "活体检测方法和装置",
    "inv": [
      "周舒畅",
      "姚聪",
      "周昕宇",
      "何蔚然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105912986B",
    "apn": "CN201610203579.0",
    "apd": "2016-04-01",
    "apy": "2016",
    "tit": "一种活体检测方法和系统",
    "inv": [
      "范浩强",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105631439B",
    "apn": "CN201610091404.5",
    "apd": "2016-02-18",
    "apy": "2016",
    "tit": "人脸图像处理方法和装置",
    "inv": [
      "何涛",
      "贾开",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN105744223B",
    "apn": "CN201610079944.1",
    "apd": "2016-02-04",
    "apy": "2016",
    "tit": "视频数据处理方法和装置",
    "inv": [
      "毛慧子",
      "张弛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105740808B",
    "apn": "CN201610058522.6",
    "apd": "2016-01-28",
    "apy": "2016",
    "tit": "人脸识别方法和装置",
    "inv": [
      "曹志敏",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105512348B",
    "apn": "CN201610058764.5",
    "apd": "2016-01-28",
    "apy": "2016",
    "tit": "用于处理视频和相关音频的方法和装置及检索方法和装置",
    "inv": [
      "许欣然",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105447483B",
    "apn": "CN201511030874.2",
    "apd": "2015-12-31",
    "apy": "2015",
    "tit": "活体检测方法及装置",
    "inv": [
      "范浩强",
      "印奇"
    ],
    "app": [
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105631427A",
    "apn": "CN201511018696.1",
    "apd": "2015-12-29",
    "apy": "2015",
    "tit": "可疑人员检测方法与系统",
    "inv": [
      "吴家楠",
      "张弛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN205281786U",
    "apn": "CN201521125867.6",
    "apd": "2015-12-29",
    "apy": "2015",
    "tit": "报警系统",
    "inv": [
      "茅佳源",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105448103B",
    "apn": "CN201510984703.7",
    "apd": "2015-12-24",
    "apy": "2015",
    "tit": "车辆套牌检测方法与系统",
    "inv": [
      "茅佳源",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN205453932U",
    "apn": "CN201521071672.8",
    "apd": "2015-12-21",
    "apy": "2015",
    "tit": "监控系统",
    "inv": [
      "冯宝库",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105554456B",
    "apn": "CN201510964922.9",
    "apd": "2015-12-21",
    "apy": "2015",
    "tit": "视频处理方法和设备",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "旷视",
    "pun": "CN105512632B",
    "apn": "CN201510900717.6",
    "apd": "2015-12-09",
    "apy": "2015",
    "tit": "活体检测方法及装置",
    "inv": [
      "贾开",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105426841B",
    "apn": "CN201510800367.6",
    "apd": "2015-11-19",
    "apy": "2015",
    "tit": "基于人脸检测的监控相机自定位方法及装置",
    "inv": [
      "范浩强",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105404860B",
    "apn": "CN201510779016.1",
    "apd": "2015-11-13",
    "apy": "2015",
    "tit": "用于管理走失人员信息的方法和设备",
    "inv": [
      "柳志贤",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105354472A",
    "apn": "CN201510746884.X",
    "apd": "2015-11-05",
    "apy": "2015",
    "tit": "一种智能设备控制方法和智能设备控制系统",
    "inv": [
      "温驭臣",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105407276A",
    "apn": "CN201510738473.6",
    "apd": "2015-11-03",
    "apy": "2015",
    "tit": "拍照方法和设备",
    "inv": [
      "姬冰芳",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "拍摄模式",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105404896B",
    "apn": "CN201510744484.5",
    "apd": "2015-11-03",
    "apy": "2015",
    "tit": "标注数据处理方法和标注数据处理系统",
    "inv": [
      "许欣然",
      "高宇",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN105447501B",
    "apn": "CN201510736192.7",
    "apd": "2015-11-02",
    "apy": "2015",
    "tit": "基于聚类的证照图像阴影检测方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "印奇"
    ],
    "app": [
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN105405130B",
    "apn": "CN201510733446.X",
    "apd": "2015-11-02",
    "apy": "2015",
    "tit": "基于聚类的证照图像高光检测方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN105429959B",
    "apn": "CN201510731519.1",
    "apd": "2015-11-02",
    "apy": "2015",
    "tit": "图像处理方法及客户端设备、图像验证方法及服务器",
    "inv": [
      "周舒畅",
      "姚聪",
      "周昕宇",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN105611147A",
    "apn": "CN201510727134.8",
    "apd": "2015-10-30",
    "apy": "2015",
    "tit": "拍照方法和设备",
    "inv": [
      "陈可卿",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "质量评价"
  },
  {
    "entity": "旷视",
    "pun": "CN105389549B",
    "apn": "CN201510713010.4",
    "apd": "2015-10-28",
    "apy": "2015",
    "tit": "基于人体动作特征的对象识别方法及装置",
    "inv": [
      "曹科垒",
      "张弛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105224932A",
    "apn": "CN201510686556.5",
    "apd": "2015-10-21",
    "apy": "2015",
    "tit": "一种人脸识别方法和系统",
    "inv": [
      "杜宇飞",
      "王建飞",
      "赵礼杰",
      "余秋燕",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105389845B",
    "apn": "CN201510680737.7",
    "apd": "2015-10-19",
    "apy": "2015",
    "tit": "三维重建的图像获取方法和系统、三维重建方法和系统",
    "inv": [
      "范浩强",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN105279484B",
    "apn": "CN201510654143.9",
    "apd": "2015-10-10",
    "apy": "2015",
    "tit": "对象检测方法和对象检测装置",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105160739B",
    "apn": "CN201510568519.4",
    "apd": "2015-09-09",
    "apy": "2015",
    "tit": "自动识别设备、方法以及门禁系统",
    "inv": [
      "刘金胜",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105245828A",
    "apn": "CN201510557968.9",
    "apd": "2015-09-02",
    "apy": "2015",
    "tit": "物品分析方法和设备",
    "inv": [
      "俞刚",
      "李超",
      "何奇正",
      "章放",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105184253A",
    "apn": "CN201510552835.2",
    "apd": "2015-09-01",
    "apy": "2015",
    "tit": "一种人脸识别方法和人脸识别系统",
    "inv": [
      "周而进",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105160318B",
    "apn": "CN201510548455.1",
    "apd": "2015-08-31",
    "apy": "2015",
    "tit": "基于面部表情的测谎方法及系统",
    "inv": [
      "张弛",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105184246A",
    "apn": "CN201510543516.5",
    "apd": "2015-08-28",
    "apy": "2015",
    "tit": "活体检测方法和活体检测系统",
    "inv": [
      "范浩强",
      "贾开",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105117463B",
    "apn": "CN201510524724.0",
    "apd": "2015-08-24",
    "apy": "2015",
    "tit": "信息处理方法和信息处理装置",
    "inv": [
      "姜宇宁",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105138879B",
    "apn": "CN201510463074.3",
    "apd": "2015-07-31",
    "apy": "2015",
    "tit": "控制软件使用权限的方法和装置",
    "inv": [
      "赵礼杰",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN105069738B",
    "apn": "CN201510441633.0",
    "apd": "2015-07-24",
    "apy": "2015",
    "tit": "图像叠加水印方法和装置",
    "inv": [
      "曹志敏",
      "陈可卿",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN105118048B",
    "apn": "CN201510424418.X",
    "apd": "2015-07-17",
    "apy": "2015",
    "tit": "翻拍证件图片的识别方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN104966109B",
    "apn": "CN201510425315.5",
    "apd": "2015-07-17",
    "apy": "2015",
    "tit": "医疗化验单图像分类方法及装置",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "吴育昕"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN104933420B",
    "apn": "CN201510389185.4",
    "apd": "2015-07-03",
    "apy": "2015",
    "tit": "一种场景图像识别方法和场景图像识别设备",
    "inv": [
      "姜宇宁",
      "李百恩"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518582B",
    "apn": "CN201580000356.8",
    "apd": "2015-06-30",
    "apy": "2015",
    "tit": "活体检测方法及设备",
    "inv": [
      "曹志敏",
      "陈可卿",
      "贾开"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518715A",
    "apn": "CN201580000358.7",
    "apd": "2015-06-30",
    "apy": "2015",
    "tit": "活体检测方法及设备、计算机程序产品",
    "inv": [
      "曹志敏",
      "陈可卿",
      "贾开"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518714A",
    "apn": "CN201580000355.3",
    "apd": "2015-06-30",
    "apy": "2015",
    "tit": "活体检测方法及设备、计算机程序产品",
    "inv": [
      "曹志敏",
      "陈可卿",
      "贾开"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518711B",
    "apn": "CN201580000332.2",
    "apd": "2015-06-29",
    "apy": "2015",
    "tit": "活体检测方法、活体检测系统以及计算机程序产品",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518744B",
    "apn": "CN201580000333.7",
    "apd": "2015-06-29",
    "apy": "2015",
    "tit": "行人再识别方法及设备",
    "inv": [
      "俞刚",
      "李超",
      "尚泽远",
      "何奇正"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518678B",
    "apn": "CN201580000313.X",
    "apd": "2015-06-29",
    "apy": "2015",
    "tit": "搜索方法、搜索装置和用户设备",
    "inv": [
      "姚聪",
      "周舒畅",
      "周昕宇",
      "吴育昕"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN105612533A",
    "apn": "CN201580000331.8",
    "apd": "2015-06-08",
    "apy": "2015",
    "tit": "活体检测方法、活体检测系统以及计算机程序产品",
    "inv": [
      "曹志敏",
      "贾开"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105637532A",
    "apn": "CN201580000335.6",
    "apd": "2015-06-08",
    "apy": "2015",
    "tit": "活体检测方法、活体检测系统以及计算机程序产品",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN104966284A",
    "apn": "CN201510290441.4",
    "apd": "2015-05-29",
    "apy": "2015",
    "tit": "基于深度数据获得对象尺寸信息的方法及设备",
    "inv": [
      "曹志敏",
      "贾开"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN104935438B",
    "apn": "CN201510282729.7",
    "apd": "2015-05-28",
    "apy": "2015",
    "tit": "用于身份验证的方法和装置",
    "inv": [
      "姜宇宁",
      "李百恩"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN104881647B",
    "apn": "CN201510278162.6",
    "apd": "2015-05-27",
    "apy": "2015",
    "tit": "信息处理方法、信息处理系统和信息处理装置",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518710B",
    "apn": "CN201580000329.0",
    "apd": "2015-04-30",
    "apy": "2015",
    "tit": "视频检测方法、视频检测系统以及计算机程序产品",
    "inv": [
      "范浩强",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518708B",
    "apn": "CN201580000312.5",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "用于验证活体人脸的方法、设备和计算机程序产品",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN104794453B",
    "apn": "CN201510214257.1",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "活体验证方法及装置",
    "inv": [
      "周舒畅",
      "周昕宇",
      "吴育昕"
    ],
    "app": [
      "徐州旷视数据科技有限公司",
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN104809441B",
    "apn": "CN201510214244.4",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "活体验证方法及装置",
    "inv": [
      "周舒畅",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN204515801U",
    "apn": "CN201520271619.6",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "活体验证装置",
    "inv": [
      "周舒畅",
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN204515800U",
    "apn": "CN201520270173.5",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "活体验证装置",
    "inv": [
      "周舒畅",
      "周昕宇",
      "吴育昕"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105493502B",
    "apn": "CN201580000328.6",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "视频监控方法、视频监控系统以及计算机可读存储介质",
    "inv": [
      "俞刚",
      "李超",
      "尚泽远",
      "何奇正"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105517680A",
    "apn": "CN201580000322.9",
    "apd": "2015-04-28",
    "apy": "2015",
    "tit": "用于识别人脸的装置、系统、方法和计算机程序产品",
    "inv": [
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN104808794A",
    "apn": "CN201510200486.8",
    "apd": "2015-04-24",
    "apy": "2015",
    "tit": "一种唇语输入方法和系统",
    "inv": [
      "张弛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518709B",
    "apn": "CN201580000324.8",
    "apd": "2015-03-26",
    "apy": "2015",
    "tit": "用于识别人脸的方法、系统和计算机程序产品",
    "inv": [
      "何涛"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518679A",
    "apn": "CN201580000354.9",
    "apd": "2015-03-26",
    "apy": "2015",
    "tit": "图片管理方法以及图片同步方法",
    "inv": [
      "姜宇宁",
      "李百恩"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105519102B",
    "apn": "CN201580000334.1",
    "apd": "2015-03-26",
    "apy": "2015",
    "tit": "视频监控方法和视频监控系统",
    "inv": [
      "俞刚",
      "李超",
      "尚泽远"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105517679A",
    "apn": "CN201580000317.8",
    "apd": "2015-03-25",
    "apy": "2015",
    "tit": "用户地理位置的确定",
    "inv": [
      "周舒畅",
      "周昕宇",
      "吴育昕",
      "吴家楠"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106960177A",
    "apn": "CN201710097331.5",
    "apd": "2015-02-15",
    "apy": "2015",
    "tit": "活体人脸验证方法及系统、活体人脸验证装置",
    "inv": [
      "何涛",
      "贾开",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105518713A",
    "apn": "CN201580000346.4",
    "apd": "2015-02-15",
    "apy": "2015",
    "tit": "活体人脸验证方法及系统、计算机程序产品",
    "inv": [
      "何涛",
      "贾开",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司",
      "北京迈格威科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN106062774A",
    "apn": "CN201480076851.2",
    "apd": "2014-11-15",
    "apy": "2014",
    "tit": "使用机器学习进行面部检测",
    "inv": [
      "印奇",
      "曹志敏",
      "贾开"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN105981051B",
    "apn": "CN201480075091.3",
    "apd": "2014-10-10",
    "apy": "2014",
    "tit": "用于图像解析的分层互连多尺度卷积网络",
    "inv": [
      "印奇",
      "曹志敏",
      "周以苏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105960657B",
    "apn": "CN201480074939.0",
    "apd": "2014-06-17",
    "apy": "2014",
    "tit": "使用卷积神经网络的面部超分辨率",
    "inv": [
      "印奇",
      "曹志敏",
      "周而进"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN105960647A",
    "apn": "CN201480074938.6",
    "apd": "2014-05-29",
    "apy": "2014",
    "tit": "紧凑人脸表示",
    "inv": [
      "印奇",
      "曹志敏",
      "姜宇宁",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105981041A",
    "apn": "CN201480075046.8",
    "apd": "2014-05-29",
    "apy": "2014",
    "tit": "使用粗到细级联神经网络的面部关键点定位",
    "inv": [
      "周而进",
      "范浩强",
      "曹志敏",
      "姜宇宁",
      "印奇"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN105981008B",
    "apn": "CN201480074764.3",
    "apd": "2014-05-27",
    "apy": "2014",
    "tit": "学习深度人脸表示",
    "inv": [
      "印奇",
      "曹志敏",
      "姜宇宁",
      "范浩强"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN103793697B",
    "apn": "CN201410053879.6",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种人脸图像的身份标注方法及人脸身份识别方法",
    "inv": [
      "曹志敏",
      "印奇",
      "姜宇宁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN103824053B",
    "apn": "CN201410053395.1",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种人脸图像的性别标注方法及人脸性别检测方法",
    "inv": [
      "印奇",
      "曹志敏",
      "姜宇宁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN103824055B",
    "apn": "CN201410053866.9",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种基于级联神经网络的人脸识别方法",
    "inv": [
      "曹志敏",
      "印奇",
      "姜宇宁"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN103824090B",
    "apn": "CN201410053332.6",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种自适应的人脸低层特征选择方法及人脸属性识别方法",
    "inv": [
      "印奇",
      "曹志敏",
      "姜宇宁",
      "杜儒成"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN103824052B",
    "apn": "CN201410053341.5",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种基于多层次语义特征的人脸特征提取方法及识别方法",
    "inv": [
      "姜宇宁",
      "印奇",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN103824089B",
    "apn": "CN201410053325.6",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种基于级联回归的人脸3D姿态识别方法",
    "inv": [
      "印奇",
      "曹志敏",
      "姜宇宁",
      "何涛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "旷视",
    "pun": "CN103824051B",
    "apn": "CN201410053334.5",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种基于局部区域匹配的人脸搜索方法",
    "inv": [
      "姜宇宁",
      "印奇",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "旷视",
    "pun": "CN103824050B",
    "apn": "CN201410053323.7",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种基于级联回归的人脸关键点定位方法",
    "inv": [
      "印奇",
      "曹志敏",
      "姜宇宁",
      "何涛"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN103824049A",
    "apn": "CN201410053321.8",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种基于级联神经网络的人脸关键点检测方法",
    "inv": [
      "姜宇宁",
      "印奇",
      "曹志敏"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN103824054B",
    "apn": "CN201410053852.7",
    "apd": "2014-02-17",
    "apy": "2014",
    "tit": "一种基于级联深度神经网络的人脸属性识别方法",
    "inv": [
      "印奇",
      "曹志敏",
      "姜宇宁",
      "杨东"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "旷视",
    "pun": "CN102938065B",
    "apn": "CN201210495625.0",
    "apd": "2012-11-28",
    "apy": "2012",
    "tit": "基于大规模图像数据的人脸特征提取方法及人脸识别方法",
    "inv": [
      "印奇",
      "曹志敏",
      "唐文斌",
      "杨沐"
    ],
    "app": [
      "北京旷视科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像处理"
  },
  {
    "entity": "旷视",
    "pun": "CN102568007B",
    "apn": "CN201110427699.6",
    "apd": "2011-12-19",
    "apy": "2011",
    "tit": "利用改进霍夫变换进行类圆形检测的图像处理方法",
    "inv": [
      "黄海清"
    ],
    "app": [
      "嘉兴旷视医疗科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "旷视",
    "pun": "CN102564922B",
    "apn": "CN201110427703.9",
    "apd": "2011-12-19",
    "apy": "2011",
    "tit": "霍夫变换细胞检测装置",
    "inv": [
      "黄海清"
    ],
    "app": [
      "嘉兴旷视医疗科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102087652A",
    "apn": "CN200910250227.0",
    "apd": "2009-12-08",
    "apy": "2009",
    "tit": "图像筛选方法及其系统",
    "inv": [
      "文林福"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN101984420B",
    "apn": "CN201010271798.5",
    "apd": "2010-09-03",
    "apy": "2010",
    "tit": "一种基于拆词处理进行图片搜索的方法与设备",
    "inv": [
      "魏星",
      "郭昶",
      "聂昆",
      "王梓旋",
      "常齐",
      "吕文先",
      "高静霞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN101976252B",
    "apn": "CN201010519881.X",
    "apd": "2010-10-26",
    "apy": "2010",
    "tit": "图片展示系统及其展示方法",
    "inv": [
      "程军",
      "刘涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN102012934A",
    "apn": "CN201010574563.3",
    "apd": "2010-11-30",
    "apy": "2010",
    "tit": "图片搜索方法及搜索系统",
    "inv": [
      "文林福"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN102693231B",
    "apn": "CN201110070669.4",
    "apd": "2011-03-23",
    "apy": "2011",
    "tit": "用于根据来自网络的图像来确定图集的方法、装置和设备",
    "inv": [
      "吕文先",
      "魏星",
      "潘腾飞",
      "韩璟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN102855245A",
    "apn": "CN201110180020.8",
    "apd": "2011-06-28",
    "apy": "2011",
    "tit": "一种用于确定图片相似度的方法与设备",
    "inv": [
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN102289479A",
    "apn": "CN201110218608.8",
    "apd": "2011-08-01",
    "apy": "2011",
    "tit": "一种确定图像展现方式并展现图像的方法、装置和设备",
    "inv": [
      "-"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102955784B",
    "apn": "CN201110240715.0",
    "apd": "2011-08-19",
    "apy": "2011",
    "tit": "一种基于数字签名对多个图像进行相似判断的设备和方法",
    "inv": [
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN102955947B",
    "apn": "CN201110240628.5",
    "apd": "2011-08-19",
    "apy": "2011",
    "tit": "一种用于确定图像清晰度的设备及其方法",
    "inv": [
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN102306287B",
    "apn": "CN201110246789.5",
    "apd": "2011-08-24",
    "apy": "2011",
    "tit": "一种用于识别敏感图像的方法与设备",
    "inv": [
      "李彦宏",
      "舒迅",
      "袁聃",
      "文林福",
      "方勇",
      "帅帅",
      "李岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102314386B",
    "apn": "CN201110260755.1",
    "apd": "2011-09-05",
    "apy": "2011",
    "tit": "一种测试移动终端的方法与设备",
    "inv": [
      "刘斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102760144A",
    "apn": "CN201110337704.4",
    "apd": "2011-10-31",
    "apy": "2011",
    "tit": "信息搜索方法及系统",
    "inv": [
      "闫鹏",
      "李彦宏",
      "蔡虎",
      "沈毅",
      "李磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN102509235B",
    "apn": "CN201110337020.4",
    "apd": "2011-10-31",
    "apy": "2011",
    "tit": "基于智能手持设备的移动视频的购物交互方法",
    "inv": [
      "刘德建",
      "李永均",
      "林其翔",
      "郑晟"
    ],
    "app": [
      "百度时代网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103324931A",
    "apn": "CN201210077470.9",
    "apd": "2012-03-22",
    "apy": "2012",
    "tit": "一种图像的彩色二值化方法和装置",
    "inv": [
      "都大龙",
      "张睿"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN103366178B",
    "apn": "CN201210091353.8",
    "apd": "2012-03-30",
    "apy": "2012",
    "tit": "一种用于对目标图像进行颜色分类的方法与设备",
    "inv": [
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102682095B",
    "apn": "CN201210129100.5",
    "apd": "2012-04-27",
    "apy": "2012",
    "tit": "用于配对图片搜索的方法和提供配对图片的搜索系统",
    "inv": [
      "李靖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103390165B",
    "apn": "CN201210144457.0",
    "apd": "2012-05-10",
    "apy": "2012",
    "tit": "一种图片聚类的方法及装置",
    "inv": [
      "文林福"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103425715B",
    "apn": "CN201210167803.7",
    "apd": "2012-05-25",
    "apy": "2012",
    "tit": "一种确定图片文本标注的方法和系统",
    "inv": [
      "阮星华",
      "欧鑫凤",
      "张敏",
      "高亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103426191B",
    "apn": "CN201210168118.6",
    "apd": "2012-05-26",
    "apy": "2012",
    "tit": "一种图片标注方法及系统",
    "inv": [
      "阮星华",
      "高亮",
      "欧鑫凤",
      "李宁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103455263B",
    "apn": "CN201210176453.0",
    "apd": "2012-05-31",
    "apy": "2012",
    "tit": "一种用于提供虚拟输入键盘的方法与设备",
    "inv": [
      "孙培犖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN103577737A",
    "apn": "CN201210279837.5",
    "apd": "2012-08-07",
    "apy": "2012",
    "tit": "移动终端及其权限自动调整方法",
    "inv": [
      "齐颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103593643B",
    "apn": "CN201210293317.X",
    "apd": "2012-08-16",
    "apy": "2012",
    "tit": "一种图像识别的方法及系统",
    "inv": [
      "刘俊启"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102867025A",
    "apn": "CN201210303583.6",
    "apd": "2012-08-23",
    "apy": "2012",
    "tit": "一种获取图片标注数据的方法和装置",
    "inv": [
      "胡星"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103634442B",
    "apn": "CN201210308653.7",
    "apd": "2012-08-27",
    "apy": "2012",
    "tit": "基于三维手势和语音的自动拨号方法及移动终端",
    "inv": [
      "齐颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102930263A",
    "apn": "CN201210364819.7",
    "apd": "2012-09-27",
    "apy": "2012",
    "tit": "一种信息处理方法及装置",
    "inv": [
      "金远"
    ],
    "app": [
      "百度国际科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN102902771A",
    "apn": "CN201210365031.8",
    "apd": "2012-09-27",
    "apy": "2012",
    "tit": "一种图片搜索方法、装置及服务器",
    "inv": [
      "金远"
    ],
    "app": [
      "百度国际科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103793434A",
    "apn": "CN201210432356.3",
    "apd": "2012-11-02",
    "apy": "2012",
    "tit": "一种基于内容的图片搜索方法和装置",
    "inv": [
      "方高林"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103838724B",
    "apn": "CN201210473675.9",
    "apd": "2012-11-20",
    "apy": "2012",
    "tit": "图像搜索方法及装置",
    "inv": [
      "刘娟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103002410B",
    "apn": "CN201210475883.2",
    "apd": "2012-11-21",
    "apy": "2012",
    "tit": "用于移动终端的增强现实方法、系统和移动终端",
    "inv": [
      "李刚"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN103020184B",
    "apn": "CN201210501037.3",
    "apd": "2012-11-29",
    "apy": "2012",
    "tit": "使用拍摄图像获取搜索结果的方法和系统",
    "inv": [
      "巫国忠"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103047969B",
    "apn": "CN201210526419.1",
    "apd": "2012-12-07",
    "apy": "2012",
    "tit": "通过移动终端生成三维图像的方法及移动终端",
    "inv": [
      "吴文升"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN103049908B",
    "apn": "CN201210530758.7",
    "apd": "2012-12-10",
    "apy": "2012",
    "tit": "生成立体视频文件的方法和装置",
    "inv": [
      "张志辉"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN103034714A",
    "apn": "CN201210533935.7",
    "apd": "2012-12-11",
    "apy": "2012",
    "tit": "移动终端的照片分类管理方法、装置和移动终端",
    "inv": [
      "张志辉"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103020231B",
    "apn": "CN201210543868.7",
    "apd": "2012-12-14",
    "apy": "2012",
    "tit": "将图片的局部特征量化为视觉词汇的方法和装置",
    "inv": [
      "李浩"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN103106335B",
    "apn": "CN201210591321.4",
    "apd": "2012-12-29",
    "apy": "2012",
    "tit": "通过移动终端记录并显示三维视图的方法、装置和移动终端",
    "inv": [
      "吴文升"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN103079034A",
    "apn": "CN201310003567.X",
    "apd": "2013-01-06",
    "apy": "2013",
    "tit": "一种感知拍摄方法及系统",
    "inv": [
      "韩钧宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103177100B",
    "apn": "CN201310090564.4",
    "apd": "2013-03-20",
    "apy": "2013",
    "tit": "搜索成套图像的方法和设备",
    "inv": [
      "丁锐",
      "付晴川",
      "郭荣锋",
      "刘婷婷",
      "陶哲"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103177110B",
    "apn": "CN201310105195.1",
    "apd": "2013-03-28",
    "apy": "2013",
    "tit": "搜索成套图像的方法和设备",
    "inv": [
      "郭荣锋",
      "陶哲",
      "丁锐",
      "宁贵文"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103226443A",
    "apn": "CN201310113383.9",
    "apd": "2013-04-02",
    "apy": "2013",
    "tit": "智能眼镜的控制方法、装置和智能眼镜",
    "inv": [
      "詹君"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103246347A",
    "apn": "CN201310113384.3",
    "apd": "2013-04-02",
    "apy": "2013",
    "tit": "控制方法、装置和终端",
    "inv": [
      "詹君"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104103038A",
    "apn": "CN201310116351.4",
    "apd": "2013-04-03",
    "apy": "2013",
    "tit": "基于时间戳的数码照片处理方法与装置",
    "inv": [
      "卫超"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104112334B",
    "apn": "CN201310132259.7",
    "apd": "2013-04-16",
    "apy": "2013",
    "tit": "疲劳驾驶预警方法及系统",
    "inv": [
      "文庆",
      "陈超超",
      "袁梦龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103294779A",
    "apn": "CN201310175516.5",
    "apd": "2013-05-13",
    "apy": "2013",
    "tit": "对象信息获取方法及设备",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103237108B",
    "apn": "CN201310175680.6",
    "apd": "2013-05-13",
    "apy": "2013",
    "tit": "用于移动终端的测试方法和测试终端",
    "inv": [
      "邓呈亮",
      "杨鹏",
      "王浙航"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104180814A",
    "apn": "CN201310193998.7",
    "apd": "2013-05-22",
    "apy": "2013",
    "tit": "移动终端上实景功能中的导航方法和电子地图客户端",
    "inv": [
      "刘鑫"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN103324682B",
    "apn": "CN201310214861.5",
    "apd": "2013-05-31",
    "apy": "2013",
    "tit": "一种基于图片来搜索视频的方法及装置",
    "inv": [
      "陈应明",
      "王瑾",
      "吴中勤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103324728B",
    "apn": "CN201310261157.5",
    "apd": "2013-06-26",
    "apy": "2013",
    "tit": "移动终端的应用程序查找方法和装置",
    "inv": [
      "朱振广",
      "黎樵",
      "谢东卫"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "APP图标",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103345516A",
    "apn": "CN201310288939.8",
    "apd": "2013-07-10",
    "apy": "2013",
    "tit": "基于应用程序图标的应用程序搜索方法和系统",
    "inv": [
      "张琳琳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "APP图标",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN103390282B",
    "apn": "CN201310325576.0",
    "apd": "2013-07-30",
    "apy": "2013",
    "tit": "图像标注方法及其装置",
    "inv": [
      "韩钧宇",
      "都大龙",
      "陶吉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103632388A",
    "apn": "CN201310703723.3",
    "apd": "2013-12-19",
    "apy": "2013",
    "tit": "图像语义标注的方法、装置和客户端",
    "inv": [
      "潘屹峰",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103647922A",
    "apn": "CN201310714667.3",
    "apd": "2013-12-20",
    "apy": "2013",
    "tit": "虚拟视频通话方法和终端",
    "inv": [
      "李刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103810248B",
    "apn": "CN201410023625.X",
    "apd": "2014-01-17",
    "apy": "2014",
    "tit": "基于照片查找人际关系的方法和装置",
    "inv": [
      "王磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103810475B",
    "apn": "CN201410056752.X",
    "apd": "2014-02-19",
    "apy": "2014",
    "tit": "一种目标物识别方法及装置",
    "inv": [
      "艾锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN103870593A",
    "apn": "CN201410125934.8",
    "apd": "2014-03-31",
    "apy": "2014",
    "tit": "物体的关联信息的处理方法及装置",
    "inv": [
      "牛章鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103914689B",
    "apn": "CN201410140768.9",
    "apd": "2014-04-09",
    "apy": "2014",
    "tit": "基于人脸识别的图片裁剪方法及装置",
    "inv": [
      "陈柄辰",
      "邓亚峰",
      "陈岳峰",
      "牛正雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103986981B",
    "apn": "CN201410148997.5",
    "apd": "2014-04-14",
    "apy": "2014",
    "tit": "多媒体文件的情节片段的识别方法及装置",
    "inv": [
      "由清圳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103945001A",
    "apn": "CN201410187096.7",
    "apd": "2014-05-05",
    "apy": "2014",
    "tit": "一种图片分享方法及装置",
    "inv": [
      "张成栋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103971126B",
    "apn": "CN201410199373.6",
    "apd": "2014-05-12",
    "apy": "2014",
    "tit": "一种交通标志识别方法和装置",
    "inv": [
      "艾锐",
      "郎咸朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN103970906B",
    "apn": "CN201410228398.4",
    "apd": "2014-05-27",
    "apy": "2014",
    "tit": "视频标签的建立方法和装置、视频内容的显示方法和装置",
    "inv": [
      "高浩渊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104063444A",
    "apn": "CN201410265913.6",
    "apd": "2014-06-13",
    "apy": "2014",
    "tit": "缩略图的生成方法和装置",
    "inv": [
      "李颖超",
      "黄明江",
      "荣岳成"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104156694B",
    "apn": "CN201410342455.1",
    "apd": "2014-07-18",
    "apy": "2014",
    "tit": "一种用于识别图像中的目标对象的方法与设备",
    "inv": [
      "丁二锐",
      "潘屹峰",
      "韩钧宇",
      "陈世佳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104133899B",
    "apn": "CN201410377461.0",
    "apd": "2014-08-01",
    "apy": "2014",
    "tit": "图片搜索库的生成方法和装置、图片搜索方法和装置",
    "inv": [
      "秦铎浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104133917B",
    "apn": "CN201410403833.2",
    "apd": "2014-08-15",
    "apy": "2014",
    "tit": "照片的分类存储方法及装置",
    "inv": [
      "高浩渊",
      "吴锡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN104199542A",
    "apn": "CN201410412560.8",
    "apd": "2014-08-20",
    "apy": "2014",
    "tit": "一种智能镜实现方法、装置和智能镜",
    "inv": [
      "秦铎浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104239433B",
    "apn": "CN201410429394.2",
    "apd": "2014-08-27",
    "apy": "2014",
    "tit": "可联网冰箱及其信息推荐方法和系统",
    "inv": [
      "彭晶鑫"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104239824A",
    "apn": "CN201410428245.4",
    "apd": "2014-08-27",
    "apy": "2014",
    "tit": "一种用于显示输入信息的方法和装置",
    "inv": [
      "张海云"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104268504B",
    "apn": "CN201410444197.8",
    "apd": "2014-09-02",
    "apy": "2014",
    "tit": "图片识别方法和装置",
    "inv": [
      "国玉晶",
      "徐饶",
      "李颖超",
      "李浩",
      "钟升达",
      "田甜",
      "许笑天"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104268168A",
    "apn": "CN201410459381.X",
    "apd": "2014-09-10",
    "apy": "2014",
    "tit": "一种向用户推送信息的方法与装置",
    "inv": [
      "肖伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "信息嵌入",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN104318562B",
    "apn": "CN201410568355.0",
    "apd": "2014-10-22",
    "apy": "2014",
    "tit": "一种用于确定互联网图像的质量的方法和装置",
    "inv": [
      "徐崴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN104361024A",
    "apn": "CN201410566603.8",
    "apd": "2014-10-22",
    "apy": "2014",
    "tit": "公交信息查询、反馈方法、移动终端以及服务器",
    "inv": [
      "李雪韬",
      "马娜",
      "刘富恩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104318218A",
    "apn": "CN201410594523.3",
    "apd": "2014-10-29",
    "apy": "2014",
    "tit": "图像识别方法和装置",
    "inv": [
      "顾嘉唯",
      "余凯"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104408743A",
    "apn": "CN201410618207.5",
    "apd": "2014-11-05",
    "apy": "2014",
    "tit": "图像分割方法和装置",
    "inv": [
      "顾嘉唯",
      "余轶南",
      "王睿",
      "余凯"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN104392438A",
    "apn": "CN201410641092.1",
    "apd": "2014-11-13",
    "apy": "2014",
    "tit": "一种图像对比方法及装置",
    "inv": [
      "袁正海"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104361572B",
    "apn": "CN201410709150.X",
    "apd": "2014-11-27",
    "apy": "2014",
    "tit": "图片处理方法和装置",
    "inv": [
      "汤巍敏",
      "刘子曜",
      "黄甜甜",
      "郑鑫",
      "徐娇茹",
      "李雅杰",
      "武毅",
      "华阔",
      "王金莹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104615640A",
    "apn": "CN201410714751.X",
    "apd": "2014-11-28",
    "apy": "2014",
    "tit": "一种用于提供搜索关键词及进行搜索的方法与装置",
    "inv": [
      "鲁晓宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104462468A",
    "apn": "CN201410788462.4",
    "apd": "2014-12-17",
    "apy": "2014",
    "tit": "信息提供方法及装置",
    "inv": [
      "李华明",
      "王道龙",
      "刘晓伟",
      "刘孟",
      "袁艺",
      "张钰",
      "易敏文",
      "吕文玉",
      "方驰",
      "袁正沧",
      "燕宇飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104573440A",
    "apn": "CN201410797763.3",
    "apd": "2014-12-18",
    "apy": "2014",
    "tit": "一种数据查看方法及装置",
    "inv": [
      "吕文玉",
      "李军",
      "王道龙",
      "刘孟",
      "易敏文",
      "李华明",
      "靳茵茵"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104504104B",
    "apn": "CN201410841895.1",
    "apd": "2014-12-30",
    "apy": "2014",
    "tit": "用于搜索引擎的图片物料处理方法、装置和搜索引擎",
    "inv": [
      "秦首科",
      "张泽明",
      "韩友",
      "江焱",
      "陈志扬",
      "程小华",
      "徐培治",
      "马小林",
      "文石磊",
      "陈世佳",
      "李旭斌",
      "陈敏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104504107B",
    "apn": "CN201410843272.8",
    "apd": "2014-12-30",
    "apy": "2014",
    "tit": "搜索图片的展示方法和装置",
    "inv": [
      "陈志扬",
      "秦首科",
      "韩友",
      "张泽明",
      "文石磊",
      "李旭斌",
      "陈世佳",
      "徐培治",
      "程小华",
      "江焱",
      "马小林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104573715B",
    "apn": "CN201410850511.2",
    "apd": "2014-12-30",
    "apy": "2014",
    "tit": "图像主体区域的识别方法及装置",
    "inv": [
      "陈世佳",
      "李旭斌",
      "文石磊",
      "余轶南"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104504712B",
    "apn": "CN201410842993.7",
    "apd": "2014-12-30",
    "apy": "2014",
    "tit": "图片处理方法和装置",
    "inv": [
      "李旭斌",
      "陈世佳",
      "文石磊",
      "秦首科",
      "张泽明",
      "韩友",
      "江焱",
      "陈志扬"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN104572996B",
    "apn": "CN201510006287.3",
    "apd": "2015-01-06",
    "apy": "2015",
    "tit": "视频网页的处理方法和装置",
    "inv": [
      "鲁晓莹",
      "李进",
      "刘世戟",
      "刘鸿宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "百度",
    "pun": "CN104573675B",
    "apn": "CN201510047554.1",
    "apd": "2015-01-29",
    "apy": "2015",
    "tit": "作业图像的展示方法和装置",
    "inv": [
      "梁爽"
    ],
    "app": [
      "作业帮教育科技(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104679839B",
    "apn": "CN201510069649.3",
    "apd": "2015-02-10",
    "apy": "2015",
    "tit": "信息推送方法和装置",
    "inv": [
      "李阳",
      "顾嘉唯",
      "余凯"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104637035B",
    "apn": "CN201510082774.8",
    "apd": "2015-02-15",
    "apy": "2015",
    "tit": "生成卡通人脸图片的方法、装置及系统",
    "inv": [
      "张肖男",
      "张渔樵",
      "王卜乐",
      "袁才镭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104915634B",
    "apn": "CN201510085058.5",
    "apd": "2015-02-16",
    "apy": "2015",
    "tit": "基于人脸识别技术的图像生成方法和装置",
    "inv": [
      "王卜乐",
      "朱福国"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104715447B",
    "apn": "CN201510092998.7",
    "apd": "2015-03-02",
    "apy": "2015",
    "tit": "图像合成方法和装置",
    "inv": [
      "王卜乐",
      "朱福国"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104751406B",
    "apn": "CN201510106570.3",
    "apd": "2015-03-11",
    "apy": "2015",
    "tit": "一种用于对图像进行虚化的方法和装置",
    "inv": [
      "齐飞",
      "徐崴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104751407B",
    "apn": "CN201510106876.9",
    "apd": "2015-03-11",
    "apy": "2015",
    "tit": "一种用于对图像进行虚化的方法和装置",
    "inv": [
      "齐飞",
      "徐崴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104751405B",
    "apn": "CN201510106567.1",
    "apd": "2015-03-11",
    "apy": "2015",
    "tit": "一种用于对图像进行虚化的方法和装置",
    "inv": [
      "齐飞",
      "徐崴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104751454B",
    "apn": "CN201510106593.4",
    "apd": "2015-03-11",
    "apy": "2015",
    "tit": "一种用于确定图像中的人物轮廓的方法和装置",
    "inv": [
      "齐飞",
      "徐崴",
      "王飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN104820770B",
    "apn": "CN201510134619.6",
    "apd": "2015-03-25",
    "apy": "2015",
    "tit": "一种用于提供健康指示的方法和装置",
    "inv": [
      "冯树勋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104700099B",
    "apn": "CN201510150525.8",
    "apd": "2015-03-31",
    "apy": "2015",
    "tit": "识别交通标志的方法和装置",
    "inv": [
      "郝志会",
      "王宁",
      "葛雷鸣"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104766058B",
    "apn": "CN201510150532.8",
    "apd": "2015-03-31",
    "apy": "2015",
    "tit": "一种获取车道线的方法和装置",
    "inv": [
      "关书伟",
      "姜雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104715449A",
    "apn": "CN201510149873.3",
    "apd": "2015-03-31",
    "apy": "2015",
    "tit": "生成马赛克图像的方法和装置",
    "inv": [
      "简荣军",
      "郎咸朋",
      "刘丽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN104735520A",
    "apn": "CN201510152782.5",
    "apd": "2015-04-01",
    "apy": "2015",
    "tit": "电视节目的播放控制方法、装置和电视机",
    "inv": [
      "张志辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104766401A",
    "apn": "CN201510209592.2",
    "apd": "2015-04-28",
    "apy": "2015",
    "tit": "智能钱包以及用于智能钱包钱币识别的方法及装置",
    "inv": [
      "吕文玉",
      "王道龙",
      "刘孟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "金融",
    "l2": "现金统计",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104856385B",
    "apn": "CN201510209584.8",
    "apd": "2015-04-28",
    "apy": "2015",
    "tit": "智能钱包和基于其的用户账户信息获取方法",
    "inv": [
      "袁艺",
      "王道龙",
      "刘晓伟",
      "李华明",
      "刘孟",
      "燕宇飞",
      "张钰",
      "易敏文",
      "吕文玉",
      "袁正沧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "金融",
    "l2": "现金统计",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104783429B",
    "apn": "CN201510209663.9",
    "apd": "2015-04-28",
    "apy": "2015",
    "tit": "智能钱包及其用于确定余额的方法和装置",
    "inv": [
      "易敏文",
      "袁正沧",
      "吕文玉",
      "燕宇飞",
      "袁艺",
      "王道龙",
      "李华明",
      "刘晓伟",
      "刘孟",
      "张钰",
      "方驰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "金融",
    "l2": "现金统计",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104866818B",
    "apn": "CN201510213317.8",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "一种用于搜索包含人脸的图片的方法和装置",
    "inv": [
      "宁京",
      "张雯",
      "王璐",
      "高巍",
      "李睿",
      "刘友斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN104850600B",
    "apn": "CN201510213855.7",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "一种用于搜索包含人脸的图片的方法和装置",
    "inv": [
      "宁京",
      "张雯",
      "王璐",
      "高巍",
      "李睿",
      "刘友斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN104866564B",
    "apn": "CN201510262791.X",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "图片搜索方法及装置",
    "inv": [
      "阎彤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN104866565B",
    "apn": "CN201510263492.8",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "图片搜索方法及装置",
    "inv": [
      "阎彤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104881457B",
    "apn": "CN201510262761.9",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "图片搜索方法及装置",
    "inv": [
      "阎彤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN104866194B",
    "apn": "CN201510262908.4",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "图片搜索方法及装置",
    "inv": [
      "阎彤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN105095343A",
    "apn": "CN201510280817.3",
    "apd": "2015-05-28",
    "apy": "2015",
    "tit": "信息处理方法、信息显示方法及装置",
    "inv": [
      "陈晓昕"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104933297A",
    "apn": "CN201510290156.2",
    "apd": "2015-05-29",
    "apy": "2015",
    "tit": "一种为用户提供治疗建议信息的方法与装置",
    "inv": [
      "白振龙",
      "李军",
      "白凯",
      "罗云峰",
      "韩基超",
      "罗建鼎",
      "裴子成",
      "靳茵茵",
      "陈敬林",
      "李江利"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104899039B",
    "apn": "CN201510325704.0",
    "apd": "2015-06-12",
    "apy": "2015",
    "tit": "用于在终端设备上提供截屏服务的方法和装置",
    "inv": [
      "陈聪",
      "宋晔"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "百度",
    "pun": "CN104899306B",
    "apn": "CN201510324860.5",
    "apd": "2015-06-12",
    "apy": "2015",
    "tit": "信息处理方法、信息显示方法及装置",
    "inv": [
      "陈晓昕"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104992146B",
    "apn": "CN201510330825.4",
    "apd": "2015-06-15",
    "apy": "2015",
    "tit": "一种用于人脸识别的方法和装置",
    "inv": [
      "李睿",
      "王璐",
      "刘友斌",
      "高巍",
      "张雯",
      "宁京"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN105100435A",
    "apn": "CN201510330011.0",
    "apd": "2015-06-15",
    "apy": "2015",
    "tit": "移动通讯应用方法和装置",
    "inv": [
      "李丰",
      "刘政"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105094597A",
    "apn": "CN201510342536.6",
    "apd": "2015-06-18",
    "apy": "2015",
    "tit": "一种用于批量选择图片的方法和装置",
    "inv": [
      "金璐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104899146B",
    "apn": "CN201510345814.3",
    "apd": "2015-06-19",
    "apy": "2015",
    "tit": "基于图像匹配技术的软件稳定性测试方法和装置",
    "inv": [
      "李彦成",
      "彭云鹏",
      "赵欢"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN104978586A",
    "apn": "CN201510367665.0",
    "apd": "2015-06-29",
    "apy": "2015",
    "tit": "商标识别方法和装置",
    "inv": [
      "刘辉",
      "邓玥琳",
      "罗祥凤",
      "程涛远"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105116920B",
    "apn": "CN201510394607.7",
    "apd": "2015-07-07",
    "apy": "2015",
    "tit": "基于人工智能的智能机器人追踪方法、装置及智能机器人",
    "inv": [
      "戴腾",
      "李江勇",
      "王昕煜",
      "李峥",
      "苏航",
      "葛行飞",
      "李佳霖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105117400B",
    "apn": "CN201510397677.8",
    "apd": "2015-07-08",
    "apy": "2015",
    "tit": "信息搜索方法和系统",
    "inv": [
      "张弛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105184204B",
    "apn": "CN201510405673.X",
    "apd": "2015-07-10",
    "apy": "2015",
    "tit": "一种物品识别装置、智能冰箱及用户终端",
    "inv": [
      "郭维"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104951966A",
    "apn": "CN201510408418.0",
    "apd": "2015-07-13",
    "apy": "2015",
    "tit": "推荐服饰商品的方法及装置",
    "inv": [
      "陶海亮",
      "范旭昂",
      "李远頔",
      "陆广",
      "孟骧龙",
      "严灿祥",
      "刘国翌",
      "黄婉瑜",
      "韩树民",
      "祖峥",
      "李浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN105469087B",
    "apn": "CN201510408391.5",
    "apd": "2015-07-13",
    "apy": "2015",
    "tit": "识别服饰图片的方法、服饰图片的标注方法及装置",
    "inv": [
      "孟骧龙",
      "刘国翌",
      "韩树民",
      "严灿祥",
      "杜宇宁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN104985599B",
    "apn": "CN201510428895.3",
    "apd": "2015-07-20",
    "apy": "2015",
    "tit": "基于人工智能的智能机器人控制方法、系统及智能机器人",
    "inv": [
      "王志昊",
      "葛行飞",
      "李福祥",
      "来杰",
      "孟超超"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105095446A",
    "apn": "CN201510441121.4",
    "apd": "2015-07-24",
    "apy": "2015",
    "tit": "药品的搜索处理方法、服务器及终端设备",
    "inv": [
      "孙舒",
      "张娜",
      "王珍",
      "郄丽晨",
      "戴蕾",
      "陈凯",
      "杨文博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN105069063A",
    "apn": "CN201510453753.2",
    "apd": "2015-07-29",
    "apy": "2015",
    "tit": "图片搜索方法及装置",
    "inv": [
      "侯思宇",
      "姜岩",
      "许笑天"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105139445B",
    "apn": "CN201510483318.4",
    "apd": "2015-08-03",
    "apy": "2015",
    "tit": "场景重建方法及装置",
    "inv": [
      "杜堂武",
      "艾锐",
      "蒋昭炎",
      "刘丽",
      "郎咸朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105046235B",
    "apn": "CN201510482990.1",
    "apd": "2015-08-03",
    "apy": "2015",
    "tit": "车道线的识别建模方法和装置、识别方法和装置",
    "inv": [
      "何贝",
      "郝志会"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105069842A",
    "apn": "CN201510481925.7",
    "apd": "2015-08-03",
    "apy": "2015",
    "tit": "道路三维模型的建模方法和装置",
    "inv": [
      "贾相飞",
      "晏阳",
      "王睿索"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105138618A",
    "apn": "CN201510490874.4",
    "apd": "2015-08-11",
    "apy": "2015",
    "tit": "在图像搜索中结合语音进行二次搜索的方法和装置",
    "inv": [
      "董华磊",
      "张莉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106485639A",
    "apn": "CN201510541216.3",
    "apd": "2015-08-28",
    "apy": "2015",
    "tit": "鉴别伪造证件图片的方法和装置",
    "inv": [
      "邓玥琳",
      "黄浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105184249B",
    "apn": "CN201510543688.2",
    "apd": "2015-08-28",
    "apy": "2015",
    "tit": "用于人脸图像处理的方法和装置",
    "inv": [
      "李广",
      "顾嘉唯",
      "言莎莎",
      "郝健平",
      "何嘉斌",
      "徐力",
      "刘经拓",
      "朱福国",
      "魏正平"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN105183827A",
    "apn": "CN201510541902.0",
    "apd": "2015-08-28",
    "apy": "2015",
    "tit": "基于手写输入的搜索方法及装置",
    "inv": [
      "蔡贇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105095919A",
    "apn": "CN201510567452.2",
    "apd": "2015-09-08",
    "apy": "2015",
    "tit": "图像识别方法和装置",
    "inv": [
      "龚龙",
      "张彦福",
      "顾嘉唯"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105260988B",
    "apn": "CN201510571925.6",
    "apd": "2015-09-09",
    "apy": "2015",
    "tit": "一种高精地图数据的处理方法和装置",
    "inv": [
      "蒋昭炎",
      "关书伟",
      "宋良",
      "晏阳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN105260699B",
    "apn": "CN201510574875.7",
    "apd": "2015-09-10",
    "apy": "2015",
    "tit": "一种车道线数据的处理方法及装置",
    "inv": [
      "晏涛",
      "王睿索",
      "宋良",
      "蒋昭炎",
      "晏阳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105205462A",
    "apn": "CN201510599253.X",
    "apd": "2015-09-18",
    "apy": "2015",
    "tit": "一种拍照提示方法及装置",
    "inv": [
      "王福健",
      "朱福国",
      "丁二锐",
      "龚龙",
      "邓亚峰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105139750B",
    "apn": "CN201510641652.8",
    "apd": "2015-09-30",
    "apy": "2015",
    "tit": "电子地图的展示方法和装置",
    "inv": [
      "张婧",
      "孙晔"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105354288A",
    "apn": "CN201510729746.0",
    "apd": "2015-10-30",
    "apy": "2015",
    "tit": "一种基于视频内容的图像搜索方法和装置",
    "inv": [
      "赵德玺",
      "尚会波"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN106708850A",
    "apn": "CN201510777584.8",
    "apd": "2015-11-13",
    "apy": "2015",
    "tit": "基于图像模板的图像生成方法和装置",
    "inv": [
      "刘俊启"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN106776619A",
    "apn": "CN201510813083.0",
    "apd": "2015-11-20",
    "apy": "2015",
    "tit": "用于确定目标对象的属性信息的方法和装置",
    "inv": [
      "高福亮",
      "侯文",
      "李冰冰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN105426861B",
    "apn": "CN201510872935.3",
    "apd": "2015-12-02",
    "apy": "2015",
    "tit": "车道线确定方法及装置",
    "inv": [
      "何贝",
      "晏涛",
      "晏阳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN105472337B",
    "apn": "CN201510917464.3",
    "apd": "2015-12-10",
    "apy": "2015",
    "tit": "图像监控方法及装置",
    "inv": [
      "赵晨",
      "高浩渊",
      "王睿"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105590098A",
    "apn": "CN201510970272.9",
    "apd": "2015-12-22",
    "apy": "2015",
    "tit": "图像识别方法及装置",
    "inv": [
      "李广"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN105631906A",
    "apn": "CN201510980500.0",
    "apd": "2015-12-23",
    "apy": "2015",
    "tit": "三维地图图像的纹理压缩方法和装置",
    "inv": [
      "蒋昭炎",
      "王睿索",
      "晏阳",
      "贾相飞",
      "晏涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105678689A",
    "apn": "CN201511029120.5",
    "apd": "2015-12-31",
    "apy": "2015",
    "tit": "高精地图数据配准关系确定方法及装置",
    "inv": [
      "蒋昭炎",
      "晏涛",
      "晏阳",
      "姜雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN105528588B",
    "apn": "CN201511021605.X",
    "apd": "2015-12-31",
    "apy": "2015",
    "tit": "一种车道线识别方法及装置",
    "inv": [
      "蒋斌",
      "蒋昭炎",
      "晏涛",
      "何贝"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105678322A",
    "apn": "CN201511025974.6",
    "apd": "2015-12-31",
    "apy": "2015",
    "tit": "样本标注方法和装置",
    "inv": [
      "何贝",
      "关书伟",
      "宋良",
      "贾相飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105631880B",
    "apn": "CN201511023177.4",
    "apd": "2015-12-31",
    "apy": "2015",
    "tit": "车道线分割方法和装置",
    "inv": [
      "何贝",
      "晏涛",
      "晏阳",
      "贾相飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN105701449B",
    "apn": "CN201511029274.4",
    "apd": "2015-12-31",
    "apy": "2015",
    "tit": "路面上的车道线的检测方法和装置",
    "inv": [
      "晏涛",
      "蒋昭炎",
      "王睿索",
      "晏阳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105653701B",
    "apn": "CN201511025975.0",
    "apd": "2015-12-31",
    "apy": "2015",
    "tit": "模型生成方法及装置、词语赋权方法及装置",
    "inv": [
      "邹红建",
      "方高林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN105718562A",
    "apn": "CN201610037780.6",
    "apd": "2016-01-20",
    "apy": "2016",
    "tit": "基于图片的信息搜索方法和装置",
    "inv": [
      "郏夹"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105739888B",
    "apn": "CN201610052344.6",
    "apd": "2016-01-26",
    "apy": "2016",
    "tit": "一种用于配置虚拟键盘动态显示效果的方法与装置",
    "inv": [
      "杨林达",
      "陈丹凤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107016004A",
    "apn": "CN201610060953.6",
    "apd": "2016-01-28",
    "apy": "2016",
    "tit": "图像处理方法及装置",
    "inv": [
      "王璐",
      "张雯",
      "宁京",
      "李睿",
      "高巍",
      "刘友斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN105763543B",
    "apn": "CN201610076991.0",
    "apd": "2016-02-03",
    "apy": "2016",
    "tit": "一种识别钓鱼网站的方法及装置",
    "inv": [
      "安子岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105786995A",
    "apn": "CN201610091257.1",
    "apd": "2016-02-18",
    "apy": "2016",
    "tit": "筛选多媒体素材的方法和装置",
    "inv": [
      "刘辉",
      "邓玥琳",
      "罗祥凤",
      "丁飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107133629A",
    "apn": "CN201610112353.X",
    "apd": "2016-02-29",
    "apy": "2016",
    "tit": "图片分类方法、装置和移动终端",
    "inv": [
      "王璐",
      "张雯",
      "宁京",
      "李睿",
      "高巍",
      "刘友斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105825228B",
    "apn": "CN201610143523.0",
    "apd": "2016-03-14",
    "apy": "2016",
    "tit": "图像识别方法及装置",
    "inv": [
      "刘国翌",
      "李广"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107205172A",
    "apn": "CN201610158803.9",
    "apd": "2016-03-18",
    "apy": "2016",
    "tit": "一种基于视频内容发起搜索的方法及装置",
    "inv": [
      "陈悦",
      "尚会波"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN105868772A",
    "apn": "CN201610170233.5",
    "apd": "2016-03-23",
    "apy": "2016",
    "tit": "图像识别方法及装置",
    "inv": [
      "赵晨",
      "高浩渊",
      "梁继"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105893565B",
    "apn": "CN201610201890.1",
    "apd": "2016-03-31",
    "apy": "2016",
    "tit": "三维地图数据库的建立、三维地图数据的传输方法及装置",
    "inv": [
      "洪祈泽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105913483A",
    "apn": "CN201610201453.X",
    "apd": "2016-03-31",
    "apy": "2016",
    "tit": "一种三维交叉道路模型生成的方法及装置",
    "inv": [
      "孙晔",
      "阳慧蓉",
      "张静"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105894575B",
    "apn": "CN201610201858.3",
    "apd": "2016-03-31",
    "apy": "2016",
    "tit": "道路的三维建模方法和装置",
    "inv": [
      "孙晔"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN105741643A",
    "apn": "CN201610248895.X",
    "apd": "2016-04-20",
    "apy": "2016",
    "tit": "用于培训驾驶技术的自动化实现方法、装置和系统",
    "inv": [
      "孟超超"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105719499B",
    "apn": "CN201610252204.3",
    "apd": "2016-04-21",
    "apy": "2016",
    "tit": "交通标志识别测试方法和装置",
    "inv": [
      "何军"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105956527B",
    "apn": "CN201610258223.7",
    "apd": "2016-04-22",
    "apy": "2016",
    "tit": "用于无人驾驶车的障碍物检测结果评估方法和装置",
    "inv": [
      "谭楚亭",
      "徐益"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105956596A",
    "apn": "CN201610264685.X",
    "apd": "2016-04-26",
    "apy": "2016",
    "tit": "图片反作弊方法及装置",
    "inv": [
      "常鑫"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105975980B",
    "apn": "CN201610269546.6",
    "apd": "2016-04-27",
    "apy": "2016",
    "tit": "监控图像标注质量的方法和装置",
    "inv": [
      "韩枫慧",
      "杨金钼",
      "张亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105957393B",
    "apn": "CN201610282333.7",
    "apd": "2016-04-29",
    "apy": "2016",
    "tit": "一种停车位的识别方法及装置",
    "inv": [
      "朱重黎",
      "武大权"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN105975939B",
    "apn": "CN201610298495.X",
    "apd": "2016-05-06",
    "apy": "2016",
    "tit": "视频检测方法和装置",
    "inv": [
      "申晓雷",
      "宋丙玉",
      "王源"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN105930841B",
    "apn": "CN201610320009.X",
    "apd": "2016-05-13",
    "apy": "2016",
    "tit": "对图像进行自动语义标注的方法、装置与计算机设备",
    "inv": [
      "刘霄",
      "王江",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106067202A",
    "apn": "CN201610371875.1",
    "apd": "2016-05-30",
    "apy": "2016",
    "tit": "货柜存取系统和方法",
    "inv": [
      "张钊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107452002A",
    "apn": "CN201610377503.X",
    "apd": "2016-05-31",
    "apy": "2016",
    "tit": "一种图像分割方法及装置",
    "inv": [
      "屈冰欣",
      "曾刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN106096574A",
    "apn": "CN201610471248.5",
    "apd": "2016-06-24",
    "apy": "2016",
    "tit": "屏幕图像的识别方法及装置",
    "inv": [
      "刘希",
      "刘晓娟",
      "张益",
      "柳梅",
      "刘社定"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN106203465A",
    "apn": "CN201610474170.2",
    "apd": "2016-06-24",
    "apy": "2016",
    "tit": "一种基于图像识别生成曲谱的方法及装置",
    "inv": [
      "刘霄",
      "文石磊",
      "乔慧",
      "刁雪飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106203341B",
    "apn": "CN201610543320.0",
    "apd": "2016-07-11",
    "apy": "2016",
    "tit": "一种无人车的车道线识别方法及装置",
    "inv": [
      "谭楚亭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107703931B",
    "apn": "CN201610647814.3",
    "apd": "2016-08-09",
    "apy": "2016",
    "tit": "用于控制无人驾驶车辆的方法和装置",
    "inv": [
      "张钊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106447660B",
    "apn": "CN201610856323.X",
    "apd": "2016-09-27",
    "apy": "2016",
    "tit": "图片检测方法和装置",
    "inv": [
      "刘霄",
      "文石磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN106297353B",
    "apn": "CN201610909222.4",
    "apd": "2016-10-18",
    "apy": "2016",
    "tit": "交通工具的自动驾驶方法及装置",
    "inv": [
      "李雅杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108009176A",
    "apn": "CN201610961340.X",
    "apd": "2016-10-28",
    "apy": "2016",
    "tit": "基于AR技术的说明书展示方法、装置及设备",
    "inv": [
      "张钰",
      "李军",
      "王道龙",
      "刘晓伟",
      "袁艺",
      "易敏文",
      "燕宇飞",
      "吕文玉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108074637A",
    "apn": "CN201610980293.3",
    "apd": "2016-11-08",
    "apy": "2016",
    "tit": "导诊方法及导诊系统",
    "inv": [
      "杨书宾",
      "张广钦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107315988A",
    "apn": "CN201611001907.5",
    "apd": "2016-11-14",
    "apy": "2016",
    "tit": "用于在无人驾驶车辆中呈现媒体内容的系统和方法",
    "inv": [
      "李力耘",
      "刘少山",
      "吴双",
      "彭军"
    ],
    "app": [
      "百度(美国)有限责任公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107369207A",
    "apn": "CN201611014753.3",
    "apd": "2016-11-15",
    "apy": "2016",
    "tit": "在无人驾驶车辆中提供增强虚拟现实内容的系统和方法",
    "inv": [
      "王全",
      "马骉",
      "刘少山",
      "彭军"
    ],
    "app": [
      "百度(美国)有限责任公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108074278A",
    "apn": "CN201611024592.6",
    "apd": "2016-11-17",
    "apy": "2016",
    "tit": "视频呈现方法、装置和设备",
    "inv": [
      "陈鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN106707293B",
    "apn": "CN201611088130.0",
    "apd": "2016-12-01",
    "apy": "2016",
    "tit": "用于车辆的障碍物识别方法和装置",
    "inv": [
      "胡太群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106776996B",
    "apn": "CN201611112125.9",
    "apd": "2016-12-02",
    "apy": "2016",
    "tit": "用于测试高精度地图的准确性的方法和装置",
    "inv": [
      "韩枫慧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN106682124A",
    "apn": "CN201611130686.1",
    "apd": "2016-12-09",
    "apy": "2016",
    "tit": "一种图片识别方法、装置和设备",
    "inv": [
      "徐英博",
      "李雪城"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106779089A",
    "apn": "CN201611147550.1",
    "apd": "2016-12-13",
    "apy": "2016",
    "tit": "测试方法和装置",
    "inv": [
      "胡太群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108230220A",
    "apn": "CN201611158621.8",
    "apd": "2016-12-15",
    "apy": "2016",
    "tit": "水印添加方法和装置",
    "inv": [
      "杜长春"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108205409A",
    "apn": "CN201611168865.4",
    "apd": "2016-12-16",
    "apy": "2016",
    "tit": "用于调整虚拟场景的方法和装置以及设备",
    "inv": [
      "李丰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN106777177A",
    "apn": "CN201611198641.8",
    "apd": "2016-12-22",
    "apy": "2016",
    "tit": "检索方法和装置",
    "inv": [
      "齐慧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN106682632A",
    "apn": "CN201611258315.1",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "用于处理人脸图像的方法和装置",
    "inv": [
      "姚淼",
      "吴中勤",
      "乔慧",
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107066082B",
    "apn": "CN201611254677.3",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "显示方法和装置",
    "inv": [
      "吴中勤",
      "乔慧",
      "李颖超",
      "应倩",
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "实验展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN106744096A",
    "apn": "CN201710046626.X",
    "apd": "2017-01-20",
    "apy": "2017",
    "tit": "电梯运行的控制方法及装置",
    "inv": [
      "崔红伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "电梯",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106844685A",
    "apn": "CN201710057271.4",
    "apd": "2017-01-26",
    "apy": "2017",
    "tit": "用于识别网站的方法、装置及服务器",
    "inv": [
      "邹红建",
      "方高林",
      "付立波"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106951433A",
    "apn": "CN201710064428.6",
    "apd": "2017-02-04",
    "apy": "2017",
    "tit": "一种检索方法及装置",
    "inv": [
      "毛尚勤",
      "李小松",
      "李志纲",
      "郭伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106934107A",
    "apn": "CN201710089333.X",
    "apd": "2017-02-20",
    "apy": "2017",
    "tit": "交通出行场景构建方法、装置、设备及存储介质",
    "inv": [
      "茹强"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN106910250A",
    "apn": "CN201710113953.2",
    "apd": "2017-02-28",
    "apy": "2017",
    "tit": "用于在虚拟现实场景中操作页面的方法和装置",
    "inv": [
      "赵俊博",
      "李晓男",
      "于秋龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN106910210B",
    "apn": "CN201710124284.9",
    "apd": "2017-03-03",
    "apy": "2017",
    "tit": "用于生成图像信息的方法和装置",
    "inv": [
      "陈思利",
      "林源",
      "董玉华",
      "郑彬彬",
      "闫一杰",
      "吴中勤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN106920279B",
    "apn": "CN201710131578.4",
    "apd": "2017-03-07",
    "apy": "2017",
    "tit": "三维地图构建方法和装置",
    "inv": [
      "林源",
      "陈思利",
      "董玉华",
      "郑彬彬",
      "闫一杰",
      "吴中勤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN106846497A",
    "apn": "CN201710131264.4",
    "apd": "2017-03-07",
    "apy": "2017",
    "tit": "应用于终端的呈现三维地图的方法和装置",
    "inv": [
      "张永杰",
      "李颖超",
      "吴中勤",
      "龚龙",
      "腾禹桥",
      "罗启菡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN106951484A",
    "apn": "CN201710142948.4",
    "apd": "2017-03-10",
    "apy": "2017",
    "tit": "图片检索方法及装置、计算机设备及计算机可读介质",
    "inv": [
      "王健",
      "周峰",
      "文石磊",
      "刘霄"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN106960219A",
    "apn": "CN201710142614.7",
    "apd": "2017-03-10",
    "apy": "2017",
    "tit": "图片识别方法及装置、计算机设备及计算机可读介质",
    "inv": [
      "周峰",
      "刘霄"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107105340A",
    "apn": "CN201710168987.1",
    "apd": "2017-03-21",
    "apy": "2017",
    "tit": "基于人工智能的视频中显示人物信息方法、装置和系统",
    "inv": [
      "程高飞",
      "孙祥平",
      "郭丛敏",
      "杨少宁",
      "郑啸天"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107169262A",
    "apn": "CN201710209289.1",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "推荐塑身方案的方法、装置、设备和计算机存储介质",
    "inv": [
      "梁领军",
      "贺凯",
      "刘晓媛",
      "王彧博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107093164A",
    "apn": "CN201710281956.7",
    "apd": "2017-04-26",
    "apy": "2017",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "门文"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN107194419A",
    "apn": "CN201710325322.7",
    "apd": "2017-05-10",
    "apy": "2017",
    "tit": "视频分类方法及装置、计算机设备与可读介质",
    "inv": [
      "常鑫"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107273458A",
    "apn": "CN201710404705.3",
    "apd": "2017-06-01",
    "apy": "2017",
    "tit": "深度模型训练方法及装置、图像检索方法及装置",
    "inv": [
      "邓玥琳",
      "高光明",
      "丁飞",
      "胡先军"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107229741A",
    "apn": "CN201710470126.9",
    "apd": "2017-06-20",
    "apy": "2017",
    "tit": "信息搜索方法、装置、设备以及存储介质",
    "inv": [
      "吕居美",
      "胡翔宇",
      "黄丽洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107169148A",
    "apn": "CN201710475500.4",
    "apd": "2017-06-21",
    "apy": "2017",
    "tit": "图像搜索方法、装置、设备以及存储介质",
    "inv": [
      "吕居美",
      "李永会",
      "胡翔宇",
      "刘宇龙",
      "赵毅",
      "蔡海龙"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109116973A",
    "apn": "CN201710486186.X",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "数据处理方法和装置",
    "inv": [
      "曹壹",
      "晋欢欢"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109116374A",
    "apn": "CN201710488088.X",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "确定障碍物距离的方法、装置、设备及存储介质",
    "inv": [
      "王睿",
      "孙讯",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109118233A",
    "apn": "CN201710487580.5",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "基于人脸识别的认证方法和装置",
    "inv": [
      "刘峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109117691A",
    "apn": "CN201710487182.3",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "可行驶区域检测方法、装置、设备及存储介质",
    "inv": [
      "陈世佳",
      "何明",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109117358A",
    "apn": "CN201710486781.3",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "用于电子设备的测试方法和测试装置",
    "inv": [
      "梁刚强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109117690A",
    "apn": "CN201710487137.8",
    "apd": "2017-06-23",
    "apy": "2017",
    "tit": "可行驶区域检测方法、装置、设备及存储介质",
    "inv": [
      "陈世佳",
      "何明",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107464286A",
    "apn": "CN201710524354.X",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "三维城市模型中的孔洞修复方法及装置、设备及可读介质",
    "inv": [
      "刘巍"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "城市建设",
    "l2": "城市模型",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107492141A",
    "apn": "CN201710525123.0",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "三维城市模型的图形处理方法及装置、设备及可读介质",
    "inv": [
      "刘巍"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "城市建设",
    "l2": "城市模型",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107415872A",
    "apn": "CN201710527017.6",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "用于机动车内设备的调节方法和装置",
    "inv": [
      "潘羽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107339996A",
    "apn": "CN201710525426.2",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "车辆自定位方法、装置、设备及存储介质",
    "inv": [
      "刘勃"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107481316A",
    "apn": "CN201710526976.6",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "三维城市模型的室内外全景切换方法、装置及可读介质",
    "inv": [
      "王刘强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "城市建设",
    "l2": "城市模型",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107480173A",
    "apn": "CN201710526914.5",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "POI信息的展示方法及装置、设备及可读介质",
    "inv": [
      "王刘强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107328411A",
    "apn": "CN201710524533.3",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "车载定位系统和自动驾驶车辆",
    "inv": [
      "艾锐",
      "晏飞",
      "侯文博",
      "曹春亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109214238A",
    "apn": "CN201710521291.2",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "多目标跟踪方法、装置、设备及存储介质",
    "inv": [
      "高涵",
      "万吉",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN107452046A",
    "apn": "CN201710525161.6",
    "apd": "2017-06-30",
    "apy": "2017",
    "tit": "三维城市模型的纹理处理方法及装置、设备及可读介质",
    "inv": [
      "刘巍"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "城市建设",
    "l2": "城市模型",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107153496A",
    "apn": "CN201710538998.4",
    "apd": "2017-07-04",
    "apy": "2017",
    "tit": "用于输入表情图标的方法和装置",
    "inv": [
      "王怡"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109214980A",
    "apn": "CN201710538045.8",
    "apd": "2017-07-04",
    "apy": "2017",
    "tit": "一种三维姿态估计方法、装置、设备和计算机存储介质",
    "inv": [
      "孙迅",
      "王睿",
      "翟玉强",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107526769A",
    "apn": "CN201710555149.X",
    "apd": "2017-07-10",
    "apy": "2017",
    "tit": "基于人工智能的照片处理方法及装置、设备及可读介质",
    "inv": [
      "王知践"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107545241A",
    "apn": "CN201710589276.1",
    "apd": "2017-07-19",
    "apy": "2017",
    "tit": "神经网络模型训练及活体检测方法、装置及存储介质",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107609463A",
    "apn": "CN201710596813.5",
    "apd": "2017-07-20",
    "apy": "2017",
    "tit": "活体检测方法、装置、设备及存储介质",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107633198A",
    "apn": "CN201710613143.3",
    "apd": "2017-07-25",
    "apy": "2017",
    "tit": "活体检测方法、装置、设备及存储介质",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107609466A",
    "apn": "CN201710619157.6",
    "apd": "2017-07-26",
    "apy": "2017",
    "tit": "人脸聚类方法、装置、设备及存储介质",
    "inv": [
      "车丽美"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107590430A",
    "apn": "CN201710619038.0",
    "apd": "2017-07-26",
    "apy": "2017",
    "tit": "活体检测方法、装置、设备及存储介质",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107590676A",
    "apn": "CN201710627778.9",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "提供个性化服务的方法、装置、设备和计算机存储介质",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107563289A",
    "apn": "CN201710641646.1",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "一种活体检测的方法、装置、设备和计算机存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107633485A",
    "apn": "CN201710666697.X",
    "apd": "2017-08-07",
    "apy": "2017",
    "tit": "脸部亮度调整方法、装置、设备及存储介质",
    "inv": [
      "张彦福",
      "谷明琴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107679860A",
    "apn": "CN201710677137.4",
    "apd": "2017-08-09",
    "apy": "2017",
    "tit": "一种用户认证的方法、装置、设备和计算机存储介质",
    "inv": [
      "杜雅洁",
      "李广"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107609481A",
    "apn": "CN201710691048.5",
    "apd": "2017-08-14",
    "apy": "2017",
    "tit": "为人脸识别生成训练数据的方法、装置和计算机存储介质",
    "inv": [
      "翁仁亮",
      "何涛",
      "张刚",
      "刘经拓",
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107657269A",
    "apn": "CN201710737312.4",
    "apd": "2017-08-24",
    "apy": "2017",
    "tit": "一种用于训练图片提纯模型的方法和装置",
    "inv": [
      "李广"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107610131A",
    "apn": "CN201710743641.X",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "一种图像裁剪方法和图像裁剪装置",
    "inv": [
      "齐慧",
      "冯原",
      "莫飞龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN107679451A",
    "apn": "CN201710744277.9",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "建立人脸识别模型的方法、装置、设备和计算机存储介质",
    "inv": [
      "翁仁亮",
      "何涛",
      "张刚",
      "刘经拓",
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107578000A",
    "apn": "CN201710742463.9",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "用于处理图像的方法及装置",
    "inv": [
      "张彦福"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107748750A",
    "apn": "CN201710762918.3",
    "apd": "2017-08-30",
    "apy": "2017",
    "tit": "相似视频查找方法、装置、设备及存储介质",
    "inv": [
      "罗俊楠",
      "高磊磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107571867B",
    "apn": "CN201710792748.3",
    "apd": "2017-09-05",
    "apy": "2017",
    "tit": "用于控制无人驾驶车辆的方法和装置",
    "inv": [
      "唐坤",
      "郁浩",
      "闫泳杉",
      "郑超",
      "张云飞",
      "姜雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107392189A",
    "apn": "CN201710790024.5",
    "apd": "2017-09-05",
    "apy": "2017",
    "tit": "用于确定无人车的驾驶行为的方法和装置",
    "inv": [
      "郁浩",
      "闫泳杉",
      "郑超",
      "唐坤",
      "张云飞",
      "姜雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107609502A",
    "apn": "CN201710792595.2",
    "apd": "2017-09-05",
    "apy": "2017",
    "tit": "用于控制无人驾驶车辆的方法和装置",
    "inv": [
      "唐坤",
      "郁浩",
      "闫泳杉",
      "郑超",
      "张云飞",
      "姜雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107563332A",
    "apn": "CN201710790586.X",
    "apd": "2017-09-05",
    "apy": "2017",
    "tit": "用于确定无人车的驾驶行为的方法和装置",
    "inv": [
      "郁浩",
      "闫泳杉",
      "郑超",
      "唐坤",
      "张云飞",
      "姜雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107633218A",
    "apn": "CN201710806066.3",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "何涛",
      "张刚",
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107578017A",
    "apn": "CN201710806070.X",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "何涛",
      "张刚",
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107609506A",
    "apn": "CN201710806650.9",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "何涛",
      "张刚",
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107516090A",
    "apn": "CN201710811916.9",
    "apd": "2017-09-11",
    "apy": "2017",
    "tit": "一体化人脸识别方法和系统",
    "inv": [
      "许天涵",
      "张发恩",
      "周恺",
      "王倩",
      "刘昆",
      "肖远昊",
      "徐东泽",
      "孙家元",
      "刘岚"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109492507A",
    "apn": "CN201710822106.3",
    "apd": "2017-09-12",
    "apy": "2017",
    "tit": "红绿灯状态的识别方法及装置、计算机设备及可读介质",
    "inv": [
      "翟玉强",
      "夏添",
      "高涵",
      "李明"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109508580A",
    "apn": "CN201710831216.6",
    "apd": "2017-09-15",
    "apy": "2017",
    "tit": "交通信号灯识别方法和装置",
    "inv": [
      "李明",
      "夏添",
      "高涵",
      "翟玉强",
      "陈世佳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109523555A",
    "apn": "CN201710842627.5",
    "apd": "2017-09-18",
    "apy": "2017",
    "tit": "用于无人驾驶车辆的前车刹车行为检测方法和装置",
    "inv": [
      "高涵",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107622282A",
    "apn": "CN201710860024.8",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "图像校验方法和装置",
    "inv": [
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107622240A",
    "apn": "CN201710858135.5",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "人脸检测方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107679466A",
    "apn": "CN201710859525.4",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "信息输出方法和装置",
    "inv": [
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107507153A",
    "apn": "CN201710859689.7",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "图像去噪方法和装置",
    "inv": [
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107644209A",
    "apn": "CN201710858134.0",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "人脸检测方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107491771A",
    "apn": "CN201710858055.X",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "人脸检测方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107622241A",
    "apn": "CN201710859103.7",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "用于移动设备的显示方法和装置",
    "inv": [
      "王逸恺"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107644208A",
    "apn": "CN201710858133.6",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "人脸检测方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107483636A",
    "apn": "CN201710867238.8",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "唐海玉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107657663A",
    "apn": "CN201710867785.6",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "用于显示信息的方法及装置",
    "inv": [
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107908641A",
    "apn": "CN201710890130.0",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "一种获取图片标注数据的方法和系统",
    "inv": [
      "刘国翌",
      "李广"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107818538A",
    "apn": "CN201710891211.2",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "水印图像的处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "刘国翌",
      "李广",
      "杜宇宁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107909088A",
    "apn": "CN201710892379.5",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "获取训练样本的方法、装置、设备和计算机存储介质",
    "inv": [
      "刘国翌",
      "李广"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107563123A",
    "apn": "CN201710888651.2",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "用于标注医学图像的方法和装置",
    "inv": [
      "张少霆",
      "张玮东",
      "段琦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107679155A",
    "apn": "CN201710889672.6",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "服饰搭配库建立方法、信息推荐方法、装置、设备及介质",
    "inv": [
      "陈军利"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107832662A",
    "apn": "CN201710889767.8",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "一种获取图片标注数据的方法和系统",
    "inv": [
      "刘国翌",
      "李广",
      "韩树民"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107590482A",
    "apn": "CN201710905911.2",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "信息生成方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN107578034A",
    "apn": "CN201710910131.7",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "信息生成方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN107944448A",
    "apn": "CN201710910372.1",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "一种图像异步编辑方法及装置",
    "inv": [
      "马丽芬",
      "孟浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107590484A",
    "apn": "CN201710910122.8",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "用于呈现信息的方法和装置",
    "inv": [
      "王琳",
      "姚淼",
      "张永杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107609536A",
    "apn": "CN201710911147.X",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "信息生成方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107622252A",
    "apn": "CN201710908680.0",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "信息生成方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN107622524A",
    "apn": "CN201710910104.X",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "用于移动终端的显示方法和显示装置",
    "inv": [
      "曹逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107679490B",
    "apn": "CN201710908705.7",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "用于检测图像质量的方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN107590807A",
    "apn": "CN201710911138.0",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "用于检测图像质量的方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN107644419A",
    "apn": "CN201710916007.1",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "用于分析医学影像的方法和装置",
    "inv": [
      "王睿",
      "段琦",
      "张少霆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107665736A",
    "apn": "CN201710914487.8",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "黄宁",
      "刘星龙",
      "张少霆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107680088A",
    "apn": "CN201710918520.4",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "用于分析医学影像的方法和装置",
    "inv": [
      "张少霆",
      "段琦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107677289A",
    "apn": "CN201710918519.1",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "信息处理方法、装置以及机动车",
    "inv": [
      "王吉芳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107622504A",
    "apn": "CN201710919124.3",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "用于处理图片的方法和装置",
    "inv": [
      "陈晓宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN107729928A",
    "apn": "CN201710918840.X",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "信息获取方法和装置",
    "inv": [
      "张刚",
      "陆进"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107563360A",
    "apn": "CN201710915703.0",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "信息获取方法和装置",
    "inv": [
      "张刚",
      "陆进"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN107729929A",
    "apn": "CN201710919995.5",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "用于获取信息的方法及装置",
    "inv": [
      "张少霆",
      "张玮东",
      "段琦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107679493A",
    "apn": "CN201710918492.6",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "人脸识别方法和装置",
    "inv": [
      "张刚",
      "陆进"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109656800A",
    "apn": "CN201710935545.5",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "图像识别应用的测试方法、装置、终端及存储介质",
    "inv": [
      "郭建彬"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107680684A",
    "apn": "CN201710946331.8",
    "apd": "2017-10-12",
    "apy": "2017",
    "tit": "用于获取信息的方法及装置",
    "inv": [
      "张少霆",
      "张玮东",
      "段琦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109684900A",
    "apn": "CN201710972792.2",
    "apd": "2017-10-18",
    "apy": "2017",
    "tit": "用于输出颜色信息的方法和装置",
    "inv": [
      "高涵",
      "李明",
      "翟玉强",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107742106A",
    "apn": "CN201710972917.1",
    "apd": "2017-10-18",
    "apy": "2017",
    "tit": "基于无人驾驶车辆的面部匹配方法和装置",
    "inv": [
      "薛召"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107766303A",
    "apn": "CN201710993954.0",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "向用户提供3D阅读场景",
    "inv": [
      "冯苗苗",
      "侯文",
      "李冰冰",
      "李庆龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107729944A",
    "apn": "CN201710993101.7",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "一种低俗图片的识别方法、装置、服务器及存储介质",
    "inv": [
      "高光明",
      "邓玥琳",
      "丁飞",
      "刘辉",
      "齐智峰",
      "武延豪"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107886339A",
    "apn": "CN201711131478.8",
    "apd": "2017-11-15",
    "apy": "2017",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "徐晓飞",
      "何佳",
      "王然"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107832721A",
    "apn": "CN201711139967.8",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "商兴奇",
      "贾巍",
      "李宏言",
      "蔡浚宇",
      "拓冰",
      "李时聪",
      "李鹏",
      "贺诚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107830869A",
    "apn": "CN201711139956.X",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "用于车辆的信息输出方法和装置",
    "inv": [
      "饶先拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107909037A",
    "apn": "CN201711136211.8",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "信息输出方法和装置",
    "inv": [
      "贾巍",
      "商兴奇",
      "李宏言"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107918764A",
    "apn": "CN201711135717.7",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "信息输出方法和装置",
    "inv": [
      "贾巍",
      "商兴奇",
      "李宏言"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107844777A",
    "apn": "CN201711137995.6",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "贾巍",
      "商兴奇",
      "李宏言"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107941226A",
    "apn": "CN201711140782.9",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "用于生成车辆的方向引导线的方法和装置",
    "inv": [
      "饶先拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107894237A",
    "apn": "CN201711138761.3",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "用于显示导航信息的方法和装置",
    "inv": [
      "李浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107945848A",
    "apn": "CN201711138234.2",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "一种健身指导实现方法、装置、设备和介质",
    "inv": [
      "冉瑞龙",
      "于静磊",
      "袁旺红"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109814703A",
    "apn": "CN201711164236.9",
    "apd": "2017-11-21",
    "apy": "2017",
    "tit": "一种显示方法、装置、设备和介质",
    "inv": [
      "施振磊",
      "赵鑫",
      "邹晓园"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109816768A",
    "apn": "CN201711163966.7",
    "apd": "2017-11-21",
    "apy": "2017",
    "tit": "一种室内重建方法、装置、设备和介质",
    "inv": [
      "邹晓园"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "装修",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN107832735A",
    "apn": "CN201711195454.9",
    "apd": "2017-11-24",
    "apy": "2017",
    "tit": "用于识别人脸的方法和装置",
    "inv": [
      "周定富",
      "杨睿刚",
      "张彦福",
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107911753A",
    "apn": "CN201711215661.6",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "用于在视频中添加数字水印的方法和装置",
    "inv": [
      "李旭斌",
      "丁二锐",
      "文石磊",
      "刘霄",
      "孙昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109859328A",
    "apn": "CN201711242167.9",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "一种场景切换方法、装置、设备和介质",
    "inv": [
      "赵鑫",
      "邹晓园",
      "施振磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109857244A",
    "apn": "CN201711236212.X",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "一种手势识别方法、装置、终端设备、存储介质及VR眼镜",
    "inv": [
      "赵俊博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108171257B",
    "apn": "CN201711249014.7",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "细粒度图像识别模型训练及识别方法、装置及存储介质",
    "inv": [
      "孙明",
      "袁宇辰",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108229535B",
    "apn": "CN201711249233.5",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "涉黄图像审核方法、装置、计算机设备及存储介质",
    "inv": [
      "李鑫",
      "边云龙",
      "刘霄",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107958455B",
    "apn": "CN201711278216.4",
    "apd": "2017-12-06",
    "apy": "2017",
    "tit": "图像清晰度评估方法、装置、计算机设备及存储介质",
    "inv": [
      "赵翔",
      "李鑫",
      "刘霄",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN109903308A",
    "apn": "CN201711297504.4",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "用于获取信息的方法及装置",
    "inv": [
      "张晔",
      "王军",
      "王昊",
      "王亮",
      "张立志",
      "毛继明",
      "朱晓星"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108021896B",
    "apn": "CN201711296090.3",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "基于增强现实的拍摄方法、装置、设备及计算机可读介质",
    "inv": [
      "王傲淇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108012081A",
    "apn": "CN201711297822.0",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "智能美颜方法、装置、终端和计算机可读存储介质",
    "inv": [
      "孙灵玲",
      "蔡玉婷",
      "陆阳阳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107945175A",
    "apn": "CN201711322001.8",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "图像的评价方法、装置、服务器及存储介质",
    "inv": [
      "王加明",
      "苏春波"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN108062377A",
    "apn": "CN201711321907.8",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "标签图片集的建立、标签的确定方法、装置、设备及介质",
    "inv": [
      "潘平",
      "雷锦艺",
      "章巍巍"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109960959A",
    "apn": "CN201711337034.X",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "李旭斌",
      "傅依",
      "文石磊",
      "刘霄",
      "丁二锐",
      "孙昊",
      "郭鹏",
      "蒋子谦",
      "李亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171260A",
    "apn": "CN201711352429.7",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "一种图片识别方法及系统",
    "inv": [
      "边云龙",
      "李鑫",
      "刘霄",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108182211A",
    "apn": "CN201711374282.1",
    "apd": "2017-12-19",
    "apy": "2017",
    "tit": "视频舆情获取方法、装置、计算机设备及存储介质",
    "inv": [
      "刘俐岑",
      "王璐",
      "韦庭",
      "卓泽成",
      "张晓聪",
      "吴伟佳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108170742A",
    "apn": "CN201711373612.5",
    "apd": "2017-12-19",
    "apy": "2017",
    "tit": "图片舆情获取方法、装置、计算机设备及存储介质",
    "inv": [
      "刘俐岑",
      "王璐",
      "韦庭",
      "卓泽成",
      "张晓聪",
      "吴伟佳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108090885A",
    "apn": "CN201711386851.4",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "傅依",
      "李旭斌",
      "文石磊",
      "丁二锐",
      "刘霄"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN108038880A",
    "apn": "CN201711387870.9",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "胡扬",
      "文石磊",
      "刘霄",
      "李旭斌",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108090916B",
    "apn": "CN201711397545.0",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "用于跟踪视频中的目标图形的方法和装置",
    "inv": [
      "王健",
      "文石磊",
      "刘霄",
      "李旭斌",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108124191B",
    "apn": "CN201711406873.2",
    "apd": "2017-12-22",
    "apy": "2017",
    "tit": "一种视频审核方法、装置及服务器",
    "inv": [
      "丁圣龙",
      "王璇",
      "尉明望",
      "郁陈焙"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108038469B",
    "apn": "CN201711440851.8",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "用于检测人体的方法和装置",
    "inv": [
      "翁仁亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108156161A",
    "apn": "CN201711442023.8",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "验证方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108052920A",
    "apn": "CN201711447009.7",
    "apd": "2017-12-27",
    "apy": "2017",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108062793A",
    "apn": "CN201711457678.2",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "基于高程的物体顶部处理方法、装置、设备和存储介质",
    "inv": [
      "刘巍"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108171167B",
    "apn": "CN201711455675.5",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "用于输出图像的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108038473A",
    "apn": "CN201711455262.7",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108053365B",
    "apn": "CN201711475042.0",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108073910A",
    "apn": "CN201711482448.1",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于生成人脸特征的方法和装置",
    "inv": [
      "姜志超"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN107909065A",
    "apn": "CN201711476846.2",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于检测人脸遮挡的方法及装置",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108197567A",
    "apn": "CN201711486200.2",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于图像处理的方法、装置和计算机可读介质",
    "inv": [
      "段雄",
      "郎咸朋",
      "周旺",
      "闫淼",
      "湛逸飞",
      "马常杰",
      "金永刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN108229386A",
    "apn": "CN201711485383.6",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于检测车道线的方法、装置和介质",
    "inv": [
      "段雄",
      "朗咸朋",
      "周旺",
      "闫淼",
      "湛逸飞",
      "马常杰",
      "金永刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108229376A",
    "apn": "CN201711478427.2",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于检测眨眼的方法及装置",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108182412A",
    "apn": "CN201711477373.8",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于检测图像类型的方法及装置",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108229375A",
    "apn": "CN201711477257.6",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "用于检测人脸图像的方法和装置",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108197652A",
    "apn": "CN201810001835.7",
    "apd": "2018-01-02",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "翁仁亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108564102A",
    "apn": "CN201810007796.1",
    "apd": "2018-01-04",
    "apy": "2018",
    "tit": "图像聚类结果评价方法和装置",
    "inv": [
      "翁仁亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108241855A",
    "apn": "CN201810007794.2",
    "apd": "2018-01-04",
    "apy": "2018",
    "tit": "图像生成方法和装置",
    "inv": [
      "翁仁亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108230033A",
    "apn": "CN201810007596.6",
    "apd": "2018-01-04",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171191B",
    "apn": "CN201810011483.3",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "用于检测人脸的方法和装置",
    "inv": [
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108563982A",
    "apn": "CN201810011481.4",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "用于检测图像的方法和装置",
    "inv": [
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108133197A",
    "apn": "CN201810010631.X",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108256451A",
    "apn": "CN201810011123.3",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "用于检测人脸的方法和装置",
    "inv": [
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108062576B",
    "apn": "CN201810010610.8",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "用于输出数据的方法和装置",
    "inv": [
      "车丽美"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108090468B",
    "apn": "CN201810010839.1",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "用于检测人脸的方法和装置",
    "inv": [
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108257122A",
    "apn": "CN201810019864.6",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "基于机器视觉的纸张缺陷检测方法、装置及服务器",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108320278A",
    "apn": "CN201810019936.7",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "产品缺陷检测定位方法、装置、设备及计算机可读介质",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108154508B",
    "apn": "CN201810019380.1",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "产品缺陷检测定位的方法、装置、存储介质及终端设备",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108230318B",
    "apn": "CN201810020267.5",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "钢包缺陷检测分类方法、装置、设备及计算机可读介质",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108564104A",
    "apn": "CN201810020247.8",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "产品缺陷检测方法、装置、系统、服务器及存储介质",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108242054A",
    "apn": "CN201810019418.5",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "一种钢板缺陷检测方法、装置、设备和服务器",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108257121B",
    "apn": "CN201810019386.9",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "产品缺陷检测模型更新的方法、装置、存储介质及终端设备",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108230317A",
    "apn": "CN201810019417.0",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "钢板缺陷检测分类方法、装置、设备及计算机可读介质",
    "inv": [
      "冷家冰",
      "刘明浩",
      "梁阳",
      "文亚伟",
      "张发恩",
      "郭江亮",
      "唐进",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108228863B",
    "apn": "CN201810031223.2",
    "apd": "2018-01-12",
    "apy": "2018",
    "tit": "情报信息查询的方法、装置、存储介质及终端设备",
    "inv": [
      "刘虎",
      "尹世明",
      "唐进",
      "张发恩",
      "郭江亮"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108229704A",
    "apn": "CN201810039890.5",
    "apd": "2018-01-16",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "刘鹏",
      "侯文",
      "李冰冰",
      "李强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "故障检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171206B",
    "apn": "CN201810045179.0",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "信息生成方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108133201B",
    "apn": "CN201810044637.9",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "人脸属性识别方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN107958247A",
    "apn": "CN201810045569.8",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于人脸图像识别的方法和装置",
    "inv": [
      "翁仁亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171274B",
    "apn": "CN201810043970.8",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于识别动物的方法和装置",
    "inv": [
      "袁宇辰",
      "周峰",
      "张成月"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "动植物识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171203A",
    "apn": "CN201810043985.4",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于识别车辆的方法和装置",
    "inv": [
      "谭啸",
      "周峰",
      "孙昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108319901B",
    "apn": "CN201810044315.4",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "人脸的活体检测方法、装置、计算机设备及可读介质",
    "inv": [
      "周定富",
      "杨睿刚",
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108256479A",
    "apn": "CN201810045567.9",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "人脸跟踪方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108256474A",
    "apn": "CN201810044439.2",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于识别菜品的方法和装置",
    "inv": [
      "钟永沣",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171275A",
    "apn": "CN201810044186.9",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于识别花卉的方法和装置",
    "inv": [
      "孙明",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "动植物识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108257081A",
    "apn": "CN201810044599.7",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于生成图片的方法和装置",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108171208A",
    "apn": "CN201810046227.8",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "信息获取方法和装置",
    "inv": [
      "何斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171204B",
    "apn": "CN201810045159.3",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "检测方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108256476A",
    "apn": "CN201810044989.4",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于识别果蔬的方法和装置",
    "inv": [
      "孙明",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "动植物识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108280413A",
    "apn": "CN201810044630.7",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "人脸识别方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108256477A",
    "apn": "CN201810045200.7",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "一种用于检测人脸的方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108269254A",
    "apn": "CN201810045570.0",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "图像质量评估方法和装置",
    "inv": [
      "翁仁亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN108171207A",
    "apn": "CN201810046090.6",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "基于视频序列的人脸识别方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108171205A",
    "apn": "CN201810045172.9",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "用于识别人脸的方法和装置",
    "inv": [
      "赵谦谦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108154547B",
    "apn": "CN201810045174.8",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "图像生成方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108154196B",
    "apn": "CN201810053763.0",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于输出图像的方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108229575A",
    "apn": "CN201810054603.8",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于检测目标的方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108280420A",
    "apn": "CN201810054604.2",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于处理图像的系统、方法和装置",
    "inv": [
      "魏楠",
      "章宏武"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108062544A",
    "apn": "CN201810054425.9",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于人脸活体检测的方法和装置",
    "inv": [
      "周定富",
      "洪智滨",
      "刘经拓",
      "庞文杰",
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108171212A",
    "apn": "CN201810055152.X",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于检测目标的方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108170282A",
    "apn": "CN201810055487.1",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于控制三维场景的方法和装置",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108171026A",
    "apn": "CN201810054500.1",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "鉴权方法和装置",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108257178A",
    "apn": "CN201810054570.7",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于定位目标人体的位置的方法和装置",
    "inv": [
      "杜雅洁",
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108197623A",
    "apn": "CN201810055231.0",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "用于检测目标的方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108171211A",
    "apn": "CN201810053762.6",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "活体检测方法和装置",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108197592A",
    "apn": "CN201810058894.8",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "信息获取方法和装置",
    "inv": [
      "何斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108154140A",
    "apn": "CN201810061009.1",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "基于唇语的语音唤醒方法、装置、设备及计算机可读介质",
    "inv": [
      "高亮"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108280477A",
    "apn": "CN201810060006.6",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "用于聚类图像的方法和装置",
    "inv": [
      "车丽美",
      "翁仁亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108181992A",
    "apn": "CN201810061028.4",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "基于手势的语音唤醒方法、装置、设备及计算机可读介质",
    "inv": [
      "高亮"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108229419A",
    "apn": "CN201810059189.X",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "用于聚类图像的方法和装置",
    "inv": [
      "车丽美"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108280422A",
    "apn": "CN201810059846.0",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "用于识别人脸的方法和装置",
    "inv": [
      "车丽美"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108198553A",
    "apn": "CN201810063682.9",
    "apd": "2018-01-23",
    "apy": "2018",
    "tit": "语音交互方法、装置、设备和计算机可读存储介质",
    "inv": [
      "高亮",
      "谢继亮"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110084709A",
    "apn": "CN201810064076.9",
    "apd": "2018-01-23",
    "apy": "2018",
    "tit": "基于面部特征的好友处理方法、服务器及计算机可读介质",
    "inv": [
      "彭祥帅",
      "范天航",
      "熊雷",
      "杨静",
      "徐迎迎",
      "尹家庆",
      "田征绿",
      "胡磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108460335A",
    "apn": "CN201810076682.2",
    "apd": "2018-01-26",
    "apy": "2018",
    "tit": "视频细粒度识别方法、装置、计算机设备及存储介质",
    "inv": [
      "谭啸",
      "周峰",
      "孙昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416744B",
    "apn": "CN201810090558.1",
    "apd": "2018-01-30",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "谭啸",
      "周峰",
      "孙昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108182746A",
    "apn": "CN201810090661.6",
    "apd": "2018-01-30",
    "apy": "2018",
    "tit": "控制系统、方法和装置",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108337505A",
    "apn": "CN201810089436.0",
    "apd": "2018-01-30",
    "apy": "2018",
    "tit": "信息获取方法和装置",
    "inv": [
      "何斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108460339A",
    "apn": "CN201810107782.7",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "行李查找方法、终端、设备及计算机可读介质",
    "inv": [
      "吴学义"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "酒店旅行",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108335390A",
    "apn": "CN201810107910.8",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "用于处理信息的方法和装置",
    "inv": [
      "吴学义"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "酒店旅行",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108229485A",
    "apn": "CN201810129865.6",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "用于测试用户界面的方法和装置",
    "inv": [
      "尹飞",
      "项金鑫",
      "柏馨",
      "张婷",
      "刘盼盼",
      "薛大伟",
      "邢潘红",
      "魏晨辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108334627A",
    "apn": "CN201810147229.6",
    "apd": "2018-02-12",
    "apy": "2018",
    "tit": "新媒体内容的搜索方法、装置和计算机设备",
    "inv": [
      "董维山",
      "王宇亮",
      "陈曼仪",
      "王群",
      "张峥"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108415995A",
    "apn": "CN201810149957.0",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "搜索方法和装置",
    "inv": [
      "杜奕岐",
      "朱虹烨"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416018A",
    "apn": "CN201810181368.0",
    "apd": "2018-03-06",
    "apy": "2018",
    "tit": "截屏搜索方法、装置及智能终端",
    "inv": [
      "彭汉迎"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108510472B",
    "apn": "CN201810191220.5",
    "apd": "2018-03-08",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "王蔚",
      "陈科第",
      "孟泉",
      "周淼",
      "范竣翔"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108319930A",
    "apn": "CN201810193228.5",
    "apd": "2018-03-09",
    "apy": "2018",
    "tit": "身份认证方法、系统、终端和计算机可读存储介质",
    "inv": [
      "谢文斌",
      "赫卫卿",
      "刘凡平",
      "陈相礼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108460761A",
    "apn": "CN201810201427.6",
    "apd": "2018-03-12",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "孟泉",
      "周淼",
      "王蔚",
      "范竣翔",
      "陈科第"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416310A",
    "apn": "CN201810209172.8",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108345387A",
    "apn": "CN201810209048.1",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "杨振中"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108376177B",
    "apn": "CN201810213755.8",
    "apd": "2018-03-15",
    "apy": "2018",
    "tit": "用于处理信息的方法和分布式系统",
    "inv": [
      "吴浩博",
      "李军",
      "王龙",
      "肖恪",
      "石宽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108388878A",
    "apn": "CN201810214258.X",
    "apd": "2018-03-15",
    "apy": "2018",
    "tit": "用于识别人脸的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108492393A",
    "apn": "CN201810218719.0",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "用于签到的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416317A",
    "apn": "CN201810226172.9",
    "apd": "2018-03-19",
    "apy": "2018",
    "tit": "用于获取信息的方法及装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108363995A",
    "apn": "CN201810225423.1",
    "apd": "2018-03-19",
    "apy": "2018",
    "tit": "用于生成数据的方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108490797A",
    "apn": "CN201810228840.1",
    "apd": "2018-03-20",
    "apy": "2018",
    "tit": "智能设备的搜索结果展示方法和装置",
    "inv": [
      "郝普",
      "赵毅"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108389172A",
    "apn": "CN201810233913.6",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "何涛",
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108446737A",
    "apn": "CN201810236270.0",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "用于识别对象的方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108446385A",
    "apn": "CN201810235162.1",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108491709A",
    "apn": "CN201810236679.2",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "用于识别权限的方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108734084A",
    "apn": "CN201810236678.8",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "人脸注册方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108446387A",
    "apn": "CN201810239167.1",
    "apd": "2018-03-22",
    "apy": "2018",
    "tit": "用于更新人脸注册库的方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108446390A",
    "apn": "CN201810240697.8",
    "apd": "2018-03-22",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108460266A",
    "apn": "CN201810241989.3",
    "apd": "2018-03-22",
    "apy": "2018",
    "tit": "用于认证身份的方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108363999A",
    "apn": "CN201810239814.9",
    "apd": "2018-03-22",
    "apy": "2018",
    "tit": "基于人脸识别的操作执行方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108388889A",
    "apn": "CN201810247286.1",
    "apd": "2018-03-23",
    "apy": "2018",
    "tit": "用于分析人脸图像的方法和装置",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108492364A",
    "apn": "CN201810259506.2",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于生成图像生成模型的方法和装置",
    "inv": [
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108398906B",
    "apn": "CN201810257775.5",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "设备控制方法、装置、电器、总控设备及存储介质",
    "inv": [
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416324A",
    "apn": "CN201810258183.5",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于检测活体的方法和装置",
    "inv": [
      "武慧敏",
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108460365A",
    "apn": "CN201810259990.9",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "身份认证方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416595A",
    "apn": "CN201810259528.9",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "信息处理方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509890A",
    "apn": "CN201810257842.3",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于提取信息的方法和装置",
    "inv": [
      "杨锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108549848A",
    "apn": "CN201810257860.1",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "杨锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509888A",
    "apn": "CN201810256950.9",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108490832A",
    "apn": "CN201810258775.7",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于发送信息的方法和装置",
    "inv": [
      "杨锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108429816A",
    "apn": "CN201810259895.9",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108415653A",
    "apn": "CN201810260174.X",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于终端设备的锁屏方法和装置",
    "inv": [
      "佟莎莎",
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108446650A",
    "apn": "CN201810257453.0",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于识别人脸的方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108399401A",
    "apn": "CN201810256946.2",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于检测人脸图像的方法和装置",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509781A",
    "apn": "CN201810256337.7",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于解锁的方法及装置",
    "inv": [
      "杨锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108537152A",
    "apn": "CN201810259543.3",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于检测活体的方法和装置",
    "inv": [
      "武慧敏",
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108446649A",
    "apn": "CN201810256338.1",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于告警的方法及装置",
    "inv": [
      "杨锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108470131A",
    "apn": "CN201810260485.6",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于生成提示信息的方法和装置",
    "inv": [
      "佟莎莎",
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108510466A",
    "apn": "CN201810257451.1",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于验证人脸的方法和装置",
    "inv": [
      "何涛",
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108537057A",
    "apn": "CN201810258899.5",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于处理相册浏览请求的方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108491805A",
    "apn": "CN201810259996.6",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "身份认证方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108460366A",
    "apn": "CN201810260582.5",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "身份认证方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108537531A",
    "apn": "CN201810256173.8",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于处理信息的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108494778A",
    "apn": "CN201810259529.3",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "身份认证方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108460364A",
    "apn": "CN201810258967.8",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "佟莎莎",
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416323A",
    "apn": "CN201810257454.5",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于识别人脸的方法和装置",
    "inv": [
      "何涛",
      "刘文献"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108446896A",
    "apn": "CN201810258898.0",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "仲召来"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108446651A",
    "apn": "CN201810257843.8",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "人脸识别方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN108416326A",
    "apn": "CN201810259989.6",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "人脸识别方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108446653A",
    "apn": "CN201810260487.5",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "用于处理面部图像的方法和装置",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108446659A",
    "apn": "CN201810266087.5",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "用于检测人脸图像的方法和装置",
    "inv": [
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108446658A",
    "apn": "CN201810264669.X",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "用于识别人脸图像的方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108470328A",
    "apn": "CN201810265515.2",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108491808A",
    "apn": "CN201810263902.2",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "用于获取信息的方法及装置",
    "inv": [
      "刘晓乾"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509895A",
    "apn": "CN201810266050.2",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "用于检测人脸图像的方法和装置",
    "inv": [
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108470290A",
    "apn": "CN201810265582.4",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "广告检测方法、装置及服务器",
    "inv": [
      "李敬医",
      "赖慎禄",
      "缪雨佳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108511066A",
    "apn": "CN201810270481.6",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "信息生成方法和装置",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509584A",
    "apn": "CN201810270839.5",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "封面图的选择方法、装置和计算机设备",
    "inv": [
      "石瑾"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN108509041A",
    "apn": "CN201810268893.6",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "用于执行操作的方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108449514A",
    "apn": "CN201810270166.3",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "信息处理方法和装置",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108491813A",
    "apn": "CN201810270170.X",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "用于更新信息的方法和装置",
    "inv": [
      "何涛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108446660A",
    "apn": "CN201810271618.X",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "用于识别人脸图像的方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108470179A",
    "apn": "CN201810270454.9",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "用于检测对象的方法和装置",
    "inv": [
      "杜康",
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108710885A",
    "apn": "CN201810270483.5",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "目标对象的检测方法和装置",
    "inv": [
      "杜康",
      "翁仁亮",
      "洪智滨",
      "刘经拓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108491812A",
    "apn": "CN201810268892.1",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "人脸识别模型的生成方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509046A",
    "apn": "CN201810290646.6",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "智能家居设备控制方法和装置",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416331B",
    "apn": "CN201810279454.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "脸部对称识别的方法、装置、存储介质及终端设备",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108491816A",
    "apn": "CN201810276460.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "在视频中进行目标跟踪的方法和装置",
    "inv": [
      "杜康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108804893A",
    "apn": "CN201810291578.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "一种基于人脸识别的控制方法、装置和服务器",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509923A",
    "apn": "CN201810297899.6",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "课堂注意力检测方法、装置、设备及计算机可读介质",
    "inv": [
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "状态监测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108507137B",
    "apn": "CN201810276022.9",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "加湿器控制方法及系统、终端以及计算机可读存储介质",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108563330A",
    "apn": "CN201810298095.8",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "应用开启方法、装置、设备及计算机可读介质",
    "inv": [
      "路双",
      "佟莎莎",
      "杨锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108520221B",
    "apn": "CN201810278797.X",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "体型识别的方法、装置、存储介质及终端设备",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416235B",
    "apn": "CN201810274878.2",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "显示界面防偷窥的方法、装置、存储介质及终端设备",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108537026A",
    "apn": "CN201810287066.1",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "应用程序控制方法、装置和服务器",
    "inv": [
      "陶天然"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509916A",
    "apn": "CN201810290271.3",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "何斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108510556A",
    "apn": "CN201810293153.8",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108733547A",
    "apn": "CN201810291704.7",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "监控方法和装置",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108710820A",
    "apn": "CN201810287084.X",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "基于人脸识别的婴儿状态识别方法、装置和服务器",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108513207B",
    "apn": "CN201810293268.7",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "音箱控制方法、装置、设备及计算机可读介质",
    "inv": [
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108491825A",
    "apn": "CN201810296449.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "信息生成方法和装置",
    "inv": [
      "包英泽",
      "周仁义",
      "覃曾攀"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108536803A",
    "apn": "CN201810292186.0",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "歌曲推荐方法、装置、设备及计算机可读介质",
    "inv": [
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108416333A",
    "apn": "CN201810297650.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "图像处理的方法和装置",
    "inv": [
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108491823A",
    "apn": "CN201810286481.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于生成人眼识别模型的方法和装置",
    "inv": [
      "陈艳琴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108334644B",
    "apn": "CN201810274877.8",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "图像识别方法和装置",
    "inv": [
      "刘赵梁",
      "张永杰",
      "章宏武"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108536027A",
    "apn": "CN201810287425.3",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "智能家居控制方法、装置和服务器",
    "inv": [
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108479070A",
    "apn": "CN201810292336.8",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "虚拟模型生成方法和装置",
    "inv": [
      "江浩森"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108427939A",
    "apn": "CN201810286240.0",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "模型生成方法和装置",
    "inv": [
      "罗远庆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509905A",
    "apn": "CN201810277034.3",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "健康状态评估方法、装置、电子设备及存储介质",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509611A",
    "apn": "CN201810295444.0",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108492144A",
    "apn": "CN201810275973.4",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "菜品推荐的方法及系统、终端以及计算机可读存储介质",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108520583A",
    "apn": "CN201810277072.9",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "出入口管理方法、装置、系统及存储介质",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108520220A",
    "apn": "CN201810277834.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "模型生成方法和装置",
    "inv": [
      "罗远庆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108550384A",
    "apn": "CN201810292476.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "佟莎莎",
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108537980A",
    "apn": "CN201810289804.6",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "面部识别开启储物柜的方法、装置、存储介质及终端设备",
    "inv": [
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108460368A",
    "apn": "CN201810277820.3",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "三维图像合成方法、装置及计算机可读存储介质",
    "inv": [
      "周仁义",
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108509904A",
    "apn": "CN201810276675.7",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108521516A",
    "apn": "CN201810310652.3",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于终端设备的控制方法和装置",
    "inv": [
      "佟莎莎",
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108510538A",
    "apn": "CN201810277922.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "三维图像合成方法、装置及计算机可读存储介质",
    "inv": [
      "周仁义",
      "杨锐",
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108509994A",
    "apn": "CN201810286272.0",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "人物图像聚类方法和装置",
    "inv": [
      "车丽美"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108537786A",
    "apn": "CN201810296417.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "用于处理图像方法和装置",
    "inv": [
      "何斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN108491824A",
    "apn": "CN201810289781.9",
    "apd": "2018-04-03",
    "apy": "2018",
    "tit": "模型生成方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509915A",
    "apn": "CN201810289806.5",
    "apd": "2018-04-03",
    "apy": "2018",
    "tit": "人脸识别模型的生成方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509621A",
    "apn": "CN201810303753.8",
    "apd": "2018-04-03",
    "apy": "2018",
    "tit": "景区全景图的景点识别方法、装置、服务器和存储介质",
    "inv": [
      "袁洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "景区",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108509921A",
    "apn": "CN201810297438.9",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "杜康",
      "何泽强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108491890A",
    "apn": "CN201810299833.0",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "图像方法和装置",
    "inv": [
      "张刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108510084A",
    "apn": "CN201810299838.3",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "杜康",
      "汤旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108564527A",
    "apn": "CN201810296759.7",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "基于神经网络的全景图内容补全和修复的方法及装置",
    "inv": [
      "董维山",
      "王园",
      "毛妤",
      "乔岳",
      "张苗"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108197618A",
    "apn": "CN201810307313.X",
    "apd": "2018-04-08",
    "apy": "2018",
    "tit": "用于生成人脸检测模型的方法和装置",
    "inv": [
      "何泽强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108427941A",
    "apn": "CN201810307489.5",
    "apd": "2018-04-08",
    "apy": "2018",
    "tit": "用于生成人脸检测模型的方法、人脸检测方法和装置",
    "inv": [
      "何泽强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108509929A",
    "apn": "CN201810315220.1",
    "apd": "2018-04-08",
    "apy": "2018",
    "tit": "用于生成人脸检测模型的方法、人脸检测方法和装置",
    "inv": [
      "何泽强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108537165A",
    "apn": "CN201810306343.9",
    "apd": "2018-04-08",
    "apy": "2018",
    "tit": "用于确定信息的方法和装置",
    "inv": [
      "何泽强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108388142A",
    "apn": "CN201810315663.0",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "用于控制家居设备的方法、装置和系统",
    "inv": [
      "贾晓博",
      "魏楠",
      "章宏武"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108549861A",
    "apn": "CN201810315189.1",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "计分方法和装置",
    "inv": [
      "陆睿"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "竞技",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108536830A",
    "apn": "CN201810320783.X",
    "apd": "2018-04-11",
    "apy": "2018",
    "tit": "图片动态搜索方法、装置、设备、服务器和存储介质",
    "inv": [
      "王群",
      "王宇亮",
      "张苗",
      "马淼",
      "张峥",
      "张弛"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108595628A",
    "apn": "CN201810371349.4",
    "apd": "2018-04-24",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "李晓鹏",
      "马立",
      "孙世文"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108573054A",
    "apn": "CN201810371264.6",
    "apd": "2018-04-24",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "李晓鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109726624A",
    "apn": "CN201810371277.3",
    "apd": "2018-04-24",
    "apy": "2018",
    "tit": "身份认证方法、终端设备和计算机可读存储介质",
    "inv": [
      "仲震宇",
      "韦韬",
      "邱喆彬"
    ],
    "app": [
      "百度(美国)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108597034A",
    "apn": "CN201810401434.0",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "王群",
      "董维山",
      "杨茗名",
      "陈玉娴",
      "赵英瑞",
      "王宇亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108563792A",
    "apn": "CN201810409788.X",
    "apd": "2018-05-02",
    "apy": "2018",
    "tit": "图像检索处理方法、服务器、客户端及存储介质",
    "inv": [
      "冯原",
      "齐慧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108615159A",
    "apn": "CN201810415794.6",
    "apd": "2018-05-03",
    "apy": "2018",
    "tit": "基于注视点检测的访问控制方法和装置",
    "inv": [
      "车丽美"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108632668A",
    "apn": "CN201810420211.9",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "视频处理方法及装置",
    "inv": [
      "张树森",
      "吴广发",
      "施鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108632641A",
    "apn": "CN201810421184.7",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "视频处理方法及装置",
    "inv": [
      "张树森",
      "吴广发",
      "施鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN108931213A",
    "apn": "CN201810427232.3",
    "apd": "2018-05-07",
    "apy": "2018",
    "tit": "平整度检测方法、装置、设备及存储介质",
    "inv": [
      "叶子石"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "装修",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108848300A",
    "apn": "CN201810429487.3",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "李冰冰",
      "张宇骢",
      "侯文"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108815845B",
    "apn": "CN201810462176.7",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "人机交互的信息处理方法及装置、计算机设备及可读介质",
    "inv": [
      "杜雅洁",
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108710697A",
    "apn": "CN201810501246.5",
    "apd": "2018-05-23",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "李财瑜",
      "曲海龙",
      "张现伟",
      "颜滔",
      "翟宇宏",
      "孙雅杰",
      "金良雨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108764352A",
    "apn": "CN201810545595.7",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "重复页面内容检测方法和装置",
    "inv": [
      "柏馨",
      "张婷",
      "崔一",
      "项金鑫",
      "尹飞",
      "刘盼盼",
      "薛大伟",
      "魏晨辉",
      "邢潘红"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108805867A",
    "apn": "CN201810517108.6",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "用于输出烟叶等级信息的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "徐玉林",
      "刘明浩",
      "张发恩",
      "郭江亮",
      "李旭",
      "唐进"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108776808A",
    "apn": "CN201810532052.1",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "一种用于检测钢包溶蚀缺陷的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "张发恩",
      "郭江亮",
      "李旭",
      "唐进"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108830837A",
    "apn": "CN201810517336.3",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "一种用于检测钢包溶蚀缺陷的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "张发恩",
      "郭江亮",
      "李旭",
      "唐进"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108764160A",
    "apn": "CN201810540819.5",
    "apd": "2018-05-30",
    "apy": "2018",
    "tit": "信息获取方法和装置",
    "inv": [
      "白校铭",
      "王凯",
      "陈雪",
      "钱承君",
      "毛楠",
      "邹浔"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108921894A",
    "apn": "CN201810586753.3",
    "apd": "2018-06-08",
    "apy": "2018",
    "tit": "对象定位方法、装置、设备和计算机可读存储介质",
    "inv": [
      "孙颖",
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109061703A",
    "apn": "CN201810597963.2",
    "apd": "2018-06-11",
    "apy": "2018",
    "tit": "用于定位的方法、装置、设备和计算机可读存储介质",
    "inv": [
      "郑京森",
      "易世春",
      "杨晓龙",
      "宋适宇",
      "董芳芳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108874136A",
    "apn": "CN201810609385.X",
    "apd": "2018-06-13",
    "apy": "2018",
    "tit": "动态图像生成方法、装置、终端和存储介质",
    "inv": [
      "李想"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108845741A",
    "apn": "CN201810628407.7",
    "apd": "2018-06-19",
    "apy": "2018",
    "tit": "一种AR表情的生成方法、客户端、终端和存储介质",
    "inv": [
      "郝冀宣",
      "蔡月"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108846886A",
    "apn": "CN201810629472.1",
    "apd": "2018-06-19",
    "apy": "2018",
    "tit": "一种AR表情的生成方法、客户端、终端和存储介质",
    "inv": [
      "蔡伟"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108961422A",
    "apn": "CN201810677886.1",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "三维模型的标记方法和装置",
    "inv": [
      "王群",
      "王宇亮",
      "乔岳",
      "张苗"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108920598A",
    "apn": "CN201810679269.5",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "全景图浏览方法、装置、终端设备、服务器及存储介质",
    "inv": [
      "王群",
      "赵辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108921081A",
    "apn": "CN201810682072.7",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "用户操作的检测方法和装置",
    "inv": [
      "罗宇轩",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109035256A",
    "apn": "CN201810689943.8",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "用户界面图像切割方法、装置、服务器和存储介质",
    "inv": [
      "张婷",
      "崔一",
      "尹飞",
      "刘盼盼",
      "柏馨",
      "项金鑫",
      "薛大伟",
      "邢潘红",
      "魏晨辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108985358A",
    "apn": "CN201810694899.X",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "情绪识别方法、装置、设备及存储介质",
    "inv": [
      "林英展",
      "陈炳金",
      "梁一川",
      "凌光",
      "周超"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109241815B",
    "apn": "CN201810695088.1",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "用户行为的检测方法、装置及机器人",
    "inv": [
      "林湘粤",
      "刘昆",
      "乔爽爽",
      "梁阳",
      "韩超",
      "朱名发",
      "郭江亮",
      "李旭",
      "刘俊",
      "李硕",
      "尹世明"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109033985A",
    "apn": "CN201810701271.8",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "商品识别的处理方法、装置、设备、系统及存储介质",
    "inv": [
      "付鹏",
      "王奎澎",
      "胡跃祥",
      "周强",
      "范彦文",
      "寇浩锋",
      "何声一",
      "周仁义",
      "方阳华",
      "包英泽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108846377A",
    "apn": "CN201810712750.X",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "用于拍摄图像的方法和装置",
    "inv": [
      "李文博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108875690A",
    "apn": "CN201810713427.4",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "无人零售商品识别系统",
    "inv": [
      "汪学军",
      "寇浩锋",
      "韩群勇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109035257A",
    "apn": "CN201810709464.8",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "人像分割方法、装置及设备",
    "inv": [
      "傅依",
      "李旭斌",
      "文石磊",
      "丁二锐",
      "刘霄"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN108921841A",
    "apn": "CN201810710536.0",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "钢铁连铸坯质量检测方法、装置、设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109064446A",
    "apn": "CN201810709847.5",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏质量检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109087280A",
    "apn": "CN201810709223.3",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "连铸坯质量检测方法、装置、控制设备、服务器及介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108846841A",
    "apn": "CN201810710537.5",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏质量检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108921839A",
    "apn": "CN201810709188.5",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "连铸坯质量检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109084955A",
    "apn": "CN201810709190.2",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏质量检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109087282A",
    "apn": "CN201810710545.X",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏外围电路检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109087281A",
    "apn": "CN201810709408.4",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏外围电路检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108961238A",
    "apn": "CN201810709189.X",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏质量检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109085174A",
    "apn": "CN201810709836.7",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏外围电路检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108921840A",
    "apn": "CN201810710534.1",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "显示屏外围电路检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108961239A",
    "apn": "CN201810710556.8",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "连铸坯质量检测方法、装置、电子设备及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108985199A",
    "apn": "CN201810710543.0",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "商品取放操作的检测方法、装置及存储介质",
    "inv": [
      "王青泽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108986164A",
    "apn": "CN201810717769.3",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "基于图像的位置检测方法、装置、设备及存储介质",
    "inv": [
      "李旭斌",
      "王健",
      "文石磊",
      "丁二锐",
      "孙昊",
      "亢乐",
      "包英泽",
      "陈明裕",
      "迟至真",
      "刘泽宇",
      "张成月"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109064548A",
    "apn": "CN201810719201.5",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "视频生成方法、装置、设备及存储介质",
    "inv": [
      "乔慧",
      "李伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108961423A",
    "apn": "CN201810719510.2",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "虚拟信息处理方法、装置、设备及存储介质",
    "inv": [
      "黄晓鹏",
      "刘浩敏",
      "李晨",
      "王志昊",
      "林源",
      "钟上焜"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108846378A",
    "apn": "CN201810719202.X",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "手语识别处理方法及装置",
    "inv": [
      "徐力",
      "李颖超",
      "马晓昕",
      "滕禹桥",
      "董玉华"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109035415A",
    "apn": "CN201810720226.7",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "虚拟模型的处理方法、装置、设备和计算机可读存储介质",
    "inv": [
      "常元章",
      "乔慧",
      "李颖超"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109035336A",
    "apn": "CN201810719611.X",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "基于图像的位置检测方法、装置、设备及存储介质",
    "inv": [
      "迟至真"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108961396A",
    "apn": "CN201810717735.4",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "三维场景的生成方法、装置及终端设备",
    "inv": [
      "乔慧",
      "李伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108905193A",
    "apn": "CN201810718219.3",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "游戏操控处理方法、设备及存储介质",
    "inv": [
      "庞文杰",
      "乔慧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109086670B",
    "apn": "CN201810717792.2",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "人脸识别方法、装置及设备",
    "inv": [
      "范彦文",
      "何声一",
      "王奎澎",
      "寇浩锋",
      "包英泽",
      "周强",
      "付鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108960139A",
    "apn": "CN201810719392.5",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "人物行为识别方法、装置及存储介质",
    "inv": [
      "刘霄",
      "李鑫",
      "杨凡",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108986141A",
    "apn": "CN201810715983.5",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "物体运动信息处理方法、装置、增强现实设备及存储介质",
    "inv": [
      "常元章",
      "乔慧",
      "李颖超"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108921098A",
    "apn": "CN201810720374.9",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "人体运动分析方法、装置、设备及存储介质",
    "inv": [
      "王健",
      "李旭斌",
      "亢乐",
      "刘泽宇",
      "迟至真",
      "张成月",
      "刘霄",
      "孙昊",
      "文石磊",
      "包英泽",
      "陈明裕",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109002275A",
    "apn": "CN201810717680.7",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "AR背景音频处理方法、装置、AR设备和可读存储介质",
    "inv": [
      "朱康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109063567A",
    "apn": "CN201810719692.3",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "人体识别方法、装置及存储介质",
    "inv": [
      "王健",
      "李旭斌",
      "亢乐",
      "刘泽宇",
      "迟至真",
      "张成月",
      "刘霄",
      "孙昊",
      "文石磊",
      "包英泽",
      "陈明裕",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109035368A",
    "apn": "CN201810720339.7",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "用于AR场景的信息处理方法、装置、设备及存储介质",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108874363A",
    "apn": "CN201810720007.9",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "用于AR场景的对象控制方法、装置、设备及存储介质",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108986191A",
    "apn": "CN201810720342.9",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "人物动作的生成方法、装置及终端设备",
    "inv": [
      "乔慧",
      "李伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108983974A",
    "apn": "CN201810717976.9",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "AR场景处理方法、装置、设备和计算机可读存储介质",
    "inv": [
      "常元章",
      "乔慧",
      "李颖超"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108921874A",
    "apn": "CN201810726681.8",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "人体跟踪处理方法、装置及系统",
    "inv": [
      "刘泽宇",
      "亢乐",
      "张成月",
      "迟至真",
      "王健",
      "李旭斌",
      "刘霄",
      "孙昊",
      "文石磊",
      "丁二锐",
      "章宏武",
      "陈明裕",
      "包英泽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109003317A",
    "apn": "CN201810722906.2",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "虚拟信息处理方法、装置、设备及存储介质",
    "inv": [
      "赵威",
      "申雪岑",
      "罗启菡",
      "李克",
      "余先波"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108985206A",
    "apn": "CN201810723901.1",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "模型训练方法、人体识别方法、装置、设备及存储介质",
    "inv": [
      "李旭斌",
      "王健",
      "亢乐",
      "刘泽宇",
      "迟至真",
      "张成月",
      "文石磊",
      "包英泽",
      "陈明裕",
      "丁二锐",
      "刘霄",
      "孙昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108921101A",
    "apn": "CN201810723916.8",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "基于手势识别控制指令的处理方法、设备及可读存储介质",
    "inv": [
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108961166A",
    "apn": "CN201810739709.1",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "全景图像生成系统、方法和装置",
    "inv": [
      "陈相礼",
      "郭武彪",
      "刘坚"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108898678A",
    "apn": "CN201810743420.7",
    "apd": "2018-07-09",
    "apy": "2018",
    "tit": "增强现实方法和装置",
    "inv": [
      "江志磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109034214A",
    "apn": "CN201810746142.0",
    "apd": "2018-07-09",
    "apy": "2018",
    "tit": "用于生成标记的方法和装置",
    "inv": [
      "毛立凡",
      "黄申荣"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108875080A",
    "apn": "CN201810765693.1",
    "apd": "2018-07-12",
    "apy": "2018",
    "tit": "一种图像搜索方法、装置、服务器及存储介质",
    "inv": [
      "王园",
      "马春洋",
      "董维山"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108985228A",
    "apn": "CN201810778514.8",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "应用于终端设备的信息生成方法和装置",
    "inv": [
      "刘侃",
      "刘健"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108920380A",
    "apn": "CN201810804807.9",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "软件兼容性的测试方法、装置、服务器、设备和存储介质",
    "inv": [
      "曹丁溧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109117742A",
    "apn": "CN201810805273.1",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "手势检测模型处理方法、装置、设备及存储介质",
    "inv": [
      "高原",
      "胡扬",
      "柏提",
      "刘霄",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108920707A",
    "apn": "CN201810803949.3",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "用于标注信息的方法及装置",
    "inv": [
      "马春洋",
      "董维山",
      "王宇亮",
      "袁洁",
      "王园"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109145752A",
    "apn": "CN201810814218.9",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "用于评估对象检测和跟踪算法的方法、装置、设备和介质",
    "inv": [
      "白校铭",
      "邹浔",
      "陈雪",
      "朱霞",
      "毛楠"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108985240A",
    "apn": "CN201810813488.8",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "评估对象检测算法的方法、装置、电子设备和存储介质",
    "inv": [
      "白校铭",
      "邹浔",
      "陈雪",
      "朱霞",
      "毛楠"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109033346A",
    "apn": "CN201810822037.0",
    "apd": "2018-07-24",
    "apy": "2018",
    "tit": "三维内容呈现的方法、装置、存储介质和终端设备",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108921159A",
    "apn": "CN201810836779.9",
    "apd": "2018-07-26",
    "apy": "2018",
    "tit": "用于检测安全帽的佩戴情况的方法和装置",
    "inv": [
      "孟泉",
      "周淼",
      "王蔚",
      "范竣翔",
      "陈科第"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109032744A",
    "apn": "CN201810848055.6",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "图标展示方法、装置、服务器及存储介质",
    "inv": [
      "马春洋",
      "董维山",
      "陈逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109144252A",
    "apn": "CN201810866053.X",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "对象确定方法、装置、设备和存储介质",
    "inv": [
      "张啸吟",
      "赵晨",
      "杜雅洁",
      "吴中勤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108985259A",
    "apn": "CN201810878634.5",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "人体动作识别方法和装置",
    "inv": [
      "沈辉",
      "高原",
      "何栋梁",
      "刘霄",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109241844A",
    "apn": "CN201810880310.5",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "三维物体的姿态估计方法、装置、设备及存储介质",
    "inv": [
      "陈思利",
      "林源",
      "张永杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109064333A",
    "apn": "CN201810878295.0",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "产品的推荐方法和装置",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109131167A",
    "apn": "CN201810877538.9",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于控制车辆的方法和装置",
    "inv": [
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109063774A",
    "apn": "CN201810878766.8",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "图像追踪效果评价方法、装置、设备及可读存储介质",
    "inv": [
      "刘赵梁",
      "陈思利",
      "张永杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109145785A",
    "apn": "CN201810879413.X",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "植物护理方式的确定方法和装置",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "动植物识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109101610A",
    "apn": "CN201810879404.0",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于AR场景的车辆定损方法、装置、设备及存储介质",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109165572A",
    "apn": "CN201810879258.1",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "王健",
      "李甫",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109165573A",
    "apn": "CN201810879268.5",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "用于提取视频特征向量的方法和装置",
    "inv": [
      "何栋梁",
      "文石磊",
      "李甫",
      "孙昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109192275A",
    "apn": "CN201810886488.0",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "人物精神状态的确定方法、装置及服务器",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108905095A",
    "apn": "CN201810886490.8",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "运动员比赛状态评估方法和设备",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "竞技",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109101169A",
    "apn": "CN201810887704.3",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "电器的控制方法、终端、电器、电子设备与存储介质",
    "inv": [
      "朱康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109117778A",
    "apn": "CN201810886489.5",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "信息处理方法、装置、服务器及存储介质",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109243600A",
    "apn": "CN201810884772.4",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "杨叶辉",
      "夏源",
      "罗程亮",
      "范斌",
      "王华南"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109241998A",
    "apn": "CN201810887705.8",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "模型训练方法、装置、设备及存储介质",
    "inv": [
      "杨少雄",
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108985262A",
    "apn": "CN201810887115.5",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "肢体运动指导方法、装置、服务器及存储介质",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN108985657A",
    "apn": "CN201810887103.2",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "人物衣物搭配的评价方法、装置及服务器",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109018773A",
    "apn": "CN201810887635.6",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "垃圾分类方法、装置及存储介质",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "垃圾分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108989756A",
    "apn": "CN201810887140.3",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "建筑工程质量监测方法、装置及存储介质",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109255297A",
    "apn": "CN201810887644.5",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "动物状态监测方法、终端设备、存储介质及电子设备",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "动植物识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109120990A",
    "apn": "CN201810887111.7",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "直播方法、装置和存储介质",
    "inv": [
      "朱康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109117779A",
    "apn": "CN201810887643.0",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "一种穿搭推荐方法、装置及电子设备",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109064555A",
    "apn": "CN201810887676.5",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "3D建模的方法、装置和存储介质",
    "inv": [
      "朱康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108876484A",
    "apn": "CN201810886305.5",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "商品推荐方法及装置",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109102573A",
    "apn": "CN201810887116.X",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "图像处理方法、装置和存储介质",
    "inv": [
      "朱康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109243576A",
    "apn": "CN201810887699.6",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "食物推荐方法和装置",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109191180A",
    "apn": "CN201810887681.6",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "评价的获取方法及装置",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "点评",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109063651A",
    "apn": "CN201810886301.7",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "物体检测方法、装置、设备及存储介质",
    "inv": [
      "杨少雄",
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109101614A",
    "apn": "CN201810887696.2",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "好友推荐方法和装置",
    "inv": [
      "戚天禹"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109102874A",
    "apn": "CN201810886304.0",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "基于AR技术的医疗处理方法、装置、设备和存储介质",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109213320A",
    "apn": "CN201810887678.4",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "选座预览的方法、装置和存储介质",
    "inv": [
      "朱康"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109241385A",
    "apn": "CN201810897484.2",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "搜索方法、设备以及存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109087172A",
    "apn": "CN201810897322.9",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "商品识别处理方法及装置",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109255071A",
    "apn": "CN201810897496.5",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "公共场所识别处理方法及装置",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109242600A",
    "apn": "CN201810898233.6",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "交易辅助方法、装置、设备和存储介质",
    "inv": [
      "徐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109242001A",
    "apn": "CN201810903034.X",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "图像数据处理方法、装置及可读存储介质",
    "inv": [
      "杨少雄",
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109242000A",
    "apn": "CN201810902228.8",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "徐化永",
      "张永杰",
      "李颖超"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108982522A",
    "apn": "CN201810904674.2",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "用于检测管道缺陷的方法和装置",
    "inv": [
      "黄耀波",
      "刘明浩",
      "冷家冰",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109190499A",
    "apn": "CN201810903741.9",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "杨少雄",
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109086780A",
    "apn": "CN201810912648.4",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于检测电极片毛刺的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109190504A",
    "apn": "CN201810910923.9",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "汽车图像数据的处理方法、装置及可读存储介质",
    "inv": [
      "杨少雄",
      "赵晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109145973A",
    "apn": "CN201810910722.9",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于检测单晶硅太阳能电池缺陷的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "徐玉林",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109190502A",
    "apn": "CN201810906602.1",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于生成位置信息的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "梁阳",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109086828A",
    "apn": "CN201810907540.6",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于检测电池极片的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109086827A",
    "apn": "CN201810907537.4",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于检测单晶硅太阳能电池缺陷的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "徐玉林",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109256341A",
    "apn": "CN201810907552.9",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于检测单晶硅太阳能电池的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "徐玉林",
      "肖慧慧",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109064464A",
    "apn": "CN201810906761.1",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于检测电池极片毛刺的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109035243A",
    "apn": "CN201810907188.6",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "用于输出电池极片毛刺信息的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109214301A",
    "apn": "CN201810910909.9",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "基于人脸识别和手势识别的控制方法及装置",
    "inv": [
      "朱康",
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109271599A",
    "apn": "CN201810917943.9",
    "apd": "2018-08-13",
    "apy": "2018",
    "tit": "数据分享方法、设备和存储介质",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN108986842B",
    "apn": "CN201810922926.4",
    "apd": "2018-08-14",
    "apy": "2018",
    "tit": "音乐风格识别处理方法及终端",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109189991A",
    "apn": "CN201810940302.5",
    "apd": "2018-08-17",
    "apy": "2018",
    "tit": "重复视频识别方法、装置、终端及计算机可读存储介质",
    "inv": [
      "李帅龙",
      "宋萌萌",
      "谭洪林",
      "李棱"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109242831A",
    "apn": "CN201810949074.8",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "图像质量检测方法、装置、计算机设备和存储介质",
    "inv": [
      "岑敏强"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN108965743A",
    "apn": "CN201810954676.2",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "基于前后景分割的视频合成方法、装置及可读存储介质",
    "inv": [
      "杨振中"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN109242978A",
    "apn": "CN201810955648.2",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "三维模型的视角调整方法和装置",
    "inv": [
      "王群",
      "董维山"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109191585A",
    "apn": "CN201810956120.7",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "信息处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109166170A",
    "apn": "CN201810955286.7",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "用于渲染增强现实场景的方法和装置",
    "inv": [
      "王博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109754417A",
    "apn": "CN201810962300.6",
    "apd": "2018-08-22",
    "apy": "2018",
    "tit": "从图像中无监督学习几何结构的系统与方法",
    "inv": [
      "王鹏",
      "徐伟",
      "杨振恒"
    ],
    "app": [
      "百度(美国)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109299293A",
    "apn": "CN201810967796.6",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "用于AR场景的烹饪指导方法、装置、设备及存储介质",
    "inv": [
      "曹逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109190032A",
    "apn": "CN201810967375.3",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "推送方法、装置的存储介质",
    "inv": [
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109166565A",
    "apn": "CN201810965724.8",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "虚拟乐器处理方法、装置、虚拟乐器设备及存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109191587A",
    "apn": "CN201810968095.4",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "颜色识别方法、装置、电子设备及存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109298780A",
    "apn": "CN201810974215.1",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "基于AR的信息处理方法、装置、AR设备及存储介质",
    "inv": [
      "贾晓博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109309757A",
    "apn": "CN201810973408.5",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "备忘录提醒方法及终端",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运行模式",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109191593A",
    "apn": "CN201810979887.1",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "虚拟三维模型的运动控制方法、装置及设备",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109300112A",
    "apn": "CN201810980615.3",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "基于AR的器械维护方法及装置",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109191588A",
    "apn": "CN201810981820.1",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "运动教学方法、装置、存储介质及电子设备",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109242984A",
    "apn": "CN201810980614.9",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "虚拟三维场景构建方法、装置及设备",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109242836A",
    "apn": "CN201810981971.7",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "疾病预测方法、装置以及存储介质",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109299510A",
    "apn": "CN201810980630.8",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "三维模型确定方法、装置及设备",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109239075A",
    "apn": "CN201810980598.3",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "电池检测方法及装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "肖慧慧",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN108876563A",
    "apn": "CN201810980269.9",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "用户与商品的互动方法、装置、设备及存储介质",
    "inv": [
      "罗袆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109032358A",
    "apn": "CN201810980628.0",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "基于手势识别的AR交互虚拟模型的控制方法及装置",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109344923A",
    "apn": "CN201810982588.3",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "关联物体的显示方法、装置及电子设备",
    "inv": [
      "曹逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109040595A",
    "apn": "CN201810979845.8",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "基于AR的历史全景处理方法、装置、设备及存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109040289A",
    "apn": "CN201810981066.1",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "兴趣点信息推送方法、服务器、终端和存储介质",
    "inv": [
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109078327A",
    "apn": "CN201810985287.6",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "基于AR的游戏实现方法和设备",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109255841A",
    "apn": "CN201810989948.2",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "AR图像呈现方法、装置、终端及存储介质",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109325904A",
    "apn": "CN201810991042.4",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "图像滤镜处理方法和装置",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109298781A",
    "apn": "CN201810989947.8",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "基于AR的留言处理方法、装置、设备及计算机存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109166090A",
    "apn": "CN201810985792.0",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "基于声纹的增强现实处理方法、装置和存储介质",
    "inv": [
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109034121A",
    "apn": "CN201810985270.0",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "商品识别处理的方法、装置、设备及计算机存储介质",
    "inv": [
      "贾晓博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109255763A",
    "apn": "CN201810985872.6",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及存储介质",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109147017A",
    "apn": "CN201810985746.0",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "动态图像生成方法、装置、设备及存储介质",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109255310A",
    "apn": "CN201810990233.9",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "动物情绪识别方法、装置、终端及可读存储介质",
    "inv": [
      "曹逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109040824A",
    "apn": "CN201810985328.1",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "视频处理方法、装置、电子设备和可读存储介质",
    "inv": [
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN108965982A",
    "apn": "CN201810985329.6",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "视频录制方法、装置、电子设备和可读存储介质",
    "inv": [
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109166182A",
    "apn": "CN201810991019.5",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "AR模拟处理方法、装置、电子设备及可读存储介质",
    "inv": [
      "曹逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "实验展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109189302A",
    "apn": "CN201810993135.0",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "AR虚拟模型的控制方法及装置",
    "inv": [
      "张岩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109040782A",
    "apn": "CN201810993104.5",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "视频播放处理方法、装置及电子设备",
    "inv": [
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109040612A",
    "apn": "CN201810998402.3",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "目标对象的图像处理方法、装置、设备及存储介质",
    "inv": [
      "常元章",
      "李颖超",
      "张永杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109247914A",
    "apn": "CN201810993227.9",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "病症数据获取方法和装置",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109035421A",
    "apn": "CN201810998401.9",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109407824A",
    "apn": "CN201811002871.1",
    "apd": "2018-08-30",
    "apy": "2018",
    "tit": "人体模型的同步运动方法与装置",
    "inv": [
      "曹逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109285220A",
    "apn": "CN201811003349.5",
    "apd": "2018-08-30",
    "apy": "2018",
    "tit": "一种三维场景地图的生成方法、装置、设备及存储介质",
    "inv": [
      "燕飞龙",
      "赵彤彤",
      "方进",
      "王亮",
      "马彧",
      "杨睿刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109407825A",
    "apn": "CN201811005228.4",
    "apd": "2018-08-30",
    "apy": "2018",
    "tit": "基于虚拟物品的互动方法与装置",
    "inv": [
      "曹逸凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109271892A",
    "apn": "CN201811004517.2",
    "apd": "2018-08-30",
    "apy": "2018",
    "tit": "一种物体识别方法、装置、设备、车辆和介质",
    "inv": [
      "桂义林",
      "高涵",
      "李琦",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109446876A",
    "apn": "CN201811009994.8",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "手语信息处理方法、装置、电子设备和可读存储介质",
    "inv": [
      "姚淼"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109376735A",
    "apn": "CN201811014777.8",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "身份信息提取方法、装置、电子设备与存储介质",
    "inv": [
      "郑学兴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109409199A",
    "apn": "CN201811014172.9",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "微表情训练方法、装置、存储介质及电子设备",
    "inv": [
      "向佳耀"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109145853A",
    "apn": "CN201811012843.8",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "用于识别噪音的方法和装置",
    "inv": [
      "李财瑜"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109407826A",
    "apn": "CN201811013346.X",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "球类运动模拟方法、装置、存储介质及电子设备",
    "inv": [
      "向佳耀"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109145931B",
    "apn": "CN201811018729.6",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "物体检测方法、装置及存储介质",
    "inv": [
      "王学辉",
      "李明",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109214334A",
    "apn": "CN201811019280.5",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "车道线处理方法和装置",
    "inv": [
      "谢术富",
      "翟玉强",
      "夏添",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109145860A",
    "apn": "CN201811026061.X",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "车道线跟踪方法和装置",
    "inv": [
      "谢术富",
      "翟玉强",
      "夏添",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109117825A",
    "apn": "CN201811025492.4",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "车道线处理方法和装置",
    "inv": [
      "谢术富",
      "翟玉强",
      "夏添",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109445569A",
    "apn": "CN201811028270.8",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "基于AR的信息处理方法、装置、设备及可读存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109360631A",
    "apn": "CN201811032859.5",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "基于图片识别的人机交互方法、装置、计算机设备及介质",
    "inv": [
      "贾伟",
      "樊钢",
      "孙禹",
      "陈鑫",
      "雷成军",
      "吴冬雪"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109389687A",
    "apn": "CN201811030452.9",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "基于AR的信息处理方法、装置、设备及可读存储介质",
    "inv": [
      "杜雅洁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109163707B",
    "apn": "CN201811039205.5",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "障碍物感知方法、系统、计算机设备、计算机存储介质",
    "inv": [
      "高涵",
      "李郭",
      "孙迅",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109145141A",
    "apn": "CN201811036860.5",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "信息展示方法和装置",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109285181A",
    "apn": "CN201811037194.7",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "用于识别图像的方法和装置",
    "inv": [
      "赵谦谦",
      "李京峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109308687A",
    "apn": "CN201811037949.3",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "用于调节图像亮度的方法和装置",
    "inv": [
      "赵谦谦",
      "李京峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109034137B",
    "apn": "CN201811044603.6",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "头部姿态标记更新方法、装置、存储介质和终端设备",
    "inv": [
      "赵彤彤",
      "燕飞龙",
      "王亮",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109300159A",
    "apn": "CN201811044487.8",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "位置检测方法、装置、设备、存储介质及车辆",
    "inv": [
      "刘铭剑",
      "王煜城",
      "孙迅",
      "谢术富",
      "翟玉强",
      "王昊",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109389042A",
    "apn": "CN201811045954.9",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "协助驾驶的方法、设备、无人驾驶设备及可读存储介质",
    "inv": [
      "陈幽涵"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109145864A",
    "apn": "CN201811046284.2",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "确定视线区域的方法、装置、存储介质和终端设备",
    "inv": [
      "赵彤彤",
      "燕飞龙",
      "王亮",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109035831A",
    "apn": "CN201811043091.1",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "交通指示灯的识别方法、装置、设备、存储介质及车辆",
    "inv": [
      "李琦",
      "高涵",
      "桂义林",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109255329A",
    "apn": "CN201811046285.7",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "确定头部姿态的方法、装置、存储介质和终端设备",
    "inv": [
      "赵彤彤",
      "燕飞龙",
      "王亮",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109271914A",
    "apn": "CN201811044578.1",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "检测视线落点的方法、装置、存储介质和终端设备",
    "inv": [
      "赵彤彤",
      "燕飞龙",
      "王亮",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109242106A",
    "apn": "CN201811043185.9",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "样本处理方法、装置、设备和存储介质",
    "inv": [
      "赵岷",
      "程健一",
      "秦华鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109146962A",
    "apn": "CN201811044585.1",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "检测脸部角度的方法、装置、存储介质和终端设备",
    "inv": [
      "赵彤彤",
      "燕飞龙",
      "王亮",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109409207A",
    "apn": "CN201811050981.5",
    "apd": "2018-09-10",
    "apy": "2018",
    "tit": "无人车内乘客状态的识别方法、装置、设备及存储介质",
    "inv": [
      "陈幽涵"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109376594A",
    "apn": "CN201811055289.1",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "基于自动驾驶车辆的视觉感知方法、装置、设备以及介质",
    "inv": [
      "陈佳佳",
      "万吉",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109345589A",
    "apn": "CN201811056854.6",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "基于自动驾驶车辆的位置检测方法、装置、设备及介质",
    "inv": [
      "陈佳佳",
      "万吉",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109166120A",
    "apn": "CN201811054409.6",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "用于获取信息的方法及装置",
    "inv": [
      "陈鑫",
      "樊钢",
      "贾伟",
      "高建忠",
      "雷成军",
      "吴冬雪"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109191451A",
    "apn": "CN201811057267.9",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "异常检测方法、装置、设备和介质",
    "inv": [
      "韩忠义",
      "杨叶辉",
      "王磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109345512A",
    "apn": "CN201811062068.7",
    "apd": "2018-09-12",
    "apy": "2018",
    "tit": "汽车图像的处理方法、装置及可读存储介质",
    "inv": [
      "陈佳佳",
      "万吉",
      "夏添"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109446893A",
    "apn": "CN201811075694.X",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "人脸识别方法、装置、计算机设备及存储介质",
    "inv": [
      "彭明浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109461203A",
    "apn": "CN201811081908.4",
    "apd": "2018-09-17",
    "apy": "2018",
    "tit": "手势三维图像生成方法、装置、计算机设备及存储介质",
    "inv": [
      "彭明浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109241436A",
    "apn": "CN201811087073.3",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "梳妆方式推荐方法、装置、设备及存储介质",
    "inv": [
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109213917A",
    "apn": "CN201811091812.6",
    "apd": "2018-09-19",
    "apy": "2018",
    "tit": "3D内容的搜索方法、装置、计算机设备和存储介质",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109462922A",
    "apn": "CN201811117620.8",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "照明设备的控制方法、装置、设备及计算机可读存储介质",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109409894A",
    "apn": "CN201811099452.4",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "人脸支付的控制方法、装置、设备及存储介质",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109240931A",
    "apn": "CN201811106727.2",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "问题反馈信息处理方法和装置",
    "inv": [
      "贾金莉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "故障检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109215760A",
    "apn": "CN201811108858.4",
    "apd": "2018-09-21",
    "apy": "2018",
    "tit": "提供饮食建议的方法、装置、设备及存储介质",
    "inv": [
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109446907A",
    "apn": "CN201811124132.X",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "一种视频聊天的方法、装置、设备和计算机存储介质",
    "inv": [
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109447621A",
    "apn": "CN201811126814.4",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "支付方法和设备",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109191267A",
    "apn": "CN201811131407.2",
    "apd": "2018-09-27",
    "apy": "2018",
    "tit": "行为场所信息的推荐方法、装置、设备及计算机可读介质",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109218615A",
    "apn": "CN201811132755.1",
    "apd": "2018-09-27",
    "apy": "2018",
    "tit": "图像拍摄辅助方法、装置、终端和存储介质",
    "inv": [
      "高原"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109272442A",
    "apn": "CN201811133511.5",
    "apd": "2018-09-27",
    "apy": "2018",
    "tit": "全景球面图像的处理方法、装置、设备和存储介质",
    "inv": [
      "马春洋",
      "董维山"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109410270A",
    "apn": "CN201811142338.5",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "一种定损方法、设备和存储介质",
    "inv": [
      "赵士超",
      "谭啸",
      "周峰",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109409241A",
    "apn": "CN201811142225.5",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "视频核验方法、装置、设备及可读存储介质",
    "inv": [
      "赵翔",
      "刘霄",
      "文石磊",
      "李旭斌",
      "丁二锐",
      "孙昊",
      "李鑫",
      "柏提",
      "杨凡"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109242042A",
    "apn": "CN201811141183.3",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "图片训练样本挖掘方法、装置、终端及计算机可读存储介质",
    "inv": [
      "孟骧龙",
      "严灿祥"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109389068A",
    "apn": "CN201811139834.5",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "用于识别驾驶行为的方法和装置",
    "inv": [
      "袁宇辰",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109389589A",
    "apn": "CN201811141002.7",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "用于统计人数的方法和装置",
    "inv": [
      "袁宇辰",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109344292A",
    "apn": "CN201811143221.9",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "赛事得分片段的生成方法、装置、服务器和存储介质",
    "inv": [
      "胡海",
      "黄先良"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "竞技",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109471954A",
    "apn": "CN201811149285.X",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "基于车载设备的内容推荐方法、装置、设备和存储介质",
    "inv": [
      "苏海东"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109523652A",
    "apn": "CN201811149959.6",
    "apd": "2018-09-29",
    "apy": "2018",
    "tit": "基于驾驶行为的保险的处理方法、装置、设备及存储介质",
    "inv": [
      "商兴奇",
      "贺诚",
      "李宏言"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109300139A",
    "apn": "CN201811159602.6",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "车道线检测方法和装置",
    "inv": [
      "李映辉",
      "张丙林",
      "周志鹏",
      "李冰",
      "廖瑞华"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109508632A",
    "apn": "CN201811156840.1",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备及计算机可读存储介质",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109344899A",
    "apn": "CN201811158003.2",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "多目标检测方法、装置和电子设备",
    "inv": [
      "邱鑫",
      "李国洪",
      "张柳清"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109523556A",
    "apn": "CN201811158064.9",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "车辆部件分割方法和装置",
    "inv": [
      "赵士超",
      "谭啸",
      "周峰",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN109255392A",
    "apn": "CN201811161801.0",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "基于非局部神经网络的视频分类方法、装置及设备",
    "inv": [
      "岳凯宇",
      "孙明",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109345015A",
    "apn": "CN201811156515.5",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "用于选取路线的方法和装置",
    "inv": [
      "李冰",
      "周志鹏",
      "李映辉",
      "廖瑞华",
      "张丙林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109409247A",
    "apn": "CN201811160154.1",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "交通标志识别方法和装置",
    "inv": [
      "李映辉",
      "周志鹏",
      "李冰",
      "胡俊霄"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109241721A",
    "apn": "CN201811159901.X",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "孙涛",
      "鲍冠伯"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109410218A",
    "apn": "CN201811169110.5",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "用于生成车辆损伤信息的方法和装置",
    "inv": [
      "谭啸",
      "周峰",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109389169A",
    "apn": "CN201811169108.8",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "袁宇辰",
      "谭啸",
      "周峰",
      "丁二锐",
      "孙昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109543510A",
    "apn": "CN201811167767.8",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "人流密度估计方法、装置和电子设备",
    "inv": [
      "袁宇辰",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109359676A",
    "apn": "CN201811169076.1",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "用于生成车辆损伤信息的方法和装置",
    "inv": [
      "赵士超",
      "谭啸",
      "周峰",
      "丁二锐",
      "孙昊",
      "邓江帆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109508636A",
    "apn": "CN201811169429.8",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "车辆属性识别方法、装置、存储介质及电子设备",
    "inv": [
      "袁宇辰",
      "谭啸",
      "周峰",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109522790A",
    "apn": "CN201811168498.7",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "人体属性识别方法、装置、存储介质及电子设备",
    "inv": [
      "袁宇辰",
      "周峰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109145883B",
    "apn": "CN201811176192.6",
    "apd": "2018-10-10",
    "apy": "2018",
    "tit": "安全监控方法、装置、终端和计算机可读存储介质",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109241345A",
    "apn": "CN201811178561.5",
    "apd": "2018-10-10",
    "apy": "2018",
    "tit": "基于人脸识别的视频定位方法和装置",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109299699A",
    "apn": "CN201811177611.8",
    "apd": "2018-10-10",
    "apy": "2018",
    "tit": "行李自助托运、拿取的方法及装置",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "酒店旅行",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109544623A",
    "apn": "CN201811181292.8",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "车辆损伤区域的测量方法和装置",
    "inv": [
      "钟永沣",
      "谭啸",
      "周峰",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109151502A",
    "apn": "CN201811184309.5",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "识别违规视频方法、装置、终端和计算机可读存储介质",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109271965A",
    "apn": "CN201811184322.0",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "视频审核方法、装置及存储介质",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109360410A",
    "apn": "CN201811183907.0",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "车辆协调方法、装置、车辆和介质",
    "inv": [
      "王雅"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109409259A",
    "apn": "CN201811182108.1",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "驾驶监控方法、装置、设备及计算机可读介质",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109409992A",
    "apn": "CN201811190463.3",
    "apd": "2018-10-12",
    "apy": "2018",
    "tit": "化妆品推荐方法、装置、设备及计算机可读介质",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109345671A",
    "apn": "CN201811191630.6",
    "apd": "2018-10-12",
    "apy": "2018",
    "tit": "基于人脸识别的小区安全警示方法、装置及存储介质",
    "inv": [
      "李元朋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109376776A",
    "apn": "CN201811199181.X",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "用于播放音乐的方法和装置",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109359683A",
    "apn": "CN201811195859.7",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "目标检测方法、装置、终端和计算机可读存储介质",
    "inv": [
      "李旭斌",
      "傅依",
      "文石磊",
      "刘霄",
      "丁二锐",
      "孙昊",
      "王健"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109409364A",
    "apn": "CN201811204069.0",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "图像标注方法和装置",
    "inv": [
      "白校铭",
      "邹浔",
      "陈雪",
      "朱霞"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109525877A",
    "apn": "CN201811215133.5",
    "apd": "2018-10-18",
    "apy": "2018",
    "tit": "基于视频的信息获取方法和装置",
    "inv": [
      "王群",
      "董维山",
      "马春洋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109447911A",
    "apn": "CN201811214073.5",
    "apd": "2018-10-18",
    "apy": "2018",
    "tit": "图像复原的方法、装置、存储介质和终端设备",
    "inv": [
      "李鑫",
      "刘霄",
      "柏提",
      "赵翔",
      "杨凡",
      "孙昊",
      "李旭斌",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109029466A",
    "apn": "CN201811240832.5",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "室内导航方法和装置",
    "inv": [
      "彭明浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109145908A",
    "apn": "CN201811237114.2",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "车辆定位方法、系统、装置、测试设备和存储介质",
    "inv": [
      "张松"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109285119A",
    "apn": "CN201811237477.6",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "超分辨图像生成方法及装置",
    "inv": [
      "柏提",
      "李鑫",
      "刘霄",
      "赵翔",
      "杨凡",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109215121A",
    "apn": "CN201811238007.1",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "宋希彬",
      "姜禾",
      "杨睿刚"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109635630A",
    "apn": "CN201811238319.2",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "手部关节点检测方法、装置及存储介质",
    "inv": [
      "沈辉",
      "高原",
      "刘霄",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109376653A",
    "apn": "CN201811247083.9",
    "apd": "2018-10-24",
    "apy": "2018",
    "tit": "用于定位车辆的方法、装置、设备和介质",
    "inv": [
      "芮晓飞",
      "宋适宇",
      "丁文东",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109614848A",
    "apn": "CN201811245356.6",
    "apd": "2018-10-24",
    "apy": "2018",
    "tit": "人体识别方法、装置、设备及计算机可读存储介质",
    "inv": [
      "赵晨",
      "杨少雄",
      "张啸吟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109377315A",
    "apn": "CN201811250858.8",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "一种信息推荐方法、装置、电子设备及存储介质",
    "inv": [
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109300013A",
    "apn": "CN201811247093.2",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "莫仁鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109376666A",
    "apn": "CN201811271116.3",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "一种商品售卖方法、装置、售卖机及存储介质",
    "inv": [
      "崔磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109345829A",
    "apn": "CN201811270066.7",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "无人车的监控方法、装置、设备及存储介质",
    "inv": [
      "王小霞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109188928A",
    "apn": "CN201811267903.0",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "用于控制智能家居设备的方法和装置",
    "inv": [
      "莫仁鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109712144A",
    "apn": "CN201811271595.9",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "面部图像的处理方法、训练方法、设备和存储介质",
    "inv": [
      "陈艳琴"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN109376661A",
    "apn": "CN201811267899.8",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "莫仁鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109229095A",
    "apn": "CN201811279406.2",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "用于确定自动泊车效果的方法、装置、设备和存储介质",
    "inv": [
      "张秋宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109684911A",
    "apn": "CN201811280336.2",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "表情识别方法、装置、电子设备及存储介质",
    "inv": [
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109657534A",
    "apn": "CN201811273942.1",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "对图像中人体进行分析的方法、装置及电子设备",
    "inv": [
      "柏提",
      "傅依",
      "沈辉",
      "孙明",
      "高原",
      "刘霄",
      "李旭斌",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109348275A",
    "apn": "CN201811277817.8",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "视频处理方法和装置",
    "inv": [
      "李会娟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109284348A",
    "apn": "CN201811279968.7",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "一种电子地图的更新方法、装置、设备和存储介质",
    "inv": [
      "董晨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109711241A",
    "apn": "CN201811280331.X",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "物体检测方法、装置与电子设备",
    "inv": [
      "胡扬",
      "高原",
      "刘霄",
      "李旭斌",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109470254A",
    "apn": "CN201811290726.8",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "地图车道线的生成方法、装置、系统及存储介质",
    "inv": [
      "杨光垚",
      "侯瑞杰",
      "沈莉霞",
      "何雷",
      "宋适宇",
      "董芳芳",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109583312A",
    "apn": "CN201811290661.7",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "车道线识别方法、装置、设备及存储介质",
    "inv": [
      "杨光垚",
      "侯瑞杰",
      "沈莉霞",
      "何雷",
      "宋适宇",
      "董芳芳",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109635639A",
    "apn": "CN201811291880.7",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "交通标识的位置检测方法、装置、设备和存储介质",
    "inv": [
      "徐胜攀",
      "万国伟",
      "宋适宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109543560A",
    "apn": "CN201811287623.6",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "一种视频中人物的分割方法、装置、设备和计算机存储介质",
    "inv": [
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN109711242A",
    "apn": "CN201811291589.X",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "车道线的修正方法、装置和存储介质",
    "inv": [
      "杨光垚",
      "侯瑞杰",
      "沈莉霞",
      "何雷",
      "宋适宇",
      "董芳芳",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109658504A",
    "apn": "CN201811290749.9",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "地图数据标注方法、装置、设备及存储介质",
    "inv": [
      "彭玮琳",
      "黄杰",
      "李春晓",
      "徐胜攀",
      "宋适宇",
      "董芳芳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109584706A",
    "apn": "CN201811289086.9",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "电子地图车道线处理方法、设备及计算机可读存储介质",
    "inv": [
      "侯瑞杰",
      "沈莉霞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109657686A",
    "apn": "CN201811290736.1",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "车道线生成方法、装置、设备以及存储介质",
    "inv": [
      "杨光垚",
      "侯瑞杰",
      "沈莉霞",
      "何雷",
      "宋适宇",
      "董芳芳",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109636820A",
    "apn": "CN201811286291.X",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "电子地图车道线修正方法、设备及计算机可读存储介质",
    "inv": [
      "侯瑞杰",
      "沈莉霞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109446016A",
    "apn": "CN201811286300.5",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "一种增强现实技术AR功能测试方法、装置及系统",
    "inv": [
      "刘红保",
      "赵齐月",
      "毛楠",
      "钱承君"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109300322A",
    "apn": "CN201811291529.8",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "引导线绘制方法、装置、设备和介质",
    "inv": [
      "胡俊霄",
      "李映辉",
      "周志鹏",
      "张丙林",
      "李冰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109636841A",
    "apn": "CN201811290730.4",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "车道线生成方法和装置",
    "inv": [
      "杨光垚",
      "侯瑞杰",
      "沈莉霞",
      "何雷",
      "宋适宇",
      "董芳芳",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109657077A",
    "apn": "CN201811291586.6",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "模型训练方法、车道线生成方法、设备及存储介质",
    "inv": [
      "沈莉霞",
      "侯瑞杰",
      "何雷",
      "杨光垚",
      "董芳芳",
      "宋适宇",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109636867A",
    "apn": "CN201811285629.X",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "郭汉奇",
      "洪智滨"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109583313A",
    "apn": "CN201811290738.0",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "车道线提取方法、装置及存储介质",
    "inv": [
      "杨光垚",
      "侯瑞杰",
      "沈莉霞",
      "何雷",
      "宋适宇",
      "董芳芳",
      "彭亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109447003A",
    "apn": "CN201811291313.1",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "车辆检测方法、装置、设备和介质",
    "inv": [
      "胡俊霄",
      "李映辉",
      "周志鹏",
      "张丙林",
      "李冰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109669997A",
    "apn": "CN201811290747.X",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "电子地图处理方法、设备及计算机可读存储介质",
    "inv": [
      "侯瑞杰",
      "沈莉霞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109657133A",
    "apn": "CN201811290662.1",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "交友对象推荐方法、装置、设备和存储介质",
    "inv": [
      "傅一"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109377539A",
    "apn": "CN201811315036.3",
    "apd": "2018-11-06",
    "apy": "2018",
    "tit": "用于生成动画的方法和装置",
    "inv": [
      "王建祥",
      "吕复强",
      "刘骁",
      "纪建超"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109558505A",
    "apn": "CN201811392516.X",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "视觉搜索方法、装置、计算机设备及存储介质",
    "inv": [
      "张柳清",
      "李国洪",
      "邱鑫",
      "高树会",
      "张亚洲"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109543683A",
    "apn": "CN201811456325.5",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "图像标注修正方法、装置、设备和介质",
    "inv": [
      "王洪志",
      "吴云鹏",
      "刘慧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109584235A",
    "apn": "CN201811456255.3",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "塑料泡沫餐盒的质量检测方法、装置、服务器及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109598712A",
    "apn": "CN201811456293.9",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "塑料泡沫餐盒的质量检测方法、装置、服务器及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109544548A",
    "apn": "CN201811458919.X",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "餐盒的缺陷检测方法、装置、服务器、设备和存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109470708A",
    "apn": "CN201811458882.0",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "塑料泡沫餐盒的质量检测方法、装置、服务器及存储介质",
    "inv": [
      "文亚伟",
      "冷家冰",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109561236A",
    "apn": "CN201811457916.4",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "图像加密、识别方法装置、设备及介质",
    "inv": [
      "孟浩",
      "马丽芬"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109451240A",
    "apn": "CN201811473455.X",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "对焦方法、装置、计算机设备和可读存储介质",
    "inv": [
      "柯海帆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109670106A",
    "apn": "CN201811488831.2",
    "apd": "2018-12-06",
    "apy": "2018",
    "tit": "基于场景的事物推荐方法和装置",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109559536A",
    "apn": "CN201811501707.5",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "交通灯、交通灯识别方法、装置、设备及存储介质",
    "inv": [
      "刘海涛",
      "沈洪顺"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109783588A",
    "apn": "CN201811502462.8",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "地图的错误信息检测方法、装置、设备、车辆及存储介质",
    "inv": [
      "杨凡",
      "朱晓星",
      "王智杰",
      "宋适宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109711285A",
    "apn": "CN201811508096.7",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "识别模型的训练、测试方法和装置",
    "inv": [
      "胡太群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109801472A",
    "apn": "CN201811509369.X",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "安保监控方法、装置、无人驾驶车辆及可读存储介质",
    "inv": [
      "杨凡",
      "柴婉琦",
      "唐萌萌"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109711296A",
    "apn": "CN201811534884.3",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "物体分类方法及其装置、计算机程序产品、可读存储介质",
    "inv": [
      "张弛",
      "王昊",
      "王亮"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109711299A",
    "apn": "CN201811540092.7",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "车辆客流统计方法、装置、设备及存储介质",
    "inv": [
      "王子杰",
      "刘颖楠",
      "饶文龙",
      "薛晶晶",
      "王月"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109710802A",
    "apn": "CN201811563523.1",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "视频分类方法及其装置",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109800654A",
    "apn": "CN201811581932.4",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "车载摄像头检测处理方法、装置及车辆",
    "inv": [
      "唐坤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109657487A",
    "apn": "CN201811583635.3",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "图像处理方法、图像验证方法及其装置",
    "inv": [
      "代一鸣"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109740469A",
    "apn": "CN201811581791.6",
    "apd": "2018-12-24",
    "apy": "2018",
    "tit": "车道线检测方法、装置、计算机设备和存储介质",
    "inv": [
      "翟玉强",
      "谢术富",
      "夏添",
      "马彧"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109711335A",
    "apn": "CN201811598714.1",
    "apd": "2018-12-26",
    "apy": "2018",
    "tit": "通过人体特征对目标图片进行驱动的方法及装置",
    "inv": [
      "季爱军",
      "叶欢",
      "袁瀚"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109657638A",
    "apn": "CN201811628789.X",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "障碍物定位方法、装置和终端",
    "inv": [
      "李傲伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109766799A",
    "apn": "CN201811621098.7",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "车位识别模型训练方法及装置和车位识别方法及装置",
    "inv": [
      "杨树"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109703465A",
    "apn": "CN201811625730.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "车载图像传感器的控制方法和装置",
    "inv": [
      "陈杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109711349A",
    "apn": "CN201811620207.3",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "用于生成控制指令的方法和装置",
    "inv": [
      "杨树"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109407679A",
    "apn": "CN201811620235.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "用于控制无人驾驶汽车的方法和装置",
    "inv": [
      "杨树"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109693672A",
    "apn": "CN201811620220.9",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "用于控制无人驾驶汽车的方法和装置",
    "inv": [
      "杨树"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109766804A",
    "apn": "CN201811627449.5",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "基于车载场景的物品识别方法、装置、设备和存储介质",
    "inv": [
      "程新强",
      "邓天坚",
      "方胜"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109685850A",
    "apn": "CN201811644293.1",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种横向定位方法及车载设备",
    "inv": [
      "魏宁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109606384A",
    "apn": "CN201811638546.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "车辆控制方法、装置、设备和存储介质",
    "inv": [
      "郁浩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109657643A",
    "apn": "CN201811640344.3",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "闫泳杉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109801339A",
    "apn": "CN201811643204.1",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "图像处理方法、装置和存储介质",
    "inv": [
      "杨阳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109624855A",
    "apn": "CN201811645339.1",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "自动泊车的停止位置确定方法及装置、电子设备和计算机可读介质",
    "inv": [
      "柳奥"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109760688A",
    "apn": "CN201811637219.7",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "路段信息确定方法、装置、车辆及计算机可读存储介质",
    "inv": [
      "杨阳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109489680A",
    "apn": "CN201811644294.6",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种螺旋车道的参考轨迹线生成方法及车载设备",
    "inv": [
      "魏宁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109544981A",
    "apn": "CN201811638484.7",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "图像处理方法、装置、设备和介质",
    "inv": [
      "朱重黎",
      "童俊涛",
      "崔永",
      "房菲"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109649382A",
    "apn": "CN201811645405.5",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "自动泊车的路径规划方法及装置、电子设备和计算机可读介质",
    "inv": [
      "柳奥"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109784234A",
    "apn": "CN201811637263.8",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "一种基于前向鱼眼镜头的直角弯识别方法及车载设备",
    "inv": [
      "魏宁"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109733403A",
    "apn": "CN201811644640.0",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "自动驾驶车辆停车方法、装置、服务器和计算机可读介质",
    "inv": [
      "张扬"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109816588A",
    "apn": "CN201811640538.3",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "行车轨迹线的记录方法、装置及设备",
    "inv": [
      "杨阳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109829935A",
    "apn": "CN201811633876.4",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "车辆的场景序列跟踪处理方法、装置及车辆",
    "inv": [
      "姚萌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109740521A",
    "apn": "CN201811645346.1",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "自动泊车的车位位置确定方法及装置、电子设备和计算机可读介质",
    "inv": [
      "柳奥"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109858369A",
    "apn": "CN201811633970.X",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "自动驾驶方法和装置",
    "inv": [
      "唐坤"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109711363A",
    "apn": "CN201811631573.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "车辆定位方法、装置、设备和存储介质",
    "inv": [
      "姚萌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109753928A",
    "apn": "CN201910004812.6",
    "apd": "2019-01-03",
    "apy": "2019",
    "tit": "违章建筑物识别方法和装置",
    "inv": [
      "孟泉",
      "周淼",
      "王蔚",
      "范竣翔",
      "陈科第"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110390240A",
    "apn": "CN201910037455.3",
    "apd": "2019-01-15",
    "apy": "2019",
    "tit": "自动驾驶车辆中的车道后处理",
    "inv": [
      "朱俊",
      "崔泰恩",
      "陈光",
      "张伟德"
    ],
    "app": [
      "百度(美国)有限责任公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109599113A",
    "apn": "CN201910058552.0",
    "apd": "2019-01-22",
    "apy": "2019",
    "tit": "用于处理信息的方法和装置",
    "inv": [
      "刘骁",
      "吕复强",
      "王建祥",
      "纪建超"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109814559A",
    "apn": "CN201910075040.5",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "用于控制挖掘机挖掘的方法和装置",
    "inv": [
      "程新景",
      "杨睿刚",
      "杨雅珏",
      "卢飞翔",
      "徐昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109903217A",
    "apn": "CN201910073701.0",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "图像变形方法和装置",
    "inv": [
      "常元章"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109816778A",
    "apn": "CN201910075865.7",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "物料堆三维重建方法、装置、电子设备和计算机可读介质",
    "inv": [
      "程新景",
      "杨睿刚",
      "卢飞翔",
      "杨雅珏",
      "徐昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109816704A",
    "apn": "CN201910077895.1",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "物体的三维信息获取方法和装置",
    "inv": [
      "宋希彬",
      "杨睿刚"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109729429A",
    "apn": "CN201910101235.2",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "视频播放方法、装置、设备和介质",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109872349A",
    "apn": "CN201910122052.9",
    "apd": "2019-02-19",
    "apy": "2019",
    "tit": "基于前景检测的图像后处理方法、装置、电子设备及介质",
    "inv": [
      "刘博",
      "舒茂"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109886199A",
    "apn": "CN201910131112.3",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "信息处理方法、装置、车辆及移动终端",
    "inv": [
      "罗序斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "百度",
    "pun": "CN109876457A",
    "apn": "CN201910130403.0",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "游戏角色生成方法、装置及存储介质",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109871807A",
    "apn": "CN201910130258.6",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "人脸图像处理方法和装置",
    "inv": [
      "莫仁鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110012257A",
    "apn": "CN201910129715.X",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "通话方法、装置及终端",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109993807A",
    "apn": "CN201910130415.3",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "头像生成方法、装置及存储介质",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109640119A",
    "apn": "CN201910132764.9",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110002290A",
    "apn": "CN201910132922.0",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "电梯门禁控制方法、装置、存储介质及电子设备",
    "inv": [
      "林成龙"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "电梯",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109886950A",
    "apn": "CN201910133360.1",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "电路板的缺陷检测方法和装置",
    "inv": [
      "文亚伟",
      "苏业",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109871895A",
    "apn": "CN201910133359.9",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "电路板的缺陷检测方法和装置",
    "inv": [
      "文亚伟",
      "苏业",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109886210A",
    "apn": "CN201910138054.7",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "一种交通图像识别方法、装置、计算机设备和介质",
    "inv": [
      "刘焱",
      "王洋",
      "郝新",
      "吴月升"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109902617A",
    "apn": "CN201910138706.7",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "一种图片识别方法、装置、计算机设备和介质",
    "inv": [
      "刘焱",
      "王洋",
      "郝新"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109670491A",
    "apn": "CN201910138127.2",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "鉴别人脸图像的方法、装置、设备和存储介质",
    "inv": [
      "刘焱",
      "王洋",
      "郝新"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109857260A",
    "apn": "CN201910145622.6",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "三维互动影像的控制方法、装置和系统",
    "inv": [
      "张继丰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109902624A",
    "apn": "CN201910146248.1",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "用于呈现信息的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109903337A",
    "apn": "CN201910148743.6",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "用于确定挖掘机的铲斗的位姿的方法和装置",
    "inv": [
      "程新景",
      "杨睿刚",
      "卢飞翔",
      "杨雅珏",
      "徐昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109815156A",
    "apn": "CN201910150300.0",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "页面中视觉元素的展示测试方法、装置、设备和存储介质",
    "inv": [
      "李兴波"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109733966A",
    "apn": "CN201910148752.5",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "用于控制电梯的方法和装置",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "电梯",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110030588A",
    "apn": "CN201910148981.7",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "煤气灶安全控制方法、装置、设备和存储介质",
    "inv": [
      "陶天然"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109872408A",
    "apn": "CN201910148856.6",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "用于发送信息的方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "办公室",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109871819A",
    "apn": "CN201910148858.5",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "用于发送信息的方法和装置",
    "inv": [
      "孙颖"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109693981A",
    "apn": "CN201910148754.4",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "用于发送信息的方法和装置",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "电梯",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109886378A",
    "apn": "CN201910164581.5",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "莫仁鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "状态监测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109871822A",
    "apn": "CN201910164539.3",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "状态监测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109934806A",
    "apn": "CN201910164692.6",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "图像确定方法、装置、设备和存储介质",
    "inv": [
      "孙钦佩",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109784337A",
    "apn": "CN201910164878.1",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "一种黄斑区识别方法、装置及计算机可读存储介质",
    "inv": [
      "孙钦佩",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109697719A",
    "apn": "CN201910164856.5",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "一种图像质量评估方法、装置及计算机可读存储介质",
    "inv": [
      "孙旭",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN109886955A",
    "apn": "CN201910163979.7",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "用于处理眼底图像的方法和装置",
    "inv": [
      "孙钦佩",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN109919079A",
    "apn": "CN201910165232.5",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "用于检测学习状态的方法和装置",
    "inv": [
      "莫仁鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "状态监测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109858469A",
    "apn": "CN201910168529.7",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109858470A",
    "apn": "CN201910168642.5",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "佟莎莎"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109829850A",
    "apn": "CN201910168399.7",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "图像处理方法、装置、设备及计算机可读介质",
    "inv": [
      "张继丰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN109872432A",
    "apn": "CN201910166785.2",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "门禁装置的控制方法、装置、设备、系统和存储介质",
    "inv": [
      "张继丰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109829447A",
    "apn": "CN201910168651.4",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "用于确定车辆三维框架的方法和装置",
    "inv": [
      "金文耀"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109919089A",
    "apn": "CN201910169306.2",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109934149A",
    "apn": "CN201910169311.3",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109916426A",
    "apn": "CN201910168108.4",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "引导箭头绘制方法、装置、设备和介质",
    "inv": [
      "胡俊霄",
      "李映辉",
      "周志鹏",
      "张丙林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109948479A",
    "apn": "CN201910168775.2",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "工厂的监控方法、装置及设备",
    "inv": [
      "张继丰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110033344A",
    "apn": "CN201910166331.5",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "基于神经网络的护肤品推荐方法、装置及存储介质",
    "inv": [
      "傅一"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109919090A",
    "apn": "CN201910169307.7",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "宠物",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109829446A",
    "apn": "CN201910167485.6",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "眼底图像识别方法、装置、电子设备及存储介质",
    "inv": [
      "刘佳",
      "杨叶辉",
      "许言午",
      "王磊",
      "黄艳"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109933723A",
    "apn": "CN201910171780.9",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "用于推送信息的方法和装置",
    "inv": [
      "田飞",
      "李彤辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109919678A",
    "apn": "CN201910172315.7",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "广告播放方法、装置、设备及计算机可读介质",
    "inv": [
      "崔磊",
      "杨锐"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109902645A",
    "apn": "CN201910171536.2",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "状态监测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109934150A",
    "apn": "CN201910172167.9",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种会议参与度识别方法、装置、服务器和存储介质",
    "inv": [
      "杨尊程"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "办公室",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109887234A",
    "apn": "CN201910172200.8",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种防儿童走失方法、装置、电子设备及存储介质",
    "inv": [
      "杨尊程"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109835260A",
    "apn": "CN201910172181.9",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种车辆信息显示方法、装置、终端和存储介质",
    "inv": [
      "杨尊程"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109887077A",
    "apn": "CN201910171928.9",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "用于生成三维模型的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109916055A",
    "apn": "CN201910175974.6",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "控制方法和装置",
    "inv": [
      "田飞",
      "杨尊程"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109947565A",
    "apn": "CN201910175565.6",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "用于分配计算任务的方法和装置",
    "inv": [
      "卢文祥",
      "李玉明",
      "王云龙"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "百度",
    "pun": "CN109933430A",
    "apn": "CN201910176102.1",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "分配图形处理器的方法和装置",
    "inv": [
      "曾李志",
      "张恒华",
      "刘传秀"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "百度",
    "pun": "CN109949286A",
    "apn": "CN201910184208.6",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "王铖成",
      "苏业",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109887078A",
    "apn": "CN201910184149.2",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "天空绘制方法、装置、设备和介质",
    "inv": [
      "胡俊霄",
      "周志鹏",
      "张丙林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109918513A",
    "apn": "CN201910184113.4",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "图像处理方法、装置、服务器及存储介质",
    "inv": [
      "张晓寒",
      "徐也",
      "任可欣",
      "冯知凡",
      "张扬",
      "朱勇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109918606A",
    "apn": "CN201910189445.1",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "违禁图片的检测方法和装置",
    "inv": [
      "刘焱",
      "王洋",
      "郝新"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109933199A",
    "apn": "CN201910189078.5",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "基于手势的控制方法、装置、电子设备及存储介质",
    "inv": [
      "罗序斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109785633A",
    "apn": "CN201910195141.6",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "危险路况提醒方法、装置、车载终端、服务器及介质",
    "inv": [
      "罗序斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109889807A",
    "apn": "CN201910194154.1",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "车载投射调节方法、装置、设备和存储介质",
    "inv": [
      "刘毅",
      "周志鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109968979A",
    "apn": "CN201910194709.2",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "车载投射处理方法、装置、车载设备及存储介质",
    "inv": [
      "刘毅",
      "周志鹏",
      "张丙林",
      "邓苏南",
      "罗志平",
      "李冰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109917920A",
    "apn": "CN201910194815.0",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "车载投射处理方法、装置、车载设备及存储介质",
    "inv": [
      "刘毅",
      "周志鹏",
      "邓苏南",
      "罗志平",
      "李冰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109949469A",
    "apn": "CN201910193739.1",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "排号装置和排号方法",
    "inv": [
      "张继丰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109977823A",
    "apn": "CN201910199192.6",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "行人识别跟踪方法、装置、计算机设备和存储介质",
    "inv": [
      "张成月",
      "亢乐",
      "连四通"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109977826A",
    "apn": "CN201910199889.3",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "物体的类别识别方法和装置",
    "inv": [
      "马明明",
      "罗宇轩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109949347A",
    "apn": "CN201910199204.5",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "人体跟踪方法、装置、系统、电子设备和存储介质",
    "inv": [
      "张成月",
      "亢乐",
      "杨洪天",
      "杨松",
      "刘涛",
      "卢景熙",
      "包英泽",
      "陈明裕",
      "章宏武",
      "刘泽宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109977825A",
    "apn": "CN201910199884.0",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "物品识别方法及装置",
    "inv": [
      "王晓"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109948515A",
    "apn": "CN201910199890.6",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "物体的类别识别方法和装置",
    "inv": [
      "马明明"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110007755A",
    "apn": "CN201910199231.2",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "基于动作识别的物体事件触发方法、装置及其相关设备",
    "inv": [
      "雷超兵"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109902658A",
    "apn": "CN201910199195.X",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "行人特征识别方法、装置、计算机设备和存储介质",
    "inv": [
      "张成月",
      "亢乐",
      "连四通"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109977824A",
    "apn": "CN201910199219.1",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "物品取放识别方法、装置及设备",
    "inv": [
      "罗宇轩",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109934175A",
    "apn": "CN201910199350.8",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "一种车辆导航方法、装置、电子设备及存储介质",
    "inv": [
      "罗志平",
      "周志鹏",
      "张丙林",
      "邓苏南",
      "李冰",
      "刘毅"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109919119A",
    "apn": "CN201910199003.5",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "被操作物品处理方法及装置、服务器及计算机可读介质",
    "inv": [
      "王青泽",
      "罗宇轩"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109949213A",
    "apn": "CN201910199011.X",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109934873A",
    "apn": "CN201910199886.X",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "标注图像获取方法、装置及设备",
    "inv": [
      "王晓",
      "王青泽"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109919118A",
    "apn": "CN201910198009.0",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "AR导航中的障碍物提示方法、装置、设备及介质",
    "inv": [
      "李映辉",
      "周志鹏",
      "冯遥",
      "马瑞兵",
      "陈真"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109895781A",
    "apn": "CN201910202612.1",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "用于控制车辆的方法和装置",
    "inv": [
      "田飞"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109920056A",
    "apn": "CN201910203318.2",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "建筑物渲染方法、装置、设备和介质",
    "inv": [
      "胡俊霄",
      "祝博",
      "周志鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109949360A",
    "apn": "CN201910204580.9",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "一种道路中心线的提取方法、装置、电子设备及存储介质",
    "inv": [
      "高建虎"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109993078A",
    "apn": "CN201910203068.2",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "车载环境的图像识别方法、装置及设备",
    "inv": [
      "罗序斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109948542A",
    "apn": "CN201910210038.4",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "手势识别方法、装置、电子设备和存储介质",
    "inv": [
      "赵晨",
      "杨少雄",
      "高原"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109948931A",
    "apn": "CN201910213303.4",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "一种人体救援方法、装置、智能眼镜及存储介质",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109948545A",
    "apn": "CN201910212167.7",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "一种用户行为的提示方法、装置、电子设备及存储介质",
    "inv": [
      "路双"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109826282A",
    "apn": "CN201910214451.8",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "供水设备的出水调节方法、装置、设备及可读存储介质",
    "inv": [
      "杨尊程"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109977845A",
    "apn": "CN201910218775.9",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "一种可行驶区域检测方法及车载终端",
    "inv": [
      "李浩",
      "谷硕"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109978868A",
    "apn": "CN201910247494.6",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "玩具外表质量检测方法及其相关设备",
    "inv": [
      "文亚伟",
      "冷家冰",
      "王铖成",
      "苏业",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109949305A",
    "apn": "CN201910247386.9",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "产品表面缺陷检测方法、装置及计算机设备",
    "inv": [
      "文亚伟"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109978867A",
    "apn": "CN201910247409.6",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "玩具外表质量检测方法及其相关设备",
    "inv": [
      "文亚伟",
      "冷家冰",
      "王铖成",
      "苏业",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110009614A",
    "apn": "CN201910248452.4",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "文亚伟",
      "冷家冰",
      "王铖成",
      "苏业",
      "刘明浩",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109978870A",
    "apn": "CN201910248451.X",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "文亚伟"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109993734A",
    "apn": "CN201910248418.7",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "文亚伟"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109886964A",
    "apn": "CN201910247392.4",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "电路板缺陷检测方法、装置及设备",
    "inv": [
      "文亚伟",
      "苏业",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109903287A",
    "apn": "CN201910247360.4",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "质量检测方法及装置",
    "inv": [
      "文亚伟"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109961433A",
    "apn": "CN201910247474.9",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "产品缺陷检测方法、装置及计算机设备",
    "inv": [
      "文亚伟"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109916921A",
    "apn": "CN201910247410.9",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "电路板缺陷处理方法、装置及设备",
    "inv": [
      "文亚伟",
      "苏业",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109974733A",
    "apn": "CN201910261153.4",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "用于AR导航的POI显示方法、装置、终端和介质",
    "inv": [
      "李冰",
      "周志鹏",
      "张丙林",
      "李映辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110009720A",
    "apn": "CN201910261454.7",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "AR场景中的图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "罗志平",
      "周志鹏",
      "张丙林",
      "刘毅",
      "邓苏南"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109931945A",
    "apn": "CN201910262940.0",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "AR导航方法、装置、设备和存储介质",
    "inv": [
      "李冰",
      "周志鹏",
      "张丙林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110119679A",
    "apn": "CN201910262603.1",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "物体三维信息估计方法及装置、计算机设备、存储介质",
    "inv": [
      "宋希彬",
      "卢飞翔",
      "周定富",
      "杨睿刚"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109961522A",
    "apn": "CN201910261358.2",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "图像投射方法、装置、设备和存储介质",
    "inv": [
      "李映辉",
      "冯遥",
      "马瑞兵",
      "胡俊霄",
      "周志鹏"
    ],
    "app": [
      "百度国际科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109931944A",
    "apn": "CN201910262929.4",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "一种AR导航方法、装置、车端设备、服务端及介质",
    "inv": [
      "李冰",
      "周志鹏",
      "张丙林",
      "李映辉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109974734A",
    "apn": "CN201910262224.2",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "一种用于AR导航的事件上报方法、装置、终端及存储介质",
    "inv": [
      "李冰",
      "周志鹏",
      "张丙林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110084133A",
    "apn": "CN201910265132.X",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "障碍物检测方法、装置、车辆、计算机设备和存储介质",
    "inv": [
      "金文耀",
      "李金鹏",
      "秦硕"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110083574A",
    "apn": "CN201910266009.X",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "图片的管理方法、装置和终端设备",
    "inv": [
      "张继丰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109993700A",
    "apn": "CN201910265528.4",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "数据处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "罗盼",
      "白宇",
      "张振理",
      "王方伟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109978796A",
    "apn": "CN201910269535.1",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "眼底血管图片生成方法、装置及存储介质",
    "inv": [
      "孙旭",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN109977905A",
    "apn": "CN201910269661.7",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "用于处理眼底图像的方法和装置",
    "inv": [
      "杨叶辉",
      "许言午",
      "王磊",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110009038A",
    "apn": "CN201910269536.6",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "筛查模型的训练方法、装置及存储介质",
    "inv": [
      "孙旭",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN109978877A",
    "apn": "CN201910269583.0",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "筛查模型的训练方法、装置及存储介质",
    "inv": [
      "杨叶辉",
      "许言午",
      "王磊",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN110070009A",
    "apn": "CN201910277774.1",
    "apd": "2019-04-08",
    "apy": "2019",
    "tit": "路面物体识别方法和装置",
    "inv": [
      "刘博",
      "舒茂"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110031010A",
    "apn": "CN201910282141.X",
    "apd": "2019-04-09",
    "apy": "2019",
    "tit": "车辆引导路线绘制方法、装置及设备",
    "inv": [
      "李冰",
      "周志鹏",
      "张丙林",
      "李映辉",
      "廖瑞华"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110084289A",
    "apn": "CN201910288875.9",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "图像标注方法、装置、电子设备及存储介质",
    "inv": [
      "王震",
      "丛会智"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110021052A",
    "apn": "CN201910291160.9",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于生成眼底图像生成模型的方法和装置",
    "inv": [
      "杨大陆",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110136698A",
    "apn": "CN201910289051.3",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于确定嘴型的方法、装置、设备和存储介质",
    "inv": [
      "李鑫",
      "刘霄",
      "赵翔",
      "李甫",
      "何栋梁",
      "龙翔",
      "迟至真",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110084230A",
    "apn": "CN201910289242.X",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "基于图像的车身方向检测方法和装置",
    "inv": [
      "李莹莹",
      "谭啸",
      "文石磊",
      "孙昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN109961060A",
    "apn": "CN201910290676.1",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于生成人群密度信息的方法和装置",
    "inv": [
      "袁宇辰",
      "谭啸",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110009627A",
    "apn": "CN201910290527.5",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "杨大陆",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN110009626A",
    "apn": "CN201910290517.1",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "杨大陆",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110174120A",
    "apn": "CN201910304850.3",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "用于AR导航模拟的时间同步方法及装置",
    "inv": [
      "李映辉",
      "冯遥",
      "卓先进",
      "周志鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110070623A",
    "apn": "CN201910303285.9",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "引导线绘制提示方法、装置、计算机设备和存储介质",
    "inv": [
      "胡俊霄",
      "李映辉",
      "周志鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110136222A",
    "apn": "CN201910307743.6",
    "apd": "2019-04-17",
    "apy": "2019",
    "tit": "虚拟车道线生成方法、装置及系统",
    "inv": [
      "姚传奇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110108294A",
    "apn": "CN201910310420.2",
    "apd": "2019-04-17",
    "apy": "2019",
    "tit": "ARHUD导航的运行系统和方法",
    "inv": [
      "冯遥",
      "李映辉",
      "周志鹏",
      "张丙林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110136519A",
    "apn": "CN201910310527.7",
    "apd": "2019-04-17",
    "apy": "2019",
    "tit": "基于ARHUD导航的模拟系统和方法",
    "inv": [
      "冯遥",
      "李映辉",
      "周志鹏",
      "张丙林"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110059619A",
    "apn": "CN201910309505.9",
    "apd": "2019-04-17",
    "apy": "2019",
    "tit": "基于图像识别自动报警的方法和装置",
    "inv": [
      "詹灿章"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110189132A",
    "apn": "CN201910319713.7",
    "apd": "2019-04-19",
    "apy": "2019",
    "tit": "人脸支付装置、方法、系统及机器可读存储介质",
    "inv": [
      "康佳美"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110084298A",
    "apn": "CN201910327769.7",
    "apd": "2019-04-23",
    "apy": "2019",
    "tit": "用于检测图像相似度的方法及装置",
    "inv": [
      "李兴波"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN110148202A",
    "apn": "CN201910340395.2",
    "apd": "2019-04-25",
    "apy": "2019",
    "tit": "用于生成图像的方法、装置、设备和存储介质",
    "inv": [
      "龙翔",
      "高原",
      "李鑫",
      "刘霄",
      "张赫男",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN109949812A",
    "apn": "CN201910343729.1",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "一种语音交互方法、装置、设备及存储介质",
    "inv": [
      "戚耀文"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110046457A",
    "apn": "CN201910344538.7",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "人体模型的控制方法、装置、电子设备以及存储介质",
    "inv": [
      "周波",
      "曾宏生",
      "王凡",
      "何径舟"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110175528A",
    "apn": "CN201910356293.X",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "人体跟踪方法及装置、计算机设备及可读介质",
    "inv": [
      "卢子鹏",
      "王健",
      "文石磊",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110175527A",
    "apn": "CN201910355601.7",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "行人再识别方法及装置、计算机设备及可读介质",
    "inv": [
      "王之港",
      "王健",
      "文石磊",
      "丁二锐",
      "孙昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110210304A",
    "apn": "CN201910355788.0",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "用于目标检测和跟踪的方法及系统",
    "inv": [
      "何刚",
      "袁宇辰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN109982106A",
    "apn": "CN201910357558.8",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "一种视频推荐方法、服务器、客户端及电子设备",
    "inv": [
      "李飞",
      "董立强",
      "王琳",
      "于灵珊",
      "牛化康",
      "王超",
      "贠挺",
      "刘霏暄",
      "陈国庆",
      "赵世奇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN110135305A",
    "apn": "CN201910363984.2",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "用于疲劳度检测的方法、装置、设备和介质",
    "inv": [
      "刘焱"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110135301A",
    "apn": "CN201910362985.5",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "交通牌识别方法、装置、设备和计算机可读介质",
    "inv": [
      "刘焱",
      "王洋"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110135302A",
    "apn": "CN201910363252.3",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "训练车道线识别模型的方法、装置、设备和存储介质",
    "inv": [
      "刘焱"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110135483A",
    "apn": "CN201910363927.4",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "训练图像识别模型的方法、装置及相关设备",
    "inv": [
      "张家栋",
      "李超",
      "刘国翌"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110070085A",
    "apn": "CN201910359065.8",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "车牌识别方法和装置",
    "inv": [
      "杨静远",
      "孙逸鹏",
      "韩钧宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110189242A",
    "apn": "CN201910370548.8",
    "apd": "2019-05-06",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "雷宇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110135437A",
    "apn": "CN201910372962.2",
    "apd": "2019-05-06",
    "apy": "2019",
    "tit": "用于车辆的定损方法、装置、电子设备和计算机存储介质",
    "inv": [
      "张伟",
      "谭啸",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110223320A",
    "apn": "CN201910381582.5",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "物体检测跟踪方法和检测跟踪装置",
    "inv": [
      "高原",
      "沈辉",
      "钟东宏",
      "刘霄",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110308468A",
    "apn": "CN201910385656.2",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "地点推荐方法和装置",
    "inv": [
      "罗序斌"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110188303A",
    "apn": "CN201910389806.7",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "页面错误识别方法和装置",
    "inv": [
      "陈都",
      "赵德玺"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110287779A",
    "apn": "CN201910410294.8",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "车道线的检测方法、装置及设备",
    "inv": [
      "潘杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110135356A",
    "apn": "CN201910412569.1",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "违章停车的检测方法及装置、电子设备、计算机可读介质",
    "inv": [
      "王冠皓",
      "叶芷"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110110696A",
    "apn": "CN201910413380.4",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "用于处理信息的方法和装置",
    "inv": [
      "周子翔"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110097600A",
    "apn": "CN201910412771.4",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "用于识别交通标志牌的方法及装置",
    "inv": [
      "段旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110188640A",
    "apn": "CN201910419484.6",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "人脸识别方法、装置、服务器和计算机可读介质",
    "inv": [
      "赵谦谦"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110086997A",
    "apn": "CN201910419580.0",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "人脸图像曝光亮度补偿方法及装置",
    "inv": [
      "赵谦谦"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110120044A",
    "apn": "CN201910417325.2",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "物品缺陷检测方法及装置、边缘设备、服务器",
    "inv": [
      "文亚伟",
      "王旭雅"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110135370A",
    "apn": "CN201910419412.1",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "人脸活体检测的方法及装置、电子设备、计算机可读介质",
    "inv": [
      "马彩虹"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110119725A",
    "apn": "CN201910419433.3",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "用于检测信号灯的方法及装置",
    "inv": [
      "段旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110120024A",
    "apn": "CN201910420683.9",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "图像处理的方法、装置、设备和存储介质",
    "inv": [
      "刘焱"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110135377A",
    "apn": "CN201910426042.4",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "物体运动状态检测方法、装置、服务器和计算机可读介质",
    "inv": [
      "舒茂"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110302524A",
    "apn": "CN201910430868.8",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "肢体训练方法、装置、设备和存储介质",
    "inv": [
      "吴中勤",
      "李颖超",
      "赵晨",
      "宫延河",
      "白超",
      "董玉华"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110188645A",
    "apn": "CN201910431152.X",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "用于车载场景的人脸检测方法、装置、车辆及存储介质",
    "inv": [
      "雷宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110309906A",
    "apn": "CN201910434293.7",
    "apd": "2019-05-23",
    "apy": "2019",
    "tit": "图像处理方法、装置、机器可读存储介质及处理器",
    "inv": [
      "王康康",
      "何斌"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110147845A",
    "apn": "CN201910435700.6",
    "apd": "2019-05-23",
    "apy": "2019",
    "tit": "基于特征空间的样本采集方法和样本采集系统",
    "inv": [
      "徐化永"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN110049351A",
    "apn": "CN201910436504.0",
    "apd": "2019-05-23",
    "apy": "2019",
    "tit": "视频流中人脸变形的方法和装置、电子设备、计算机可读介质",
    "inv": [
      "常元章",
      "马晓昕",
      "赵辉"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110163153A",
    "apn": "CN201910435230.3",
    "apd": "2019-05-23",
    "apy": "2019",
    "tit": "用于识别交通标志牌边界的方法及装置",
    "inv": [
      "段旭"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN110287203A",
    "apn": "CN201910441493.5",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "用于售货机的更新方法、更新装置及售货机",
    "inv": [
      "王晓",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110288513A",
    "apn": "CN201910441508.8",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "用于改变人脸属性的方法、装置、设备和存储介质",
    "inv": [
      "刘霄",
      "左斌华",
      "丁予康",
      "张赫男",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110189272A",
    "apn": "CN201910442250.3",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "用于处理图像的方法、装置、设备和存储介质",
    "inv": [
      "刘霄",
      "丁予康",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN110189312A",
    "apn": "CN201910440756.0",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "眼底图像的亮度评估方法、装置、电子设备及存储介质",
    "inv": [
      "黄甜甜",
      "杨大陆",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN110135517A",
    "apn": "CN201910441616.5",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "用于获取车辆相似度的方法及装置",
    "inv": [
      "段旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110147776A",
    "apn": "CN201910441092.X",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "确定人脸关键点位置的方法和装置",
    "inv": [
      "洪智滨",
      "郭汉奇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110162649A",
    "apn": "CN201910441621.6",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "样本数据获取方法、获取系统、服务器和计算机可读介质",
    "inv": [
      "杨大陆",
      "孙旭",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110245579A",
    "apn": "CN201910440778.7",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "人流密度预测方法及装置、计算机设备及可读介质",
    "inv": [
      "袁宇辰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110163832A",
    "apn": "CN201910441224.9",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "人脸融合方法、装置和终端",
    "inv": [
      "康洋",
      "洪智滨"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110288568A",
    "apn": "CN201910443966.5",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "眼底图像处理方法、装置、设备和存储介质",
    "inv": [
      "杨叶辉",
      "杨大陆",
      "许言午",
      "王磊",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110148130A",
    "apn": "CN201910446993.8",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "用于检测零件缺陷的方法和装置",
    "inv": [
      "黄特辉",
      "刘明浩",
      "聂磊",
      "郭江亮",
      "苏业",
      "邹建法"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110163930A",
    "apn": "CN201910447156.7",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "车道线生成方法、装置、设备、系统及可读存储介质",
    "inv": [
      "高超",
      "蔡育展",
      "郑超"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110135396A",
    "apn": "CN201910445433.0",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "地面标志的识别方法、装置、设备及介质",
    "inv": [
      "杨镜"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110147382A",
    "apn": "CN201910451302.3",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "车道线更新方法、装置、设备、系统及可读存储介质",
    "inv": [
      "高超",
      "蔡育展",
      "郑超"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110059098A",
    "apn": "CN201910452399.X",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "高精地图的更新方法和装置、电子设备、计算机可读介质",
    "inv": [
      "裴新欣"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110176011A",
    "apn": "CN201910452387.7",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "车辆异常提醒方法及装置",
    "inv": [
      "左雄"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110163176A",
    "apn": "CN201910450841.5",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "车道线变化位置识别方法、装置、设备和介质",
    "inv": [
      "赵祖轩"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110175577A",
    "apn": "CN201910456784.1",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "车载挂号方法、装置、设备、计算机可读存储介质和车辆",
    "inv": [
      "蔡浚宇",
      "崔新霞"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "就医引导",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110246280A",
    "apn": "CN201910463224.9",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "人货绑定方法及装置、计算机设备及可读介质",
    "inv": [
      "雷超兵",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110223144A",
    "apn": "CN201910470522.0",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "无人购物的信息处理系统及方法、计算机设备及可读介质",
    "inv": [
      "雷超兵",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110210393A",
    "apn": "CN201910472084.1",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "人脸图像的检测方法和装置",
    "inv": [
      "王洋",
      "刘焱",
      "熊俊峰",
      "郝新"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110222641A",
    "apn": "CN201910489384.0",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "用于识别图像的方法和装置",
    "inv": [
      "聂磊",
      "肖慧慧",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110222774A",
    "apn": "CN201910498093.8",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "非法图像鉴别方法、装置、内容安全防火墙及存储介质",
    "inv": [
      "刘焱"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110362709A",
    "apn": "CN201910499948.9",
    "apd": "2019-06-11",
    "apy": "2019",
    "tit": "人物配图选取方法、装置、计算机设备及存储介质",
    "inv": [
      "邝智杰",
      "卢佳俊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN110222235A",
    "apn": "CN201910504980.1",
    "apd": "2019-06-11",
    "apy": "2019",
    "tit": "三维立体内容显示方法、装置、设备及存储介质",
    "inv": [
      "杨茗名",
      "王群",
      "张苗"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110363193A",
    "apn": "CN201910507310.5",
    "apd": "2019-06-12",
    "apy": "2019",
    "tit": "车辆重识别方法、装置、设备和计算机存储介质",
    "inv": [
      "杨喜鹏",
      "谭啸",
      "文石磊",
      "丁二锐",
      "孙昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110222780A",
    "apn": "CN201910507035.7",
    "apd": "2019-06-12",
    "apy": "2019",
    "tit": "物体检测方法、装置、设备和存储介质",
    "inv": [
      "高晨昊",
      "赵鹏昊",
      "李曙鹏",
      "施恩",
      "谢永康",
      "喻友平",
      "吴甜"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110287832A",
    "apn": "CN201910510550.0",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "高速自动驾驶场景障碍物感知评测方法及装置",
    "inv": [
      "赵晓健",
      "向旭东"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110363810A",
    "apn": "CN201910515574.5",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "建立图像检测模型的方法、装置、设备和计算机存储介质",
    "inv": [
      "黎健成",
      "孙逸鹏",
      "姚锟",
      "韩钧宇",
      "刘经拓",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110245612A",
    "apn": "CN201910517338.7",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "人脸图像的检测方法和装置",
    "inv": [
      "王洋",
      "刘焱",
      "熊俊峰",
      "郝新"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110223220A",
    "apn": "CN201910515878.1",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "一种处理图像的方法和装置",
    "inv": [
      "庞文杰",
      "赵晨",
      "佟莎莎",
      "李啸",
      "刘莹莹"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110400329A",
    "apn": "CN201910519920.7",
    "apd": "2019-06-17",
    "apy": "2019",
    "tit": "人流计数方法及其系统",
    "inv": [
      "张成月"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110276287A",
    "apn": "CN201910523566.5",
    "apd": "2019-06-17",
    "apy": "2019",
    "tit": "车位检测方法、装置、计算机设备以及存储介质",
    "inv": [
      "吕文玉"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110310260A",
    "apn": "CN201910530692.3",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "基于机器学习模型的分料决策方法、设备和存储介质",
    "inv": [
      "苏业",
      "邹建法",
      "刘明浩",
      "聂磊",
      "冷家冰",
      "黄特辉",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110309741A",
    "apn": "CN201910532194.2",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "障碍物检测方法及装置",
    "inv": [
      "吕文玉",
      "杨宇昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110261392A",
    "apn": "CN201910533045.8",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "质量检测方法、装置、电子设备和系统",
    "inv": [
      "苏业",
      "邹建法",
      "刘明浩",
      "聂磊",
      "冷家冰",
      "黄特辉",
      "徐玉林",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110263713A",
    "apn": "CN201910536130.X",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "车道线检测方法、装置、电子设备及存储介质",
    "inv": [
      "潘杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110276293A",
    "apn": "CN201910536493.3",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "车道线检测方法、装置、电子设备及存储介质",
    "inv": [
      "潘杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110263714A",
    "apn": "CN201910536495.2",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "车道线检测方法、装置、电子设备及存储介质",
    "inv": [
      "潘杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110232368A",
    "apn": "CN201910536138.6",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "车道线检测方法、装置、电子设备及存储介质",
    "inv": [
      "潘杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110223482A",
    "apn": "CN201910537794.8",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "无人驾驶车辆的报警方法和装置",
    "inv": [
      "张秋宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110245638A",
    "apn": "CN201910539863.9",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "视频生成方法和装置",
    "inv": [
      "卞东海",
      "蒋帅",
      "陈思姣",
      "罗雨",
      "陈奇石",
      "曾启飞"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110427810A",
    "apn": "CN201910544334.8",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "视频定损方法、装置、拍摄端及机器可读存储介质",
    "inv": [
      "李莹莹",
      "谭啸",
      "文石磊",
      "丁二锐",
      "孙昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110288019A",
    "apn": "CN201910546282.8",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "图像标注方法、装置和存储介质",
    "inv": [
      "李曙鹏",
      "赵鹏昊",
      "张海滨",
      "徐彬彬",
      "高晨昊",
      "赵颖",
      "谢永康",
      "施恩"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110263528A",
    "apn": "CN201910541588.4",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "去除重复账号的方法、装置、设备及存储介质",
    "inv": [
      "林成龙"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN110264522A",
    "apn": "CN201910552196.8",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "物品操作者的检测方法、装置、设备和存储介质",
    "inv": [
      "罗宇轩",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110276300A",
    "apn": "CN201910547978.2",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "用于识别垃圾品质的方法和装置",
    "inv": [
      "黄特辉",
      "刘明浩",
      "郭江亮"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/生活",
    "l2": "垃圾分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110246183A",
    "apn": "CN201910552199.1",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "车轮接地点检测方法、装置及存储介质",
    "inv": [
      "邓逸安"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110390327A",
    "apn": "CN201910555311.7",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "前景提取方法、装置、计算机设备及存储介质",
    "inv": [
      "徐彬彬",
      "胡勇波",
      "李曙鹏",
      "谢永康",
      "施恩"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN110298667A",
    "apn": "CN201910557218.X",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "基于人脸识别的支付处理方法、装置及存储介质",
    "inv": [
      "车生然",
      "李兴彪",
      "孙传祥"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110276756A",
    "apn": "CN201910555502.3",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "路面裂缝检测方法、装置及设备",
    "inv": [
      "杨宇昊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "城市建设",
    "l2": "公路养护",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110263793A",
    "apn": "CN201910555581.8",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "物品标签识别方法及装置",
    "inv": [
      "彭明浩"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110262665A",
    "apn": "CN201910559574.5",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "徐化永"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110276405A",
    "apn": "CN201910559812.2",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "黄特辉",
      "刘明浩",
      "郭江亮"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/生活",
    "l2": "垃圾分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110263748A",
    "apn": "CN201910563429.4",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "用于发送信息的方法和装置",
    "inv": [
      "王冠皓",
      "叶芷"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110298306A",
    "apn": "CN201910569828.1",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "目标对象运动信息的确定方法、装置及设备",
    "inv": [
      "宫延河",
      "赵晨",
      "李颖超",
      "吴中勤"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110321008A",
    "apn": "CN201910576731.3",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "基于AR模型的交互方法、装置、设备和存储介质",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110334637A",
    "apn": "CN201910575380.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "人脸活体检测方法、装置及存储介质",
    "inv": [
      "张峰",
      "陈轶博"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110298833A",
    "apn": "CN201910580304.2",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图片处理方法和装置",
    "inv": [
      "张苗",
      "王群",
      "杨茗名"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司",
      "百度时代网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN110309772A",
    "apn": "CN201910578381.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "值机服务提供方法、装置、设备和存储介质",
    "inv": [
      "李士岩",
      "翟宇宏",
      "李扬",
      "吴准",
      "李晓凡",
      "邬诗雨",
      "关岱松"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "酒店旅行",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110288683A",
    "apn": "CN201910573596.7",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "王励皓",
      "何建斌",
      "孔世康",
      "蔡振盛"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110298906A",
    "apn": "CN201910573150.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "用于生成信息的方法和装置",
    "inv": [
      "黄靖博",
      "范美华",
      "何建斌",
      "王励皓",
      "蔡振盛",
      "孔世康",
      "赵亚飞",
      "王建祥",
      "陈超",
      "陈波",
      "李全港",
      "刘羽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110275977A",
    "apn": "CN201910584263.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "一种信息展示方法及装置",
    "inv": [
      "庞文杰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "景区",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110320911A",
    "apn": "CN201910586493.4",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "无人车控制方法、装置、无人车及存储介质",
    "inv": [
      "杨凡",
      "朱晓星",
      "陈臣",
      "柴婉琦",
      "朱帆"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110334941A",
    "apn": "CN201910586498.7",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "无人物流车调度方法、装置、电子设备和可读存储介质",
    "inv": [
      "杨凡",
      "于宁",
      "陈臣",
      "柴婉琦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110308792A",
    "apn": "CN201910586338.2",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "虚拟角色的控制方法、装置、设备及可读存储介质",
    "inv": [
      "董玉华",
      "张永杰",
      "赵晨",
      "李颖超"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110262503A",
    "apn": "CN201910586496.8",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "无人售卖车调度方法、装置、设备和可读存储介质",
    "inv": [
      "杨凡",
      "于宁",
      "陈臣",
      "柴婉琦"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "特定车辆",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110335351A",
    "apn": "CN201910589238.5",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "多模态AR处理方法、装置、系统、设备及可读存储介质",
    "inv": [
      "张永杰",
      "陈思利",
      "刘赵梁"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110309784A",
    "apn": "CN201910591158.3",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "动作识别处理方法、装置、设备和存储介质",
    "inv": [
      "迟至真",
      "李甫",
      "何栋梁",
      "龙翔",
      "周志超",
      "赵翔",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110298850A",
    "apn": "CN201910590552.5",
    "apd": "2019-07-02",
    "apy": "2019",
    "tit": "眼底图像的分割方法和装置",
    "inv": [
      "孙钦佩",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN110322569A",
    "apn": "CN201910592876.2",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "多模态AR处理方法、装置、设备和可读存储介质",
    "inv": [
      "陈思利",
      "刘赵梁",
      "张永杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110321009A",
    "apn": "CN201910597475.6",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "AR表情处理方法、装置、设备和存储介质",
    "inv": [
      "王尧"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110308793A",
    "apn": "CN201910597494.9",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "增强现实AR表情生成方法、装置及存储介质",
    "inv": [
      "郝冀宣",
      "顾星宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110321854A",
    "apn": "CN201910605899.2",
    "apd": "2019-07-05",
    "apy": "2019",
    "tit": "用于检测目标对象的方法和装置",
    "inv": [
      "刘毅",
      "周志鹏"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110428399A",
    "apn": "CN201910606028.2",
    "apd": "2019-07-05",
    "apy": "2019",
    "tit": "用于检测图像的方法、装置、设备和存储介质",
    "inv": [
      "熊俊峰",
      "王洋",
      "刘焱",
      "郝新"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110458193A",
    "apn": "CN201910610019.0",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "垃圾分类处理方法、装置及系统、计算机设备与可读介质",
    "inv": [
      "冯博豪"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工作/生活",
    "l2": "垃圾分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110329178A",
    "apn": "CN201910617861.7",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "用于调整行车模式的方法和装置",
    "inv": [
      "王璇"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110334689A",
    "apn": "CN201910640913.2",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "视频分类方法和装置",
    "inv": [
      "龙翔",
      "何栋梁",
      "李甫",
      "迟至真",
      "周志超",
      "赵翔",
      "王平",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110348463A",
    "apn": "CN201910640084.8",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "用于识别车辆的方法和装置",
    "inv": [
      "蒋旻悦",
      "谭啸",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110347772A",
    "apn": "CN201910641470.9",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "物品状态检测方法、装置及计算机可读存储介质",
    "inv": [
      "汪学军"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110378831A",
    "apn": "CN201910657051.4",
    "apd": "2019-07-19",
    "apy": "2019",
    "tit": "水印的处理方法和装置",
    "inv": [
      "杨茗名",
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110363171A",
    "apn": "CN201910661094.X",
    "apd": "2019-07-22",
    "apy": "2019",
    "tit": "天空区域预测模型的训练方法和识别天空区域的方法",
    "inv": [
      "彭冕",
      "傅依",
      "张赫男",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110390025A",
    "apn": "CN201910673268.4",
    "apd": "2019-07-24",
    "apy": "2019",
    "tit": "封面配图确定方法、装置、设备及计算机可读存储介质",
    "inv": [
      "于天宝",
      "郅波",
      "齐冰洁",
      "刘美辰",
      "贠挺",
      "陈国庆",
      "刘霏暄",
      "乐力"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN110378841A",
    "apn": "CN201910669393.8",
    "apd": "2019-07-24",
    "apy": "2019",
    "tit": "显示处理方法及装置",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110428377A",
    "apn": "CN201910683255.5",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "数据扩充方法、装置、设备和介质",
    "inv": [
      "孙旭",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110443812A",
    "apn": "CN201910684271.6",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "眼底图像分割方法、装置、设备和介质",
    "inv": [
      "孙旭",
      "杨大陆",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110390829A",
    "apn": "CN201910692306.0",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "交通信号灯识别的方法及装置",
    "inv": [
      "卫勇",
      "禤彪",
      "田山"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110443814A",
    "apn": "CN201910692914.1",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "车辆的定损方法、装置、设备和存储介质",
    "inv": [
      "李莹莹",
      "张伟",
      "谭啸",
      "文石磊",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN110472743A",
    "apn": "CN201910700792.6",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "样本集中特征穿越的处理方法及装置、设备与可读介质",
    "inv": [
      "刘昊骋",
      "李原",
      "许韩晨玺"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110389995A",
    "apn": "CN201910704429.1",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "车道信息检测方法、装置、设备和介质",
    "inv": [
      "邓伟辉",
      "马赛",
      "李烜赫"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110381266A",
    "apn": "CN201910700099.9",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "一种视频生成方法、装置以及终端",
    "inv": [
      "杜念冬",
      "鲍冠伯",
      "杨杰"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110443764A",
    "apn": "CN201910706996.0",
    "apd": "2019-08-01",
    "apy": "2019",
    "tit": "视频修复方法、装置及服务器",
    "inv": [
      "赵翔",
      "朱曼瑜",
      "李甫",
      "何栋梁",
      "刘霄",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110443191A",
    "apn": "CN201910707008.4",
    "apd": "2019-08-01",
    "apy": "2019",
    "tit": "用于识别物品的方法和装置",
    "inv": [
      "王青泽",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110413740A",
    "apn": "CN201910721082.1",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "化学表达式的查询方法、装置、电子设备及存储介质",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110458086A",
    "apn": "CN201910725794.0",
    "apd": "2019-08-07",
    "apy": "2019",
    "tit": "车辆重识别方法及装置",
    "inv": [
      "杨喜鹏",
      "谭啸",
      "文石磊",
      "丁二锐",
      "孙昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110428442A",
    "apn": "CN201910725468.X",
    "apd": "2019-08-07",
    "apy": "2019",
    "tit": "目标确定方法、目标确定系统和监控安防系统",
    "inv": [
      "钟东宏",
      "苏翔博",
      "何刚",
      "袁宇辰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110427908A",
    "apn": "CN201910733765.9",
    "apd": "2019-08-08",
    "apy": "2019",
    "tit": "一种人物检测的方法、装置及计算机可读存储介质",
    "inv": [
      "钟东宏",
      "苏翔博",
      "袁宇辰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110427905A",
    "apn": "CN201910731655.9",
    "apd": "2019-08-08",
    "apy": "2019",
    "tit": "行人跟踪方法、装置以及终端",
    "inv": [
      "苏翔博",
      "钟东宏",
      "袁宇辰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110443210A",
    "apn": "CN201910731654.4",
    "apd": "2019-08-08",
    "apy": "2019",
    "tit": "一种行人跟踪方法、装置以及终端",
    "inv": [
      "苏翔博",
      "钟东宏",
      "袁宇辰"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110458107A",
    "apn": "CN201910744806.4",
    "apd": "2019-08-13",
    "apy": "2019",
    "tit": "用于图像识别的方法和装置",
    "inv": [
      "张滨",
      "韩树民",
      "冯原",
      "李振东",
      "刘静伟"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110443218A",
    "apn": "CN201910746179.8",
    "apd": "2019-08-13",
    "apy": "2019",
    "tit": "一种人物检测方法和装置",
    "inv": [
      "马明明",
      "罗宇轩",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110458781A",
    "apn": "CN201910750649.8",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "用于处理图像的方法和装置",
    "inv": [
      "郭汉奇",
      "康洋",
      "洪智滨"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110427917A",
    "apn": "CN201910750139.0",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "用于检测关键点的方法和装置",
    "inv": [
      "卢子鹏",
      "王健",
      "文石磊",
      "孙昊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110427915A",
    "apn": "CN201910749781.7",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "用于输出信息的方法和装置",
    "inv": [
      "王之港",
      "王健",
      "文石磊",
      "丁二锐",
      "孙昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110443222A",
    "apn": "CN201910748135.9",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "用于训练脸部关键点检测模型的方法和装置",
    "inv": [
      "郭汉奇",
      "洪智滨"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110458130A",
    "apn": "CN201910760681.4",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "人物识别方法、装置、电子设备及存储介质",
    "inv": [
      "高磊磊"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110472600A",
    "apn": "CN201910769997.X",
    "apd": "2019-08-20",
    "apy": "2019",
    "tit": "眼底图识别及其训练方法、装置、设备和存储介质",
    "inv": [
      "黄甜甜",
      "杨大陆",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110490142A",
    "apn": "CN201910775072.6",
    "apd": "2019-08-21",
    "apy": "2019",
    "tit": "用于生成告警信号的方法及装置",
    "inv": [
      "王猛涛",
      "李乐丁",
      "陆丹峰",
      "王晓晨",
      "刘智宇"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110443230A",
    "apn": "CN201910777844.X",
    "apd": "2019-08-21",
    "apy": "2019",
    "tit": "人脸融合方法、装置以及电子设备",
    "inv": [
      "杨静",
      "洪智滨",
      "张世昌"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110517298A",
    "apn": "CN201910799610.5",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "轨迹匹配方法和装置",
    "inv": [
      "张成月",
      "亢乐",
      "包英泽"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110517247A",
    "apn": "CN201910794767.9",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "获取信息的方法及装置",
    "inv": [
      "任思可",
      "刘明浩",
      "邹建法",
      "聂磊",
      "苏业"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "百度",
    "pun": "CN110517248A",
    "apn": "CN201910796692.8",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "眼底图像的处理、训练方法、装置及其设备",
    "inv": [
      "孙钦佩",
      "杨叶辉",
      "王磊",
      "许言午",
      "黄艳"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110516598A",
    "apn": "CN201910797619.2",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "胡天舒",
      "张世昌",
      "洪智滨",
      "韩钧宇",
      "刘经拓"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110516099A",
    "apn": "CN201910794758.X",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "徐志敏",
      "刘国翌",
      "孟骧龙",
      "王晓"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "百度",
    "pun": "CN110516678A",
    "apn": "CN201910796565.8",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "张伟",
      "谭啸",
      "孙昊",
      "文石磊",
      "丁二锐"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110503653A",
    "apn": "CN201910795857.X",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "图像分割方法及装置",
    "inv": [
      "任思可",
      "刘明浩",
      "邹建法",
      "聂磊",
      "苏业"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "百度",
    "pun": "CN110503725A",
    "apn": "CN201910799607.3",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "图像处理的方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "王群"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "百度",
    "pun": "CN110502665A",
    "apn": "CN201910794759.4",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "视频处理方法和装置",
    "inv": [
      "李甫",
      "何栋梁",
      "周志超",
      "孙昊"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "质量评价"
  },
  {
    "entity": "百度",
    "pun": "CN110503703A",
    "apn": "CN201910798510.0",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "胡天舒",
      "康洋",
      "洪智滨",
      "韩钧宇",
      "刘经拓"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110515464A",
    "apn": "CN201910807374.7",
    "apd": "2019-08-28",
    "apy": "2019",
    "tit": "AR显示方法、装置、车辆和存储介质",
    "inv": [
      "张秋宇",
      "徐蔷"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "百度",
    "pun": "CN110517214A",
    "apn": "CN201910799782.2",
    "apd": "2019-08-28",
    "apy": "2019",
    "tit": "用于生成图像的方法和装置",
    "inv": [
      "姚锟",
      "洪智滨",
      "胡天舒",
      "康洋",
      "韩钧宇",
      "刘经拓"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110502205A",
    "apn": "CN201910805951.9",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "图片显示边缘处理方法、装置、电子设备和可读存储介质",
    "inv": [
      "杨茗名",
      "王群",
      "张苗"
    ],
    "app": [
      "百度在线网络技术(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "百度",
    "pun": "CN110517259A",
    "apn": "CN201910816859.2",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "一种产品表面状态的检测方法、装置、设备及介质",
    "inv": [
      "苏业",
      "矫函哲",
      "冷家冰",
      "聂磊",
      "刘明浩",
      "郭江亮",
      "李旭"
    ],
    "app": [
      "北京百度网讯科技有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458154A",
    "apn": "CN201910865923.6",
    "apd": "2019-09-12",
    "apy": "2019",
    "tit": "人脸识别方法、装置和计算机可读存储介质",
    "inv": [
      "原俊",
      "张颖",
      "耿志军",
      "郭润增",
      "吴进发",
      "王少鸣",
      "唐川鹏",
      "田朝会",
      "张晋铭",
      "叶婷婷",
      "陈磊",
      "刘文君",
      "黄家宇",
      "吴志伟",
      "许阳辉",
      "向卓林",
      "李胤恺",
      "李俊斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110473328A",
    "apn": "CN201910849663.3",
    "apd": "2019-09-09",
    "apy": "2019",
    "tit": "社区门禁管理方法及装置、存储介质、电子设备",
    "inv": [
      "潘崇",
      "欧阳国灵",
      "单文波",
      "欧阳砚池",
      "李永韬",
      "陈紫杰",
      "李玉",
      "杨帝海",
      "黄炜康",
      "刘海波",
      "孙继东",
      "翟志芳",
      "许钧发",
      "何俊池",
      "李震",
      "孙裕",
      "刘轶斌",
      "马侠霖",
      "陈剑锋",
      "梁湘武",
      "陈诗杨",
      "冯腾霄",
      "章效培",
      "胡长鸿",
      "刘太平",
      "李超",
      "周巍",
      "尹兴伟",
      "何煦",
      "林志达",
      "姚专"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490272A",
    "apn": "CN201910836797.1",
    "apd": "2019-09-05",
    "apy": "2019",
    "tit": "图片内容相似度分析方法、装置及存储介质",
    "inv": [
      "王征韬"
    ],
    "app": [
      "腾讯音乐娱乐科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110414631A",
    "apn": "CN201910833614.0",
    "apd": "2019-09-04",
    "apy": "2019",
    "tit": "基于医学图像的病灶检测方法、模型训练的方法及装置",
    "inv": [
      "沈荣波",
      "颜克洲",
      "田宽",
      "江铖",
      "周可"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "华中科技大学"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110475069A",
    "apn": "CN201910827282.5",
    "apd": "2019-09-03",
    "apy": "2019",
    "tit": "图像的拍摄方法及装置",
    "inv": [
      "杨广煜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110505498A",
    "apn": "CN201910828434.3",
    "apd": "2019-09-03",
    "apy": "2019",
    "tit": "视频的处理、播放方法、装置及计算机可读介质",
    "inv": [
      "熊章俊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110517339A",
    "apn": "CN201910816780.X",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "一种基于人工智能的动画形象驱动方法和装置",
    "inv": [
      "王盛",
      "季兴",
      "朱展图",
      "林祥凯",
      "暴林超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110517346A",
    "apn": "CN201910813258.6",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "虚拟环境界面的展示方法、装置、计算机设备及存储介质",
    "inv": [
      "赵江曼",
      "汪林",
      "任刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110517340A",
    "apn": "CN201910816788.6",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "一种基于人工智能的脸部模型确定方法和装置",
    "inv": [
      "王盛",
      "林祥凯",
      "季兴",
      "朱展图",
      "暴林超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110446104A",
    "apn": "CN201910817479.0",
    "apd": "2019-08-30",
    "apy": "2019",
    "tit": "视频处理方法、装置及存储介质",
    "inv": [
      "阳萍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110517759A",
    "apn": "CN201910820773.7",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "一种待标注图像确定的方法、模型训练的方法及装置",
    "inv": [
      "胡一凡",
      "李悦翔",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110516620A",
    "apn": "CN201910810167.7",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "目标跟踪方法、装置、存储介质及电子设备",
    "inv": [
      "唐梦云",
      "周文",
      "陈泳君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110516672A",
    "apn": "CN201910809453.1",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "卡证信息识别方法、装置及终端",
    "inv": [
      "吕鹏原",
      "沈小勇",
      "戴宇荣",
      "贾佳亚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110503074A",
    "apn": "CN201910807774.8",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "视频帧的信息标注方法、装置、设备及存储介质",
    "inv": [
      "吴锐正",
      "贾佳亚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110503076A",
    "apn": "CN201910808157.X",
    "apd": "2019-08-29",
    "apy": "2019",
    "tit": "基于人工智能的视频分类方法、装置、设备和介质",
    "inv": [
      "姜育刚",
      "黄帆",
      "邱志勇",
      "张星",
      "吴祖煊",
      "陈绍祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110516671A",
    "apn": "CN201910794384.1",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "神经网络模型的训练方法、图像检测方法及装置",
    "inv": [
      "王子愉",
      "黄浩智",
      "姜文浩",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110502664A",
    "apn": "CN201910798447.0",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "视频标签索引库创建方法、视频标签生成方法及装置",
    "inv": [
      "李争",
      "郑茂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110503097A",
    "apn": "CN201910798468.2",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "图像处理模型的训练方法、装置及存储介质",
    "inv": [
      "王子愉",
      "姜文浩",
      "黄浩智",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110503057A",
    "apn": "CN201910797433.7",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "一种信息处理方法、装置、检测节点设备及存储介质",
    "inv": [
      "王星雅"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110505534A",
    "apn": "CN201910805209.8",
    "apd": "2019-08-26",
    "apy": "2019",
    "tit": "监控视频处理方法、装置及存储介质",
    "inv": [
      "牛志伟",
      "董超",
      "陈泳君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110517246A",
    "apn": "CN201910785909.5",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "一种图像处理方法、装置、电子设备及存储介质",
    "inv": [
      "陈少华",
      "余亭浩",
      "曹霖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110516734A",
    "apn": "CN201910786185.6",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "一种图像匹配方法、装置、设备及存储介质",
    "inv": [
      "侯昊迪",
      "余亭浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110489510A",
    "apn": "CN201910784059.7",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "道路数据的处理方法、装置、可读存储介质和计算机设备",
    "inv": [
      "张勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490159A",
    "apn": "CN201910784865.4",
    "apd": "2019-08-23",
    "apy": "2019",
    "tit": "识别显微图像中的细胞的方法、装置、设备及存储介质",
    "inv": [
      "沈昊成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472616A",
    "apn": "CN201910780213.3",
    "apd": "2019-08-22",
    "apy": "2019",
    "tit": "图像识别方法、装置、计算机设备及存储介质",
    "inv": [
      "沈昊成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110473282A",
    "apn": "CN201910780565.9",
    "apd": "2019-08-22",
    "apy": "2019",
    "tit": "物体模型的染色处理方法、装置、计算机设备和存储介质",
    "inv": [
      "汪林",
      "刘晶",
      "任刚",
      "林洁",
      "黄碧文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490951A",
    "apn": "CN201910773299.7",
    "apd": "2019-08-21",
    "apy": "2019",
    "tit": "一种图像绘制方法和装置",
    "inv": [
      "陈锡显",
      "赵胜林",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472594A",
    "apn": "CN201910768099.2",
    "apd": "2019-08-20",
    "apy": "2019",
    "tit": "目标跟踪方法、信息插入方法及设备",
    "inv": [
      "秦淮",
      "宋奕兵",
      "黄浩智",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472602A",
    "apn": "CN201910770808.0",
    "apd": "2019-08-20",
    "apy": "2019",
    "tit": "一种卡证识别方法、装置、终端及存储介质",
    "inv": [
      "吕鹏原",
      "沈小勇",
      "戴宇荣",
      "贾佳亚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472090A",
    "apn": "CN201910770152.2",
    "apd": "2019-08-20",
    "apy": "2019",
    "tit": "基于语义标签的图像检索方法以及相关装置、存储介质",
    "inv": [
      "刘龙坡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110489593A",
    "apn": "CN201910770189.5",
    "apd": "2019-08-20",
    "apy": "2019",
    "tit": "视频的话题处理方法、装置、电子设备及存储介质",
    "inv": [
      "何奕江",
      "郑茂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490881A",
    "apn": "CN201910764981.X",
    "apd": "2019-08-19",
    "apy": "2019",
    "tit": "医学影像分割方法、装置、计算机设备及可读存储介质",
    "inv": [
      "曹世磊",
      "胡一凡",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490882A",
    "apn": "CN201910765920.5",
    "apd": "2019-08-19",
    "apy": "2019",
    "tit": "细胞膜染色图像分析方法、装置及系统",
    "inv": [
      "张军",
      "颜克洲",
      "姚建华",
      "韩骁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458835A",
    "apn": "CN201910759288.3",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "一种图像处理方法、装置、设备及介质",
    "inv": [
      "张军",
      "颜克洲",
      "姚建华",
      "韩骁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472582A",
    "apn": "CN201910758404.X",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "基于眼部识别的3D人脸识别方法、装置和终端",
    "inv": [
      "陈昱",
      "马彬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110448909A",
    "apn": "CN201910760123.8",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "一种应用中目标角色的结果输出方法和装置以及介质",
    "inv": [
      "黄盈",
      "张力柯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110364008A",
    "apn": "CN201910759639.0",
    "apd": "2019-08-16",
    "apy": "2019",
    "tit": "路况确定方法、装置、计算机设备和存储介质",
    "inv": [
      "阳勇",
      "孙立光"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110516019A",
    "apn": "CN201910751472.3",
    "apd": "2019-08-15",
    "apy": "2019",
    "tit": "一种新路发现方法、装置、设备及存储介质",
    "inv": [
      "张志辉",
      "杨泽昕"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458833A",
    "apn": "CN201910752632.6",
    "apd": "2019-08-15",
    "apy": "2019",
    "tit": "基于人工智能的医学图像处理方法、医学设备和存储介质",
    "inv": [
      "张富博",
      "魏东",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490123A",
    "apn": "CN201910754376.4",
    "apd": "2019-08-15",
    "apy": "2019",
    "tit": "来访人员识别方法及装置",
    "inv": [
      "崔冉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110503685A",
    "apn": "CN201910750244.4",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "一种数据处理方法以及设备",
    "inv": [
      "郭晓楠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490119A",
    "apn": "CN201910749088.X",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "重复视频标记方法、装置和计算机可读存储介质",
    "inv": [
      "张晗"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490245A",
    "apn": "CN201910750225.1",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "身份验证模型训练方法及装置、存储介质、电子设备",
    "inv": [
      "梁健",
      "白琨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490959A",
    "apn": "CN201910750316.5",
    "apd": "2019-08-14",
    "apy": "2019",
    "tit": "文化娱乐方法及装置、虚拟形象生成方法以及电子设备",
    "inv": [
      "赵丹阳",
      "耿天平",
      "曹文升"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458233A",
    "apn": "CN201910743898.4",
    "apd": "2019-08-13",
    "apy": "2019",
    "tit": "混合粒度物体识别模型训练及识别方法、装置及存储介质",
    "inv": [
      "郭卉",
      "袁豪磊",
      "黄飞跃"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458232A",
    "apn": "CN201910743518.7",
    "apd": "2019-08-13",
    "apy": "2019",
    "tit": "一种确定图像风格相似度的方法及设备",
    "inv": [
      "俞一鹏",
      "孙子荀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490852A",
    "apn": "CN201910742168.2",
    "apd": "2019-08-13",
    "apy": "2019",
    "tit": "目标对象的检索方法、装置、计算机可读介质及电子设备",
    "inv": [
      "王伟",
      "曾凡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110446071A",
    "apn": "CN201910745322.1",
    "apd": "2019-08-13",
    "apy": "2019",
    "tit": "基于神经网络的多媒体处理方法、装置、设备及介质",
    "inv": [
      "陈为",
      "张博文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458564A",
    "apn": "CN201910739698.1",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "基于人脸识别的支付方法、装置、终端、系统及存储介质",
    "inv": [
      "郑尚镇",
      "郭润增",
      "王少鸣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110457523A",
    "apn": "CN201910739802.7",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "封面图片的选取方法、模型的训练方法、装置及介质",
    "inv": [
      "刘军煜",
      "沈招益",
      "高洵"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110430401A",
    "apn": "CN201910741309.9",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "车辆盲区预警方法、预警装置、MEC平台和存储介质",
    "inv": [
      "刘恒进"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110442771A",
    "apn": "CN201910741015.6",
    "apd": "2019-08-12",
    "apy": "2019",
    "tit": "一种基于深度学习的检测站点篡改的方法及装置",
    "inv": [
      "魏向前",
      "张融"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472087A",
    "apn": "CN201910725995.0",
    "apd": "2019-08-07",
    "apy": "2019",
    "tit": "一种表情图像推荐方法、装置、设备及介质",
    "inv": [
      "刘龙坡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110443852A",
    "apn": "CN201910733944.2",
    "apd": "2019-08-07",
    "apy": "2019",
    "tit": "一种图像定位的方法及相关装置",
    "inv": [
      "皮智雄",
      "宋奕兵",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458820A",
    "apn": "CN201910723289.2",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "一种多媒体信息植入方法、装置、设备及存储介质",
    "inv": [
      "生辉",
      "黄东波",
      "陈戈"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490238A",
    "apn": "CN201910722443.4",
    "apd": "2019-08-06",
    "apy": "2019",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "卢建东",
      "余衍炳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110456956A",
    "apn": "CN201910716639.2",
    "apd": "2019-08-05",
    "apy": "2019",
    "tit": "截图方法、装置、计算机设备和存储介质",
    "inv": [
      "何丹",
      "颜玮",
      "李光",
      "王军利"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110414539A",
    "apn": "CN201910718001.2",
    "apd": "2019-08-05",
    "apy": "2019",
    "tit": "一种提取特征描述信息的方法和相关装置",
    "inv": [
      "江铖",
      "田宽"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110442807A",
    "apn": "CN201910718614.6",
    "apd": "2019-08-05",
    "apy": "2019",
    "tit": "一种网页类型识别方法、装置、服务器及存储介质",
    "inv": [
      "周菲",
      "张融"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110427887A",
    "apn": "CN201910713772.2",
    "apd": "2019-08-02",
    "apy": "2019",
    "tit": "一种基于智能的会员身份识别方法及装置",
    "inv": [
      "王军",
      "郭润增",
      "王少鸣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110442521A",
    "apn": "CN201910713313.4",
    "apd": "2019-08-02",
    "apy": "2019",
    "tit": "控件单元检测方法及装置",
    "inv": [
      "陈家俊",
      "智绪浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110446065A",
    "apn": "CN201910713633.X",
    "apd": "2019-08-02",
    "apy": "2019",
    "tit": "一种视频召回方法、装置及存储介质",
    "inv": [
      "谭莲芝"
    ],
    "app": [
      "腾讯科技(武汉)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110456955A",
    "apn": "CN201910707485.0",
    "apd": "2019-08-01",
    "apy": "2019",
    "tit": "暴露服饰检测方法、装置、系统、设备及存储介质",
    "inv": [
      "阎明",
      "陈珍",
      "盛露"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110415239A",
    "apn": "CN201910708114.4",
    "apd": "2019-08-01",
    "apy": "2019",
    "tit": "图像处理方法、装置、设备、医疗电子设备以及介质",
    "inv": [
      "张军",
      "王亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110415341A",
    "apn": "CN201910707899.3",
    "apd": "2019-08-01",
    "apy": "2019",
    "tit": "一种三维人脸模型的生成方法、装置、电子设备及介质",
    "inv": [
      "赵丹阳",
      "耿天平",
      "曹文升"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472537A",
    "apn": "CN201910699845.7",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "一种自适应识别方法、装置、设备及介质",
    "inv": [
      "赵伟",
      "叶泽雄",
      "段克晓",
      "王松健",
      "李火荣",
      "肖万鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110428448A",
    "apn": "CN201910703381.2",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "目标检测跟踪方法、装置、设备及存储介质",
    "inv": [
      "黄湘琦",
      "周文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110428449A",
    "apn": "CN201910704524.1",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "目标检测跟踪方法、装置、设备及存储介质",
    "inv": [
      "周文",
      "黄湘琦",
      "陈泳君",
      "唐梦云",
      "涂思嘉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110413818A",
    "apn": "CN201910703688.2",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "贴纸推荐方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "顾水云"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110443828A",
    "apn": "CN201910704621.0",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "对象跟踪方法和装置、存储介质及电子装置",
    "inv": [
      "黄湘琦",
      "周文",
      "陈泳君",
      "唐梦云",
      "颜小云",
      "唐艳平",
      "涂思嘉",
      "冷鹏宇",
      "刘水生",
      "牛志伟",
      "董超",
      "路明",
      "贺鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110442238A",
    "apn": "CN201910703830.3",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "一种确定动态效果的方法及装置",
    "inv": [
      "李峰",
      "邱日明",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110443190A",
    "apn": "CN201910704061.9",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "一种对象识别方法和装置",
    "inv": [
      "彭瑾龙",
      "张睿欣",
      "汪铖杰",
      "李季檩",
      "甘振业",
      "熊意超",
      "王亚彪",
      "姚永强",
      "葛彦昊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472532A",
    "apn": "CN201910696522.2",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "一种视频对象行为识别方法和装置",
    "inv": [
      "乔宇",
      "颜岸",
      "王亚立",
      "李志鋒"
    ],
    "app": [
      "中国科学院深圳先进技术研究院",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472728A",
    "apn": "CN201910694188.7",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "目标信息确定方法、目标信息确定装置、介质及电子设备",
    "inv": [
      "宋奕兵",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110442867A",
    "apn": "CN201910693744.9",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "图像处理方法、装置、终端及计算机存储介质",
    "inv": [
      "王伟航"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110378860A",
    "apn": "CN201910692753.6",
    "apd": "2019-07-30",
    "apy": "2019",
    "tit": "修复视频的方法、装置、计算机设备和存储介质",
    "inv": [
      "贺思颖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110457518A",
    "apn": "CN201910691590.X",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "一种室内定位方法、装置及存储介质",
    "inv": [
      "周巍",
      "欧阳国灵",
      "欧阳砚池",
      "李永韬",
      "杨帝海",
      "黄炜康",
      "何俊池",
      "刘轶斌",
      "胡长鸿",
      "何煦",
      "李震",
      "刘海波",
      "马侠霖",
      "章效培",
      "刘太平",
      "尹兴伟",
      "冯腾霄"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472531A",
    "apn": "CN201910690174.8",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "视频处理方法、装置、电子设备及存储介质",
    "inv": [
      "易阳",
      "李峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110414433A",
    "apn": "CN201910690640.2",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "图像处理方法、装置、存储介质和计算机设备",
    "inv": [
      "郭梓铿",
      "白琨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110427864A",
    "apn": "CN201910689979.0",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "一种图像处理方法、装置及电子设备",
    "inv": [
      "黄浩智",
      "李崔卿",
      "沈力",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110414432A",
    "apn": "CN201910690389.X",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "对象识别模型的训练方法、对象识别方法及相应的装置",
    "inv": [
      "蒋忻洋",
      "朱智慧",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110415166A",
    "apn": "CN201910690935.X",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "融合图像处理模型的训练方法、图像处理方法、装置及存储介质",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110392306A",
    "apn": "CN201910689334.7",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "一种数据处理方法以及设备",
    "inv": [
      "陈前",
      "泮华杰",
      "杨璧嘉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110290425A",
    "apn": "CN201910691577.4",
    "apd": "2019-07-29",
    "apy": "2019",
    "tit": "一种视频处理方法、装置及存储介质",
    "inv": [
      "段聪",
      "吴江红"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110390676A",
    "apn": "CN201910684849.8",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "显微镜下医学染色图像的细胞检测方法、智能显微镜系统",
    "inv": [
      "张军",
      "颜克洲",
      "姚建华",
      "韩骁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110446063A",
    "apn": "CN201910684487.2",
    "apd": "2019-07-26",
    "apy": "2019",
    "tit": "视频封面的生成方法、装置及电子设备",
    "inv": [
      "周智昊",
      "李时坦",
      "彭江军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110457507A",
    "apn": "CN201910677015.4",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "一种图片识别处理方法、装置、电子设备及存储介质",
    "inv": [
      "李龙彬"
    ],
    "app": [
      "腾讯科技(武汉)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110415463A",
    "apn": "CN201910676477.4",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "一种支付方法及终端",
    "inv": [
      "许洋洋",
      "樊宇",
      "陈磊",
      "陈彦珊",
      "李振",
      "汪晓轩",
      "劉浩妤"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110414596A",
    "apn": "CN201910679036.X",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "视频处理、模型训练方法和装置、存储介质及电子装置",
    "inv": [
      "生辉",
      "黄东波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110390033A",
    "apn": "CN201910678440.5",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "图像分类模型的训练方法、装置、电子设备及存储介质",
    "inv": [
      "周智昊",
      "熊欢",
      "彭江军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110443171A",
    "apn": "CN201910677764.7",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "视频文件的分类方法、装置、存储介质及终端",
    "inv": [
      "张义飞",
      "王兴华",
      "康斌"
    ],
    "app": [
      "腾讯科技(武汉)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110362714A",
    "apn": "CN201910678039.1",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "视频内容的搜索方法和装置",
    "inv": [
      "吕文辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110365903A",
    "apn": "CN201910676167.2",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "基于视频的对象处理方法、装置、设备及可读存储介质",
    "inv": [
      "田元"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458924A",
    "apn": "CN201910667561.X",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "一种三维脸部模型建立方法、装置和电子设备",
    "inv": [
      "陈雅静",
      "宋奕兵",
      "凌永根",
      "暴林超",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110381382A",
    "apn": "CN201910666679.0",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "视频笔记生成方法、装置、存储介质和计算机设备",
    "inv": [
      "吕文辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110378429A",
    "apn": "CN201910666776.X",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "残局牌面筛选方法、装置、服务器及存储介质",
    "inv": [
      "张榕",
      "曾子骄"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363762A",
    "apn": "CN201910664262.0",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "细胞检测方法、装置、智能显微镜系统和可读存储介质",
    "inv": [
      "张军",
      "颜克洲",
      "姚建华",
      "韩骁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363709A",
    "apn": "CN201910667659.5",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "一种图像处理方法、图像展示方法、模型训练方法及装置",
    "inv": [
      "陈为",
      "张博文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "北京交通大学"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363780A",
    "apn": "CN201910666655.5",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "图像分割方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "胡一凡",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110378423A",
    "apn": "CN201910663371.0",
    "apd": "2019-07-22",
    "apy": "2019",
    "tit": "特征提取方法、装置、计算机设备及存储介质",
    "inv": [
      "赵亮",
      "杨韬",
      "仇希如",
      "陈志奎"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "大连理工大学"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110414581A",
    "apn": "CN201910656512.6",
    "apd": "2019-07-19",
    "apy": "2019",
    "tit": "图片检测方法和装置、存储介质及电子装置",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110381369A",
    "apn": "CN201910655586.8",
    "apd": "2019-07-19",
    "apy": "2019",
    "tit": "推荐信息植入位置的确定方法、装置、设备及存储介质",
    "inv": [
      "生辉",
      "黄东波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110266973A",
    "apn": "CN201910657251.X",
    "apd": "2019-07-19",
    "apy": "2019",
    "tit": "视频处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "胡贝"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110442749A",
    "apn": "CN201910651319.3",
    "apd": "2019-07-18",
    "apy": "2019",
    "tit": "视频帧处理方法及装置",
    "inv": [
      "吕孟叶",
      "董治",
      "李深远"
    ],
    "app": [
      "腾讯音乐娱乐科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110348420A",
    "apn": "CN201910650159.0",
    "apd": "2019-07-18",
    "apy": "2019",
    "tit": "手语识别方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "阳赵阳",
      "沈小勇",
      "戴宇荣",
      "贾佳亚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458746A",
    "apn": "CN201910647176.9",
    "apd": "2019-07-17",
    "apy": "2019",
    "tit": "添加水印的方法及装置、电子设备",
    "inv": [
      "张哲铭",
      "唐文宁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110381338A",
    "apn": "CN201910643514.1",
    "apd": "2019-07-17",
    "apy": "2019",
    "tit": "视频数据处理与分析方法、装置、设备及介质",
    "inv": [
      "刘思明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110347608A",
    "apn": "CN201910647927.7",
    "apd": "2019-07-17",
    "apy": "2019",
    "tit": "一种交互式应用的测试方法及相关装置",
    "inv": [
      "韩尹波",
      "孙大伟",
      "方亮",
      "荆彦青",
      "贺国睿",
      "曹斌",
      "李林申"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110348411A",
    "apn": "CN201910642257.X",
    "apd": "2019-07-16",
    "apy": "2019",
    "tit": "一种图像处理方法、装置和设备",
    "inv": [
      "胡一凡",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110377774A",
    "apn": "CN201910636600.X",
    "apd": "2019-07-15",
    "apy": "2019",
    "tit": "进行人物聚类的方法、装置、服务器和存储介质",
    "inv": [
      "程雅慧",
      "王吉",
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363785A",
    "apn": "CN201910638541.X",
    "apd": "2019-07-15",
    "apy": "2019",
    "tit": "一种文本超框检测方法及装置",
    "inv": [
      "智绪浩",
      "毕研涛",
      "魏学峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110348387A",
    "apn": "CN201910629829.0",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "一种图像数据处理方法、装置以及计算机可读存储介质",
    "inv": [
      "武文琦",
      "叶泽雄",
      "肖万鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363138A",
    "apn": "CN201910634411.9",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "模型训练方法、图像处理方法、装置、终端及存储介质",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363243A",
    "apn": "CN201910629171.3",
    "apd": "2019-07-12",
    "apy": "2019",
    "tit": "分类模型的评估方法和装置",
    "inv": [
      "唐梦云"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110321965A",
    "apn": "CN201910621764.5",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "物体重识别模型的训练方法、物体重识别的方法及装置",
    "inv": [
      "王伟农",
      "裴文杰",
      "曹琼",
      "刘枢",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110348515A",
    "apn": "CN201910620467.9",
    "apd": "2019-07-10",
    "apy": "2019",
    "tit": "图像分类方法、图像分类模型训练方法及装置",
    "inv": [
      "胡一凡",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110322416A",
    "apn": "CN201910616020.4",
    "apd": "2019-07-09",
    "apy": "2019",
    "tit": "图像数据处理方法、装置以及计算机可读存储介质",
    "inv": [
      "吴锐正",
      "陶鑫",
      "沈小勇",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110334658A",
    "apn": "CN201910611826.4",
    "apd": "2019-07-08",
    "apy": "2019",
    "tit": "信息推荐方法、装置、设备和存储介质",
    "inv": [
      "张菁芸",
      "郭润增",
      "王少鸣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110335224A",
    "apn": "CN201910603499.8",
    "apd": "2019-07-05",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机设备及存储介质",
    "inv": [
      "刘小蒙",
      "姜文浩",
      "黄浩智",
      "揭泽群",
      "凌永根"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110321849A",
    "apn": "CN201910603362.2",
    "apd": "2019-07-05",
    "apy": "2019",
    "tit": "图像数据处理方法、装置以及计算机可读存储介质",
    "inv": [
      "吴锐正",
      "陶鑫",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110347872A",
    "apn": "CN201910607614.9",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "视频封面图像提取方法及装置、存储介质及电子设备",
    "inv": [
      "田思达"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110213672A",
    "apn": "CN201910598337.X",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "视频生成、播放方法、系统、装置、存储介质和设备",
    "inv": [
      "郜光耀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110309795A",
    "apn": "CN201910600863.5",
    "apd": "2019-07-04",
    "apy": "2019",
    "tit": "视频检测方法、装置、电子设备及存储介质",
    "inv": [
      "吴韬",
      "徐敘遠",
      "龚国平",
      "杨喻茸"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110309335A",
    "apn": "CN201910592749.2",
    "apd": "2019-07-03",
    "apy": "2019",
    "tit": "一种图片匹配方法、装置、设备及存储介质",
    "inv": [
      "黎伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110288163A",
    "apn": "CN201910585204.9",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "信息处理的方法、装置、设备及存储介质",
    "inv": [
      "李磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110335291A",
    "apn": "CN201910585627.0",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "人物追踪方法及终端",
    "inv": [
      "张毅",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110287929A",
    "apn": "CN201910585861.3",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "群体区域中目标的数量确定方法、装置、设备及存储介质",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110286773A",
    "apn": "CN201910585850.5",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "基于增强现实的信息提供方法、装置、设备及存储介质",
    "inv": [
      "吴正山"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110287923A",
    "apn": "CN201910581506.9",
    "apd": "2019-06-29",
    "apy": "2019",
    "tit": "人体姿态获取方法、装置、计算机设备及存储介质",
    "inv": [
      "陈泳君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276411A",
    "apn": "CN201910573560.9",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图像分类方法、装置、设备、存储介质和医疗电子设备",
    "inv": [
      "揭泽群",
      "赵波",
      "冯佳时"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110349082A",
    "apn": "CN201910584273.8",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "图像区域的裁剪方法和装置、存储介质及电子装置",
    "inv": [
      "高洵",
      "沈招益",
      "刘军煜",
      "吴韬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110309876A",
    "apn": "CN201910576104.X",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "目标检测方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "黄超",
      "周大军",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110298309A",
    "apn": "CN201910575623.4",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "基于图像的动作特征处理方法、装置、终端及存储介质",
    "inv": [
      "田元"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276408A",
    "apn": "CN201910568666.X",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "3D图像的分类方法、装置、设备及存储介质",
    "inv": [
      "胡一凡",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110225368A",
    "apn": "CN201910570609.5",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "一种视频定位方法、装置及电子设备",
    "inv": [
      "袁艺天",
      "马林",
      "王景文",
      "刘威",
      "朱文武"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110321448A",
    "apn": "CN201910569695.8",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "一种图像检索方法、装置和存储介质",
    "inv": [
      "王润泽",
      "金星明",
      "邹晓园",
      "李科"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110349102A",
    "apn": "CN201910566769.2",
    "apd": "2019-06-27",
    "apy": "2019",
    "tit": "图像美化的处理方法、图像美化的处理装置以及电子设备",
    "inv": [
      "严宇轩",
      "程培",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110399900A",
    "apn": "CN201910562344.4",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "对象检测方法、装置、设备及介质",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110378247A",
    "apn": "CN201910563728.8",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "虚拟对象识别方法和装置、存储介质及电子装置",
    "inv": [
      "姚文韬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363574A",
    "apn": "CN201910563662.2",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "产品推荐方法、装置、电子设备和可读介质",
    "inv": [
      "谢思发",
      "程序",
      "成昊",
      "刘文强",
      "张涵宇",
      "江小琴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110278449A",
    "apn": "CN201910561856.9",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "一种视频检测方法、装置、设备及介质",
    "inv": [
      "赵世杰",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276892A",
    "apn": "CN201910563814.9",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "无人售货方法、装置、设备及存储介质",
    "inv": [
      "李火荣",
      "王松健",
      "段克晓",
      "吴锐洲",
      "赵伟",
      "杨程",
      "杨帆",
      "邹杨波"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276406A",
    "apn": "CN201910561766.X",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "表情分类方法、装置、计算机设备及存储介质",
    "inv": [
      "刘龙坡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110225366A",
    "apn": "CN201910563663.7",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "视频数据处理及广告位确定方法、装置、介质及电子设备",
    "inv": [
      "揭泽群",
      "黄浩智"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110287341A",
    "apn": "CN201910559777.4",
    "apd": "2019-06-26",
    "apy": "2019",
    "tit": "一种数据处理方法、装置以及可读存储介质",
    "inv": [
      "刘巍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110335259A",
    "apn": "CN201910555451.4",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "一种医学影像识别方法、装置和存储介质",
    "inv": [
      "曹世磊",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110287875A",
    "apn": "CN201910555757.X",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "视频目标的检测方法、装置、电子设备和存储介质",
    "inv": [
      "揭泽群",
      "李宁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110287877A",
    "apn": "CN201910556593.2",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "视频目标的处理方法及装置",
    "inv": [
      "揭泽群",
      "李宁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110298302A",
    "apn": "CN201910566084.8",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "一种人体目标检测方法及相关设备",
    "inv": [
      "揭泽群",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110335386A",
    "apn": "CN201910557166.6",
    "apd": "2019-06-25",
    "apy": "2019",
    "tit": "一种身份验证方法、装置、终端以及存储介质",
    "inv": [
      "段克晓",
      "王松健",
      "赵伟",
      "吴锐洲",
      "邓攀",
      "杨程",
      "杨帆",
      "李火荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110336739A",
    "apn": "CN201910551282.7",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "一种图像预警方法、装置和存储介质",
    "inv": [
      "查文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110290426A",
    "apn": "CN201910550282.5",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "展示资源的方法、装置、设备及存储介质",
    "inv": [
      "生辉",
      "孙昌",
      "黄东波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110348327A",
    "apn": "CN201910547197.3",
    "apd": "2019-06-24",
    "apy": "2019",
    "tit": "实现监控场景中遗留物品检测的方法及装置",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110428475A",
    "apn": "CN201910721287.X",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "一种医学图像的分类方法、模型训练方法和服务器",
    "inv": [
      "肖凯文",
      "韩骁",
      "叶虎",
      "周昵昀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110232719A",
    "apn": "CN201910543018.9",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "一种医学图像的分类方法、模型训练方法和服务器",
    "inv": [
      "肖凯文",
      "韩骁",
      "叶虎",
      "周昵昀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276298A",
    "apn": "CN201910543144.4",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "用户行为的判定方法、装置、存储介质和计算机设备",
    "inv": [
      "张毅",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263724A",
    "apn": "CN201910544392.0",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "图片识别方法、识别模型训练方法、装置及存储介质",
    "inv": [
      "庄新瑞",
      "李悦翔",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110264478A",
    "apn": "CN201910551946.X",
    "apd": "2019-06-21",
    "apy": "2019",
    "tit": "一种线稿图像上色的方法及装置",
    "inv": [
      "高林",
      "张加其",
      "陈姝宇",
      "郑规"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院计算技术研究所"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276348A",
    "apn": "CN201910538314.X",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "一种图像定位方法、装置、服务器及存储介质",
    "inv": [
      "揭泽群",
      "黄鑫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110232696A",
    "apn": "CN201910537529.X",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "一种图像区域分割的方法、模型训练的方法及装置",
    "inv": [
      "王瑞琛",
      "王晓利",
      "闫桂霞",
      "陈楚城"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110246160A",
    "apn": "CN201910539115.0",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "视频目标的检测方法、装置、设备及介质",
    "inv": [
      "揭泽群",
      "李宁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110215706A",
    "apn": "CN201910538136.0",
    "apd": "2019-06-20",
    "apy": "2019",
    "tit": "虚拟对象的位置确定方法、装置、终端及存储介质",
    "inv": [
      "宋奕兵",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110413823A",
    "apn": "CN201910532918.3",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "服装图片推送方法及相关装置",
    "inv": [
      "卢建东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110334614A",
    "apn": "CN201910531860.0",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "一种疲劳状态预警方法、装置、设备及存储介质",
    "inv": [
      "陈强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110246209A",
    "apn": "CN201910533095.6",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "图像处理方法及装置",
    "inv": [
      "刘闻",
      "罗文寒",
      "马林",
      "高盛华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490202A",
    "apn": "CN201910849298.6",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "检测模型训练方法、装置、计算机设备和存储介质",
    "inv": [
      "揭泽群",
      "冯佳时"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263847A",
    "apn": "CN201910527752.6",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "轨迹获取方法、装置、计算机设备及存储介质",
    "inv": [
      "蒋忻洋",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110245662A",
    "apn": "CN201910528002.0",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "检测模型训练方法、装置、计算机设备和存储介质",
    "inv": [
      "揭泽群",
      "冯佳时"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263703A",
    "apn": "CN201910527298.4",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "人员流量统计方法、装置和计算机设备",
    "inv": [
      "张睿欣",
      "陈超",
      "章吴浩",
      "李绍欣",
      "汪铖杰",
      "甘振业",
      "吴佳祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110245710A",
    "apn": "CN201910527051.2",
    "apd": "2019-06-18",
    "apy": "2019",
    "tit": "语义分割模型的训练方法、语义分割方法及装置",
    "inv": [
      "马林",
      "揭泽群",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110245611A",
    "apn": "CN201910516145.X",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "图像识别方法、装置、计算机设备和存储介质",
    "inv": [
      "泮诚",
      "袁豪磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110231871A",
    "apn": "CN201910518106.3",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "页面阅读方法、装置、存储介质及电子设备",
    "inv": [
      "张楠",
      "陈勇",
      "陈贝",
      "陈冬莹",
      "陶然"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110222789A",
    "apn": "CN201910517479.9",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "图像识别方法及存储介质",
    "inv": [
      "王吉",
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110211205A",
    "apn": "CN201910514603.6",
    "apd": "2019-06-14",
    "apy": "2019",
    "tit": "图像处理方法、装置、设备和存储介质",
    "inv": [
      "胡一凡",
      "曹世磊",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110516113A",
    "apn": "CN201910838607.X",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "一种视频分类的方法、视频分类模型训练的方法及装置",
    "inv": [
      "姜文浩",
      "李弼",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110322317A",
    "apn": "CN201910510886.7",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "一种交易数据处理方法、装置、电子设备及介质",
    "inv": [
      "曹源",
      "王旭东",
      "张薇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263216A",
    "apn": "CN201910511487.2",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "一种视频分类的方法、视频分类模型训练的方法及装置",
    "inv": [
      "姜文浩",
      "李弼",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110232722A",
    "apn": "CN201910510027.8",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "刘龙坡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110232713A",
    "apn": "CN201910519331.9",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "一种图像目标定位修正方法及相关设备",
    "inv": [
      "陈翔",
      "潘杰茂",
      "唐斌"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110225373A",
    "apn": "CN201910509756.1",
    "apd": "2019-06-13",
    "apy": "2019",
    "tit": "一种视频审核方法、装置及电子设备",
    "inv": [
      "刘刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263197A",
    "apn": "CN201910507945.5",
    "apd": "2019-06-12",
    "apy": "2019",
    "tit": "一种图像搜索方法、装置、计算机设备和存储介质",
    "inv": [
      "王祥敏",
      "梁泽锋",
      "颜强",
      "张庆扬",
      "姚创沐",
      "王戈",
      "丁佳艺",
      "陈震鸿",
      "赵猛",
      "白乾",
      "赵航",
      "祝文锋",
      "孙科亮",
      "林巧英",
      "陈丹丹",
      "林秀敏",
      "邹明",
      "陈家敏",
      "张华薇",
      "陈志浩",
      "莫雨润",
      "钟伽文",
      "苑木",
      "邹夫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110334746A",
    "apn": "CN201910508310.7",
    "apd": "2019-06-12",
    "apy": "2019",
    "tit": "一种图像检测方法和装置",
    "inv": [
      "卢建东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210573A",
    "apn": "CN201910502774.7",
    "apd": "2019-06-11",
    "apy": "2019",
    "tit": "对抗图像的生成方法、装置、终端及存储介质",
    "inv": [
      "吴保元",
      "樊艳波",
      "张勇",
      "李志锋",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110222658A",
    "apn": "CN201910502866.5",
    "apd": "2019-06-11",
    "apy": "2019",
    "tit": "道路灭点位置的获取方法及装置",
    "inv": [
      "宫鲁津"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458796A",
    "apn": "CN201910497485.2",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "一种图像标注方法、装置和存储介质",
    "inv": [
      "郭子滨",
      "陈星宇",
      "陈超",
      "李绍欣",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276283A",
    "apn": "CN201910497461.7",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "图片识别方法、目标识别模型训练方法及装置",
    "inv": [
      "龚丽君"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110232348A",
    "apn": "CN201910495047.2",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "行人属性识别方法、装置和计算机设备",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210572A",
    "apn": "CN201910498386.6",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "图像分类方法、装置、存储介质及设备",
    "inv": [
      "唐芃",
      "马林",
      "揭泽群",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163188A",
    "apn": "CN201910495416.8",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "视频处理以及在视频中嵌入目标对象的方法、装置和设备",
    "inv": [
      "陈博恒",
      "殷泽龙",
      "李文俊",
      "谢年华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210571A",
    "apn": "CN201910497448.1",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "图像识别方法、装置、计算机设备及计算机可读存储介质",
    "inv": [
      "龚丽君"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110197522A",
    "apn": "CN201910498450.0",
    "apd": "2019-06-10",
    "apy": "2019",
    "tit": "一种图像数据处理方法、装置以及计算机可读存储介质",
    "inv": [
      "姜文浩",
      "刘小蒙",
      "黄浩智",
      "凌永根",
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110188223A",
    "apn": "CN201910492017.6",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "图像处理方法、装置及计算机设备",
    "inv": [
      "揭泽群",
      "袁粒",
      "冯佳时"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110213488A",
    "apn": "CN201910490705.9",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "一种定位方法及相关设备",
    "inv": [
      "俞一帆"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110175245A",
    "apn": "CN201910487392.1",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "多媒体推荐方法、装置、设备及存储介质",
    "inv": [
      "田元"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110209867A",
    "apn": "CN201910487664.8",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "图像检索模型的训练方法、装置、设备及存储介质",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276344A",
    "apn": "CN201910481441.0",
    "apd": "2019-06-04",
    "apy": "2019",
    "tit": "一种图像分割的方法、图像识别的方法以及相关装置",
    "inv": [
      "王瑞琛",
      "王晓利",
      "闫桂霞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263681A",
    "apn": "CN201910478195.3",
    "apd": "2019-06-03",
    "apy": "2019",
    "tit": "面部表情的识别方法和装置、存储介质、电子装置",
    "inv": [
      "樊艳波",
      "张勇",
      "李乐",
      "吴保元",
      "李志锋",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110193192A",
    "apn": "CN201910476863.9",
    "apd": "2019-06-03",
    "apy": "2019",
    "tit": "一种自动化游戏方法和装置",
    "inv": [
      "黄超",
      "周大军",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "深圳市腾讯网域计算机网络有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110223322A",
    "apn": "CN201910468700.6",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "图像识别方法、装置、计算机设备和存储介质",
    "inv": [
      "毕明伟",
      "丁守鸿",
      "李季檩",
      "骆雷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110222758A",
    "apn": "CN201910471077.X",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "一种图像处理方法、装置、设备及存储介质",
    "inv": [
      "李德东",
      "李志成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110213458A",
    "apn": "CN201910472603.4",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "一种图像数据处理方法、装置及存储介质",
    "inv": [
      "邬振海"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110197229A",
    "apn": "CN201910470449.7",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "图像处理模型的训练方法、装置及存储介质",
    "inv": [
      "陈嘉伟",
      "李悦翔",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110322571A",
    "apn": "CN201910461221.1",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "一种页面处理方法、装置及介质",
    "inv": [
      "肖辉",
      "黄剑鑫",
      "黄惟洁",
      "蔡树豪",
      "周昶灵",
      "刘洋",
      "张文慧"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110264544A",
    "apn": "CN201910464729.7",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "图片处理方法和装置、存储介质及电子装置",
    "inv": [
      "吴江红"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110175995A",
    "apn": "CN201910457380.4",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "一种基于病理图像的图像状态确定方法、装置以及系统",
    "inv": [
      "韩宝昌",
      "韩骁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110443794A",
    "apn": "CN201910737633.3",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "一种基于病理图像的图像状态确定方法、装置以及系统",
    "inv": [
      "韩宝昌",
      "韩骁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110377779A",
    "apn": "CN201910695847.9",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "图像标注的方法、基于病理图像的标注展示方法及装置",
    "inv": [
      "叶虎",
      "韩骁",
      "肖凯文",
      "周昵昀",
      "陈明扬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110175255A",
    "apn": "CN201910458139.3",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "图像标注的方法、基于病理图像的标注展示方法及装置",
    "inv": [
      "叶虎",
      "韩骁",
      "肖凯文",
      "周昵昀",
      "陈明扬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110188754A",
    "apn": "CN201910455150.4",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "图像分割方法和装置、模型训练方法和装置",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163827A",
    "apn": "CN201910452539.3",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "图像去噪模型的训练方法、图像去噪方法、装置及介质",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110176012A",
    "apn": "CN201910452561.8",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "图像中的目标分割方法、池化方法、装置及存储介质",
    "inv": [
      "张逸鹤",
      "伍健荣",
      "钱天翼"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163221A",
    "apn": "CN201910453298.4",
    "apd": "2019-05-28",
    "apy": "2019",
    "tit": "在图像中进行物体检测的方法、装置、车辆、机器人",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110232678A",
    "apn": "CN201910445331.9",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "一种图像不确定度预测方法、装置、设备及存储介质",
    "inv": [
      "边成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110176027A",
    "apn": "CN201910447379.3",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "视频目标跟踪方法、装置、设备及存储介质",
    "inv": [
      "崔振",
      "揭泽群",
      "魏力",
      "许春燕",
      "张桐"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148142A",
    "apn": "CN201910448095.6",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "图像分割模型的训练方法、装置、设备和存储介质",
    "inv": [
      "柳露艳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110222686A",
    "apn": "CN201910446799.X",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "物体检测方法、装置、计算机设备和存储介质",
    "inv": [
      "戴宇榮",
      "范琦",
      "卓炜",
      "沈小勇",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110491480A",
    "apn": "CN201910718873.9",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "一种医疗图像处理方法、装置、电子医疗设备和存储介质",
    "inv": [
      "田宽",
      "江铖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136809A",
    "apn": "CN201910429414.9",
    "apd": "2019-05-22",
    "apy": "2019",
    "tit": "一种医疗图像处理方法、装置、电子医疗设备和存储介质",
    "inv": [
      "田宽",
      "江铖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110135508A",
    "apn": "CN201910425520.X",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "模型训练方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "边成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110176024A",
    "apn": "CN201910424359.4",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "在视频中对目标进行检测的方法、装置、设备和存储介质",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110211063A",
    "apn": "CN201910420510.7",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "一种图像处理方法、装置、电子设备和系统",
    "inv": [
      "邰颖",
      "葛彦昊",
      "张晓雯",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210344A",
    "apn": "CN201910419763.2",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "视频动作识别方法及装置、电子设备、存储介质",
    "inv": [
      "倪烽",
      "易阳",
      "赵世杰",
      "邱日明",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110175549A",
    "apn": "CN201910420279.1",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "人脸图像处理方法、装置、设备及存储介质",
    "inv": [
      "陈超",
      "甘振业",
      "王文全",
      "张睿欣",
      "吴佳祥",
      "沈鹏程",
      "李安平",
      "徐兴坤",
      "李绍欣",
      "汪铖杰",
      "李季檩",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110135422A",
    "apn": "CN201910421176.7",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "一种密集目标的检测方法和装置",
    "inv": [
      "葛政",
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110288692A",
    "apn": "CN201910413385.7",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "光照渲染方法和装置、存储介质及电子装置",
    "inv": [
      "魏知晓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136236A",
    "apn": "CN201910414362.8",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "三维角色的个性化脸部显示方法、装置、设备及存储介质",
    "inv": [
      "刘绵光"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110175990A",
    "apn": "CN201910412004.3",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "人脸图像质量判定方法、装置及计算机设备",
    "inv": [
      "陈超",
      "陈星宇",
      "郭子滨",
      "沈鹏程",
      "李安平",
      "李绍欣",
      "李季檩",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110211218A",
    "apn": "CN201910413396.5",
    "apd": "2019-05-17",
    "apy": "2019",
    "tit": "画面渲染方法和装置、存储介质及电子装置",
    "inv": [
      "房超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136829A",
    "apn": "CN201910407807.X",
    "apd": "2019-05-16",
    "apy": "2019",
    "tit": "乳腺钼靶图像的辅助诊断方法、装置、系统及介质",
    "inv": [
      "田宽",
      "江铖",
      "颜克洲",
      "沈荣波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110189248A",
    "apn": "CN201910411369.4",
    "apd": "2019-05-16",
    "apy": "2019",
    "tit": "图像融合方法及装置、存储介质、电子设备",
    "inv": [
      "赵艳丹",
      "曹玮剑",
      "曹煊",
      "曹赟",
      "葛彦昊",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110135497A",
    "apn": "CN201910409882.X",
    "apd": "2019-05-16",
    "apy": "2019",
    "tit": "模型训练的方法、面部动作单元强度估计的方法及装置",
    "inv": [
      "张勇",
      "吴保元",
      "董未名",
      "李志锋",
      "刘威",
      "胡包钢",
      "纪强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136228A",
    "apn": "CN201910407224.7",
    "apd": "2019-05-16",
    "apy": "2019",
    "tit": "虚拟角色的面部替换方法、装置、终端及存储介质",
    "inv": [
      "肖辉",
      "黄剑鑫",
      "黄惟洁",
      "蔡树豪",
      "周昶灵",
      "刘洋",
      "张文慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163140A",
    "apn": "CN201910403159.0",
    "apd": "2019-05-15",
    "apy": "2019",
    "tit": "人群密度图获取方法及装置",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110135336A",
    "apn": "CN201910398152.4",
    "apd": "2019-05-14",
    "apy": "2019",
    "tit": "行人生成模型的训练方法、装置及存储介质",
    "inv": [
      "严骏驰",
      "罗文寒",
      "马林",
      "杨昭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110135329A",
    "apn": "CN201910394221.4",
    "apd": "2019-05-13",
    "apy": "2019",
    "tit": "从视频中提取姿势的方法、装置、设备及存储介质",
    "inv": [
      "卢云帆",
      "易阳",
      "赵世杰",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147836A",
    "apn": "CN201910397253.X",
    "apd": "2019-05-13",
    "apy": "2019",
    "tit": "模型训练方法、装置、终端及存储介质",
    "inv": [
      "王宁",
      "宋奕兵",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136082A",
    "apn": "CN201910395675.3",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "遮挡剔除方法、装置及计算机设备",
    "inv": [
      "唐江峻"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110209859A",
    "apn": "CN201910390693.2",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "地点识别及其模型训练的方法和装置以及电子设备",
    "inv": [
      "白栋栋",
      "凌永根",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148100A",
    "apn": "CN201910389112.3",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "一种图像变换方法、装置、存储介质及计算机设备",
    "inv": [
      "李东明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148157A",
    "apn": "CN201910391497.7",
    "apd": "2019-05-10",
    "apy": "2019",
    "tit": "画面目标跟踪方法、装置、存储介质及电子设备",
    "inv": [
      "孙冲",
      "孙宇轩",
      "卢湖川",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110121034A",
    "apn": "CN201910385878.4",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "一种在视频中植入信息的方法、装置及存储介质",
    "inv": [
      "高琛琼",
      "殷泽龙",
      "谢年华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147744A",
    "apn": "CN201910384137.4",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "一种人脸图像质量评估方法、装置及终端",
    "inv": [
      "季兴",
      "王一同",
      "周正"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147745A",
    "apn": "CN201910384360.9",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "一种视频关键帧检测方法及装置",
    "inv": [
      "宋浩",
      "黄珊"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110084767A",
    "apn": "CN201910386977.4",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "图像处理方法、装置、终端及存储介质",
    "inv": [
      "汪义明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148121A",
    "apn": "CN201910385633.1",
    "apd": "2019-05-09",
    "apy": "2019",
    "tit": "一种皮肤图像处理方法、装置、电子设备及介质",
    "inv": [
      "晏阳天",
      "范伟",
      "吴贤",
      "葛屾",
      "乔治",
      "王锴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110321920A",
    "apn": "CN201910379277.2",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "图像分类方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "胡一凡",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110188620A",
    "apn": "CN201910381831.0",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "对抗测试看图说话系统的方法和相关装置",
    "inv": [
      "吴保元",
      "许焱",
      "樊艳波",
      "张勇",
      "刘威",
      "沈复民",
      "申恒涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110239533A",
    "apn": "CN201910381122.2",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "确定车辆的驾驶风险的方法、设备、装置和可读存储介质",
    "inv": [
      "侯琛",
      "俞一帆",
      "张云飞",
      "王涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147742A",
    "apn": "CN201910380714.2",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "一种关键点定位方法、装置及终端",
    "inv": [
      "季兴",
      "王一同",
      "周正"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163194A",
    "apn": "CN201910378956.8",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "曹浩宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110222578A",
    "apn": "CN201910381832.5",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "对抗测试看图说话系统的方法和装置",
    "inv": [
      "吴保元",
      "许焱",
      "樊艳波",
      "张勇",
      "刘威",
      "沈复民",
      "申恒涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147511A",
    "apn": "CN201910381543.5",
    "apd": "2019-05-08",
    "apy": "2019",
    "tit": "一种页面处理方法、装置、电子设备及介质",
    "inv": [
      "肖辉",
      "黄剑鑫",
      "黄惟洁",
      "蔡树豪",
      "周昶灵",
      "刘洋",
      "张文慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110335277A",
    "apn": "CN201910373797.2",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "顾晓光",
      "付立波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110097130A",
    "apn": "CN201910377510.3",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "分类任务模型的训练方法、装置、设备及存储介质",
    "inv": [
      "沈荣波",
      "周可",
      "田宽",
      "颜克洲",
      "江铖"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司",
      "华中科技大学"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110110787A",
    "apn": "CN201910371250.9",
    "apd": "2019-05-06",
    "apy": "2019",
    "tit": "目标的位置获取方法、装置、计算机设备及存储介质",
    "inv": [
      "王宁",
      "宋奕兵",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136106A",
    "apn": "CN201910372711.4",
    "apd": "2019-05-06",
    "apy": "2019",
    "tit": "医疗内窥镜图像的识别方法、系统、设备和内窥镜影像系统",
    "inv": [
      "章子健",
      "孙钟前",
      "付星辉",
      "尚鸿",
      "王晓宁",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110222220A",
    "apn": "CN201910369974.X",
    "apd": "2019-05-06",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机可读介质及电子设备",
    "inv": [
      "金坤",
      "赵世杰",
      "易阳",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110111279A",
    "apn": "CN201910367512.4",
    "apd": "2019-05-05",
    "apy": "2019",
    "tit": "一种图像处理方法、装置及终端设备",
    "inv": [
      "夏海雄"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110102050A",
    "apn": "CN201910364229.6",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "虚拟对象显示方法、装置、电子设备及存储介质",
    "inv": [
      "丁志轩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163265A",
    "apn": "CN201910362817.6",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "数据处理方法、装置及计算机设备",
    "inv": [
      "吴佳祥",
      "沈鹏程",
      "李绍欣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110111364A",
    "apn": "CN201910361796.6",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "运动检测方法、装置、电子设备及存储介质",
    "inv": [
      "凌永根",
      "张晟浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110097570A",
    "apn": "CN201910364702.0",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "易成",
      "罗程",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110062272A",
    "apn": "CN201910358569.8",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "一种视频数据处理方法和相关装置",
    "inv": [
      "郑远力",
      "殷泽龙",
      "谢年华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163121A",
    "apn": "CN201910360905.2",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机设备及存储介质",
    "inv": [
      "缪畅宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110090440A",
    "apn": "CN201910365338.X",
    "apd": "2019-04-30",
    "apy": "2019",
    "tit": "虚拟对象显示方法、装置、电子设备及存储介质",
    "inv": [
      "丁志轩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110097576A",
    "apn": "CN201910356752.4",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "图像特征点的运动信息确定方法、任务执行方法和设备",
    "inv": [
      "凌永根",
      "张晟浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110083742A",
    "apn": "CN201910355782.3",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "一种视频查询方法和装置",
    "inv": [
      "冯洋",
      "马林",
      "刘威",
      "罗杰波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110198492A",
    "apn": "CN201910348979.4",
    "apd": "2019-04-28",
    "apy": "2019",
    "tit": "一种视频的水印添加方法、装置、设备及存储介质",
    "inv": [
      "范志兴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110134829A",
    "apn": "CN201910351116.2",
    "apd": "2019-04-28",
    "apy": "2019",
    "tit": "视频定位方法和装置、存储介质及电子装置",
    "inv": [
      "李祖桐",
      "阳赵阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163115A",
    "apn": "CN201910342354.7",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "一种视频处理方法、装置和计算机可读存储介质",
    "inv": [
      "李志成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110141857A",
    "apn": "CN201910342925.7",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "虚拟角色的面部显示方法、装置、设备及存储介质",
    "inv": [
      "沈超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110084193A",
    "apn": "CN201910345276.6",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "用于面部图像生成的数据处理方法、设备和介质",
    "inv": [
      "张勇",
      "李乐",
      "刘志磊",
      "吴保元",
      "樊艳波",
      "李志锋",
      "刘威"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110047053A",
    "apn": "CN201910344489.7",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "人像图片生成方法、装置和计算机设备",
    "inv": [
      "李悦馨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163262A",
    "apn": "CN201910351990.6",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "模型训练方法、业务处理方法、装置、终端及存储介质",
    "inv": [
      "牟帅",
      "陈宸",
      "肖万鹏"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110059661A",
    "apn": "CN201910345010.1",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "动作识别方法、人机交互方法、装置及存储介质",
    "inv": [
      "罗镜民",
      "乔亮",
      "朱晓龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110059807A",
    "apn": "CN201910345632.4",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "图像处理方法、装置和存储介质",
    "inv": [
      "牟帅",
      "肖万鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110070056A",
    "apn": "CN201910338835.0",
    "apd": "2019-04-25",
    "apy": "2019",
    "tit": "图像处理方法、装置、存储介质及设备",
    "inv": [
      "亓鲁",
      "蒋理",
      "刘枢",
      "沈小勇",
      "贾佳亚",
      "戴宇荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110059652A",
    "apn": "CN201910335080.9",
    "apd": "2019-04-24",
    "apy": "2019",
    "tit": "人脸图像处理方法、装置及存储介质",
    "inv": [
      "邰颖",
      "殷希",
      "李绍欣",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110064205A",
    "apn": "CN201910335020.7",
    "apd": "2019-04-24",
    "apy": "2019",
    "tit": "用于游戏的数据处理方法、设备和介质",
    "inv": [
      "梁静",
      "李宏亮",
      "李思琴",
      "杜雪莹",
      "王亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110058694A",
    "apn": "CN201910338224.6",
    "apd": "2019-04-24",
    "apy": "2019",
    "tit": "视线追踪模型训练的方法、视线追踪的方法及装置",
    "inv": [
      "周正",
      "季兴",
      "王一同",
      "朱晓龙",
      "罗敏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110135465A",
    "apn": "CN201910325428.6",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "模型参数表示空间大小估计方法及装置、推荐方法",
    "inv": [
      "王涌壮",
      "徐宇辉",
      "毛志成",
      "袁镱"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110111313A",
    "apn": "CN201910324565.8",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "基于深度学习的医学图像检测方法及相关设备",
    "inv": [
      "龚丽君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109874029A",
    "apn": "CN201910325193.0",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "视频描述生成方法、装置、设备及存储介质",
    "inv": [
      "裴文杰",
      "张记袁",
      "柯磊",
      "戴宇荣",
      "沈小勇",
      "贾佳亚",
      "王向荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110110617A",
    "apn": "CN201910322783.8",
    "apd": "2019-04-22",
    "apy": "2019",
    "tit": "医学影像分割方法、装置、电子设备和存储介质",
    "inv": [
      "曹世磊",
      "王仁振",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110046586A",
    "apn": "CN201910319953.7",
    "apd": "2019-04-19",
    "apy": "2019",
    "tit": "一种数据处理方法、设备及存储介质",
    "inv": [
      "徐兴坤"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110060276A",
    "apn": "CN201910314829.1",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "对象追踪方法、追踪处理方法、相应的装置、电子设备",
    "inv": [
      "王一同",
      "黄军",
      "季兴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110049242A",
    "apn": "CN201910314745.8",
    "apd": "2019-04-18",
    "apy": "2019",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "刘钊",
      "朱峰明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110020633A",
    "apn": "CN201910294734.8",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "姿态识别模型的训练方法、图像识别方法及装置",
    "inv": [
      "罗镜民",
      "朱晓龙",
      "王一同",
      "季兴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110033463A",
    "apn": "CN201910292962.1",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "一种前景数据生成及其应用方法、相关装置和系统",
    "inv": [
      "罗镜民",
      "朱晓龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110047152A",
    "apn": "CN201910292427.6",
    "apd": "2019-04-12",
    "apy": "2019",
    "tit": "基于虚拟环境的物体构建方法、装置及可读存储介质",
    "inv": [
      "沈超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110059608A",
    "apn": "CN201910290188.0",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "一种物体检测方法、装置、电子设备和存储介质",
    "inv": [
      "陈逸伦",
      "刘枢",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110009052A",
    "apn": "CN201910289986.1",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "一种图像识别的方法、图像识别模型训练的方法及装置",
    "inv": [
      "王一同",
      "黄佳博",
      "季兴",
      "周正"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110009623A",
    "apn": "CN201910284918.6",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "一种图像识别模型训练及图像识别方法、装置及系统",
    "inv": [
      "郑瀚",
      "孙钟前",
      "尚鸿",
      "付星辉",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110047118A",
    "apn": "CN201910276831.4",
    "apd": "2019-04-08",
    "apy": "2019",
    "tit": "视频生成方法、装置、计算机设备及存储介质",
    "inv": [
      "龙如蛟",
      "邱日明",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163810A",
    "apn": "CN201910277018.9",
    "apd": "2019-04-08",
    "apy": "2019",
    "tit": "一种图像处理方法、装置以及终端",
    "inv": [
      "田野",
      "王梦娜",
      "殷文婧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110162669A",
    "apn": "CN201910273083.4",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "视频分类处理方法、装置、计算机设备及存储介质",
    "inv": [
      "何奕江",
      "郑茂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110032962A",
    "apn": "CN201910267019.5",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "一种物体检测方法、装置、网络设备和存储介质",
    "inv": [
      "杨泽同",
      "孙亚楠",
      "賈佳亞",
      "戴宇榮",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109842811A",
    "apn": "CN201910266669.8",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "一种在视频中植入推送信息的方法、装置及电子设备",
    "inv": [
      "顾照鹏",
      "郑远力",
      "谢年华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109993125A",
    "apn": "CN201910266743.6",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "模型训练方法、人脸识别方法、装置、设备及存储介质",
    "inv": [
      "王浩",
      "龚迪洪",
      "李志鋒",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163082A",
    "apn": "CN201910262855.4",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "一种图像识别网络模型训练方法、图像识别方法及装置",
    "inv": [
      "葛政",
      "揭泽群",
      "王浩",
      "李志鋒",
      "龚迪洪",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110070005A",
    "apn": "CN201910262290.X",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "图像目标识别方法、装置、存储介质及电子设备",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "熊意超",
      "李绍欣",
      "陈超",
      "葛彦昊",
      "倪辉",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163080A",
    "apn": "CN201910260404.7",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "人脸关键点检测方法及装置、存储介质和电子设备",
    "inv": [
      "王一同",
      "季兴",
      "周正"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109993712A",
    "apn": "CN201910259016.7",
    "apd": "2019-04-01",
    "apy": "2019",
    "tit": "图像处理模型的训练方法、图像处理方法及相关设备",
    "inv": [
      "高宏运",
      "陶鑫",
      "賈佳亞",
      "戴宇榮",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110060237A",
    "apn": "CN201910247429.3",
    "apd": "2019-03-29",
    "apy": "2019",
    "tit": "一种故障检测方法、装置、设备及系统",
    "inv": [
      "高立钊",
      "易振彧",
      "贾佳亚",
      "戴宇荣",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978058A",
    "apn": "CN201910241812.8",
    "apd": "2019-03-28",
    "apy": "2019",
    "tit": "确定图像分类的方法、装置、终端及存储介质",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110009027A",
    "apn": "CN201910242869.X",
    "apd": "2019-03-28",
    "apy": "2019",
    "tit": "图像的比对方法、装置、存储介质及电子装置",
    "inv": [
      "赵安元",
      "李洋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978936A",
    "apn": "CN201910243260.4",
    "apd": "2019-03-28",
    "apy": "2019",
    "tit": "视差图获取方法、装置、存储介质及设备",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109961498A",
    "apn": "CN201910243265.7",
    "apd": "2019-03-28",
    "apy": "2019",
    "tit": "图像渲染方法、装置、终端及存储介质",
    "inv": [
      "吴宝",
      "王学强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109859299A",
    "apn": "CN201910238694.5",
    "apd": "2019-03-27",
    "apy": "2019",
    "tit": "人像图片处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "田野",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109949412A",
    "apn": "CN201910233202.3",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "一种三维对象重建方法和装置",
    "inv": [
      "高源",
      "林祥凯",
      "暴林超",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109784424A",
    "apn": "CN201910233985.5",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "一种图像分类模型训练的方法、图像处理的方法及装置",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978893A",
    "apn": "CN201910231466.5",
    "apd": "2019-03-26",
    "apy": "2019",
    "tit": "图像语义分割网络的训练方法、装置、设备及存储介质",
    "inv": [
      "揭泽群",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148081A",
    "apn": "CN201910228962.5",
    "apd": "2019-03-25",
    "apy": "2019",
    "tit": "图像处理模型的训练方法、图像处理方法、装置及存储介质",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163079A",
    "apn": "CN201910226798.4",
    "apd": "2019-03-25",
    "apy": "2019",
    "tit": "视频检测方法及装置、计算机可读介质和电子设备",
    "inv": [
      "龚国平",
      "徐敘遠",
      "吴韬",
      "杨喻茸"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109977859A",
    "apn": "CN201910228432.0",
    "apd": "2019-03-25",
    "apy": "2019",
    "tit": "一种图标识别的方法以及相关装置",
    "inv": [
      "黎伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163193A",
    "apn": "CN201910228327.7",
    "apd": "2019-03-25",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "姜媚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163910A",
    "apn": "CN201910222521.4",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "物体对象定位方法、装置、计算机设备和存储介质",
    "inv": [
      "姜媚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109961507A",
    "apn": "CN201910222403.3",
    "apd": "2019-03-22",
    "apy": "2019",
    "tit": "一种人脸图像生成方法、装置、设备及存储介质",
    "inv": [
      "者雪飞",
      "凌永根",
      "暴林超",
      "宋奕兵",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163078A",
    "apn": "CN201910217452.8",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "活体检测方法、装置及应用活体检测方法的服务系统",
    "inv": [
      "王智慧",
      "吴迪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109961041A",
    "apn": "CN201910218314.1",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "一种视频识别方法、装置及存储介质",
    "inv": [
      "王柏瑞",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109992679A",
    "apn": "CN201910218914.8",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "一种多媒体数据的分类方法及装置",
    "inv": [
      "唐永毅",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978918A",
    "apn": "CN201910216785.9",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "一种轨迹追踪方法、装置和存储介质",
    "inv": [
      "孙星",
      "蒋忻洋",
      "张毅",
      "李珂",
      "张均",
      "胡易",
      "姚雨石",
      "郭晓威",
      "余宗桥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919251A",
    "apn": "CN201910218444.5",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "一种基于图像的目标检测方法、模型训练的方法及装置",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109948707A",
    "apn": "CN201910215411.5",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "模型训练方法、装置、终端及存储介质",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109977832A",
    "apn": "CN201910206834.0",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109920065A",
    "apn": "CN201910203491.2",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "资讯的展示方法、装置、设备及存储介质",
    "inv": [
      "修海锟",
      "丁海峰",
      "张燕"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978756A",
    "apn": "CN201910204386.0",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "目标检测方法、系统、装置、存储介质和计算机设备",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978037A",
    "apn": "CN201910203359.1",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "图像处理方法、模型训练方法、装置、和存储介质",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109902660A",
    "apn": "CN201910202697.3",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "一种表情识别方法及装置",
    "inv": [
      "贺珂珂",
      "葛彦昊",
      "汪铖杰",
      "李季檩",
      "吴永坚",
      "黄飞跃",
      "朱敏",
      "黄小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109934845A",
    "apn": "CN201910197423.X",
    "apd": "2019-03-15",
    "apy": "2019",
    "tit": "基于自注意力网络的时序行为捕捉框生成方法及装置",
    "inv": [
      "郭大山",
      "姜文浩",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109871826A",
    "apn": "CN201910192289.4",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "信息展示方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "张菁芸"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109934173A",
    "apn": "CN201910194881.8",
    "apd": "2019-03-14",
    "apy": "2019",
    "tit": "表情识别方法、装置及电子设备",
    "inv": [
      "季兴",
      "王一同",
      "周正"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109903314A",
    "apn": "CN201910190207.2",
    "apd": "2019-03-13",
    "apy": "2019",
    "tit": "一种图像区域定位的方法、模型训练的方法及相关装置",
    "inv": [
      "马林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919993A",
    "apn": "CN201910186202.2",
    "apd": "2019-03-12",
    "apy": "2019",
    "tit": "视差图获取方法、装置和设备及控制系统",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163953A",
    "apn": "CN201910181932.3",
    "apd": "2019-03-11",
    "apy": "2019",
    "tit": "三维人脸重建方法、装置、存储介质和电子装置",
    "inv": [
      "吴凡子",
      "暴林超",
      "凌永根",
      "宋奕兵",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458830A",
    "apn": "CN201910746265.9",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "图像处理方法、装置、服务器及存储介质",
    "inv": [
      "王亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109934812A",
    "apn": "CN201910176668.4",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "图像处理方法、装置、服务器及存储介质",
    "inv": [
      "王亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978838A",
    "apn": "CN201910175745.4",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "图像区域定位方法、装置和医学图像处理设备",
    "inv": [
      "王亮",
      "张军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458813A",
    "apn": "CN201910684018.0",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "图像区域定位方法、装置和医学图像处理设备",
    "inv": [
      "王亮",
      "张军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110491502A",
    "apn": "CN201910758817.8",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "显微镜视频流处理方法、系统、计算机设备和存储介质",
    "inv": [
      "陆唯佳",
      "姚建华",
      "韩骁",
      "周昵昀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490247A",
    "apn": "CN201910755063.0",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "图像处理模型生成方法、图像处理方法及装置、电子设备",
    "inv": [
      "周昵昀",
      "韩骁",
      "姚建华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263801A",
    "apn": "CN201910177348.0",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "图像处理模型生成方法及装置、电子设备",
    "inv": [
      "周昵昀",
      "韩骁",
      "姚建华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110276741A",
    "apn": "CN201910176671.6",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "结节检测及其模型训练的方法和装置以及电子设备",
    "inv": [
      "陈鹏",
      "孙钟前",
      "程陈",
      "杨巍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109993969A",
    "apn": "CN201910176262.6",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "一种路况判定信息获取方法、装置及设备",
    "inv": [
      "孙立光",
      "谢建家"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109920518A",
    "apn": "CN201910176877.9",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "医学影像分析方法、装置、计算机设备和存储介质",
    "inv": [
      "陆唯佳",
      "姚建华",
      "韩骁",
      "周昵昀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458883A",
    "apn": "CN201910755558.3",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种医疗影像的处理系统、方法、装置和设备",
    "inv": [
      "田宽",
      "江铖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110033456A",
    "apn": "CN201910173138.4",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种医疗影像的处理方法、装置、设备和系统",
    "inv": [
      "田宽",
      "江铖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919874A",
    "apn": "CN201910171831.8",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机设备及存储介质",
    "inv": [
      "张凯皓",
      "罗文寒",
      "李宏东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110032510A",
    "apn": "CN201910171594.5",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "应用的测试方法及装置",
    "inv": [
      "俞瑜",
      "赖勇辉",
      "黄贵江",
      "方李志"
    ],
    "app": [
      "深圳市腾讯信息技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110032652A",
    "apn": "CN201910170583.5",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "媒体文件查找方法和装置、存储介质及电子装置",
    "inv": [
      "杨喻茸",
      "徐敘遠",
      "龚国平",
      "吴韬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109977799A",
    "apn": "CN201910172259.7",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "一种人眼与屏幕距离监控方法、装置及终端",
    "inv": [
      "张鑫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210694A",
    "apn": "CN201910171657.7",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "空间管理方法、装置、存储介质和计算机设备",
    "inv": [
      "袁伟康"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "景区",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919928A",
    "apn": "CN201910167844.8",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "医学影像的检测方法、装置和存储介质",
    "inv": [
      "周洪宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110033019A",
    "apn": "CN201910168066.4",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "人体部位的异常检测方法、装置和存储介质",
    "inv": [
      "周洪宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919087A",
    "apn": "CN201910168236.9",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "一种视频分类的方法、模型训练的方法及装置",
    "inv": [
      "唐永毅",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109951654A",
    "apn": "CN201910169985.3",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "一种视频合成的方法、模型训练的方法以及相关装置",
    "inv": [
      "黄浩智",
      "成昆",
      "袁春",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109816615A",
    "apn": "CN201910168409.7",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "图像处理方法、装置、设备以及存储介质",
    "inv": [
      "王毅",
      "陶鑫",
      "賈佳亞",
      "戴宇榮",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163076A",
    "apn": "CN201910164648.5",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "一种图像数据处理方法和相关装置",
    "inv": [
      "项小明",
      "徐浩",
      "王飞",
      "占克有",
      "郑克松",
      "刘承全"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110009656A",
    "apn": "CN201910164771.7",
    "apd": "2019-03-05",
    "apy": "2019",
    "tit": "目标对象的确定方法、装置、存储介质及电子装置",
    "inv": [
      "边成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109948671A",
    "apn": "CN201910161562.7",
    "apd": "2019-03-04",
    "apy": "2019",
    "tit": "图像分类方法、装置、存储介质以及内窥镜成像设备",
    "inv": [
      "陈嘉伟",
      "李悦翔",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109886243A",
    "apn": "CN201910156660.1",
    "apd": "2019-03-01",
    "apy": "2019",
    "tit": "图像处理方法、装置、存储介质、设备以及系统",
    "inv": [
      "郑贺",
      "姚建华",
      "韩骁",
      "黄俊洲"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109961444A",
    "apn": "CN201910156437.7",
    "apd": "2019-03-01",
    "apy": "2019",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "李灏峰",
      "李冠彬",
      "刘婷婷",
      "黄婷婷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163201A",
    "apn": "CN201910156754.9",
    "apd": "2019-03-01",
    "apy": "2019",
    "tit": "图像测试方法和装置、存储介质及电子装置",
    "inv": [
      "黄盈",
      "周大军",
      "丁熠",
      "弓霖芃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109939439A",
    "apn": "CN201910156985.X",
    "apd": "2019-03-01",
    "apy": "2019",
    "tit": "虚拟角色的卡住检测方法、模型训练方法、装置及设备",
    "inv": [
      "黄超",
      "周大军",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919869A",
    "apn": "CN201910148574.6",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "一种图像增强方法、装置及存储介质",
    "inv": [
      "王瑞星",
      "陶鑫",
      "沈小勇",
      "賈佳亞",
      "戴宇榮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147457A",
    "apn": "CN201910152063.1",
    "apd": "2019-02-28",
    "apy": "2019",
    "tit": "图文匹配方法、装置、存储介质及设备",
    "inv": [
      "贲有成",
      "吴航昊",
      "袁春",
      "周杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109847361A",
    "apn": "CN201910147619.8",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "运动状态的同步方法和装置、存储介质、电子装置",
    "inv": [
      "方煜宽",
      "蔡洋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "多屏互动",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147711A",
    "apn": "CN201910146975.8",
    "apd": "2019-02-27",
    "apy": "2019",
    "tit": "视频场景识别方法、装置、存储介质和电子装置",
    "inv": [
      "易阳",
      "涂娟辉",
      "李峰",
      "高孟平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490212A",
    "apn": "CN201910827719.5",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "钼靶影像处理设备、方法和装置",
    "inv": [
      "沈荣波",
      "颜克洲",
      "田宽",
      "张军",
      "周可"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "华中科技大学"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109886282A",
    "apn": "CN201910143267.9",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "对象检测方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "沈荣波",
      "颜克洲",
      "田宽",
      "张军",
      "周可"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "华中科技大学"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919888A",
    "apn": "CN201910142210.7",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "一种图像融合的方法、模型训练的方法以及相关装置",
    "inv": [
      "黄浩智",
      "胥森哲",
      "胡事民",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109978989A",
    "apn": "CN201910140602.X",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "三维人脸模型生成方法、装置、计算机设备及存储介质",
    "inv": [
      "陈雅静",
      "宋奕兵",
      "凌永根",
      "暴林超",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458834A",
    "apn": "CN201910755538.6",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "一种乳腺肿瘤图像处理系统、方法及装置",
    "inv": [
      "王亮",
      "张军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109949274A",
    "apn": "CN201910138930.6",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "一种图像处理方法、装置及系统",
    "inv": [
      "王亮",
      "张军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109886272A",
    "apn": "CN201910138419.6",
    "apd": "2019-02-25",
    "apy": "2019",
    "tit": "点云分割方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "刘枢",
      "王鑫龙",
      "沈小勇",
      "贾佳亚",
      "戴宇荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919164A",
    "apn": "CN201910134144.9",
    "apd": "2019-02-22",
    "apy": "2019",
    "tit": "用户界面对象的识别方法及装置",
    "inv": [
      "赵菁",
      "方李志",
      "赖勇辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109858562A",
    "apn": "CN201910130011.4",
    "apd": "2019-02-21",
    "apy": "2019",
    "tit": "一种医学影像的分类方法、装置和存储介质",
    "inv": [
      "高殿宇",
      "曹世磊",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872333A",
    "apn": "CN201910126219.9",
    "apd": "2019-02-20",
    "apy": "2019",
    "tit": "医学影像分割方法、装置、计算机设备和存储介质",
    "inv": [
      "曹世磊",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166828A",
    "apn": "CN201910122357.X",
    "apd": "2019-02-19",
    "apy": "2019",
    "tit": "一种视频处理方法和装置",
    "inv": [
      "李志成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490851A",
    "apn": "CN201910741376.0",
    "apd": "2019-02-15",
    "apy": "2019",
    "tit": "基于人工智能的乳腺图像分割方法、装置及系统",
    "inv": [
      "王亮",
      "张军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872312A",
    "apn": "CN201910116353.0",
    "apd": "2019-02-15",
    "apy": "2019",
    "tit": "医学图像分割方法、装置、系统及图像分割方法",
    "inv": [
      "王亮",
      "张军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109949271A",
    "apn": "CN201910116648.8",
    "apd": "2019-02-14",
    "apy": "2019",
    "tit": "一种基于医学图像的检测方法、模型训练的方法及装置",
    "inv": [
      "田宽",
      "江铖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110473186A",
    "apn": "CN201910728690.5",
    "apd": "2019-02-14",
    "apy": "2019",
    "tit": "一种基于医学图像的检测方法、模型训练的方法及装置",
    "inv": [
      "田宽",
      "江铖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490850A",
    "apn": "CN201910735273.3",
    "apd": "2019-02-14",
    "apy": "2019",
    "tit": "一种肿块区域检测方法、装置和医学图像处理设备",
    "inv": [
      "江铖",
      "田宽"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110009600A",
    "apn": "CN201910115522.9",
    "apd": "2019-02-14",
    "apy": "2019",
    "tit": "一种医学图像区域过滤方法、装置及存储介质",
    "inv": [
      "江铖",
      "田宽"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109815965A",
    "apn": "CN201910112755.3",
    "apd": "2019-02-13",
    "apy": "2019",
    "tit": "一种图像过滤方法、装置及存储介质",
    "inv": [
      "边成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109933635A",
    "apn": "CN201910112872.X",
    "apd": "2019-02-13",
    "apy": "2019",
    "tit": "一种更新地图数据库的方法及装置",
    "inv": [
      "张红卫",
      "刘龙"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109840504A",
    "apn": "CN201910102996.X",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "物品取放行为识别方法、装置、存储介质及设备",
    "inv": [
      "李习华",
      "贾佳亚",
      "戴宇荣",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109859296A",
    "apn": "CN201910103414.X",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "SMPL参数预测模型的训练方法、服务器及存储介质",
    "inv": [
      "孙爽",
      "李琛",
      "戴宇荣",
      "贾佳亚",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110009599A",
    "apn": "CN201910102836.5",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "肝占位检测方法、装置、设备及存储介质",
    "inv": [
      "曹世磊",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109886169A",
    "apn": "CN201910102825.7",
    "apd": "2019-02-01",
    "apy": "2019",
    "tit": "应用于无人货柜的物品识别方法、装置、设备及存储介质",
    "inv": [
      "李习华",
      "姚俊军",
      "贾佳亚",
      "戴宇荣",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110428405A",
    "apn": "CN201910684650.5",
    "apd": "2019-01-30",
    "apy": "2019",
    "tit": "一种检测生物组织图像中肿块的方法、相应设备及介质",
    "inv": [
      "江铖",
      "田宽"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872307A",
    "apn": "CN201910092954.2",
    "apd": "2019-01-30",
    "apy": "2019",
    "tit": "一种检测生物组织图像中肿块的方法、相应设备及介质",
    "inv": [
      "江铖",
      "田宽"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像诊断",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109745062A",
    "apn": "CN201910093446.6",
    "apd": "2019-01-30",
    "apy": "2019",
    "tit": "CT图像的生成方法、装置、设备及存储介质",
    "inv": [
      "郭恒",
      "应兴德",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109916416A",
    "apn": "CN201910083931.5",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "车道线数据处理与更新方法、装置及设备",
    "inv": [
      "刘春"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109815150A",
    "apn": "CN201910087252.5",
    "apd": "2019-01-29",
    "apy": "2019",
    "tit": "应用测试方法、装置、电子设备及存储介质",
    "inv": [
      "王洁梅",
      "周大军",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872306A",
    "apn": "CN201910082092.5",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "医学图像分割方法、装置和存储介质",
    "inv": [
      "陈雄涛",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109815298A",
    "apn": "CN201910082085.5",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "一种人物关系网确定方法、装置及存储介质",
    "inv": [
      "何睿毅",
      "刘能"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872364A",
    "apn": "CN201910082060.5",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "图像区域定位方法、装置、存储介质和医学影像处理设备",
    "inv": [
      "柴志忠",
      "曹世磊",
      "马锴",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872328A",
    "apn": "CN201910070881.7",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "一种脑部图像分割方法、装置和存储介质",
    "inv": [
      "郭恒",
      "李悦翔",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109886933A",
    "apn": "CN201910074060.0",
    "apd": "2019-01-25",
    "apy": "2019",
    "tit": "一种医学图像识别方法、装置和存储介质",
    "inv": [
      "胡一凡",
      "郑冶枫",
      "宋睿",
      "周旋",
      "王小军",
      "胡延洋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872362A",
    "apn": "CN201910047009.0",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "一种目标检测方法及装置",
    "inv": [
      "赵世杰",
      "李峰",
      "易阳",
      "邱日明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109801221A",
    "apn": "CN201910104171.1",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "生成对抗网络的训练方法、图像处理方法、装置和存储介质",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109858402A",
    "apn": "CN201910039627.0",
    "apd": "2019-01-16",
    "apy": "2019",
    "tit": "一种图像检测方法、装置、终端以及存储介质",
    "inv": [
      "张志辉",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109815868A",
    "apn": "CN201910034102.8",
    "apd": "2019-01-15",
    "apy": "2019",
    "tit": "一种图像目标检测方法、装置及存储介质",
    "inv": [
      "崔志鹏",
      "王亚彪",
      "罗栋豪",
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110164135A",
    "apn": "CN201910032720.9",
    "apd": "2019-01-14",
    "apy": "2019",
    "tit": "一种定位方法、定位装置及定位系统",
    "inv": [
      "刘恒进"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109819313A",
    "apn": "CN201910023976.3",
    "apd": "2019-01-10",
    "apy": "2019",
    "tit": "视频处理方法、装置及存储介质",
    "inv": [
      "田元"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109766840A",
    "apn": "CN201910029579.7",
    "apd": "2019-01-10",
    "apy": "2019",
    "tit": "人脸表情识别方法、装置、终端及存储介质",
    "inv": [
      "李冠彬",
      "朱鑫",
      "王巨宏",
      "黄婷婷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109697434A",
    "apn": "CN201910012006.3",
    "apd": "2019-01-07",
    "apy": "2019",
    "tit": "一种行为识别方法、装置和存储介质",
    "inv": [
      "王吉",
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109784256A",
    "apn": "CN201910012840.2",
    "apd": "2019-01-07",
    "apy": "2019",
    "tit": "人脸识别方法和装置、存储介质及电子装置",
    "inv": [
      "陈志博",
      "陈立",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109587556A",
    "apn": "CN201910005161.2",
    "apd": "2019-01-03",
    "apy": "2019",
    "tit": "视频处理方法、视频播放方法、装置、设备和存储介质",
    "inv": [
      "李杨",
      "孙炜",
      "潘梅"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109803172A",
    "apn": "CN201910005457.4",
    "apd": "2019-01-03",
    "apy": "2019",
    "tit": "一种直播视频的处理方法、装置及电子设备",
    "inv": [
      "李杨",
      "孙炜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109739609A",
    "apn": "CN201910004947.2",
    "apd": "2019-01-03",
    "apy": "2019",
    "tit": "图像处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "罗绮琪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109767378A",
    "apn": "CN201910001590.2",
    "apd": "2019-01-02",
    "apy": "2019",
    "tit": "图像处理方法和装置",
    "inv": [
      "易成",
      "罗程",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109753927A",
    "apn": "CN201910002499.2",
    "apd": "2019-01-02",
    "apy": "2019",
    "tit": "一种人脸检测方法和装置",
    "inv": [
      "武文琦",
      "叶泽雄",
      "肖万鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109815846A",
    "apn": "CN201811648106.7",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "图像处理方法、装置、存储介质和电子装置",
    "inv": [
      "赵安元",
      "李洋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "动植物识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109621413A",
    "apn": "CN201811624322.8",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "游戏画面的渲染显示方法、装置、终端及存储介质",
    "inv": [
      "夏富荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110162665A",
    "apn": "CN201811628462.2",
    "apd": "2018-12-28",
    "apy": "2018",
    "tit": "视频搜索方法、计算机设备及存储介质",
    "inv": [
      "龚国平",
      "徐叙远",
      "吴韬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109858333A",
    "apn": "CN201811564701.2",
    "apd": "2018-12-20",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及计算机可读介质",
    "inv": [
      "邰颖",
      "曹玮剑",
      "葛彦昊",
      "汪铖杰",
      "李季檩",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109657615A",
    "apn": "CN201811557119.3",
    "apd": "2018-12-19",
    "apy": "2018",
    "tit": "一种目标检测的训练方法、装置及终端设备",
    "inv": [
      "曹赟",
      "赵艳丹",
      "曹玮剑",
      "葛彦昊",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110413871A",
    "apn": "CN201811550753.4",
    "apd": "2018-12-18",
    "apy": "2018",
    "tit": "应用推荐方法、装置及电子设备",
    "inv": [
      "肖泽锋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109618224A",
    "apn": "CN201811548970.X",
    "apd": "2018-12-18",
    "apy": "2018",
    "tit": "视频数据处理方法、装置、计算机可读存储介质和设备",
    "inv": [
      "杨阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109785387A",
    "apn": "CN201811543605.X",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "机器人的回环检测方法、装置及机器人",
    "inv": [
      "张锲石",
      "程俊",
      "刘袁",
      "方璡",
      "向荣"
    ],
    "app": [
      "中国科学院深圳先进技术研究院",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110162664A",
    "apn": "CN201811542848.1",
    "apd": "2018-12-17",
    "apy": "2018",
    "tit": "视频推荐方法、装置、计算机设备及存储介质",
    "inv": [
      "刘龙坡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109697416A",
    "apn": "CN201811532116.4",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "一种视频数据处理方法和相关装置",
    "inv": [
      "毕明伟",
      "丁守鸿",
      "李季檩",
      "孟嘉",
      "吴双",
      "刘尧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109359636A",
    "apn": "CN201811535837.0",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "视频分类方法、装置及服务器",
    "inv": [
      "屈冰欣",
      "郑茂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109635752A",
    "apn": "CN201811536436.7",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "人脸关键点的定位方法、人脸图像处理方法和相关装置",
    "inv": [
      "晏轶超",
      "汪铖杰",
      "李季檩",
      "葛彦昊",
      "曹赟",
      "曹玮剑",
      "曹煊",
      "梁亦聪",
      "赵艳丹"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109670437A",
    "apn": "CN201811532337.1",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "年龄预估模型训练方法、面部图像识别方法及装置",
    "inv": [
      "贺珂珂",
      "葛彦昊",
      "汪铖杰",
      "李季檩",
      "吴永坚",
      "黄飞跃",
      "杨思骞",
      "姚永强",
      "朱敏",
      "黄小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109242769B",
    "apn": "CN201811520307.9",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "郑远力",
      "顾照鹏",
      "许可",
      "肖泽东",
      "陈宗豪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163068A",
    "apn": "CN201811528379.8",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "目标对象跟踪方法、装置、存储介质和计算机设备",
    "inv": [
      "邱日明",
      "赵世杰",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147710A",
    "apn": "CN201811506344.4",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "人脸特征的处理方法、装置和存储介质",
    "inv": [
      "陈超",
      "吴佳祥",
      "沈鹏程",
      "王文全",
      "李安平",
      "梁亦聪",
      "张睿欣",
      "徐兴坤",
      "李绍欣",
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109657583A",
    "apn": "CN201811503905.5",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "脸部关键点检测方法、装置、计算机设备和存储介质",
    "inv": [
      "曹煊",
      "曹玮剑",
      "葛彦昊",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163066A",
    "apn": "CN201811497054.8",
    "apd": "2018-12-07",
    "apy": "2018",
    "tit": "多媒体数据推荐方法、装置及存储介质",
    "inv": [
      "肖磊",
      "蒋杰",
      "芦清林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109615686A",
    "apn": "CN201811493375.0",
    "apd": "2018-12-07",
    "apy": "2018",
    "tit": "潜在可视集合的确定方法、装置、设备及存储介质",
    "inv": [
      "李海全",
      "赖贵雄"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109670427A",
    "apn": "CN201811494173.8",
    "apd": "2018-12-07",
    "apy": "2018",
    "tit": "一种图像信息的处理方法、装置及存储介质",
    "inv": [
      "袁佳平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109697460A",
    "apn": "CN201811479732.8",
    "apd": "2018-12-05",
    "apy": "2018",
    "tit": "对象检测模型训练方法、目标对象检测方法",
    "inv": [
      "王兴刚",
      "刘文予",
      "李超",
      "黄俊洲",
      "王巨宏",
      "马东嫄"
    ],
    "app": [
      "华中科技大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109377544A",
    "apn": "CN201811459413.0",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "一种人脸三维图像生成方法、装置和可读介质",
    "inv": [
      "陈雅静",
      "林祥凯",
      "宋奕兵",
      "凌永根",
      "暴林超",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109685116A",
    "apn": "CN201811460241.9",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "图像描述信息生成方法和装置及电子装置",
    "inv": [
      "陈宸",
      "牟帅",
      "肖万鹏",
      "鞠奇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109598301A",
    "apn": "CN201811459246.X",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "检测区域去除方法、装置、终端和存储介质",
    "inv": [
      "亓鲁",
      "刘枢",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109598728A",
    "apn": "CN201811462063.3",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "图像分割方法、装置、诊断系统及存储介质",
    "inv": [
      "胡一凡",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109620293A",
    "apn": "CN201811456731.1",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "一种图像识别方法、装置以及存储介质",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109584151A",
    "apn": "CN201811453182.2",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "人脸美化方法、装置、终端及存储介质",
    "inv": [
      "汪倩怡",
      "邢雪源"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110223367A",
    "apn": "CN201811458182.1",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "动画显示方法、装置、终端及存储介质",
    "inv": [
      "刘希呈"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109409335A",
    "apn": "CN201811455877.4",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机可读介质及电子设备",
    "inv": [
      "林祥凯",
      "暴林超",
      "凌永根",
      "宋奕兵",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163064A",
    "apn": "CN201811459262.9",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "一种道路标志物的识别方法、装置及存储介质",
    "inv": [
      "江旻"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109670413A",
    "apn": "CN201811455936.8",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "人脸活体验证方法及装置",
    "inv": [
      "高源",
      "罗文寒",
      "暴林超",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109598749A",
    "apn": "CN201811458670.2",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "一种三维人脸模型的参数配置方法、装置、设备及介质",
    "inv": [
      "胡木",
      "郜思睿",
      "凌永根",
      "王一同",
      "暴林超",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109584178A",
    "apn": "CN201811442428.6",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "图像修复方法、装置和存储介质",
    "inv": [
      "陶鑫",
      "王毅",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109522450A",
    "apn": "CN201811445857.9",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "一种视频分类的方法以及服务器",
    "inv": [
      "马林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163063A",
    "apn": "CN201811434738.3",
    "apd": "2018-11-28",
    "apy": "2018",
    "tit": "表情处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "郭艺帆",
      "江会福",
      "薛丰",
      "刘楠"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109492608B",
    "apn": "CN201811425694.8",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "图像分割方法、装置、计算机设备及存储介质",
    "inv": [
      "陈思宏",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166827A",
    "apn": "CN201811427035.8",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "视频片段的确定方法、装置、存储介质及电子装置",
    "inv": [
      "黄超",
      "周大军",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "深圳市腾讯信息技术有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210279A",
    "apn": "CN201811427403.9",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "目标检测方法、装置及计算机可读存储介质",
    "inv": [
      "谢植淮",
      "高孟平",
      "李峰",
      "赵世杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110009598A",
    "apn": "CN201811419143.0",
    "apd": "2018-11-26",
    "apy": "2018",
    "tit": "用于图像分割的方法和图像分割设备",
    "inv": [
      "陈雨",
      "李悦翔",
      "郑冶枫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109670532A",
    "apn": "CN201811410221.0",
    "apd": "2018-11-23",
    "apy": "2018",
    "tit": "生物体器官组织图像的异常识别方法、装置及系统",
    "inv": [
      "章子健",
      "孙钟前",
      "付星辉",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109614510A",
    "apn": "CN201811406387.5",
    "apd": "2018-11-23",
    "apy": "2018",
    "tit": "一种图像检索方法、装置、图形处理器和存储介质",
    "inv": [
      "丁丹迪",
      "李彦融",
      "田恒锋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210278A",
    "apn": "CN201811391328.5",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "一种视频目标检测方法、装置及存储介质",
    "inv": [
      "谢植淮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109657554A",
    "apn": "CN201811392529.7",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "一种基于微表情的图像识别方法、装置以及相关设备",
    "inv": [
      "张凯皓",
      "罗文寒",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109613002A",
    "apn": "CN201811392995.5",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "一种玻璃缺陷检测方法、装置和存储介质",
    "inv": [
      "唐景群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166826A",
    "apn": "CN201811392100.8",
    "apd": "2018-11-21",
    "apy": "2018",
    "tit": "视频的场景识别方法、装置、存储介质及计算机设备",
    "inv": [
      "易阳",
      "涂娟辉",
      "李峰",
      "高孟平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109934775A",
    "apn": "CN201811384682.5",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "一种图像处理、模型训练、方法、装置和存储介质",
    "inv": [
      "任文琦",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109492698A",
    "apn": "CN201811384448.2",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "一种模型训练的方法、对象检测的方法以及相关装置",
    "inv": [
      "范奇",
      "何庆玮",
      "何炜霞",
      "潘晖",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163062A",
    "apn": "CN201811385466.2",
    "apd": "2018-11-20",
    "apy": "2018",
    "tit": "掌纹主线提取方法、装置、计算机设备及存储介质",
    "inv": [
      "郭莎",
      "夏珍",
      "李凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109493407A",
    "apn": "CN201811374889.4",
    "apd": "2018-11-19",
    "apy": "2018",
    "tit": "实现激光点云稠密化的方法、装置及计算机设备",
    "inv": [
      "陈仁",
      "孙银健",
      "黄天"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109684920A",
    "apn": "CN201811377195.6",
    "apd": "2018-11-19",
    "apy": "2018",
    "tit": "物体关键点的定位方法、图像处理方法、装置及存储介质",
    "inv": [
      "彭伟龙",
      "沈小勇",
      "陈逸伦",
      "孙亚楠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109461199A",
    "apn": "CN201811371864.9",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "画面渲染方法和装置、存储介质及电子装置",
    "inv": [
      "张怀涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109584266A",
    "apn": "CN201811358627.9",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "一种目标检测方法及装置",
    "inv": [
      "刘毅",
      "李峰",
      "赵世杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109499059A",
    "apn": "CN201811361069.1",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "对象的渲染方法和装置、存储介质、电子装置",
    "inv": [
      "张怀涛",
      "宋立强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163061A",
    "apn": "CN201811353102.6",
    "apd": "2018-11-14",
    "apy": "2018",
    "tit": "用于提取视频指纹的方法、装置、设备和计算机可读介质",
    "inv": [
      "叶燕罡",
      "沈小勇",
      "陈忠磊",
      "马子扬",
      "戴宇榮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109523532A",
    "apn": "CN201811348721.6",
    "apd": "2018-11-13",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机可读介质及电子设备",
    "inv": [
      "尚鸿",
      "孙钟前",
      "付星辉",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163237A",
    "apn": "CN201811325357.1",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "模型训练及图像处理方法、装置、介质、电子设备",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109523526A",
    "apn": "CN201811326267.4",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "组织结节检测及其模型训练方法、装置、设备和系统",
    "inv": [
      "程陈",
      "孙钟前",
      "陈钊",
      "杨巍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110209881A",
    "apn": "CN201811322381.X",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "视频搜索方法、装置及存储介质",
    "inv": [
      "庄钟鑫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109413434A",
    "apn": "CN201811324357.X",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "图像处理方法、装置、系统、存储介质和计算机设备",
    "inv": [
      "应玉龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163060A",
    "apn": "CN201811321592.1",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "图像中人群密度的确定方法及电子设备",
    "inv": [
      "罗文寒",
      "万佳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109559195A",
    "apn": "CN201811316998.0",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "用于无人超市的购物管理方法、系统及相关设备",
    "inv": [
      "戚超杰",
      "陈碧琳",
      "刘勇刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148147A",
    "apn": "CN201811320653.2",
    "apd": "2018-11-07",
    "apy": "2018",
    "tit": "图像检测方法、装置、存储介质和电子装置",
    "inv": [
      "薛涛"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163863A",
    "apn": "CN201811315438.3",
    "apd": "2018-11-06",
    "apy": "2018",
    "tit": "三维物体分割方法、设备和介质",
    "inv": [
      "周城",
      "王天舟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110149551A",
    "apn": "CN201811313939.8",
    "apd": "2018-11-06",
    "apy": "2018",
    "tit": "媒体文件播放方法和装置、存储介质及电子装置",
    "inv": [
      "刘玉杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147709A",
    "apn": "CN201811306036.7",
    "apd": "2018-11-02",
    "apy": "2018",
    "tit": "车辆属性模型的训练方法、装置、终端及存储介质",
    "inv": [
      "戴雨辰",
      "郭晓威",
      "余宗桥",
      "张睿欣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109493280A",
    "apn": "CN201811305849.4",
    "apd": "2018-11-02",
    "apy": "2018",
    "tit": "图像处理方法、装置、终端及存储介质",
    "inv": [
      "戴雨辰",
      "张睿欣",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109461495A",
    "apn": "CN201811296263.6",
    "apd": "2018-11-01",
    "apy": "2018",
    "tit": "一种医学图像的识别方法、模型训练的方法及服务器",
    "inv": [
      "肖凯文",
      "孙钟前",
      "程陈",
      "杨巍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110149529A",
    "apn": "CN201811294527.4",
    "apd": "2018-11-01",
    "apy": "2018",
    "tit": "媒体信息的处理方法、服务器及存储介质",
    "inv": [
      "刘刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109447973A",
    "apn": "CN201811287489.X",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "一种结肠息肉图像的处理方法和装置及系统",
    "inv": [
      "付星辉",
      "孙钟前",
      "尚鸿",
      "章子健",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163059A",
    "apn": "CN201811275350.3",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "多人姿态识别方法、装置及电子设备",
    "inv": [
      "黄浩智",
      "龚新宇",
      "罗镜民",
      "朱晓龙",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109523522A",
    "apn": "CN201811276885.2",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "内窥镜图像的处理方法、装置、系统及存储介质",
    "inv": [
      "付星辉",
      "孙钟前",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147708A",
    "apn": "CN201811276686.1",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "一种图像数据处理方法和相关装置",
    "inv": [
      "郑克松"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109427060A",
    "apn": "CN201811278418.3",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "一种影像识别的方法、装置、终端设备和医疗系统",
    "inv": [
      "付星辉",
      "孙钟前",
      "尚鸿",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110198432A",
    "apn": "CN201811280806.5",
    "apd": "2018-10-30",
    "apy": "2018",
    "tit": "视频数据的处理方法、装置、计算机可读介质及电子设备",
    "inv": [
      "谢金运",
      "杨宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163057A",
    "apn": "CN201811273526.1",
    "apd": "2018-10-29",
    "apy": "2018",
    "tit": "目标检测方法、装置、设备和计算机可读介质",
    "inv": [
      "罗栋豪",
      "王亚彪",
      "崔志鹏",
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109447149A",
    "apn": "CN201811251214.0",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "一种检测模型的训练方法、装置及终端设备",
    "inv": [
      "程陈",
      "孙钟前",
      "陈豪",
      "杨巍"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109492551A",
    "apn": "CN201811252616.2",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "活体检测方法、装置及应用活体检测方法的相关系统",
    "inv": [
      "罗文寒",
      "王耀东",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109492550A",
    "apn": "CN201811250025.1",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "活体检测方法、装置及应用活体检测方法的相关系统",
    "inv": [
      "罗文寒",
      "王耀东",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109446981A",
    "apn": "CN201811251558.1",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "一种脸部活体检测、身份认证方法及装置",
    "inv": [
      "黎伟",
      "叶泽雄",
      "肖万鹏",
      "马福明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109195011A",
    "apn": "CN201811249465.5",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "一种视频处理方法、装置、设备及存储介质",
    "inv": [
      "刘袁",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109492547A",
    "apn": "CN201811243255.5",
    "apd": "2018-10-24",
    "apy": "2018",
    "tit": "一种结节识别方法、装置和存储介质",
    "inv": [
      "刘洵",
      "蒋忻洋",
      "王睿",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147706A",
    "apn": "CN201811245582.4",
    "apd": "2018-10-24",
    "apy": "2018",
    "tit": "障碍物的识别方法和装置、存储介质、电子装置",
    "inv": [
      "陈仁",
      "孙银健"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109376781A",
    "apn": "CN201811243088.4",
    "apd": "2018-10-24",
    "apy": "2018",
    "tit": "一种图像识别模型的训练方法、图像识别方法和相关装置",
    "inv": [
      "潘晖",
      "范奇",
      "艾长青",
      "何庆玮",
      "田昊野",
      "张力柯",
      "荆彦青",
      "姚英杰",
      "王君乐"
    ],
    "app": [
      "深圳市腾讯网络信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110162690A",
    "apn": "CN201811233142.7",
    "apd": "2018-10-23",
    "apy": "2018",
    "tit": "确定用户对物品的兴趣度的方法与装置、设备和存储介质",
    "inv": [
      "徐聪",
      "马明远"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109450867B",
    "apn": "CN201811227867.5",
    "apd": "2018-10-22",
    "apy": "2018",
    "tit": "一种身份认证方法、装置及存储介质",
    "inv": [
      "胡晓楠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163862A",
    "apn": "CN201811232265.9",
    "apd": "2018-10-22",
    "apy": "2018",
    "tit": "图像语义分割方法、装置及计算机设备",
    "inv": [
      "王志杰",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148191A",
    "apn": "CN201811214523.0",
    "apd": "2018-10-18",
    "apy": "2018",
    "tit": "视频虚拟表情生成方法、装置及计算机可读存储介质",
    "inv": [
      "郭艺帆",
      "薛丰",
      "刘楠",
      "江会福"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109360210B",
    "apn": "CN201811204371.6",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "图像分割方法、装置、计算机设备及存储介质",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109410220A",
    "apn": "CN201811205146.4",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "图像分割方法、装置、计算机设备及存储介质",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109446951A",
    "apn": "CN201811204375.4",
    "apd": "2018-10-16",
    "apy": "2018",
    "tit": "三维图像的语义分割方法、装置、设备及存储介质",
    "inv": [
      "陈思宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163889A",
    "apn": "CN201811198327.9",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "目标跟踪方法、目标跟踪装置、目标跟踪设备",
    "inv": [
      "彭瑾龙",
      "何长伟",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109461168A",
    "apn": "CN201811197547.X",
    "apd": "2018-10-15",
    "apy": "2018",
    "tit": "目标对象的识别方法和装置、存储介质、电子装置",
    "inv": [
      "陈炳文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109389088A",
    "apn": "CN201811191485.1",
    "apd": "2018-10-12",
    "apy": "2018",
    "tit": "视频识别方法、装置、机器设备以及计算机可读存储介质",
    "inv": [
      "陆康"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110160502A",
    "apn": "CN201811186664.6",
    "apd": "2018-10-12",
    "apy": "2018",
    "tit": "地图要素提取方法、装置及服务器",
    "inv": [
      "舒茂",
      "陈偲"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163235A",
    "apn": "CN201811183016.5",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "图像增强模型的训练、图像增强方法、装置和存储介质",
    "inv": [
      "石世昌",
      "黄飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109410185B",
    "apn": "CN201811176821.5",
    "apd": "2018-10-10",
    "apy": "2018",
    "tit": "一种图像分割方法、装置和存储介质",
    "inv": [
      "边成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109525859A",
    "apn": "CN201811186315.4",
    "apd": "2018-10-10",
    "apy": "2018",
    "tit": "模型训练、图像发送、图像处理方法及相关装置设备",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110162204A",
    "apn": "CN201811173020.3",
    "apd": "2018-10-09",
    "apy": "2018",
    "tit": "触发设备功能的方法、装置以及控制进行图像捕获的方法",
    "inv": [
      "汪雨秋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147806A",
    "apn": "CN201811167476.9",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "图像描述模型的训练方法、装置及存储介质",
    "inv": [
      "冯洋",
      "马林",
      "刘威",
      "罗杰波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163222A",
    "apn": "CN201811169065.3",
    "apd": "2018-10-08",
    "apy": "2018",
    "tit": "一种图像识别的方法、模型训练的方法以及服务器",
    "inv": [
      "张凯皓",
      "罗文寒",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109359575A",
    "apn": "CN201811165758.5",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "人脸检测方法、业务处理方法、装置、终端及介质",
    "inv": [
      "郑克松"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163199A",
    "apn": "CN201811159787.0",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "车牌识别方法、车牌识别装置、车牌识别设备及介质",
    "inv": [
      "戴雨辰",
      "董鑫",
      "郭晓威",
      "余宗桥",
      "张睿欣",
      "姚雨石"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110161513A",
    "apn": "CN201811143089.1",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "估计道路坡度的方法、装置、存储介质和计算机设备",
    "inv": [
      "刘春"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109359558A",
    "apn": "CN201811125625.5",
    "apd": "2018-09-26",
    "apy": "2018",
    "tit": "图像标注方法、目标检测方法、装置及存储介质",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "熊意超",
      "钮小光",
      "彭瑾龙",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109241299A",
    "apn": "CN201811116140.X",
    "apd": "2018-09-25",
    "apy": "2018",
    "tit": "多媒体资源搜索方法、装置、存储介质及设备",
    "inv": [
      "卢鹏飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109165307A",
    "apn": "CN201811094210.6",
    "apd": "2018-09-19",
    "apy": "2018",
    "tit": "一种特征检索方法、装置和存储介质",
    "inv": [
      "李彦融",
      "王星晨",
      "戴冠雄",
      "容清员"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109344884A",
    "apn": "CN201811076275.8",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "媒体信息分类方法、训练图片分类模型的方法及装置",
    "inv": [
      "柴子峰",
      "王煦祥",
      "陈涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109325967A",
    "apn": "CN201811076284.7",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "目标跟踪方法、装置、介质以及设备",
    "inv": [
      "张浩",
      "牛志伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109344742A",
    "apn": "CN201811073920.0",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "特征点定位方法、装置、存储介质和计算机设备",
    "inv": [
      "赵艳丹",
      "晏轶超",
      "曹玮剑",
      "曹赟",
      "葛彦昊",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110162643A",
    "apn": "CN201811069853.5",
    "apd": "2018-09-13",
    "apy": "2018",
    "tit": "电子相册报告生成方法、装置及存储介质",
    "inv": [
      "石智灵",
      "刘灿尧",
      "廖东鸣",
      "李欣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148196A",
    "apn": "CN201811063723.0",
    "apd": "2018-09-12",
    "apy": "2018",
    "tit": "一种图像处理方法、装置以及相关设备",
    "inv": [
      "舒茂",
      "陈偲"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163904A",
    "apn": "CN201811060480.5",
    "apd": "2018-09-11",
    "apy": "2018",
    "tit": "对象标注方法、移动控制方法、装置、设备及存储介质",
    "inv": [
      "曾超"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109343920A",
    "apn": "CN201811053229.6",
    "apd": "2018-09-10",
    "apy": "2018",
    "tit": "一种图像处理方法及其装置、设备和存储介质",
    "inv": [
      "李旭冬",
      "黄盈",
      "周大军",
      "张力柯",
      "丁熠"
    ],
    "app": [
      "深圳市腾讯网络信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109308727A",
    "apn": "CN201811047752.8",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "虚拟形象模型生成方法、装置及存储介质",
    "inv": [
      "赵艳丹",
      "曹玮剑",
      "汪铖杰",
      "李季檩",
      "黄渊",
      "程盼",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109117635A",
    "apn": "CN201811042877.1",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "应用程序的病毒检测方法、装置、计算机设备及存储介质",
    "inv": [
      "雷经纬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109189959A",
    "apn": "CN201811039071.7",
    "apd": "2018-09-06",
    "apy": "2018",
    "tit": "一种构建图像数据库的方法及装置",
    "inv": [
      "李岩",
      "王汉杰",
      "曹刚",
      "龚治",
      "陈波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109344287A",
    "apn": "CN201811032777.0",
    "apd": "2018-09-05",
    "apy": "2018",
    "tit": "一种信息推荐方法及相关设备",
    "inv": [
      "章飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109376581A",
    "apn": "CN201811022504.8",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "对象关系识别方法和装置、存储介质及电子装置",
    "inv": [
      "赵安元",
      "李洋"
    ],
    "app": [
      "腾讯科技(武汉)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109299315A",
    "apn": "CN201811022608.9",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "多媒体资源分类方法、装置、计算机设备及存储介质",
    "inv": [
      "唐永毅",
      "马林",
      "刘威",
      "周连强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109189950A",
    "apn": "CN201811023323.7",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "多媒体资源分类方法、装置、计算机设备及存储介质",
    "inv": [
      "唐永毅",
      "马林",
      "刘威",
      "周连强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109447895A",
    "apn": "CN201811019688.2",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "图片生成方法和装置、存储介质及电子装置",
    "inv": [
      "巩晓波"
    ],
    "app": [
      "腾讯科技(武汉)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109147058A",
    "apn": "CN201811012768.5",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "用于视觉惯导信息融合的初始化方法和装置以及存储介质",
    "inv": [
      "凌永根",
      "暴林超",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110209880A",
    "apn": "CN201811009469.6",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "视频内容检索方法、视频内容检索装置及存储介质",
    "inv": [
      "孙祥学"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109173263A",
    "apn": "CN201811014154.0",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "一种图像数据处理方法和装置",
    "inv": [
      "李科慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109186592A",
    "apn": "CN201811014745.8",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "用于视觉惯导信息融合的方法和装置以及存储介质",
    "inv": [
      "凌永根",
      "暴林超",
      "揭泽群",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109242765A",
    "apn": "CN201811009591.3",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "一种人脸图像处理方法、装置和存储介质",
    "inv": [
      "段聪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109165606A",
    "apn": "CN201810992443.1",
    "apd": "2018-08-29",
    "apy": "2018",
    "tit": "一种车辆信息的获取方法、装置以及存储介质",
    "inv": [
      "胡易",
      "张睿欣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109285215A",
    "apn": "CN201810990982.1",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "一种人体三维模型重建方法、装置和存储介质",
    "inv": [
      "李琛",
      "易鸿伟",
      "曹琼",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109241896A",
    "apn": "CN201810990426.4",
    "apd": "2018-08-28",
    "apy": "2018",
    "tit": "一种通道安全检测方法、装置及电子设备",
    "inv": [
      "白则人"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109260708A",
    "apn": "CN201810971215.6",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "地图渲染方法、装置以及计算机设备",
    "inv": [
      "邵岳伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163197A",
    "apn": "CN201810974541.2",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "目标检测方法、装置、计算机可读存储介质及计算机设备",
    "inv": [
      "苗捷",
      "冉辰",
      "许典平",
      "贾晓义",
      "姜媚",
      "林榆耿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109117890A",
    "apn": "CN201810973916.3",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "一种图像分类方法、装置和存储介质",
    "inv": [
      "伍健荣",
      "贾琼",
      "孙星",
      "郭晓威",
      "周旋",
      "常佳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109190540A",
    "apn": "CN201810975021.3",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "活检区域预测方法、图像识别方法、装置和存储介质",
    "inv": [
      "伍健荣",
      "贾琼",
      "孙星",
      "郭晓威",
      "周旋",
      "常佳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109064431A",
    "apn": "CN201810967143.8",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "一种图片亮度调节方法、设备及其存储介质",
    "inv": [
      "夏俊伟"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109243602A",
    "apn": "CN201810968667.9",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "数据识别方法和装置、存储介质及其对应的电子装置",
    "inv": [
      "李建东",
      "隋微"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110008971A",
    "apn": "CN201810967559.X",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机可读存储介质及计算机设备",
    "inv": [
      "颜克洲"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166851A",
    "apn": "CN201810955587.X",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "一种视频摘要生成方法、装置和存储介质",
    "inv": [
      "张浩",
      "黄湘琦",
      "陈泳君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109299658A",
    "apn": "CN201810954609.0",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "脸部检测方法、脸部图像渲染方法、装置及存储介质",
    "inv": [
      "赵艳丹",
      "曹玮剑",
      "曹赟",
      "汪铖杰",
      "黄小明",
      "梁小龙",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109117803A",
    "apn": "CN201810952542.7",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "人脸图像的聚类方法、装置、服务器及存储介质",
    "inv": [
      "晏轶超",
      "汪铖杰",
      "李季檩",
      "葛彦昊",
      "甘振业",
      "何长伟",
      "李科",
      "金星明",
      "钱柄桦",
      "吴永坚",
      "黄飞跃",
      "吴运声"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109242943A",
    "apn": "CN201810954469.7",
    "apd": "2018-08-21",
    "apy": "2018",
    "tit": "一种图像渲染方法、装置及图像处理设备、存储介质",
    "inv": [
      "趙瑞祥",
      "李懐哲"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147703A",
    "apn": "CN201810949946.0",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "人脸关键点检测方法、装置及存储介质",
    "inv": [
      "赵世杰",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109145809A",
    "apn": "CN201810940982.0",
    "apd": "2018-08-17",
    "apy": "2018",
    "tit": "一种记谱处理方法和装置以及计算机可读存储介质",
    "inv": [
      "卫然",
      "孙广元"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109215017A",
    "apn": "CN201810935227.3",
    "apd": "2018-08-16",
    "apy": "2018",
    "tit": "图片处理方法、装置、用户终端、服务器及存储介质",
    "inv": [
      "胡晓楠",
      "杨智"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109240689A",
    "apn": "CN201810926890.7",
    "apd": "2018-08-15",
    "apy": "2018",
    "tit": "一种应用图标的生成方法、装置、服务器及介质",
    "inv": [
      "杨瑞璇",
      "苏旎"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109034102A",
    "apn": "CN201810924096.9",
    "apd": "2018-08-14",
    "apy": "2018",
    "tit": "人脸活体检测方法、装置、设备及存储介质",
    "inv": [
      "吴双",
      "丁守鸿",
      "李季檩",
      "梁小龙",
      "梁麟开",
      "杨静"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109145901A",
    "apn": "CN201810923633.8",
    "apd": "2018-08-14",
    "apy": "2018",
    "tit": "物品识别方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "刘强",
      "戴宇荣",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109189696A",
    "apn": "CN201810911523.X",
    "apd": "2018-08-12",
    "apy": "2018",
    "tit": "一种照片分类器训练方法、SSD缓存系统及缓存方法",
    "inv": [
      "王桦",
      "周可",
      "易锌波",
      "程彬",
      "肖志立"
    ],
    "app": [
      "华中科技大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163055A",
    "apn": "CN201810911801.1",
    "apd": "2018-08-10",
    "apy": "2018",
    "tit": "手势识别方法、装置和计算机设备",
    "inv": [
      "程君",
      "汪青",
      "李峰",
      "李昊沅",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109102560A",
    "apn": "CN201810904311.9",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "三维模型渲染方法及装置",
    "inv": [
      "胡峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109190617A",
    "apn": "CN201810902268.2",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "一种图像的矩形检测方法、装置及存储介质",
    "inv": [
      "李鑫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108960209A",
    "apn": "CN201810904086.9",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "身份识别方法、装置及计算机可读存储介质",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109272457A",
    "apn": "CN201810904814.6",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "一种图像掩膜生成方法、装置及服务器",
    "inv": [
      "暴林超",
      "吴保元",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109063662A",
    "apn": "CN201810904405.6",
    "apd": "2018-08-09",
    "apy": "2018",
    "tit": "数据处理方法、装置、设备及存储介质",
    "inv": [
      "张云"
    ],
    "app": [
      "腾讯科技(成都)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109118482A",
    "apn": "CN201810888604.2",
    "apd": "2018-08-07",
    "apy": "2018",
    "tit": "一种面板缺陷分析方法、装置及存储介质",
    "inv": [
      "冀永楠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163806A",
    "apn": "CN201810887095.1",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "一种图像处理方法、装置以及存储介质",
    "inv": [
      "吴江红"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109191410A",
    "apn": "CN201810886318.2",
    "apd": "2018-08-06",
    "apy": "2018",
    "tit": "一种人脸图像融合方法、装置及存储介质",
    "inv": [
      "钱梦仁",
      "鹿镇",
      "李晓懿",
      "张悦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163054A",
    "apn": "CN201810879329.8",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "一种人脸三维图像生成方法和装置",
    "inv": [
      "沈珂轶",
      "程培",
      "周景锦",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109086720A",
    "apn": "CN201810878563.9",
    "apd": "2018-08-03",
    "apy": "2018",
    "tit": "一种人脸聚类方法、装置和存储介质",
    "inv": [
      "陈超",
      "甘振业",
      "汪铖杰",
      "沈鹏程",
      "王文全",
      "李安平",
      "李绍欣",
      "李季檩",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110209878A",
    "apn": "CN201810868629.6",
    "apd": "2018-08-02",
    "apy": "2018",
    "tit": "视频处理方法、装置、计算机可读介质及电子设备",
    "inv": [
      "冯洋",
      "马林",
      "刘威",
      "罗杰波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163053A",
    "apn": "CN201810869295.4",
    "apd": "2018-08-02",
    "apy": "2018",
    "tit": "生成人脸识别的负样本的方法、装置及计算机设备",
    "inv": [
      "罗文寒",
      "暴林超",
      "高源",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110149558A",
    "apn": "CN201810871605.6",
    "apd": "2018-08-02",
    "apy": "2018",
    "tit": "一种基于内容识别的视频播放实时推荐方法及系统",
    "inv": [
      "熊玉辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109101602A",
    "apn": "CN201810863354.7",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "图像检索模型训练方法、图像检索方法、设备及存储介质",
    "inv": [
      "琚震",
      "彭湃",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163052A",
    "apn": "CN201810861147.8",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "视频动作识别方法、装置和机器设备",
    "inv": [
      "厉扬豪",
      "宋思捷",
      "刘家瑛",
      "刘婷婷",
      "黄婷婷",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "北京大学"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108961315A",
    "apn": "CN201810867036.8",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "目标跟踪方法、装置、计算机设备和存储介质",
    "inv": [
      "赵艳丹",
      "汪铖杰",
      "曹玮剑",
      "曹赟",
      "程盼",
      "黄渊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109034078A",
    "apn": "CN201810864567.1",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "年龄识别模型的训练方法、年龄识别方法及相关设备",
    "inv": [
      "葛彦昊",
      "李季檩",
      "汪铖杰",
      "黄飞跃",
      "吴永坚",
      "黄小明",
      "梁小龙",
      "晏轶超",
      "贺珂珂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109086713B",
    "apn": "CN201810847632.X",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "眼部识别方法、装置、终端及存储介质",
    "inv": [
      "赵艳丹",
      "曹玮剑",
      "汪铖杰",
      "李季檩",
      "曹赟",
      "王亚彪",
      "葛彦昊",
      "甘振业",
      "何长伟",
      "梁亦聪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109086709A",
    "apn": "CN201810841956.2",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "特征提取模型训练方法、装置及存储介质",
    "inv": [
      "龚国平",
      "徐敘遠",
      "吴韬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109146760A",
    "apn": "CN201810823383.0",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "一种水印生成方法、装置、终端及存储介质",
    "inv": [
      "王泽一",
      "谭悦伟",
      "林莉",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109035234A",
    "apn": "CN201810826933.4",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "一种结节检测方法、装置和存储介质",
    "inv": [
      "孙星",
      "张毅",
      "蒋忻洋",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109087239A",
    "apn": "CN201810827850.7",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "一种人脸图像处理方法、装置及存储介质",
    "inv": [
      "胡凯",
      "李小奇",
      "杨悦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108961296A",
    "apn": "CN201810825633.4",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "眼底图像分割方法、装置、存储介质和计算机设备",
    "inv": [
      "蒋忻洋",
      "王子龙",
      "孙星",
      "王睿"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109145759A",
    "apn": "CN201810828675.3",
    "apd": "2018-07-25",
    "apy": "2018",
    "tit": "车辆属性识别方法、装置、服务器及存储介质",
    "inv": [
      "姚雨石",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147805A",
    "apn": "CN201810812675.4",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "图像处理方法、装置、终端及存储介质",
    "inv": [
      "熊唯",
      "黄飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108985241A",
    "apn": "CN201810814349.7",
    "apd": "2018-07-23",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机设备及存储介质",
    "inv": [
      "高杨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109086697A",
    "apn": "CN201810803085.5",
    "apd": "2018-07-20",
    "apy": "2018",
    "tit": "一种人脸数据处理方法、装置及存储介质",
    "inv": [
      "王一同",
      "季兴",
      "周正",
      "王浩",
      "李志鋒"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166795A",
    "apn": "CN201810798032.9",
    "apd": "2018-07-19",
    "apy": "2018",
    "tit": "一种视频截图方法及装置",
    "inv": [
      "张志辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108881739A",
    "apn": "CN201810790667.4",
    "apd": "2018-07-18",
    "apy": "2018",
    "tit": "图像生成方法、装置、终端及存储介质",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163942A",
    "apn": "CN201810791620.X",
    "apd": "2018-07-18",
    "apy": "2018",
    "tit": "一种图像数据处理方法和装置",
    "inv": [
      "罗爽",
      "黎静波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163049A",
    "apn": "CN201810787870.6",
    "apd": "2018-07-18",
    "apy": "2018",
    "tit": "一种人脸属性预测方法、装置及存储介质",
    "inv": [
      "贺珂珂",
      "葛彦昊",
      "邰颖",
      "汪铖杰",
      "李季檩",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109002797A",
    "apn": "CN201810778062.3",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "车辆变道检测方法、装置、存储介质和计算机设备",
    "inv": [
      "阮志强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109087335A",
    "apn": "CN201810776248.5",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "一种人脸跟踪方法、装置和存储介质",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "彭瑾龙",
      "王亚彪",
      "赵艳丹",
      "甘振业",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147702A",
    "apn": "CN201810771226.X",
    "apd": "2018-07-13",
    "apy": "2018",
    "tit": "一种实时视频的目标检测与识别方法和系统",
    "inv": [
      "程君",
      "尚海豹",
      "李峰",
      "李昊沅",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108985220A",
    "apn": "CN201810759499.2",
    "apd": "2018-07-11",
    "apy": "2018",
    "tit": "一种人脸图像处理方法、装置及存储介质",
    "inv": [
      "曹玮剑",
      "赵艳丹",
      "汪铖杰",
      "李季檩",
      "程盼",
      "黄渊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163861A",
    "apn": "CN201810755907.7",
    "apd": "2018-07-11",
    "apy": "2018",
    "tit": "图像处理方法、装置、存储介质和计算机设备",
    "inv": [
      "程君",
      "朱莹",
      "李昊沅",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108960167A",
    "apn": "CN201810758353.6",
    "apd": "2018-07-11",
    "apy": "2018",
    "tit": "发型识别方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "贺珂珂",
      "葛彦昊",
      "汪铖杰",
      "陈志博",
      "蒋楠",
      "李季檩",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109002852A",
    "apn": "CN201810758796.5",
    "apd": "2018-07-11",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109034013A",
    "apn": "CN201810750438.X",
    "apd": "2018-07-10",
    "apy": "2018",
    "tit": "一种人脸图像识别方法、装置及存储介质",
    "inv": [
      "陈志博",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163048A",
    "apn": "CN201810752953.1",
    "apd": "2018-07-10",
    "apy": "2018",
    "tit": "手部关键点的识别模型训练方法、识别方法及设备",
    "inv": [
      "易阳",
      "赵世杰",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108962355A",
    "apn": "CN201810736744.8",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "一种影像的关联显示方法、装置及服务设备、存储介质",
    "inv": [
      "王小军",
      "陈广域",
      "常佳",
      "周旋",
      "王雪晖",
      "陈飞",
      "朱邦义",
      "贾如",
      "尚晓"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109003264A",
    "apn": "CN201810732444.2",
    "apd": "2018-07-05",
    "apy": "2018",
    "tit": "一种视网膜病变图像类型识别方法、装置和存储介质",
    "inv": [
      "张毅",
      "孙星",
      "郭晓威",
      "周旋",
      "常佳"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147538A",
    "apn": "CN201810732095.4",
    "apd": "2018-07-05",
    "apy": "2018",
    "tit": "图片集描述生成方法、装置和计算机设备",
    "inv": [
      "王柏瑞",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109002846A",
    "apn": "CN201810724219.4",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "一种图像识别方法、装置和存储介质",
    "inv": [
      "孙星",
      "贾琼",
      "伍健荣",
      "彭湃",
      "郭晓威",
      "周旋",
      "常佳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108898118A",
    "apn": "CN201810721857.0",
    "apd": "2018-07-04",
    "apy": "2018",
    "tit": "一种视频数据处理方法、装置和存储介质",
    "inv": [
      "周景锦",
      "程培",
      "曾毅榕",
      "傅斌",
      "高雨",
      "朱晓龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108897541A",
    "apn": "CN201810717697.2",
    "apd": "2018-07-03",
    "apy": "2018",
    "tit": "应用程序的视觉还原方法、装置、存储介质及终端",
    "inv": [
      "陈海中"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166799A",
    "apn": "CN201810710268.2",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "直播互动方法、装置及存储介质",
    "inv": [
      "伍东方"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166684A",
    "apn": "CN201810693249.3",
    "apd": "2018-06-29",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机可读介质及电子设备",
    "inv": [
      "杨扬",
      "马文晔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108833973A",
    "apn": "CN201810690954.8",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "视频特征的提取方法、装置和计算机设备",
    "inv": [
      "熊玉辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108833818A",
    "apn": "CN201810688229.7",
    "apd": "2018-06-28",
    "apy": "2018",
    "tit": "视频录制方法、装置、终端及存储介质",
    "inv": [
      "陈春勇",
      "余墉林",
      "陈骢"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147701A",
    "apn": "CN201810678758.9",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "关键点标注方法、装置、计算机设备及存储介质",
    "inv": [
      "邱日明",
      "李峰",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108960114A",
    "apn": "CN201810681840.7",
    "apd": "2018-06-27",
    "apy": "2018",
    "tit": "人体识别方法及装置、计算机可读存储介质及电子设备",
    "inv": [
      "葛彦昊",
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "吴永坚",
      "晏轶超",
      "贺珂珂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109118470A",
    "apn": "CN201810669989.3",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "一种图像质量评价方法、装置、终端和服务器",
    "inv": [
      "陈志博",
      "石楷弘",
      "王川南",
      "蒋楠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN108920580A",
    "apn": "CN201810660654.5",
    "apd": "2018-06-25",
    "apy": "2018",
    "tit": "图像匹配方法、装置、存储介质及终端",
    "inv": [
      "付丹青",
      "徐浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108776822A",
    "apn": "CN201810650498.4",
    "apd": "2018-06-22",
    "apy": "2018",
    "tit": "目标区域检测方法、装置、终端及存储介质",
    "inv": [
      "姜媚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108898087A",
    "apn": "CN201810650499.9",
    "apd": "2018-06-22",
    "apy": "2018",
    "tit": "人脸关键点定位模型的训练方法、装置、设备及存储介质",
    "inv": [
      "姜媚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108845742A",
    "apn": "CN201810654362.0",
    "apd": "2018-06-22",
    "apy": "2018",
    "tit": "一种图像画面的获取方法和装置以及计算机可读存储介质",
    "inv": [
      "张云",
      "周俊清"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110149475A",
    "apn": "CN201810641275.1",
    "apd": "2018-06-21",
    "apy": "2018",
    "tit": "图像拍摄方法及装置、电子装置、存储介质和计算机设备",
    "inv": [
      "黎雄志"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN108898086A",
    "apn": "CN201810639495.0",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "视频图像处理方法及装置、计算机可读介质和电子设备",
    "inv": [
      "王亚彪",
      "葛彦昊",
      "甘振业",
      "黄渊",
      "邓长友",
      "赵亚峰",
      "黄飞跃",
      "吴永坚",
      "黄小明",
      "梁小龙",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108846440A",
    "apn": "CN201810639830.7",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "图像处理方法及装置、计算机可读介质和电子设备",
    "inv": [
      "王亚彪",
      "崔志鹏",
      "王文全",
      "葛彦昊",
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108960090A",
    "apn": "CN201810639496.5",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "视频图像处理方法及装置、计算机可读介质和电子设备",
    "inv": [
      "王亚彪",
      "甘振业",
      "何长伟",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108921061A",
    "apn": "CN201810638695.4",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "一种表情识别方法、装置和设备",
    "inv": [
      "贺珂珂",
      "葛彦昊",
      "汪铖杰",
      "李季檩",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108961375A",
    "apn": "CN201810638697.3",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "一种根据二维图像生成三维图像的方法及装置",
    "inv": [
      "周景锦",
      "程培",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163046A",
    "apn": "CN201810627611.7",
    "apd": "2018-06-19",
    "apy": "2018",
    "tit": "人体姿态识别方法、装置、服务器及存储介质",
    "inv": [
      "卢策吾"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "上海交通大学"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110149550A",
    "apn": "CN201810628987.X",
    "apd": "2018-06-19",
    "apy": "2018",
    "tit": "一种图像数据处理方法和装置",
    "inv": [
      "邓朔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148089A",
    "apn": "CN201810633205.1",
    "apd": "2018-06-19",
    "apy": "2018",
    "tit": "一种图像处理方法、装置及设备、计算机存储介质",
    "inv": [
      "刘日升",
      "程世超",
      "姜智颖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "大连理工大学"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163230A",
    "apn": "CN201810622626.4",
    "apd": "2018-06-15",
    "apy": "2018",
    "tit": "一种图像标注方法和装置",
    "inv": [
      "吴保元",
      "陈卫东",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108765295B",
    "apn": "CN201810598659.X",
    "apd": "2018-06-12",
    "apy": "2018",
    "tit": "图像处理方法、图像处理装置及存储介质",
    "inv": [
      "戴宇榮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108846355A",
    "apn": "CN201810594760.8",
    "apd": "2018-06-11",
    "apy": "2018",
    "tit": "图像处理方法、人脸识别方法、装置和计算机设备",
    "inv": [
      "邰颖",
      "曹赟",
      "丁守鸿",
      "李绍欣",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108805203A",
    "apn": "CN201810595450.8",
    "apd": "2018-06-11",
    "apy": "2018",
    "tit": "图像处理及对象再识别方法、装置、设备和存储介质",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163215A",
    "apn": "CN201810588686.9",
    "apd": "2018-06-08",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机可读介质及电子设备",
    "inv": [
      "陈卫东",
      "吴保元",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108805577A",
    "apn": "CN201810590189.2",
    "apd": "2018-06-08",
    "apy": "2018",
    "tit": "信息处理方法、装置、系统、计算机设备及存储介质",
    "inv": [
      "叶婷婷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110176044A",
    "apn": "CN201810590159.1",
    "apd": "2018-06-08",
    "apy": "2018",
    "tit": "信息处理方法、装置、存储介质和计算机设备",
    "inv": [
      "冯驰伟",
      "王清",
      "师凯凯",
      "王文涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163045A",
    "apn": "CN201810582795.X",
    "apd": "2018-06-07",
    "apy": "2018",
    "tit": "一种手势动作的识别方法、装置以及设备",
    "inv": [
      "赵世杰",
      "李峰",
      "左小祥",
      "程君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108804652A",
    "apn": "CN201810580155.5",
    "apd": "2018-06-07",
    "apy": "2018",
    "tit": "封面图片的生成方法、装置、存储介质和电子装置",
    "inv": [
      "刘冲"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108898068A",
    "apn": "CN201810575775.X",
    "apd": "2018-06-06",
    "apy": "2018",
    "tit": "一种人脸图像的处理方法和装置以及计算机可读存储介质",
    "inv": [
      "杨悦",
      "罗爽"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108846340A",
    "apn": "CN201810566938.8",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "人脸识别方法、装置及分类模型训练方法、装置、存储介质和计算机设备",
    "inv": [
      "李安平",
      "李绍欣",
      "陈超",
      "沈鹏程",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163804A",
    "apn": "CN201810569120.1",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "图像去雾方法、装置、计算机设备和存储介质",
    "inv": [
      "张菁芸"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163805A",
    "apn": "CN201810578769.X",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "一种图像处理方法、装置和存储介质",
    "inv": [
      "师凯凯",
      "王文涛",
      "张元昊",
      "陈雪丹",
      "顾况"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108806074A",
    "apn": "CN201810568380.7",
    "apd": "2018-06-05",
    "apy": "2018",
    "tit": "购物信息的生成方法、装置及存储介质",
    "inv": [
      "姚俊军",
      "曹涛",
      "陈亮",
      "王灏",
      "金基东",
      "吴开强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108921795A",
    "apn": "CN201810563880.1",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "一种图像融合方法、装置及存储介质",
    "inv": [
      "周景锦",
      "程培",
      "钱梦仁",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108830579A",
    "apn": "CN201810563808.9",
    "apd": "2018-06-04",
    "apy": "2018",
    "tit": "车辆的数据处理方法、系统、装置和计算机设备",
    "inv": [
      "周航"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108898579A",
    "apn": "CN201810541233.0",
    "apd": "2018-05-30",
    "apy": "2018",
    "tit": "一种图像清晰度识别方法、装置和存储介质",
    "inv": [
      "高永强",
      "谯睿智",
      "柯磊",
      "戴宇榮",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN108921022A",
    "apn": "CN201810541546.6",
    "apd": "2018-05-30",
    "apy": "2018",
    "tit": "一种人体属性识别方法、装置、设备及介质",
    "inv": [
      "杨思骞",
      "李季檩",
      "吴永坚",
      "晏轶超",
      "贺珂珂",
      "葛彦昊",
      "黄飞跃",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108762505A",
    "apn": "CN201810527046.7",
    "apd": "2018-05-29",
    "apy": "2018",
    "tit": "基于手势的虚拟对象控制方法、装置、存储介质和设备",
    "inv": [
      "李峰",
      "程君",
      "李昊沅",
      "左小祥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108875797A",
    "apn": "CN201810530916.6",
    "apd": "2018-05-29",
    "apy": "2018",
    "tit": "一种确定图像相似度的方法、相册管理方法及相关设备",
    "inv": [
      "李习华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108875594A",
    "apn": "CN201810524777.6",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "一种人脸图像的处理方法、装置以及存储介质",
    "inv": [
      "张子鋆"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166759A",
    "apn": "CN201810523698.3",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "图像的处理方法和装置、存储介质、电子装置",
    "inv": [
      "龚新宇",
      "黄浩智",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108829764A",
    "apn": "CN201810525326.4",
    "apd": "2018-05-28",
    "apy": "2018",
    "tit": "推荐信息获取方法、装置、系统、服务器及存储介质",
    "inv": [
      "李习华",
      "申瑞珉",
      "戴宇荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108805048A",
    "apn": "CN201810517987.2",
    "apd": "2018-05-25",
    "apy": "2018",
    "tit": "一种人脸识别模型的调整方法、装置和存储介质",
    "inv": [
      "沈鹏程",
      "李绍欣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163940A",
    "apn": "CN201810508663.2",
    "apd": "2018-05-24",
    "apy": "2018",
    "tit": "超声图像的显示方法和装置、存储介质及电子装置",
    "inv": [
      "王亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108764325A",
    "apn": "CN201810502263.0",
    "apd": "2018-05-23",
    "apy": "2018",
    "tit": "图像识别方法、装置、计算机设备和存储介质",
    "inv": [
      "陈炳文",
      "王翔",
      "周斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108846792A",
    "apn": "CN201810502438.8",
    "apd": "2018-05-23",
    "apy": "2018",
    "tit": "图像处理方法、装置、电子设备及计算机可读介质",
    "inv": [
      "李东晖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108717719A",
    "apn": "CN201810501832.X",
    "apd": "2018-05-23",
    "apy": "2018",
    "tit": "卡通人脸图像的生成方法、装置及计算机存储介质",
    "inv": [
      "葛彦昊",
      "邢雪源",
      "傅斌",
      "曾毅榕",
      "沈鹏程",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108805828A",
    "apn": "CN201810493278.5",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机设备和存储介质",
    "inv": [
      "邰颖",
      "丁守鸿",
      "李绍欣",
      "李安平",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110213210A",
    "apn": "CN201810494597.8",
    "apd": "2018-05-22",
    "apy": "2018",
    "tit": "一种网络操作请求方法、服务器及存储介质",
    "inv": [
      "黄引刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147700A",
    "apn": "CN201810478237.9",
    "apd": "2018-05-18",
    "apy": "2018",
    "tit": "视频分类方法、装置、存储介质以及设备",
    "inv": [
      "刘东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学技术大学先进技术研究院"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163043A",
    "apn": "CN201810482950.0",
    "apd": "2018-05-18",
    "apy": "2018",
    "tit": "面部检测方法、装置、存储介质及电子装置",
    "inv": [
      "孙祥学"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108734211A",
    "apn": "CN201810474974.1",
    "apd": "2018-05-17",
    "apy": "2018",
    "tit": "图像处理的方法和装置",
    "inv": [
      "张睿欣",
      "蒋忻洋",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108921782A",
    "apn": "CN201810475606.9",
    "apd": "2018-05-17",
    "apy": "2018",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "邰颖",
      "丁守鸿",
      "李绍欣",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108665457A",
    "apn": "CN201810467783.2",
    "apd": "2018-05-16",
    "apy": "2018",
    "tit": "图像识别方法、装置、存储介质及计算机设备",
    "inv": [
      "孙星",
      "张毅",
      "蒋忻洋",
      "郭晓威",
      "周旋",
      "常佳"
    ],
    "app": [
      "腾讯医疗健康(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110502961A",
    "apn": "CN201810476712.9",
    "apd": "2018-05-16",
    "apy": "2018",
    "tit": "一种面部图像检测方法及装置",
    "inv": [
      "陈志博",
      "王吉",
      "石楷弘",
      "黄小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210276A",
    "apn": "CN201810461812.4",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "一种移动轨迹获取方法及其设备、存储介质、终端",
    "inv": [
      "陈志博",
      "蒋楠",
      "石楷弘",
      "黄小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163834A",
    "apn": "CN201810456463.7",
    "apd": "2018-05-14",
    "apy": "2018",
    "tit": "对象识别方法和装置及存储介质",
    "inv": [
      "王睿",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108694736A",
    "apn": "CN201810453935.3",
    "apd": "2018-05-11",
    "apy": "2018",
    "tit": "图像处理方法、装置、服务器及计算机存储介质",
    "inv": [
      "李琛",
      "彭伟龙",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108579094A",
    "apn": "CN201810449670.X",
    "apd": "2018-05-11",
    "apy": "2018",
    "tit": "一种用户界面检测方法及相关装置、系统和存储介质",
    "inv": [
      "方李志",
      "赖勇辉"
    ],
    "app": [
      "深圳市腾讯网络信息技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108596277A",
    "apn": "CN201810444371.7",
    "apd": "2018-05-10",
    "apy": "2018",
    "tit": "一种车辆身份识别方法、装置和存储介质",
    "inv": [
      "彭湃",
      "张有才",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108665506A",
    "apn": "CN201810442810.0",
    "apd": "2018-05-10",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机存储介质及服务器",
    "inv": [
      "姜文浩",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110162689A",
    "apn": "CN201810442927.9",
    "apd": "2018-05-10",
    "apy": "2018",
    "tit": "信息推送方法、装置、计算机设备和存储介质",
    "inv": [
      "张伸正"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110166939A",
    "apn": "CN201810444869.3",
    "apd": "2018-05-10",
    "apy": "2018",
    "tit": "公共交通站点判断方法及装置",
    "inv": [
      "刘昆明"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110473147A",
    "apn": "CN201810438831.5",
    "apd": "2018-05-09",
    "apy": "2018",
    "tit": "一种视频去模糊方法和装置",
    "inv": [
      "张凯皓",
      "罗文寒",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108564874A",
    "apn": "CN201810427093.4",
    "apd": "2018-05-07",
    "apy": "2018",
    "tit": "地面标志提取的方法、模型训练的方法、设备及存储介质",
    "inv": [
      "舒茂"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108664914A",
    "apn": "CN201810425714.5",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "人脸检索方法、装置及服务器",
    "inv": [
      "陈志博",
      "蒋楠",
      "王川南",
      "石楷弘"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108419145A",
    "apn": "CN201810421057.7",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "一种视频摘要的生成方法和装置以及计算机可读存储介质",
    "inv": [
      "刘笑江",
      "王文",
      "王丰",
      "李俊",
      "郑萌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110413239A",
    "apn": "CN201810408980.7",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "终端设置参数调节方法、装置及存储介质",
    "inv": [
      "刘艳峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108632370A",
    "apn": "CN201810403649.6",
    "apd": "2018-04-28",
    "apy": "2018",
    "tit": "任务推送方法和装置、存储介质及电子装置",
    "inv": [
      "雷璐",
      "袁世培",
      "陈磊",
      "宋飞",
      "史景慧",
      "赵千千"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110264509A",
    "apn": "CN201810393076.3",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "确定图像捕捉设备的位姿的方法、装置及其存储介质",
    "inv": [
      "乔亮",
      "林祥凯",
      "暴林超",
      "凌永根",
      "朱峰明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108596834A",
    "apn": "CN201810392834.X",
    "apd": "2018-04-27",
    "apy": "2018",
    "tit": "图像的分辨率处理方法、图像处理装置及系统、存储介质",
    "inv": [
      "李懐哲",
      "陳凱威",
      "陳之容"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110415328A",
    "apn": "CN201810388212.X",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "三维场景图生成方法、装置和机器人",
    "inv": [
      "胡事民",
      "杨晟",
      "李北辰",
      "穆太江",
      "解洪文",
      "柴晓杰",
      "张明远",
      "刘荐",
      "陈波"
    ],
    "app": [
      "清华大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108665508A",
    "apn": "CN201810385608.9",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "一种即时定位与地图构建方法、装置及存储介质",
    "inv": [
      "陈超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210920A",
    "apn": "CN201810387772.3",
    "apd": "2018-04-26",
    "apy": "2018",
    "tit": "用餐信息的推荐方法和装置",
    "inv": [
      "杨伟俊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108629747A",
    "apn": "CN201810377408.9",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "图像增强方法、装置、电子设备及存储介质",
    "inv": [
      "王瑞星",
      "沈小勇",
      "贾佳亚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110392298A",
    "apn": "CN201810367876.8",
    "apd": "2018-04-23",
    "apy": "2018",
    "tit": "一种音量调节方法、装置、设备及介质",
    "inv": [
      "邓朔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108647587A",
    "apn": "CN201810369521.2",
    "apd": "2018-04-23",
    "apy": "2018",
    "tit": "人数统计方法、装置、终端及存储介质",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "甘振业",
      "王亚彪",
      "赵艳丹",
      "葛彦昊",
      "倪辉",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110392281A",
    "apn": "CN201810359953.5",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "视频合成方法、装置、计算机设备及存储介质",
    "inv": [
      "严怡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108597003A",
    "apn": "CN201810362149.2",
    "apd": "2018-04-20",
    "apy": "2018",
    "tit": "一种文章封面生成方法、装置、处理服务器及存储介质",
    "inv": [
      "谯睿智",
      "高永强",
      "戴宇荣",
      "沈小勇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "电子书",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108550190A",
    "apn": "CN201810355283.X",
    "apd": "2018-04-19",
    "apy": "2018",
    "tit": "增强现实数据处理方法、装置、计算机设备和存储介质",
    "inv": [
      "何金文",
      "熊唯",
      "肖欢",
      "许扬",
      "王旅波",
      "刘天成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108550101A",
    "apn": "CN201810352942.4",
    "apd": "2018-04-19",
    "apy": "2018",
    "tit": "图像处理方法、装置及存储介质",
    "inv": [
      "刘冲",
      "邹正宇",
      "明细龙",
      "蒋健",
      "李甜甜",
      "张宏业"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108564127A",
    "apn": "CN201810354082.8",
    "apd": "2018-04-19",
    "apy": "2018",
    "tit": "图像转换方法、装置、计算机设备及存储介质",
    "inv": [
      "李旻骏",
      "黄浩智",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108573527A",
    "apn": "CN201810350251.0",
    "apd": "2018-04-18",
    "apy": "2018",
    "tit": "一种表情图片生成方法及其设备、存储介质",
    "inv": [
      "李琛",
      "彭伟龙",
      "张智",
      "魏哲",
      "谢君朋",
      "张钟方",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108537881A",
    "apn": "CN201810349627.6",
    "apd": "2018-04-18",
    "apy": "2018",
    "tit": "一种人脸模型处理方法及其设备、存储介质",
    "inv": [
      "彭伟龙",
      "李琛",
      "戴宇榮",
      "沈小勇",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108564045A",
    "apn": "CN201810344721.2",
    "apd": "2018-04-17",
    "apy": "2018",
    "tit": "增强现实的数据处理方法、装置、存储介质和计算机设备",
    "inv": [
      "伍敏慧",
      "解洪文"
    ],
    "app": [
      "广州腾讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108520247A",
    "apn": "CN201810346029.3",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "对图像中的对象节点的识别方法、装置、终端及可读介质",
    "inv": [
      "李峰",
      "左小祥",
      "李昊沅",
      "程君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108594997A",
    "apn": "CN201810339747.8",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "手势骨架构建方法、装置、设备及存储介质",
    "inv": [
      "赵世杰",
      "左小祥",
      "李峰",
      "程君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108681743A",
    "apn": "CN201810338448.2",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "图像对象识别方法和装置、存储介质",
    "inv": [
      "李睿宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108491827A",
    "apn": "CN201810336903.5",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "一种车辆检测方法、装置及存储介质",
    "inv": [
      "张睿欣",
      "曾雨",
      "郭晓威",
      "孙星"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108647245A",
    "apn": "CN201810333805.6",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "多媒体资源的匹配方法、装置、存储介质及电子装置",
    "inv": [
      "徐敘遠",
      "龚国平",
      "吴韬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163042A",
    "apn": "CN201810331869.2",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "图像识别方法及装置",
    "inv": [
      "欧阳鹏",
      "赵巍胜",
      "张有光"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "北京航空航天大学"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108564087A",
    "apn": "CN201810333099.5",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "小广告的风险识别方法、装置、终端及存储介质",
    "inv": [
      "罗芳柠",
      "高威煌",
      "许振声",
      "林钊武",
      "宋平波",
      "廖唯心",
      "梁立安"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305309A",
    "apn": "CN201810329387.3",
    "apd": "2018-04-13",
    "apy": "2018",
    "tit": "基于立体动画的人脸表情生成方法和装置",
    "inv": [
      "汪林",
      "王文恺"
    ],
    "app": [
      "腾讯科技(成都)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110443232A",
    "apn": "CN201910848288.0",
    "apd": "2018-04-12",
    "apy": "2018",
    "tit": "视频处理方法及相关装置，图像处理方法及相关装置",
    "inv": [
      "张凯皓",
      "罗文寒",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110378170A",
    "apn": "CN201810327638.4",
    "apd": "2018-04-12",
    "apy": "2018",
    "tit": "视频处理方法及相关装置，图像处理方法及相关装置",
    "inv": [
      "张凯皓",
      "罗文寒",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110472622A",
    "apn": "CN201910848859.0",
    "apd": "2018-04-12",
    "apy": "2018",
    "tit": "视频处理方法及相关装置，图像处理方法及相关装置",
    "inv": [
      "张凯皓",
      "罗文寒",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108491843A",
    "apn": "CN201810325315.1",
    "apd": "2018-04-12",
    "apy": "2018",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "余三思"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110147699A",
    "apn": "CN201810325819.3",
    "apd": "2018-04-12",
    "apy": "2018",
    "tit": "一种图像识别方法、装置以及相关设备",
    "inv": [
      "刘家瑛",
      "宋思捷",
      "厉扬豪",
      "马林",
      "刘威",
      "王巨宏",
      "黄婷婷"
    ],
    "app": [
      "北京大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108549863B",
    "apn": "CN201810321191.X",
    "apd": "2018-04-11",
    "apy": "2018",
    "tit": "人体姿态预测方法、装置、设备及存储介质",
    "inv": [
      "张虹",
      "沈小勇",
      "贾佳亚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110377670A",
    "apn": "CN201810322044.4",
    "apd": "2018-04-11",
    "apy": "2018",
    "tit": "一种确定道路要素信息的方法、装置、介质及设备",
    "inv": [
      "薛涛",
      "郭勤振",
      "汤蓉"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110363210A",
    "apn": "CN201810317672.3",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "一种图像语义分割模型的训练方法和服务器",
    "inv": [
      "揭泽群"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108446669A",
    "apn": "CN201810315741.7",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "运动识别方法、装置及存储介质",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "甘振业",
      "王亚彪",
      "赵艳丹",
      "葛彦昊",
      "倪辉",
      "熊意超",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108597027A",
    "apn": "CN201810313091.2",
    "apd": "2018-04-09",
    "apy": "2018",
    "tit": "图形数据的访问方法及装置",
    "inv": [
      "张悦",
      "唐骏"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108596180A",
    "apn": "CN201810312473.3",
    "apd": "2018-04-09",
    "apy": "2018",
    "tit": "图像中的参数识别、参数识别模型的训练方法及装置",
    "inv": [
      "黄盈",
      "荆彦青"
    ],
    "app": [
      "深圳市腾讯网络信息技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108537837A",
    "apn": "CN201810301988.3",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "一种深度信息确定的方法及相关装置",
    "inv": [
      "揭泽群",
      "凌永根",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108629743A",
    "apn": "CN201810301685.1",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "图像的处理方法、装置、存储介质和电子装置",
    "inv": [
      "陶鑫",
      "高宏运",
      "沈小勇",
      "戴宇榮",
      "賈佳亞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163041A",
    "apn": "CN201810300886.X",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "视频行人再识别方法、装置及存储介质",
    "inv": [
      "邵杰",
      "欧阳德强",
      "张永辉",
      "申恒涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "电子科技大学"
    ],
    "l1": "人脸/活体识别",
    "l2": "重识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108259997B",
    "apn": "CN201810285137.4",
    "apd": "2018-04-02",
    "apy": "2018",
    "tit": "图像相关处理方法及装置、智能终端、服务器、存储介质",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108513139A",
    "apn": "CN201810284401.2",
    "apd": "2018-04-02",
    "apy": "2018",
    "tit": "视频直播中的虚拟对象识别方法、装置、存储介质和设备",
    "inv": [
      "刘龙坡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108391142B",
    "apn": "CN201810293737.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "一种视频增强的方法及相关设备",
    "inv": [
      "秦智"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108447043A",
    "apn": "CN201810296289.4",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "一种图像合成方法、设备及计算机可读介质",
    "inv": [
      "程培",
      "傅斌",
      "曾毅榕",
      "沈珂轶",
      "赵艳丹",
      "罗爽",
      "覃华峥",
      "钱梦仁",
      "周景锦",
      "黎静波",
      "李晓懿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110267079A",
    "apn": "CN201810276537.9",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "待播放视频中人脸的替换方法和装置",
    "inv": [
      "陈宇",
      "李洋",
      "孙晓雨",
      "高杨",
      "闫富国",
      "陈曦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110263909A",
    "apn": "CN201810310628.X",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "图像识别方法及装置",
    "inv": [
      "孟金涛",
      "兰海东",
      "邓民文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108764232A",
    "apn": "CN201810291786.5",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "标签位置获取方法及装置",
    "inv": [
      "李习华",
      "蔡建涛",
      "戴宇榮",
      "吴振宇",
      "施宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110335200A",
    "apn": "CN201810273751.9",
    "apd": "2018-03-29",
    "apy": "2018",
    "tit": "一种虚拟现实反畸变方法、装置以及相关设备",
    "inv": [
      "贺辉超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108600863A",
    "apn": "CN201810265191.2",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "多媒体文件处理方法和装置、存储介质及电子装置",
    "inv": [
      "秦智"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108509896A",
    "apn": "CN201810267283.4",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "一种轨迹跟踪方法、装置和存储介质",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "王亚彪",
      "赵艳丹",
      "葛彦昊",
      "倪辉",
      "熊意超",
      "甘振业",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210045A",
    "apn": "CN201810266561.4",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "目标区域的人数估算方法、装置及存储介质",
    "inv": [
      "王芃森",
      "李欣",
      "李一非",
      "卢海波"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108564646A",
    "apn": "CN201810267110.2",
    "apd": "2018-03-28",
    "apy": "2018",
    "tit": "对象的渲染方法和装置、存储介质、电子装置",
    "inv": [
      "朱锡麟",
      "曹雨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110309691A",
    "apn": "CN201810258504.1",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "一种人脸识别方法、装置、服务器及存储介质",
    "inv": [
      "谭莲芝",
      "刘诗超",
      "张肇勇",
      "潘益伟",
      "夏武"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108804996A",
    "apn": "CN201810260031.9",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "人脸验证方法、装置、计算机设备及存储介质",
    "inv": [
      "戴宇榮",
      "胡晓灵"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110197474A",
    "apn": "CN201810261926.4",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "图像处理方法和装置及神经网络模型的训练方法",
    "inv": [
      "肖芬",
      "常佳",
      "周旋",
      "颜克洲",
      "江铖",
      "田宽",
      "朱建平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108491804A",
    "apn": "CN201810258316.9",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "一种棋局展示的方法、相关装置及系统",
    "inv": [
      "卫然",
      "陆遥",
      "李东",
      "许欢欢",
      "王瑶",
      "孙广元",
      "晁阳",
      "郑滔",
      "郭晓婷",
      "赵毅",
      "庄夏敏",
      "严亦佳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108388888A",
    "apn": "CN201810243700.1",
    "apd": "2018-03-23",
    "apy": "2018",
    "tit": "一种车辆识别方法、装置和存储介质",
    "inv": [
      "彭湃",
      "张有才",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108492352A",
    "apn": "CN201810242139.5",
    "apd": "2018-03-22",
    "apy": "2018",
    "tit": "增强现实的实现方法、装置、系统、计算机设备及存储介质",
    "inv": [
      "张庆吉",
      "魏扼",
      "庞英明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108491794A",
    "apn": "CN201810239389.3",
    "apd": "2018-03-22",
    "apy": "2018",
    "tit": "面部识别的方法和装置",
    "inv": [
      "李习华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110298212A",
    "apn": "CN201810235671.4",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "模型训练方法、情绪识别方法、表情显示方法及相关设备",
    "inv": [
      "周力",
      "张小山",
      "王松旭"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110262763A",
    "apn": "CN201810235460.0",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "基于增强现实的显示方法和装置以及存储介质和电子设备",
    "inv": [
      "赵彬如",
      "何金文",
      "刘天成",
      "潘红"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108521576A",
    "apn": "CN201810218794.7",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "媒体资源的显示方法、装置、存储介质和电子装置",
    "inv": [
      "周俊清",
      "徐晓敏",
      "朱春林",
      "卢璐"
    ],
    "app": [
      "腾讯科技(成都)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110148088A",
    "apn": "CN201810212524.5",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "图像处理方法、图像去雨方法、装置、终端及介质",
    "inv": [
      "刘武",
      "马华东",
      "李雅楠",
      "刘鲲",
      "黄嘉文",
      "黄婷婷"
    ],
    "app": [
      "北京邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108388876A",
    "apn": "CN201810206459.5",
    "apd": "2018-03-13",
    "apy": "2018",
    "tit": "一种图像识别方法、装置以及相关设备",
    "inv": [
      "张凯皓",
      "罗文寒",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110309336A",
    "apn": "CN201810200560.X",
    "apd": "2018-03-12",
    "apy": "2018",
    "tit": "图像检索方法、装置、系统、服务器以及存储介质",
    "inv": [
      "黄桂洲",
      "宋翔宇",
      "贺伟",
      "郭德安",
      "江启泉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110175974A",
    "apn": "CN201810200525.8",
    "apd": "2018-03-12",
    "apy": "2018",
    "tit": "图像显著性检测方法、装置、计算机设备和存储介质",
    "inv": [
      "何盛烽",
      "张怀东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "华南理工大学"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108470159A",
    "apn": "CN201810194981.6",
    "apd": "2018-03-09",
    "apy": "2018",
    "tit": "车道线数据处理方法、装置、计算机设备和存储介质",
    "inv": [
      "舒茂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110245668A",
    "apn": "CN201810195132.2",
    "apd": "2018-03-09",
    "apy": "2018",
    "tit": "基于图像识别的终端信息获取方法、获取装置及存储介质",
    "inv": [
      "周超强",
      "曹飞",
      "凌灵",
      "何加淼",
      "黄普光",
      "余光镇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110248219A",
    "apn": "CN201810190455.2",
    "apd": "2018-03-08",
    "apy": "2018",
    "tit": "视频播放方法、装置及设备",
    "inv": [
      "周彬",
      "祁杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108364346A",
    "apn": "CN201810189748.9",
    "apd": "2018-03-08",
    "apy": "2018",
    "tit": "构建三维人脸模型的方法、装置和计算机可读存储介质",
    "inv": [
      "潘伟洲"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108446612A",
    "apn": "CN201810187535.2",
    "apd": "2018-03-07",
    "apy": "2018",
    "tit": "车辆识别方法、装置及存储介质",
    "inv": [
      "彭湃",
      "张有才",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108537226A",
    "apn": "CN201810182391.1",
    "apd": "2018-03-06",
    "apy": "2018",
    "tit": "一种车牌识别方法和装置",
    "inv": [
      "傅慧源",
      "马华东",
      "曹雨"
    ],
    "app": [
      "北京邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110163036A",
    "apn": "CN201810183491.6",
    "apd": "2018-03-06",
    "apy": "2018",
    "tit": "一种图像识别方法及装置",
    "inv": [
      "樊林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108345890A",
    "apn": "CN201810172602.3",
    "apd": "2018-03-01",
    "apy": "2018",
    "tit": "图像处理方法、装置和相关设备",
    "inv": [
      "戴宇榮",
      "范琦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108363982A",
    "apn": "CN201810172642.8",
    "apd": "2018-03-01",
    "apy": "2018",
    "tit": "确定对象数量的方法及装置",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "甘振业",
      "王亚彪",
      "赵艳丹",
      "葛彦昊",
      "倪辉",
      "李集佳",
      "李彦融",
      "黄渊",
      "吴永坚",
      "黄飞跃",
      "黄小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108470353A",
    "apn": "CN201810172742.0",
    "apd": "2018-03-01",
    "apy": "2018",
    "tit": "一种目标跟踪方法、装置和存储介质",
    "inv": [
      "何长伟",
      "汪铖杰",
      "李季檩",
      "王亚彪",
      "赵艳丹",
      "葛彦昊",
      "倪辉",
      "甘振业",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110246110A",
    "apn": "CN201810170617.6",
    "apd": "2018-03-01",
    "apy": "2018",
    "tit": "图像评估方法、装置及存储介质",
    "inv": [
      "谢奕",
      "江进",
      "徐澜",
      "程诚",
      "杨宇星",
      "杨光",
      "张韬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110211085A",
    "apn": "CN201810168825.2",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "一种图像融合质量评价方法和系统",
    "inv": [
      "胡事民",
      "朱哲",
      "卢嘉铭",
      "王敏轩",
      "张松海",
      "拉尔夫·马丁",
      "刘涵涛",
      "王巨宏",
      "郑宇飞"
    ],
    "app": [
      "清华大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN110213476A",
    "apn": "CN201810166027.6",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "图像处理方法及装置",
    "inv": [
      "李科慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110210490A",
    "apn": "CN201810167102.0",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "图像数据处理方法、装置、计算机设备和存储介质",
    "inv": [
      "李毅",
      "张伟辰",
      "王红法",
      "肖磊",
      "薛伟"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110211086A",
    "apn": "CN201810169244.0",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "图像分割方法、装置及存储介质",
    "inv": [
      "颜克洲",
      "王小军",
      "何晓旭",
      "肖芬",
      "江铖",
      "田宽",
      "朱建平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN110197102A",
    "apn": "CN201810162341.7",
    "apd": "2018-02-27",
    "apy": "2018",
    "tit": "人脸识别方法及装置",
    "inv": [
      "王浩",
      "王一同",
      "季兴",
      "周正",
      "李志鋒"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108268778A",
    "apn": "CN201810161049.3",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "数据处理方法、装置及存储介质",
    "inv": [
      "许天胜",
      "程虎",
      "彭宁",
      "王容强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108388637A",
    "apn": "CN201810159281.3",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "一种用于提供增强现实服务的方法、装置以及相关设备",
    "inv": [
      "黄蓉",
      "钟庆华",
      "刘立强",
      "廖戈语"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110197099A",
    "apn": "CN201810160154.5",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "跨年龄人脸识别及其模型训练的方法和装置",
    "inv": [
      "周正",
      "王一同",
      "季兴",
      "王浩",
      "李志鋒"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110198457A",
    "apn": "CN201810161973.1",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "视频播放方法及其设备、系统、存储介质、终端、服务器",
    "inv": [
      "刘艳峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108377422A",
    "apn": "CN201810157614.9",
    "apd": "2018-02-24",
    "apy": "2018",
    "tit": "一种多媒体内容的播放控制方法、装置及存储介质",
    "inv": [
      "蒋伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108335275A",
    "apn": "CN201810149722.1",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "图像增强方法、装置、计算设备及存储介质",
    "inv": [
      "赵永飞",
      "郭治姣",
      "汪亮",
      "翟海昌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108337532A",
    "apn": "CN201810149601.7",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "演出片段的标注方法、视频播放方法、装置及系统",
    "inv": [
      "高小猛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108346168B",
    "apn": "CN201810145637.8",
    "apd": "2018-02-12",
    "apy": "2018",
    "tit": "一种手势图像生成方法、装置及存储介质",
    "inv": [
      "赵世杰",
      "左小祥",
      "李峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108256505A",
    "apn": "CN201810147314.2",
    "apd": "2018-02-12",
    "apy": "2018",
    "tit": "图像处理方法及装置",
    "inv": [
      "陈宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304820A",
    "apn": "CN201810145321.9",
    "apd": "2018-02-12",
    "apy": "2018",
    "tit": "一种人脸检测方法、装置及终端设备",
    "inv": [
      "季兴",
      "王一同",
      "周正",
      "王浩",
      "李志鋒"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108320322A",
    "apn": "CN201810141715.7",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "动画数据处理方法、装置、计算机设备和存储介质",
    "inv": [
      "梁家斌",
      "凌飞"
    ],
    "app": [
      "腾讯科技(成都)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110149474A",
    "apn": "CN201810142617.5",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "一种图像采集方法及其装置、设备和存储介质",
    "inv": [
      "孙晓磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136198A",
    "apn": "CN201810134949.9",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "图像处理方法及其装置、设备和存储介质",
    "inv": [
      "余志伟",
      "孙子荀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110135226A",
    "apn": "CN201810136285.X",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "表情动画数据处理方法、装置、计算机设备和存储介质",
    "inv": [
      "郭艺帆",
      "刘楠",
      "薛丰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108509846A",
    "apn": "CN201810134026.3",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机设备及存储介质",
    "inv": [
      "张子鋆"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110134444A",
    "apn": "CN201810136303.4",
    "apd": "2018-02-09",
    "apy": "2018",
    "tit": "控制多媒体设备的方法、装置及多媒体设备",
    "inv": [
      "孙千柱"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108211363A",
    "apn": "CN201810130410.6",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "一种信息处理方法及装置",
    "inv": [
      "王洁梅",
      "周大军",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108345854A",
    "apn": "CN201810128166.X",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "基于图像分析的信息处理方法、装置、系统及存储介质",
    "inv": [
      "孟宾宾",
      "陈志博",
      "韦跃明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108427951A",
    "apn": "CN201810130459.1",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "图像处理方法、装置、存储介质和计算机设备",
    "inv": [
      "王睿",
      "孙星",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108236785A",
    "apn": "CN201810130417.8",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "一种获取对象信息的方法及装置",
    "inv": [
      "王洁梅",
      "张力柯",
      "荆彦青"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110517335A",
    "apn": "CN201910838616.9",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "一种动态纹理视频生成方法、装置、服务器及存储介质",
    "inv": [
      "唐永毅",
      "马林",
      "刘威"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110458919A",
    "apn": "CN201910838615.4",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "一种动态纹理视频生成方法、装置、服务器及存储介质",
    "inv": [
      "唐永毅",
      "马林",
      "刘威"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110120085A",
    "apn": "CN201810123812.3",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "一种动态纹理视频生成方法、装置、服务器及存储介质",
    "inv": [
      "唐永毅",
      "马林",
      "刘威"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108108499A",
    "apn": "CN201810121581.2",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "人脸检索方法、装置、存储介质及设备",
    "inv": [
      "王川南",
      "陈志博",
      "张杰",
      "岳文龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108307116A",
    "apn": "CN201810122474.1",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "图像拍摄方法、装置、计算机设备和存储介质",
    "inv": [
      "李科慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304882A",
    "apn": "CN201810124834.1",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "一种图像分类方法、装置及服务器、用户终端、存储介质",
    "inv": [
      "陈承",
      "冯晓冰",
      "褚攀",
      "徐浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108197326A",
    "apn": "CN201810119550.3",
    "apd": "2018-02-06",
    "apy": "2018",
    "tit": "一种车辆检索方法及装置、电子设备、存储介质",
    "inv": [
      "彭湃",
      "郭晓威",
      "张有才"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108346175A",
    "apn": "CN201810118865.6",
    "apd": "2018-02-06",
    "apy": "2018",
    "tit": "一种人脸图像修复方法、装置及存储介质",
    "inv": [
      "吴江红"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136055A",
    "apn": "CN201810107278.7",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "图像的超分辨率方法和装置、存储介质、电子装置",
    "inv": [
      "陈法圣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110136070A",
    "apn": "CN201810104756.9",
    "apd": "2018-02-02",
    "apy": "2018",
    "tit": "图像处理方法、装置、计算机可读存储介质和电子设备",
    "inv": [
      "马文晔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108307248B",
    "apn": "CN201810101152.9",
    "apd": "2018-02-01",
    "apy": "2018",
    "tit": "视频播放方法、装置、计算设备及存储介质",
    "inv": [
      "张志辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108306886A",
    "apn": "CN201810101607.7",
    "apd": "2018-02-01",
    "apy": "2018",
    "tit": "一种身份验证方法、装置及存储介质",
    "inv": [
      "贾祎文"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110099237A",
    "apn": "CN201810093720.5",
    "apd": "2018-01-31",
    "apy": "2018",
    "tit": "图像处理方法、电子装置及计算机可读存储介质",
    "inv": [
      "郭勤振",
      "刘龙",
      "薛涛",
      "张红卫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110097583A",
    "apn": "CN201810095953.9",
    "apd": "2018-01-31",
    "apy": "2018",
    "tit": "图片筛选方法及装置",
    "inv": [
      "宫鲁津"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110096938A",
    "apn": "CN201810098321.8",
    "apd": "2018-01-31",
    "apy": "2018",
    "tit": "一种视频中的动作行为的处理方法和装置",
    "inv": [
      "袁园",
      "马林",
      "揭泽群",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110110145A",
    "apn": "CN201810082485.1",
    "apd": "2018-01-29",
    "apy": "2018",
    "tit": "描述文本生成方法及装置",
    "inv": [
      "杨小汕",
      "徐常胜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院自动化研究所"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108319907A",
    "apn": "CN201810079512.X",
    "apd": "2018-01-26",
    "apy": "2018",
    "tit": "一种车辆识别方法、装置和存储介质",
    "inv": [
      "姚雨石",
      "陶冶",
      "胡易",
      "张睿欣",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108259990A",
    "apn": "CN201810079259.8",
    "apd": "2018-01-26",
    "apy": "2018",
    "tit": "一种视频剪辑的方法及装置",
    "inv": [
      "李俊",
      "王丰",
      "刘笑江",
      "王文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108255304A",
    "apn": "CN201810079019.8",
    "apd": "2018-01-26",
    "apy": "2018",
    "tit": "基于增强现实的视频数据处理方法、装置和存储介质",
    "inv": [
      "邬振海"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304841A",
    "apn": "CN201810076796.7",
    "apd": "2018-01-26",
    "apy": "2018",
    "tit": "乳头定位方法、装置及存储介质",
    "inv": [
      "江铖",
      "颜克洲",
      "肖芬",
      "田宽",
      "朱建平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110072142A",
    "apn": "CN201810072504.2",
    "apd": "2018-01-24",
    "apy": "2018",
    "tit": "视频描述生成方法、装置、视频播放方法、装置和存储介质",
    "inv": [
      "王景文",
      "姜文浩",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108364335A",
    "apn": "CN201810064592.1",
    "apd": "2018-01-23",
    "apy": "2018",
    "tit": "一种动画绘制方法及装置",
    "inv": [
      "罗焱",
      "张冠君"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110068328A",
    "apn": "CN201810060968.1",
    "apd": "2018-01-22",
    "apy": "2018",
    "tit": "导航路线生成方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "赵娜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304790A",
    "apn": "CN201810055213.2",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "骨骼运动预测处理方法、装置和肢体运动预测处理方法",
    "inv": [
      "唐永毅",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110060210A",
    "apn": "CN201810054848.0",
    "apd": "2018-01-19",
    "apy": "2018",
    "tit": "图像处理方法及相关装置",
    "inv": [
      "任文琦",
      "马林",
      "刘威",
      "操晓春"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110062269A",
    "apn": "CN201810050497.6",
    "apd": "2018-01-18",
    "apy": "2018",
    "tit": "附加对象显示方法、装置及计算机设备",
    "inv": [
      "肖仙敏",
      "张中宝",
      "蒋辉",
      "王文涛",
      "肖鹏",
      "黎雄志",
      "张元昊",
      "林锋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108154031A",
    "apn": "CN201810045938.3",
    "apd": "2018-01-17",
    "apy": "2018",
    "tit": "伪装应用程序的识别方法、装置、存储介质和电子装置",
    "inv": [
      "赵亚鑫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110058887A",
    "apn": "CN201810040523.7",
    "apd": "2018-01-16",
    "apy": "2018",
    "tit": "视频处理方法、装置、计算机可读存储介质和计算机设备",
    "inv": [
      "卢子填",
      "丁海峰",
      "陈泽滨",
      "戴月"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305236A",
    "apn": "CN201810038866.X",
    "apd": "2018-01-16",
    "apy": "2018",
    "tit": "图像增强处理方法及装置",
    "inv": [
      "任文琦",
      "马林",
      "刘威",
      "操晓春"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108288032A",
    "apn": "CN201810015245.X",
    "apd": "2018-01-08",
    "apy": "2018",
    "tit": "动作特征获取方法、装置及存储介质",
    "inv": [
      "舒祥波",
      "严锐",
      "马林",
      "唐金辉"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109992237A",
    "apn": "CN201810005208.0",
    "apd": "2018-01-03",
    "apy": "2018",
    "tit": "智能语音设备控制方法、装置、计算机设备和存储介质",
    "inv": [
      "梁栋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108182714A",
    "apn": "CN201810001014.3",
    "apd": "2018-01-02",
    "apy": "2018",
    "tit": "图像处理方法及装置、存储介质",
    "inv": [
      "邬振海"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108288248A",
    "apn": "CN201810002386.8",
    "apd": "2018-01-02",
    "apy": "2018",
    "tit": "一种眼部图像融合方法及其设备、存储介质、终端",
    "inv": [
      "李小奇",
      "程培",
      "杨悦",
      "傅斌",
      "蒋兴华"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108090463A",
    "apn": "CN201711483962.7",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "对象控制方法、装置、存储介质和计算机设备",
    "inv": [
      "徐冬成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305214B",
    "apn": "CN201711466702.9",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "图像数据处理方法、装置、存储介质和计算机设备",
    "inv": [
      "黄晓政",
      "陈家君",
      "戴宇榮",
      "陶鑫",
      "丁飘",
      "王诗涛",
      "罗斌姬",
      "吴祖榕"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107911614B",
    "apn": "CN201711422385.0",
    "apd": "2017-12-25",
    "apy": "2017",
    "tit": "一种基于手势的图像拍摄方法、装置和存储介质",
    "inv": [
      "汪倩怡",
      "王志斌"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109949390A",
    "apn": "CN201711391032.9",
    "apd": "2017-12-21",
    "apy": "2017",
    "tit": "图像生成方法、动态表情图像生成方法及装置",
    "inv": [
      "梁睿思",
      "李强",
      "张雨涵",
      "刘晓峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108334540A",
    "apn": "CN201711352237.6",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "媒体信息的展示方法和装置、存储介质、电子装置",
    "inv": [
      "何静",
      "邹子馨"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109960962A",
    "apn": "CN201711338566.5",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "图像识别方法、装置、电子设备以及可读存储介质",
    "inv": [
      "朱晓龙",
      "黄凯宁",
      "罗镜民",
      "梅利健",
      "黄生辉",
      "郑永森",
      "王一同",
      "黄浩智"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109918975A",
    "apn": "CN201711329984.8",
    "apd": "2017-12-13",
    "apy": "2017",
    "tit": "一种增强现实的处理方法、对象识别的方法及终端",
    "inv": [
      "朱晓龙",
      "王一同",
      "黄凯宁",
      "梅利健",
      "黄生辉",
      "罗镜民"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109919846A",
    "apn": "CN201711318233.6",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "一种图像增强方法、装置及计算设备",
    "inv": [
      "周景锦",
      "程培",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108090508A",
    "apn": "CN201711322612.2",
    "apd": "2017-12-12",
    "apy": "2017",
    "tit": "一种分类训练方法、装置及存储介质",
    "inv": [
      "黄飞",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109903291A",
    "apn": "CN201711307381.8",
    "apd": "2017-12-11",
    "apy": "2017",
    "tit": "图像处理方法及相关装置",
    "inv": [
      "朱晓龙",
      "黄凯宁",
      "罗镜民",
      "梅利健",
      "黄生辉",
      "郑永森",
      "王一同",
      "黄浩智"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108073898A",
    "apn": "CN201711295898.X",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "人头区域识别方法、装置及设备",
    "inv": [
      "王吉",
      "陈志博",
      "许昀璐",
      "严冰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109905590A",
    "apn": "CN201711294694.4",
    "apd": "2017-12-08",
    "apy": "2017",
    "tit": "一种视频图像处理方法及装置",
    "inv": [
      "朱晓龙",
      "王一同",
      "黄凯宁",
      "梅利健",
      "黄生辉",
      "罗镜民"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108335329A",
    "apn": "CN201711279436.9",
    "apd": "2017-12-06",
    "apy": "2017",
    "tit": "应用于飞行器中的位置检测方法和装置、飞行器",
    "inv": [
      "王洁梅",
      "荆彦青"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109872530A",
    "apn": "CN201711268959.3",
    "apd": "2017-12-05",
    "apy": "2017",
    "tit": "一种路况信息的生成方法、车载终端及服务器",
    "inv": [
      "刘日佳",
      "刘志斌",
      "郑博"
    ],
    "app": [
      "广州腾讯科技有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107948733A",
    "apn": "CN201711262429.8",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "视频图像处理方法及装置、电子设备",
    "inv": [
      "梁宇轩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109874021A",
    "apn": "CN201711260540.3",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "直播互动方法、装置及系统",
    "inv": [
      "黄小凤",
      "陈阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109871115A",
    "apn": "CN201711262366.6",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "多媒体交互中的控制方法、装置和计算机可读存储介质",
    "inv": [
      "黄小凤",
      "冯智超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109874047A",
    "apn": "CN201711260493.2",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "直播互动方法、装置及系统",
    "inv": [
      "陈春勇",
      "彭放",
      "冯智超",
      "曹超利",
      "余墉林",
      "李安琪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108021881A",
    "apn": "CN201711249162.9",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "一种肤色分割方法、装置和存储介质",
    "inv": [
      "金晓东"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109874043A",
    "apn": "CN201711250078.9",
    "apd": "2017-12-01",
    "apy": "2017",
    "tit": "视频流发送方法、播放方法及装置",
    "inv": [
      "林渊灿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304847A",
    "apn": "CN201711244572.4",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "图像分类方法及装置、个性化推荐方法及装置",
    "inv": [
      "顾佳伟",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110349156A",
    "apn": "CN201910697322.9",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "眼底图片中病变特征的识别方法和装置、存储介质",
    "inv": [
      "彭湃",
      "蒋忻洋",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107993191A",
    "apn": "CN201711243948.X",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "李科慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109858505A",
    "apn": "CN201711244226.6",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "分类识别方法、装置及设备",
    "inv": [
      "黄悦",
      "郑瀚",
      "陈云舒",
      "袁坤",
      "刘婷婷",
      "黄婷婷"
    ],
    "app": [
      "厦门大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108022286B",
    "apn": "CN201711244459.6",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "画面渲染方法、装置及存储介质",
    "inv": [
      "邓君",
      "杨国谦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109858215A",
    "apn": "CN201711243183.X",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "资源获取、分享、处理方法、装置、存储介质和设备",
    "inv": [
      "谢英媛",
      "赵松",
      "汪大伟",
      "蓝中第",
      "管静娴",
      "王坤鹏",
      "吴婷",
      "王子健",
      "伍家灶",
      "高舜东",
      "王雷",
      "孙华昱",
      "胡文灿",
      "謝方鈞",
      "王超",
      "李思华",
      "余立",
      "梁汉熙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108230296A",
    "apn": "CN201711244421.9",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "图像特征的识别方法和装置、存储介质、电子装置",
    "inv": [
      "彭湃",
      "蒋忻洋",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304762A",
    "apn": "CN201711240868.9",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "一种人体姿态匹配方法及其设备、存储介质、终端",
    "inv": [
      "张中宝",
      "谭君",
      "丁寻",
      "周奕",
      "伍学平",
      "向华",
      "汪雨秋",
      "何永辉",
      "翁锦深",
      "刘立",
      "苏凌枫",
      "林友荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108176048A",
    "apn": "CN201711240798.7",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "图像的处理方法和装置、存储介质、电子装置",
    "inv": [
      "冯宇",
      "吴彬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107948743A",
    "apn": "CN201711225111.2",
    "apd": "2017-11-29",
    "apy": "2017",
    "tit": "视频推送方法及其装置、存储介质",
    "inv": [
      "郜光耀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305256B",
    "apn": "CN201711218897.5",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "视频抠像处理方法、处理装置及计算机可读存储介质",
    "inv": [
      "陈贻东",
      "汪大伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107844781A",
    "apn": "CN201711214467.6",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "人脸属性识别方法及装置、电子设备及存储介质",
    "inv": [
      "葛彦昊",
      "李季檩",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107895369A",
    "apn": "CN201711212049.3",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "图像分类方法、装置、存储介质及设备",
    "inv": [
      "孙星",
      "曹鸿吉",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109840947A",
    "apn": "CN201711216327.2",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "增强现实场景的实现方法、装置、设备及存储介质",
    "inv": [
      "郑博",
      "刘日佳",
      "刘志斌",
      "陈谦"
    ],
    "app": [
      "广州腾讯科技有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107833219A",
    "apn": "CN201711212620.1",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "图像识别方法及装置",
    "inv": [
      "孙星",
      "刘诗昆",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107911737A",
    "apn": "CN201711213271.5",
    "apd": "2017-11-28",
    "apy": "2017",
    "tit": "媒体内容的展示方法、装置、计算设备及存储介质",
    "inv": [
      "董霙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107948627B",
    "apn": "CN201711205462.7",
    "apd": "2017-11-27",
    "apy": "2017",
    "tit": "视频播放方法、装置、计算设备及存储介质",
    "inv": [
      "邓朔",
      "李大龙",
      "左洪涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305273B",
    "apn": "CN201711206483.0",
    "apd": "2017-11-27",
    "apy": "2017",
    "tit": "一种对象检测方法、装置及存储介质",
    "inv": [
      "陈超",
      "吴伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109842542A",
    "apn": "CN201711206081.0",
    "apd": "2017-11-27",
    "apy": "2017",
    "tit": "即时会话方法及装置、电子设备、存储介质",
    "inv": [
      "宋睿",
      "钟庆华",
      "卢锟",
      "颜玮",
      "黄蓉",
      "戴维"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108043027A",
    "apn": "CN201711208105.6",
    "apd": "2017-11-27",
    "apy": "2017",
    "tit": "存储介质、电子装置、游戏画面的显示方法和装置",
    "inv": [
      "蔡广益",
      "王晗"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107945098A",
    "apn": "CN201711191814.8",
    "apd": "2017-11-24",
    "apy": "2017",
    "tit": "图像处理方法、装置、计算机设备和存储介质",
    "inv": [
      "戴宇榮",
      "高立钊",
      "付强",
      "陈芳民",
      "姚达",
      "田恒锋",
      "谢渝彬",
      "周刘纪",
      "王涛",
      "黄俊洪",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "腾讯",
    "pun": "CN108024071A",
    "apn": "CN201711190271.8",
    "apd": "2017-11-24",
    "apy": "2017",
    "tit": "视频内容生成方法、视频内容生成装置及存储介质",
    "inv": [
      "汪倩怡",
      "王志斌"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108022301A",
    "apn": "CN201711184883.6",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "一种图像处理方法、装置以及存储介质",
    "inv": [
      "魏嘉",
      "沙鹰",
      "张哲川",
      "张宏",
      "潘晓",
      "范传康",
      "范晓玮"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109829456A",
    "apn": "CN201711180320.X",
    "apd": "2017-11-23",
    "apy": "2017",
    "tit": "图像识别方法、装置及终端",
    "inv": [
      "李峰",
      "左小祥",
      "陈家君",
      "李昊沅",
      "曾维亿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109816718A",
    "apn": "CN201711174120.3",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "一种播放提示的方法、装置及存储介质",
    "inv": [
      "江宁",
      "李胤愷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109819342A",
    "apn": "CN201711176605.6",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "弹幕内容控制方法、装置、计算机设备和存储介质",
    "inv": [
      "陈姿",
      "孔凡阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频中植入信息",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109814704A",
    "apn": "CN201711177139.3",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "一种视频数据处理方法和装置",
    "inv": [
      "卢鹏飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109815776A",
    "apn": "CN201711180258.4",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "动作提示方法和装置、存储介质及电子装置",
    "inv": [
      "卢鹏飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107993216A",
    "apn": "CN201711173149.X",
    "apd": "2017-11-22",
    "apy": "2017",
    "tit": "一种图像融合方法及其设备、存储介质、终端",
    "inv": [
      "沈珂轶",
      "程培",
      "钱梦仁",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107818313B",
    "apn": "CN201711159039.8",
    "apd": "2017-11-20",
    "apy": "2017",
    "tit": "活体识别方法、装置和存储介质",
    "inv": [
      "吴双",
      "丁守鸿",
      "梁亦聪",
      "刘尧",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107944380A",
    "apn": "CN201711159539.1",
    "apd": "2017-11-20",
    "apy": "2017",
    "tit": "身份识别方法、装置及存储设备",
    "inv": [
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107808149A",
    "apn": "CN201711148022.2",
    "apd": "2017-11-17",
    "apy": "2017",
    "tit": "一种人脸信息标注方法、装置和存储介质",
    "inv": [
      "张亿皓",
      "李绍欣",
      "陈志博",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109799899A",
    "apn": "CN201711142437.9",
    "apd": "2017-11-17",
    "apy": "2017",
    "tit": "交互控制方法、装置、存储介质和计算机设备",
    "inv": [
      "周扬",
      "王金桂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108319633A",
    "apn": "CN201711148477.4",
    "apd": "2017-11-17",
    "apy": "2017",
    "tit": "一种图像处理方法、装置及服务器、系统、存储介质",
    "inv": [
      "王飞",
      "陈承"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109803163A",
    "apn": "CN201711137094.7",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "图像展示方法及其装置、存储介质",
    "inv": [
      "左洪涛",
      "夏海雄"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107798354A",
    "apn": "CN201711138418.9",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "一种基于人脸图像的图片聚类方法、装置及存储设备",
    "inv": [
      "汪铖杰",
      "李季檩",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110019608A",
    "apn": "CN201711137207.3",
    "apd": "2017-11-16",
    "apy": "2017",
    "tit": "一种信息采集方法、装置和系统及存储设备",
    "inv": [
      "张红卫",
      "薛涛",
      "刘龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107832714A",
    "apn": "CN201711120710.8",
    "apd": "2017-11-14",
    "apy": "2017",
    "tit": "活体鉴别方法、装置及存储设备",
    "inv": [
      "倪辉"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107909104A",
    "apn": "CN201711117174.6",
    "apd": "2017-11-13",
    "apy": "2017",
    "tit": "一种图片的人脸聚类方法、装置及存储介质",
    "inv": [
      "汪铖杰",
      "李季檩",
      "丁守鸿",
      "李绍欣",
      "史淼晶",
      "王亚彪",
      "赵艳丹",
      "葛彦昊",
      "倪辉",
      "吴永坚",
      "黄飞跃",
      "程盼",
      "梁小龙",
      "黄小明"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109788227A",
    "apn": "CN201711116895.5",
    "apd": "2017-11-13",
    "apy": "2017",
    "tit": "一种图像处理方法及其装置、设备和存储介质",
    "inv": [
      "赵娜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109785368A",
    "apn": "CN201711116929.0",
    "apd": "2017-11-13",
    "apy": "2017",
    "tit": "一种目标跟踪方法和装置",
    "inv": [
      "王珏",
      "黄梁华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109753207A",
    "apn": "CN201711066456.8",
    "apd": "2017-11-02",
    "apy": "2017",
    "tit": "一种信息处理方法及其装置、存储介质",
    "inv": [
      "郜光耀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107750015B",
    "apn": "CN201711062624.6",
    "apd": "2017-11-02",
    "apy": "2017",
    "tit": "视频版权的检测方法、装置、存储介质及设备",
    "inv": [
      "徐敘遠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN110399929A",
    "apn": "CN201910689712.1",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "眼底图像分类方法、装置以及计算机可读存储介质",
    "inv": [
      "彭湃",
      "吴凯琳",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109753978A",
    "apn": "CN201711060208.2",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "图像分类方法、装置以及计算机可读存储介质",
    "inv": [
      "彭湃",
      "吴凯琳",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107679525A",
    "apn": "CN201711060265.0",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "图像分类方法、装置及计算机可读存储介质",
    "inv": [
      "彭湃",
      "郭晓威",
      "吴凯琳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110348428A",
    "apn": "CN201910690067.5",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "眼底图像分类方法、装置及计算机可读存储介质",
    "inv": [
      "彭湃",
      "郭晓威",
      "吴凯琳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109947991A",
    "apn": "CN201711050676.1",
    "apd": "2017-10-31",
    "apy": "2017",
    "tit": "一种关键帧提取方法、装置和存储介质",
    "inv": [
      "董霙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109729365A",
    "apn": "CN201711021898.0",
    "apd": "2017-10-27",
    "apy": "2017",
    "tit": "一种视频处理方法、装置及智能终端、存储介质",
    "inv": [
      "周扬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109711422A",
    "apn": "CN201711015902.2",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "图像数据处理、模型的建立方法、装置、计算机设备和存储介质",
    "inv": [
      "刘武",
      "马华东",
      "高文慧",
      "黄婷婷"
    ],
    "app": [
      "北京邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107992794B",
    "apn": "CN201711012244.1",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "一种活体检测方法、装置和存储介质",
    "inv": [
      "刘尧",
      "李季檩",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109725956A",
    "apn": "CN201711013575.7",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "一种场景渲染的方法以及相关装置",
    "inv": [
      "贺辉超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110249626A",
    "apn": "CN201780055279.5",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "增强现实图像的实现方法、装置、终端设备和存储介质",
    "inv": [
      "王志斌",
      "覃华峥",
      "汪倩怡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107886112A",
    "apn": "CN201711014564.0",
    "apd": "2017-10-26",
    "apy": "2017",
    "tit": "一种对象聚类方法、装置及存储设备",
    "inv": [
      "黄安埠"
    ],
    "app": [
      "腾讯音乐娱乐科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109711228A",
    "apn": "CN201711008168.7",
    "apd": "2017-10-25",
    "apy": "2017",
    "tit": "一种实现图像识别的图像处理方法及装置、电子设备",
    "inv": [
      "阮航"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304104A",
    "apn": "CN201711000155.5",
    "apd": "2017-10-24",
    "apy": "2017",
    "tit": "一种数据获取方法及其设备、存储介质、终端",
    "inv": [
      "龙振海",
      "张锦建",
      "邓衍",
      "罗俊",
      "谢建平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "APP图标",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110069651A",
    "apn": "CN201710991271.1",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "图片筛选方法及装置、存储介质",
    "inv": [
      "李会珠",
      "卫磊",
      "花贵春",
      "姜国华"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109698952A",
    "apn": "CN201710997898.8",
    "apd": "2017-10-23",
    "apy": "2017",
    "tit": "全景视频图像的播放方法、装置、存储介质及电子装置",
    "inv": [
      "曾新海",
      "涂远东",
      "管坤"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109698949A",
    "apn": "CN201710982001.4",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "基于虚拟现实场景的视频处理方法、装置和系统",
    "inv": [
      "曾新海",
      "涂远东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109697698A",
    "apn": "CN201710982754.5",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "低照度增强处理方法、装置和计算机可读存储介质",
    "inv": [
      "李凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109696954A",
    "apn": "CN201710987005.1",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "视线追踪方法、装置、设备和存储介质",
    "inv": [
      "高林",
      "刘婷婷",
      "袁坤",
      "黄婷婷"
    ],
    "app": [
      "中国科学院计算技术研究所",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109670385A",
    "apn": "CN201710959429.7",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "一种应用程序中表情更新的方法及装置",
    "inv": [
      "汪俊明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109947972A",
    "apn": "CN201710939863.9",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "缩略图生成方法及装置、电子设备、存储介质",
    "inv": [
      "陈翔",
      "潘杰茂",
      "陈挺",
      "唐斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109947967A",
    "apn": "CN201710936280.0",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "图像识别方法、装置、存储介质和计算机设备",
    "inv": [
      "樊林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109660854A",
    "apn": "CN201710936861.4",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "视频推荐方法、装置、设备和存储介质",
    "inv": [
      "陈姿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109658495A",
    "apn": "CN201710934684.6",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "环境光遮蔽效果的渲染方法、装置及电子设备",
    "inv": [
      "李传志"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110019919A",
    "apn": "CN201710939775.9",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "一种押韵歌词的生成方法和装置",
    "inv": [
      "邹子馨",
      "王楠",
      "朱晓龙",
      "张友谊",
      "林少彬",
      "郑永森",
      "李廣之",
      "康世胤",
      "陀得意",
      "何静",
      "陈在真"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109599079A",
    "apn": "CN201710920219.7",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "一种音乐的生成方法和装置",
    "inv": [
      "王楠",
      "刘威",
      "马林",
      "姜文浩",
      "李廣之",
      "康世胤",
      "陀得意",
      "朱晓龙",
      "张友谊",
      "林少彬",
      "郑永森",
      "邹子馨",
      "何静",
      "陈在真",
      "李品逸"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109587390A",
    "apn": "CN201710940337.4",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "画面渲染方法、画面渲染装置及存储介质",
    "inv": [
      "邢雪源"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107704819A",
    "apn": "CN201710901427.2",
    "apd": "2017-09-28",
    "apy": "2017",
    "tit": "一种动作识别方法、系统及终端设备",
    "inv": [
      "李懿",
      "程俊",
      "姬晓鹏",
      "方璡"
    ],
    "app": [
      "中国科学院深圳先进技术研究院",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109561333A",
    "apn": "CN201710890314.7",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "视频播放方法、装置、存储介质和计算机设备",
    "inv": [
      "艾立超",
      "颜正浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109559344A",
    "apn": "CN201710882948.8",
    "apd": "2017-09-26",
    "apy": "2017",
    "tit": "边框检测方法、装置及存储介质",
    "inv": [
      "孙星",
      "王睿",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109544668A",
    "apn": "CN201710860925.7",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "纹理坐标处理方法、终端设备及计算机可读存储介质",
    "inv": [
      "李传志"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109547849A",
    "apn": "CN201710861461.1",
    "apd": "2017-09-21",
    "apy": "2017",
    "tit": "一种视频信息播放方法、装置、终端和计算机存储介质",
    "inv": [
      "蒋权臻",
      "王贤骏",
      "汪坤先",
      "祁杰",
      "张宓"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109508595A",
    "apn": "CN201710844628.3",
    "apd": "2017-09-15",
    "apy": "2017",
    "tit": "一种图像识别的方法及相关设备",
    "inv": [
      "郑永森",
      "朱晓龙",
      "彭翔",
      "陈晓畅"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109508584A",
    "apn": "CN201710833668.8",
    "apd": "2017-09-15",
    "apy": "2017",
    "tit": "视频分类的方法、信息处理的方法以及服务器",
    "inv": [
      "唐永毅",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109493376A",
    "apn": "CN201710826678.9",
    "apd": "2017-09-13",
    "apy": "2017",
    "tit": "图像处理方法和装置、存储介质及电子装置",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109509243A",
    "apn": "CN201710823492.8",
    "apd": "2017-09-13",
    "apy": "2017",
    "tit": "一种液体仿真方法、液体交互方法及装置",
    "inv": [
      "张天翔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108284833B",
    "apn": "CN201710818341.3",
    "apd": "2017-09-12",
    "apy": "2017",
    "tit": "对车辆进行驾驶控制的方法和装置",
    "inv": [
      "王斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490213A",
    "apn": "CN201910848729.7",
    "apd": "2017-09-11",
    "apy": "2017",
    "tit": "图像识别方法、装置及存储介质",
    "inv": [
      "姜文浩",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304846A",
    "apn": "CN201710814187.2",
    "apd": "2017-09-11",
    "apy": "2017",
    "tit": "图像识别方法、装置及存储介质",
    "inv": [
      "姜文浩",
      "马林",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304435A",
    "apn": "CN201710806375.0",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "信息推荐方法、装置、计算机设备及存储介质",
    "inv": [
      "石智灵",
      "林立"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107481327B",
    "apn": "CN201710804532.4",
    "apd": "2017-09-08",
    "apy": "2017",
    "tit": "关于增强现实场景的处理方法、装置、终端设备及系统",
    "inv": [
      "付丹青",
      "徐浩",
      "刘承全",
      "邹成卓",
      "卢霆",
      "项小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107610108B",
    "apn": "CN201710787492.7",
    "apd": "2017-09-04",
    "apy": "2017",
    "tit": "图像处理方法和装置",
    "inv": [
      "项小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107590453B",
    "apn": "CN201710787487.6",
    "apd": "2017-09-04",
    "apy": "2017",
    "tit": "增强现实场景的处理方法、装置及设备、计算机存储介质",
    "inv": [
      "项小明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107633526A",
    "apn": "CN201710788265.6",
    "apd": "2017-09-04",
    "apy": "2017",
    "tit": "一种图像跟踪点获取方法及设备、存储介质",
    "inv": [
      "项小明",
      "徐浩",
      "卢霆",
      "邹成卓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109420338A",
    "apn": "CN201710770774.6",
    "apd": "2017-08-31",
    "apy": "2017",
    "tit": "模拟镜头移动的虚拟场景显示方法及装置、电子设备",
    "inv": [
      "袁佳平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305296A",
    "apn": "CN201710763735.3",
    "apd": "2017-08-30",
    "apy": "2017",
    "tit": "图像描述生成方法、模型训练方法、设备和存储介质",
    "inv": [
      "姜文浩",
      "马林",
      "刘威"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109426831A",
    "apn": "CN201710761108.6",
    "apd": "2017-08-30",
    "apy": "2017",
    "tit": "图片相似匹配及模型训练的方法、装置及计算机设备",
    "inv": [
      "唐亚腾",
      "王志平",
      "潘树燊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109429002A",
    "apn": "CN201710750479.4",
    "apd": "2017-08-28",
    "apy": "2017",
    "tit": "一种拍摄人像的方法及装置",
    "inv": [
      "程俊",
      "张丰",
      "王鹏",
      "姬晓鹏",
      "方璡"
    ],
    "app": [
      "中国科学院深圳先进技术研究院",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109428804A",
    "apn": "CN201710752249.1",
    "apd": "2017-08-28",
    "apy": "2017",
    "tit": "一种账号管理方法及装置",
    "inv": [
      "黄珊珊",
      "贺啸"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110019580A",
    "apn": "CN201710740562.3",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "地图显示方法、装置、存储介质及终端",
    "inv": [
      "曾超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109844734A",
    "apn": "CN201780049954.3",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "一种图片文件管理的方法及终端、计算机存储介质",
    "inv": [
      "郑妤妤",
      "张伟男"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN110019899A",
    "apn": "CN201710743372.7",
    "apd": "2017-08-25",
    "apy": "2017",
    "tit": "一种目标对象识别方法、装置、终端及存储介质",
    "inv": [
      "李龙彬",
      "吴正山"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109952757A",
    "apn": "CN201780054182.2",
    "apd": "2017-08-24",
    "apy": "2017",
    "tit": "基于虚拟现实应用录制视频的方法、终端设备及存储介质",
    "inv": [
      "陈阳",
      "黄雨川",
      "沈晓斌",
      "麦伟强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109791703A",
    "apn": "CN201780057976.4",
    "apd": "2017-08-22",
    "apy": "2017",
    "tit": "基于二维媒体内容生成三维用户体验",
    "inv": [
      "吴歆婉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107516099A",
    "apn": "CN201710719860.4",
    "apd": "2017-08-21",
    "apy": "2017",
    "tit": "一种标记图片检测的方法、装置及计算机可读存储介质",
    "inv": [
      "贾琼",
      "赵凌",
      "孙星",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107516327A",
    "apn": "CN201710719545.1",
    "apd": "2017-08-21",
    "apy": "2017",
    "tit": "基于多层滤波确定相机姿态矩阵的方法及装置、设备",
    "inv": [
      "赵凌",
      "孙星",
      "贾琼",
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(上海)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107545049A",
    "apn": "CN201710713380.7",
    "apd": "2017-08-18",
    "apy": "2017",
    "tit": "图片处理方法及相关产品",
    "inv": [
      "宋翔宇",
      "贺伟",
      "郭德安",
      "黄桂洲",
      "江启泉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108460065A",
    "apn": "CN201710707329.5",
    "apd": "2017-08-17",
    "apy": "2017",
    "tit": "照片清理方法、装置和终端设备",
    "inv": [
      "刘灿尧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN107358007B",
    "apn": "CN201710692424.2",
    "apd": "2017-08-14",
    "apy": "2017",
    "tit": "控制智能家居系统的方法、装置和计算可读存储介质",
    "inv": [
      "梁宇轩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108288208A",
    "apn": "CN201710685418.4",
    "apd": "2017-08-11",
    "apy": "2017",
    "tit": "基于图像内容的展示对象确定方法、装置、介质及设备",
    "inv": [
      "欧彦麟",
      "芦清林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107545277A",
    "apn": "CN201710687385.7",
    "apd": "2017-08-11",
    "apy": "2017",
    "tit": "模型训练、身份验证方法、装置、存储介质和计算机设备",
    "inv": [
      "李绍欣",
      "邰颖",
      "梁亦聪",
      "丁守鸿",
      "李安平",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305261A",
    "apn": "CN201710686692.3",
    "apd": "2017-08-11",
    "apy": "2017",
    "tit": "图片分割方法、装置、存储介质和计算机设备",
    "inv": [
      "韩盛",
      "陈谦",
      "宋辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107564089A",
    "apn": "CN201710682445.6",
    "apd": "2017-08-10",
    "apy": "2017",
    "tit": "三维图像处理方法、装置、存储介质和计算机设备",
    "inv": [
      "覃华峥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108307031A",
    "apn": "CN201710669035.8",
    "apd": "2017-08-08",
    "apy": "2017",
    "tit": "屏幕处理方法、装置及存储介质",
    "inv": [
      "任旻"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107451979A",
    "apn": "CN201710672016.0",
    "apd": "2017-08-08",
    "apy": "2017",
    "tit": "一种图像处理方法、装置和存储介质",
    "inv": [
      "钱梦仁",
      "傅斌",
      "沈珂轶",
      "程培"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305317A",
    "apn": "CN201710661746.0",
    "apd": "2017-08-04",
    "apy": "2017",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "林经纬",
      "朱莹",
      "廖戈语"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107368820A",
    "apn": "CN201710656434.0",
    "apd": "2017-08-03",
    "apy": "2017",
    "tit": "一种精细化手势识别方法、装置及设备",
    "inv": [
      "姬晓鹏",
      "程俊",
      "潘亮亮",
      "张丰",
      "方琎"
    ],
    "app": [
      "中国科学院深圳先进技术研究院",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107230182A",
    "apn": "CN201710657280.7",
    "apd": "2017-08-03",
    "apy": "2017",
    "tit": "一种图像的处理方法、装置以及存储介质",
    "inv": [
      "周景锦",
      "程培",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN109325988A",
    "apn": "CN201710640537.8",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "一种面部表情合成方法、装置及电子设备",
    "inv": [
      "郑永森",
      "朱晓龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109325978A",
    "apn": "CN201710643376.8",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "增强现实显示的方法、姿态信息的确定方法及装置",
    "inv": [
      "暴林超",
      "刘威"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107481200B",
    "apn": "CN201710644878.2",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "图像处理方法和装置",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107492110A",
    "apn": "CN201710638305.9",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "一种图像边缘检测方法、装置和存储介质",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107390875A",
    "apn": "CN201710629157.4",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "信息处理方法、装置、终端设备和计算机可读存储介质",
    "inv": [
      "魏嘉",
      "沙鹰",
      "张哲川",
      "张宏",
      "范传康",
      "潘晓",
      "范晓玮"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107341827A",
    "apn": "CN201710623011.9",
    "apd": "2017-07-27",
    "apy": "2017",
    "tit": "一种视频处理方法、装置和存储介质",
    "inv": [
      "程培",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN109325990A",
    "apn": "CN201710625513.5",
    "apd": "2017-07-27",
    "apy": "2017",
    "tit": "图像处理方法及图像处理装置、存储介质",
    "inv": [
      "易成",
      "李斌",
      "王新亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108319953B",
    "apn": "CN201710625671.0",
    "apd": "2017-07-27",
    "apy": "2017",
    "tit": "目标对象的遮挡检测方法及装置、电子设备及存储介质",
    "inv": [
      "易成",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109948093A",
    "apn": "CN201710586647.0",
    "apd": "2017-07-18",
    "apy": "2017",
    "tit": "表情图片生成方法、装置及电子设备",
    "inv": [
      "梁睿思",
      "刘晓峰",
      "李靓",
      "陆思音",
      "邹放",
      "赵婷",
      "徐杨",
      "李强",
      "邢起源",
      "沃迪",
      "张雨涵",
      "金媛媛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109272565A",
    "apn": "CN201710586589.1",
    "apd": "2017-07-18",
    "apy": "2017",
    "tit": "动画播放方法、装置、存储介质以及终端",
    "inv": [
      "邓春国",
      "谌启亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107330439A",
    "apn": "CN201710573908.5",
    "apd": "2017-07-14",
    "apy": "2017",
    "tit": "一种图像中物体姿态的确定方法、客户端及服务器",
    "inv": [
      "李佳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110473232A",
    "apn": "CN201910849463.8",
    "apd": "2017-07-14",
    "apy": "2017",
    "tit": "图像识别方法、装置、存储介质及电子设备",
    "inv": [
      "罗文寒"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107392937A",
    "apn": "CN201710573025.4",
    "apd": "2017-07-14",
    "apy": "2017",
    "tit": "目标跟踪方法、装置及电子设备",
    "inv": [
      "罗文寒"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109255807A",
    "apn": "CN201710571722.6",
    "apd": "2017-07-13",
    "apy": "2017",
    "tit": "一种图像信息处理方法及服务器、计算机存储介质",
    "inv": [
      "王晓慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "北京科技大学"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304936A",
    "apn": "CN201710566325.X",
    "apd": "2017-07-12",
    "apy": "2017",
    "tit": "机器学习模型训练方法和装置、表情图像分类方法和装置",
    "inv": [
      "刘龙坡",
      "万伟",
      "陈谦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109241810A",
    "apn": "CN201710555575.3",
    "apd": "2017-07-10",
    "apy": "2017",
    "tit": "虚拟角色图像的构建方法及装置、存储介质",
    "inv": [
      "葛仕明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院信息工程研究所"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN109214247A",
    "apn": "CN201710539365.5",
    "apd": "2017-07-04",
    "apy": "2017",
    "tit": "基于视频的人脸标识方法和装置",
    "inv": [
      "张一帆"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院自动化研究所"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107316029B",
    "apn": "CN201710533353.1",
    "apd": "2017-07-03",
    "apy": "2017",
    "tit": "一种活体验证方法及设备",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109145933A",
    "apn": "CN201710508695.8",
    "apd": "2017-06-28",
    "apy": "2017",
    "tit": "媒体资源的分类器训练方法及装置",
    "inv": [
      "原春锋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院自动化研究所"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109117688A",
    "apn": "CN201710479266.2",
    "apd": "2017-06-22",
    "apy": "2017",
    "tit": "身份认证方法、装置及移动终端",
    "inv": [
      "潘福霞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107295361B",
    "apn": "CN201710480351.0",
    "apd": "2017-06-22",
    "apy": "2017",
    "tit": "一种内容推送方法",
    "inv": [
      "匡翠芸"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304758A",
    "apn": "CN201710473506.8",
    "apd": "2017-06-21",
    "apy": "2017",
    "tit": "人脸特征点跟踪方法及装置",
    "inv": [
      "林梦然",
      "王新亮",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108307102B",
    "apn": "CN201710458231.0",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "信息显示方法、装置及系统",
    "inv": [
      "林经纬",
      "朱莹",
      "廖戈语"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109150690A",
    "apn": "CN201710458909.5",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "交互数据处理方法、装置、计算机设备和存储介质",
    "inv": [
      "李斌",
      "陈晓波",
      "李磊",
      "王俊山"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108288017A",
    "apn": "CN201710461619.6",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "获取对象密度的方法及装置",
    "inv": [
      "王达峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304757A",
    "apn": "CN201710458868.X",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "身份识别方法及装置",
    "inv": [
      "王达峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107257338B",
    "apn": "CN201710457646.6",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "媒体数据处理方法、装置及存储介质",
    "inv": [
      "陈姿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107330905A",
    "apn": "CN201710455122.3",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "刘华星"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304431A",
    "apn": "CN201710447392.X",
    "apd": "2017-06-14",
    "apy": "2017",
    "tit": "一种图像检索方法及装置、设备、存储介质",
    "inv": [
      "李毅",
      "刘越",
      "薛伟",
      "肖磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109145926A",
    "apn": "CN201710444106.4",
    "apd": "2017-06-13",
    "apy": "2017",
    "tit": "相似图片识别方法及计算机设备",
    "inv": [
      "宋翔宇",
      "贺伟",
      "黄桂洲",
      "郭德安",
      "江启泉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109033107A",
    "apn": "CN201710433984.6",
    "apd": "2017-06-09",
    "apy": "2017",
    "tit": "图像检索方法和装置、计算机设备和存储介质",
    "inv": [
      "赖韩江"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中山大学"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107295251B",
    "apn": "CN201710444859.5",
    "apd": "2017-06-09",
    "apy": "2017",
    "tit": "图像处理方法、装置、终端及存储介质",
    "inv": [
      "梁宇轩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN110490177A",
    "apn": "CN201910834138.4",
    "apd": "2017-06-02",
    "apy": "2017",
    "tit": "一种人脸检测器训练方法及装置",
    "inv": [
      "王浩",
      "李志锋",
      "季兴",
      "王一同"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108985135A",
    "apn": "CN201710406726.9",
    "apd": "2017-06-02",
    "apy": "2017",
    "tit": "一种人脸检测器训练方法、装置及电子设备",
    "inv": [
      "王浩",
      "李志锋",
      "季兴",
      "王一同"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108984087A",
    "apn": "CN201710406674.5",
    "apd": "2017-06-02",
    "apy": "2017",
    "tit": "基于三维虚拟形象的社交互动方法及装置",
    "inv": [
      "李斌",
      "张玖林",
      "冉蓉",
      "邓智文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108986224A",
    "apn": "CN201710405645.7",
    "apd": "2017-06-01",
    "apy": "2017",
    "tit": "电子地图处理方法、装置和计算机设备",
    "inv": [
      "张玖林",
      "李斌",
      "罗程"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108985132A",
    "apn": "CN201710400467.9",
    "apd": "2017-05-31",
    "apy": "2017",
    "tit": "一种人脸图像处理方法、装置、计算设备及存储介质",
    "inv": [
      "奚驰",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108932273A",
    "apn": "CN201710392957.9",
    "apd": "2017-05-27",
    "apy": "2017",
    "tit": "图片筛选方法及装置",
    "inv": [
      "张红卫",
      "刘龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107578373A",
    "apn": "CN201710393435.0",
    "apd": "2017-05-27",
    "apy": "2017",
    "tit": "全景图像拼接方法、终端设备及计算机可读存储介质",
    "inv": [
      "钟春斌",
      "程俊",
      "方璡"
    ],
    "app": [
      "深圳先进技术研究院",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108933891A",
    "apn": "CN201710385944.9",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "拍照方法、终端及系统",
    "inv": [
      "梁峰起"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107357563B",
    "apn": "CN201710383748.8",
    "apd": "2017-05-25",
    "apy": "2017",
    "tit": "对象移动方法和装置以及存储介质、电子装置",
    "inv": [
      "莫锡昌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108959323A",
    "apn": "CN201710382359.3",
    "apd": "2017-05-25",
    "apy": "2017",
    "tit": "视频分类方法和装置",
    "inv": [
      "聂秀山"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "山东财经大学"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107256555A",
    "apn": "CN201710387281.4",
    "apd": "2017-05-25",
    "apy": "2017",
    "tit": "一种图像处理方法、装置及存储介质",
    "inv": [
      "吴磊",
      "蒋兴华"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108932457A",
    "apn": "CN201710373656.1",
    "apd": "2017-05-24",
    "apy": "2017",
    "tit": "图像识别方法及相关装置",
    "inv": [
      "桂杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院合肥物质科学研究院"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108958571A",
    "apn": "CN201710375103.X",
    "apd": "2017-05-24",
    "apy": "2017",
    "tit": "三维会话数据展示方法、装置、存储介质和计算机设备",
    "inv": [
      "赵晓强",
      "李斌",
      "陈郁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107194869A",
    "apn": "CN201710367732.8",
    "apd": "2017-05-23",
    "apy": "2017",
    "tit": "一种图像处理方法及终端、计算机存储介质、计算机设备",
    "inv": [
      "吴磊"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108932745A",
    "apn": "CN201710369091.X",
    "apd": "2017-05-23",
    "apy": "2017",
    "tit": "图像绘制方法、装置、终端设备及计算机可读存储介质",
    "inv": [
      "郭金辉",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305240A",
    "apn": "CN201710361810.3",
    "apd": "2017-05-22",
    "apy": "2017",
    "tit": "图像质量检测方法及装置",
    "inv": [
      "王星星"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN107169463B",
    "apn": "CN201710365520.6",
    "apd": "2017-05-22",
    "apy": "2017",
    "tit": "人脸检测方法、装置、计算机设备及存储介质",
    "inv": [
      "王新亮",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107341442A",
    "apn": "CN201710365516.X",
    "apd": "2017-05-22",
    "apy": "2017",
    "tit": "运动控制方法、装置、计算机设备和服务机器人",
    "inv": [
      "孟宾宾"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107154030A",
    "apn": "CN201710348772.8",
    "apd": "2017-05-17",
    "apy": "2017",
    "tit": "图像处理方法及装置、电子设备及存储介质",
    "inv": [
      "吴磊"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108880975A",
    "apn": "CN201710343814.9",
    "apd": "2017-05-16",
    "apy": "2017",
    "tit": "信息显示方法、装置及系统",
    "inv": [
      "李斌",
      "张玖林",
      "易薇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108875460A",
    "apn": "CN201710340898.0",
    "apd": "2017-05-15",
    "apy": "2017",
    "tit": "增强现实处理方法及装置、显示终端及计算机存储介质",
    "inv": [
      "庞英明",
      "魏扼"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108876879A",
    "apn": "CN201710335509.5",
    "apd": "2017-05-12",
    "apy": "2017",
    "tit": "人脸动画实现的方法、装置、计算机设备及存储介质",
    "inv": [
      "陈静聪",
      "王新亮",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108876931A",
    "apn": "CN201710335996.5",
    "apd": "2017-05-12",
    "apy": "2017",
    "tit": "三维物体颜色调整方法、装置、计算机设备及计算机可读存储介质",
    "inv": [
      "郭金辉",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108880921A",
    "apn": "CN201710329418.0",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "网页监测方法",
    "inv": [
      "高翔宇",
      "徐伟"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN109242940A",
    "apn": "CN201710330523.6",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "三维动态图像的生成方法和装置",
    "inv": [
      "彭向阳",
      "李斌",
      "刘文婷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108876498A",
    "apn": "CN201710328796.7",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "信息展示方法及装置",
    "inv": [
      "郭金辉",
      "陈扬",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107205083B",
    "apn": "CN201710329785.0",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "信息展示方法及装置",
    "inv": [
      "赵晓强",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304838A",
    "apn": "CN201710331435.8",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "一种图片信息识别方法及终端",
    "inv": [
      "晏栋",
      "赵伟",
      "汤耀洲",
      "李发霓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107147947B",
    "apn": "CN201710330148.5",
    "apd": "2017-05-11",
    "apy": "2017",
    "tit": "关键帧识别方法及装置",
    "inv": [
      "王杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108287859A",
    "apn": "CN201710326718.3",
    "apd": "2017-05-10",
    "apy": "2017",
    "tit": "一种多媒体信息检索方法及装置",
    "inv": [
      "江佳伟",
      "崔斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108875451A",
    "apn": "CN201710325609.X",
    "apd": "2017-05-10",
    "apy": "2017",
    "tit": "一种定位图像的方法、装置、存储介质和程序产品",
    "inv": [
      "晏栋",
      "赵伟",
      "邓亮专"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108876886A",
    "apn": "CN201710322668.1",
    "apd": "2017-05-09",
    "apy": "2017",
    "tit": "图像处理方法、装置和计算机设备",
    "inv": [
      "易成",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108882019A",
    "apn": "CN201710322491.5",
    "apd": "2017-05-09",
    "apy": "2017",
    "tit": "视频播放测试方法、电子设备及系统",
    "inv": [
      "年奇东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108874114A",
    "apn": "CN201710318050.8",
    "apd": "2017-05-08",
    "apy": "2017",
    "tit": "实现虚拟对象情绪表达的方法、装置、计算机设备及存储介质",
    "inv": [
      "张玖林",
      "李斌",
      "刘文婷",
      "易薇",
      "陈扬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108876921A",
    "apn": "CN201710318057.X",
    "apd": "2017-05-08",
    "apy": "2017",
    "tit": "三维装扮模型处理方法、装置、计算机设备和存储介质",
    "inv": [
      "郭金辉",
      "陈慧",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107247920A",
    "apn": "CN201710317463.4",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "交互控制方法、装置和计算机可读存储介质",
    "inv": [
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108803383A",
    "apn": "CN201710312558.7",
    "apd": "2017-05-05",
    "apy": "2017",
    "tit": "一种设备控制方法、装置、系统和存储介质",
    "inv": [
      "陈志博"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108804983A",
    "apn": "CN201710304207.1",
    "apd": "2017-05-03",
    "apy": "2017",
    "tit": "交通信号灯状态识别方法、装置、车载控制终端及机动车",
    "inv": [
      "王珏",
      "王斌",
      "李宇明",
      "邢腾飞",
      "李成军",
      "苏奎峰",
      "陈仁",
      "向南"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108806025A",
    "apn": "CN201710304241.9",
    "apd": "2017-05-03",
    "apy": "2017",
    "tit": "实现访客临时访问的门禁授权方法及装置",
    "inv": [
      "朱佳",
      "贾镕企",
      "卓松",
      "李俊聪",
      "范铭",
      "彭诗辉",
      "罗挺"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107204031A",
    "apn": "CN201710299057.X",
    "apd": "2017-04-27",
    "apy": "2017",
    "tit": "信息展示方法及装置",
    "inv": [
      "刘亦辰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107171932A",
    "apn": "CN201710289348.0",
    "apd": "2017-04-27",
    "apy": "2017",
    "tit": "一种图片风格转换方法、装置及系统",
    "inv": [
      "刘刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108737903A",
    "apn": "CN201710278090.4",
    "apd": "2017-04-25",
    "apy": "2017",
    "tit": "一种多媒体处理系统及多媒体处理方法",
    "inv": [
      "熊章俊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107092684B",
    "apn": "CN201710267710.4",
    "apd": "2017-04-21",
    "apy": "2017",
    "tit": "图像处理方法及装置、存储介质",
    "inv": [
      "吴耀文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107103070A",
    "apn": "CN201710264322.0",
    "apd": "2017-04-21",
    "apy": "2017",
    "tit": "一种封面设置方法、装置及系统",
    "inv": [
      "刘刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN107066983A",
    "apn": "CN201710261931.0",
    "apd": "2017-04-20",
    "apy": "2017",
    "tit": "一种身份验证方法及装置",
    "inv": [
      "梁晓晴",
      "梁亦聪",
      "丁守鸿",
      "刘畅",
      "陶芝伟",
      "周可菁"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108734813A",
    "apn": "CN201710255812.4",
    "apd": "2017-04-19",
    "apy": "2017",
    "tit": "临时门禁卡的发放方法及装置",
    "inv": [
      "卓松",
      "李俊聪",
      "朱佳",
      "范铭",
      "彭诗辉",
      "贾镕企",
      "罗挺"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107085509B",
    "apn": "CN201710257372.6",
    "apd": "2017-04-19",
    "apy": "2017",
    "tit": "一种在虚拟场景中前景图片的处理方法及终端",
    "inv": [
      "陈永"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107103619A",
    "apn": "CN201710257558.1",
    "apd": "2017-04-19",
    "apy": "2017",
    "tit": "一种头发纹理方向的处理方法、装置及系统",
    "inv": [
      "蒋兴华"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107025613A",
    "apn": "CN201710254571.1",
    "apd": "2017-04-18",
    "apy": "2017",
    "tit": "一种自动点餐方法及终端",
    "inv": [
      "陈志博",
      "王时全",
      "张亿皓",
      "于佳骏",
      "王远大",
      "孟宾宾"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107066980A",
    "apn": "CN201710255930.5",
    "apd": "2017-04-18",
    "apy": "2017",
    "tit": "一种图像变形检测方法及装置",
    "inv": [
      "黎洪宋",
      "黄嘉文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108509466A",
    "apn": "CN201710243429.7",
    "apd": "2017-04-14",
    "apy": "2017",
    "tit": "一种信息推荐方法和装置",
    "inv": [
      "姚伶伶",
      "何琪",
      "王芊",
      "郭永",
      "项则远",
      "陈骥远"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106815881B",
    "apn": "CN201710240835.8",
    "apd": "2017-04-13",
    "apy": "2017",
    "tit": "一种角色模型的颜色控制方法和装置",
    "inv": [
      "林卓胤"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107145833A",
    "apn": "CN201710233590.6",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "人脸区域的确定方法和装置",
    "inv": [
      "王亚彪",
      "倪辉",
      "赵艳丹",
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107018336B",
    "apn": "CN201710234566.4",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "图像处理的方法和装置及视频处理的方法和装置",
    "inv": [
      "董霙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106970800B",
    "apn": "CN201710214239.2",
    "apd": "2017-04-01",
    "apy": "2017",
    "tit": "换装方法和装置",
    "inv": [
      "蒋浩",
      "林声炜",
      "许弈",
      "郭启发"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108664016A",
    "apn": "CN201710209738.2",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "确定车道中心线的方法及装置",
    "inv": [
      "陈偲",
      "曾超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106990897A",
    "apn": "CN201710208958.3",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "一种界面调整方法及装置",
    "inv": [
      "吴歆婉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108664364A",
    "apn": "CN201710208201.4",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "一种终端测试方法及装置",
    "inv": [
      "张学利"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107203765A",
    "apn": "CN201710203568.7",
    "apd": "2017-03-30",
    "apy": "2017",
    "tit": "敏感图像检测方法和装置",
    "inv": [
      "余宗桥",
      "胡易",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107133981B",
    "apn": "CN201710207403.7",
    "apd": "2017-03-30",
    "apy": "2017",
    "tit": "图像处理方法和装置",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107194817A",
    "apn": "CN201710199079.9",
    "apd": "2017-03-29",
    "apy": "2017",
    "tit": "用户社交信息的展示方法、装置和计算机设备",
    "inv": [
      "杨田从雨",
      "陈宇",
      "张浩",
      "华有为",
      "薛丰",
      "肖鸿志",
      "冯绪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108665403A",
    "apn": "CN201710196907.3",
    "apd": "2017-03-29",
    "apy": "2017",
    "tit": "数字水印嵌入方法、提取方法、装置及数字水印系统",
    "inv": [
      "钟和旺",
      "孙亮",
      "屈政斌",
      "黄达毅",
      "杨友山"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107025457A",
    "apn": "CN201710199165.X",
    "apd": "2017-03-29",
    "apy": "2017",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "朱晓龙",
      "郑永森",
      "王浩",
      "黄凯宁",
      "罗文寒",
      "高雨",
      "杨之华",
      "华园",
      "曾毅榕",
      "吴发强",
      "黄祥瑞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107168619A",
    "apn": "CN201710199078.4",
    "apd": "2017-03-29",
    "apy": "2017",
    "tit": "用户生成内容处理方法和装置",
    "inv": [
      "杨田从雨",
      "陈宇",
      "张浩",
      "华有为",
      "薛丰",
      "肖鸿志",
      "冯绪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108664118A",
    "apn": "CN201710188766.0",
    "apd": "2017-03-27",
    "apy": "2017",
    "tit": "眼球追踪方法和装置、隐形眼镜、虚拟现实系统",
    "inv": [
      "周伟彪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106951869B",
    "apn": "CN201710175495.5",
    "apd": "2017-03-22",
    "apy": "2017",
    "tit": "一种活体验证方法及设备",
    "inv": [
      "熊鹏飞",
      "王汉杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107025275B",
    "apn": "CN201710169694.5",
    "apd": "2017-03-21",
    "apy": "2017",
    "tit": "视频搜索方法及装置",
    "inv": [
      "杨文强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107038715A",
    "apn": "CN201710171010.5",
    "apd": "2017-03-21",
    "apy": "2017",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "钱梦仁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106982389A",
    "apn": "CN201710160533.X",
    "apd": "2017-03-17",
    "apy": "2017",
    "tit": "视频类型识别方法及装置",
    "inv": [
      "李智"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106951856A",
    "apn": "CN201710157803.1",
    "apd": "2017-03-16",
    "apy": "2017",
    "tit": "表情包提取方法及装置",
    "inv": [
      "陈姿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106943745A",
    "apn": "CN201710153418.X",
    "apd": "2017-03-15",
    "apy": "2017",
    "tit": "一种图像绘制的方法及设备",
    "inv": [
      "谢巍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108573513A",
    "apn": "CN201710151348.4",
    "apd": "2017-03-14",
    "apy": "2017",
    "tit": "随机元素生成方法及随机元素生成装置",
    "inv": [
      "徐勋",
      "李晨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106934364A",
    "apn": "CN201710138035.5",
    "apd": "2017-03-09",
    "apy": "2017",
    "tit": "人脸图片的识别方法及装置",
    "inv": [
      "张亿皓",
      "李绍欣",
      "李集佳",
      "陈志博",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304755A",
    "apn": "CN201710136471.9",
    "apd": "2017-03-08",
    "apy": "2017",
    "tit": "用于图像处理的神经网络模型的训练方法和装置",
    "inv": [
      "黄浩智",
      "王浩",
      "罗文寒",
      "马林",
      "杨鹏",
      "姜文浩",
      "朱晓龙",
      "刘威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108288312A",
    "apn": "CN201710127810.7",
    "apd": "2017-03-06",
    "apy": "2017",
    "tit": "驾驶行为确定方法及装置",
    "inv": [
      "王达峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108305475A",
    "apn": "CN201710127705.3",
    "apd": "2017-03-06",
    "apy": "2017",
    "tit": "一种交通灯识别方法及装置",
    "inv": [
      "邢腾飞",
      "李宇明",
      "王珏",
      "王斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304754A",
    "apn": "CN201710121609.8",
    "apd": "2017-03-02",
    "apy": "2017",
    "tit": "车型的识别方法和装置",
    "inv": [
      "郑克松",
      "张力",
      "徐浩",
      "申玉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108509165A",
    "apn": "CN201710113503.3",
    "apd": "2017-02-28",
    "apy": "2017",
    "tit": "数据处理方法、数据处理装置及终端设备",
    "inv": [
      "韩山骏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106919918A",
    "apn": "CN201710108748.7",
    "apd": "2017-02-27",
    "apy": "2017",
    "tit": "一种人脸跟踪方法和装置",
    "inv": [
      "赵凌",
      "李季檩"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108337471A",
    "apn": "CN201710103891.7",
    "apd": "2017-02-24",
    "apy": "2017",
    "tit": "视频画面的处理方法及装置",
    "inv": [
      "赵娜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108513090A",
    "apn": "CN201710104669.9",
    "apd": "2017-02-24",
    "apy": "2017",
    "tit": "群组视频会话的方法及装置",
    "inv": [
      "李凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108513088A",
    "apn": "CN201710104439.2",
    "apd": "2017-02-24",
    "apy": "2017",
    "tit": "群组视频会话的方法及装置",
    "inv": [
      "李凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108510424A",
    "apn": "CN201710099135.1",
    "apd": "2017-02-23",
    "apy": "2017",
    "tit": "一种图像处理的方法及装置",
    "inv": [
      "范俊豪",
      "程明宇",
      "罗凡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN108460815A",
    "apn": "CN201710097659.7",
    "apd": "2017-02-22",
    "apy": "2017",
    "tit": "地图道路要素编辑方法和装置",
    "inv": [
      "李成军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN110036356A",
    "apn": "CN201780058691.2",
    "apd": "2017-02-22",
    "apy": "2017",
    "tit": "VR系统中的图像处理",
    "inv": [
      "达瓦·詹米·乔什"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106803909A",
    "apn": "CN201710093528.1",
    "apd": "2017-02-21",
    "apy": "2017",
    "tit": "一种视频文件的生成方法及终端",
    "inv": [
      "邬振海"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106878809B",
    "apn": "CN201710080398.8",
    "apd": "2017-02-15",
    "apy": "2017",
    "tit": "一种视频收藏方法、播放方法、装置、终端及系统",
    "inv": [
      "邱志善"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106909219A",
    "apn": "CN201710082270.5",
    "apd": "2017-02-15",
    "apy": "2017",
    "tit": "基于三维空间的交互控制方法和装置、智能终端",
    "inv": [
      "麦伟强",
      "陈阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108287857A",
    "apn": "CN201710075877.0",
    "apd": "2017-02-13",
    "apy": "2017",
    "tit": "表情图片推荐方法及装置",
    "inv": [
      "万伟",
      "李霖",
      "刘龙坡",
      "陈谦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106846490B",
    "apn": "CN201710071281.3",
    "apd": "2017-02-09",
    "apy": "2017",
    "tit": "一种弧面模型用于在3D场景进行展示的展示方法及装置",
    "inv": [
      "王洪浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108401005A",
    "apn": "CN201710069474.5",
    "apd": "2017-02-08",
    "apy": "2017",
    "tit": "一种表情推荐方法和装置",
    "inv": [
      "刘龙坡",
      "万伟",
      "李霖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108303103A",
    "apn": "CN201710073556.7",
    "apd": "2017-02-07",
    "apy": "2017",
    "tit": "目标车道的确定方法和装置",
    "inv": [
      "付玉锦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106875422A",
    "apn": "CN201710068185.3",
    "apd": "2017-02-06",
    "apy": "2017",
    "tit": "人脸跟踪方法和装置",
    "inv": [
      "梁亦聪",
      "汪铖杰",
      "李绍欣",
      "赵艳丹",
      "李季檩"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106846336A",
    "apn": "CN201710065799.6",
    "apd": "2017-02-06",
    "apy": "2017",
    "tit": "提取前景图像、替换图像背景的方法及装置",
    "inv": [
      "程培",
      "傅斌",
      "钱梦仁",
      "沈珂轶"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN106774945A",
    "apn": "CN201710060176.X",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "一种飞行器飞行控制方法、装置、飞行器及系统",
    "inv": [
      "王洁梅"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106843489B",
    "apn": "CN201710060380.1",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "一种飞行器的飞行路线控制方法及飞行器",
    "inv": [
      "王洁梅",
      "黄盈",
      "周大军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108304753A",
    "apn": "CN201710060309.3",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "视频通信方法及视频通信装置",
    "inv": [
      "李斌",
      "易成",
      "王新亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108347578A",
    "apn": "CN201710058161.X",
    "apd": "2017-01-23",
    "apy": "2017",
    "tit": "视频通话中视频图像的处理方法及装置",
    "inv": [
      "李斌",
      "易成",
      "王新亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN108345822A",
    "apn": "CN201710054366.0",
    "apd": "2017-01-22",
    "apy": "2017",
    "tit": "一种点云数据处理方法及装置",
    "inv": [
      "曾超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106874857A",
    "apn": "CN201710044150.6",
    "apd": "2017-01-19",
    "apy": "2017",
    "tit": "一种基于视频分析的活体判别方法及系统",
    "inv": [
      "赵凌",
      "李季檩"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106815351A",
    "apn": "CN201710038508.4",
    "apd": "2017-01-18",
    "apy": "2017",
    "tit": "一种标签推荐方法及服务器",
    "inv": [
      "周华斌",
      "焦健"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106874936A",
    "apn": "CN201710031063.7",
    "apd": "2017-01-17",
    "apy": "2017",
    "tit": "图像传播监测方法及装置",
    "inv": [
      "余宗桥"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106845385A",
    "apn": "CN201710032132.6",
    "apd": "2017-01-17",
    "apy": "2017",
    "tit": "视频目标跟踪的方法和装置",
    "inv": [
      "余三思"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106845383A",
    "apn": "CN201710029244.6",
    "apd": "2017-01-16",
    "apy": "2017",
    "tit": "人头检测方法和装置",
    "inv": [
      "姜德强"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106878767B",
    "apn": "CN201710007572.6",
    "apd": "2017-01-05",
    "apy": "2017",
    "tit": "视频播放方法和装置",
    "inv": [
      "邱志善"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106658079B",
    "apn": "CN201710007418.9",
    "apd": "2017-01-05",
    "apy": "2017",
    "tit": "自定义生成表情图像的方法及装置",
    "inv": [
      "李钟伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN109168037A",
    "apn": "CN201811190835.2",
    "apd": "2017-01-05",
    "apy": "2017",
    "tit": "视频播放方法和装置",
    "inv": [
      "邱志善"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107067020B",
    "apn": "CN201611256335.5",
    "apd": "2016-12-30",
    "apy": "2016",
    "tit": "图片识别方法及装置",
    "inv": [
      "彭湃",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108241645A",
    "apn": "CN201611208759.4",
    "apd": "2016-12-23",
    "apy": "2016",
    "tit": "图像处理方法及装置",
    "inv": [
      "宋翔宇",
      "郭德安",
      "曾晋川",
      "黄桂洲",
      "江启泉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN108229262A",
    "apn": "CN201611200177.1",
    "apd": "2016-12-22",
    "apy": "2016",
    "tit": "一种色情视频检测方法及装置",
    "inv": [
      "侯鑫",
      "牛志伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108234932A",
    "apn": "CN201611191607.8",
    "apd": "2016-12-21",
    "apy": "2016",
    "tit": "视频监控图像中的人员形态提取方法及装置",
    "inv": [
      "张磊",
      "陈紫杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108234927A",
    "apn": "CN201611187174.9",
    "apd": "2016-12-20",
    "apy": "2016",
    "tit": "视频追踪方法和系统",
    "inv": [
      "张磊",
      "陈紫杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108205544A",
    "apn": "CN201611170430.3",
    "apd": "2016-12-16",
    "apy": "2016",
    "tit": "网页内容识别方法、装置、服务器",
    "inv": [
      "赵铭鑫",
      "卓居超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108229252A",
    "apn": "CN201611161846.9",
    "apd": "2016-12-15",
    "apy": "2016",
    "tit": "一种瞳孔定位方法及系统",
    "inv": [
      "王新亮",
      "李斌",
      "黄铁鸣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106528884B",
    "apn": "CN201611163080.8",
    "apd": "2016-12-15",
    "apy": "2016",
    "tit": "一种信息展示图片生成方法及装置",
    "inv": [
      "曾佩玲"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106679665A",
    "apn": "CN201611149580.6",
    "apd": "2016-12-13",
    "apy": "2016",
    "tit": "一种路线规划方法及装置",
    "inv": [
      "李雪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108230680A",
    "apn": "CN201611146242.7",
    "apd": "2016-12-13",
    "apy": "2016",
    "tit": "一种车辆行为信息获取方法、装置及终端",
    "inv": [
      "刘杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108615044A",
    "apn": "CN201611139498.5",
    "apd": "2016-12-12",
    "apy": "2016",
    "tit": "一种分类模型训练的方法、数据分类的方法及装置",
    "inv": [
      "尹红军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106780306A",
    "apn": "CN201611131379.5",
    "apd": "2016-12-09",
    "apy": "2016",
    "tit": "一种重构稿生成方法及装置",
    "inv": [
      "陈存腾"
    ],
    "app": [
      "腾讯音乐娱乐(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN106778585B",
    "apn": "CN201611121387.1",
    "apd": "2016-12-08",
    "apy": "2016",
    "tit": "一种人脸关键点跟踪方法和装置",
    "inv": [
      "汪铖杰",
      "倪辉",
      "赵艳丹",
      "王亚彪",
      "丁守鸿",
      "李绍欣",
      "赵凌",
      "李季檩",
      "吴永坚",
      "黄飞跃",
      "梁亦聪"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106599817A",
    "apn": "CN201611113324.1",
    "apd": "2016-12-07",
    "apy": "2016",
    "tit": "一种人脸替换方法及装置",
    "inv": [
      "曾佩玲"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106686403B",
    "apn": "CN201611116814.7",
    "apd": "2016-12-07",
    "apy": "2016",
    "tit": "一种视频预览图生成方法、装置、服务器以及系统",
    "inv": [
      "于海"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106444797A",
    "apn": "CN201611094196.0",
    "apd": "2016-12-01",
    "apy": "2016",
    "tit": "一种控制飞行器降落的方法以及相关装置",
    "inv": [
      "申俊峰",
      "王洁梅",
      "杨瑞波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106767817B",
    "apn": "CN201611100259.9",
    "apd": "2016-12-01",
    "apy": "2016",
    "tit": "一种获取飞行定位信息的方法及飞行器",
    "inv": [
      "黄盈"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106792153A",
    "apn": "CN201611088673.2",
    "apd": "2016-12-01",
    "apy": "2016",
    "tit": "一种视频标识处理方法及装置",
    "inv": [
      "刘啸南"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106767682A",
    "apn": "CN201611100232.X",
    "apd": "2016-12-01",
    "apy": "2016",
    "tit": "一种获取飞行高度信息的方法及飞行器",
    "inv": [
      "黄盈"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108121350A",
    "apn": "CN201611071489.7",
    "apd": "2016-11-29",
    "apy": "2016",
    "tit": "一种控制飞行器降落的方法以及相关装置",
    "inv": [
      "王洁梅",
      "黄盈",
      "申俊峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106789922A",
    "apn": "CN201611061995.8",
    "apd": "2016-11-25",
    "apy": "2016",
    "tit": "找回帐号、身份验证的方法和装置",
    "inv": [
      "王小叶",
      "陆莉",
      "阳萍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106843278A",
    "apn": "CN201611050870.5",
    "apd": "2016-11-24",
    "apy": "2016",
    "tit": "一种飞行器跟踪方法、装置及飞行器",
    "inv": [
      "王洁梅",
      "申俊峰",
      "周大军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108108649A",
    "apn": "CN201611049484.4",
    "apd": "2016-11-24",
    "apy": "2016",
    "tit": "身份验证方法及装置",
    "inv": [
      "陈阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106529538A",
    "apn": "CN201611044625.3",
    "apd": "2016-11-24",
    "apy": "2016",
    "tit": "一种飞行器的定位方法和装置",
    "inv": [
      "黄盈"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106529495A",
    "apn": "CN201611045197.6",
    "apd": "2016-11-24",
    "apy": "2016",
    "tit": "一种飞行器的障碍物检测方法和装置",
    "inv": [
      "黄盈",
      "周大军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN108090405A",
    "apn": "CN201611046965.X",
    "apd": "2016-11-23",
    "apy": "2016",
    "tit": "一种人脸识别方法及终端",
    "inv": [
      "袁丽娜",
      "郭计伟",
      "李轶峰",
      "王亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN106803057A",
    "apn": "CN201611018568.1",
    "apd": "2016-11-18",
    "apy": "2016",
    "tit": "图像信息处理方法及装置",
    "inv": [
      "张振伟",
      "张新磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106780553A",
    "apn": "CN201611034666.4",
    "apd": "2016-11-18",
    "apy": "2016",
    "tit": "一种飞行器的移动位置确定方法、装置及飞行器",
    "inv": [
      "王洁梅",
      "杨夏",
      "黄盈"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106683152B",
    "apn": "CN201611026997.3",
    "apd": "2016-11-16",
    "apy": "2016",
    "tit": "三维视觉效果模拟方法及装置",
    "inv": [
      "高斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108073855A",
    "apn": "CN201610998257.X",
    "apd": "2016-11-11",
    "apy": "2016",
    "tit": "一种人脸表情的识别方法及系统",
    "inv": [
      "李斌",
      "易成",
      "王新亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106570489A",
    "apn": "CN201610992121.8",
    "apd": "2016-11-10",
    "apy": "2016",
    "tit": "活体判别方法和装置、身份认证方法和装置",
    "inv": [
      "赵凌",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108073267A",
    "apn": "CN201610992224.4",
    "apd": "2016-11-10",
    "apy": "2016",
    "tit": "基于运动轨迹的三维控制方法及装置",
    "inv": [
      "陈阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN108022331A",
    "apn": "CN201610934803.3",
    "apd": "2016-11-01",
    "apy": "2016",
    "tit": "门禁系统中实现人脸识别的方法和装置",
    "inv": [
      "陈紫杰",
      "张磊",
      "高杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108024148A",
    "apn": "CN201610929276.7",
    "apd": "2016-10-31",
    "apy": "2016",
    "tit": "基于行为特征的多媒体文件识别方法、处理方法及装置",
    "inv": [
      "刘杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN108008804A",
    "apn": "CN201610971193.4",
    "apd": "2016-10-28",
    "apy": "2016",
    "tit": "智能设备的屏幕控制方法及装置",
    "inv": [
      "蔡英珏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106528758A",
    "apn": "CN201610972355.6",
    "apd": "2016-10-28",
    "apy": "2016",
    "tit": "一种选取图片方法及装置",
    "inv": [
      "孙子荀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "电子书",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN106383587A",
    "apn": "CN201610949178.X",
    "apd": "2016-10-26",
    "apy": "2016",
    "tit": "一种增强现实场景生成方法、装置及设备",
    "inv": [
      "吴雁林",
      "李德元",
      "王鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106484913A",
    "apn": "CN201610947631.3",
    "apd": "2016-10-26",
    "apy": "2016",
    "tit": "一种目标图片确定的方法以及服务器",
    "inv": [
      "罗波罗"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN106385591A",
    "apn": "CN201610903697.2",
    "apd": "2016-10-17",
    "apy": "2016",
    "tit": "视频处理方法及视频处理装置",
    "inv": [
      "汪倩怡",
      "高雨",
      "王志斌",
      "李恩",
      "李榕"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107525516B",
    "apn": "CN201610881491.4",
    "apd": "2016-10-09",
    "apy": "2016",
    "tit": "用于导航的车道线显示方法和装置",
    "inv": [
      "肖旺裕",
      "杨帅文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106412699A",
    "apn": "CN201610881887.9",
    "apd": "2016-10-09",
    "apy": "2016",
    "tit": "媒体文件推送方法及装置",
    "inv": [
      "李学强",
      "徐宁"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106484837A",
    "apn": "CN201610873855.4",
    "apd": "2016-09-30",
    "apy": "2016",
    "tit": "相似视频文件的检测方法和装置",
    "inv": [
      "胡丹",
      "万明月",
      "冯少伟"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106292721A",
    "apn": "CN201610864808.3",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "一种控制飞行器跟踪目标对象的方法、设备及系统",
    "inv": [
      "黄盈",
      "杨夏",
      "周大军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106354156A",
    "apn": "CN201610871172.5",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "一种跟踪目标对象的方法、装置及飞行器",
    "inv": [
      "王洁梅",
      "黄盈",
      "杨瑞波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107659545A",
    "apn": "CN201610862882.1",
    "apd": "2016-09-28",
    "apy": "2016",
    "tit": "一种媒体信息处理方法及媒体信息处理系统、电子设备",
    "inv": [
      "周彬"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107870666A",
    "apn": "CN201610861832.1",
    "apd": "2016-09-28",
    "apy": "2016",
    "tit": "一种终端控制方法及终端",
    "inv": [
      "袁丽娜",
      "林陆一",
      "李轶峰",
      "王亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106650581B",
    "apn": "CN201610856298.5",
    "apd": "2016-09-27",
    "apy": "2016",
    "tit": "一种人流量统计方法及装置",
    "inv": [
      "孟宾宾",
      "王时全",
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106446950A",
    "apn": "CN201610855310.0",
    "apd": "2016-09-27",
    "apy": "2016",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "严航宇",
      "郑妤妤",
      "张伟男",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107864408A",
    "apn": "CN201610844502.1",
    "apd": "2016-09-22",
    "apy": "2016",
    "tit": "信息展示方法、装置及系统",
    "inv": [
      "薛笛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107818288B",
    "apn": "CN201610822521.4",
    "apd": "2016-09-13",
    "apy": "2016",
    "tit": "标志牌信息获取方法及装置",
    "inv": [
      "曾超"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106331880A",
    "apn": "CN201610816135.4",
    "apd": "2016-09-09",
    "apy": "2016",
    "tit": "一种信息处理方法及系统",
    "inv": [
      "王功华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106339006B",
    "apn": "CN201610813951.X",
    "apd": "2016-09-09",
    "apy": "2016",
    "tit": "一种飞行器的目标跟踪方法和装置",
    "inv": [
      "黄盈",
      "杨夏",
      "周大军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106168807B",
    "apn": "CN201610814711.1",
    "apd": "2016-09-09",
    "apy": "2016",
    "tit": "一种飞行器的飞行控制方法和飞行控制装置",
    "inv": [
      "周大军",
      "申俊峰",
      "王斌",
      "魏学峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106375448A",
    "apn": "CN201610803342.6",
    "apd": "2016-09-05",
    "apy": "2016",
    "tit": "图像处理方法和装置及系统",
    "inv": [
      "孟宾宾"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106446797B",
    "apn": "CN201610791647.X",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "图像聚类方法及装置",
    "inv": [
      "李绍欣",
      "张亿皓",
      "于佳骏",
      "陈志博",
      "李季檩",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106407912B",
    "apn": "CN201610796542.3",
    "apd": "2016-08-31",
    "apy": "2016",
    "tit": "一种人脸验证的方法及装置",
    "inv": [
      "梁亦聪",
      "李季檩",
      "汪铖杰",
      "丁守鸿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106372662A",
    "apn": "CN201610778655.0",
    "apd": "2016-08-30",
    "apy": "2016",
    "tit": "安全帽佩戴的检测方法和装置、摄像头、服务器",
    "inv": [
      "王时全",
      "陈志博",
      "吴永坚",
      "黄飞跃",
      "张磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106406693B",
    "apn": "CN201610776396.8",
    "apd": "2016-08-30",
    "apy": "2016",
    "tit": "图像选中方法及装置",
    "inv": [
      "赵毅",
      "冯宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN107786349A",
    "apn": "CN201610716489.1",
    "apd": "2016-08-24",
    "apy": "2016",
    "tit": "一种针对用户账号的安全管理方法及装置",
    "inv": [
      "阳萍",
      "陆莉",
      "王小叶"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107786487A",
    "apn": "CN201610712437.7",
    "apd": "2016-08-24",
    "apy": "2016",
    "tit": "一种信息认证处理方法、系统以及相关设备",
    "inv": [
      "肖沙沙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107786491A",
    "apn": "CN201610717780.0",
    "apd": "2016-08-24",
    "apy": "2016",
    "tit": "帐号验证方法及装置",
    "inv": [
      "王小叶"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106296617B",
    "apn": "CN201610700384.7",
    "apd": "2016-08-22",
    "apy": "2016",
    "tit": "人脸图像的处理方法及装置",
    "inv": [
      "袁同根"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106340023B",
    "apn": "CN201610702051.8",
    "apd": "2016-08-22",
    "apy": "2016",
    "tit": "图像分割的方法和装置",
    "inv": [
      "蒋兴华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN106339672A",
    "apn": "CN201610685589.2",
    "apd": "2016-08-18",
    "apy": "2016",
    "tit": "一种基于手势识别的邮件签名设置方法、系统及终端",
    "inv": [
      "贺桀"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106303331A",
    "apn": "CN201610686815.9",
    "apd": "2016-08-18",
    "apy": "2016",
    "tit": "视频录制方法、终端及系统",
    "inv": [
      "詹庆丰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106303595A",
    "apn": "CN201610681283.X",
    "apd": "2016-08-17",
    "apy": "2016",
    "tit": "一种智能电视信息交互方法和智能电视",
    "inv": [
      "孙卓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "多屏互动",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106231356B",
    "apn": "CN201610682592.9",
    "apd": "2016-08-17",
    "apy": "2016",
    "tit": "视频的处理方法和装置",
    "inv": [
      "布礼文",
      "徐敘遠",
      "简伟华",
      "黄嘉文",
      "袁方"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106295567B",
    "apn": "CN201610654125.5",
    "apd": "2016-08-10",
    "apy": "2016",
    "tit": "一种关键点的定位方法及终端",
    "inv": [
      "汪铖杰",
      "李季檩",
      "赵艳丹",
      "倪辉",
      "王亚彪",
      "赵凌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106210808B",
    "apn": "CN201610643369.3",
    "apd": "2016-08-08",
    "apy": "2016",
    "tit": "媒体信息投放方法、终端、服务器及系统",
    "inv": [
      "王星星"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106296605B",
    "apn": "CN201610638285.0",
    "apd": "2016-08-05",
    "apy": "2016",
    "tit": "一种图像修补方法及装置",
    "inv": [
      "蒋兴华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106789866B",
    "apn": "CN201610635742.0",
    "apd": "2016-08-04",
    "apy": "2016",
    "tit": "一种检测恶意网址的方法及装置",
    "inv": [
      "周强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107666606B",
    "apn": "CN201610621487.4",
    "apd": "2016-07-29",
    "apy": "2016",
    "tit": "双目全景图像获取方法及装置",
    "inv": [
      "姚莉",
      "王巨宏",
      "黄婷婷",
      "郑轩"
    ],
    "app": [
      "东南大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107644189A",
    "apn": "CN201610574489.2",
    "apd": "2016-07-20",
    "apy": "2016",
    "tit": "一种生物特征识别的方法及装置",
    "inv": [
      "陈杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106445995A",
    "apn": "CN201610565611.X",
    "apd": "2016-07-18",
    "apy": "2016",
    "tit": "图片的分类方法和装置",
    "inv": [
      "李智文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106228628A",
    "apn": "CN201610560334.3",
    "apd": "2016-07-15",
    "apy": "2016",
    "tit": "基于人脸识别的签到系统、方法和装置",
    "inv": [
      "于佳骏",
      "王时全",
      "陈志博",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106203514B",
    "apn": "CN201610548024.X",
    "apd": "2016-07-12",
    "apy": "2016",
    "tit": "图像识别回调通知的方法和装置",
    "inv": [
      "王淼",
      "刘冬冬",
      "陈会娟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106204588A",
    "apn": "CN201610539785.9",
    "apd": "2016-07-08",
    "apy": "2016",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "唐斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN106131627B",
    "apn": "CN201610532562.X",
    "apd": "2016-07-07",
    "apy": "2016",
    "tit": "一种视频处理方法、装置及系统",
    "inv": [
      "黄达熙",
      "宋涛",
      "程鹏",
      "周晟",
      "方璡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106162143B",
    "apn": "CN201610522270.8",
    "apd": "2016-07-04",
    "apy": "2016",
    "tit": "视差融合方法和装置",
    "inv": [
      "袁梓瑾"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106713840B",
    "apn": "CN201610490630.0",
    "apd": "2016-06-28",
    "apy": "2016",
    "tit": "虚拟信息显示方法及装置",
    "inv": [
      "陈阳",
      "王宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107526999B",
    "apn": "CN201610458513.6",
    "apd": "2016-06-22",
    "apy": "2016",
    "tit": "一种标准人脸图片更新方法、数据处理设备及系统",
    "inv": [
      "刘杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107529071B",
    "apn": "CN201610458362.4",
    "apd": "2016-06-22",
    "apy": "2016",
    "tit": "一种视频数据处理方法以及装置",
    "inv": [
      "徐敘遠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106126617B",
    "apn": "CN201610457780.1",
    "apd": "2016-06-22",
    "apy": "2016",
    "tit": "一种视频检测方法及服务器",
    "inv": [
      "徐叙远"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105959686B",
    "apn": "CN201610460125.1",
    "apd": "2016-06-22",
    "apy": "2016",
    "tit": "一种视频特征提取方法、视频匹配方法及装置",
    "inv": [
      "徐敘遠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105913476B",
    "apn": "CN201610417045.8",
    "apd": "2016-06-14",
    "apy": "2016",
    "tit": "植被图像的渲染方法和装置",
    "inv": [
      "解卫博",
      "沈俊玮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107493311A",
    "apn": "CN201610414439.8",
    "apd": "2016-06-13",
    "apy": "2016",
    "tit": "实现操控设备的方法、装置和系统",
    "inv": [
      "张磊",
      "张世鹏",
      "谢志杰",
      "万超",
      "徐欣",
      "丁超辉",
      "毛华",
      "王涛",
      "李永韬",
      "赵沫",
      "杨惠琴",
      "廖利珍",
      "刘畅",
      "王克己",
      "阮凤立"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107491716B",
    "apn": "CN201610414668.X",
    "apd": "2016-06-13",
    "apy": "2016",
    "tit": "一种人脸认证方法及装置",
    "inv": [
      "刘杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105915953A",
    "apn": "CN201610414734.3",
    "apd": "2016-06-12",
    "apy": "2016",
    "tit": "直播视频鉴别的方法、装置和系统",
    "inv": [
      "范志兴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107481341A",
    "apn": "CN201610398474.5",
    "apd": "2016-06-07",
    "apy": "2016",
    "tit": "一种签到方法及装置",
    "inv": [
      "林梅贞",
      "贺啸",
      "苏楠",
      "赖付明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107481342A",
    "apn": "CN201610402953.X",
    "apd": "2016-06-07",
    "apy": "2016",
    "tit": "考勤系统、方法、服务器和终端",
    "inv": [
      "林梅贞",
      "贺啸",
      "苏楠",
      "陈江洪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106028160A",
    "apn": "CN201610392196.2",
    "apd": "2016-06-03",
    "apy": "2016",
    "tit": "一种图像数据处理方法及其设备",
    "inv": [
      "张从玺"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107465911B",
    "apn": "CN201610382517.0",
    "apd": "2016-06-01",
    "apy": "2016",
    "tit": "一种深度信息提取方法及装置",
    "inv": [
      "姚莉",
      "刘助奎"
    ],
    "app": [
      "东南大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106096519A",
    "apn": "CN201610388213.5",
    "apd": "2016-06-01",
    "apy": "2016",
    "tit": "活体鉴别方法及装置",
    "inv": [
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "倪辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106056083B",
    "apn": "CN201610380397.0",
    "apd": "2016-05-31",
    "apy": "2016",
    "tit": "一种信息处理方法及终端",
    "inv": [
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107452049A",
    "apn": "CN201610371399.3",
    "apd": "2016-05-30",
    "apy": "2016",
    "tit": "一种三维头部建模方法及装置",
    "inv": [
      "肖业清"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "四川托普信息技术职业学院"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105913528B",
    "apn": "CN201610353026.3",
    "apd": "2016-05-25",
    "apy": "2016",
    "tit": "门禁控制数据处理方法和装置、门禁控制方法和装置",
    "inv": [
      "王时全",
      "黄飞跃",
      "吴永坚",
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105955308B",
    "apn": "CN201610339586.3",
    "apd": "2016-05-20",
    "apy": "2016",
    "tit": "一种飞行器的控制方法和装置",
    "inv": [
      "黄盈",
      "杨夏",
      "周大军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107391510A",
    "apn": "CN201610324088.1",
    "apd": "2016-05-16",
    "apy": "2016",
    "tit": "一种网络视频事件挖掘方法及系统",
    "inv": [
      "张承德"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中南财经政法大学"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN107391505A",
    "apn": "CN201610322369.3",
    "apd": "2016-05-16",
    "apy": "2016",
    "tit": "一种图像处理方法及系统",
    "inv": [
      "张俊格"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院自动化研究所"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105979092A",
    "apn": "CN201610322307.2",
    "apd": "2016-05-13",
    "apy": "2016",
    "tit": "一种异常监控方法及装置",
    "inv": [
      "范保成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107370983A",
    "apn": "CN201610318941.9",
    "apd": "2016-05-13",
    "apy": "2016",
    "tit": "用于视频监控系统的行踪轨迹的获取方法和装置",
    "inv": [
      "张磊",
      "张世鹏",
      "谢志杰",
      "万超",
      "徐欣",
      "丁超辉",
      "毛华",
      "王涛",
      "刘畅",
      "王克己",
      "阮凤立",
      "杨惠琴",
      "赵沫",
      "陈紫杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105761500B",
    "apn": "CN201610308302.4",
    "apd": "2016-05-10",
    "apy": "2016",
    "tit": "交通事故处理方法及交通事故处理装置",
    "inv": [
      "吴一凡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107358621A",
    "apn": "CN201610305056.7",
    "apd": "2016-05-10",
    "apy": "2016",
    "tit": "对象跟踪方法及装置",
    "inv": [
      "田秀霞",
      "彭源"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "上海电力学院"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106022221A",
    "apn": "CN201610302568.8",
    "apd": "2016-05-09",
    "apy": "2016",
    "tit": "一种图像处理方法及处理系统",
    "inv": [
      "郭安泰",
      "姜德强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN105763431B",
    "apn": "CN201610299024.0",
    "apd": "2016-05-06",
    "apy": "2016",
    "tit": "一种信息推送方法、装置及系统",
    "inv": [
      "陈凯斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106713681B",
    "apn": "CN201610289031.2",
    "apd": "2016-05-04",
    "apy": "2016",
    "tit": "一种信息处理方法、装置、终端及服务器",
    "inv": [
      "袁灿材",
      "吴志刚",
      "冯家耀",
      "林钊武"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105975935B",
    "apn": "CN201610293280.9",
    "apd": "2016-05-04",
    "apy": "2016",
    "tit": "一种人脸图像处理方法和装置",
    "inv": [
      "郑兆廷",
      "邱璇",
      "吴运声",
      "傅斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107315555A",
    "apn": "CN201610269048.1",
    "apd": "2016-04-27",
    "apy": "2016",
    "tit": "签到信息显示方法和装置",
    "inv": [
      "孔晶晶"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105956997B",
    "apn": "CN201610270547.2",
    "apd": "2016-04-27",
    "apy": "2016",
    "tit": "图像形变处理的方法和装置",
    "inv": [
      "钱梦仁",
      "王志斌",
      "程培",
      "邱璇",
      "李晓懿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107316343A",
    "apn": "CN201610270164.5",
    "apd": "2016-04-26",
    "apy": "2016",
    "tit": "一种基于数据驱动的模型处理方法及设备",
    "inv": [
      "高林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院计算技术研究所"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107315985A",
    "apn": "CN201610266365.8",
    "apd": "2016-04-26",
    "apy": "2016",
    "tit": "一种虹膜识别方法及终端",
    "inv": [
      "张曼"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "中国科学院自动化研究所"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105956022A",
    "apn": "CN201610257054.5",
    "apd": "2016-04-22",
    "apy": "2016",
    "tit": "电子镜图像处理方法和装置、图像处理方法和装置",
    "inv": [
      "万昌洵"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105868733A",
    "apn": "CN201610252267.9",
    "apd": "2016-04-21",
    "apy": "2016",
    "tit": "一种人脸活体验证方法及装置",
    "inv": [
      "鲍冠伯",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105956522A",
    "apn": "CN201610254906.5",
    "apd": "2016-04-21",
    "apy": "2016",
    "tit": "图片处理方法和装置",
    "inv": [
      "蒋兴华",
      "邱璇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105930797B",
    "apn": "CN201610250901.5",
    "apd": "2016-04-21",
    "apy": "2016",
    "tit": "一种人脸验证方法及装置",
    "inv": [
      "汪铖杰",
      "李季檩",
      "梁亦聪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105956518A",
    "apn": "CN201610251027.7",
    "apd": "2016-04-21",
    "apy": "2016",
    "tit": "一种人脸识别方法、装置和系统",
    "inv": [
      "张亿皓",
      "陈志博",
      "王时全"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105847728A",
    "apn": "CN201610232008.X",
    "apd": "2016-04-13",
    "apy": "2016",
    "tit": "一种信息处理方法及终端",
    "inv": [
      "吴运声",
      "吴发强",
      "戴阳刚",
      "高雨",
      "时峰",
      "汪倩怡",
      "熊涛",
      "崔凌睿",
      "应磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105898561B",
    "apn": "CN201610226956.2",
    "apd": "2016-04-13",
    "apy": "2016",
    "tit": "一种视频图像处理方法和装置",
    "inv": [
      "王志斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107295393A",
    "apn": "CN201610221533.1",
    "apd": "2016-04-11",
    "apy": "2016",
    "tit": "媒体播放中的附加媒体展示方法及装置",
    "inv": [
      "周彬",
      "张驰",
      "王贤骏"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107291737B",
    "apn": "CN201610201148.0",
    "apd": "2016-04-01",
    "apy": "2016",
    "tit": "敏感图像识别方法及装置",
    "inv": [
      "余宗桥",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105894560B",
    "apn": "CN201610192801.1",
    "apd": "2016-03-30",
    "apy": "2016",
    "tit": "一种图像处理的方法、用户设备及系统",
    "inv": [
      "金隽",
      "解卫博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105869216A",
    "apn": "CN201610188631.X",
    "apd": "2016-03-29",
    "apy": "2016",
    "tit": "目标对象展示方法和装置",
    "inv": [
      "杨若虚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107240155B",
    "apn": "CN201610187835.1",
    "apd": "2016-03-29",
    "apy": "2016",
    "tit": "一种模型对象构建的方法、服务器及3D应用系统",
    "inv": [
      "莫锡昌",
      "安柏霖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107203963B",
    "apn": "CN201610152987.8",
    "apd": "2016-03-17",
    "apy": "2016",
    "tit": "一种图像处理方法及装置、电子设备",
    "inv": [
      "吴磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107204034B",
    "apn": "CN201610154448.8",
    "apd": "2016-03-17",
    "apy": "2016",
    "tit": "一种图像处理方法及终端",
    "inv": [
      "吴磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN107204956A",
    "apn": "CN201610149762.7",
    "apd": "2016-03-16",
    "apy": "2016",
    "tit": "网站识别方法及装置",
    "inv": [
      "申瑞珉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105791692A",
    "apn": "CN201610143985.2",
    "apd": "2016-03-14",
    "apy": "2016",
    "tit": "一种信息处理方法及终端",
    "inv": [
      "汪倩怡",
      "戴阳刚",
      "应磊",
      "吴发强",
      "崔凌睿",
      "邬振海",
      "高雨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107180446A",
    "apn": "CN201610139141.0",
    "apd": "2016-03-10",
    "apy": "2016",
    "tit": "人物面部模型的表情动画生成方法及装置",
    "inv": [
      "李岚",
      "王强",
      "陈晨",
      "李小猛",
      "杨帆",
      "屈禹呈"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105809415A",
    "apn": "CN201610124812.6",
    "apd": "2016-03-04",
    "apy": "2016",
    "tit": "基于人脸识别的签到系统、方法和装置",
    "inv": [
      "黄飞跃",
      "吴永坚",
      "谭国富",
      "李季檩",
      "陈志博",
      "梁晓晴",
      "陶芝伟",
      "周可菁",
      "梅珂"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107154890A",
    "apn": "CN201610117759.7",
    "apd": "2016-03-02",
    "apy": "2016",
    "tit": "社交网络中添加联系人的方法、装置及系统",
    "inv": [
      "黄飞跃",
      "李季檩",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107154016B",
    "apn": "CN201610117909.4",
    "apd": "2016-03-01",
    "apy": "2016",
    "tit": "立体图像中目标对象的拼接方法和装置",
    "inv": [
      "李小猛",
      "李成栋",
      "王强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN107145313A",
    "apn": "CN201610116284.X",
    "apd": "2016-03-01",
    "apy": "2016",
    "tit": "图像显示方法及装置",
    "inv": [
      "张然",
      "王梓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "背景图处理",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN105787982B",
    "apn": "CN201610112549.9",
    "apd": "2016-02-29",
    "apy": "2016",
    "tit": "一种制作电子书的方法和装置",
    "inv": [
      "田亮",
      "陈凡",
      "厍寅斌",
      "陈开",
      "张锦",
      "王新柱",
      "孙淑芳"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "电子书",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107103321B",
    "apn": "CN201610097110.3",
    "apd": "2016-02-23",
    "apy": "2016",
    "tit": "道路二值化图像的生成方法及生成系统",
    "inv": [
      "付玉锦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107102786A",
    "apn": "CN201610095182.4",
    "apd": "2016-02-19",
    "apy": "2016",
    "tit": "一种信息处理方法及客户端",
    "inv": [
      "李光"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN107093171A",
    "apn": "CN201610090915.5",
    "apd": "2016-02-18",
    "apy": "2016",
    "tit": "一种图像处理方法及装置、系统",
    "inv": [
      "张磊",
      "张世鹏",
      "谢志杰",
      "万超",
      "徐欣",
      "丁超辉",
      "毛华",
      "王涛",
      "黄飞跃",
      "陈志博",
      "王时全",
      "廖利珍",
      "高杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107092603A",
    "apn": "CN201610090956.4",
    "apd": "2016-02-18",
    "apy": "2016",
    "tit": "一种图片识别方法及装置",
    "inv": [
      "李雪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN107038173A",
    "apn": "CN201610079451.8",
    "apd": "2016-02-04",
    "apy": "2016",
    "tit": "应用查询方法和装置、相似应用检测方法和装置",
    "inv": [
      "王龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "APP图标",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105681316A",
    "apn": "CN201610074819.1",
    "apd": "2016-02-02",
    "apy": "2016",
    "tit": "身份验证方法和装置",
    "inv": [
      "赵海",
      "郭振声",
      "王栋",
      "徐丰",
      "李翠",
      "陈平",
      "杨木辉",
      "那军武",
      "林晓智",
      "许士涛",
      "李季檩",
      "汪铖杰",
      "谭国富",
      "江晓力",
      "倪辉",
      "陈骏武",
      "谢建国",
      "晏栋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105740688A",
    "apn": "CN201610070773.6",
    "apd": "2016-02-01",
    "apy": "2016",
    "tit": "解锁的方法和装置",
    "inv": [
      "阳萍",
      "陆莉",
      "王小叶"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106991367B",
    "apn": "CN201610041938.7",
    "apd": "2016-01-21",
    "apy": "2016",
    "tit": "确定人脸转动角度的方法和装置",
    "inv": [
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106991362A",
    "apn": "CN201610040289.9",
    "apd": "2016-01-21",
    "apy": "2016",
    "tit": "一种针对视频的图片处理方法及装置",
    "inv": [
      "葛祥为"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106991423B",
    "apn": "CN201610038952.1",
    "apd": "2016-01-21",
    "apy": "2016",
    "tit": "识别规格图片中是否包含水印的方法及装置",
    "inv": [
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106991360B",
    "apn": "CN201610039995.1",
    "apd": "2016-01-20",
    "apy": "2016",
    "tit": "人脸识别方法及人脸识别系统",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105512646B",
    "apn": "CN201610035275.8",
    "apd": "2016-01-19",
    "apy": "2016",
    "tit": "一种数据处理方法、装置及终端",
    "inv": [
      "付玉锦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105718143A",
    "apn": "CN201610035836.4",
    "apd": "2016-01-19",
    "apy": "2016",
    "tit": "屏幕截图方法及屏幕截图装置",
    "inv": [
      "熊飞",
      "张旭",
      "张涛",
      "李强",
      "吴少勤"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105719284B",
    "apn": "CN201610031452.5",
    "apd": "2016-01-18",
    "apy": "2016",
    "tit": "一种数据处理方法、装置及终端",
    "inv": [
      "付玉锦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106941586A",
    "apn": "CN201610005502.2",
    "apd": "2016-01-05",
    "apy": "2016",
    "tit": "拍摄照片的方法和装置",
    "inv": [
      "熊涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105677735A",
    "apn": "CN201511017439.6",
    "apd": "2015-12-30",
    "apy": "2015",
    "tit": "一种视频搜索方法及装置",
    "inv": [
      "肖瑛",
      "杨振宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105450664B",
    "apn": "CN201511020306.4",
    "apd": "2015-12-29",
    "apy": "2015",
    "tit": "一种信息处理方法及终端",
    "inv": [
      "倪辉",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106919891B",
    "apn": "CN201510996643.0",
    "apd": "2015-12-26",
    "apy": "2015",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "倪辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106911550A",
    "apn": "CN201510970401.4",
    "apd": "2015-12-22",
    "apy": "2015",
    "tit": "信息推送方法、信息推送装置及系统",
    "inv": [
      "王达峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "人车互动",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106909538A",
    "apn": "CN201510964717.2",
    "apd": "2015-12-21",
    "apy": "2015",
    "tit": "使用效果展示方法及装置",
    "inv": [
      "阳丹",
      "周莜",
      "谢奕",
      "瞿佳"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106897658A",
    "apn": "CN201510964347.2",
    "apd": "2015-12-18",
    "apy": "2015",
    "tit": "人脸活体的鉴别方法和装置",
    "inv": [
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106897659B",
    "apn": "CN201510964968.0",
    "apd": "2015-12-18",
    "apy": "2015",
    "tit": "眨眼运动的识别方法和装置",
    "inv": [
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106886774A",
    "apn": "CN201510954358.2",
    "apd": "2015-12-16",
    "apy": "2015",
    "tit": "识别身份证信息的方法和装置",
    "inv": [
      "倪辉",
      "张睿欣",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106887024B",
    "apn": "CN201510943006.7",
    "apd": "2015-12-16",
    "apy": "2015",
    "tit": "照片的处理方法及处理系统",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106874921A",
    "apn": "CN201510921073.9",
    "apd": "2015-12-11",
    "apy": "2015",
    "tit": "图像分类方法和装置",
    "inv": [
      "徐昆",
      "郭晓威",
      "黄飞跃",
      "张睿欣",
      "王巨宏",
      "胡事民",
      "刘斌"
    ],
    "app": [
      "清华大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106874826A",
    "apn": "CN201510922450.0",
    "apd": "2015-12-11",
    "apy": "2015",
    "tit": "人脸关键点跟踪方法和装置",
    "inv": [
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105553947A",
    "apn": "CN201510900205.X",
    "apd": "2015-12-08",
    "apy": "2015",
    "tit": "找回帐号、保护帐号安全、帐号防盗的方法和装置",
    "inv": [
      "王小叶",
      "陆莉",
      "阳萍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106845508B",
    "apn": "CN201510890003.1",
    "apd": "2015-12-07",
    "apy": "2015",
    "tit": "一种检测图像中信息卡的方法、装置和系统",
    "inv": [
      "汪铖杰",
      "倪辉",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105447819A",
    "apn": "CN201510884587.1",
    "apd": "2015-12-04",
    "apy": "2015",
    "tit": "图像处理方法及装置",
    "inv": [
      "李凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105512627B",
    "apn": "CN201510880899.5",
    "apd": "2015-12-03",
    "apy": "2015",
    "tit": "一种关键点的定位方法及终端",
    "inv": [
      "宫鲁津"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106803055B",
    "apn": "CN201510843973.6",
    "apd": "2015-11-26",
    "apy": "2015",
    "tit": "人脸识别方法和装置",
    "inv": [
      "丁守鸿",
      "李季檩",
      "汪铖杰",
      "黄飞跃",
      "吴永坚",
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106778450A",
    "apn": "CN201510827420.1",
    "apd": "2015-11-25",
    "apy": "2015",
    "tit": "一种面部识别方法和装置",
    "inv": [
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106778454B",
    "apn": "CN201510834497.1",
    "apd": "2015-11-25",
    "apy": "2015",
    "tit": "人脸识别的方法和装置",
    "inv": [
      "汪铖杰",
      "李季檩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105512187A",
    "apn": "CN201510833256.5",
    "apd": "2015-11-25",
    "apy": "2015",
    "tit": "基于展示图片的信息展示方法及信息展示装置",
    "inv": [
      "欧阳婷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "防虚假商品图",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106776662A",
    "apn": "CN201510833467.9",
    "apd": "2015-11-25",
    "apy": "2015",
    "tit": "一种照片的分类整理方法和装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106778453A",
    "apn": "CN201510833176.X",
    "apd": "2015-11-25",
    "apy": "2015",
    "tit": "人脸图像中检测眼镜佩戴的方法及装置",
    "inv": [
      "汪铖杰",
      "谭国富",
      "倪辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106791346B",
    "apn": "CN201510807516.1",
    "apd": "2015-11-19",
    "apy": "2015",
    "tit": "一种图像预测的方法及装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105407296A",
    "apn": "CN201510798982.8",
    "apd": "2015-11-18",
    "apy": "2015",
    "tit": "实时视频增强方法和装置",
    "inv": [
      "邓海波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106713811B",
    "apn": "CN201510791121.7",
    "apd": "2015-11-17",
    "apy": "2015",
    "tit": "视频通话方法和装置",
    "inv": [
      "王法"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106713248A",
    "apn": "CN201510791517.1",
    "apd": "2015-11-17",
    "apy": "2015",
    "tit": "一种线上申请虚拟卡的方法、用户终端、线上申请虚拟卡的系统",
    "inv": [
      "郭懿心",
      "韦德志",
      "杨耿彬",
      "郑伟涛",
      "吴文勤"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106709776A",
    "apn": "CN201510791506.3",
    "apd": "2015-11-17",
    "apy": "2015",
    "tit": "商品推送方法及装置",
    "inv": [
      "谭国富",
      "黄飞跃",
      "吴永坚",
      "周可菁",
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106713255A",
    "apn": "CN201510797129.4",
    "apd": "2015-11-17",
    "apy": "2015",
    "tit": "用户信息管理方法和系统",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106709412A",
    "apn": "CN201510797278.0",
    "apd": "2015-11-17",
    "apy": "2015",
    "tit": "交通标志检测方法和装置",
    "inv": [
      "徐昆",
      "高磊",
      "薛涛",
      "桂天宜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "清华大学"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106709411A",
    "apn": "CN201510793468.5",
    "apd": "2015-11-17",
    "apy": "2015",
    "tit": "一种颜值获取方法及装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105354307A",
    "apn": "CN201510753125.6",
    "apd": "2015-11-06",
    "apy": "2015",
    "tit": "一种图像内容识别方法及装置",
    "inv": [
      "李向林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105430512A",
    "apn": "CN201510751308.4",
    "apd": "2015-11-06",
    "apy": "2015",
    "tit": "一种在视频图像上显示信息的方法和装置",
    "inv": [
      "黄利华"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106682563B",
    "apn": "CN201510749314.6",
    "apd": "2015-11-05",
    "apy": "2015",
    "tit": "一种车道线检测自适应调整方法及装置",
    "inv": [
      "张世亮",
      "王斌",
      "桂天宜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106648042A",
    "apn": "CN201510740862.2",
    "apd": "2015-11-04",
    "apy": "2015",
    "tit": "一种识别控制方法以及装置",
    "inv": [
      "钱鹰",
      "张文静",
      "王清玲",
      "张天乐"
    ],
    "app": [
      "重庆邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "电子书",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106657560B",
    "apn": "CN201510737701.8",
    "apd": "2015-11-03",
    "apy": "2015",
    "tit": "图像处理方法及移动终端",
    "inv": [
      "任亚飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106648344B",
    "apn": "CN201510731860.7",
    "apd": "2015-11-02",
    "apy": "2015",
    "tit": "一种屏幕内容调整方法及其设备",
    "inv": [
      "葛君伟",
      "王清玲",
      "范张群",
      "张玮玮",
      "崔玉岩"
    ],
    "app": [
      "重庆邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106611441B",
    "apn": "CN201510708496.2",
    "apd": "2015-10-27",
    "apy": "2015",
    "tit": "三维地图的处理方法和装置",
    "inv": [
      "张永军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106611429B",
    "apn": "CN201510700287.3",
    "apd": "2015-10-26",
    "apy": "2015",
    "tit": "检测皮肤区域的方法和检测皮肤区域的装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN106612425B",
    "apn": "CN201510697963.6",
    "apd": "2015-10-23",
    "apy": "2015",
    "tit": "图像调整方法及终端设备",
    "inv": [
      "谭国富",
      "倪辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106611402B",
    "apn": "CN201510697934.X",
    "apd": "2015-10-23",
    "apy": "2015",
    "tit": "图像处理方法及装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106611152A",
    "apn": "CN201510698690.7",
    "apd": "2015-10-23",
    "apy": "2015",
    "tit": "用户身份确定方法及装置",
    "inv": [
      "谭国富",
      "黄飞跃",
      "吴永坚",
      "周可菁",
      "陈志博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106611149A",
    "apn": "CN201510690645.7",
    "apd": "2015-10-22",
    "apy": "2015",
    "tit": "一种定位皮肤区域的方法和装置",
    "inv": [
      "谭国富",
      "倪辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106611147B",
    "apn": "CN201510670356.0",
    "apd": "2015-10-15",
    "apy": "2015",
    "tit": "车辆追踪方法和装置",
    "inv": [
      "张世亮",
      "桂天宜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106598374A",
    "apn": "CN201510673788.7",
    "apd": "2015-10-14",
    "apy": "2015",
    "tit": "用于界面的色彩过渡方法和装置",
    "inv": [
      "朱绮琦",
      "王为",
      "王倩",
      "朱向冬",
      "都人华",
      "史潇",
      "张乐"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106570445B",
    "apn": "CN201510657140.0",
    "apd": "2015-10-13",
    "apy": "2015",
    "tit": "一种特征检测方法及装置",
    "inv": [
      "张红卫",
      "桂天宜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105491396A",
    "apn": "CN201510651162.6",
    "apd": "2015-10-10",
    "apy": "2015",
    "tit": "一种多媒体信息处理方法及服务器",
    "inv": [
      "余学亮"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN106570444B",
    "apn": "CN201510652601.5",
    "apd": "2015-10-10",
    "apy": "2015",
    "tit": "基于行为识别的车载智能提醒方法及系统",
    "inv": [
      "张红卫",
      "桂天宜",
      "薛涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106570439A",
    "apn": "CN201510648221.4",
    "apd": "2015-10-09",
    "apy": "2015",
    "tit": "一种车辆检测方法及装置",
    "inv": [
      "桂天宜",
      "刘龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106570899A",
    "apn": "CN201510646160.8",
    "apd": "2015-10-08",
    "apy": "2015",
    "tit": "一种目标物体检测方法及装置",
    "inv": [
      "桂天宜",
      "刘龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106557732A",
    "apn": "CN201510644603.X",
    "apd": "2015-09-30",
    "apy": "2015",
    "tit": "一种身份认证方法及系统",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106557972A",
    "apn": "CN201510641134.6",
    "apd": "2015-09-30",
    "apy": "2015",
    "tit": "实现虚拟用户卡的信息处理方法和系统",
    "inv": [
      "郭懿心",
      "韦德志",
      "杨耿彬",
      "郑伟涛",
      "吴文勤"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106558025A",
    "apn": "CN201510631783.8",
    "apd": "2015-09-29",
    "apy": "2015",
    "tit": "一种图片的处理方法和装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106557512A",
    "apn": "CN201510632286.X",
    "apd": "2015-09-29",
    "apy": "2015",
    "tit": "图像分享方法、装置和系统",
    "inv": [
      "张磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106558039B",
    "apn": "CN201510612934.5",
    "apd": "2015-09-23",
    "apy": "2015",
    "tit": "一种人像处理方法及装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106548659B",
    "apn": "CN201510611655.7",
    "apd": "2015-09-23",
    "apy": "2015",
    "tit": "一种前向碰撞预警方法及装置、终端及计算机存储介质",
    "inv": [
      "王斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106548117B",
    "apn": "CN201510611018.X",
    "apd": "2015-09-23",
    "apy": "2015",
    "tit": "一种人脸图像处理方法和装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106558040B",
    "apn": "CN201510613580.6",
    "apd": "2015-09-23",
    "apy": "2015",
    "tit": "人物图像处理方法和装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106548114B",
    "apn": "CN201510596071.7",
    "apd": "2015-09-17",
    "apy": "2015",
    "tit": "图像处理方法、装置及计算机可读介质",
    "inv": [
      "王星星",
      "熊鹏飞",
      "黄嘉文",
      "简伟华"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106534734A",
    "apn": "CN201510580228.7",
    "apd": "2015-09-11",
    "apy": "2015",
    "tit": "视频播放、地图展示的方法和装置、数据处理方法和系统",
    "inv": [
      "恩克",
      "张弦",
      "何郢丁",
      "刘龙",
      "林法宝",
      "周康",
      "李华锋",
      "王长春"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106503534A",
    "apn": "CN201510567786.X",
    "apd": "2015-09-08",
    "apy": "2015",
    "tit": "一种信息处理方法及终端",
    "inv": [
      "丘志光",
      "侯鑫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106503609B",
    "apn": "CN201510566721.3",
    "apd": "2015-09-08",
    "apy": "2015",
    "tit": "指纹纹线点的识别方法及装置",
    "inv": [
      "王红法"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106507021A",
    "apn": "CN201510567282.8",
    "apd": "2015-09-07",
    "apy": "2015",
    "tit": "视频处理方法及终端设备",
    "inv": [
      "朱明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106485191B",
    "apn": "CN201510555903.0",
    "apd": "2015-09-02",
    "apy": "2015",
    "tit": "一种驾驶员疲劳状态检测方法及系统",
    "inv": [
      "薛涛",
      "桂天宜",
      "张红卫"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106484737A",
    "apn": "CN201510553028.2",
    "apd": "2015-09-01",
    "apy": "2015",
    "tit": "一种网络社交方法及网络社交装置",
    "inv": [
      "胡凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106485663A",
    "apn": "CN201510532779.6",
    "apd": "2015-08-26",
    "apy": "2015",
    "tit": "一种车道线图像增强方法及系统",
    "inv": [
      "张世亮",
      "桂天宜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106482709B",
    "apn": "CN201510528216.X",
    "apd": "2015-08-25",
    "apy": "2015",
    "tit": "一种车距测量的方法、装置及系统",
    "inv": [
      "王斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "自动驾驶",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106407984A",
    "apn": "CN201510464842.7",
    "apd": "2015-07-31",
    "apy": "2015",
    "tit": "目标对象识别方法及装置",
    "inv": [
      "王达峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "腾讯",
    "pun": "CN106406838A",
    "apn": "CN201510464666.7",
    "apd": "2015-07-31",
    "apy": "2015",
    "tit": "一种截屏的分享方法、装置和系统",
    "inv": [
      "张锬锬",
      "徐欣",
      "常青",
      "王诚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "多屏互动",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106372546A",
    "apn": "CN201510442627.7",
    "apd": "2015-07-24",
    "apy": "2015",
    "tit": "一种餐饮智能装置、信息处理系统及信息处理方法",
    "inv": [
      "黄达熙",
      "方璡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106371552A",
    "apn": "CN201510433741.3",
    "apd": "2015-07-22",
    "apy": "2015",
    "tit": "一种在移动终端进行媒体展示的控制方法及装置",
    "inv": [
      "庄延军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106355684B",
    "apn": "CN201510429143.9",
    "apd": "2015-07-20",
    "apy": "2015",
    "tit": "受控设备的控制方法、装置和系统",
    "inv": [
      "张磊",
      "谢志杰",
      "张世鹏",
      "万超",
      "徐欣",
      "陈明波",
      "丁超辉",
      "毛华",
      "孙丹青",
      "王金桂",
      "赵沫",
      "杨惠琴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106325841A",
    "apn": "CN201510372429.8",
    "apd": "2015-06-30",
    "apy": "2015",
    "tit": "控件图像显示方法及装置",
    "inv": [
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105045818B",
    "apn": "CN201510362248.7",
    "apd": "2015-06-26",
    "apy": "2015",
    "tit": "一种图片的推荐方法、装置和系统",
    "inv": [
      "尹程果"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106326091A",
    "apn": "CN201510354968.9",
    "apd": "2015-06-24",
    "apy": "2015",
    "tit": "一种浏览器网页兼容性的检测方法及系统",
    "inv": [
      "陆瑶瑶"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106330658A",
    "apn": "CN201510348794.5",
    "apd": "2015-06-23",
    "apy": "2015",
    "tit": "基于互联网的信息关联方法、终端、服务器和系统",
    "inv": [
      "徐冬成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104966053B",
    "apn": "CN201510319470.9",
    "apd": "2015-06-11",
    "apy": "2015",
    "tit": "人脸识别方法及识别系统",
    "inv": [
      "汪铖杰",
      "李季檩",
      "倪辉",
      "吴永坚",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106296640B",
    "apn": "CN201510312807.3",
    "apd": "2015-06-09",
    "apy": "2015",
    "tit": "一种识别模糊图像的方法和装置",
    "inv": [
      "刘思翔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106296593A",
    "apn": "CN201510287332.7",
    "apd": "2015-05-29",
    "apy": "2015",
    "tit": "图像恢复方法和装置",
    "inv": [
      "陈俊峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106295476B",
    "apn": "CN201510289727.0",
    "apd": "2015-05-29",
    "apy": "2015",
    "tit": "人脸关键点定位方法和装置",
    "inv": [
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106296634B",
    "apn": "CN201510283434.1",
    "apd": "2015-05-28",
    "apy": "2015",
    "tit": "一种检测相似图像的方法和装置",
    "inv": [
      "刘思翔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106303690A",
    "apn": "CN201510280037.9",
    "apd": "2015-05-27",
    "apy": "2015",
    "tit": "一种视频处理方法及装置",
    "inv": [
      "李晓伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104966316B",
    "apn": "CN201510268521.X",
    "apd": "2015-05-22",
    "apy": "2015",
    "tit": "一种3D人脸重建方法、装置及服务器",
    "inv": [
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "张磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN106296760A",
    "apn": "CN201510262397.6",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "图片的裁剪方法及装置",
    "inv": [
      "许小川"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN106302330A",
    "apn": "CN201510264333.X",
    "apd": "2015-05-21",
    "apy": "2015",
    "tit": "身份验证方法、装置和系统",
    "inv": [
      "黄飞跃",
      "李季檩",
      "谭国富",
      "江晓力",
      "吴丹",
      "陈骏武",
      "谢建国",
      "郭玮",
      "刘奕慧",
      "谢建东"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104966046B",
    "apn": "CN201510259823.0",
    "apd": "2015-05-20",
    "apy": "2015",
    "tit": "一种人脸关键点位定位结果的评估方法，及评估装置",
    "inv": [
      "汪铖杰",
      "李季檩",
      "黄飞跃",
      "盛柯恺",
      "董未名"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106296779B",
    "apn": "CN201510257945.6",
    "apd": "2015-05-19",
    "apy": "2015",
    "tit": "一种三维模型渲染显示方法及系统",
    "inv": [
      "王斌"
    ],
    "app": [
      "腾讯大地通途(北京)科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104966315B",
    "apn": "CN201510252704.2",
    "apd": "2015-05-18",
    "apy": "2015",
    "tit": "三维模型的处理方法和装置",
    "inv": [
      "王晔"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104837066B",
    "apn": "CN201510248899.3",
    "apd": "2015-05-15",
    "apy": "2015",
    "tit": "物品图像处理方法、装置和系统",
    "inv": [
      "刘维果",
      "刘浩鹏"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104837043A",
    "apn": "CN201510245760.3",
    "apd": "2015-05-14",
    "apy": "2015",
    "tit": "多媒体信息处理方法及电子设备",
    "inv": [
      "余学亮"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104867174B",
    "apn": "CN201510232430.0",
    "apd": "2015-05-08",
    "apy": "2015",
    "tit": "一种三维地图渲染显示方法及系统",
    "inv": [
      "冯路",
      "马腾"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104978564B",
    "apn": "CN201510230418.6",
    "apd": "2015-05-07",
    "apy": "2015",
    "tit": "一种生理特征数据处理方法及装置",
    "inv": [
      "柯向荣"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像处理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106157354B",
    "apn": "CN201510227642.X",
    "apd": "2015-05-06",
    "apy": "2015",
    "tit": "一种三维场景切换方法及系统",
    "inv": [
      "马腾",
      "李成军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104967900B",
    "apn": "CN201510221018.9",
    "apd": "2015-05-04",
    "apy": "2015",
    "tit": "一种生成视频的方法和装置",
    "inv": [
      "王超",
      "李纯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106203235A",
    "apn": "CN201510218901.2",
    "apd": "2015-04-30",
    "apy": "2015",
    "tit": "活体鉴别方法和装置",
    "inv": [
      "李季檩",
      "汪铖杰",
      "黄飞跃",
      "吴永坚",
      "倪辉",
      "张睿欣",
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106203459A",
    "apn": "CN201510213616.1",
    "apd": "2015-04-29",
    "apy": "2015",
    "tit": "图片处理方法及装置",
    "inv": [
      "钟琳琳",
      "杨朗"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN110049206A",
    "apn": "CN201910408059.7",
    "apd": "2015-04-28",
    "apy": "2015",
    "tit": "图像处理方法及装置",
    "inv": [
      "薛涛",
      "桂天宜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104767911A",
    "apn": "CN201510209374.9",
    "apd": "2015-04-28",
    "apy": "2015",
    "tit": "图像处理方法及装置",
    "inv": [
      "薛涛",
      "桂天宜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN106161030A",
    "apn": "CN201510198494.3",
    "apd": "2015-04-23",
    "apy": "2015",
    "tit": "基于图像识别的账号注册验证请求及注册验证方法和装置",
    "inv": [
      "刘小琛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106157257B",
    "apn": "CN201510197993.0",
    "apd": "2015-04-23",
    "apy": "2015",
    "tit": "图像滤波的方法和装置",
    "inv": [
      "李凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106156578A",
    "apn": "CN201510193780.0",
    "apd": "2015-04-22",
    "apy": "2015",
    "tit": "身份验证方法和装置",
    "inv": [
      "任杰"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104822070B",
    "apn": "CN201510174107.2",
    "apd": "2015-04-13",
    "apy": "2015",
    "tit": "多路视频流播放方法及装置",
    "inv": [
      "左洪涛"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106156144A",
    "apn": "CN201510172677.8",
    "apd": "2015-04-13",
    "apy": "2015",
    "tit": "信息推送方法及装置",
    "inv": [
      "颜国平",
      "马纬章",
      "刘飞飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104750848B",
    "apn": "CN201510171176.8",
    "apd": "2015-04-10",
    "apy": "2015",
    "tit": "图像文件的处理方法、服务器及图像显示设备",
    "inv": [
      "刘浩鹏"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104754367A",
    "apn": "CN201510162015.2",
    "apd": "2015-04-07",
    "apy": "2015",
    "tit": "一种多媒体信息处理方法及设备",
    "inv": [
      "周健",
      "罗少华"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106161743A",
    "apn": "CN201510157445.5",
    "apd": "2015-04-03",
    "apy": "2015",
    "tit": "一种媒体资源处理方法、装置及终端",
    "inv": [
      "陈泽滨",
      "丁海峰",
      "陆璐"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频去重",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106156807A",
    "apn": "CN201510154027.0",
    "apd": "2015-04-02",
    "apy": "2015",
    "tit": "卷积神经网络模型的训练方法及装置",
    "inv": [
      "白翔",
      "黄飞跃",
      "郭晓威",
      "姚聪",
      "石葆光"
    ],
    "app": [
      "华中科技大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN106033334A",
    "apn": "CN201510107005.9",
    "apd": "2015-03-11",
    "apy": "2015",
    "tit": "绘制界面元素的方法和装置",
    "inv": [
      "陈威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104766360B",
    "apn": "CN201510101233.5",
    "apd": "2015-03-09",
    "apy": "2015",
    "tit": "一种三维空间数据的渲染方法及装置",
    "inv": [
      "付玉锦",
      "吴伟",
      "李成军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104837034B",
    "apn": "CN201510102278.4",
    "apd": "2015-03-09",
    "apy": "2015",
    "tit": "一种信息处理方法、客户端及服务器",
    "inv": [
      "李钟伟",
      "袁国勇",
      "董振琳"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105989575B",
    "apn": "CN201510092763.8",
    "apd": "2015-03-02",
    "apy": "2015",
    "tit": "一种图像模糊处理方法和装置",
    "inv": [
      "王刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN106850648A",
    "apn": "CN201710092448.4",
    "apd": "2015-02-13",
    "apy": "2015",
    "tit": "身份验证方法、客户端和服务平台",
    "inv": [
      "黄飞跃",
      "李季檩",
      "谭国富",
      "江晓力",
      "吴丹",
      "陈骏武",
      "谢建国",
      "郭玮",
      "刘奕慧",
      "谢建东",
      "吴永坚",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105119872A",
    "apn": "CN201510080688.3",
    "apd": "2015-02-13",
    "apy": "2015",
    "tit": "身份验证方法、客户端和服务平台",
    "inv": [
      "黄飞跃",
      "李季檩",
      "谭国富",
      "江晓力",
      "吴丹",
      "陈骏武",
      "谢建国",
      "郭玮",
      "刘奕慧",
      "谢建东",
      "吴永坚",
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105989572A",
    "apn": "CN201510069655.9",
    "apd": "2015-02-10",
    "apy": "2015",
    "tit": "图片处理方法及装置",
    "inv": [
      "沈子琦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104967801B",
    "apn": "CN201510063653.9",
    "apd": "2015-02-04",
    "apy": "2015",
    "tit": "一种视频数据处理方法和装置",
    "inv": [
      "欧阳金凯",
      "李纯",
      "陈向文",
      "袁海亮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104794149A",
    "apn": "CN201510041473.0",
    "apd": "2015-01-27",
    "apy": "2015",
    "tit": "一种网络信息搜索方法、服务器、用户终端以及系统",
    "inv": [
      "张佳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105868797B",
    "apn": "CN201510031685.0",
    "apd": "2015-01-22",
    "apy": "2015",
    "tit": "识别景物类型的网络参数训练方法、景物类型识别方法及装置",
    "inv": [
      "徐昆",
      "邓海峰",
      "梁缘"
    ],
    "app": [
      "清华大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "工作/生活",
    "l2": "动植物识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104599230A",
    "apn": "CN201510021593.4",
    "apd": "2015-01-16",
    "apy": "2015",
    "tit": "视觉焦点显示方法及装置",
    "inv": [
      "冯辰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104599247B",
    "apn": "CN201510003719.5",
    "apd": "2015-01-04",
    "apy": "2015",
    "tit": "图像校正方法及装置",
    "inv": [
      "胡事民",
      "桂天宜",
      "朱哲"
    ],
    "app": [
      "清华大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104599310B",
    "apn": "CN201410841507.X",
    "apd": "2014-12-30",
    "apy": "2014",
    "tit": "三维场景动画录制方法及装置",
    "inv": [
      "王斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104901939B",
    "apn": "CN201410850789.X",
    "apd": "2014-12-30",
    "apy": "2014",
    "tit": "多媒体文件播放方法和终端及服务器",
    "inv": [
      "周彬",
      "白少林"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104616333A",
    "apn": "CN201410817538.1",
    "apd": "2014-12-24",
    "apy": "2014",
    "tit": "游戏视频处理方法及装置",
    "inv": [
      "谢志聪",
      "冯海鹏",
      "林森",
      "唐庆钰"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104616021A",
    "apn": "CN201410817079.7",
    "apd": "2014-12-24",
    "apy": "2014",
    "tit": "交通标志图像处理方法及装置",
    "inv": [
      "胡事民",
      "邓海峰",
      "黄浩智"
    ],
    "app": [
      "清华大学",
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "道路信息采集",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104811787B",
    "apn": "CN201410816434.9",
    "apd": "2014-12-24",
    "apy": "2014",
    "tit": "游戏视频录制方法及装置",
    "inv": [
      "唐庆钰",
      "杨婉婷",
      "梁琨銮",
      "叶茂瑶",
      "林森",
      "幸晖皓"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104881421B",
    "apn": "CN201410776655.8",
    "apd": "2014-12-15",
    "apy": "2014",
    "tit": "一种三维图形的切换方法及装置",
    "inv": [
      "王斌",
      "李成军"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104599315B",
    "apn": "CN201410749177.1",
    "apd": "2014-12-09",
    "apy": "2014",
    "tit": "一种三维场景的构建方法及系统",
    "inv": [
      "付玉锦"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105653550B",
    "apn": "CN201410648193.1",
    "apd": "2014-11-14",
    "apy": "2014",
    "tit": "网页过滤方法和装置",
    "inv": [
      "朱龙军",
      "孙钟前"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104966086B",
    "apn": "CN201410647776.2",
    "apd": "2014-11-14",
    "apy": "2014",
    "tit": "活体鉴别方法及装置",
    "inv": [
      "黄飞跃",
      "李季檩",
      "汪铖杰",
      "吴永坚"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104572440B",
    "apn": "CN201410625720.7",
    "apd": "2014-11-07",
    "apy": "2014",
    "tit": "一种测试软件兼容性的方法和装置",
    "inv": [
      "马识佳"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105630791A",
    "apn": "CN201410589331.3",
    "apd": "2014-10-27",
    "apy": "2014",
    "tit": "网络相册浏览方法和装置",
    "inv": [
      "李新星",
      "王敬轩",
      "李玉滔",
      "汪璇",
      "魏哲",
      "刘晶晶",
      "王雁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105590043A",
    "apn": "CN201410567734.8",
    "apd": "2014-10-22",
    "apy": "2014",
    "tit": "身份验证方法、装置及系统",
    "inv": [
      "廖晨",
      "王进",
      "张东蕊",
      "许灿冲",
      "张杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104901994B",
    "apn": "CN201410567731.4",
    "apd": "2014-10-22",
    "apy": "2014",
    "tit": "网络系统中用户的属性数值转移方法、装置及系统",
    "inv": [
      "王小叶"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104967593B",
    "apn": "CN201410545593.X",
    "apd": "2014-10-15",
    "apy": "2014",
    "tit": "一种身份验证方法、装置和系统",
    "inv": [
      "陈敏浩",
      "涂杰",
      "王玉叶",
      "陈辉",
      "陈荣",
      "郑兴"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104952092A",
    "apn": "CN201410535728.4",
    "apd": "2014-10-11",
    "apy": "2014",
    "tit": "一种图片的匹配方法及装置",
    "inv": [
      "田奇"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104952027A",
    "apn": "CN201410535950.4",
    "apd": "2014-10-11",
    "apy": "2014",
    "tit": "一种包含人脸信息的图片剪裁方法及装置",
    "inv": [
      "田奇"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN105426899B",
    "apn": "CN201410484133.0",
    "apd": "2014-09-19",
    "apy": "2014",
    "tit": "车辆识别方法、装置和客户端",
    "inv": [
      "张宇"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104967902B",
    "apn": "CN201410475199.3",
    "apd": "2014-09-17",
    "apy": "2014",
    "tit": "视频分享方法、装置及系统",
    "inv": [
      "刘健"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105404631A",
    "apn": "CN201410468582.6",
    "apd": "2014-09-15",
    "apy": "2014",
    "tit": "图片识别方法和装置",
    "inv": [
      "袁丽娜",
      "郭计伟",
      "周进",
      "张少愚",
      "林耀城"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105404845B",
    "apn": "CN201410468528.1",
    "apd": "2014-09-15",
    "apy": "2014",
    "tit": "图片处理方法及装置",
    "inv": [
      "郭晓威",
      "张睿欣",
      "侯方",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "图上贴纸/标签",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105469381A",
    "apn": "CN201410461497.7",
    "apd": "2014-09-11",
    "apy": "2014",
    "tit": "一种信息处理方法及终端",
    "inv": [
      "王文涛"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104199944B",
    "apn": "CN201410457789.3",
    "apd": "2014-09-10",
    "apy": "2014",
    "tit": "一种实现街景视图展示的方法及装置",
    "inv": [
      "袁正午",
      "张玮玮",
      "杨明"
    ],
    "app": [
      "重庆邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104966280B",
    "apn": "CN201410447204.X",
    "apd": "2014-09-04",
    "apy": "2014",
    "tit": "照片处理方法及装置",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105389835B",
    "apn": "CN201410446966.8",
    "apd": "2014-09-03",
    "apy": "2014",
    "tit": "一种图像处理方法、装置及终端",
    "inv": [
      "谭国富"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104200249B",
    "apn": "CN201410424590.0",
    "apd": "2014-08-26",
    "apy": "2014",
    "tit": "一种衣物自动搭配的方法，装置及系统",
    "inv": [
      "谢水宁",
      "冉会琼"
    ],
    "app": [
      "重庆邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "服饰搭配",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105374055B",
    "apn": "CN201410414574.3",
    "apd": "2014-08-20",
    "apy": "2014",
    "tit": "图像处理方法及装置",
    "inv": [
      "李季檩",
      "余宗桥",
      "李科"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104202604B",
    "apn": "CN201410401578.8",
    "apd": "2014-08-14",
    "apy": "2014",
    "tit": "视频增强的方法和装置",
    "inv": [
      "李凯",
      "王浦林",
      "姬晓翠"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105469376B",
    "apn": "CN201410393684.6",
    "apd": "2014-08-12",
    "apy": "2014",
    "tit": "确定图片相似度的方法和装置",
    "inv": [
      "姚伶伶",
      "赫南",
      "何琪",
      "胡繁星",
      "卫望",
      "王兵"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105468596B",
    "apn": "CN201410395792.7",
    "apd": "2014-08-12",
    "apy": "2014",
    "tit": "图片检索方法和装置",
    "inv": [
      "姚伶伶",
      "赫南",
      "何琪",
      "胡繁星",
      "卫望",
      "王兵"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105338237A",
    "apn": "CN201410385180.X",
    "apd": "2014-08-06",
    "apy": "2014",
    "tit": "图像处理方法及装置",
    "inv": [
      "袁骁",
      "唐婉莹",
      "聂奇",
      "杨溯",
      "李颖",
      "迟星德",
      "庄家琦",
      "苏智威",
      "于健"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105338403A",
    "apn": "CN201410384375.2",
    "apd": "2014-08-06",
    "apy": "2014",
    "tit": "滤镜处理方法、装置及电子设备",
    "inv": [
      "高晓宇",
      "黄本华"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105376640A",
    "apn": "CN201410384165.3",
    "apd": "2014-08-06",
    "apy": "2014",
    "tit": "滤镜处理方法、装置及电子设备",
    "inv": [
      "高晓宇"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105335956A",
    "apn": "CN201410385310.X",
    "apd": "2014-08-06",
    "apy": "2014",
    "tit": "同源图像的校验方法及装置",
    "inv": [
      "李岩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105320921B",
    "apn": "CN201410374902.1",
    "apd": "2014-07-31",
    "apy": "2014",
    "tit": "双眼定位方法及双眼定位装置",
    "inv": [
      "汪铖杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105323624B",
    "apn": "CN201410373389.4",
    "apd": "2014-07-31",
    "apy": "2014",
    "tit": "一种录制视频的方法、装置和系统",
    "inv": [
      "吴歆婉",
      "杨溯",
      "于健",
      "唐婉莹",
      "聂奇",
      "李颖",
      "迟星德",
      "庄家琦",
      "苏智威",
      "张文杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104613975B",
    "apn": "CN201410371171.5",
    "apd": "2014-07-30",
    "apy": "2014",
    "tit": "导航中显示和发送街景图的方法及设备、服务器和系统",
    "inv": [
      "李伟征"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105323420B",
    "apn": "CN201410368035.0",
    "apd": "2014-07-29",
    "apy": "2014",
    "tit": "视频图像处理方法及装置",
    "inv": [
      "戴超",
      "吕静",
      "陈伟",
      "唐骋洲",
      "王荣刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105278664B",
    "apn": "CN201410354173.3",
    "apd": "2014-07-24",
    "apy": "2014",
    "tit": "一种执行操作的方法和装置",
    "inv": [
      "沙斌斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN105321161B",
    "apn": "CN201410344546.9",
    "apd": "2014-07-18",
    "apy": "2014",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "郭安泰",
      "梁柱",
      "吴运声",
      "任博",
      "高雨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN105279186A",
    "apn": "CN201410341169.3",
    "apd": "2014-07-17",
    "apy": "2014",
    "tit": "一种图像处理方法和系统",
    "inv": [
      "吴运声",
      "黄飞跃",
      "吴永坚",
      "李科",
      "李季檩",
      "郭晓威",
      "王红法",
      "余宗桥",
      "倪辉",
      "董未名"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105279751B",
    "apn": "CN201410340486.3",
    "apd": "2014-07-17",
    "apy": "2014",
    "tit": "一种为图片进行处理的方法和装置",
    "inv": [
      "吴运声",
      "高雨",
      "傅斌",
      "周璇",
      "侯方"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105320957A",
    "apn": "CN201410328821.8",
    "apd": "2014-07-10",
    "apy": "2014",
    "tit": "分类器训练方法和装置",
    "inv": [
      "贲国生",
      "李岩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105321139A",
    "apn": "CN201410323555.X",
    "apd": "2014-07-08",
    "apy": "2014",
    "tit": "图片处理方法及装置",
    "inv": [
      "王梦溪",
      "任博",
      "龚佳毅"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105318881A",
    "apn": "CN201410321047.8",
    "apd": "2014-07-07",
    "apy": "2014",
    "tit": "地图导航方法、装置及系统",
    "inv": [
      "侯鑫",
      "陈杰",
      "汪伟",
      "郑海源",
      "何锦潮",
      "卢莎莎",
      "侯维苇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105306204A",
    "apn": "CN201410318191.6",
    "apd": "2014-07-04",
    "apy": "2014",
    "tit": "安全验证方法、装置及系统",
    "inv": [
      "郭计伟",
      "王小叶"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105241471A",
    "apn": "CN201410313020.4",
    "apd": "2014-07-02",
    "apy": "2014",
    "tit": "一种导航提示方法和装置",
    "inv": [
      "邵娜"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105227901A",
    "apn": "CN201410311109.7",
    "apd": "2014-07-01",
    "apy": "2014",
    "tit": "一种敏感信息输入的安全监控方法及装置",
    "inv": [
      "陈宪涛",
      "刘雅兰",
      "陈单枝",
      "商冲晨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104596499B",
    "apn": "CN201410301994.0",
    "apd": "2014-06-27",
    "apy": "2014",
    "tit": "一种通过图像获取进行导航的方法、装置和系统",
    "inv": [
      "李成军"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105303150B",
    "apn": "CN201410299852.5",
    "apd": "2014-06-26",
    "apy": "2014",
    "tit": "实现图像处理的方法和系统",
    "inv": [
      "李季檩",
      "陈志博",
      "邬瑞奇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105321147B",
    "apn": "CN201410295073.8",
    "apd": "2014-06-25",
    "apy": "2014",
    "tit": "图像处理的方法及装置",
    "inv": [
      "郭安泰",
      "任博",
      "高雨",
      "杨阳",
      "刘银松"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105184735B",
    "apn": "CN201410275849.X",
    "apd": "2014-06-19",
    "apy": "2014",
    "tit": "一种人像变形方法及装置",
    "inv": [
      "余三思"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105303514B",
    "apn": "CN201410271385.5",
    "apd": "2014-06-17",
    "apy": "2014",
    "tit": "图像处理方法和装置",
    "inv": [
      "吴磊",
      "梁柱",
      "任博",
      "黄小明",
      "王梦溪"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105205066A",
    "apn": "CN201410268464.0",
    "apd": "2014-06-16",
    "apy": "2014",
    "tit": "一种图片查找方法和装置",
    "inv": [
      "吕书田",
      "沈珂轶",
      "邱璇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105302812A",
    "apn": "CN201410267570.7",
    "apd": "2014-06-16",
    "apy": "2014",
    "tit": "图像信息处理方法及装置",
    "inv": [
      "陈志博",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104093079B",
    "apn": "CN201410265804.4",
    "apd": "2014-06-13",
    "apy": "2014",
    "tit": "基于多媒体节目的交互方法、终端、服务器和系统",
    "inv": [
      "叶娃",
      "王泓扬",
      "侯杰",
      "徐震宇",
      "唐卓",
      "王蕊",
      "黎可人",
      "孙博",
      "臧静"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104023249B",
    "apn": "CN201410262126.6",
    "apd": "2014-06-12",
    "apy": "2014",
    "tit": "电视频道识别方法和装置",
    "inv": [
      "姜媚",
      "刘海龙",
      "刘荐",
      "陈波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104966312B",
    "apn": "CN201410255533.4",
    "apd": "2014-06-10",
    "apy": "2014",
    "tit": "一种3D模型的渲染方法、装置及终端设备",
    "inv": [
      "曾纵望"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105279726A",
    "apn": "CN201410256570.7",
    "apd": "2014-06-10",
    "apy": "2014",
    "tit": "水印图层生成方法及系统、水印图像生成方法及系统",
    "inv": [
      "倪浩"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105282626A",
    "apn": "CN201410255082.4",
    "apd": "2014-06-10",
    "apy": "2014",
    "tit": "基于视频分享应用的视频文件处理方法和装置",
    "inv": [
      "黄本华"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104596523B",
    "apn": "CN201410247087.2",
    "apd": "2014-06-05",
    "apy": "2014",
    "tit": "一种街景目的地引导方法和设备",
    "inv": [
      "马腾"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105303149B",
    "apn": "CN201410233954.7",
    "apd": "2014-05-29",
    "apy": "2014",
    "tit": "人物图像的展示方法和装置",
    "inv": [
      "袁青云"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105205487B",
    "apn": "CN201410230512.7",
    "apd": "2014-05-28",
    "apy": "2014",
    "tit": "一种图片处理方法及装置",
    "inv": [
      "张博"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN105096350B",
    "apn": "CN201410216357.3",
    "apd": "2014-05-21",
    "apy": "2014",
    "tit": "图像检测方法及装置",
    "inv": [
      "李岩",
      "梅树起"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104598504A",
    "apn": "CN201410204688.5",
    "apd": "2014-05-15",
    "apy": "2014",
    "tit": "电子地图的信息显示控制方法和装置",
    "inv": [
      "杜娟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105096355B",
    "apn": "CN201410193717.2",
    "apd": "2014-05-08",
    "apy": "2014",
    "tit": "图像处理方法和系统",
    "inv": [
      "王景宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104613973B",
    "apn": "CN201410191431.0",
    "apd": "2014-05-07",
    "apy": "2014",
    "tit": "图片生成、展示方法和装置",
    "inv": [
      "廖正中",
      "江红英",
      "张弦",
      "田丽娜",
      "孙尚旖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN105096353A",
    "apn": "CN201410186450.4",
    "apd": "2014-05-05",
    "apy": "2014",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "余宗桥",
      "李科",
      "李季檩",
      "黄飞跃",
      "谢志峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105005755B",
    "apn": "CN201410171665.9",
    "apd": "2014-04-25",
    "apy": "2014",
    "tit": "三维人脸识别方法和系统",
    "inv": [
      "明悦",
      "蒋杰",
      "刘婷婷",
      "王巨宏"
    ],
    "app": [
      "北京邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105022982B",
    "apn": "CN201410163979.4",
    "apd": "2014-04-22",
    "apy": "2014",
    "tit": "手部运动识别方法和装置",
    "inv": [
      "明悦",
      "蒋杰",
      "刘婷婷",
      "王巨宏"
    ],
    "app": [
      "北京邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN105005960A",
    "apn": "CN201410161589.3",
    "apd": "2014-04-21",
    "apy": "2014",
    "tit": "获取水印照片的方法、装置及系统",
    "inv": [
      "高雨",
      "袁青云",
      "邬振海",
      "王景宇",
      "王亚晨",
      "郑兆廷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN105023259B",
    "apn": "CN201410159269.4",
    "apd": "2014-04-18",
    "apy": "2014",
    "tit": "图片融合方法、装置、终端和计算机可读存储介质",
    "inv": [
      "王玉龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104980643A",
    "apn": "CN201410141092.5",
    "apd": "2014-04-10",
    "apy": "2014",
    "tit": "图片处理方法、终端及系统",
    "inv": [
      "傅斌",
      "吕书田",
      "卢章永",
      "吴发强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104980791A",
    "apn": "CN201410138225.3",
    "apd": "2014-04-08",
    "apy": "2014",
    "tit": "一种对视频进行滤镜处理的方法及装置",
    "inv": [
      "黄本华"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104978712B",
    "apn": "CN201410138449.4",
    "apd": "2014-04-08",
    "apy": "2014",
    "tit": "图像处理方法及装置",
    "inv": [
      "黄鹏程"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104978553B",
    "apn": "CN201410138389.6",
    "apd": "2014-04-08",
    "apy": "2014",
    "tit": "图像分析的方法及装置",
    "inv": [
      "余三思"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104978549B",
    "apn": "CN201410133560.4",
    "apd": "2014-04-03",
    "apy": "2014",
    "tit": "三维人脸图像特征提取方法和系统",
    "inv": [
      "明悦",
      "蒋杰",
      "刘婷婷",
      "王巨宏"
    ],
    "app": [
      "北京邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104952026B",
    "apn": "CN201410128161.9",
    "apd": "2014-03-31",
    "apy": "2014",
    "tit": "图像处理的方法及装置",
    "inv": [
      "侯方",
      "单佩佩",
      "王景宇",
      "戴阳刚",
      "姚达"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104952089B",
    "apn": "CN201410116571.1",
    "apd": "2014-03-26",
    "apy": "2014",
    "tit": "一种图像处理方法及系统",
    "inv": [
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104820665A",
    "apn": "CN201410099228.0",
    "apd": "2014-03-17",
    "apy": "2014",
    "tit": "展示推荐信息的方法、终端及服务器",
    "inv": [
      "左洪涛",
      "何健"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104918044B",
    "apn": "CN201410096366.3",
    "apd": "2014-03-14",
    "apy": "2014",
    "tit": "图像处理方法及装置",
    "inv": [
      "李飞云",
      "任桥",
      "赵明"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104915354A",
    "apn": "CN201410091343.3",
    "apd": "2014-03-12",
    "apy": "2014",
    "tit": "多媒体文件推送方法及装置",
    "inv": [
      "肖磊"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104899820B",
    "apn": "CN201410088783.3",
    "apd": "2014-03-11",
    "apy": "2014",
    "tit": "为图像添加标签的方法、系统和装置",
    "inv": [
      "周莜",
      "戴佳瑶",
      "瞿佳",
      "谢奕"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104915625B",
    "apn": "CN201410088003.5",
    "apd": "2014-03-11",
    "apy": "2014",
    "tit": "一种人脸识别的方法及装置",
    "inv": [
      "米建勋"
    ],
    "app": [
      "重庆邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104915626A",
    "apn": "CN201410088084.9",
    "apd": "2014-03-11",
    "apy": "2014",
    "tit": "一种人脸识别方法及装置",
    "inv": [
      "李伟生",
      "王立逗"
    ],
    "app": [
      "重庆邮电大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104902163B",
    "apn": "CN201410084616.1",
    "apd": "2014-03-07",
    "apy": "2014",
    "tit": "图片处理方法和装置、图片生成方法和装置",
    "inv": [
      "魏志伟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104899825B",
    "apn": "CN201410080691.0",
    "apd": "2014-03-06",
    "apy": "2014",
    "tit": "一种对图片人物造型的方法和装置",
    "inv": [
      "郑志昊",
      "余宗桥",
      "郭晓威",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104899080A",
    "apn": "CN201410079835.0",
    "apd": "2014-03-05",
    "apy": "2014",
    "tit": "即时通讯切换实时画面的方法和装置",
    "inv": [
      "杨崇哲",
      "付茂卿",
      "冼业成",
      "刘梦诗",
      "杨光"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104899853A",
    "apn": "CN201410077257.7",
    "apd": "2014-03-04",
    "apy": "2014",
    "tit": "图像区域的划分方法及装置",
    "inv": [
      "袁同根"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104157007B",
    "apn": "CN201410075138.8",
    "apd": "2014-03-03",
    "apy": "2014",
    "tit": "视频处理的方法及装置",
    "inv": [
      "李飞云",
      "黄石柱"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104867097A",
    "apn": "CN201410067738.X",
    "apd": "2014-02-26",
    "apy": "2014",
    "tit": "一种水印图片的生成方法和装置",
    "inv": [
      "郑志昊",
      "吴发强",
      "吕书田",
      "郑兆廷"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104618803B",
    "apn": "CN201410066693.4",
    "apd": "2014-02-26",
    "apy": "2014",
    "tit": "信息推送方法、装置、终端及服务器",
    "inv": [
      "王璟瑶"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104866809A",
    "apn": "CN201410064770.2",
    "apd": "2014-02-25",
    "apy": "2014",
    "tit": "画面播放方法和装置",
    "inv": [
      "侯方",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104867096A",
    "apn": "CN201410064787.8",
    "apd": "2014-02-25",
    "apy": "2014",
    "tit": "水印内容动态变换的方法和装置",
    "inv": [
      "郑志昊",
      "王亚晨",
      "张子鋆",
      "傅斌",
      "肖曦",
      "时峰",
      "徐冬成",
      "高雨",
      "倪浩",
      "吴江红",
      "王景宇",
      "沈珂轶",
      "王梦溪",
      "李科慧",
      "郑兆廷",
      "吴运声",
      "吴发强",
      "戴阳刚",
      "张腾",
      "陈展"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104866808B",
    "apn": "CN201410063247.8",
    "apd": "2014-02-24",
    "apy": "2014",
    "tit": "人眼定位方法及装置",
    "inv": [
      "蒋兴华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104866807B",
    "apn": "CN201410062695.6",
    "apd": "2014-02-24",
    "apy": "2014",
    "tit": "一种人脸定位方法及系统",
    "inv": [
      "张颖",
      "李季檩",
      "郑昉劢",
      "倪辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104866414B",
    "apn": "CN201410063291.9",
    "apd": "2014-02-24",
    "apy": "2014",
    "tit": "应用程序的测试方法、装置及系统",
    "inv": [
      "李鸿翔"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104867095A",
    "apn": "CN201410059147.8",
    "apd": "2014-02-21",
    "apy": "2014",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "高雨",
      "李科慧",
      "徐冬成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104866805A",
    "apn": "CN201410059064.9",
    "apd": "2014-02-20",
    "apy": "2014",
    "tit": "人脸实时跟踪的方法和装置",
    "inv": [
      "李科",
      "姚达",
      "郑昉劢"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104850820B",
    "apn": "CN201410056495.X",
    "apd": "2014-02-19",
    "apy": "2014",
    "tit": "一种脸部识别方法及装置",
    "inv": [
      "郭安泰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103793521B",
    "apn": "CN201410055503.9",
    "apd": "2014-02-18",
    "apy": "2014",
    "tit": "图片处理方法和装置",
    "inv": [
      "梁柱",
      "高雨",
      "邱璇",
      "吴发强",
      "周丹"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104835105A",
    "apn": "CN201410049342.2",
    "apd": "2014-02-12",
    "apy": "2014",
    "tit": "图片处理方法和装置",
    "inv": [
      "郑志昊",
      "傅斌",
      "王佳",
      "李晓懿"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104809694A",
    "apn": "CN201410031186.7",
    "apd": "2014-01-23",
    "apy": "2014",
    "tit": "一种数字图像处理方法和装置",
    "inv": [
      "蒋兴华"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104796660B",
    "apn": "CN201410026454.6",
    "apd": "2014-01-20",
    "apy": "2014",
    "tit": "一种防盗告警的方法及装置",
    "inv": [
      "马瑞林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104794685B",
    "apn": "CN201410022974.X",
    "apd": "2014-01-17",
    "apy": "2014",
    "tit": "一种实现图像去噪的方法及装置",
    "inv": [
      "姜德强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104766041A",
    "apn": "CN201410007005.7",
    "apd": "2014-01-07",
    "apy": "2014",
    "tit": "一种图像识别方法、装置及系统",
    "inv": [
      "郑志昊",
      "黄飞跃",
      "侯方",
      "郭晓威"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104750737B",
    "apn": "CN201310746306.7",
    "apd": "2013-12-30",
    "apy": "2013",
    "tit": "一种相册管理方法及装置",
    "inv": [
      "黄飞跃",
      "郑志昊",
      "梁柱",
      "黄琴",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104715445B",
    "apn": "CN201310690424.0",
    "apd": "2013-12-13",
    "apy": "2013",
    "tit": "图像处理方法和系统",
    "inv": [
      "姜德强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104715224B",
    "apn": "CN201310675181.3",
    "apd": "2013-12-11",
    "apy": "2013",
    "tit": "一种获取用户群体的面部特征图像的方法及装置",
    "inv": [
      "侯方",
      "吴永坚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104679627B",
    "apn": "CN201310634527.5",
    "apd": "2013-12-02",
    "apy": "2013",
    "tit": "测试方法及装置",
    "inv": [
      "崔祥",
      "王伟",
      "李伟博",
      "张彦涛",
      "李晓娇",
      "张恕"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104679466B",
    "apn": "CN201310631944.4",
    "apd": "2013-11-29",
    "apy": "2013",
    "tit": "一种图像显示方法、装置及电子设备",
    "inv": [
      "张敏",
      "郭晓伟",
      "刘凌",
      "罗根",
      "傅仲",
      "陈鸿图",
      "骆晓君",
      "李博",
      "夏磊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104680121A",
    "apn": "CN201310636576.2",
    "apd": "2013-11-27",
    "apy": "2013",
    "tit": "一种人脸图像的处理方法及装置",
    "inv": [
      "郑志昊",
      "侯方",
      "吴永坚",
      "倪辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104657974A",
    "apn": "CN201310606873.2",
    "apd": "2013-11-25",
    "apy": "2013",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "郑志昊",
      "黄飞跃",
      "侯方"
    ],
    "app": [
      "腾讯科技(上海)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104133665B",
    "apn": "CN201310585139.2",
    "apd": "2013-11-19",
    "apy": "2013",
    "tit": "基于图像检测的定位方法与装置",
    "inv": [
      "林森",
      "李炯",
      "唐庆钰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104637078B",
    "apn": "CN201310573504.8",
    "apd": "2013-11-14",
    "apy": "2013",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "于晓光",
      "黄曙光",
      "郭晓威",
      "贺飏",
      "张丞昭",
      "黄飞跃",
      "余宗桥"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104599122A",
    "apn": "CN201310530498.8",
    "apd": "2013-10-31",
    "apy": "2013",
    "tit": "一种快捷支付方法及相关设备、系统",
    "inv": [
      "张育淼"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104066009B",
    "apn": "CN201310533874.9",
    "apd": "2013-10-31",
    "apy": "2013",
    "tit": "节目识别方法、装置、终端、服务器及系统",
    "inv": [
      "张小龙",
      "邝俊斌",
      "柳洋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104599126B",
    "apn": "CN201310530089.8",
    "apd": "2013-10-30",
    "apy": "2013",
    "tit": "一种安全支付方法、相关装置及系统",
    "inv": [
      "张育淼"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104580930B",
    "apn": "CN201310515969.8",
    "apd": "2013-10-28",
    "apy": "2013",
    "tit": "合影方法及系统",
    "inv": [
      "钟锦松",
      "李诚",
      "郑晖",
      "杨振宇",
      "钟颖",
      "黄珊珊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104580121B",
    "apn": "CN201310516675.7",
    "apd": "2013-10-28",
    "apy": "2013",
    "tit": "寻人/人员信息匹配推送的方法、系统、客户端和服务器",
    "inv": [
      "姜大巍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104572732A",
    "apn": "CN201310500623.0",
    "apd": "2013-10-22",
    "apy": "2013",
    "tit": "查询用户标识的方法及装置、获取用户标识的方法及装置",
    "inv": [
      "姜大巍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104090879A",
    "apn": "CN201310462720.5",
    "apd": "2013-09-30",
    "apy": "2013",
    "tit": "一种图片分享方法、装置和系统",
    "inv": [
      "王梦溪",
      "梁柱",
      "高雨",
      "李晓懿",
      "傅斌",
      "边前卫",
      "吴运声"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104516812A",
    "apn": "CN201310450584.8",
    "apd": "2013-09-27",
    "apy": "2013",
    "tit": "一种软件测试方法和装置",
    "inv": [
      "吴英",
      "李凌虹"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104378542A",
    "apn": "CN201310353909.0",
    "apd": "2013-08-14",
    "apy": "2013",
    "tit": "一种媒体内容的处理方法及装置、终端设备",
    "inv": [
      "丁如敏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104376003B",
    "apn": "CN201310351539.7",
    "apd": "2013-08-13",
    "apy": "2013",
    "tit": "一种视频检索方法及装置",
    "inv": [
      "梅树起",
      "刘伯兴"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104346368A",
    "apn": "CN201310326944.3",
    "apd": "2013-07-30",
    "apy": "2013",
    "tit": "室内场景切换显示方法、装置和移动终端",
    "inv": [
      "程陆宁"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104349021A",
    "apn": "CN201310314013.1",
    "apd": "2013-07-24",
    "apy": "2013",
    "tit": "图像采集方法、装置及终端",
    "inv": [
      "廖海珍"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104333564A",
    "apn": "CN201310309287.1",
    "apd": "2013-07-22",
    "apy": "2013",
    "tit": "目标操作方法、系统及设备",
    "inv": [
      "牟伟成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104143104A",
    "apn": "CN201310288861.X",
    "apd": "2013-07-10",
    "apy": "2013",
    "tit": "一种图像识别方法、装置、终端设备及服务器",
    "inv": [
      "周德懋",
      "肖斌",
      "黎可人",
      "陈品霖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "腾讯",
    "pun": "CN104284125B",
    "apn": "CN201310284295.5",
    "apd": "2013-07-08",
    "apy": "2013",
    "tit": "一种多媒体拍摄处理方法、装置及系统",
    "inv": [
      "许华彬",
      "张明威",
      "张陈博男",
      "王明慧"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN104281833B",
    "apn": "CN201310284730.4",
    "apd": "2013-07-08",
    "apy": "2013",
    "tit": "色情图像识别方法和装置",
    "inv": [
      "梅树起"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104078035B",
    "apn": "CN201310275056.3",
    "apd": "2013-07-02",
    "apy": "2013",
    "tit": "一种演奏乐曲的方法及装置",
    "inv": [
      "王新宇"
    ],
    "app": [
      "广州酷狗计算机科技有限公司"
    ],
    "l1": "教育",
    "l2": "教学",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103425736B",
    "apn": "CN201310254215.1",
    "apd": "2013-06-24",
    "apy": "2013",
    "tit": "一种网页信息识别方法、装置及系统",
    "inv": [
      "王佳斌",
      "罗谷才"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104240277B",
    "apn": "CN201310253772.1",
    "apd": "2013-06-24",
    "apy": "2013",
    "tit": "基于人脸检测的增强现实交互方法和系统",
    "inv": [
      "王玉龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104219130B",
    "apn": "CN201310206566.5",
    "apd": "2013-05-29",
    "apy": "2013",
    "tit": "一种官方邮件识别方法及装置",
    "inv": [
      "陈梦",
      "董梁",
      "常瑞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104182962A",
    "apn": "CN201310204043.7",
    "apd": "2013-05-28",
    "apy": "2013",
    "tit": "评价图片清晰度的方法及装置",
    "inv": [
      "厚桂娟"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN104182671A",
    "apn": "CN201310196171.1",
    "apd": "2013-05-23",
    "apy": "2013",
    "tit": "一种浏览器的隐私信息保护方法及装置",
    "inv": [
      "牛东升",
      "鲁静罡"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN109711304A",
    "apn": "CN201811554203.X",
    "apd": "2013-05-21",
    "apy": "2013",
    "tit": "一种人脸特征点定位方法及装置",
    "inv": [
      "何金文",
      "龙彦波"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104182718B",
    "apn": "CN201310189786.1",
    "apd": "2013-05-21",
    "apy": "2013",
    "tit": "一种人脸特征点定位方法及装置",
    "inv": [
      "何金文",
      "龙彦波"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104158790B",
    "apn": "CN201310177304.0",
    "apd": "2013-05-14",
    "apy": "2013",
    "tit": "用户登录方法、装置及设备",
    "inv": [
      "侯杰",
      "熊鹏飞",
      "刘严",
      "刘海龙",
      "陈波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104143079B",
    "apn": "CN201310172492.8",
    "apd": "2013-05-10",
    "apy": "2013",
    "tit": "人脸属性识别的方法和系统",
    "inv": [
      "熊鹏飞",
      "刘海龙",
      "陈波",
      "刘严"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104143078B",
    "apn": "CN201310169250.3",
    "apd": "2013-05-09",
    "apy": "2013",
    "tit": "活体人脸识别方法、装置和设备",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104143076B",
    "apn": "CN201310169188.8",
    "apd": "2013-05-09",
    "apy": "2013",
    "tit": "人脸形状的匹配方法和系统",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104143097B",
    "apn": "CN201310169175.0",
    "apd": "2013-05-09",
    "apy": "2013",
    "tit": "分类函数获取方法、人脸年龄识别方法、装置和设备",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104142936A",
    "apn": "CN201310164776.2",
    "apd": "2013-05-07",
    "apy": "2013",
    "tit": "匹配音视频的方法和装置",
    "inv": [
      "张云",
      "董重里"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN106969774A",
    "apn": "CN201710262213.5",
    "apd": "2013-04-28",
    "apy": "2013",
    "tit": "导航方法与装置、终端、服务器及系统",
    "inv": [
      "邝俊斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104121910A",
    "apn": "CN201310157571.1",
    "apd": "2013-04-28",
    "apy": "2013",
    "tit": "导航方法与装置、终端、服务器及系统",
    "inv": [
      "邝俊斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104123221A",
    "apn": "CN201310157757.7",
    "apd": "2013-04-28",
    "apy": "2013",
    "tit": "响应时间的测试方法及装置",
    "inv": [
      "傅学君",
      "徐铮"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103377287B",
    "apn": "CN201310148026.6",
    "apd": "2013-04-25",
    "apy": "2013",
    "tit": "一种投放物品信息的方法和装置",
    "inv": [
      "刘海龙",
      "肖斌",
      "查文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN104102678B",
    "apn": "CN201310129360.7",
    "apd": "2013-04-15",
    "apy": "2013",
    "tit": "增强现实的实现方法以及实现装置",
    "inv": [
      "曹晨",
      "崔欣宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103218844B",
    "apn": "CN201310113497.3",
    "apd": "2013-04-03",
    "apy": "2013",
    "tit": "虚拟形象的配置方法、实现方法、客户端、服务器及系统",
    "inv": [
      "李科佑",
      "汤焱彬",
      "沈婧",
      "黄敏",
      "詹昊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104076898B",
    "apn": "CN201310101229.X",
    "apd": "2013-03-27",
    "apy": "2013",
    "tit": "一种控制移动终端屏幕亮度的方法和装置",
    "inv": [
      "廖志",
      "宁京",
      "尹程果"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104077214B",
    "apn": "CN201310101067.X",
    "apd": "2013-03-26",
    "apy": "2013",
    "tit": "软件系统加载过程图像识别方法及系统",
    "inv": [
      "万宇"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104079468A",
    "apn": "CN201310098660.3",
    "apd": "2013-03-25",
    "apy": "2013",
    "tit": "动画传输方法和系统",
    "inv": [
      "李鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN104065888A",
    "apn": "CN201310091374.4",
    "apd": "2013-03-21",
    "apy": "2013",
    "tit": "通信终端视频制作方法及制作装置",
    "inv": [
      "宋丹"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN104063844B",
    "apn": "CN201310092220.7",
    "apd": "2013-03-21",
    "apy": "2013",
    "tit": "一种缩略图生成方法及系统",
    "inv": [
      "王川南"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103177474B",
    "apn": "CN201310081110.0",
    "apd": "2013-03-14",
    "apy": "2013",
    "tit": "三维模型的邻域点坐标确定方法及装置、构建方法及装置",
    "inv": [
      "马腾",
      "李成军",
      "李保利"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104050177B",
    "apn": "CN201310079271.6",
    "apd": "2013-03-13",
    "apy": "2013",
    "tit": "街景生成方法及服务器",
    "inv": [
      "屈孝志"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN104035813B",
    "apn": "CN201310071241.0",
    "apd": "2013-03-06",
    "apy": "2013",
    "tit": "跨平台交互的检测方法及装置",
    "inv": [
      "张锦铭",
      "丁如敏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN104036471B",
    "apn": "CN201310069373.X",
    "apd": "2013-03-05",
    "apy": "2013",
    "tit": "一种图像噪声估值方法及图像噪声估值装置",
    "inv": [
      "孙金阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN103198473B",
    "apn": "CN201310069640.3",
    "apd": "2013-03-05",
    "apy": "2013",
    "tit": "一种深度图生成方法及装置",
    "inv": [
      "马腾",
      "李保利",
      "李成军",
      "屈孝志"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103150759B",
    "apn": "CN201310069545.3",
    "apd": "2013-03-05",
    "apy": "2013",
    "tit": "一种对街景图像进行动态增强的方法和装置",
    "inv": [
      "桂天宜"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "视觉定位",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103177475B",
    "apn": "CN201310066729.4",
    "apd": "2013-03-04",
    "apy": "2013",
    "tit": "一种街景地图展现方法及系统",
    "inv": [
      "万聪"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103106272B",
    "apn": "CN201310047173.4",
    "apd": "2013-02-06",
    "apy": "2013",
    "tit": "兴趣点信息获取方法及装置",
    "inv": [
      "石再杰"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "高精地图",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103970804B",
    "apn": "CN201310046415.8",
    "apd": "2013-02-06",
    "apy": "2013",
    "tit": "一种信息查询方法及装置",
    "inv": [
      "黄达熙",
      "贾云",
      "张振伟",
      "王令"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN103971112B",
    "apn": "CN201310046365.3",
    "apd": "2013-02-05",
    "apy": "2013",
    "tit": "图像特征提取方法及装置",
    "inv": [
      "郭安泰",
      "孙金阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103971330B",
    "apn": "CN201310046029.9",
    "apd": "2013-02-05",
    "apy": "2013",
    "tit": "图像增强方法及装置",
    "inv": [
      "邓海峰",
      "李保利",
      "张弦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103971356B",
    "apn": "CN201310043317.9",
    "apd": "2013-02-04",
    "apy": "2013",
    "tit": "基于视差信息的街景图像目标分割方法及装置",
    "inv": [
      "屈孝志",
      "马腾"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103971399B",
    "apn": "CN201310037566.7",
    "apd": "2013-01-30",
    "apy": "2013",
    "tit": "街景图像过渡方法和装置",
    "inv": [
      "徐昆",
      "王建宇",
      "李保利",
      "李成军",
      "黄浩智"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司",
      "清华大学"
    ],
    "l1": "车辆/交通",
    "l2": "三维地图",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103973441B",
    "apn": "CN201310033654.X",
    "apd": "2013-01-29",
    "apy": "2013",
    "tit": "基于音视频的用户认证方法和装置",
    "inv": [
      "张翔",
      "卢鲤",
      "王尔玉",
      "岳帅",
      "饶丰",
      "刘海波",
      "李露",
      "陆读羚",
      "陈波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103105993B",
    "apn": "CN201310030109.5",
    "apd": "2013-01-25",
    "apy": "2013",
    "tit": "基于增强现实技术实现互动的方法和系统",
    "inv": [
      "张振伟",
      "王令",
      "肖芬",
      "吴哲惠"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103942754B",
    "apn": "CN201310019530.6",
    "apd": "2013-01-18",
    "apy": "2013",
    "tit": "全景图像补全方法及装置",
    "inv": [
      "徐昆",
      "王建宇",
      "李保利",
      "邓海峰",
      "朱哲"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司",
      "清华大学"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103942750A",
    "apn": "CN201310017542.5",
    "apd": "2013-01-17",
    "apy": "2013",
    "tit": "录制屏幕图像的方法及装置",
    "inv": [
      "付云雷",
      "袁树健",
      "曾健烽"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN103927509B",
    "apn": "CN201310015921.0",
    "apd": "2013-01-16",
    "apy": "2013",
    "tit": "眼睛定位方法及装置",
    "inv": [
      "郭安泰",
      "余三思"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103914861B",
    "apn": "CN201310006129.9",
    "apd": "2013-01-08",
    "apy": "2013",
    "tit": "图片处理方法及装置",
    "inv": [
      "郭凯",
      "陈皓"
    ],
    "app": [
      "腾讯科技(武汉)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103916621A",
    "apn": "CN201310003670.4",
    "apd": "2013-01-06",
    "apy": "2013",
    "tit": "视频通信方法及装置",
    "inv": [
      "江修才",
      "陈波",
      "刘海龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103902192A",
    "apn": "CN201210583819.6",
    "apd": "2012-12-28",
    "apy": "2012",
    "tit": "人机交互操作的触发控制方法和装置",
    "inv": [
      "周彬",
      "盛森"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103903224B",
    "apn": "CN201210570565.4",
    "apd": "2012-12-25",
    "apy": "2012",
    "tit": "数字图像条带噪声的处理方法及装置",
    "inv": [
      "李辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103903244B",
    "apn": "CN201210569912.1",
    "apd": "2012-12-25",
    "apy": "2012",
    "tit": "一种图像相似区块搜索方法及装置",
    "inv": [
      "孙金阳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN103903221B",
    "apn": "CN201210567953.7",
    "apd": "2012-12-24",
    "apy": "2012",
    "tit": "一种图片生成方法、装置和系统",
    "inv": [
      "张红林"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN103886622B",
    "apn": "CN201210560218.3",
    "apd": "2012-12-21",
    "apy": "2012",
    "tit": "自动图像区域划分的实现方法及实现装置",
    "inv": [
      "陈皓",
      "郭凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN103871028B",
    "apn": "CN201210548427.6",
    "apd": "2012-12-17",
    "apy": "2012",
    "tit": "图片缺陷调整方法及系统",
    "inv": [
      "蒋兴华",
      "余三思"
    ],
    "app": [
      "合肥市腾讯信息科技有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103853959A",
    "apn": "CN201210516847.6",
    "apd": "2012-12-05",
    "apy": "2012",
    "tit": "权限控制装置及方法",
    "inv": [
      "付云雷",
      "袁树健",
      "张亚峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103856614A",
    "apn": "CN201210510570.6",
    "apd": "2012-12-04",
    "apy": "2012",
    "tit": "一种避免移动终端误休眠方法及装置",
    "inv": [
      "李飞云",
      "黄石柱",
      "任桥",
      "周连江"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103839042B",
    "apn": "CN201210491316.6",
    "apd": "2012-11-27",
    "apy": "2012",
    "tit": "人脸识别方法和人脸识别系统",
    "inv": [
      "熊鹏飞"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103838357B",
    "apn": "CN201210478609.0",
    "apd": "2012-11-22",
    "apy": "2012",
    "tit": "一种画面交互方法、装置、系统和移动终端",
    "inv": [
      "周彬",
      "王明亮"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103841384A",
    "apn": "CN201210471817.8",
    "apd": "2012-11-20",
    "apy": "2012",
    "tit": "一种图像质量的优化方法及装置",
    "inv": [
      "李辉"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103824256B",
    "apn": "CN201210464899.3",
    "apd": "2012-11-16",
    "apy": "2012",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "余三思"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN103777852B",
    "apn": "CN201210397363.4",
    "apd": "2012-10-18",
    "apy": "2012",
    "tit": "一种获取图像的方法、装置",
    "inv": [
      "李诚",
      "郑晖"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103685657B",
    "apn": "CN201210345621.4",
    "apd": "2012-09-17",
    "apy": "2012",
    "tit": "电子终端测试方法及装置",
    "inv": [
      "邓曦"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103632129A",
    "apn": "CN201210309313.6",
    "apd": "2012-08-28",
    "apy": "2012",
    "tit": "一种人脸特征点定位方法及装置",
    "inv": [
      "饶丰",
      "陈波",
      "肖斌",
      "刘海龙",
      "熊鹏飞"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103631370B",
    "apn": "CN201210310115.1",
    "apd": "2012-08-28",
    "apy": "2012",
    "tit": "一种控制虚拟形象的方法及装置",
    "inv": [
      "饶丰",
      "肖斌",
      "陈波",
      "张龙",
      "查文"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103634555B",
    "apn": "CN201210306753.6",
    "apd": "2012-08-27",
    "apy": "2012",
    "tit": "一种全景视频通信的方法及系统",
    "inv": [
      "肖锐毅",
      "邹灵灵"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103631786B",
    "apn": "CN201210299743.4",
    "apd": "2012-08-22",
    "apy": "2012",
    "tit": "一种视频文件的聚类方法和装置",
    "inv": [
      "刘刚",
      "傅斌",
      "吴运声"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频分类",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN103581662B",
    "apn": "CN201210261645.1",
    "apd": "2012-07-26",
    "apy": "2012",
    "tit": "视频清晰度测量方法和系统",
    "inv": [
      "刘刚"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN103546907A",
    "apn": "CN201210239054.4",
    "apd": "2012-07-11",
    "apy": "2012",
    "tit": "一种无线应用协议网页的测试系统及测试方法",
    "inv": [
      "张军宝",
      "吴思敏",
      "张欣",
      "李锐",
      "于章涛",
      "林淑芳",
      "樊华",
      "贾兴伟",
      "李京晶",
      "乔建秀"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103513890B",
    "apn": "CN201210216274.5",
    "apd": "2012-06-28",
    "apy": "2012",
    "tit": "一种基于图片的交互方法、装置和服务器",
    "inv": [
      "郑志昊",
      "梁柱",
      "王慧星",
      "马佳",
      "吴昊",
      "甘晖明",
      "周怡婷",
      "刘真",
      "张浩",
      "陈波",
      "饶丰",
      "刘海龙",
      "林淦雄"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103514211A",
    "apn": "CN201210217672.9",
    "apd": "2012-06-27",
    "apy": "2012",
    "tit": "获取信息的方法和装置",
    "inv": [
      "李颖慧",
      "张晶",
      "王莹莹",
      "张晨",
      "陈单枝",
      "李美娜",
      "马群立",
      "刘念"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103514090A",
    "apn": "CN201210213735.3",
    "apd": "2012-06-26",
    "apy": "2012",
    "tit": "测试浏览器的方法和装置",
    "inv": [
      "王伟",
      "廖志",
      "欧阳骏",
      "罗巍",
      "唐文",
      "刘泽",
      "皮凯"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103513946B",
    "apn": "CN201210213702.9",
    "apd": "2012-06-26",
    "apy": "2012",
    "tit": "控制显示的方法及装置",
    "inv": [
      "张志军",
      "翟萌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103491444B",
    "apn": "CN201210196592.X",
    "apd": "2012-06-14",
    "apy": "2012",
    "tit": "图像互动方法和系统以及对应的显示装置",
    "inv": [
      "马斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103456043B",
    "apn": "CN201210170074.0",
    "apd": "2012-05-29",
    "apy": "2012",
    "tit": "一种基于全景图的视点间漫游方法和装置",
    "inv": [
      "李保利",
      "武可新",
      "李成军",
      "张弦"
    ],
    "app": [
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103425701B",
    "apn": "CN201210164767.9",
    "apd": "2012-05-24",
    "apy": "2012",
    "tit": "在网页的图片中发布内文信息的方法、系统及装置",
    "inv": [
      "陈轶卿"
    ],
    "app": [
      "腾讯科技(北京)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN103425993B",
    "apn": "CN201210160305.X",
    "apd": "2012-05-22",
    "apy": "2012",
    "tit": "图像识别方法和系统",
    "inv": [
      "查文",
      "刘海龙",
      "侯杰",
      "刘骁",
      "栾昊",
      "姜炳楠",
      "陈波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103426003B",
    "apn": "CN201210160524.8",
    "apd": "2012-05-22",
    "apy": "2012",
    "tit": "增强现实交互的实现方法和系统",
    "inv": [
      "刘骁",
      "刘海龙",
      "侯杰",
      "饶丰",
      "伍敏慧",
      "陈波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司",
      "腾讯云计算(北京)有限责任公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN103428539B",
    "apn": "CN201210150099.4",
    "apd": "2012-05-15",
    "apy": "2012",
    "tit": "一种推送信息的发布方法及装置",
    "inv": [
      "易琦娜",
      "唐元海"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN103384234B",
    "apn": "CN201210137251.5",
    "apd": "2012-05-04",
    "apy": "2012",
    "tit": "人脸身份认证方法和系统",
    "inv": [
      "喻欣",
      "秦雷",
      "胡育辉",
      "郝允允"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103377463B",
    "apn": "CN201210124312.4",
    "apd": "2012-04-25",
    "apy": "2012",
    "tit": "一种滤镜处理的方法及装置",
    "inv": [
      "姜德强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN102682091A",
    "apn": "CN201210123853.5",
    "apd": "2012-04-25",
    "apy": "2012",
    "tit": "基于云服务的视觉搜索方法和系统",
    "inv": [
      "刘海龙",
      "侯杰",
      "熊鹏飞",
      "陈波",
      "周晓波",
      "饶丰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN103377119B",
    "apn": "CN201210121186.7",
    "apd": "2012-04-23",
    "apy": "2012",
    "tit": "一种非标准控件自动化测试方法和装置",
    "inv": [
      "陈力",
      "李晓波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103377473B",
    "apn": "CN201210115756.1",
    "apd": "2012-04-19",
    "apy": "2012",
    "tit": "一种图像排重方法和装置",
    "inv": [
      "张永华",
      "关涛",
      "黄斌强"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN103366782B",
    "apn": "CN201210098817.8",
    "apd": "2012-04-06",
    "apy": "2012",
    "tit": "在虚拟形象上自动播放表情的方法和装置",
    "inv": [
      "王栋"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN102663794A",
    "apn": "CN201210088875.2",
    "apd": "2012-03-29",
    "apy": "2012",
    "tit": "图像合成方法及装置",
    "inv": [
      "胡事民",
      "徐昆",
      "马里千",
      "陈韬",
      "刘玉璇"
    ],
    "app": [
      "清华大学",
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN103325089B",
    "apn": "CN201210076277.3",
    "apd": "2012-03-21",
    "apy": "2012",
    "tit": "图像中的肤色处理方法及装置",
    "inv": [
      "李扬",
      "吴永坚",
      "杨宝龙",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN102646190B",
    "apn": "CN201210072147.2",
    "apd": "2012-03-19",
    "apy": "2012",
    "tit": "一种基于生物特征的认证方法、装置及系统",
    "inv": [
      "侯杰",
      "熊鹏飞",
      "刘海龙",
      "陈波"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103297837B",
    "apn": "CN201210049885.5",
    "apd": "2012-02-29",
    "apy": "2012",
    "tit": "视频播放的方法和装置",
    "inv": [
      "胡鹏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "界面运作模式",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103294667B",
    "apn": "CN201110430389.X",
    "apd": "2012-02-27",
    "apy": "2012",
    "tit": "通过水印进行同源图片追踪的方法及系统",
    "inv": [
      "李凯",
      "郭晓威",
      "吴海松"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103188377B",
    "apn": "CN201110451480.X",
    "apd": "2011-12-29",
    "apy": "2011",
    "tit": "一种移动终端防盗方法和防盗移动终端",
    "inv": [
      "王庆",
      "罗章虎",
      "黄泽丰",
      "郭浩然",
      "肖权浩",
      "袁宜霞",
      "宋家顺",
      "李朋涛",
      "戴云峰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN103177413B",
    "apn": "CN201110430387.0",
    "apd": "2011-12-20",
    "apy": "2011",
    "tit": "局部化盲水印生成、检测的方法及装置",
    "inv": [
      "郭晓威",
      "李凯"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN103164705A",
    "apn": "CN201110414844.7",
    "apd": "2011-12-13",
    "apy": "2011",
    "tit": "一种基于社交网络的圈人方法及装置",
    "inv": [
      "王莹",
      "陈达力",
      "吴昊",
      "秦爽",
      "陶奕"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN103164848B",
    "apn": "CN201110409768.0",
    "apd": "2011-12-09",
    "apy": "2011",
    "tit": "图像处理方法和系统",
    "inv": [
      "唐声福"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN103049445B",
    "apn": "CN201110308488.0",
    "apd": "2011-10-12",
    "apy": "2011",
    "tit": "一种查询图片信息的方法、系统和图片状态服务器",
    "inv": [
      "王涛伟",
      "张立明",
      "李瑞方",
      "杨巍"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN102955830A",
    "apn": "CN201110254535.8",
    "apd": "2011-08-31",
    "apy": "2011",
    "tit": "表情搜索开放平台、表情搜索方法及系统",
    "inv": [
      "黄斌强",
      "齐志宏",
      "梁波"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交资源分类",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN102902675B",
    "apn": "CN201110210249.1",
    "apd": "2011-07-26",
    "apy": "2011",
    "tit": "图片内容审核方法和装置",
    "inv": [
      "刘致远"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN102624576B",
    "apn": "CN201110032465.1",
    "apd": "2011-01-27",
    "apy": "2011",
    "tit": "一种自动测试浏览器的网页下载时间的方法和系统",
    "inv": [
      "罗巍",
      "李龙",
      "叶方正",
      "丁如敏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN102567319B",
    "apn": "CN201010583425.1",
    "apd": "2010-12-10",
    "apy": "2010",
    "tit": "网页图片过滤方法及系统",
    "inv": [
      "李瑞方",
      "张立明"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN102486788B",
    "apn": "CN201010578206.4",
    "apd": "2010-12-02",
    "apy": "2010",
    "tit": "一种实现产品检索的方法及装置",
    "inv": [
      "刘致远"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN102467513B",
    "apn": "CN201010535421.6",
    "apd": "2010-11-03",
    "apy": "2010",
    "tit": "图片搜索方法和系统",
    "inv": [
      "刘致远"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN102411582B",
    "apn": "CN201010290681.1",
    "apd": "2010-09-21",
    "apy": "2010",
    "tit": "图片搜索方法、装置及客户端",
    "inv": [
      "刘占亮",
      "李永健",
      "齐志宏"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN102402115A",
    "apn": "CN201010280262.X",
    "apd": "2010-09-09",
    "apy": "2010",
    "tit": "一种获得游戏3D图像的方法及装置",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(成都)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN102377684B",
    "apn": "CN201010251440.6",
    "apd": "2010-08-11",
    "apy": "2010",
    "tit": "一种自动阻断色情视频文件传输的方法、终端和系统",
    "inv": [
      "何健"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN102339472B",
    "apn": "CN201010230914.9",
    "apd": "2010-07-15",
    "apy": "2010",
    "tit": "图片编辑方法及装置",
    "inv": [
      "杨洋",
      "王建宇",
      "刘元芳",
      "黄飞跃"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN102236850A",
    "apn": "CN201010152913.7",
    "apd": "2010-04-21",
    "apy": "2010",
    "tit": "一种商品特征属性的显示方法及装置",
    "inv": [
      "李李"
    ],
    "app": [
      "北京京东尚科信息技术有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN102196233B",
    "apn": "CN201010139011.X",
    "apd": "2010-03-18",
    "apy": "2010",
    "tit": "一种改善视频图像质量的方法和装置",
    "inv": [
      "刘俊",
      "梁晨"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN102194443B",
    "apn": "CN201010118034.2",
    "apd": "2010-03-04",
    "apy": "2010",
    "tit": "视频画中画窗口的显示方法、系统及视频处理设备",
    "inv": [
      "何健"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN101783861B",
    "apn": "CN201010111636.5",
    "apd": "2010-02-09",
    "apy": "2010",
    "tit": "一种美化图像的方法和装置",
    "inv": [
      "傅斌",
      "吴永坚",
      "杨洋"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN102073652A",
    "apn": "CN200910224158.6",
    "apd": "2009-11-24",
    "apy": "2009",
    "tit": "一种电子相册生成方法及装置和电子相册播放器",
    "inv": [
      "黄翚",
      "吴运声",
      "曾远"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN102063436A",
    "apn": "CN200910223367.9",
    "apd": "2009-11-18",
    "apy": "2009",
    "tit": "一种利用终端获取图像实现商品信息搜索的系统及方法",
    "inv": [
      "何健"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN102054287B",
    "apn": "CN200910180143.4",
    "apd": "2009-11-09",
    "apy": "2009",
    "tit": "面部动画视频生成的方法及装置",
    "inv": [
      "路依莎",
      "王建宇"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN102054159B",
    "apn": "CN200910208510.7",
    "apd": "2009-10-28",
    "apy": "2009",
    "tit": "一种人脸跟踪的方法和装置",
    "inv": [
      "孙炜",
      "龙一民"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN101599073A",
    "apn": "CN200910088280.5",
    "apd": "2009-07-13",
    "apy": "2009",
    "tit": "一种实现图片搜索的方法及装置",
    "inv": [
      "王顼"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN101930284B",
    "apn": "CN200910150595.8",
    "apd": "2009-06-23",
    "apy": "2009",
    "tit": "一种实现视频和虚拟网络场景交互的方法、装置和系统",
    "inv": [
      "李转科"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN101621519A",
    "apn": "CN200910129610.0",
    "apd": "2009-03-17",
    "apy": "2009",
    "tit": "对用户进行视频认证的方法和装置",
    "inv": [
      "郑志昊",
      "梁柱",
      "付学宝",
      "孙超",
      "冯超",
      "杜智",
      "吴云林",
      "黄家乐",
      "陈国胜",
      "张杉",
      "胡子敏"
    ],
    "app": [
      "腾讯数码(天津)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN101477592B",
    "apn": "CN200810220019.1",
    "apd": "2008-12-16",
    "apy": "2008",
    "tit": "显示3D动画的方法及装置",
    "inv": [
      "孙宏宇",
      "曾亮",
      "李国宏",
      "常鑫",
      "林力"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN101364263A",
    "apn": "CN200810167132.8",
    "apd": "2008-09-28",
    "apy": "2008",
    "tit": "对图像进行皮肤纹理检测的方法及系统",
    "inv": [
      "陈波"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN101359372B",
    "apn": "CN200810198788.6",
    "apd": "2008-09-26",
    "apy": "2008",
    "tit": "分类器的训练方法及装置、识别敏感图片的方法及装置",
    "inv": [
      "付立波",
      "王建宇",
      "陈波"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN100559376C",
    "apn": "CN200810068209.6",
    "apd": "2008-06-30",
    "apy": "2008",
    "tit": "生成视频摘要的方法、系统及设备",
    "inv": [
      "李世平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "封面/截图",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN101303734B",
    "apn": "CN200810125798.7",
    "apd": "2008-06-25",
    "apy": "2008",
    "tit": "图片检测系统及方法",
    "inv": [
      "付立波",
      "王建宇",
      "陈波",
      "杨干荣"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN101251896B",
    "apn": "CN200810066161.5",
    "apd": "2008-03-21",
    "apy": "2008",
    "tit": "一种基于多分类器的物体检测系统及方法",
    "inv": [
      "王建宇",
      "王亮"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN101217511A",
    "apn": "CN200810000063.1",
    "apd": "2008-01-03",
    "apy": "2008",
    "tit": "个人形象管理系统和管理方法",
    "inv": [
      "戴永裕",
      "李斌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "目标分割"
  },
  {
    "entity": "腾讯",
    "pun": "CN101216830B",
    "apn": "CN200710306385.4",
    "apd": "2007-12-28",
    "apy": "2007",
    "tit": "根据颜色搜索商品的方法和系统",
    "inv": [
      "刘大林",
      "熊佳彦"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN100550038C",
    "apn": "CN200710304204.4",
    "apd": "2007-12-26",
    "apy": "2007",
    "tit": "图片内容识别方法及识别系统",
    "inv": [
      "王晖"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN100505840C",
    "apn": "CN200710176790.9",
    "apd": "2007-11-02",
    "apy": "2007",
    "tit": "一种人脸合成视频传输的方法及装置",
    "inv": [
      "何健"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN101137152B",
    "apn": "CN200710123774.3",
    "apd": "2007-09-27",
    "apy": "2007",
    "tit": "一种在移动即时通信中交互三维动画的方法、系统及设备",
    "inv": [
      "冼文佟",
      "游敏丽",
      "范瑞彬"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN100574463C",
    "apn": "CN200710073574.1",
    "apd": "2007-03-13",
    "apy": "2007",
    "tit": "一种在即时通信系统中视频显示的系统及方法",
    "inv": [
      "陈敬昌"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN100551065C",
    "apn": "CN200610167182.7",
    "apd": "2006-12-26",
    "apy": "2006",
    "tit": "一种视频内容审核系统和方法",
    "inv": [
      "刘俊"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频审核",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN101068314A",
    "apn": "CN200610152460.1",
    "apd": "2006-09-29",
    "apy": "2006",
    "tit": "一种网络视频秀方法及系统",
    "inv": [
      "李世平"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "目标检测"
  },
  {
    "entity": "腾讯",
    "pun": "CN101155301B",
    "apn": "CN200610141029.7",
    "apd": "2006-09-28",
    "apy": "2006",
    "tit": "流媒体视频图像放大方法和流媒体接收客户端",
    "inv": [
      "邓宇龙"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN101098241A",
    "apn": "CN200610091597.0",
    "apd": "2006-06-26",
    "apy": "2006",
    "tit": "虚拟形象实现方法及其系统",
    "inv": [
      "文强",
      "梁柱"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN100524342C",
    "apn": "CN200610081029.2",
    "apd": "2006-05-19",
    "apy": "2006",
    "tit": "一种建立人脸检测器的方法",
    "inv": [
      "许华彬",
      "董挺"
    ],
    "app": [
      "深圳市腾讯计算机系统有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "腾讯",
    "pun": "CN100437642C",
    "apn": "CN200610065191.5",
    "apd": "2006-03-27",
    "apy": "2006",
    "tit": "一种模型几何空间轮廓线查找的预处理方法",
    "inv": [
      "刘皓"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN101046880A",
    "apn": "CN200610065926.4",
    "apd": "2006-03-27",
    "apy": "2006",
    "tit": "一种在三维模型中显示人物图像的方法及装置",
    "inv": [
      "范骁",
      "李黎"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "游戏",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "腾讯",
    "pun": "CN101005609B",
    "apn": "CN200610033279.9",
    "apd": "2006-01-21",
    "apy": "2006",
    "tit": "生成互动视频图像的方法及系统",
    "inv": [
      "盛馥钟",
      "杜修杏",
      "赵艳"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频制作",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN1997141B",
    "apn": "CN200610032605.4",
    "apd": "2006-01-01",
    "apy": "2006",
    "tit": "一种即时通信中控制视频截图传送的方法及系统",
    "inv": [
      "冼业成",
      "罗学成"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN1992885A",
    "apn": "CN200510135418.4",
    "apd": "2005-12-28",
    "apy": "2005",
    "tit": "一种显示视频质量的方法及客户端",
    "inv": [
      "冼业成",
      "盛馥钟",
      "赵艳",
      "杜修杏",
      "龚毅",
      "陈妍",
      "秦鹏程"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "质量评价"
  },
  {
    "entity": "腾讯",
    "pun": "CN100458778C",
    "apn": "CN200510107792.3",
    "apd": "2005-09-30",
    "apy": "2005",
    "tit": "图片搜索方法及其装置",
    "inv": [
      "邹小旻",
      "张枫林"
    ],
    "app": [
      "深圳市世纪光速信息技术有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "腾讯",
    "pun": "CN1816149A",
    "apn": "CN200510007604.X",
    "apd": "2005-02-06",
    "apy": "2005",
    "tit": "去除视频图像中块效应的滤波方法及环路滤波器",
    "inv": [
      "文强"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "腾讯",
    "pun": "CN1315035C",
    "apn": "CN200410028030.X",
    "apd": "2004-07-08",
    "apy": "2004",
    "tit": "一种图像分割显示方法及装置",
    "inv": [
      "陈泱",
      "李志宏",
      "张杰"
    ],
    "app": [
      "腾讯科技(深圳)有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN110516739A",
    "apn": "CN201910796423.1",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "一种证件识别方法、装置及设备",
    "inv": [
      "陈志军",
      "徐崴",
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110490200A",
    "apn": "CN201910794950.9",
    "apd": "2019-08-27",
    "apy": "2019",
    "tit": "一种证件扫描方法、装置及设备",
    "inv": [
      "陈志军",
      "徐崴",
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110490169A",
    "apn": "CN201910793128.0",
    "apd": "2019-08-26",
    "apy": "2019",
    "tit": "一种物品架巡检方法、装置以及设备",
    "inv": [
      "岑文峰",
      "贺武杰",
      "曹峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110503109A",
    "apn": "CN201910715981.0",
    "apd": "2019-08-02",
    "apy": "2019",
    "tit": "图像特征提取方法和装置、图像处理方法和装置",
    "inv": [
      "赵威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110458954A",
    "apn": "CN201910703833.7",
    "apd": "2019-07-31",
    "apy": "2019",
    "tit": "一种等高线生成方法、装置及设备",
    "inv": [
      "郭燚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110443746A",
    "apn": "CN201910675265.4",
    "apd": "2019-07-25",
    "apy": "2019",
    "tit": "基于生成对抗网络的图片处理方法、装置及电子设备",
    "inv": [
      "刘弘一",
      "陈若田",
      "熊军",
      "李若鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110472515A",
    "apn": "CN201910665552.7",
    "apd": "2019-07-23",
    "apy": "2019",
    "tit": "货架商品检测方法及系统",
    "inv": [
      "张晓博",
      "侯章军",
      "杨旭东",
      "曾晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110458024A",
    "apn": "CN201910624362.0",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "活体检测方法及装置和电子设备",
    "inv": [
      "曹佳炯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110428388A",
    "apn": "CN201910626093.1",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "一种图像数据生成方法及装置",
    "inv": [
      "邹成",
      "郁树达",
      "郭林杰",
      "李思琪",
      "马岳文",
      "赵雄心",
      "周大江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110490960A",
    "apn": "CN201910626114.X",
    "apd": "2019-07-11",
    "apy": "2019",
    "tit": "一种合成图像生成方法及装置",
    "inv": [
      "邹成",
      "郁树达",
      "郭林杰",
      "李思琪",
      "马岳文",
      "赵雄心",
      "周大江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110458569A",
    "apn": "CN201910586693.X",
    "apd": "2019-07-01",
    "apy": "2019",
    "tit": "一种刷脸支付方法和装置",
    "inv": [
      "周亮",
      "林述民",
      "江璇",
      "陈宏敏",
      "郭雷",
      "马郡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110334824A",
    "apn": "CN201910573793.9",
    "apd": "2019-06-28",
    "apy": "2019",
    "tit": "安全入住方法及装置",
    "inv": [
      "黄琪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110349281A",
    "apn": "CN201910531275.0",
    "apd": "2019-06-19",
    "apy": "2019",
    "tit": "一种收费方法、装置及系统",
    "inv": [
      "吴建锋",
      "贾冬麟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "自动缴费",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110263700A",
    "apn": "CN201910523401.8",
    "apd": "2019-06-17",
    "apy": "2019",
    "tit": "视频处理方法、装置、设备及视频监控系统",
    "inv": [
      "韩喆",
      "杨磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN110390712A",
    "apn": "CN201910507966.7",
    "apd": "2019-06-12",
    "apy": "2019",
    "tit": "图像渲染方法及装置、三维图像构建方法及装置",
    "inv": [
      "刘欢"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110309859A",
    "apn": "CN201910489357.3",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "一种图像真伪检测方法、装置及电子设备",
    "inv": [
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110310149A",
    "apn": "CN201910489419.0",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "售卖商品推荐方法、装置及设备",
    "inv": [
      "刘钊",
      "赵雄心",
      "周大江",
      "孙强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110349267A",
    "apn": "CN201910492809.3",
    "apd": "2019-06-06",
    "apy": "2019",
    "tit": "三维热度模型的构建方法以及装置",
    "inv": [
      "倪朝浩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110363733A",
    "apn": "CN201910487835.7",
    "apd": "2019-06-05",
    "apy": "2019",
    "tit": "一种混合图像生成方法及装置",
    "inv": [
      "倪朝浩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110348298A",
    "apn": "CN201910471683.1",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "餐品制作信息的确定方法、装置及设备",
    "inv": [
      "夏命星"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110348943A",
    "apn": "CN201910473032.6",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "商品推荐信息的处理方法、装置、存储介质及计算机设备",
    "inv": [
      "李叶卷"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110245257A",
    "apn": "CN201910470058.5",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "推送信息的生成方法及装置",
    "inv": [
      "刘弘一",
      "陈若田",
      "熊军",
      "李若鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110189406A",
    "apn": "CN201910471476.6",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "图像数据标注方法及其装置",
    "inv": [
      "马岳文",
      "郁树达",
      "郭林杰",
      "邹成",
      "李思琪",
      "赵雄心",
      "周大江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110189204A",
    "apn": "CN201910472420.2",
    "apd": "2019-05-31",
    "apy": "2019",
    "tit": "基于菜单扫描的点餐方法及装置",
    "inv": [
      "孙泽义"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110349033A",
    "apn": "CN201910464468.9",
    "apd": "2019-05-30",
    "apy": "2019",
    "tit": "项目案件初审方法以及装置",
    "inv": [
      "胡越",
      "陈景东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "案件审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110263775A",
    "apn": "CN201910456505.1",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "图像识别方法、装置、设备和认证方法、装置、设备",
    "inv": [
      "徐崴",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110264444A",
    "apn": "CN201910447785.X",
    "apd": "2019-05-27",
    "apy": "2019",
    "tit": "基于弱分割的损伤检测方法及装置",
    "inv": [
      "张伟",
      "褚崴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110334590A",
    "apn": "CN201910441193.7",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "图像采集引导方法以及装置",
    "inv": [
      "葛官法",
      "朱世艾",
      "刘修岑"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN110324531A",
    "apn": "CN201910442039.1",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "图像采集引导方法以及装置",
    "inv": [
      "葛官法",
      "朱世艾",
      "刘修岑"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN110309821A",
    "apn": "CN201910442034.9",
    "apd": "2019-05-24",
    "apy": "2019",
    "tit": "图像采集检测方法以及装置",
    "inv": [
      "葛官法",
      "朱世艾",
      "刘修岑"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "案件审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110262950A",
    "apn": "CN201910425881.4",
    "apd": "2019-05-21",
    "apy": "2019",
    "tit": "基于多项指标的异动检测方法和装置",
    "inv": [
      "周扬",
      "杨树波",
      "于君泽"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110276257A",
    "apn": "CN201910417065.9",
    "apd": "2019-05-20",
    "apy": "2019",
    "tit": "人脸识别方法、装置、系统、服务器及可读存储介质",
    "inv": [
      "曹佳炯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN110262938A",
    "apn": "CN201910374512.7",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "一种内容监控方法和装置",
    "inv": [
      "张迎亚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110263805A",
    "apn": "CN201910374005.3",
    "apd": "2019-05-07",
    "apy": "2019",
    "tit": "证件验证、身份验证方法、装置及设备",
    "inv": [
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110263615A",
    "apn": "CN201910353164.5",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "车辆拍摄中的交互处理方法、装置、设备及客户端",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110245552A",
    "apn": "CN201910353156.0",
    "apd": "2019-04-29",
    "apy": "2019",
    "tit": "车损图像拍摄的交互处理方法、装置、设备及客户端",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110009433A",
    "apn": "CN201910344431.2",
    "apd": "2019-04-26",
    "apy": "2019",
    "tit": "评估产品价格的方法及装置",
    "inv": [
      "刘向峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "二手估价",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110175849A",
    "apn": "CN201910335454.7",
    "apd": "2019-04-24",
    "apy": "2019",
    "tit": "收款方法、装置、设备、服务器及系统",
    "inv": [
      "朱亚东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110187921A",
    "apn": "CN201910327492.8",
    "apd": "2019-04-23",
    "apy": "2019",
    "tit": "唤醒设备的方法及装置",
    "inv": [
      "王勤中",
      "宋启恒",
      "方渊",
      "廖耿耿"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110188790A",
    "apn": "CN201910310510.1",
    "apd": "2019-04-17",
    "apy": "2019",
    "tit": "图片样本的自动化生成方法和系统",
    "inv": [
      "赵俊生"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110136009A",
    "apn": "CN201910308224.1",
    "apd": "2019-04-17",
    "apy": "2019",
    "tit": "事故车辆理赔方法和装置、电子设备",
    "inv": [
      "张泰玮",
      "程丹妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110264645A",
    "apn": "CN201910305807.9",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "一种商品的自助收银方法和设备",
    "inv": [
      "宋杨"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110189343A",
    "apn": "CN201910305358.8",
    "apd": "2019-04-16",
    "apy": "2019",
    "tit": "图像标注方法、装置及系统",
    "inv": [
      "刘钊",
      "陈超",
      "韩春龙",
      "赵雄心",
      "周大江",
      "郁树达"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110175514A",
    "apn": "CN201910301307.8",
    "apd": "2019-04-15",
    "apy": "2019",
    "tit": "一种刷脸支付提示方法、装置及设备",
    "inv": [
      "周亮",
      "郭雷",
      "林述民"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110147721A",
    "apn": "CN201910288401.4",
    "apd": "2019-04-11",
    "apy": "2019",
    "tit": "一种三维人脸识别方法、模型训练方法和装置",
    "inv": [
      "陈锦伟",
      "马晨光",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109993822A",
    "apn": "CN201910287070.2",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "一种水墨风格渲染方法和装置",
    "inv": [
      "倪朝浩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110163250A",
    "apn": "CN201910286625.1",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "基于分布式调度的图像脱敏处理系统、方法以及装置",
    "inv": [
      "魏庆成",
      "谢福恒"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110175623A",
    "apn": "CN201910285984.5",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "基于图像识别的脱敏处理方法以及装置",
    "inv": [
      "谢福恒",
      "董建峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110163218A",
    "apn": "CN201910286610.5",
    "apd": "2019-04-10",
    "apy": "2019",
    "tit": "基于图像识别的脱敏处理方法以及装置",
    "inv": [
      "谢福恒",
      "连琨",
      "魏庆成"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110174936A",
    "apn": "CN201910269922.5",
    "apd": "2019-04-04",
    "apy": "2019",
    "tit": "头戴式可视设备的控制方法、装置及设备",
    "inv": [
      "王磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110163715A",
    "apn": "CN201910267131.9",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "信息推荐方法、装置、设备及系统",
    "inv": [
      "郭雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110189329A",
    "apn": "CN201910267473.0",
    "apd": "2019-04-03",
    "apy": "2019",
    "tit": "用于定位色卡的色块区域的系统和方法",
    "inv": [
      "吕俊杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN110059468A",
    "apn": "CN201910262663.3",
    "apd": "2019-04-02",
    "apy": "2019",
    "tit": "一种小程序风险识别方法和装置",
    "inv": [
      "张骐"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110163761A",
    "apn": "CN201910238911.0",
    "apd": "2019-03-27",
    "apy": "2019",
    "tit": "基于图像处理的可疑项目成员识别方法及装置",
    "inv": [
      "王修坤",
      "程丹妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "案件审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110032956A",
    "apn": "CN201910239714.0",
    "apd": "2019-03-27",
    "apy": "2019",
    "tit": "危险物品识别方法和装置",
    "inv": [
      "杨永晟",
      "李莹洁",
      "黄馨誉",
      "袁锦程"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110032945A",
    "apn": "CN201910216315.2",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "基于人脸识别的方法及装置",
    "inv": [
      "邢丹丹"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110059569A",
    "apn": "CN201910216920.X",
    "apd": "2019-03-21",
    "apy": "2019",
    "tit": "活体检测方法和装置、模型评估方法和装置",
    "inv": [
      "曹佳炯",
      "丁菁汀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110084113A",
    "apn": "CN201910213300.0",
    "apd": "2019-03-20",
    "apy": "2019",
    "tit": "活体检测方法、装置、系统、服务器及可读存储介质",
    "inv": [
      "曹佳炯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110070452A",
    "apn": "CN201910209744.7",
    "apd": "2019-03-19",
    "apy": "2019",
    "tit": "模型训练方法、装置、计算设备及计算机可读存储介质",
    "inv": [
      "连琨",
      "谢福恒"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "案件审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110060200A",
    "apn": "CN201910201872.7",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "图像透视变换方法、装置及设备",
    "inv": [
      "陈家大"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110059560A",
    "apn": "CN201910201864.2",
    "apd": "2019-03-18",
    "apy": "2019",
    "tit": "人脸识别的方法、装置及设备",
    "inv": [
      "郑丹丹",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN110070448A",
    "apn": "CN201910178109.7",
    "apd": "2019-03-08",
    "apy": "2019",
    "tit": "一种电子保单的处理方法及服务器",
    "inv": [
      "冶秀刚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "保险单",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110033386A",
    "apn": "CN201910171587.5",
    "apd": "2019-03-07",
    "apy": "2019",
    "tit": "车辆事故的鉴定方法及装置、电子设备",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008673A",
    "apn": "CN201910166328.3",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "一种基于人脸识别的身份鉴权方法和装置",
    "inv": [
      "江璇",
      "林述民"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110059543A",
    "apn": "CN201910168813.4",
    "apd": "2019-03-06",
    "apy": "2019",
    "tit": "一种人脸留底静默注册的方法、装置、服务器和终端",
    "inv": [
      "郑丹丹",
      "李亮",
      "王庭"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN110032877A",
    "apn": "CN201910156992.X",
    "apd": "2019-03-01",
    "apy": "2019",
    "tit": "图像存取方法及其系统",
    "inv": [
      "陈爱民"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110032931A",
    "apn": "CN201910156666.9",
    "apd": "2019-03-01",
    "apy": "2019",
    "tit": "生成对抗网络训练、网纹去除方法、装置及电子设备",
    "inv": [
      "郑丹丹",
      "赵凯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110046644A",
    "apn": "CN201910142956.8",
    "apd": "2019-02-26",
    "apy": "2019",
    "tit": "一种证件防伪的方法及装置、计算设备和存储介质",
    "inv": [
      "徐崴",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008823A",
    "apn": "CN201910124408.2",
    "apd": "2019-02-19",
    "apy": "2019",
    "tit": "车辆定损方法和装置、电子设备",
    "inv": [
      "蔡伟琦"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109919823A",
    "apn": "CN201910119079.2",
    "apd": "2019-02-13",
    "apy": "2019",
    "tit": "一种水印生成方法和装置",
    "inv": [
      "李超仲",
      "李岩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110033256A",
    "apn": "CN201910112208.5",
    "apd": "2019-02-13",
    "apy": "2019",
    "tit": "基于智能锁的支付方法、装置以及系统",
    "inv": [
      "黄顺钊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110033077A",
    "apn": "CN201910110389.8",
    "apd": "2019-02-11",
    "apy": "2019",
    "tit": "神经网络训练方法以及装置",
    "inv": [
      "曹佳炯",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110009296A",
    "apn": "CN201910110220.2",
    "apd": "2019-02-11",
    "apy": "2019",
    "tit": "理赔业务的处理方法及装置",
    "inv": [
      "陶彬贤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "案件审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008821A",
    "apn": "CN201910106681.2",
    "apd": "2019-02-02",
    "apy": "2019",
    "tit": "一种预测人像底图更新的方法和设备",
    "inv": [
      "尚萌萌",
      "厉科嘉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109903172A",
    "apn": "CN201910097463.7",
    "apd": "2019-01-31",
    "apy": "2019",
    "tit": "理赔信息提取方法和装置、电子设备",
    "inv": [
      "吴博坤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110020656A",
    "apn": "CN201910092696.8",
    "apd": "2019-01-30",
    "apy": "2019",
    "tit": "图像的校正方法、装置及设备",
    "inv": [
      "陈家大"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110059711A",
    "apn": "CN201910081583.8",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "图像的对齐方法、装置及设备",
    "inv": [
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110033341A",
    "apn": "CN201910082297.3",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "一种产品分享方法、装置及电子设备",
    "inv": [
      "冶秀刚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "保险单",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110008813A",
    "apn": "CN201910066681.4",
    "apd": "2019-01-24",
    "apy": "2019",
    "tit": "基于活体检测技术的人脸识别方法和系统",
    "inv": [
      "丁菁汀",
      "傅秉涛",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110020587A",
    "apn": "CN201910047336.6",
    "apd": "2019-01-18",
    "apy": "2019",
    "tit": "识别系统智能提速的方法、系统、装置和设备",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008942A",
    "apn": "CN201910037426.7",
    "apd": "2019-01-15",
    "apy": "2019",
    "tit": "证件验证方法、装置、服务器及存储介质",
    "inv": [
      "李静",
      "陶冶",
      "郭明宇",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008810A",
    "apn": "CN201910019304.5",
    "apd": "2019-01-09",
    "apy": "2019",
    "tit": "人脸图像采集的方法、装置、设备、及机器可读存储介质",
    "inv": [
      "郑丹丹"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109725946A",
    "apn": "CN201910004948.7",
    "apd": "2019-01-03",
    "apy": "2019",
    "tit": "一种基于人脸检测唤醒智能设备的方法、装置及设备",
    "inv": [
      "鲁亚然"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110009509A",
    "apn": "CN201910002372.0",
    "apd": "2019-01-02",
    "apy": "2019",
    "tit": "评估车损识别模型的方法及装置",
    "inv": [
      "徐娟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110009370A",
    "apn": "CN201811613614.1",
    "apd": "2018-12-27",
    "apy": "2018",
    "tit": "信息处理方法、装置、电子设备及可读存储介质",
    "inv": [
      "叶珩",
      "冯力国",
      "李洁",
      "胡圻圻",
      "赵闻飙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110018920A",
    "apn": "CN201811589392.4",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "一种页面展示异常的检测方法、装置及设备",
    "inv": [
      "张仕奇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110009508A",
    "apn": "CN201811594050.1",
    "apd": "2018-12-25",
    "apy": "2018",
    "tit": "一种车险自动赔付方法和系统",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110033011A",
    "apn": "CN201811529729.2",
    "apd": "2018-12-14",
    "apy": "2018",
    "tit": "车祸事故处理方法和装置、电子设备",
    "inv": [
      "吴博坤",
      "程远"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105303189B",
    "apn": "CN201410367807.9",
    "apd": "2014-07-29",
    "apy": "2014",
    "tit": "一种用于检测预定区域中特定标识图像的方法及装置",
    "inv": [
      "程刚",
      "楚汝峰",
      "张伦"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109727015A",
    "apn": "CN201811516041.0",
    "apd": "2018-12-12",
    "apy": "2018",
    "tit": "支付方法及装置",
    "inv": [
      "贺三元"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110046636A",
    "apn": "CN201811513553.1",
    "apd": "2018-12-11",
    "apy": "2018",
    "tit": "分类预测方法及装置、预测模型训练方法及装置",
    "inv": [
      "张雅淋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110009770A",
    "apn": "CN201811507467.X",
    "apd": "2018-12-10",
    "apy": "2018",
    "tit": "一种标识用户的方法、设备及门禁装置",
    "inv": [
      "周升洋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110032328A",
    "apn": "CN201811489657.3",
    "apd": "2018-12-06",
    "apy": "2018",
    "tit": "一种操作对象的尺寸调节方法及装置",
    "inv": [
      "吴钊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110008803A",
    "apn": "CN201811477859.6",
    "apd": "2018-12-05",
    "apy": "2018",
    "tit": "行人检测、训练检测器的方法、装置及设备",
    "inv": [
      "赵威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008802A",
    "apn": "CN201811474564.3",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "从多个脸部中选择目标脸部及脸部识别比对方法、装置",
    "inv": [
      "郑丹丹"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109948638A",
    "apn": "CN201811474965.9",
    "apd": "2018-12-04",
    "apy": "2018",
    "tit": "对象匹配方法、装置、设备及计算机可读存储介质",
    "inv": [
      "王国强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106294099A",
    "apn": "CN201510250889.3",
    "apd": "2015-05-15",
    "apy": "2015",
    "tit": "一种测试方法及设备",
    "inv": [
      "江进朋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110020581A",
    "apn": "CN201811468225.4",
    "apd": "2018-12-03",
    "apy": "2018",
    "tit": "一种基于多帧脸部图像的比对方法、装置和电子设备",
    "inv": [
      "郑丹丹"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN109461003A",
    "apn": "CN201811459514.8",
    "apd": "2018-11-30",
    "apy": "2018",
    "tit": "基于多视角的多人脸场景刷脸支付风险防控方法和设备",
    "inv": [
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109598515A",
    "apn": "CN201811442459.1",
    "apd": "2018-11-29",
    "apy": "2018",
    "tit": "一种支付方法、支付装置及终端设备",
    "inv": [
      "周亮",
      "曹恺",
      "林述民"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109766052A",
    "apn": "CN201811427279.6",
    "apd": "2018-11-27",
    "apy": "2018",
    "tit": "菜品图片上传方法、装置、计算机设备及可读存储介质",
    "inv": [
      "许晓吟",
      "张依蕾",
      "胡奥宇"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "电商",
    "l2": "自动录入",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109583348A",
    "apn": "CN201811397008.0",
    "apd": "2018-11-22",
    "apy": "2018",
    "tit": "一种人脸识别方法、装置、设备及系统",
    "inv": [
      "徐崴",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109598138A",
    "apn": "CN201811361542.6",
    "apd": "2018-11-15",
    "apy": "2018",
    "tit": "图片中敏感信息识别、掩盖和感知是否查看的方法及装置",
    "inv": [
      "吉亚霖",
      "成强",
      "贾茜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109598208A",
    "apn": "CN201811350974.7",
    "apd": "2018-11-14",
    "apy": "2018",
    "tit": "人像验证方法及装置",
    "inv": [
      "厉科嘉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN109542562A",
    "apn": "CN201811333886.6",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "界面图片的识别方法及装置",
    "inv": [
      "贺三元"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008799A",
    "apn": "CN201811332839.X",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "指定区域的人流状况估算方法和装置",
    "inv": [
      "张晓博",
      "侯章军",
      "杨旭东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109902551A",
    "apn": "CN201811331786.X",
    "apd": "2018-11-09",
    "apy": "2018",
    "tit": "开放场景的实时人流统计方法和装置",
    "inv": [
      "张晓博",
      "侯章军",
      "杨旭东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109902550A",
    "apn": "CN201811324908.2",
    "apd": "2018-11-08",
    "apy": "2018",
    "tit": "行人属性的识别方法和装置",
    "inv": [
      "杨旭东",
      "张晓博",
      "侯章军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "行人识别",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109614609A",
    "apn": "CN201811311382.4",
    "apd": "2018-11-06",
    "apy": "2018",
    "tit": "模型建立方法及装置",
    "inv": [
      "席云",
      "王维强",
      "许辽萨",
      "赵闻飙",
      "袁锦程",
      "易灿"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109615649A",
    "apn": "CN201811282580.2",
    "apd": "2018-10-31",
    "apy": "2018",
    "tit": "一种图像标注方法、装置及系统",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109583910A",
    "apn": "CN201811260942.8",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "一种商品授权鉴定方法、装置及设备",
    "inv": [
      "张林江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "真伪鉴定",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109614983A",
    "apn": "CN201811260697.0",
    "apd": "2018-10-26",
    "apy": "2018",
    "tit": "训练数据的生成方法、装置及系统",
    "inv": [
      "刘源"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109493073A",
    "apn": "CN201811249700.9",
    "apd": "2018-10-25",
    "apy": "2018",
    "tit": "一种基于人脸的身份识别方法、装置及电子设备",
    "inv": [
      "王保初"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109583471A",
    "apn": "CN201811244335.2",
    "apd": "2018-10-24",
    "apy": "2018",
    "tit": "用于智能手机识别的方法及装置",
    "inv": [
      "倪壮",
      "贾冰鑫",
      "毛仁歆"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "二手估价",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109461110A",
    "apn": "CN201811184720.2",
    "apd": "2018-10-11",
    "apy": "2018",
    "tit": "确定图片的溯源信息的方法及装置",
    "inv": [
      "傅佳琪",
      "贾冰鑫",
      "毛仁歆"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "版权",
    "l2": "防盗版",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109657088A",
    "apn": "CN201811157481.1",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "一种图片风险检测方法、装置、设备及介质",
    "inv": [
      "李海亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109325898A",
    "apn": "CN201811157307.7",
    "apd": "2018-09-30",
    "apy": "2018",
    "tit": "写入和读取数字水印的方法及装置",
    "inv": [
      "傅佳琪",
      "贾冰鑫",
      "毛仁歆"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109615703A",
    "apn": "CN201811142256.0",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "增强现实的图像展示方法、装置及设备",
    "inv": [
      "周岳峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109377467A",
    "apn": "CN201811141521.3",
    "apd": "2018-09-28",
    "apy": "2018",
    "tit": "训练样本的生成方法、目标检测方法和装置",
    "inv": [
      "钱佳景",
      "王康",
      "管维刚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109284712A",
    "apn": "CN201811099695.8",
    "apd": "2018-09-20",
    "apy": "2018",
    "tit": "商品信息的配置方法及装置",
    "inv": [
      "蒋尉",
      "罗旋",
      "郑元拢",
      "刘盛兰"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "电商",
    "l2": "自动录入",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109493285A",
    "apn": "CN201811086909.8",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "基于众包的图像处理方法、装置、服务器及存储介质",
    "inv": [
      "任望",
      "韩冬",
      "张云龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109215119A",
    "apn": "CN201811088882.6",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "受损车辆的三维模型建立方法及装置",
    "inv": [
      "王萌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109325489A",
    "apn": "CN201811075410.7",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "图像的识别方法及装置、存储介质、电子装置",
    "inv": [
      "贺三元",
      "黄建锋",
      "罗晗璐",
      "张桀宁"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109377494A",
    "apn": "CN201811076028.8",
    "apd": "2018-09-14",
    "apy": "2018",
    "tit": "一种用于图像的语义分割方法和装置",
    "inv": [
      "郭昕",
      "程远"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN109344732A",
    "apn": "CN201811050088.2",
    "apd": "2018-09-10",
    "apy": "2018",
    "tit": "一种鉴权以及确定鉴权方式的方法、装置及电子设备",
    "inv": [
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109376592A",
    "apn": "CN201811051838.8",
    "apd": "2018-09-10",
    "apy": "2018",
    "tit": "活体检测方法、装置和计算机可读存储介质",
    "inv": [
      "丁菁汀",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109255382A",
    "apn": "CN201811046086.6",
    "apd": "2018-09-07",
    "apy": "2018",
    "tit": "用于图片匹配定位的神经网络系统，方法及装置",
    "inv": [
      "巢林林",
      "徐娟",
      "褚崴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109376585A",
    "apn": "CN201811027491.3",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "一种人脸识别的辅助方法、人脸识别方法及终端设备",
    "inv": [
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109299594A",
    "apn": "CN201811025103.8",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "身份验证方法及装置",
    "inv": [
      "江南"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109165619A",
    "apn": "CN201811025632.8",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "一种图像的处理方法、装置及电子设备",
    "inv": [
      "谢昭",
      "王立彬",
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109409979A",
    "apn": "CN201811026627.9",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "虚拟化妆方法、装置及设备",
    "inv": [
      "谢杨易"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109063692A",
    "apn": "CN201811024881.5",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "一种人脸识别方法和装置",
    "inv": [
      "江南"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109345253A",
    "apn": "CN201811024632.6",
    "apd": "2018-09-04",
    "apy": "2018",
    "tit": "资源转移方法、装置及系统",
    "inv": [
      "徐崴",
      "陈继东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109377384A",
    "apn": "CN201811019409.2",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "健康险的确定方法、装置和服务器",
    "inv": [
      "赖倩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "健康险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109271908A",
    "apn": "CN201811023202.2",
    "apd": "2018-09-03",
    "apy": "2018",
    "tit": "车损检测方法、装置及设备",
    "inv": [
      "王萌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109117847A",
    "apn": "CN201811012740.1",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "用于车辆损伤识别的部件分割方法及装置",
    "inv": [
      "王萌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN109389712A",
    "apn": "CN201811014469.5",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "智能锁的解锁方法、移动终端、服务器及可读存储介质",
    "inv": [
      "廖晖",
      "黄琪",
      "赵生波"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109190631A",
    "apn": "CN201811014320.7",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "图片的目标对象标注方法及装置",
    "inv": [
      "张伟",
      "程远"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109242473A",
    "apn": "CN201811010240.4",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "一种支付方法、装置及电子设备",
    "inv": [
      "杨磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109191252A",
    "apn": "CN201811009050.0",
    "apd": "2018-08-31",
    "apy": "2018",
    "tit": "基于增强现实技术的菜品推荐方法及装置",
    "inv": [
      "窦方钰"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109345637A",
    "apn": "CN201810982275.8",
    "apd": "2018-08-27",
    "apy": "2018",
    "tit": "基于增强现实的互动方法及装置",
    "inv": [
      "吴瑾",
      "段青龙",
      "季婧",
      "吴承军",
      "程佳慧",
      "王亚迪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109190539A",
    "apn": "CN201810972167.2",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "人脸识别方法及装置",
    "inv": [
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109040594A",
    "apn": "CN201810972155.X",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "拍照方法及装置",
    "inv": [
      "宋洪磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109145827A",
    "apn": "CN201810972153.0",
    "apd": "2018-08-24",
    "apy": "2018",
    "tit": "视频通讯方法及装置",
    "inv": [
      "宋洪磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109102198A",
    "apn": "CN201810969499.5",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "图像众包标注方法和装置",
    "inv": [
      "程远",
      "胡越",
      "程丹妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109102030A",
    "apn": "CN201810969492.3",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "控制在线模型更新的方法及装置",
    "inv": [
      "蒋晨"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109242006A",
    "apn": "CN201810967661.X",
    "apd": "2018-08-23",
    "apy": "2018",
    "tit": "基于车型分类的识别车辆损伤的方法及装置",
    "inv": [
      "王萌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109145903A",
    "apn": "CN201810961701.X",
    "apd": "2018-08-22",
    "apy": "2018",
    "tit": "一种图像处理方法和装置",
    "inv": [
      "郭昕",
      "程远",
      "蒋晨",
      "鲁志红"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN109214995A",
    "apn": "CN201810946573.1",
    "apd": "2018-08-20",
    "apy": "2018",
    "tit": "图片质量的确定方法、装置和服务器",
    "inv": [
      "邱怡琳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN109146030A",
    "apn": "CN201810941621.8",
    "apd": "2018-08-17",
    "apy": "2018",
    "tit": "一种低碳消费的识别方法、装置及电子设备",
    "inv": [
      "刘慧",
      "管维刚",
      "王康"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109086847A",
    "apn": "CN201810938867.X",
    "apd": "2018-08-17",
    "apy": "2018",
    "tit": "基于人脸识别的取餐处理方法及装置",
    "inv": [
      "窦方钰"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109190509A",
    "apn": "CN201810917915.7",
    "apd": "2018-08-13",
    "apy": "2018",
    "tit": "一种身份识别方法、装置和计算机可读存储介质",
    "inv": [
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109284675A",
    "apn": "CN201810917402.6",
    "apd": "2018-08-13",
    "apy": "2018",
    "tit": "一种用户的识别方法、装置及设备",
    "inv": [
      "江南"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109242601A",
    "apn": "CN201810898962.1",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "商品信息查询方法和系统",
    "inv": [
      "杨旭东",
      "侯章军",
      "张晓博"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109274575A",
    "apn": "CN201810898224.7",
    "apd": "2018-08-08",
    "apy": "2018",
    "tit": "消息发送方法及装置和电子设备",
    "inv": [
      "段青龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109359963A",
    "apn": "CN201810873630.8",
    "apd": "2018-08-02",
    "apy": "2018",
    "tit": "医保结算方法及装置和电子设备",
    "inv": [
      "邱岳亮",
      "魏青格乐图",
      "杨鑫霞",
      "江璇",
      "余逢行",
      "沈伟",
      "赵智慧",
      "陈宏敏",
      "耿浩",
      "张海宁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "医疗",
    "l2": "就医引导",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109254650A",
    "apn": "CN201810871070.2",
    "apd": "2018-08-02",
    "apy": "2018",
    "tit": "一种人机交互方法和装置",
    "inv": [
      "荣涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109240687A",
    "apn": "CN201810863629.7",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "图形界面描述文件的生成方法及装置",
    "inv": [
      "贺三元",
      "黄建锋",
      "张桀宁",
      "钱毅",
      "刘国良",
      "钱鸿强"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109271847A",
    "apn": "CN201810865617.8",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "无人结算场景中异常检测方法、装置及设备",
    "inv": [
      "侯章军",
      "杨旭东",
      "张晓博"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109146457A",
    "apn": "CN201810862936.3",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "信息录入辅助方法及装置",
    "inv": [
      "程超"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "自动录入",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109117228A",
    "apn": "CN201810863639.0",
    "apd": "2018-08-01",
    "apy": "2018",
    "tit": "图形界面的生成方法及装置",
    "inv": [
      "贺三元",
      "黄建锋",
      "张桀宁",
      "钱毅",
      "刘国良",
      "钱鸿强"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109190469A",
    "apn": "CN201810840451.4",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "一种检测方法及装置、一种计算设备及存储介质",
    "inv": [
      "杨文学"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109146464A",
    "apn": "CN201810841114.7",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "一种身份验证方法及装置、一种计算设备及存储介质",
    "inv": [
      "李发贵",
      "刘晓莹"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109117762A",
    "apn": "CN201810848248.1",
    "apd": "2018-07-27",
    "apy": "2018",
    "tit": "活体检测系统、方法及设备",
    "inv": [
      "谷晨",
      "落红卫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109063085A",
    "apn": "CN201810833890.2",
    "apd": "2018-07-26",
    "apy": "2018",
    "tit": "缩略图生成方法和装置",
    "inv": [
      "耿晓曼"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109145636A",
    "apn": "CN201810787921.5",
    "apd": "2018-07-18",
    "apy": "2018",
    "tit": "在计算设备上呈现信息的方法、装置、设备及可读介质",
    "inv": [
      "何玉婵"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "账户隐藏",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109086691A",
    "apn": "CN201810777429.X",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "一种三维脸部活体检测方法、脸部认证识别方法及装置",
    "inv": [
      "马晨光",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109063604A",
    "apn": "CN201810777461.8",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "一种人脸识别方法及终端设备",
    "inv": [
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109002796A",
    "apn": "CN201810777979.1",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "一种图像采集方法、装置和系统以及电子设备",
    "inv": [
      "马晨光",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/活体预测",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108960841A",
    "apn": "CN201810778548.7",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "支付方法、装置及系统",
    "inv": [
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109165488A",
    "apn": "CN201810777329.7",
    "apd": "2018-07-16",
    "apy": "2018",
    "tit": "身份鉴别方法及装置",
    "inv": [
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109150967A",
    "apn": "CN201810752314.5",
    "apd": "2018-07-10",
    "apy": "2018",
    "tit": "一种相册创建方法、装置以及电子设备",
    "inv": [
      "孙健康",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109145720A",
    "apn": "CN201810735594.9",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "一种人脸识别方法和装置",
    "inv": [
      "江南"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "遮挡恢复",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109064341A",
    "apn": "CN201810734268.6",
    "apd": "2018-07-06",
    "apy": "2018",
    "tit": "一种自助终端、自助装置及电子设备",
    "inv": [
      "郭玉锋",
      "王彬",
      "刘亚辉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "碎屏险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109242590A",
    "apn": "CN201810708502.8",
    "apd": "2018-07-02",
    "apy": "2018",
    "tit": "线下商品信息查询方法、装置、设备及系统",
    "inv": [
      "王磊",
      "吴军",
      "陈力"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109063442A",
    "apn": "CN201810671601.3",
    "apd": "2018-06-26",
    "apy": "2018",
    "tit": "业务实现、相机实现的方法和装置",
    "inv": [
      "孙元博",
      "杨文波",
      "黄冕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108960839A",
    "apn": "CN201810636745.5",
    "apd": "2018-06-20",
    "apy": "2018",
    "tit": "一种支付方法及装置",
    "inv": [
      "舒昌衡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108874467A",
    "apn": "CN201810627397.5",
    "apd": "2018-06-19",
    "apy": "2018",
    "tit": "一种应用功能启动方法、装置以及设备",
    "inv": [
      "梁建冬"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108921927A",
    "apn": "CN201810598120.4",
    "apd": "2018-06-12",
    "apy": "2018",
    "tit": "一种基于粒子的烟花特效实现方法、装置以及设备",
    "inv": [
      "谭旻"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108958475A",
    "apn": "CN201810575938.4",
    "apd": "2018-06-06",
    "apy": "2018",
    "tit": "虚拟对象控制方法、装置及设备",
    "inv": [
      "吴承军",
      "季婧",
      "程佳慧",
      "王亚迪",
      "段青龙",
      "吴瑾"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108921592A",
    "apn": "CN201810549476.9",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "一种价格信息的处理方法、处理装置和存储介质",
    "inv": [
      "徐达峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "海关",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108959392A",
    "apn": "CN201810556568.X",
    "apd": "2018-05-31",
    "apy": "2018",
    "tit": "在3D模型上展示富文本的方法、装置及设备",
    "inv": [
      "袁飞虎",
      "符鑫",
      "詹劲",
      "刘欢"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108664319A",
    "apn": "CN201810460529.X",
    "apd": "2018-05-15",
    "apy": "2018",
    "tit": "基于人脸识别进行任务处理的方法以及装置",
    "inv": [
      "朱沁",
      "王竹灵",
      "戈晴"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110490214A",
    "apn": "CN201810457675.7",
    "apd": "2018-05-14",
    "apy": "2018",
    "tit": "图像的识别方法及系统、存储介质及处理器",
    "inv": [
      "张帆",
      "刘永亮",
      "黄继武"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110490026A",
    "apn": "CN201810458318.2",
    "apd": "2018-05-14",
    "apy": "2018",
    "tit": "验证对象的方法、装置和系统",
    "inv": [
      "陈静"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108573286A",
    "apn": "CN201810440795.6",
    "apd": "2018-05-10",
    "apy": "2018",
    "tit": "一种理赔业务的数据处理方法、装置、设备及服务器",
    "inv": [
      "胡越"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "案件审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108647712A",
    "apn": "CN201810433919.8",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "车辆损伤识别的处理方法、处理设备、客户端及服务器",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108632530A",
    "apn": "CN201810432696.3",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "一种车辆定损的数据处理方法、装置、处理设备及客户端",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108665373A",
    "apn": "CN201810434232.6",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "一种车辆定损的交互处理方法、装置、处理设备及客户端",
    "inv": [
      "郭之友",
      "周凡",
      "张侃"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108682010A",
    "apn": "CN201810434385.0",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "车辆损伤识别的处理方法、处理设备、客户端及服务器",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108665531A",
    "apn": "CN201810434006.8",
    "apd": "2018-05-08",
    "apy": "2018",
    "tit": "3D粒子模型的变换方法及装置",
    "inv": [
      "吕耿敏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110458932A",
    "apn": "CN201810427030.9",
    "apd": "2018-05-07",
    "apy": "2018",
    "tit": "图像处理方法、装置、系统、存储介质和图像扫描设备",
    "inv": [
      "王彬",
      "冯晓端",
      "朱文峤",
      "潘攀",
      "任小枫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110443664A",
    "apn": "CN201810421323.6",
    "apd": "2018-05-04",
    "apy": "2018",
    "tit": "信息推送系统、投影系统、方法、装置及电子设备",
    "inv": [
      "张哲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110443699A",
    "apn": "CN201810414263.5",
    "apd": "2018-05-03",
    "apy": "2018",
    "tit": "资源处理方法和系统",
    "inv": [
      "孙东方"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108764391A",
    "apn": "CN201810377988.1",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "一种智能设备，商品盘点方法、装置以及设备",
    "inv": [
      "孙健康",
      "杨凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108846449A",
    "apn": "CN201810377649.3",
    "apd": "2018-04-25",
    "apy": "2018",
    "tit": "一种智能设备，商品盘点方法、装置以及设备",
    "inv": [
      "孙健康",
      "郑梦雪",
      "黄凯明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108765525A",
    "apn": "CN201810373359.1",
    "apd": "2018-04-24",
    "apy": "2018",
    "tit": "一种图片转换方法和装置",
    "inv": [
      "洪珊珊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110390641A",
    "apn": "CN201810339029.0",
    "apd": "2018-04-16",
    "apy": "2018",
    "tit": "图像脱敏方法、电子设备及存储介质",
    "inv": [
      "曹柯",
      "邱俊",
      "李伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108846399A",
    "apn": "CN201810314751.9",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "一种图像处理的方法及装置",
    "inv": [
      "杨磊磊",
      "方刚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110363830A",
    "apn": "CN201810315058.3",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "元素图像生成方法、装置及系统",
    "inv": [
      "孙东慧",
      "张庆",
      "唐浩超"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110363049A",
    "apn": "CN201810315013.6",
    "apd": "2018-04-10",
    "apy": "2018",
    "tit": "图形元素检测识别和类别确定的方法及装置",
    "inv": [
      "张庆",
      "隋宛辰",
      "唐浩超",
      "许彬"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN110363207A",
    "apn": "CN201810309030.9",
    "apd": "2018-04-09",
    "apy": "2018",
    "tit": "图像检测和分类器模型的生成方法、装置及设备",
    "inv": [
      "张帆",
      "刘永亮",
      "黄继武"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110348925A",
    "apn": "CN201810301154.2",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "门店系统、物品搭配方法、装置及电子设备",
    "inv": [
      "石克阳",
      "盛丁",
      "宾林",
      "何舒荣",
      "洪海",
      "姚迪狄",
      "冯伟",
      "谢伟志",
      "朱伟",
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110348927A",
    "apn": "CN201810302120.5",
    "apd": "2018-04-04",
    "apy": "2018",
    "tit": "信息展示处理方法、装置及门店系统",
    "inv": [
      "石克阳",
      "姚迪狄",
      "王强",
      "姚秋林",
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108921811A",
    "apn": "CN201810295312.8",
    "apd": "2018-04-03",
    "apy": "2018",
    "tit": "检测物品损伤的方法和装置、物品损伤检测器",
    "inv": [
      "刘永超",
      "章海涛",
      "郭玉锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "二手估价",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110322262A",
    "apn": "CN201810276396.0",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "门店信息处理方法、装置及门店系统",
    "inv": [
      "苏起扬",
      "曹林"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110321048A",
    "apn": "CN201810277597.2",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "三维全景场景信息处理、交互方法及装置",
    "inv": [
      "马林",
      "苏起扬",
      "胡浪宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108492111A",
    "apn": "CN201810278430.8",
    "apd": "2018-03-30",
    "apy": "2018",
    "tit": "一种交通工具的支付系统及方法",
    "inv": [
      "王猛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "自动缴费",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108647563A",
    "apn": "CN201810259950.4",
    "apd": "2018-03-27",
    "apy": "2018",
    "tit": "一种车辆定损的方法、装置及设备",
    "inv": [
      "郭之友"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108537533A",
    "apn": "CN201810245204.X",
    "apd": "2018-03-23",
    "apy": "2018",
    "tit": "一种自助购物结算方法及系统",
    "inv": [
      "孙健康",
      "杨凡",
      "黄凯明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108520605A",
    "apn": "CN201810246195.6",
    "apd": "2018-03-23",
    "apy": "2018",
    "tit": "一种自助购物风控方法及系统",
    "inv": [
      "孙健康",
      "杨凡",
      "黄凯明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108521597A",
    "apn": "CN201810234860.X",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "直播信息动态展示方法及装置",
    "inv": [
      "盛娇麒",
      "张桀宁"
    ],
    "app": [
      "浙江口碑网络技术有限公司"
    ],
    "l1": "电商",
    "l2": "信息嵌入",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108600509A",
    "apn": "CN201810236664.6",
    "apd": "2018-03-21",
    "apy": "2018",
    "tit": "三维场景模型中信息的分享方法及装置",
    "inv": [
      "钱坤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108550080A",
    "apn": "CN201810220310.2",
    "apd": "2018-03-16",
    "apy": "2018",
    "tit": "物品定损方法及装置",
    "inv": [
      "周凡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110276230A",
    "apn": "CN201810210851.7",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "用户认证、唇语识别的方法、装置和电子设备",
    "inv": [
      "吕江靖",
      "李晓波"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110278486A",
    "apn": "CN201810210845.1",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "图像处理、图像分辨率处理参数的生成方法及装置",
    "inv": [
      "杨江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110278487A",
    "apn": "CN201810210854.0",
    "apd": "2018-03-14",
    "apy": "2018",
    "tit": "图像处理方法、装置及设备",
    "inv": [
      "杨江"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110246185A",
    "apn": "CN201810187242.4",
    "apd": "2018-03-07",
    "apy": "2018",
    "tit": "图像处理方法、装置、系统、存储介质和标定系统",
    "inv": [
      "朱文峤",
      "冯晓端",
      "王彬",
      "潘攀",
      "任小枫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108551462A",
    "apn": "CN201810165947.6",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "一种信息推送方法、装置及设备",
    "inv": [
      "贺三元"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110211000A",
    "apn": "CN201810169214.X",
    "apd": "2018-02-28",
    "apy": "2018",
    "tit": "桌位状态信息处理方法、装置及系统",
    "inv": [
      "陈舒婷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108537111A",
    "apn": "CN201810159297.4",
    "apd": "2018-02-26",
    "apy": "2018",
    "tit": "一种活体检测的方法、装置及设备",
    "inv": [
      "吴鹏",
      "朱珠",
      "王海",
      "赵宏伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110166764A",
    "apn": "CN201810151922.0",
    "apd": "2018-02-14",
    "apy": "2018",
    "tit": "虚拟现实VR直播中的视角同步方法及装置",
    "inv": [
      "张哲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "直播",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108399382A",
    "apn": "CN201810148839.8",
    "apd": "2018-02-13",
    "apy": "2018",
    "tit": "车险图像处理方法和装置",
    "inv": [
      "章海涛",
      "刘永超"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110163950A",
    "apn": "CN201810145999.7",
    "apd": "2018-02-12",
    "apy": "2018",
    "tit": "一种在对象中生成阴影的方法、设备以及系统",
    "inv": [
      "姜赟",
      "晏兵兵",
      "常昕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110163699A",
    "apn": "CN201810142322.8",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "商品处理方法、系统及移动设备",
    "inv": [
      "胡斐"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110163622A",
    "apn": "CN201810142321.3",
    "apd": "2018-02-11",
    "apy": "2018",
    "tit": "获取商品鉴定书的方法、系统及商品信息展示方法",
    "inv": [
      "鲁立华",
      "冯婷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "真伪鉴定",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108427914A",
    "apn": "CN201810127142.2",
    "apd": "2018-02-08",
    "apy": "2018",
    "tit": "入离场状态检测方法和装置",
    "inv": [
      "韩喆",
      "张晓博",
      "姚四海",
      "吴军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人流统计",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110119967A",
    "apn": "CN201810122662.4",
    "apd": "2018-02-07",
    "apy": "2018",
    "tit": "图像的处理方法、系统、移动终端及产品分享方法",
    "inv": [
      "巫俊岚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "信息嵌入",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110119914A",
    "apn": "CN201810119017.7",
    "apd": "2018-02-06",
    "apy": "2018",
    "tit": "对象补货处理方法、装置和系统",
    "inv": [
      "李景崴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109934931A",
    "apn": "CN201810118842.5",
    "apd": "2018-02-06",
    "apy": "2018",
    "tit": "采集图像、建立目标物体识别模型的方法及装置",
    "inv": [
      "谢宏伟",
      "李博韧",
      "李军舰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN110119915A",
    "apn": "CN201810119026.6",
    "apd": "2018-02-06",
    "apy": "2018",
    "tit": "对象入库处理方法、装置和系统",
    "inv": [
      "李景崴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "盘点",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110111777A",
    "apn": "CN201810102463.7",
    "apd": "2018-02-01",
    "apy": "2018",
    "tit": "远程设备、用户设备、状态信息发送及接收方法和装置",
    "inv": [
      "郭云云"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108427911A",
    "apn": "CN201810089504.3",
    "apd": "2018-01-30",
    "apy": "2018",
    "tit": "一种身份验证方法、系统、装置及设备",
    "inv": [
      "张鸿",
      "官砚楚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110097488A",
    "apn": "CN201810085009.5",
    "apd": "2018-01-29",
    "apy": "2018",
    "tit": "隐形数字水印的生成和提取方法及装置",
    "inv": [
      "马越"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108364316A",
    "apn": "CN201810077811.X",
    "apd": "2018-01-26",
    "apy": "2018",
    "tit": "交互行为检测方法、装置、系统及设备",
    "inv": [
      "黄凯明",
      "张晓博",
      "傅春霖",
      "蔡鸿博",
      "陈力",
      "周乐",
      "曾晓东",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108460263A",
    "apn": "CN201810071589.2",
    "apd": "2018-01-25",
    "apy": "2018",
    "tit": "信息分享方法、装置和电子设备",
    "inv": [
      "冶秀刚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108322722A",
    "apn": "CN201810068563.2",
    "apd": "2018-01-24",
    "apy": "2018",
    "tit": "基于增强现实的图像处理方法、装置及电子设备",
    "inv": [
      "袁飞虎",
      "张敏琪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108335387A",
    "apn": "CN201810065563.7",
    "apd": "2018-01-23",
    "apy": "2018",
    "tit": "人脸识别门禁系统及门禁控制方法",
    "inv": [
      "顾超"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN108388833A",
    "apn": "CN201810036011.3",
    "apd": "2018-01-15",
    "apy": "2018",
    "tit": "一种图像识别方法、装置及设备",
    "inv": [
      "周明才",
      "张宇",
      "王楠",
      "杜志军",
      "何强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110033293A",
    "apn": "CN201810032432.9",
    "apd": "2018-01-12",
    "apy": "2018",
    "tit": "获取用户信息的方法、装置及系统",
    "inv": [
      "吴纲律",
      "邓玉明",
      "戚赟炜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "行为统计",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN108197658A",
    "apn": "CN201810026329.3",
    "apd": "2018-01-11",
    "apy": "2018",
    "tit": "图像标注信息处理方法、装置、服务器及系统",
    "inv": [
      "胡越",
      "郭昕",
      "章海涛",
      "程丹妮",
      "吴博坤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108346139A",
    "apn": "CN201810018753.3",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "一种图像筛选方法及装置",
    "inv": [
      "柳林东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN110020879A",
    "apn": "CN201810020066.5",
    "apd": "2018-01-09",
    "apy": "2018",
    "tit": "试衣间信息处理方法、装置及系统",
    "inv": [
      "魏俊卿"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108364420A",
    "apn": "CN201810016409.0",
    "apd": "2018-01-08",
    "apy": "2018",
    "tit": "图像识别方法及装置和电子设备",
    "inv": [
      "冯士恒"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108376335A",
    "apn": "CN201810010595.7",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "一种支付方法、装置及设备",
    "inv": [
      "李京",
      "冯春培",
      "高谊",
      "黄冕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110008450A",
    "apn": "CN201810012177.1",
    "apd": "2018-01-05",
    "apy": "2018",
    "tit": "图片的生成方法、装置、设备和介质",
    "inv": [
      "蔡会祥",
      "刘奎龙",
      "李郭",
      "吴尉林"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108364012A",
    "apn": "CN201810008888.1",
    "apd": "2018-01-04",
    "apy": "2018",
    "tit": "一种钱币总额的确定方法及装置",
    "inv": [
      "王康",
      "管维刚",
      "钱佳景"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "现金统计",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108171185A",
    "apn": "CN201810004129.8",
    "apd": "2018-01-03",
    "apy": "2018",
    "tit": "身份识别的方法、装置及系统",
    "inv": [
      "孙健康",
      "张晓博",
      "曾晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108280144A",
    "apn": "CN201711484443.2",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "信息展示方法、装置和电子设备",
    "inv": [
      "冶秀刚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "保险单",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108182414A",
    "apn": "CN201711479122.3",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "通行检测方法、装置以及系统",
    "inv": [
      "徐文海"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN110019877A",
    "apn": "CN201711487740.2",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "图像搜索方法、装置及系统、终端",
    "inv": [
      "王甸甸"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN110019405A",
    "apn": "CN201711457255.0",
    "apd": "2017-12-28",
    "apy": "2017",
    "tit": "信息系统、电子设备、计算机可读介质及信息处理方法",
    "inv": [
      "李天民"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能导购",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109961566A",
    "apn": "CN201711435148.8",
    "apd": "2017-12-26",
    "apy": "2017",
    "tit": "自动售货机及其信息处理方法、装置及系统",
    "inv": [
      "毛恺",
      "王庶",
      "蒋耀",
      "吕杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110110014A",
    "apn": "CN201711434485.5",
    "apd": "2017-12-26",
    "apy": "2017",
    "tit": "目标对象的位置信息的确定方法、服务器及用户客户端",
    "inv": [
      "于继军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "酒店旅行",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109961594A",
    "apn": "CN201711436577.7",
    "apd": "2017-12-26",
    "apy": "2017",
    "tit": "数据对象信息处理方法、装置及系统",
    "inv": [
      "朱瑜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109951724A",
    "apn": "CN201711386148.3",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "直播推荐方法、主播推荐模型训练方法及相关设备",
    "inv": [
      "肖蒴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108229937A",
    "apn": "CN201711387928.X",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "基于增强现实的虚拟对象分配方法及装置",
    "inv": [
      "段青龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "娱乐性支付",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN110110117A",
    "apn": "CN201711385248.4",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "一种商品搜索方法、装置以及系统",
    "inv": [
      "杨喆",
      "张志鹏",
      "袁力",
      "柯敦灵",
      "卢颖",
      "朴诚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN108230574A",
    "apn": "CN201711386786.5",
    "apd": "2017-12-20",
    "apy": "2017",
    "tit": "储物终端取件方法及装置和电子设备",
    "inv": [
      "靳慧峰",
      "刘杨辉",
      "张校川",
      "胡国安",
      "赵智慧",
      "董鹂赟",
      "韩冬"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109931923A",
    "apn": "CN201711353151.5",
    "apd": "2017-12-15",
    "apy": "2017",
    "tit": "一种导航引导图的生成方法和装置",
    "inv": [
      "吴晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107516095A",
    "apn": "CN201610430736.1",
    "apd": "2016-06-16",
    "apy": "2016",
    "tit": "一种图像识别方法及装置",
    "inv": [
      "熊诗尧",
      "江文斐",
      "储开颜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107993106A",
    "apn": "CN201711340440.1",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "电子发票生成方法及装置",
    "inv": [
      "王宇航",
      "方芳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108228050A",
    "apn": "CN201711328508.4",
    "apd": "2017-12-13",
    "apy": "2017",
    "tit": "一种图片缩放方法、装置以及电子设备",
    "inv": [
      "吴钊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108109033A",
    "apn": "CN201711262508.9",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "识别对象损伤的处理方法、装置、服务器、客户端",
    "inv": [
      "胡越",
      "郭昕",
      "章海涛",
      "程丹妮",
      "吴博坤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108022102A",
    "apn": "CN201711255814.X",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "一种身份验证方法、装置及设备",
    "inv": [
      "刘贺",
      "李哲",
      "赵宏伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109858504A",
    "apn": "CN201711240738.5",
    "apd": "2017-11-30",
    "apy": "2017",
    "tit": "一种图像识别方法、装置、系统以及计算设备",
    "inv": [
      "高凯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108133207A",
    "apn": "CN201711192125.9",
    "apd": "2017-11-24",
    "apy": "2017",
    "tit": "辅助物品的图像合规的方法、装置和电子设备",
    "inv": [
      "徐崴",
      "郑丹丹",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN108090838A",
    "apn": "CN201711161926.9",
    "apd": "2017-11-21",
    "apy": "2017",
    "tit": "识别车辆受损部件的方法、装置、服务器、客户端及系统",
    "inv": [
      "郭之友",
      "方勇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109801351A",
    "apn": "CN201711128596.3",
    "apd": "2017-11-15",
    "apy": "2017",
    "tit": "动态图像生成方法和处理设备",
    "inv": [
      "马春阳",
      "王彬"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107920202A",
    "apn": "CN201711129714.2",
    "apd": "2017-11-15",
    "apy": "2017",
    "tit": "基于增强现实的视频处理方法、装置及电子设备",
    "inv": [
      "袁飞虎",
      "余龙飞",
      "刘欢"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108010095A",
    "apn": "CN201711120649.7",
    "apd": "2017-11-14",
    "apy": "2017",
    "tit": "一种纹理合成的方法、装置及设备",
    "inv": [
      "袁飞虎"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109785276A",
    "apn": "CN201711107339.1",
    "apd": "2017-11-10",
    "apy": "2017",
    "tit": "一种业务对象图片的合成方法和装置",
    "inv": [
      "刘晨凌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108629738A",
    "apn": "CN201710155775.X",
    "apd": "2017-03-16",
    "apy": "2017",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "唐振",
      "周士奇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108022181A",
    "apn": "CN201711083393.7",
    "apd": "2017-11-07",
    "apy": "2017",
    "tit": "酒店快速入住方法及装置和电子设备",
    "inv": [
      "郭玉锋",
      "刘中胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109756781A",
    "apn": "CN201711078679.6",
    "apd": "2017-11-06",
    "apy": "2017",
    "tit": "数据处理和视频中图像定位方法和装置",
    "inv": [
      "高欣羽"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN108021865A",
    "apn": "CN201711069063.2",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "无人值守场景中非法行为的识别方法和装置",
    "inv": [
      "贺武杰",
      "曾晓东",
      "张晓博"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "治安防控",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107895267A",
    "apn": "CN201711071003.4",
    "apd": "2017-11-03",
    "apy": "2017",
    "tit": "一种公共交通支付的方法、装置及设备",
    "inv": [
      "徐文海"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "自动缴费",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109753979A",
    "apn": "CN201711061292.X",
    "apd": "2017-11-01",
    "apy": "2017",
    "tit": "基于图像特征的故障检测方法及装置",
    "inv": [
      "吴云崇",
      "闵万里",
      "金鸿",
      "吴亮亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109697728A",
    "apn": "CN201710984833.X",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "数据处理方法、装置、系统和存储介质",
    "inv": [
      "王彬",
      "于景铭",
      "冯晓端",
      "潘攀",
      "金榕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107832679A",
    "apn": "CN201710984690.2",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107729847A",
    "apn": "CN201710985755.5",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107944339A",
    "apn": "CN201710986508.7",
    "apd": "2017-10-20",
    "apy": "2017",
    "tit": "一种证件验证、身份验证方法和装置",
    "inv": [
      "郑丹丹",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109685593A",
    "apn": "CN201710980144.1",
    "apd": "2017-10-19",
    "apy": "2017",
    "tit": "数据对象信息提供方法、装置及电子设备",
    "inv": [
      "刘晓倩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109685755A",
    "apn": "CN201710966076.3",
    "apd": "2017-10-17",
    "apy": "2017",
    "tit": "电子照片生成方法、装置、设备及计算机存储介质",
    "inv": [
      "蔡羽"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107818301A",
    "apn": "CN201710970203.7",
    "apd": "2017-10-16",
    "apy": "2017",
    "tit": "更新生物特征模板的方法、装置和电子设备",
    "inv": [
      "刘贺",
      "赵宏伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109669783A",
    "apn": "CN201710953537.3",
    "apd": "2017-10-13",
    "apy": "2017",
    "tit": "数据处理方法和设备",
    "inv": [
      "方文骁",
      "张剑"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN109657673A",
    "apn": "CN201710943489.X",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "图像识别方法和终端",
    "inv": [
      "薛琴",
      "王炎",
      "郭山",
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109658365A",
    "apn": "CN201710942829.7",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "图像处理方法、装置、系统和存储介质",
    "inv": [
      "王彬",
      "于景铭",
      "冯晓端",
      "潘攀",
      "金榕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109658455A",
    "apn": "CN201710941265.5",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "图像处理方法和处理设备",
    "inv": [
      "刘宇",
      "刘瑞涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109658515A",
    "apn": "CN201710941538.6",
    "apd": "2017-10-11",
    "apy": "2017",
    "tit": "点云网格化方法、装置、设备及计算机存储介质",
    "inv": [
      "王彬",
      "于景铭",
      "冯晓端",
      "潘攀",
      "金榕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109461215A",
    "apn": "CN201811088966.X",
    "apd": "2018-09-18",
    "apy": "2018",
    "tit": "角色插画的生成方法、装置、计算机设备及存储介质",
    "inv": [
      "林晨希",
      "曹燕琦"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109658167A",
    "apn": "CN201710936943.9",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "试妆镜设备及其控制方法、装置",
    "inv": [
      "丁海霞",
      "程飞",
      "毛恺",
      "陈远铭",
      "王庶",
      "蒋耀",
      "吕杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109634788A",
    "apn": "CN201710930361.X",
    "apd": "2017-10-09",
    "apy": "2017",
    "tit": "一种终端适配验证方法及系统、终端",
    "inv": [
      "何霞",
      "张文",
      "吴雄",
      "黄秀美"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109600544A",
    "apn": "CN201710939457.2",
    "apd": "2017-09-30",
    "apy": "2017",
    "tit": "一种局部动态影像生成方法及装置",
    "inv": [
      "耿军",
      "朱斌",
      "胡康康",
      "马春阳",
      "李郭",
      "刍牧"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109583287A",
    "apn": "CN201710911613.4",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "实物识别方法及验证方法",
    "inv": [
      "王炎",
      "冯雪涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109584295A",
    "apn": "CN201710912283.0",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "对图像内目标物体进行自动标注的方法、装置及系统",
    "inv": [
      "李博韧",
      "谢宏伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109583453A",
    "apn": "CN201710905598.2",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "图像的识别方法和装置、数据的识别方法、终端",
    "inv": [
      "于鲲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109598517A",
    "apn": "CN201710910632.5",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "商品通关处理、对象的处理及其类别预测方法和装置",
    "inv": [
      "林沛坤",
      "王浩",
      "朱洪波"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "海关",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109583285A",
    "apn": "CN201710909942.5",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "对象识别方法",
    "inv": [
      "王炎",
      "冯雪涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109582122A",
    "apn": "CN201710911603.0",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "增强现实信息提供方法、装置及电子设备",
    "inv": [
      "吴晓东",
      "肖领",
      "周建华",
      "周剑",
      "王劲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107748893A",
    "apn": "CN201710911173.2",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "提升车辆定损图像识别结果的方法、装置及服务器",
    "inv": [
      "王子霄",
      "李冠如",
      "王剑",
      "张侃",
      "周凡",
      "张泰玮",
      "樊太飞",
      "程丹妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109583264A",
    "apn": "CN201710898341.9",
    "apd": "2017-09-28",
    "apy": "2017",
    "tit": "信息识别方法、装置及电子设备",
    "inv": [
      "贾海军",
      "李文龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107679480A",
    "apn": "CN201710891254.0",
    "apd": "2017-09-27",
    "apy": "2017",
    "tit": "识别真实拍摄图片的方法和装置",
    "inv": [
      "郑飞"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109558773A",
    "apn": "CN201710884606.X",
    "apd": "2017-09-26",
    "apy": "2017",
    "tit": "信息识别方法、装置及电子设备",
    "inv": [
      "朱碧军",
      "贾海军",
      "李文龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109559183A",
    "apn": "CN201710884248.2",
    "apd": "2017-09-26",
    "apy": "2017",
    "tit": "加油站数据处理方法、装置及系统",
    "inv": [
      "徐思齐",
      "谢逸清"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "自动缴费",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107833083A",
    "apn": "CN201710873473.6",
    "apd": "2017-09-25",
    "apy": "2017",
    "tit": "一种货物订单处理方法、装置、服务器、购物终端及系统",
    "inv": [
      "张鸿",
      "曾晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109544698A",
    "apn": "CN201710866908.4",
    "apd": "2017-09-22",
    "apy": "2017",
    "tit": "图像展示方法、装置及电子设备",
    "inv": [
      "吴晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109509140A",
    "apn": "CN201710832013.9",
    "apd": "2017-09-15",
    "apy": "2017",
    "tit": "显示方法及装置",
    "inv": [
      "陈宇",
      "郭红"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109509141A",
    "apn": "CN201710832373.9",
    "apd": "2017-09-15",
    "apy": "2017",
    "tit": "图像处理方法、头像设置方法及装置",
    "inv": [
      "张振东",
      "谢铝菁",
      "陈宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交表情制作",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107766703A",
    "apn": "CN201710828104.5",
    "apd": "2017-09-14",
    "apy": "2017",
    "tit": "水印添加处理方法、装置及客户端",
    "inv": [
      "张仕奇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109509304A",
    "apn": "CN201710828802.5",
    "apd": "2017-09-14",
    "apy": "2017",
    "tit": "自动售货机及其控制方法、装置及计算机系统",
    "inv": [
      "廖成",
      "黄小林",
      "曲阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "智能货柜",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109493431A",
    "apn": "CN201710819123.1",
    "apd": "2017-09-12",
    "apy": "2017",
    "tit": "3D模型数据处理方法、装置及系统",
    "inv": [
      "张哲",
      "胡晓航"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109521927A",
    "apn": "CN201710851144.1",
    "apd": "2017-09-20",
    "apy": "2017",
    "tit": "机器人互动方法和设备",
    "inv": [
      "贾梓筠"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109472655A",
    "apn": "CN201710802415.4",
    "apd": "2017-09-07",
    "apy": "2017",
    "tit": "数据对象试用方法、装置及系统",
    "inv": [
      "程飞",
      "李晨",
      "方芳",
      "梁栋",
      "肖领",
      "陈远铭",
      "易东",
      "邹建法",
      "陈岩",
      "檀彦利",
      "刘博",
      "寇威",
      "占芳",
      "王嘉羽",
      "丁海霞",
      "邱红阳",
      "张自萍"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107679457A",
    "apn": "CN201710795160.3",
    "apd": "2017-09-06",
    "apy": "2017",
    "tit": "用户身份校验方法及装置",
    "inv": [
      "徐崴",
      "郑丹丹",
      "李静",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109947965A",
    "apn": "CN201710787773.2",
    "apd": "2017-09-04",
    "apy": "2017",
    "tit": "对象识别、数据集合的更新、数据处理方法与装置",
    "inv": [
      "于鲲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109426714A",
    "apn": "CN201710766078.8",
    "apd": "2017-08-30",
    "apy": "2017",
    "tit": "换人检测方法及装置、用户身份验证方法及装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107679861A",
    "apn": "CN201710765152.4",
    "apd": "2017-08-30",
    "apy": "2017",
    "tit": "资源转移方法、资金支付方法、装置及电子设备",
    "inv": [
      "林述民",
      "董鹂贇",
      "黄建龙",
      "贾冬麟",
      "李渊深",
      "周亮",
      "赵宏伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109426781A",
    "apn": "CN201710755241.0",
    "apd": "2017-08-29",
    "apy": "2017",
    "tit": "人脸识别数据库的构建方法、人脸识别方法、装置及设备",
    "inv": [
      "江南"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN108509436A",
    "apn": "CN201710102422.3",
    "apd": "2017-02-24",
    "apy": "2017",
    "tit": "一种确定推荐对象的方法、装置及计算机存储介质",
    "inv": [
      "程治淇",
      "刘扬",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109427050A",
    "apn": "CN201710727968.8",
    "apd": "2017-08-23",
    "apy": "2017",
    "tit": "导轮质量检测方法及设备",
    "inv": [
      "李锐",
      "吴云崇",
      "周小栋",
      "闵万里"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109429062A",
    "apn": "CN201710726197.0",
    "apd": "2017-08-22",
    "apy": "2017",
    "tit": "金字塔模型的处理方法和装置、图像编码方法和装置",
    "inv": [
      "盛骁杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109427031A",
    "apn": "CN201710724353.X",
    "apd": "2017-08-22",
    "apy": "2017",
    "tit": "一种数据处理方法及设备",
    "inv": [
      "潘学瑞",
      "程哲",
      "张玉亮",
      "唐迪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107613046A",
    "apn": "CN201710724994.5",
    "apd": "2017-08-22",
    "apy": "2017",
    "tit": "滤镜管道系统、图像数据处理方法、装置以及电子设备",
    "inv": [
      "曾柏然"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109405735A",
    "apn": "CN201710714277.4",
    "apd": "2017-08-18",
    "apy": "2017",
    "tit": "三维扫描系统和三维扫描方法",
    "inv": [
      "于景铭",
      "冯晓端",
      "王彬",
      "潘攀",
      "金榕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109410311A",
    "apn": "CN201710713427.X",
    "apd": "2017-08-18",
    "apy": "2017",
    "tit": "三维建模设备、系统、方法和存储介质",
    "inv": [
      "冯晓端",
      "王彬",
      "于景铭",
      "潘攀",
      "金榕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107563416A",
    "apn": "CN201710705919.4",
    "apd": "2017-08-17",
    "apy": "2017",
    "tit": "一种对象识别方法和装置",
    "inv": [
      "夏威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107622434A",
    "apn": "CN201710692154.5",
    "apd": "2017-08-14",
    "apy": "2017",
    "tit": "信息处理方法、系统及智能设备",
    "inv": [
      "董鹂贇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107451868A",
    "apn": "CN201710659600.2",
    "apd": "2017-08-04",
    "apy": "2017",
    "tit": "一种物品估价的方法及装置",
    "inv": [
      "郭玉锋",
      "王彬",
      "刘亚辉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "二手估价",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109388734A",
    "apn": "CN201710660663.X",
    "apd": "2017-08-04",
    "apy": "2017",
    "tit": "基于图片的商品购买实现方法、利用图片进行搜索的方法及装置",
    "inv": [
      "李谦"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109389669A",
    "apn": "CN201710660572.6",
    "apd": "2017-08-04",
    "apy": "2017",
    "tit": "虚拟环境中人体三维模型构建方法及系统",
    "inv": [
      "孔喆"
    ],
    "app": [
      "阿里健康信息技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109389002A",
    "apn": "CN201710652099.7",
    "apd": "2017-08-02",
    "apy": "2017",
    "tit": "活体检测方法及装置",
    "inv": [
      "车向前"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107610091A",
    "apn": "CN201710640784.8",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "车险图像处理方法、装置、服务器及系统",
    "inv": [
      "侯金龙",
      "章海涛",
      "郭昕",
      "徐娟",
      "王剑",
      "程远",
      "程丹妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107491965A",
    "apn": "CN201710637558.4",
    "apd": "2017-07-31",
    "apy": "2017",
    "tit": "一种生物特征库的建立方法和装置",
    "inv": [
      "翟芬",
      "顾春雷",
      "路磊",
      "刘杨辉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109309809A",
    "apn": "CN201710632975.X",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "跨区域目标轨迹跟踪的方法及数据处理方法、装置和系统",
    "inv": [
      "陈伟",
      "葛有功"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109325933A",
    "apn": "CN201710632714.8",
    "apd": "2017-07-28",
    "apy": "2017",
    "tit": "一种翻拍图像识别方法及装置",
    "inv": [
      "冯雪涛",
      "王炎"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107491674A",
    "apn": "CN201710622716.9",
    "apd": "2017-07-27",
    "apy": "2017",
    "tit": "基于特征信息进行用户验证的方法及装置",
    "inv": [
      "江南",
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109308740A",
    "apn": "CN201710625589.8",
    "apd": "2017-07-27",
    "apy": "2017",
    "tit": "3D场景数据处理方法、装置及电子设备",
    "inv": [
      "吴晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109302636A",
    "apn": "CN201710608608.6",
    "apd": "2017-07-24",
    "apy": "2017",
    "tit": "提供数据对象全景图信息的方法及装置",
    "inv": [
      "宁宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107516105A",
    "apn": "CN201710594226.2",
    "apd": "2017-07-20",
    "apy": "2017",
    "tit": "图像处理方法及装置",
    "inv": [
      "江南",
      "郭明宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "底图更新",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN107590429A",
    "apn": "CN201710594470.9",
    "apd": "2017-07-20",
    "apy": "2017",
    "tit": "基于眼纹特征进行验证的方法及装置",
    "inv": [
      "曾岳伟",
      "何晓光"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109993687A",
    "apn": "CN201711489949.2",
    "apd": "2017-12-29",
    "apy": "2017",
    "tit": "一种图像信息处理方法及装置",
    "inv": [
      "马春阳",
      "耿军",
      "李郭"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109308729A",
    "apn": "CN201710625596.8",
    "apd": "2017-07-27",
    "apy": "2017",
    "tit": "图片合成处理方法、装置及系统",
    "inv": [
      "谢晓君"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107578439A",
    "apn": "CN201710589943.6",
    "apd": "2017-07-19",
    "apy": "2017",
    "tit": "生成目标图像的方法、装置及设备",
    "inv": [
      "郑丹丹",
      "吴燕萍",
      "徐崴",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109272369A",
    "apn": "CN201710587790.1",
    "apd": "2017-07-18",
    "apy": "2017",
    "tit": "数据对象信息处理方法、装置及系统",
    "inv": [
      "朱瑜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "餐饮",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109214253A",
    "apn": "CN201710552142.2",
    "apd": "2017-07-07",
    "apy": "2017",
    "tit": "一种视频帧检测方法及装置",
    "inv": [
      "赵一儒",
      "刘垚",
      "邓兵",
      "黄建强",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109214403A",
    "apn": "CN201710546203.4",
    "apd": "2017-07-06",
    "apy": "2017",
    "tit": "图像识别方法、装置及设备、可读介质",
    "inv": [
      "李博",
      "张伦",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107480587A",
    "apn": "CN201710544836.1",
    "apd": "2017-07-06",
    "apy": "2017",
    "tit": "一种模型配置以及图像识别的方法及装置",
    "inv": [
      "郑毅",
      "杜志军",
      "宋雪梅",
      "王楠"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109213310A",
    "apn": "CN201710543374.1",
    "apd": "2017-07-05",
    "apy": "2017",
    "tit": "信息互动设备、数据对象信息处理方法及装置",
    "inv": [
      "李明",
      "陈畅",
      "赵鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109101103A",
    "apn": "CN201710474295.X",
    "apd": "2017-06-21",
    "apy": "2017",
    "tit": "一种眨眼检测方法和装置",
    "inv": [
      "肖蒴"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107526090A",
    "apn": "CN201710462361.1",
    "apd": "2017-06-19",
    "apy": "2017",
    "tit": "位置定位方法、设备及系统、计算机可读存储介质",
    "inv": [
      "刘杰",
      "李佳佳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109146587A",
    "apn": "CN201710459202.6",
    "apd": "2017-06-16",
    "apy": "2017",
    "tit": "信息测量方法及装置",
    "inv": [
      "谭建国",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109086645A",
    "apn": "CN201710444122.3",
    "apd": "2017-06-13",
    "apy": "2017",
    "tit": "人脸识别方法、装置以及虚假用户的识别方法、装置",
    "inv": [
      "林钧燧"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN109034815A",
    "apn": "CN201710433523.9",
    "apd": "2017-06-09",
    "apy": "2017",
    "tit": "基于生物特征进行安全验证的方法及装置",
    "inv": [
      "靳慧峰",
      "刘杨辉",
      "修超",
      "董鹂贇",
      "肖维杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107358157A",
    "apn": "CN201710421333.5",
    "apd": "2017-06-07",
    "apy": "2017",
    "tit": "一种人脸活体检测方法、装置以及电子设备",
    "inv": [
      "马晨光"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN109002826A",
    "apn": "CN201710422373.1",
    "apd": "2017-06-07",
    "apy": "2017",
    "tit": "一种基于图像的注册与验证方法及装置",
    "inv": [
      "杨磊磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108961362A",
    "apn": "CN201710393580.9",
    "apd": "2017-05-27",
    "apy": "2017",
    "tit": "一种网络图片的生成方法与装置",
    "inv": [
      "刘恒",
      "鲍军",
      "徐晗曦",
      "吴春松",
      "陈雯",
      "陈彤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108958573A",
    "apn": "CN201710386069.6",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "基于虚拟现实场景的身份认证方法及装置",
    "inv": [
      "刘杰",
      "李佳佳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108961260A",
    "apn": "CN201710383690.7",
    "apd": "2017-05-26",
    "apy": "2017",
    "tit": "图像二值化方法及装置、计算机存储介质",
    "inv": [
      "刘正保"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108958462A",
    "apn": "CN201710377499.1",
    "apd": "2017-05-25",
    "apy": "2017",
    "tit": "一种虚拟对象的展示方法及装置",
    "inv": [
      "沈慧",
      "陈永健",
      "姜飞俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109246412A",
    "apn": "CN201710380411.1",
    "apd": "2017-05-25",
    "apy": "2017",
    "tit": "一种手术室记录系统及方法、手术室",
    "inv": [
      "孔喆",
      "张效民"
    ],
    "app": [
      "阿里健康信息技术有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108961170A",
    "apn": "CN201710375442.8",
    "apd": "2017-05-24",
    "apy": "2017",
    "tit": "图像处理方法、装置和系统",
    "inv": [
      "王东",
      "刘华平"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108960951A",
    "apn": "CN201710370300.2",
    "apd": "2017-05-23",
    "apy": "2017",
    "tit": "一种订单处理的方法及设备",
    "inv": [
      "曲奎林"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108960266A",
    "apn": "CN201710364321.3",
    "apd": "2017-05-22",
    "apy": "2017",
    "tit": "图像目标检测方法及装置",
    "inv": [
      "储文青",
      "华先胜",
      "黄建强",
      "周昌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN108957024A",
    "apn": "CN201710364557.7",
    "apd": "2017-05-22",
    "apy": "2017",
    "tit": "一种速度测量的方法、装置以及电子设备",
    "inv": [
      "夏瑜",
      "赵一儒",
      "周昌",
      "黄建强",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN108960265A",
    "apn": "CN201710362944.7",
    "apd": "2017-05-22",
    "apy": "2017",
    "tit": "图像分类过程的优化方法、图像分类方法、装置及系统",
    "inv": [
      "魏溪含",
      "申晨"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108960247A",
    "apn": "CN201710363563.0",
    "apd": "2017-05-22",
    "apy": "2017",
    "tit": "图像显著性检测方法、装置以及电子设备",
    "inv": [
      "胡康康"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN108880815A",
    "apn": "CN201710335664.7",
    "apd": "2017-05-12",
    "apy": "2017",
    "tit": "身份验证方法、装置和系统",
    "inv": [
      "蒋伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108876944A",
    "apn": "CN201710333813.6",
    "apd": "2017-05-12",
    "apy": "2017",
    "tit": "一种车载支付的方法和装置",
    "inv": [
      "张鸿",
      "叶仲正",
      "陈力",
      "周乐"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "自动缴费",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107194323A",
    "apn": "CN201710294010.4",
    "apd": "2017-04-28",
    "apy": "2017",
    "tit": "车辆定损图像获取方法、装置、服务器和终端设备",
    "inv": [
      "章海涛",
      "侯金龙",
      "郭昕",
      "程远",
      "王剑",
      "徐娟",
      "周凡",
      "张侃"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN107368776A",
    "apn": "CN201710294742.3",
    "apd": "2017-04-28",
    "apy": "2017",
    "tit": "车辆定损图像获取方法、装置、服务器和终端设备",
    "inv": [
      "章海涛",
      "侯金龙",
      "郭昕",
      "程远",
      "王剑",
      "徐娟",
      "周凡",
      "张侃"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN107248135A",
    "apn": "CN201710281412.0",
    "apd": "2017-04-26",
    "apy": "2017",
    "tit": "防伪图像的生成、识别方法及装置、计算机存储介质",
    "inv": [
      "丁伟伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "娱乐性支付",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107368775A",
    "apn": "CN201710266805.4",
    "apd": "2017-04-21",
    "apy": "2017",
    "tit": "一种虹膜识别过程中的预览方法及装置",
    "inv": [
      "李小峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107391060A",
    "apn": "CN201710266388.3",
    "apd": "2017-04-21",
    "apy": "2017",
    "tit": "图像显示方法、装置、系统及设备、可读介质",
    "inv": [
      "郭一贤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108733690A",
    "apn": "CN201710252448.6",
    "apd": "2017-04-18",
    "apy": "2017",
    "tit": "图像数据存储方法、装置及电子设备",
    "inv": [
      "董行"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107395853A",
    "apn": "CN201710245191.1",
    "apd": "2017-04-14",
    "apy": "2017",
    "tit": "电子设备的安全防护方法和装置",
    "inv": [
      "柳林东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107358596A",
    "apn": "CN201710232956.8",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "一种基于图像的车辆定损方法、装置、电子设备及系统",
    "inv": [
      "章海涛",
      "徐娟",
      "侯金龙",
      "王剑",
      "郭昕",
      "程丹妮",
      "胡越",
      "吴博坤",
      "陈燕青"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107403424A",
    "apn": "CN201710233656.1",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "一种基于图像的车辆定损方法、装置及电子设备",
    "inv": [
      "章海涛",
      "徐娟",
      "侯金龙",
      "王剑",
      "郭昕",
      "程丹妮",
      "胡越",
      "吴博坤",
      "陈燕青"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107766805A",
    "apn": "CN201710911129.1",
    "apd": "2017-09-29",
    "apy": "2017",
    "tit": "提升车辆定损图像识别结果的方法、装置及服务器",
    "inv": [
      "王子霄",
      "李冠如",
      "王剑",
      "张侃",
      "周凡",
      "张泰玮",
      "樊太飞",
      "程丹妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107392218A",
    "apn": "CN201710233657.6",
    "apd": "2017-04-11",
    "apy": "2017",
    "tit": "一种基于图像的车辆定损方法、装置及电子设备",
    "inv": [
      "章海涛",
      "徐娟",
      "侯金龙",
      "王剑",
      "郭昕",
      "程丹妮",
      "胡越",
      "吴博坤",
      "陈燕青"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "保险",
    "l2": "车险",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108664514A",
    "apn": "CN201710209625.2",
    "apd": "2017-03-31",
    "apy": "2017",
    "tit": "一种图像搜索方法、服务器及存储介质",
    "inv": [
      "李敏",
      "赵康",
      "潘攀",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107093199A",
    "apn": "CN201710197791.5",
    "apd": "2017-03-29",
    "apy": "2017",
    "tit": "用于生成线索图的方法及装置",
    "inv": [
      "周明才",
      "杜志军",
      "丁威",
      "王楠",
      "何强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "娱乐性支付",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107093066A",
    "apn": "CN201710175401.4",
    "apd": "2017-03-22",
    "apy": "2017",
    "tit": "业务实现方法和装置",
    "inv": [
      "修超",
      "陈星",
      "靳慧峰",
      "刘杨辉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107122642A",
    "apn": "CN201710154808.9",
    "apd": "2017-03-15",
    "apy": "2017",
    "tit": "基于虚拟现实环境的身份认证方法及装置",
    "inv": [
      "尹欢密",
      "林锋",
      "叶仲正",
      "王磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108573519A",
    "apn": "CN201710149137.7",
    "apd": "2017-03-14",
    "apy": "2017",
    "tit": "三维图形文件生成和在客户端展示三维图形的方法及装置",
    "inv": [
      "蒋善斌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN109285122A",
    "apn": "CN201710597224.9",
    "apd": "2017-07-20",
    "apy": "2017",
    "tit": "一种进行图像处理的方法和设备",
    "inv": [
      "蒋静远"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108573515A",
    "apn": "CN201710143028.4",
    "apd": "2017-03-10",
    "apy": "2017",
    "tit": "一种合图生成方法及装置、合图模板生成方法及装置",
    "inv": [
      "钱峰",
      "彭朝辉",
      "孟颖",
      "蔡劲",
      "陈养剑",
      "叶浩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107276974A",
    "apn": "CN201710140189.8",
    "apd": "2017-03-10",
    "apy": "2017",
    "tit": "一种信息处理方法及装置",
    "inv": [
      "贺三元"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107103503A",
    "apn": "CN201710132344.1",
    "apd": "2017-03-07",
    "apy": "2017",
    "tit": "一种订单信息确定方法和装置",
    "inv": [
      "李佳佳",
      "焦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "自助结算",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107274431A",
    "apn": "CN201710131857.0",
    "apd": "2017-03-07",
    "apy": "2017",
    "tit": "视频内容增强方法及装置",
    "inv": [
      "刘欢"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107093191A",
    "apn": "CN201710127050.X",
    "apd": "2017-03-06",
    "apy": "2017",
    "tit": "一种图像匹配算法的验证方法、装置及计算机存储介质",
    "inv": [
      "郭永震",
      "姚维",
      "郭庆巍"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107123013A",
    "apn": "CN201710119872.3",
    "apd": "2017-03-01",
    "apy": "2017",
    "tit": "基于增强现实的线下交互方法及装置",
    "inv": [
      "叶仲正",
      "张鸿"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108513119A",
    "apn": "CN201710109026.3",
    "apd": "2017-02-27",
    "apy": "2017",
    "tit": "图像的映射、处理方法、装置和机器可读介质",
    "inv": [
      "盛骁杰",
      "吴俞醒"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108510432A",
    "apn": "CN201710103237.6",
    "apd": "2017-02-24",
    "apy": "2017",
    "tit": "一种影像图像的展示方法、装置、系统及存储介质",
    "inv": [
      "张效民",
      "吴宏星",
      "孔喆"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "医疗",
    "l2": "医疗影像展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106980983A",
    "apn": "CN201710100962.8",
    "apd": "2017-02-23",
    "apy": "2017",
    "tit": "基于虚拟现实场景的业务认证方法及装置",
    "inv": [
      "吴军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108460649A",
    "apn": "CN201710097375.8",
    "apd": "2017-02-22",
    "apy": "2017",
    "tit": "一种图像识别方法及装置",
    "inv": [
      "陈凯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防翻拍",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107016550A",
    "apn": "CN201710093894.7",
    "apd": "2017-02-21",
    "apy": "2017",
    "tit": "增强现实场景下虚拟对象的分配方法和装置",
    "inv": [
      "郭龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "娱乐性支付",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108460389A",
    "apn": "CN201710089723.7",
    "apd": "2017-02-20",
    "apy": "2017",
    "tit": "一种识别图像中对象的类型预测方法、装置及电子设备",
    "inv": [
      "潘攀",
      "刘巍",
      "李敏",
      "张迎亚",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108459700A",
    "apn": "CN201710087497.9",
    "apd": "2017-02-17",
    "apy": "2017",
    "tit": "提供交互对象信息的方法及装置",
    "inv": [
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108459782A",
    "apn": "CN201710085422.7",
    "apd": "2017-02-17",
    "apy": "2017",
    "tit": "一种输入方法、装置、设备、系统和计算机存储介质",
    "inv": [
      "姚迪狄",
      "黄丛宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108462889A",
    "apn": "CN201710086538.2",
    "apd": "2017-02-17",
    "apy": "2017",
    "tit": "直播过程中的信息推荐方法及装置",
    "inv": [
      "王鹏飞"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108428215A",
    "apn": "CN201710080325.9",
    "apd": "2017-02-15",
    "apy": "2017",
    "tit": "一种图像处理方法、装置和设备",
    "inv": [
      "秦文煜",
      "唐振"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108428211A",
    "apn": "CN201710081731.7",
    "apd": "2017-02-15",
    "apy": "2017",
    "tit": "图像的处理方法、装置和机器可读介质",
    "inv": [
      "盛骁杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108428214A",
    "apn": "CN201710075865.8",
    "apd": "2017-02-13",
    "apy": "2017",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "徐冉",
      "唐振"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108416759A",
    "apn": "CN201710074056.5",
    "apd": "2017-02-10",
    "apy": "2017",
    "tit": "显示故障的检测方法、装置及设备、可读介质",
    "inv": [
      "陶震"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工农业",
    "l2": "缺陷",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108416757A",
    "apn": "CN201710073806.7",
    "apd": "2017-02-10",
    "apy": "2017",
    "tit": "一种图像处理方法及其应用方法、装置及设备",
    "inv": [
      "罗悦"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107038619A",
    "apn": "CN201710071890.9",
    "apd": "2017-02-09",
    "apy": "2017",
    "tit": "虚拟资源管理方法及装置",
    "inv": [
      "尹欢密",
      "曾晓东",
      "张奇",
      "赵琳昕"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "娱乐性支付",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108363939A",
    "apn": "CN201710061682.0",
    "apd": "2017-01-26",
    "apy": "2017",
    "tit": "特征图像的获取方法及获取装置、用户认证方法",
    "inv": [
      "王正博"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108346056A",
    "apn": "CN201710055287.1",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "团体状况的认证方法及装置",
    "inv": [
      "周静",
      "傅秋艳",
      "王宇",
      "林青青",
      "李文南",
      "吕鸣程",
      "汪宁校",
      "王芳成"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103871044B",
    "apn": "CN201210545728.3",
    "apd": "2012-12-14",
    "apy": "2012",
    "tit": "一种图像签名生成方法和图像验证方法及装置",
    "inv": [
      "何成剑",
      "薛晖",
      "邓宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "防虚假商品图",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108319888A",
    "apn": "CN201710041149.8",
    "apd": "2017-01-17",
    "apy": "2017",
    "tit": "视频类型的识别方法及装置、计算机终端",
    "inv": [
      "谢世鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107038408A",
    "apn": "CN201710018395.1",
    "apd": "2017-01-11",
    "apy": "2017",
    "tit": "基于增强现实的图像识别方法及装置",
    "inv": [
      "王林青",
      "曾晓东",
      "张鸿",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108280459A",
    "apn": "CN201710012643.1",
    "apd": "2017-01-06",
    "apy": "2017",
    "tit": "图片的处理方法、装置及系统",
    "inv": [
      "许涵斌",
      "靳玉康"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108268575A",
    "apn": "CN201710006138.6",
    "apd": "2017-01-04",
    "apy": "2017",
    "tit": "标注信息的处理方法、装置和系统",
    "inv": [
      "占怀旻"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108257205A",
    "apn": "CN201611245387.2",
    "apd": "2016-12-29",
    "apy": "2016",
    "tit": "一种三维模型构建方法、装置及系统",
    "inv": [
      "张哲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108242046A",
    "apn": "CN201611229145.4",
    "apd": "2016-12-27",
    "apy": "2016",
    "tit": "图片处理方法及相关设备",
    "inv": [
      "任豪毅"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN108205804A",
    "apn": "CN201611168058.2",
    "apd": "2016-12-16",
    "apy": "2016",
    "tit": "图像处理方法、装置及电子设备",
    "inv": [
      "秦文煜",
      "黄英"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106920079A",
    "apn": "CN201611146666.3",
    "apd": "2016-12-13",
    "apy": "2016",
    "tit": "基于增强现实的虚拟对象分配方法及装置",
    "inv": [
      "段青龙",
      "陈冠华",
      "季婧",
      "程佳慧",
      "苑露"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "娱乐性支付",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106888203A",
    "apn": "CN201611146660.6",
    "apd": "2016-12-13",
    "apy": "2016",
    "tit": "基于增强现实的虚拟对象分配方法及装置",
    "inv": [
      "段青龙",
      "陈冠华",
      "季婧",
      "程佳慧",
      "苑露"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "娱乐性支付",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107024982A",
    "apn": "CN201611129582.9",
    "apd": "2016-12-09",
    "apy": "2016",
    "tit": "一种虚拟现实设备的安全监控方法、装置及虚拟现实设备",
    "inv": [
      "柳林东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN104050568B",
    "apn": "CN201310076941.9",
    "apd": "2013-03-11",
    "apy": "2013",
    "tit": "一种商品图片展现的方法和系统",
    "inv": [
      "钟顶明",
      "高洁",
      "左梓佑",
      "叶克良"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "信息嵌入",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN104240175A",
    "apn": "CN201310228876.7",
    "apd": "2013-06-08",
    "apy": "2013",
    "tit": "一种图片编辑及渲染的方法和装置",
    "inv": [
      "黄可杰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108154413A",
    "apn": "CN201611104700.0",
    "apd": "2016-12-05",
    "apy": "2016",
    "tit": "生成、提供数据对象信息页面的方法及装置",
    "inv": [
      "吴晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107025583A",
    "apn": "CN201610067014.4",
    "apd": "2016-01-29",
    "apy": "2016",
    "tit": "一种页面控件的渲染方法、设备和终端设备",
    "inv": [
      "栗志果"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108133454A",
    "apn": "CN201611091651.1",
    "apd": "2016-12-01",
    "apy": "2016",
    "tit": "空间几何模型图像切换方法、装置、系统及交互设备",
    "inv": [
      "胡可本"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108121943A",
    "apn": "CN201611090126.8",
    "apd": "2016-11-30",
    "apy": "2016",
    "tit": "基于图片的判别方法及装置和计算设备",
    "inv": [
      "于鲲",
      "王炎"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107016452A",
    "apn": "CN201611089932.3",
    "apd": "2016-11-30",
    "apy": "2016",
    "tit": "基于增强现实的线下交互方法及装置",
    "inv": [
      "尹欢密"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107066079A",
    "apn": "CN201611079020.8",
    "apd": "2016-11-29",
    "apy": "2016",
    "tit": "基于虚拟现实场景的业务实现方法及装置",
    "inv": [
      "吴军",
      "尹欢密",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108122196A",
    "apn": "CN201611070684.8",
    "apd": "2016-11-28",
    "apy": "2016",
    "tit": "图片的纹理贴图方法和装置",
    "inv": [
      "李利民",
      "董民",
      "慕银锁",
      "陶伟成"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108109195A",
    "apn": "CN201611059471.5",
    "apd": "2016-11-25",
    "apy": "2016",
    "tit": "一种用于展示文物缺损部件的方法、装置及系统",
    "inv": [
      "陶智",
      "朱哲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "文物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108109212A",
    "apn": "CN201611060601.7",
    "apd": "2016-11-25",
    "apy": "2016",
    "tit": "一种文物修复方法、装置及系统",
    "inv": [
      "陶智",
      "朱哲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "文物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN105631455B",
    "apn": "CN201410584982.3",
    "apd": "2014-10-27",
    "apy": "2014",
    "tit": "一种图像主体提取方法及系统",
    "inv": [
      "安山",
      "张洪明",
      "刘彬",
      "陈国成"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN108074114A",
    "apn": "CN201610987413.2",
    "apd": "2016-11-09",
    "apy": "2016",
    "tit": "提供虚拟资源对象的方法及装置",
    "inv": [
      "王玲",
      "袁芳舟",
      "郑重",
      "沈琦绩",
      "赵盛",
      "卢唯",
      "陈煌",
      "袁岳峰",
      "许大芮",
      "金擘",
      "覃栋",
      "王昕若",
      "孟庆广",
      "林豪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN108008870A",
    "apn": "CN201610942775.X",
    "apd": "2016-11-01",
    "apy": "2016",
    "tit": "一种图像呈现方法、装置、电子设备",
    "inv": [
      "张磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106327549A",
    "apn": "CN201510377375.4",
    "apd": "2015-06-30",
    "apy": "2015",
    "tit": "图片展示方法及装置",
    "inv": [
      "李恬"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105868219B",
    "apn": "CN201510035601.0",
    "apd": "2015-01-23",
    "apy": "2015",
    "tit": "一种信息发布方法及装置",
    "inv": [
      "陈霄",
      "张萌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN108009839A",
    "apn": "CN201610973454.6",
    "apd": "2016-10-27",
    "apy": "2016",
    "tit": "提供虚拟资源对象信息的方法及装置",
    "inv": [
      "王东琳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN105373929A",
    "apn": "CN201410443984.0",
    "apd": "2014-09-02",
    "apy": "2014",
    "tit": "提供拍照推荐信息的方法及装置",
    "inv": [
      "朱烈兰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107103218A",
    "apn": "CN201610939700.6",
    "apd": "2016-10-24",
    "apy": "2016",
    "tit": "一种业务实现方法和装置",
    "inv": [
      "李静",
      "贾冬麟",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106886781A",
    "apn": "CN201610940596.2",
    "apd": "2016-10-24",
    "apy": "2016",
    "tit": "物体识别方法及装置",
    "inv": [
      "何凯"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107977834A",
    "apn": "CN201610921190.X",
    "apd": "2016-10-21",
    "apy": "2016",
    "tit": "一种虚拟现实/增强现实空间环境中的数据对象交互方法及装置",
    "inv": [
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107464269A",
    "apn": "CN201610387618.7",
    "apd": "2016-06-02",
    "apy": "2016",
    "tit": "搭配效果图的生成方法、装置及系统",
    "inv": [
      "王潇北",
      "方淘",
      "黄如华"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107957775A",
    "apn": "CN201610909519.0",
    "apd": "2016-10-18",
    "apy": "2016",
    "tit": "虚拟现实空间环境中的数据对象交互方法及装置",
    "inv": [
      "郝力滨",
      "王茂",
      "胡浪宇",
      "郭俊",
      "胡晓航",
      "费义云",
      "李军舰",
      "周莅",
      "苏起扬",
      "代登科"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107957774A",
    "apn": "CN201610909499.7",
    "apd": "2016-10-18",
    "apy": "2016",
    "tit": "虚拟现实空间环境中的交互方法及装置",
    "inv": [
      "郝力滨",
      "王茂",
      "胡浪宇",
      "郭俊",
      "胡晓航",
      "费义云",
      "李军舰",
      "周莅",
      "苏起扬",
      "代登科"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107957772A",
    "apn": "CN201610900654.9",
    "apd": "2016-10-17",
    "apy": "2016",
    "tit": "现实场景中采集VR 图像的处理方法以及实现VR 体验的方法",
    "inv": [
      "苏起杨",
      "费义云"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106997239A",
    "apn": "CN201610895470.8",
    "apd": "2016-10-13",
    "apy": "2016",
    "tit": "基于虚拟现实场景的业务实现方法及装置",
    "inv": [
      "吴军",
      "曾晓东",
      "尹欢密",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107038361A",
    "apn": "CN201610896522.3",
    "apd": "2016-10-13",
    "apy": "2016",
    "tit": "基于虚拟现实场景的业务实现方法及装置",
    "inv": [
      "吴军",
      "曾晓东",
      "尹欢密",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106981000A",
    "apn": "CN201610896495.X",
    "apd": "2016-10-13",
    "apy": "2016",
    "tit": "基于增强现实的多人线下交互、点餐方法及系统",
    "inv": [
      "尹欢密",
      "曾晓东",
      "林锋",
      "吴军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107945270A",
    "apn": "CN201610892300.4",
    "apd": "2016-10-12",
    "apy": "2016",
    "tit": "一种三维数字沙盘系统",
    "inv": [
      "朱哲",
      "陶智"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106980362A",
    "apn": "CN201610881695.8",
    "apd": "2016-10-09",
    "apy": "2016",
    "tit": "基于虚拟现实场景的输入方法及装置",
    "inv": [
      "尹欢密",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "新体验",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107895293A",
    "apn": "CN201610873527.4",
    "apd": "2016-09-30",
    "apy": "2016",
    "tit": "一种图像显示方法、装置及系统",
    "inv": [
      "张天顺",
      "谢继彬",
      "操斌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107886387A",
    "apn": "CN201610872093.6",
    "apd": "2016-09-30",
    "apy": "2016",
    "tit": "采用增强现实技术提供手掌装饰虚拟图像的实现方法及其装置",
    "inv": [
      "谭建国",
      "张洁",
      "闫晓亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "虚拟试物",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107886032A",
    "apn": "CN201610873804.1",
    "apd": "2016-09-30",
    "apy": "2016",
    "tit": "终端设备、智能手机、基于脸部识别的认证方法和系统",
    "inv": [
      "林钧燧"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107885316A",
    "apn": "CN201610866360.9",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "一种基于手势的交互方法及装置",
    "inv": [
      "张磊",
      "杜武平"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107885317A",
    "apn": "CN201610866367.0",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "一种基于手势的交互方法及装置",
    "inv": [
      "张磊",
      "彭俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107885313A",
    "apn": "CN201610863692.1",
    "apd": "2016-09-29",
    "apy": "2016",
    "tit": "一种设备交互方法、装置和设备",
    "inv": [
      "邓柯",
      "张磊",
      "杜武平"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107818596A",
    "apn": "CN201610825765.8",
    "apd": "2016-09-14",
    "apy": "2016",
    "tit": "一种场景参数确定方法、装置及电子设备",
    "inv": [
      "谢宏伟",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107085722A",
    "apn": "CN201610810926.6",
    "apd": "2016-09-08",
    "apy": "2016",
    "tit": "在图像中定位指定信息的方法及装置",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107526489A",
    "apn": "CN201610454746.9",
    "apd": "2016-06-21",
    "apy": "2016",
    "tit": "图片调整方法及装置",
    "inv": [
      "卢俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107784541A",
    "apn": "CN201610743662.7",
    "apd": "2016-08-26",
    "apy": "2016",
    "tit": "提供数据对象信息的方法及装置",
    "inv": [
      "许芯",
      "曲智",
      "陈煌",
      "冯佳峥"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106899567A",
    "apn": "CN201610717080.1",
    "apd": "2016-08-24",
    "apy": "2016",
    "tit": "用户核身方法、装置及系统",
    "inv": [
      "何乐",
      "庹宇鲲",
      "李亮",
      "黄冕",
      "陈继东",
      "杨文波"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103632361B",
    "apn": "CN201210297923.9",
    "apd": "2012-08-20",
    "apy": "2012",
    "tit": "一种图像分割方法和系统",
    "inv": [
      "邓宇",
      "张涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN107767358A",
    "apn": "CN201610709852.7",
    "apd": "2016-08-23",
    "apy": "2016",
    "tit": "一种图像中物体模糊度确定方法和装置",
    "inv": [
      "段炎彪",
      "易东",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN107766864A",
    "apn": "CN201610712507.9",
    "apd": "2016-08-23",
    "apy": "2016",
    "tit": "提取特征的方法和装置、物体识别的方法和装置",
    "inv": [
      "易东",
      "曹恩华",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107766373A",
    "apn": "CN201610694497.0",
    "apd": "2016-08-19",
    "apy": "2016",
    "tit": "图片所属类目的确定方法及其系统",
    "inv": [
      "赵康",
      "李敏",
      "潘攀",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107729910A",
    "apn": "CN201610659370.5",
    "apd": "2016-08-11",
    "apy": "2016",
    "tit": "一种图形识别的方法、装置及系统",
    "inv": [
      "张迎亚",
      "刘巍",
      "潘攀",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN101662454A",
    "apn": "CN200810135527.X",
    "apd": "2008-08-29",
    "apy": "2008",
    "tit": "互联网中图像处理的方法、装置和系统",
    "inv": [
      "张建锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105608459B",
    "apn": "CN201410597626.5",
    "apd": "2014-10-29",
    "apy": "2014",
    "tit": "商品图片的分割方法及其装置",
    "inv": [
      "林海略"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN107705586A",
    "apn": "CN201610644132.7",
    "apd": "2016-08-08",
    "apy": "2016",
    "tit": "道路交叉口的车流控制方法以及装置",
    "inv": [
      "华先胜",
      "黄建强",
      "周昌",
      "邓兵",
      "张旭",
      "金志勇",
      "任沛然",
      "申晨",
      "储文青",
      "刘垚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "路况",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107705240A",
    "apn": "CN201610642756.5",
    "apd": "2016-08-08",
    "apy": "2016",
    "tit": "虚拟试妆方法、装置和电子设备",
    "inv": [
      "潘攀",
      "王彬",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "虚拟服饰/妆容",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN101576932B",
    "apn": "CN200910146726.5",
    "apd": "2009-06-16",
    "apy": "2009",
    "tit": "近重复图片的计算机查找方法和装置",
    "inv": [
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN101599122B",
    "apn": "CN200910149552.8",
    "apd": "2009-07-02",
    "apy": "2009",
    "tit": "一种图像识别方法及装置",
    "inv": [
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107645530A",
    "apn": "CN201610586556.2",
    "apd": "2016-07-22",
    "apy": "2016",
    "tit": "虚拟现实环境下的通信方法及装置",
    "inv": [
      "张哲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "个性化互动",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107643084A",
    "apn": "CN201610581226.4",
    "apd": "2016-07-21",
    "apy": "2016",
    "tit": "提供数据对象信息、实景导航方法及装置",
    "inv": [
      "谢宏伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "AR导航",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN102122389A",
    "apn": "CN201010002240.7",
    "apd": "2010-01-12",
    "apy": "2010",
    "tit": "一种图像相似性判断的方法及装置",
    "inv": [
      "戴能",
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN102279909A",
    "apn": "CN201010195869.8",
    "apd": "2010-06-08",
    "apy": "2010",
    "tit": "一种鉴定图片的归属权的方法及装置",
    "inv": [
      "张旭",
      "魏立晴",
      "汪巍"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "真伪鉴定",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN102385749B",
    "apn": "CN201010273307.0",
    "apd": "2010-09-02",
    "apy": "2010",
    "tit": "一种检测图片的方法和装置",
    "inv": [
      "蔡景助"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN102567952B",
    "apn": "CN201010592877.6",
    "apd": "2010-12-16",
    "apy": "2010",
    "tit": "一种图像分割方法及系统",
    "inv": [
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN105989594B",
    "apn": "CN201510075465.8",
    "apd": "2015-02-12",
    "apy": "2015",
    "tit": "一种图像区域检测方法及装置",
    "inv": [
      "石克阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN102622366B",
    "apn": "CN201110031701.8",
    "apd": "2011-01-28",
    "apy": "2011",
    "tit": "相似图像的识别方法和装置",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN102693429B",
    "apn": "CN201110074426.8",
    "apd": "2011-03-25",
    "apy": "2011",
    "tit": "特征模型选取方法与模拟体验平台设备",
    "inv": [
      "蔡景助"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN102722880B",
    "apn": "CN201110077433.3",
    "apd": "2011-03-29",
    "apy": "2011",
    "tit": "图像主颜色的识别方法、装置及图像匹配方法和服务器",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110020183A",
    "apn": "CN201810003055.6",
    "apd": "2018-01-02",
    "apy": "2018",
    "tit": "产品处理方法、装置及电子设备",
    "inv": [
      "应瑞",
      "潘飞",
      "金玥"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103020947B",
    "apn": "CN201110286895.6",
    "apd": "2011-09-23",
    "apy": "2011",
    "tit": "一种图像的质量分析方法及装置",
    "inv": [
      "陈克",
      "邓宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "社交工具/平台",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN103093180B",
    "apn": "CN201110336168.6",
    "apd": "2011-10-28",
    "apy": "2011",
    "tit": "一种色情图像侦测的方法和系统",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103164713B",
    "apn": "CN201110412537.5",
    "apd": "2011-12-12",
    "apy": "2011",
    "tit": "图像分类方法和装置",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103164433B",
    "apn": "CN201110415259.9",
    "apd": "2011-12-13",
    "apy": "2011",
    "tit": "一种图像搜索方法、装置及服务器",
    "inv": [
      "段曼妮",
      "王从德",
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107480576A",
    "apn": "CN201610399481.7",
    "apd": "2016-06-07",
    "apy": "2016",
    "tit": "人脸识别方法、活体人脸检测方法、装置和设备",
    "inv": [
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN103164436B",
    "apn": "CN201110415494.6",
    "apd": "2011-12-13",
    "apy": "2011",
    "tit": "一种图像搜索方法及装置",
    "inv": [
      "段曼妮",
      "郑琪",
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103164687B",
    "apn": "CN201110421474.X",
    "apd": "2011-12-15",
    "apy": "2011",
    "tit": "一种色情图像侦测的方法和系统",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103179095B",
    "apn": "CN201110436261.4",
    "apd": "2011-12-22",
    "apy": "2011",
    "tit": "一种检测钓鱼网站的方法及客户端装置",
    "inv": [
      "聂万泉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107437012A",
    "apn": "CN201610365535.8",
    "apd": "2016-05-27",
    "apy": "2016",
    "tit": "数据的保护方法及装置",
    "inv": [
      "臧悦",
      "廉洁",
      "黄慧"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "账户隐藏",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107436899A",
    "apn": "CN201610363572.5",
    "apd": "2016-05-26",
    "apy": "2016",
    "tit": "活体识别页面的实现方法和装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN103186538A",
    "apn": "CN201110444864.9",
    "apd": "2011-12-27",
    "apy": "2011",
    "tit": "一种图像分类方法和装置、图像检索方法和装置",
    "inv": [
      "贾宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110069650A",
    "apn": "CN201710936315.0",
    "apd": "2017-10-10",
    "apy": "2017",
    "tit": "一种搜索方法和处理设备",
    "inv": [
      "刘瑞涛",
      "刘宇",
      "徐良鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103207879B",
    "apn": "CN201210015290.8",
    "apd": "2012-01-17",
    "apy": "2012",
    "tit": "图像索引的生成方法及设备",
    "inv": [
      "邓宇",
      "陈克"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107368495A",
    "apn": "CN201610317976.0",
    "apd": "2016-05-12",
    "apy": "2016",
    "tit": "确定用户对互联网对象的情绪的方法及装置",
    "inv": [
      "王往"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107358141A",
    "apn": "CN201610306321.3",
    "apd": "2016-05-10",
    "apy": "2016",
    "tit": "数据识别的方法及装置",
    "inv": [
      "毛锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103324949B",
    "apn": "CN201210077223.9",
    "apd": "2012-03-21",
    "apy": "2012",
    "tit": "从图像中识别物体的方法和装置",
    "inv": [
      "郝凯",
      "陈克"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103377376A",
    "apn": "CN201210110400.9",
    "apd": "2012-04-13",
    "apy": "2012",
    "tit": "图像分类的方法和系统、图像检索的方法和系统",
    "inv": [
      "贾宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107316346A",
    "apn": "CN201610270611.7",
    "apd": "2016-04-27",
    "apy": "2016",
    "tit": "电子票据的取票方法和装置",
    "inv": [
      "李静",
      "贾冬麟",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103514434B",
    "apn": "CN201210227208.8",
    "apd": "2012-06-29",
    "apy": "2012",
    "tit": "一种图像识别方法及装置",
    "inv": [
      "邓宇",
      "吴倩",
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107274703A",
    "apn": "CN201610214140.8",
    "apd": "2016-04-07",
    "apy": "2016",
    "tit": "车辆位置的标定方法、装置及系统",
    "inv": [
      "张晓昱",
      "任万喜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "车外观识别",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN108229337A",
    "apn": "CN201711334828.0",
    "apd": "2017-12-14",
    "apy": "2017",
    "tit": "一种数据处理的方法、装置及设备",
    "inv": [
      "管维刚",
      "邓翔",
      "王康"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "自动缴费",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103577475B",
    "apn": "CN201210276320.0",
    "apd": "2012-08-03",
    "apy": "2012",
    "tit": "一种图片自动化分类方法、图片处理方法及其装置",
    "inv": [
      "贾梦雷",
      "王永攀",
      "郑琪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103578098B",
    "apn": "CN201210279081.4",
    "apd": "2012-08-07",
    "apy": "2012",
    "tit": "在商品图片中提取商品主体的方法和装置",
    "inv": [
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN103577993B",
    "apn": "CN201210278822.7",
    "apd": "2012-08-07",
    "apy": "2012",
    "tit": "颜色选取方法及装置",
    "inv": [
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107229560A",
    "apn": "CN201610170136.6",
    "apd": "2016-03-23",
    "apy": "2016",
    "tit": "一种界面显示效果测试方法、图像样张获取方法及装置",
    "inv": [
      "王晓宇",
      "石存沣"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN101414307A",
    "apn": "CN200810173071.6",
    "apd": "2008-11-26",
    "apy": "2008",
    "tit": "提供图片搜索的方法和服务器",
    "inv": [
      "林宣寅"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "信息嵌入",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN104424230B",
    "apn": "CN201310376347.1",
    "apd": "2013-08-26",
    "apy": "2013",
    "tit": "一种网络商品推荐方法及装置",
    "inv": [
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "搭配推荐",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103810488B",
    "apn": "CN201210448102.0",
    "apd": "2012-11-09",
    "apy": "2012",
    "tit": "图像特征提取方法、图像搜索方法、服务器、终端及系统",
    "inv": [
      "段曼妮",
      "王从德",
      "贾梦雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107205162A",
    "apn": "CN201610150800.0",
    "apd": "2016-03-16",
    "apy": "2016",
    "tit": "视频识别方法及装置",
    "inv": [
      "张荣"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107203463A",
    "apn": "CN201610151530.5",
    "apd": "2016-03-16",
    "apy": "2016",
    "tit": "界面测试的方法、界面绘制方法及装置",
    "inv": [
      "姜立娣",
      "徐庆贺",
      "张翀",
      "曾旭"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103810502B",
    "apn": "CN201210448015.5",
    "apd": "2012-11-09",
    "apy": "2012",
    "tit": "一种图像匹配方法和系统",
    "inv": [
      "张伦",
      "楚汝峰",
      "张春晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104424226B",
    "apn": "CN201310375662.2",
    "apd": "2013-08-26",
    "apy": "2013",
    "tit": "一种获得视觉词词典、图像检索的方法及装置",
    "inv": [
      "张荣"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103903291B",
    "apn": "CN201210567860.4",
    "apd": "2012-12-24",
    "apy": "2012",
    "tit": "一种自动修改图片的方法和装置",
    "inv": [
      "刘广"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN103971357A",
    "apn": "CN201310044988.7",
    "apd": "2013-02-04",
    "apy": "2013",
    "tit": "一种图像中斑点区域提取方法和装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107153358A",
    "apn": "CN201610125396.1",
    "apd": "2016-03-04",
    "apy": "2016",
    "tit": "家用设备的控制方法、装置以及系统",
    "inv": [
      "魏留宏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104714962B",
    "apn": "CN201310687015.5",
    "apd": "2013-12-13",
    "apy": "2013",
    "tit": "一种图像搜索引擎的生成方法及系统",
    "inv": [
      "王从德",
      "孔祥衡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN104112284B",
    "apn": "CN201310140673.2",
    "apd": "2013-04-22",
    "apy": "2013",
    "tit": "一种图片的相似度检测方法和设备",
    "inv": [
      "张增明",
      "梁宁清",
      "姜飞俊",
      "陈德品"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN104123528B",
    "apn": "CN201310146353.8",
    "apd": "2013-04-24",
    "apy": "2013",
    "tit": "一种识别图片的方法及装置",
    "inv": [
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107123009A",
    "apn": "CN201610102412.5",
    "apd": "2016-02-24",
    "apy": "2016",
    "tit": "一种信息上传系统",
    "inv": [
      "刘万钧"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104778170A",
    "apn": "CN201410010720.6",
    "apd": "2014-01-09",
    "apy": "2014",
    "tit": "搜索展示商品图像的方法和装置",
    "inv": [
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN104240228B",
    "apn": "CN201310254232.5",
    "apd": "2013-06-24",
    "apy": "2013",
    "tit": "一种应用于网站的特定图片的检测方法及装置",
    "inv": [
      "梁宁清",
      "姜飞俊",
      "张增明",
      "陈德品"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107092818A",
    "apn": "CN201610089871.4",
    "apd": "2016-02-17",
    "apy": "2016",
    "tit": "活体识别的实现方法和装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN106326288A",
    "apn": "CN201510375311.0",
    "apd": "2015-06-30",
    "apy": "2015",
    "tit": "图像搜索方法及装置",
    "inv": [
      "童志军",
      "陈宇",
      "安山",
      "孙佰贵",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN104346370B",
    "apn": "CN201310328673.5",
    "apd": "2013-07-31",
    "apy": "2013",
    "tit": "图像搜索、获取图像文本信息的方法及装置",
    "inv": [
      "刘瑞涛",
      "张洪明",
      "茹新峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107025397A",
    "apn": "CN201610066074.4",
    "apd": "2016-01-29",
    "apy": "2016",
    "tit": "身份信息的获取方法和装置",
    "inv": [
      "刘坤",
      "饶利他",
      "姜巍"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN103793717A",
    "apn": "CN201210433786.7",
    "apd": "2012-11-02",
    "apy": "2012",
    "tit": "判断图像主体显著性及训练其分类器的方法和系统",
    "inv": [
      "邓宇",
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104424475B",
    "apn": "CN201310409431.9",
    "apd": "2013-09-10",
    "apy": "2013",
    "tit": "图像中的条纹区域的识别方法及装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104574454A",
    "apn": "CN201310519643.2",
    "apd": "2013-10-29",
    "apy": "2013",
    "tit": "图像的处理方法及装置",
    "inv": [
      "叶浩",
      "叶克良"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106997234A",
    "apn": "CN201610045739.3",
    "apd": "2016-01-22",
    "apy": "2016",
    "tit": "虚拟现实模式处理方法、设备和系统",
    "inv": [
      "袁志俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN104835134B",
    "apn": "CN201410047760.8",
    "apd": "2014-02-11",
    "apy": "2014",
    "tit": "一种计算商品图像牛皮癣分值的方法和装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104837007B",
    "apn": "CN201410048608.1",
    "apd": "2014-02-11",
    "apy": "2014",
    "tit": "一种数字图像质量分级的方法和装置",
    "inv": [
      "郑琪",
      "王永攀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN104980278B",
    "apn": "CN201410148019.0",
    "apd": "2014-04-14",
    "apy": "2014",
    "tit": "验证生物特征图像的可用性的方法和装置",
    "inv": [
      "曹恺"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108074271A",
    "apn": "CN201711259637.2",
    "apd": "2017-12-04",
    "apy": "2017",
    "tit": "电子信息图片展示方法、装置及计算机存储介质",
    "inv": [
      "陈双",
      "张亚峰",
      "张宇翔",
      "陈一卓"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "信息嵌入",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109840818A",
    "apn": "CN201711205142.1",
    "apd": "2017-11-27",
    "apy": "2017",
    "tit": "一种商品信息发布与浏览方法、相关设备及系统",
    "inv": [
      "栾天舒"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN104978723B",
    "apn": "CN201410147879.2",
    "apd": "2014-04-14",
    "apy": "2014",
    "tit": "一种图片处理方法和装置",
    "inv": [
      "茅晓锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "浏览展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105335111B",
    "apn": "CN201410226041.2",
    "apd": "2014-05-26",
    "apy": "2014",
    "tit": "一种图像的展示处理方法和装置",
    "inv": [
      "王鹤翔",
      "卞文瀚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106875203A",
    "apn": "CN201510922602.7",
    "apd": "2015-12-14",
    "apy": "2015",
    "tit": "一种确定商品图片的款式信息的方法及装置",
    "inv": [
      "冯子明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "自动录入",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105205054A",
    "apn": "CN201410244693.9",
    "apd": "2014-06-04",
    "apy": "2014",
    "tit": "用于展示图片的方法和获取图片色相特征值的方法及装置",
    "inv": [
      "王立"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105447842B",
    "apn": "CN201410331209.6",
    "apd": "2014-07-11",
    "apy": "2014",
    "tit": "一种图像匹配的方法及装置",
    "inv": [
      "王立"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106813568B",
    "apn": "CN201510847486.7",
    "apd": "2015-11-27",
    "apy": "2015",
    "tit": "物体测量方法及装置",
    "inv": [
      "何勇",
      "崔晶"
    ],
    "app": [
      "菜鸟智能物流控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106779791A",
    "apn": "CN201510831134.2",
    "apd": "2015-11-25",
    "apy": "2015",
    "tit": "一种搭配对象图片组合的生成方法及装置",
    "inv": [
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "搭配推荐",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106709932A",
    "apn": "CN201510772348.7",
    "apd": "2015-11-12",
    "apy": "2015",
    "tit": "一种人脸位置跟踪方法、装置和电子设备",
    "inv": [
      "王楠",
      "杜志军",
      "张宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106683037A",
    "apn": "CN201510750068.6",
    "apd": "2015-11-06",
    "apy": "2015",
    "tit": "三维可视化移动轨迹数据的方法和设备",
    "inv": [
      "马瑞娴",
      "周宁奕",
      "闻啸",
      "章也"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107272983A",
    "apn": "CN201610211628.5",
    "apd": "2016-04-06",
    "apy": "2016",
    "tit": "一种物品图像的展示方法、装置及系统",
    "inv": [
      "卢颖",
      "张志鹏",
      "柯敦灵",
      "袁力",
      "马召"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106611431A",
    "apn": "CN201510695116.6",
    "apd": "2015-10-22",
    "apy": "2015",
    "tit": "图片检测方法和装置",
    "inv": [
      "安山"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN108345624A",
    "apn": "CN201710062757.7",
    "apd": "2017-01-24",
    "apy": "2017",
    "tit": "生成页面的方法及装置",
    "inv": [
      "刘恒",
      "鲍军",
      "徐晗曦",
      "吴春松",
      "崔苗苗",
      "李戈"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106600903A",
    "apn": "CN201510684026.7",
    "apd": "2015-10-20",
    "apy": "2015",
    "tit": "一种基于图像识别的预警方法及装置",
    "inv": [
      "魏强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106598980A",
    "apn": "CN201510672813.X",
    "apd": "2015-10-16",
    "apy": "2015",
    "tit": "信息的推送方法及装置",
    "inv": [
      "童骏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106599762A",
    "apn": "CN201510671365.1",
    "apd": "2015-10-15",
    "apy": "2015",
    "tit": "动作信息识别方法和系统",
    "inv": [
      "王鑫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105551012B",
    "apn": "CN201410613520.X",
    "apd": "2014-11-04",
    "apy": "2014",
    "tit": "计算机图像配准中降低错误匹配对的方法及其系统",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106570703A",
    "apn": "CN201510649209.5",
    "apd": "2015-10-09",
    "apy": "2015",
    "tit": "商品对象鉴定信息处理方法及装置",
    "inv": [
      "张怡远"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "真伪鉴定",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106570408A",
    "apn": "CN201510645122.0",
    "apd": "2015-10-08",
    "apy": "2015",
    "tit": "敏感信息的展现方法和装置",
    "inv": [
      "王晓宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "账户隐藏",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106570714A",
    "apn": "CN201510646100.6",
    "apd": "2015-10-08",
    "apy": "2015",
    "tit": "一种搭配对象图片的推荐方法、映射关系建立方法及装置",
    "inv": [
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "搭配推荐",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106548121A",
    "apn": "CN201510614229.9",
    "apd": "2015-09-23",
    "apy": "2015",
    "tit": "一种活体识别的测试方法及装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105740876B",
    "apn": "CN201410758179.7",
    "apd": "2014-12-10",
    "apy": "2014",
    "tit": "一种图像预处理方法及装置",
    "inv": [
      "丁威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106529379A",
    "apn": "CN201510587678.9",
    "apd": "2015-09-15",
    "apy": "2015",
    "tit": "一种活体识别方法及设备",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105786417B",
    "apn": "CN201410805305.X",
    "apd": "2014-12-19",
    "apy": "2014",
    "tit": "一种静态图片的动态显示方法、装置及设备",
    "inv": [
      "蔡华志"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105787419A",
    "apn": "CN201410816108.8",
    "apd": "2014-12-23",
    "apy": "2014",
    "tit": "手掌图像的手掌检测方法及系统",
    "inv": [
      "谢永祥",
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106503610A",
    "apn": "CN201510568027.5",
    "apd": "2015-09-08",
    "apy": "2015",
    "tit": "视频识别方法和装置",
    "inv": [
      "毛锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105787424B",
    "apn": "CN201410832007.X",
    "apd": "2014-12-26",
    "apy": "2014",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "方涛",
      "谢永祥"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106469460B",
    "apn": "CN201510502346.6",
    "apd": "2015-08-14",
    "apy": "2015",
    "tit": "马赛克图像生成的方法和装置及电子设备",
    "inv": [
      "高洋",
      "安山",
      "缪亚希",
      "冯颖慧",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105809181B",
    "apn": "CN201410855921.6",
    "apd": "2014-12-31",
    "apy": "2014",
    "tit": "用于Logo检测的方法和设备",
    "inv": [
      "王慧琼"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106447949A",
    "apn": "CN201510494594.0",
    "apd": "2015-08-12",
    "apy": "2015",
    "tit": "一种信息处理方法及装置",
    "inv": [
      "潘勇",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105825161B",
    "apn": "CN201510008076.3",
    "apd": "2015-01-07",
    "apy": "2015",
    "tit": "图像的肤色检测方法及其系统",
    "inv": [
      "谢永祥",
      "方涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN105825243A",
    "apn": "CN201510007186.8",
    "apd": "2015-01-07",
    "apy": "2015",
    "tit": "证件图像检测方法及设备",
    "inv": [
      "陈岳峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "证件检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105844283B",
    "apn": "CN201510023173.X",
    "apd": "2015-01-16",
    "apy": "2015",
    "tit": "用于识别图像类目归属的方法、图像搜索方法及装置",
    "inv": [
      "唐铭谦",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106445939A",
    "apn": "CN201510475003.5",
    "apd": "2015-08-06",
    "apy": "2015",
    "tit": "图像检索、获取图像信息及图像识别方法、装置及系统",
    "inv": [
      "杨川",
      "张伦",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106412383A",
    "apn": "CN201510462844.2",
    "apd": "2015-07-31",
    "apy": "2015",
    "tit": "视频图像的处理方法和装置",
    "inv": [
      "胡飞阳",
      "徐月钢",
      "黄敦笔"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105844284B",
    "apn": "CN201510024255.6",
    "apd": "2015-01-16",
    "apy": "2015",
    "tit": "人脸特征点定位方法和装置",
    "inv": [
      "王楠",
      "杜志军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN105868678B",
    "apn": "CN201510026163.1",
    "apd": "2015-01-19",
    "apy": "2015",
    "tit": "人脸识别模型的训练方法及装置",
    "inv": [
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105868767A",
    "apn": "CN201510025719.5",
    "apd": "2015-01-19",
    "apy": "2015",
    "tit": "人脸特征点定位方法和装置",
    "inv": [
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106649296A",
    "apn": "CN201510428990.3",
    "apd": "2015-07-20",
    "apy": "2015",
    "tit": "提供拍照提示信息、业务对象搜索方法及装置",
    "inv": [
      "许亚卓",
      "陈文锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "引导拍照",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN105868768A",
    "apn": "CN201510028053.9",
    "apd": "2015-01-20",
    "apy": "2015",
    "tit": "一种识别图片是否带有特定标记的方法及系统",
    "inv": [
      "李鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105869235B",
    "apn": "CN201510028044.X",
    "apd": "2015-01-20",
    "apy": "2015",
    "tit": "一种安全门禁方法及系统",
    "inv": [
      "肖存"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105868769A",
    "apn": "CN201510036577.2",
    "apd": "2015-01-23",
    "apy": "2015",
    "tit": "图像中的人脸关键点定位方法及装置",
    "inv": [
      "童志军",
      "刘彬",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106358092B",
    "apn": "CN201510407944.5",
    "apd": "2015-07-13",
    "apy": "2015",
    "tit": "信息处理方法及装置",
    "inv": [
      "党茂昌"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "视频处理",
    "l2": "视频显示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106340020A",
    "apn": "CN201510404423.4",
    "apd": "2015-07-10",
    "apy": "2015",
    "tit": "超像素分割方法和装置",
    "inv": [
      "安山",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106339658A",
    "apn": "CN201510401150.8",
    "apd": "2015-07-09",
    "apy": "2015",
    "tit": "数据的处理方法和装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "点评",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105989367B",
    "apn": "CN201510059261.5",
    "apd": "2015-02-04",
    "apy": "2015",
    "tit": "目标获取方法及设备",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN105989043A",
    "apn": "CN201510059267.2",
    "apd": "2015-02-04",
    "apy": "2015",
    "tit": "自动获取商品图像中商标和检索商标的方法及其装置",
    "inv": [
      "薛晖"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106294527A",
    "apn": "CN201510363624.4",
    "apd": "2015-06-26",
    "apy": "2015",
    "tit": "一种信息推荐方法和设备",
    "inv": [
      "屈琳雅"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106295290A",
    "apn": "CN201510369312.4",
    "apd": "2015-06-26",
    "apy": "2015",
    "tit": "基于指纹信息生成认证信息的方法、装置及系统",
    "inv": [
      "皮维"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106295673A",
    "apn": "CN201510359225.0",
    "apd": "2015-06-25",
    "apy": "2015",
    "tit": "物品信息处理方法及处理装置",
    "inv": [
      "蒋宇东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106303361A",
    "apn": "CN201510319896.4",
    "apd": "2015-06-11",
    "apy": "2015",
    "tit": "视频通话中的图像处理方法、装置、系统及图形处理器",
    "inv": [
      "於聪",
      "陶媛",
      "严飞军",
      "孙健康",
      "裘钰钢"
    ],
    "app": [
      "钉钉控股(开曼)有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106295287B",
    "apn": "CN201510315782.2",
    "apd": "2015-06-10",
    "apy": "2015",
    "tit": "活体检测方法和装置以及身份认证方法和装置",
    "inv": [
      "陈继东",
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106295288B",
    "apn": "CN201510316625.3",
    "apd": "2015-06-10",
    "apy": "2015",
    "tit": "一种信息校验方法及装置",
    "inv": [
      "周建波"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106033621B",
    "apn": "CN201510117117.2",
    "apd": "2015-03-17",
    "apy": "2015",
    "tit": "一种三维建模的方法及装置",
    "inv": [
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106156692A",
    "apn": "CN201510134068.3",
    "apd": "2015-03-25",
    "apy": "2015",
    "tit": "一种用于人脸边缘特征点定位的方法及装置",
    "inv": [
      "邹建法",
      "黄英",
      "陈岩"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN110166792A",
    "apn": "CN201810151931.X",
    "apd": "2018-02-14",
    "apy": "2018",
    "tit": "图像显示方法、装置、系统和存储介质",
    "inv": [
      "郑子杰",
      "孙宜进"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106161346B",
    "apn": "CN201510145112.0",
    "apd": "2015-03-30",
    "apy": "2015",
    "tit": "图片合成方法及装置",
    "inv": [
      "杜志军",
      "黎三平",
      "王楠",
      "何强",
      "赵严军",
      "劳振强",
      "石杰",
      "王卫星"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN106157341B",
    "apn": "CN201510144685.1",
    "apd": "2015-03-30",
    "apy": "2015",
    "tit": "生成合成图片的方法及装置",
    "inv": [
      "张宇",
      "杜志军",
      "王楠",
      "丁威",
      "何强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN106202089A",
    "apn": "CN201510221796.8",
    "apd": "2015-05-04",
    "apy": "2015",
    "tit": "一种确定图片质量和网页展示的方法及设备",
    "inv": [
      "费红辉"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN106157273A",
    "apn": "CN201510146155.0",
    "apd": "2015-03-30",
    "apy": "2015",
    "tit": "生成合成图片的方法及装置",
    "inv": [
      "丁威",
      "杜志军",
      "王楠",
      "张宇",
      "何强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN106155301A",
    "apn": "CN201510205185.4",
    "apd": "2015-04-27",
    "apy": "2015",
    "tit": "一种家庭物联网控制方法、装置及系统",
    "inv": [
      "姜维"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106156118B",
    "apn": "CN201510161730.4",
    "apd": "2015-04-07",
    "apy": "2015",
    "tit": "基于计算机系统的图片相似度计算方法及其系统",
    "inv": [
      "薛晖",
      "猛安",
      "夏雷"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106157239B",
    "apn": "CN201510194908.5",
    "apd": "2015-04-22",
    "apy": "2015",
    "tit": "一种图片处理方法及相关设备与系统",
    "inv": [
      "李穆",
      "代卓贤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106157291B",
    "apn": "CN201510192509.5",
    "apd": "2015-04-22",
    "apy": "2015",
    "tit": "识别重复纹理的方法和装置",
    "inv": [
      "高飞宇",
      "郑琪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106157235A",
    "apn": "CN201510159422.8",
    "apd": "2015-04-03",
    "apy": "2015",
    "tit": "图片合成方法、装置及即时通信方法、图片合成服务器",
    "inv": [
      "冯普超"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106157281A",
    "apn": "CN201510147814.2",
    "apd": "2015-03-31",
    "apy": "2015",
    "tit": "一种图像主体识别方法及装置",
    "inv": [
      "石克阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106157280B",
    "apn": "CN201510147805.3",
    "apd": "2015-03-31",
    "apy": "2015",
    "tit": "一种图像数据处理方法及装置",
    "inv": [
      "石克阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品图处理",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106202086A",
    "apn": "CN201510221512.5",
    "apd": "2015-05-04",
    "apy": "2015",
    "tit": "一种图片处理、获取方法、装置及系统",
    "inv": [
      "刘奎龙",
      "李波",
      "黄凯明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106204690A",
    "apn": "CN201510222313.6",
    "apd": "2015-05-04",
    "apy": "2015",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "刘奎龙",
      "李波",
      "黄凯明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106203242A",
    "apn": "CN201510229654.6",
    "apd": "2015-05-07",
    "apy": "2015",
    "tit": "一种相似图像识别方法及设备",
    "inv": [
      "陈岳峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106157045A",
    "apn": "CN201510138246.X",
    "apd": "2015-03-26",
    "apy": "2015",
    "tit": "基于物流数据识别虚假交易的方法、装置及服务器",
    "inv": [
      "李岱骏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "防刷单",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106031631A",
    "apn": "CN201510114501.7",
    "apd": "2015-03-16",
    "apy": "2015",
    "tit": "一种心率检测方法、装置及系统",
    "inv": [
      "陈佳秋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "医疗",
    "l2": "健康指示",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106296578A",
    "apn": "CN201510291061.2",
    "apd": "2015-05-29",
    "apy": "2015",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "丁威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106033547A",
    "apn": "CN201510109142.6",
    "apd": "2015-03-12",
    "apy": "2015",
    "tit": "颜色搭配评价方法、服饰搭配推荐方法及装置",
    "inv": [
      "冯子明",
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "搭配推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106257495A",
    "apn": "CN201510346080.0",
    "apd": "2015-06-19",
    "apy": "2015",
    "tit": "一种数字识别方法及装置",
    "inv": [
      "丁威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108170498A",
    "apn": "CN201611106240.5",
    "apd": "2016-12-05",
    "apy": "2016",
    "tit": "页面内容展示方法及装置",
    "inv": [
      "吴晓东",
      "刘佳佳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106326824B",
    "apn": "CN201510394966.2",
    "apd": "2015-07-02",
    "apy": "2015",
    "tit": "一种自动筛选图片的方法及装置",
    "inv": [
      "李川石"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN105989330A",
    "apn": "CN201510055621.4",
    "apd": "2015-02-03",
    "apy": "2015",
    "tit": "一种图片检测方法及设备",
    "inv": [
      "陈岳峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106355188A",
    "apn": "CN201510408021.1",
    "apd": "2015-07-13",
    "apy": "2015",
    "tit": "图像检测方法及装置",
    "inv": [
      "祝健飞",
      "刘彬",
      "刘扬",
      "陈宇",
      "高洋",
      "安山",
      "赵小伟",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN105893920A",
    "apn": "CN201510038591.6",
    "apd": "2015-01-26",
    "apy": "2015",
    "tit": "一种人脸活体检测方法和装置",
    "inv": [
      "李鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106708823A",
    "apn": "CN201510429078.X",
    "apd": "2015-07-20",
    "apy": "2015",
    "tit": "一种搜索处理方法、装置及系统",
    "inv": [
      "安山",
      "陈宇",
      "何源",
      "孙佰贵",
      "童志军",
      "宋宏亮",
      "黄馨",
      "杨全",
      "李鹏飞",
      "刘跃辉",
      "曾煜",
      "葛铁铮",
      "彭爽"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输入",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN105868677A",
    "apn": "CN201510025899.7",
    "apd": "2015-01-19",
    "apy": "2015",
    "tit": "一种活体人脸检测方法及装置",
    "inv": [
      "李亮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106408037A",
    "apn": "CN201510457979.X",
    "apd": "2015-07-30",
    "apy": "2015",
    "tit": "图像识别方法及装置",
    "inv": [
      "童志军",
      "刘彬",
      "张洪明"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105843816A",
    "apn": "CN201510020689.9",
    "apd": "2015-01-15",
    "apy": "2015",
    "tit": "一种用于确定图片陈列信息的方法及设备",
    "inv": [
      "石克阳",
      "曹阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110276250A",
    "apn": "CN201910392792.4",
    "apd": "2015-01-12",
    "apy": "2015",
    "tit": "一种人脸活体检测方法和装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105844203B",
    "apn": "CN201510015237.1",
    "apd": "2015-01-12",
    "apy": "2015",
    "tit": "一种人脸活体检测方法和装置",
    "inv": [
      "曾岳伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106446754A",
    "apn": "CN201510490041.8",
    "apd": "2015-08-11",
    "apy": "2015",
    "tit": "图像识别方法、度量学习方法、图像来源识别方法及装置",
    "inv": [
      "易东",
      "刘荣",
      "张帆",
      "张伦",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105825084B",
    "apn": "CN201510005669.4",
    "apd": "2015-01-06",
    "apy": "2015",
    "tit": "用于对具有图像的对象进行匹配检测的方法",
    "inv": [
      "侯冬梅",
      "余文涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106445977A",
    "apn": "CN201510494397.9",
    "apd": "2015-08-12",
    "apy": "2015",
    "tit": "图片推送方法及装置",
    "inv": [
      "何勇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105790948B",
    "apn": "CN201410837631.9",
    "apd": "2014-12-26",
    "apy": "2014",
    "tit": "一种身份认证方法及装置",
    "inv": [
      "吕鲲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106485186A",
    "apn": "CN201510531886.7",
    "apd": "2015-08-26",
    "apy": "2015",
    "tit": "图像特征提取方法、装置、终端设备及系统",
    "inv": [
      "刘荣",
      "易东",
      "张帆",
      "张伦",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106530194A",
    "apn": "CN201510570605.9",
    "apd": "2015-09-09",
    "apy": "2015",
    "tit": "一种疑似侵权产品图片的检测方法及装置",
    "inv": [
      "张增明",
      "陈德品"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "真伪鉴定",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104469179B",
    "apn": "CN201410803625.1",
    "apd": "2014-12-22",
    "apy": "2014",
    "tit": "一种将动态图片结合到手机视频中的方法",
    "inv": [
      "王强宇"
    ],
    "app": [
      "阿里云计算有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106558060A",
    "apn": "CN201510617104.1",
    "apd": "2015-09-24",
    "apy": "2015",
    "tit": "图片处理方法和装置",
    "inv": [
      "安山",
      "刘扬",
      "祝健飞",
      "郑乃行"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106558043B",
    "apn": "CN201510633094.0",
    "apd": "2015-09-29",
    "apy": "2015",
    "tit": "一种确定融合系数的方法和装置",
    "inv": [
      "陈岩",
      "黄英",
      "邹建法"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106558042A",
    "apn": "CN201510631053.8",
    "apd": "2015-09-29",
    "apy": "2015",
    "tit": "一种对图像进行关键点定位的方法和装置",
    "inv": [
      "陈岩",
      "黄英",
      "邹建法"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "拍照/美化",
    "l2": "美化/美颜",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106611401A",
    "apn": "CN201510690513.4",
    "apd": "2015-10-22",
    "apy": "2015",
    "tit": "一种在纹理内存中存储图像的方法及装置",
    "inv": [
      "邵振江",
      "陈村"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN105575037B",
    "apn": "CN201410545664.6",
    "apd": "2014-10-15",
    "apy": "2014",
    "tit": "车内环境的监控方法及系统",
    "inv": [
      "屈琳雅"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "车辆/交通",
    "l2": "行为识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105577378A",
    "apn": "CN201410542476.8",
    "apd": "2014-10-14",
    "apy": "2014",
    "tit": "身份认证方法、装置和系统",
    "inv": [
      "莫鋆"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106651340A",
    "apn": "CN201510735998.4",
    "apd": "2015-11-02",
    "apy": "2015",
    "tit": "结算方法及装置",
    "inv": [
      "陈永平"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "金融",
    "l2": "支付",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105516068B",
    "apn": "CN201410505646.5",
    "apd": "2014-09-26",
    "apy": "2014",
    "tit": "信息交互以及人体生物特征数据与账号的关联方法、装置",
    "inv": [
      "王磊",
      "孟超峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106709400A",
    "apn": "CN201510772383.9",
    "apd": "2015-11-12",
    "apy": "2015",
    "tit": "一种感官张闭状态的识别方法、装置及客户端",
    "inv": [
      "王楠",
      "杜志军",
      "张宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN106815223A",
    "apn": "CN201510849675.8",
    "apd": "2015-11-27",
    "apy": "2015",
    "tit": "一种海量图片管理方法和装置",
    "inv": [
      "张增明",
      "陈智强",
      "陈德品"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "存储",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN105468950A",
    "apn": "CN201410446657.0",
    "apd": "2014-09-03",
    "apy": "2014",
    "tit": "身份认证方法、装置、终端及服务器",
    "inv": [
      "杜志军"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106815588A",
    "apn": "CN201510867224.7",
    "apd": "2015-12-01",
    "apy": "2015",
    "tit": "垃圾图片过滤方法及装置",
    "inv": [
      "陈刚"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105447708A",
    "apn": "CN201410433045.8",
    "apd": "2014-08-28",
    "apy": "2014",
    "tit": "一种信息提供方法及装置",
    "inv": [
      "杜志军",
      "张宇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106846419A",
    "apn": "CN201510883297.5",
    "apd": "2015-12-03",
    "apy": "2015",
    "tit": "一种图像中人像轮廓的确定方法和装置",
    "inv": [
      "李川石"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN105450591A",
    "apn": "CN201410376888.9",
    "apd": "2014-08-01",
    "apy": "2014",
    "tit": "一种验证信息的方法、系统、客户端及服务器",
    "inv": [
      "曹恺",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105450407A",
    "apn": "CN201410374930.3",
    "apd": "2014-07-31",
    "apy": "2014",
    "tit": "身份认证方法和装置",
    "inv": [
      "曹恺",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN108765115A",
    "apn": "CN201810476450.6",
    "apd": "2018-05-17",
    "apy": "2018",
    "tit": "一种退税方法、系统、装置、移动终端及业务服务器",
    "inv": [
      "朴永彬"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "新零售",
    "l2": "海关",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110046085A",
    "apn": "CN201811468430.0",
    "apd": "2018-12-03",
    "apy": "2018",
    "tit": "识别终端设备上显示的应用程序控件的方法及装置",
    "inv": [
      "黄伟东",
      "李佳楠",
      "孙震",
      "陈忻",
      "张新琛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "应用程序",
    "l2": "软件测试",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN109992680A",
    "apn": "CN201811525691.1",
    "apd": "2018-12-13",
    "apy": "2018",
    "tit": "信息处理方法、装置、电子设备及计算机可读存储介质",
    "inv": [
      "黄冕",
      "宋宜涛"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105407069B",
    "apn": "CN201410309308.4",
    "apd": "2014-06-30",
    "apy": "2014",
    "tit": "活体认证方法、装置、客户端设备及服务器",
    "inv": [
      "曹恺",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105227310A",
    "apn": "CN201410289054.4",
    "apd": "2014-06-24",
    "apy": "2014",
    "tit": "一种身份认证方法和设备",
    "inv": [
      "李麟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "防伪造",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106874296A",
    "apn": "CN201510922684.5",
    "apd": "2015-12-14",
    "apy": "2015",
    "tit": "一种商品的风格识别方法及装置",
    "inv": [
      "石克阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN203870618U",
    "apn": "CN201420297178.2",
    "apd": "2014-06-05",
    "apy": "2014",
    "tit": "一种生物特征信息的处理装置",
    "inv": [
      "李晓玲",
      "崔鹏",
      "吴伟军",
      "李华"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106874923A",
    "apn": "CN201510922584.2",
    "apd": "2015-12-14",
    "apy": "2015",
    "tit": "一种商品的风格分类确定方法及装置",
    "inv": [
      "冯子明",
      "石克阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106874924A",
    "apn": "CN201510922662.9",
    "apd": "2015-12-14",
    "apy": "2015",
    "tit": "一种图片风格识别方法及装置",
    "inv": [
      "石克阳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106897970A",
    "apn": "CN201510962417.0",
    "apd": "2015-12-21",
    "apy": "2015",
    "tit": "一种图像修复方法及装置",
    "inv": [
      "宋雪梅",
      "杜志军",
      "王楠",
      "何强"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN106919821A",
    "apn": "CN201510993734.9",
    "apd": "2015-12-25",
    "apy": "2015",
    "tit": "用户验证方法和装置",
    "inv": [
      "储刚",
      "苏鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106919326A",
    "apn": "CN201510997392.8",
    "apd": "2015-12-25",
    "apy": "2015",
    "tit": "一种图片搜索方法及装置",
    "inv": [
      "康琳",
      "马士龙"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106921621A",
    "apn": "CN201510993733.4",
    "apd": "2015-12-25",
    "apy": "2015",
    "tit": "用户验证方法和装置",
    "inv": [
      "储刚",
      "苏鹏"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106937117A",
    "apn": "CN201511017681.3",
    "apd": "2015-12-29",
    "apy": "2015",
    "tit": "图像压缩方法和装置",
    "inv": [
      "丁威"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110119608A",
    "apn": "CN201910393043.3",
    "apd": "2014-03-27",
    "apy": "2014",
    "tit": "一种生物特征信息处理方法、生物特征信息保存方法及装置",
    "inv": [
      "曹恺"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN104951680B",
    "apn": "CN201410119810.9",
    "apd": "2014-03-27",
    "apy": "2014",
    "tit": "一种生物特征信息处理方法、保存方法及装置",
    "inv": [
      "曹恺"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN109327444A",
    "apn": "CN201811205033.4",
    "apd": "2014-03-27",
    "apy": "2014",
    "tit": "一种账户信息的注册和认证方法及装置",
    "inv": [
      "曹恺"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN104954127B",
    "apn": "CN201410119349.7",
    "apd": "2014-03-27",
    "apy": "2014",
    "tit": "一种授权方法、生物特征信息发送方法及装置",
    "inv": [
      "曹恺"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "隐私保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN104866804B",
    "apn": "CN201410058760.8",
    "apd": "2014-02-20",
    "apy": "2014",
    "tit": "一种掌纹信息识别的方法和设备",
    "inv": [
      "曹恺",
      "张洁"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104754377A",
    "apn": "CN201310741071.2",
    "apd": "2013-12-27",
    "apy": "2013",
    "tit": "智能电视的数据处理方法、智能电视以及智能电视系统",
    "inv": [
      "杜武平",
      "曹坤勇"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "智能家居",
    "l2": "人机交互",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN105824871B",
    "apn": "CN201610034656.4",
    "apd": "2016-01-19",
    "apy": "2016",
    "tit": "一种图片检测方法与设备",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106991364A",
    "apn": "CN201610040749.8",
    "apd": "2016-01-21",
    "apy": "2016",
    "tit": "人脸识别处理方法、装置以及移动终端",
    "inv": [
      "易东",
      "刘荣",
      "张帆",
      "张伦",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN104639517B",
    "apn": "CN201310571710.5",
    "apd": "2013-11-15",
    "apy": "2013",
    "tit": "利用人体生物特征进行身份验证的方法和装置",
    "inv": [
      "金伟安",
      "黄冕",
      "李晓玲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "智能进入/鉴权",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106997580A",
    "apn": "CN201610049672.0",
    "apd": "2016-01-25",
    "apy": "2016",
    "tit": "图片处理方法和装置",
    "inv": [
      "林桐"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN107015998A",
    "apn": "CN201610059928.6",
    "apd": "2016-01-28",
    "apy": "2016",
    "tit": "一种图片处理方法、装置和智能终端",
    "inv": [
      "张俊文",
      "康琳",
      "程艳"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107085575A",
    "apn": "CN201610086025.7",
    "apd": "2016-02-15",
    "apy": "2016",
    "tit": "一种存储图片的方法和设备",
    "inv": [
      "朱一梦",
      "李文君"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "存储",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN104142946A",
    "apn": "CN201310167405.X",
    "apd": "2013-05-08",
    "apy": "2013",
    "tit": "一种同款业务对象聚合、搜索的方法和系统",
    "inv": [
      "邓宇",
      "欧海峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107133230A",
    "apn": "CN201610110027.5",
    "apd": "2016-02-26",
    "apy": "2016",
    "tit": "一种图片处理方法及装置",
    "inv": [
      "朱一梦",
      "徐亮",
      "金甸"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "相册管理",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107180405A",
    "apn": "CN201610136920.5",
    "apd": "2016-03-10",
    "apy": "2016",
    "tit": "一种图片处理方法、装置和智能终端",
    "inv": [
      "张翀"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "图像管理",
    "l2": "存储",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107180343A",
    "apn": "CN201610141656.4",
    "apd": "2016-03-11",
    "apy": "2016",
    "tit": "一种自动缴费的方法和装置",
    "inv": [
      "王旭"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "工作/生活",
    "l2": "自动缴费",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107203775A",
    "apn": "CN201610158639.1",
    "apd": "2016-03-18",
    "apy": "2016",
    "tit": "一种图像分类的方法、装置和设备",
    "inv": [
      "张帆",
      "张伦",
      "刘曦",
      "刘荣",
      "楚汝峰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像识别",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107229892A",
    "apn": "CN201610172281.8",
    "apd": "2016-03-24",
    "apy": "2016",
    "tit": "一种基于人脸识别产品的识别调整方法和设备",
    "inv": [
      "陈静",
      "吴聪"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "人脸/人体算法",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN110033413A",
    "apn": "CN201811640209.9",
    "apd": "2018-12-29",
    "apy": "2018",
    "tit": "客户端的图像处理方法、装置、电子设备、计算机可读介质",
    "inv": [
      "尤洋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107239473A",
    "apn": "CN201610189034.9",
    "apd": "2016-03-29",
    "apy": "2016",
    "tit": "一种展示图片选择方法及装置",
    "inv": [
      "周政"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "个性化推荐",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107292829A",
    "apn": "CN201610201082.5",
    "apd": "2016-03-31",
    "apy": "2016",
    "tit": "图像处理方法及装置",
    "inv": [
      "谭建国"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107292808A",
    "apn": "CN201610202145.9",
    "apd": "2016-03-31",
    "apy": "2016",
    "tit": "图像处理方法、装置及图像协处理器",
    "inv": [
      "牛功彪",
      "李舒"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN106056529A",
    "apn": "CN201610203216.7",
    "apd": "2016-04-01",
    "apy": "2016",
    "tit": "一种对用于图片识别的卷积神经网络训练的方法与设备",
    "inv": [
      "王琤",
      "贾喆"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "算力分配",
    "l2": "算力分配",
    "tech": "算力分配"
  },
  {
    "entity": "阿里",
    "pun": "CN106920141A",
    "apn": "CN201511001533.2",
    "apd": "2015-12-28",
    "apy": "2015",
    "tit": "页面展示内容处理方法及装置",
    "inv": [
      "顾伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN103295217B",
    "apn": "CN201210052031.2",
    "apd": "2012-03-01",
    "apy": "2012",
    "tit": "图片信息处理方法以及装置",
    "inv": [
      "张焱"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107358574A",
    "apn": "CN201610305162.5",
    "apd": "2016-05-10",
    "apy": "2016",
    "tit": "一种图像缩小方法及装置",
    "inv": [
      "何文俊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN107452041A",
    "apn": "CN201610374626.8",
    "apd": "2016-05-31",
    "apy": "2016",
    "tit": "图片的生成方法及装置",
    "inv": [
      "卢伟勤"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN103176996A",
    "apn": "CN201110432359.2",
    "apd": "2011-12-21",
    "apy": "2011",
    "tit": "基于图片特征信息的图片搜索方法及图片搜索引擎服务器",
    "inv": [
      "李伟"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "搜索",
    "l2": "搜索输出",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN103164799A",
    "apn": "CN201110424932.5",
    "apd": "2011-12-16",
    "apy": "2011",
    "tit": "一种认证信息生成方法、发布方法及系统",
    "inv": [
      "樊航成"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "真伪鉴定",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN106919619A",
    "apn": "CN201511000890.7",
    "apd": "2015-12-28",
    "apy": "2015",
    "tit": "一种商品聚类方法、装置及电子设备",
    "inv": [
      "陈力",
      "吴振元",
      "董宇",
      "汤佳宇",
      "林锋"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106327537A",
    "apn": "CN201610405078.0",
    "apd": "2016-06-08",
    "apy": "2016",
    "tit": "一种图像预处理方法及装置",
    "inv": [
      "于鲲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "质量评价",
    "tech": "质量评价"
  },
  {
    "entity": "阿里",
    "pun": "CN106886698A",
    "apn": "CN201610404940.6",
    "apd": "2016-06-08",
    "apy": "2016",
    "tit": "一种认证方法及装置",
    "inv": [
      "曾岳伟",
      "黄冕",
      "应宇乾",
      "庹宇鲲"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107491459A",
    "apn": "CN201610414781.8",
    "apd": "2016-06-13",
    "apy": "2016",
    "tit": "三维立体图像的检索方法和装置",
    "inv": [
      "孙修宇",
      "李昊",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107491456A",
    "apn": "CN201610412993.2",
    "apd": "2016-06-13",
    "apy": "2016",
    "tit": "图像排序方法和装置",
    "inv": [
      "余宙",
      "潘攀",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像检索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN110262731A",
    "apn": "CN201910456383.6",
    "apd": "2019-05-29",
    "apy": "2019",
    "tit": "一种交互系统的输入信息获取方法、装置及电子设备",
    "inv": [
      "张晓博",
      "杨旭东",
      "侯章军",
      "曾晓东"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "文化娱乐",
    "l2": "人机交互",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN103092861B",
    "apn": "CN201110341926.3",
    "apd": "2011-11-02",
    "apy": "2011",
    "tit": "一种商品代表图的选取方法和系统",
    "inv": [
      "王从德",
      "孔祥衡"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107527319A",
    "apn": "CN201610447226.5",
    "apd": "2016-06-20",
    "apy": "2016",
    "tit": "图像收缩方法和装置",
    "inv": [
      "王彬",
      "潘攀",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "图像处理",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN102903071B",
    "apn": "CN201110212080.3",
    "apd": "2011-07-27",
    "apy": "2011",
    "tit": "水印添加方法及系统、水印识别方法及系统",
    "inv": [
      "王永攀",
      "贾梦雷",
      "段曼妮"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "版权",
    "l2": "版权保护",
    "tech": "图像处理"
  },
  {
    "entity": "阿里",
    "pun": "CN110008358A",
    "apn": "CN201910082776.5",
    "apd": "2019-01-28",
    "apy": "2019",
    "tit": "一种资源信息展示方法和系统、客户端及服务端",
    "inv": [
      "陈双",
      "陈一卓"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "页面展示",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN106920251A",
    "apn": "CN201610461515.0",
    "apd": "2016-06-23",
    "apy": "2016",
    "tit": "人手检测跟踪方法及装置",
    "inv": [
      "杜志军",
      "王楠"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107545271A",
    "apn": "CN201610499639.8",
    "apd": "2016-06-29",
    "apy": "2016",
    "tit": "图像识别方法、装置和系统",
    "inv": [
      "孙佰贵",
      "刘扬",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107590491A",
    "apn": "CN201610533632.3",
    "apd": "2016-07-07",
    "apy": "2016",
    "tit": "一种图像处理方法及装置",
    "inv": [
      "金炫"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107590154A",
    "apn": "CN201610534932.3",
    "apd": "2016-07-08",
    "apy": "2016",
    "tit": "基于图像识别的物体相似度判定方法和装置",
    "inv": [
      "王健",
      "郑赟",
      "潘攀",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "商品搜索",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN102339289B",
    "apn": "CN201010236052.0",
    "apd": "2010-07-21",
    "apy": "2010",
    "tit": "文字信息与图像信息的匹配识别方法及装置",
    "inv": [
      "蔡景助"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "电商",
    "l2": "图像质量",
    "tech": "图像识别"
  },
  {
    "entity": "阿里",
    "pun": "CN107622188A",
    "apn": "CN201610563106.1",
    "apd": "2016-07-15",
    "apy": "2016",
    "tit": "基于生物特征的验证方法、装置、系统和设备",
    "inv": [
      "匡雨驰"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "活体检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN102110122B",
    "apn": "CN200910260175.5",
    "apd": "2009-12-24",
    "apy": "2009",
    "tit": "一种建立样本图片索引表和图片过滤、搜索方法及装置",
    "inv": [
      "段曼妮",
      "温新赐",
      "冯旭瑞"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN106874926A",
    "apn": "CN201610634562.0",
    "apd": "2016-08-04",
    "apy": "2016",
    "tit": "基于图像特征的业务异常检测方法及装置",
    "inv": [
      "程磊"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "安全/证件安全",
    "l2": "内容审核",
    "tech": "图像检索"
  },
  {
    "entity": "阿里",
    "pun": "CN107689073A",
    "apn": "CN201610639783.7",
    "apd": "2016-08-05",
    "apy": "2016",
    "tit": "图像集的生成方法、装置及图像识别模型训练方法、系统",
    "inv": [
      "赵永科"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "VR/AR,3D",
    "tech": "VR/AR,3D"
  },
  {
    "entity": "阿里",
    "pun": "CN107729901A",
    "apn": "CN201610652942.7",
    "apd": "2016-08-10",
    "apy": "2016",
    "tit": "图像处理模型的建立方法、装置及图像处理方法及系统",
    "inv": [
      "李昊",
      "孙修宇",
      "刘巍",
      "潘攀",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标检测",
    "tech": "目标检测"
  },
  {
    "entity": "阿里",
    "pun": "CN107729908A",
    "apn": "CN201610653563.X",
    "apd": "2016-08-10",
    "apy": "2016",
    "tit": "一种机器学习分类模型的建立方法、装置及系统",
    "inv": [
      "赵小伟",
      "高洋",
      "华先胜"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "泛场景",
    "l2": "目标分割",
    "tech": "目标分割"
  },
  {
    "entity": "阿里",
    "pun": "CN107730483A",
    "apn": "CN201610654033.7",
    "apd": "2016-08-10",
    "apy": "2016",
    "tit": "移动设备、处理脸部生物特征的方法、装置和系统",
    "inv": [
      "罗运广"
    ],
    "app": [
      "阿里巴巴集团控股有限公司"
    ],
    "l1": "人脸/活体识别",
    "l2": "状态/情绪识别",
    "tech": "图像识别"
  }
];
