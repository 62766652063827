import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

import {PATH_PREFIX} from './constants';
// import ForceLayout from './pages/Papers/ForceLayout';
// import Compare from './pages/Compare';
import VisualComposition from './pages/Patents/VisualComposition';
import Trends from './pages/Papers/Trends';
import Network from './pages/Papers/Network';
import VisualEvolution from './pages/Patents/VisualEvolution';
import VisualMatrix from './pages/Patents/VisualMatrix';
import Index from './pages/Index';
import OCREvolution from './pages/Patents/OCREvolution';
import OCRComposition from './pages/Patents/OCRComposition';
import OCRMatrix from './pages/Patents/OCRMatrix';


function App() {
  return (
    <BrowserRouter basename={PATH_PREFIX}>
      <ConfigProvider locale={zhCN}>
        <Switch>
          <Route exact path={'/paper/trends/'} component={Trends} />
          <Route exact path={'/paper/network/'} component={Network} />

          <Route exact path={'/patent/visual/evolution/'} component={VisualEvolution} />
          <Route exact path={'/patent/visual/composition/'} component={VisualComposition} />
          <Route exact path={'/patent/visual/matrix/'} component={VisualMatrix} />

          <Route exact path={'/patent/ocr/evolution/'} component={OCREvolution} />
          <Route exact path={'/patent/ocr/composition/'} component={OCRComposition} />
          <Route exact path={'/patent/ocr/matrix/'} component={OCRMatrix} />

          <Route component={Index} />

          {/*<Route exact path={'/compare/'} component={Compare} />*/}
          {/*<Route exact path={'/force/'} component={ForceLayout} />*/}
        </Switch>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
