export const data = {
  'repo': [
    {
      'id': 101053002,
      'title': 'Balanced Self-Paced Learning for Generative Adversarial Clustering Network',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1407499368,
          'name': 'Kamran Ghasedi'
        },
        {
          'id': 1062068585,
          'name': 'Xiaoqian Wang'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1277559265,
          'name': 'Heng Huang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Ghasedi_Balanced_Self-Paced_Learning_for_Generative_Adversarial_Clustering_Network_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 101491471,
      'title': 'See More, Know More: Unsupervised Video Object Segmentation With Co-Attention Siamese Networks',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1259846417,
          'name': 'Xiankai Lu'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1002172473,
          'name': 'Chao Ma'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Lu_See_More_Know_More_Unsupervised_Video_Object_Segmentation_With_Co-Attention_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 101708422,
      'title': 'ClusterNet: Deep Hierarchical Cluster Network With Rigorously Rotation-Invariant Representation for Point Cloud Analysis',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1000076810,
          'name': 'Chao Chen'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1377537327,
          'name': 'Ruijia Xu'
        },
        {
          'id': 1298778203,
          'name': 'Tianshui Chen'
        },
        {
          'id': 1001002278,
          'name': 'Meng Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2002561005,
          'name': 'Hierarchical clustering'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Chen_ClusterNet_Deep_Hierarchical_Cluster_Network_With_Rigorously_Rotation-Invariant_Representation_for_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 102310906,
      'title': 'Meta-SR: A Magnification-Arbitrary Network for Super-Resolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1190551893,
          'name': 'Xuecai Hu'
        },
        {
          'id': 1320344181,
          'name': 'Haoyuan Mu'
        },
        {
          'id': 1040991163,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1018910775,
          'name': 'Zilei Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        },
        {
          'id': 1000062846,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2047155301,
          'name': 'Magnification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Hu_Meta-SR_A_Magnification-Arbitrary_Network_for_Super-Resolution_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 102718920,
      'title': 'RGBD Based Dimensional Decomposition Residual Network for 3D Semantic Scene Completion',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1496513416,
          'name': 'Jie Li'
        },
        {
          'id': 1000284948,
          'name': 'Yu Liu'
        },
        {
          'id': 1151308559,
          'name': 'Dong Gong'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        },
        {
          'id': 1152087861,
          'name': 'Xia Yuan'
        },
        {
          'id': 1023181580,
          'name': 'Chunxia Zhao'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://www.semanticscholar.org/paper/RGBD-Based-Dimensional-Decomposition-Residual-for-Li-Liu/79a9e466b3f494ac429bfa78935c8c1e64d880d0',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 103286779,
      'title': 'Multi-person Articulated Tracking with Spatial and Temporal Embeddings.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1009936383,
          'name': 'Sheng Jin'
        },
        {
          'id': 1225487178,
          'name': 'Wentao Liu'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1257940400,
          'name': 'Chen Qian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Jin_Multi-Person_Articulated_Tracking_With_Spatial_and_Temporal_Embeddings_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 104021985,
      'title': 'An End-To-End Network for Panoptic Segmentation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1300899670,
          'name': 'Huanyu Liu'
        },
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1255703345,
          'name': 'Changqian Yu'
        },
        {
          'id': 1189593101,
          'name': 'Jingbo Wang'
        },
        {
          'id': 1004266194,
          'name': 'Xu Liu'
        },
        {
          'id': 1416154934,
          'name': 'Gang Yu'
        },
        {
          'id': 1001073673,
          'name': 'Wei Jiang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024741998,
          'name': 'Panopticon'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_An_End-To-End_Network_for_Panoptic_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 106017578,
      'title': 'Led3D: A Lightweight and Efficient Deep Approach to Recognizing Low-Quality 3D Faces',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1421886109,
          'name': 'Guodong Mu'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1102859019,
          'name': 'guosheng Hu'
        },
        {
          'id': 1215278001,
          'name': 'Jia Sun'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Mu_Led3D_A_Lightweight_and_Efficient_Deep_Approach_to_Recognizing_Low-Quality_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 106924979,
      'title': 'Distant Supervised Centroid Shift: A Simple and Efficient Approach to Visual Domain Adaptation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1111727891,
          'name': 'Jian Liang'
        },
        {
          'id': 1020627459,
          'name': 'Ran He'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2044405812,
          'name': 'Centroid'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liang_Distant_Supervised_Centroid_Shift_A_Simple_and_Efficient_Approach_to_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 110621527,
      'title': 'Adaptive NMS: Refining Pedestrian Detection in a Crowd',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1037258992,
          'name': 'Songtao Liu'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2013553633,
          'name': 'Refining (metallurgy)'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Adaptive_NMS_Refining_Pedestrian_Detection_in_a_Crowd_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 111133296,
      'title': 'Unequal-Training for Deep Face Recognition With Long-Tailed Noisy Data',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1354792759,
          'name': 'Yaoyao Zhong'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1394920256,
          'name': 'Mei Wang'
        },
        {
          'id': 1481827642,
          'name': 'Jiani Hu'
        },
        {
          'id': 1471778755,
          'name': 'Jianteng Peng'
        },
        {
          'id': 1057619188,
          'name': 'Xunqiang Tao'
        },
        {
          'id': 1122453510,
          'name': 'Yaohai Huang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042314525,
          'name': 'Noisy data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhong_Unequal-Training_for_Deep_Face_Recognition_With_Long-Tailed_Noisy_Data_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 112850723,
      'title': 'Relation-Shape Convolutional Neural Network for Point Cloud Analysis.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1409279138,
          'name': 'Yongcheng Liu'
        },
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1114885725,
          'name': 'Chunhong Pan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 112966933,
      'title': 'Multi-Source Weak Supervision for Saliency Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1144644555,
          'name': 'Yu Zeng'
        },
        {
          'id': 1461993227,
          'name': 'Yunzhi Zhuge'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1030253801,
          'name': 'Mingyang Qian'
        },
        {
          'id': 1419663498,
          'name': 'Yizhou Yu'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025617559,
          'name': 'Multi-source'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zeng_Multi-Source_Weak_Supervision_for_Saliency_Detection_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 113739739,
      'title': 'Learning Instance Activation Maps for Weakly Supervised Instance Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        }
      ],
      'author': [
        {
          'id': 1259889084,
          'name': 'Yi Zhu'
        },
        {
          'id': 1238435354,
          'name': 'Yanzhao Zhou'
        },
        {
          'id': 1106997946,
          'name': 'Huijuan. Xu'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1267631771,
          'name': 'David S. Doermann'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhu_Learning_Instance_Activation_Maps_for_Weakly_Supervised_Instance_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 11418242,
      'title': 'TACNet: Transition-Aware Context Network for Spatio-Temporal Action Detection',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1002373019,
          'name': 'Lin Song'
        },
        {
          'id': 1473350743,
          'name': 'Shiwei Zhang'
        },
        {
          'id': 1016760006,
          'name': 'Gang Yu'
        },
        {
          'id': 1149443514,
          'name': 'Hongbin Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Song_TACNet_Transition-Aware_Context_Network_for_Spatio-Temporal_Action_Detection_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 11428932,
      'title': 'Student Becoming the Master: Knowledge Amalgamation for Joint Scene Parsing, Depth Estimation, and More',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103556481,
          'abbr': 'SIT',
          'name': 'Stevens Institute of Technology'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2101896410,
          'abbr': 'Yunda',
          'name': 'Yunnan University'
        },
        {
          'id': 2104054782,
          'abbr': 'UW',
          'name': 'University of Washington'
        }
      ],
      'author': [
        {
          'id': 1097201400,
          'name': 'Jingwen Ye'
        },
        {
          'id': 1315537578,
          'name': 'Yixin Ji'
        },
        {
          'id': 1151290883,
          'name': 'Xinchao Wang'
        },
        {
          'id': 1438764439,
          'name': 'Kairi Ou'
        },
        {
          'id': 1418905484,
          'name': 'Dapeng Tao'
        },
        {
          'id': 1299921524,
          'name': 'Mingli Song'
        }
      ],
      'field': [
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Ye_Student_Becoming_the_Master_Knowledge_Amalgamation_for_Joint_Scene_Parsing_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 117439455,
      'title': 'Parametric Skeleton Generation via Gaussian Mixture Models',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1347623422,
          'name': 'Chang Liu'
        },
        {
          'id': 1130792590,
          'name': 'Dezhao Luo'
        },
        {
          'id': 1008740152,
          'name': 'Yifei Zhang'
        },
        {
          'id': 1030826032,
          'name': 'Wei Ke'
        },
        {
          'id': 1437119785,
          'name': 'Fang Wan'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/SkelNetOn/Liu_Parametric_Skeleton_Generation_via_Gaussian_Mixture_Models_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 119194643,
      'title': 'Semantic Alignment: Finding Semantically Consistent Ground-truth for Facial Landmark Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1101352300,
          'name': 'Zhiwei Liu'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1102859019,
          'name': 'guosheng Hu'
        },
        {
          'id': 1461930620,
          'name': 'Haiyun Guo'
        },
        {
          'id': 1086300120,
          'name': 'Ming Tang'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1331370766,
          'name': 'Neil Robertson'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Semantic_Alignment_Finding_Semantically_Consistent_Ground-Truth_for_Facial_Landmark_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 121945807,
      'title': 'Unsupervised Domain Adaptation for Multispectral Pedestrian Detection.',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103303185,
          'abbr': 'UTWENTE',
          'name': 'University of Twente'
        }
      ],
      'author': [
        {
          'id': 1243165617,
          'name': 'Dayan Guan'
        },
        {
          'id': 1046944488,
          'name': 'Xing Luo'
        },
        {
          'id': 1132157418,
          'name': 'Yanpeng Cao'
        },
        {
          'id': 1338315429,
          'name': 'Jiangxin Yang'
        },
        {
          'id': 1383822911,
          'name': 'Yanlong Cao'
        },
        {
          'id': 1217560631,
          'name': 'George Vosselman'
        },
        {
          'id': 1496002253,
          'name': 'Michael Ying Yang'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/MULA/Guan_Unsupervised_Domain_Adaptation_for_Multispectral_Pedestrian_Detection_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 123284074,
      'title': 'Knowledge Distillation via Instance Relationship Graph',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1310664425,
          'name': 'Yufan Liu'
        },
        {
          'id': 1307198579,
          'name': 'Jiajiong Cao'
        },
        {
          'id': 1138746327,
          'name': 'Bing Li'
        },
        {
          'id': 1215326337,
          'name': 'Chunfeng Yuan'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1242857901,
          'name': 'Yangxi Li'
        },
        {
          'id': 1222816389,
          'name': 'Yunqiang Duan'
        }
      ],
      'field': [
        {
          'id': 2010918187,
          'name': 'Distillation'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Knowledge_Distillation_via_Instance_Relationship_Graph_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 127073242,
      'title': 'Destruction and Construction Learning for Fine-Grained Image Recognition',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1010424782,
          'name': 'Yue Chen'
        },
        {
          'id': 1416228231,
          'name': 'Yalong Bai'
        },
        {
          'id': 1004803622,
          'name': 'Wei Zhang'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_Destruction_and_Construction_Learning_for_Fine-Grained_Image_Recognition_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 127158025,
      'title': 'MHP-VOS: Multiple Hypotheses Propagation for Video Object Segmentation',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1344527433,
          'name': 'Shuangjie Xu'
        },
        {
          'id': 1101811590,
          'name': 'Daizong Liu'
        },
        {
          'id': 1084291466,
          'name': 'Linchao Bao'
        },
        {
          'id': 1355615246,
          'name': 'Wei Liu'
        },
        {
          'id': 1467586349,
          'name': 'Pan Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xu_MHP-VOS_Multiple_Hypotheses_Propagation_for_Video_Object_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 127500336,
      'title': 'A Mutual Learning Method for Salient Object Detection With Intertwined Multi-Supervision',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1203262537,
          'name': 'Runmin Wu'
        },
        {
          'id': 1451407143,
          'name': 'Mengyang Feng'
        },
        {
          'id': 1337031112,
          'name': 'Wenlong Guan'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1312833909,
          'name': 'Errui Ding'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wu_A_Mutual_Learning_Method_for_Salient_Object_Detection_With_Intertwined_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 127975427,
      'title': 'Weakly-Supervised Discovery of Geometry-Aware Representation for 3D Human Pose Estimation.',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1178248026,
          'name': 'Xipeng Chen'
        },
        {
          'id': 1167966062,
          'name': 'Kwan-Yee Lin'
        },
        {
          'id': 1225487178,
          'name': 'Wentao Liu'
        },
        {
          'id': 1257940400,
          'name': 'Chen Qian'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_Weakly-Supervised_Discovery_of_Geometry-Aware_Representation_for_3D_Human_Pose_Estimation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 130016409,
      'title': 'ODE-Inspired Network Design for Single Image Super-Resolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1020524329,
          'name': 'Xiangyu He'
        },
        {
          'id': 1034909840,
          'name': 'Zitao Mo'
        },
        {
          'id': 1261785545,
          'name': 'Peisong Wang'
        },
        {
          'id': 1392088963,
          'name': 'Yang Liu'
        },
        {
          'id': 1483800028,
          'name': 'Mingyuan Yang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044280876,
          'name': 'Ode'
        },
        {
          'id': 2025093314,
          'name': 'Network planning and design'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/He_ODE-Inspired_Network_Design_for_Single_Image_Super-Resolution_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 13252762,
      'title': 'Single Image Reflection Removal Exploiting Misaligned Training Data and Network Enhancements.',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1045421762,
          'name': 'Kaixuan Wei'
        },
        {
          'id': 1402890126,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1192145103,
          'name': 'Ying Fu'
        },
        {
          'id': 1056288383,
          'name': 'David Wipf'
        },
        {
          'id': 1482749841,
          'name': 'Hua Huang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 132864571,
      'title': 'Multi-Scale Adaptive Dehazing Network',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1094576103,
          'name': 'Shuxin Chen'
        },
        {
          'id': 1400511359,
          'name': 'Yizi Chen'
        },
        {
          'id': 1309298089,
          'name': 'Yanyun Qu'
        },
        {
          'id': 1391559988,
          'name': 'Jingying Huang'
        },
        {
          'id': 1041778254,
          'name': 'Ming Hong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/NTIRE/Chen_Multi-Scale_Adaptive_Dehazing_Network_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 133756364,
      'title': 'Exploring Object Relation in Mean Teacher for Cross-Domain Detection.',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100888609,
          'abbr': 'ACM',
          'name': 'Association for Computing Machinery'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1140511387,
          'name': 'Qi Cai'
        },
        {
          'id': 1429448092,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1114237355,
          'name': 'Chong-Wah Ngo'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        }
      ],
      'field': [
        {
          'id': 2033978492,
          'name': 'Object relations theory'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Cai_Exploring_Object_Relation_in_Mean_Teacher_for_Cross-Domain_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 135687394,
      'title': 'BridgeNet: A Continuity-Aware Probabilistic Network for Age Estimation.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1006403406,
          'name': 'Wanhua Li'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1361061781,
          'name': 'Jianjiang Feng'
        },
        {
          'id': 1166168767,
          'name': 'Chunjing Xu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_BridgeNet_A_Continuity-Aware_Probabilistic_Network_for_Age_Estimation_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 1396612,
      'title': 'Sequence-To-Sequence Domain Adaptation Network for Robust Text Image Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1222089400,
          'name': 'Yaping Zhang'
        },
        {
          'id': 1306964192,
          'name': 'Shuai Nie'
        },
        {
          'id': 1037947754,
          'name': 'Wenju Liu'
        },
        {
          'id': 1260168660,
          'name': 'Xing Xu'
        },
        {
          'id': 1125728180,
          'name': 'Dongxiang Zhang'
        },
        {
          'id': 1168663224,
          'name': 'Heng Tao Shen'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Sequence-To-Sequence_Domain_Adaptation_Network_for_Robust_Text_Image_Recognition_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 14334075,
      'title': 'Adversarial Defense Through Network Profiling Based Path Extraction',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1455240475,
          'name': 'Yuxian Qiu'
        },
        {
          'id': 1269501441,
          'name': 'Jingwen Leng'
        },
        {
          'id': 1450135871,
          'name': 'Cong Guo'
        },
        {
          'id': 1216204513,
          'name': 'Quan Chen'
        },
        {
          'id': 1014426723,
          'name': 'Chao Li'
        },
        {
          'id': 1358545007,
          'name': 'Minyi Guo'
        },
        {
          'id': 1315125133,
          'name': 'Yuhao Zhu'
        }
      ],
      'field': [
        {
          'id': 2037439519,
          'name': 'Profiling (computer programming)'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Qiu_Adversarial_Defense_Through_Network_Profiling_Based_Path_Extraction_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 144632726,
      'title': 'Multi-Modal Face Presentation Attack Detection via Spatial and Channel Attentions',
      'affiliation': [
        {
          'id': 2101674663,
          'abbr': 'HEU',
          'name': 'Harbin Engineering University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1362747379,
          'name': 'Guoqing Wang'
        },
        {
          'id': 1344116512,
          'name': 'Chuanxin Lan'
        },
        {
          'id': 1217280926,
          'name': 'Hu Han'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028889101,
          'name': 'Modal'
        },
        {
          'id': 2026437312,
          'name': 'Face Presentation'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CFS/Wang_Multi-Modal_Face_Presentation_Attack_Detection_via_Spatial_and_Channel_Attentions_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 147269766,
      'title': 'Overcoming Limitations of Mixture Density Networks: A Sampling and Fitting Framework for Multimodal Future Prediction',
      'affiliation': [
        {
          'id': 2100215493,
          'abbr': 'UNI-FREIBURG',
          'name': 'University of Freiburg'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1057330466,
          'name': 'Osama Makansi'
        },
        {
          'id': 1402634458,
          'name': 'Eddy Ilg'
        },
        {
          'id': 1185914230,
          'name': 'Özgün Çiçek'
        },
        {
          'id': 1291270053,
          'name': 'Thomas Brox'
        }
      ],
      'field': [
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048893815,
          'name': 'Mixture distribution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://lmb.informatik.uni-freiburg.de/Publications/2019/MICB19/',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 148331518,
      'title': 'Multiscale Kernels for Enhanced U-Shaped Network to Improve 3D Neuron Tracing',
      'affiliation': [
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2104294788,
          'abbr': '',
          'name': 'Westmead Hospital'
        },
        {
          'id': 2103100482,
          'abbr': 'SIEMENS',
          'name': 'Siemens'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102990488,
          'abbr': 'QUB',
          'name': 'Queen\'s University Belfast'
        },
        {
          'id': 2104085410,
          'abbr': 'SEU',
          'name': 'Southeast University'
        }
      ],
      'author': [
        {
          'id': 1166623593,
          'name': 'Heng Wang'
        },
        {
          'id': 1325275851,
          'name': 'Donghao Zhang'
        },
        {
          'id': 1034207288,
          'name': 'Yang Song'
        },
        {
          'id': 1375601266,
          'name': 'Siqi Liu'
        },
        {
          'id': 1277559265,
          'name': 'Heng Huang'
        },
        {
          'id': 1008978302,
          'name': 'Mei Chen'
        },
        {
          'id': 1246680480,
          'name': 'Hanchuan Peng'
        },
        {
          'id': 1210919005,
          'name': 'Weidong Cai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043351799,
          'name': 'Tracing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/CVMI/Wang_Multiscale_Kernels_for_Enhanced_U-Shaped_Network_to_Improve_3D_Neuron_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 148870644,
      'title': 'DET: A High-Resolution DVS Dataset for Lane Extraction',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1051073091,
          'name': 'Wensheng Cheng'
        },
        {
          'id': 1007150390,
          'name': 'Hao Luo'
        },
        {
          'id': 1376097180,
          'name': 'Wen Yang'
        },
        {
          'id': 1000852138,
          'name': 'Lei Yu'
        },
        {
          'id': 1056896242,
          'name': 'Shoushun Chen'
        },
        {
          'id': 1000038819,
          'name': 'Wei Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/EventVision/Cheng_DET_A_High-Resolution_DVS_Dataset_for_Lane_Extraction_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 149405176,
      'title': 'NTIRE 2019 Image Dehazing Challenge Report',
      'affiliation': [
        {
          'id': 2104794917,
          'abbr': 'UHasselt',
          'name': 'University of Hasselt'
        },
        {
          'id': 2103024857,
          'abbr': 'UCL',
          'name': 'Université catholique de Louvain'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1366401621,
          'name': 'Codruta Orniana Ancuti'
        },
        {
          'id': 1440684276,
          'name': 'Cosmin Ancuti'
        },
        {
          'id': 1089908655,
          'name': 'Radu Timofte'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        },
        {
          'id': 1421777131,
          'name': 'Lei Zhang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/NTIRE/Ancuti_NTIRE_2019_Image_Dehazing_Challenge_Report_CVPRW_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 15144961,
      'title': 'Contrast Prior and Fluid Pyramid Integration for RGBD Salient Object Detection',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        }
      ],
      'author': [
        {
          'id': 1026728100,
          'name': 'Jia Xing Zhao'
        },
        {
          'id': 1478948706,
          'name': 'Yang Cao'
        },
        {
          'id': 1235240356,
          'name': 'Deng-Ping Fan'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1237338552,
          'name': 'Xuan-Yi Li'
        },
        {
          'id': 1204120232,
          'name': 'Le Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhao_Contrast_Prior_and_Fluid_Pyramid_Integration_for_RGBD_Salient_Object_CVPR_2019_paper.html',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 153153744,
      'title': 'Pointing Novel Objects in Image Captioning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100888609,
          'abbr': 'ACM',
          'name': 'Association for Computing Machinery'
        }
      ],
      'author': [
        {
          'id': 1403845832,
          'name': 'Yehao Li'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1429448092,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Pointing_Novel_Objects_in_Image_Captioning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 153626104,
      'title': 'Bounding Box Regression With Uncertainty for Accurate Object Detection',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1342689546,
          'name': 'Yihui He'
        },
        {
          'id': 1010759809,
          'name': 'Chenchen Zhu'
        },
        {
          'id': 1438974523,
          'name': 'Jianren Wang'
        },
        {
          'id': 1352525808,
          'name': 'Marios Savvides'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/He_Bounding_Box_Regression_With_Uncertainty_for_Accurate_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 153744993,
      'title': 'Deep Robust Subjective Visual Property Prediction in Crowdsourcing.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1136712973,
          'name': 'Qianqian Xu'
        },
        {
          'id': 1149714789,
          'name': 'Zhiyong Yang'
        },
        {
          'id': 1036345794,
          'name': 'Yangbangyan Jiang'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1063017494,
          'name': 'Yuan Yao'
        }
      ],
      'field': [
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002903134,
          'name': 'Crowdsourcing'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Xu_Deep_Robust_Subjective_Visual_Property_Prediction_in_Crowdsourcing_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 30
    },
    {
      'id': 154614949,
      'title': 'Hardness-Aware Deep Metric Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1388549606,
          'name': 'Wenzhao Zheng'
        },
        {
          'id': 1250822429,
          'name': 'Zhaodong Chen'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1085154187,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zheng_Hardness-Aware_Deep_Metric_Learning_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 156023391,
      'title': 'Exact Adversarial Attack to Image Captioning via Structured Output Learning With Latent Variables',
      'affiliation': [
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1138475303,
          'name': 'Yan Xu'
        },
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1109440690,
          'name': 'Yanbo Fan'
        },
        {
          'id': 1114571624,
          'name': 'Yong Zhang'
        },
        {
          'id': 1168663224,
          'name': 'Heng Tao Shen'
        },
        {
          'id': 1355615246,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xu_Exact_Adversarial_Attack_to_Image_Captioning_via_Structured_Output_Learning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 156652935,
      'title': 'Bag of Tricks and a Strong Baseline for Deep Person Re-Identification',
      'affiliation': [
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1302395596,
          'name': 'Hao Luo'
        },
        {
          'id': 1030160042,
          'name': 'Youzhi Gu'
        },
        {
          'id': 1478664406,
          'name': 'Xingyu Liao'
        },
        {
          'id': 1421205405,
          'name': 'Shenqi Lai'
        },
        {
          'id': 1326747483,
          'name': 'Wei Jiang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/TRMTMCT/Luo_Bag_of_Tricks_and_a_Strong_Baseline_for_Deep_Person_CVPRW_2019_paper.pdf',
      'citation_count': 11,
      'reference_count': 0
    },
    {
      'id': 156922018,
      'title': 'Depth Image Quality Assessment for View Synthesis Based on Weighted Edge Similarity',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2104431718,
          'abbr': 'CUMT',
          'name': 'China University of Mining and Technology'
        }
      ],
      'author': [
        {
          'id': 1096814822,
          'name': 'Leida Li'
        },
        {
          'id': 1000990382,
          'name': 'Xi Chen'
        },
        {
          'id': 1049214243,
          'name': 'Yu Zhou'
        },
        {
          'id': 1060237063,
          'name': 'Jinjian Wu'
        },
        {
          'id': 1291914506,
          'name': 'Guangming Shi'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/UG2+ Prize Challenge/Li_Depth_Image_Quality_Assessment_for_View_Synthesis_Based_on_Weighted_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 15800346,
      'title': 'Reasoning-RCNN: Unifying Adaptive Global Reasoning Into Large-Scale Object Detection',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1005299132,
          'name': 'Hang Xu'
        },
        {
          'id': 1207780394,
          'name': 'Chenhan Jiang'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1049902462,
          'name': 'Zhenguo Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xu_Reasoning-RCNN_Unifying_Adaptive_Global_Reasoning_Into_Large-Scale_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 159000836,
      'title': 'Spatially Variant Linear Representation Models for Joint Filtering',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1363750438,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1118840716,
          'name': 'Jiangxin Dong'
        },
        {
          'id': 1446742884,
          'name': 'Jimmy S. J. Ren'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pan_Spatially_Variant_Linear_Representation_Models_for_Joint_Filtering_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 160012220,
      'title': 'K-Nearest Neighbors Hashing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1020524329,
          'name': 'Xiangyu He'
        },
        {
          'id': 1261785545,
          'name': 'Peisong Wang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/He_K-Nearest_Neighbors_Hashing_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 16134854,
      'title': 'Unsupervised Traffic Anomaly Detection Using Trajectories',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1475247179,
          'name': 'Jianfei Zhao'
        },
        {
          'id': 1438104897,
          'name': 'Zitong Yi'
        },
        {
          'id': 1209435551,
          'name': 'Siyang Pan'
        },
        {
          'id': 1166162652,
          'name': 'Yanyun Zhao'
        },
        {
          'id': 1228821156,
          'name': 'Zhicheng Zhao'
        },
        {
          'id': 1204909747,
          'name': 'Fei Su'
        },
        {
          'id': 1029847396,
          'name': 'Bojin Zhuang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/AI City/Zhao_Unsupervised_Traffic_Anomaly_Detection_Using_Trajectories_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 163382218,
      'title': 'Deep Plug-And-Play Super-Resolution for Arbitrary Blur Kernels',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        }
      ],
      'author': [
        {
          'id': 1221197508,
          'name': 'Kai Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1428956362,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027434884,
          'name': 'Plug and play'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Deep_Plug-And-Play_Super-Resolution_for_Arbitrary_Blur_Kernels_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 167020748,
      'title': 'Transferrable Prototypical Networks for Unsupervised Domain Adaptation.',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101079810,
          'abbr': 'CAM',
          'name': 'University of Cambridge'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1403845832,
          'name': 'Yehao Li'
        },
        {
          'id': 1407991464,
          'name': 'Yu Wang'
        },
        {
          'id': 1114237355,
          'name': 'Chong-Wah Ngo'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pan_Transferrable_Prototypical_Networks_for_Unsupervised_Domain_Adaptation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 169153925,
      'title': 'Connecting the Dots: Learning Representations for Active Monocular Depth Estimation',
      'affiliation': [
        {
          'id': 2102405609,
          'abbr': 'TUGRAZ',
          'name': 'Graz University of Technology'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2102440343,
          'abbr': 'MPG',
          'name': 'Max Planck Society'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1476263641,
          'name': 'Gernot Riegler'
        },
        {
          'id': 1445655198,
          'name': 'Yiyi Liao'
        },
        {
          'id': 1313230675,
          'name': 'Simon Donné'
        },
        {
          'id': 1084331012,
          'name': 'Vladlen Koltun'
        },
        {
          'id': 1187506242,
          'name': 'Andreas Geiger'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Riegler_Connecting_the_Dots_Learning_Representations_for_Active_Monocular_Depth_Estimation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 170344390,
      'title': 'Efficient Featurized Image Pyramid Network for Single Shot Detector',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102407013,
          'abbr': 'AALTO',
          'name': 'Aalto University'
        },
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        }
      ],
      'author': [
        {
          'id': 1381098672,
          'name': 'Yanwei Pang'
        },
        {
          'id': 1091381200,
          'name': 'Tiancai Wang'
        },
        {
          'id': 1308203576,
          'name': 'Rao Muhammad Anwer'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1122227807,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pang_Efficient_Featurized_Image_Pyramid_Network_for_Single_Shot_Detector_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 170874702,
      'title': 'Self-Supervised Spatiotemporal Learning via Video Clip Order Prediction',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1495905229,
          'name': 'Dejing Xu'
        },
        {
          'id': 1122653209,
          'name': 'Jun Xiao'
        },
        {
          'id': 1404594076,
          'name': 'Zhou Zhao'
        },
        {
          'id': 1130645051,
          'name': 'Jian Shao'
        },
        {
          'id': 1268940531,
          'name': 'Di Xie'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Xu_Self-Supervised_Spatiotemporal_Learning_via_Video_Clip_Order_Prediction_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 171110070,
      'title': 'Efficient Decision-based Black-box Adversarial Attacks on Face Recognition.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1126291268,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1321789444,
          'name': 'Wei Liu'
        },
        {
          'id': 1053600132,
          'name': 'Tong Zhang'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024299838,
          'name': 'Black box (phreaking)'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Dong_Efficient_Decision-Based_Black-Box_Adversarial_Attacks_on_Face_Recognition_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 175969213,
      'title': 'Attribute-Driven Feature Disentangling and Temporal Aggregation for Video Person Re-Identification',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        }
      ],
      'author': [
        {
          'id': 1161731938,
          'name': 'Yiru Zhao'
        },
        {
          'id': 1438130493,
          'name': 'Xu Shen'
        },
        {
          'id': 1275354817,
          'name': 'Zhongming Jin'
        },
        {
          'id': 1467680309,
          'name': 'Hongtao Lu'
        },
        {
          'id': 1202510511,
          'name': 'Xian-Sheng Hua'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhao_Attribute-Driven_Feature_Disentangling_and_Temporal_Aggregation_for_Video_Person_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 177887075,
      'title': 'Mask-Guided Portrait Editing with Conditional GANs.',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1409404232,
          'name': 'Shuyang Gu'
        },
        {
          'id': 1373420012,
          'name': 'Jianmin Bao'
        },
        {
          'id': 1434628678,
          'name': 'Hao Yang'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024435654,
          'name': 'Portrait'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Gu_Mask-Guided_Portrait_Editing_With_Conditional_GANs_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 179124039,
      'title': 'Compressing Unknown Images With Product Quantizer for Efficient Zero-Shot Classification',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1261160872,
          'name': 'Jin Li'
        },
        {
          'id': 1337466628,
          'name': 'Xuguang Lan'
        },
        {
          'id': 1000076948,
          'name': 'Yang Liu'
        },
        {
          'id': 1238522754,
          'name': 'Le Wang'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_Compressing_Unknown_Images_With_Product_Quantizer_for_Efficient_Zero-Shot_Classification_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 180186142,
      'title': 'Context and Attribute Grounded Dense Captioning',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1142128166,
          'name': 'Guojun Yin'
        },
        {
          'id': 1099193953,
          'name': 'Lu Sheng'
        },
        {
          'id': 1035353314,
          'name': 'Bin Liu'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1123668712,
          'name': 'Jing Shao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yin_Context_and_Attribute_Grounded_Dense_Captioning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 181376167,
      'title': 'DrivingStereo: A Large-Scale Dataset for Stereo Matching in Autonomous Driving Scenarios',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1467222094,
          'name': 'Guorun Yang'
        },
        {
          'id': 1061361633,
          'name': 'Xiao Song'
        },
        {
          'id': 1325964319,
          'name': 'Chaoqin Huang'
        },
        {
          'id': 1364679732,
          'name': 'Zhidong Deng'
        },
        {
          'id': 1224758535,
          'name': 'Jianping Shi'
        },
        {
          'id': 1203724752,
          'name': 'Bolei Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Yang_DrivingStereo_A_Large-Scale_Dataset_for_Stereo_Matching_in_Autonomous_Driving_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 181561189,
      'title': 'Facial Emotion Distribution Learning by Exploiting Low-Rank Label Correlations Locally',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102926590,
          'abbr': 'NUAA',
          'name': 'Nanjing University of Aeronautics and Astronautics'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1488872121,
          'name': 'Xiuyi Jia'
        },
        {
          'id': 1006114645,
          'name': 'Xiang Zheng'
        },
        {
          'id': 1414047070,
          'name': 'Weiwei Li'
        },
        {
          'id': 1179117743,
          'name': 'Changqing Zhang'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Jia_Facial_Emotion_Distribution_Learning_by_Exploiting_Low-Rank_Label_Correlations_Locally_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 18179899,
      'title': 'Adaptive Transfer Network for Cross-Domain Person Re-Identification',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        },
        {
          'id': 2103492460,
          'abbr': 'YSU',
          'name': 'Yanshan University'
        }
      ],
      'author': [
        {
          'id': 1327896010,
          'name': 'Jiawei Liu'
        },
        {
          'id': 1225297593,
          'name': 'Zheng-Jun Zha'
        },
        {
          'id': 1057676572,
          'name': 'Di Chen'
        },
        {
          'id': 1188079795,
          'name': 'Richang Hong'
        },
        {
          'id': 1250164035,
          'name': 'Meng Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Adaptive_Transfer_Network_for_Cross-Domain_Person_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 18388336,
      'title': 'SIXray : A Large-scale Security Inspection X-ray Benchmark for Prohibited Item Discovery in Overlapping Images',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1372922873,
          'name': 'Caijing Miao'
        },
        {
          'id': 1322192347,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1437119785,
          'name': 'Fang Wan'
        },
        {
          'id': 1421052666,
          'name': 'Chi Su'
        },
        {
          'id': 1481018373,
          'name': 'Hongye Liu'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Miao_SIXray_A_Large-Scale_Security_Inspection_X-Ray_Benchmark_for_Prohibited_Item_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 184276794,
      'title': 'Audio-Visual Event Localization in the Wild',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1434042206,
          'name': 'Yapeng Tian'
        },
        {
          'id': 1064341213,
          'name': 'Jing Shi'
        },
        {
          'id': 1048710360,
          'name': 'Bochen Li'
        },
        {
          'id': 1074096931,
          'name': 'Zhiyao Duan'
        },
        {
          'id': 1184802648,
          'name': 'Chenliang Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/Sight and Sound/Yapeng_Tian_Audio-Visual_Event_Localization_in_the_Wild_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 187237740,
      'title': 'Looking for the Devil in the Details: Learning Trilinear Attention Sampling Network for Fine-grained Image Recognition.',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1362733542,
          'name': 'Heliang Zheng'
        },
        {
          'id': 1057895870,
          'name': 'Jianlong Fu'
        },
        {
          'id': 1071421645,
          'name': 'Zheng-Jun Zha'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zheng_Looking_for_the_Devil_in_the_Details_Learning_Trilinear_Attention_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 187405730,
      'title': 'Text Guided Person Image Synthesis.',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100548198,
          'abbr': 'SUNY',
          'name': 'State University of New York System'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1081639489,
          'name': 'Xingran Zhou'
        },
        {
          'id': 1412650233,
          'name': 'Siyu Huang'
        },
        {
          'id': 1000797699,
          'name': 'Bin Li'
        },
        {
          'id': 1094069549,
          'name': 'Yingming Li'
        },
        {
          'id': 1160499429,
          'name': 'Jiachen Li'
        },
        {
          'id': 1260701992,
          'name': 'Zhongfei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhou_Text_Guided_Person_Image_Synthesis_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 188207146,
      'title': 'Modulating Image Restoration with Continual Levels via Adaptive Feature Modification Layers.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100355666,
          'abbr': 'TUM',
          'name': 'Technische Universität München'
        }
      ],
      'author': [
        {
          'id': 1118714781,
          'name': 'Jingwen He'
        },
        {
          'id': 1352196033,
          'name': 'Chao Dong'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 189468,
      'title': 'Learning Raw Image Denoising With Bayer Pattern Unification and Bayer Preserving Augmentation',
      'affiliation': [
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2103783453,
          'abbr': 'UMASS',
          'name': 'University of Massachusetts Amherst'
        },
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1067010091,
          'name': 'Jiaming Liu'
        },
        {
          'id': 1022615577,
          'name': 'Chi-Hao Wu'
        },
        {
          'id': 1027450744,
          'name': 'Yuzhi Wang'
        },
        {
          'id': 1000493023,
          'name': 'Qin Xu'
        },
        {
          'id': 1279612939,
          'name': 'Yuqian Zhou'
        },
        {
          'id': 1068168862,
          'name': 'Haibin Huang'
        },
        {
          'id': 1302418409,
          'name': 'Chuan Wang'
        },
        {
          'id': 1295479152,
          'name': 'Shaofan Cai'
        },
        {
          'id': 1160599532,
          'name': 'Yifan Ding'
        },
        {
          'id': 1270567884,
          'name': 'Haoqiang Fan'
        },
        {
          'id': 1038841241,
          'name': 'Jue Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2019347553,
          'name': 'Bayer filter'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014122647,
          'name': 'Unification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/NTIRE/Liu_Learning_Raw_Image_Denoising_With_Bayer_Pattern_Unification_and_Bayer_CVPRW_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 190460849,
      'title': 'Foreground-aware Image Inpainting.',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1106068593,
          'name': 'Wei Xiong'
        },
        {
          'id': 1406601254,
          'name': 'Jiahui Yu'
        },
        {
          'id': 1256462433,
          'name': 'Zhe Lin'
        },
        {
          'id': 1357326848,
          'name': 'Jimei Yang'
        },
        {
          'id': 1157414599,
          'name': 'Xin Lu'
        },
        {
          'id': 1232114296,
          'name': 'Connelly Barnes'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xiong_Foreground-Aware_Image_Inpainting_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 192054369,
      'title': 'Separate to Adapt: Open Set Domain Adaptation via Progressive Separation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1457733536,
          'name': 'Hong Liu'
        },
        {
          'id': 1126860916,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1192835994,
          'name': 'Qiang Yang'
        }
      ],
      'field': [
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Liu_Separate_to_Adapt_Open_Set_Domain_Adaptation_via_Progressive_Separation_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 19373595,
      'title': 'Graphonomy: Universal Human Parsing via Graph Transfer Learning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1045211755,
          'name': 'Ke Gong'
        },
        {
          'id': 1129414865,
          'name': 'Yiming Gao'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1498250219,
          'name': 'Meng Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gong_Graphonomy_Universal_Human_Parsing_via_Graph_Transfer_Learning_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 197435548,
      'title': 'Accurate 3D Face Reconstruction With Weakly-Supervised Learning: From Single Image to Image Set',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1031522269,
          'name': 'Yu Deng'
        },
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1413046430,
          'name': 'Sicheng Xu'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        },
        {
          'id': 1348430863,
          'name': 'Xin Tong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/AMFG/Deng_Accurate_3D_Face_Reconstruction_With_Weakly-Supervised_Learning_From_Single_Image_CVPRW_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 197500960,
      'title': 'Signal-To-Noise Ratio: A Robust Distance Metric for Deep Metric Learning',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100957234,
          'abbr': 'SU',
          'name': 'Syracuse University'
        }
      ],
      'author': [
        {
          'id': 1352496634,
          'name': 'Tongtong Yuan'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1228951288,
          'name': 'Jian Tang'
        },
        {
          'id': 1063365636,
          'name': 'Yinan Tang'
        },
        {
          'id': 1261227821,
          'name': 'Binghui Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2043447266,
          'name': 'Signal-to-noise ratio'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yuan_Signal-To-Noise_Ratio_A_Robust_Distance_Metric_for_Deep_Metric_Learning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 197697434,
      'title': 'Deep Multimodal Clustering for Unsupervised Audiovisual Learning.',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1088670356,
          'name': 'Di Hu'
        },
        {
          'id': 1410325846,
          'name': 'Feiping Nie'
        },
        {
          'id': 1496861582,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Hu_Deep_Multimodal_Clustering_for_Unsupervised_Audiovisual_Learning_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 200556544,
      'title': 'Spatiotemporal CNN for Video Object Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        }
      ],
      'author': [
        {
          'id': 1490961721,
          'name': 'Kai Xu'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1136073280,
          'name': 'Guorong Li'
        },
        {
          'id': 1389215474,
          'name': 'Liefeng Bo'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xu_Spatiotemporal_CNN_for_Video_Object_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 201754230,
      'title': 'GCAN: Graph Convolutional Adversarial Network for Unsupervised Domain Adaptation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1397008364,
          'name': 'Xinhong Ma'
        },
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Ma_GCAN_Graph_Convolutional_Adversarial_Network_for_Unsupervised_Domain_Adaptation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 202600633,
      'title': 'Deep Fitting Degree Scoring Network for Monocular 3D Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1276812152,
          'name': 'Lijie Liu'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1166168767,
          'name': 'Chunjing Xu'
        },
        {
          'id': 1238691319,
          'name': 'Qi Tian'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Deep_Fitting_Degree_Scoring_Network_for_Monocular_3D_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 207583904,
      'title': 'SeerNet: Predicting Convolutional Neural Network Feature-Map Sparsity Through Low-Bit Quantization',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1248940546,
          'name': 'Shijie Cao'
        },
        {
          'id': 1078349521,
          'name': 'Lingxiao Ma'
        },
        {
          'id': 1227378060,
          'name': 'Wencong Xiao'
        },
        {
          'id': 1288435519,
          'name': 'Chen Zhang'
        },
        {
          'id': 1272242091,
          'name': 'Yunxin Liu'
        },
        {
          'id': 1123032209,
          'name': 'Lintao Zhang'
        },
        {
          'id': 1200556757,
          'name': 'Lanshun Nie'
        },
        {
          'id': 1044893536,
          'name': 'Zhi Yang'
        }
      ],
      'field': [
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036412563,
          'name': 'Low bit'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Cao_SeerNet_Predicting_Convolutional_Neural_Network_Feature-Map_Sparsity_Through_Low-Bit_Quantization_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 209137731,
      'title': 'Learning to Film From Professional Human Motion Videos',
      'affiliation': [
        {
          'id': 2102092327,
          'abbr': 'UCSB',
          'name': 'University of California, Santa Barbara'
        },
        {
          'id': 2104487593,
          'abbr': 'ZJUT',
          'name': 'Zhejiang University of Technology'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1407743761,
          'name': 'Chong Huang'
        },
        {
          'id': 1472701570,
          'name': 'Chuan-En Lin'
        },
        {
          'id': 1289618578,
          'name': 'Zhenyu Yang'
        },
        {
          'id': 1121550609,
          'name': 'Yan Kong'
        },
        {
          'id': 1195514934,
          'name': 'Peng Chen'
        },
        {
          'id': 1061178820,
          'name': 'Xin Yang'
        },
        {
          'id': 1049038079,
          'name': 'Kwang-Ting Cheng'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Huang_Learning_to_Film_From_Professional_Human_Motion_Videos_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 211567754,
      'title': 'Beyond Tracking: Selecting Memory and Refining Poses for Deep Visual Odometry',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1332703827,
          'name': 'Fei Xue'
        },
        {
          'id': 1239688213,
          'name': 'Xin Wang'
        },
        {
          'id': 1452233123,
          'name': 'Shunkai Li'
        },
        {
          'id': 1113648885,
          'name': 'Qiuyuan Wang'
        },
        {
          'id': 1119059195,
          'name': 'Junqiu Wang'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000376905,
          'name': 'Visual odometry'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xue_Beyond_Tracking_Selecting_Memory_and_Refining_Poses_for_Deep_Visual_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 213122007,
      'title': 'UniformFace: Learning Deep Equidistributed Representation for Face Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1495536695,
          'name': 'Yueqi Duan'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2000723137,
          'name': 'Equidistributed sequence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Duan_UniformFace_Learning_Deep_Equidistributed_Representation_for_Face_Recognition_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 213676756,
      'title': 'Live Demonstration: Real-Time Vi-SLAM With High-Resolution Event Camera',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1363472208,
          'name': 'Gongyu Yang'
        },
        {
          'id': 1012649550,
          'name': 'Qilin Ye'
        },
        {
          'id': 1131114280,
          'name': 'Wanjun He'
        },
        {
          'id': 1012469625,
          'name': 'Lifeng Zhou'
        },
        {
          'id': 1188247672,
          'name': 'Xinyu Chen'
        },
        {
          'id': 1000852138,
          'name': 'Lei Yu'
        },
        {
          'id': 1376097180,
          'name': 'Wen Yang'
        },
        {
          'id': 1056896242,
          'name': 'Shoushun Chen'
        },
        {
          'id': 1000038819,
          'name': 'Wei Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/EventVision/Yang_Live_Demonstration_Real-Time_Vi-SLAM_With_High-Resolution_Event_Camera_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 21374900,
      'title': 'LO-Net: Deep Real-Time Lidar Odometry',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1095201947,
          'name': 'Qing Li'
        },
        {
          'id': 1388544634,
          'name': 'Shaoyang Chen'
        },
        {
          'id': 1081011056,
          'name': 'Cheng Wang'
        },
        {
          'id': 1177947989,
          'name': 'Xin Li'
        },
        {
          'id': 1089448233,
          'name': 'Chenglu Wen'
        },
        {
          'id': 1223148105,
          'name': 'Ming Cheng'
        },
        {
          'id': 1404025338,
          'name': 'Jonathan Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048206591,
          'name': 'Odometry'
        },
        {
          'id': 2016797546,
          'name': 'Lidar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_LO-Net_Deep_Real-Time_Lidar_Odometry_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 214197526,
      'title': 'SOSNet: Second Order Similarity Regularization for Local Descriptor Learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2100984406,
          'abbr': 'IMPERIAL',
          'name': 'Imperial College London'
        }
      ],
      'author': [
        {
          'id': 1363329326,
          'name': 'Yurun Tian'
        },
        {
          'id': 1369864653,
          'name': 'Xin Yu'
        },
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1451401325,
          'name': 'Fuchao Wu'
        },
        {
          'id': 1222324383,
          'name': 'Huub Heijnen'
        },
        {
          'id': 1378529005,
          'name': 'Vassileios Balntas'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Tian_SOSNet_Second_Order_Similarity_Regularization_for_Local_Descriptor_Learning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 214514425,
      'title': 'Pattern-Affinitive Propagation Across Depth, Surface Normal and Semantic Segmentation',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2104935093,
          'abbr': 'UNITN',
          'name': 'University of Trento'
        }
      ],
      'author': [
        {
          'id': 1323385679,
          'name': 'Zhenyu Zhang'
        },
        {
          'id': 1249663582,
          'name': 'Zhen Cui'
        },
        {
          'id': 1290256705,
          'name': 'Chunyan Xu'
        },
        {
          'id': 1104476380,
          'name': 'Yan Yan'
        },
        {
          'id': 1304196418,
          'name': 'Nicu Sebe'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002018009,
          'name': 'Normal'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Pattern-Affinitive_Propagation_Across_Depth_Surface_Normal_and_Semantic_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 214679593,
      'title': 'Face Anti-Spoofing: Model Matters, so Does Data',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2100931896,
          'abbr': 'UF',
          'name': 'University of Florida'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1002335589,
          'name': 'Xiao Yang'
        },
        {
          'id': 1176702019,
          'name': 'Wenhan Luo'
        },
        {
          'id': 1369921054,
          'name': 'Linchao Bao'
        },
        {
          'id': 1004813023,
          'name': 'Yuan Gao'
        },
        {
          'id': 1232229134,
          'name': 'Dihong Gong'
        },
        {
          'id': 1034443774,
          'name': 'Shibao Zheng'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028022761,
          'name': 'Spoofing attack'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_Face_Anti-Spoofing_Model_Matters_so_Does_Data_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 215329092,
      'title': 'NM-Net: Mining Reliable Neighbors for Robust Feature Correspondences.',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1151927673,
          'name': 'Chen Zhao'
        },
        {
          'id': 1216320010,
          'name': 'Zhiguo Cao'
        },
        {
          'id': 1050150809,
          'name': 'Chi Li'
        },
        {
          'id': 1001380397,
          'name': 'Xin Li'
        },
        {
          'id': 1158768137,
          'name': 'Jiaqi Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/abs/1904.00320',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 221406369,
      'title': 'Cascaded Generative and Discriminative Learning for Microcalcification Detection in Breast Mammograms',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1414696875,
          'name': 'Fandong Zhang'
        },
        {
          'id': 1031501911,
          'name': 'Ling Luo'
        },
        {
          'id': 1475709902,
          'name': 'Xinwei Sun'
        },
        {
          'id': 1005303976,
          'name': 'Zhen Zhou'
        },
        {
          'id': 1216580622,
          'name': 'Xiuli Li'
        },
        {
          'id': 1017160858,
          'name': 'Yizhou Yu'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        }
      ],
      'field': [
        {
          'id': 2005136076,
          'name': 'Microcalcification'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2001392051,
          'name': 'Discriminative learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhang_Cascaded_Generative_and_Discriminative_Learning_for_Microcalcification_Detection_in_Breast_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 222770094,
      'title': 'Learning Generalizable Final-State Dynamics of 3D Rigid Objects',
      'affiliation': [
        {
          'id': 2102440343,
          'abbr': 'MPG',
          'name': 'Max Planck Society'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1047484022,
          'name': 'Davis Rempe'
        },
        {
          'id': 1047225030,
          'name': 'Srinath Sridhar'
        },
        {
          'id': 1024604554,
          'name': 'He Wang'
        },
        {
          'id': 1471272024,
          'name': 'Leonidas J. Guibas'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/Vision_Meets_Cognition_Camera_Ready/Rempe_Learning_Generalizable_Final-State_Dynamics_of_3D_Rigid_Objects_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 223453843,
      'title': 'Exploring Context and Visual Pattern of Relationship for Scene Graph Generation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1002061106,
          'name': 'Wenbin Wang'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023497584,
          'name': 'Scene graph'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Exploring_Context_and_Visual_Pattern_of_Relationship_for_Scene_Graph_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 224430644,
      'title': 'An Attention Enhanced Graph Convolutional LSTM Network for Skeleton-Based Action Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1102824081,
          'name': 'Chenyang Si'
        },
        {
          'id': 1171661384,
          'name': 'Wentao Chen'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Si_An_Attention_Enhanced_Graph_Convolutional_LSTM_Network_for_Skeleton-Based_Action_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 224587991,
      'title': 'AdaCos: Adaptively Scaling Cosine Logits for Effectively Learning Deep Face Representations',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1001120881,
          'name': 'Xiao Zhang'
        },
        {
          'id': 1413621210,
          'name': 'Rui Zhao'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037635684,
          'name': 'Scaling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008670243,
          'name': 'Trigonometric functions'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_AdaCos_Adaptively_Scaling_Cosine_Logits_for_Effectively_Learning_Deep_Face_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 22494617,
      'title': 'Domain-Symmetric Networks for Adversarial Domain Adaptation',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1116195762,
          'name': 'Yabin Zhang'
        },
        {
          'id': 1133121266,
          'name': 'Hui Tang'
        },
        {
          'id': 1477244207,
          'name': 'Kui Jia'
        },
        {
          'id': 1121483151,
          'name': 'Mingkui Tan'
        }
      ],
      'field': [
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Domain-Symmetric_Networks_for_Adversarial_Domain_Adaptation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 227466194,
      'title': '6D-VNet: End-to-End 6-DoF Vehicle Pose Estimation From Monocular RGB Images',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103397249,
          'abbr': 'SZU',
          'name': 'Shenzhen University'
        }
      ],
      'author': [
        {
          'id': 1413996244,
          'name': 'Di Wu'
        },
        {
          'id': 1136742183,
          'name': 'Zhaoyong Zhuang'
        },
        {
          'id': 1350583656,
          'name': 'Canqun Xiang'
        },
        {
          'id': 1215176656,
          'name': 'Wenbin Zou'
        },
        {
          'id': 1134807283,
          'name': 'Xia Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/Autonomous Driving/Wu_6D-VNet_End-to-End_6-DoF_Vehicle_Pose_Estimation_From_Monocular_RGB_Images_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 227594614,
      'title': 'A Late Fusion CNN for Digital Matting',
      'affiliation': [
        {
          'id': 2102730661,
          'abbr': 'HZNU',
          'name': 'Hangzhou Normal University'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1201502469,
          'name': 'Yunke Zhang'
        },
        {
          'id': 1136486422,
          'name': 'Lixue Gong'
        },
        {
          'id': 1266043137,
          'name': 'Lubin Fan'
        },
        {
          'id': 1072138155,
          'name': 'Peiran Ren'
        },
        {
          'id': 1100918936,
          'name': 'Qixing Huang'
        },
        {
          'id': 1445642419,
          'name': 'Hujun Bao'
        },
        {
          'id': 1488653740,
          'name': 'Weiwei Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_A_Late_Fusion_CNN_for_Digital_Matting_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 231490609,
      'title': 'Efficient Learning Based Sub-pixel Image Compression',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1147674907,
          'name': 'Chunlei Cai'
        },
        {
          'id': 1438739039,
          'name': 'Guo Lu'
        },
        {
          'id': 1030065938,
          'name': 'Qiang Hu'
        },
        {
          'id': 1396467771,
          'name': 'Li Chen'
        },
        {
          'id': 1278281228,
          'name': 'Zhiyong Gao'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CLIC 2019/Cai_Efficient_Learning_Based_Sub-pixel_Image_Compression_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 231744924,
      'title': 'Deep Graph Laplacian Regularization for Robust Denoising of Real Images',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1293520122,
          'name': 'Jin Zeng'
        },
        {
          'id': 1217660585,
          'name': 'Jiahao Pang'
        },
        {
          'id': 1482384528,
          'name': 'Wenxiu Sun'
        },
        {
          'id': 1412709434,
          'name': 'Gene Cheung'
        }
      ],
      'field': [
        {
          'id': 2034388435,
          'name': 'Laplacian matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/NTIRE/Zeng_Deep_Graph_Laplacian_Regularization_for_Robust_Denoising_of_Real_Images_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 232051989,
      'title': 'Joint Representation and Estimator Learning for Facial Action Unit Intensity Estimation',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1114571624,
          'name': 'Yong Zhang'
        },
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1442505283,
          'name': 'Weiming Dong'
        },
        {
          'id': 1126291268,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        },
        {
          'id': 1341931364,
          'name': 'Bao-Gang Hu'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014430643,
          'name': 'Estimator'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Joint_Representation_and_Estimator_Learning_for_Facial_Action_Unit_Intensity_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 23565199,
      'title': 'Polynomial Representation for Persistence Diagram',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103850856,
          'abbr': 'DEAKIN',
          'name': 'Deakin University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1472793682,
          'name': 'Zhichao Wang'
        },
        {
          'id': 1113912249,
          'name': 'Qian Li'
        },
        {
          'id': 1181424035,
          'name': 'Gang Li'
        },
        {
          'id': 1248520069,
          'name': 'Guandong Xu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037707526,
          'name': 'Persistence (computer science)'
        },
        {
          'id': 2047497056,
          'name': 'Algebra'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012403556,
          'name': 'Diagram'
        },
        {
          'id': 2026815810,
          'name': 'Polynomial'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Polynomial_Representation_for_Persistence_Diagram_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 238381505,
      'title': 'A Neurorobotic Experiment for Crossmodal Conflict Resolution',
      'affiliation': [
        {
          'id': 2102907018,
          'abbr': 'UH',
          'name': 'University of Hamburg'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1036317556,
          'name': 'German Ignacio Parisi'
        },
        {
          'id': 1092957504,
          'name': 'Pablo V. A. Barros'
        },
        {
          'id': 1161531739,
          'name': 'Di Fu'
        },
        {
          'id': 1025166589,
          'name': 'Sven Magg'
        },
        {
          'id': 1008343052,
          'name': 'Haiyan Wu'
        },
        {
          'id': 1048059091,
          'name': 'Xun Liu'
        },
        {
          'id': 1053511388,
          'name': 'Stefan Wermter'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027218380,
          'name': 'Conflict resolution'
        },
        {
          'id': 2020753514,
          'name': 'Crossmodal'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016896741,
          'name': 'Cognitive science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/Sight and Sound/German_Parisi_A_Neurorobotic_Experiment_for_Crossmodal_Conflict_Resolution_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 243645569,
      'title': 'AdaptiveFace: Adaptive Margin and Sampling for Face Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1226812050,
          'name': 'Hao Liu'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1420503791,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Liu_AdaptiveFace_Adaptive_Margin_and_Sampling_for_Face_Recognition_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 24384815,
      'title': 'Circulant Binary Convolutional Networks: Enhancing the Performance of 1-Bit DCNNs With Circulant Back Propagation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2103578022,
          'abbr': 'UB',
          'name': 'University at Buffalo'
        }
      ],
      'author': [
        {
          'id': 1298198486,
          'name': 'Chunlei Liu'
        },
        {
          'id': 1359962388,
          'name': 'Wenrui Ding'
        },
        {
          'id': 1108563708,
          'name': 'Xin Xia'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1312760378,
          'name': 'Jiaxin Gu'
        },
        {
          'id': 1381503284,
          'name': 'Jianzhuang Liu'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1216852570,
          'name': 'David S. Doermann'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2014799960,
          'name': 'Circulant matrix'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Circulant_Binary_Convolutional_Networks_Enhancing_the_Performance_of_1-Bit_DCNNs_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 244337829,
      'title': 'Enhanced Bayesian Compression via Deep Reinforcement Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1068878246,
          'name': 'Xin Yuan'
        },
        {
          'id': 1219558905,
          'name': 'Liangliang Ren'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1085154187,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Yuan_Enhanced_Bayesian_Compression_via_Deep_Reinforcement_Learning_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 244371423,
      'title': 'Cascaded Partial Decoder for Fast and Accurate Salient Object Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1409809318,
          'name': 'Zhe Wu'
        },
        {
          'id': 1004936751,
          'name': 'Li Su'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wu_Cascaded_Partial_Decoder_for_Fast_and_Accurate_Salient_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 245066353,
      'title': 'Patch-Based Discriminative Feature Learning for Unsupervised Person Re-Identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1276515679,
          'name': 'Qize Yang'
        },
        {
          'id': 1046260562,
          'name': 'Hong-Xing Yu'
        },
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_Patch-Based_Discriminative_Feature_Learning_for_Unsupervised_Person_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 245235063,
      'title': 'DynTypo: Example-Based Dynamic Text Effects Transfer',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1243401505,
          'name': 'Yifang Men'
        },
        {
          'id': 1144931875,
          'name': 'Zhouhui Lian'
        },
        {
          'id': 1380821707,
          'name': 'Yingmin Tang'
        },
        {
          'id': 1135263000,
          'name': 'Jianguo Xiao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Men_DynTypo_Example-Based_Dynamic_Text_Effects_Transfer_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 245761513,
      'title': 'Detection Based Defense Against Adversarial Examples From the Steganalysis Point of View',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1450940429,
          'name': 'Jiayang Liu'
        },
        {
          'id': 1109145351,
          'name': 'Weiming Zhang'
        },
        {
          'id': 1035693726,
          'name': 'Yiwei Zhang'
        },
        {
          'id': 1389242914,
          'name': 'Dongdong Hou'
        },
        {
          'id': 1246813939,
          'name': 'Yujia Liu'
        },
        {
          'id': 1062043020,
          'name': 'Hongyue Zha'
        },
        {
          'id': 1484616702,
          'name': 'Nenghai Yu'
        }
      ],
      'field': [
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2027711440,
          'name': 'Steganalysis'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036439149,
          'name': 'Adversary'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/abs/1806.09186',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 247698372,
      'title': 'Blind Super-Resolution With Iterative Kernel Correction',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100355666,
          'abbr': 'TUM',
          'name': 'Technische Universität München'
        }
      ],
      'author': [
        {
          'id': 1023317089,
          'name': 'Jinjin Gu'
        },
        {
          'id': 1020904788,
          'name': 'Hannan Lu'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1352196033,
          'name': 'Chao Dong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gu_Blind_Super-Resolution_With_Iterative_Kernel_Correction_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 248673452,
      'title': 'Density Map Regression Guided Detection Network for RGB-D Crowd Counting and Localization',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1406842602,
          'name': 'Dongze Lian'
        },
        {
          'id': 1002249247,
          'name': 'Jing Li'
        },
        {
          'id': 1061826179,
          'name': 'Jia Zheng'
        },
        {
          'id': 1252850696,
          'name': 'Weixin Luo'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042991472,
          'name': 'Map regression'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Lian_Density_Map_Regression_Guided_Detection_Network_for_RGB-D_Crowd_Counting_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 249393438,
      'title': 'Deep Global Generalized Gaussian Networks',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1484711233,
          'name': 'Qilong Wang'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1180699841,
          'name': 'Qinghua Hu'
        },
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wang_Deep_Global_Generalized_Gaussian_Networks_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 250122858,
      'title': 'SR-LSTM: State Refinement for LSTM Towards Pedestrian Trajectory Prediction',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1356574178,
          'name': 'Pu Zhang'
        },
        {
          'id': 1304514976,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1172150847,
          'name': 'Pengfei Zhang'
        },
        {
          'id': 1221299331,
          'name': 'Jianru Xue'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_SR-LSTM_State_Refinement_for_LSTM_Towards_Pedestrian_Trajectory_Prediction_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 254049270,
      'title': 'Re-Identification Supervised Texture Generation.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1339461696,
          'name': 'Jian Wang'
        },
        {
          'id': 1436090476,
          'name': 'Yunshan Zhong'
        },
        {
          'id': 1352463649,
          'name': 'Yachun Li'
        },
        {
          'id': 1001166615,
          'name': 'Chi Zhang'
        },
        {
          'id': 1027153220,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 254090391,
      'title': 'Topology Reconstruction of Tree-Like Structure in Images via Structural Similarity Measure and Dominant Set Clustering',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101059749,
          'abbr': 'EDGEHILL',
          'name': 'Edge Hill University'
        },
        {
          'id': 2103747295,
          'abbr': 'NCEPU',
          'name': 'North China Electric Power University'
        },
        {
          'id': 2104203505,
          'abbr': 'CRANFIELD',
          'name': 'Cranfield University'
        },
        {
          'id': 2101989839,
          'abbr': 'LIV',
          'name': 'University of Liverpool'
        }
      ],
      'author': [
        {
          'id': 1217500164,
          'name': 'Jianyang Xie'
        },
        {
          'id': 1038283038,
          'name': 'Yitian Zhao'
        },
        {
          'id': 1249386276,
          'name': 'Yonghuai Liu'
        },
        {
          'id': 1046011917,
          'name': 'Pan Su'
        },
        {
          'id': 1000616877,
          'name': 'Yifan Zhao'
        },
        {
          'id': 1334904699,
          'name': 'Jun Cheng'
        },
        {
          'id': 1094834257,
          'name': 'Yalin Zheng'
        },
        {
          'id': 1145212981,
          'name': 'Jiang Liu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xie_Topology_Reconstruction_of_Tree-Like_Structure_in_Images_via_Structural_Similarity_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 254393422,
      'title': 'A Skeleton-Bridged Deep Learning Approach for Generating Meshes of Complex Topologies From Single RGB Images',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1123302549,
          'name': 'Jiapeng Tang'
        },
        {
          'id': 1076070771,
          'name': 'Xiaoguang Han'
        },
        {
          'id': 1313335656,
          'name': 'Junyi Pan'
        },
        {
          'id': 1477244207,
          'name': 'Kui Jia'
        },
        {
          'id': 1348430863,
          'name': 'Xin Tong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2026817921,
          'name': 'Network topology'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Tang_A_Skeleton-Bridged_Deep_Learning_Approach_for_Generating_Meshes_of_Complex_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 254403061,
      'title': 'Towards Natural and Accurate Future Motion Prediction of Humans and Animals',
      'affiliation': [
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1344097753,
          'name': 'Zhenguang Liu'
        },
        {
          'id': 1009664889,
          'name': 'Shuang Wu'
        },
        {
          'id': 1025745161,
          'name': 'Shuyuan Jin'
        },
        {
          'id': 1013188317,
          'name': 'Qi Liu'
        },
        {
          'id': 1169334293,
          'name': 'Shijian Lu'
        },
        {
          'id': 1158276306,
          'name': 'Roger Zimmermann'
        },
        {
          'id': 1422052911,
          'name': 'Li Cheng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Towards_Natural_and_Accurate_Future_Motion_Prediction_of_Humans_and_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 255944231,
      'title': 'Two-Stream Adaptive Graph Convolutional Networks for Skeleton-Based Action Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1009757061,
          'name': 'Lei Shi'
        },
        {
          'id': 1011431260,
          'name': 'Yifan Zhang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Shi_Two-Stream_Adaptive_Graph_Convolutional_Networks_for_Skeleton-Based_Action_Recognition_CVPR_2019_paper.html',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 256873552,
      'title': 'Multi-Level Context Ultra-Aggregation for Stereo Matching',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1425025718,
          'name': 'Guang-Yu Nie'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1189823451,
          'name': 'Yun Liu'
        },
        {
          'id': 1012090004,
          'name': 'Zhengfa Liang'
        },
        {
          'id': 1235240356,
          'name': 'Deng-Ping Fan'
        },
        {
          'id': 1013915283,
          'name': 'Yue Liu'
        },
        {
          'id': 1129052117,
          'name': 'Yongtian Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Nie_Multi-Level_Context_Ultra-Aggregation_for_Stereo_Matching_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 259148376,
      'title': 'Single Image Deraining: A Comprehensive Benchmark Analysis',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100577709,
          'abbr': 'TAMU',
          'name': 'Texas A&M University'
        },
        {
          'id': 2100693134,
          'abbr': 'USP',
          'name': 'University of São Paulo'
        }
      ],
      'author': [
        {
          'id': 1147653141,
          'name': 'Siyuan Li'
        },
        {
          'id': 1310738100,
          'name': 'Iago Breno Araujo'
        },
        {
          'id': 1224361079,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1474290517,
          'name': 'Zhangyang Wang'
        },
        {
          'id': 1220695830,
          'name': 'Eric K. Tokuda'
        },
        {
          'id': 1244565905,
          'name': 'Roberto Hirata Junior'
        },
        {
          'id': 1229558212,
          'name': 'Roberto Cesar-Junior'
        },
        {
          'id': 1036621052,
          'name': 'Jiawan Zhang'
        },
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Single_Image_Deraining_A_Comprehensive_Benchmark_Analysis_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 259182150,
      'title': 'CRAVES: Controlling Robotic Arm With a Vision-Based Economic System',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1026247555,
          'name': 'Yiming Zuo'
        },
        {
          'id': 1393738390,
          'name': 'Weichao Qiu'
        },
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1328164841,
          'name': 'Fangwei Zhong'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023960143,
          'name': 'Robotic arm'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zuo_CRAVES_Controlling_Robotic_Arm_With_a_Vision-Based_Economic_System_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 259267810,
      'title': 'Cross-Stream Selective Networks for Action Recognition',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1026595821,
          'name': 'Bowen Pan'
        },
        {
          'id': 1051166250,
          'name': 'Jiankai Sun'
        },
        {
          'id': 1075170650,
          'name': 'Wuwei Lin'
        },
        {
          'id': 1003894377,
          'name': 'Limin Wang'
        },
        {
          'id': 1152911756,
          'name': 'Weiyao Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/MULA/Pan_Cross-Stream_Selective_Networks_for_Action_Recognition_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 259675647,
      'title': 'Self-Supervised Convolutional Subspace Clustering Network',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1053904421,
          'name': 'Junjian Zhang'
        },
        {
          'id': 1067719877,
          'name': 'Chun-Guang Li'
        },
        {
          'id': 1005565804,
          'name': 'Chong You'
        },
        {
          'id': 1354020470,
          'name': 'Xianbiao Qi'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Self-Supervised_Convolutional_Subspace_Clustering_Network_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 261475746,
      'title': 'Compact Feature Learning for Multi-Domain Image Classification',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1342575784,
          'name': 'Yajing Liu'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1427454096,
          'name': 'Ya Li'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Liu_Compact_Feature_Learning_for_Multi-Domain_Image_Classification_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 263316724,
      'title': '3D Shape Reconstruction From Images in the Frequency Domain',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1398933002,
          'name': 'Weichao Shen'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        },
        {
          'id': 1134499897,
          'name': 'Yuwei Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039512801,
          'name': 'Frequency domain'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Shen_3D_Shape_Reconstruction_From_Images_in_the_Frequency_Domain_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 263566223,
      'title': 'MirrorGAN: Learning Text-to-image Generation by Redescription.',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1494988553,
          'name': 'Tingting Qiao'
        },
        {
          'id': 1341012480,
          'name': 'Jing Zhang'
        },
        {
          'id': 1295080973,
          'name': 'Duanqing Xu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Qiao_MirrorGAN_Learning_Text-To-Image_Generation_by_Redescription_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 263704089,
      'title': 'iSAID: A Large-scale Dataset for Instance Segmentation in Aerial Images',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1418297116,
          'name': 'Syed Waqas Zamir'
        },
        {
          'id': 1085578975,
          'name': 'Aditya Arora'
        },
        {
          'id': 1366866052,
          'name': 'Akshita Gupta'
        },
        {
          'id': 1076674290,
          'name': 'Salman Khan'
        },
        {
          'id': 1206361786,
          'name': 'Guolei Sun'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1057433103,
          'name': 'Fan Zhu'
        },
        {
          'id': 1122227807,
          'name': 'Ling Shao'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/DOAI/Zamir_iSAID_A_Large-scale_Dataset_for_Instance_Segmentation_in_Aerial_Images_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 266115395,
      'title': 'Visual Query Answering by Entity-Attribute Graph Matching and Reasoning',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103853126,
          'abbr': 'BWH',
          'name': 'Brigham and Women\'s Hospital'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1190799160,
          'name': 'Peixi Xiong'
        },
        {
          'id': 1095486791,
          'name': 'Huayi Zhan'
        },
        {
          'id': 1382619239,
          'name': 'Xin Wang'
        },
        {
          'id': 1469592598,
          'name': 'Baivab Sinha'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xiong_Visual_Query_Answering_by_Entity-Attribute_Graph_Matching_and_Reasoning_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 268945187,
      'title': 'KE-GAN: Knowledge Embedded Generative Adversarial Networks for Semi-Supervised Scene Parsing',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        }
      ],
      'author': [
        {
          'id': 1246457806,
          'name': 'Mengshi Qi'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1478991474,
          'name': 'Jie Qin'
        },
        {
          'id': 1158763441,
          'name': 'Annan Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Qi_KE-GAN_Knowledge_Embedded_Generative_Adversarial_Networks_for_Semi-Supervised_Scene_Parsing_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 268956156,
      'title': 'Self-critical n-step Training for Image Captioning.',
      'affiliation': [
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1228808865,
          'name': 'Junlong Gao'
        },
        {
          'id': 1397265918,
          'name': 'Shiqi Wang'
        },
        {
          'id': 1408181882,
          'name': 'Shanshe Wang'
        },
        {
          'id': 1364235909,
          'name': 'Siwei Ma'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gao_Self-Critical_N-Step_Training_for_Image_Captioning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 27055889,
      'title': 'Triangulation Learning Network: from Monocular to Stereo 3D Object Detection.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1130593979,
          'name': 'Zengyi Qin'
        },
        {
          'id': 1008040187,
          'name': 'Jinglu Wang'
        },
        {
          'id': 1060816602,
          'name': 'Yan Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2000198985,
          'name': 'Triangulation (social science)'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Qin_Triangulation_Learning_Network_From_Monocular_to_Stereo_3D_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 272199615,
      'title': 'Single Image Reflection Removal Beyond Linearity',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1454565873,
          'name': 'Qiang Wen'
        },
        {
          'id': 1493960969,
          'name': 'Yinjie Tan'
        },
        {
          'id': 1014874264,
          'name': 'Jing Qin'
        },
        {
          'id': 1169502804,
          'name': 'Wenxi Liu'
        },
        {
          'id': 1402700358,
          'name': 'Guoqiang Han'
        },
        {
          'id': 1301454973,
          'name': 'Shengfeng He'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020879486,
          'name': 'Linearity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wen_Single_Image_Reflection_Removal_Beyond_Linearity_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 273464421,
      'title': 'Salient Object Detection With Pyramid Attention and Salient Edges',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2101195408,
          'abbr': 'SMU',
          'name': 'Singapore Management University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1025396997,
          'name': 'Shuyang Zhao'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1433462722,
          'name': 'Steven C. H. Hoi'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wang_Salient_Object_Detection_With_Pyramid_Attention_and_Salient_Edges_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 274509062,
      'title': 'NM-Net: Mining Reliable Neighbors for Robust Feature Correspondences.',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102522667,
          'abbr': 'WVU',
          'name': 'West Virginia University'
        }
      ],
      'author': [
        {
          'id': 1151927673,
          'name': 'Chen Zhao'
        },
        {
          'id': 1216320010,
          'name': 'Zhiguo Cao'
        },
        {
          'id': 1050150809,
          'name': 'Chi Li'
        },
        {
          'id': 1173434986,
          'name': 'Xin Li'
        },
        {
          'id': 1158768137,
          'name': 'Jiaqi Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhao_NM-Net_Mining_Reliable_Neighbors_for_Robust_Feature_Correspondences_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 279739330,
      'title': 'Versatile Multiple Choice Learning and Its Application to Vision Computing.',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        }
      ],
      'author': [
        {
          'id': 1124462619,
          'name': 'Kai Tian'
        },
        {
          'id': 1005071462,
          'name': 'Yi Xu'
        },
        {
          'id': 1494078016,
          'name': 'Shuigeng Zhou'
        },
        {
          'id': 1363593318,
          'name': 'Jihong Guan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025106967,
          'name': 'Multiple choice'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 279780480,
      'title': 'Vehicle Re-Identification with Location and Time Stamps',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1287013358,
          'name': 'Kai Lv'
        },
        {
          'id': 1025476914,
          'name': 'Heming Du'
        },
        {
          'id': 1445765359,
          'name': 'Yunzhong Hou'
        },
        {
          'id': 1163501203,
          'name': 'Weijian Deng'
        },
        {
          'id': 1352251956,
          'name': 'Hao Sheng'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/AI City/Lv_Vehicle_Re-Identification_with_Location_and_Time_Stamps_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 28005626,
      'title': 'Deep Spectral Clustering using Dual Autoencoder Network.',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1004368789,
          'name': 'Xu Yang'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1082380717,
          'name': 'Feng Zheng'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1355615246,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_Deep_Spectral_Clustering_Using_Dual_Autoencoder_Network_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 28127585,
      'title': 'Multi-Scale Weighted Branch Network for Remote Sensing Image Classification',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1327989364,
          'name': 'Kunping Yang'
        },
        {
          'id': 1123398440,
          'name': 'Zicheng Liu'
        },
        {
          'id': 1064350286,
          'name': 'Qikai Lu'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        }
      ],
      'field': [
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/DOAI/Yang_Multi-Scale_Weighted_Branch_Network_for_Remote_Sensing_Image_Classification_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 281962150,
      'title': 'Feature Hourglass Network for Skeleton Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1010983264,
          'name': 'Nan Jiang'
        },
        {
          'id': 1008740152,
          'name': 'Yifei Zhang'
        },
        {
          'id': 1130792590,
          'name': 'Dezhao Luo'
        },
        {
          'id': 1347623422,
          'name': 'Chang Liu'
        },
        {
          'id': 1126538548,
          'name': 'Yu Zhou'
        },
        {
          'id': 1470484219,
          'name': 'Zhenjun Han'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2044048149,
          'name': 'Hourglass'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/SkelNetOn/Jiang_Feature_Hourglass_Network_for_Skeleton_Detection_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 283016660,
      'title': 'Hyperspectral Imaging With Random Printed Mask',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1310656003,
          'name': 'Yuanyuan Zhao'
        },
        {
          'id': 1003518647,
          'name': 'Hui Guo'
        },
        {
          'id': 1049262418,
          'name': 'Zhan Ma'
        },
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        },
        {
          'id': 1019303489,
          'name': 'Tao Yue'
        },
        {
          'id': 1026626024,
          'name': 'Xuemei Hu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhao_Hyperspectral_Imaging_With_Random_Printed_Mask_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 28710323,
      'title': 'Shape Robust Text Detection With Progressive Scale Expansion Network',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1022129838,
          'name': 'Wenhai Wang'
        },
        {
          'id': 1172666805,
          'name': 'Enze Xie'
        },
        {
          'id': 1204869756,
          'name': 'Xiang Li'
        },
        {
          'id': 1207722540,
          'name': 'Wenbo Hou'
        },
        {
          'id': 1149898936,
          'name': 'Tong Lu'
        },
        {
          'id': 1416154934,
          'name': 'Gang Yu'
        },
        {
          'id': 1143985982,
          'name': 'Shuai Shao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wang_Shape_Robust_Text_Detection_With_Progressive_Scale_Expansion_Network_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 289559661,
      'title': 'FOCNet: A Fractional Optimal Control Network for Image Denoising',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        }
      ],
      'author': [
        {
          'id': 1340918900,
          'name': 'Xixi Jia'
        },
        {
          'id': 1009813511,
          'name': 'Sanyang Liu'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        },
        {
          'id': 1428956362,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2006011408,
          'name': 'Optimal control'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Jia_FOCNet_A_Fractional_Optimal_Control_Network_for_Image_Denoising_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 290242043,
      'title': 'Object-Aware Aggregation With Bidirectional Temporal Graph for Video Captioning',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1462426421,
          'name': 'Junchao Zhang'
        },
        {
          'id': 1031131117,
          'name': 'Yuxin Peng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/abs/1906.04375v1',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 290703264,
      'title': 'Box-Driven Class-Wise Region Masking and Filling Rate Guided Loss for Weakly Supervised Semantic Segmentation',
      'affiliation': [
        {
          'id': 2103747295,
          'abbr': 'NCEPU',
          'name': 'North China Electric Power University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1211719879,
          'name': 'Chunfeng Song'
        },
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003673557,
          'name': 'Masking (art)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Song_Box-Driven_Class-Wise_Region_Masking_and_Filling_Rate_Guided_Loss_for_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 291986741,
      'title': 'Dual Encoding for Zero-Example Video Retrieval',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101081544,
          'abbr': 'RUC',
          'name': 'Renmin University of China'
        },
        {
          'id': 2102062912,
          'abbr': 'SWUST',
          'name': 'Southwest University of Science and Technology'
        },
        {
          'id': 2104229167,
          'abbr': 'ZJSU',
          'name': 'Zhejiang Gongshang University'
        }
      ],
      'author': [
        {
          'id': 1015147915,
          'name': 'Jianfeng Dong'
        },
        {
          'id': 1380220214,
          'name': 'Xirong Li'
        },
        {
          'id': 1236938512,
          'name': 'Chaoxi Xu'
        },
        {
          'id': 1225849496,
          'name': 'Shouling Ji'
        },
        {
          'id': 1015279094,
          'name': 'Yuan He'
        },
        {
          'id': 1449725856,
          'name': 'Gang Yang'
        },
        {
          'id': 1440623134,
          'name': 'Xun Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Dong_Dual_Encoding_for_Zero-Example_Video_Retrieval_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 292845663,
      'title': 'Self-Supervised Representation Learning From Videos for Facial Action Unit Detection',
      'affiliation': [
        {
          'id': 2104905128,
          'abbr': 'USTB',
          'name': 'University of Science and Technology Beijing'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1428559066,
          'name': 'Yong Li'
        },
        {
          'id': 1162783045,
          'name': 'Jiabei Zeng'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_Self-Supervised_Representation_Learning_From_Videos_for_Facial_Action_Unit_Detection_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 293609028,
      'title': 'Suppressing Model Overfitting for Image Super-Resolution Networks.',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1092147948,
          'name': 'Ruicheng Feng'
        },
        {
          'id': 1023317089,
          'name': 'Jinjin Gu'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1130918340,
          'name': 'Chao Dong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/NTIRE/Feng_Suppressing_Model_Overfitting_for_Image_Super-Resolution_Networks_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 296741525,
      'title': 'Composition-Regularized Near-Duplicate Vehicle Re-Identification',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1038786610,
          'name': 'Bing He'
        },
        {
          'id': 1149258320,
          'name': 'Jia Li'
        },
        {
          'id': 1184640346,
          'name': 'Yifan Zhao'
        },
        {
          'id': 1175008959,
          'name': 'Yonghong Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/He_Part-Regularized_Near-Duplicate_Vehicle_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 298010791,
      'title': 'Towards Accurate One-Stage Object Detection With AP-Loss',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100691916,
          'abbr': 'MMU',
          'name': 'Multimedia University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1020994684,
          'name': 'Kean Chen'
        },
        {
          'id': 1002688712,
          'name': 'Jianguo Li'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1331147109,
          'name': 'John See'
        },
        {
          'id': 1029347789,
          'name': 'Ji Wang'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1010211628,
          'name': 'Zhibo Chen'
        },
        {
          'id': 1195228165,
          'name': 'Changwei He'
        },
        {
          'id': 1066568517,
          'name': 'Junni Zou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_Towards_Accurate_One-Stage_Object_Detection_With_AP-Loss_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 298964165,
      'title': 'Weakly Supervised Image Classification Through Noise Regularization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1089994242,
          'name': 'Mengying Hu'
        },
        {
          'id': 1217280926,
          'name': 'Hu Han'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Hu_Weakly_Supervised_Image_Classification_Through_Noise_Regularization_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 299557438,
      'title': 'Probabilistic End-To-End Noise Correction for Learning With Noisy Labels',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1278753565,
          'name': 'Kun Yi'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        }
      ],
      'field': [
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yi_Probabilistic_End-To-End_Noise_Correction_for_Learning_With_Noisy_Labels_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 299864305,
      'title': 'Synthesizing 3D Shapes from Silhouette Image Collections using Multi-projection Generative Adversarial Networks.',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101928421,
          'abbr': 'W&M',
          'name': 'College of William & Mary'
        }
      ],
      'author': [
        {
          'id': 1155013370,
          'name': 'Xiao Li'
        },
        {
          'id': 1100002116,
          'name': 'Yue Dong'
        },
        {
          'id': 1443866937,
          'name': 'Pieter Peers'
        },
        {
          'id': 1348430863,
          'name': 'Xin Tong'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038172366,
          'name': 'Silhouette'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Synthesizing_3D_Shapes_From_Silhouette_Image_Collections_Using_Multi-Projection_Generative_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 30017751,
      'title': 'Mutual Learning of Complementary Networks via Residual Correction for Improving Semi-Supervised Classification',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1144281894,
          'name': 'Si Wu'
        },
        {
          'id': 1347060866,
          'name': 'Jichang Li'
        },
        {
          'id': 1385168827,
          'name': 'Cheng Liu'
        },
        {
          'id': 1170504373,
          'name': 'Zhiwen Yu'
        },
        {
          'id': 1000739482,
          'name': 'Hau-San Wong'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wu_Mutual_Learning_of_Complementary_Networks_via_Residual_Correction_for_Improving_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 300564338,
      'title': 'LO-Net: Deep Real-Time Lidar Odometry',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1095201947,
          'name': 'Qing Li'
        },
        {
          'id': 1388544634,
          'name': 'Shaoyang Chen'
        },
        {
          'id': 1081011056,
          'name': 'Cheng Wang'
        },
        {
          'id': 1177947989,
          'name': 'Xin Li'
        },
        {
          'id': 1089448233,
          'name': 'Chenglu Wen'
        },
        {
          'id': 1223148105,
          'name': 'Ming Cheng'
        },
        {
          'id': 1404025338,
          'name': 'Jonathan Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016797546,
          'name': 'Lidar'
        },
        {
          'id': 2048206591,
          'name': 'Odometry'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_LO-Net_Deep_Real-Time_Lidar_Odometry_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 302475005,
      'title': 'Transferrable Prototypical Networks for Unsupervised Domain Adaptation.',
      'affiliation': [
        {
          'id': 2100888609,
          'abbr': 'ACM',
          'name': 'Association for Computing Machinery'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101079810,
          'abbr': 'CAM',
          'name': 'University of Cambridge'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1429448092,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1403845832,
          'name': 'Yehao Li'
        },
        {
          'id': 1407991464,
          'name': 'Yu Wang'
        },
        {
          'id': 1114237355,
          'name': 'Chong-Wah Ngo'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pan_Transferrable_Prototypical_Networks_for_Unsupervised_Domain_Adaptation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 303581094,
      'title': 'Typography With Decor: Intelligent Text Style Transfer',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1065829692,
          'name': 'Wenjing Wang'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1023932251,
          'name': 'Shuai Yang'
        },
        {
          'id': 1273935622,
          'name': 'Zongming Guo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035849435,
          'name': 'Typography'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wang_Typography_With_Decor_Intelligent_Text_Style_Transfer_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 304483866,
      'title': 'Multispectral and Hyperspectral Image Fusion by MS/HS Fusion Net',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1097800340,
          'name': 'Qi Xie'
        },
        {
          'id': 1185331356,
          'name': 'Minghao Zhou'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xie_Multispectral_and_Hyperspectral_Image_Fusion_by_MSHS_Fusion_Net_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 306522344,
      'title': 'High-Level Semantic Feature Detection: A New Perspective for Pedestrian Detection',
      'affiliation': [
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1355615246,
          'name': 'Wei Liu'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1331261075,
          'name': 'Weiqiang Ren'
        },
        {
          'id': 1182795802,
          'name': 'Weidong Hu'
        },
        {
          'id': 1225242930,
          'name': 'Yinan Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026794874,
          'name': 'Semantic feature'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_High-Level_Semantic_Feature_Detection_A_New_Perspective_for_Pedestrian_Detection_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 306596636,
      'title': 'Blending-target Domain Adaptation by Adversarial Meta-Adaptation Networks',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1275022701,
          'name': 'Ziliang Chen'
        },
        {
          'id': 1032774718,
          'name': 'Jingyu Zhuang'
        },
        {
          'id': 1095714373,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_Blending-Target_Domain_Adaptation_by_Adversarial_Meta-Adaptation_Networks_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 30691546,
      'title': 'Learning to Learn Relation for Important People Detection in Still Images.',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1430073203,
          'name': 'Wei-Hong Li'
        },
        {
          'id': 1153169533,
          'name': 'Fa-Ting Hong'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 308323014,
      'title': 'P3SGD: Patient Privacy Preserving SGD for Regularizing Deep CNNs in Pathological Image Classification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1380715078,
          'name': 'Bingzhe Wu'
        },
        {
          'id': 1266402837,
          'name': 'Shiwan Zhao'
        },
        {
          'id': 1441900644,
          'name': 'Guangyu Sun'
        },
        {
          'id': 1395642855,
          'name': 'Xiaolu Zhang'
        },
        {
          'id': 1422471056,
          'name': 'Zhong Su'
        },
        {
          'id': 1370616224,
          'name': 'Caihong Zeng'
        },
        {
          'id': 1022973570,
          'name': 'Zhihong Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027209868,
          'name': 'Pathological'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009811404,
          'name': 'Patient privacy'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wu_P3SGD_Patient_Privacy_Preserving_SGD_for_Regularizing_Deep_CNNs_in_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 310170392,
      'title': 'COIN: A Large-Scale Dataset for Comprehensive Instructional Video Analysis',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1378949082,
          'name': 'Yansong Tang'
        },
        {
          'id': 1186825406,
          'name': 'Dajun Ding'
        },
        {
          'id': 1223346775,
          'name': 'Yongming Rao'
        },
        {
          'id': 1006718387,
          'name': 'Yu Zheng'
        },
        {
          'id': 1332494006,
          'name': 'Danyang Zhang'
        },
        {
          'id': 1028815581,
          'name': 'Lili Zhao'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1085154187,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Tang_COIN_A_Large-Scale_Dataset_for_Comprehensive_Instructional_Video_Analysis_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 311108625,
      'title': 'Building Detail-Sensitive Semantic Segmentation Networks With Polynomial Pooling',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2100556387,
          'abbr': 'IMF',
          'name': 'International Monetary Fund'
        },
        {
          'id': 2102959018,
          'abbr': '',
          'name': 'New York University Abu Dhabi'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        }
      ],
      'author': [
        {
          'id': 1066692196,
          'name': 'Zhen Wei'
        },
        {
          'id': 1483249472,
          'name': 'Jingyi Zhang'
        },
        {
          'id': 1202070516,
          'name': 'Li Liu'
        },
        {
          'id': 1037247309,
          'name': 'Fan Zhu'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1128806697,
          'name': 'Yi Zhou'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1055371194,
          'name': 'Yao Sun'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026815810,
          'name': 'Polynomial'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wei_Building_Detail-Sensitive_Semantic_Segmentation_Networks_With_Polynomial_Pooling_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 311295303,
      'title': 'Real Photographs Denoising With Noise Domain Adaptation and Attentive Generative Adversarial Network',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1261525770,
          'name': 'Kai Lin'
        },
        {
          'id': 1039031537,
          'name': 'Thomas H. Li'
        },
        {
          'id': 1439974622,
          'name': 'Shan Liu'
        },
        {
          'id': 1444701965,
          'name': 'Ge Li'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/NTIRE/Lin_Real_Photographs_Denoising_With_Noise_Domain_Adaptation_and_Attentive_Generative_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 311886964,
      'title': 'P2SGrad: Refined Gradients for Optimizing Deep Face Models',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1001120881,
          'name': 'Xiao Zhang'
        },
        {
          'id': 1413621210,
          'name': 'Rui Zhao'
        },
        {
          'id': 1126332756,
          'name': 'Junjie Yan'
        },
        {
          'id': 1032968259,
          'name': 'Mengya Gao'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_P2SGrad_Refined_Gradients_for_Optimizing_Deep_Face_Models_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 31239855,
      'title': 'Deep Incremental Hashing Network for Efficient Image Retrieval',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1347375343,
          'name': 'Dayan Wu'
        },
        {
          'id': 1332241016,
          'name': 'Qi Dai'
        },
        {
          'id': 1143495402,
          'name': 'Jing Liu'
        },
        {
          'id': 1142157162,
          'name': 'Bo Li'
        },
        {
          'id': 1052204030,
          'name': 'Weiping Wang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wu_Deep_Incremental_Hashing_Network_for_Efficient_Image_Retrieval_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 312824908,
      'title': 'Knowledge-Embedded Routing Network for Scene Graph Generation.',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1298778203,
          'name': 'Tianshui Chen'
        },
        {
          'id': 1325222147,
          'name': 'Weihao Yu'
        },
        {
          'id': 1496898736,
          'name': 'Riquan Chen'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023497584,
          'name': 'Scene graph'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_Knowledge-Embedded_Routing_Network_for_Scene_Graph_Generation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 314264788,
      'title': 'Leveraging Heterogeneous Auxiliary Tasks to Assist Crowd Counting',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1351628589,
          'name': 'Muming Zhao'
        },
        {
          'id': 1047653608,
          'name': 'Jian Zhang'
        },
        {
          'id': 1153867412,
          'name': 'Chongyang Zhang'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhao_Leveraging_Heterogeneous_Auxiliary_Tasks_to_Assist_Crowd_Counting_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 31579078,
      'title': 'Collaborative Learning of Semi-Supervised Segmentation and Classification for Medical Images',
      'affiliation': [
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101592826,
          'abbr': 'CQU',
          'name': 'Chongqing University'
        },
        {
          'id': 2102959018,
          'abbr': '',
          'name': 'New York University Abu Dhabi'
        }
      ],
      'author': [
        {
          'id': 1128806697,
          'name': 'Yi Zhou'
        },
        {
          'id': 1006185566,
          'name': 'Xiaodong He'
        },
        {
          'id': 1300509348,
          'name': 'Lei Huang'
        },
        {
          'id': 1369963298,
          'name': 'Li Liu'
        },
        {
          'id': 1037247309,
          'name': 'Fan Zhu'
        },
        {
          'id': 1300944061,
          'name': 'Shanshan Cui'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048628453,
          'name': 'Collaborative learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhou_Collaborative_Learning_of_Semi-Supervised_Segmentation_and_Classification_for_Medical_Images_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 315797604,
      'title': 'Attention-guided Network for Ghost-free High Dynamic Range Imaging.',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1411475454,
          'name': 'Qingsen Yan'
        },
        {
          'id': 1151308559,
          'name': 'Dong Gong'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012825488,
          'name': 'High-dynamic-range imaging'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yan_Attention-Guided_Network_for_Ghost-Free_High_Dynamic_Range_Imaging_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 316146283,
      'title': 'Fully Learnable Group Convolution for Acceleration of Deep Neural Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1040162474,
          'name': 'Xijun Wang'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013841216,
          'name': 'Acceleration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wang_Fully_Learnable_Group_Convolution_for_Acceleration_of_Deep_Neural_Networks_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 316494580,
      'title': 'State-aware Re-identification Feature for Multi-target Multi-camera Tracking.',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1493817415,
          'name': 'Peng Li'
        },
        {
          'id': 1257993268,
          'name': 'Jiabin Zhang'
        },
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1362521565,
          'name': 'Yanwei Li'
        },
        {
          'id': 1031133917,
          'name': 'Lu Jiang'
        },
        {
          'id': 1168372245,
          'name': 'Guan Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/TRMTMCT/Li_State-Aware_Re-Identification_Feature_for_Multi-Target_Multi-Camera_Tracking_CVPRW_2019_paper.html',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 317055319,
      'title': 'Feature-Level Frankenstein: Eliminating Variations for Discriminative Recognition',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1462671181,
          'name': 'Xiaofeng Liu'
        },
        {
          'id': 1495759735,
          'name': 'Site Li'
        },
        {
          'id': 1190564374,
          'name': 'Lingsheng Kong'
        },
        {
          'id': 1222247092,
          'name': 'Wanqing Xie'
        },
        {
          'id': 1094548282,
          'name': 'Ping Jia'
        },
        {
          'id': 1167489460,
          'name': 'Jane You'
        },
        {
          'id': 1036255537,
          'name': 'B. V. K. Kumar'
        }
      ],
      'field': [
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Liu_Feature-Level_Frankenstein_Eliminating_Variations_for_Discriminative_Recognition_CVPR_2019_paper.html',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 317320805,
      'title': 'Customizable Architecture Search for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104117587,
          'abbr': 'SRI',
          'name': 'SRI International'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1482027026,
          'name': 'Yiheng Zhang'
        },
        {
          'id': 1348886811,
          'name': 'Zhaofan Qiu'
        },
        {
          'id': 1274988605,
          'name': 'Jingen Liu'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Customizable_Architecture_Search_for_Semantic_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 319506308,
      'title': 'Attention Based Glaucoma Detection: A Large-Scale Database and CNN Model',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1000195960,
          'name': 'Liu Li'
        },
        {
          'id': 1222524734,
          'name': 'Mai Xu'
        },
        {
          'id': 1026382202,
          'name': 'Xiaofei Wang'
        },
        {
          'id': 1157400121,
          'name': 'Lai Jiang'
        },
        {
          'id': 1477467595,
          'name': 'Hanruo Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040102785,
          'name': 'Glaucoma'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Attention_Based_Glaucoma_Detection_A_Large-Scale_Database_and_CNN_Model_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 320418545,
      'title': 'Deep Dual Relation Modeling for Egocentric Interaction Recognition',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1064088168,
          'name': 'Haoxin Li'
        },
        {
          'id': 1483168120,
          'name': 'Yijun Cai'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_Deep_Dual_Relation_Modeling_for_Egocentric_Interaction_Recognition_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 321182110,
      'title': 'Unsupervised Deep Epipolar Flow for Stationary or Dynamic Scenes.',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1374135088,
          'name': 'Yiran Zhong'
        },
        {
          'id': 1328724140,
          'name': 'Pan Ji'
        },
        {
          'id': 1202451152,
          'name': 'Jianyuan Wang'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032713836,
          'name': 'Flow (psychology)'
        },
        {
          'id': 2020539549,
          'name': 'Epipolar geometry'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 32493608,
      'title': 'Double Nuclear Norm Based Low Rank Representation on Grassmann Manifolds for Clustering',
      'affiliation': [
        {
          'id': 2101273003,
          'abbr': 'BJUT',
          'name': 'Beijing University of Technology'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1101733648,
          'name': 'Xinglin Piao'
        },
        {
          'id': 1187234710,
          'name': 'Yongli Hu'
        },
        {
          'id': 1010384661,
          'name': 'Junbin Gao'
        },
        {
          'id': 1145734411,
          'name': 'Yanfeng Sun'
        },
        {
          'id': 1310020299,
          'name': 'Baocai Yin'
        }
      ],
      'field': [
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2047497056,
          'name': 'Algebra'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Piao_Double_Nuclear_Norm_Based_Low_Rank_Representation_on_Grassmann_Manifolds_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 325142000,
      'title': 'Learning to Learn Image Classifiers With Visual Analogy',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1478872539,
          'name': 'Linjun Zhou'
        },
        {
          'id': 1214095950,
          'name': 'Peng Cui'
        },
        {
          'id': 1022153272,
          'name': 'Shiqiang Yang'
        },
        {
          'id': 1184005377,
          'name': 'Wenwu Zhu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017763283,
          'name': 'Analogy'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhou_Learning_to_Learn_Image_Classifiers_With_Visual_Analogy_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 32529562,
      'title': 'Frame-Consistent Recurrent Video Deraining With Dual-Level Flow',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1077278755,
          'name': 'Wenhan Yang'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032713836,
          'name': 'Flow (psychology)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Yang_Frame-Consistent_Recurrent_Video_Deraining_With_Dual-Level_Flow_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 326100719,
      'title': 'An Iterative and Cooperative Top-Down and Bottom-Up Inference Network for Salient Object Detection',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        }
      ],
      'author': [
        {
          'id': 1143159782,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004379144,
          'name': 'Top-down and bottom-up design'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_An_Iterative_and_Cooperative_Top-Down_and_Bottom-Up_Inference_Network_for_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 328125113,
      'title': 'Exploiting Offset-guided Network for Pose Estimation and Tracking.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1009338585,
          'name': 'Rui Zhang'
        },
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1493817415,
          'name': 'Peng Li'
        },
        {
          'id': 1003223286,
          'name': 'Rui Wu'
        },
        {
          'id': 1034959630,
          'name': 'Chaoxu Guo'
        },
        {
          'id': 1168372245,
          'name': 'Guan Huang'
        },
        {
          'id': 1421479958,
          'name': 'Hailun Xia'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040026179,
          'name': 'Offset (computer science)'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1906.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 331860961,
      'title': 'Graph Attention Convolution for Point Cloud Semantic Segmentation',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1004434621,
          'name': 'Lei Wang'
        },
        {
          'id': 1073780935,
          'name': 'Yuchun Huang'
        },
        {
          'id': 1046883926,
          'name': 'Yaolin Hou'
        },
        {
          'id': 1090104096,
          'name': 'Shenman Zhang'
        },
        {
          'id': 1005528735,
          'name': 'Jie Shan'
        }
      ],
      'field': [
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Graph_Attention_Convolution_for_Point_Cloud_Semantic_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 334594270,
      'title': 'Enhancing TripleGAN for Semi-Supervised Conditional Instance Synthesis and Classification',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1144281894,
          'name': 'Si Wu'
        },
        {
          'id': 1037776833,
          'name': 'Guangchang Deng'
        },
        {
          'id': 1347060866,
          'name': 'Jichang Li'
        },
        {
          'id': 1250432414,
          'name': 'Rui Li'
        },
        {
          'id': 1170504373,
          'name': 'Zhiwen Yu'
        },
        {
          'id': 1000739482,
          'name': 'Hau-San Wong'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wu_Enhancing_TripleGAN_for_Semi-Supervised_Conditional_Instance_Synthesis_and_Classification_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 335844600,
      'title': 'Semantics Disentangling for Text-to-Image Generation.',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1142128166,
          'name': 'Guojun Yin'
        },
        {
          'id': 1035353314,
          'name': 'Bin Liu'
        },
        {
          'id': 1099193953,
          'name': 'Lu Sheng'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1123668712,
          'name': 'Jing Shao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yin_Semantics_Disentangling_for_Text-To-Image_Generation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 337074091,
      'title': 'Structured Knowledge Distillation for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104553663,
          'abbr': '',
          'name': 'Samsung'
        }
      ],
      'author': [
        {
          'id': 1387940487,
          'name': 'Yifan Liu'
        },
        {
          'id': 1013140030,
          'name': 'Ke Chen'
        },
        {
          'id': 1001218260,
          'name': 'Chris Liu'
        },
        {
          'id': 1327246159,
          'name': 'Zengchang Qin'
        },
        {
          'id': 1074532305,
          'name': 'Zhenbo Luo'
        },
        {
          'id': 1003953660,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010918187,
          'name': 'Distillation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/abs/1903.04197',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 338397934,
      'title': 'Patch-based 3D Human Pose Refinement.',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1067240447,
          'name': 'Qingfu Wan'
        },
        {
          'id': 1393738390,
          'name': 'Weichao Qiu'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1905.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 339926003,
      'title': 'Pyramidal Person Re-IDentification via Multi-Loss Dynamic Training',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1082380717,
          'name': 'Feng Zheng'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1214289542,
          'name': 'Xing Sun'
        },
        {
          'id': 1200221899,
          'name': 'Xinyang Jiang'
        },
        {
          'id': 1269291599,
          'name': 'Xiaowei Guo'
        },
        {
          'id': 1120313640,
          'name': 'Zongqiao Yu'
        },
        {
          'id': 1397769541,
          'name': 'Feiyue Huang'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zheng_Pyramidal_Person_Re-IDentification_via_Multi-Loss_Dynamic_Training_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 340591337,
      'title': 'Unified Visual-Semantic Embeddings: Bridging Vision and Language With Structured Meaning Representations',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1001831890,
          'name': 'Hao Wu'
        },
        {
          'id': 1396601583,
          'name': 'Jiayuan Mao'
        },
        {
          'id': 1052586725,
          'name': 'Yufeng Zhang'
        },
        {
          'id': 1314532880,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1000106526,
          'name': 'Lei Li'
        },
        {
          'id': 1394823087,
          'name': 'Weiwei Sun'
        },
        {
          'id': 1069132499,
          'name': 'Wei-Ying Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000350581,
          'name': 'Bridging (networking)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wu_Unified_Visual-Semantic_Embeddings_Bridging_Vision_and_Language_With_Structured_Meaning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 340682765,
      'title': 'Learning a Unified Classifier Incrementally via Rebalancing',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1112157554,
          'name': 'Saihui Hou'
        },
        {
          'id': 1402806659,
          'name': 'Xinyu Pan'
        },
        {
          'id': 1034665245,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1149174550,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Hou_Learning_a_Unified_Classifier_Incrementally_via_Rebalancing_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 345333636,
      'title': 'Variational Convolutional Neural Network Pruning',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1380400068,
          'name': 'Chenglong Zhao'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1047653608,
          'name': 'Jian Zhang'
        },
        {
          'id': 1192171775,
          'name': 'Qiwei Zhao'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1238691319,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2035276397,
          'name': 'Pruning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhao_Variational_Convolutional_Neural_Network_Pruning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 347956098,
      'title': 'Gaussian Temporal Awareness Networks for Action Localization',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1156054509,
          'name': 'Fuchen Long'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1348886811,
          'name': 'Zhaofan Qiu'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Long_Gaussian_Temporal_Awareness_Networks_for_Action_Localization_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 352563576,
      'title': 'Example-Guided Style-Consistent Image Synthesis From Semantic Labeling',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103632264,
          'abbr': 'UW',
          'name': 'University of Waterloo'
        }
      ],
      'author': [
        {
          'id': 1243727720,
          'name': 'Miao Wang'
        },
        {
          'id': 1202737349,
          'name': 'Guo-Ye Yang'
        },
        {
          'id': 1202178136,
          'name': 'Ruilong Li'
        },
        {
          'id': 1351840312,
          'name': 'Run-Ze Liang'
        },
        {
          'id': 1059439771,
          'name': 'Song-Hai Zhang'
        },
        {
          'id': 1394809969,
          'name': 'Peter Hall'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Example-Guided_Style-Consistent_Image_Synthesis_From_Semantic_Labeling_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 354039194,
      'title': 'Skeleton-Based Action Recognition With Directed Graph Neural Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1009757061,
          'name': 'Lei Shi'
        },
        {
          'id': 1011431260,
          'name': 'Yifan Zhang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2019954370,
          'name': 'Directed graph'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Shi_Skeleton-Based_Action_Recognition_With_Directed_Graph_Neural_Networks_CVPR_2019_paper.html',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 354046021,
      'title': 'APA: Adaptive Pose Alignment for Robust Face Recognition',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1023126814,
          'name': 'Zhanfu An'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1354792759,
          'name': 'Yaoyao Zhong'
        },
        {
          'id': 1122453510,
          'name': 'Yaohai Huang'
        },
        {
          'id': 1057619188,
          'name': 'Xunqiang Tao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/AMFG/An_APA_Adaptive_Pose_Alignment_for_Robust_Face_Recognition_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 354414404,
      'title': 'Cross-Modal Relationship Inference for Grounding Referring Expressions',
      'affiliation': [
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1361097680,
          'name': 'Sibei Yang'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1419663498,
          'name': 'Yizhou Yu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036397490,
          'name': 'Ground'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2028889101,
          'name': 'Modal'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_Cross-Modal_Relationship_Inference_for_Grounding_Referring_Expressions_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 355288199,
      'title': 'Learning Pyramid-Context Encoder Network for High-Quality Image Inpainting',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1097280436,
          'name': 'Yanhong Zeng'
        },
        {
          'id': 1057895870,
          'name': 'Jianlong Fu'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1247333574,
          'name': 'Baining Guo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zeng_Learning_Pyramid-Context_Encoder_Network_for_High-Quality_Image_Inpainting_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 359747345,
      'title': 'Depth-Aware Video Frame Interpolation.',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1332028776,
          'name': 'Wenbo Bao'
        },
        {
          'id': 1477719517,
          'name': 'Wei-Sheng Lai'
        },
        {
          'id': 1254987849,
          'name': 'Chao Ma'
        },
        {
          'id': 1168997702,
          'name': 'Xiaoyun Zhang'
        },
        {
          'id': 1278281228,
          'name': 'Zhiyong Gao'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046363833,
          'name': 'Motion interpolation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Bao_Depth-Aware_Video_Frame_Interpolation_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 361401252,
      'title': 'AE2-Nets: Autoencoder in Autoencoder Networks',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        }
      ],
      'author': [
        {
          'id': 1179117743,
          'name': 'Changqing Zhang'
        },
        {
          'id': 1378630743,
          'name': 'Yeqing Liu'
        },
        {
          'id': 1357204816,
          'name': 'Huazhu Fu'
        }
      ],
      'field': [
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_AE2-Nets_Autoencoder_in_Autoencoder_Networks_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 362428509,
      'title': 'Unsupervised Face Normalization With Extreme Pose and Expression in the Wild',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1074407714,
          'name': 'Yichen Qian'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1481827642,
          'name': 'Jiani Hu'
        }
      ],
      'field': [
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Qian_Unsupervised_Face_Normalization_With_Extreme_Pose_and_Expression_in_the_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 362440149,
      'title': 'Fast and Robust Multi-Person 3D Pose Estimation from Multiple Views.',
      'affiliation': [
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1145159076,
          'name': 'Junting Dong'
        },
        {
          'id': 1009585552,
          'name': 'Wen Jiang'
        },
        {
          'id': 1022255500,
          'name': 'Qixing Huang'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        },
        {
          'id': 1143104821,
          'name': 'Xiaowei Zhou'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Dong_Fast_and_Robust_Multi-Person_3D_Pose_Estimation_From_Multiple_Views_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 363108182,
      'title': 'Large-Scale Few-Shot Learning: Knowledge Transfer With Class Hierarchy',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101081544,
          'abbr': 'RUC',
          'name': 'Renmin University of China'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1045948389,
          'name': 'Aoxue Li'
        },
        {
          'id': 1438410785,
          'name': 'Tiange Luo'
        },
        {
          'id': 1332242526,
          'name': 'Zhiwu Lu'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1324567904,
          'name': 'Liwei Wang'
        }
      ],
      'field': [
        {
          'id': 2003444945,
          'name': 'Class hierarchy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Large-Scale_Few-Shot_Learning_Knowledge_Transfer_With_Class_Hierarchy_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 36443069,
      'title': 'ScratchDet: Training Single-Shot Object Detectors From Scratch',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        }
      ],
      'author': [
        {
          'id': 1414626659,
          'name': 'Rui Zhu'
        },
        {
          'id': 1108322389,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1080319340,
          'name': 'Xiaobo Wang'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1477164535,
          'name': 'Hailin Shi'
        },
        {
          'id': 1389215474,
          'name': 'Liefeng Bo'
        },
        {
          'id': 1211015446,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023124670,
          'name': 'Scratch'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhu_ScratchDet_Training_Single-Shot_Object_Detectors_From_Scratch_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 366058670,
      'title': 'Reasoning Visual Dialogs with Structural and Partial Observations.',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1315752410,
          'name': 'Zilong Zheng'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1417522252,
          'name': 'Siyuan Qi'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zheng_Reasoning_Visual_Dialogs_With_Structural_and_Partial_Observations_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 366453666,
      'title': 'Practical Stacked Non-local Attention Modules for Image Compression',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1366948388,
          'name': 'Haojie Liu'
        },
        {
          'id': 1212631297,
          'name': 'Tong Chen'
        },
        {
          'id': 1287130343,
          'name': 'Qiu Shen'
        },
        {
          'id': 1049262418,
          'name': 'Zhan Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CLIC 2019/Liu_Practical_Stacked_Non-local_Attention_Modules_for_Image_Compression_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 367171429,
      'title': 'DM-GAN: Dynamic Memory Generative Adversarial Networks for Text-To-Image Synthesis',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1097894998,
          'name': 'Minfeng Zhu'
        },
        {
          'id': 1350814045,
          'name': 'Pingbo Pan'
        },
        {
          'id': 1289827602,
          'name': 'Wei Chen'
        },
        {
          'id': 1010861762,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000179670,
          'name': 'Dynamic random-access memory'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhu_DM-GAN_Dynamic_Memory_Generative_Adversarial_Networks_for_Text-To-Image_Synthesis_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 367521784,
      'title': 'Learning to Calibrate Straight Lines for Fisheye Image Rectification',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1319179737,
          'name': 'Zhucun Xue'
        },
        {
          'id': 1202705676,
          'name': 'Nan Xue'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1460895345,
          'name': 'Weiming Shen'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021101066,
          'name': 'Image rectification'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xue_Learning_to_Calibrate_Straight_Lines_for_Fisheye_Image_Rectification_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 36777000,
      'title': 'Turn a Silicon Camera Into an InGaAs Camera',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1256311356,
          'name': 'Feifan Lv'
        },
        {
          'id': 1074678357,
          'name': 'Yinqiang Zheng'
        },
        {
          'id': 1262396943,
          'name': 'Bohan Zhang'
        },
        {
          'id': 1313394467,
          'name': 'Feng Lu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035171283,
          'name': 'Silicon'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Lv_Turn_a_Silicon_Camera_Into_an_InGaAs_Camera_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 36936204,
      'title': 'Spectral Reconstruction From Dispersive Blur: A Novel Light Efficient Spectral Imager',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1310656003,
          'name': 'Yuanyuan Zhao'
        },
        {
          'id': 1026626024,
          'name': 'Xuemei Hu'
        },
        {
          'id': 1003518647,
          'name': 'Hui Guo'
        },
        {
          'id': 1049262418,
          'name': 'Zhan Ma'
        },
        {
          'id': 1019303489,
          'name': 'Tao Yue'
        },
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhao_Spectral_Reconstruction_From_Dispersive_Blur_A_Novel_Light_Efficient_Spectral_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 372690211,
      'title': 'VRSTC: Occlusion-Free Video Person Re-Identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1370074744,
          'name': 'Ruibing Hou'
        },
        {
          'id': 1296981365,
          'name': 'Bingpeng Ma'
        },
        {
          'id': 1467937951,
          'name': 'Hong Chang'
        },
        {
          'id': 1450893536,
          'name': 'Xinqian Gu'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Hou_VRSTC_Occlusion-Free_Video_Person_Re-Identification_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 372875006,
      'title': 'Look More Than Once: An Accurate Detector for Text of Arbitrary Shapes',
      'affiliation': [
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1137890505,
          'name': 'Chengquan Zhang'
        },
        {
          'id': 1364875473,
          'name': 'Borong Liang'
        },
        {
          'id': 1191017055,
          'name': 'Zuming Huang'
        },
        {
          'id': 1277226972,
          'name': 'Mengyi En'
        },
        {
          'id': 1245474880,
          'name': 'Junyu Han'
        },
        {
          'id': 1109540728,
          'name': 'Errui Ding'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Look_More_Than_Once_An_Accurate_Detector_for_Text_of_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 377315320,
      'title': 'Viewport Proposal CNN for 360deg Video Quality Assessment',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1000317711,
          'name': 'Chen Li'
        },
        {
          'id': 1118664104,
          'name': 'Mai Xu'
        },
        {
          'id': 1067503627,
          'name': 'Lai Jiang'
        },
        {
          'id': 1210283381,
          'name': 'Shanyi Zhang'
        },
        {
          'id': 1198578078,
          'name': 'Xiaoming Tao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000288666,
          'name': 'Viewport'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012801258,
          'name': 'Video quality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_Viewport_Proposal_CNN_for_360deg_Video_Quality_Assessment_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 37768822,
      'title': 'Learning to Transfer Examples for Partial Domain Adaptation.',
      'affiliation': [
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1198121269,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1444918301,
          'name': 'Kaichao You'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1192835994,
          'name': 'Qiang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Cao_Learning_to_Transfer_Examples_for_Partial_Domain_Adaptation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 380345234,
      'title': 'A Dual Attention Network with Semantic Embedding for Few-shot Learning.',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1393712452,
          'name': 'Shipeng Yan'
        },
        {
          'id': 1382107634,
          'name': 'Songyang Zhang'
        },
        {
          'id': 1171646407,
          'name': 'Xuming He'
        }
      ],
      'field': [
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 382189004,
      'title': 'PPGNet: Learning Point-Pair Graph for Line Segment Detection',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1114348800,
          'name': 'Ziheng Zhang'
        },
        {
          'id': 1013804710,
          'name': 'Zhengxin Li'
        },
        {
          'id': 1205348113,
          'name': 'Ning Bi'
        },
        {
          'id': 1061826179,
          'name': 'Jia Zheng'
        },
        {
          'id': 1411792390,
          'name': 'Jinlei Wang'
        },
        {
          'id': 1016239771,
          'name': 'Kun Huang'
        },
        {
          'id': 1252850696,
          'name': 'Weixin Luo'
        },
        {
          'id': 1373263498,
          'name': 'Yanyu Xu'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2004058443,
          'name': 'Line segment'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_PPGNet_Learning_Point-Pair_Graph_for_Line_Segment_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 383387269,
      'title': 'Fully Quantized Network for Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1188129359,
          'name': 'Rundong Li'
        },
        {
          'id': 1000306321,
          'name': 'Yan Wang'
        },
        {
          'id': 1004721911,
          'name': 'Feng Liang'
        },
        {
          'id': 1436337105,
          'name': 'Hongwei Qin'
        },
        {
          'id': 1116151503,
          'name': 'Junjie Yan'
        },
        {
          'id': 1200851089,
          'name': 'Rui Fan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039464793,
          'name': 'Quantization (physics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Fully_Quantized_Network_for_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 384266785,
      'title': 'DeFusionNET: Defocus Blur Detection via Recurrently Fusing and Refining Multi-Scale Deep Features',
      'affiliation': [
        {
          'id': 2103554712,
          'abbr': 'CUGB ',
          'name': 'China University of Geosciences'
        },
        {
          'id': 2100425220,
          'abbr': 'ZJNU',
          'name': 'Zhejiang Normal University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1240354208,
          'name': 'Chang Tang'
        },
        {
          'id': 1138232876,
          'name': 'Xinzhong Zhu'
        },
        {
          'id': 1162571328,
          'name': 'Xinwang Liu'
        },
        {
          'id': 1193104394,
          'name': 'Lizhe Wang'
        },
        {
          'id': 1014220111,
          'name': 'Albert Zomaya'
        }
      ],
      'field': [
        {
          'id': 2013553633,
          'name': 'Refining (metallurgy)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Tang_DeFusionNET_Defocus_Blur_Detection_via_Recurrently_Fusing_and_Refining_Multi-Scale_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 384460770,
      'title': 'Stereo R-CNN Based 3D Object Detection for Autonomous Driving',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1406383119,
          'name': 'Peiliang Li'
        },
        {
          'id': 1331893764,
          'name': 'Xiaozhi Chen'
        },
        {
          'id': 1115289729,
          'name': 'Shaojie Shen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Stereo_R-CNN_Based_3D_Object_Detection_for_Autonomous_Driving_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 385749990,
      'title': 'Evading Defenses to Transferable Adversarial Examples by Translation-Invariant Attacks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1258883894,
          'name': 'Tianyu Pang'
        },
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018935324,
          'name': 'Computer security'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Dong_Evading_Defenses_to_Transferable_Adversarial_Examples_by_Translation-Invariant_Attacks_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 387217188,
      'title': 'FeatherNets: Convolutional Neural Networks as Light as Feather for Face Anti-Spoofing',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1000987497,
          'name': 'Peng Zhang'
        },
        {
          'id': 1196728386,
          'name': 'Fuhao Zou'
        },
        {
          'id': 1026714899,
          'name': 'Zhiwen. Wu'
        },
        {
          'id': 1453513900,
          'name': 'Nengli Dai'
        },
        {
          'id': 1492177573,
          'name': 'Skarpness Mark'
        },
        {
          'id': 1048255046,
          'name': 'Michael Fu'
        },
        {
          'id': 1025609042,
          'name': 'Juan Zhao'
        },
        {
          'id': 1240266121,
          'name': 'Kai Li'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028022761,
          'name': 'Spoofing attack'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/CFS/Zhang_FeatherNets_Convolutional_Neural_Networks_as_Light_as_Feather_for_Face_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 388138156,
      'title': 'Unsupervised Primitive Discovery for Improved 3D Generative Modeling',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2102903983,
          'abbr': 'UC',
          'name': 'University of Canberra'
        }
      ],
      'author': [
        {
          'id': 1076674290,
          'name': 'Salman Khan'
        },
        {
          'id': 1206681019,
          'name': 'Yulan Guo'
        },
        {
          'id': 1329406038,
          'name': 'Munawar Hayat'
        },
        {
          'id': 1239130726,
          'name': 'Nick Barnes'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/abs/1906.03650v1',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 388713487,
      'title': 'Knowledge Adaptation for Efficient Semantic Segmentation',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        }
      ],
      'author': [
        {
          'id': 1125643104,
          'name': 'Tong He'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1321815528,
          'name': 'Zhi Tian'
        },
        {
          'id': 1151308559,
          'name': 'Dong Gong'
        },
        {
          'id': 1333537378,
          'name': 'Changming Sun'
        },
        {
          'id': 1336118820,
          'name': 'Youliang Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/abs/1903.04688',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 38927228,
      'title': 'Guaranteed Matrix Completion Under Multiple Linear Transformations',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2102518052,
          'abbr': 'SIT',
          'name': 'Saitama Institute of Technology'
        },
        {
          'id': 2103911346,
          'abbr': 'GDUT',
          'name': 'Guangdong University of Technology'
        }
      ],
      'author': [
        {
          'id': 1002300289,
          'name': 'Chao Li'
        },
        {
          'id': 1044388342,
          'name': 'Wei He'
        },
        {
          'id': 1304792613,
          'name': 'Longhao Yuan'
        },
        {
          'id': 1035697736,
          'name': 'Zhun Sun'
        },
        {
          'id': 1000507695,
          'name': 'Qibin Zhao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2042592337,
          'name': 'Linear map'
        },
        {
          'id': 2004564439,
          'name': 'Matrix completion'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_Guaranteed_Matrix_Completion_Under_Multiple_Linear_Transformations_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 396857037,
      'title': 'Linkage Based Face Clustering via Graph Convolution Network',
      'affiliation': [
        {
          'id': 2101979648,
          'abbr': 'SUTD',
          'name': 'Singapore University of Technology and Design'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1044321018,
          'name': 'Zhongdao Wang'
        },
        {
          'id': 1028988623,
          'name': 'Liang Zheng'
        },
        {
          'id': 1014730232,
          'name': 'Yali Li'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        }
      ],
      'field': [
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/pdf/1903.11306.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 39718659,
      'title': 'Structural Relational Reasoning of Point Clouds',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1495536695,
          'name': 'Yueqi Duan'
        },
        {
          'id': 1006718387,
          'name': 'Yu Zheng'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1085154187,
          'name': 'Jie Zhou'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Duan_Structural_Relational_Reasoning_of_Point_Clouds_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 403771413,
      'title': 'Deeper and Wider Siamese Networks for Real-Time Visual Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1004181748,
          'name': 'Zhipeng Zhang'
        },
        {
          'id': 1048739089,
          'name': 'Houwen Peng'
        },
        {
          'id': 1001343316,
          'name': 'Qiang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhang_Deeper_and_Wider_Siamese_Networks_for_Real-Time_Visual_Tracking_CVPR_2019_paper.html',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 40453535,
      'title': 'DistillHash: Unsupervised Deep Hashing by Distilling Data Pairs',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1094289790,
          'name': 'Erkun Yang'
        },
        {
          'id': 1356544795,
          'name': 'Tongliang Liu'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_DistillHash_Unsupervised_Deep_Hashing_by_Distilling_Data_Pairs_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 407353610,
      'title': 'Towards Rich Feature Discovery With Class Activation Maps Augmentation for Person Re-Identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1090960976,
          'name': 'Wenjie Yang'
        },
        {
          'id': 1230203872,
          'name': 'Houjing Huang'
        },
        {
          'id': 1070491497,
          'name': 'Zhang Zhang'
        },
        {
          'id': 1055699222,
          'name': 'Xiaotang Chen'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1024040037,
          'name': 'Shu Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_Towards_Rich_Feature_Discovery_With_Class_Activation_Maps_Augmentation_for_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 409229257,
      'title': 'Shape2Motion: Joint Analysis of Motion Parts and Attributes From 3D Shapes',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1048568178,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1065095520,
          'name': 'Bin Zhou'
        },
        {
          'id': 1393775070,
          'name': 'Yahao Shi'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        },
        {
          'id': 1275772888,
          'name': 'Kai Xu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Shape2Motion_Joint_Analysis_of_Motion_Parts_and_Attributes_From_3D_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 409626905,
      'title': 'Hyperspectral Image Reconstruction Using a Deep Spatial-Spectral Prior',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1372850284,
          'name': 'Lizhi Wang'
        },
        {
          'id': 1005986030,
          'name': 'Chen Sun'
        },
        {
          'id': 1192145103,
          'name': 'Ying Fu'
        },
        {
          'id': 1269537530,
          'name': 'Min H. Kim'
        },
        {
          'id': 1194539518,
          'name': 'Hua Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Hyperspectral_Image_Reconstruction_Using_a_Deep_Spatial-Spectral_Prior_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 410019620,
      'title': 'Multi-camera vehicle tracking and re-identification based on visual and spatial-temporal features',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1005782332,
          'name': 'Xiao Tan'
        },
        {
          'id': 1016479569,
          'name': 'Zhigang Wang'
        },
        {
          'id': 1028375128,
          'name': 'Minyue Jiang'
        },
        {
          'id': 1056881659,
          'name': 'Xipeng Yang'
        },
        {
          'id': 1339461696,
          'name': 'Jian Wang'
        },
        {
          'id': 1004813023,
          'name': 'Yuan Gao'
        },
        {
          'id': 1282784177,
          'name': 'Xiangbo Su'
        },
        {
          'id': 1199925301,
          'name': 'Xiaoqing Ye'
        },
        {
          'id': 1436954715,
          'name': 'YuChen Yuan'
        },
        {
          'id': 1123668686,
          'name': 'Dongliang He'
        },
        {
          'id': 1456081223,
          'name': 'Shilei Wen'
        },
        {
          'id': 1109540728,
          'name': 'Errui Ding'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025425813,
          'name': 'Vehicle tracking system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/AI City/Tan_Multi-camera_vehicle_tracking_and_re-identification_based_on_visual_and_spatial-temporal_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 410891158,
      'title': 'Local Relationship Learning With Person-Specific Shape Regularization for Facial Action Unit Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101117222,
          'abbr': 'SCU',
          'name': 'Sichuan University'
        }
      ],
      'author': [
        {
          'id': 1128362465,
          'name': 'Xuesong Niu'
        },
        {
          'id': 1217280926,
          'name': 'Hu Han'
        },
        {
          'id': 1038643404,
          'name': 'Songfan Yang'
        },
        {
          'id': 1001357952,
          'name': 'Yan Huang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Niu_Local_Relationship_Learning_With_Person-Specific_Shape_Regularization_for_Facial_Action_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 41227923,
      'title': 'A Dataset and Benchmark for Large-Scale Multi-Modal Face Anti-Spoofing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102976531,
          'abbr': 'UB',
          'name': 'University of Barcelona'
        }
      ],
      'author': [
        {
          'id': 1108322389,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1080319340,
          'name': 'Xiaobo Wang'
        },
        {
          'id': 1387942647,
          'name': 'Ajian Liu'
        },
        {
          'id': 1128365711,
          'name': 'Chenxu Zhao'
        },
        {
          'id': 1119293493,
          'name': 'Jun Wan'
        },
        {
          'id': 1011188708,
          'name': 'Sergio Escalera'
        },
        {
          'id': 1477164535,
          'name': 'Hailin Shi'
        },
        {
          'id': 1417888643,
          'name': 'Zezheng Wang'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028889101,
          'name': 'Modal'
        },
        {
          'id': 2028022761,
          'name': 'Spoofing attack'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_A_Dataset_and_Benchmark_for_Large-Scale_Multi-Modal_Face_Anti-Spoofing_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 415051536,
      'title': 'Unsupervised Person Re-Identification by Soft Multilabel Learning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1046260562,
          'name': 'Hong-Xing Yu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1269291599,
          'name': 'Xiaowei Guo'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/abs/1903.06325',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 417033440,
      'title': 'Iterative Alignment Network for Continuous Sign Language Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1282892984,
          'name': 'Junfu Pu'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015795312,
          'name': 'Sign language'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Pu_Iterative_Alignment_Network_for_Continuous_Sign_Language_Recognition_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 418899622,
      'title': 'Conditional Single-View Shape Generation for Multi-View Stereo Reconstruction',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1003607605,
          'name': 'Yi Wei'
        },
        {
          'id': 1320286591,
          'name': 'Shaohui Liu'
        },
        {
          'id': 1000730444,
          'name': 'Wang Zhao'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wei_Conditional_Single-View_Shape_Generation_for_Multi-View_Stereo_Reconstruction_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 419290578,
      'title': 'Distilled Person Re-Identification: Towards a More Scalable System',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1269291599,
          'name': 'Xiaowei Guo'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wu_Distilled_Person_Re-Identification_Towards_a_More_Scalable_System_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 424333048,
      'title': 'Riemannian Loss for Image Restoration.',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1213570119,
          'name': 'Jing Mu'
        },
        {
          'id': 1112786959,
          'name': 'Xinfeng Zhang'
        },
        {
          'id': 1458254710,
          'name': 'Shuyuan Zhu'
        },
        {
          'id': 1293244045,
          'name': 'Ruiqin Xiong'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/WiCV/Mu_Riemannian_Loss_for_Image_Restoration_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 425751536,
      'title': 'Listen to the Image',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104905128,
          'abbr': 'USTB',
          'name': 'University of Science and Technology Beijing'
        }
      ],
      'author': [
        {
          'id': 1088670356,
          'name': 'Di Hu'
        },
        {
          'id': 1454214530,
          'name': 'Dong Wang'
        },
        {
          'id': 1496861582,
          'name': 'Xuelong Li'
        },
        {
          'id': 1410325846,
          'name': 'Feiping Nie'
        },
        {
          'id': 1486849999,
          'name': 'Qi Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Hu_Listen_to_the_Image_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 42583386,
      'title': 'CrossInfoNet: Multi-Task Information Sharing Based Hand Pose Estimation',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1219598212,
          'name': 'Kuo Du'
        },
        {
          'id': 1421517312,
          'name': 'Xiangbo Lin'
        },
        {
          'id': 1359392906,
          'name': 'Yi Sun'
        },
        {
          'id': 1355670895,
          'name': 'Xiaohong Ma'
        }
      ],
      'field': [
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022287674,
          'name': 'Information sharing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Du_CrossInfoNet_Multi-Task_Information_Sharing_Based_Hand_Pose_Estimation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 426148773,
      'title': 'Cross Domain Model Compression by Structurally Weight Sharing',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        }
      ],
      'author': [
        {
          'id': 1223713507,
          'name': 'Shangqian Gao'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1282955999,
          'name': 'Heng Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016950361,
          'name': 'Domain model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Gao_Cross_Domain_Model_Compression_by_Structurally_Weight_Sharing_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 428050630,
      'title': 'Boosting Local Shape Matching for Dense 3D Face Correspondence',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102508462,
          'abbr': 'NU',
          'name': 'Northeastern University'
        }
      ],
      'author': [
        {
          'id': 1323992119,
          'name': 'Zhenfeng Fan'
        },
        {
          'id': 1063962263,
          'name': 'Xiyuan Hu'
        },
        {
          'id': 1071077481,
          'name': 'Chen Chen'
        },
        {
          'id': 1066477677,
          'name': 'Silong Peng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Fan_Boosting_Local_Shape_Matching_for_Dense_3D_Face_Correspondence_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 433491667,
      'title': 'Hyperspectral Image Super-Resolution With Optimized RGB Guidance',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102805074,
          'abbr': 'NII',
          'name': 'National Institute of Informatics'
        }
      ],
      'author': [
        {
          'id': 1192145103,
          'name': 'Ying Fu'
        },
        {
          'id': 1445823841,
          'name': 'Tao Zhang'
        },
        {
          'id': 1285220304,
          'name': 'Yinqiang Zheng'
        },
        {
          'id': 1334782672,
          'name': 'Debing Zhang'
        },
        {
          'id': 1194539518,
          'name': 'Hua Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Fu_Hyperspectral_Image_Super-Resolution_With_Optimized_RGB_Guidance_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 43392112,
      'title': 'MSCap: Multi-Style Image Captioning With Unpaired Stylized Text',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1126761585,
          'name': 'Longteng Guo'
        },
        {
          'id': 1143495402,
          'name': 'Jing Liu'
        },
        {
          'id': 1034495410,
          'name': 'Peng Yao'
        },
        {
          'id': 1217732391,
          'name': 'Jiangwei Li'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2007469624,
          'name': 'Stylized fact'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Guo_MSCap_Multi-Style_Image_Captioning_With_Unpaired_Stylized_Text_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 434991097,
      'title': 'Extreme Relative Pose Estimation for RGB-D Scans via Scene Completion',
      'affiliation': [
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        }
      ],
      'author': [
        {
          'id': 1243394880,
          'name': 'Zhenpei Yang'
        },
        {
          'id': 1252151595,
          'name': 'Jeffrey Z.Pan'
        },
        {
          'id': 1350801362,
          'name': 'Linjie Luo'
        },
        {
          'id': 1143104821,
          'name': 'Xiaowei Zhou'
        },
        {
          'id': 1075935428,
          'name': 'Kristen Grauman'
        },
        {
          'id': 1022255500,
          'name': 'Qixing Huang'
        }
      ],
      'field': [
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Yang_Extreme_Relative_Pose_Estimation_for_RGB-D_Scans_via_Scene_Completion_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 437293837,
      'title': 'Shifting More Attention to Video Salient Object Detection',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1235240356,
          'name': 'Deng-Ping Fan'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Fan_Shifting_More_Attention_to_Video_Salient_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 15,
      'reference_count': 0
    },
    {
      'id': 437367121,
      'title': 'Multi-Scale Geometric Consistency Guided Multi-View Stereo',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1049819740,
          'name': 'Qingshan Xu'
        },
        {
          'id': 1145425243,
          'name': 'Wenbing Tao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xu_Multi-Scale_Geometric_Consistency_Guided_Multi-View_Stereo_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 437567087,
      'title': 'Learning RoI Transformer for Oriented Object Detection in Aerial Images',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1004951150,
          'name': 'Jian Ding'
        },
        {
          'id': 1202705676,
          'name': 'Nan Xue'
        },
        {
          'id': 1075075705,
          'name': 'Yang Long'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1064350286,
          'name': 'Qikai Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012198305,
          'name': 'Transformer'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Ding_Learning_RoI_Transformer_for_Oriented_Object_Detection_in_Aerial_Images_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 440394588,
      'title': 'Noise-Aware Unsupervised Deep Lidar-Stereo Fusion',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1066178544,
          'name': 'Xuelian Cheng'
        },
        {
          'id': 1374135088,
          'name': 'Yiran Zhong'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1328724140,
          'name': 'Pan Ji'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016797546,
          'name': 'Lidar'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Cheng_Noise-Aware_Unsupervised_Deep_Lidar-Stereo_Fusion_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 442730368,
      'title': 'Utilizing the Instability in Weakly Supervised Object Detection.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1417272670,
          'name': 'Boxiao Liu'
        },
        {
          'id': 1164872342,
          'name': 'Yan Gao'
        },
        {
          'id': 1488810582,
          'name': 'Nan Guo'
        },
        {
          'id': 1384908573,
          'name': 'Xiaochun Ye'
        },
        {
          'id': 1437119785,
          'name': 'Fang Wan'
        },
        {
          'id': 1121939386,
          'name': 'Haihang You'
        },
        {
          'id': 1067221696,
          'name': 'Dongrui Fan'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034196030,
          'name': 'Instability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1906.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 443170075,
      'title': 'Learning from Synthetic Data for Crowd Counting in the Wild',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1486849999,
          'name': 'Qi Wang'
        },
        {
          'id': 1470156477,
          'name': 'Junyu Gao'
        },
        {
          'id': 1004774432,
          'name': 'Wei Lin'
        },
        {
          'id': 1041571510,
          'name': 'Yuan Yuan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/pdf/1903.03303.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 444732923,
      'title': 'Transferable AutoML by Model Sharing Over Grouped Datasets',
      'affiliation': [
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1156706699,
          'name': 'Chao Xue'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1371425058,
          'name': 'Rong Yan'
        },
        {
          'id': 1036975246,
          'name': 'Stephen M. Chu'
        },
        {
          'id': 1018726639,
          'name': 'Yonggang Hu'
        },
        {
          'id': 1014292710,
          'name': 'Yonghua Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xue_Transferable_AutoML_by_Model_Sharing_Over_Grouped_Datasets_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 44546359,
      'title': 'End-To-End Interpretable Neural Motion Planner',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        }
      ],
      'author': [
        {
          'id': 1407848406,
          'name': 'Wenyuan Zeng'
        },
        {
          'id': 1036185677,
          'name': 'Wenjie Luo'
        },
        {
          'id': 1099947076,
          'name': 'Simon Suo'
        },
        {
          'id': 1425402035,
          'name': 'Abbas Sadat'
        },
        {
          'id': 1321754694,
          'name': 'Bin Yang'
        },
        {
          'id': 1193831999,
          'name': 'Sergio Casas'
        },
        {
          'id': 1174742793,
          'name': 'Raquel Urtasun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2037189365,
          'name': 'Planner'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zeng_End-To-End_Interpretable_Neural_Motion_Planner_CVPR_2019_paper.pdf',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 446471719,
      'title': 'Label Efficient Semi-Supervised Learning via Graph Filtering',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1347926672,
          'name': 'Qimai Li'
        },
        {
          'id': 1230769510,
          'name': 'Xiao-Ming Wu'
        },
        {
          'id': 1251938840,
          'name': 'Han Liu'
        },
        {
          'id': 1385970088,
          'name': 'Xiaotong Zhang'
        },
        {
          'id': 1000498462,
          'name': 'Zhichao Guan'
        }
      ],
      'field': [
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Label_Efficient_Semi-Supervised_Learning_via_Graph_Filtering_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 447811484,
      'title': 'Invariance Matters: Exemplar Memory for Domain Adaptive Person Re-Identification',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2101979648,
          'abbr': 'SUTD',
          'name': 'Singapore University of Technology and Design'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1277244399,
          'name': 'Zhun Zhong'
        },
        {
          'id': 1028988623,
          'name': 'Liang Zheng'
        },
        {
          'id': 1447279511,
          'name': 'Zhiming Luo'
        },
        {
          'id': 1438105862,
          'name': 'Shaozi Li'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040109696,
          'name': 'Invariant (physics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhong_Invariance_Matters_Exemplar_Memory_for_Domain_Adaptive_Person_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 451269745,
      'title': 'Revisiting Local Descriptor Based Image-To-Class Measure for Few-Shot Learning',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104068513,
          'abbr': 'ECUST',
          'name': 'East China University of Science and Technology'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1271939470,
          'name': 'Wenbin Li'
        },
        {
          'id': 1038321914,
          'name': 'Lei Wang'
        },
        {
          'id': 1294080867,
          'name': 'Jinglin Xu'
        },
        {
          'id': 1285246091,
          'name': 'Jing Huo'
        },
        {
          'id': 1451404808,
          'name': 'Yang Gao'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Revisiting_Local_Descriptor_Based_Image-To-Class_Measure_for_Few-Shot_Learning_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 451634886,
      'title': 'Visual Tracking via Adaptive Spatially-Regularized Correlation Filters',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1207394926,
          'name': 'Kenan Dai'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1068750469,
          'name': 'Jianhua Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Dai_Visual_Tracking_via_Adaptive_Spatially-Regularized_Correlation_Filters_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 451951663,
      'title': 'Tightness-aware Evaluation Protocol for Scene Text Detection.',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1232954503,
          'name': 'Yuliang Liu'
        },
        {
          'id': 1031430118,
          'name': 'Lianwen Jin'
        },
        {
          'id': 1476601701,
          'name': 'Zecheng Xie'
        },
        {
          'id': 1213407681,
          'name': 'Canjie Luo'
        },
        {
          'id': 1358515506,
          'name': 'Shuaitao Zhang'
        },
        {
          'id': 1015705237,
          'name': 'Lele Xie'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Tightness-Aware_Evaluation_Protocol_for_Scene_Text_Detection_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 452618284,
      'title': 'Learning Unsupervised Video Object Segmentation Through Visual Attention',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1016014144,
          'name': 'Hongmei Song'
        },
        {
          'id': 1025396997,
          'name': 'Shuyang Zhao'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1394770802,
          'name': 'Sanyuan Zhao'
        },
        {
          'id': 1424107531,
          'name': 'Steven C.H. Hoi'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Learning_Unsupervised_Video_Object_Segmentation_Through_Visual_Attention_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 453050629,
      'title': 'Interaction-And-Aggregation Network for Person Re-Identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1150296935,
          'name': 'Ruibing Hou'
        },
        {
          'id': 1296981365,
          'name': 'Bingpeng Ma'
        },
        {
          'id': 1467937951,
          'name': 'Hong Chang'
        },
        {
          'id': 1450893536,
          'name': 'Xinqian Gu'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Hou_Interaction-And-Aggregation_Network_for_Person_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 454769814,
      'title': 'An Efficient Schmidt-EKF for 3D Visual-Inertial SLAM',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        }
      ],
      'author': [
        {
          'id': 1353585745,
          'name': 'Patrick Geneva'
        },
        {
          'id': 1056248919,
          'name': 'James Maley'
        },
        {
          'id': 1333075190,
          'name': 'Guoquan Huang'
        }
      ],
      'field': [
        {
          'id': 2017802098,
          'name': 'Extended Kalman filter'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019436259,
          'name': 'Inertial frame of reference'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Geneva_An_Efficient_Schmidt-EKF_for_3D_Visual-Inertial_SLAM_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 458215938,
      'title': 'DenseFusion: 6D Object Pose Estimation by Iterative Dense Fusion',
      'affiliation': [
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1005152737,
          'name': 'Chen Wang'
        },
        {
          'id': 1416154646,
          'name': 'Danfei Xu'
        },
        {
          'id': 1309086187,
          'name': 'Yuke Zhu'
        },
        {
          'id': 1057765962,
          'name': 'Roberto Martin-Martin'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        },
        {
          'id': 1383507387,
          'name': 'Li Fei-Fei'
        },
        {
          'id': 1273458277,
          'name': 'Silvio Savarese'
        }
      ],
      'field': [
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_DenseFusion_6D_Object_Pose_Estimation_by_Iterative_Dense_Fusion_CVPR_2019_paper.pdf',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 459104521,
      'title': 'PA3D: Pose-Action 3D Machine for Video Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1003220969,
          'name': 'An Yan'
        },
        {
          'id': 1162163141,
          'name': 'Yali Wang'
        },
        {
          'id': 1030979970,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yan_PA3D_Pose-Action_3D_Machine_for_Video_Recognition_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 459823760,
      'title': 'CapSal: Leveraging Captioning to Boost Semantics for Salient Object Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        }
      ],
      'author': [
        {
          'id': 1256761721,
          'name': 'Lu Zhang'
        },
        {
          'id': 1486537886,
          'name': 'Jianming Zhang'
        },
        {
          'id': 1256462433,
          'name': 'Zhe Lin'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1039635255,
          'name': 'You He'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhang_CapSal_Leveraging_Captioning_to_Boost_Semantics_for_Salient_Object_Detection_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 459982972,
      'title': 'Adaptive Pyramid Context Network for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1104145781,
          'name': 'Junjun He'
        },
        {
          'id': 1223574574,
          'name': 'Zhongying Deng'
        },
        {
          'id': 1001532102,
          'name': 'Lei Zhou'
        },
        {
          'id': 1195612761,
          'name': 'Yali Wang'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/He_Adaptive_Pyramid_Context_Network_for_Semantic_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 460344688,
      'title': 'Local to Global Learning: Gradually Adding Classes for Training Deep Neural Networks.',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1478935516,
          'name': 'Hao Cheng'
        },
        {
          'id': 1406842602,
          'name': 'Dongze Lian'
        },
        {
          'id': 1339430732,
          'name': 'Bowen Deng'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        },
        {
          'id': 1112467840,
          'name': 'Tao Tan'
        },
        {
          'id': 1370114195,
          'name': 'Yanlin Geng'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Cheng_Local_to_Global_Learning_Gradually_Adding_Classes_for_Training_Deep_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 462359391,
      'title': 'Learning Transformation Synchronization',
      'affiliation': [
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1315403600,
          'name': 'Xiangru Huang'
        },
        {
          'id': 1081146375,
          'name': 'Zhenxiao Liang'
        },
        {
          'id': 1143104821,
          'name': 'Xiaowei Zhou'
        },
        {
          'id': 1333314118,
          'name': 'Yao Xie'
        },
        {
          'id': 1471272024,
          'name': 'Leonidas J. Guibas'
        },
        {
          'id': 1022255500,
          'name': 'Qixing Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049721966,
          'name': 'Synchronization'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Huang_Learning_Transformation_Synchronization_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 463142026,
      'title': 'VERI-Wild: A Large Dataset and a New Method for Vehicle Re-Identification in the Wild',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1293722752,
          'name': 'Yihang Lou'
        },
        {
          'id': 1266874881,
          'name': 'Yan Bai'
        },
        {
          'id': 1279547932,
          'name': 'Jun Liu'
        },
        {
          'id': 1397265918,
          'name': 'Shiqi Wang'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Lou_VERI-Wild_A_Large_Dataset_and_a_New_Method_for_Vehicle_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 463362095,
      'title': 'Transferable Interactiveness Knowledge for Human-Object Interaction Detection',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1489183023,
          'name': 'Yonglu Li'
        },
        {
          'id': 1375092337,
          'name': 'Siyuan Zhou'
        },
        {
          'id': 1495476035,
          'name': 'Xijie Huang'
        },
        {
          'id': 1008217488,
          'name': 'Liang Xu'
        },
        {
          'id': 1309337637,
          'name': 'Ze Ma'
        },
        {
          'id': 1268667380,
          'name': 'Hao-Shu Fang'
        },
        {
          'id': 1071340546,
          'name': 'Yan-Feng Wang'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Transferable_Interactiveness_Knowledge_for_Human-Object_Interaction_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 465380962,
      'title': 'DAVANet: Stereo Deblurring With View Aggregation',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1205594209,
          'name': 'Shangchen Zhou'
        },
        {
          'id': 1487109651,
          'name': 'Jiawei Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1310409822,
          'name': 'Haozhe Xie'
        },
        {
          'id': 1363750438,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1446742884,
          'name': 'Jimmy S. J. Ren'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 466405282,
      'title': 'RENAS: Reinforced Evolutionary Neural Architecture Search',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1372998335,
          'name': 'Yukang Chen'
        },
        {
          'id': 1296467528,
          'name': 'Gaofeng Meng'
        },
        {
          'id': 1005388313,
          'name': 'Qian Zhang'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1197066356,
          'name': 'Chang Huang'
        },
        {
          'id': 1436059863,
          'name': 'Lisen Mu'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_RENAS_Reinforced_Evolutionary_Neural_Architecture_Search_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 466777093,
      'title': 'A Site Model Based Change Detection Method for SAR Images',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1346259816,
          'name': 'Wei Wang'
        },
        {
          'id': 1194391775,
          'name': 'Jianhua Shi'
        },
        {
          'id': 1461557858,
          'name': 'Lingjun Zhao'
        },
        {
          'id': 1488410627,
          'name': 'Xingwei Yan'
        }
      ],
      'field': [
        {
          'id': 2011282698,
          'name': 'Change detection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CEFRL/Wang_A_Site_Model_Based_Change_Detection_Method_for_SAR_Images_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 467374612,
      'title': 'Deep Asymmetric Metric Learning via Rich Relationship Mining',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1251324326,
          'name': 'Xinyi Xu'
        },
        {
          'id': 1043705082,
          'name': 'Yanhua Yang'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1082380717,
          'name': 'Feng Zheng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xu_Deep_Asymmetric_Metric_Learning_via_Rich_Relationship_Mining_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 468247113,
      'title': 'Audio-Visual Interpretable and Controllable Video Captioning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1434042206,
          'name': 'Yapeng Tian'
        },
        {
          'id': 1253347157,
          'name': 'Chenxiao Guan'
        },
        {
          'id': 1267744717,
          'name': 'Goodman Justin'
        },
        {
          'id': 1267435538,
          'name': 'Marc Moore'
        },
        {
          'id': 1184802648,
          'name': 'Chenliang Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/Sight and Sound/Yapeng_Tian_Audio-Visual_Interpretable_and_Controllable_Video_Captioning_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 468427174,
      'title': '2D-3D Heterogeneous Face Recognition Based on Deep Coupled Spectral Regression',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1155257596,
          'name': 'Yangtao Zheng'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1064045542,
          'name': 'Weixin Li'
        },
        {
          'id': 1150758946,
          'name': 'Shupeng Wang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/AMFG/Zheng_2D-3D_Heterogeneous_Face_Recognition_Based_on_Deep_Coupled_Spectral_Regression_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 469290365,
      'title': 'Crowd Counting and Density Estimation by Trellis Encoder-Decoder Networks',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103578022,
          'abbr': 'UB',
          'name': 'University at Buffalo'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        }
      ],
      'author': [
        {
          'id': 1126641962,
          'name': 'Xiaolong Jiang'
        },
        {
          'id': 1330307465,
          'name': 'Zehao Xiao'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1348093805,
          'name': 'Xiantong Zhen'
        },
        {
          'id': 1025923725,
          'name': 'Xianbin Cao'
        },
        {
          'id': 1199764397,
          'name': 'David Doermann'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2041409623,
          'name': 'Density estimation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Jiang_Crowd_Counting_and_Density_Estimation_by_Trellis_Encoder-Decoder_Networks_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 469812332,
      'title': 'Noise-Tolerant Paradigm for Training Face Recognition CNNs',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101907037,
          'abbr': 'BUCT',
          'name': 'Beijing University of Chemical Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1258536746,
          'name': 'Wei Hu'
        },
        {
          'id': 1214896906,
          'name': 'Yangyu Huang'
        },
        {
          'id': 1320546158,
          'name': 'Fan Zhang'
        },
        {
          'id': 1028314915,
          'name': 'Ruirui Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Hu_Noise-Tolerant_Paradigm_for_Training_Face_Recognition_CNNs_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 473867412,
      'title': 'Normalized Object Coordinate Space for Category-Level 6D Object Pose and Size Estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102440343,
          'abbr': 'MPG',
          'name': 'Max Planck Society'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        }
      ],
      'author': [
        {
          'id': 1024604554,
          'name': 'He Wang'
        },
        {
          'id': 1047225030,
          'name': 'Srinath Sridhar'
        },
        {
          'id': 1469997256,
          'name': 'Jingwei Huang'
        },
        {
          'id': 1413113011,
          'name': 'Julien Valentin'
        },
        {
          'id': 1151030967,
          'name': 'Shuran Song'
        },
        {
          'id': 1471272024,
          'name': 'Leonidas J. Guibas'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045970411,
          'name': 'Coordinate space'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://geometry.stanford.edu/projects/NOCS_CVPR2019/',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 476765914,
      'title': 'Learning Object-Wise Semantic Representation for Detection in Remote Sensing Imagery',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103831083,
          'abbr': 'UQ',
          'name': 'University of Queensland'
        }
      ],
      'author': [
        {
          'id': 1357420109,
          'name': 'Chengzheng Li'
        },
        {
          'id': 1290256705,
          'name': 'Chunyan Xu'
        },
        {
          'id': 1249663582,
          'name': 'Zhen Cui'
        },
        {
          'id': 1002760099,
          'name': 'Dan Wang'
        },
        {
          'id': 1403252987,
          'name': 'Zequn Jie'
        },
        {
          'id': 1053600132,
          'name': 'Tong Zhang'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002878760,
          'name': 'Learning object'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/DOAI/Li_Learning_Object-Wise_Semantic_Representation_for_Detection_in_Remote_Sensing_Imagery_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 476936817,
      'title': 'Generalising Fine-Grained Sketch-Based Image Retrieval',
      'affiliation': [
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100780330,
          'abbr': 'ED',
          'name': 'University of Edinburgh'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        }
      ],
      'author': [
        {
          'id': 1329943217,
          'name': 'Kaiyue Pang'
        },
        {
          'id': 1291380510,
          'name': 'Ke Li'
        },
        {
          'id': 1274491871,
          'name': 'Yongxin Yang'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1360648697,
          'name': 'Yi-Zhe Song'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Pang_Generalising_Fine-Grained_Sketch-Based_Image_Retrieval_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 477716467,
      'title': 'Adaptively Connected Neural Networks',
      'affiliation': [
        {
          'id': 2102398193,
          'abbr': '',
          'name': 'Guangzhou Higher Education Mega Center'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1098700751,
          'name': 'Guangrun Wang'
        },
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'https://arxiv.org/pdf/1904.03579.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 478544345,
      'title': 'Social Relation Recognition From Videos via Multi-Scale Spatial-Temporal Reasoning',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1083107883,
          'name': 'Xinchen Liu'
        },
        {
          'id': 1334736635,
          'name': 'Wu Liu'
        },
        {
          'id': 1005795863,
          'name': 'Meng Zhang'
        },
        {
          'id': 1032551736,
          'name': 'Jingwen Chen'
        },
        {
          'id': 1425700944,
          'name': 'Lianli Gao'
        },
        {
          'id': 1058477447,
          'name': 'Chenggang Yan'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035516905,
          'name': 'Social relation'
        },
        {
          'id': 2023601856,
          'name': 'Spatial–temporal reasoning'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Social_Relation_Recognition_From_Videos_via_Multi-Scale_Spatial-Temporal_Reasoning_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 479083432,
      'title': 'Orthogonal Decomposition Network for Pixel-Wise Binary Classification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1347623422,
          'name': 'Chang Liu'
        },
        {
          'id': 1437119785,
          'name': 'Fang Wan'
        },
        {
          'id': 1030826032,
          'name': 'Wei Ke'
        },
        {
          'id': 1127112921,
          'name': 'Zhuowei Xiao'
        },
        {
          'id': 1001587154,
          'name': 'Yuan Yao'
        },
        {
          'id': 1106930038,
          'name': 'Xiaosong Zhang'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Orthogonal_Decomposition_Network_for_Pixel-Wise_Binary_Classification_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 481434629,
      'title': 'Direct Object Recognition Without Line-Of-Sight Using Optical Coherence',
      'affiliation': [
        {
          'id': 2104530293,
          'abbr': 'UW',
          'name': 'University of Wisconsin-Madison'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1062694805,
          'name': 'Xin Lei'
        },
        {
          'id': 1224075795,
          'name': 'Liangyu He'
        },
        {
          'id': 1289437306,
          'name': 'Yixuan Tan'
        },
        {
          'id': 1071723527,
          'name': 'Ken Xingze Wang'
        },
        {
          'id': 1239442845,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1245258664,
          'name': 'Yihan Du'
        },
        {
          'id': 1334662964,
          'name': 'Shanhui Fan'
        },
        {
          'id': 1178799464,
          'name': 'Zongfu Yu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2040870080,
          'name': 'Line-of-sight'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Lei_Direct_Object_Recognition_Without_Line-Of-Sight_Using_Optical_Coherence_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 48189185,
      'title': 'Deep Embedding Learning With Discriminative Sampling Policy',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1495536695,
          'name': 'Yueqi Duan'
        },
        {
          'id': 1347590099,
          'name': 'Lei Chen'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1155099615,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Duan_Deep_Embedding_Learning_With_Discriminative_Sampling_Policy_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 483233925,
      'title': 'Arbitrary Shape Scene Text Detection With Adaptive Text Region Representation',
      'affiliation': [
        {
          'id': 2104553663,
          'abbr': '',
          'name': 'Samsung'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1308999596,
          'name': 'Xiaobing Wang'
        },
        {
          'id': 1133015142,
          'name': 'Yingying Jiang'
        },
        {
          'id': 1074532305,
          'name': 'Zhenbo Luo'
        },
        {
          'id': 1429067054,
          'name': 'Cheng-Lin Liu'
        },
        {
          'id': 1397649411,
          'name': 'Hyunsoo Choi'
        },
        {
          'id': 1086158210,
          'name': 'Sungjin Kim'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Arbitrary_Shape_Scene_Text_Detection_With_Adaptive_Text_Region_Representation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 483279875,
      'title': 'PCAN: 3D Attention Map Learning Using Contextual Information for Point Cloud Based Retrieval',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1155450412,
          'name': 'Wenxiao Zhang'
        },
        {
          'id': 1048335762,
          'name': 'Chunxia Xiao'
        }
      ],
      'field': [
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_PCAN_3D_Attention_Map_Learning_Using_Contextual_Information_for_Point_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 485593841,
      'title': 'Masked Graph Attention Network for Person Re-Identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1447894751,
          'name': 'Liqiang Bao'
        },
        {
          'id': 1296981365,
          'name': 'Bingpeng Ma'
        },
        {
          'id': 1467937951,
          'name': 'Hong Chang'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/TRMTMCT/Bao_Masked_Graph_Attention_Network_for_Person_Re-Identification_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 485700260,
      'title': 'Cyclic Guidance for Weakly Supervised Joint Detection and Segmentation',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1142344163,
          'name': 'Yunhang Shen'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1026941031,
          'name': 'Yan Wang'
        },
        {
          'id': 1478890822,
          'name': 'Yongjian Wu'
        },
        {
          'id': 1121245329,
          'name': 'Liujuan Cao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Shen_Cyclic_Guidance_for_Weakly_Supervised_Joint_Detection_and_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 485876072,
      'title': 'C-MIL: Continuation Multiple Instance Learning for Weakly Supervised Object Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1437119785,
          'name': 'Fang Wan'
        },
        {
          'id': 1347623422,
          'name': 'Chang Liu'
        },
        {
          'id': 1355568057,
          'name': 'Wei Ke'
        },
        {
          'id': 1145500222,
          'name': 'Xiangyang Ji'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2048582641,
          'name': 'Continuation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wan_C-MIL_Continuation_Multiple_Instance_Learning_for_Weakly_Supervised_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 488890488,
      'title': 'Learning Spatio-Temporal Representation With Local and Global Diffusion',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1348886811,
          'name': 'Zhaofan Qiu'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1114237355,
          'name': 'Chong-Wah Ngo'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Qiu_Learning_Spatio-Temporal_Representation_With_Local_and_Global_Diffusion_CVPR_2019_paper.html',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 48986706,
      'title': 'Robustness Verification of Classification Deep Neural Networks via Linear Programming',
      'affiliation': [
        {
          'id': 2103743992,
          'abbr': 'WZU',
          'name': 'Wenzhou University'
        },
        {
          'id': 2100414475,
          'abbr': 'ECNU',
          'name': 'East China Normal University'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2102597892,
          'abbr': 'SWU',
          'name': 'Southwest University'
        }
      ],
      'author': [
        {
          'id': 1138383892,
          'name': 'Wang Lin'
        },
        {
          'id': 1066395663,
          'name': 'Zhengfeng Yang'
        },
        {
          'id': 1425382390,
          'name': 'Xin Chen'
        },
        {
          'id': 1495692080,
          'name': 'Qingye Zhao'
        },
        {
          'id': 1032200501,
          'name': 'Xiangkun Li'
        },
        {
          'id': 1136675270,
          'name': 'Zhiming Liu'
        },
        {
          'id': 1171069261,
          'name': 'Jifeng He'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Lin_Robustness_Verification_of_Classification_Deep_Neural_Networks_via_Linear_Programming_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 490319188,
      'title': 'Learning Channel-Wise Interactions for Binary Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1499286484,
          'name': 'Ziwei Wang'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1323787113,
          'name': 'Chenxin Tao'
        },
        {
          'id': 1085154187,
          'name': 'Jie Zhou'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Learning_Channel-Wise_Interactions_for_Binary_Convolutional_Neural_Networks_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 490432985,
      'title': 'Curls & Whey: Boosting Black-Box Adversarial Attacks.',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1230815599,
          'name': 'Yucheng Shi'
        },
        {
          'id': 1052920312,
          'name': 'Siyu Wang'
        },
        {
          'id': 1015841651,
          'name': 'Yahong Han'
        }
      ],
      'field': [
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2024299838,
          'name': 'Black box (phreaking)'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 49145244,
      'title': 'Progressive Pose Attention Transfer for Person Image Generation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1392321334,
          'name': 'Zhen Zhu'
        },
        {
          'id': 1245431160,
          'name': 'Tengteng Huang'
        },
        {
          'id': 1154870261,
          'name': 'Baoguang Shi'
        },
        {
          'id': 1003779449,
          'name': 'Miao Yu'
        },
        {
          'id': 1308172095,
          'name': 'Bofei Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1904.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 495416549,
      'title': 'Live Demonstration: Real-Time Vi-SLAM With High-Resolution Event Camera',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1363472208,
          'name': 'Gongyu Yang'
        },
        {
          'id': 1012649550,
          'name': 'Qilin Ye'
        },
        {
          'id': 1131114280,
          'name': 'Wanjun He'
        },
        {
          'id': 1012469625,
          'name': 'Lifeng Zhou'
        },
        {
          'id': 1188247672,
          'name': 'Xinyu Chen'
        },
        {
          'id': 1000852138,
          'name': 'Lei Yu'
        },
        {
          'id': 1376097180,
          'name': 'Wen Yang'
        },
        {
          'id': 1056896242,
          'name': 'Shoushun Chen'
        },
        {
          'id': 1000038819,
          'name': 'Wei Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/EventVision/Yang_Live_Demonstration_Real-Time_Vi-SLAM_With_High-Resolution_Event_Camera_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 495769845,
      'title': 'Detailed Human Shape Estimation From a Single Image by Hierarchical Mesh Deformation',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1011894368,
          'name': 'Hao Zhu'
        },
        {
          'id': 1403459598,
          'name': 'Xinxin Zuo'
        },
        {
          'id': 1066945238,
          'name': 'Sen Wang'
        },
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhu_Detailed_Human_Shape_Estimation_From_a_Single_Image_by_Hierarchical_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 496453048,
      'title': 'Robust Visual Tracking via Collaborative and Reinforced Convolutional Feature Learning',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1312771884,
          'name': 'Dongdong Li'
        },
        {
          'id': 1445310476,
          'name': 'Yangliu Kuai'
        },
        {
          'id': 1110480004,
          'name': 'Gongjian Wen'
        },
        {
          'id': 1000064940,
          'name': 'Li Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CEFRL/Li_Robust_Visual_Tracking_via_Collaborative_and_Reinforced_Convolutional_Feature_Learning_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 498979696,
      'title': 'Spherical Fractal Convolutional Neural Networks for Point Cloud Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1223346775,
          'name': 'Yongming Rao'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1085154187,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2004631569,
          'name': 'Fractal'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Rao_Spherical_Fractal_Convolutional_Neural_Networks_for_Point_Cloud_Recognition_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 50599313,
      'title': 'Efficient Deep Palmprint Recognition via Distilled Hashing Coding',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1353091094,
          'name': 'Huikai Shao'
        },
        {
          'id': 1429689534,
          'name': 'Dexing Zhong'
        },
        {
          'id': 1101350602,
          'name': 'Xuefeng Du'
        }
      ],
      'field': [
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CEFRL/Shao_Efficient_Deep_Palmprint_Recognition_via_Distilled_Hashing_Coding_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 5115723,
      'title': 'Building Explainable AI Evaluation for Autonomous Perception',
      'affiliation': [
        {
          'id': 2103626749,
          'abbr': 'EDUCATION',
          'name': 'Ministry of Education'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1324963189,
          'name': 'Chi Zhang'
        },
        {
          'id': 1337933082,
          'name': 'Biyao Shang'
        },
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1063765828,
          'name': 'Li Li'
        },
        {
          'id': 1065458831,
          'name': 'Yuehu Liu'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/Explainable_AI/Zhang_Building_Explainable_AI_Evaluation_for_Autonomous_Perception_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 51233734,
      'title': 'Ray-Space Projection Model for Light Field Camera',
      'affiliation': [
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1366357804,
          'name': 'Qi Zhang'
        },
        {
          'id': 1333543650,
          'name': 'Jinbo Ling'
        },
        {
          'id': 1375869814,
          'name': 'Qing Wang'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021507899,
          'name': 'Light-field camera'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhang_Ray-Space_Projection_Model_for_Light_Field_Camera_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 51344759,
      'title': 'Learning Metrics From Teachers: Compact Networks for Image Embedding',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100853613,
          'abbr': 'UAB',
          'name': 'Autonomous University of Barcelona'
        },
        {
          'id': 2100924597,
          'abbr': 'CSIC',
          'name': 'Spanish National Research Council'
        }
      ],
      'author': [
        {
          'id': 1492978045,
          'name': 'Lu Yu'
        },
        {
          'id': 1265726050,
          'name': 'Vacit Oguz Yazici'
        },
        {
          'id': 1477712362,
          'name': 'Xialei Liu'
        },
        {
          'id': 1479818478,
          'name': 'Joost van de Weijer'
        },
        {
          'id': 1225691858,
          'name': 'Yongmei Cheng'
        },
        {
          'id': 1267987843,
          'name': 'Arnau Ramisa'
        }
      ],
      'field': [
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yu_Learning_Metrics_From_Teachers_Compact_Networks_for_Image_Embedding_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 51786079,
      'title': 'Iterative Normalization: Beyond Standardization towards Efficient Whitening.',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2102959018,
          'abbr': '',
          'name': 'New York University Abu Dhabi'
        }
      ],
      'author': [
        {
          'id': 1300509348,
          'name': 'Lei Huang'
        },
        {
          'id': 1128806697,
          'name': 'Yi Zhou'
        },
        {
          'id': 1037247309,
          'name': 'Fan Zhu'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002832735,
          'name': 'Standardization'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Huang_Iterative_Normalization_Beyond_Standardization_Towards_Efficient_Whitening_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 5234706,
      'title': 'Weakly Supervised Complementary Parts Models for Fine-Grained Image Classification from the Bottom Up.',
      'affiliation': [
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1060638418,
          'name': 'Weifeng Ge'
        },
        {
          'id': 1435649774,
          'name': 'Xiangru Lin'
        },
        {
          'id': 1419663498,
          'name': 'Yizhou Yu'
        }
      ],
      'field': [
        {
          'id': 2004379144,
          'name': 'Top-down and bottom-up design'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Ge_Weakly_Supervised_Complementary_Parts_Models_for_Fine-Grained_Image_Classification_From_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 53309159,
      'title': 'A Robust Local Spectral Descriptor for Matching Non-Rigid Shapes With Incompatible Shape Structures',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1003372978,
          'name': 'Yiqun Wang'
        },
        {
          'id': 1228700627,
          'name': 'Jianwei Guo'
        },
        {
          'id': 1032675923,
          'name': 'Dong-Ming Yan'
        },
        {
          'id': 1000329809,
          'name': 'Kai Wang'
        },
        {
          'id': 1493506431,
          'name': 'Xiaopeng Zhang'
        }
      ],
      'field': [
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2000198985,
          'name': 'Triangulation (social science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_A_Robust_Local_Spectral_Descriptor_for_Matching_Non-Rigid_Shapes_With_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 53423340,
      'title': 'Scalable Convolutional Neural Network for Image Compressed Sensing',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1222045399,
          'name': 'Wuzhen Shi'
        },
        {
          'id': 1117510171,
          'name': 'Feng Jiang'
        },
        {
          'id': 1355802438,
          'name': 'Shaohui Liu'
        },
        {
          'id': 1149572625,
          'name': 'Debin Zhao'
        }
      ],
      'field': [
        {
          'id': 2048710313,
          'name': 'Compressed sensing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Shi_Scalable_Convolutional_Neural_Network_for_Image_Compressed_Sensing_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 54109141,
      'title': 'Progressive Teacher-Student Learning for Early Action Prediction',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103751681,
          'abbr': 'DUNDEE',
          'name': 'University of Dundee'
        }
      ],
      'author': [
        {
          'id': 1080437455,
          'name': 'Xionghui Wang'
        },
        {
          'id': 1225752713,
          'name': 'Jian-Fang Hu'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1255955000,
          'name': 'Jianguo Zhang'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033866315,
          'name': 'Mathematics education'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Progressive_Teacher-Student_Learning_for_Early_Action_Prediction_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 55609919,
      'title': 'UnOS: Unified Unsupervised Optical-Flow and Stereo-Depth Estimation by Watching Videos',
      'affiliation': [
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1007755032,
          'name': 'Yang Wang'
        },
        {
          'id': 1226191267,
          'name': 'Peng Wang'
        },
        {
          'id': 1076949431,
          'name': 'Zhenheng Yang'
        },
        {
          'id': 1091827554,
          'name': 'Chenxu Luo'
        },
        {
          'id': 1154969954,
          'name': 'Yi Yang'
        },
        {
          'id': 1383270866,
          'name': 'Wei Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Wang_UnOS_Unified_Unsupervised_Optical-Flow_and_Stereo-Depth_Estimation_by_Watching_Videos_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 57212534,
      'title': 'Variational Bayesian Dropout With a Hierarchical Prior',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1383369244,
          'name': 'Yuhang Liu'
        },
        {
          'id': 1026133965,
          'name': 'Wenyong Dong'
        },
        {
          'id': 1386051449,
          'name': 'Lei Zhang'
        },
        {
          'id': 1151308559,
          'name': 'Dong Gong'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Variational_Bayesian_Dropout_With_a_Hierarchical_Prior_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 57789668,
      'title': 'Pose2Seg: Detection Free Human Instance Segmentation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1059439771,
          'name': 'Song-Hai Zhang'
        },
        {
          'id': 1202178136,
          'name': 'Ruilong Li'
        },
        {
          'id': 1163766026,
          'name': 'Xin Dong'
        },
        {
          'id': 1293598313,
          'name': 'Paul L. Rosin'
        },
        {
          'id': 1055766245,
          'name': 'Zixi Cai'
        },
        {
          'id': 1062169813,
          'name': 'Xi Han'
        },
        {
          'id': 1431770135,
          'name': 'Dingcheng Yang'
        },
        {
          'id': 1169917948,
          'name': 'Hao-Zhi Huang'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Pose2Seg_Detection_Free_Human_Instance_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 58905526,
      'title': 'Deep Reinforcement Learning of Volume-Guided Progressive View Inpainting for 3D Point Scene Completion From a Single Depth Image',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100577709,
          'abbr': 'TAMU',
          'name': 'Texas A&M University'
        }
      ],
      'author': [
        {
          'id': 1076070771,
          'name': 'Xiaoguang Han'
        },
        {
          'id': 1081282732,
          'name': 'Zhaoxuan Zhang'
        },
        {
          'id': 1226472046,
          'name': 'Dong Du'
        },
        {
          'id': 1352815369,
          'name': 'Mingdai Yang'
        },
        {
          'id': 1344209733,
          'name': 'Jingming Yu'
        },
        {
          'id': 1037817478,
          'name': 'Pan Pan'
        },
        {
          'id': 1026161454,
          'name': 'Xin Yang'
        },
        {
          'id': 1297670722,
          'name': 'Ligang Liu'
        },
        {
          'id': 1396393786,
          'name': 'Zixiang Xiong'
        },
        {
          'id': 1279948632,
          'name': 'Shuguang Cui'
        }
      ],
      'field': [
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Han_Deep_Reinforcement_Learning_of_Volume-Guided_Progressive_View_Inpainting_for_3D_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 59237351,
      'title': 'Multi-Modal Face Anti-Spoofing Attack Detection Challenge at CVPR2019',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102976531,
          'abbr': 'UB',
          'name': 'University of Barcelona'
        },
        {
          'id': 2103895481,
          'abbr': 'INAOE',
          'name': 'National Institute of Astrophysics, Optics and Electronics'
        },
        {
          'id': 2103774576,
          'abbr': 'MUST',
          'name': 'Macau University of Science and Technology'
        },
        {
          'id': 2102522667,
          'abbr': 'WVU',
          'name': 'West Virginia University'
        },
        {
          'id': 2102813173,
          'abbr': 'U-PSUD',
          'name': 'University of Paris-Sud'
        }
      ],
      'author': [
        {
          'id': 1387942647,
          'name': 'Ajian Liu'
        },
        {
          'id': 1119293493,
          'name': 'Jun Wan'
        },
        {
          'id': 1011188708,
          'name': 'Sergio Escalera'
        },
        {
          'id': 1012327536,
          'name': 'Hugo Jair Escalante'
        },
        {
          'id': 1399110820,
          'name': 'Zichang Tan'
        },
        {
          'id': 1033230361,
          'name': 'Qi Yuan'
        },
        {
          'id': 1000329809,
          'name': 'Kai Wang'
        },
        {
          'id': 1194398010,
          'name': 'Chi Lin'
        },
        {
          'id': 1030692633,
          'name': 'Guodong Guo'
        },
        {
          'id': 1025130881,
          'name': 'Isabelle Guyon'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2028022761,
          'name': 'Spoofing attack'
        },
        {
          'id': 2028889101,
          'name': 'Modal'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CFS/Liu_Multi-Modal_Face_Anti-Spoofing_Attack_Detection_Challenge_at_CVPR2019_CVPRW_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 6276609,
      'title': 'RDO-based Secondary Prediction Scheme for Image Compression',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1105233963,
          'name': 'Hongkui Wang'
        },
        {
          'id': 1287763268,
          'name': 'Junhui Liang'
        },
        {
          'id': 1388581785,
          'name': 'Yamei Chen'
        },
        {
          'id': 1342791524,
          'name': 'Hailang Yang'
        },
        {
          'id': 1281625529,
          'name': 'Shengwei Wang'
        },
        {
          'id': 1395505586,
          'name': 'Li Yu'
        },
        {
          'id': 1009529870,
          'name': 'Ning Wang'
        },
        {
          'id': 1054728967,
          'name': 'Zhengang Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CLIC 2019/Wang_RDO-based_Secondary_Prediction_Scheme_for_Image_Compression_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 63614302,
      'title': 'Hybrid Task Cascade for Instance Segmentation.',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1455410857,
          'name': 'Kai Chen'
        },
        {
          'id': 1060757048,
          'name': 'Jiangmiao Pang'
        },
        {
          'id': 1226253248,
          'name': 'Jiaqi Wang'
        },
        {
          'id': 1049254588,
          'name': 'Yu Xiong'
        },
        {
          'id': 1068966988,
          'name': 'Xiaoxiao Li'
        },
        {
          'id': 1262506962,
          'name': 'Shuyang Sun'
        },
        {
          'id': 1275003576,
          'name': 'Wansen Feng'
        },
        {
          'id': 1267956808,
          'name': 'Ziwei Liu'
        },
        {
          'id': 1224758535,
          'name': 'Jianping Shi'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1205290384,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1149174550,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_Hybrid_Task_Cascade_for_Instance_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 66783617,
      'title': 'Collaborative Learning of Semi-Supervised Segmentation and Classification for Medical Images',
      'affiliation': [
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100556387,
          'abbr': 'IMF',
          'name': 'International Monetary Fund'
        },
        {
          'id': 2102959018,
          'abbr': '',
          'name': 'New York University Abu Dhabi'
        }
      ],
      'author': [
        {
          'id': 1128806697,
          'name': 'Yi Zhou'
        },
        {
          'id': 1006185566,
          'name': 'Xiaodong He'
        },
        {
          'id': 1300509348,
          'name': 'Lei Huang'
        },
        {
          'id': 1202070516,
          'name': 'Li Liu'
        },
        {
          'id': 1037247309,
          'name': 'Fan Zhu'
        },
        {
          'id': 1300944061,
          'name': 'Shanshan Cui'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2048628453,
          'name': 'Collaborative learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhou_Collaborative_Learning_of_Semi-Supervised_Segmentation_and_Classification_for_Medical_Images_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 70011204,
      'title': 'Universal Domain Adaptation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1444918301,
          'name': 'Kaichao You'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1126860916,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1226959733,
          'name': 'Michael I. Jordan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/You_Universal_Domain_Adaptation_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 71692217,
      'title': 'Layout-Graph Reasoning for Fashion Landmark Detection',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1270778895,
          'name': 'Weijiang Yu'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1045211755,
          'name': 'Ke Gong'
        },
        {
          'id': 1037103622,
          'name': 'Chenhan Jiang'
        },
        {
          'id': 1415508527,
          'name': 'Nong Xiao'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yu_Layout-Graph_Reasoning_for_Fashion_Landmark_Detection_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 71790177,
      'title': 'Learned Image Restoration for VVC Intra Coding',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1002625423,
          'name': 'Ming Lu'
        },
        {
          'id': 1212631297,
          'name': 'Tong Chen'
        },
        {
          'id': 1366948388,
          'name': 'Haojie Liu'
        },
        {
          'id': 1049262418,
          'name': 'Zhan Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/CLIC 2019/Lu_Learned_Image_Restoration_for_VVC_Intra_Coding_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 72611088,
      'title': 'Machine Vision Guided 3D Medical Image Compression for Efficient Transmission and Accurate Segmentation in the Clouds',
      'affiliation': [
        {
          'id': 2104436163,
          'abbr': 'FIU',
          'name': 'Florida International University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102508462,
          'abbr': 'NU',
          'name': 'Northeastern University'
        },
        {
          'id': 2102771059,
          'abbr': 'ND',
          'name': 'University of Notre Dame'
        },
        {
          'id': 2104725373,
          'abbr': 'ACMEDSCI',
          'name': 'Academy of Medical Sciences, United Kingdom'
        }
      ],
      'author': [
        {
          'id': 1358557622,
          'name': 'Zihao Liu'
        },
        {
          'id': 1234068900,
          'name': 'Xiaowei Xu'
        },
        {
          'id': 1013121973,
          'name': 'Tao Liu'
        },
        {
          'id': 1121513928,
          'name': 'Qi Liu'
        },
        {
          'id': 1209358418,
          'name': 'Yanzhi Wang'
        },
        {
          'id': 1433008519,
          'name': 'Yiyu Shi'
        },
        {
          'id': 1231709705,
          'name': 'Wujie Wen'
        },
        {
          'id': 1333574693,
          'name': 'Meiping Huang'
        },
        {
          'id': 1349062953,
          'name': 'Haiyun Yuan'
        },
        {
          'id': 1035603759,
          'name': 'Jian-zhuang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        },
        {
          'id': 2034848742,
          'name': 'Machine vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Machine_Vision_Guided_3D_Medical_Image_Compression_for_Efficient_Transmission_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 72658440,
      'title': 'Aggregation Cross-Entropy for Sequence Recognition',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1476601701,
          'name': 'Zecheng Xie'
        },
        {
          'id': 1270137426,
          'name': 'Yaoxiong Huang'
        },
        {
          'id': 1414306286,
          'name': 'Yuanzhi Zhu'
        },
        {
          'id': 1276589951,
          'name': 'Lianwen Jin'
        },
        {
          'id': 1232954503,
          'name': 'Yuliang Liu'
        },
        {
          'id': 1015705237,
          'name': 'Lele Xie'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024303337,
          'name': 'Cross entropy'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xie_Aggregation_Cross-Entropy_for_Sequence_Recognition_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 73926557,
      'title': 'AOGNets: Compositional Grammatical Architectures for Deep Learning',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104096522,
          'abbr': 'NCSU',
          'name': 'North Carolina State University'
        }
      ],
      'author': [
        {
          'id': 1058084241,
          'name': 'Xilai Li'
        },
        {
          'id': 1404890702,
          'name': 'Xi Song'
        },
        {
          'id': 1245313663,
          'name': 'Tianfu Wu'
        }
      ],
      'field': [
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_AOGNets_Compositional_Grammatical_Architectures_for_Deep_Learning_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 76439940,
      'title': 'RegularFace: Deep Face Recognition via Exclusive Regularization',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1034237242,
          'name': 'Kai Zhao'
        },
        {
          'id': 1040112137,
          'name': 'Jingyi. Xu'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhao_RegularFace_Deep_Face_Recognition_via_Exclusive_Regularization_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 77277536,
      'title': 'HPLFlowNet: Hierarchical Permutohedral Lattice FlowNet for Scene Flow Estimation on Large-Scale Point Clouds',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100263420,
          'abbr': 'UCD',
          'name': 'University of California, Davis'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        }
      ],
      'author': [
        {
          'id': 1296753835,
          'name': 'Xiuye Gu'
        },
        {
          'id': 1143859699,
          'name': 'Yijie Wang'
        },
        {
          'id': 1145616575,
          'name': 'Chongruo Wu'
        },
        {
          'id': 1150486798,
          'name': 'Yong Jae Lee'
        },
        {
          'id': 1040241939,
          'name': 'Panqu Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2021556405,
          'name': 'Lattice (order)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030514377,
          'name': 'Flownet'
        },
        {
          'id': 2032713836,
          'name': 'Flow (psychology)'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gu_HPLFlowNet_Hierarchical_Permutohedral_Lattice_FlowNet_for_Scene_Flow_Estimation_on_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 77999765,
      'title': 'Graph Convolutional Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1115592857,
          'name': 'Junyu Gao'
        },
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gao_Graph_Convolutional_Tracking_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 79203413,
      'title': 'Orientation-Aware Deep Neural Network for Real Image Super-Resolution',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1037112890,
          'name': 'Chen Du'
        },
        {
          'id': 1172569287,
          'name': 'He Zewei'
        },
        {
          'id': 1224879261,
          'name': 'Sun Anshun'
        },
        {
          'id': 1083982225,
          'name': 'Yang Jiangxin'
        },
        {
          'id': 1157602598,
          'name': 'Cao Yanlong'
        },
        {
          'id': 1495409824,
          'name': 'Cao Yanpeng'
        },
        {
          'id': 1161497386,
          'name': 'Tang Siliang'
        },
        {
          'id': 1118290301,
          'name': 'Michael Ying Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/NTIRE/Du_Orientation-Aware_Deep_Neural_Network_for_Real_Image_Super-Resolution_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 81159180,
      'title': 'Pyramid Feature Attention Network for Saliency Detection',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1353167217,
          'name': 'Ting Zhao'
        },
        {
          'id': 1326824858,
          'name': 'Xiangqian Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhao_Pyramid_Feature_Attention_Network_for_Saliency_Detection_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 81223050,
      'title': 'Modeling Point Clouds with Self-Attention and Gumbel Subset Sampling.',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1326026836,
          'name': 'Jiancheng Yang'
        },
        {
          'id': 1143493834,
          'name': 'Qiang Zhang'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1118220897,
          'name': 'Linguo Li'
        },
        {
          'id': 1356747537,
          'name': 'Jinxian Liu'
        },
        {
          'id': 1271269845,
          'name': 'Mengdie Zhou'
        },
        {
          'id': 1238691319,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2044450670,
          'name': 'Gumbel distribution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_Modeling_Point_Clouds_With_Self-Attention_and_Gumbel_Subset_Sampling_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 82973288,
      'title': 'Pairwise Teacher-Student Network for Semi-Supervised Hashing',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1078446449,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1259560054,
          'name': 'JianMin Li'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/CEFRL/Zhang_Pairwise_Teacher-Student_Network_for_Semi-Supervised_Hashing_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 83017662,
      'title': 'Second-Order Attention Network for Single Image Super-Resolution',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1124950818,
          'name': 'Tao Dai'
        },
        {
          'id': 1205383682,
          'name': 'Jianrui Cai'
        },
        {
          'id': 1001790681,
          'name': 'Yongbing Zhang'
        },
        {
          'id': 1387424504,
          'name': 'Shu-Tao Xia'
        },
        {
          'id': 1001386854,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Dai_Second-Order_Attention_Network_for_Single_Image_Super-Resolution_CVPR_2019_paper.html',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 85689513,
      'title': 'Centripetal SGD for Pruning Very Deep Convolutional Networks With Complicated Structure',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104488735,
          'abbr': 'LANCASTER',
          'name': 'Lancaster University'
        }
      ],
      'author': [
        {
          'id': 1180593596,
          'name': 'Xiaohan Ding'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1383996063,
          'name': 'Yuchen Guo'
        },
        {
          'id': 1153306339,
          'name': 'Jungong Han'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043625761,
          'name': 'Centripetal force'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035276397,
          'name': 'Pruning'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Ding_Centripetal_SGD_for_Pruning_Very_Deep_Convolutional_Networks_With_Complicated_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 8614074,
      'title': 'ROI Pooled Correlation Filters for Visual Tracking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1038842392,
          'name': 'Yuxuan Sun'
        },
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1256138439,
          'name': 'Nithar Ranjan Madhu'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Sun_ROI_Pooled_Correlation_Filters_for_Visual_Tracking_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 86417215,
      'title': 'Weakly Supervised Person Re-Identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1117210074,
          'name': 'Jingke Meng'
        },
        {
          'id': 1000020132,
          'name': 'Sheng Wu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Meng_Weakly_Supervised_Person_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 87260063,
      'title': 'Libra R-CNN: Towards Balanced Learning for Object Detection',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1060757048,
          'name': 'Jiangmiao Pang'
        },
        {
          'id': 1455410857,
          'name': 'Kai Chen'
        },
        {
          'id': 1224758535,
          'name': 'Jianping Shi'
        },
        {
          'id': 1041143053,
          'name': 'Huajun Feng'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1149174550,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pang_Libra_R-CNN_Towards_Balanced_Learning_for_Object_Detection_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 8735916,
      'title': 'Light Field Super-Resolution: A Benchmark',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1026547204,
          'name': 'Zhen Cheng'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1082179902,
          'name': 'Chang Chen'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/NTIRE/Cheng_Light_Field_Super-Resolution_A_Benchmark_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 88834857,
      'title': 'A Variational Pan-Sharpening With Local Gradient Constraints',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1083964890,
          'name': 'Xueyang Fu'
        },
        {
          'id': 1147490313,
          'name': 'Zihuang Lin'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020237083,
          'name': 'Sharpening'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Fu_A_Variational_Pan-Sharpening_With_Local_Gradient_Constraints_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 90091943,
      'title': 'Learning Shape-Aware Embedding for Scene Text Detection',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1133663965,
          'name': 'Zhuotao Tian'
        },
        {
          'id': 1269363513,
          'name': 'Michelle Shu'
        },
        {
          'id': 1433542305,
          'name': 'Pengyuan Lyu'
        },
        {
          'id': 1227076135,
          'name': 'Ruiyu Li'
        },
        {
          'id': 1003516509,
          'name': 'Chao Zhou'
        },
        {
          'id': 1021240487,
          'name': 'Xiaoyong Shen'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Tian_Learning_Shape-Aware_Embedding_for_Scene_Text_Detection_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 90732105,
      'title': 'Weakly Supervised Open-Set Domain Adaptation by Dual-Domain Collaboration',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1322904641,
          'name': 'Shuhan Tan'
        },
        {
          'id': 1226618701,
          'name': 'Jiening Jiao'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Tan_Weakly_Supervised_Open-Set_Domain_Adaptation_by_Dual-Domain_Collaboration_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 91083309,
      'title': 'Attention-Aware Multi-Stroke Style Transfer',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102926590,
          'abbr': 'NUAA',
          'name': 'Nanjing University of Aeronautics and Astronautics'
        }
      ],
      'author': [
        {
          'id': 1001587154,
          'name': 'Yuan Yao'
        },
        {
          'id': 1247976449,
          'name': 'Jianqiang Ren'
        },
        {
          'id': 1442207129,
          'name': 'Xuansong Xie'
        },
        {
          'id': 1009765598,
          'name': 'Weidong Liu'
        },
        {
          'id': 1212426579,
          'name': 'Yong-Jin Liu'
        },
        {
          'id': 1397940631,
          'name': 'Jun Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2002134091,
          'name': 'Stroke'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yao_Attention-Aware_Multi-Stroke_Style_Transfer_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 91498831,
      'title': 'Pyramid Feature Attention Network for Saliency Detection',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1353167217,
          'name': 'Ting Zhao'
        },
        {
          'id': 1326824858,
          'name': 'Xiangqian Wu'
        }
      ],
      'field': [
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhao_Pyramid_Feature_Attention_Network_for_Saliency_Detection_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 94435453,
      'title': 'Adapting Object Detectors via Selective Cross-Domain Alignment',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1208823144,
          'name': 'Xinge Zhu'
        },
        {
          'id': 1060757048,
          'name': 'Jiangmiao Pang'
        },
        {
          'id': 1368066969,
          'name': 'Ceyuan Yang'
        },
        {
          'id': 1224758535,
          'name': 'Jianping Shi'
        },
        {
          'id': 1149174550,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhu_Adapting_Object_Detectors_via_Selective_Cross-Domain_Alignment_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 97695896,
      'title': 'Pyramid Convolutional Network for Single Image Deraining',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1182917054,
          'name': 'Jing Zhao'
        },
        {
          'id': 1371741039,
          'name': 'Jiyu Xie'
        },
        {
          'id': 1293244045,
          'name': 'Ruiqin Xiong'
        },
        {
          'id': 1364235909,
          'name': 'Siwei Ma'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134064471',
        'name': 'CVPR',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/UG2+ Prize Challenge/Zhao_Pyramid_Convolutional_Network_for_Single_Image_Deraining_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 102044378,
      'title': 'ADCrowdNet: An Attention-Injective Deformable Convolutional Network for Crowd Understanding',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1013500427,
          'name': 'Ning Liu'
        },
        {
          'id': 1391332692,
          'name': 'Yongchao Long'
        },
        {
          'id': 1136464600,
          'name': 'Changqing Zou'
        },
        {
          'id': 1004384317,
          'name': 'Qun Niu'
        },
        {
          'id': 1033303559,
          'name': 'Li Pan'
        },
        {
          'id': 1496570999,
          'name': 'Hefeng Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036297028,
          'name': 'Injective function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_ADCrowdNet_An_Attention-Injective_Deformable_Convolutional_Network_for_Crowd_Understanding_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 102194796,
      'title': 'Memory Matching Networks for One-Shot Image Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103644482,
          'abbr': 'HDU',
          'name': 'Hangzhou Dianzi University'
        }
      ],
      'author': [
        {
          'id': 1140511387,
          'name': 'Qi Cai'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1246526630,
          'name': 'Chenggang Yan'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034082534,
          'name': 'Unified Model'
        },
        {
          'id': 2000717699,
          'name': 'Knowledge engineering'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2002093614,
          'name': 'On the fly'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 9,
      'reference_count': 31
    },
    {
      'id': 102913167,
      'title': 'Revisiting Dilated Convolution: A Simple Approach for Weakly- and Semi-Supervised Semantic Segmentation',
      'affiliation': [
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1305989134,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1047018509,
          'name': 'Huaxin Xiao'
        },
        {
          'id': 1209822350,
          'name': 'Honghui Shi'
        },
        {
          'id': 1273266408,
          'name': 'Zequn Jie'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2013716316,
          'name': 'Dilation (morphology)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 41
    },
    {
      'id': 107517738,
      'title': 'Image Correction via Deep Reciprocating HDR Transformation',
      'affiliation': [
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1131651203,
          'name': 'Xin Yang'
        },
        {
          'id': 1329658043,
          'name': 'Ke Xu'
        },
        {
          'id': 1355309202,
          'name': 'Yibing Song'
        },
        {
          'id': 1075635062,
          'name': 'Qiang Zhang'
        },
        {
          'id': 1101786650,
          'name': 'Xiaopeng Wei'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2043955330,
          'name': 'Dynamic range'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041313107,
          'name': 'Tone mapping'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034573784,
          'name': 'Image formation'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042633047,
          'name': 'Reciprocating motion'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 38
    },
    {
      'id': 107739363,
      'title': 'SketchMate: Deep Hashing for Million-Scale Human Sketch Retrieval',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2100780330,
          'abbr': 'ED',
          'name': 'University of Edinburgh'
        }
      ],
      'author': [
        {
          'id': 1165012984,
          'name': 'Peng Xu'
        },
        {
          'id': 1051656923,
          'name': 'Yongye Huang'
        },
        {
          'id': 1352496634,
          'name': 'Tongtong Yuan'
        },
        {
          'id': 1329943217,
          'name': 'Kaiyue Pang'
        },
        {
          'id': 1276350016,
          'name': 'Yi-Zhe Song'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1376499826,
          'name': 'Zhanyu Ma'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2007773467,
          'name': 'Sketch recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018927283,
          'name': 'Testbed'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 23
    },
    {
      'id': 110098326,
      'title': 'Deep RNN Framework for Visual Sequential Applications',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1002196202,
          'name': 'Bo Pang'
        },
        {
          'id': 1271840088,
          'name': 'Kaiwen Zha'
        },
        {
          'id': 1219983959,
          'name': 'Hanwen Cao'
        },
        {
          'id': 1006738992,
          'name': 'Chen Shi'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pang_Deep_RNN_Framework_for_Visual_Sequential_Applications_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 110480095,
      'title': 'Attentive Generative Adversarial Network for Raindrop Removal from A Single Image',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1245424907,
          'name': 'Rui Qian'
        },
        {
          'id': 1276044969,
          'name': 'Robby T. Tan'
        },
        {
          'id': 1077278755,
          'name': 'Wenhan Yang'
        },
        {
          'id': 1470369375,
          'name': 'Jiajun Su'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2008563812,
          'name': 'Lens (optics)'
        },
        {
          'id': 2041894794,
          'name': 'Local consistency'
        },
        {
          'id': 2007587561,
          'name': 'Camera lens'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 5,
      'reference_count': 21
    },
    {
      'id': 110685462,
      'title': 'Variational Autoencoders for Deforming 3D Mesh Models',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1374222687,
          'name': 'Qingyang Tan'
        },
        {
          'id': 1354158678,
          'name': 'Lin Gao'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        },
        {
          'id': 1439972712,
          'name': 'Shihong Xia'
        }
      ],
      'field': [
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2043875830,
          'name': 'Computer animation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 47
    },
    {
      'id': 112870528,
      'title': 'Multi-frame Quality Enhancement for Compressed Video',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1044394617,
          'name': 'Ren Yang'
        },
        {
          'id': 1118664104,
          'name': 'Mai Xu'
        },
        {
          'id': 1287578436,
          'name': 'Zulin Wang'
        },
        {
          'id': 1476671974,
          'name': 'Tianyi Li'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040758323,
          'name': 'Subnet'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2030410974,
          'name': 'Motion compensation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010620100,
          'name': 'Compression artifact'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 36
    },
    {
      'id': 116111984,
      'title': 'Edit Probability for Scene Text Recognition',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1351963402,
          'name': 'Fan Bai'
        },
        {
          'id': 1152243443,
          'name': 'Zhanzhan Cheng'
        },
        {
          'id': 1130921084,
          'name': 'Yi Niu'
        },
        {
          'id': 1384883042,
          'name': 'Shiliang Pu'
        },
        {
          'id': 1494078016,
          'name': 'Shuigeng Zhou'
        }
      ],
      'field': [
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 34
    },
    {
      'id': 116261210,
      'title': 'Explicit Loss-Error-Aware Quantization for Low-Bit Deep Neural Networks',
      'affiliation': [
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1054625859,
          'name': 'Aojun Zhou'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1316872796,
          'name': 'Kuan Wang'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2012191016,
          'name': 'Ternary operation'
        },
        {
          'id': 2039464793,
          'name': 'Quantization (physics)'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029612372,
          'name': 'Approximation error'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 0
    },
    {
      'id': 116335947,
      'title': 'Cascaded Pyramid Network for Multi-person Pose Estimation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1081688086,
          'name': 'Yilun Chen'
        },
        {
          'id': 1002782225,
          'name': 'Zhicheng Wang'
        },
        {
          'id': 1144979659,
          'name': 'Yuxiang Peng'
        },
        {
          'id': 1434291055,
          'name': 'Zhiqiang Zhang'
        },
        {
          'id': 1416154934,
          'name': 'Gang Yu'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 69,
      'reference_count': 7
    },
    {
      'id': 11649716,
      'title': 'Deep Unsupervised Saliency Detection: A Multiple Noisy Labeling Perspective',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1086021836,
          'name': 'Jing Zhang'
        },
        {
          'id': 1266553407,
          'name': 'Tong Zhang'
        },
        {
          'id': 1441298781,
          'name': 'Yuchao Daf'
        },
        {
          'id': 1490172637,
          'name': 'Mehrtash Tafazzoli Harandi'
        },
        {
          'id': 1140300658,
          'name': 'Richard I. Hartley'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 44
    },
    {
      'id': 11697269,
      'title': 'Occluded Pedestrian Detection Through Guided Attention in CNNs',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1415996572,
          'name': 'Shanshan Zhang'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        },
        {
          'id': 1010759743,
          'name': 'Bernt Schiele'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 0
    },
    {
      'id': 117555643,
      'title': 'Feature Selective Networks for Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1354544146,
          'name': 'Yao Zhai'
        },
        {
          'id': 1015860143,
          'name': 'Jingjing Fu'
        },
        {
          'id': 1060816602,
          'name': 'Yan Lu'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2023746547,
          'name': 'Aspect ratio (image)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042225087,
          'name': 'Subnetwork'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 38
    },
    {
      'id': 118710220,
      'title': 'Trust Region Based Adversarial Attack on Neural Networks',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1166276439,
          'name': 'Zhewei Yao'
        },
        {
          'id': 1402400513,
          'name': 'Amir Gholami'
        },
        {
          'id': 1304563650,
          'name': 'Peng Xu'
        },
        {
          'id': 1021865461,
          'name': 'Kurt Keutzer'
        },
        {
          'id': 1030308717,
          'name': 'Michael W. Mahoney'
        }
      ],
      'field': [
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027967038,
          'name': 'Trust region'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yao_Trust_Region_Based_Adversarial_Attack_on_Neural_Networks_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 11943240,
      'title': 'M3: Multimodal Memory Modelling for Video Captioning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1107891216,
          'name': 'Junbo Wang'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040778548,
          'name': 'Auxiliary memory'
        },
        {
          'id': 2014457327,
          'name': 'BLEU'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010662768,
          'name': 'Memory model'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2015197265,
          'name': 'Shared memory'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 41
    },
    {
      'id': 119639638,
      'title': 'A Decomposition Algorithm for the Sparse Generalized Eigenvalue Problem',
      'affiliation': [
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1123863574,
          'name': 'Ganzhao Yuan'
        },
        {
          'id': 1420511133,
          'name': 'Li Shen'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2048015713,
          'name': 'Eigendecomposition of a matrix'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yuan_A_Decomposition_Algorithm_for_the_Sparse_Generalized_Eigenvalue_Problem_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 120229263,
      'title': 'CSRNet: Dilated Convolutional Neural Networks for Understanding the Highly Congested Scenes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1246627075,
          'name': 'Yuhong Li'
        },
        {
          'id': 1363690899,
          'name': 'Xiaofan Zhang'
        },
        {
          'id': 1202515373,
          'name': 'Deming Chen'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2032372603,
          'name': 'Mean absolute error'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 31
    },
    {
      'id': 120402083,
      'title': 'Exploring Disentangled Feature Representation Beyond Face Identification',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1058037770,
          'name': 'Yu Liu'
        },
        {
          'id': 1165172349,
          'name': 'Fangyin Wei'
        },
        {
          'id': 1495848024,
          'name': 'Jing Shao'
        },
        {
          'id': 1099193953,
          'name': 'Lu Sheng'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028685291,
          'name': 'Minimal supervision'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 15
    },
    {
      'id': 121077758,
      'title': 'Structured Attention Guided Convolutional Neural Fields for Monocular Depth Estimation',
      'affiliation': [
        {
          'id': 2104935093,
          'abbr': 'UNITN',
          'name': 'University of Trento'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1033898246,
          'name': 'Dan Xu'
        },
        {
          'id': 1291461363,
          'name': 'Wei Wang'
        },
        {
          'id': 1478124809,
          'name': 'Hao Tang'
        },
        {
          'id': 1422060233,
          'name': 'Hong Liu'
        },
        {
          'id': 1304196418,
          'name': 'Nicu Sebe'
        },
        {
          'id': 1265701882,
          'name': 'Elisa Ricci'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2036255561,
          'name': 'CRFS'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 8,
      'reference_count': 37
    },
    {
      'id': 122433860,
      'title': 'Multi-shot Pedestrian Re-identification via Sequential Decision Making',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1200257162,
          'name': 'Jianfu Zhang'
        },
        {
          'id': 1128137696,
          'name': 'Naiyan Wang'
        },
        {
          'id': 1195526895,
          'name': 'Liqing Zhang'
        }
      ],
      'field': [
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2007812099,
          'name': 'Time series'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 37
    },
    {
      'id': 122750994,
      'title': 'Learning Attentions: Residual Attentional Siamese Network for High Performance Online Visual Tracking',
      'affiliation': [
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104876881,
          'abbr': 'BBK',
          'name': 'Birkbeck, University of London'
        }
      ],
      'author': [
        {
          'id': 1021140366,
          'name': 'Qiang Wang'
        },
        {
          'id': 1442894463,
          'name': 'Zhu Teng'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1436742442,
          'name': 'Jin Gao'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1242761004,
          'name': 'Stephen J. Maybank'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030909330,
          'name': 'Adaptability'
        },
        {
          'id': 2017318404,
          'name': 'Frame rate'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 0
    },
    {
      'id': 123768923,
      'title': 'Deep Cauchy Hashing for Hamming Space Retrieval',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1027988051,
          'name': 'Yue Cao'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1148092358,
          'name': 'Bin Liu'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        }
      ],
      'field': [
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046455943,
          'name': 'Hamming distance'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 0
    },
    {
      'id': 124991119,
      'title': 'Human Action Adverb Recognition: ADHA Dataset and a Three-Stream Hybrid Model',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1002196202,
          'name': 'Bo Pang'
        },
        {
          'id': 1271840088,
          'name': 'Kaiwen Zha'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2000503217,
          'name': 'Emotion recognition'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027431641,
          'name': 'Commit'
        },
        {
          'id': 2015448983,
          'name': 'Adverb'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w48/Pang_Human_Action_Adverb_CVPR_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 37
    },
    {
      'id': 127306463,
      'title': 'Joint Cuts and Matching of Partitions in One Graph',
      'affiliation': [
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100610652,
          'abbr': 'UTH',
          'name': 'University of Texas Health Science Center at Houston'
        }
      ],
      'author': [
        {
          'id': 1025138269,
          'name': 'Tianshu Yu'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1178468700,
          'name': 'Jieyi Zhao'
        },
        {
          'id': 1360566008,
          'name': 'Baoxin Li'
        }
      ],
      'field': [
        {
          'id': 2007914973,
          'name': 'Quartic graph'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016959849,
          'name': 'Graph power'
        },
        {
          'id': 2044947287,
          'name': 'Butterfly graph'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2041729208,
          'name': 'Graph factorization'
        },
        {
          'id': 2040634048,
          'name': 'Factor-critical graph'
        },
        {
          'id': 2016278136,
          'name': 'Voltage graph'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035244343,
          'name': 'Strength of a graph'
        },
        {
          'id': 2046002229,
          'name': 'Simplex graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 26
    },
    {
      'id': 127565027,
      'title': 'Deep Regression Forests for Age Estimation',
      'affiliation': [
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1100920997,
          'name': 'Yilu Guo'
        },
        {
          'id': 1204480417,
          'name': 'Yan Wang'
        },
        {
          'id': 1463521460,
          'name': 'Kai Zhao'
        },
        {
          'id': 1374123895,
          'name': 'Bo Wang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040723423,
          'name': 'Nonlinear regression'
        },
        {
          'id': 2035071765,
          'name': 'Technical report'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 48
    },
    {
      'id': 129951559,
      'title': 'Taking a Closer Look at Domain Shift: Category-Level Adversaries for Semantics Consistent Domain Adaptation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1288722613,
          'name': 'Yawei Luo'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1435687262,
          'name': 'Tao Guan'
        },
        {
          'id': 1483834528,
          'name': 'Junqing Yu'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Luo_Taking_a_Closer_Look_at_Domain_Shift_Category-Level_Adversaries_for_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 13072728,
      'title': 'A PID Controller Approach for Stochastic Optimization of Deep Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1177943372,
          'name': 'Wangpeng An'
        },
        {
          'id': 1098536622,
          'name': 'Haoqian Wang'
        },
        {
          'id': 1097405261,
          'name': 'Qingyun Sun'
        },
        {
          'id': 1446421712,
          'name': 'Jun Xu'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2013997165,
          'name': 'PID controller'
        },
        {
          'id': 2023861424,
          'name': 'Control theory'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011992298,
          'name': 'Control engineering'
        },
        {
          'id': 2022475187,
          'name': 'Stochastic optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019693047,
          'name': 'Overshoot (signal)'
        },
        {
          'id': 2023905290,
          'name': 'Automatic control'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 0
    },
    {
      'id': 130960663,
      'title': 'CSRNet: Dilated Convolutional Neural Networks for Understanding the Highly Congested Scenes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1246627075,
          'name': 'Yuhong Li'
        },
        {
          'id': 1363690899,
          'name': 'Xiaofan Zhang'
        },
        {
          'id': 1202515373,
          'name': 'Deming Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2032372603,
          'name': 'Mean absolute error'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 23,
      'reference_count': 44
    },
    {
      'id': 132656759,
      'title': 'GSPN: Generative Shape Proposal Network for 3D Instance Segmentation in Point Cloud',
      'affiliation': [
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1194319412,
          'name': 'Li Yi'
        },
        {
          'id': 1000730444,
          'name': 'Wang Zhao'
        },
        {
          'id': 1024604554,
          'name': 'He Wang'
        },
        {
          'id': 1264954975,
          'name': 'Minhyuk Sung'
        },
        {
          'id': 1471272024,
          'name': 'Leonidas J. Guibas'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yi_GSPN_Generative_Shape_Proposal_Network_for_3D_Instance_Segmentation_in_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 13404416,
      'title': 'Towards Faster Training of Global Covariance Pooling Networks by Iterative Matrix Square Root Normalization',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1190238743,
          'name': 'Jiangtao Xie'
        },
        {
          'id': 1484711233,
          'name': 'Qilong Wang'
        },
        {
          'id': 1394956158,
          'name': 'Zilin Gao'
        }
      ],
      'field': [
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2048015713,
          'name': 'Eigendecomposition of a matrix'
        },
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2000280526,
          'name': 'Symmetric matrix'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2002289677,
          'name': 'Square root of a matrix'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 8,
      'reference_count': 36
    },
    {
      'id': 135141266,
      'title': 'Gait Recognition by Deformable Registration',
      'affiliation': [
        {
          'id': 2103675905,
          'abbr': 'OSAKA-U',
          'name': 'Osaka University'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1328353770,
          'name': 'Yasushi Makihara'
        },
        {
          'id': 1127250309,
          'name': 'Daisuke Adachi'
        },
        {
          'id': 1208754176,
          'name': 'Chi Xu'
        },
        {
          'id': 1268234615,
          'name': 'Yasushi Yagi'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2013094599,
          'name': 'Gait'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005083615,
          'name': 'STRIDE'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w11/Makihara_Gait_Recognition_by_CVPR_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 136405673,
      'title': 'Persistent Memory Residual Network for Single Image Super Resolution',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1448795566,
          'name': 'Rong Chen'
        },
        {
          'id': 1309298089,
          'name': 'Yanyun Qu'
        },
        {
          'id': 1415533632,
          'name': 'Kun Zeng'
        },
        {
          'id': 1027991065,
          'name': 'Jinkang Guo'
        },
        {
          'id': 1111389283,
          'name': 'Cuihua Li'
        },
        {
          'id': 1157583500,
          'name': 'Yuan Xie'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2045937712,
          'name': 'Bicubic interpolation'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w13/Chen_Persistent_Memory_Residual_CVPR_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 137889027,
      'title': 'Joint Pose and Expression Modeling for Facial Expression Recognition',
      'affiliation': [
        {
          'id': 2103769856,
          'abbr': 'UJS',
          'name': 'Jiangsu University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1425614489,
          'name': 'Feifei Zhang'
        },
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1130662993,
          'name': 'Qirong Mao'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2015992372,
          'name': 'Qualitative Evaluations'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 0
    },
    {
      'id': 138598751,
      'title': 'Resource Aware Person Re-identification Across Multiple Resolutions',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1193438847,
          'name': 'Yan Wang'
        },
        {
          'id': 1033548683,
          'name': 'Lequn Wang'
        },
        {
          'id': 1217544667,
          'name': 'Yurong You'
        },
        {
          'id': 1110834122,
          'name': 'Xu Zou'
        },
        {
          'id': 1253464845,
          'name': 'Vincent Chen'
        },
        {
          'id': 1202505251,
          'name': 'Serena Li'
        },
        {
          'id': 1366951731,
          'name': 'Gao Huang'
        },
        {
          'id': 1419046009,
          'name': 'Bharath Hariharan'
        },
        {
          'id': 1159231599,
          'name': 'Kilian Q. Weinberger'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2007742396,
          'name': 'Trade-off'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 36
    },
    {
      'id': 138722785,
      'title': 'Convolutional Neural Networks with Alternately Updated Clique',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1114296133,
          'name': 'Yibo Yang'
        },
        {
          'id': 1229351626,
          'name': 'Zhisheng Zhong'
        },
        {
          'id': 1124655160,
          'name': 'Tiancheng Shen'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        }
      ],
      'field': [
        {
          'id': 2047564696,
          'name': 'Input/output'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2003528380,
          'name': 'Clique'
        },
        {
          'id': 2036252551,
          'name': 'Information flow (information theory)'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 43
    },
    {
      'id': 138884324,
      'title': 'Structured Attention Guided Convolutional Neural Fields for Monocular Depth Estimation',
      'affiliation': [
        {
          'id': 2104935093,
          'abbr': 'UNITN',
          'name': 'University of Trento'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1033898246,
          'name': 'Dan Xu'
        },
        {
          'id': 1291461363,
          'name': 'Wei Wang'
        },
        {
          'id': 1478124809,
          'name': 'Hao Tang'
        },
        {
          'id': 1422060233,
          'name': 'Hong Liu'
        },
        {
          'id': 1304196418,
          'name': 'Nicu Sebe'
        },
        {
          'id': 1491360264,
          'name': 'Elisa Ricci'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2036255561,
          'name': 'CRFS'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 33
    },
    {
      'id': 141034843,
      'title': 'Learning Descriptor Networks for 3D Shape Synthesis and Analysis',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1336211972,
          'name': 'Jianwen Xie'
        },
        {
          'id': 1315752410,
          'name': 'Zilong Zheng'
        },
        {
          'id': 1129662997,
          'name': 'Ruiqi Gao'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        },
        {
          'id': 1128144622,
          'name': 'Ying Nian Wu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2041477979,
          'name': 'Shape analysis (digital geometry)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016541624,
          'name': 'Speech coding'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023666742,
          'name': 'Markov chain Monte Carlo'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2002625218,
          'name': 'Langevin dynamics'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 27
    },
    {
      'id': 141186527,
      'title': 'Learning to Parse Wireframes in Images of Man-Made Environments',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2102205365,
          'abbr': 'PSU',
          'name': 'Pennsylvania State University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1092503922,
          'name': 'Kun Huang'
        },
        {
          'id': 1399476423,
          'name': 'Yifan Wang'
        },
        {
          'id': 1258889174,
          'name': 'Zihan Zhou'
        },
        {
          'id': 1177115580,
          'name': 'Tianjiao Ding'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2004058443,
          'name': 'Line segment'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 141305273,
      'title': 'Recurrent Residual Module for Fast Inference in Videos',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1026595821,
          'name': 'Bowen Pan'
        },
        {
          'id': 1406248313,
          'name': 'Wuwei Lin'
        },
        {
          'id': 1277749712,
          'name': 'Xiaolin Fang'
        },
        {
          'id': 1465365372,
          'name': 'Chaoqin Huang'
        },
        {
          'id': 1194297590,
          'name': 'Bolei Zhou'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2015932993,
          'name': 'Inference engine'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 59
    },
    {
      'id': 146949269,
      'title': 'Unsupervised Cross-Dataset Person Re-identification by Transfer Learning of Spatial-Temporal Patterns',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1154819179,
          'name': 'Jianming Lv'
        },
        {
          'id': 1376279573,
          'name': 'Weihang Chen'
        },
        {
          'id': 1488101603,
          'name': 'Qing Li'
        },
        {
          'id': 1430666615,
          'name': 'Can Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2018978572,
          'name': 'Incremental learning'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 8,
      'reference_count': 35
    },
    {
      'id': 147231639,
      'title': 'Human Action Adverb Recognition: ADHA Dataset and a Three-Stream Hybrid Model',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1002196202,
          'name': 'Bo Pang'
        },
        {
          'id': 1271840088,
          'name': 'Kaiwen Zha'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2000503217,
          'name': 'Emotion recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046020565,
          'name': 'Labelling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015448983,
          'name': 'Adverb'
        },
        {
          'id': 2027431641,
          'name': 'Commit'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 28
    },
    {
      'id': 147416276,
      'title': 'SSNet: Scale Selection Network for Online 3D Action Prediction',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1279547932,
          'name': 'Jun Liu'
        },
        {
          'id': 1447085714,
          'name': 'Amir Shahroudy'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1314678280,
          'name': 'Alex C. Kot'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2007819124,
          'name': 'Microsoft Windows'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 0
    },
    {
      'id': 147521005,
      'title': 'Deep Hashing via Discrepancy Minimization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1334971498,
          'name': 'Zhixiang Chen'
        },
        {
          'id': 1068878246,
          'name': 'Xin Yuan'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2029562301,
          'name': 'Integer programming'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2005133827,
          'name': 'Discrete optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005808282,
          'name': 'Binary constraint'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 147738647,
      'title': 'SketchMate: Deep Hashing for Million-Scale Human Sketch Retrieval',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2100780330,
          'abbr': 'ED',
          'name': 'University of Edinburgh'
        }
      ],
      'author': [
        {
          'id': 1165012984,
          'name': 'Peng Xu'
        },
        {
          'id': 1051656923,
          'name': 'Yongye Huang'
        },
        {
          'id': 1352496634,
          'name': 'Tongtong Yuan'
        },
        {
          'id': 1329943217,
          'name': 'Kaiyue Pang'
        },
        {
          'id': 1276350016,
          'name': 'Yi-Zhe Song'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1376499826,
          'name': 'Zhanyu Ma'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        }
      ],
      'field': [
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2007773467,
          'name': 'Sketch recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 29
    },
    {
      'id': 148481660,
      'title': 'Recurrent Residual Module for Fast Inference in Videos',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1026595821,
          'name': 'Bowen Pan'
        },
        {
          'id': 1406248313,
          'name': 'Wuwei Lin'
        },
        {
          'id': 1277749712,
          'name': 'Xiaolin Fang'
        },
        {
          'id': 1465365372,
          'name': 'Chaoqin Huang'
        },
        {
          'id': 1436314989,
          'name': 'Bolei Zhou'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2015932993,
          'name': 'Inference engine'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 46
    },
    {
      'id': 151394892,
      'title': 'VizWiz Grand Challenge: Answering Visual Questions from Blind People',
      'affiliation': [
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104172720,
          'abbr': 'UCB',
          'name': 'University of Colorado Boulder'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1091453635,
          'name': 'Danna Gurari'
        },
        {
          'id': 1178760828,
          'name': 'Qing Li'
        },
        {
          'id': 1280518788,
          'name': 'Abigale Stangl'
        },
        {
          'id': 1323926631,
          'name': 'Anhong Guo'
        },
        {
          'id': 1066765949,
          'name': 'Chi Lin'
        },
        {
          'id': 1075935428,
          'name': 'Kristen Grauman'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        },
        {
          'id': 1457233645,
          'name': 'Jeffrey P. Bigham'
        }
      ],
      'field': [
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2015161306,
          'name': 'Mobile phone'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007507776,
          'name': 'Blindness'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 41
    },
    {
      'id': 152402718,
      'title': 'FOTS: Fast Oriented Text Spotting with a Unified Network',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1494826247,
          'name': 'Xuebo Liu'
        },
        {
          'id': 1038374316,
          'name': 'Ding Liang'
        },
        {
          'id': 1001529082,
          'name': 'Shi Yan'
        },
        {
          'id': 1049166955,
          'name': 'Dagui Chen'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1126332756,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2022892673,
          'name': 'Spotting'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 42
    },
    {
      'id': 153069679,
      'title': 'CrowdPose: Efficient Crowded Scenes Pose Estimation and a New Benchmark',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1126498682,
          'name': 'Jiefeng Li'
        },
        {
          'id': 1482646191,
          'name': 'Can Wang'
        },
        {
          'id': 1193097986,
          'name': 'Hao Zhu'
        },
        {
          'id': 1069623662,
          'name': 'Yihuan Mao'
        },
        {
          'id': 1488644272,
          'name': 'Hao-Shu Fang'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Li_CrowdPose_Efficient_Crowded_Scenes_Pose_Estimation_and_a_New_Benchmark_CVPR_2019_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 156774394,
      'title': 'Generative Adversarial Perturbations',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1426691386,
          'name': 'Omid Poursaeed'
        },
        {
          'id': 1144470759,
          'name': 'Isay Katsman'
        },
        {
          'id': 1235094784,
          'name': 'Bicheng Gao'
        },
        {
          'id': 1238813530,
          'name': 'Serge J. Belongie'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 58
    },
    {
      'id': 156945524,
      'title': 'CondenseNet: An Efficient DenseNet Using Learned Group Convolutions',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        }
      ],
      'author': [
        {
          'id': 1366951731,
          'name': 'Gao Huang'
        },
        {
          'id': 1170942169,
          'name': 'Shichen Liu'
        },
        {
          'id': 1325400198,
          'name': 'Laurens van der Maaten'
        },
        {
          'id': 1159231599,
          'name': 'Kilian Q. Weinberger'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2019967986,
          'name': 'Computer architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 15,
      'reference_count': 36
    },
    {
      'id': 156951638,
      'title': 'R-FCN-3000 at 30fps: Decoupling Detection and Classification',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2103551367,
          'abbr': 'IITR',
          'name': 'Indian Institute of Technology Roorkee'
        }
      ],
      'author': [
        {
          'id': 1112350808,
          'name': 'Bharat Singh'
        },
        {
          'id': 1218401323,
          'name': 'Hengduo Li'
        },
        {
          'id': 1397439878,
          'name': 'Abhishek Sharma'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039104951,
          'name': 'Decoupling (cosmology)'
        },
        {
          'id': 2047795701,
          'name': 'Linear classifier'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042148111,
          'name': 'Modular design'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 27
    },
    {
      'id': 157294001,
      'title': 'Weakly Supervised Instance Segmentation Using Class Peak Response',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1238435354,
          'name': 'Yanzhao Zhou'
        },
        {
          'id': 1259889084,
          'name': 'Yi Zhu'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1246182727,
          'name': 'Qiang Qiu'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2037197408,
          'name': 'Pointwise'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 42
    },
    {
      'id': 157601173,
      'title': 'Learning Visual Knowledge Memory Networks for Visual Question Answering',
      'affiliation': [
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1332823866,
          'name': 'Zhou Su'
        },
        {
          'id': 1296593637,
          'name': 'Chen Zhu'
        },
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1028557740,
          'name': 'Dongqi Cai'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        }
      ],
      'field': [
        {
          'id': 2049678556,
          'name': 'Knowledge-based systems'
        },
        {
          'id': 2002009818,
          'name': 'Knowledge extraction'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2009659598,
          'name': 'Comprehension'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 46
    },
    {
      'id': 15815228,
      'title': 'Learning Face Age Progression: A Pyramid Architecture of GANs',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103683216,
          'abbr': 'MSU',
          'name': 'Michigan State University'
        }
      ],
      'author': [
        {
          'id': 1323708457,
          'name': 'Hongyu Yang'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1126752003,
          'name': 'Anil K. Jain'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2027478313,
          'name': 'Age progression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040512229,
          'name': 'Quantitative Evaluations'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 32
    },
    {
      'id': 159932576,
      'title': 'Multi-level Wavelet-CNN for Image Restoration',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1155183098,
          'name': 'Pengju Liu'
        },
        {
          'id': 1145045238,
          'name': 'Hongzhi Zhang'
        },
        {
          'id': 1221197508,
          'name': 'Kai Zhang'
        },
        {
          'id': 1058130101,
          'name': 'Liang Lin'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 50
    },
    {
      'id': 16051692,
      'title': 'High-Speed Tracking with Multi-kernel Correlation Filters',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1086300120,
          'name': 'Ming Tang'
        },
        {
          'id': 1000913401,
          'name': 'Bin Yu'
        },
        {
          'id': 1116230964,
          'name': 'Fan Zhang'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034486721,
          'name': 'Upper and lower bounds'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 0
    },
    {
      'id': 161637120,
      'title': 'Path Aggregation Network for Instance Segmentation',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1015055898,
          'name': 'Shu Liu'
        },
        {
          'id': 1000077958,
          'name': 'Lu Qi'
        },
        {
          'id': 1211784386,
          'name': 'Haifang Qin'
        },
        {
          'id': 1081776166,
          'name': 'Jianping Shi'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        }
      ],
      'field': [
        {
          'id': 2036252551,
          'name': 'Information flow (information theory)'
        },
        {
          'id': 2033391627,
          'name': 'Overhead (computing)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 32,
      'reference_count': 64
    },
    {
      'id': 16168017,
      'title': 'Crowd Counting via Adversarial Cross-Scale Consistency Pursuit',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1457815508,
          'name': 'Zan Shen'
        },
        {
          'id': 1124030868,
          'name': 'Yi Xu'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1112955314,
          'name': 'Minsi Wang'
        },
        {
          'id': 1055738615,
          'name': 'Jianguo Hu'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041409623,
          'name': 'Density estimation'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 0
    },
    {
      'id': 162759003,
      'title': 'Correlation Tracking via Joint Discrimination and Reliability Learning',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2003093565,
          'name': 'Fourier transform'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 22
    },
    {
      'id': 163218826,
      'title': 'Multiple Granularity Group Interaction Prediction',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1206127675,
          'name': 'Taiping Yao'
        },
        {
          'id': 1112955314,
          'name': 'Minsi Wang'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1276953135,
          'name': 'Huawei Wei'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 163257985,
      'title': 'Quantization of Fully Convolutional Networks for Accurate Biomedical Image Segmentation',
      'affiliation': [
        {
          'id': 2102771059,
          'abbr': 'ND',
          'name': 'University of Notre Dame'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1142056179,
          'name': 'Xiaowei Xu'
        },
        {
          'id': 1345247356,
          'name': 'Qing Lu'
        },
        {
          'id': 1059377696,
          'name': 'Lin Yang'
        },
        {
          'id': 1267198488,
          'name': 'Sharon Hu'
        },
        {
          'id': 1308347698,
          'name': 'Danny Z. Chen'
        },
        {
          'id': 1239516510,
          'name': 'Yu Hu'
        },
        {
          'id': 1433008519,
          'name': 'Yiyu Shi'
        }
      ],
      'field': [
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2038956318,
          'name': 'Medical imaging'
        },
        {
          'id': 2017041859,
          'name': 'Reduction (complexity)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003010881,
          'name': 'Memory management'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 24
    },
    {
      'id': 163259382,
      'title': 'Learning to Estimate 3D Human Pose and Shape from a Single Color Image',
      'affiliation': [
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1099865504,
          'name': 'Georgios Pavlakos'
        },
        {
          'id': 1481342916,
          'name': 'Luyang Zhu'
        },
        {
          'id': 1330511943,
          'name': 'Xiaowei Zhou'
        },
        {
          'id': 1375446170,
          'name': 'Kostas Daniilidis'
        }
      ],
      'field': [
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024086463,
          'name': 'Rendering (computer graphics)'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 52
    },
    {
      'id': 163277069,
      'title': 'Fully Convolutional Adaptation Networks for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1482027026,
          'name': 'Yiheng Zhang'
        },
        {
          'id': 1348886811,
          'name': 'Zhaofan Qiu'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 35
    },
    {
      'id': 164628989,
      'title': 'End-to-End Flow Correlation Tracking with Spatial-Temporal Attention',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1004795249,
          'name': 'Wei Wu'
        },
        {
          'id': 1268828894,
          'name': 'Wei Zou'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 40
    },
    {
      'id': 164638952,
      'title': 'Weakly Supervised Coupled Networks for Visual Sentiment Analysis',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1003062086,
          'name': 'Jufeng Yang'
        },
        {
          'id': 1086713113,
          'name': 'Dongyu She'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        },
        {
          'id': 1293598313,
          'name': 'Paul L. Rosin'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2012636528,
          'name': 'Sentiment analysis'
        },
        {
          'id': 2032401552,
          'name': 'Abstraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 0
    },
    {
      'id': 164858921,
      'title': 'Deep Mutual Learning',
      'affiliation': [
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2100780330,
          'abbr': 'ED',
          'name': 'University of Edinburgh'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1056841273,
          'name': 'Ying Zhang'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 102,
      'reference_count': 7
    },
    {
      'id': 165684926,
      'title': 'DOTA: A Large-Scale Dataset for Object Detection in Aerial Images',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        },
        {
          'id': 2103747449,
          'abbr': 'UPB',
          'name': 'Politehnica University of Bucharest'
        },
        {
          'id': 2100716060,
          'abbr': 'UNIVE',
          'name': 'Ca\' Foscari University of Venice'
        }
      ],
      'author': [
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1098451795,
          'name': 'Jian Ding'
        },
        {
          'id': 1392321334,
          'name': 'Zhen Zhu'
        },
        {
          'id': 1238813530,
          'name': 'Serge J. Belongie'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        },
        {
          'id': 1247382328,
          'name': 'Mihai Datcu'
        },
        {
          'id': 1388382508,
          'name': 'Marcello Pelillo'
        },
        {
          'id': 1131819447,
          'name': 'Liangpei Zhang'
        }
      ],
      'field': [
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2028120887,
          'name': 'Quadrilateral'
        },
        {
          'id': 2003337676,
          'name': 'Aerial image'
        },
        {
          'id': 2008631642,
          'name': 'Earth observation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001076255,
          'name': 'DOTA'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 9,
      'reference_count': 37
    },
    {
      'id': 166168327,
      'title': 'Beyond Trade-Off: Accelerate FCN-Based Face Detector with Higher Accuracy',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1197873817,
          'name': 'Guanglu Song'
        },
        {
          'id': 1058037770,
          'name': 'Yu Liu'
        },
        {
          'id': 1215318893,
          'name': 'Ming Jiang'
        },
        {
          'id': 1259806560,
          'name': 'Yujie Wang'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1043106211,
          'name': 'Biao Leng'
        }
      ],
      'field': [
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2007742396,
          'name': 'Trade-off'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010616972,
          'name': 'False alarm'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 36
    },
    {
      'id': 166821210,
      'title': 'Learning for Disparity Estimation Through Feature Constancy',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1037734705,
          'name': 'Zhengfa Liang'
        },
        {
          'id': 1477404811,
          'name': 'Yiliu Feng'
        },
        {
          'id': 1206681019,
          'name': 'Yulan Guo'
        },
        {
          'id': 1072692215,
          'name': 'Hengzhu Liu'
        },
        {
          'id': 1069844836,
          'name': 'Wei Chen'
        },
        {
          'id': 1376960313,
          'name': 'Linbo Qiao'
        },
        {
          'id': 1496867942,
          'name': 'Li Zhou'
        },
        {
          'id': 1182628058,
          'name': 'Jianfeng Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2008327062,
          'name': 'Correctness'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 26
    },
    {
      'id': 167106203,
      'title': 'MegDet: A Large Mini-Batch Object Detector',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1125500898,
          'name': 'Tete Xiao'
        },
        {
          'id': 1213309621,
          'name': 'Zeming Li'
        },
        {
          'id': 1314532880,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1104024960,
          'name': 'Kai Jia'
        },
        {
          'id': 1416154934,
          'name': 'Gang Yu'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018246249,
          'name': 'Principal curvature-based region detector'
        },
        {
          'id': 2011945734,
          'name': 'mmap'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 70,
      'reference_count': 34
    },
    {
      'id': 168389911,
      'title': 'Rethinking Feature Distribution for Loss Functions in Image Classification',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1032246936,
          'name': 'Weitao Wan'
        },
        {
          'id': 1275391601,
          'name': 'Yuanyi Zhong'
        },
        {
          'id': 1347211469,
          'name': 'Tianpeng Li'
        },
        {
          'id': 1470618407,
          'name': 'Jiansheng Chen'
        }
      ],
      'field': [
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2048893815,
          'name': 'Mixture distribution'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 31
    },
    {
      'id': 172407883,
      'title': 'Weakly-Supervised Semantic Segmentation by Iteratively Mining Common Object Features',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        }
      ],
      'author': [
        {
          'id': 1362478452,
          'name': 'Xiang Wang'
        },
        {
          'id': 1444358315,
          'name': 'Shaodi You'
        },
        {
          'id': 1126081045,
          'name': 'Xi Li'
        },
        {
          'id': 1144842077,
          'name': 'Huimin Ma'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 32
    },
    {
      'id': 173411373,
      'title': 'Deep Cocktail Network: Multi-source Unsupervised Domain Adaptation with Category Shift',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1173893498,
          'name': 'Ruijia Xu'
        },
        {
          'id': 1275022701,
          'name': 'Ziliang Chen'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1126332756,
          'name': 'Junjie Yan'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2036405579,
          'name': 'Perplexity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025617559,
          'name': 'Multi-source'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 46
    },
    {
      'id': 174942079,
      'title': 'Learning from Noisy Web Data with Category-Level Supervision',
      'affiliation': [
        {
          'id': 2102304039,
          'abbr': 'RICE',
          'name': 'Rice University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1030250014,
          'name': 'Li Niu'
        },
        {
          'id': 1023880377,
          'name': 'Qingtao Tang'
        },
        {
          'id': 1357068268,
          'name': 'Ashok Veeraraghavan'
        },
        {
          'id': 1316064475,
          'name': 'Ashutosh Sabharwal'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2045365530,
          'name': 'Web resource'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2000956888,
          'name': 'Test data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 177825529,
      'title': 'Learning a Single Convolutional Super-Resolution Network for Multiple Degradations',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1221197508,
          'name': 'Kai Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 20,
      'reference_count': 44
    },
    {
      'id': 180049677,
      'title': 'Learning Not to Learn: Training Deep Neural Networks with Biased Data.',
      'affiliation': [
        {
          'id': 2104875451,
          'abbr': 'KAIST',
          'name': 'KAIST'
        },
        {
          'id': 2104553663,
          'abbr': '',
          'name': 'Samsung'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1449377869,
          'name': 'Byungju Kim'
        },
        {
          'id': 1424811639,
          'name': 'Hyun-Woo Kim'
        },
        {
          'id': 1292040938,
          'name': 'Kyungsu Kim'
        },
        {
          'id': 1086158210,
          'name': 'Sungjin Kim'
        },
        {
          'id': 1490901573,
          'name': 'Junmo Kim'
        }
      ],
      'field': [
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Kim_Learning_Not_to_Learn_Training_Deep_Neural_Networks_With_Biased_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 181573682,
      'title': 'Explainable and Explicit Visual Reasoning Over Scene Graphs',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1470324399,
          'name': 'Jiaxin Shi'
        },
        {
          'id': 1424881830,
          'name': 'Hanwang Zhang'
        },
        {
          'id': 1486064558,
          'name': 'Juanzi Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2004092319,
          'name': 'Visual reasoning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Shi_Explainable_and_Explicit_Visual_Reasoning_Over_Scene_Graphs_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 182279655,
      'title': 'Modulated Convolutional Networks',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102005978,
          'abbr': 'NORTHUMBRIA',
          'name': 'Northumbria University'
        }
      ],
      'author': [
        {
          'id': 1141138742,
          'name': 'Xiaodi Wang'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1429251461,
          'name': 'Ce Li'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1153306339,
          'name': 'Jungong Han'
        },
        {
          'id': 1381503284,
          'name': 'Jianzhuang Liu'
        },
        {
          'id': 1025923725,
          'name': 'Xianbin Cao'
        }
      ],
      'field': [
        {
          'id': 2044959998,
          'name': 'Software deployment'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2038996624,
          'name': 'Network model'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2004213994,
          'name': 'Software portability'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004524944,
          'name': 'Modulation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 182845164,
      'title': 'VITAL: VIsual Tracking via Adversarial Learning',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1355309202,
          'name': 'Yibing Song'
        },
        {
          'id': 1254987849,
          'name': 'Chao Ma'
        },
        {
          'id': 1343560243,
          'name': 'Xiaohe Wu'
        },
        {
          'id': 1001892649,
          'name': 'Lijun Gong'
        },
        {
          'id': 1084291466,
          'name': 'Linchao Bao'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2027547384,
          'name': 'Existential quantification'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 13,
      'reference_count': 56
    },
    {
      'id': 184890195,
      'title': 'Smooth Neighbors on Teacher Graphs for Semi-Supervised Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1305012402,
          'name': 'Yucen Luo'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        },
        {
          'id': 1446494334,
          'name': 'Mengxi Li'
        },
        {
          'id': 1251220616,
          'name': 'Yong Ren'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2036679242,
          'name': 'Word error rate'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 11,
      'reference_count': 47
    },
    {
      'id': 186049133,
      'title': 'DSFD: Dual Shot Face Detector',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1154250470,
          'name': 'Jian Li'
        },
        {
          'id': 1156100880,
          'name': 'Yabiao Wang'
        },
        {
          'id': 1451231310,
          'name': 'Changan Wang'
        },
        {
          'id': 1164892754,
          'name': 'Ying Tai'
        },
        {
          'id': 1143500098,
          'name': 'Jianjun Qian'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        },
        {
          'id': 1324652455,
          'name': 'Chengjie Wang'
        },
        {
          'id': 1379138596,
          'name': 'Jilin Li'
        },
        {
          'id': 1397769541,
          'name': 'Feiyue Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_DSFD_Dual_Shot_Face_Detector_CVPR_2019_paper.pdf',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 186368189,
      'title': 'Kernelized Subspace Pooling for Deep Local Descriptors',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1149114015,
          'name': 'Xing Wei'
        },
        {
          'id': 1285507897,
          'name': 'Yue Zhang'
        },
        {
          'id': 1136791764,
          'name': 'Yihong Gong'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 0
    },
    {
      'id': 186535534,
      'title': 'Feature Selective Networks for Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1354544146,
          'name': 'Yao Zhai'
        },
        {
          'id': 1015860143,
          'name': 'Jingjing Fu'
        },
        {
          'id': 1060816602,
          'name': 'Yan Lu'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2023746547,
          'name': 'Aspect ratio (image)'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 35
    },
    {
      'id': 187236818,
      'title': 'Discriminative Learning of Latent Features for Zero-Shot Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1150911725,
          'name': 'Yan Li'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1255955000,
          'name': 'Jianguo Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2001048872,
          'name': 'Zoom'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2001392051,
          'name': 'Discriminative learning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 25
    },
    {
      'id': 187470092,
      'title': 'Im2Struct: Recovering 3D Shape Structure from a Single RGB Image',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        }
      ],
      'author': [
        {
          'id': 1151949416,
          'name': 'Chengjie Niu'
        },
        {
          'id': 1462352723,
          'name': 'Jun Li'
        },
        {
          'id': 1048406954,
          'name': 'Kai Xu'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2003501260,
          'name': 'Cuboid'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024086463,
          'name': 'Rendering (computer graphics)'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 24
    },
    {
      'id': 188176393,
      'title': 'Rotation-Sensitive Regression for Oriented Scene Text Detection',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1168135169,
          'name': 'Minghui Liao'
        },
        {
          'id': 1392321334,
          'name': 'Zhen Zhu'
        },
        {
          'id': 1154870261,
          'name': 'Baoguang Shi'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 48
    },
    {
      'id': 188181687,
      'title': 'Flow Guided Recurrent Neural Encoder for Video Salient Object Detection',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1370339927,
          'name': 'Yuan Xie'
        },
        {
          'id': 1241840830,
          'name': 'Tianhao Wei'
        },
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 0
    },
    {
      'id': 190634741,
      'title': 'DoubleFusion: Real-Time Capture of Human Performances with Inner Body Shapes from a Single Depth Sensor',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1415675733,
          'name': 'Tao Yu'
        },
        {
          'id': 1416105486,
          'name': 'Zerong Zheng'
        },
        {
          'id': 1111748420,
          'name': 'Kaiwen Guo'
        },
        {
          'id': 1346709451,
          'name': 'Jianhui Zhao'
        },
        {
          'id': 1392233130,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1002172115,
          'name': 'Hao Li'
        },
        {
          'id': 1126851015,
          'name': 'Gerard Pons Moll'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        }
      ],
      'field': [
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020416489,
          'name': 'Surface layer'
        },
        {
          'id': 2005822874,
          'name': 'Body shape'
        },
        {
          'id': 2049290898,
          'name': 'Double layer (surface science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005202005,
          'name': 'Match moving'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 36
    },
    {
      'id': 190843899,
      'title': 'Real-Time Rotation-Invariant Face Detection with Progressive Calibration Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1004413138,
          'name': 'Xuepeng Shi'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1464445414,
          'name': 'Shuzhe Wu'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022417328,
          'name': 'Compromise'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 21
    },
    {
      'id': 190939213,
      'title': 'Future Frame Prediction for Anomaly Detection - A New Baseline',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1405436572,
          'name': 'Wen Liu'
        },
        {
          'id': 1252850696,
          'name': 'Weixin Luo'
        },
        {
          'id': 1406842602,
          'name': 'Dongze Lian'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 29
    },
    {
      'id': 19232441,
      'title': 'Alive Caricature from 2D to 3D',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1400579019,
          'name': 'Qianyi Wu'
        },
        {
          'id': 1142911606,
          'name': 'Juyong Zhang'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        },
        {
          'id': 1170812991,
          'name': 'Jianmin Zheng'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        }
      ],
      'field': [
        {
          'id': 2043059786,
          'name': 'Extrapolation'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2040543323,
          'name': '2D to 3D conversion'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 33
    },
    {
      'id': 192566907,
      'title': 'Optical Flow Guided Feature: A Fast and Robust Motion Representation for Video Action Recognition',
      'affiliation': [
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1262506962,
          'name': 'Shuyang Sun'
        },
        {
          'id': 1232840477,
          'name': 'Zhanghui Kuang'
        },
        {
          'id': 1099193953,
          'name': 'Lu Sheng'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1022906146,
          'name': 'Wei Zhang'
        }
      ],
      'field': [
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 49
    },
    {
      'id': 193724764,
      'title': 'Attentive Relational Networks for Mapping Images to Scene Graphs',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1246457806,
          'name': 'Mengshi Qi'
        },
        {
          'id': 1204786356,
          'name': 'Weijian Li'
        },
        {
          'id': 1269334147,
          'name': 'Zhengyuan Yang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Qi_Attentive_Relational_Networks_for_Mapping_Images_to_Scene_Graphs_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 193922669,
      'title': 'Learning Spatial-Temporal Regularized Correlation Filters for Visual Tracking',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1386848118,
          'name': 'Feng Li'
        },
        {
          'id': 1166342548,
          'name': 'Cheng Tian'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 2
    },
    {
      'id': 194362328,
      'title': 'Interpret Neural Networks by Identifying Critical Data Routing Paths',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1271268096,
          'name': 'Yulong Wang'
        },
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046784632,
          'name': 'Intelligent agent'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030024898,
          'name': 'Interpretability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 194411528,
      'title': 'CNN in MRF: Video Object Segmentation via Inference in a CNN-Based Higher-Order Spatio-Temporal MRF',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1084291466,
          'name': 'Linchao Bao'
        },
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2034154305,
          'name': 'Initial and terminal objects'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2040908446,
          'name': 'Approximate inference'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 31
    },
    {
      'id': 195985024,
      'title': 'Multispectral Image Intrinsic Decomposition via Subspace Constraint',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2104254113,
          'abbr': 'NYU',
          'name': 'New York University'
        }
      ],
      'author': [
        {
          'id': 1174222783,
          'name': 'Qian Huang'
        },
        {
          'id': 1485303049,
          'name': 'Weixin Zhu'
        },
        {
          'id': 1023891971,
          'name': 'Yang Zhao'
        },
        {
          'id': 1495360191,
          'name': 'Linsen Chen'
        },
        {
          'id': 1096288529,
          'name': 'Yao Wang'
        },
        {
          'id': 1311917372,
          'name': 'Tao Yue'
        },
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010264609,
          'name': 'Color constancy'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044457252,
          'name': 'Spectral space'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 196162216,
      'title': 'Transductive Unbiased Embedding for Zero-Shot Learning',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1274764510,
          'name': 'Jie Song'
        },
        {
          'id': 1493466798,
          'name': 'Chengchao Shen'
        },
        {
          'id': 1493220987,
          'name': 'Yezhou Yang'
        },
        {
          'id': 1073563786,
          'name': 'Yang Liu'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014952153,
          'name': 'Transduction (machine learning)'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2017543523,
          'name': 'Fixed point'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 34
    },
    {
      'id': 196544046,
      'title': 'Towards Effective Low-Bitwidth Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1407150119,
          'name': 'Bohan Zhuang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1121483151,
          'name': 'Mingkui Tan'
        },
        {
          'id': 1468852495,
          'name': 'Lingqiao Liu'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        }
      ],
      'field': [
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2019179373,
          'name': 'Maxima and minima'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 5,
      'reference_count': 34
    },
    {
      'id': 196765806,
      'title': 'CondenseNet: An Efficient DenseNet Using Learned Group Convolutions',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        }
      ],
      'author': [
        {
          'id': 1366951731,
          'name': 'Gao Huang'
        },
        {
          'id': 1170942169,
          'name': 'Shichen Liu'
        },
        {
          'id': 1483427321,
          'name': 'Laurens van der Maaten'
        },
        {
          'id': 1159231599,
          'name': 'Kilian Q. Weinberger'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 77,
      'reference_count': 43
    },
    {
      'id': 198579528,
      'title': 'Discriminative Learning of Latent Features for Zero-Shot Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1002082398,
          'name': 'Yan Li'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1010146054,
          'name': 'Jianguo Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2001392051,
          'name': 'Discriminative learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001048872,
          'name': 'Zoom'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 9,
      'reference_count': 26
    },
    {
      'id': 200046430,
      'title': 'Gated Fusion Network for Single Image Dehazing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2102262321,
          'abbr': 'UTA',
          'name': 'University of Texas at Arlington'
        }
      ],
      'author': [
        {
          'id': 1224361079,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1346819320,
          'name': 'Jiawei Zhang'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048093308,
          'name': 'Attenuation'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2018889831,
          'name': 'Color balance'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011226169,
          'name': 'Gamma correction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 40
    },
    {
      'id': 201466217,
      'title': 'HSCNN+: Advanced CNN-Based Hyperspectral Recovery from RGB Images',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1019333850,
          'name': 'Zhan Shi'
        },
        {
          'id': 1082179902,
          'name': 'Chang Chen'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w13/Shi_HSCNN_Advanced_CNN-Based_CVPR_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 201930152,
      'title': 'GVCNN: Group-View Convolutional Neural Networks for 3D Shape Recognition',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1051030620,
          'name': 'Yifan Feng'
        },
        {
          'id': 1394167033,
          'name': 'Zizhao Zhang'
        },
        {
          'id': 1164600152,
          'name': 'Xibin Zhao'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1018171187,
          'name': 'Yue Gao'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 0
    },
    {
      'id': 202528905,
      'title': 'Stereoscopic Neural Style Transfer',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1313251152,
          'name': 'Dongdong Chen'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1092358509,
          'name': 'Jing Liao'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018261156,
          'name': 'Stereoscopy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2016967103,
          'name': 'Left and right'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 35
    },
    {
      'id': 20288427,
      'title': 'Deep Cost-Sensitive and Order-Preserving Feature Learning for Cross-Population Age Estimation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104876881,
          'abbr': 'BBK',
          'name': 'Birkbeck, University of London'
        }
      ],
      'author': [
        {
          'id': 1226842249,
          'name': 'Kai Li'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1424228217,
          'name': 'Chi Su'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1227109698,
          'name': 'Yundong Zhang'
        },
        {
          'id': 1242761004,
          'name': 'Stephen J. Maybank'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006497253,
          'name': 'Population'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2044069825,
          'name': 'Source Population'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 32
    },
    {
      'id': 203896246,
      'title': 'Densely Semantically Aligned Person Re-Identification',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1404536306,
          'name': 'Zhizheng Zhang'
        },
        {
          'id': 1171674693,
          'name': 'Cuiling Lan'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        },
        {
          'id': 1239989664,
          'name': 'Zhibo Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Densely_Semantically_Aligned_Person_Re-Identification_CVPR_2019_paper.pdf',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 204684120,
      'title': 'Uncalibrated Photometric Stereo Under Natural Illumination',
      'affiliation': [
        {
          'id': 2101979648,
          'abbr': 'SUTD',
          'name': 'Singapore University of Technology and Design'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2103675905,
          'abbr': 'OSAKA-U',
          'name': 'Osaka University'
        }
      ],
      'author': [
        {
          'id': 1387450539,
          'name': 'Zhipeng Mo'
        },
        {
          'id': 1100550194,
          'name': 'Boxin Shi'
        },
        {
          'id': 1313394467,
          'name': 'Feng Lu'
        },
        {
          'id': 1022309053,
          'name': 'Sai-Kit Yeung'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2036141665,
          'name': 'Photometric stereo'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 20514010,
      'title': 'MoNet: Deep Motion Exploitation for Video Object Segmentation',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1047018509,
          'name': 'Huaxin Xiao'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1139106464,
          'name': 'Guosheng Lin'
        },
        {
          'id': 1024297504,
          'name': 'Yu Liu'
        },
        {
          'id': 1433493238,
          'name': 'Maojun Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2018186560,
          'name': 'Distance transform'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 0
    },
    {
      'id': 205274352,
      'title': 'Learning a Single Convolutional Super-Resolution Network for Multiple Degradations',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1221197508,
          'name': 'Kai Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 45
    },
    {
      'id': 208042203,
      'title': 'A Bi-Directional Message Passing Model for Salient Object Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1256761721,
          'name': 'Lu Zhang'
        },
        {
          'id': 1426905073,
          'name': 'Ju Dai'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1039635255,
          'name': 'You He'
        },
        {
          'id': 1017033586,
          'name': 'Gang Wang'
        }
      ],
      'field': [
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 0
    },
    {
      'id': 21056695,
      'title': 'Towards Human-Machine Cooperation: Self-Supervised Sample Mining for Object Detection',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1127017914,
          'name': 'Xiaopeng Yan'
        },
        {
          'id': 1044968095,
          'name': 'Dongyu Zhang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1104906125,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2029111411,
          'name': 'Human–machine system'
        },
        {
          'id': 2010417696,
          'name': 'Active learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 37
    },
    {
      'id': 210748160,
      'title': 'Sparse Photometric 3D Face Reconstruction Guided by Morphable Models',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1375374154,
          'name': 'Xuan Cao'
        },
        {
          'id': 1317179635,
          'name': 'Zhang Chen'
        },
        {
          'id': 1194766867,
          'name': 'Anpei Chen'
        },
        {
          'id': 1295830375,
          'name': 'Xin Chen'
        },
        {
          'id': 1072463225,
          'name': 'Shiying Li'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010101173,
          'name': 'Small set'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2029958136,
          'name': 'Photometry (optics)'
        },
        {
          'id': 2037296273,
          'name': 'Robust optimization'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036141665,
          'name': 'Photometric stereo'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 50
    },
    {
      'id': 211523520,
      'title': 'Beyond Trade-Off: Accelerate FCN-Based Face Detector with Higher Accuracy',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1197873817,
          'name': 'Guanglu Song'
        },
        {
          'id': 1134450671,
          'name': 'Yu Liu'
        },
        {
          'id': 1215318893,
          'name': 'Ming Jiang'
        },
        {
          'id': 1259806560,
          'name': 'Yujie Wang'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1043106211,
          'name': 'Biao Leng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2010616972,
          'name': 'False alarm'
        },
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2013841216,
          'name': 'Acceleration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 43
    },
    {
      'id': 212869612,
      'title': 'DVC: An End-to-end Deep Video Compression Framework.',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1438739039,
          'name': 'Guo Lu'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1168997702,
          'name': 'Xiaoyun Zhang'
        },
        {
          'id': 1147674907,
          'name': 'Chunlei Cai'
        },
        {
          'id': 1278281228,
          'name': 'Zhiyong Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Lu_DVC_An_End-To-End_Deep_Video_Compression_Framework_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 213235775,
      'title': 'PVNet: Pixel-Wise Voting Network for 6DoF Pose Estimation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        }
      ],
      'author': [
        {
          'id': 1107428247,
          'name': 'Sida Peng'
        },
        {
          'id': 1035225494,
          'name': 'Yuan Liu'
        },
        {
          'id': 1022255500,
          'name': 'Qixing Huang'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        },
        {
          'id': 1330511943,
          'name': 'Xiaowei Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033899647,
          'name': 'Voting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/pdf/1812.11788.pdf',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 21380945,
      'title': 'Zoom and Learn: Generalizing Deep Stereo Matching to Novel Domains',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1217660585,
          'name': 'Jiahao Pang'
        },
        {
          'id': 1401096853,
          'name': 'Wenxiu Sun'
        },
        {
          'id': 1012501044,
          'name': 'Chengxi Yang'
        },
        {
          'id': 1028004617,
          'name': 'Jimmy S. J. Ren'
        },
        {
          'id': 1143992684,
          'name': 'Ruichao Xiao'
        },
        {
          'id': 1293520122,
          'name': 'Jin Zeng'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2001048872,
          'name': 'Zoom'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034388435,
          'name': 'Laplacian matrix'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2015316182,
          'name': 'Generalization'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 33
    },
    {
      'id': 21643713,
      'title': 'Where and Why are They Looking? Jointly Inferring Human Attention and Intentions in Complex Tasks',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1482713211,
          'name': 'Yang Liu'
        },
        {
          'id': 1103040551,
          'name': 'Tianmin Shu'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2012493528,
          'name': 'Hierarchical database model'
        },
        {
          'id': 2047932954,
          'name': 'Beam search'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 21746403,
      'title': 'Learning Semantic Concepts and Order for Image and Sentence Matching',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1103669667,
          'name': 'Qi Wu'
        },
        {
          'id': 1005235175,
          'name': 'Chunfeng Song'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 27
    },
    {
      'id': 217559477,
      'title': 'Edit Probability for Scene Text Recognition',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1351963402,
          'name': 'Fan Bai'
        },
        {
          'id': 1046738235,
          'name': 'Zhanzhan Cheng'
        },
        {
          'id': 1130921084,
          'name': 'Yi Niu'
        },
        {
          'id': 1384883042,
          'name': 'Shiliang Pu'
        },
        {
          'id': 1494078016,
          'name': 'Shuigeng Zhou'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 24
    },
    {
      'id': 217652706,
      'title': 'Progressive Feature Alignment for Unsupervised Domain Adaptation.',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1164298194,
          'name': 'Chaoqi Chen'
        },
        {
          'id': 1281660022,
          'name': 'Weiping Xie'
        },
        {
          'id': 1089153248,
          'name': 'Tingyang Xu'
        },
        {
          'id': 1467293440,
          'name': 'Wenbing Huang'
        },
        {
          'id': 1170942468,
          'name': 'Yu Rong'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1324706883,
          'name': 'Junzhou Huang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Chen_Progressive_Feature_Alignment_for_Unsupervised_Domain_Adaptation_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 219340635,
      'title': 'PartNet: A Large-Scale Benchmark for Fine-Grained and Hierarchical Composition-Level 3D Object Understanding',
      'affiliation': [
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        }
      ],
      'author': [
        {
          'id': 1392583190,
          'name': 'Kaichun Mo'
        },
        {
          'id': 1317948802,
          'name': 'Shilin Zhu'
        },
        {
          'id': 1008842225,
          'name': 'Angel X. Chang'
        },
        {
          'id': 1194319412,
          'name': 'Li Yi'
        },
        {
          'id': 1165251567,
          'name': 'Subarna Tripathi'
        },
        {
          'id': 1471272024,
          'name': 'Leonidas J. Guibas'
        },
        {
          'id': 1464060409,
          'name': 'Hao Su'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Mo_PartNet_A_Large-Scale_Benchmark_for_Fine-Grained_and_Hierarchical_Part-Level_3D_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 219385157,
      'title': 'Defense Against Adversarial Attacks Using High-Level Representation Guided Denoiser',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1391196842,
          'name': 'Fangzhou Liao'
        },
        {
          'id': 1192294770,
          'name': 'Ming Liang'
        },
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1258883894,
          'name': 'Tianyu Pang'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 75,
      'reference_count': 27
    },
    {
      'id': 220152311,
      'title': 'Salience Guided Depth Calibration for Perceptually Optimized Compressive Light Field 3D Display',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103578022,
          'abbr': 'UB',
          'name': 'University at Buffalo'
        }
      ],
      'author': [
        {
          'id': 1229084289,
          'name': 'Shizheng Wang'
        },
        {
          'id': 1231775490,
          'name': 'Wenjuan Liao'
        },
        {
          'id': 1488458041,
          'name': 'Phil Surman'
        },
        {
          'id': 1263009109,
          'name': 'Zhigang Tu'
        },
        {
          'id': 1098667210,
          'name': 'Yuanjin Zheng'
        },
        {
          'id': 1262920571,
          'name': 'Junsong Yuan'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2038653330,
          'name': 'Salience (language)'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013719661,
          'name': 'Stereo display'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        },
        {
          'id': 2015521768,
          'name': 'Liquid-crystal display'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 222227291,
      'title': 'Grid R-CNN',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1001835628,
          'name': 'Xin Lu'
        },
        {
          'id': 1330479713,
          'name': 'Buyu Li'
        },
        {
          'id': 1390000021,
          'name': 'Yuxin Yue'
        },
        {
          'id': 1082563712,
          'name': 'Quanquan Li'
        },
        {
          'id': 1103441994,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041433144,
          'name': 'Grid'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Lu_Grid_R-CNN_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 222495440,
      'title': 'Fooling Vision and Language Models Despite Localization and Attention Mechanism',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1437916343,
          'name': 'Xiaojun Xu'
        },
        {
          'id': 1377525726,
          'name': 'Xinyun Chen'
        },
        {
          'id': 1112232677,
          'name': 'Chang Liu'
        },
        {
          'id': 1210334594,
          'name': 'Anna Rohrbach'
        },
        {
          'id': 1255937776,
          'name': 'Trevor Darrell'
        },
        {
          'id': 1176780689,
          'name': 'Dawn Song'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002009818,
          'name': 'Knowledge extraction'
        },
        {
          'id': 2007200049,
          'name': 'Natural language understanding'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049525942,
          'name': 'Language model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 31
    },
    {
      'id': 222758281,
      'title': 'Stereoscopic Neural Style Transfer',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1313251152,
          'name': 'Dongdong Chen'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1092358509,
          'name': 'Jing Liao'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2018261156,
          'name': 'Stereoscopy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 36
    },
    {
      'id': 223102893,
      'title': 'Attribute Enhanced Face Aging with Wavelet-based Generative Adversarial Networks.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1042670037,
          'name': 'Yunfan Liu'
        },
        {
          'id': 1206668105,
          'name': 'Qi Li'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Liu_Attribute-Aware_Face_Aging_With_Wavelet-Based_Generative_Adversarial_Networks_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 223744201,
      'title': 'Dual Skipping Networks',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1059700472,
          'name': 'Changmao Cheng'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1073731168,
          'name': 'Wenlian Lu'
        },
        {
          'id': 1222297492,
          'name': 'Jianfeng Feng'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038996624,
          'name': 'Network model'
        },
        {
          'id': 2014316225,
          'name': 'Spatial frequency'
        },
        {
          'id': 2023036760,
          'name': 'Gating'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 35
    },
    {
      'id': 224170059,
      'title': 'Learning a Discriminative Prior for Blind Image Deblurring',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1427761351,
          'name': 'Lerenhan Li'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1477719517,
          'name': 'Wei-Sheng Lai'
        },
        {
          'id': 1028164167,
          'name': 'Changxin Gao'
        },
        {
          'id': 1248648678,
          'name': 'Nong Sang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026800590,
          'name': 'Gradient descent'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 35
    },
    {
      'id': 224184127,
      'title': 'Aligning Infinite-Dimensional Covariance Matrices in Reproducing Kernel Hilbert Spaces for Domain Adaptation',
      'affiliation': [
        {
          'id': 2101817535,
          'abbr': 'WUSTL',
          'name': 'Washington University in St. Louis'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1463050915,
          'name': 'Zhen Zhang'
        },
        {
          'id': 1299529620,
          'name': 'Mianzhi Wang'
        },
        {
          'id': 1038821701,
          'name': 'Yan Huang'
        },
        {
          'id': 1356080263,
          'name': 'Arye Nehorai'
        }
      ],
      'field': [
        {
          'id': 2009945168,
          'name': 'Hilbert space'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        },
        {
          'id': 2027601801,
          'name': 'Euclidean geometry'
        },
        {
          'id': 2046405230,
          'name': 'Reproducing kernel Hilbert space'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 225143619,
      'title': 'VITAL: VIsual Tracking via Adversarial Learning',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1355309202,
          'name': 'Yibing Song'
        },
        {
          'id': 1053338084,
          'name': 'Chao Ma'
        },
        {
          'id': 1343560243,
          'name': 'Xiaohe Wu'
        },
        {
          'id': 1001892649,
          'name': 'Lijun Gong'
        },
        {
          'id': 1084291466,
          'name': 'Linchao Bao'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 46
    },
    {
      'id': 225421515,
      'title': 'Two-Step Quantization for Low-bit Neural Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1261785545,
          'name': 'Peisong Wang'
        },
        {
          'id': 1449587090,
          'name': 'Qinghao Hu'
        },
        {
          'id': 1011431260,
          'name': 'Yifan Zhang'
        },
        {
          'id': 1075868260,
          'name': 'Chunjie Zhang'
        },
        {
          'id': 1392088963,
          'name': 'Yang Liu'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2039464793,
          'name': 'Quantization (physics)'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2046430142,
          'name': 'Transformation (function)'
        },
        {
          'id': 2012191016,
          'name': 'Ternary operation'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 22756667,
      'title': 'Weakly-Supervised Semantic Segmentation Network with Deep Seeded Region Growing',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1487074933,
          'name': 'Zilong Huang'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1359181088,
          'name': 'Jiasi Wang'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046248120,
          'name': 'Region growing'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 0
    },
    {
      'id': 228355018,
      'title': 'Rotation-Sensitive Regression for Oriented Scene Text Detection',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1168135169,
          'name': 'Minghui Liao'
        },
        {
          'id': 1392321334,
          'name': 'Zhen Zhu'
        },
        {
          'id': 1154870261,
          'name': 'Baoguang Shi'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040776147,
          'name': 'Generality'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 40
    },
    {
      'id': 228512352,
      'title': 'Feature Super-Resolution: Make Machine See More Clearly',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1401142855,
          'name': 'Weimin Tan'
        },
        {
          'id': 1114917563,
          'name': 'Bo Yan'
        },
        {
          'id': 1054474661,
          'name': 'Bahetiyaer Bare'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034347468,
          'name': 'G-network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 228696480,
      'title': 'High-Speed Tracking with Multi-kernel Correlation Filters',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1123944716,
          'name': 'Ming Tang'
        },
        {
          'id': 1000913401,
          'name': 'Bin Yu'
        },
        {
          'id': 1116230964,
          'name': 'Fan Zhang'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        }
      ],
      'field': [
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2003914179,
          'name': 'Interference (wave propagation)'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2034486721,
          'name': 'Upper and lower bounds'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 53
    },
    {
      'id': 231603880,
      'title': 'Defocus Blur Detection via Multi-stream Bottom-Top-Bottom Fully Convolutional Network',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1410329428,
          'name': 'Wenda Zhao'
        },
        {
          'id': 1119099147,
          'name': 'Fan Zhao'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2049269054,
          'name': 'Homogeneous'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 232146426,
      'title': 'Convolutional Neural Networks with Alternately Updated Clique',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1114296133,
          'name': 'Yibo Yang'
        },
        {
          'id': 1229351626,
          'name': 'Zhisheng Zhong'
        },
        {
          'id': 1124655160,
          'name': 'Tiancheng Shen'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2036252551,
          'name': 'Information flow (information theory)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2047564696,
          'name': 'Input/output'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2003528380,
          'name': 'Clique'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 38
    },
    {
      'id': 233048693,
      'title': 'Gaze Prediction in Dynamic 360° Immersive Videos',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        }
      ],
      'author': [
        {
          'id': 1373263498,
          'name': 'Yanyu Xu'
        },
        {
          'id': 1232307139,
          'name': 'Yanbing Dong'
        },
        {
          'id': 1184048680,
          'name': 'Junru Wu'
        },
        {
          'id': 1387500108,
          'name': 'Zhengzhong Sun'
        },
        {
          'id': 1143302751,
          'name': 'Zhiru Shi'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2041467086,
          'name': 'Gaze'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037918317,
          'name': 'Immersion (virtual reality)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2023247074,
          'name': 'Field of view'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 233863738,
      'title': 'Practical Block-Wise Neural Network Architecture Generation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1250794071,
          'name': 'Zhao Zhong'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1004795249,
          'name': 'Wei Wu'
        },
        {
          'id': 1495848024,
          'name': 'Jing Shao'
        },
        {
          'id': 1429067054,
          'name': 'Cheng-Lin Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2044004772,
          'name': 'Asynchronous communication'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 55,
      'reference_count': 0
    },
    {
      'id': 234611221,
      'title': 'Weakly Supervised Facial Action Unit Recognition Through Adversarial Training',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1370199115,
          'name': 'Guozhu Peng'
        },
        {
          'id': 1368791712,
          'name': 'Shangfei Wang'
        }
      ],
      'field': [
        {
          'id': 2023689791,
          'name': 'Adversarial process'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2035470008,
          'name': 'Domain knowledge'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 234981520,
      'title': 'Separating Style and Content for Generalized Style Transfer',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1007889389,
          'name': 'Yexun Zhang'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1112625158,
          'name': 'Wenbin Cai'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2003617976,
          'name': 'Transfer problem'
        },
        {
          'id': 2033565064,
          'name': 'Conditional dependence'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2016707335,
          'name': 'Typeface'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 6,
      'reference_count': 21
    },
    {
      'id': 235297794,
      'title': 'Towards Pose Invariant Face Recognition in the Wild',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103356563,
          'abbr': 'PANASONIC',
          'name': 'Panasonic'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1144640029,
          'name': 'Jian Zhao'
        },
        {
          'id': 1325489102,
          'name': 'Yu Cheng'
        },
        {
          'id': 1394008496,
          'name': 'Yan Xu'
        },
        {
          'id': 1118341442,
          'name': 'Lin Xiong'
        },
        {
          'id': 1488545021,
          'name': 'Jianshu Li'
        },
        {
          'id': 1272920548,
          'name': 'Fang Zhao'
        },
        {
          'id': 1374119233,
          'name': 'Karlekar Jayashree'
        },
        {
          'id': 1465851410,
          'name': 'Sugiri Pranata'
        },
        {
          'id': 1026821132,
          'name': 'Shengmei Shen'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        }
      ],
      'field': [
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 0
    },
    {
      'id': 235658639,
      'title': 'Interpretable Video Captioning via Trajectory Structured Localization',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1000622068,
          'name': 'Xian Wu'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1224924548,
          'name': 'Qingxing Cao'
        },
        {
          'id': 1428627696,
          'name': 'Qingge Ji'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 0
    },
    {
      'id': 236519009,
      'title': 'Parsing R-CNN for Instance-Level Human Analysis.',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1117370399,
          'name': 'Lu Yang'
        },
        {
          'id': 1035437062,
          'name': 'Qing Song'
        },
        {
          'id': 1069384778,
          'name': 'Zhihui Wang'
        },
        {
          'id': 1015368856,
          'name': 'Ming Jiang'
        }
      ],
      'field': [
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yang_Parsing_R-CNN_for_Instance-Level_Human_Analysis_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 236911799,
      'title': 'Jointly Localizing and Describing Events for Dense Video Captioning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1403845832,
          'name': 'Yehao Li'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029384766,
          'name': 'Descriptive complexity theory'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 37
    },
    {
      'id': 236921214,
      'title': 'Weakly Supervised Instance Segmentation Using Class Peak Response',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1238435354,
          'name': 'Yanzhao Zhou'
        },
        {
          'id': 1259889084,
          'name': 'Yi Zhu'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1246182727,
          'name': 'Qiang Qiu'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037197408,
          'name': 'Pointwise'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 42
    },
    {
      'id': 237882761,
      'title': 'Real-Time Rotation-Invariant Face Detection with Progressive Calibration Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1004413138,
          'name': 'Xuepeng Shi'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1464445414,
          'name': 'Shuzhe Wu'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2042828818,
          'name': 'Division (mathematics)'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 23
    },
    {
      'id': 239897540,
      'title': 'Unsupervised Deep Generative Adversarial Hashing Network',
      'affiliation': [
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        },
        {
          'id': 2103964416,
          'abbr': 'UTD',
          'name': 'University of Texas at Dallas'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1327953326,
          'name': 'Kamran Ghasedi Dizaji'
        },
        {
          'id': 1012525307,
          'name': 'Feng Zheng'
        },
        {
          'id': 1336819271,
          'name': 'Najmeh Sadoughi Nourabadi'
        },
        {
          'id': 1043705082,
          'name': 'Yanhua Yang'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1282955999,
          'name': 'Heng Huang'
        }
      ],
      'field': [
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 0
    },
    {
      'id': 241841450,
      'title': 'CNN-Optimized Image Compression with Uncertainty based Resource Allocation',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1065148540,
          'name': 'Zhenzhong Chen'
        },
        {
          'id': 1038471111,
          'name': 'Yiming Li'
        },
        {
          'id': 1486288172,
          'name': 'Feiyang Liu'
        },
        {
          'id': 1129040712,
          'name': 'Zizheng Liu'
        },
        {
          'id': 1029024234,
          'name': 'Xiang Pan'
        },
        {
          'id': 1299787540,
          'name': 'Wanjie Sun'
        },
        {
          'id': 1389631964,
          'name': 'Yingbin Wang'
        },
        {
          'id': 1085626242,
          'name': 'Yan Zhou'
        },
        {
          'id': 1160358943,
          'name': 'Han Zhu'
        },
        {
          'id': 1439974622,
          'name': 'Shan Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006020891,
          'name': 'Resource allocation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/w50/html/Chen_CNN-Optimized_Image_Compression_CVPR_2018_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 242158051,
      'title': 'Learning Spatial-Aware Regressions for Visual Tracking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018186560,
          'name': 'Distance transform'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2014570814,
          'name': 'Kernel adaptive filter'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 47,
      'reference_count': 30
    },
    {
      'id': 244367831,
      'title': 'Intention Oriented Image Captions with Guiding Objects.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1017781633,
          'name': 'Yue Zheng'
        },
        {
          'id': 1014730232,
          'name': 'Yali Li'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zheng_Intention_Oriented_Image_Captions_With_Guiding_Objects_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 245420705,
      'title': 'An End-to-End TextSpotter with Explicit Alignment and Attention',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        }
      ],
      'author': [
        {
          'id': 1125643104,
          'name': 'Tong He'
        },
        {
          'id': 1201435277,
          'name': 'Zhi Tian'
        },
        {
          'id': 1173392213,
          'name': 'Weilin Huang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1333537378,
          'name': 'Changming Sun'
        }
      ],
      'field': [
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2021282490,
          'name': 'Word recognition'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2015605404,
          'name': 'Lexicon'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 39
    },
    {
      'id': 247185429,
      'title': 'Salient Object Detection Driven by Fixation Prediction',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1000641760,
          'name': 'Xingping Dong'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 0
    },
    {
      'id': 248289988,
      'title': 'Weakly and Semi Supervised Human Body Composition Parsing via Pose-Guided Knowledge Transfer',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1488644272,
          'name': 'Hao-Shu Fang'
        },
        {
          'id': 1010923251,
          'name': 'Guansong Lu'
        },
        {
          'id': 1277749712,
          'name': 'Xiaolin Fang'
        },
        {
          'id': 1336211972,
          'name': 'Jianwen Xie'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 32
    },
    {
      'id': 249452774,
      'title': 'Dissecting Person Re-Identification From the Viewpoint of Viewpoint',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1464422618,
          'name': 'Xiaoxiao Sun'
        },
        {
          'id': 1387078177,
          'name': 'Liang Zheng'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Sun_Dissecting_Person_Re-Identification_From_the_Viewpoint_of_Viewpoint_CVPR_2019_paper.pdf',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 249752481,
      'title': 'Fusing Crowd Density Maps and Visual Object Trackers for People Tracking in Crowd Scenes',
      'affiliation': [
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1301763226,
          'name': 'Weihong Ren'
        },
        {
          'id': 1051980371,
          'name': 'Di Kang'
        },
        {
          'id': 1321471777,
          'name': 'Yandong Tang'
        },
        {
          'id': 1215408031,
          'name': 'Antoni B. Chan'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2047278335,
          'name': 'Spurious relationship'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034589600,
          'name': 'Batch processing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 253470537,
      'title': 'Textbook Question Answering Under Instructor Guidance with Memory Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1132743614,
          'name': 'Juzheng Li'
        },
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        },
        {
          'id': 1052920312,
          'name': 'Siyu Wang'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2002009818,
          'name': 'Knowledge extraction'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 253861126,
      'title': 'Triplet-Center Loss for Multi-view 3D Object Retrieval',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1032603775,
          'name': 'Xinwei He'
        },
        {
          'id': 1412528199,
          'name': 'Yang Zhou'
        },
        {
          'id': 1164775868,
          'name': 'Zhichao Zhou'
        },
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 34
    },
    {
      'id': 253876427,
      'title': 'Learning Facial Action Units from Web Images with Scalable Weakly Supervised Clustering',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102835841,
          'abbr': 'OSU',
          'name': 'Ohio State University'
        }
      ],
      'author': [
        {
          'id': 1208665046,
          'name': 'Kaili Zhao'
        },
        {
          'id': 1357110762,
          'name': 'Wen-Sheng Chu'
        },
        {
          'id': 1003031081,
          'name': 'Aleix M. Martinez'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 25524035,
      'title': 'Learning Attraction Field Representation for Robust Line Segment Detection',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1202705676,
          'name': 'Nan Xue'
        },
        {
          'id': 1325469744,
          'name': 'Song Bai'
        },
        {
          'id': 1077833492,
          'name': 'Fudong Wang'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1088002310,
          'name': 'Tianfu Wu'
        },
        {
          'id': 1131819447,
          'name': 'Liangpei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004058443,
          'name': 'Line segment'
        },
        {
          'id': 2011838053,
          'name': 'Attraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xue_Learning_Attraction_Field_Representation_for_Robust_Line_Segment_Detection_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 255717042,
      'title': 'DOTA: A Large-Scale Dataset for Object Detection in Aerial Images',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        },
        {
          'id': 2103747449,
          'abbr': 'UPB',
          'name': 'Politehnica University of Bucharest'
        },
        {
          'id': 2100716060,
          'abbr': 'UNIVE',
          'name': 'Ca\' Foscari University of Venice'
        }
      ],
      'author': [
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1098451795,
          'name': 'Jian Ding'
        },
        {
          'id': 1392321334,
          'name': 'Zhen Zhu'
        },
        {
          'id': 1238813530,
          'name': 'Serge J. Belongie'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        },
        {
          'id': 1273200149,
          'name': 'Mihai Datcu'
        },
        {
          'id': 1388382508,
          'name': 'Marcello Pelillo'
        },
        {
          'id': 1131819447,
          'name': 'Liangpei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003337676,
          'name': 'Aerial image'
        },
        {
          'id': 2001076255,
          'name': 'DOTA'
        },
        {
          'id': 2008631642,
          'name': 'Earth observation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 35
    },
    {
      'id': 25639055,
      'title': 'Iterative Learning with Open-set Noisy Labels',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2104380003,
          'abbr': '',
          'name': 'University of Melbourne'
        }
      ],
      'author': [
        {
          'id': 1036063581,
          'name': 'Yisen Wang'
        },
        {
          'id': 1371439823,
          'name': 'Weiyang Liu'
        },
        {
          'id': 1044809008,
          'name': 'Xingjun Ma'
        },
        {
          'id': 1460973562,
          'name': 'James Bailey'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        },
        {
          'id': 1399541633,
          'name': 'Le Song'
        },
        {
          'id': 1387424504,
          'name': 'Shu-Tao Xia'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2015808417,
          'name': 'Iterative learning control'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 39
    },
    {
      'id': 256533086,
      'title': 'Computer vision for lifelogging',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102072436,
          'abbr': 'DCU',
          'name': 'Dublin City University'
        }
      ],
      'author': [
        {
          'id': 1246277196,
          'name': 'Peng Wang'
        },
        {
          'id': 1230152445,
          'name': 'Lifeng Sun'
        },
        {
          'id': 1108196068,
          'name': 'Alan F. Smeaton'
        },
        {
          'id': 1028748719,
          'name': 'Cathal Gurrin'
        },
        {
          'id': 1090637131,
          'name': 'Shiqian Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018094585,
          'name': 'Wearable technology'
        },
        {
          'id': 2037227597,
          'name': 'Lifelog'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000619510,
          'name': 'Wearable computer'
        },
        {
          'id': 2017152651,
          'name': 'Compass'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 257930031,
      'title': 'Covariance Pooling for Facial Expression Recognition',
      'affiliation': [
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1234478356,
          'name': 'Dinesh Acharya'
        },
        {
          'id': 1030210329,
          'name': 'Zhiwu Huang'
        },
        {
          'id': 1477003323,
          'name': 'Danda Pani Paudel'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        }
      ],
      'field': [
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w10/Acharya_Covariance_Pooling_for_CVPR_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 25
    },
    {
      'id': 258629027,
      'title': 'PoseFlow: A Deep Motion Representation for Understanding Human Behaviors in Videos',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1037690962,
          'name': 'Guangyu Guo'
        },
        {
          'id': 1432027227,
          'name': 'Dong Huang'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2033908478,
          'name': 'Human video'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2046151365,
          'name': 'Human behavior'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 258959789,
      'title': 'A Twofold Siamese Network for Real-Time Object Tracking',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1193966662,
          'name': 'Anfeng He'
        },
        {
          'id': 1469454660,
          'name': 'Chong Luo'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022748559,
          'name': 'Design choice'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 16,
      'reference_count': 38
    },
    {
      'id': 259257112,
      'title': 'Structured Binary Neural Networks for Accurate Image Classification and Semantic Segmentation.',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1407150119,
          'name': 'Bohan Zhuang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1121483151,
          'name': 'Mingkui Tan'
        },
        {
          'id': 1468852495,
          'name': 'Lingqiao Liu'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhuang_Structured_Binary_Neural_Networks_for_Accurate_Image_Classification_and_Semantic_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 260818627,
      'title': 'Dual-Mode Vehicle Motion Pattern Learning for High Performance Road Traffic Anomaly Detection',
      'affiliation': [
        {
          'id': 2103356563,
          'abbr': 'PANASONIC',
          'name': 'Panasonic'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100337811,
          'abbr': 'MMU',
          'name': 'Manchester Metropolitan University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1394008496,
          'name': 'Yan Xu'
        },
        {
          'id': 1389733376,
          'name': 'Xi Ouyang'
        },
        {
          'id': 1325489102,
          'name': 'Yu Cheng'
        },
        {
          'id': 1019156438,
          'name': 'Shining Yu'
        },
        {
          'id': 1118341442,
          'name': 'Lin Xiong'
        },
        {
          'id': 1009006928,
          'name': 'Choon-Ching Ng'
        },
        {
          'id': 1465851410,
          'name': 'Sugiri Pranata'
        },
        {
          'id': 1026821132,
          'name': 'Shengmei Shen'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        }
      ],
      'field': [
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2024371758,
          'name': 'Vehicle dynamics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048610031,
          'name': 'Dual-mode vehicle'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w3/Xu_Dual-Mode_Vehicle_Motion_CVPR_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 261200900,
      'title': 'NDDR-CNN: Layer-wise Feature Fusing in Multi-Task CNN by Neural Discriminative Dimensionality Reduction.',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1413115818,
          'name': 'Yuan Gao'
        },
        {
          'id': 1019980942,
          'name': 'Qi She'
        },
        {
          'id': 1387619660,
          'name': 'Jiayi Ma'
        },
        {
          'id': 1086259011,
          'name': 'Mingbo Zhao'
        },
        {
          'id': 1002508246,
          'name': 'Wei Liu'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gao_NDDR-CNN_Layerwise_Feature_Fusing_in_Multi-Task_CNNs_by_Neural_Discriminative_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 262050467,
      'title': 'Encoding Crowd Interaction with Deep Neural Network for Pedestrian Trajectory Prediction',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1373263498,
          'name': 'Yanyu Xu'
        },
        {
          'id': 1026247391,
          'name': 'Zhixin Piao'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 0
    },
    {
      'id': 264131168,
      'title': 'Decorrelated Batch Normalization',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        }
      ],
      'author': [
        {
          'id': 1361124393,
          'name': 'Lei Huangi'
        },
        {
          'id': 1388181632,
          'name': 'Dawei Yang'
        },
        {
          'id': 1031446264,
          'name': 'Bo Lang'
        },
        {
          'id': 1389718805,
          'name': 'Jia Deng'
        }
      ],
      'field': [
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040590825,
          'name': 'Decorrelation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 46
    },
    {
      'id': 264511372,
      'title': 'Min-Entropy Latent Model for Weakly Supervised Object Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1437119785,
          'name': 'Fang Wan'
        },
        {
          'id': 1403550545,
          'name': 'Pengxu Wei'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        },
        {
          'id': 1091190936,
          'name': 'Zhenjun Han'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043926031,
          'name': 'Min entropy'
        },
        {
          'id': 2049589594,
          'name': 'Randomness'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 58
    },
    {
      'id': 269621358,
      'title': 'Joint Cuts and Matching of Partitions in One Graph',
      'affiliation': [
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100610652,
          'abbr': 'UTH',
          'name': 'University of Texas Health Science Center at Houston'
        }
      ],
      'author': [
        {
          'id': 1025138269,
          'name': 'Tianshu Yu'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1178468700,
          'name': 'Jieyi Zhao'
        },
        {
          'id': 1360566008,
          'name': 'Baoxin Li'
        }
      ],
      'field': [
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2007430677,
          'name': 'Pattern matching'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 31
    },
    {
      'id': 270137717,
      'title': 'Exploiting Kernel Sparsity and Entropy for Interpretable CNN Compression',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1157275387,
          'name': 'Yuchao Li'
        },
        {
          'id': 1298194939,
          'name': 'Shaohui Lin'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1381503284,
          'name': 'Jianzhuang Liu'
        },
        {
          'id': 1267631771,
          'name': 'David S. Doermann'
        },
        {
          'id': 1478890822,
          'name': 'Yongjian Wu'
        },
        {
          'id': 1397769541,
          'name': 'Feiyue Huang'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Exploiting_Kernel_Sparsity_and_Entropy_for_Interpretable_CNN_Compression_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 270619191,
      'title': 'IRLAS: Inverse Reinforcement Learning for Architecture Search',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1246078217,
          'name': 'Minghao Guo'
        },
        {
          'id': 1250794071,
          'name': 'Zhao Zhong'
        },
        {
          'id': 1366664310,
          'name': 'Wei Wu'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        },
        {
          'id': 1465824659,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2008028573,
          'name': 'Inverse'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Guo_IRLAS_Inverse_Reinforcement_Learning_for_Architecture_Search_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 274825621,
      'title': 'Partially Shared Multi-task Convolutional Neural Network with Local Constraint for Face Attribute Learning',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100523771,
          'abbr': 'BU',
          'name': 'Binghamton University'
        }
      ],
      'author': [
        {
          'id': 1001360185,
          'name': 'Jiajiong Cao'
        },
        {
          'id': 1470899736,
          'name': 'Yingming Li'
        },
        {
          'id': 1260701992,
          'name': 'Zhongfei Zhang'
        }
      ],
      'field': [
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 276052763,
      'title': 'LiDAR-Video Driving Dataset: Learning Driving Policies Effectively',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103632264,
          'abbr': 'UW',
          'name': 'University of Waterloo'
        }
      ],
      'author': [
        {
          'id': 1233080115,
          'name': 'Yiping Chen'
        },
        {
          'id': 1135790829,
          'name': 'Jingkang Wang'
        },
        {
          'id': 1404025338,
          'name': 'Jonathan Li'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        },
        {
          'id': 1105821142,
          'name': 'Zhipeng Luo'
        },
        {
          'id': 1178934985,
          'name': 'Han Xue'
        },
        {
          'id': 1081011056,
          'name': 'Cheng Wang'
        }
      ],
      'field': [
        {
          'id': 2036965631,
          'name': 'Dashboard (business)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016797546,
          'name': 'Lidar'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 0
    },
    {
      'id': 277381838,
      'title': 'Feature Denoising for Improving Adversarial Robustness',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        }
      ],
      'author': [
        {
          'id': 1087587910,
          'name': 'Cihang Xie'
        },
        {
          'id': 1169219539,
          'name': 'Yuxin Wu'
        },
        {
          'id': 1325400198,
          'name': 'Laurens van der Maaten'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Xie_Feature_Denoising_for_Improving_Adversarial_Robustness_CVPR_2019_paper.pdf',
      'citation_count': 9,
      'reference_count': 0
    },
    {
      'id': 277468116,
      'title': 'Learning to Promote Saliency Detectors',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1144644555,
          'name': 'Yu Zeng'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1451407143,
          'name': 'Mengyang Feng'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035780509,
          'name': 'Small data'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 277824907,
      'title': 'Conditional Image-to-Image Translation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1107283513,
          'name': 'Jianxin Lin'
        },
        {
          'id': 1349638896,
          'name': 'Yingce Xia'
        },
        {
          'id': 1101447196,
          'name': 'Tao Qin'
        },
        {
          'id': 1239989664,
          'name': 'Zhibo Chen'
        },
        {
          'id': 1341781768,
          'name': 'Tie-Yan Liu'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2003950936,
          'name': 'Image translation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2010643714,
          'name': 'Unpaired Data'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 21
    },
    {
      'id': 278909548,
      'title': 'Learning Descriptor Networks for 3D Shape Synthesis and Analysis',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1336211972,
          'name': 'Jianwen Xie'
        },
        {
          'id': 1315752410,
          'name': 'Zilong Zheng'
        },
        {
          'id': 1129662997,
          'name': 'Ruiqi Gao'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        },
        {
          'id': 1128144622,
          'name': 'Ying Nian Wu'
        }
      ],
      'field': [
        {
          'id': 2041477979,
          'name': 'Shape analysis (digital geometry)'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2016541624,
          'name': 'Speech coding'
        },
        {
          'id': 2023666742,
          'name': 'Markov chain Monte Carlo'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2002625218,
          'name': 'Langevin dynamics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 32
    },
    {
      'id': 280496534,
      'title': 'Deep Progressive Reinforcement Learning for Skeleton-Based Action Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1378949082,
          'name': 'Yansong Tang'
        },
        {
          'id': 1441758492,
          'name': 'Yi Tian'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1234579867,
          'name': 'Peiyang Li'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 0
    },
    {
      'id': 282703004,
      'title': '4D Human Body Correspondences from Panoramic Depth Maps',
      'affiliation': [
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1289308332,
          'name': 'Zhong Li'
        },
        {
          'id': 1130150114,
          'name': 'Minye Wu'
        },
        {
          'id': 1084806863,
          'name': 'Wangyiteng Zhou'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2012478815,
          'name': 'Concentric'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 282706650,
      'title': 'Visual Grounding via Accumulated Attention',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2100194206,
          'abbr': 'USTS',
          'name': 'Suzhou University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1013344695,
          'name': 'Chaorui Deng'
        },
        {
          'id': 1103669667,
          'name': 'Qi Wu'
        },
        {
          'id': 1421026305,
          'name': 'Qingyao Wu'
        },
        {
          'id': 1176845588,
          'name': 'Fuyuan Hu'
        },
        {
          'id': 1458583237,
          'name': 'Fan Lyu'
        },
        {
          'id': 1121483151,
          'name': 'Mingkui Tan'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036348455,
          'name': 'Natural language user interface'
        },
        {
          'id': 2044656016,
          'name': 'Phrase'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 0
    },
    {
      'id': 283408536,
      'title': 'Semantic Segmentation Based Building Extraction Method Using Multi-source GIS Map Datasets and Satellite Imagery',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1115707503,
          'name': 'Weijia Li'
        },
        {
          'id': 1307658014,
          'name': 'Conghui He'
        },
        {
          'id': 1270549157,
          'name': 'Jiarui Fang'
        },
        {
          'id': 1427944831,
          'name': 'Haohuan Fu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2015539801,
          'name': 'Ensemble learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2032883977,
          'name': 'Satellite imagery'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025617559,
          'name': 'Multi-source'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'https://ieeexplore.ieee.org/document/8575502/',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 284073105,
      'title': 'Partial Transfer Learning with Selective Adversarial Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1126860916,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1226959733,
          'name': 'Michael I. Jordan'
        }
      ],
      'field': [
        {
          'id': 2012454591,
          'name': 'Negative transfer'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023689791,
          'name': 'Adversarial process'
        },
        {
          'id': 2031926264,
          'name': 'Big data'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 28
    },
    {
      'id': 284476450,
      'title': 'Camera Style Adaptation for Person Re-identification',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1277244399,
          'name': 'Zhun Zhong'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1051842533,
          'name': 'Zhedong Zheng'
        },
        {
          'id': 1438105862,
          'name': 'Shaozi Li'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 41,
      'reference_count': 32
    },
    {
      'id': 284673933,
      'title': 'Pix3D: Dataset and Methods for Single-Image 3D Shape Modeling',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1145259079,
          'name': 'Xingyuan Sun'
        },
        {
          'id': 1385296331,
          'name': 'Jiajun Wu'
        },
        {
          'id': 1285404779,
          'name': 'Xiuming Zhang'
        },
        {
          'id': 1066067475,
          'name': 'Zhoutong Zhang'
        },
        {
          'id': 1104354022,
          'name': 'Chengkai Zhang'
        },
        {
          'id': 1430657300,
          'name': 'Tianfan Xue'
        },
        {
          'id': 1123737115,
          'name': 'Joshua B. Tenenbaum'
        },
        {
          'id': 1234648702,
          'name': 'William T. Freeman'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 60
    },
    {
      'id': 284841145,
      'title': 'Attentive Fashion Grammar Network for Fashion Landmark Detection and Clothing Category Classification',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1079584817,
          'name': 'Yuanlu Xu'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2039181411,
          'name': 'Dependency grammar'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046331716,
          'name': 'Grammar'
        },
        {
          'id': 2027954625,
          'name': 'Clothing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026817921,
          'name': 'Network topology'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022875756,
          'name': 'Rule-based machine translation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 0
    },
    {
      'id': 285081881,
      'title': 'Semi-Supervised Transfer Learning for Image Rain Removal',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1407710234,
          'name': 'Weishu Wei'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wei_Semi-Supervised_Transfer_Learning_for_Image_Rain_Removal_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 285421176,
      'title': 'Iterative Reorganization With Weak Spatial Constraints: Solving Arbitrary Jigsaw Puzzles for Unsupervised Representation Learning',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1000436202,
          'name': 'Chen Wei'
        },
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1324580717,
          'name': 'Xutong Ren'
        },
        {
          'id': 1217745771,
          'name': 'Yingda Xia'
        },
        {
          'id': 1011487126,
          'name': 'Chi Su'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008998833,
          'name': 'Jigsaw'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wei_Iterative_Reorganization_With_Weak_Spatial_Constraints_Solving_Arbitrary_Jigsaw_Puzzles_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 28615867,
      'title': 'Finding Tiny Faces in the Wild with Generative Adversarial Network',
      'affiliation': [
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1041138396,
          'name': 'Yancheng Bai'
        },
        {
          'id': 1235033683,
          'name': 'Yongqiang Zhang'
        },
        {
          'id': 1061600911,
          'name': 'Mingli Ding'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 0
    },
    {
      'id': 286602846,
      'title': 'Erase or Fill? Deep Joint Recurrent Rain Removal and Reconstruction in Videos',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1077278755,
          'name': 'Wenhan Yang'
        },
        {
          'id': 1023932251,
          'name': 'Shuai Yang'
        },
        {
          'id': 1273935622,
          'name': 'Zongming Guo'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 0
    },
    {
      'id': 286701889,
      'title': 'Generative Adversarial Perturbations',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1426691386,
          'name': 'Omid Poursaeed'
        },
        {
          'id': 1144470759,
          'name': 'Isay Katsman'
        },
        {
          'id': 1235094784,
          'name': 'Bicheng Gao'
        },
        {
          'id': 1238813530,
          'name': 'Serge J. Belongie'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 56
    },
    {
      'id': 28702739,
      'title': 'Learning Spatial-Temporal Regularized Correlation Filters for Visual Tracking',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1386848118,
          'name': 'Feng Li'
        },
        {
          'id': 1166342548,
          'name': 'Cheng Tian'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 9,
      'reference_count': 40
    },
    {
      'id': 287442878,
      'title': 'Bringing a Blurry Frame Alive at High Frame-Rate With an Event Camera',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1066655092,
          'name': 'Liyuan Pan'
        },
        {
          'id': 1319183293,
          'name': 'Cedric Scheerlinck'
        },
        {
          'id': 1166961751,
          'name': 'Xin Yu'
        },
        {
          'id': 1424665306,
          'name': 'Richard Hartley'
        },
        {
          'id': 1109581722,
          'name': 'Miaomiao Liu'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017318404,
          'name': 'Frame rate'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pan_Bringing_a_Blurry_Frame_Alive_at_High_Frame-Rate_With_an_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 292761147,
      'title': 'Deep Cross-Media Knowledge Transfer',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1123749845,
          'name': 'Xin Huang'
        },
        {
          'id': 1111804441,
          'name': 'Yuxin Peng'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2044933139,
          'name': 'Hotspot (Wi-Fi)'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 39
    },
    {
      'id': 293750281,
      'title': 'Hyperparameter Optimization for Tracking with Continuous Deep Q-Learning',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1000641760,
          'name': 'Xingping Dong'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1289774990,
          'name': 'Yu Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        }
      ],
      'field': [
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041190030,
          'name': 'Hyperparameter'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032221899,
          'name': 'Q-learning'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2002988612,
          'name': 'Hyperparameter optimization'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 0
    },
    {
      'id': 293817523,
      'title': 'Multi-scale Location-Aware Kernel Representation for Object Detection',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1324123639,
          'name': 'Hao Wang'
        },
        {
          'id': 1484711233,
          'name': 'Qilong Wang'
        },
        {
          'id': 1157522842,
          'name': 'Mingqi Gao'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2031785153,
          'name': 'Polynomial kernel'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 37
    },
    {
      'id': 293927640,
      'title': 'SemStyle: Learning to Generate Stylised Image Captions Using Unaligned Text',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1289644645,
          'name': 'Alexander Patrick Mathews'
        },
        {
          'id': 1338420997,
          'name': 'Lexing Xie'
        },
        {
          'id': 1291806924,
          'name': 'Xuming He'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047608516,
          'name': 'Frame semantics'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033461377,
          'name': 'Syntax'
        },
        {
          'id': 2026170240,
          'name': 'Attractiveness'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2049525942,
          'name': 'Language model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 48
    },
    {
      'id': 297931670,
      'title': 'Environment Upgrade Reinforcement Learning for Non-differentiable Multi-stage Pipelines',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1378060791,
          'name': 'Shuqin Xie'
        },
        {
          'id': 1394806836,
          'name': 'Zitian Chen'
        },
        {
          'id': 1329594775,
          'name': 'Chao Xu'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002248393,
          'name': 'Upgrade'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 298438189,
      'title': 'CRRN: Multi-scale Guided Concurrent Reflection Removal Network',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1458086827,
          'name': 'Renjie Wan'
        },
        {
          'id': 1100550194,
          'name': 'Boxin Shi'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1159618503,
          'name': 'Ah-Hwee Tan'
        },
        {
          'id': 1314678280,
          'name': 'Alex C. Kot'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2015618768,
          'name': 'Separable space'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 29
    },
    {
      'id': 302432529,
      'title': 'Multi-scale Location-Aware Kernel Representation for Object Detection',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1324123639,
          'name': 'Hao Wang'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1157522842,
          'name': 'Mingqi Gao'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2031785153,
          'name': 'Polynomial kernel'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 33
    },
    {
      'id': 302542282,
      'title': 'Dynamic Fusion With Intra- and Inter-Modality Attention Flow for Visual Question Answering',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101195408,
          'abbr': 'SMU',
          'name': 'Singapore Management University'
        }
      ],
      'author': [
        {
          'id': 1144084662,
          'name': 'Peng Gao'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1460379301,
          'name': 'Haoxuan You'
        },
        {
          'id': 1386932000,
          'name': 'Zhengkai Jiang'
        },
        {
          'id': 1273916223,
          'name': 'Pan Lu'
        },
        {
          'id': 1433462722,
          'name': 'Steven C. H. Hoi'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gao_Dynamic_Fusion_With_Intra-_and_Inter-Modality_Attention_Flow_for_Visual_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 303697892,
      'title': 'Dual Skipping Networks',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1059700472,
          'name': 'Changmao Cheng'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1255509433,
          'name': 'Wei Liu'
        },
        {
          'id': 1073731168,
          'name': 'Wenlian Lu'
        },
        {
          'id': 1222297492,
          'name': 'Jianfeng Feng'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2014316225,
          'name': 'Spatial frequency'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2038996624,
          'name': 'Network model'
        },
        {
          'id': 2023036760,
          'name': 'Gating'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 42
    },
    {
      'id': 304040930,
      'title': 'Adversarially Occluded Samples for Person Re-identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1230203872,
          'name': 'Houjing Huang'
        },
        {
          'id': 1229829139,
          'name': 'Dangwei Li'
        },
        {
          'id': 1070491497,
          'name': 'Zhang Zhang'
        },
        {
          'id': 1055699222,
          'name': 'Xiaotang Chen'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2010502260,
          'name': 'Local optimum'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 0
    },
    {
      'id': 304152167,
      'title': 'Resource Aware Person Re-identification Across Multiple Resolutions',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1193438847,
          'name': 'Yan Wang'
        },
        {
          'id': 1033548683,
          'name': 'Lequn Wang'
        },
        {
          'id': 1217544667,
          'name': 'Yurong You'
        },
        {
          'id': 1110834122,
          'name': 'Xu Zou'
        },
        {
          'id': 1253464845,
          'name': 'Vincent Chen'
        },
        {
          'id': 1202505251,
          'name': 'Serena Li'
        },
        {
          'id': 1366951731,
          'name': 'Gao Huang'
        },
        {
          'id': 1419046009,
          'name': 'Bharath Hariharan'
        },
        {
          'id': 1159231599,
          'name': 'Kilian Q. Weinberger'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 11,
      'reference_count': 61
    },
    {
      'id': 304881043,
      'title': 'Interleaved Structured Sparse Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1254646380,
          'name': 'Guotian Xie'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1009100021,
          'name': 'Ting Zhang'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1188079795,
          'name': 'Richang Hong'
        },
        {
          'id': 1240277240,
          'name': 'Guo-Jun Qi'
        }
      ],
      'field': [
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 2
    },
    {
      'id': 305218302,
      'title': 'Revisiting Video Saliency: A Large-Scale Benchmark and a New Model',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1437038452,
          'name': 'Fang Guo'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2040776147,
          'name': 'Generality'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2036320587,
          'name': 'Object type'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 67
    },
    {
      'id': 30553795,
      'title': 'End-to-End Learning of Motion Representation for Video Understanding',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1455971560,
          'name': 'Lijie Fan'
        },
        {
          'id': 1467293440,
          'name': 'Wenbing Huang'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1189023951,
          'name': 'Stefano Ermon'
        },
        {
          'id': 1347218119,
          'name': 'Boqing Gong'
        },
        {
          'id': 1007876913,
          'name': 'Junzhou Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        },
        {
          'id': 2002279732,
          'name': 'Optical computing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045807287,
          'name': 'Brightness'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 8,
      'reference_count': 44
    },
    {
      'id': 305580844,
      'title': 'Iterative Learning with Open-set Noisy Labels',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2104380003,
          'abbr': '',
          'name': 'University of Melbourne'
        }
      ],
      'author': [
        {
          'id': 1036063581,
          'name': 'Yisen Wang'
        },
        {
          'id': 1371439823,
          'name': 'Weiyang Liu'
        },
        {
          'id': 1044809008,
          'name': 'Xingjun Ma'
        },
        {
          'id': 1460973562,
          'name': 'James Bailey'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        },
        {
          'id': 1399541633,
          'name': 'Le Song'
        },
        {
          'id': 1387424504,
          'name': 'Shu-Tao Xia'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015808417,
          'name': 'Iterative learning control'
        },
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 33
    },
    {
      'id': 305797004,
      'title': 'FilterReg: Robust and Efficient Probabilistic Point-Set Registration Using Gaussian Filter and Twist Parameterization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1178628754,
          'name': 'Wei Gao'
        },
        {
          'id': 1427980509,
          'name': 'Russ Tedrake'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2000539458,
          'name': 'Point set registration'
        },
        {
          'id': 2033087121,
          'name': 'Twist'
        },
        {
          'id': 2009245076,
          'name': 'Gaussian filter'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039252156,
          'name': 'Parametrization'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gao_FilterReg_Robust_and_Efficient_Probabilistic_Point-Set_Registration_Using_Gaussian_Filter_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 305907554,
      'title': 'Smooth Neighbors on Teacher Graphs for Semi-Supervised Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1305012402,
          'name': 'Yucen Luo'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        },
        {
          'id': 1446494334,
          'name': 'Mengxi Li'
        },
        {
          'id': 1251220616,
          'name': 'Yong Ren'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036679242,
          'name': 'Word error rate'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 41
    },
    {
      'id': 306667275,
      'title': 'MnasNet: Platform-Aware Neural Architecture Search for Mobile',
      'affiliation': [
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1163002620,
          'name': 'Mingxing Tan'
        },
        {
          'id': 1115960749,
          'name': 'Bo Chen'
        },
        {
          'id': 1496001476,
          'name': 'Ruoming Pang'
        },
        {
          'id': 1045213008,
          'name': 'Vijay Vasudevan'
        },
        {
          'id': 1400062249,
          'name': 'Mark B. Sandler'
        },
        {
          'id': 1044328273,
          'name': 'Andrew Howard'
        },
        {
          'id': 1415089226,
          'name': 'Quoc V. Le'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Tan_MnasNet_Platform-Aware_Neural_Architecture_Search_for_Mobile_CVPR_2019_paper.pdf',
      'citation_count': 29,
      'reference_count': 0
    },
    {
      'id': 307791507,
      'title': 'Multi-oriented Scene Text Detection via Corner Localization and Region Segmentation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1433542305,
          'name': 'Pengyuan Lyu'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1214777185,
          'name': 'Wenhao Wu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2049243508,
          'name': 'Text box'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 54
    },
    {
      'id': 308795649,
      'title': 'Learning Globally Optimized Object Detector via Policy Gradient',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1223346775,
          'name': 'Yongming Rao'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2033391627,
          'name': 'Overhead (computing)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 31083794,
      'title': 'FSRNet: End-to-End Learning Face Super-Resolution with Facial Priors',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103683216,
          'abbr': 'MSU',
          'name': 'Michigan State University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1047159045,
          'name': 'Yu Chen'
        },
        {
          'id': 1178629966,
          'name': 'Ying Tai'
        },
        {
          'id': 1304173056,
          'name': 'Xiaoming Liu'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 38
    },
    {
      'id': 310996265,
      'title': 'Person Transfer GAN to Bridge Domain Gap for Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1124769303,
          'name': 'Longhui Wei'
        },
        {
          'id': 1398975099,
          'name': 'Shiliang Zhang'
        },
        {
          'id': 1317119711,
          'name': 'Wen Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 36,
      'reference_count': 37
    },
    {
      'id': 311200008,
      'title': 'Deep Cross-Media Knowledge Transfer',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1123749845,
          'name': 'Xin Huang'
        },
        {
          'id': 1111804441,
          'name': 'Yuxin Peng'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2044933139,
          'name': 'Hotspot (Wi-Fi)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 41
    },
    {
      'id': 311427959,
      'title': 'Single-Shot Refinement Neural Network for Object Detection',
      'affiliation': [
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1108322389,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1484089631,
          'name': 'Xiao Bian'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2040731089,
          'name': 'Time delay neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 70,
      'reference_count': 46
    },
    {
      'id': 311454737,
      'title': 'Learning Dual Convolutional Neural Networks for Low-Level Vision',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1043059512,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1313041809,
          'name': 'Sifei Liu'
        },
        {
          'id': 1330695574,
          'name': 'Deqing Sun'
        },
        {
          'id': 1346819320,
          'name': 'Jiawei Zhang'
        },
        {
          'id': 1070692057,
          'name': 'Yang Liu'
        },
        {
          'id': 1160123101,
          'name': 'Jimmy S. J. Ren'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 36
    },
    {
      'id': 311493852,
      'title': 'Learning for Disparity Estimation Through Feature Constancy',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1037734705,
          'name': 'Zhengfa Liang'
        },
        {
          'id': 1477404811,
          'name': 'Yiliu Feng'
        },
        {
          'id': 1206681019,
          'name': 'Yulan Guo'
        },
        {
          'id': 1072692215,
          'name': 'Hengzhu Liu'
        },
        {
          'id': 1069844836,
          'name': 'Wei Chen'
        },
        {
          'id': 1486264340,
          'name': 'Linbo Qiao'
        },
        {
          'id': 1496867942,
          'name': 'Li Zhou'
        },
        {
          'id': 1182628058,
          'name': 'Jianfeng Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2008327062,
          'name': 'Correctness'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 10,
      'reference_count': 32
    },
    {
      'id': 312452439,
      'title': 'Partial Transfer Learning with Selective Adversarial Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1126860916,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1226959733,
          'name': 'Michael I. Jordan'
        }
      ],
      'field': [
        {
          'id': 2003617976,
          'name': 'Transfer problem'
        },
        {
          'id': 2031926264,
          'name': 'Big data'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2012454591,
          'name': 'Negative transfer'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 32
    },
    {
      'id': 313009604,
      'title': 'Elastic Boundary Projection for 3D Medical Imaging Segmentation.',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103058886,
          'abbr': '',
          'name': 'Johns Hopkins University School of Medicine'
        }
      ],
      'author': [
        {
          'id': 1038734524,
          'name': 'Tianwei Ni'
        },
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1282991138,
          'name': 'Huangjie Zheng'
        },
        {
          'id': 1008402547,
          'name': 'Elliot K. Fishman'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2038956318,
          'name': 'Medical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Ni_Elastic_Boundary_Projection_for_3D_Medical_Image_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 315904601,
      'title': 'Mean-Variance Loss for Deep Age Estimation from a Face',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1300075300,
          'name': 'Hongyu Pan'
        },
        {
          'id': 1217280926,
          'name': 'Hu Han'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2044296626,
          'name': 'Age regression'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012275282,
          'name': 'Social network'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 0
    },
    {
      'id': 31790801,
      'title': 'Dual Attention Matching Network for Context-Aware Feature Sequence Based Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        }
      ],
      'author': [
        {
          'id': 1162891747,
          'name': 'Jianlou Si'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1267223511,
          'name': 'Chun-Guang Li'
        },
        {
          'id': 1356416651,
          'name': 'Jason Kuen'
        },
        {
          'id': 1392639789,
          'name': 'Xiangfei Kong'
        },
        {
          'id': 1314678280,
          'name': 'Alex C. Kot'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2010150933,
          'name': 'Metric space'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040590825,
          'name': 'Decorrelation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 30
    },
    {
      'id': 318064021,
      'title': 'Triplet-Center Loss for Multi-view 3D Object Retrieval',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1032603775,
          'name': 'Xinwei He'
        },
        {
          'id': 1320022297,
          'name': 'Yang Zhou'
        },
        {
          'id': 1164775868,
          'name': 'Zhichao Zhou'
        },
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 38
    },
    {
      'id': 318146553,
      'title': 'Ordinal Depth Supervision for 3D Human Pose Estimation',
      'affiliation': [
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1099865504,
          'name': 'Georgios Pavlakos'
        },
        {
          'id': 1330511943,
          'name': 'Xiaowei Zhou'
        },
        {
          'id': 1375446170,
          'name': 'Kostas Daniilidis'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2004061258,
          'name': 'Motion capture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 62
    },
    {
      'id': 320236824,
      'title': 'Towards Open-Set Identity Preserving Face Synthesis',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1373420012,
          'name': 'Jianmin Bao'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2007597257,
          'name': 'Morphing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 38
    },
    {
      'id': 320239661,
      'title': 'Video Rain Streak Removal by Multiscale Convolutional Sparse Coding',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1250996801,
          'name': 'Minghan Li'
        },
        {
          'id': 1097800340,
          'name': 'Qi Xie'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1215664862,
          'name': 'Wei Wei'
        },
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1011930138,
          'name': 'Jing Tao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        }
      ],
      'field': [
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011893552,
          'name': 'Streak'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 0
    },
    {
      'id': 320424753,
      'title': 'Temporal Hallucinating for Action Recognition with Few Still Images',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1195612761,
          'name': 'Yali Wang'
        },
        {
          'id': 1001532102,
          'name': 'Lei Zhou'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033189281,
          'name': 'Memory module'
        },
        {
          'id': 2047981673,
          'name': 'Hallucinate'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        },
        {
          'id': 2023135446,
          'name': 'Hallucinating'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 320444463,
      'title': 'Non-Local Meets Global: An Integrated Paradigm for Hyperspectral Denoising',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        }
      ],
      'author': [
        {
          'id': 1044388342,
          'name': 'Wei He'
        },
        {
          'id': 1256982411,
          'name': 'Quanming Yao'
        },
        {
          'id': 1002300289,
          'name': 'Chao Li'
        },
        {
          'id': 1018407184,
          'name': 'Naoto Yokoya'
        },
        {
          'id': 1381563987,
          'name': 'Qibin Zhao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/He_Non-Local_Meets_Global_An_Integrated_Paradigm_for_Hyperspectral_Denoising_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 321471400,
      'title': 'W2F: A Weakly-Supervised to Fully-Supervised Framework for Object Detection',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1235033683,
          'name': 'Yongqiang Zhang'
        },
        {
          'id': 1041138396,
          'name': 'Yancheng Bai'
        },
        {
          'id': 1061600911,
          'name': 'Mingli Ding'
        },
        {
          'id': 1448298421,
          'name': 'Yongqiang Li'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 0
    },
    {
      'id': 321887821,
      'title': 'Sparse Photometric 3D Face Reconstruction Guided by Morphable Models',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1375374154,
          'name': 'Xuan Cao'
        },
        {
          'id': 1317179635,
          'name': 'Zhang Chen'
        },
        {
          'id': 1194766867,
          'name': 'Anpei Chen'
        },
        {
          'id': 1295830375,
          'name': 'Xin Chen'
        },
        {
          'id': 1072463225,
          'name': 'Shiying Li'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036141665,
          'name': 'Photometric stereo'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010101173,
          'name': 'Small set'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029958136,
          'name': 'Photometry (optics)'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2037296273,
          'name': 'Robust optimization'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 31
    },
    {
      'id': 323433208,
      'title': 'Single View Stereo Matching',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1241318173,
          'name': 'Yue Luo'
        },
        {
          'id': 1160123101,
          'name': 'Jimmy S. J. Ren'
        },
        {
          'id': 1068274881,
          'name': 'Mude Lin'
        },
        {
          'id': 1028788820,
          'name': 'Jiahao Pang'
        },
        {
          'id': 1311099785,
          'name': 'Wenxiu Sun'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1104906125,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 5,
      'reference_count': 35
    },
    {
      'id': 323953520,
      'title': 'CosFace: Large Margin Cosine Loss for Deep Face Recognition',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1198143559,
          'name': 'Hao Wang'
        },
        {
          'id': 1017284661,
          'name': 'Yitong Wang'
        },
        {
          'id': 1440940030,
          'name': 'Zheng Zhou'
        },
        {
          'id': 1056467124,
          'name': 'Xing Ji'
        },
        {
          'id': 1146907601,
          'name': 'Dihong Gong'
        },
        {
          'id': 1140658563,
          'name': 'Jingchao Zhou'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2011384812,
          'name': 'Maximization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008670243,
          'name': 'Trigonometric functions'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 13,
      'reference_count': 46
    },
    {
      'id': 326670357,
      'title': 'Structure Inference Net: Object Detection Using Scene-Level Context and Instance-Level Relationships',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1005945216,
          'name': 'Yong Liu'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 34
    },
    {
      'id': 328685259,
      'title': 'Geometry Guided Convolutional Neural Networks for Self-Supervised Video Representation Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1347218119,
          'name': 'Boqing Gong'
        },
        {
          'id': 1359305277,
          'name': 'Kun Liu'
        },
        {
          'id': 1464060409,
          'name': 'Hao Su'
        },
        {
          'id': 1471272024,
          'name': 'Leonidas J. Guibas'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2015875560,
          'name': 'Geometry'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 0
    },
    {
      'id': 330621715,
      'title': 'The ApolloScape Dataset for Autonomous Driving',
      'affiliation': [
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104227910,
          'abbr': 'AUP',
          'name': 'University of Paris'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        }
      ],
      'author': [
        {
          'id': 1477306013,
          'name': 'Xinyu Huang'
        },
        {
          'id': 1014289154,
          'name': 'Xinjing Cheng'
        },
        {
          'id': 1134320656,
          'name': 'Qichuan Geng'
        },
        {
          'id': 1196677640,
          'name': 'Binbin Cao'
        },
        {
          'id': 1395901344,
          'name': 'Dingfu Zhou'
        },
        {
          'id': 1226191267,
          'name': 'Peng Wang'
        },
        {
          'id': 1130467706,
          'name': 'Yuanqing Lin'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2009553560,
          'name': 'Limiting'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w14/Huang_The_ApolloScape_Dataset_CVPR_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 13
    },
    {
      'id': 331429361,
      'title': 'CartoonGAN: Generative Adversarial Networks for Photo Cartoonization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1030300495,
          'name': 'Yang Chen'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        },
        {
          'id': 1212426579,
          'name': 'Yong-Jin Liu'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2041203790,
          'name': 'Computer graphics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2032401552,
          'name': 'Abstraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 0
    },
    {
      'id': 332721275,
      'title': 'Bidirectional Attentive Fusion with Context Gating for Dense Video Captioning',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1048077541,
          'name': 'Jingwen Wang'
        },
        {
          'id': 1243936135,
          'name': 'Wenhao Jiang'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        },
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        }
      ],
      'field': [
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2023036760,
          'name': 'Gating'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 44
    },
    {
      'id': 332910905,
      'title': 'Deep Regression Forests for Age Estimation',
      'affiliation': [
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1100920997,
          'name': 'Yilu Guo'
        },
        {
          'id': 1204480417,
          'name': 'Yan Wang'
        },
        {
          'id': 1034237242,
          'name': 'Kai Zhao'
        },
        {
          'id': 1000835752,
          'name': 'Bo Wang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2040723423,
          'name': 'Nonlinear regression'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035071765,
          'name': 'Technical report'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 47
    },
    {
      'id': 333847480,
      'title': 'GraphBit: Bitwise Interaction Mining via Deep Reinforcement Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1495536695,
          'name': 'Yueqi Duan'
        },
        {
          'id': 1499286484,
          'name': 'Ziwei Wang'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1037813009,
          'name': 'Xudong Lin'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2028333178,
          'name': 'Mutual information'
        },
        {
          'id': 2033482100,
          'name': 'Directed acyclic graph'
        },
        {
          'id': 2048258097,
          'name': 'Bitwise operation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 335287569,
      'title': 'Deep Semantic Face Deblurring',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        }
      ],
      'author': [
        {
          'id': 1077019498,
          'name': 'Ziyi Shen'
        },
        {
          'id': 1477719517,
          'name': 'Wei-Sheng Lai'
        },
        {
          'id': 1252794356,
          'name': 'Tingfa Xu'
        },
        {
          'id': 1098313743,
          'name': 'Jan Kautz'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036626715,
          'name': 'Strong prior'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 5,
      'reference_count': 45
    },
    {
      'id': 335584111,
      'title': 'Eliminating Background-bias for Robust Person Re-identification',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1424800356,
          'name': 'Maoqing Tian'
        },
        {
          'id': 1246061694,
          'name': 'Shuai Yi'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1135465378,
          'name': 'Shihua Li'
        },
        {
          'id': 1245546266,
          'name': 'Xuesen Zhang'
        },
        {
          'id': 1443702376,
          'name': 'Jianping Shi'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 0
    },
    {
      'id': 337618602,
      'title': 'Adversarial Defense by Stratified Convolutional Sparse Coding',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1487232324,
          'name': 'Bo Sun'
        },
        {
          'id': 1076885429,
          'name': 'Nian-hsuan Tsai'
        },
        {
          'id': 1090958914,
          'name': 'Fangchen Liu'
        },
        {
          'id': 1359103361,
          'name': 'Ronald Yu'
        },
        {
          'id': 1116347973,
          'name': 'Hao Su'
        }
      ],
      'field': [
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Sun_Adversarial_Defense_by_Stratified_Convolutional_Sparse_Coding_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 339528134,
      'title': 'Bidirectional Attentive Fusion with Context Gating for Dense Video Captioning',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102262321,
          'abbr': 'UTA',
          'name': 'University of Texas at Arlington'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1048077541,
          'name': 'Jingwen Wang'
        },
        {
          'id': 1243936135,
          'name': 'Wenhao Jiang'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023036760,
          'name': 'Gating'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 42
    },
    {
      'id': 340505492,
      'title': 'Easy Identification from Better Constraints: Multi-shot Person Re-identification from Reference Constraints',
      'affiliation': [
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1028139797,
          'name': 'Jiahuan Zhou'
        },
        {
          'id': 1204542489,
          'name': 'Bing Su'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 340595847,
      'title': 'A Large-Scale Attribute Dataset for Zero-Shot Learning',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1113109398,
          'name': 'Bo Zhao'
        },
        {
          'id': 1366165068,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1030081116,
          'name': 'Rui Liang'
        },
        {
          'id': 1066183190,
          'name': 'Jiahong Wu'
        },
        {
          'id': 1018415965,
          'name': 'Yonggang Wang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/html/MULA/Zhao_A_Large-Scale_Attribute_Dataset_for_Zero-Shot_Learning_CVPRW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 343097089,
      'title': 'Person Transfer GAN to Bridge Domain Gap for Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1124769303,
          'name': 'Longhui Wei'
        },
        {
          'id': 1398975099,
          'name': 'Shiliang Zhang'
        },
        {
          'id': 1317119711,
          'name': 'Wen Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 28
    },
    {
      'id': 344406729,
      'title': 'Intrinsic Image Transformation via Scale Space Decomposition',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1330932121,
          'name': 'Lechao Cheng'
        },
        {
          'id': 1390387188,
          'name': 'Chengyi Zhang'
        },
        {
          'id': 1296564998,
          'name': 'Zicheng Liao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046430142,
          'name': 'Transformation (function)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047564696,
          'name': 'Input/output'
        },
        {
          'id': 2022543674,
          'name': 'Albedo'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        },
        {
          'id': 2018324713,
          'name': 'Scale space'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 39
    },
    {
      'id': 344950254,
      'title': 'Stacked Conditional Generative Adversarial Networks for Jointly Learning Shadow Detection and Shadow Removal',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1323321006,
          'name': 'Jifeng Wang'
        },
        {
          'id': 1204869756,
          'name': 'Xiang Li'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2013878316,
          'name': 'Shadow mask'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 5,
      'reference_count': 54
    },
    {
      'id': 345209128,
      'title': 'Self-Calibrating Polarising Radiometric Calibration',
      'affiliation': [
        {
          'id': 2101979648,
          'abbr': 'SUTD',
          'name': 'Singapore University of Technology and Design'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102805074,
          'abbr': 'NII',
          'name': 'National Institute of Informatics'
        }
      ],
      'author': [
        {
          'id': 1019603821,
          'name': 'Daniel Teo Guangwei'
        },
        {
          'id': 1100550194,
          'name': 'Boxin Shi'
        },
        {
          'id': 1285220304,
          'name': 'Yinqiang Zheng'
        },
        {
          'id': 1022309053,
          'name': 'Sai-Kit Yeung'
        }
      ],
      'field': [
        {
          'id': 2034023280,
          'name': 'Radiometric calibration'
        },
        {
          'id': 2026413035,
          'name': 'Polarizing filter'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026022629,
          'name': 'Radiometry'
        },
        {
          'id': 2020173566,
          'name': 'Multiple exposure'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033244107,
          'name': 'Polarization (waves)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 346041208,
      'title': 'Learning a Discriminative Feature Network for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1255703345,
          'name': 'Changqian Yu'
        },
        {
          'id': 1189593101,
          'name': 'Jingbo Wang'
        },
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1028164167,
          'name': 'Changxin Gao'
        },
        {
          'id': 1003104441,
          'name': 'Gang Yu'
        },
        {
          'id': 1248648678,
          'name': 'Nong Sang'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 71,
      'reference_count': 33
    },
    {
      'id': 347182473,
      'title': 'The ApolloScape Dataset for Autonomous Driving',
      'affiliation': [
        {
          'id': 2102853692,
          'abbr': 'NCCU',
          'name': 'North Carolina Central University'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        }
      ],
      'author': [
        {
          'id': 1277338163,
          'name': 'Xinyu Huang'
        },
        {
          'id': 1014289154,
          'name': 'Xinjing Cheng'
        },
        {
          'id': 1134320656,
          'name': 'Qichuan Geng'
        },
        {
          'id': 1196677640,
          'name': 'Binbin Cao'
        },
        {
          'id': 1057527437,
          'name': 'Dingfu Zhou'
        },
        {
          'id': 1226191267,
          'name': 'Peng Wang'
        },
        {
          'id': 1013659899,
          'name': 'Yuanqing Lin'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2015844766,
          'name': 'Pipeline transport'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 71,
      'reference_count': 60
    },
    {
      'id': 347935476,
      'title': 'Covariance Pooling for Facial Expression Recognition',
      'affiliation': [
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1234478356,
          'name': 'Dinesh Acharya'
        },
        {
          'id': 1030210329,
          'name': 'Zhiwu Huang'
        },
        {
          'id': 1477003323,
          'name': 'Danda Pani Paudel'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040935861,
          'name': 'Affect (psychology)'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 24
    },
    {
      'id': 347986227,
      'title': 'View Extrapolation of Human Body from a Single Image',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1011894368,
          'name': 'Hao Zhu'
        },
        {
          'id': 1116347973,
          'name': 'Hao Su'
        },
        {
          'id': 1226191267,
          'name': 'Peng Wang'
        },
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        },
        {
          'id': 1086456018,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2032713836,
          'name': 'Flow (psychology)'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2016560143,
          'name': 'Observable'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043059786,
          'name': 'Extrapolation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 40
    },
    {
      'id': 351710833,
      'title': 'Self-Supervised Adversarial Hashing Networks for Cross-Modal Retrieval',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1132655615,
          'name': 'Chao Li'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1163912921,
          'name': 'Ning Li'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2045207422,
          'name': 'Semantic network'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 14
    },
    {
      'id': 352517031,
      'title': 'AON: Towards Arbitrarily-Oriented Text Recognition',
      'affiliation': [
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1046738235,
          'name': 'Zhanzhan Cheng'
        },
        {
          'id': 1322345530,
          'name': 'Yangliu Xu'
        },
        {
          'id': 1351963402,
          'name': 'Fan Bai'
        },
        {
          'id': 1130921084,
          'name': 'Yi Niu'
        },
        {
          'id': 1384883042,
          'name': 'Shiliang Pu'
        },
        {
          'id': 1494078016,
          'name': 'Shuigeng Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2039013772,
          'name': 'Optical character recognition'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 0
    },
    {
      'id': 35290033,
      'title': 'Content-Sensitive Supervoxels via Uniform Tessellations on Video Manifolds',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1300318540,
          'name': 'Ran Yi'
        },
        {
          'id': 1212426579,
          'name': 'Yong-Jin Liu'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        }
      ],
      'field': [
        {
          'id': 2049269054,
          'name': 'Homogeneous'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008782984,
          'name': 'Tessellation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001805909,
          'name': 'Simple extension'
        },
        {
          'id': 2005769687,
          'name': 'Competitive analysis'
        },
        {
          'id': 2037875506,
          'name': 'Voxel'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2003050182,
          'name': 'Cascading Style Sheets'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 354158423,
      'title': 'FSRNet: End-to-End Learning Face Super-Resolution with Facial Priors',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103683216,
          'abbr': 'MSU',
          'name': 'Michigan State University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1047159045,
          'name': 'Yu Chen'
        },
        {
          'id': 1164892754,
          'name': 'Ying Tai'
        },
        {
          'id': 1304173056,
          'name': 'Xiaoming Liu'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 41
    },
    {
      'id': 354165549,
      'title': 'Arbitrary Style Transfer with Deep Feature Reshuffle',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1409404232,
          'name': 'Shuyang Gu'
        },
        {
          'id': 1345736629,
          'name': 'Congliang Chen'
        },
        {
          'id': 1092358509,
          'name': 'Jing Liao'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2026372859,
          'name': 'Permutation'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 35
    },
    {
      'id': 354358702,
      'title': 'VizWiz Grand Challenge: Answering Visual Questions from Blind People',
      'affiliation': [
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104172720,
          'abbr': 'UCB',
          'name': 'University of Colorado Boulder'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1091453635,
          'name': 'Danna Gurari'
        },
        {
          'id': 1056755133,
          'name': 'Qing Li'
        },
        {
          'id': 1280518788,
          'name': 'Abigale Stangl'
        },
        {
          'id': 1323926631,
          'name': 'Anhong Guo'
        },
        {
          'id': 1066765949,
          'name': 'Chi Lin'
        },
        {
          'id': 1075935428,
          'name': 'Kristen Grauman'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        },
        {
          'id': 1457233645,
          'name': 'Jeffrey P. Bigham'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2015161306,
          'name': 'Mobile phone'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2007507776,
          'name': 'Blindness'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 37
    },
    {
      'id': 355091336,
      'title': 'An Online and Flexible Multi-object Tracking Framework Using Long Short-Term Memory',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1467177648,
          'name': 'XingYu Wan'
        },
        {
          'id': 1072625952,
          'name': 'Jinjun Wang'
        },
        {
          'id': 1192874082,
          'name': 'Sanping Zhou'
        }
      ],
      'field': [
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2023893789,
          'name': 'Kalman filter'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2014351305,
          'name': 'Long short term memory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w21/Wan_An_Online_and_CVPR_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 355183954,
      'title': 'Dual Attention Matching Network for Context-Aware Feature Sequence Based Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        }
      ],
      'author': [
        {
          'id': 1162891747,
          'name': 'Jianlou Si'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1267223511,
          'name': 'Chun-Guang Li'
        },
        {
          'id': 1356416651,
          'name': 'Jason Kuen'
        },
        {
          'id': 1392639789,
          'name': 'Xiangfei Kong'
        },
        {
          'id': 1314678280,
          'name': 'Alex C. Kot'
        },
        {
          'id': 1423324634,
          'name': 'Gang Wang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010150933,
          'name': 'Metric space'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040590825,
          'name': 'Decorrelation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 13,
      'reference_count': 55
    },
    {
      'id': 355522195,
      'title': 'Attentive Generative Adversarial Network for Raindrop Removal from A Single Image',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1245424907,
          'name': 'Rui Qian'
        },
        {
          'id': 1276044969,
          'name': 'Robby T. Tan'
        },
        {
          'id': 1077278755,
          'name': 'Wenhan Yang'
        },
        {
          'id': 1470369375,
          'name': 'Jiajun Su'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041894794,
          'name': 'Local consistency'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008563812,
          'name': 'Lens (optics)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 23
    },
    {
      'id': 35566961,
      'title': 'View Extrapolation of Human Body from a Single Image',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1011894368,
          'name': 'Hao Zhu'
        },
        {
          'id': 1464060409,
          'name': 'Hao Su'
        },
        {
          'id': 1226191267,
          'name': 'Peng Wang'
        },
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        },
        {
          'id': 1086456018,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2043059786,
          'name': 'Extrapolation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2016560143,
          'name': 'Observable'
        },
        {
          'id': 2004356190,
          'name': 'Composition (visual arts)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 36
    },
    {
      'id': 357131684,
      'title': 'Dimensionality\'s Blessing: Clustering Images by Underlying Distribution',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103675905,
          'abbr': 'OSAKA-U',
          'name': 'Osaka University'
        }
      ],
      'author': [
        {
          'id': 1195216375,
          'name': 'Wen Yan Lin'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1118373277,
          'name': 'Siying Liu'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        }
      ],
      'field': [
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2023756774,
          'name': 'Cluster (physics)'
        },
        {
          'id': 2008582367,
          'name': 'Phenomenon'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2029735533,
          'name': 'Data point'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031529057,
          'name': 'Law of large numbers'
        },
        {
          'id': 2000668410,
          'name': 'Random variable'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 43
    },
    {
      'id': 357283133,
      'title': 'HashGAN: Deep Learning to Hash with Pair Conditional Wasserstein GAN',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1027988051,
          'name': 'Yue Cao'
        },
        {
          'id': 1148092358,
          'name': 'Bin Liu'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 35941833,
      'title': 'Monocular Relative Depth Perception with Web Stereo Data Supervision',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2104553663,
          'abbr': '',
          'name': 'Samsung'
        }
      ],
      'author': [
        {
          'id': 1131102328,
          'name': 'Ke Xian'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1216320010,
          'name': 'Zhiguo Cao'
        },
        {
          'id': 1257968729,
          'name': 'Hao Lu'
        },
        {
          'id': 1345972902,
          'name': 'Yang Xiao'
        },
        {
          'id': 1437440844,
          'name': 'Ruibo Li'
        },
        {
          'id': 1074532305,
          'name': 'Zhenbo Luo'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2014250958,
          'name': 'Depth perception'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 0
    },
    {
      'id': 360798292,
      'title': 'LSTM Pose Machines',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1241318173,
          'name': 'Yue Luo'
        },
        {
          'id': 1028004617,
          'name': 'Jimmy S. J. Ren'
        },
        {
          'id': 1267345673,
          'name': 'Zhouxia Wang'
        },
        {
          'id': 1401096853,
          'name': 'Wenxiu Sun'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1085799393,
          'name': 'Jianbo Liu'
        },
        {
          'id': 1217660585,
          'name': 'Jiahao Pang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 36
    },
    {
      'id': 363618285,
      'title': 'Structure from Recurrent Motion: From Rigidity to Recurrency',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        }
      ],
      'author': [
        {
          'id': 1297620460,
          'name': 'Xiu Li'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1065834811,
          'name': 'Hanbyul Joo'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1082988072,
          'name': 'Yaser Sheikh'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2009654346,
          'name': 'Rigidity (psychology)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 35
    },
    {
      'id': 364288637,
      'title': 'ICE-BA: Incremental, Consistent and Efficient Bundle Adjustment for Visual-Inertial SLAM',
      'affiliation': [
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1098068505,
          'name': 'Haomin Liu'
        },
        {
          'id': 1320813379,
          'name': 'Mingyu Chen'
        },
        {
          'id': 1439429759,
          'name': 'Guofeng Zhang'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        },
        {
          'id': 1008132429,
          'name': 'Yingze Bao'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2019436259,
          'name': 'Inertial frame of reference'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2021183717,
          'name': 'Simultaneous localization and mapping'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018533029,
          'name': 'Bundle adjustment'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 0
    },
    {
      'id': 366978693,
      'title': 'Deep Adversarial Metric Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1495536695,
          'name': 'Yueqi Duan'
        },
        {
          'id': 1388549606,
          'name': 'Wenzhao Zheng'
        },
        {
          'id': 1037813009,
          'name': 'Xudong Lin'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 0
    },
    {
      'id': 367509761,
      'title': 'Zigzag Learning for Weakly Supervised Object Detection',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1198100917,
          'name': 'Xiaopeng Zhang'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1086324331,
          'name': 'Hongkai Xiong'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003673557,
          'name': 'Masking (art)'
        },
        {
          'id': 2041560669,
          'name': 'Zigzag'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 15
    },
    {
      'id': 367732737,
      'title': 'Memory Matching Networks for One-Shot Image Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103644482,
          'abbr': 'HDU',
          'name': 'Hangzhou Dianzi University'
        }
      ],
      'author': [
        {
          'id': 1140511387,
          'name': 'Qi Cai'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1246526630,
          'name': 'Chenggang Yan'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002093614,
          'name': 'On the fly'
        },
        {
          'id': 2000717699,
          'name': 'Knowledge engineering'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 30
    },
    {
      'id': 369964317,
      'title': 'Automatic 3D Indoor Scene Modeling from Single Panorama',
      'affiliation': [
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1475304097,
          'name': 'Yang Yang'
        },
        {
          'id': 1196590850,
          'name': 'Shi Jin'
        },
        {
          'id': 1402327309,
          'name': 'Ruiyang Liu'
        },
        {
          'id': 1157673674,
          'name': 'Sing Bing Kang'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018721970,
          'name': 'Complex geometry'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045308702,
          'name': 'Constraint graph'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2004058443,
          'name': 'Line segment'
        },
        {
          'id': 2045060697,
          'name': 'Ground plane'
        },
        {
          'id': 2046361361,
          'name': 'Vanishing point'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 370555807,
      'title': 'Jointly Localizing and Describing Events for Dense Video Captioning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1403845832,
          'name': 'Yehao Li'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2029384766,
          'name': 'Descriptive complexity theory'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 38
    },
    {
      'id': 371343987,
      'title': 'Attention-Guided Unified Network for Panoptic Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1362521565,
          'name': 'Yanwei Li'
        },
        {
          'id': 1331532033,
          'name': 'Xinze Chen'
        },
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1407348730,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1460766835,
          'name': 'Guan Huang'
        },
        {
          'id': 1319563912,
          'name': 'Dalong Du'
        },
        {
          'id': 1296563907,
          'name': 'Xingang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024741998,
          'name': 'Panopticon'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Attention-Guided_Unified_Network_for_Panoptic_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 371942522,
      'title': 'Towards Human-Machine Cooperation: Self-Supervised Sample Mining for Object Detection',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1127017914,
          'name': 'Xiaopeng Yan'
        },
        {
          'id': 1044968095,
          'name': 'Dongyu Zhang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010417696,
          'name': 'Active learning'
        },
        {
          'id': 2029111411,
          'name': 'Human–machine system'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 33
    },
    {
      'id': 372896812,
      'title': 'Learning Visual Knowledge Memory Networks for Visual Question Answering',
      'affiliation': [
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1332823866,
          'name': 'Zhou Su'
        },
        {
          'id': 1296593637,
          'name': 'Chen Zhu'
        },
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1028557740,
          'name': 'Dongqi Cai'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        }
      ],
      'field': [
        {
          'id': 2049678556,
          'name': 'Knowledge-based systems'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2009659598,
          'name': 'Comprehension'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2002009818,
          'name': 'Knowledge extraction'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 29
    },
    {
      'id': 373555333,
      'title': 'Texture Mapping for 3D Reconstruction with RGB-D Sensor',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2102722512,
          'abbr': 'NWAFU',
          'name': 'Northwest A&F University'
        }
      ],
      'author': [
        {
          'id': 1417763075,
          'name': 'Yanping Fu'
        },
        {
          'id': 1115773552,
          'name': 'Qingan Yan'
        },
        {
          'id': 1150662102,
          'name': 'Long Yang'
        },
        {
          'id': 1470309353,
          'name': 'Jie Liao'
        },
        {
          'id': 1048335762,
          'name': 'Chunxia Xiao'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037510253,
          'name': 'Texture mapping'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 373804967,
      'title': 'Towards High Performance Video Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1368545847,
          'name': 'Xizhou Zhu'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017069615,
          'name': 'Flight envelope'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 31
    },
    {
      'id': 373925075,
      'title': 'Single-Shot Refinement Neural Network for Object Detection',
      'affiliation': [
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1253565952,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1484089631,
          'name': 'Xiao Bian'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1119311368,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 32,
      'reference_count': 47
    },
    {
      'id': 373966290,
      'title': 'ShuffleNet: An Extremely Efficient Convolutional Neural Network for Mobile Devices',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1089470532,
          'name': 'Xinyu Zhou'
        },
        {
          'id': 1366796233,
          'name': 'MENGXiao-lin'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2002106245,
          'name': 'Distributed computing'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 399,
      'reference_count': 41
    },
    {
      'id': 374120148,
      'title': 'A Twofold Siamese Network for Real-Time Object Tracking',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1193966662,
          'name': 'Anfeng He'
        },
        {
          'id': 1469454660,
          'name': 'Chong Luo'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022748559,
          'name': 'Design choice'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 47,
      'reference_count': 28
    },
    {
      'id': 374408449,
      'title': 'Reinforcement Cutting-Agent Learning for Video Object Segmentation',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1380636237,
          'name': 'Le Yang'
        },
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1174238937,
          'name': 'Xiaojun Chang'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        }
      ],
      'field': [
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039992481,
          'name': 'Markov decision process'
        },
        {
          'id': 2031854447,
          'name': 'Cutting agent'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2046663815,
          'name': 'Rationality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2028906170,
          'name': 'Reinforcement'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 0
    },
    {
      'id': 37579039,
      'title': 'Beyond Holistic Object Recognition: Enriching Image Understanding with Composition States',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        },
        {
          'id': 1464060409,
          'name': 'Hao Su'
        },
        {
          'id': 1489183023,
          'name': 'Yonglu Li'
        },
        {
          'id': 1391997077,
          'name': 'Yongyi Lu'
        },
        {
          'id': 1194319412,
          'name': 'Li Yi'
        },
        {
          'id': 1212626136,
          'name': 'Chi-Keung Tang'
        },
        {
          'id': 1471272024,
          'name': 'Leonidas J. Guibas'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 25
    },
    {
      'id': 375927342,
      'title': 'Future Frame Prediction for Anomaly Detection - A New Baseline',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1405436572,
          'name': 'Wen Liu'
        },
        {
          'id': 1252850696,
          'name': 'Weixin Luo'
        },
        {
          'id': 1406842602,
          'name': 'Dongze Lian'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 8,
      'reference_count': 36
    },
    {
      'id': 376148115,
      'title': 'Regularizing RNNs for Caption Generation by Reconstructing the Past with the Present',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102262321,
          'abbr': 'UTA',
          'name': 'University of Texas at Arlington'
        }
      ],
      'author': [
        {
          'id': 1490737560,
          'name': 'Xinpeng Chen'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1243936135,
          'name': 'Wenhao Jiang'
        },
        {
          'id': 1468884656,
          'name': 'Jian Yao'
        },
        {
          'id': 1018424614,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 37694206,
      'title': 'Attention Clusters: Purely Attention Based Local Feature Integration for Video Classification',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101105301,
          'abbr': 'RU',
          'name': 'Rutgers University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1397158762,
          'name': 'Xiang Long'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1408823315,
          'name': 'Gerard de Melo'
        },
        {
          'id': 1385296331,
          'name': 'Jiajun Wu'
        },
        {
          'id': 1056587154,
          'name': 'Xiao Liu'
        },
        {
          'id': 1256014321,
          'name': 'Shilei Wen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2023756774,
          'name': 'Cluster (physics)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 29
    },
    {
      'id': 377609137,
      'title': 'Detect Globally, Refine Locally: A Novel Approach to Saliency Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102508462,
          'abbr': 'NU',
          'name': 'Northeastern University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1176287567,
          'name': 'Tiantian Wang'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1058857468,
          'name': 'Shuo Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1320816009,
          'name': 'Gang Yang'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 0
    },
    {
      'id': 377897101,
      'title': 'Structure Preserving Video Prediction',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1012488015,
          'name': 'Jingwei Xu'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1189400893,
          'name': 'Zefan Li'
        },
        {
          'id': 1311343399,
          'name': 'Shuo Cheng'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002476364,
          'name': 'Ranging'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 378055511,
      'title': 'Multi-frame Quality Enhancement for Compressed Video',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1044394617,
          'name': 'Ren Yang'
        },
        {
          'id': 1118664104,
          'name': 'Mai Xu'
        },
        {
          'id': 1287578436,
          'name': 'Zulin Wang'
        },
        {
          'id': 1476671974,
          'name': 'Tianyi Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2040758323,
          'name': 'Subnet'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030410974,
          'name': 'Motion compensation'
        },
        {
          'id': 2010620100,
          'name': 'Compression artifact'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 29
    },
    {
      'id': 379830248,
      'title': 'Deep Semantic Face Deblurring',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        }
      ],
      'author': [
        {
          'id': 1077019498,
          'name': 'Ziyi Shen'
        },
        {
          'id': 1477719517,
          'name': 'Wei-Sheng Lai'
        },
        {
          'id': 1252794356,
          'name': 'Tingfa Xu'
        },
        {
          'id': 1098313743,
          'name': 'Jan Kautz'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2036626715,
          'name': 'Strong prior'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 45
    },
    {
      'id': 380470825,
      'title': 'HSA-RNN: Hierarchical Structure-Adaptive RNN for Video Summarization',
      'affiliation': [
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1169077571,
          'name': 'Bin Zhao'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        },
        {
          'id': 1356856029,
          'name': 'Xiaoqiang Lu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030342359,
          'name': 'Automatic summarization'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 0
    },
    {
      'id': 380552694,
      'title': 'Image Blind Denoising with Generative Adversarial Network Based Noise Modeling',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1160310387,
          'name': 'Jingwen Chen'
        },
        {
          'id': 1422104201,
          'name': 'Jiawei Chen'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1361654017,
          'name': 'Ming Yang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001392051,
          'name': 'Discriminative learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 0
    },
    {
      'id': 386575410,
      'title': 'Unsupervised Cross-Dataset Person Re-identification by Transfer Learning of Spatial-Temporal Patterns',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1154819179,
          'name': 'Jianming Lv'
        },
        {
          'id': 1376279573,
          'name': 'Weihang Chen'
        },
        {
          'id': 1488101603,
          'name': 'Qing Li'
        },
        {
          'id': 1430666615,
          'name': 'Can Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018978572,
          'name': 'Incremental learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 17
    },
    {
      'id': 386679298,
      'title': 'Hallucinated-IQA: No-Reference Image Quality Assessment via Adversarial Learning',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1167966062,
          'name': 'Kwan-Yee Lin'
        },
        {
          'id': 1424161119,
          'name': 'Guanxiang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023361932,
          'name': 'Scene statistics'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2025641305,
          'name': 'Hallucinated'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 35
    },
    {
      'id': 386690655,
      'title': 'A Novel Framework for Remote Photoplethysmography Pulse Extraction on Compressed Videos',
      'affiliation': [
        {
          'id': 2100580424,
          'abbr': 'NCHU',
          'name': 'National Chung Hsing University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1448010823,
          'name': 'Changchen Zhao'
        },
        {
          'id': 1456985016,
          'name': 'Chun-Liang Lin'
        },
        {
          'id': 1489751745,
          'name': 'Weihai Chen'
        },
        {
          'id': 1356905622,
          'name': 'Zhengguo Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2013004239,
          'name': 'Colors of noise'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020700813,
          'name': 'Waveform'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2048476053,
          'name': 'Pulse (signal processing)'
        },
        {
          'id': 2029110680,
          'name': 'Uncompressed video'
        },
        {
          'id': 2022159621,
          'name': 'Photoplethysmogram'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w27/Zhao_A_Novel_Framework_CVPR_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 386808016,
      'title': 'Depth-Aware Stereo Video Retargeting',
      'affiliation': [
        {
          'id': 2101283317,
          'abbr': 'NTHU',
          'name': 'National Tsing Hua University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        }
      ],
      'author': [
        {
          'id': 1138746327,
          'name': 'Bing Li'
        },
        {
          'id': 1057299451,
          'name': 'Chia-Wen Lin'
        },
        {
          'id': 1100550194,
          'name': 'Boxin Shi'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        },
        {
          'id': 1487389517,
          'name': 'C.-C. Jay Kuo'
        }
      ],
      'field': [
        {
          'id': 2038091395,
          'name': 'Constrained optimization'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014491173,
          'name': 'User experience design'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042430043,
          'name': 'Retargeting'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 38756642,
      'title': 'Face Aging with Identity-Preserved Conditional Generative Adversarial Networks',
      'affiliation': [
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1130705554,
          'name': 'Xu Tang'
        },
        {
          'id': 1378847010,
          'name': 'Zongwei Wang'
        },
        {
          'id': 1252850696,
          'name': 'Weixin Luo'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 0
    },
    {
      'id': 387935178,
      'title': 'Robust Classification with Convolutional Prototype Learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1192235684,
          'name': 'Hong-Ming Yang'
        },
        {
          'id': 1279838029,
          'name': 'Xu-Yao Zhang'
        },
        {
          'id': 1382350923,
          'name': 'Fei Yin'
        },
        {
          'id': 1429067054,
          'name': 'Cheng-Lin Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2041368339,
          'name': 'Concept learning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 27
    },
    {
      'id': 389621978,
      'title': 'ComDefend: An Efficient Image Compression Model to Defend Adversarial Examples',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1414441811,
          'name': 'Xiaojun Jia'
        },
        {
          'id': 1056753598,
          'name': 'Xingxing Wei'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1218663880,
          'name': 'Hassan Foroosh'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Jia_ComDefend_An_Efficient_Image_Compression_Model_to_Defend_Adversarial_Examples_CVPR_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 390819279,
      'title': 'Fine-Grained Video Captioning for Sports Narrative',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1078896886,
          'name': 'Huanyu Yu'
        },
        {
          'id': 1311343399,
          'name': 'Shuo Cheng'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1112955314,
          'name': 'Minsi Wang'
        },
        {
          'id': 1012397796,
          'name': 'Jian Zhang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2043073214,
          'name': 'Linguistic performance'
        },
        {
          'id': 2046963195,
          'name': 'Narrative'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 0
    },
    {
      'id': 391900895,
      'title': 'High Performance Visual Tracking with Siamese Region Proposal Network',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1197729198,
          'name': 'Bo Li'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1004795249,
          'name': 'Wei Wu'
        },
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2042225087,
          'name': 'Subnetwork'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 97,
      'reference_count': 0
    },
    {
      'id': 392098782,
      'title': 'Alive Caricature from 2D to 3D',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1400579019,
          'name': 'Qianyi Wu'
        },
        {
          'id': 1142911606,
          'name': 'Juyong Zhang'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        },
        {
          'id': 1170812991,
          'name': 'Jianmin Zheng'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        }
      ],
      'field': [
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2043059786,
          'name': 'Extrapolation'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2040543323,
          'name': '2D to 3D conversion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 39
    },
    {
      'id': 39470459,
      'title': 'Image Correction via Deep Reciprocating HDR Transformation',
      'affiliation': [
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1109983409,
          'name': 'Xin Yang'
        },
        {
          'id': 1329658043,
          'name': 'Ke Xu'
        },
        {
          'id': 1355309202,
          'name': 'Yibing Song'
        },
        {
          'id': 1075635062,
          'name': 'Qiang Zhang'
        },
        {
          'id': 1101786650,
          'name': 'Xiaopeng Wei'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043955330,
          'name': 'Dynamic range'
        },
        {
          'id': 2041313107,
          'name': 'Tone mapping'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2034573784,
          'name': 'Image formation'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042633047,
          'name': 'Reciprocating motion'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 49
    },
    {
      'id': 398446417,
      'title': 'Deep Network Interpolation for Continuous Imagery Effect Transition.',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1158326101,
          'name': 'Xintao Wang'
        },
        {
          'id': 1274028524,
          'name': 'Ke Yu'
        },
        {
          'id': 1130918340,
          'name': 'Chao Dong'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Deep_Network_Interpolation_for_Continuous_Imagery_Effect_Transition_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 399506095,
      'title': 'Memory In Memory: A Predictive Neural Network for Learning Higher-Order Non-Stationarity from Spatiotemporal Dynamics.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103382811,
          'abbr': 'UIC',
          'name': 'University of Illinois at Chicago'
        }
      ],
      'author': [
        {
          'id': 1498309642,
          'name': 'Yunbo Wang'
        },
        {
          'id': 1444819693,
          'name': 'Jianjin Zhang'
        },
        {
          'id': 1079547771,
          'name': 'HongYu Zhu'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1445179598,
          'name': 'Philip S. Yu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Memory_in_Memory_A_Predictive_Neural_Network_for_Learning_Higher-Order_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 400681946,
      'title': 'Variational Autoencoders for Deforming 3D Mesh Models',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1374222687,
          'name': 'Qingyang Tan'
        },
        {
          'id': 1354158678,
          'name': 'Lin Gao'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        },
        {
          'id': 1439972712,
          'name': 'Shihong Xia'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2043875830,
          'name': 'Computer animation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 42
    },
    {
      'id': 401272466,
      'title': 'An End-to-End TextSpotter with Explicit Alignment and Attention',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        }
      ],
      'author': [
        {
          'id': 1451904479,
          'name': 'Tong He'
        },
        {
          'id': 1321815528,
          'name': 'Zhi Tian'
        },
        {
          'id': 1054920101,
          'name': 'Weilin Huang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1333537378,
          'name': 'Changming Sun'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021282490,
          'name': 'Word recognition'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2015605404,
          'name': 'Lexicon'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 25
    },
    {
      'id': 403013744,
      'title': 'Separating Style and Content for Generalized Style Transfer',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1007889389,
          'name': 'Yexun Zhang'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1275439962,
          'name': 'Wenbin Cai'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016707335,
          'name': 'Typeface'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 20
    },
    {
      'id': 404696748,
      'title': 'Fast End-to-End Trainable Guided Filter',
      'affiliation': [
        {
          'id': 2100330623,
          'abbr': '',
          'name': 'eBay'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1114933458,
          'name': 'Huikai Wu'
        },
        {
          'id': 1250283021,
          'name': 'Shuai Zheng'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2046430142,
          'name': 'Transformation (function)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 30
    },
    {
      'id': 405421491,
      'title': 'Direct Shape Regression Networks for End-to-End Face Alignment',
      'affiliation': [
        {
          'id': 2102262321,
          'abbr': 'UTA',
          'name': 'University of Texas at Arlington'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        }
      ],
      'author': [
        {
          'id': 1399110128,
          'name': 'Xin Miao'
        },
        {
          'id': 1348093805,
          'name': 'Xiantong Zhen'
        },
        {
          'id': 1025575364,
          'name': 'Xianglong Liu'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1355972768,
          'name': 'Vassilis Athitsos'
        },
        {
          'id': 1282955999,
          'name': 'Heng Huang'
        }
      ],
      'field': [
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2005310215,
          'name': 'Structured prediction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 0
    },
    {
      'id': 405726165,
      'title': 'Classifier Learning with Prior Probabilities for Facial Action Unit Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1073183237,
          'name': 'Yong Zhang'
        },
        {
          'id': 1442505283,
          'name': 'Weiming Dong'
        },
        {
          'id': 1341931364,
          'name': 'Bao-Gang Hu'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2017876651,
          'name': 'Subject-matter expert'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 407892328,
      'title': 'Structure from Recurrent Motion: From Rigidity to Recurrency',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        }
      ],
      'author': [
        {
          'id': 1297620460,
          'name': 'Xiu Li'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1065834811,
          'name': 'Hanbyul Joo'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1278162431,
          'name': 'Yaser Sheikh'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2009654346,
          'name': 'Rigidity (psychology)'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 409098362,
      'title': 'Multi-image Semantic Matching by Mining Consistent Features',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        }
      ],
      'author': [
        {
          'id': 1392425096,
          'name': 'Qianqian Wang'
        },
        {
          'id': 1330511943,
          'name': 'Xiaowei Zhou'
        },
        {
          'id': 1375446170,
          'name': 'Kostas Daniilidis'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2026978225,
          'name': 'Semantic computing'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2022766243,
          'name': 'Multi-image'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2000444311,
          'name': 'Semantic matching'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 44
    },
    {
      'id': 409401335,
      'title': 'Image-Image Domain Adaptation with Preserved Self-Similarity and Domain-Dissimilarity for Person Re-identification',
      'affiliation': [
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1163501203,
          'name': 'Weijian Deng'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1476639755,
          'name': 'Guoliang Kang'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029117650,
          'name': 'Self-similarity'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 38
    },
    {
      'id': 414129230,
      'title': 'Multi-shot Pedestrian Re-identification via Sequential Decision Making',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1200257162,
          'name': 'Jianfu Zhang'
        },
        {
          'id': 1128137696,
          'name': 'Naiyan Wang'
        },
        {
          'id': 1195526895,
          'name': 'Liqing Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2007742396,
          'name': 'Trade-off'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007812099,
          'name': 'Time series'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 23
    },
    {
      'id': 414589740,
      'title': 'CosFace: Large Margin Cosine Loss for Deep Face Recognition',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1198143559,
          'name': 'Hao Wang'
        },
        {
          'id': 1017284661,
          'name': 'Yitong Wang'
        },
        {
          'id': 1440940030,
          'name': 'Zheng Zhou'
        },
        {
          'id': 1056467124,
          'name': 'Xing Ji'
        },
        {
          'id': 1135404713,
          'name': 'Dihong Gong'
        },
        {
          'id': 1140658563,
          'name': 'Jingchao Zhou'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2011384812,
          'name': 'Maximization'
        },
        {
          'id': 2008670243,
          'name': 'Trigonometric functions'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 91,
      'reference_count': 30
    },
    {
      'id': 415245941,
      'title': 'Multi-oriented Scene Text Detection via Corner Localization and Region Segmentation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1433542305,
          'name': 'Pengyuan Lyu'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1214777185,
          'name': 'Wenhao Wu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2049243508,
          'name': 'Text box'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023746547,
          'name': 'Aspect ratio (image)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000871037,
          'name': 'Market segmentation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 37
    },
    {
      'id': 417245974,
      'title': 'Fast End-to-End Trainable Guided Filter',
      'affiliation': [
        {
          'id': 2100330623,
          'abbr': '',
          'name': 'eBay'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1114933458,
          'name': 'Huikai Wu'
        },
        {
          'id': 1250283021,
          'name': 'Shuai Zheng'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1460651777,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042592337,
          'name': 'Linear map'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        },
        {
          'id': 2046430142,
          'name': 'Transformation (function)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 39
    },
    {
      'id': 417324600,
      'title': 'Decoupled Networks',
      'affiliation': [
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100293592,
          'abbr': 'Eagles',
          'name': 'Emory University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1371439823,
          'name': 'Weiyang Liu'
        },
        {
          'id': 1294720015,
          'name': 'Zhen Liu'
        },
        {
          'id': 1096488246,
          'name': 'Zhiding Yu'
        },
        {
          'id': 1003235921,
          'name': 'Bo Dai'
        },
        {
          'id': 1376334799,
          'name': 'Rongmei Lin'
        },
        {
          'id': 1036063581,
          'name': 'Yisen Wang'
        },
        {
          'id': 1074242694,
          'name': 'James M. Rehg'
        },
        {
          'id': 1399541633,
          'name': 'Le Song'
        }
      ],
      'field': [
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 13
    },
    {
      'id': 417644904,
      'title': 'Multi-level Fusion Based 3D Object Detection from Monocular Images',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1004545915,
          'name': 'Bin Xu'
        },
        {
          'id': 1065148540,
          'name': 'Zhenzhong Chen'
        }
      ],
      'field': [
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 0
    },
    {
      'id': 418141903,
      'title': 'Exploring Disentangled Feature Representation Beyond Face Identification',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1134450671,
          'name': 'Yu Liu'
        },
        {
          'id': 1165172349,
          'name': 'Fangyin Wei'
        },
        {
          'id': 1004481198,
          'name': 'Jing Shao'
        },
        {
          'id': 1099193953,
          'name': 'Lu Sheng'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2028685291,
          'name': 'Minimal supervision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 46
    },
    {
      'id': 419207340,
      'title': 'Towards Visual Feature Translation',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1030560697,
          'name': 'Jie Hu'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1358234232,
          'name': 'Hong Liu'
        },
        {
          'id': 1150403679,
          'name': 'Shengchuan Zhang'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Hu_Towards_Visual_Feature_Translation_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 420504404,
      'title': 'Inferring Shared Attention in Social Scene Videos',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1406448513,
          'name': 'Lifeng Fan'
        },
        {
          'id': 1281784065,
          'name': 'Yixin Chen'
        },
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000994941,
          'name': 'Gaze directions'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 421848551,
      'title': 'End-to-End Flow Correlation Tracking with Spatial-Temporal Attention',
      'affiliation': [
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1038968143,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1366664310,
          'name': 'Wei Wu'
        },
        {
          'id': 1268828894,
          'name': 'Wei Zou'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2032713836,
          'name': 'Flow (psychology)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 16,
      'reference_count': 43
    },
    {
      'id': 4236266,
      'title': 'Revisiting Video Saliency: A Large-Scale Benchmark and a New Model',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1437038452,
          'name': 'Fang Guo'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2036320587,
          'name': 'Object type'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2040776147,
          'name': 'Generality'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 62
    },
    {
      'id': 423631874,
      'title': 'Pix3D: Dataset and Methods for Single-Image 3D Shape Modeling',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1145259079,
          'name': 'Xingyuan Sun'
        },
        {
          'id': 1385296331,
          'name': 'Jiajun Wu'
        },
        {
          'id': 1285404779,
          'name': 'Xiuming Zhang'
        },
        {
          'id': 1066067475,
          'name': 'Zhoutong Zhang'
        },
        {
          'id': 1104354022,
          'name': 'Chengkai Zhang'
        },
        {
          'id': 1071777382,
          'name': 'Tianfan Xue'
        },
        {
          'id': 1123737115,
          'name': 'Joshua B. Tenenbaum'
        },
        {
          'id': 1234648702,
          'name': 'William T. Freeman'
        }
      ],
      'field': [
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 9,
      'reference_count': 64
    },
    {
      'id': 424516222,
      'title': 'A Causal And-Or Graph Model for Visibility Fluent Reasoning in Tracking Interacting Objects',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102954011,
          'abbr': 'SDSU',
          'name': 'San Diego State University'
        }
      ],
      'author': [
        {
          'id': 1079584817,
          'name': 'Yuanlu Xu'
        },
        {
          'id': 1370887323,
          'name': 'Lei Qin'
        },
        {
          'id': 1467929556,
          'name': 'Xiaobai Liu'
        },
        {
          'id': 1336211972,
          'name': 'Jianwen Xie'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2022582519,
          'name': 'Fluent'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037674894,
          'name': 'Search algorithm'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 424836181,
      'title': 'Dual Attention Network for Scene Segmentation.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100724939,
          'abbr': 'CHILDRENSHOSPITAL',
          'name': 'Boston Children\'s Hospital'
        }
      ],
      'author': [
        {
          'id': 1220702111,
          'name': 'Jun Fu'
        },
        {
          'id': 1055475485,
          'name': 'Jing Liu'
        },
        {
          'id': 1067076946,
          'name': 'Haijie Tian'
        },
        {
          'id': 1455298451,
          'name': 'Yong Li'
        },
        {
          'id': 1268109080,
          'name': 'Yongjun Bao'
        },
        {
          'id': 1321191871,
          'name': 'Zhiwei Fang'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Fu_Dual_Attention_Network_for_Scene_Segmentation_CVPR_2019_paper.pdf',
      'citation_count': 21,
      'reference_count': 0
    },
    {
      'id': 42489433,
      'title': 'Transductive Unbiased Embedding for Zero-Shot Learning',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1274764510,
          'name': 'Jie Song'
        },
        {
          'id': 1493466798,
          'name': 'Chengchao Shen'
        },
        {
          'id': 1493220987,
          'name': 'Yezhou Yang'
        },
        {
          'id': 1073563786,
          'name': 'Yang Liu'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2014952153,
          'name': 'Transduction (machine learning)'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2017543523,
          'name': 'Fixed point'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 6,
      'reference_count': 36
    },
    {
      'id': 426060979,
      'title': 'Human Pose Estimation with Parsing Induced Learner',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1311615180,
          'name': 'Xuecheng Nie'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1412047347,
          'name': 'Yiming Zuo'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024049029,
          'name': 'Adapter (computing)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 0
    },
    {
      'id': 426899599,
      'title': 'DoubleFusion: Real-Time Capture of Human Performances with Inner Body Shapes from a Single Depth Sensor',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1415675733,
          'name': 'Tao Yu'
        },
        {
          'id': 1217567060,
          'name': 'Zherong Zheng'
        },
        {
          'id': 1111748420,
          'name': 'Kaiwen Guo'
        },
        {
          'id': 1346709451,
          'name': 'Jianhui Zhao'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1347646526,
          'name': 'Hao Li'
        },
        {
          'id': 1197539891,
          'name': 'Gerard Pons-Moll'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        }
      ],
      'field': [
        {
          'id': 2020416489,
          'name': 'Surface layer'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049290898,
          'name': 'Double layer (surface science)'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005202005,
          'name': 'Match moving'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 39
    },
    {
      'id': 428380287,
      'title': 'Duplex Generative Adversarial Network for Unsupervised Domain Adaptation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1482479888,
          'name': 'Lanqing Hu'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 0
    },
    {
      'id': 432123439,
      'title': 'Divergence Triangle for Joint Training of Generator Model, Energy-Based Model, and Inferential Model',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1212831272,
          'name': 'Tian Han'
        },
        {
          'id': 1137737892,
          'name': 'Erik Nijkamp'
        },
        {
          'id': 1277749712,
          'name': 'Xiaolin Fang'
        },
        {
          'id': 1389639894,
          'name': 'Mitch Hill'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        },
        {
          'id': 1128144622,
          'name': 'Ying Nian Wu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029230238,
          'name': 'Divergence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Han_Divergence_Triangle_for_Joint_Training_of_Generator_Model_Energy-Based_Model_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 434642955,
      'title': 'D-LinkNet: LinkNet with Pretrained Encoder and Dilated Convolution for High Resolution Satellite Imagery Road Extraction',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1432056895,
          'name': 'Lichen Zhou'
        },
        {
          'id': 1347120024,
          'name': 'Chuang Zhang'
        },
        {
          'id': 1000313477,
          'name': 'Ming Wu'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2013716316,
          'name': 'Dilation (morphology)'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'https://ieeexplore.ieee.org/document/8575492/',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 435537710,
      'title': 'Progressive Attention Guided Recurrent Network for Salient Object Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1374208900,
          'name': 'Xiaoning Zhang'
        },
        {
          'id': 1176287567,
          'name': 'Tiantian Wang'
        },
        {
          'id': 1019616468,
          'name': 'Jinqing Qi'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1017033586,
          'name': 'Gang Wang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043050332,
          'name': 'Distraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2013752717,
          'name': 'Backbone network'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 0
    },
    {
      'id': 436543415,
      'title': 'Visual Question Reasoning on General Dependency Tree',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1224924548,
          'name': 'Qingxing Cao'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1147026935,
          'name': 'Bailing Li'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1104906125,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2042148111,
          'name': 'Modular design'
        },
        {
          'id': 2031277625,
          'name': 'Parse tree'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2044112887,
          'name': 'Reasoning system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 29
    },
    {
      'id': 436600853,
      'title': 'ApolloCar3D: A Large 3D Car Instance Understanding Benchmark for Autonomous Driving.',
      'affiliation': [
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2104227910,
          'abbr': 'AUP',
          'name': 'University of Paris'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        }
      ],
      'author': [
        {
          'id': 1168846762,
          'name': 'Xibin Song'
        },
        {
          'id': 1250058468,
          'name': 'Peng Wang'
        },
        {
          'id': 1395901344,
          'name': 'Dingfu Zhou'
        },
        {
          'id': 1004967123,
          'name': 'Rui Zhu'
        },
        {
          'id': 1268460554,
          'name': 'Chenye Guan'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1464060409,
          'name': 'Hao Su'
        },
        {
          'id': 1399076948,
          'name': 'Hongdong Li'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Song_ApolloCar3D_A_Large_3D_Car_Instance_Understanding_Benchmark_for_Autonomous_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 437500159,
      'title': 'Understanding the Disharmony Between Dropout and Batch Normalization by Variance Shift',
      'affiliation': [
        {
          'id': 2101720716,
          'abbr': 'UWO',
          'name': 'University of Western Ontario'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1227499918,
          'name': 'Xiang Li'
        },
        {
          'id': 1423921076,
          'name': 'Shuo Chen'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Understanding_the_Disharmony_Between_Dropout_and_Batch_Normalization_by_Variance_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 437888818,
      'title': 'Learning a Discriminative Prior for Blind Image Deblurring',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1427761351,
          'name': 'Lerenhan Li'
        },
        {
          'id': 1043059512,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1477719517,
          'name': 'Wei-Sheng Lai'
        },
        {
          'id': 1028164167,
          'name': 'Changxin Gao'
        },
        {
          'id': 1248648678,
          'name': 'Nong Sang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2026800590,
          'name': 'Gradient descent'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 6,
      'reference_count': 38
    },
    {
      'id': 43845154,
      'title': 'Hallucinated-IQA: No-Reference Image Quality Assessment via Adversarial Learning',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1167966062,
          'name': 'Kwan-Yee Lin'
        },
        {
          'id': 1424161119,
          'name': 'Guanxiang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023361932,
          'name': 'Scene statistics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025641305,
          'name': 'Hallucinated'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 47
    },
    {
      'id': 439096981,
      'title': 'Pose Transferrable Person Re-identification',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1356747537,
          'name': 'Jinxian Liu'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1289303631,
          'name': 'Yichao Yan'
        },
        {
          'id': 1345064832,
          'name': 'Peng Zhou'
        },
        {
          'id': 1311343399,
          'name': 'Shuo Cheng'
        },
        {
          'id': 1055738615,
          'name': 'Jianguo Hu'
        }
      ],
      'field': [
        {
          'id': 2031569861,
          'name': 'Performance improvement'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 0
    },
    {
      'id': 441012428,
      'title': 'Tagging Like Humans: Diverse and Distinct Image Annotation',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        }
      ],
      'author': [
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1051963070,
          'name': 'Weidong Chen'
        },
        {
          'id': 1311529883,
          'name': 'Peng Sun'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        }
      ],
      'field': [
        {
          'id': 2043470114,
          'name': 'Preprint'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034176497,
          'name': 'Determinantal point process'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 27
    },
    {
      'id': 441415615,
      'title': 'FOTS: Fast Oriented Text Spotting with a Unified Network',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1494826247,
          'name': 'Xuebo Liu'
        },
        {
          'id': 1038374316,
          'name': 'Ding Liang'
        },
        {
          'id': 1001529082,
          'name': 'Shi Yan'
        },
        {
          'id': 1049166955,
          'name': 'Dagui Chen'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1126332756,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2022892673,
          'name': 'Spotting'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 20,
      'reference_count': 52
    },
    {
      'id': 44263392,
      'title': 'Deep Image Compression via End-to-End Learning.',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1366948388,
          'name': 'Haojie Liu'
        },
        {
          'id': 1212631297,
          'name': 'Tong Chen'
        },
        {
          'id': 1287130343,
          'name': 'Qiu Shen'
        },
        {
          'id': 1311917372,
          'name': 'Tao Yue'
        },
        {
          'id': 1049262418,
          'name': 'Zhan Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/w50/html/Liu_Deep_Image_Compression_CVPR_2018_paper.html',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 443026935,
      'title': 'PiCANet: Learning Pixel-Wise Contextual Attention for Saliency Detection',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1393164687,
          'name': 'Nian Liu'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 13,
      'reference_count': 46
    },
    {
      'id': 443408505,
      'title': 'Towards Open-Set Identity Preserving Face Synthesis',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1373420012,
          'name': 'Jianmin Bao'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007597257,
          'name': 'Morphing'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 36
    },
    {
      'id': 443847151,
      'title': 'Scale-Transferrable Object Detection',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1345064832,
          'name': 'Peng Zhou'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1267650246,
          'name': 'Cong Geng'
        },
        {
          'id': 1055738615,
          'name': 'Jianguo Hu'
        },
        {
          'id': 1124030868,
          'name': 'Yi Xu'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 0
    },
    {
      'id': 446800208,
      'title': 'Depth-Based 3D Hand Pose Estimation: From Current Achievements to Future Goals',
      'affiliation': [
        {
          'id': 2100984406,
          'abbr': 'IMPERIAL',
          'name': 'Imperial College London'
        },
        {
          'id': 2104102737,
          'abbr': 'TOSHIBA',
          'name': 'Toshiba'
        },
        {
          'id': 2102731498,
          'abbr': 'SNU',
          'name': 'Seoul National University'
        },
        {
          'id': 2101042242,
          'abbr': 'KW',
          'name': 'Kwangwoon University'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2103207263,
          'abbr': 'UMONTREAL',
          'name': 'Université de Montréal'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102156196,
          'abbr': 'NAIST',
          'name': 'Nara Institute of Science and Technology'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2102976531,
          'abbr': 'UB',
          'name': 'University of Barcelona'
        },
        {
          'id': 2100355666,
          'abbr': 'TUM',
          'name': 'Technische Universität München'
        }
      ],
      'author': [
        {
          'id': 1370536086,
          'name': 'Shanxin Yuan'
        },
        {
          'id': 1231578207,
          'name': 'Guillermo Garcia-Hernando'
        },
        {
          'id': 1102409148,
          'name': 'Bjorn Stenger'
        },
        {
          'id': 1487647863,
          'name': 'Gyeongsik Moon'
        },
        {
          'id': 1371135059,
          'name': 'Ju Yong Chang'
        },
        {
          'id': 1383915828,
          'name': 'Kyoung Mu Lee'
        },
        {
          'id': 1411351957,
          'name': 'Pavlo Molchanov'
        },
        {
          'id': 1098313743,
          'name': 'Jan Kautz'
        },
        {
          'id': 1141432241,
          'name': 'Sina Honari'
        },
        {
          'id': 1285042124,
          'name': 'Liuhao Ge'
        },
        {
          'id': 1262920571,
          'name': 'Junsong Yuan'
        },
        {
          'id': 1372905836,
          'name': 'Xinghao Chen'
        },
        {
          'id': 1200371104,
          'name': 'Guijin Wang'
        },
        {
          'id': 1172689315,
          'name': 'Fan Yang'
        },
        {
          'id': 1224793267,
          'name': 'Kai Akiyama'
        },
        {
          'id': 1448998456,
          'name': 'Yang Wu'
        },
        {
          'id': 1067240447,
          'name': 'Qingfu Wan'
        },
        {
          'id': 1362012934,
          'name': 'Meysam Madadi'
        },
        {
          'id': 1011188708,
          'name': 'Sergio Escalera'
        },
        {
          'id': 1011650354,
          'name': 'Shile Li'
        },
        {
          'id': 1023134034,
          'name': 'Dongheui Lee'
        },
        {
          'id': 1216941700,
          'name': 'Iason Oikonomidis'
        },
        {
          'id': 1468429326,
          'name': 'Antonis A. Argyros'
        },
        {
          'id': 1216774416,
          'name': 'Tae-Kyun Kim'
        }
      ],
      'field': [
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 0
    },
    {
      'id': 446806023,
      'title': 'Fast Online Object Tracking and Segmentation: A Unifying Approach',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        }
      ],
      'author': [
        {
          'id': 1021140366,
          'name': 'Qiang Wang'
        },
        {
          'id': 1432530147,
          'name': 'Li Zhang'
        },
        {
          'id': 1200036889,
          'name': 'Luca Bertinetto'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        }
      ],
      'field': [
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_Fast_Online_Object_Tracking_and_Segmentation_A_Unifying_Approach_CVPR_2019_paper.pdf',
      'citation_count': 13,
      'reference_count': 0
    },
    {
      'id': 447392138,
      'title': 'Deep Spatial Feature Reconstruction for Partial Person Re-identification: Alignment-free Approach',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1395912429,
          'name': 'Lingxiao He'
        },
        {
          'id': 1111727891,
          'name': 'Jian Liang'
        },
        {
          'id': 1348872749,
          'name': 'Haiqing Li'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2007819124,
          'name': 'Microsoft Windows'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 25
    },
    {
      'id': 448680878,
      'title': 'Pose-Robust Face Recognition via Deep Residual Equivariant Mapping',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1085383820,
          'name': 'Kaidi Cao'
        },
        {
          'id': 1189903834,
          'name': 'Yu Rong'
        },
        {
          'id': 1187972211,
          'name': 'Cheng Li'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2043297818,
          'name': 'Equivariant map'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 28
    },
    {
      'id': 45138397,
      'title': 'Towards Effective Low-Bitwidth Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1407150119,
          'name': 'Bohan Zhuang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1121483151,
          'name': 'Mingkui Tan'
        },
        {
          'id': 1468852495,
          'name': 'Lingqiao Liu'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2019179373,
          'name': 'Maxima and minima'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 32
    },
    {
      'id': 451620041,
      'title': 'FaceID-GAN: Learning a Symmetry Three-Player GAN for Identity-Preserving Face Synthesis',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1466301479,
          'name': 'Yujun Shen'
        },
        {
          'id': 1275825081,
          'name': 'Ping Luo'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008264590,
          'name': 'Stationary point'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 0
    },
    {
      'id': 452958914,
      'title': 'Multi-image Semantic Matching by Mining Consistent Features',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        }
      ],
      'author': [
        {
          'id': 1392425096,
          'name': 'Qianqian Wang'
        },
        {
          'id': 1143104821,
          'name': 'Xiaowei Zhou'
        },
        {
          'id': 1375446170,
          'name': 'Kostas Daniilidis'
        }
      ],
      'field': [
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2022766243,
          'name': 'Multi-image'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000444311,
          'name': 'Semantic matching'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 51
    },
    {
      'id': 453306912,
      'title': 'Generative Adversarial Learning Towards Fast Weakly Supervised Detection',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1142344163,
          'name': 'Yunhang Shen'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1150403679,
          'name': 'Shengchuan Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1026941031,
          'name': 'Yan Wang'
        }
      ],
      'field': [
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2017318404,
          'name': 'Frame rate'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 45340013,
      'title': 'MegDet: A Large Mini-Batch Object Detector',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1125500898,
          'name': 'Tete Xiao'
        },
        {
          'id': 1213309621,
          'name': 'Zeming Li'
        },
        {
          'id': 1083874295,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1040991163,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1104024960,
          'name': 'Kai Jia'
        },
        {
          'id': 1003104441,
          'name': 'Gang Yu'
        },
        {
          'id': 1080993047,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2011945734,
          'name': 'mmap'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 10,
      'reference_count': 29
    },
    {
      'id': 454975088,
      'title': 'Bilateral Ordinal Relevance Multi-instance Regression for Facial Action Unit Intensity Estimation',
      'affiliation': [
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1073183237,
          'name': 'Yong Zhang'
        },
        {
          'id': 1348163422,
          'name': 'Rui Zhao'
        },
        {
          'id': 1442505283,
          'name': 'Weiming Dong'
        },
        {
          'id': 1341931364,
          'name': 'Bao-Gang Hu'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2014430643,
          'name': 'Estimator'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2035470008,
          'name': 'Domain knowledge'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 456785297,
      'title': 'Recurrent Saliency Transformation Network: Incorporating Multi-stage Visual Cues for Small Organ Segmentation',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2103058886,
          'abbr': '',
          'name': 'Johns Hopkins University School of Medicine'
        }
      ],
      'author': [
        {
          'id': 1274552737,
          'name': 'Qihang Yu'
        },
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1204480417,
          'name': 'Yan Wang'
        },
        {
          'id': 1032751027,
          'name': 'Yuyin Zhou'
        },
        {
          'id': 1008402547,
          'name': 'Elliot K. Fishman'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2013623356,
          'name': 'Computed tomography'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000871037,
          'name': 'Market segmentation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 0
    },
    {
      'id': 4581253,
      'title': 'Camera Style Adaptation for Person Re-identification',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1277244399,
          'name': 'Zhun Zhong'
        },
        {
          'id': 1010471532,
          'name': 'Liang Zheng'
        },
        {
          'id': 1051842533,
          'name': 'Zhedong Zheng'
        },
        {
          'id': 1438105862,
          'name': 'Shaozi Li'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 16,
      'reference_count': 44
    },
    {
      'id': 458866984,
      'title': 'A2-RL: Aesthetics Aware Reinforcement Learning for Image Cropping',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1199559674,
          'name': 'Debang Li'
        },
        {
          'id': 1114933458,
          'name': 'Huikai Wu'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023746547,
          'name': 'Aspect ratio (image)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007819124,
          'name': 'Microsoft Windows'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2032750659,
          'name': 'Cropping'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037388878,
          'name': 'Aesthetics'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 28
    },
    {
      'id': 459260269,
      'title': 'Towards Faster Training of Global Covariance Pooling Networks by Iterative Matrix Square Root Normalization',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1190238743,
          'name': 'Jiangtao Xie'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1394956158,
          'name': 'Zilin Gao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048015713,
          'name': 'Eigendecomposition of a matrix'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2002289677,
          'name': 'Square root of a matrix'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 25
    },
    {
      'id': 459424728,
      'title': 'SiamRPN++: Evolution of Siamese Visual Tracking With Very Deep Networks',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1173230308,
          'name': 'Bo Li'
        },
        {
          'id': 1366664310,
          'name': 'Wei Wu'
        },
        {
          'id': 1021140366,
          'name': 'Qiang Wang'
        },
        {
          'id': 1023950167,
          'name': 'Fangyi Zhang'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1465824659,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_SiamRPN_Evolution_of_Siamese_Visual_Tracking_With_Very_Deep_Networks_CVPR_2019_paper.pdf',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 459795988,
      'title': 'Decorrelated Batch Normalization',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        }
      ],
      'author': [
        {
          'id': 1361124393,
          'name': 'Lei Huangi'
        },
        {
          'id': 1388181632,
          'name': 'Dawei Yang'
        },
        {
          'id': 1031446264,
          'name': 'Bo Lang'
        },
        {
          'id': 1389718805,
          'name': 'Jia Deng'
        }
      ],
      'field': [
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002832735,
          'name': 'Standardization'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040590825,
          'name': 'Decorrelation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 47
    },
    {
      'id': 46136877,
      'title': 'Visual Question Reasoning on General Dependency Tree',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1224924548,
          'name': 'Qingxing Cao'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1147026935,
          'name': 'Bailing Li'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031277625,
          'name': 'Parse tree'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044112887,
          'name': 'Reasoning system'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 33
    },
    {
      'id': 461839216,
      'title': 'GroupCap: Group-Based Image Captioning with Structured Relevance and Diversity Constraints',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1456112077,
          'name': 'Fuhai Chen'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1082822706,
          'name': 'Xiaoshuai Sun'
        },
        {
          'id': 1478890822,
          'name': 'Yongjian Wu'
        },
        {
          'id': 1380127678,
          'name': 'Jinsong Su'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007989407,
          'name': 'Tree structure'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 0
    },
    {
      'id': 463621982,
      'title': 'Conditional Image-to-Image Translation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1107283513,
          'name': 'Jianxin Lin'
        },
        {
          'id': 1349638896,
          'name': 'Yingce Xia'
        },
        {
          'id': 1101447196,
          'name': 'Tao Qin'
        },
        {
          'id': 1239989664,
          'name': 'Zhibo Chen'
        },
        {
          'id': 1341781768,
          'name': 'Tie-Yan Liu'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003950936,
          'name': 'Image translation'
        },
        {
          'id': 2010643714,
          'name': 'Unpaired Data'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 22
    },
    {
      'id': 464380888,
      'title': 'Crowd Counting with Deep Negative Correlation Learning',
      'affiliation': [
        {
          'id': 2104239519,
          'abbr': 'UB',
          'name': 'University of Bern'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2100954550,
          'abbr': '',
          'name': 'Zhengzhou University'
        }
      ],
      'author': [
        {
          'id': 1113024657,
          'name': 'Zenglin Shi'
        },
        {
          'id': 1142056405,
          'name': 'Le Zhang'
        },
        {
          'id': 1189823451,
          'name': 'Yun Liu'
        },
        {
          'id': 1118865369,
          'name': 'Xiaofeng Cao'
        },
        {
          'id': 1282533450,
          'name': 'Yangdong Ye'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1365239748,
          'name': 'Guoyan Zheng'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2040590825,
          'name': 'Decorrelation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 41,
      'reference_count': 0
    },
    {
      'id': 46597776,
      'title': 'Look at Boundary: A Boundary-Aware Face Alignment Algorithm',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1014155152,
          'name': 'Wenyan Wu'
        },
        {
          'id': 1003915721,
          'name': 'Chen Qian'
        },
        {
          'id': 1024062129,
          'name': 'Shuo Yang'
        },
        {
          'id': 1027398808,
          'name': 'Quan Wang'
        },
        {
          'id': 1103291674,
          'name': 'Yici Cai'
        },
        {
          'id': 1084358172,
          'name': 'Qiang Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2018861954,
          'name': 'Failure rate'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2001153861,
          'name': 'Mean squared error'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 9,
      'reference_count': 68
    },
    {
      'id': 467442213,
      'title': 'Fast and Accurate Single Image Super-Resolution via Information Distillation Network',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1427107741,
          'name': 'Zheng Hui'
        },
        {
          'id': 1263516969,
          'name': 'Xiumei Wang'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010918187,
          'name': 'Distillation'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 22
    },
    {
      'id': 467698376,
      'title': 'Learning Semantic Concepts and Order for Image and Sentence Matching',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1103669667,
          'name': 'Qi Wu'
        },
        {
          'id': 1109666898,
          'name': 'Chunfeng Song'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 9,
      'reference_count': 37
    },
    {
      'id': 470267072,
      'title': 'DecideNet: Counting Varying Density Crowds Through Attention Guided Detection and Density Estimation',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101631867,
          'abbr': 'CQUPT',
          'name': 'Chongqing University of Posts and Telecommunications'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1069241267,
          'name': 'Jiang Liu'
        },
        {
          'id': 1310082457,
          'name': 'Chenqiang Gao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2001143093,
          'name': 'Crowds'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041409623,
          'name': 'Density estimation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 42
    },
    {
      'id': 470699201,
      'title': 'Neural Style Transfer via Meta Networks',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1187588030,
          'name': 'Falong Shen'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        }
      ],
      'field': [
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2005832358,
          'name': 'Stochastic gradient descent'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 470914426,
      'title': 'PiCANet: Learning Pixel-Wise Contextual Attention for Saliency Detection',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1393164687,
          'name': 'Nian Liu'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 11
    },
    {
      'id': 471949319,
      'title': 'Geometry-Aware Scene Text Detection with Instance Transformation Network',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1249914108,
          'name': 'Fangfang Wang'
        },
        {
          'id': 1338175284,
          'name': 'Liming Zhao'
        },
        {
          'id': 1158327425,
          'name': 'Xi Li'
        },
        {
          'id': 1379576641,
          'name': 'Xinchao Wang'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 0
    },
    {
      'id': 47327182,
      'title': 'Deep Spatial Feature Reconstruction for Partial Person Re-identification: Alignment-free Approach',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1142219881,
          'name': 'Lingxiao He'
        },
        {
          'id': 1002339613,
          'name': 'Jian Liang'
        },
        {
          'id': 1348872749,
          'name': 'Haiqing Li'
        },
        {
          'id': 1133646882,
          'name': 'Zhenan Sun'
        }
      ],
      'field': [
        {
          'id': 2007819124,
          'name': 'Microsoft Windows'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 29
    },
    {
      'id': 473329740,
      'title': 'Binary Ensemble Neural Network: More Bits per Network or More Networks per Bit?',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1317948802,
          'name': 'Shilin Zhu'
        },
        {
          'id': 1039997979,
          'name': 'Xin Dong'
        },
        {
          'id': 1116347973,
          'name': 'Hao Su'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhu_Binary_Ensemble_Neural_Network_More_Bits_per_Network_or_More_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 474329709,
      'title': 'Global Second-order Pooling Convolutional Networks.',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1394956158,
          'name': 'Zilin Gao'
        },
        {
          'id': 1190238743,
          'name': 'Jiangtao Xie'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Gao_Global_Second-Order_Pooling_Convolutional_Networks_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 474571023,
      'title': 'Single View Stereo Matching',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1241318173,
          'name': 'Yue Luo'
        },
        {
          'id': 1028004617,
          'name': 'Jimmy S. J. Ren'
        },
        {
          'id': 1068274881,
          'name': 'Mude Lin'
        },
        {
          'id': 1217660585,
          'name': 'Jiahao Pang'
        },
        {
          'id': 1401096853,
          'name': 'Wenxiu Sun'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 34
    },
    {
      'id': 474697337,
      'title': 'Attention Clusters: Purely Attention Based Local Feature Integration for Video Classification',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101105301,
          'abbr': 'RU',
          'name': 'Rutgers University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1397158762,
          'name': 'Xiang Long'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1408823315,
          'name': 'Gerard de Melo'
        },
        {
          'id': 1385296331,
          'name': 'Jiajun Wu'
        },
        {
          'id': 1056587154,
          'name': 'Xiao Liu'
        },
        {
          'id': 1256014321,
          'name': 'Shilei Wen'
        }
      ],
      'field': [
        {
          'id': 2023756774,
          'name': 'Cluster (physics)'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 18,
      'reference_count': 33
    },
    {
      'id': 475565207,
      'title': 'Learning Face Age Progression: A Pyramid Architecture of GANs',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103683216,
          'abbr': 'MSU',
          'name': 'Michigan State University'
        }
      ],
      'author': [
        {
          'id': 1323708457,
          'name': 'Hongyu Yang'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1126752003,
          'name': 'Anil K. Jain'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2027478313,
          'name': 'Age progression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040512229,
          'name': 'Quantitative Evaluations'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 6,
      'reference_count': 30
    },
    {
      'id': 475588582,
      'title': 'Pose-Robust Face Recognition via Deep Residual Equivariant Mapping',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1085383820,
          'name': 'Kaidi Cao'
        },
        {
          'id': 1189903834,
          'name': 'Yu Rong'
        },
        {
          'id': 1000759338,
          'name': 'Cheng Li'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1205290384,
          'name': 'Chen Change Loy'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2043297818,
          'name': 'Equivariant map'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 34
    },
    {
      'id': 475955695,
      'title': 'MiCT: Mixed 3D/2D Convolutional Tube for Human Action Recognition',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1425172909,
          'name': 'Yizhou Zhou'
        },
        {
          'id': 1423798589,
          'name': 'Xiaoyan Sun'
        },
        {
          'id': 1011720102,
          'name': 'Zheng-Jun Zha'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 0
    },
    {
      'id': 479511131,
      'title': 'Multistage Adversarial Losses for Pose-Based Human Image Synthesis',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1102824081,
          'name': 'Chenyang Si'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 0
    },
    {
      'id': 479625414,
      'title': 'Image Generation From Layout',
      'affiliation': [
        {
          'id': 2101488188,
          'abbr': 'SWJTU',
          'name': 'Southwest Jiaotong University'
        },
        {
          'id': 2100726728,
          'abbr': 'UBC',
          'name': 'University of British Columbia'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1382490733,
          'name': 'Bo Zhao'
        },
        {
          'id': 1291239351,
          'name': 'Lili Meng'
        },
        {
          'id': 1177591169,
          'name': 'Weidong Yin'
        },
        {
          'id': 1474763857,
          'name': 'Leonid Sigal'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Zhao_Image_Generation_From_Layout_CVPR_2019_paper.html',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 481065657,
      'title': 'Dynamic Feature Learning for Partial Face Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1395912429,
          'name': 'Lingxiao He'
        },
        {
          'id': 1348872749,
          'name': 'Haiqing Li'
        },
        {
          'id': 1002279751,
          'name': 'Qi Zhang'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        }
      ],
      'field': [
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2007819124,
          'name': 'Microsoft Windows'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043864582,
          'name': 'Design for manufacturability'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 483095145,
      'title': 'Boosting Adversarial Attacks with Momentum',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1391196842,
          'name': 'Fangzhou Liao'
        },
        {
          'id': 1258883894,
          'name': 'Tianyu Pang'
        },
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        }
      ],
      'field': [
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2043533591,
          'name': 'Iterative and incremental development'
        },
        {
          'id': 2003581630,
          'name': 'Momentum'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 138,
      'reference_count': 20
    },
    {
      'id': 484493071,
      'title': 'Multi-level Wavelet-CNN for Image Restoration',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1155183098,
          'name': 'Pengju Liu'
        },
        {
          'id': 1129910359,
          'name': 'Hongzhi Zhang'
        },
        {
          'id': 1221197508,
          'name': 'Kai Zhang'
        },
        {
          'id': 1058130101,
          'name': 'Liang Lin'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2042225087,
          'name': 'Subnetwork'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w13/Liu_Multi-Level_Wavelet-CNN_for_CVPR_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 50
    },
    {
      'id': 48537790,
      'title': 'Crafting a Toolchain for Image Restoration by Deep Reinforcement Learning',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1274028524,
          'name': 'Ke Yu'
        },
        {
          'id': 1130918340,
          'name': 'Chao Dong'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2004008403,
          'name': 'Toolbox'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001606385,
          'name': 'Toolchain'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 40
    },
    {
      'id': 485662498,
      'title': 'A Common Framework for Interactive Texture Transfer',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1243401505,
          'name': 'Yifang Men'
        },
        {
          'id': 1144931875,
          'name': 'Zhouhui Lian'
        },
        {
          'id': 1380821707,
          'name': 'Yingmin Tang'
        },
        {
          'id': 1135263000,
          'name': 'Jianguo Xiao'
        }
      ],
      'field': [
        {
          'id': 2022139458,
          'name': 'Matrix multiplication'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047221932,
          'name': 'Interactivity'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 486671106,
      'title': 'Zoom-In-To-Check: Boosting Video Interpolation via Instance-Level Discrimination',
      'affiliation': [
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1105129086,
          'name': 'Liangzhe Yuan'
        },
        {
          'id': 1368104336,
          'name': 'Yibo Chen'
        },
        {
          'id': 1497796971,
          'name': 'Hantian Liu'
        },
        {
          'id': 1242912686,
          'name': 'Tao Kong'
        },
        {
          'id': 1456916710,
          'name': 'Jianbo Shi'
        }
      ],
      'field': [
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001048872,
          'name': 'Zoom'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Yuan_Zoom-In-To-Check_Boosting_Video_Interpolation_via_Instance-Level_Discrimination_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 488611737,
      'title': 'Deep Image Compression via End-to-End Learning.',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1366948388,
          'name': 'Haojie Liu'
        },
        {
          'id': 1212631297,
          'name': 'Tong Chen'
        },
        {
          'id': 1287130343,
          'name': 'Qiu Shen'
        },
        {
          'id': 1311917372,
          'name': 'Tao Yue'
        },
        {
          'id': 1049262418,
          'name': 'Zhan Ma'
        }
      ],
      'field': [
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2040502106,
          'name': 'Lossy compression'
        },
        {
          'id': 2018712183,
          'name': 'WebP'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2036412563,
          'name': 'Low bit'
        },
        {
          'id': 2005688087,
          'name': 'JPEG 2000'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 6
    },
    {
      'id': 489551559,
      'title': 'Pose-Guided Photorealistic Face Rotation',
      'affiliation': [
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1411770867,
          'name': 'Yibo Hu'
        },
        {
          'id': 1011691068,
          'name': 'Xiang Wu'
        },
        {
          'id': 1436620392,
          'name': 'Bing Yu'
        },
        {
          'id': 1020627459,
          'name': 'Ran He'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        }
      ],
      'field': [
        {
          'id': 2000809816,
          'name': 'Total variation denoising'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 0
    },
    {
      'id': 489771947,
      'title': 'Tracking by Animation: Unsupervised Learning of Multi-Object Attentive Trackers',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1010770063,
          'name': 'Zhen He'
        },
        {
          'id': 1001364965,
          'name': 'Jian Li'
        },
        {
          'id': 1462434527,
          'name': 'Daxue Liu'
        },
        {
          'id': 1350698181,
          'name': 'Hangen He'
        },
        {
          'id': 1010077175,
          'name': 'David Barber'
        }
      ],
      'field': [
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026679767,
          'name': 'Animation'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/He_Tracking_by_Animation_Unsupervised_Learning_of_Multi-Object_Attentive_Trackers_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 493201034,
      'title': 'Learning Convolutional Networks for Content-Weighted Image Compression',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1028298673,
          'name': 'Mu Li'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1263603098,
          'name': 'Debin Zhao'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        }
      ],
      'field': [
        {
          'id': 2006174459,
          'name': 'Data compression ratio'
        },
        {
          'id': 2005688087,
          'name': 'JPEG 2000'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2040502106,
          'name': 'Lossy compression'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009009423,
          'name': 'Lossless compression'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 21
    },
    {
      'id': 493473341,
      'title': 'Towards High Performance Video Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1368545847,
          'name': 'Xizhou Zhu'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2017069615,
          'name': 'Flight envelope'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 4,
      'reference_count': 28
    },
    {
      'id': 493529,
      'title': 'SemStyle: Learning to Generate Stylised Image Captions Using Unaligned Text',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1289644645,
          'name': 'Alexander Patrick Mathews'
        },
        {
          'id': 1338420997,
          'name': 'Lexing Xie'
        },
        {
          'id': 1171646407,
          'name': 'Xuming He'
        }
      ],
      'field': [
        {
          'id': 2026933709,
          'name': 'CLARITY'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2047608516,
          'name': 'Frame semantics'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033461377,
          'name': 'Syntax'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2049525942,
          'name': 'Language model'
        },
        {
          'id': 2014537035,
          'name': 'Decodes'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 10,
      'reference_count': 52
    },
    {
      'id': 495550981,
      'title': 'ShuffleNet: An Extremely Efficient Convolutional Neural Network for Mobile Devices',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1040991163,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1089470532,
          'name': 'Xinyu Zhou'
        },
        {
          'id': 1366796233,
          'name': 'MENGXiao-lin'
        },
        {
          'id': 1080993047,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2016965578,
          'name': 'Computer engineering'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046490491,
          'name': 'FLOPS'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 80,
      'reference_count': 37
    },
    {
      'id': 498328816,
      'title': 'Clinical Skin Lesion Diagnosis Using Representations Inspired by Dermatologist Criteria',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1003062086,
          'name': 'Jufeng Yang'
        },
        {
          'id': 1464422618,
          'name': 'Xiaoxiao Sun'
        },
        {
          'id': 1448634214,
          'name': 'Jie Liang'
        },
        {
          'id': 1293598313,
          'name': 'Paul L. Rosin'
        }
      ],
      'field': [
        {
          'id': 2002620626,
          'name': 'Computer-aided diagnosis'
        },
        {
          'id': 2000366711,
          'name': 'Lesion'
        },
        {
          'id': 2027452984,
          'name': 'Dermatology'
        },
        {
          'id': 2033204163,
          'name': 'Uninterpretable'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014873883,
          'name': 'CAD'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 499118148,
      'title': 'Mask-Guided Contrastive Attention Model for Person Re-identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1005235175,
          'name': 'Chunfeng Song'
        },
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000790668,
          'name': 'Viewpoints'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 0
    },
    {
      'id': 499405001,
      'title': 'Multi-cue Correlation Filters for Robust Visual Tracking',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        }
      ],
      'author': [
        {
          'id': 1046619653,
          'name': 'Ning Wang'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1188079795,
          'name': 'Richang Hong'
        },
        {
          'id': 1289931589,
          'name': 'Meng Wang'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041015682,
          'name': 'Evaluation strategy'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 0
    },
    {
      'id': 499880242,
      'title': 'Deformable ConvNets V2: More Deformable, Better Results',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1368545847,
          'name': 'Xizhou Zhu'
        },
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhu_Deformable_ConvNets_V2_More_Deformable_Better_Results_CVPR_2019_paper.pdf',
      'citation_count': 8,
      'reference_count': 0
    },
    {
      'id': 50534687,
      'title': 'Are You Talking to Me? Reasoned Visual Dialog Generation Through Adversarial Learning',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1103669667,
          'name': 'Qi Wu'
        },
        {
          'id': 1330916017,
          'name': 'Peng Wang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2015236575,
          'name': 'Dialog box'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2040497686,
          'name': 'Conversation'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 43
    },
    {
      'id': 52117116,
      'title': 'End-to-End Learning of Motion Representation for Video Understanding',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1245715755,
          'name': 'Lijie Fan'
        },
        {
          'id': 1467293440,
          'name': 'Wenbing Huang'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1189023951,
          'name': 'Stefano Ermon'
        },
        {
          'id': 1347218119,
          'name': 'Boqing Gong'
        },
        {
          'id': 1132819407,
          'name': 'Junzhou Huang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        },
        {
          'id': 2002279732,
          'name': 'Optical computing'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 38
    },
    {
      'id': 5304574,
      'title': 'Correlation Tracking via Joint Discrimination and Reliability Learning',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2003093565,
          'name': 'Fourier transform'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 2,
      'reference_count': 31
    },
    {
      'id': 53093730,
      'title': 'Single Image Dehazing via Conditional Generative Adversarial Network',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1121774169,
          'name': 'Runde Li'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 0
    },
    {
      'id': 53154866,
      'title': 'Quantization of Fully Convolutional Networks for Accurate Biomedical Image Segmentation',
      'affiliation': [
        {
          'id': 2102771059,
          'abbr': 'ND',
          'name': 'University of Notre Dame'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1234068900,
          'name': 'Xiaowei Xu'
        },
        {
          'id': 1345247356,
          'name': 'Qing Lu'
        },
        {
          'id': 1059377696,
          'name': 'Lin Yang'
        },
        {
          'id': 1334687490,
          'name': 'Sharon X. Hu'
        },
        {
          'id': 1308347698,
          'name': 'Danny Z. Chen'
        },
        {
          'id': 1239516510,
          'name': 'Yu Hu'
        },
        {
          'id': 1433008519,
          'name': 'Yiyu Shi'
        }
      ],
      'field': [
        {
          'id': 2003010881,
          'name': 'Memory management'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2017041859,
          'name': 'Reduction (complexity)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 20
    },
    {
      'id': 53631739,
      'title': 'Visual Question Answering as Reading Comprehension',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1172450887,
          'name': 'Hui Li'
        },
        {
          'id': 1330916017,
          'name': 'Peng Wang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016896741,
          'name': 'Cognitive science'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2012298003,
          'name': 'Reading comprehension'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Li_Visual_Question_Answering_as_Reading_Comprehension_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 54843077,
      'title': 'One-Shot Action Localization by Learning Sequence Matching Network',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1309323425,
          'name': 'Hongtao Yang'
        },
        {
          'id': 1291806924,
          'name': 'Xuming He'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        }
      ],
      'field': [
        {
          'id': 2007819124,
          'name': 'Microsoft Windows'
        },
        {
          'id': 2007268696,
          'name': 'Target–action'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2046434352,
          'name': 'Uncountable set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027547384,
          'name': 'Existential quantification'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 55183774,
      'title': 'Zigzag Learning for Weakly Supervised Object Detection',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1495807585,
          'name': 'Xiaopeng Zhang'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1086324331,
          'name': 'Hongkai Xiong'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2003673557,
          'name': 'Masking (art)'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2041560669,
          'name': 'Zigzag'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 25
    },
    {
      'id': 56133490,
      'title': 'Self-Supervised Adversarial Hashing Networks for Cross-Modal Retrieval',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1132655615,
          'name': 'Chao Li'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1163912921,
          'name': 'Ning Li'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2045207422,
          'name': 'Semantic network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 43
    },
    {
      'id': 56657776,
      'title': 'Sparse, Smart Contours to Represent and Edit Images',
      'affiliation': [
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1376174527,
          'name': 'Tali Dekel'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1224550724,
          'name': 'Dilip Krishnan'
        },
        {
          'id': 1492665421,
          'name': 'Ce Liu'
        },
        {
          'id': 1234648702,
          'name': 'William T. Freeman'
        }
      ],
      'field': [
        {
          'id': 2011729194,
          'name': 'Semantic memory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 0
    },
    {
      'id': 59790773,
      'title': 'DecideNet: Counting Varying Density Crowds Through Attention Guided Detection and Density Estimation',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101631867,
          'abbr': 'CQUPT',
          'name': 'Chongqing University of Posts and Telecommunications'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1025718453,
          'name': 'Jiang Liu'
        },
        {
          'id': 1310082457,
          'name': 'Chenqiang Gao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2041409623,
          'name': 'Density estimation'
        },
        {
          'id': 2001143093,
          'name': 'Crowds'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 14,
      'reference_count': 40
    },
    {
      'id': 62268988,
      'title': 'Fast and Accurate Online Video Object Segmentation via Tracking Parts',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1127761433,
          'name': 'Jingchun Cheng'
        },
        {
          'id': 1138046264,
          'name': 'Yi-Hsuan Tsai'
        },
        {
          'id': 1126504332,
          'name': 'Wei-Chih Hung'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 36
    },
    {
      'id': 62294421,
      'title': 'CRRN: Multi-scale Guided Concurrent Reflection Removal Network',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1458086827,
          'name': 'Renjie Wan'
        },
        {
          'id': 1100550194,
          'name': 'Boxin Shi'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1159618503,
          'name': 'Ah-Hwee Tan'
        },
        {
          'id': 1314678280,
          'name': 'Alex C. Kot'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 17
    },
    {
      'id': 62942299,
      'title': 'In Defense of the Classification Loss for Person Re-Identification',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1354544146,
          'name': 'Yao Zhai'
        },
        {
          'id': 1037079149,
          'name': 'Xun-guo'
        },
        {
          'id': 1060816602,
          'name': 'Yan Lu'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPRW_2019/papers/TRMTMCT/Zhai_In_Defense_of_the_Classification_Loss_for_Person_Re-Identification_CVPRW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 63145728,
      'title': 'Toward Convolutional Blind Denoising of Real Photographs.',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1001794751,
          'name': 'Shi Guo'
        },
        {
          'id': 1292588935,
          'name': 'Zifei Yan'
        },
        {
          'id': 1221197508,
          'name': 'Kai Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Guo_Toward_Convolutional_Blind_Denoising_of_Real_Photographs_CVPR_2019_paper.pdf',
      'citation_count': 6,
      'reference_count': 0
    },
    {
      'id': 64403353,
      'title': 'Deep Marching Cubes: Learning Explicit Surface Representations',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103875966,
          'abbr': 'UGENT',
          'name': 'Ghent University'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        }
      ],
      'author': [
        {
          'id': 1445655198,
          'name': 'Yiyi Liao'
        },
        {
          'id': 1267132672,
          'name': 'Simon Donné'
        },
        {
          'id': 1212257794,
          'name': 'Andreas Geiger'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2003187680,
          'name': 'Marching cubes'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 0
    },
    {
      'id': 66761684,
      'title': 'Repulsion Loss: Detecting Pedestrians in a Crowd',
      'affiliation': [
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1312161593,
          'name': 'Xinlong Wang'
        },
        {
          'id': 1125500898,
          'name': 'Tete Xiao'
        },
        {
          'id': 1083874295,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1143985982,
          'name': 'Shuai Shao'
        },
        {
          'id': 1080993047,
          'name': 'Jian Sun'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 13,
      'reference_count': 30
    },
    {
      'id': 67185323,
      'title': 'Rethinking Knowledge Graph Propagation for Zero-Shot Learning',
      'affiliation': [
        {
          'id': 2103837596,
          'abbr': 'UIT',
          'name': 'University of Tromsø'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1291678037,
          'name': 'Michael Kampffmeyer'
        },
        {
          'id': 1109722175,
          'name': 'Yinbo Chen'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1449188887,
          'name': 'Hao Wang'
        },
        {
          'id': 1241250905,
          'name': 'Yujia Zhang'
        },
        {
          'id': 1192263557,
          'name': 'Eric P. Xing'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Kampffmeyer_Rethinking_Knowledge_Graph_Propagation_for_Zero-Shot_Learning_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 67956754,
      'title': 'R-FCN-3000 at 30fps: Decoupling Detection and Classification',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2103551367,
          'abbr': 'IITR',
          'name': 'Indian Institute of Technology Roorkee'
        }
      ],
      'author': [
        {
          'id': 1112350808,
          'name': 'Bharat Singh'
        },
        {
          'id': 1218401323,
          'name': 'Hengduo Li'
        },
        {
          'id': 1397439878,
          'name': 'Abhishek Sharma'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2039104951,
          'name': 'Decoupling (cosmology)'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2042148111,
          'name': 'Modular design'
        },
        {
          'id': 2047795701,
          'name': 'Linear classifier'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 1,
      'reference_count': 38
    },
    {
      'id': 69786175,
      'title': 'Revisiting Perspective Information for Efficient Crowd Counting',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        }
      ],
      'author': [
        {
          'id': 1300183854,
          'name': 'Miaojing Shi'
        },
        {
          'id': 1132538796,
          'name': 'Zhaohui Yang'
        },
        {
          'id': 1192418823,
          'name': 'Chao Xu'
        },
        {
          'id': 1089888335,
          'name': 'Qijun Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046442909,
          'name': 'ENCODE'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040012394,
          'name': 'Weighting'
        },
        {
          'id': 2001363999,
          'name': 'Perspective distortion'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Shi_Revisiting_Perspective_Information_for_Efficient_Crowd_Counting_CVPR_2019_paper.pdf',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 71062589,
      'title': 'Image-Image Domain Adaptation with Preserved Self-Similarity and Domain-Dissimilarity for Person Re-identification',
      'affiliation': [
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1163501203,
          'name': 'Weijian Deng'
        },
        {
          'id': 1010471532,
          'name': 'Liang Zheng'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1476639755,
          'name': 'Guoliang Kang'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2029117650,
          'name': 'Self-similarity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 50
    },
    {
      'id': 71474818,
      'title': 'Stacked Conditional Generative Adversarial Networks for Jointly Learning Shadow Detection and Shadow Removal',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1323321006,
          'name': 'Jifeng Wang'
        },
        {
          'id': 1204869756,
          'name': 'Xiang Li'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013878316,
          'name': 'Shadow mask'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 50
    },
    {
      'id': 71656228,
      'title': 'Rethinking Feature Distribution for Loss Functions in Image Classification',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1032246936,
          'name': 'Weitao Wan'
        },
        {
          'id': 1275391601,
          'name': 'Yuanyi Zhong'
        },
        {
          'id': 1347211469,
          'name': 'Tianpeng Li'
        },
        {
          'id': 1470618407,
          'name': 'Jiansheng Chen'
        }
      ],
      'field': [
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2048893815,
          'name': 'Mixture distribution'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 32
    },
    {
      'id': 72258650,
      'title': 'Regularizing RNNs for Caption Generation by Reconstructing the Past with the Present',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1490737560,
          'name': 'Xinpeng Chen'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1243936135,
          'name': 'Wenhao Jiang'
        },
        {
          'id': 1468884656,
          'name': 'Jian Yao'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 37
    },
    {
      'id': 76411483,
      'title': 'Interleaved Structured Sparse Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1254646380,
          'name': 'Guotian Xie'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1204011511,
          'name': 'Ting Zhang'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1188079795,
          'name': 'Richang Hong'
        },
        {
          'id': 1240277240,
          'name': 'Guo-Jun Qi'
        }
      ],
      'field': [
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2020635529,
          'name': 'Design methods'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 41
    },
    {
      'id': 78754949,
      'title': 'Im2Struct: Recovering 3D Shape Structure from a Single RGB Image',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        }
      ],
      'author': [
        {
          'id': 1151949416,
          'name': 'Chengjie Niu'
        },
        {
          'id': 1462352723,
          'name': 'Jun Li'
        },
        {
          'id': 1048406954,
          'name': 'Kai Xu'
        }
      ],
      'field': [
        {
          'id': 2024086463,
          'name': 'Rendering (computer graphics)'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2003501260,
          'name': 'Cuboid'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 0,
      'reference_count': 26
    },
    {
      'id': 81229794,
      'title': 'Weakly-Supervised Deep Convolutional Neural Network Learning for Facial Action Unit Intensity Estimation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1073183237,
          'name': 'Yong Zhang'
        },
        {
          'id': 1442505283,
          'name': 'Weiming Dong'
        },
        {
          'id': 1341931364,
          'name': 'Bao-Gang Hu'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008108336,
          'name': 'Affective computing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017876651,
          'name': 'Subject-matter expert'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 81325086,
      'title': 'DeepFlux for Skeletons in the Wild',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        },
        {
          'id': 2104829265,
          'abbr': '',
          'name': 'McGill University'
        }
      ],
      'author': [
        {
          'id': 1225560936,
          'name': 'Yukang Wang'
        },
        {
          'id': 1396365093,
          'name': 'Yongchao Xu'
        },
        {
          'id': 1003092904,
          'name': 'Stavros Tsogkas'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1244208963,
          'name': 'Sven Dickinson'
        },
        {
          'id': 1105082428,
          'name': 'Kaleem Siddiqi'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Wang_DeepFlux_for_Skeletons_in_the_Wild_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 81852846,
      'title': 'Tangent-Normal Adversarial Regularization for Semi-supervised Learning',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1069755659,
          'name': 'Bing Yu'
        },
        {
          'id': 1361427667,
          'name': 'Jingfeng Wu'
        },
        {
          'id': 1445619584,
          'name': 'Jinwen Ma'
        },
        {
          'id': 1354996779,
          'name': 'Zhanxing Zhu'
        }
      ],
      'field': [
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2036032756,
          'name': 'Tangent'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/html/Yu_Tangent-Normal_Adversarial_Regularization_for_Semi-Supervised_Learning_CVPR_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 84648345,
      'title': 'Resolution-Enhanced Lensless Color Shadow Imaging Microscopy Based on Large Field-of-View Submicron-Pixel Imaging Sensors',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1154334676,
          'name': 'Cheng Yang'
        },
        {
          'id': 1087483770,
          'name': 'Xiaofeng Bu'
        },
        {
          'id': 1109926878,
          'name': 'Haowen Ma'
        },
        {
          'id': 1436187417,
          'name': 'Limin Zhang'
        },
        {
          'id': 1012477500,
          'name': 'Xu Cao'
        },
        {
          'id': 1009301761,
          'name': 'Tao Yue'
        },
        {
          'id': 1111039153,
          'name': 'Xia Hua'
        },
        {
          'id': 1001495762,
          'name': 'Feng Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2023843715,
          'name': 'Microscopy'
        },
        {
          'id': 2016207230,
          'name': 'Optics'
        },
        {
          'id': 2039700608,
          'name': 'Microscope'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023247074,
          'name': 'Field of view'
        },
        {
          'id': 2018363555,
          'name': 'Monochromatic color'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2027424833,
          'name': 'Grating'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w44/Yang_Resolution-Enhanced_Lensless_Color_CVPR_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 90519969,
      'title': 'Deep Cocktail Network: Multi-source Unsupervised Domain Adaptation with Category Shift',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        }
      ],
      'author': [
        {
          'id': 1173893498,
          'name': 'Ruijia Xu'
        },
        {
          'id': 1275022701,
          'name': 'Ziliang Chen'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1126332756,
          'name': 'Junjie Yan'
        },
        {
          'id': 1104906125,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2036405579,
          'name': 'Perplexity'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025617559,
          'name': 'Multi-source'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 7,
      'reference_count': 46
    },
    {
      'id': 93587287,
      'title': 'Phase-Only Image Based Kernel Estimation for Single Image Blind Deblurring',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1066655092,
          'name': 'Liyuan Pan'
        },
        {
          'id': 1140300658,
          'name': 'Richard I. Hartley'
        },
        {
          'id': 1109581722,
          'name': 'Miaomiao Liu'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Pan_Phase-Only_Image_Based_Kernel_Estimation_for_Single_Image_Blind_Deblurring_CVPR_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 9434365,
      'title': 'Repulsion Loss: Detecting Pedestrians in a Crowd',
      'affiliation': [
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1312161593,
          'name': 'Xinlong Wang'
        },
        {
          'id': 1125500898,
          'name': 'Tete Xiao'
        },
        {
          'id': 1314532880,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1143985982,
          'name': 'Shuai Shao'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2011838053,
          'name': 'Attraction'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 27
    },
    {
      'id': 94542256,
      'title': 'Fast Human Pose Estimation',
      'affiliation': [
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1125642088,
          'name': 'Feng Zhang'
        },
        {
          'id': 1121622391,
          'name': 'Xiatian Zhu'
        },
        {
          'id': 1339124869,
          'name': 'Mao Ye'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_CVPR_2019/papers/Zhang_Fast_Human_Pose_Estimation_CVPR_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 95660253,
      'title': 'Cascaded Pyramid Network for Multi-person Pose Estimation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1081688086,
          'name': 'Yilun Chen'
        },
        {
          'id': 1002782225,
          'name': 'Zhicheng Wang'
        },
        {
          'id': 1144979659,
          'name': 'Yuxiang Peng'
        },
        {
          'id': 1434291055,
          'name': 'Zhiqiang Zhang'
        },
        {
          'id': 1003104441,
          'name': 'Gang Yu'
        },
        {
          'id': 1080993047,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 21,
      'reference_count': 37
    },
    {
      'id': 96580404,
      'title': 'Person Re-identification with Cascaded Pairwise Convolutions',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1111551178,
          'name': 'Yicheng Wang'
        },
        {
          'id': 1065148540,
          'name': 'Zhenzhong Chen'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        },
        {
          'id': 1017033586,
          'name': 'Gang Wang'
        }
      ],
      'field': [
        {
          'id': 2016027577,
          'name': 'Sampling (signal processing)'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2007430677,
          'name': 'Pattern matching'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037635684,
          'name': 'Scaling'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 0
    },
    {
      'id': 96634303,
      'title': 'Fully Convolutional Adaptation Networks for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1482027026,
          'name': 'Yiheng Zhang'
        },
        {
          'id': 1348886811,
          'name': 'Zhaofan Qiu'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1007025121,
          'name': 'Dong Liu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 13,
      'reference_count': 35
    },
    {
      'id': 9686834,
      'title': 'Dimensionality\'s Blessing: Clustering Images by Underlying Distribution',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103675905,
          'abbr': 'OSAKA-U',
          'name': 'Osaka University'
        }
      ],
      'author': [
        {
          'id': 1008180239,
          'name': 'Wen-Yan Lin'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1118373277,
          'name': 'Siying Liu'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029735533,
          'name': 'Data point'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2031529057,
          'name': 'Law of large numbers'
        },
        {
          'id': 2023756774,
          'name': 'Cluster (physics)'
        },
        {
          'id': 2008582367,
          'name': 'Phenomenon'
        },
        {
          'id': 2000668410,
          'name': 'Random variable'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 39
    },
    {
      'id': 98056874,
      'title': 'Gated Fusion Network for Single Image Dehazing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1224361079,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1346819320,
          'name': 'Jiawei Zhang'
        },
        {
          'id': 1043059512,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2018889831,
          'name': 'Color balance'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011226169,
          'name': 'Gamma correction'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 5,
      'reference_count': 41
    },
    {
      'id': 98150331,
      'title': 'Robust Classification with Convolutional Prototype Learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1192235684,
          'name': 'Hong-Ming Yang'
        },
        {
          'id': 1279838029,
          'name': 'Xu-Yao Zhang'
        },
        {
          'id': 1382350923,
          'name': 'Fei Yin'
        },
        {
          'id': 1255850207,
          'name': 'Cheng-Lin Liu'
        }
      ],
      'field': [
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2041368339,
          'name': 'Concept learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': 'http://cvpr2018.thecvf.com/program/main_conference',
      'citation_count': 3,
      'reference_count': 36
    },
    {
      'id': 99533847,
      'title': 'Learning Dual Convolutional Neural Networks for Low-Level Vision',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1313041809,
          'name': 'Sifei Liu'
        },
        {
          'id': 1330695574,
          'name': 'Deqing Sun'
        },
        {
          'id': 1346819320,
          'name': 'Jiawei Zhang'
        },
        {
          'id': 1070692057,
          'name': 'Yang Liu'
        },
        {
          'id': 1028004617,
          'name': 'Jimmy S. J. Ren'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2042654944,
          'name': 'Task analysis'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130271769',
        'name': 'CVPR',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 29
    },
    {
      'id': 10146544,
      'title': 'SRN: Side-Output Residual Network for Object Symmetry Detection in the Wild',
      'affiliation': [
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1030826032,
          'name': 'Wei Ke'
        },
        {
          'id': 1223927465,
          'name': 'Jie Chen'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        },
        {
          'id': 1259121145,
          'name': 'Guoying Zhao'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 30
    },
    {
      'id': 101735698,
      'title': 'Hardware-Efficient Guided Image Filtering for Multi-label Problem',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1252764777,
          'name': 'Longquan Dai'
        },
        {
          'id': 1133463783,
          'name': 'Mengke Yuan'
        },
        {
          'id': 1296702819,
          'name': 'Zechao Li'
        },
        {
          'id': 1493506431,
          'name': 'Xiaopeng Zhang'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2008685983,
          'name': 'Linear model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2026815810,
          'name': 'Polynomial'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 29
    },
    {
      'id': 106478548,
      'title': 'Neural Aggregation Network for Video Face Recognition',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1233564618,
          'name': 'Peiran Ren'
        },
        {
          'id': 1051040068,
          'name': 'Dongqing Zhang'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2041846228,
          'name': 'Convex hull'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 85,
      'reference_count': 47
    },
    {
      'id': 107832676,
      'title': 'Predicting Salient Face in Multiple-Face Videos',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2104258153,
          'abbr': 'NICTA',
          'name': 'NICTA'
        }
      ],
      'author': [
        {
          'id': 1310664425,
          'name': 'Yufan Liu'
        },
        {
          'id': 1382107634,
          'name': 'Songyang Zhang'
        },
        {
          'id': 1222524734,
          'name': 'Mai Xu'
        },
        {
          'id': 1291806924,
          'name': 'Xuming He'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 38
    },
    {
      'id': 109671986,
      'title': 'Multi-level Attention Networks for Visual Question Answering',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1366533650,
          'name': 'Dongfei Yu'
        },
        {
          'id': 1057895870,
          'name': 'Jianlong Fu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2010372103,
          'name': 'Semantic gap'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 38
    },
    {
      'id': 112356291,
      'title': 'Non-local Deep Features for Salient Object Detection',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2103632264,
          'abbr': 'UW',
          'name': 'University of Waterloo'
        },
        {
          'id': 2103337174,
          'abbr': 'USHERBROOKE',
          'name': 'Université de Sherbrooke'
        }
      ],
      'author': [
        {
          'id': 1447279511,
          'name': 'Zhiming Luo'
        },
        {
          'id': 1191513026,
          'name': 'Akshaya Kumar Mishra'
        },
        {
          'id': 1219399821,
          'name': 'Andrew Achkar'
        },
        {
          'id': 1373062113,
          'name': 'Justin A. Eichel'
        },
        {
          'id': 1438105862,
          'name': 'Shaozi Li'
        },
        {
          'id': 1357142826,
          'name': 'Pierre-Marc Jodoin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041433144,
          'name': 'Grid'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 55,
      'reference_count': 49
    },
    {
      'id': 118721322,
      'title': 'Surveillance Video Parsing with Single Frame Supervision',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1247318969,
          'name': 'Changhu Wang'
        },
        {
          'id': 1314270601,
          'name': 'Ruihe Qian'
        },
        {
          'id': 1358948854,
          'name': 'Han Yu'
        },
        {
          'id': 1295089707,
          'name': 'Renda Bao'
        },
        {
          'id': 1055371194,
          'name': 'Yao Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2044391384,
          'name': 'Block-matching algorithm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036409233,
          'name': 'Reference frame'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 48
    },
    {
      'id': 121681712,
      'title': 'Collaborative Deep Reinforcement Learning for Joint Object Search',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1399665861,
          'name': 'Xiangyu Kong'
        },
        {
          'id': 1410218114,
          'name': 'Bo Xin'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003588821,
          'name': 'Active object'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 39
    },
    {
      'id': 124277078,
      'title': 'StyleBank: An Explicit Representation for Neural Image Style Transfer',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1313251152,
          'name': 'Dongdong Chen'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1092358509,
          'name': 'Jing Liao'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041216465,
          'name': 'Texton'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025093314,
          'name': 'Network planning and design'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034325238,
          'name': 'Filter bank'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 97,
      'reference_count': 41
    },
    {
      'id': 128185049,
      'title': 'Deep Joint Rain Detection and Removal from a Single Image',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1077278755,
          'name': 'Wenhan Yang'
        },
        {
          'id': 1276044969,
          'name': 'Robby T. Tan'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1273935622,
          'name': 'Zongming Guo'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033840971,
          'name': 'Mist'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022031314,
          'name': 'Atmospheric model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011893552,
          'name': 'Streak'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008582367,
          'name': 'Phenomenon'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 72,
      'reference_count': 39
    },
    {
      'id': 137645569,
      'title': 'Network Sketching: Exploiting Binary Structure in Deep CNNs',
      'affiliation': [
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1198053711,
          'name': 'Yiwen Guo'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1263201152,
          'name': 'Hao Zhao'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        }
      ],
      'field': [
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2006889201,
          'name': 'Associative property'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003010881,
          'name': 'Memory management'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Guo_Network_Sketching_Exploiting_CVPR_2017_paper.pdf',
      'citation_count': 2,
      'reference_count': 32
    },
    {
      'id': 139531939,
      'title': 'Hardware-Efficient Guided Image Filtering for Multi-label Problem',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1252764777,
          'name': 'Longquan Dai'
        },
        {
          'id': 1133463783,
          'name': 'Mengke Yuan'
        },
        {
          'id': 1296702819,
          'name': 'Zechao Li'
        },
        {
          'id': 1493506431,
          'name': 'Xiaopeng Zhang'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        }
      ],
      'field': [
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008685983,
          'name': 'Linear model'
        },
        {
          'id': 2008028573,
          'name': 'Inverse'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2026815810,
          'name': 'Polynomial'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/conf/cvpr/cvpr2017.html',
      'citation_count': 1,
      'reference_count': 29
    },
    {
      'id': 143015358,
      'title': 'Fine-Grained Image Classification via Combining Vision and Language',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1026895110,
          'name': 'Xiangteng He'
        },
        {
          'id': 1111804441,
          'name': 'Yuxin Peng'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 43
    },
    {
      'id': 145353665,
      'title': 'Concurrence-Aware Long Short-Term Sub-Memories for Person-Person Action Recognition',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102926590,
          'abbr': 'NUAA',
          'name': 'Nanjing University of Aeronautics and Astronautics'
        }
      ],
      'author': [
        {
          'id': 1140220159,
          'name': 'Xiangbo Shu'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1240277240,
          'name': 'Guo-Jun Qi'
        },
        {
          'id': 1228173415,
          'name': 'Yan Song'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1000397774,
          'name': 'Liyan Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036785106,
          'name': 'Aerodynamics'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005580323,
          'name': 'Concurrence'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017_workshops/w37/papers/Shu_Concurrence-Aware_Long_Short-Term_CVPR_2017_paper.pdf',
      'citation_count': 0,
      'reference_count': 40
    },
    {
      'id': 152861137,
      'title': 'Robust FEC-CNN: A High Accuracy Facial Landmark Detection System',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1059369777,
          'name': 'Zhenliang He'
        },
        {
          'id': 1209179313,
          'name': 'Jie Zhang'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2026679767,
          'name': 'Animation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 27
    },
    {
      'id': 16174663,
      'title': 'Simultaneous Facial Landmark Detection, Pose and Deformation Estimation Under Facial Occlusion',
      'affiliation': [
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1367076216,
          'name': 'Yue Wu'
        },
        {
          'id': 1406000932,
          'name': 'Chao Gou'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 32
    },
    {
      'id': 163634076,
      'title': 'RON: Reverse Connection with Objectness Prior Networks for Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1242912686,
          'name': 'Tao Kong'
        },
        {
          'id': 1233632450,
          'name': 'Fuchun Sun'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1108094283,
          'name': 'Huaping Liu'
        },
        {
          'id': 1354266357,
          'name': 'Ming Lu'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        }
      ],
      'field': [
        {
          'id': 2026550381,
          'name': 'Reverse connection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Kong_RON_Reverse_Connection_CVPR_2017_paper.pdf',
      'citation_count': 30,
      'reference_count': 32
    },
    {
      'id': 164668681,
      'title': 'S3Pool: Pooling with Stochastic Spatial Sampling',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1471004512,
          'name': 'Shuangfei Zhai'
        },
        {
          'id': 1365222121,
          'name': 'Hui Wu'
        },
        {
          'id': 1219413755,
          'name': 'Abhishek Kumar'
        },
        {
          'id': 1464066788,
          'name': 'Yu Cheng'
        },
        {
          'id': 1263429302,
          'name': 'Yongxi Lu'
        },
        {
          'id': 1260701992,
          'name': 'Zhongfei Zhang'
        },
        {
          'id': 1063600234,
          'name': 'Rogério Schmidt Feris'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030242097,
          'name': 'Signal processing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025700882,
          'name': 'Signal reconstruction'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 29
    },
    {
      'id': 165127347,
      'title': 'Learning Deep CNN Denoiser Prior for Image Restoration',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1221197508,
          'name': 'Kai Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023693789,
          'name': 'Inverse problem'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 225,
      'reference_count': 65
    },
    {
      'id': 165176407,
      'title': 'Turning an Urban Scene Video into a Cinemagraph',
      'affiliation': [
        {
          'id': 2101817535,
          'abbr': 'WUSTL',
          'name': 'Washington University in St. Louis'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1355022357,
          'name': 'Hang Yan'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1364931837,
          'name': 'Yasutaka Furukawa'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027651673,
          'name': 'Computer graphics (images)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025153043,
          'name': 'Video capture'
        },
        {
          'id': 2026679767,
          'name': 'Animation'
        },
        {
          'id': 2030410974,
          'name': 'Motion compensation'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2000499954,
          'name': 'Geometric modeling'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 28
    },
    {
      'id': 166247013,
      'title': 'Multi-view 3D Object Detection Network for Autonomous Driving',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1331893764,
          'name': 'Xiaozhi Chen'
        },
        {
          'id': 1144842077,
          'name': 'Huimin Ma'
        },
        {
          'id': 1250621265,
          'name': 'Ji Wan'
        },
        {
          'id': 1345697497,
          'name': 'Bo Li'
        },
        {
          'id': 1244477036,
          'name': 'Tian Xia'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2016797546,
          'name': 'Lidar'
        },
        {
          'id': 2049946651,
          'name': 'Bird\'s-eye view'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 256,
      'reference_count': 34
    },
    {
      'id': 168648337,
      'title': 'L2-Net: Deep Learning of Discriminative Patch Descriptor in Euclidean Space',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1363329326,
          'name': 'Yurun Tian'
        },
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1163323626,
          'name': 'Fuchao Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026750366,
          'name': 'Compact space'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004101032,
          'name': 'Euclidean space'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 28
    },
    {
      'id': 1700244,
      'title': 'Snapshot Hyperspectral Light Field Imaging',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1372850284,
          'name': 'Lizhi Wang'
        },
        {
          'id': 1322974471,
          'name': 'Huiqun Li'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2002910651,
          'name': 'Reconstruction algorithm'
        },
        {
          'id': 2023606800,
          'name': 'Full spectral imaging'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035434896,
          'name': 'Snapshot (computer storage)'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2031517022,
          'name': 'Spectral bands'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 33
    },
    {
      'id': 17266968,
      'title': 'Fast Haze Removal for Nighttime Image Using Maximum Reflectance Prior',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        },
        {
          'id': 2103578022,
          'abbr': 'UB',
          'name': 'University at Buffalo'
        }
      ],
      'author': [
        {
          'id': 1098218155,
          'name': 'Jing Zhang'
        },
        {
          'id': 1004356455,
          'name': 'Yang Cao'
        },
        {
          'id': 1236717600,
          'name': 'Shuai Fang'
        },
        {
          'id': 1023644353,
          'name': 'Yu Kang'
        },
        {
          'id': 1148347089,
          'name': 'Chang Wen Chen'
        }
      ],
      'field': [
        {
          'id': 2048093308,
          'name': 'Attenuation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025222731,
          'name': 'Haze'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037257956,
          'name': 'Scattering'
        },
        {
          'id': 2001942329,
          'name': 'Reflectivity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2029730892,
          'name': 'Channel (digital image)'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 27
    },
    {
      'id': 173153033,
      'title': 'Learning Object Interactions and Descriptions for Semantic Image Segmentation',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1177260794,
          'name': 'Guangrun Wang'
        },
        {
          'id': 1275825081,
          'name': 'Ping Luo'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2002878760,
          'name': 'Learning object'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 32
    },
    {
      'id': 175664255,
      'title': 'DeshadowNet: A Multi-context Embedding Deep Network for Shadow Removal',
      'affiliation': [
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2102673436,
          'abbr': 'SIA',
          'name': 'Shenyang Institute of Automation'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1377431742,
          'name': 'Liangqiong Qu'
        },
        {
          'id': 1404282428,
          'name': 'Jiandong Tian'
        },
        {
          'id': 1301454973,
          'name': 'Shengfeng He'
        },
        {
          'id': 1321471777,
          'name': 'Yandong Tang'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2033971677,
          'name': 'Global network'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 34
    },
    {
      'id': 176205674,
      'title': 'Large Margin Object Tracking with Circulant Feature Maps',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1194736908,
          'name': 'Mengmeng Wang'
        },
        {
          'id': 1444675629,
          'name': 'Yong Liu'
        },
        {
          'id': 1400887066,
          'name': 'Zeyi Huang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2034116922,
          'name': 'Algorithm design'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2017318404,
          'name': 'Frame rate'
        },
        {
          'id': 2014799960,
          'name': 'Circulant matrix'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 72,
      'reference_count': 34
    },
    {
      'id': 177744110,
      'title': 'Richer Convolutional Features for Edge Detection',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1189823451,
          'name': 'Yun Liu'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1295307408,
          'name': 'Xiaowei Hu'
        },
        {
          'id': 1169182352,
          'name': 'Kai Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023746547,
          'name': 'Aspect ratio (image)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 114,
      'reference_count': 64
    },
    {
      'id': 181686468,
      'title': 'Scale-Aware Face Detection',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1415582824,
          'name': 'Zekun Hao'
        },
        {
          'id': 1058037770,
          'name': 'Yu Liu'
        },
        {
          'id': 1436337105,
          'name': 'Hongwei Qin'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1297620460,
          'name': 'Xiu Li'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2001048872,
          'name': 'Zoom'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 42
    },
    {
      'id': 183153292,
      'title': 'Image Splicing Detection via Camera Response Function Analysis',
      'affiliation': [
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        },
        {
          'id': 2103044204,
          'abbr': '',
          'name': 'Honeywell'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1227275058,
          'name': 'Can Chen'
        },
        {
          'id': 1091323953,
          'name': 'Scott McCloskey'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2036255561,
          'name': 'CRFS'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023625450,
          'name': 'RNA splicing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2022981540,
          'name': 'Handwriting recognition'
        },
        {
          'id': 2045710126,
          'name': 'Shutter'
        },
        {
          'id': 2048954615,
          'name': 'Aperture'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 38
    },
    {
      'id': 186176451,
      'title': 'A Taxonomy and Evaluation of Dense Light Field Depth Estimation Algorithms',
      'affiliation': [
        {
          'id': 2103026869,
          'abbr': 'UNI-KONSTANZ',
          'name': 'University of Konstanz'
        },
        {
          'id': 2103408752,
          'abbr': 'UNI-HEIDELBERG',
          'name': 'Heidelberg University'
        },
        {
          'id': 2101830261,
          'abbr': 'Levrieri',
          'name': 'Roma Tre University'
        },
        {
          'id': 2100369085,
          'abbr': 'ETRI',
          'name': 'Electronics and Telecommunications Research Institute'
        },
        {
          'id': 2104875451,
          'abbr': 'KAIST',
          'name': 'KAIST'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102978668,
          'abbr': 'NU',
          'name': 'Northwest University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1405276921,
          'name': 'Ole Johannsen'
        },
        {
          'id': 1060546120,
          'name': 'Katrin Honauer'
        },
        {
          'id': 1485337970,
          'name': 'Bastian Goldluecke'
        },
        {
          'id': 1065128979,
          'name': 'Anna Alperovich'
        },
        {
          'id': 1348339696,
          'name': 'Federica Battisti'
        },
        {
          'id': 1238484999,
          'name': 'Yunsu Bok'
        },
        {
          'id': 1219077775,
          'name': 'Michele Brizzi'
        },
        {
          'id': 1183523712,
          'name': 'Marco Carli'
        },
        {
          'id': 1008150583,
          'name': 'Gyeongmin Choe'
        },
        {
          'id': 1319288656,
          'name': 'Maximilian Diebold'
        },
        {
          'id': 1123692462,
          'name': 'Marcel Gutsche'
        },
        {
          'id': 1030504939,
          'name': 'Hae-Gon Jeon'
        },
        {
          'id': 1065070848,
          'name': 'In So Kweon'
        },
        {
          'id': 1426147004,
          'name': 'Jaesik Park'
        },
        {
          'id': 1178958363,
          'name': 'Jinsun Park'
        },
        {
          'id': 1433860865,
          'name': 'Hendrik Schilling'
        },
        {
          'id': 1352251956,
          'name': 'Hao Sheng'
        },
        {
          'id': 1218934513,
          'name': 'Lipeng Si'
        },
        {
          'id': 1101690546,
          'name': 'Michael Strecke'
        },
        {
          'id': 1074751530,
          'name': 'Antonin Sulc'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1375869814,
          'name': 'Qing Wang'
        },
        {
          'id': 1314540228,
          'name': 'Ting-Chun Wang'
        },
        {
          'id': 1264311192,
          'name': 'Sven Wanner'
        },
        {
          'id': 1176567166,
          'name': 'Zhang Xiong'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        },
        {
          'id': 1000903969,
          'name': 'Shuo Zhang'
        },
        {
          'id': 1096561903,
          'name': 'Hao Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2002842550,
          'name': 'Web page'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035434896,
          'name': 'Snapshot (computer storage)'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2016120293,
          'name': 'Strengths and weaknesses'
        },
        {
          'id': 2046794490,
          'name': 'Data mining'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 26
    },
    {
      'id': 187448077,
      'title': 'Learning Spatial Regularization with Image-Level Supervisions for Multi-label Image Classification',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1472316569,
          'name': 'Feng Zhu'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1227327887,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004563257,
          'name': 'Spatial relation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Zhu_Learning_Spatial_Regularization_CVPR_2017_paper.pdf',
      'citation_count': 6,
      'reference_count': 45
    },
    {
      'id': 187832492,
      'title': 'Task-Driven Dynamic Fusion: Reducing Ambiguity in Video Description',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1384809909,
          'name': 'Xishan Zhang'
        },
        {
          'id': 1223382402,
          'name': 'Ke Gao'
        },
        {
          'id': 1355307321,
          'name': 'Yongdong Zhang'
        },
        {
          'id': 1095549603,
          'name': 'Dongming Zhang'
        },
        {
          'id': 1154435778,
          'name': 'Jintao Li'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 39
    },
    {
      'id': 18796734,
      'title': 'A Matrix Splitting Method for Composite Function Minimization',
      'affiliation': [
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1239998239,
          'name': 'Ganzhao Yuan'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        }
      ],
      'field': [
        {
          'id': 2038091395,
          'name': 'Constrained optimization'
        },
        {
          'id': 2011741409,
          'name': 'Rate of convergence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2012350712,
          'name': 'Matrix splitting'
        },
        {
          'id': 2000280526,
          'name': 'Symmetric matrix'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2046713073,
          'name': 'Non-negative matrix factorization'
        },
        {
          'id': 2023706493,
          'name': 'Gaussian elimination'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 40
    },
    {
      'id': 1939678,
      'title': 'Improving Interpretability of Deep Neural Networks with Semantic Information',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2016120293,
          'name': 'Strengths and weaknesses'
        },
        {
          'id': 2030024898,
          'name': 'Interpretability'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2011384812,
          'name': 'Maximization'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 42
    },
    {
      'id': 19451542,
      'title': 'Recurrent 3D Pose Sequence Machines',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1194102210,
          'name': 'Mude Lin'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1103323841,
          'name': 'Hui Cheng'
        }
      ],
      'field': [
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042278253,
          'name': 'Articulated body pose estimation'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040543323,
          'name': '2D to 3D conversion'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 41
    },
    {
      'id': 196891568,
      'title': 'NTIRE 2017 Challenge on Single Image Super-Resolution: Methods and Results',
      'affiliation': [
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102394860,
          'abbr': 'IBS PAN',
          'name': 'Systems Research Institute'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104875451,
          'abbr': 'KAIST',
          'name': 'KAIST'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2100577709,
          'abbr': 'TAMU',
          'name': 'Texas A&M University'
        },
        {
          'id': 2100495785,
          'abbr': 'MU',
          'name': 'University of Missouri'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2102205365,
          'abbr': 'PSU',
          'name': 'Pennsylvania State University'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        }
      ],
      'author': [
        {
          'id': 1089908655,
          'name': 'Radu Timofte'
        },
        {
          'id': 1392176907,
          'name': 'Eirikur Agustsson'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1345784542,
          'name': 'Bee Lim'
        },
        {
          'id': 1453618248,
          'name': 'Sanghyun Son'
        },
        {
          'id': 1105918586,
          'name': 'Heewon Kim'
        },
        {
          'id': 1191672113,
          'name': 'Seungjun Nah'
        },
        {
          'id': 1383915828,
          'name': 'Kyoung Mu Lee'
        },
        {
          'id': 1166400983,
          'name': 'Xintao Wang'
        },
        {
          'id': 1434042206,
          'name': 'Yapeng Tian'
        },
        {
          'id': 1002063845,
          'name': 'Ke Yu'
        },
        {
          'id': 1205361105,
          'name': 'Yulun Zhang'
        },
        {
          'id': 1152255939,
          'name': 'Shixiang Wu'
        },
        {
          'id': 1130918340,
          'name': 'Chao Dong'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1113867669,
          'name': 'Woong Bae'
        },
        {
          'id': 1257383122,
          'name': 'Jaejun Yoo'
        },
        {
          'id': 1478762397,
          'name': 'Yoseob Han'
        },
        {
          'id': 1070329684,
          'name': 'Jong Chul Ye'
        },
        {
          'id': 1172188493,
          'name': 'Jae-Seok Choi'
        },
        {
          'id': 1363123282,
          'name': 'Munchurl Kim'
        },
        {
          'id': 1013038000,
          'name': 'Yuchen Fan'
        },
        {
          'id': 1406601254,
          'name': 'Jiahui Yu'
        },
        {
          'id': 1056368378,
          'name': 'Wei Han'
        },
        {
          'id': 1394627968,
          'name': 'Ding Liu'
        },
        {
          'id': 1279353929,
          'name': 'Haichao Yu'
        },
        {
          'id': 1474290517,
          'name': 'Zhangyang Wang'
        },
        {
          'id': 1209822350,
          'name': 'Honghui Shi'
        },
        {
          'id': 1379576641,
          'name': 'Xinchao Wang'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        },
        {
          'id': 1198730177,
          'name': 'Yunjin Chen'
        },
        {
          'id': 1007613985,
          'name': 'Kai Zhang'
        },
        {
          'id': 1047601223,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1189307605,
          'name': 'Zhimin Tang'
        },
        {
          'id': 1155215708,
          'name': 'Linkai Luo'
        },
        {
          'id': 1124035119,
          'name': 'Shaohui Li'
        },
        {
          'id': 1066108816,
          'name': 'Min Fu'
        },
        {
          'id': 1021333952,
          'name': 'Lei Cao'
        },
        {
          'id': 1452819376,
          'name': 'Wen Heng'
        },
        {
          'id': 1281677978,
          'name': 'Giang Bui'
        },
        {
          'id': 1281714456,
          'name': 'Truc Le'
        },
        {
          'id': 1326581002,
          'name': 'Ye. Duan'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1059492465,
          'name': 'Ruxin Wang'
        },
        {
          'id': 1177410076,
          'name': 'Xu Lin'
        },
        {
          'id': 1373423572,
          'name': 'Jianxin Pang'
        },
        {
          'id': 1200139156,
          'name': 'Jinchang Xu'
        },
        {
          'id': 1008308841,
          'name': 'Yu Zhao'
        },
        {
          'id': 1101111692,
          'name': 'Xiangyu Xu'
        },
        {
          'id': 1363750438,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1008918307,
          'name': 'Deqing Sun'
        },
        {
          'id': 1003800835,
          'name': 'Yujin Zhang'
        },
        {
          'id': 1171818947,
          'name': 'Xibin Song'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1093267435,
          'name': 'Xueying Qin'
        },
        {
          'id': 1035149192,
          'name': 'Xuan Phung Huynh'
        },
        {
          'id': 1320291343,
          'name': 'Tiantong Guo'
        },
        {
          'id': 1051284882,
          'name': 'Hojjat Seyed Mousavi'
        },
        {
          'id': 1497795954,
          'name': 'Tiep Huu Vu'
        },
        {
          'id': 1260672291,
          'name': 'Vishal Monga'
        },
        {
          'id': 1145175138,
          'name': 'Cristovao Cruz'
        },
        {
          'id': 1001772226,
          'name': 'Karen Egiazarian'
        },
        {
          'id': 1370614387,
          'name': 'Vladimir Katkovnik'
        },
        {
          'id': 1031376985,
          'name': 'Rakesh Mehta'
        },
        {
          'id': 1184623826,
          'name': 'Arnav Kumar Jain'
        },
        {
          'id': 1292507882,
          'name': 'Abhinav Agarwalla'
        },
        {
          'id': 1361052482,
          'name': 'Ch V.Sai Praveen'
        },
        {
          'id': 1390052761,
          'name': 'Ruofan Zhou'
        },
        {
          'id': 1333527817,
          'name': 'Hongdiao Wen'
        },
        {
          'id': 1173477064,
          'name': 'Che Zhu'
        },
        {
          'id': 1419806617,
          'name': 'Zhiqiang Xia'
        },
        {
          'id': 1353829386,
          'name': 'Zhengtao Wang'
        },
        {
          'id': 1011317596,
          'name': 'Qi Guo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032805116,
          'name': 'Sub-pixel resolution'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        },
        {
          'id': 2045937712,
          'name': 'Bicubic interpolation'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2036743030,
          'name': '2K resolution'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 105,
      'reference_count': 39
    },
    {
      'id': 198664709,
      'title': 'Quality Aware Network for Set to Set Recognition',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1260288408,
          'name': 'Yu Liu'
        },
        {
          'id': 1010547470,
          'name': 'Junjie Yan'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2012284111,
          'name': 'Quality Score'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Liu_Quality_Aware_Network_CVPR_2017_paper.pdf',
      'citation_count': 22,
      'reference_count': 39
    },
    {
      'id': 200518887,
      'title': 'G2DeNet: Global Gaussian Distribution Embedding Network and Its Application to Visual Recognition',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2039634285,
          'name': 'Gaussian random field'
        },
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2039760485,
          'name': 'Gaussian function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2000280526,
          'name': 'Symmetric matrix'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 41
    },
    {
      'id': 201183000,
      'title': 'Attention-Aware Face Hallucination via Deep Reinforcement Learning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1224924548,
          'name': 'Qingxing Cao'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1195736920,
          'name': 'Yukai Shi'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1316453658,
          'name': 'Guanbin Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Cao_Attention-Aware_Face_Hallucination_CVPR_2017_paper.pdf',
      'citation_count': 7,
      'reference_count': 33
    },
    {
      'id': 203293252,
      'title': 'Concurrence-Aware Long Short-Term Sub-Memories for Person-Person Action Recognition',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102926590,
          'abbr': 'NUAA',
          'name': 'Nanjing University of Aeronautics and Astronautics'
        }
      ],
      'author': [
        {
          'id': 1140220159,
          'name': 'Xiangbo Shu'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1240277240,
          'name': 'Guo-Jun Qi'
        },
        {
          'id': 1228173415,
          'name': 'Yan Song'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1000397774,
          'name': 'Liyan Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2005580323,
          'name': 'Concurrence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 40
    },
    {
      'id': 203429602,
      'title': 'Deep Cross-Modal Hashing',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1048955324,
          'name': 'Qing-Yuan Jiang'
        },
        {
          'id': 1449029323,
          'name': 'Wu-Jun Li'
        }
      ],
      'field': [
        {
          'id': 2046794490,
          'name': 'Data mining'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023124670,
          'name': 'Scratch'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 105,
      'reference_count': 51
    },
    {
      'id': 20451989,
      'title': 'Fully Convolutional Instance-Aware Semantic Segmentation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1219021850,
          'name': 'Yi Li'
        },
        {
          'id': 1402900587,
          'name': 'Haozhi Qi'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1216213174,
          'name': 'Xiangyang Ji'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 201,
      'reference_count': 38
    },
    {
      'id': 20636292,
      'title': 'Fast and Accurate Image Super-Resolution Using a Combined Loss',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1426006385,
          'name': 'Jinchang Xu'
        },
        {
          'id': 1307490485,
          'name': 'Yu Zhao'
        },
        {
          'id': 1003328915,
          'name': 'Yuan Dong'
        },
        {
          'id': 1054921134,
          'name': 'Hongliang Bai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2029730892,
          'name': 'Channel (digital image)'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 21034485,
      'title': 'Detecting Oriented Text in Natural Images by Linking Segments',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        }
      ],
      'author': [
        {
          'id': 1154870261,
          'name': 'Baoguang Shi'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1238813530,
          'name': 'Serge J. Belongie'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 111,
      'reference_count': 29
    },
    {
      'id': 212652491,
      'title': 'HOPE: Hierarchical Object Prototype Encoding for Efficient Object Instance Search in Videos',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1151633296,
          'name': 'Tan Yu'
        },
        {
          'id': 1134499897,
          'name': 'Yuwei Wu'
        },
        {
          'id': 1262920571,
          'name': 'Junsong Yuan'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038711435,
          'name': 'Multiplication'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2043719263,
          'name': 'Method'
        },
        {
          'id': 2024356877,
          'name': 'Search problem'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 39
    },
    {
      'id': 215154232,
      'title': 'Learning Dynamic GMM for Attention Distribution on Single-Face Videos',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1477250630,
          'name': 'Yun Ren'
        },
        {
          'id': 1287578436,
          'name': 'Zulin Wang'
        },
        {
          'id': 1118664104,
          'name': 'Mai Xu'
        },
        {
          'id': 1020705429,
          'name': 'Haoyu Dong'
        },
        {
          'id': 1404210287,
          'name': 'Shengxi Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2044009394,
          'name': 'Fixation (psychology)'
        },
        {
          'id': 2049390455,
          'name': 'Videoconferencing'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 38
    },
    {
      'id': 221067718,
      'title': 'Video Segmentation via Multiple Granularity Analysis',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1001548902,
          'name': 'Rui Yang'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1053338084,
          'name': 'Chao Ma'
        },
        {
          'id': 1124030868,
          'name': 'Yi Xu'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2002781344,
          'name': 'Bounding volume'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 44
    },
    {
      'id': 221230856,
      'title': 'Incorporating Copying Mechanism in Image Captioning for Learning Novel Objects',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1403845832,
          'name': 'Yehao Li'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041704918,
          'name': 'Copying'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042780085,
          'name': 'Copying mechanism'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 29
    },
    {
      'id': 222569670,
      'title': 'Neural Aggregation Network for Video Face Recognition',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1457563122,
          'name': 'Peiran Ren'
        },
        {
          'id': 1051040068,
          'name': 'Dongqing Zhang'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2041846228,
          'name': 'Convex hull'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1603.05474.pdf',
      'citation_count': 13,
      'reference_count': 41
    },
    {
      'id': 224803780,
      'title': 'RON: Reverse Connection with Objectness Prior Networks for Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1242912686,
          'name': 'Tao Kong'
        },
        {
          'id': 1233632450,
          'name': 'Fuchun Sun'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1108094283,
          'name': 'Huaping Liu'
        },
        {
          'id': 1354266357,
          'name': 'Ming Lu'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        }
      ],
      'field': [
        {
          'id': 2042313595,
          'name': 'Pascal (unit)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026550381,
          'name': 'Reverse connection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 33
    },
    {
      'id': 224829692,
      'title': 'Adaptive Deep Metric Learning for Identity-Aware Facial Expression Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1462671181,
          'name': 'Xiaofeng Liu'
        },
        {
          'id': 1486557385,
          'name': 'B. V. K. Vijaya Kumar'
        },
        {
          'id': 1167489460,
          'name': 'Jane You'
        },
        {
          'id': 1094548282,
          'name': 'Ping Jia'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 49
    },
    {
      'id': 22521872,
      'title': 'Physics Inspired Optimization on Semantic Transfer Features: An Alternative Method for Room Layout Estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1263201152,
          'name': 'Hao Zhao'
        },
        {
          'id': 1354266357,
          'name': 'Ming Lu'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1198053711,
          'name': 'Yiwen Guo'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1168982309,
          'name': 'Li Zhang'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 2,
      'reference_count': 30
    },
    {
      'id': 226351132,
      'title': 'Classification and Retrieval of Digital Pathology Scans: A New Dataset',
      'affiliation': [
        {
          'id': 2103632264,
          'abbr': 'UW',
          'name': 'University of Waterloo'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2103850856,
          'abbr': 'DEAKIN',
          'name': 'Deakin University'
        },
        {
          'id': 2102694495,
          'abbr': 'UOIT',
          'name': 'University of Ontario Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1066676037,
          'name': 'Morteza Babaie'
        },
        {
          'id': 1406017157,
          'name': 'Shivam Kalra'
        },
        {
          'id': 1421684851,
          'name': 'Aditya Sriram'
        },
        {
          'id': 1422442441,
          'name': 'Christopher Mitcheltree'
        },
        {
          'id': 1095350503,
          'name': 'Shujin Zhu'
        },
        {
          'id': 1252904306,
          'name': 'Amin Khatami'
        },
        {
          'id': 1032246392,
          'name': 'Shahryar Rahnamayan'
        },
        {
          'id': 1306557819,
          'name': 'Hamid R. Tizhoosh'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2034116922,
          'name': 'Algorithm design'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2010269555,
          'name': 'Digital pathology'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 38
    },
    {
      'id': 228066620,
      'title': 'S 4 Net: Single Stage Salient-Instance Segmentation.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1277383896,
          'name': 'Ruochen Fan'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1118893621,
          'name': 'Tai-Jiang Mu'
        },
        {
          'id': 1003953660,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1711.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 233631617,
      'title': 'Look Closer to See Better: Recurrent Attention Convolutional Neural Network for Fine-Grained Image Recognition',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1057895870,
          'name': 'Jianlong Fu'
        },
        {
          'id': 1362733542,
          'name': 'Heliang Zheng'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 167,
      'reference_count': 31
    },
    {
      'id': 233758419,
      'title': 'Look into Person: Self-Supervised Structure-Sensitive Learning and a New Benchmark for Human Parsing',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        }
      ],
      'author': [
        {
          'id': 1045211755,
          'name': 'Ke Gong'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1088090337,
          'name': 'DongYu Zhang'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2046794490,
          'name': 'Data mining'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2029515839,
          'name': 'Server'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 81,
      'reference_count': 36
    },
    {
      'id': 234628751,
      'title': 'FormResNet: Formatted Residual Learning for Image Restoration',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101276577,
          'abbr': 'NTU',
          'name': 'National Taiwan University'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1198093426,
          'name': 'Jianbo Jiao'
        },
        {
          'id': 1494390522,
          'name': 'Wei-Chih Tu'
        },
        {
          'id': 1301454973,
          'name': 'Shengfeng He'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2001513213,
          'name': 'Disk formatting'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 41
    },
    {
      'id': 238142693,
      'title': 'Robust Interpolation of Correspondences for Large Displacement Optical Flow',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102951625,
          'abbr': 'SWPU',
          'name': 'Southwest Petroleum University'
        }
      ],
      'author': [
        {
          'id': 1028169900,
          'name': 'Yinlin Hu'
        },
        {
          'id': 1054947225,
          'name': 'Yunsong Li'
        },
        {
          'id': 1319912337,
          'name': 'Rui Song'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 42
    },
    {
      'id': 238248799,
      'title': 'Learning Category-Specific 3D Shape Models from Weakly Labeled 2D Images',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1000650126,
          'name': 'Yang Yang'
        },
        {
          'id': 1432027227,
          'name': 'Dong Huang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006137252,
          'name': 'Active shape model'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 37
    },
    {
      'id': 239340624,
      'title': 'Deep Matching Prior Network: Toward Tighter Multi-oriented Text Detection',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1054273681,
          'name': 'Yuliang Liu'
        },
        {
          'id': 1276589951,
          'name': 'Lianwen Jin'
        }
      ],
      'field': [
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033182780,
          'name': 'Quadrangle'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028120887,
          'name': 'Quadrilateral'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 40
    },
    {
      'id': 240298752,
      'title': 'Point to Set Similarity Based Deep Feature Learning for Person Re-Identification',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1142696844,
          'name': 'Sanping Zhou'
        },
        {
          'id': 1072625952,
          'name': 'Jinjun Wang'
        },
        {
          'id': 1306860307,
          'name': 'Jiayun Wang'
        },
        {
          'id': 1136791764,
          'name': 'Yihong Gong'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 41
    },
    {
      'id': 241057755,
      'title': 'Global Context-Aware Attention LSTM Networks for 3D Action Recognition',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1279547932,
          'name': 'Jun Liu'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        },
        {
          'id': 1007415920,
          'name': 'Ping Hu'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1314678280,
          'name': 'Alex C. Kot'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 84,
      'reference_count': 71
    },
    {
      'id': 242899984,
      'title': 'Multi-context Attention for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1182467688,
          'name': 'Xiao Chu'
        },
        {
          'id': 1022602061,
          'name': 'Wei Yang'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1037455158,
          'name': 'Cheng Ma'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        },
        {
          'id': 1227327887,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Chu_Multi-Context_Attention_for_CVPR_2017_paper.pdf',
      'citation_count': 40,
      'reference_count': 49
    },
    {
      'id': 243906901,
      'title': 'Noisy Softmax: Improving the Generalization Ability of DCNN via Postponing the Early Softmax Saturation',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1376251330,
          'name': 'Binghui Chen'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1328787968,
          'name': 'Junping Du'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004009963,
          'name': 'Saturation (chemistry)'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 52
    },
    {
      'id': 245395067,
      'title': 'Spatiotemporal Pyramid Network for Video Action Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103382811,
          'abbr': 'UIC',
          'name': 'University of Illinois at Chicago'
        }
      ],
      'author': [
        {
          'id': 1498309642,
          'name': 'Yunbo Wang'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1445179598,
          'name': 'Philip S. Yu'
        }
      ],
      'field': [
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2032401552,
          'name': 'Abstraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 40
    },
    {
      'id': 250440817,
      'title': 'Binary Coding for Partial Action Analysis with Limited Observation Ratios',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1478991474,
          'name': 'Jie Qin'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1081239144,
          'name': 'Chen Chen'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 61
    },
    {
      'id': 251654762,
      'title': 'Cross-Modality Binary Code Learning via Fusion Similarity Hashing',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1358234232,
          'name': 'Hong Liu'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1478890822,
          'name': 'Yongjian Wu'
        },
        {
          'id': 1397769541,
          'name': 'Feiyue Huang'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        }
      ],
      'field': [
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038927413,
          'name': 'Asymmetric graph'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2046455943,
          'name': 'Hamming distance'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000280526,
          'name': 'Symmetric matrix'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 32
    },
    {
      'id': 255609583,
      'title': 'Exploring the Granularity of Sparsity in Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1499479693,
          'name': 'Huizi Mao'
        },
        {
          'id': 1049517621,
          'name': 'Song Han'
        },
        {
          'id': 1046664665,
          'name': 'Jeff Pool'
        },
        {
          'id': 1135144477,
          'name': 'Wenshuo Li'
        },
        {
          'id': 1021542075,
          'name': 'Xingyu Liu'
        },
        {
          'id': 1386866877,
          'name': 'Yu Wang'
        },
        {
          'id': 1344202749,
          'name': 'William J. Dally'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2019901692,
          'name': 'Hardware architecture'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021960591,
          'name': 'Hardware acceleration'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 22
    },
    {
      'id': 256896920,
      'title': 'A Novel Tensor-Based Video Rain Streaks Removal Approach via Utilizing Discriminatively Intrinsic Priors',
      'affiliation': [
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1496027980,
          'name': 'Tai-Xiang Jiang'
        },
        {
          'id': 1488402842,
          'name': 'Ting-Zhu Huang'
        },
        {
          'id': 1359383566,
          'name': 'Xi-Le Zhao'
        },
        {
          'id': 1200047102,
          'name': 'Liang-Jian Deng'
        },
        {
          'id': 1212993904,
          'name': 'Yao Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2013517689,
          'name': 'Stress (mechanics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 29
    },
    {
      'id': 258533382,
      'title': 'Residual Attention Network for Image Classification',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1002286924,
          'name': 'Fei Wang'
        },
        {
          'id': 1313918411,
          'name': 'Mengqing Jiang'
        },
        {
          'id': 1444539972,
          'name': 'Chen Qian'
        },
        {
          'id': 1024062129,
          'name': 'Shuo Yang'
        },
        {
          'id': 1187972211,
          'name': 'Cheng Li'
        },
        {
          'id': 1084731274,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2032550953,
          'name': 'Feed forward'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 153,
      'reference_count': 39
    },
    {
      'id': 260765955,
      'title': 'Hyper-Laplacian Regularized Unidirectional Low-Rank Tensor Recovery for Multispectral Image Denoising',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1253723875,
          'name': 'Yi Chang'
        },
        {
          'id': 1001152243,
          'name': 'Luxin Yan'
        },
        {
          'id': 1101071010,
          'name': 'Sheng Zhong'
        }
      ],
      'field': [
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2013517689,
          'name': 'Stress (mechanics)'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2008086083,
          'name': 'Ringing artifacts'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2041758568,
          'name': 'Laplace operator'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 34
    },
    {
      'id': 267332359,
      'title': 'Simultaneous Facial Landmark Detection, Pose and Deformation Estimation Under Facial Occlusion',
      'affiliation': [
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1367076216,
          'name': 'Yue Wu'
        },
        {
          'id': 1406000932,
          'name': 'Chao Gou'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Wu_Simultaneous_Facial_Landmark_CVPR_2017_paper.pdf',
      'citation_count': 9,
      'reference_count': 32
    },
    {
      'id': 268228855,
      'title': 'Modeling Temporal Dynamics and Spatial Configurations of Actions Using Two-Stream Recurrent Neural Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1159458467,
          'name': 'Hongsong Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2000979948,
          'name': 'Kinematics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2037635684,
          'name': 'Scaling'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 52,
      'reference_count': 55
    },
    {
      'id': 271626500,
      'title': 'Large Kernel Matters — Improve Semantic Segmentation by Global Convolutional Network',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1416154934,
          'name': 'Gang Yu'
        },
        {
          'id': 1219723136,
          'name': 'Guiming Luo'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 177,
      'reference_count': 30
    },
    {
      'id': 271851674,
      'title': 'AMVH: Asymmetric Multi-Valued hashing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1059503473,
          'name': 'Cheng Da'
        },
        {
          'id': 1150982761,
          'name': 'Shibiao Xu'
        },
        {
          'id': 1210303434,
          'name': 'Kun Ding'
        },
        {
          'id': 1296467528,
          'name': 'Gaofeng Meng'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1114885725,
          'name': 'Chunhong Pan'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2029562301,
          'name': 'Integer programming'
        },
        {
          'id': 2020288432,
          'name': 'Storage efficiency'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2035624340,
          'name': 'Semantic search'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 40
    },
    {
      'id': 273220297,
      'title': 'Recurrent 3D Pose Sequence Machines',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1194102210,
          'name': 'Mude Lin'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1103323841,
          'name': 'Hui Cheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040543323,
          'name': '2D to 3D conversion'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 10,
      'reference_count': 40
    },
    {
      'id': 273700340,
      'title': 'Learning Discriminative and Transformation Covariant Local Feature Detectors',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1461070388,
          'name': 'Xu Zhang'
        },
        {
          'id': 1379625839,
          'name': 'Felix X. Yu'
        },
        {
          'id': 1414215655,
          'name': 'Svebor Karaman'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2008612489,
          'name': 'Covariant transformation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2017602194,
          'name': 'Feature detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 26
    },
    {
      'id': 273734682,
      'title': 'Object Region Mining with Adversarial Erasing: A Simple Classification to Semantic Segmentation Approach',
      'affiliation': [
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1305989134,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1114137804,
          'name': 'Yao Zhao'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 123,
      'reference_count': 33
    },
    {
      'id': 275861842,
      'title': 'Distinguishing the Indistinguishable: Exploring Structural Ambiguities via Geodesic Context',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2102722512,
          'abbr': 'NWAFU',
          'name': 'Northwest A&F University'
        }
      ],
      'author': [
        {
          'id': 1115773552,
          'name': 'Qingan Yan'
        },
        {
          'id': 1150662102,
          'name': 'Long Yang'
        },
        {
          'id': 1419037781,
          'name': 'Ling Zhang'
        },
        {
          'id': 1048335762,
          'name': 'Chunxia Xiao'
        }
      ],
      'field': [
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 35
    },
    {
      'id': 276214806,
      'title': 'Incorporating Copying Mechanism in Image Captioning for Learning Novel Objects',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1255778048,
          'name': 'Ting Yao'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1446767239,
          'name': 'Yehao Li'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2042780085,
          'name': 'Copying mechanism'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041704918,
          'name': 'Copying'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Yao_Incorporating_Copying_Mechanism_CVPR_2017_paper.pdf',
      'citation_count': 8,
      'reference_count': 29
    },
    {
      'id': 278942684,
      'title': 'Learning Adaptive Receptive Fields for Deep Image Parsing Network',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1066692196,
          'name': 'Zhen Wei'
        },
        {
          'id': 1055371194,
          'name': 'Yao Sun'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        },
        {
          'id': 1249782652,
          'name': 'Hanjiang Lai'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 21
    },
    {
      'id': 279264977,
      'title': 'Deep Quantization: Encoding Convolutional Activations with Deep Generative Model',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1348886811,
          'name': 'Zhaofan Qiu'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2005597368,
          'name': 'Deep belief network'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 49
    },
    {
      'id': 281311887,
      'title': 'SCA-CNN: Spatial and Channel-Wise Attention in Convolutional Networks for Image Captioning',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1407712068,
          'name': 'Long Chen'
        },
        {
          'id': 1424881830,
          'name': 'Hanwang Zhang'
        },
        {
          'id': 1122653209,
          'name': 'Jun Xiao'
        },
        {
          'id': 1316313123,
          'name': 'Liqiang Nie'
        },
        {
          'id': 1130645051,
          'name': 'Jian Shao'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1334725215,
          'name': 'Tat-Seng Chua'
        }
      ],
      'field': [
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 221,
      'reference_count': 42
    },
    {
      'id': 288369436,
      'title': 'Light Field Reconstruction Using Deep Convolutional Network on EPI',
      'affiliation': [
        {
          'id': 2100343429,
          'abbr': 'NEU',
          'name': 'Northeastern University (China)'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1005759202,
          'name': 'Gaochang Wu'
        },
        {
          'id': 1167572555,
          'name': 'Mandan Zhao'
        },
        {
          'id': 1089479906,
          'name': 'Liangyong Wang'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1200313007,
          'name': 'Tianyou Chai'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2000880898,
          'name': 'Undersampling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2020539549,
          'name': 'Epipolar geometry'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 33
    },
    {
      'id': 290167958,
      'title': 'Video Captioning with Transferred Semantic Attributes',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 77,
      'reference_count': 41
    },
    {
      'id': 291474326,
      'title': 'Densely Connected Convolutional Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102294146,
          'abbr': 'TUDELFT',
          'name': 'Delft University of Technology'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        }
      ],
      'author': [
        {
          'id': 1070895922,
          'name': 'Gao Huang'
        },
        {
          'id': 1434676857,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1483427321,
          'name': 'Laurens van der Maaten'
        },
        {
          'id': 1159231599,
          'name': 'Kilian Q. Weinberger'
        }
      ],
      'field': [
        {
          'id': 2016965578,
          'name': 'Computer engineering'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2039238611,
          'name': 'Reuse'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Huang_Densely_Connected_Convolutional_CVPR_2017_paper.pdf',
      'citation_count': 665,
      'reference_count': 39
    },
    {
      'id': 297628913,
      'title': 'Diverse Image Annotation',
      'affiliation': [
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1266946770,
          'name': 'Fan Jia'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043278624,
          'name': 'F1 score'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2034176497,
          'name': 'Determinantal point process'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 33
    },
    {
      'id': 302263151,
      'title': 'From Zero-Shot Learning to Conventional Supervised Classification: Unseen Visual Data Synthesis',
      'affiliation': [
        {
          'id': 2101550415,
          'abbr': 'SHEFFIELD',
          'name': 'University of Sheffield'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102005978,
          'abbr': 'NORTHUMBRIA',
          'name': 'Northumbria University'
        }
      ],
      'author': [
        {
          'id': 1108220910,
          'name': 'Yang Long'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1356043250,
          'name': 'Jungong Han'
        }
      ],
      'field': [
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Long_From_Zero-Shot_Learning_CVPR_2017_paper.pdf',
      'citation_count': 7,
      'reference_count': 54
    },
    {
      'id': 30616002,
      'title': 'Learning Non-Lambertian Object Intrinsics Across ShapeNet Categories',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1083043076,
          'name': 'Jian Shi'
        },
        {
          'id': 1100002116,
          'name': 'Yue Dong'
        },
        {
          'id': 1464060409,
          'name': 'Hao Su'
        },
        {
          'id': 1309932068,
          'name': 'Stella X. Yu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023693789,
          'name': 'Inverse problem'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022543674,
          'name': 'Albedo'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2013822762,
          'name': 'Specular highlight'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2016670402,
          'name': 'Intrinsics'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 33
    },
    {
      'id': 311551571,
      'title': 'Improving Training of Deep Neural Networks via Singular Value Bounding',
      'affiliation': [
        {
          'id': 2100888578,
          'abbr': 'UM',
          'name': 'University of Macau'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1136612103,
          'name': 'Kui Jia'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1033627606,
          'name': 'Shenghua Gao'
        },
        {
          'id': 1042427767,
          'name': 'Xiangmin Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025865676,
          'name': 'Feasible region'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029498710,
          'name': 'Spite'
        },
        {
          'id': 2011767737,
          'name': 'Bounded function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 37
    },
    {
      'id': 312037368,
      'title': 'Re-ranking Person Re-identification with k-Reciprocal Encoding',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1277244399,
          'name': 'Zhun Zhong'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1177350000,
          'name': 'Donglin Cao'
        },
        {
          'id': 1438105862,
          'name': 'Shaozi Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031276404,
          'name': 'Jaccard index'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2007925444,
          'name': 'Reciprocal'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 134,
      'reference_count': 48
    },
    {
      'id': 315029899,
      'title': 'Recurrent Convolutional Neural Networks for Continuous Sign Language Recognition by Staged Optimization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1155134345,
          'name': 'Runpeng Cui'
        },
        {
          'id': 1022037645,
          'name': 'Hu Liu'
        },
        {
          'id': 1110227432,
          'name': 'Changshui Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2015795312,
          'name': 'Sign language'
        },
        {
          'id': 2038401195,
          'name': 'Sequence learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 30
    },
    {
      'id': 315077926,
      'title': 'Learning to Detect Salient Objects with Image-Level Supervision',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1175228459,
          'name': 'Yifan Wang'
        },
        {
          'id': 1451407143,
          'name': 'Mengyang Feng'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1310020299,
          'name': 'Baocai Yin'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 60
    },
    {
      'id': 318677831,
      'title': 'Network Sketching: Exploiting Binary Structure in Deep CNNs',
      'affiliation': [
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1198053711,
          'name': 'Yiwen Guo'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1263201152,
          'name': 'Hao Zhao'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2003010881,
          'name': 'Memory management'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2006889201,
          'name': 'Associative property'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 32
    },
    {
      'id': 319084455,
      'title': 'What Can Help Pedestrian Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1396601583,
          'name': 'Jiayuan Mao'
        },
        {
          'id': 1125500898,
          'name': 'Tete Xiao'
        },
        {
          'id': 1314532880,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1454179856,
          'name': 'Zhimin Cao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 37
    },
    {
      'id': 321098851,
      'title': 'Deeply Supervised Salient Object Detection with Short Connections',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        }
      ],
      'author': [
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1295307408,
          'name': 'Xiaowei Hu'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 161,
      'reference_count': 69
    },
    {
      'id': 321137315,
      'title': 'One-To-Many Network for Visually Pleasing Compression Artifacts Reduction',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1076495158,
          'name': 'Jun Guo'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        }
      ],
      'field': [
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028502729,
          'name': 'Naturalness'
        },
        {
          'id': 2005161079,
          'name': 'One-to-many'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010620100,
          'name': 'Compression artifact'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 44
    },
    {
      'id': 336957106,
      'title': 'SPFTN: A Self-Paced Fine-Tuning Network for Segmenting Objects in Weakly Labelled Videos',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1380636237,
          'name': 'Le Yang'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2026464504,
          'name': 'Fine-tuning'
        },
        {
          'id': 2000871037,
          'name': 'Market segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 39
    },
    {
      'id': 339886789,
      'title': 'Learning Multifunctional Binary Codes for Both Category and Attribute Oriented Retrieval Tasks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1407582576,
          'name': 'Haomiao Liu'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006310969,
          'name': 'The Internet'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 43
    },
    {
      'id': 34414046,
      'title': 'Real-Time Neural Style Transfer for Videos',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1074505593,
          'name': 'Haozhi Huang'
        },
        {
          'id': 1198143559,
          'name': 'Hao Wang'
        },
        {
          'id': 1176702019,
          'name': 'Wenhan Luo'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1243936135,
          'name': 'Wenhao Jiang'
        },
        {
          'id': 1025990401,
          'name': 'Xiaolong Zhu'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007469624,
          'name': 'Stylized fact'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002093614,
          'name': 'On the fly'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 25
    },
    {
      'id': 346985545,
      'title': 'Semantic Compositional Networks for Visual Captioning',
      'affiliation': [
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1254855809,
          'name': 'Zhe Gan'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1103020618,
          'name': 'Xiaodong He'
        },
        {
          'id': 1055363036,
          'name': 'Yunchen Pu'
        },
        {
          'id': 1001021745,
          'name': 'Kenneth Tran'
        },
        {
          'id': 1323535504,
          'name': 'Jianfeng Gao'
        },
        {
          'id': 1363323578,
          'name': 'Lawrence Carin'
        },
        {
          'id': 1166776264,
          'name': 'Li Deng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036641578,
          'name': 'CLIPS'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 136,
      'reference_count': 59
    },
    {
      'id': 347721122,
      'title': 'Unconstrained Face Alignment Without Face Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1052816179,
          'name': 'Xiaohu Shao'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1422866757,
          'name': 'Jiang-Jing Lv'
        },
        {
          'id': 1045603127,
          'name': 'Chunlin Xiao'
        },
        {
          'id': 1497090597,
          'name': 'Pengcheng Liu'
        },
        {
          'id': 1047344499,
          'name': 'Youji Feng'
        },
        {
          'id': 1139780231,
          'name': 'Cheng Cheng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 26
    },
    {
      'id': 352546901,
      'title': 'Perceptual Generative Adversarial Networks for Small Object Detection',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1418987820,
          'name': 'Jianan Li'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1235764616,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1252794356,
          'name': 'Tingfa Xu'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 80,
      'reference_count': 45
    },
    {
      'id': 352649281,
      'title': 'Temporal Action Localization by Structured Maximal Sums',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        },
        {
          'id': 2102243180,
          'abbr': 'RI',
          'name': 'Robotics Institute'
        }
      ],
      'author': [
        {
          'id': 1369437093,
          'name': 'Zehuan Yuan'
        },
        {
          'id': 1147061239,
          'name': 'Jonathan C. Stroud'
        },
        {
          'id': 1149898936,
          'name': 'Tong Lu'
        },
        {
          'id': 1389718805,
          'name': 'Jia Deng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042509845,
          'name': 'Thumos'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2005310215,
          'name': 'Structured prediction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 28
    },
    {
      'id': 353643843,
      'title': 'Deeply Supervised Salient Object Detection with Short Connections',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        }
      ],
      'author': [
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1295307408,
          'name': 'Xiaowei Hu'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Hou_Deeply_Supervised_Salient_CVPR_2017_paper.pdf',
      'citation_count': 46,
      'reference_count': 54
    },
    {
      'id': 356624894,
      'title': 'Mind the Class Weight Bias: Weighted Maximum Mean Discrepancy for Unsupervised Domain Adaptation',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1468980796,
          'name': 'Hongliang Yan'
        },
        {
          'id': 1088635186,
          'name': 'Yukang Ding'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1004556477,
          'name': 'Yong Xu'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2002073950,
          'name': 'Expectation–maximization algorithm'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 43
    },
    {
      'id': 359570212,
      'title': 'Multi-context Attention for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1479602131,
          'name': 'Xiao Chu'
        },
        {
          'id': 1375862671,
          'name': 'Wei Yang'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1037455158,
          'name': 'Cheng Ma'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 109,
      'reference_count': 51
    },
    {
      'id': 360509095,
      'title': 'Online Asymmetric Similarity Learning for Cross-Modal Retrieval',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1171681140,
          'name': 'Yiling Wu'
        },
        {
          'id': 1265461014,
          'name': 'Shuhui Wang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2009945168,
          'name': 'Hilbert space'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028025525,
          'name': 'Semantic similarity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2003425367,
          'name': 'Hinge loss'
        },
        {
          'id': 2012712064,
          'name': 'Similarity learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022299342,
          'name': 'Similarity heuristic'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 41
    },
    {
      'id': 362097026,
      'title': 'Specular Highlight Removal in Facial Images',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        }
      ],
      'author': [
        {
          'id': 1032681086,
          'name': 'Chen Li'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        },
        {
          'id': 1266662130,
          'name': 'Kun Zhou'
        },
        {
          'id': 1279361264,
          'name': 'Katsushi Ikeuchi'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021795247,
          'name': 'Removal techniques'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039767465,
          'name': 'Color calibration'
        },
        {
          'id': 2018889831,
          'name': 'Color balance'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2013822762,
          'name': 'Specular highlight'
        },
        {
          'id': 2043986729,
          'name': 'Color model'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 43
    },
    {
      'id': 363111624,
      'title': 'Quality Aware Network for Set to Set Recognition',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1058037770,
          'name': 'Yu Liu'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2012284111,
          'name': 'Quality Score'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 69,
      'reference_count': 39
    },
    {
      'id': 366331333,
      'title': 'Radiometric Calibration from Faces in Images',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        }
      ],
      'author': [
        {
          'id': 1032681086,
          'name': 'Chen Li'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        },
        {
          'id': 1266662130,
          'name': 'Kun Zhou'
        },
        {
          'id': 1279361264,
          'name': 'Katsushi Ikeuchi'
        }
      ],
      'field': [
        {
          'id': 2034023280,
          'name': 'Radiometric calibration'
        },
        {
          'id': 2003657012,
          'name': 'Color filter array'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026022629,
          'name': 'Radiometry'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022543674,
          'name': 'Albedo'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 370069839,
      'title': 'Mind the Class Weight Bias: Weighted Maximum Mean Discrepancy for Unsupervised Domain Adaptation',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1468980796,
          'name': 'Hongliang Yan'
        },
        {
          'id': 1088635186,
          'name': 'Yukang Ding'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1169123174,
          'name': 'Yong Xu'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2002073950,
          'name': 'Expectation–maximization algorithm'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Yan_Mind_the_Class_CVPR_2017_paper.pdf',
      'citation_count': 8,
      'reference_count': 41
    },
    {
      'id': 373026446,
      'title': 'Interspecies Knowledge Transfer for Facial Keypoint Detection',
      'affiliation': [
        {
          'id': 2100263420,
          'abbr': 'UCD',
          'name': 'University of California, Davis'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1162018460,
          'name': 'Maheen Rashid'
        },
        {
          'id': 1296753835,
          'name': 'Xiuye Gu'
        },
        {
          'id': 1150486798,
          'name': 'Yong Jae Lee'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036749526,
          'name': 'Thin plate spline'
        },
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 59
    },
    {
      'id': 373869043,
      'title': 'Physics Inspired Optimization on Semantic Transfer Features: An Alternative Method for Room Layout Estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1263201152,
          'name': 'Hao Zhao'
        },
        {
          'id': 1354266357,
          'name': 'Ming Lu'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1198053711,
          'name': 'Yiwen Guo'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1168982309,
          'name': 'Li Zhang'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 29
    },
    {
      'id': 378186744,
      'title': 'Self-Learning Scene-Specific Pedestrian Detectors Using a Progressive Latent Model',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1244795700,
          'name': 'Tianliang Zhang'
        },
        {
          'id': 1030826032,
          'name': 'Wei Ke'
        },
        {
          'id': 1246182727,
          'name': 'Qiang Qiu'
        },
        {
          'id': 1223927465,
          'name': 'Jie Chen'
        },
        {
          'id': 1180322365,
          'name': 'Guillermo Sapiro'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        }
      ],
      'field': [
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2001297348,
          'name': 'Enforcement'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 45
    },
    {
      'id': 388874448,
      'title': 'Attention-Aware Face Hallucination via Deep Reinforcement Learning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1224924548,
          'name': 'Qingxing Cao'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1350359073,
          'name': 'Yukai Shi'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 34
    },
    {
      'id': 391186497,
      'title': 'Deep Sketch Hashing: Fast Free-Hand Sketch-Based Image Retrieval',
      'affiliation': [
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1434568414,
          'name': 'Yuming Shen'
        },
        {
          'id': 1025575364,
          'name': 'Xianglong Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017552540,
          'name': 'Memory footprint'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 73
    },
    {
      'id': 392528628,
      'title': 'Locality-Sensitive Deconvolution Networks with Gated Fusion for RGB-D Indoor Semantic Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1487923076,
          'name': 'Yanhua Cheng'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1209252863,
          'name': 'Xin Zhao'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 29
    },
    {
      'id': 399704922,
      'title': 'Learning Spatial Regularization with Image-Level Supervisions for Multi-label Image Classification',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1472316569,
          'name': 'Feng Zhu'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2004563257,
          'name': 'Spatial relation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 44
    },
    {
      'id': 402957274,
      'title': 'A Matrix Splitting Method for Composite Function Minimization',
      'affiliation': [
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1123863574,
          'name': 'Ganzhao Yuan'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        }
      ],
      'field': [
        {
          'id': 2038091395,
          'name': 'Constrained optimization'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2002116501,
          'name': 'Cardinality'
        },
        {
          'id': 2000280526,
          'name': 'Symmetric matrix'
        },
        {
          'id': 2023706493,
          'name': 'Gaussian elimination'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046713073,
          'name': 'Non-negative matrix factorization'
        },
        {
          'id': 2011741409,
          'name': 'Rate of convergence'
        },
        {
          'id': 2012350712,
          'name': 'Matrix splitting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Yuan_A_Matrix_Splitting_CVPR_2017_paper.pdf',
      'citation_count': 0,
      'reference_count': 43
    },
    {
      'id': 406285335,
      'title': 'Awesome Typography: Statistics-Based Text Effects Transfer',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1023932251,
          'name': 'Shuai Yang'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1144931875,
          'name': 'Zhouhui Lian'
        },
        {
          'id': 1273935622,
          'name': 'Zongming Guo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2035849435,
          'name': 'Typography'
        },
        {
          'id': 2007469624,
          'name': 'Stylized fact'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040433204,
          'name': 'Texture synthesis'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2039761441,
          'name': 'Analytics'
        },
        {
          'id': 2003882441,
          'name': 'Spatial distribution'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 36
    },
    {
      'id': 408317281,
      'title': 'Consistent-Aware Deep Learning for Person Re-identification in a Camera Network',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1260768795,
          'name': 'Ji Lin'
        },
        {
          'id': 1219558905,
          'name': 'Liangliang Ren'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1361061781,
          'name': 'Jianjiang Feng'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2019146609,
          'name': 'Body images'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031569861,
          'name': 'Performance improvement'
        },
        {
          'id': 2005169804,
          'name': 'Optimal matching'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 44
    },
    {
      'id': 412723135,
      'title': 'Seeing into Darkness: Scotopic Visual Recognition',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102247394,
          'abbr': 'CIT',
          'name': 'California Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1115960749,
          'name': 'Bo Chen'
        },
        {
          'id': 1369706316,
          'name': 'Pietro Perona'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2016751983,
          'name': 'Asymptotically optimal algorithm'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2039174908,
          'name': 'Spiking neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2010152203,
          'name': 'Scotopic vision'
        },
        {
          'id': 2036679242,
          'name': 'Word error rate'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 53
    },
    {
      'id': 414181790,
      'title': 'See the Forest for the Trees: Joint Spatial and Temporal Recurrent Neural Networks for Video-Based Person Re-identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1075006884,
          'name': 'Zhen Zhou'
        },
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 64,
      'reference_count': 30
    },
    {
      'id': 418513261,
      'title': 'Revisiting Metric Learning for SPD Matrix Based Visual Representation',
      'affiliation': [
        {
          'id': 2103150234,
          'abbr': 'ITU',
          'name': 'Information Technology University'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1130844234,
          'name': 'Luping Zhou'
        },
        {
          'id': 1384396648,
          'name': 'Lei Wang'
        },
        {
          'id': 1384024699,
          'name': 'Jianjia Zhang'
        },
        {
          'id': 1357346833,
          'name': 'Yinghuan Shi'
        },
        {
          'id': 1253373124,
          'name': 'Yang Gao'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007931699,
          'name': 'Positive-definite matrix'
        },
        {
          'id': 2021743058,
          'name': 'Eigenvalues and eigenvectors'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2018284600,
          'name': 'Transformation matrix'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 29
    },
    {
      'id': 422108601,
      'title': 'Noisy Softmax: Improving the Generalization Ability of DCNN via Postponing the Early Softmax Saturation',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1261227821,
          'name': 'Binghui Chen'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1328787968,
          'name': 'Junping Du'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004009963,
          'name': 'Saturation (chemistry)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Chen_Noisy_Softmax_Improving_CVPR_2017_paper.pdf',
      'citation_count': 6,
      'reference_count': 49
    },
    {
      'id': 423565130,
      'title': 'Deep Visual-Semantic Quantization for Efficient Image Retrieval',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1027988051,
          'name': 'Yue Cao'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1170942169,
          'name': 'Shichen Liu'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 41
    },
    {
      'id': 424805540,
      'title': 'Delving Deep Into Coarse-to-Fine Framework for Facial Landmark Localization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1000990382,
          'name': 'Xi Chen'
        },
        {
          'id': 1083441339,
          'name': 'Erjin Zhou'
        },
        {
          'id': 1378319856,
          'name': 'Yuchen Mo'
        },
        {
          'id': 1007518009,
          'name': 'Jiancheng Liu'
        },
        {
          'id': 1454179856,
          'name': 'Zhimin Cao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2042592337,
          'name': 'Linear map'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 27
    },
    {
      'id': 425606718,
      'title': 'Low-Rank-Sparse Subspace Representation for Robust Regression',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1235033683,
          'name': 'Yongqiang Zhang'
        },
        {
          'id': 1214836917,
          'name': 'Daming Shi'
        },
        {
          'id': 1010384661,
          'name': 'Junbin Gao'
        },
        {
          'id': 1020639265,
          'name': 'Dansong Cheng'
        }
      ],
      'field': [
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2040449991,
          'name': 'Robust regression'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047147174,
          'name': 'Polynomial regression'
        },
        {
          'id': 2037601121,
          'name': 'Gaussian noise'
        },
        {
          'id': 2037758878,
          'name': 'Linear regression'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2037189603,
          'name': 'Proper linear model'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 40
    },
    {
      'id': 425684849,
      'title': 'Instance-Aware Image and Sentence Matching with Selective Multimodal LSTM',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 41
    },
    {
      'id': 425824861,
      'title': 'Scalable Person Re-identification on Supervised Smoothed Manifold',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014248115,
          'name': 'Time complexity'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 92,
      'reference_count': 63
    },
    {
      'id': 427449311,
      'title': 'Deep Level Sets for Salient Object Detection',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1007415920,
          'name': 'Ping Hu'
        },
        {
          'id': 1223415641,
          'name': 'Bing Shuai'
        },
        {
          'id': 1279547932,
          'name': 'Jun Liu'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 58
    },
    {
      'id': 42763428,
      'title': 'Turning an Urban Scene Video into a Cinemagraph',
      'affiliation': [
        {
          'id': 2101817535,
          'abbr': 'WUSTL',
          'name': 'Washington University in St. Louis'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1355022357,
          'name': 'Hang Yan'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1376112669,
          'name': 'Yasutaka Furukawa'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2024371758,
          'name': 'Vehicle dynamics'
        },
        {
          'id': 2026679767,
          'name': 'Animation'
        },
        {
          'id': 2025153043,
          'name': 'Video capture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2000499954,
          'name': 'Geometric modeling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Yan_Turning_an_Urban_CVPR_2017_paper.pdf',
      'citation_count': 0,
      'reference_count': 27
    },
    {
      'id': 430602292,
      'title': 'From Zero-Shot Learning to Conventional Supervised Classification: Unseen Visual Data Synthesis',
      'affiliation': [
        {
          'id': 2101550415,
          'abbr': 'SHEFFIELD',
          'name': 'University of Sheffield'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102005978,
          'abbr': 'NORTHUMBRIA',
          'name': 'Northumbria University'
        }
      ],
      'author': [
        {
          'id': 1447610016,
          'name': 'Yang Long'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1153306339,
          'name': 'Jungong Han'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 54
    },
    {
      'id': 432502662,
      'title': 'Learning Deep Context-Aware Features over Body and Latent Parts for Person Re-identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1229829139,
          'name': 'Dangwei Li'
        },
        {
          'id': 1055699222,
          'name': 'Xiaotang Chen'
        },
        {
          'id': 1070491497,
          'name': 'Zhang Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 143,
      'reference_count': 56
    },
    {
      'id': 434071673,
      'title': 'Large Margin Object Tracking with Circulant Feature Maps',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1194736908,
          'name': 'Mengmeng Wang'
        },
        {
          'id': 1444675629,
          'name': 'Yong Liu'
        },
        {
          'id': 1400887066,
          'name': 'Zeyi Huang'
        }
      ],
      'field': [
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2034116922,
          'name': 'Algorithm design'
        },
        {
          'id': 2048841513,
          'name': 'Background noise'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017318404,
          'name': 'Frame rate'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Wang_Large_Margin_Object_CVPR_2017_paper.pdf',
      'citation_count': 34,
      'reference_count': 34
    },
    {
      'id': 434283120,
      'title': 'Latent Multi-view Subspace Clustering',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        }
      ],
      'author': [
        {
          'id': 1179117743,
          'name': 'Changqing Zhang'
        },
        {
          'id': 1180699841,
          'name': 'Qinghua Hu'
        },
        {
          'id': 1357204816,
          'name': 'Huazhu Fu'
        },
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2037814540,
          'name': 'Data stream clustering'
        },
        {
          'id': 2031194188,
          'name': 'Constrained clustering'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030753631,
          'name': 'Probabilistic latent semantic analysis'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2001604607,
          'name': 'Canopy clustering algorithm'
        },
        {
          'id': 2035951257,
          'name': 'CURE data clustering algorithm'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 31
    },
    {
      'id': 436176005,
      'title': 'Spatio-Temporal Self-Organizing Map Deep Network for Dynamic Object Detection from Videos',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104876881,
          'abbr': 'BBK',
          'name': 'Birkbeck, University of London'
        }
      ],
      'author': [
        {
          'id': 1198092842,
          'name': 'Yang Du'
        },
        {
          'id': 1215326337,
          'name': 'Chunfeng Yuan'
        },
        {
          'id': 1138746327,
          'name': 'Bing Li'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1242761004,
          'name': 'Stephen J. Maybank'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023578727,
          'name': 'Self-organizing map'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2017575560,
          'name': 'Estimation theory'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025715469,
          'name': 'Background subtraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 29
    },
    {
      'id': 436792219,
      'title': 'Oriented Response Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1238435354,
          'name': 'Yanzhao Zhou'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1246182727,
          'name': 'Qiang Qiu'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034325238,
          'name': 'Filter bank'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048190627,
          'name': 'Active filter'
        },
        {
          'id': 2033490411,
          'name': 'Object-orientation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 44
    },
    {
      'id': 437579866,
      'title': 'Image Super-Resolution via Deep Recursive Residual Network',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1178629966,
          'name': 'Ying Tai'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        },
        {
          'id': 1304173056,
          'name': 'Xiaoming Liu'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 251,
      'reference_count': 38
    },
    {
      'id': 437634334,
      'title': 'Learning Deep Context-Aware Features over Body and Latent Parts for Person Re-identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1229829139,
          'name': 'Dangwei Li'
        },
        {
          'id': 1055699222,
          'name': 'Xiaotang Chen'
        },
        {
          'id': 1070491497,
          'name': 'Zhang Zhang'
        },
        {
          'id': 1089529478,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1710.06555.pdf',
      'citation_count': 55,
      'reference_count': 55
    },
    {
      'id': 438498839,
      'title': 'ER3: A Unified Framework for Event Retrieval, Recognition and Recounting',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1189196498,
          'name': 'Zhanning Gao'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1413479175,
          'name': 'Dongqing Zhang'
        },
        {
          'id': 1395986119,
          'name': 'Nebojsa Jojic'
        },
        {
          'id': 1238522754,
          'name': 'Le Wang'
        },
        {
          'id': 1221299331,
          'name': 'Jianru Xue'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2049525942,
          'name': 'Language model'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 45
    },
    {
      'id': 444473761,
      'title': 'StyleNet: Generating Attractive Visual Captions with Styles',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1254855809,
          'name': 'Zhe Gan'
        },
        {
          'id': 1103020618,
          'name': 'Xiaodong He'
        },
        {
          'id': 1323535504,
          'name': 'Jianfeng Gao'
        },
        {
          'id': 1166776264,
          'name': 'Li Deng'
        }
      ],
      'field': [
        {
          'id': 2042484654,
          'name': 'Text corpus'
        },
        {
          'id': 2025677606,
          'name': 'Paired Data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007469624,
          'name': 'Stylized fact'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 55,
      'reference_count': 53
    },
    {
      'id': 445836661,
      'title': 'Semantic Segmentation via Structured Patch Prediction, Context CRF and Guidance CRF',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1187588030,
          'name': 'Falong Shen'
        },
        {
          'id': 1311627122,
          'name': 'Rui Gan'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 22
    },
    {
      'id': 446240658,
      'title': 'Learning to Rank Retargeted Images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1030300495,
          'name': 'Yang Chen'
        },
        {
          'id': 1212426579,
          'name': 'Yong-Jin Liu'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2028160375,
          'name': 'Seam carving'
        },
        {
          'id': 2017212264,
          'name': 'General regression neural network'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042430043,
          'name': 'Retargeting'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020482756,
          'name': 'Generalizability theory'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 28
    },
    {
      'id': 447040792,
      'title': 'Oriented Response Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1238435354,
          'name': 'Yanzhao Zhou'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1246182727,
          'name': 'Qiang Qiu'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034325238,
          'name': 'Filter bank'
        },
        {
          'id': 2043909659,
          'name': 'residual neural network'
        },
        {
          'id': 2033490411,
          'name': 'Object-orientation'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048190627,
          'name': 'Active filter'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Zhou_Oriented_Response_Networks_CVPR_2017_paper.pdf',
      'citation_count': 17,
      'reference_count': 45
    },
    {
      'id': 448811225,
      'title': 'Learning Deep Binary Descriptor with Multi-quantization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1495536695,
          'name': 'Yueqi Duan'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1499286484,
          'name': 'Ziwei Wang'
        },
        {
          'id': 1361061781,
          'name': 'Jianjiang Feng'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 49
    },
    {
      'id': 45265387,
      'title': 'Deep Crisp Boundaries',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101481594,
          'abbr': 'QUALCOMM',
          'name': 'Qualcomm'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1038495456,
          'name': 'Yupei Wang'
        },
        {
          'id': 1132894950,
          'name': 'Xin Zhao'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 36
    },
    {
      'id': 452787036,
      'title': 'GMS: Grid-Based Motion Statistics for Fast, Ultra-Robust Feature Correspondence',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        },
        {
          'id': 2103675905,
          'abbr': 'OSAKA-U',
          'name': 'Osaka University'
        },
        {
          'id': 2101979648,
          'abbr': 'SUTD',
          'name': 'Singapore University of Technology and Design'
        }
      ],
      'author': [
        {
          'id': 1405162603,
          'name': 'JiaWang Bian'
        },
        {
          'id': 1008180239,
          'name': 'Wen-Yan Lin'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        },
        {
          'id': 1022309053,
          'name': 'Sai-Kit Yeung'
        },
        {
          'id': 1100118097,
          'name': 'Tan Dat Nguyen'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010096043,
          'name': 'Parity (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041433144,
          'name': 'Grid'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 43,
      'reference_count': 42
    },
    {
      'id': 453618408,
      'title': 'A Graph Regularized Deep Neural Network for Unsupervised Image Representation Learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1159899723,
          'name': 'Shijie Yang'
        },
        {
          'id': 1266097533,
          'name': 'Liang Li'
        },
        {
          'id': 1265461014,
          'name': 'Shuhui Wang'
        },
        {
          'id': 1354296024,
          'name': 'Weigang Zhang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020446020,
          'name': 'Random geometric graph'
        },
        {
          'id': 2045501904,
          'name': 'Bipartite graph'
        },
        {
          'id': 2037132101,
          'name': 'Tangent space'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011268437,
          'name': 'Network complexity'
        },
        {
          'id': 2034388435,
          'name': 'Laplacian matrix'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 21
    },
    {
      'id': 454778246,
      'title': 'Parametric T-Spline Face Morphable Model for Detailed Fitting in Shape Subspace',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1052231697,
          'name': 'Weilong Peng'
        },
        {
          'id': 1456863924,
          'name': 'Zhiyong Feng'
        },
        {
          'id': 1120296233,
          'name': 'Chao Xu'
        },
        {
          'id': 1492886059,
          'name': 'Yong Su'
        }
      ],
      'field': [
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2011285792,
          'name': 'T-spline'
        },
        {
          'id': 2021724463,
          'name': 'Missing data'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2012955293,
          'name': 'Facial Action Coding System'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007597257,
          'name': 'Morphing'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 26
    },
    {
      'id': 455977078,
      'title': 'Learning Fully Convolutional Networks for Iterative Non-blind Deconvolution',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1346819320,
          'name': 'Jiawei Zhang'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1477719517,
          'name': 'Wei-Sheng Lai'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045758093,
          'name': 'Blind deconvolution'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2047056776,
          'name': 'Richardson–Lucy deconvolution'
        },
        {
          'id': 2016673644,
          'name': 'Wiener deconvolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 39
    },
    {
      'id': 46008585,
      'title': 'Binarized Mode Seeking for Scalable Visual Pattern Discovery',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1301283836,
          'name': 'Wei Zhang'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1017800361,
          'name': 'Rui Wang'
        },
        {
          'id': 1064639179,
          'name': 'Yuanfang Guo'
        },
        {
          'id': 1461919326,
          'name': 'Zhineng Chen'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2005808282,
          'name': 'Binary constraint'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004101032,
          'name': 'Euclidean space'
        },
        {
          'id': 2049895596,
          'name': 'Mean-shift'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 30
    },
    {
      'id': 460861593,
      'title': 'Fast Person Re-identification via Cross-Camera Semantic Binary Transformation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        }
      ],
      'author': [
        {
          'id': 1058742151,
          'name': 'Jiaxin Chen'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1478991474,
          'name': 'Jie Qin'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 59
    },
    {
      'id': 462275276,
      'title': 'Multi-task Correlation Particle Filter for Robust Object Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027479703,
          'name': 'Particle'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2001815435,
          'name': 'Control theory'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 97,
      'reference_count': 47
    },
    {
      'id': 471698938,
      'title': 'What is and What is Not a Salient Object? Learning Salient Object Detector by Ensembling Linear Exemplar Regressors',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1376919782,
          'name': 'Changqun Xia'
        },
        {
          'id': 1149258320,
          'name': 'Jia Li'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1327455568,
          'name': 'Anlin Zheng'
        },
        {
          'id': 1088663906,
          'name': 'Yu Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 42
    },
    {
      'id': 476889980,
      'title': 'SphereFace: Deep Hypersphere Embedding for Face Recognition',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1371439823,
          'name': 'Weiyang Liu'
        },
        {
          'id': 1369857672,
          'name': 'Yandong Wen'
        },
        {
          'id': 1096488246,
          'name': 'Zhiding Yu'
        },
        {
          'id': 1392521294,
          'name': 'Ming Li'
        },
        {
          'id': 1413245183,
          'name': 'Bhiksha Raj'
        },
        {
          'id': 1399541633,
          'name': 'Le Song'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2010150933,
          'name': 'Metric space'
        },
        {
          'id': 2003915252,
          'name': 'Hypersphere'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 227,
      'reference_count': 35
    },
    {
      'id': 477053554,
      'title': 'Instance-Level Salient Object Segmentation',
      'affiliation': [
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1370339927,
          'name': 'Yuan Xie'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1485462380,
          'name': 'Yizhou Yu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 58,
      'reference_count': 55
    },
    {
      'id': 478265976,
      'title': 'Interpretable Structure-Evolving LSTM',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1192263557,
          'name': 'Eric P. Xing'
        }
      ],
      'field': [
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2009246595,
          'name': 'External Data Representation'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2035780509,
          'name': 'Small data'
        },
        {
          'id': 2040504686,
          'name': 'Graph (abstract data type)'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 35
    },
    {
      'id': 480976189,
      'title': 'Learning Dynamic Guidance for Depth Image Enhancement',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102405609,
          'abbr': 'TUGRAZ',
          'name': 'Graz University of Technology'
        }
      ],
      'author': [
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1001794751,
          'name': 'Shi Guo'
        },
        {
          'id': 1355557695,
          'name': 'Yunjin Chen'
        },
        {
          'id': 1249487282,
          'name': 'Chongyu Chen'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027402901,
          'name': 'Weight function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 38
    },
    {
      'id': 481720359,
      'title': 'Robust Energy Minimization for BRDF-Invariant Shape from Light Fields',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        }
      ],
      'author': [
        {
          'id': 1475971673,
          'name': 'Zhengqin Li'
        },
        {
          'id': 1305218646,
          'name': 'Zexiang Xu'
        },
        {
          'id': 1068541687,
          'name': 'Ravi Ramamoorthi'
        },
        {
          'id': 1258594675,
          'name': 'Manmohan Chandraker'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2032112486,
          'name': 'Microlens'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2016177128,
          'name': 'Bidirectional reflectance distribution function'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 28
    },
    {
      'id': 483689485,
      'title': 'Deep Feature Flow for Video Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1368545847,
          'name': 'Xizhou Zhu'
        },
        {
          'id': 1027853302,
          'name': 'Yuwen Xiong'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 69,
      'reference_count': 45
    },
    {
      'id': 485693304,
      'title': 'Exclusivity-Consistency Regularized Multi-view Subspace Clustering',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1080319340,
          'name': 'Xiaobo Wang'
        },
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1179117743,
          'name': 'Changqing Zhang'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2014337830,
          'name': 'Fuzzy clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2031194188,
          'name': 'Constrained clustering'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2031837788,
          'name': 'Complementarity (molecular biology)'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 29
    },
    {
      'id': 487047556,
      'title': 'Joint Detection and Identification Feature Learning for Person Search',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1378080271,
          'name': 'Tong Xiao'
        },
        {
          'id': 1015191394,
          'name': 'Shuang Li'
        },
        {
          'id': 1277327641,
          'name': 'Bochao Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 128,
      'reference_count': 52
    },
    {
      'id': 489980190,
      'title': 'Teaching Compositionality to CNNs',
      'affiliation': [
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2102440343,
          'abbr': 'MPG',
          'name': 'Max Planck Society'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1411635539,
          'name': 'Austin Stone'
        },
        {
          'id': 1200492232,
          'name': 'Huayan Wang'
        },
        {
          'id': 1446053876,
          'name': 'Michael Stark'
        },
        {
          'id': 1269799452,
          'name': 'Yi Liu'
        },
        {
          'id': 1002533480,
          'name': 'D. Scott Phoenix'
        },
        {
          'id': 1055309300,
          'name': 'Dileep George'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031202758,
          'name': 'Principle of compositionality'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 50
    },
    {
      'id': 495597288,
      'title': 'Position Determines Perspective: Investigating Perspective Distortion for Image Forensics of Faces',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1283298094,
          'name': 'Bo Peng'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1061773451,
          'name': 'Jing Dong'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2001363999,
          'name': 'Perspective distortion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017575560,
          'name': 'Estimation theory'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 19
    },
    {
      'id': 496142998,
      'title': 'Deep Spatial-Temporal Fusion Network for Video-Based Person Re-identification',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1007058294,
          'name': 'Lin Chen'
        },
        {
          'id': 1061593427,
          'name': 'Hua Yang'
        },
        {
          'id': 1216109597,
          'name': 'Ji Zhu'
        },
        {
          'id': 1005486571,
          'name': 'Qin Zhou'
        },
        {
          'id': 1128763129,
          'name': 'Shuang Wu'
        },
        {
          'id': 1278281228,
          'name': 'Zhiyong Gao'
        }
      ],
      'field': [
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 23
    },
    {
      'id': 496865244,
      'title': 'Scale-Aware Face Detection',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1415582824,
          'name': 'Zekun Hao'
        },
        {
          'id': 1260288408,
          'name': 'Yu Liu'
        },
        {
          'id': 1436337105,
          'name': 'Hongwei Qin'
        },
        {
          'id': 1010547470,
          'name': 'Junjie Yan'
        },
        {
          'id': 1297620460,
          'name': 'Xiu Li'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001048872,
          'name': 'Zoom'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Hao_Scale-Aware_Face_Detection_CVPR_2017_paper.pdf',
      'citation_count': 8,
      'reference_count': 40
    },
    {
      'id': 50045063,
      'title': 'Multiple Instance Detection Network with Online Instance Classifier Refinement',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1289566418,
          'name': 'Peng Tang'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 50,
      'reference_count': 38
    },
    {
      'id': 50517761,
      'title': 'Fast, Accurate Thin-Structure Obstacle Detection for Autonomous Mobile Robots',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1012603219,
          'name': 'Chen Zhou'
        },
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1288101998,
          'name': 'Chunshui Zhao'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006102683,
          'name': 'Obstacle'
        },
        {
          'id': 2035788587,
          'name': 'Inertial measurement unit'
        },
        {
          'id': 2031597428,
          'name': 'Sonar'
        },
        {
          'id': 2006989132,
          'name': 'Stereo camera'
        },
        {
          'id': 2000376905,
          'name': 'Visual odometry'
        },
        {
          'id': 2044077131,
          'name': 'Stereo cameras'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045951359,
          'name': 'Mobile robot'
        },
        {
          'id': 2021183717,
          'name': 'Simultaneous localization and mapping'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017_workshops/w4/papers/Zhou_Fast_Accurate_Thin-Structure_CVPR_2017_paper.pdf',
      'citation_count': 1,
      'reference_count': 40
    },
    {
      'id': 50684186,
      'title': 'EAST: An Efficient and Accurate Scene Text Detector',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1095461971,
          'name': 'Xinyu Zhou'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1103027098,
          'name': 'He Wen'
        },
        {
          'id': 1410454849,
          'name': 'Yuzhi Wang'
        },
        {
          'id': 1486783328,
          'name': 'Shuchang Zhou'
        },
        {
          'id': 1481318005,
          'name': 'Weiran He'
        },
        {
          'id': 1488383778,
          'name': 'Jiajun Liang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028120887,
          'name': 'Quadrilateral'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 133,
      'reference_count': 51
    },
    {
      'id': 52358589,
      'title': 'Recurrent Modeling of Interaction Context for Collective Activity Recognition',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1112955314,
          'name': 'Minsi Wang'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 36
    },
    {
      'id': 618212,
      'title': 'Zero-Shot Recognition Using Dual Visual-Semantic Mapping Paths',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1087982069,
          'name': 'Yanan Li'
        },
        {
          'id': 1286482841,
          'name': 'Donghui Wang'
        },
        {
          'id': 1487389997,
          'name': 'Huanhang Hu'
        },
        {
          'id': 1411857157,
          'name': 'Yuetan Lin'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011729194,
          'name': 'Semantic memory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2007914467,
          'name': 'Semantic mapping'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 39
    },
    {
      'id': 68249289,
      'title': 'Image Deblurring via Extreme Channels Prior',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1376555919,
          'name': 'Yanyang Yan'
        },
        {
          'id': 1224361079,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1064639179,
          'name': 'Yuanfang Guo'
        },
        {
          'id': 1017800361,
          'name': 'Rui Wang'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2008582367,
          'name': 'Phenomenon'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 26
    },
    {
      'id': 70244418,
      'title': 'Enhancing Detection Model for Multiple Hypothesis Tracking',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1439838183,
          'name': 'Jiahui Chen'
        },
        {
          'id': 1352251956,
          'name': 'Hao Sheng'
        },
        {
          'id': 1460099239,
          'name': 'Yang Zhang'
        },
        {
          'id': 1176567166,
          'name': 'Zhang Xiong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 29
    },
    {
      'id': 73449557,
      'title': 'SphereFace: Deep Hypersphere Embedding for Face Recognition',
      'affiliation': [
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1371439823,
          'name': 'Weiyang Liu'
        },
        {
          'id': 1100402541,
          'name': 'Yandong Wen'
        },
        {
          'id': 1096488246,
          'name': 'Zhiding Yu'
        },
        {
          'id': 1392521294,
          'name': 'Ming Li'
        },
        {
          'id': 1413245183,
          'name': 'Bhiksha Raj'
        },
        {
          'id': 1399541633,
          'name': 'Le Song'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2010150933,
          'name': 'Metric space'
        },
        {
          'id': 2003915252,
          'name': 'Hypersphere'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Liu_SphereFace_Deep_Hypersphere_CVPR_2017_paper.pdf',
      'citation_count': 103,
      'reference_count': 39
    },
    {
      'id': 74244545,
      'title': 'HSfM: Hybrid Structure-from-Motion',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1298583001,
          'name': 'Hainan Cui'
        },
        {
          'id': 1432038680,
          'name': 'Xiang Gao'
        },
        {
          'id': 1364720438,
          'name': 'Shuhan Shen'
        },
        {
          'id': 1420413515,
          'name': 'Zhanyi Hu'
        }
      ],
      'field': [
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2020539549,
          'name': 'Epipolar geometry'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 44
    },
    {
      'id': 76866717,
      'title': 'Incremental Kernel Null Space Discriminant Analysis for Novelty Detection',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1301308996,
          'name': 'Juncheng Liu'
        },
        {
          'id': 1144931875,
          'name': 'Zhouhui Lian'
        },
        {
          'id': 1014321616,
          'name': 'Yi Wang'
        },
        {
          'id': 1135263000,
          'name': 'Jianguo Xiao'
        }
      ],
      'field': [
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025986945,
          'name': 'Novelty detection'
        },
        {
          'id': 2034116922,
          'name': 'Algorithm design'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 24
    },
    {
      'id': 78179355,
      'title': 'Weakly Supervised Dense Video Captioning',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1316790742,
          'name': 'Zhiqiang Shen'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        },
        {
          'id': 1332823866,
          'name': 'Zhou Su'
        },
        {
          'id': 1214099888,
          'name': 'Minjun Li'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044171927,
          'name': 'Submodular set function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2049525942,
          'name': 'Language model'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 58
    },
    {
      'id': 78461345,
      'title': 'Removing Rain from Single Images via a Deep Detail Network',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1083964890,
          'name': 'Xueyang Fu'
        },
        {
          'id': 1071127001,
          'name': 'Jiabin Huang'
        },
        {
          'id': 1047270814,
          'name': 'Delu Zeng'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        },
        {
          'id': 1005874265,
          'name': 'John W. Paisley'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 90,
      'reference_count': 38
    },
    {
      'id': 80155217,
      'title': 'Fractal Dimension Invariant Filtering and Its CNN-Based Implementation',
      'affiliation': [
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2100414475,
          'abbr': 'ECNU',
          'name': 'East China Normal University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1122096088,
          'name': 'Hongteng Xu'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1001420415,
          'name': 'Nils Persson'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        }
      ],
      'field': [
        {
          'id': 2036526479,
          'name': 'Fractal analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037604943,
          'name': 'Fractal compression'
        },
        {
          'id': 2041448749,
          'name': 'Fractal transform'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2047705629,
          'name': 'Fractal landscape'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2039954432,
          'name': 'Fractal dimension'
        },
        {
          'id': 2040085207,
          'name': 'Anisotropic filtering'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2004631569,
          'name': 'Fractal'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 51
    },
    {
      'id': 83561896,
      'title': 'Zero-Shot Action Recognition with Error-Correcting Output Codes',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1478991474,
          'name': 'Jie Qin'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1058742151,
          'name': 'Jiaxin Chen'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045022011,
          'name': 'Data structure'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 63
    },
    {
      'id': 83838046,
      'title': 'Fixed-Point Factorized Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1261785545,
          'name': 'Peisong Wang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017543523,
          'name': 'Fixed point'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 32
    },
    {
      'id': 87474768,
      'title': 'Densely Connected Convolutional Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102294146,
          'abbr': 'TUDELFT',
          'name': 'Delft University of Technology'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        }
      ],
      'author': [
        {
          'id': 1366951731,
          'name': 'Gao Huang'
        },
        {
          'id': 1434676857,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1483427321,
          'name': 'Laurens van der Maaten'
        },
        {
          'id': 1159231599,
          'name': 'Kilian Q. Weinberger'
        }
      ],
      'field': [
        {
          'id': 2039238611,
          'name': 'Reuse'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2727,
      'reference_count': 43
    },
    {
      'id': 87875345,
      'title': 'Age Estimation Guided Convolutional Neural Network for Age-Invariant Face Recognition',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1198065715,
          'name': 'Tianyue Zheng'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1481827642,
          'name': 'Jiani Hu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 26
    },
    {
      'id': 92049306,
      'title': 'Classification and Retrieval of Digital Pathology Scans: A New Dataset',
      'affiliation': [
        {
          'id': 2103632264,
          'abbr': 'UW',
          'name': 'University of Waterloo'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2103850856,
          'abbr': 'DEAKIN',
          'name': 'Deakin University'
        },
        {
          'id': 2102694495,
          'abbr': 'UOIT',
          'name': 'University of Ontario Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1066676037,
          'name': 'Morteza Babaie'
        },
        {
          'id': 1406017157,
          'name': 'Shivam Kalra'
        },
        {
          'id': 1421684851,
          'name': 'Aditya Sriram'
        },
        {
          'id': 1422442441,
          'name': 'Christopher Mitcheltree'
        },
        {
          'id': 1095350503,
          'name': 'Shujin Zhu'
        },
        {
          'id': 1252904306,
          'name': 'Amin Khatami'
        },
        {
          'id': 1032246392,
          'name': 'Shahryar Rahnamayan'
        },
        {
          'id': 1306557819,
          'name': 'Hamid R. Tizhoosh'
        }
      ],
      'field': [
        {
          'id': 2038956318,
          'name': 'Medical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010269555,
          'name': 'Digital pathology'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2034116922,
          'name': 'Algorithm design'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017_workshops/w8/html/Babaie_Classification_and_Retrieval_CVPR_2017_paper.html',
      'citation_count': 1,
      'reference_count': 40
    },
    {
      'id': 94699496,
      'title': 'Fast, Accurate Thin-Structure Obstacle Detection for Autonomous Mobile Robots',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1012603219,
          'name': 'Chen Zhou'
        },
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1288101998,
          'name': 'Chunshui Zhao'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2045951359,
          'name': 'Mobile robot'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044077131,
          'name': 'Stereo cameras'
        },
        {
          'id': 2035788587,
          'name': 'Inertial measurement unit'
        },
        {
          'id': 2000376905,
          'name': 'Visual odometry'
        },
        {
          'id': 2006102683,
          'name': 'Obstacle'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031597428,
          'name': 'Sonar'
        },
        {
          'id': 2021183717,
          'name': 'Simultaneous localization and mapping'
        },
        {
          'id': 2006989132,
          'name': 'Stereo camera'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 40
    },
    {
      'id': 96293536,
      'title': 'Joint Detection and Identification Feature Learning for Person Search',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1006475640,
          'name': 'Tong Xiao'
        },
        {
          'id': 1015191394,
          'name': 'Shuang Li'
        },
        {
          'id': 1277327641,
          'name': 'Bochao Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2017/papers/Xiao_Joint_Detection_and_CVPR_2017_paper.pdf',
      'citation_count': 41,
      'reference_count': 46
    },
    {
      'id': 98453917,
      'title': 'Detecting Masked Faces in the Wild with LLE-CNNs',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1324002851,
          'name': 'Shiming Ge'
        },
        {
          'id': 1000328478,
          'name': 'Jia Li'
        },
        {
          'id': 1151763356,
          'name': 'Qiting Ye'
        },
        {
          'id': 1386252190,
          'name': 'Zhao Luo'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133767550',
        'name': 'CVPR',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 37
    },
    {
      'id': 106077236,
      'title': 'Joint Multiview Segmentation and Localization of RGB-D Images Using Depth-Induced Silhouette Consistency',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1318990977,
          'name': 'Chi Zhang'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2038172366,
          'name': 'Silhouette'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2002093614,
          'name': 'On the fly'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 28
    },
    {
      'id': 110399698,
      'title': 'Tensor Power Iteration for Multi-graph Matching',
      'affiliation': [
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1408306263,
          'name': 'Xinchu Shi'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        }
      ],
      'field': [
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2011548508,
          'name': 'Unary operation'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2045501904,
          'name': 'Bipartite graph'
        },
        {
          'id': 2025690529,
          'name': 'Power iteration'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 37
    },
    {
      'id': 11205774,
      'title': 'Real-Time Salient Object Detection with a Minimum Spanning Tree',
      'affiliation': [
        {
          'id': 2101276577,
          'abbr': 'NTU',
          'name': 'National Taiwan University'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1494390522,
          'name': 'Wei-Chih Tu'
        },
        {
          'id': 1301454973,
          'name': 'Shengfeng He'
        },
        {
          'id': 1324299713,
          'name': 'Qingxiong Yang'
        },
        {
          'id': 1041967776,
          'name': 'Shao-Yi Chien'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014243555,
          'name': 'Minimum spanning tree-based segmentation'
        },
        {
          'id': 2027472525,
          'name': 'Economic shortage'
        },
        {
          'id': 2018186560,
          'name': 'Distance transform'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2012923248,
          'name': 'Minimum spanning tree'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 77,
      'reference_count': 34
    },
    {
      'id': 113841535,
      'title': 'Dictionary Pair Classifier Driven Convolutional Neural Networks for Object Detection',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 43
    },
    {
      'id': 114948017,
      'title': 'Multi-oriented Text Detection with Fully Convolutional Networks',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        }
      ],
      'author': [
        {
          'id': 1377460166,
          'name': 'Zheng Zhang'
        },
        {
          'id': 1023273385,
          'name': 'Chengquan Zhang'
        },
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2044405812,
          'name': 'Centroid'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 151,
      'reference_count': 35
    },
    {
      'id': 1173964,
      'title': 'Cluster Sensing Superpixel and Grouping',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1004883557,
          'name': 'Rui Li'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        }
      ],
      'field': [
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033525226,
          'name': 'Computational problem'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045501904,
          'name': 'Bipartite graph'
        },
        {
          'id': 2012456598,
          'name': 'Pixel density'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 25
    },
    {
      'id': 13125568,
      'title': 'GIFT: A Real-Time and Scalable 3D Shape Search Engine',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        }
      ],
      'author': [
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1164775868,
          'name': 'Zhichao Zhou'
        },
        {
          'id': 1376978113,
          'name': 'Zhaoxiang Zhang'
        },
        {
          'id': 1358872243,
          'name': 'Longin Jan Latecki'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2013841216,
          'name': 'Acceleration'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2039166637,
          'name': 'Inverted index'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 73,
      'reference_count': 45
    },
    {
      'id': 131911724,
      'title': 'Soft-Segmentation Guided Object Motion Deblurring',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        }
      ],
      'author': [
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1240803967,
          'name': 'Zhe Hu'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        },
        {
          'id': 1407814657,
          'name': 'Hsin-Ying Lee'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 51
    },
    {
      'id': 133061752,
      'title': 'CRAFT Objects from Images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1003624645,
          'name': 'Bin Yang'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1007099668,
          'name': 'Zhen Lei'
        },
        {
          'id': 1028398168,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2040590228,
          'name': 'False positive paradox'
        },
        {
          'id': 2019556046,
          'name': 'Divide and conquer algorithms'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        },
        {
          'id': 2042828818,
          'name': 'Division (mathematics)'
        },
        {
          'id': 2021273121,
          'name': 'Craft'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://arxiv.org/pdf/1604.03239.pdf',
      'citation_count': 9,
      'reference_count': 38
    },
    {
      'id': 13571350,
      'title': '6D Dynamic Camera Relocalization from Single Reference Image',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1096901309,
          'name': 'Wei Feng'
        },
        {
          'id': 1351293465,
          'name': 'Fei-Peng Tian'
        },
        {
          'id': 1431546386,
          'name': 'Qian Zhang'
        },
        {
          'id': 1241260093,
          'name': 'Jizhou Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011741409,
          'name': 'Rate of convergence'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001815435,
          'name': 'Control theory'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2011767737,
          'name': 'Bounded function'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 35
    },
    {
      'id': 138526622,
      'title': 'Supervised Quantization for Similarity Search',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1082779436,
          'name': 'Xiaojuan Wang'
        },
        {
          'id': 1009100021,
          'name': 'Ting Zhang'
        },
        {
          'id': 1240277240,
          'name': 'Guo-Jun Qi'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2039464793,
          'name': 'Quantization (physics)'
        },
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2029735533,
          'name': 'Data point'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 48
    },
    {
      'id': 14008735,
      'title': 'RIFD-CNN: Rotation-Invariant and Fisher Discriminative Convolutional Neural Networks for Object Detection',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1160298449,
          'name': 'Gong Cheng'
        },
        {
          'id': 1442460926,
          'name': 'Peicheng Zhou'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        }
      ],
      'field': [
        {
          'id': 2003337676,
          'name': 'Aerial image'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2044093663,
          'name': 'Fisher kernel'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 54
    },
    {
      'id': 141534679,
      'title': 'Monocular Long-Term Target Following on UAVs',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1004883557,
          'name': 'Rui Li'
        },
        {
          'id': 1129765098,
          'name': 'Minjian Pang'
        },
        {
          'id': 1391835685,
          'name': 'Cong Zhao'
        },
        {
          'id': 1249889488,
          'name': 'Guyue Zhou'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        }
      ],
      'field': [
        {
          'id': 2029148409,
          'name': 'Brute-force search'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 40
    },
    {
      'id': 144122268,
      'title': 'Monocular 3D Object Detection for Autonomous Driving',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        }
      ],
      'author': [
        {
          'id': 1331893764,
          'name': 'Xiaozhi Chen'
        },
        {
          'id': 1062043537,
          'name': 'Kaustav Kundu'
        },
        {
          'id': 1337029974,
          'name': 'Ziyu Zhang'
        },
        {
          'id': 1144842077,
          'name': 'Huimin Ma'
        },
        {
          'id': 1352379588,
          'name': 'Sanja Fidler'
        },
        {
          'id': 1174742793,
          'name': 'Raquel Urtasun'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2045926880,
          'name': 'Image plane'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2007665589,
          'name': 'Competitor analysis'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 172,
      'reference_count': 54
    },
    {
      'id': 153234773,
      'title': 'Object Skeleton Extraction in Natural Images by Fusing Scale-Associated Deep Side Outputs',
      'affiliation': [
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1463521460,
          'name': 'Kai Zhao'
        },
        {
          'id': 1428608778,
          'name': 'Yuan Jiang'
        },
        {
          'id': 1264789363,
          'name': 'Yan Wang'
        },
        {
          'id': 1123343745,
          'name': 'Zhijiang Zhang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2016/html/Shen_Object_Skeleton_Extraction_CVPR_2016_paper.html',
      'citation_count': 4,
      'reference_count': 35
    },
    {
      'id': 15418364,
      'title': 'You Lead, We Exceed: Labor-Free Video Concept Learning by Jointly Exploiting Web Videos and Images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1047940084,
          'name': 'Kuiyuan Yang'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2041368339,
          'name': 'Concept learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019103014,
          'name': 'Trim'
        },
        {
          'id': 2013924117,
          'name': 'TRECVID'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 53,
      'reference_count': 48
    },
    {
      'id': 154490733,
      'title': 'Collaborative Quantization for Cross-Modal Similarity Search',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1009100021,
          'name': 'Ting Zhang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010835822,
          'name': 'Incremental heuristic search'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039464793,
          'name': 'Quantization (physics)'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2047932954,
          'name': 'Beam search'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042532604,
          'name': 'Best-first search'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 39
    },
    {
      'id': 155445194,
      'title': 'Semantic Object Parsing with Local-Global Long Short-Term Memory',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1095714373,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1166166664,
          'name': 'Donglai Xiang'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2005356592,
          'name': 'Memorization'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://dblp.uni-trier.de/db/conf/cvpr/cvpr2016.html',
      'citation_count': 13,
      'reference_count': 39
    },
    {
      'id': 157481897,
      'title': 'Mining 3D Key-Pose-Motifs for Action Recognition',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1335216092,
          'name': 'Chunyu Wang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 33
    },
    {
      'id': 165567193,
      'title': 'STCT: Sequentially Training Convolutional Networks for Visual Tracking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 126,
      'reference_count': 43
    },
    {
      'id': 16912524,
      'title': 'A Weighted Variational Model for Simultaneous Reflectance and Illumination Estimation',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1083964890,
          'name': 'Xueyang Fu'
        },
        {
          'id': 1047270814,
          'name': 'Delu Zeng'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1368493164,
          'name': 'Xiao-Ping Zhang'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001942329,
          'name': 'Reflectivity'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2009821638,
          'name': 'Data transformation (statistics)'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 36
    },
    {
      'id': 170385295,
      'title': 'Sparse Coding and Dictionary Learning with Linear Dynamical Systems',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2104258153,
          'abbr': 'NICTA',
          'name': 'NICTA'
        }
      ],
      'author': [
        {
          'id': 1467293440,
          'name': 'Wenbing Huang'
        },
        {
          'id': 1233632450,
          'name': 'Fuchun Sun'
        },
        {
          'id': 1254063453,
          'name': 'Lele Cao'
        },
        {
          'id': 1320668265,
          'name': 'Deli Zhao'
        },
        {
          'id': 1108094283,
          'name': 'Huaping Liu'
        },
        {
          'id': 1490172637,
          'name': 'Mehrtash Tafazzoli Harandi'
        }
      ],
      'field': [
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019188024,
          'name': 'Linear dynamical system'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2032890372,
          'name': 'Observability'
        },
        {
          'id': 2028571302,
          'name': 'Grassmannian'
        },
        {
          'id': 2040215507,
          'name': 'Homeomorphism'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000280526,
          'name': 'Symmetric matrix'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 46
    },
    {
      'id': 173371509,
      'title': 'Modality and Component Aware Feature Fusion for RGB-D Scene Classification',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1382569636,
          'name': 'Anran Wang'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1424297808,
          'name': 'Tat-Jen Cham'
        }
      ],
      'field': [
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001136715,
          'name': 'Spatial variability'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2048065051,
          'name': 'Lasso (statistics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 37
    },
    {
      'id': 17555456,
      'title': 'HyperNet: Towards Accurate Region Proposal Generation and Joint Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1242912686,
          'name': 'Tao Kong'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1233632450,
          'name': 'Fuchun Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020557398,
          'name': 'Uniform space'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://arxiv.org/pdf/1604.00600.pdf',
      'citation_count': 40,
      'reference_count': 36
    },
    {
      'id': 182388782,
      'title': 'Progressively Parsing Interactional Objects for Fine Grained Action Detection',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 35
    },
    {
      'id': 18649975,
      'title': 'Semantic Object Parsing with Local-Global Long Short-Term Memory',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1166166664,
          'name': 'Donglai Xiang'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005356592,
          'name': 'Memorization'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 58,
      'reference_count': 38
    },
    {
      'id': 188968594,
      'title': 'The Solution Path Algorithm for Identity-Aware Multi-object Tracking',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1256961584,
          'name': 'Shoou-I Yu'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2013839451,
          'name': 'Quadratic programming'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010417696,
          'name': 'Active learning'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2001770042,
          'name': 'Lp space'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 41
    },
    {
      'id': 189535569,
      'title': 'Hierarchical Recurrent Neural Encoder for Video Representation with Application to Captioning',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1056481938,
          'name': 'Pingbo Pan'
        },
        {
          'id': 1365078931,
          'name': 'Zhongwen Xu'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1374018797,
          'name': 'Fei Wu'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2018606483,
          'name': 'Video content analysis'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 159,
      'reference_count': 42
    },
    {
      'id': 198631801,
      'title': 'D3: Deep Dual-Domain Based Fast Restoration of JPEG-Compressed Images',
      'affiliation': [
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1474290517,
          'name': 'Zhangyang Wang'
        },
        {
          'id': 1394627968,
          'name': 'Ding Liu'
        },
        {
          'id': 1102368127,
          'name': 'Shiyu Chang'
        },
        {
          'id': 1433617922,
          'name': 'Qing Ling'
        },
        {
          'id': 1496263788,
          'name': 'Yingzhen Yang'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 34
    },
    {
      'id': 203872186,
      'title': 'A Hole Filling Approach Based on Background Reconstruction for View Synthesis in 3D Video',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100888578,
          'abbr': 'UM',
          'name': 'University of Macau'
        }
      ],
      'author': [
        {
          'id': 1206435100,
          'name': 'Guibo Luo'
        },
        {
          'id': 1342392370,
          'name': 'Yuesheng Zhu'
        },
        {
          'id': 1081454575,
          'name': 'Zhaotian Li'
        },
        {
          'id': 1207475427,
          'name': 'Liming Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038412597,
          'name': 'Image-based modeling and rendering'
        },
        {
          'id': 2030410974,
          'name': 'Motion compensation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022305872,
          'name': 'Video denoising'
        },
        {
          'id': 2046246198,
          'name': 'Video post-processing'
        },
        {
          'id': 2044391384,
          'name': 'Block-matching algorithm'
        },
        {
          'id': 2025715469,
          'name': 'Background subtraction'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 31
    },
    {
      'id': 204895466,
      'title': 'Rain Streak Removal Using Layer Priors',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        }
      ],
      'author': [
        {
          'id': 1179271313,
          'name': 'Yu Li'
        },
        {
          'id': 1276044969,
          'name': 'Robby T. Tan'
        },
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1368374079,
          'name': 'Jiangbo Lu'
        },
        {
          'id': 1362764324,
          'name': 'Michael S. Brown'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2011893552,
          'name': 'Streak'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 92,
      'reference_count': 29
    },
    {
      'id': 209270297,
      'title': 'Temporal Action Localization in Untrimmed Videos via Multi-stage CNNs',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1458473118,
          'name': 'Zheng Shou'
        },
        {
          'id': 1071546611,
          'name': 'Dongang Wang'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 175,
      'reference_count': 45
    },
    {
      'id': 21566828,
      'title': 'Euclidean and Hamming Embedding for Image Patch Description with Convolutional Networks',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1297712997,
          'name': 'Zishun Liu'
        },
        {
          'id': 1363273524,
          'name': 'Zhenxi Li'
        },
        {
          'id': 1142911606,
          'name': 'Juyong Zhang'
        },
        {
          'id': 1297670722,
          'name': 'Ligang Liu'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2027601801,
          'name': 'Euclidean geometry'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 34
    },
    {
      'id': 218277555,
      'title': 'Facial Expression Intensity Estimation Using Ordinal Information',
      'affiliation': [
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1348163422,
          'name': 'Rui Zhao'
        },
        {
          'id': 1185367134,
          'name': 'Quan Gan'
        },
        {
          'id': 1445199645,
          'name': 'Shangfei Wang'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 32
    },
    {
      'id': 219972001,
      'title': 'Fast and Accurate Registration of Structured Point Clouds with Small Overlaps',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1438172003,
          'name': 'Yanxin Ma'
        },
        {
          'id': 1206681019,
          'name': 'Yulan Guo'
        },
        {
          'id': 1284233558,
          'name': 'Jian Zhao'
        },
        {
          'id': 1026163384,
          'name': 'Min Lu'
        },
        {
          'id': 1137390542,
          'name': 'Jun Zhang'
        },
        {
          'id': 1384795503,
          'name': 'Jianwei Wan'
        }
      ],
      'field': [
        {
          'id': 2034747575,
          'name': 'Rotation matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006359314,
          'name': 'Translation (geometry)'
        },
        {
          'id': 2013537828,
          'name': 'Cross-validation'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 25
    },
    {
      'id': 220557752,
      'title': 'Deep Relative Distance Learning: Tell the Difference between Similar Vehicles',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1334903417,
          'name': 'Hongye Liu'
        },
        {
          'id': 1484177249,
          'name': 'Yonghong Tian'
        },
        {
          'id': 1306980273,
          'name': 'Yaowei Wang'
        },
        {
          'id': 1258207390,
          'name': 'Lu Pang'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022550578,
          'name': 'Distance education'
        },
        {
          'id': 2004101032,
          'name': 'Euclidean space'
        },
        {
          'id': 2025425813,
          'name': 'Vehicle tracking system'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2004650574,
          'name': 'Vehicle search'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 90,
      'reference_count': 23
    },
    {
      'id': 227353141,
      'title': 'Recognizing Car Fluents from Video',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101371417,
          'abbr': 'SALESFORCE',
          'name': 'Salesforce.com'
        }
      ],
      'author': [
        {
          'id': 1173230308,
          'name': 'Bo Li'
        },
        {
          'id': 1088002310,
          'name': 'Tianfu Wu'
        },
        {
          'id': 1098362549,
          'name': 'Caiming Xiong'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2022582519,
          'name': 'Fluent'
        },
        {
          'id': 2012493528,
          'name': 'Hierarchical database model'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 73
    },
    {
      'id': 231972063,
      'title': 'Deeply Exploit Depth Information for Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1112157554,
          'name': 'Saihui Hou'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022050995,
          'name': 'Semantic property'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1605.html',
      'citation_count': 1,
      'reference_count': 31
    },
    {
      'id': 236387429,
      'title': 'Semi-supervised Vocabulary-Informed Learning',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100362165,
          'abbr': 'DISNEYRESEARCH',
          'name': 'Disney Research'
        }
      ],
      'author': [
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1235056674,
          'name': 'Leonid Sigal'
        }
      ],
      'field': [
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        },
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2008010037,
          'name': 'Versa'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Fu_Semi-Supervised_Vocabulary-Informed_Learning_CVPR_2016_paper.html',
      'citation_count': 10,
      'reference_count': 47
    },
    {
      'id': 239595747,
      'title': 'Learning Attributes Equals Multi-Source Domain Generalization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102379318,
          'abbr': 'UI',
          'name': 'University of Iowa'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1284958855,
          'name': 'Tianbao Yang'
        },
        {
          'id': 1347218119,
          'name': 'Boqing Gong'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025617559,
          'name': 'Multi-source'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 60,
      'reference_count': 86
    },
    {
      'id': 240823363,
      'title': 'Robust Tensor Factorization with Unknown Noise',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1284269681,
          'name': 'Xi\'ai Chen'
        },
        {
          'id': 1028968436,
          'name': 'Zhi Han'
        },
        {
          'id': 1212993904,
          'name': 'Yao Wang'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1321471777,
          'name': 'Yandong Tang'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 31
    },
    {
      'id': 241847011,
      'title': 'Composition-Stacked CNN for Fine-Grained Visual Categorization',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1308105996,
          'name': 'Shaoli Huang'
        },
        {
          'id': 1259982936,
          'name': 'Zhe Xu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030024898,
          'name': 'Interpretability'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 54
    },
    {
      'id': 242024576,
      'title': 'Multispectral Images Denoising by Intrinsic Tensor Sparsity Regularization',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1097800340,
          'name': 'Qi Xie'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        },
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2047672818,
          'name': 'Instrumental and intrinsic value'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2039645437,
          'name': 'Faithful representation'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 34
    },
    {
      'id': 242207181,
      'title': 'Harnessing Object and Scene Semantics for Large-Scale Video Understanding',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100362165,
          'abbr': 'DISNEYRESEARCH',
          'name': 'Disney Research'
        }
      ],
      'author': [
        {
          'id': 1263259745,
          'name': 'Zuxuan Wu'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1235056674,
          'name': 'Leonid Sigal'
        }
      ],
      'field': [
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 43
    },
    {
      'id': 244194719,
      'title': 'Trace Quotient Meets Sparsity: A Method for Learning Low Dimensional Image Representations',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100355666,
          'abbr': 'TUM',
          'name': 'Technische Universität München'
        }
      ],
      'author': [
        {
          'id': 1128873543,
          'name': 'Xian Wei'
        },
        {
          'id': 1236988171,
          'name': 'Hao Shen'
        },
        {
          'id': 1212163933,
          'name': 'Martin Kleinsteuber'
        }
      ],
      'field': [
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2019453830,
          'name': 'Elastic net regularization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018879351,
          'name': 'Quotient'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 33
    },
    {
      'id': 245229749,
      'title': 'Manifold SLIC: A Fast Method to Compute Content-Sensitive Superpixels',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1212426579,
          'name': 'Yong-Jin Liu'
        },
        {
          'id': 1230363316,
          'name': 'Cheng-Chi Yu'
        },
        {
          'id': 1454206707,
          'name': 'Minjing Yu'
        },
        {
          'id': 1455633708,
          'name': 'Ying He'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037299454,
          'name': 'Centroidal Voronoi tessellation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045380777,
          'name': 'Uniform tessellation'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2023032611,
          'name': 'Voronoi diagram'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 21
    },
    {
      'id': 246936214,
      'title': 'Layered Scene Decomposition via the Occlusion-CRF',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101817535,
          'abbr': 'WUSTL',
          'name': 'Washington University in St. Louis'
        }
      ],
      'author': [
        {
          'id': 1007248548,
          'name': 'Chen Liu'
        },
        {
          'id': 1223786509,
          'name': 'Pushmeet Kohli'
        },
        {
          'id': 1364931837,
          'name': 'Yasutaka Furukawa'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 28
    },
    {
      'id': 252480734,
      'title': 'Latent Factor Guided Convolutional Neural Networks for Age-Invariant Face Recognition',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1369857672,
          'name': 'Yandong Wen'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 47,
      'reference_count': 37
    },
    {
      'id': 256109501,
      'title': 'Fast Training of Triplet-Based Deep Binary Embedding Networks',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1407150119,
          'name': 'Bohan Zhuang'
        },
        {
          'id': 1139106464,
          'name': 'Guosheng Lin'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 38
    },
    {
      'id': 260453708,
      'title': 'Joint Learning of Single-Image and Cross-Image Representations for Person Re-identification',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1451271758,
          'name': 'Faqiang Wang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009605270,
          'name': 'VIPeR'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 139,
      'reference_count': 37
    },
    {
      'id': 263805556,
      'title': 'Deeply Exploit Depth Information for Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1112157554,
          'name': 'Saihui Hou'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022050995,
          'name': 'Semantic property'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2011350140,
          'name': 'Derivation'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 33
    },
    {
      'id': 267214636,
      'title': 'Do We Need Binary Features for 3D Reconstruction',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        }
      ],
      'author': [
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1244945868,
          'name': 'Qingqun Kong'
        },
        {
          'id': 1141090364,
          'name': 'Wei Sui'
        },
        {
          'id': 1063625561,
          'name': 'Zhiheng Wang'
        },
        {
          'id': 1379576641,
          'name': 'Xinchao Wang'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1114885725,
          'name': 'Chunhong Pan'
        },
        {
          'id': 1116814108,
          'name': 'Pascal Fua'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2046455943,
          'name': 'Hamming distance'
        },
        {
          'id': 2036616100,
          'name': 'Completeness (statistics)'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 42
    },
    {
      'id': 276046973,
      'title': 'Factors in Finetuning Deep Model for Object Detection with Long-Tail Distribution',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1137294273,
          'name': 'Cong Zhang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2028902918,
          'name': 'Long tail'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 47
    },
    {
      'id': 281089870,
      'title': 'ChaLearn Looking at People RGB-D Isolated and Continuous Datasets for Gesture Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2102976531,
          'abbr': 'UB',
          'name': 'University of Barcelona'
        }
      ],
      'author': [
        {
          'id': 1119293493,
          'name': 'Jun Wan'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        },
        {
          'id': 1306327236,
          'name': 'Yibing Zhao'
        },
        {
          'id': 1275718815,
          'name': 'Shuai Zhou'
        },
        {
          'id': 1146896407,
          'name': 'Isabelle Guyon'
        },
        {
          'id': 1011188708,
          'name': 'Sergio Escalera'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2029531426,
          'name': 'Bag-of-words model in computer vision'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 24
    },
    {
      'id': 281223098,
      'title': 'Tensor Robust Principal Component Analysis: Exact Recovery of Corrupted Low-Rank Tensors via Convex Optimization',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1081691569,
          'name': 'Canyi Lu'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1260911928,
          'name': 'Yudong Chen'
        },
        {
          'id': 1027781498,
          'name': 'Weiwei Liu'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2035705126,
          'name': 'Regular polygon'
        },
        {
          'id': 2014714176,
          'name': 'Applied mathematics'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2018992595,
          'name': 'Robust principal component analysis'
        },
        {
          'id': 2000585400,
          'name': 'Special case'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Lu_Tensor_Robust_Principal_CVPR_2016_paper.html',
      'citation_count': 11,
      'reference_count': 27
    },
    {
      'id': 286020310,
      'title': 'Reversible Recursive Instance-Level Object Segmentation',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        }
      ],
      'author': [
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1235764616,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1273266408,
          'name': 'Zequn Jie'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 41
    },
    {
      'id': 288753423,
      'title': '3D Convolutional Networks-Based Mitotic Event Detection in Time-Lapse Phase Contrast Microscopy Image Sequences of Stem Cell Populations',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102621831,
          'abbr': 'TNU',
          'name': 'Tianjin Normal University'
        }
      ],
      'author': [
        {
          'id': 1133282074,
          'name': 'Weizhi Nie'
        },
        {
          'id': 1446646372,
          'name': 'Wenhui Li'
        },
        {
          'id': 1080635904,
          'name': 'Anan Liu'
        },
        {
          'id': 1053601609,
          'name': 'Tong Hao'
        },
        {
          'id': 1086591118,
          'name': 'Yuting Su'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043669260,
          'name': 'Stem cell'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014760654,
          'name': 'Mitosis'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2003908288,
          'name': 'Phase contrast microscopy'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 22
    },
    {
      'id': 293335720,
      'title': 'CRAFT Objects from Images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1321754694,
          'name': 'Bin Yang'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042828818,
          'name': 'Division (mathematics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2019556046,
          'name': 'Divide and conquer algorithms'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043719263,
          'name': 'Method'
        },
        {
          'id': 2045015420,
          'name': 'Still room'
        },
        {
          'id': 2021273121,
          'name': 'Craft'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 55,
      'reference_count': 41
    },
    {
      'id': 300704252,
      'title': 'Semi-supervised Vocabulary-Informed Learning',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100362165,
          'abbr': 'DISNEYRESEARCH',
          'name': 'Disney Research'
        }
      ],
      'author': [
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1235056674,
          'name': 'Leonid Sigal'
        }
      ],
      'field': [
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2008010037,
          'name': 'Versa'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 50,
      'reference_count': 50
    },
    {
      'id': 30331718,
      'title': 'Multi-view Deep Network for Cross-View Classification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007924723,
          'name': 'Rayleigh quotient'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007410355,
          'name': 'Discriminant'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 30
    },
    {
      'id': 303663629,
      'title': 'DisturbLabel: Regularizing CNN on the Loss Layer',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1224461583,
          'name': 'Zhen Wei'
        },
        {
          'id': 1289931589,
          'name': 'Meng Wang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041396835,
          'name': 'SIMPLE algorithm'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 47,
      'reference_count': 42
    },
    {
      'id': 306392527,
      'title': 'A Key Volume Mining Deep Framework for Action Recognition',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1006489365,
          'name': 'Wangjiang Zhu'
        },
        {
          'id': 1007345331,
          'name': 'Jie Hu'
        },
        {
          'id': 1003140657,
          'name': 'Gang Sun'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005832358,
          'name': 'Stochastic gradient descent'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 118,
      'reference_count': 33
    },
    {
      'id': 314839367,
      'title': 'Recurrent Convolutional Neural Network Regression for Continuous Pain Intensity Estimation in Video',
      'affiliation': [
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1000280247,
          'name': 'Jing Zhou'
        },
        {
          'id': 1246004700,
          'name': 'Xiaopeng Hong'
        },
        {
          'id': 1204909747,
          'name': 'Fei Su'
        },
        {
          'id': 1259121145,
          'name': 'Guoying Zhao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2009553560,
          'name': 'Limiting'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://arxiv.org/pdf/1605.00894.pdf',
      'citation_count': 6,
      'reference_count': 36
    },
    {
      'id': 316751625,
      'title': 'Joint Training of Cascaded CNN for Face Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1436337105,
          'name': 'Hongwei Qin'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1297620460,
          'name': 'Xiu Li'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 106,
      'reference_count': 41
    },
    {
      'id': 318055094,
      'title': 'SketchNet: Sketch Classification with Web Images',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1111205108,
          'name': 'Hua Zhang'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1179117743,
          'name': 'Changqing Zhang'
        },
        {
          'id': 1224361079,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1017800361,
          'name': 'Rui Wang'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007773467,
          'name': 'Sketch recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 26
    },
    {
      'id': 320171434,
      'title': 'Jointly Modeling Embedding and Translation to Bridge Video and Language',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 249,
      'reference_count': 57
    },
    {
      'id': 324454223,
      'title': 'Temporal Action Localization in Untrimmed Videos via Multi-stage CNNs',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1227531887,
          'name': 'Zheng Shou'
        },
        {
          'id': 1071546611,
          'name': 'Dongang Wang'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2016/html/Shou_Temporal_Action_Localization_CVPR_2016_paper.html',
      'citation_count': 48,
      'reference_count': 42
    },
    {
      'id': 3252691,
      'title': 'Learning Compact Binary Descriptors with Unsupervised Deep Neural Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1191435287,
          'name': 'Kevin Lin'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1355047444,
          'name': 'Chu-Song Chen'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 94,
      'reference_count': 55
    },
    {
      'id': 326969724,
      'title': 'Sparse Coding for Classification via Discrimination Ensemble',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1181038210,
          'name': 'Yuhui Quan'
        },
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        },
        {
          'id': 1147949542,
          'name': 'Yuping Sun'
        },
        {
          'id': 1220653455,
          'name': 'Yan Huang'
        },
        {
          'id': 1186862432,
          'name': 'Hui Ji'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2047795701,
          'name': 'Linear classifier'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2028335632,
          'name': 'Quadratic classifier'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 41
    },
    {
      'id': 335187129,
      'title': 'Sample-Specific SVM Learning for Person Re-identification',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1056841273,
          'name': 'Ying Zhang'
        },
        {
          'id': 1050505036,
          'name': 'Baohua Li'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1252365443,
          'name': 'Atshushi Irie'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032409045,
          'name': 'Disjoint sets'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 74,
      'reference_count': 34
    },
    {
      'id': 338862988,
      'title': 'Similarity Learning with Spatial Constraints for Person Re-identification',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1037882621,
          'name': 'Dapeng Chen'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1471304424,
          'name': 'Badong Chen'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012712064,
          'name': 'Similarity learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003882441,
          'name': 'Spatial distribution'
        },
        {
          'id': 2026815810,
          'name': 'Polynomial'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 211,
      'reference_count': 34
    },
    {
      'id': 345830616,
      'title': 'ForgetMeNot: Memory-Aware Forensic Facial Sketch Matching',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1228249224,
          'name': 'Shuxin Ouyang'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1276350016,
          'name': 'Yi-Zhe Song'
        },
        {
          'id': 1086146853,
          'name': 'Xueming Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005513671,
          'name': 'Forensic science'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2007773467,
          'name': 'Sketch recognition'
        },
        {
          'id': 2038498666,
          'name': 'Forgetting'
        },
        {
          'id': 2006041051,
          'name': 'Law enforcement'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 25
    },
    {
      'id': 347553285,
      'title': 'Saliency Guided Dictionary Learning for Weakly-Supervised Image Parsing',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1343356754,
          'name': 'Baisheng Lai'
        },
        {
          'id': 1026609011,
          'name': 'Xiaojin Gong'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 40
    },
    {
      'id': 349406148,
      'title': 'Unsupervised Cross-Dataset Transfer Learning for Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2101433684,
          'abbr': 'IIT',
          'name': 'Istituto Italiano di Tecnologia'
        }
      ],
      'author': [
        {
          'id': 1178660196,
          'name': 'Peixi Peng'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1306980273,
          'name': 'Yaowei Wang'
        },
        {
          'id': 1346478157,
          'name': 'Massimiliano Pontil'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        },
        {
          'id': 1484177249,
          'name': 'Yonghong Tian'
        }
      ],
      'field': [
        {
          'id': 2017679758,
          'name': 'Competitive learning'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2014234673,
          'name': 'Online machine learning'
        },
        {
          'id': 2010451535,
          'name': 'Active learning (machine learning)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2034845953,
          'name': 'Multi-task learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 111,
      'reference_count': 37
    },
    {
      'id': 349829135,
      'title': 'Dense Human Body Correspondences Using Convolutional Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103810453,
          'abbr': 'TTIC',
          'name': 'Toyota Technological Institute at Chicago'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        }
      ],
      'author': [
        {
          'id': 1378303923,
          'name': 'Lingyu Wei'
        },
        {
          'id': 1070058207,
          'name': 'Qixing Huang'
        },
        {
          'id': 1038877551,
          'name': 'Duygu Ceylan'
        },
        {
          'id': 1052484681,
          'name': 'Etienne Vouga'
        },
        {
          'id': 1347646526,
          'name': 'Hao Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024086463,
          'name': 'Rendering (computer graphics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2016497804,
          'name': 'Correspondence problem'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Wei_Dense_Human_Body_CVPR_2016_paper.html',
      'citation_count': 9,
      'reference_count': 44
    },
    {
      'id': 351223782,
      'title': 'Learning Discriminative Features with Class Encoder',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1477164535,
          'name': 'Hailin Shi'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 28
    },
    {
      'id': 354555615,
      'title': 'Deep Structured Scene Parsing by Learning with Image Descriptions',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1177260794,
          'name': 'Guangrun Wang'
        },
        {
          'id': 1092132777,
          'name': 'Rui Zhang'
        },
        {
          'id': 1057785633,
          'name': 'Ruimao Zhang'
        },
        {
          'id': 1095714373,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2002913880,
          'name': 'Verb'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2049518231,
          'name': 'Noun'
        },
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://dblp.uni-trier.de/db/conf/cvpr/cvpr2016.html',
      'citation_count': 1,
      'reference_count': 37
    },
    {
      'id': 355046482,
      'title': 'Multilinear Hyperplane Hashing',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1025575364,
          'name': 'Xianglong Liu'
        },
        {
          'id': 1145088697,
          'name': 'Xinjie Fan'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1368581235,
          'name': 'Zhujin Li'
        },
        {
          'id': 1464060409,
          'name': 'Hao Su'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2012690106,
          'name': 'Open addressing'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017191986,
          'name': 'Hopscotch hashing'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 31
    },
    {
      'id': 357994334,
      'title': 'Single-Image Crowd Counting via Multi-Column Convolutional Neural Network',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1003486650,
          'name': 'Yingying Zhang'
        },
        {
          'id': 1286489679,
          'name': 'Desen Zhou'
        },
        {
          'id': 1141488758,
          'name': 'Siqin Chen'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 223,
      'reference_count': 35
    },
    {
      'id': 358909512,
      'title': 'Deep Supervised Hashing for Fast Image Retrieval',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1407582576,
          'name': 'Haomiao Liu'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2031909882,
          'name': 'Binary image'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 222,
      'reference_count': 30
    },
    {
      'id': 363529706,
      'title': 'Relaxation-Based Preprocessing Techniques for Markov Random Field Inference',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        }
      ],
      'author': [
        {
          'id': 1081170536,
          'name': 'Chen Wang'
        },
        {
          'id': 1306993101,
          'name': 'Ramin Zabih'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2033391627,
          'name': 'Overhead (computing)'
        },
        {
          'id': 2047824847,
          'name': 'Random field'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2022297451,
          'name': 'Dead-end elimination'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 30
    },
    {
      'id': 364307849,
      'title': 'Variable Aperture Light Field Photography: Overcoming the Diffraction-Limited Spatio-Angular Resolution Tradeoff',
      'affiliation': [
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1229784218,
          'name': 'Julie Chang'
        },
        {
          'id': 1015434651,
          'name': 'Isaac Kauvar'
        },
        {
          'id': 1186547571,
          'name': 'Xuemei Hu'
        },
        {
          'id': 1017674813,
          'name': 'Gordon Wetzstein'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2039879149,
          'name': 'Computational photography'
        },
        {
          'id': 2027651673,
          'name': 'Computer graphics (images)'
        },
        {
          'id': 2037410910,
          'name': 'Photography'
        },
        {
          'id': 2034848742,
          'name': 'Machine vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2048954615,
          'name': 'Aperture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047366969,
          'name': 'Robotics'
        },
        {
          'id': 2009660747,
          'name': 'Angular resolution'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 38
    },
    {
      'id': 368880826,
      'title': 'Hedged Deep Tracking',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101355250,
          'abbr': 'HANYANG',
          'name': 'Hanyang University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1188685537,
          'name': 'Yuankai Qi'
        },
        {
          'id': 1136796451,
          'name': 'Shengping Zhang'
        },
        {
          'id': 1370887323,
          'name': 'Lei Qin'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1229893089,
          'name': 'Jongwoo Lim'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 226,
      'reference_count': 41
    },
    {
      'id': 373594017,
      'title': 'Robust Optical Flow Estimation of Double-Layer Images under Transparency or Reflection',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1192440391,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1276044969,
          'name': 'Robby T. Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2049290898,
          'name': 'Double layer (surface science)'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2020211709,
          'name': 'Transparency (graphic)'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045807287,
          'name': 'Brightness'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://dblp.uni-trier.de/db/conf/cvpr/cvpr2016.html',
      'citation_count': 3,
      'reference_count': 46
    },
    {
      'id': 374731717,
      'title': 'Constructing Canonical Regions for Fast and Effective View Selection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1186516891,
          'name': 'Wencheng Wang'
        },
        {
          'id': 1203727491,
          'name': 'Tianhao Gao'
        }
      ],
      'field': [
        {
          'id': 2032104252,
          'name': 'Canonical model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 37
    },
    {
      'id': 377051059,
      'title': 'RAID-G: Robust Estimation of Approximate Infinite Dimensional Gaussian with Application to Material Recognition',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2049633074,
          'name': 'RAID'
        },
        {
          'id': 2029230238,
          'name': 'Divergence'
        },
        {
          'id': 2008941526,
          'name': 'Kernel (statistics)'
        },
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 53
    },
    {
      'id': 388309466,
      'title': 'A Probabilistic Collaborative Representation Based Approach for Pattern Classification',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1236093116,
          'name': 'Sijia Cai'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003522973,
          'name': 'Random subspace method'
        },
        {
          'id': 2002476364,
          'name': 'Ranging'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010818823,
          'name': 'Probabilistic classification'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2028335632,
          'name': 'Quadratic classifier'
        },
        {
          'id': 2023319810,
          'name': 'Margin classifier'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 69,
      'reference_count': 54
    },
    {
      'id': 393831098,
      'title': 'Learning Structured Inference Neural Networks with Label Relations',
      'affiliation': [
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        },
        {
          'id': 2103565249,
          'abbr': 'SFU',
          'name': 'Simon Fraser University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1191938240,
          'name': 'Hexiang Hu'
        },
        {
          'id': 1080402916,
          'name': 'Guang-Tong Zhou'
        },
        {
          'id': 1400458390,
          'name': 'Zhiwei Deng'
        },
        {
          'id': 1061710418,
          'name': 'Zicheng Liao'
        },
        {
          'id': 1481166169,
          'name': 'Greg Mori'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2032401552,
          'name': 'Abstraction'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 40
    },
    {
      'id': 396632186,
      'title': 'Recurrent Convolutional Neural Network Regression for Continuous Pain Intensity Estimation in Video',
      'affiliation': [
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1000280247,
          'name': 'Jing Zhou'
        },
        {
          'id': 1246004700,
          'name': 'Xiaopeng Hong'
        },
        {
          'id': 1204909747,
          'name': 'Fei Su'
        },
        {
          'id': 1259121145,
          'name': 'Guoying Zhao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009553560,
          'name': 'Limiting'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 37
    },
    {
      'id': 400699169,
      'title': 'Picking Deep Filter Responses for Fine-Grained Image Recognition',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1189798332,
          'name': 'Xiaopeng Zhang'
        },
        {
          'id': 1086324331,
          'name': 'Hongkai Xiong'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029025337,
          'name': 'Retraining'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 81,
      'reference_count': 34
    },
    {
      'id': 403054399,
      'title': 'Equiangular Kernel Dictionary Learning with Applications to Dynamic Texture Analysis',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1181038210,
          'name': 'Yuhui Quan'
        },
        {
          'id': 1054519464,
          'name': 'Chenglong Bao'
        },
        {
          'id': 1186862432,
          'name': 'Hui Ji'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031433926,
          'name': 'Mutual coherence'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2028995778,
          'name': 'Kernel embedding of distributions'
        },
        {
          'id': 2006122888,
          'name': 'Radial basis function kernel'
        },
        {
          'id': 2001750399,
          'name': 'Tree kernel'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 50
    },
    {
      'id': 40597300,
      'title': 'Natural Language Object Retrieval',
      'affiliation': [
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102821251,
          'abbr': 'UML',
          'name': 'University of Massachusetts Lowell'
        }
      ],
      'author': [
        {
          'id': 1280392357,
          'name': 'Ronghang Hu'
        },
        {
          'id': 1046892366,
          'name': 'Huazhe Xu'
        },
        {
          'id': 1103146898,
          'name': 'Marcus Rohrbach'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1465783171,
          'name': 'Kate Saenko'
        },
        {
          'id': 1255937776,
          'name': 'Trevor Darrell'
        }
      ],
      'field': [
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2036348455,
          'name': 'Natural language user interface'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://openaccess.thecvf.com/content_cvpr_2016/html/Hu_Natural_Language_Object_CVPR_2016_paper.html',
      'citation_count': 52,
      'reference_count': 32
    },
    {
      'id': 408327759,
      'title': 'Oracle Based Active Set Algorithm for Scalable Elastic Net Subspace Clustering',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1005565804,
          'name': 'Chong You'
        },
        {
          'id': 1067719877,
          'name': 'Chun-Guang Li'
        },
        {
          'id': 1486514115,
          'name': 'Daniel P. Robinson'
        },
        {
          'id': 1484844444,
          'name': 'René Vidal'
        }
      ],
      'field': [
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2022354964,
          'name': 'Social connectedness'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2006507159,
          'name': 'Active set method'
        },
        {
          'id': 2019453830,
          'name': 'Elastic net regularization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/You_Oracle_Based_Active_CVPR_2016_paper.html',
      'citation_count': 8,
      'reference_count': 49
    },
    {
      'id': 408688874,
      'title': 'Robust Scene Text Recognition with Automatic Rectification',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1154870261,
          'name': 'Baoguang Shi'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1433542305,
          'name': 'Pengyuan Lyu'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2001363999,
          'name': 'Perspective distortion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016069254,
          'name': 'Noisy text analytics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025838069,
          'name': 'Rectification'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 46
    },
    {
      'id': 41936315,
      'title': 'Exploit Bounding Box Annotations for Multi-Label Object Recognition',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1277193811,
          'name': 'Hao Yang'
        },
        {
          'id': 1442971782,
          'name': 'Joey Tianyi Zhou'
        },
        {
          'id': 1460892622,
          'name': 'Yu Zhang'
        },
        {
          'id': 1107807353,
          'name': 'Bin-Bin Gao'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 35
    },
    {
      'id': 424788751,
      'title': 'ReD-SFA: Relation Discovery Based Slow Feature Analysis for Trajectory Clustering',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1070491497,
          'name': 'Zhang Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        },
        {
          'id': 1165951422,
          'name': 'Peipei Yang'
        },
        {
          'id': 1026423917,
          'name': 'Jun Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2014337830,
          'name': 'Fuzzy clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2031194188,
          'name': 'Constrained clustering'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 38
    },
    {
      'id': 428465896,
      'title': 'A Pointing Gesture Based Egocentric Interaction System: Dataset, Approach and Application',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1117343503,
          'name': 'Yichao Huang'
        },
        {
          'id': 1186348798,
          'name': 'Xiaorui Liu'
        },
        {
          'id': 1366371321,
          'name': 'Xin Zhang'
        },
        {
          'id': 1276589951,
          'name': 'Lianwen Jin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041467508,
          'name': 'Augmented reality'
        },
        {
          'id': 2000619510,
          'name': 'Wearable computer'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 26
    },
    {
      'id': 42920954,
      'title': 'Hierarchical Recurrent Neural Encoder for Video Representation with Application to Captioning',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1056481938,
          'name': 'Pingbo Pan'
        },
        {
          'id': 1365078931,
          'name': 'Zhongwen Xu'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1225427693,
          'name': 'Fei Wu'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        }
      ],
      'field': [
        {
          'id': 2005987546,
          'name': 'Compositing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018606483,
          'name': 'Video content analysis'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Pan_Hierarchical_Recurrent_Neural_CVPR_2016_paper.html',
      'citation_count': 17,
      'reference_count': 44
    },
    {
      'id': 436473970,
      'title': 'Weakly Supervised Object Localization with Progressive Domain Adaptation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1081657789,
          'name': 'Dong Li'
        },
        {
          'id': 1484879248,
          'name': 'Jia-Bin Huang'
        },
        {
          'id': 1014730232,
          'name': 'Yali Li'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031569861,
          'name': 'Performance improvement'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 48
    },
    {
      'id': 437075993,
      'title': 'Oracle Based Active Set Algorithm for Scalable Elastic Net Subspace Clustering',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1005565804,
          'name': 'Chong You'
        },
        {
          'id': 1067719877,
          'name': 'Chun-Guang Li'
        },
        {
          'id': 1486514115,
          'name': 'Daniel P. Robinson'
        },
        {
          'id': 1484844444,
          'name': 'René Vidal'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2019453830,
          'name': 'Elastic net regularization'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2006507159,
          'name': 'Active set method'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 47,
      'reference_count': 52
    },
    {
      'id': 440713524,
      'title': 'Natural Language Object Retrieval',
      'affiliation': [
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102821251,
          'abbr': 'UML',
          'name': 'University of Massachusetts Lowell'
        }
      ],
      'author': [
        {
          'id': 1280392357,
          'name': 'Ronghang Hu'
        },
        {
          'id': 1145949776,
          'name': 'Huazhe Xu'
        },
        {
          'id': 1103146898,
          'name': 'Marcus Rohrbach'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1117109347,
          'name': 'Kate Saenko'
        },
        {
          'id': 1255937776,
          'name': 'Trevor Darrell'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008071310,
          'name': 'Query expansion'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004846876,
          'name': 'Concept search'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2002673071,
          'name': 'Query language'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 181,
      'reference_count': 33
    },
    {
      'id': 44192241,
      'title': 'Actionness Estimation Using Hybrid Fully Convolutional Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        }
      ],
      'author': [
        {
          'id': 1242240210,
          'name': 'Limin Wang'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2003271894,
          'name': 'Generic Action'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 50
    },
    {
      'id': 446670223,
      'title': 'Deep Region and Multi-label Learning for Facial Action Unit Detection',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1208665046,
          'name': 'Kaili Zhao'
        },
        {
          'id': 1357110762,
          'name': 'Wen-Sheng Chu'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 56,
      'reference_count': 33
    },
    {
      'id': 447492897,
      'title': 'Robust Optical Flow Estimation of Double-Layer Images under Transparency or Reflection',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1276044969,
          'name': 'Robby T. Tan'
        }
      ],
      'field': [
        {
          'id': 2049290898,
          'name': 'Double layer (surface science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020211709,
          'name': 'Transparency (graphic)'
        },
        {
          'id': 2045807287,
          'name': 'Brightness'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 46
    },
    {
      'id': 451199727,
      'title': 'Top-Push Video-Based Person Re-identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1220782732,
          'name': 'Jinjie You'
        },
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1131472851,
          'name': 'Xiang Li'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2032409045,
          'name': 'Disjoint sets'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 40
    },
    {
      'id': 452642776,
      'title': 'Neuron Segmentation Based on CNN with Semi-Supervised Regularization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1009785621,
          'name': 'Kun Xu'
        },
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        },
        {
          'id': 1463336942,
          'name': 'Ji-Song Guan'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2034957758,
          'name': 'Neuron'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 30
    },
    {
      'id': 453579669,
      'title': 'Gender and Smile Classification Using Deep Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2101276577,
          'abbr': 'NTU',
          'name': 'National Taiwan University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1094933525,
          'name': 'Kaipeng Zhang'
        },
        {
          'id': 1363173183,
          'name': 'Lianzhi Tan'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 13
    },
    {
      'id': 453765503,
      'title': 'Efficient 3D Room Shape Recovery from a Single Panorama',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1139002167,
          'name': 'Hao Yang'
        },
        {
          'id': 1161113214,
          'name': 'Hui Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045308702,
          'name': 'Constraint graph'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2003353278,
          'name': 'Panorama'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2004058443,
          'name': 'Line segment'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 20
    },
    {
      'id': 462279238,
      'title': 'Robust Scene Text Recognition with Automatic Rectification',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1154870261,
          'name': 'Baoguang Shi'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1433542305,
          'name': 'Pengyuan Lyu'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025838069,
          'name': 'Rectification'
        },
        {
          'id': 2001363999,
          'name': 'Perspective distortion'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Shi_Robust_Scene_Text_CVPR_2016_paper.html',
      'citation_count': 26,
      'reference_count': 44
    },
    {
      'id': 464767302,
      'title': 'Comparative Deep Learning of Hybrid Representations for Image Recommendations',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1048574741,
          'name': 'Chenyi Lei'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1109246004,
          'name': 'Weiping Li'
        },
        {
          'id': 1011720102,
          'name': 'Zheng-Jun Zha'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2005597368,
          'name': 'Deep belief network'
        },
        {
          'id': 2011268437,
          'name': 'Network complexity'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 33
    },
    {
      'id': 466914083,
      'title': 'Highway Vehicle Counting in Compressed Domain',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1179479654,
          'name': 'Xu Liu'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1031805824,
          'name': 'Hongsheng Xi'
        }
      ],
      'field': [
        {
          'id': 2028010657,
          'name': 'Metadata'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003059411,
          'name': 'Motion vector'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 33
    },
    {
      'id': 467808654,
      'title': 'On Benefits of Selection Diversity via Bilevel Exclusive Sparsity',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        },
        {
          'id': 2100861992,
          'abbr': 'UAB',
          'name': 'University of Alabama at Birmingham'
        },
        {
          'id': 2104054782,
          'abbr': 'UW',
          'name': 'University of Washington'
        }
      ],
      'author': [
        {
          'id': 1318251080,
          'name': 'Haichuan Yang'
        },
        {
          'id': 1351819629,
          'name': 'Yijun Huang'
        },
        {
          'id': 1132011066,
          'name': 'Lam Tran'
        },
        {
          'id': 1024609454,
          'name': 'Ji Liu'
        },
        {
          'id': 1260686628,
          'name': 'Shuai Huang'
        }
      ],
      'field': [
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2011741409,
          'name': 'Rate of convergence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025855638,
          'name': 'Empirical research'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 48
    },
    {
      'id': 468708368,
      'title': 'Learning Discriminative Features with Class Encoder',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104002633,
          'abbr': 'WUT',
          'name': 'Wuhan University of Technology'
        }
      ],
      'author': [
        {
          'id': 1477164535,
          'name': 'Hailin Shi'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1068901390,
          'name': 'Zhen Lei'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1605.html',
      'citation_count': 0,
      'reference_count': 28
    },
    {
      'id': 468835919,
      'title': 'Cascaded Interactional Targeting Network for Egocentric Video Analysis',
      'affiliation': [
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        }
      ],
      'author': [
        {
          'id': 1347697632,
          'name': 'Yang Zhou'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1188079795,
          'name': 'Richang Hong'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003588821,
          'name': 'Active object'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2010101173,
          'name': 'Small set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 45
    },
    {
      'id': 469564013,
      'title': 'Coordinating Multiple Disparity Proposals for Stereo Computation',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1373283712,
          'name': 'Ang Li'
        },
        {
          'id': 1037882621,
          'name': 'Dapeng Chen'
        },
        {
          'id': 1386612941,
          'name': 'Yuanliu Liu'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 37
    },
    {
      'id': 4769881,
      'title': 'Deep Structured Scene Parsing by Learning with Image Descriptions',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1177260794,
          'name': 'Guangrun Wang'
        },
        {
          'id': 1092132777,
          'name': 'Rui Zhang'
        },
        {
          'id': 1057785633,
          'name': 'Ruimao Zhang'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049518231,
          'name': 'Noun'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 38
    },
    {
      'id': 483425525,
      'title': 'Deep Residual Learning for Image Recognition',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1038906976,
          'name': 'Shaoqing Ren'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042893011,
          'name': 'Vanishing gradient problem'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 17586,
      'reference_count': 54
    },
    {
      'id': 486081433,
      'title': 'Automatic Image Cropping: A Computational Complexity Study',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1470618407,
          'name': 'Jiansheng Chen'
        },
        {
          'id': 1040738832,
          'name': 'Gaocheng Bai'
        },
        {
          'id': 1096618421,
          'name': 'Shaoheng Liang'
        },
        {
          'id': 1475971673,
          'name': 'Zhengqin Li'
        }
      ],
      'field': [
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032750659,
          'name': 'Cropping'
        },
        {
          'id': 2041081180,
          'name': 'Rectangle'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 26
    },
    {
      'id': 487935570,
      'title': 'Blind Image Deblurring Using Dark Channel Prior',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101712668,
          'abbr': 'HARVARD',
          'name': 'Harvard University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1330695574,
          'name': 'Deqing Sun'
        },
        {
          'id': 1449629447,
          'name': 'Hanspeter Pfister'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2005765806,
          'name': 'Linear approximation'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 142,
      'reference_count': 36
    },
    {
      'id': 488537883,
      'title': 'Object Skeleton Extraction in Natural Images by Fusing Scale-Associated Deep Side Outputs',
      'affiliation': [
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1463521460,
          'name': 'Kai Zhao'
        },
        {
          'id': 1428608778,
          'name': 'Yuan Jiang'
        },
        {
          'id': 1204480417,
          'name': 'Yan Wang'
        },
        {
          'id': 1123343745,
          'name': 'Zhijiang Zhang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 39
    },
    {
      'id': 488755526,
      'title': 'Robust Kernel Estimation with Outliers Handling for Image Deblurring',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034573784,
          'name': 'Image formation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 40
    },
    {
      'id': 489890594,
      'title': 'Traffic-Sign Detection and Classification in the Wild',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103220118,
          'abbr': 'LEHIGH',
          'name': 'Lehigh University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1003633930,
          'name': 'Zhe Zhu'
        },
        {
          'id': 1026297172,
          'name': 'Dun Liang'
        },
        {
          'id': 1059439771,
          'name': 'Song-Hai Zhang'
        },
        {
          'id': 1366939506,
          'name': 'Xiaolei Huang'
        },
        {
          'id': 1437681889,
          'name': 'Baoli Li'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 30
    },
    {
      'id': 493303841,
      'title': 'DeepBE: Learning Deep Binary Encoding for Multi-label Classification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1021177925,
          'name': 'Chenghua Li'
        },
        {
          'id': 1116619073,
          'name': 'Qi Kang'
        },
        {
          'id': 1227464812,
          'name': 'Guojing Ge'
        },
        {
          'id': 1194165957,
          'name': 'Qiang Song'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2036249034,
          'name': 'Multi-label classification'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2001153861,
          'name': 'Mean squared error'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 24
    },
    {
      'id': 494997945,
      'title': 'Camera Calibration from Periodic Motion of a Pedestrian',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1362988269,
          'name': 'Shiyao Huang'
        },
        {
          'id': 1233810335,
          'name': 'Xianghua Ying'
        },
        {
          'id': 1192611089,
          'name': 'Jiangpeng Rong'
        },
        {
          'id': 1030052913,
          'name': 'Zeyu Shang'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2044619755,
          'name': 'Vertical direction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021720001,
          'name': 'Harmonic conjugate'
        },
        {
          'id': 2041984668,
          'name': 'Line (geometry)'
        },
        {
          'id': 2040902695,
          'name': 'Horizontal plane'
        },
        {
          'id': 2026845680,
          'name': 'Perpendicular'
        },
        {
          'id': 2005547078,
          'name': 'Camera resectioning'
        },
        {
          'id': 2017968033,
          'name': 'Camera auto-calibration'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046361361,
          'name': 'Vanishing point'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 26
    },
    {
      'id': 55691268,
      'title': 'Efficient Coarse-to-Fine Patch Match for Large Displacement Optical Flow',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103738594,
          'abbr': 'WILEY',
          'name': 'John Wiley & Sons'
        }
      ],
      'author': [
        {
          'id': 1028169900,
          'name': 'Yinlin Hu'
        },
        {
          'id': 1319912337,
          'name': 'Rui Song'
        },
        {
          'id': 1054947225,
          'name': 'Yunsong Li'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2048609072,
          'name': 'Random search'
        },
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 50,
      'reference_count': 31
    },
    {
      'id': 59110487,
      'title': 'Pedestrian Detection Inspired by Appearance Constancy and Shape Symmetry',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1183180025,
          'name': 'Jiale Cao'
        },
        {
          'id': 1381098672,
          'name': 'Yanwei Pang'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 30
    },
    {
      'id': 62748738,
      'title': 'Pairwise Matching through Max-Weight Bipartite Belief Propagation',
      'affiliation': [
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1063073861,
          'name': 'Zhen Zhang'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        },
        {
          'id': 1167596707,
          'name': 'Julian McAuley'
        },
        {
          'id': 1444713601,
          'name': 'Wei Wei'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2013787702,
          'name': 'Belief propagation'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2022137271,
          'name': 'Spectral method'
        },
        {
          'id': 2045501904,
          'name': 'Bipartite graph'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 35
    },
    {
      'id': 64172494,
      'title': 'The Best of BothWorlds: Combining Data-Independent and Data-Driven Approaches for Action Recognition',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1489992503,
          'name': 'Zhenzhong Lan'
        },
        {
          'id': 1256961584,
          'name': 'Shoou-I Yu'
        },
        {
          'id': 1233862446,
          'name': 'Dezhong Yao'
        },
        {
          'id': 1274008613,
          'name': 'Ming Lin'
        },
        {
          'id': 1413245183,
          'name': 'Bhiksha Raj'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047973581,
          'name': 'Data-driven'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 49
    },
    {
      'id': 65745884,
      'title': 'Ordinal Regression with Multiple Output CNN for Age Estimation',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101456381,
          'abbr': '',
          'name': 'Microsoft Research Asia (China)'
        }
      ],
      'author': [
        {
          'id': 1306869427,
          'name': 'Zhenxing Niu'
        },
        {
          'id': 1256945730,
          'name': 'Mo Zhou'
        },
        {
          'id': 1238522754,
          'name': 'Le Wang'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036309585,
          'name': 'Ordinal regression'
        },
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 110,
      'reference_count': 31
    },
    {
      'id': 67332812,
      'title': 'Person Re-identification by Multi-Channel Parts-Based CNN with Improved Triplet Loss Function',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1300355815,
          'name': 'De Cheng'
        },
        {
          'id': 1136791764,
          'name': 'Yihong Gong'
        },
        {
          'id': 1142696844,
          'name': 'Sanping Zhou'
        },
        {
          'id': 1072625952,
          'name': 'Jinjun Wang'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009605270,
          'name': 'VIPeR'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 276,
      'reference_count': 46
    },
    {
      'id': 68031842,
      'title': 'HyperNet: Towards Accurate Region Proposal Generation and Joint Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1242912686,
          'name': 'Tao Kong'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1233632450,
          'name': 'Fuchun Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2020557398,
          'name': 'Uniform space'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 156,
      'reference_count': 38
    },
    {
      'id': 75075147,
      'title': 'Actionness Estimation Using Hybrid Fully Convolutional Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        }
      ],
      'author': [
        {
          'id': 1080083793,
          'name': 'Limin Wang'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        }
      ],
      'field': [
        {
          'id': 2003271894,
          'name': 'Generic Action'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://arxiv.org/pdf/1604.07279.pdf',
      'citation_count': 9,
      'reference_count': 47
    },
    {
      'id': 84398985,
      'title': 'Compact Bilinear Pooling',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        }
      ],
      'author': [
        {
          'id': 1400133953,
          'name': 'Yang Gao'
        },
        {
          'id': 1003456287,
          'name': 'Oscar Beijbom'
        },
        {
          'id': 1405381065,
          'name': 'Ning Zhang'
        },
        {
          'id': 1255937776,
          'name': 'Trevor Darrell'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Gao_Compact_Bilinear_Pooling_CVPR_2016_paper.html',
      'citation_count': 30,
      'reference_count': 38
    },
    {
      'id': 84523237,
      'title': 'Temporal Action Localization with Pyramid of Score Distribution Features',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1210753126,
          'name': 'Jun Yuan'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1117175455,
          'name': 'Ashraf A. Kassim'
        }
      ],
      'field': [
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2031569861,
          'name': 'Performance improvement'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 78,
      'reference_count': 36
    },
    {
      'id': 86479138,
      'title': 'Texture Complexity Based Redundant Regions Ranking for Object Proposal',
      'affiliation': [
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1030826032,
          'name': 'Wei Ke'
        },
        {
          'id': 1244795700,
          'name': 'Tianliang Zhang'
        },
        {
          'id': 1223927465,
          'name': 'Jie Chen'
        },
        {
          'id': 1437119785,
          'name': 'Fang Wan'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1091190936,
          'name': 'Zhenjun Han'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 24
    },
    {
      'id': 88051522,
      'title': 'Towards Facial Expression Recognition in the Wild: A New Database and Deep Recognition System',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1471113063,
          'name': 'Xianlin Peng'
        },
        {
          'id': 1259502751,
          'name': 'Zhaoqiang Xia'
        },
        {
          'id': 1000106526,
          'name': 'Lei Li'
        },
        {
          'id': 1024183183,
          'name': 'Xiaoyi Feng'
        }
      ],
      'field': [
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035990448,
          'name': 'Database'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 24
    },
    {
      'id': 89497071,
      'title': 'MovieQA: Understanding Stories in Movies through Question-Answering',
      'affiliation': [
        {
          'id': 2104591464,
          'abbr': 'KIT',
          'name': 'Karlsruhe Institute of Technology'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1297004123,
          'name': 'Makarand Tapaswi'
        },
        {
          'id': 1201204531,
          'name': 'Yukun Zhu'
        },
        {
          'id': 1451943888,
          'name': 'Rainer Stiefelhagen'
        },
        {
          'id': 1464617438,
          'name': 'Antonio Torralba'
        },
        {
          'id': 1474294385,
          'name': 'Raquel Urtasun'
        },
        {
          'id': 1352379588,
          'name': 'Sanja Fidler'
        }
      ],
      'field': [
        {
          'id': 2009659598,
          'name': 'Comprehension'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2036641578,
          'name': 'CLIPS'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042622191,
          'name': 'Scripting language'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Tapaswi_MovieQA_Understanding_Stories_CVPR_2016_paper.html',
      'citation_count': 31,
      'reference_count': 49
    },
    {
      'id': 90242676,
      'title': 'Learning Structured Inference Neural Networks with Label Relations',
      'affiliation': [
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        },
        {
          'id': 2103565249,
          'abbr': 'SFU',
          'name': 'Simon Fraser University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1191938240,
          'name': 'Hexiang Hu'
        },
        {
          'id': 1080402916,
          'name': 'Guang-Tong Zhou'
        },
        {
          'id': 1400458390,
          'name': 'Zhiwei Deng'
        },
        {
          'id': 1061710418,
          'name': 'Zicheng Liao'
        },
        {
          'id': 1481166169,
          'name': 'Greg Mori'
        }
      ],
      'field': [
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2032401552,
          'name': 'Abstraction'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': 'http://www.cs.sfu.ca/~mori/research/papers/hu-cvpr16.pdf',
      'citation_count': 9,
      'reference_count': 38
    },
    {
      'id': 91310542,
      'title': 'Tensor Robust Principal Component Analysis: Exact Recovery of Corrupted Low-Rank Tensors via Convex Optimization',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1081691569,
          'name': 'Canyi Lu'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1260911928,
          'name': 'Yudong Chen'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2017639414,
          'name': 'Cartesian tensor'
        },
        {
          'id': 2000994823,
          'name': 'Tensor field'
        },
        {
          'id': 2034021448,
          'name': 'Tensor density'
        },
        {
          'id': 2033803715,
          'name': 'Tensor contraction'
        },
        {
          'id': 2022175790,
          'name': 'Tensor (intrinsic definition)'
        },
        {
          'id': 2040873464,
          'name': 'Symmetric tensor'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2004348315,
          'name': 'Tensor product of Hilbert spaces'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 67,
      'reference_count': 27
    },
    {
      'id': 96298625,
      'title': 'Multi-view Multi-modal Feature Embedding for Endomicroscopy Mosaic Classification',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100984406,
          'abbr': 'IMPERIAL',
          'name': 'Imperial College London'
        }
      ],
      'author': [
        {
          'id': 1218972288,
          'name': 'Yun Gu'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        },
        {
          'id': 1075109834,
          'name': 'Guang-Zhong Yang'
        }
      ],
      'field': [
        {
          'id': 2014759039,
          'name': 'Endomicroscopy'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041216465,
          'name': 'Texton'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 23
    },
    {
      'id': 98101636,
      'title': 'Temporal Multimodal Learning in Audiovisual Speech Recognition',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1088670356,
          'name': 'Di Hu'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        },
        {
          'id': 1356856029,
          'name': 'Xiaoqiang Lu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2011150678,
          'name': 'Multimodal learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2047934002,
          'name': 'Boltzmann machine'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2134928760',
        'name': 'CVPR',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 29
    },
    {
      'id': 104248666,
      'title': 'Understanding image structure via hierarchical shape parsing',
      'affiliation': [
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2104613919,
          'abbr': 'HQU',
          'name': 'Huaqiao University'
        }
      ],
      'author': [
        {
          'id': 1422216204,
          'name': 'Xianming Liu'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1288576539,
          'name': 'Changhu Wang'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1404363237,
          'name': 'Bineng Zhong'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006582462,
          'name': 'Bundle'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2018324713,
          'name': 'Scale space'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 42
    },
    {
      'id': 108202602,
      'title': 'Principles of Magneto-optic Imaging and Its Applications',
      'affiliation': [
        {
          'id': 2104041736,
          'abbr': 'UCD',
          'name': 'University of Colorado Denver'
        },
        {
          'id': 2100494269,
          'abbr': 'GEHEALTHCARE',
          'name': 'GE Healthcare'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1277202514,
          'name': 'Yiming Deng'
        },
        {
          'id': 1014796759,
          'name': 'Yuhua Cheng'
        },
        {
          'id': 1212562301,
          'name': 'Liang Xuan'
        },
        {
          'id': 1207830552,
          'name': 'Zhiwei Zeng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031909882,
          'name': 'Binary image'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010727174,
          'name': 'Discrete wavelet transform'
        },
        {
          'id': 2005237766,
          'name': 'Eddy current'
        },
        {
          'id': 2004428869,
          'name': 'Electrical impedance'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000462507,
          'name': 'Magneto'
        },
        {
          'id': 2015750066,
          'name': 'Nondestructive testing'
        },
        {
          'id': 2014701733,
          'name': 'Eddy-current testing'
        },
        {
          'id': 2030809048,
          'name': 'Faraday cage'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 36
    },
    {
      'id': 111595321,
      'title': 'Clique-graph matching by preserving global & local structure',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2104025124,
          'abbr': 'TJUT',
          'name': 'Tianjin University of Technology'
        }
      ],
      'author': [
        {
          'id': 1133282074,
          'name': 'Weizhi Nie'
        },
        {
          'id': 1080635904,
          'name': 'Anan Liu'
        },
        {
          'id': 1142910056,
          'name': 'Zan Gao'
        },
        {
          'id': 1086591118,
          'name': 'Yuting Su'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2003528380,
          'name': 'Clique'
        },
        {
          'id': 2037836576,
          'name': 'Clique graph'
        },
        {
          'id': 2001973016,
          'name': 'Split graph'
        },
        {
          'id': 2005625151,
          'name': 'Block graph'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2046002229,
          'name': 'Simplex graph'
        },
        {
          'id': 2018261767,
          'name': 'Clique (graph theory)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 41,
      'reference_count': 22
    },
    {
      'id': 116264189,
      'title': 'Joint patch and multi-label learning for facial action unit detection',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1208665046,
          'name': 'Kaili Zhao'
        },
        {
          'id': 1357110762,
          'name': 'Wen-Sheng Chu'
        },
        {
          'id': 1300722889,
          'name': 'Fernando De la Torre'
        },
        {
          'id': 1442054127,
          'name': 'Jeffrey F. Cohn'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        }
      ],
      'field': [
        {
          'id': 2012955293,
          'name': 'Facial Action Coding System'
        },
        {
          'id': 2040767902,
          'name': 'Nonverbal communication'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2007851922,
          'name': 'Facial muscles'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 36
    },
    {
      'id': 116696490,
      'title': 'DeepContour: A deep convolutional feature learned by positive-sharing loss for contour detection',
      'affiliation': [
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1204480417,
          'name': 'Yan Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1123343745,
          'name': 'Zhijiang Zhang'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2041513897,
          'name': 'Subclass'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 211,
      'reference_count': 47
    },
    {
      'id': 117174172,
      'title': 'UniHIST: A unified framework for image restoration with marginal histogram constraints',
      'affiliation': [
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1483429238,
          'name': 'Xing Mei'
        },
        {
          'id': 1442505283,
          'name': 'Weiming Dong'
        },
        {
          'id': 1341931364,
          'name': 'Bao-Gang Hu'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041409623,
          'name': 'Density estimation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 38
    },
    {
      'id': 118799507,
      'title': 'Depth and surface normal estimation from monocular images using regression on deep features and hierarchical CRFs',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1126297935,
          'name': 'Bo Li'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        },
        {
          'id': 1446146009,
          'name': 'Mingyi He'
        }
      ],
      'field': [
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2002018009,
          'name': 'Normal'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2036255561,
          'name': 'CRFS'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030062540,
          'name': 'Underdetermined system'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 205,
      'reference_count': 31
    },
    {
      'id': 119897909,
      'title': 'Discriminant analysis on Riemannian manifold of Gaussian distributions for face recognition with image sets',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1164317530,
          'name': 'Wen Wang'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1030210329,
          'name': 'Zhiwu Huang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2039760485,
          'name': 'Gaussian function'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2029885454,
          'name': 'Kernel Fisher discriminant analysis'
        },
        {
          'id': 2049479510,
          'name': 'Riemannian geometry'
        },
        {
          'id': 2024511208,
          'name': 'Riemannian manifold'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017117862,
          'name': 'Information geometry'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 39
    },
    {
      'id': 121042847,
      'title': 'Efficient 3D kernel estimation for non-uniform camera shake removal using perpendicular camera system',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1311917372,
          'name': 'Tao Yue'
        },
        {
          'id': 1462346539,
          'name': 'Jinli Suo'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2006278524,
          'name': 'RANSAC'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001180820,
          'name': '3D projection'
        },
        {
          'id': 2024154092,
          'name': 'Kernel smoother'
        },
        {
          'id': 2006122888,
          'name': 'Radial basis function kernel'
        },
        {
          'id': 2043785615,
          'name': 'Variable kernel density estimation'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 29
    },
    {
      'id': 132902178,
      'title': 'Deep networks for saliency detection via local estimation and global search',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2005485485,
          'name': 'Saliency map'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 232,
      'reference_count': 43
    },
    {
      'id': 134972135,
      'title': 'Early burst detection for memory-efficient image retrieval',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101658193,
          'abbr': 'UOA',
          'name': 'National and Kapodistrian University of Athens'
        },
        {
          'id': 2104422715,
          'abbr': 'INRIA',
          'name': 'French Institute for Research in Computer Science and Automation'
        }
      ],
      'author': [
        {
          'id': 1061723194,
          'name': 'Miaojing Shi'
        },
        {
          'id': 1388530550,
          'name': 'Yannis S. Avrithis'
        },
        {
          'id': 1249876727,
          'name': 'Hervé Jégou'
        }
      ],
      'field': [
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 46
    },
    {
      'id': 136986894,
      'title': 'Joint multi-feature spatial context for scene recognition in the semantic manifold',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1186202391,
          'name': 'Xinhang Song'
        },
        {
          'id': 1266998431,
          'name': 'Shuqiang Jiang'
        },
        {
          'id': 1230607955,
          'name': 'Luis Herranz'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028025525,
          'name': 'Semantic similarity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006804888,
          'name': 'Multinomial distribution'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 39
    },
    {
      'id': 137622818,
      'title': 'Long-term correlation tracking',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1053338084,
          'name': 'Chao Ma'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1153867412,
          'name': 'Chongyang Zhang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2001611344,
          'name': 'Temporal context'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 359,
      'reference_count': 30
    },
    {
      'id': 140707677,
      'title': 'JOTS: Joint Online Tracking and Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 58,
      'reference_count': 28
    },
    {
      'id': 140877117,
      'title': 'Improving object detection with deep convolutional networks via Bayesian optimization and structured prediction',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        }
      ],
      'author': [
        {
          'id': 1434795179,
          'name': 'Yuting Zhang'
        },
        {
          'id': 1063709080,
          'name': 'Kihyuk Sohn'
        },
        {
          'id': 1372430324,
          'name': 'Ruben Villegas'
        },
        {
          'id': 1402712917,
          'name': 'Gang Pan'
        },
        {
          'id': 1301389252,
          'name': 'Honglak Lee'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008983747,
          'name': 'Bayesian optimization'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2037674894,
          'name': 'Search algorithm'
        },
        {
          'id': 2005310215,
          'name': 'Structured prediction'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 110,
      'reference_count': 46
    },
    {
      'id': 149513971,
      'title': 'Subspace clustering by Mixture of Gaussian Regression',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1050505036,
          'name': 'Baohua Li'
        },
        {
          'id': 1056841273,
          'name': 'Ying Zhang'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2035842457,
          'name': 'Gaussian process'
        },
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2015043929,
          'name': 'Statistics'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 34
    },
    {
      'id': 155782760,
      'title': 'Projection Metric Learning on Grassmann Manifold with Application to Video based Face Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1030210329,
          'name': 'Zhiwu Huang'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2029691725,
          'name': 'Fisher information metric'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017117862,
          'name': 'Information geometry'
        },
        {
          'id': 2014134030,
          'name': 'Statistical manifold'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018882205,
          'name': 'Manifold alignment'
        },
        {
          'id': 2004101032,
          'name': 'Euclidean space'
        },
        {
          'id': 2020447605,
          'name': 'Pseudo-Riemannian manifold'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2004914173,
          'name': 'Hilbert manifold'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2026937781,
          'name': 'Topology'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 96,
      'reference_count': 45
    },
    {
      'id': 161193523,
      'title': 'Jointly learning heterogeneous features for RGB-D activity recognition',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103751681,
          'abbr': 'DUNDEE',
          'name': 'University of Dundee'
        }
      ],
      'author': [
        {
          'id': 1225752713,
          'name': 'Jian-Fang Hu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1255955000,
          'name': 'Jianguo Zhang'
        }
      ],
      'field': [
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026135480,
          'name': 'Instance-based learning'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 153,
      'reference_count': 41
    },
    {
      'id': 161408722,
      'title': '3D model-based continuous emotion recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1073037128,
          'name': 'Hui Chen'
        },
        {
          'id': 1224268650,
          'name': 'Jiangdong Li'
        },
        {
          'id': 1137295227,
          'name': 'Fengjun Zhang'
        },
        {
          'id': 1069085225,
          'name': 'Yang Li'
        },
        {
          'id': 1139771374,
          'name': 'Hongan Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2047154298,
          'name': 'Correlation coefficient'
        },
        {
          'id': 2000503217,
          'name': 'Emotion recognition'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 38
    },
    {
      'id': 161767844,
      'title': 'Salient object detection via bootstrap learning',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1302389358,
          'name': 'Na Tong'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 140,
      'reference_count': 42
    },
    {
      'id': 162826853,
      'title': 'Light field from micro-baseline image pair',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1331248684,
          'name': 'Zhoutong Zhang'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2038412597,
          'name': 'Image-based modeling and rendering'
        },
        {
          'id': 2019481560,
          'name': 'Steerable pyramid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2016541624,
          'name': 'Speech coding'
        },
        {
          'id': 2008086083,
          'name': 'Ringing artifacts'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 36
    },
    {
      'id': 163264682,
      'title': 'A maximum entropy feature descriptor for age invariant face recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1135404713,
          'name': 'Dihong Gong'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1381503284,
          'name': 'Jianzhuang Liu'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021895381,
          'name': 'Principle of maximum entropy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020482756,
          'name': 'Generalizability theory'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 51
    },
    {
      'id': 167037875,
      'title': 'Deep semantic ranking based hashing for multi-label image retrieval',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1272920548,
          'name': 'Fang Zhao'
        },
        {
          'id': 1260597004,
          'name': 'Yongzhen Huang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2028025525,
          'name': 'Semantic similarity'
        },
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 252,
      'reference_count': 35
    },
    {
      'id': 171841711,
      'title': 'Multi-manifold deep metric learning for image set classification',
      'affiliation': [
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1427738889,
          'name': 'Gang Wang'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1379785310,
          'name': 'Pierre Moulin'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000790668,
          'name': 'Viewpoints'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021185547,
          'name': 'Object Class'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 73,
      'reference_count': 43
    },
    {
      'id': 17243199,
      'title': 'Super-resolution Person re-identification with semi-coupled low-rank discriminant dictionary learning',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104697949,
          'abbr': 'NUPT',
          'name': 'Nanjing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1349079166,
          'name': 'Xiao-Yuan Jing'
        },
        {
          'id': 1466076323,
          'name': 'Xiaoke Zhu'
        },
        {
          'id': 1084815636,
          'name': 'Fei Wu'
        },
        {
          'id': 1269583289,
          'name': 'Xinge You'
        },
        {
          'id': 1341994956,
          'name': 'Qinglong Liu'
        },
        {
          'id': 1026273676,
          'name': 'Dong Yue'
        },
        {
          'id': 1307434978,
          'name': 'Ruimin Hu'
        },
        {
          'id': 1374344598,
          'name': 'Baowen Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007410355,
          'name': 'Discriminant'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 70,
      'reference_count': 37
    },
    {
      'id': 173417330,
      'title': 'Face video retrieval with image query via hashing across Euclidean space and Riemannian manifold',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1150911725,
          'name': 'Yan Li'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1030210329,
          'name': 'Zhiwu Huang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2009945168,
          'name': 'Hilbert space'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024511208,
          'name': 'Riemannian manifold'
        },
        {
          'id': 2004101032,
          'name': 'Euclidean space'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 45
    },
    {
      'id': 191567662,
      'title': 'Simulating makeup through physics-based manipulation of intrinsic image layers',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1032681086,
          'name': 'Chen Li'
        },
        {
          'id': 1266662130,
          'name': 'Kun Zhou'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047672818,
          'name': 'Instrumental and intrinsic value'
        },
        {
          'id': 2027651673,
          'name': 'Computer graphics (images)'
        },
        {
          'id': 2001942329,
          'name': 'Reflectivity'
        },
        {
          'id': 2025112408,
          'name': 'Eye shadow'
        },
        {
          'id': 2003450942,
          'name': 'Image-based lighting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 23
    },
    {
      'id': 195481345,
      'title': 'Weakly supervised semantic segmentation for social images',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1039939827,
          'name': 'Wei Zhang'
        },
        {
          'id': 1327828939,
          'name': 'Sheng Zeng'
        },
        {
          'id': 1016332717,
          'name': 'Dequan Wang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2006440307,
          'name': 'Iterative refinement'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033757095,
          'name': 'Topic model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 34
    },
    {
      'id': 198211561,
      'title': 'Person re-identification by Local Maximal Occurrence representation and metric learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1010968140,
          'name': 'Yang Hu'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010264609,
          'name': 'Color constancy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041433144,
          'name': 'Grid'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2012362224,
          'name': 'Scale invariance'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2028335632,
          'name': 'Quadratic classifier'
        },
        {
          'id': 2007410355,
          'name': 'Discriminant'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 732,
      'reference_count': 50
    },
    {
      'id': 199613723,
      'title': 'From dictionary of visual words to subspaces: Locality-constrained affine subspace coding',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1251034596,
          'name': 'Xiaoxiao Lu'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        }
      ],
      'field': [
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2029691725,
          'name': 'Fisher information metric'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2047657425,
          'name': 'Piecewise linear function'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2016777268,
          'name': 'Affine space'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 43
    },
    {
      'id': 206881592,
      'title': 'High-speed hyperspectral video acquisition with a dual-camera architecture',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1372850284,
          'name': 'Lizhi Wang'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1296954301,
          'name': 'Dahua Gao'
        },
        {
          'id': 1291914506,
          'name': 'Guangming Shi'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2017318404,
          'name': 'Frame rate'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029110680,
          'name': 'Uncompressed video'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2022305872,
          'name': 'Video denoising'
        },
        {
          'id': 2046246198,
          'name': 'Video post-processing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2014162048,
          'name': 'Multiview Video Coding'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 35
    },
    {
      'id': 219012445,
      'title': 'SOM: Semantic obviousness metric for image quality assessment',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1054273230,
          'name': 'Peng Zhang'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1341367658,
          'name': 'Lei Wu'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 28
    },
    {
      'id': 219436961,
      'title': 'Dense, accurate optical flow estimation with piecewise parametric model',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        }
      ],
      'field': [
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2049269054,
          'name': 'Homogeneous'
        },
        {
          'id': 2042130964,
          'name': 'Parametric model'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 55,
      'reference_count': 50
    },
    {
      'id': 224382783,
      'title': 'Dual domain filters based texture and structure preserving image non-blind deconvolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104206252,
          'abbr': '吉大',
          'name': 'Jilin University'
        }
      ],
      'author': [
        {
          'id': 1494463969,
          'name': 'Hang Yang'
        },
        {
          'id': 1054307464,
          'name': 'Ming Zhu'
        },
        {
          'id': 1095937893,
          'name': 'Yan Niu'
        },
        {
          'id': 1022963841,
          'name': 'Yujing Guan'
        },
        {
          'id': 1440224309,
          'name': 'Zhongbo Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2016673644,
          'name': 'Wiener deconvolution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045758093,
          'name': 'Blind deconvolution'
        },
        {
          'id': 2026344762,
          'name': 'Hybrid algorithm'
        },
        {
          'id': 2003093565,
          'name': 'Fourier transform'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 32
    },
    {
      'id': 225482477,
      'title': 'Pedestrian detection aided by deep learning semantic tasks',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1119735106,
          'name': 'Yonglong Tian'
        },
        {
          'id': 1275825081,
          'name': 'Ping Luo'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 149,
      'reference_count': 43
    },
    {
      'id': 228630758,
      'title': 'Discriminative learning of iteration-wise priors for blind deconvolution',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1236132553,
          'name': 'Dongwei Ren'
        },
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2045758093,
          'name': 'Blind deconvolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 39
    },
    {
      'id': 243763228,
      'title': 'Co-saliency detection via looking deep and wide',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102978668,
          'abbr': 'NU',
          'name': 'Northwest University'
        }
      ],
      'author': [
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1400774619,
          'name': 'Chao Li'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 45
    },
    {
      'id': 250884912,
      'title': 'Handling motion blur in multi-frame super-resolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1329825635,
          'name': 'Ziyang Ma'
        },
        {
          'id': 1308454511,
          'name': 'Renjie Liao'
        },
        {
          'id': 1474323680,
          'name': 'Xin Tao'
        },
        {
          'id': 1145110683,
          'name': 'Li Xu'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        },
        {
          'id': 1290762362,
          'name': 'Enhua Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031538077,
          'name': 'Gaussian blur'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 32
    },
    {
      'id': 251268673,
      'title': 'The application of two-level attention models in deep convolutional neural network for fine-grained image classification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104027711,
          'abbr': '',
          'name': 'New York University Shanghai'
        }
      ],
      'author': [
        {
          'id': 1362415142,
          'name': 'Tianjun Xiao'
        },
        {
          'id': 1192196952,
          'name': 'Yichong Xu'
        },
        {
          'id': 1047940084,
          'name': 'Kuiyuan Yang'
        },
        {
          'id': 1481720822,
          'name': 'Jiaxing Zhang'
        },
        {
          'id': 1111804441,
          'name': 'Yuxin Peng'
        },
        {
          'id': 1350894404,
          'name': 'Zheng Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 242,
      'reference_count': 26
    },
    {
      'id': 252147101,
      'title': 'Global refinement of random forest',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1038906976,
          'name': 'Shaoqing Ren'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2035276397,
          'name': 'Pruning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029498710,
          'name': 'Spite'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025449743,
          'name': 'Learning rule'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 36
    },
    {
      'id': 254098127,
      'title': 'A new retraction for accelerating the Riemannian three-factor low-rank matrix completion algorithm',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104941014,
          'abbr': 'hTc',
          'name': 'HTC'
        }
      ],
      'author': [
        {
          'id': 1206060282,
          'name': 'Zhizhong Li'
        },
        {
          'id': 1320668265,
          'name': 'Deli Zhao'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1281300734,
          'name': 'Edward Y. Chang'
        }
      ],
      'field': [
        {
          'id': 2004564439,
          'name': 'Matrix completion'
        },
        {
          'id': 2036032756,
          'name': 'Tangent'
        },
        {
          'id': 2040398505,
          'name': 'Line search'
        },
        {
          'id': 2024511208,
          'name': 'Riemannian manifold'
        },
        {
          'id': 2033464047,
          'name': 'Low-rank approximation'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2018879351,
          'name': 'Quotient'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 11
    },
    {
      'id': 254360217,
      'title': 'Saliency-aware geodesic video object segmentation',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        }
      ],
      'field': [
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2009466758,
          'name': 'Location model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 201,
      'reference_count': 32
    },
    {
      'id': 261829674,
      'title': 'Data-driven sparsity-based restoration of JPEG-compressed images in dual transform-pixel domain',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101000664,
          'abbr': 'MCMASTER',
          'name': 'McMaster University'
        },
        {
          'id': 2100888578,
          'abbr': 'UM',
          'name': 'University of Macau'
        }
      ],
      'author': [
        {
          'id': 1164703402,
          'name': 'Xianming Liu'
        },
        {
          'id': 1304233103,
          'name': 'Xiaolin Wu'
        },
        {
          'id': 1069159330,
          'name': 'Jiantao Zhou'
        },
        {
          'id': 1149572625,
          'name': 'Debin Zhao'
        }
      ],
      'field': [
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2031426542,
          'name': 'Quantization (image processing)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035024680,
          'name': 'Discrete cosine transform'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 31
    },
    {
      'id': 269262702,
      'title': 'Structured Sparse Subspace Clustering: A unified optimization framework',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1067719877,
          'name': 'Chun-Guang Li'
        },
        {
          'id': 1484844444,
          'name': 'René Vidal'
        }
      ],
      'field': [
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 75,
      'reference_count': 49
    },
    {
      'id': 274941862,
      'title': 'Object proposal by multi-branch hierarchical segmentation',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1447728128,
          'name': 'Chaoyang Wang'
        },
        {
          'id': 1028628137,
          'name': 'Long Zhao'
        },
        {
          'id': 1158233654,
          'name': 'Shuang Liang'
        },
        {
          'id': 1195526895,
          'name': 'Liqing Zhang'
        },
        {
          'id': 1275824770,
          'name': 'Jinyuan Jia'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 27
    },
    {
      'id': 281095263,
      'title': 'Modeling deformable gradient compositions for single-image super-resolution',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1312078133,
          'name': 'Yu Zhu'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1472072626,
          'name': 'Boyan Bonev'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031974094,
          'name': 'Vector field'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 30
    },
    {
      'id': 287342753,
      'title': 'Deep hashing for compact binary codes learning',
      'affiliation': [
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1476100718,
          'name': 'Venice Erin Liong'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1427738889,
          'name': 'Gang Wang'
        },
        {
          'id': 1379785310,
          'name': 'Pierre Moulin'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2020762433,
          'name': 'Visual search'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008291660,
          'name': 'Projection (linear algebra)'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 248,
      'reference_count': 37
    },
    {
      'id': 288278627,
      'title': 'Similarity learning on an explicit polynomial kernel feature map for person re-identification',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103556481,
          'abbr': 'SIT',
          'name': 'Stevens Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1037882621,
          'name': 'Dapeng Chen'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2002446881,
          'name': 'Kernel method'
        },
        {
          'id': 2012712064,
          'name': 'Similarity learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034769815,
          'name': 'Mahalanobis distance'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031785153,
          'name': 'Polynomial kernel'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 131,
      'reference_count': 39
    },
    {
      'id': 291208755,
      'title': 'Computer Vision and Pattern Recognition in Environmental Informatics',
      'affiliation': [
        {
          'id': 2100347986,
          'abbr': 'GRIFFITH',
          'name': 'Griffith University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104380003,
          'abbr': '',
          'name': 'University of Melbourne'
        }
      ],
      'author': [
        {
          'id': 1331593199,
          'name': 'Jun Zhou'
        },
        {
          'id': 1223969568,
          'name': 'Xiao Bai'
        },
        {
          'id': 1004364879,
          'name': 'Terry Caelli'
        }
      ],
      'field': [
        {
          'id': 2023903302,
          'name': 'Environmental monitoring'
        },
        {
          'id': 2048802152,
          'name': 'Environmental informatics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023612680,
          'name': 'Sensor fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006037874,
          'name': 'Data science'
        },
        {
          'id': 2002392938,
          'name': 'Environmental data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046794490,
          'name': 'Data mining'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2005202005,
          'name': 'Match moving'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 292415794,
      'title': 'DevNet: A Deep Event Network for multimedia event detection and evidence recounting',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1133501095,
          'name': 'Naiyan Wang'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1173748546,
          'name': 'Dit Yan Yeung'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015276722,
          'name': 'Intrinsic and extrinsic properties (philosophy)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006310969,
          'name': 'The Internet'
        },
        {
          'id': 2005485485,
          'name': 'Saliency map'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013924117,
          'name': 'TRECVID'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 153,
      'reference_count': 51
    },
    {
      'id': 296191447,
      'title': 'Sparse composite quantization',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1009100021,
          'name': 'Ting Zhang'
        },
        {
          'id': 1240277240,
          'name': 'Guo-Jun Qi'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2028189100,
          'name': 'Vector quantization'
        },
        {
          'id': 2013285993,
          'name': 'Linde–Buzo–Gray algorithm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039166637,
          'name': 'Inverted index'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003527042,
          'name': 'Learning vector quantization'
        },
        {
          'id': 2007802918,
          'name': 'Lookup table'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2029254938,
          'name': 'Composite number'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 52,
      'reference_count': 34
    },
    {
      'id': 297099023,
      'title': 'Saturation-preserving specular reflection separation',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102156196,
          'abbr': 'NAIST',
          'name': 'Nara Institute of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1386612941,
          'name': 'Yuanliu Liu'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        },
        {
          'id': 1448998456,
          'name': 'Yang Wu'
        }
      ],
      'field': [
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2013078986,
          'name': 'Specular reflection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2013822762,
          'name': 'Specular highlight'
        },
        {
          'id': 2026221349,
          'name': 'Chromaticity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049663528,
          'name': 'Diffuse reflection'
        },
        {
          'id': 2015394621,
          'name': 'Hue'
        },
        {
          'id': 2004009963,
          'name': 'Saturation (chemistry)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 21
    },
    {
      'id': 304236187,
      'title': 'Semantics-preserving hashing for cross-view retrieval',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1361176412,
          'name': 'Zijia Lin'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1130136473,
          'name': 'Mingqing Hu'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        }
      ],
      'field': [
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004577253,
          'name': '2-choice hashing'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 147,
      'reference_count': 27
    },
    {
      'id': 306210254,
      'title': 'SOLD: Sub-optimal low-rank decomposition for efficient video segmentation',
      'affiliation': [
        {
          'id': 2102878340,
          'abbr': '安大',
          'name': 'Anhui University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1214658663,
          'name': 'Chenglong Li'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1483002092,
          'name': 'Jin Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2006193051,
          'name': 'Rank (linear algebra)'
        },
        {
          'id': 2022305872,
          'name': 'Video denoising'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 28
    },
    {
      'id': 317910391,
      'title': 'Improving object proposals with multi-thresholding straddling expansion',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1331893764,
          'name': 'Xiaozhi Chen'
        },
        {
          'id': 1144842077,
          'name': 'Huimin Ma'
        },
        {
          'id': 1362478452,
          'name': 'Xiang Wang'
        },
        {
          'id': 1274336316,
          'name': 'Zhichen Zhao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033391627,
          'name': 'Overhead (computing)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002379278,
          'name': 'Diversification (marketing strategy)'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 58,
      'reference_count': 24
    },
    {
      'id': 318120936,
      'title': 'Diversity-induced Multi-view Subspace Clustering',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1179117743,
          'name': 'Changqing Zhang'
        },
        {
          'id': 1357204816,
          'name': 'Huazhu Fu'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1111205108,
          'name': 'Hua Zhang'
        }
      ],
      'field': [
        {
          'id': 2014337830,
          'name': 'Fuzzy clustering'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2024545963,
          'name': 'FLAME clustering'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2001604607,
          'name': 'Canopy clustering algorithm'
        },
        {
          'id': 2043009089,
          'name': 'Brown clustering'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2035951257,
          'name': 'CURE data clustering algorithm'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 30
    },
    {
      'id': 320281251,
      'title': 'Symmetry-based text line detection in natural scenes',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        }
      ],
      'author': [
        {
          'id': 1377460166,
          'name': 'Zheng Zhang'
        },
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030909330,
          'name': 'Adaptability'
        },
        {
          'id': 2023361932,
          'name': 'Scene statistics'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 160,
      'reference_count': 50
    },
    {
      'id': 326754043,
      'title': 'Heteroscedastic max-min distance analysis',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1204542489,
          'name': 'Bing Su'
        },
        {
          'id': 1234300559,
          'name': 'Xiaoqing Ding'
        },
        {
          'id': 1048604432,
          'name': 'Changsong Liu'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2007271113,
          'name': 'Homoscedasticity'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2037674894,
          'name': 'Search algorithm'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2021562912,
          'name': 'MMDA'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 28
    },
    {
      'id': 329562712,
      'title': 'ℓ 0 TV: A new method for image restoration in the presence of impulse noise',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1239998239,
          'name': 'Ganzhao Yuan'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        }
      ],
      'field': [
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2007571054,
          'name': 'Impulse noise'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 49
    },
    {
      'id': 329723244,
      'title': 'Active sample selection and correction propagation on a gradually-augmented graph',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101483039,
          'abbr': 'MST',
          'name': 'Missouri University of Science and Technology'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1331788745,
          'name': 'Zhaozheng Yin'
        },
        {
          'id': 1345191262,
          'name': 'Takeo Kanade'
        },
        {
          'id': 1208636001,
          'name': 'Seungil Huh'
        }
      ],
      'field': [
        {
          'id': 2047705465,
          'name': 'Complex manifold'
        },
        {
          'id': 2047381697,
          'name': 'Moral graph'
        },
        {
          'id': 2014816070,
          'name': 'Mean squared prediction error'
        },
        {
          'id': 2044286533,
          'name': 'Graph bandwidth'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 28
    },
    {
      'id': 335502775,
      'title': 'Convolutional recurrent neural networks: Learning spatial dependencies for image representation',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1122459535,
          'name': 'Zhen Zuo'
        },
        {
          'id': 1223415641,
          'name': 'Bing Shuai'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        },
        {
          'id': 1148625237,
          'name': 'Xiao Liu'
        },
        {
          'id': 1388565666,
          'name': 'Xingxing Wang'
        },
        {
          'id': 1068871262,
          'name': 'Bing Wang'
        },
        {
          'id': 1235708616,
          'name': 'Yushi Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 53,
      'reference_count': 55
    },
    {
      'id': 353256080,
      'title': 'Rent3D: Floor-plan priors for monocular layout estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        }
      ],
      'author': [
        {
          'id': 1398227961,
          'name': 'Chenxi Liu'
        },
        {
          'id': 1389857892,
          'name': 'Alexander Gerhard Schwing'
        },
        {
          'id': 1062043537,
          'name': 'Kaustav Kundu'
        },
        {
          'id': 1174742793,
          'name': 'Raquel Urtasun'
        },
        {
          'id': 1352379588,
          'name': 'Sanja Fidler'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2010101173,
          'name': 'Small set'
        },
        {
          'id': 2014065271,
          'name': 'Floor plan'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2002716574,
          'name': 'Integral geometry'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 28
    },
    {
      'id': 353522493,
      'title': 'Sparse projections for high-dimensional binary codes',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1104529640,
          'name': 'Yan Xia'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1223786509,
          'name': 'Pushmeet Kohli'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2014277208,
          'name': 'Clustering high-dimensional data'
        },
        {
          'id': 2048051571,
          'name': 'Group method of data handling'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008291660,
          'name': 'Projection (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 35
    },
    {
      'id': 35385676,
      'title': 'Combination features and models for human detection',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1250747763,
          'name': 'Yunsheng Jiang'
        },
        {
          'id': 1445619584,
          'name': 'Jinwen Ma'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 33
    },
    {
      'id': 355607411,
      'title': 'High-fidelity Pose and Expression Normalization for face recognition in the wild',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2004697794,
          'name': 'High fidelity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 163,
      'reference_count': 49
    },
    {
      'id': 36336247,
      'title': 'Channel-Max, Channel-Drop and Stochastic Max-pooling',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1302705915,
          'name': 'Yuchi Huang'
        },
        {
          'id': 1021186288,
          'name': 'Xiuyu Sun'
        },
        {
          'id': 1354266357,
          'name': 'Ming Lu'
        },
        {
          'id': 1001148237,
          'name': 'Ming Xu'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 25
    },
    {
      'id': 364442132,
      'title': 'Exploiting global priors for RGB-D saliency detection',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103644482,
          'abbr': 'HDU',
          'name': 'Hangzhou Dianzi University'
        },
        {
          'id': 2104711903,
          'abbr': 'TUD',
          'name': 'Dresden University of Technology'
        }
      ],
      'author': [
        {
          'id': 1202638246,
          'name': 'Jianqiang Ren'
        },
        {
          'id': 1026609011,
          'name': 'Xiaojin Gong'
        },
        {
          'id': 1076245199,
          'name': 'Lu Yu'
        },
        {
          'id': 1339309712,
          'name': 'Wenhui Zhou'
        },
        {
          'id': 1496002253,
          'name': 'Michael Ying Yang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2038584844,
          'name': 'PageRank'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 32
    },
    {
      'id': 364556522,
      'title': 'Beyond Mahalanobis metric: Cayley-Klein metric learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1085440989,
          'name': 'Yanhong Bi'
        },
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1163323626,
          'name': 'Fuchao Wu'
        }
      ],
      'field': [
        {
          'id': 2029691725,
          'name': 'Fisher information metric'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2003653070,
          'name': 'Product metric'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028301944,
          'name': 'Cayley–Klein metric'
        },
        {
          'id': 2028446589,
          'name': 'Large margin nearest neighbor'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021845286,
          'name': 'Convex metric space'
        },
        {
          'id': 2002614814,
          'name': 'String metric'
        },
        {
          'id': 2035256865,
          'name': 'Equivalence of metrics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 31
    },
    {
      'id': 36841492,
      'title': 'An approximate shading model for object relighting',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1061710418,
          'name': 'Zicheng Liao'
        },
        {
          'id': 1041629896,
          'name': 'Kevin Karsch'
        },
        {
          'id': 1349113912,
          'name': 'David Alexander Forsyth'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040540419,
          'name': '3D rendering'
        },
        {
          'id': 2038412597,
          'name': 'Image-based modeling and rendering'
        },
        {
          'id': 2044658123,
          'name': 'Object model'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004356190,
          'name': 'Composition (visual arts)'
        },
        {
          'id': 2024086463,
          'name': 'Rendering (computer graphics)'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 29
    },
    {
      'id': 371193769,
      'title': 'Efficient and accurate approximations of nonlinear convolutional networks',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1398571715,
          'name': 'Jianhua Zou'
        },
        {
          'id': 1172183139,
          'name': 'Xiang Ming'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2044162997,
          'name': 'Nonlinear programming'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2036679242,
          'name': 'Word error rate'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2036665182,
          'name': 'Linear filter'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 111,
      'reference_count': 25
    },
    {
      'id': 376347808,
      'title': 'Saliency detection by multi-context deep learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1413621210,
          'name': 'Rui Zhao'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 351,
      'reference_count': 59
    },
    {
      'id': 388464178,
      'title': 'Towards 3D object detection with bimodal deep Boltzmann machines over RGBD imagery',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1111590737,
          'name': 'Wei Liu'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1438105862,
          'name': 'Shaozi Li'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 25
    },
    {
      'id': 392162842,
      'title': 'Simplified mirror-based camera pose computation via rotation averaging',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1218065812,
          'name': 'Gucan Long'
        },
        {
          'id': 1099683443,
          'name': 'Laurent Kneip'
        },
        {
          'id': 1234347036,
          'name': 'Xin Li'
        },
        {
          'id': 1311053258,
          'name': 'Xiaohu Zhang'
        },
        {
          'id': 1288598170,
          'name': 'Qifeng Yu'
        }
      ],
      'field': [
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2017968033,
          'name': 'Camera auto-calibration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2005547078,
          'name': 'Camera resectioning'
        },
        {
          'id': 2014295717,
          'name': 'Chordal graph'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 14
    },
    {
      'id': 398129997,
      'title': 'Superpixel segmentation using Linear Spectral Clustering',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1475971673,
          'name': 'Zhengqin Li'
        },
        {
          'id': 1470618407,
          'name': 'Jiansheng Chen'
        }
      ],
      'field': [
        {
          'id': 2008941526,
          'name': 'Kernel (statistics)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2005979105,
          'name': 'High memory'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 120,
      'reference_count': 21
    },
    {
      'id': 406001016,
      'title': 'Query-adaptive late fusion for image search and person re-identification',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1299899041,
          'name': 'Lu Tian'
        },
        {
          'id': 1388609454,
          'name': 'Fei He'
        },
        {
          'id': 1474070778,
          'name': 'Ziqiong Liu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2008071310,
          'name': 'Query expansion'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 146,
      'reference_count': 38
    },
    {
      'id': 406367904,
      'title': 'Online sketching hashing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1238585749,
          'name': 'Cong Leng'
        },
        {
          'id': 1384391592,
          'name': 'Jiaxiang Wu'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        },
        {
          'id': 1223969568,
          'name': 'Xiao Bai'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 35
    },
    {
      'id': 41368145,
      'title': 'Hierarchical recurrent neural network for skeleton based action recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1099228814,
          'name': 'Yong Du'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2040731089,
          'name': 'Time delay neural network'
        },
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030954467,
          'name': 'Whole skeleton'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2018188840,
          'name': 'Human skeleton'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 522,
      'reference_count': 40
    },
    {
      'id': 418231375,
      'title': 'Saliency propagation from simple to difficult',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2104876881,
          'abbr': 'BBK',
          'name': 'Birkbeck, University of London'
        }
      ],
      'author': [
        {
          'id': 1227217774,
          'name': 'Chen Gong'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1242761004,
          'name': 'Stephen J. Maybank'
        },
        {
          'id': 1456157074,
          'name': 'Meng Fang'
        },
        {
          'id': 1184401672,
          'name': 'Keren Fu'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 52,
      'reference_count': 30
    },
    {
      'id': 419146750,
      'title': 'Guidance: A visual sensing platform for robotic applications',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1106951171,
          'name': 'Guyue Zhou'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        },
        {
          'id': 1454739938,
          'name': 'Ketan Tang'
        },
        {
          'id': 1037205396,
          'name': 'Honghui Zhang'
        },
        {
          'id': 1000329809,
          'name': 'Kai Wang'
        },
        {
          'id': 1067333498,
          'name': 'Kang Yang'
        }
      ],
      'field': [
        {
          'id': 2000376905,
          'name': 'Visual odometry'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2035909059,
          'name': 'Obstacle avoidance'
        },
        {
          'id': 2003803793,
          'name': 'Visual processing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021183717,
          'name': 'Simultaneous localization and mapping'
        },
        {
          'id': 2048044401,
          'name': 'Robot'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 28
    },
    {
      'id': 421971616,
      'title': 'Semantic object segmentation via detection in weakly labeled video',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1088663906,
          'name': 'Yu Zhang'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1149258320,
          'name': 'Jia Li'
        },
        {
          'id': 1344286964,
          'name': 'Chen Wang'
        },
        {
          'id': 1376919782,
          'name': 'Changqun Xia'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 50,
      'reference_count': 33
    },
    {
      'id': 422206090,
      'title': 'Line-based Multi-Label Energy Optimization for fisheye image rectification and calibration',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        }
      ],
      'author': [
        {
          'id': 1343280544,
          'name': 'Mi Zhang'
        },
        {
          'id': 1468884656,
          'name': 'Jian Yao'
        },
        {
          'id': 1193932721,
          'name': 'Menghan Xia'
        },
        {
          'id': 1049532956,
          'name': 'Kai Li'
        },
        {
          'id': 1152113887,
          'name': 'Yi Zhang'
        },
        {
          'id': 1094559998,
          'name': 'Yaping Liu'
        }
      ],
      'field': [
        {
          'id': 2045926880,
          'name': 'Image plane'
        },
        {
          'id': 2021101066,
          'name': 'Image rectification'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2035645948,
          'name': 'Arc (geometry)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025838069,
          'name': 'Rectification'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 24
    },
    {
      'id': 422990466,
      'title': '3D Reconstruction in the presence of glasses by acoustic and stereo fusion',
      'affiliation': [
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2104030429,
          'abbr': 'UNC',
          'name': 'University of North Carolina at Chapel Hill'
        }
      ],
      'author': [
        {
          'id': 1417334466,
          'name': 'Mao Ye'
        },
        {
          'id': 1436670049,
          'name': 'Yu Zhang'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        },
        {
          'id': 1473600617,
          'name': 'Dinesh Manocha'
        }
      ],
      'field': [
        {
          'id': 2023612680,
          'name': 'Sensor fusion'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008584860,
          'name': 'Human visual system model'
        },
        {
          'id': 2045163240,
          'name': 'Opacity'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2002651821,
          'name': 'Ultrasonic sensor'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 27
    },
    {
      'id': 425337879,
      'title': 'Motion Composition Regularization: Improving action recognition via trajectory group selection',
      'affiliation': [
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1379785310,
          'name': 'Pierre Moulin'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 33
    },
    {
      'id': 429623266,
      'title': 'Reweighted laplace prior based hyperspectral compressive sensing for unknown sparsity',
      'affiliation': [
        {
          'id': 2102978668,
          'abbr': 'NU',
          'name': 'Northwest University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1386051449,
          'name': 'Lei Zhang'
        },
        {
          'id': 1444713601,
          'name': 'Wei Wei'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1323979781,
          'name': 'Chunna Tian'
        },
        {
          'id': 1038198811,
          'name': 'Fei Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001928736,
          'name': 'Bayes\' theorem'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048710313,
          'name': 'Compressed sensing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042860337,
          'name': 'Laplace transform'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 28
    },
    {
      'id': 431304586,
      'title': 'Learning a convolutional neural network for non-uniform motion blur removal',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102044357,
          'abbr': 'ENS',
          'name': 'École Normale Supérieure'
        }
      ],
      'author': [
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1045904497,
          'name': 'Wenfei Cao'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        },
        {
          'id': 1461311619,
          'name': 'Jean Ponce'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 174,
      'reference_count': 34
    },
    {
      'id': 437879333,
      'title': 'Semi-supervised Domain Adaptation with Subspace Learning for visual recognition',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1114237355,
          'name': 'Chong-Wah Ngo'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2014057446,
          'name': 'Source data'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 29
    },
    {
      'id': 44297870,
      'title': 'Object detection by labeling superpixels',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1225242930,
          'name': 'Yinan Yu'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046442909,
          'name': 'ENCODE'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2015911965,
          'name': 'Labeling Problem'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 66
    },
    {
      'id': 446724175,
      'title': 'Shape driven kernel adaptation in Convolutional Neural Network for robust facial trait recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1497130500,
          'name': 'Shaoxin Li'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1104534622,
          'name': 'Zhiheng Niu'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2036494929,
          'name': 'Intuition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047385040,
          'name': 'Trait'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003882441,
          'name': 'Spatial distribution'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 39
    },
    {
      'id': 447055331,
      'title': 'Simultaneous video defogging and stereo reconstruction',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103565249,
          'abbr': 'SFU',
          'name': 'Simon Fraser University'
        },
        {
          'id': 2101861149,
          'abbr': 'SUSS',
          'name': 'SIM University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1476012931,
          'name': 'Zhuwen Li'
        },
        {
          'id': 1056951125,
          'name': 'Ping Tan'
        },
        {
          'id': 1276044969,
          'name': 'Robby T. Tan'
        },
        {
          'id': 1177694162,
          'name': 'Danping Zou'
        },
        {
          'id': 1375966451,
          'name': 'Steven Zhiying Zhou'
        },
        {
          'id': 1028461562,
          'name': 'Loong Fah Cheong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037257956,
          'name': 'Scattering'
        },
        {
          'id': 2014250958,
          'name': 'Depth perception'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041758568,
          'name': 'Laplace operator'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 39
    },
    {
      'id': 448335454,
      'title': 'Discrete hyper-graph matching',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1048462365,
          'name': 'Chao Zhang'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1036975246,
          'name': 'Stephen M. Chu'
        }
      ],
      'field': [
        {
          'id': 2035143843,
          'name': 'Discrete space'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2017543523,
          'name': 'Fixed point'
        },
        {
          'id': 2045878311,
          'name': 'Extant taxon'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2021281596,
          'name': 'Discrete system'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2011548508,
          'name': 'Unary operation'
        },
        {
          'id': 2044692108,
          'name': 'Rounding'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 35
    },
    {
      'id': 460100037,
      'title': 'Cross-scene crowd counting via deep convolutional neural networks',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1137294273,
          'name': 'Cong Zhang'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2010502260,
          'name': 'Local optimum'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022024035,
          'name': 'Crowd analysis'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017062996,
          'name': 'Data Annotation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 299,
      'reference_count': 30
    },
    {
      'id': 462600897,
      'title': 'GRSA: Generalized range swap algorithm for the efficient optimization of MRFs',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1189721525,
          'name': 'Kangwei Liu'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1165951422,
          'name': 'Peipei Yang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2044171927,
          'name': 'Submodular set function'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2048774318,
          'name': 'Convex function'
        },
        {
          'id': 2029718417,
          'name': 'Swap (finance)'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2020471894,
          'name': 'Set cover problem'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 28
    },
    {
      'id': 466129091,
      'title': 'Recurrent convolutional neural network for object recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1192294770,
          'name': 'Ming Liang'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040731089,
          'name': 'Time delay neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 311,
      'reference_count': 54
    },
    {
      'id': 471311682,
      'title': 'Robust saliency detection via regularized random walks ranking',
      'affiliation': [
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1219025053,
          'name': 'Changyang Li'
        },
        {
          'id': 1128713109,
          'name': 'Yuchen Yuan'
        },
        {
          'id': 1210919005,
          'name': 'Weidong Cai'
        },
        {
          'id': 1059959468,
          'name': 'Yong Xia'
        },
        {
          'id': 1468763200,
          'name': 'David Dagan Feng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2004353831,
          'name': 'Random walk'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 91,
      'reference_count': 30
    },
    {
      'id': 473661691,
      'title': 'A geodesic-preserving method for image warping',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1282493308,
          'name': 'Dongping Li'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1266662130,
          'name': 'Kun Zhou'
        }
      ],
      'field': [
        {
          'id': 2015875560,
          'name': 'Geometry'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2049542856,
          'name': 'Ellipse'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 25
    },
    {
      'id': 480405737,
      'title': 'Multi-task deep visual-semantic embedding for video thumbnail selection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1334736635,
          'name': 'Wu Liu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        },
        {
          'id': 1355307321,
          'name': 'Yongdong Zhang'
        },
        {
          'id': 1131412248,
          'name': 'Cherry Che'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2043057959,
          'name': 'Thumbnail'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 100,
      'reference_count': 26
    },
    {
      'id': 483143688,
      'title': 'Image denoising via adaptive soft-thresholding based on non-local samples',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1173515532,
          'name': 'Hangfan Liu'
        },
        {
          'id': 1293244045,
          'name': 'Ruiqin Xiong'
        },
        {
          'id': 1280483124,
          'name': 'Jian Zhang'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2006695514,
          'name': 'Laplace distribution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 28
    },
    {
      'id': 484425067,
      'title': 'Region-based temporally consistent video post-processing',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1480448044,
          'name': 'Xuan Dong'
        },
        {
          'id': 1472072626,
          'name': 'Boyan Bonev'
        },
        {
          'id': 1312078133,
          'name': 'Yu Zhu'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046246198,
          'name': 'Video post-processing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2004697794,
          'name': 'High fidelity'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 23
    },
    {
      'id': 48814398,
      'title': 'Saliency detection via Cellular Automata',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1420167691,
          'name': 'Yao Qin'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1305085405,
          'name': 'Yiqun Xu'
        },
        {
          'id': 1320735477,
          'name': 'He Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028411873,
          'name': 'Cellular automaton'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 213,
      'reference_count': 51
    },
    {
      'id': 492644492,
      'title': 'Matching-CNN meets KNN: Quasi-parametric human parsing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1354534685,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1350047351,
          'name': 'Luoqi Liu'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1224129051,
          'name': 'Jianchao Yang'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2028963219,
          'name': 'Nonparametric statistics'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 78,
      'reference_count': 34
    },
    {
      'id': 494009348,
      'title': 'Blind optical aberration correction by exploring geometric and visual priors',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1311917372,
          'name': 'Tao Yue'
        },
        {
          'id': 1462346539,
          'name': 'Jinli Suo'
        },
        {
          'id': 1295811729,
          'name': 'Jue Wang'
        },
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032616152,
          'name': 'Rotational symmetry'
        },
        {
          'id': 2000449488,
          'name': 'Visual artifact'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029730892,
          'name': 'Channel (digital image)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2003438925,
          'name': 'Optical aberration'
        },
        {
          'id': 2010146798,
          'name': 'Reflection symmetry'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 20
    },
    {
      'id': 494168565,
      'title': 'Reliable Patch Trackers: Robust visual tracking by exploiting reliable patches',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101195408,
          'abbr': 'SMU',
          'name': 'Singapore Management University'
        }
      ],
      'author': [
        {
          'id': 1372846994,
          'name': 'Yang Li'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1433462722,
          'name': 'Steven C. H. Hoi'
        }
      ],
      'field': [
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020399200,
          'name': 'Visual Objects'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 171,
      'reference_count': 41
    },
    {
      'id': 50918617,
      'title': 'Making better use of edges via perceptual grouping',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1025602439,
          'name': 'Yonggang Qi'
        },
        {
          'id': 1276350016,
          'name': 'Yi-Zhe Song'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1313344913,
          'name': 'Yi Li'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2024777000,
          'name': 'Gestalt psychology'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        },
        {
          'id': 2045590784,
          'name': 'Graph partition'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 56
    },
    {
      'id': 60289653,
      'title': 'Background Subtraction via generalized fused lasso foreground modeling',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1410218114,
          'name': 'Bo Xin'
        },
        {
          'id': 1307169614,
          'name': 'Yuan Tian'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2041111693,
          'name': 'Lagrange multiplier'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048065051,
          'name': 'Lasso (statistics)'
        },
        {
          'id': 2048841513,
          'name': 'Background noise'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2025715469,
          'name': 'Background subtraction'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 27
    },
    {
      'id': 857673,
      'title': 'Learning coarse-to-fine sparselets for efficient object detection and scene classification',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100530516,
          'abbr': 'UGA',
          'name': 'University of Georgia'
        }
      ],
      'author': [
        {
          'id': 1160298449,
          'name': 'Gong Cheng'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1218734856,
          'name': 'Lei Guo'
        },
        {
          'id': 1005003126,
          'name': 'Tianming Liu'
        }
      ],
      'field': [
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2007436620,
          'name': 'Universal set'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 54
    },
    {
      'id': 86494595,
      'title': 'Predicting eye fixations using convolutional neural networks',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100530516,
          'abbr': 'UGA',
          'name': 'University of Georgia'
        }
      ],
      'author': [
        {
          'id': 1393164687,
          'name': 'Nian Liu'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1047907296,
          'name': 'Shifeng Wen'
        },
        {
          'id': 1005003126,
          'name': 'Tianming Liu'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026731675,
          'name': 'Logistic regression'
        },
        {
          'id': 2000336464,
          'name': 'Fixation (visual)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047185316,
          'name': 'Eye movement'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133617980',
        'name': 'CVPR',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 95,
      'reference_count': 40
    },
    {
      'id': 102459564,
      'title': 'MILCut: A Sweeping Line Multiple Instance Learning Paradigm for Interactive Image Segmentation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        }
      ],
      'author': [
        {
          'id': 1385296331,
          'name': 'Jiajun Wu'
        },
        {
          'id': 1319862769,
          'name': 'Yibiao Zhao'
        },
        {
          'id': 1404583400,
          'name': 'Jun-Yan Zhu'
        },
        {
          'id': 1468641376,
          'name': 'Siwei Luo'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2048776180,
          'name': 'Image editing'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2014243555,
          'name': 'Minimum spanning tree-based segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 67,
      'reference_count': 46
    },
    {
      'id': 103452210,
      'title': 'Finding Matches in a Haystack: A Max-Pooling Strategy for Graph Matching in the Presence of Outliers',
      'affiliation': [
        {
          'id': 2102731498,
          'abbr': 'SNU',
          'name': 'Seoul National University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2102044357,
          'abbr': 'ENS',
          'name': 'École Normale Supérieure'
        }
      ],
      'author': [
        {
          'id': 1288209629,
          'name': 'Minsu Cho'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1184614680,
          'name': 'Olivier Duchenne'
        },
        {
          'id': 1461311619,
          'name': 'Jean Ponce'
        }
      ],
      'field': [
        {
          'id': 2004350624,
          'name': 'Haystack'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021185547,
          'name': 'Object Class'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046794490,
          'name': 'Data mining'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 64,
      'reference_count': 33
    },
    {
      'id': 108532093,
      'title': 'What Are You Talking About? Text-to-Image Coreference',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        }
      ],
      'author': [
        {
          'id': 1311580804,
          'name': 'Chen Kong'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        },
        {
          'id': 1322054891,
          'name': 'Mohit Bansal'
        },
        {
          'id': 1174742793,
          'name': 'Raquel Urtasun'
        },
        {
          'id': 1352379588,
          'name': 'Sanja Fidler'
        }
      ],
      'field': [
        {
          'id': 2019849416,
          'name': 'Coreference'
        },
        {
          'id': 2033169878,
          'name': 'Pronoun'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2049518231,
          'name': 'Noun'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020399200,
          'name': 'Visual Objects'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 124,
      'reference_count': 35
    },
    {
      'id': 114609663,
      'title': 'Weakly Supervised Multiclass Video Segmentation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1412520726,
          'name': 'Xiao Liu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        },
        {
          'id': 1000327262,
          'name': 'Ying Ruan'
        },
        {
          'id': 1397597581,
          'name': 'Chun Chen'
        },
        {
          'id': 1114109048,
          'name': 'Jiajun Bu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2028025525,
          'name': 'Semantic similarity'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 37
    },
    {
      'id': 123303364,
      'title': 'Smooth Representation Clustering',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1361061781,
          'name': 'Jianjiang Feng'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2027234364,
          'name': 'Single-linkage clustering'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2024545963,
          'name': 'FLAME clustering'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2035951257,
          'name': 'CURE data clustering algorithm'
        },
        {
          'id': 2001604607,
          'name': 'Canopy clustering algorithm'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2043009089,
          'name': 'Brown clustering'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 87,
      'reference_count': 28
    },
    {
      'id': 12467064,
      'title': 'Object-Based Multiple Foreground Video Co-segmentation',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1357204816,
          'name': 'Huazhu Fu'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1065296920,
          'name': 'Bao Zhang'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 24
    },
    {
      'id': 133492665,
      'title': 'Fourier Analysis on Transient Imaging with a Multifrequency Time-of-Flight Camera',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100789236,
          'abbr': '',
          'name': 'University of Bonn'
        }
      ],
      'author': [
        {
          'id': 1244240800,
          'name': 'Jingyu Lin'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1161646762,
          'name': 'Matthias B. Hullin'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2011239532,
          'name': 'Impulse response'
        },
        {
          'id': 2032635142,
          'name': 'Fourier analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049332256,
          'name': 'Pulse sequence'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003093565,
          'name': 'Fourier transform'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039639829,
          'name': 'Systematic error'
        },
        {
          'id': 2001414570,
          'name': 'Time-of-flight camera'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 26
    },
    {
      'id': 169795723,
      'title': 'Attributed Graph Mining and Matching: An Attempt to Define and Extract Soft Attributed Patterns',
      'affiliation': [
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1430985511,
          'name': 'Quanshi Zhang'
        },
        {
          'id': 1124218438,
          'name': 'Xuan Song'
        },
        {
          'id': 1322338161,
          'name': 'Xiaowei Shao'
        },
        {
          'id': 1230185443,
          'name': 'Huijing Zhao'
        },
        {
          'id': 1182426429,
          'name': 'Ryosuke Shibasaki'
        }
      ],
      'field': [
        {
          'id': 2030696514,
          'name': 'Null graph'
        },
        {
          'id': 2016959849,
          'name': 'Graph power'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040634048,
          'name': 'Factor-critical graph'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2016278136,
          'name': 'Voltage graph'
        },
        {
          'id': 2044947287,
          'name': 'Butterfly graph'
        },
        {
          'id': 2035244343,
          'name': 'Strength of a graph'
        },
        {
          'id': 2045499782,
          'name': 'Distance-hereditary graph'
        },
        {
          'id': 2046002229,
          'name': 'Simplex graph'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 31
    },
    {
      'id': 169925004,
      'title': 'A Reverse Hierarchy Model for Predicting Eye Fixations',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1115023640,
          'name': 'Tianlin Shi'
        },
        {
          'id': 1192294770,
          'name': 'Ming Liang'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037839189,
          'name': 'Visual hierarchy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038781371,
          'name': 'GiST'
        },
        {
          'id': 2029212302,
          'name': 'Statistical model'
        },
        {
          'id': 2027173610,
          'name': 'Hierarchy theory'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 46
    },
    {
      'id': 174185678,
      'title': 'Weighted Nuclear Norm Minimization with Application to Image Denoising',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        }
      ],
      'field': [
        {
          'id': 2018331948,
          'name': 'Singular value'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2033464047,
          'name': 'Low-rank approximation'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2006645328,
          'name': 'Convexity'
        },
        {
          'id': 2040012394,
          'name': 'Weighting'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 431,
      'reference_count': 32
    },
    {
      'id': 175331031,
      'title': 'Robust Subspace Segmentation with Block-Diagonal Prior',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1268898520,
          'name': 'Huan Xu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2000700521,
          'name': 'Block matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2004328858,
          'name': 'Subgradient method'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2034388435,
          'name': 'Laplacian matrix'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 85,
      'reference_count': 28
    },
    {
      'id': 175872271,
      'title': 'Improving 3D Face Details Based on Normal Map of Hetero-source Images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1037094392,
          'name': 'Chang Yang'
        },
        {
          'id': 1470618407,
          'name': 'Jiansheng Chen'
        },
        {
          'id': 1332012654,
          'name': 'Nan Su'
        },
        {
          'id': 1388511371,
          'name': 'Guangda Su'
        }
      ],
      'field': [
        {
          'id': 2012148577,
          'name': 'Spherical harmonics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035705126,
          'name': 'Regular polygon'
        },
        {
          'id': 2025013130,
          'name': 'Normal mapping'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 19
    },
    {
      'id': 180673185,
      'title': 'When 3D Reconstruction Meets Ubiquitous RGB-D Images',
      'affiliation': [
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1430985511,
          'name': 'Quanshi Zhang'
        },
        {
          'id': 1124218438,
          'name': 'Xuan Song'
        },
        {
          'id': 1322338161,
          'name': 'Xiaowei Shao'
        },
        {
          'id': 1230185443,
          'name': 'Huijing Zhao'
        },
        {
          'id': 1182426429,
          'name': 'Ryosuke Shibasaki'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044172385,
          'name': 'Knowledge base'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030713365,
          'name': 'Ubiquitous learning'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 28
    },
    {
      'id': 182441991,
      'title': 'Deformable Object Matching via Deformation Decomposition Based 2D Label MRF',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1189721525,
          'name': 'Kangwei Liu'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2017342794,
          'name': 'Elastic matching'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 33
    },
    {
      'id': 188140974,
      'title': 'Towards Good Practices for Action Video Encoding',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1460892622,
          'name': 'Yu Zhang'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021895381,
          'name': 'Principle of maximum entropy'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 55,
      'reference_count': 28
    },
    {
      'id': 195215214,
      'title': 'Robust Estimation of 3D Human Poses from a Single Image',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1335216092,
          'name': 'Chunyu Wang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2042278253,
          'name': 'Articulated body pose estimation'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014430643,
          'name': 'Estimator'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2010499686,
          'name': 'Limb length'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 122,
      'reference_count': 20
    },
    {
      'id': 201833792,
      'title': 'A Primal-Dual Algorithm for Higher-Order Multilabel Markov Random Fields',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1056872364,
          'name': 'Alexander Fix'
        },
        {
          'id': 1081170536,
          'name': 'Chen Wang'
        },
        {
          'id': 1306993101,
          'name': 'Ramin Zabih'
        }
      ],
      'field': [
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2014975600,
          'name': 'Diffusing update algorithm'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047824847,
          'name': 'Random field'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 30
    },
    {
      'id': 20288695,
      'title': 'Semi-supervised Relational Topic Model for Weakly Annotated Image Recognition in Social Media',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103556481,
          'abbr': 'SIT',
          'name': 'Stevens Institute of Technology'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1306869427,
          'name': 'Zhenxing Niu'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011565621,
          'name': 'Social media'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033757095,
          'name': 'Topic model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 19
    },
    {
      'id': 21346854,
      'title': 'Separable Kernel for Image Deblurring',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        },
        {
          'id': 1436184414,
          'name': 'Haifeng Liu'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        },
        {
          'id': 1423798589,
          'name': 'Xiaoyan Sun'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        }
      ],
      'field': [
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037112133,
          'name': 'Point spread function'
        },
        {
          'id': 2028995778,
          'name': 'Kernel embedding of distributions'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006122888,
          'name': 'Radial basis function kernel'
        },
        {
          'id': 2043785615,
          'name': 'Variable kernel density estimation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 26
    },
    {
      'id': 214870420,
      'title': 'Generalized Autoencoder: A Neural Network Framework for Dimensionality Reduction',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 71,
      'reference_count': 22
    },
    {
      'id': 23159041,
      'title': 'Guided Depth Upsampling via a Cosparse Analysis Model',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1026609011,
          'name': 'Xiaojin Gong'
        },
        {
          'id': 1202638246,
          'name': 'Jianqiang Ren'
        },
        {
          'id': 1343356754,
          'name': 'Baisheng Lai'
        },
        {
          'id': 1224681724,
          'name': 'Chaohua Yan'
        },
        {
          'id': 1135110895,
          'name': 'Hui Qian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2008364648,
          'name': 'Finite difference'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 24
    },
    {
      'id': 234013180,
      'title': 'Quality Assessment for Comparing Image Enhancement Algorithms',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1263880641,
          'name': 'Zhengying Chen'
        },
        {
          'id': 1372819545,
          'name': 'Tingting Jiang'
        },
        {
          'id': 1484177249,
          'name': 'Yonghong Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012284111,
          'name': 'Quality Score'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 28
    },
    {
      'id': 235338195,
      'title': 'Adaptive Object Retrieval with Kernel Reconstructive Hashing',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100347986,
          'abbr': 'GRIFFITH',
          'name': 'Griffith University'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1318251080,
          'name': 'Haichuan Yang'
        },
        {
          'id': 1223969568,
          'name': 'Xiao Bai'
        },
        {
          'id': 1331593199,
          'name': 'Jun Zhou'
        },
        {
          'id': 1437165705,
          'name': 'Peng Ren'
        },
        {
          'id': 1419855735,
          'name': 'Zhihong Zhang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2006122888,
          'name': 'Radial basis function kernel'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 23
    },
    {
      'id': 255000550,
      'title': 'Generalized Nonconvex Nonsmooth Low-Rank Minimization',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1081691569,
          'name': 'Canyi Lu'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        }
      ],
      'field': [
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2011438810,
          'name': 'Monotonic function'
        },
        {
          'id': 2014269599,
          'name': 'Concave function'
        },
        {
          'id': 2043932775,
          'name': 'Penalty method'
        },
        {
          'id': 2048774318,
          'name': 'Convex function'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008264590,
          'name': 'Stationary point'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 117,
      'reference_count': 23
    },
    {
      'id': 258974390,
      'title': 'Robust Separation of Reflection from Multiple Images',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049037711,
          'name': 'Augmented Lagrangian method'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2049224349,
          'name': 'Superposition principle'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 17
    },
    {
      'id': 292785616,
      'title': 'Asymmetrical Gauss Mixture Models for Point Sets Matching',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1145425243,
          'name': 'Wenbing Tao'
        },
        {
          'id': 1267802854,
          'name': 'Kun Sun'
        }
      ],
      'field': [
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2047692570,
          'name': 'Probabilistic method'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030346636,
          'name': 'Gauss'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 18
    },
    {
      'id': 294877668,
      'title': 'Packing and Padding: Coupled Multi-index for Accurate Image Retrieval',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1474070778,
          'name': 'Ziqiong Liu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039166637,
          'name': 'Inverted index'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 149,
      'reference_count': 32
    },
    {
      'id': 297602027,
      'title': 'Seeing the Arrow of Time',
      'affiliation': [
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2102440343,
          'abbr': 'MPG',
          'name': 'Max Planck Society'
        }
      ],
      'author': [
        {
          'id': 1305841428,
          'name': 'Lyndsey C. Pickup'
        },
        {
          'id': 1084436389,
          'name': 'Zheng Pan'
        },
        {
          'id': 1254841904,
          'name': 'Donglai Wei'
        },
        {
          'id': 1429063150,
          'name': 'YiChang Shih'
        },
        {
          'id': 1110227432,
          'name': 'Changshui Zhang'
        },
        {
          'id': 1486007301,
          'name': 'Andrew Zisserman'
        },
        {
          'id': 1025851296,
          'name': 'Bernhard Schölkopf'
        },
        {
          'id': 1234648702,
          'name': 'William T. Freeman'
        }
      ],
      'field': [
        {
          'id': 2036641578,
          'name': 'CLIPS'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044391384,
          'name': 'Block-matching algorithm'
        },
        {
          'id': 2033679769,
          'name': 'Arrow of time'
        },
        {
          'id': 2028552257,
          'name': 'Video compression picture types'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2009537528,
          'name': 'Arrow'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030410974,
          'name': 'Motion compensation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 27
    },
    {
      'id': 311036317,
      'title': 'Multi-shot Imaging: Joint Alignment, Deblurring, and Resolution-Enhancement',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1219158789,
          'name': 'Haichao Zhang'
        },
        {
          'id': 1363323578,
          'name': 'Lawrence Carin'
        }
      ],
      'field': [
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 28
    },
    {
      'id': 314962915,
      'title': 'Deblurring Text Images via L0-Regularized Intensity and Gradient Prior',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1240803967,
          'name': 'Zhe Hu'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 120,
      'reference_count': 22
    },
    {
      'id': 32788539,
      'title': 'Topic Modeling of Multimodal Data: An Autoregressive Approach',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1111416273,
          'name': 'Yin Zheng'
        },
        {
          'id': 1289921224,
          'name': 'Yu-Jin Zhang'
        },
        {
          'id': 1020905687,
          'name': 'Hugo Larochelle'
        }
      ],
      'field': [
        {
          'id': 2016016661,
          'name': 'LabelMe'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011877324,
          'name': 'Latent Dirichlet allocation'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033757095,
          'name': 'Topic model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 22
    },
    {
      'id': 331185498,
      'title': 'Semi-supervised Coupled Dictionary Learning for Person Re-identification',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1412520726,
          'name': 'Xiao Liu'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1147143762,
          'name': 'Xingchen Zhou'
        },
        {
          'id': 1397597581,
          'name': 'Chun Chen'
        },
        {
          'id': 1114109048,
          'name': 'Jiajun Bu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 120,
      'reference_count': 30
    },
    {
      'id': 337682950,
      'title': 'Similarity-Aware Patchwork Assembly for Depth Image Super-resolution',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1048990969,
          'name': 'Jing Li'
        },
        {
          'id': 1237354662,
          'name': 'Zhichao Lu'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1311627122,
          'name': 'Rui Gan'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2032805116,
          'name': 'Sub-pixel resolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003855789,
          'name': 'Coincidence'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2029117650,
          'name': 'Self-similarity'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 26
    },
    {
      'id': 347046441,
      'title': 'Transfer Joint Matching for Unsupervised Domain Adaptation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103382811,
          'abbr': 'UIC',
          'name': 'University of Illinois at Chicago'
        }
      ],
      'author': [
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1458814224,
          'name': 'Jiaguang Sun'
        },
        {
          'id': 1445179598,
          'name': 'Philip S. Yu'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 176,
      'reference_count': 29
    },
    {
      'id': 348708116,
      'title': 'Sparse Dictionary Learning for Edit Propagation of High-Resolution Images',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103565249,
          'abbr': 'SFU',
          'name': 'Simon Fraser University'
        }
      ],
      'author': [
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1383637716,
          'name': 'Dongqing Zou'
        },
        {
          'id': 1434502322,
          'name': 'Jianwei Li'
        },
        {
          'id': 1147264019,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        },
        {
          'id': 1126750079,
          'name': 'Hao Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2005979105,
          'name': 'High memory'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026750366,
          'name': 'Compact space'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 28
    },
    {
      'id': 348771122,
      'title': 'Visual Semantic Search: Retrieving Videos via Complex Textual Queries',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        },
        {
          'id': 1352379588,
          'name': 'Sanja Fidler'
        },
        {
          'id': 1311580804,
          'name': 'Chen Kong'
        },
        {
          'id': 1174742793,
          'name': 'Raquel Urtasun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026978225,
          'name': 'Semantic computing'
        },
        {
          'id': 2035624340,
          'name': 'Semantic search'
        },
        {
          'id': 2045501904,
          'name': 'Bipartite graph'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004563257,
          'name': 'Spatial relation'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 93,
      'reference_count': 28
    },
    {
      'id': 351740790,
      'title': 'Robust Low-Rank Regularized Regression for Face Recognition with Occlusion',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1143500098,
          'name': 'Jianjun Qian'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        },
        {
          'id': 1324383247,
          'name': 'Fanglong Zhang'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        }
      ],
      'field': [
        {
          'id': 2015595111,
          'name': 'Error detection and correction'
        },
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2029792318,
          'name': 'Contiguity (probability theory)'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 25
    },
    {
      'id': 354851013,
      'title': 'Linear Ranking Analysis',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1481827642,
          'name': 'Jiani Hu'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011384812,
          'name': 'Maximization'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2011607150,
          'name': 'Greedy algorithm'
        },
        {
          'id': 2044405812,
          'name': 'Centroid'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 24
    },
    {
      'id': 356323380,
      'title': 'CID: Combined Image Denoising in Spatial and Frequency Domains Using Web Images',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1283447815,
          'name': 'Huanjing Yue'
        },
        {
          'id': 1423798589,
          'name': 'Xiaoyan Sun'
        },
        {
          'id': 1185676299,
          'name': 'Jingyu Yang'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2017733928,
          'name': 'Median filter'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2022305872,
          'name': 'Video denoising'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2039512801,
          'name': 'Frequency domain'
        },
        {
          'id': 2037010349,
          'name': 'Data cube'
        },
        {
          'id': 2001568964,
          'name': 'Image registration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 29
    },
    {
      'id': 356639966,
      'title': 'Diversity-Enhanced Condensation Algorithm and Its Application for Robust and Accurate Endoscope Three-Dimensional Motion Tracking',
      'affiliation': [
        {
          'id': 2103009371,
          'abbr': 'NAGOYA-U',
          'name': 'Nagoya University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1289648306,
          'name': 'Xiongbiao Luo'
        },
        {
          'id': 1185816145,
          'name': 'Ying Wan'
        },
        {
          'id': 1156673188,
          'name': 'Xiangjian He'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        },
        {
          'id': 1229759234,
          'name': 'Kensaku Mori'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036781200,
          'name': 'Differential evolution'
        },
        {
          'id': 2021711797,
          'name': 'Condensation algorithm'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2000185610,
          'name': 'Evolutionary computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006497253,
          'name': 'Population'
        },
        {
          'id': 2049611789,
          'name': 'Degeneracy (mathematics)'
        },
        {
          'id': 2019179373,
          'name': 'Maxima and minima'
        },
        {
          'id': 2005202005,
          'name': 'Match moving'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 15
    },
    {
      'id': 3620121,
      'title': 'Clothing Co-parsing by Joint Image Segmentation and Labeling',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1375862671,
          'name': 'Wei Yang'
        },
        {
          'id': 1275825081,
          'name': 'Ping Luo'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002073950,
          'name': 'Expectation–maximization algorithm'
        },
        {
          'id': 2027954625,
          'name': 'Clothing'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 76,
      'reference_count': 31
    },
    {
      'id': 363232043,
      'title': 'Recovering Surface Details under General Unknown Illumination Using Shading and Coarse Multi-view Stereo',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1428816321,
          'name': 'Di Xu'
        },
        {
          'id': 1010078498,
          'name': 'Qi Duan'
        },
        {
          'id': 1266450501,
          'name': 'Jianming Zheng'
        },
        {
          'id': 1142911606,
          'name': 'Juyong Zhang'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        },
        {
          'id': 1424297808,
          'name': 'Tat-Jen Cham'
        }
      ],
      'field': [
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2049037711,
          'name': 'Augmented Lagrangian method'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024753776,
          'name': 'Visual hull'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 26
    },
    {
      'id': 385368437,
      'title': 'Orientational Pyramid Matching for Recognizing Indoor Scenes',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1212262694,
          'name': 'Baining Guo'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005911017,
          'name': 'Novelty'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 50
    },
    {
      'id': 394268741,
      'title': 'Transparent Object Reconstruction via Coded Transport of Intensity',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1089565693,
          'name': 'Chenguang Ma'
        },
        {
          'id': 1383289547,
          'name': 'Xing Lin'
        },
        {
          'id': 1462346539,
          'name': 'Jinli Suo'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1017674813,
          'name': 'Gordon Wetzstein'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048103201,
          'name': 'Refraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015028931,
          'name': 'Turbulence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 34
    },
    {
      'id': 400015880,
      'title': 'Transitive Distance Clustering with K-Means Duality',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1096488246,
          'name': 'Zhiding Yu'
        },
        {
          'id': 1358483092,
          'name': 'Chunjing Xu'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1252224307,
          'name': 'Zhuo Hui'
        },
        {
          'id': 1450626424,
          'name': 'Fanyi Xiao'
        },
        {
          'id': 1381013466,
          'name': 'Wenbo Liu'
        },
        {
          'id': 1381503284,
          'name': 'Jianzhuang Liu'
        }
      ],
      'field': [
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2014337830,
          'name': 'Fuzzy clustering'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2024545963,
          'name': 'FLAME clustering'
        },
        {
          'id': 2035951257,
          'name': 'CURE data clustering algorithm'
        },
        {
          'id': 2001604607,
          'name': 'Canopy clustering algorithm'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043009089,
          'name': 'Brown clustering'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 22
    },
    {
      'id': 413622024,
      'title': 'Compact Representation for Image Classification: To Choose or to Compress?',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1460892622,
          'name': 'Yu Zhang'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        }
      ],
      'field': [
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2029498710,
          'name': 'Spite'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 28
    },
    {
      'id': 419653915,
      'title': 'Joint Coupled-Feature Representation and Coupled Boosting for AD Diagnosis',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2104030429,
          'abbr': 'UNC',
          'name': 'University of North Carolina at Chapel Hill'
        }
      ],
      'author': [
        {
          'id': 1357346833,
          'name': 'Yinghuan Shi'
        },
        {
          'id': 1207912251,
          'name': 'Heung Il Suk'
        },
        {
          'id': 1253373124,
          'name': 'Yang Gao'
        },
        {
          'id': 1277369288,
          'name': 'Dinggang Shen'
        }
      ],
      'field': [
        {
          'id': 2023612680,
          'name': 'Sensor fusion'
        },
        {
          'id': 2006270737,
          'name': 'Complex system'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010033551,
          'name': 'Text mining'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 27
    },
    {
      'id': 42034129,
      'title': 'Face Alignment at 3000 FPS via Regressing Local Binary Features',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1038906976,
          'name': 'Shaoqing Ren'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2015161306,
          'name': 'Mobile phone'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037758878,
          'name': 'Linear regression'
        },
        {
          'id': 2015584863,
          'name': 'Principle of locality'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 507,
      'reference_count': 35
    },
    {
      'id': 424978814,
      'title': 'Visual Tracking via Probability Continuous Outlier Model',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2010807206,
          'name': 'Likelihood function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2040512229,
          'name': 'Quantitative Evaluations'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 83,
      'reference_count': 29
    },
    {
      'id': 431439770,
      'title': 'Strokelets: A Learned Multi-scale Representation for Scene Text Recognition',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1154870261,
          'name': 'Baoguang Shi'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037070784,
          'name': 'Usability'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2016069254,
          'name': 'Noisy text analytics'
        },
        {
          'id': 2007773467,
          'name': 'Sketch recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2043815219,
          'name': 'Intelligent character recognition'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040776147,
          'name': 'Generality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 148,
      'reference_count': 35
    },
    {
      'id': 452306896,
      'title': 'Cross-Scale Cost Aggregation for Stereo Matching',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1010961365,
          'name': 'Kang Zhang'
        },
        {
          'id': 1420842307,
          'name': 'Yuqiang Fang'
        },
        {
          'id': 1386354323,
          'name': 'Dongbo Min'
        },
        {
          'id': 1230152445,
          'name': 'Lifeng Sun'
        },
        {
          'id': 1022153272,
          'name': 'Shiqiang Yang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 35
    },
    {
      'id': 47606364,
      'title': 'Unsupervised Learning of Dictionaries of Hierarchical Compositional Models',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1112568078,
          'name': 'Yi Hong'
        },
        {
          'id': 1294319516,
          'name': 'Wenze Hu'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        },
        {
          'id': 1128144622,
          'name': 'Ying Nian Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030941587,
          'name': 'Template'
        },
        {
          'id': 2048236700,
          'name': 'Gabor wavelet'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2036083470,
          'name': 'Iterated function'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038620405,
          'name': 'Matching pursuit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 30
    },
    {
      'id': 479043900,
      'title': 'BING: Binarized Normed Gradients for Objectness Estimation at 300fps',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        }
      ],
      'author': [
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1410274510,
          'name': 'Ziming Zhang'
        },
        {
          'id': 1008180239,
          'name': 'Wen-Yan Lin'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        }
      ],
      'field': [
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2010101173,
          'name': 'Small set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048258097,
          'name': 'Bitwise operation'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2047486484,
          'name': 'Color space'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 652,
      'reference_count': 61
    },
    {
      'id': 483970953,
      'title': 'Lacunarity Analysis on Image Patterns for Texture Classification',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1181038210,
          'name': 'Yuhui Quan'
        },
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        },
        {
          'id': 1147949542,
          'name': 'Yuping Sun'
        },
        {
          'id': 1417541561,
          'name': 'Yu Luo'
        }
      ],
      'field': [
        {
          'id': 2024357727,
          'name': 'Spatial ecology'
        },
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2013305724,
          'name': 'Lacunarity'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2004631569,
          'name': 'Fractal'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2003882441,
          'name': 'Spatial distribution'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 30
    },
    {
      'id': 66505865,
      'title': 'Bayes Merging of Multiple Vocabularies for Scalable Image Retrieval',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2001928736,
          'name': 'Bayes\' theorem'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 26
    },
    {
      'id': 71576803,
      'title': 'Collaborative Hashing',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1025575364,
          'name': 'Xianglong Liu'
        },
        {
          'id': 1075185772,
          'name': 'Junfeng He'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1031446264,
          'name': 'Bo Lang'
        }
      ],
      'field': [
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2008779518,
          'name': 'Bag-of-words model'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012147228,
          'name': 'Duality (optimization)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002476364,
          'name': 'Ranging'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': 'http://dl.acm.org/citation.cfm?id=2679973',
      'citation_count': 67,
      'reference_count': 20
    },
    {
      'id': 73497379,
      'title': 'Collective Matrix Factorization Hashing for Multimodal Data',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1383996063,
          'name': 'Yuchen Guo'
        },
        {
          'id': 1484375802,
          'name': 'Jile Zhou'
        }
      ],
      'field': [
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017191986,
          'name': 'Hopscotch hashing'
        },
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 188,
      'reference_count': 25
    },
    {
      'id': 77489462,
      'title': 'Adaptive Partial Differential Equation Learning for Visual Saliency Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1369218000,
          'name': 'Risheng Liu'
        },
        {
          'id': 1399768632,
          'name': 'Junjie Cao'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        }
      ],
      'field': [
        {
          'id': 2025959320,
          'name': 'Uniform matroid'
        },
        {
          'id': 2003526827,
          'name': 'Dirichlet distribution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2046048480,
          'name': 'Boundary value problem'
        },
        {
          'id': 2044171927,
          'name': 'Submodular set function'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025242424,
          'name': 'Partial differential equation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 87,
      'reference_count': 39
    },
    {
      'id': 89001609,
      'title': 'Enriching Visual Knowledge Bases via Object Discovery and Segmentation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1350530735,
          'name': 'Xinlei Chen'
        },
        {
          'id': 1441187167,
          'name': 'Abhinav Shrivastava'
        },
        {
          'id': 1305493357,
          'name': 'Abhinav Gupta'
        }
      ],
      'field': [
        {
          'id': 2044172385,
          'name': 'Knowledge base'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006310969,
          'name': 'The Internet'
        },
        {
          'id': 2004379144,
          'name': 'Top-down and bottom-up design'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 102,
      'reference_count': 34
    },
    {
      'id': 9219123,
      'title': 'Decomposable Nonlocal Tensor Dictionary Learning for Multispectral Image Denoising',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101631867,
          'abbr': 'CQUPT',
          'name': 'Chongqing University of Posts and Telecommunications'
        },
        {
          'id': 2103831083,
          'abbr': 'UQ',
          'name': 'University of Queensland'
        }
      ],
      'author': [
        {
          'id': 1045918409,
          'name': 'Yi Peng'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        },
        {
          'id': 1310082457,
          'name': 'Chenqiang Gao'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1172671031,
          'name': 'Biao Zhang'
        }
      ],
      'field': [
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2041191542,
          'name': 'Higher-order statistics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039645437,
          'name': 'Faithful representation'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 91,
      'reference_count': 31
    },
    {
      'id': 94730065,
      'title': 'Product Sparse Coding',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1007108791,
          'name': 'Tiezheng Ge'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2029522347,
          'name': 'Codebook'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2014248115,
          'name': 'Time complexity'
        },
        {
          'id': 2000585400,
          'name': 'Special case'
        },
        {
          'id': 2006706201,
          'name': 'Cartesian product'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 32
    },
    {
      'id': 99349172,
      'title': 'Occlusion Detection via Structured Sparse Learning for Robust Object Tracking',
      'affiliation': [
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1152287350,
          'name': 'Narendra Ahuja'
        }
      ],
      'field': [
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        }
      ],
      'venue': {
        'id': '2134375815',
        'name': 'CVPR',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 44
    },
    {
      'id': 108899944,
      'title': 'Hash Bit Selection: A Unified Solution for Selection Problems in Hashing',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1025575364,
          'name': 'Xianglong Liu'
        },
        {
          'id': 1075185772,
          'name': 'Junfeng He'
        },
        {
          'id': 1031446264,
          'name': 'Bo Lang'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2017191986,
          'name': 'Hopscotch hashing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012690106,
          'name': 'Open addressing'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 55,
      'reference_count': 19
    },
    {
      'id': 110398211,
      'title': 'Exploring Implicit Image Statistics for Visual Representativeness Modeling',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1082822706,
          'name': 'Xiaoshuai Sun'
        },
        {
          'id': 1401161842,
          'name': 'Xin-Jing Wang'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2011729194,
          'name': 'Semantic memory'
        },
        {
          'id': 2049678556,
          'name': 'Knowledge-based systems'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004484553,
          'name': 'Representativeness heuristic'
        },
        {
          'id': 2039217836,
          'name': 'Heuristics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2007939929,
          'name': 'Ontology'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 30
    },
    {
      'id': 117601101,
      'title': 'Learning without Human Scores for Blind Image Quality Assessment',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1430612842,
          'name': 'Wufeng Xue'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1242928838,
          'name': 'Xuanqin Mou'
        }
      ],
      'field': [
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012284111,
          'name': 'Quality Score'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 153,
      'reference_count': 28
    },
    {
      'id': 131824850,
      'title': 'PISA: Pixelwise Image Saliency by Aggregating Complementary Appearance Contrast Measures with Spatial Priors',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1159389582,
          'name': 'Keyang Shi'
        },
        {
          'id': 1317058236,
          'name': 'Keze Wang'
        },
        {
          'id': 1368374079,
          'name': 'Jiangbo Lu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 66,
      'reference_count': 23
    },
    {
      'id': 133131406,
      'title': 'Transfer Sparse Coding for Robust Image Representation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103382811,
          'abbr': 'UIC',
          'name': 'University of Illinois at Chicago'
        }
      ],
      'author': [
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1458814224,
          'name': 'Jiaguang Sun'
        },
        {
          'id': 1383996063,
          'name': 'Yuchen Guo'
        },
        {
          'id': 1445179598,
          'name': 'Philip S. Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2014435714,
          'name': 'Basis function'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 103,
      'reference_count': 24
    },
    {
      'id': 150844612,
      'title': 'Category Modeling from Just a Single Labeling: Use Depth Information to Guide the Learning of 2D Models',
      'affiliation': [
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1430985511,
          'name': 'Quanshi Zhang'
        },
        {
          'id': 1124218438,
          'name': 'Xuan Song'
        },
        {
          'id': 1322338161,
          'name': 'Xiaowei Shao'
        },
        {
          'id': 1182426429,
          'name': 'Ryosuke Shibasaki'
        },
        {
          'id': 1230185443,
          'name': 'Huijing Zhao'
        }
      ],
      'field': [
        {
          'id': 2044658123,
          'name': 'Object model'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009070732,
          'name': 'Incremental build model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2027090307,
          'name': 'Deep-sky object'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 40
    },
    {
      'id': 161544127,
      'title': 'Pose from Flow and Flow from Pose',
      'affiliation': [
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1145493025,
          'name': 'Katerina Fragkiadaki'
        },
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1456916710,
          'name': 'Jianbo Shi'
        }
      ],
      'field': [
        {
          'id': 2042278253,
          'name': 'Articulated body pose estimation'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 26
    },
    {
      'id': 164970660,
      'title': 'Supervised Kernel Descriptors for Visual Recognition',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1226191267,
          'name': 'Peng Wang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1107949689,
          'name': 'Weiwei Xu'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        },
        {
          'id': 1056811471,
          'name': 'Shipeng Li'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 45
    },
    {
      'id': 165592484,
      'title': 'Capturing Complex Spatio-temporal Relations among Facial Muscles for Facial Expression Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1111238404,
          'name': 'Ziheng Wang'
        },
        {
          'id': 1445199645,
          'name': 'Shangfei Wang'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007851922,
          'name': 'Facial muscles'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032900044,
          'name': 'Bayesian network'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 101,
      'reference_count': 15
    },
    {
      'id': 167092337,
      'title': 'Exploring Structural Information and Fusing Multiple Features for Person Re-identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103683216,
          'abbr': 'MSU',
          'name': 'Michigan State University'
        }
      ],
      'author': [
        {
          'id': 1010968140,
          'name': 'Yang Hu'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2012191016,
          'name': 'Ternary operation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020489969,
          'name': 'HSL and HSV'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 19
    },
    {
      'id': 186230151,
      'title': 'Robust Object Co-detection',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1398757155,
          'name': 'Xin Guo'
        },
        {
          'id': 1205259868,
          'name': 'Dong Liu'
        },
        {
          'id': 1397171742,
          'name': 'Brendan Jou'
        },
        {
          'id': 1014939000,
          'name': 'Mojun Zhu'
        },
        {
          'id': 1324715703,
          'name': 'Anni Cai'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 32
    },
    {
      'id': 216420875,
      'title': 'Video Editing with Temporal, Spatial and Appearance Consistency',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2011076621,
          'name': 'Video editing'
        },
        {
          'id': 2025838069,
          'name': 'Rectification'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 18
    },
    {
      'id': 224516911,
      'title': 'Lp-Norm IDF for Large Scale Image Search',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1474070778,
          'name': 'Ziqiong Liu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2033064312,
          'name': 'tf–idf'
        },
        {
          'id': 2000585400,
          'name': 'Special case'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000210729,
          'name': 'Burstiness'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008582367,
          'name': 'Phenomenon'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 70,
      'reference_count': 24
    },
    {
      'id': 229654715,
      'title': 'Depth Acquisition from Density Modulated Binary Patterns',
      'affiliation': [
        {
          'id': 2100343429,
          'abbr': 'NEU',
          'name': 'Northeastern University (China)'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1132429857,
          'name': 'Zhe Yang'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1444858762,
          'name': 'Yueyi Zhang'
        },
        {
          'id': 1028017165,
          'name': 'Jiao Wang'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026040048,
          'name': 'Binary pattern'
        },
        {
          'id': 2044675089,
          'name': 'Diffraction grating'
        },
        {
          'id': 2015918219,
          'name': 'Laser'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2009050054,
          'name': 'Blossom algorithm'
        },
        {
          'id': 2015690902,
          'name': 'Phase (waves)'
        },
        {
          'id': 2048286749,
          'name': 'Infrared'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 28
    },
    {
      'id': 230400005,
      'title': 'Weakly-Supervised Dual Clustering for Image Semantic Segmentation',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1392088963,
          'name': 'Yang Liu'
        },
        {
          'id': 1143495402,
          'name': 'Jing Liu'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2014337830,
          'name': 'Fuzzy clustering'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2014243555,
          'name': 'Minimum spanning tree-based segmentation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 29
    },
    {
      'id': 25181340,
      'title': 'Multi-task Sparse Learning with Beta Process Prior for Action Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104085410,
          'abbr': 'SEU',
          'name': 'Southeast University'
        }
      ],
      'author': [
        {
          'id': 1215326337,
          'name': 'Chunfeng Yuan'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1268254570,
          'name': 'Guodong Tian'
        },
        {
          'id': 1200614384,
          'name': 'Shuang Yang'
        },
        {
          'id': 1004372793,
          'name': 'Haoran Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048621736,
          'name': 'Beta (finance)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2022854456,
          'name': 'Gibbs sampling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045620921,
          'name': 'MTSL'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 28
    },
    {
      'id': 260566222,
      'title': 'An Approach to Pose-Based Action Recognition',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1335216092,
          'name': 'Chunyu Wang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2047803603,
          'name': 'Body joints'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 228,
      'reference_count': 28
    },
    {
      'id': 261076288,
      'title': 'Harvesting Mid-level Visual Concepts from Large-Scale Internet Images',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1466387691,
          'name': 'Quannan Li'
        },
        {
          'id': 1385296331,
          'name': 'Jiajun Wu'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2003331133,
          'name': 'Image analysis'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006310969,
          'name': 'The Internet'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041368339,
          'name': 'Concept learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 123,
      'reference_count': 38
    },
    {
      'id': 269038996,
      'title': 'Fast Patch-Based Denoising Using Approximated Patch Geodesic Paths',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        }
      ],
      'author': [
        {
          'id': 1060362439,
          'name': 'Xiaogang Chen'
        },
        {
          'id': 1157673674,
          'name': 'Sing Bing Kang'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2018476708,
          'name': 'Approximation theory'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2015335388,
          'name': 'Discretization'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 38
    },
    {
      'id': 277281966,
      'title': 'Single-Sample Face Recognition with Image Corruption and Misalignment via Sparse Illumination Transfer',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1385007257,
          'name': 'Liansheng Zhuang'
        },
        {
          'id': 1056083972,
          'name': 'Allen Y. Yang'
        },
        {
          'id': 1258889174,
          'name': 'Zihan Zhou'
        },
        {
          'id': 1449566732,
          'name': 'Shankar Sastry'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2004033663,
          'name': 'Digital image'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 31
    },
    {
      'id': 278213508,
      'title': 'Beyond Physical Connections: Tree Models in Human Pose Estimation',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2104258153,
          'abbr': 'NICTA',
          'name': 'NICTA'
        }
      ],
      'author': [
        {
          'id': 1284336851,
          'name': 'Fang Wang'
        },
        {
          'id': 1030198773,
          'name': 'Yi Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007989407,
          'name': 'Tree structure'
        },
        {
          'id': 2031739240,
          'name': 'Decision tree model'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2001975124,
          'name': 'Joint probability distribution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039794279,
          'name': 'Incremental decision tree'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 68,
      'reference_count': 17
    },
    {
      'id': 284857984,
      'title': 'In Defense of Sparsity Based Face Recognition',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1481827642,
          'name': 'Jiani Hu'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2015280246,
          'name': 'FERET'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 102,
      'reference_count': 20
    },
    {
      'id': 296864469,
      'title': 'A Divide-and-Conquer Method for Scalable Low-Rank Latent Matrix Pursuit',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1130600180,
          'name': 'Yan Pan'
        },
        {
          'id': 1249782652,
          'name': 'Hanjiang Lai'
        },
        {
          'id': 1123953723,
          'name': 'Cong Liu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2023612680,
          'name': 'Sensor fusion'
        },
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019556046,
          'name': 'Divide and conquer algorithms'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 22
    },
    {
      'id': 304882068,
      'title': 'Weakly Supervised Learning for Attribute Localization in Outdoor Scenes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1114017858,
          'name': 'Shuo Wang'
        },
        {
          'id': 1425930056,
          'name': 'Jungseock Joo'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2025250989,
          'name': 'Attribute domain'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2031277625,
          'name': 'Parse tree'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 41,
      'reference_count': 17
    },
    {
      'id': 313830027,
      'title': 'Templateless Quasi-rigid Shape Modeling with Implicit Loop-Closure',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1119732629,
          'name': 'Ming Zeng'
        },
        {
          'id': 1060053621,
          'name': 'Jiaxiang Zheng'
        },
        {
          'id': 1208178920,
          'name': 'Xuan Cheng'
        },
        {
          'id': 1171804136,
          'name': 'Xinguo Liu'
        }
      ],
      'field': [
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2016181585,
          'name': '3D modeling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 31
    },
    {
      'id': 31435643,
      'title': 'Towards Pose Robust Face Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2015280246,
          'name': 'FERET'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 136,
      'reference_count': 31
    },
    {
      'id': 315166899,
      'title': 'Salient Object Detection: A Discriminative Regional Feature Integration Approach',
      'affiliation': [
        {
          'id': 2103783453,
          'abbr': 'UMASS',
          'name': 'University of Massachusetts Amherst'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1229315070,
          'name': 'Huaizu Jiang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1448998456,
          'name': 'Yang Wu'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        },
        {
          'id': 1056811471,
          'name': 'Shipeng Li'
        }
      ],
      'field': [
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2040290192,
          'name': 'Computational model'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 538,
      'reference_count': 98
    },
    {
      'id': 317067394,
      'title': 'Least Soft-Threshold Squares Tracking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2040449991,
          'name': 'Robust regression'
        },
        {
          'id': 2029772564,
          'name': 'Ordinary least squares'
        },
        {
          'id': 2043304010,
          'name': 'Total least squares'
        },
        {
          'id': 2046112833,
          'name': 'Iteratively reweighted least squares'
        },
        {
          'id': 2031485522,
          'name': 'Least trimmed squares'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2043874841,
          'name': 'Non-linear least squares'
        },
        {
          'id': 2037326220,
          'name': 'Linear least squares'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 207,
      'reference_count': 32
    },
    {
      'id': 324096004,
      'title': 'Prostate Segmentation in CT Images via Spatial-Constrained Transductive Lasso',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2104030429,
          'abbr': 'UNC',
          'name': 'University of North Carolina at Chapel Hill'
        }
      ],
      'author': [
        {
          'id': 1357346833,
          'name': 'Yinghuan Shi'
        },
        {
          'id': 1385777278,
          'name': 'Shu Liao'
        },
        {
          'id': 1438043911,
          'name': 'Yaozong Gao'
        },
        {
          'id': 1106893197,
          'name': 'Daoqiang Zhang'
        },
        {
          'id': 1253373124,
          'name': 'Yang Gao'
        },
        {
          'id': 1277369288,
          'name': 'Dinggang Shen'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2014952153,
          'name': 'Transduction (machine learning)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045761081,
          'name': 'Prostate'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048065051,
          'name': 'Lasso (statistics)'
        },
        {
          'id': 2037875506,
          'name': 'Voxel'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 25
    },
    {
      'id': 331853821,
      'title': 'Texture Enhanced Image Denoising via Gradient Histogram Preservation',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1179258585,
          'name': 'Chunwei Song'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036984325,
          'name': 'Image gradient'
        },
        {
          'id': 2022305872,
          'name': 'Video denoising'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 53,
      'reference_count': 31
    },
    {
      'id': 333823582,
      'title': 'Maximum Cohesive Grid of Superpixels for Fast Object Localization',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1392892375,
          'name': 'Liang Li'
        },
        {
          'id': 1096901309,
          'name': 'Wei Feng'
        },
        {
          'id': 1119606354,
          'name': 'Liang Wan'
        },
        {
          'id': 1036621052,
          'name': 'Jiawan Zhang'
        }
      ],
      'field': [
        {
          'id': 2015842062,
          'name': 'Locality'
        },
        {
          'id': 2041433144,
          'name': 'Grid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044405812,
          'name': 'Centroid'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 24
    },
    {
      'id': 341582105,
      'title': 'Probabilistic Graphlet Cut: Exploiting Spatial Structure Cue for Weakly Supervised Image Segmentation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1436259633,
          'name': 'Luming Zhang'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        },
        {
          'id': 1150299244,
          'name': 'Zicheng Liu'
        },
        {
          'id': 1412520726,
          'name': 'Xiao Liu'
        },
        {
          'id': 1114109048,
          'name': 'Jiajun Bu'
        },
        {
          'id': 1397597581,
          'name': 'Chun Chen'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2014243555,
          'name': 'Minimum spanning tree-based segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 138,
      'reference_count': 22
    },
    {
      'id': 346547734,
      'title': 'Area Preserving Brain Mapping',
      'affiliation': [
        {
          'id': 2103998608,
          'abbr': 'SBU',
          'name': 'Stony Brook University'
        },
        {
          'id': 2103284627,
          'abbr': 'UM',
          'name': 'University of Miami'
        },
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1214254057,
          'name': 'Zhengyu Su'
        },
        {
          'id': 1173072723,
          'name': 'Wei Zeng'
        },
        {
          'id': 1258938777,
          'name': 'Rui Shi'
        },
        {
          'id': 1266112357,
          'name': 'Yalin Wang'
        },
        {
          'id': 1080993047,
          'name': 'Jian Sun'
        },
        {
          'id': 1023103330,
          'name': 'Xianfeng Gu'
        }
      ],
      'field': [
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2019369034,
          'name': 'Caudate nucleus'
        },
        {
          'id': 2034509054,
          'name': 'Brain mapping'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023032611,
          'name': 'Voronoi diagram'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040948050,
          'name': 'Diffeomorphism'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2010047798,
          'name': 'Numerical stability'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 33
    },
    {
      'id': 349557427,
      'title': 'Blessing of Dimensionality: High-Dimensional Feature and Its Efficient Compression for Face Verification',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2038017848,
          'name': 'Feature (machine learning)'
        },
        {
          'id': 2003602520,
          'name': 'Projection method'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 362,
      'reference_count': 41
    },
    {
      'id': 35280791,
      'title': 'Image Matting with Local and Nonlocal Smooth Priors',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1383637716,
          'name': 'Dongqing Zou'
        },
        {
          'id': 1375966451,
          'name': 'Steven Zhiying Zhou'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        },
        {
          'id': 1056951125,
          'name': 'Ping Tan'
        }
      ],
      'field': [
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2041758568,
          'name': 'Laplace operator'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 65,
      'reference_count': 23
    },
    {
      'id': 358457569,
      'title': 'Bayesian Depth-from-Defocus with Shading Constraints',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1032681086,
          'name': 'Chen Li'
        },
        {
          'id': 1477641371,
          'name': 'Shuochen Su'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        },
        {
          'id': 1266662130,
          'name': 'Kun Zhou'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        },
        {
          'id': 2038563202,
          'name': 'Shading'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 34
    },
    {
      'id': 367382841,
      'title': 'Semi-supervised Node Splitting for Random Forest Construction',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1412520726,
          'name': 'Xiao Liu'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1150299244,
          'name': 'Zicheng Liu'
        },
        {
          'id': 1436259633,
          'name': 'Luming Zhang'
        },
        {
          'id': 1397597581,
          'name': 'Chun Chen'
        },
        {
          'id': 1114109048,
          'name': 'Jiajun Bu'
        }
      ],
      'field': [
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032708578,
          'name': 'Hyperplane'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 69,
      'reference_count': 28
    },
    {
      'id': 367456604,
      'title': 'Compressed Hashing',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103683216,
          'abbr': 'MSU',
          'name': 'Michigan State University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1487785514,
          'name': 'Yue Lin'
        },
        {
          'id': 1424995837,
          'name': 'Rong Jin'
        },
        {
          'id': 1444957931,
          'name': 'Deng Cai'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2004577253,
          'name': '2-choice hashing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012690106,
          'name': 'Open addressing'
        },
        {
          'id': 2017191986,
          'name': 'Hopscotch hashing'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 19
    },
    {
      'id': 392035758,
      'title': 'Binary Code Ranking with Weighted Hamming Distance',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        },
        {
          'id': 1355307321,
          'name': 'Yongdong Zhang'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1019941393,
          'name': 'Ke Lu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2046455943,
          'name': 'Hamming distance'
        },
        {
          'id': 2001365795,
          'name': 'Hamming bound'
        },
        {
          'id': 2034343294,
          'name': 'Linear code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034202567,
          'name': 'Hamming(7,4)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025551888,
          'name': 'Hamming graph'
        },
        {
          'id': 2011333272,
          'name': 'Hamming weight'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 26
    },
    {
      'id': 397148883,
      'title': 'Multi-level Discriminative Dictionary Learning towards Hierarchical Visual Categorization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1490545157,
          'name': 'Li Shen'
        },
        {
          'id': 1265461014,
          'name': 'Shuhui Wang'
        },
        {
          'id': 1499584189,
          'name': 'Gang Sun'
        },
        {
          'id': 1266998431,
          'name': 'Shuqiang Jiang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 33
    },
    {
      'id': 408306173,
      'title': 'Incorporating Structural Alternatives and Sharing into Hierarchy for Multiclass Object Recognition and Detection',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1260618775,
          'name': 'Xiaolong Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1315730169,
          'name': 'Lichao Huang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044658123,
          'name': 'Object model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043537062,
          'name': '3D single-object recognition'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2043719263,
          'name': 'Method'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002491204,
          'name': 'Multiclass classification'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 32
    },
    {
      'id': 433743568,
      'title': 'Recovering Line-Networks in Images by Junction-Point Processes',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100789236,
          'abbr': '',
          'name': 'University of Bonn'
        },
        {
          'id': 2104422715,
          'abbr': 'INRIA',
          'name': 'French Institute for Research in Computer Science and Automation'
        }
      ],
      'author': [
        {
          'id': 1270645595,
          'name': 'Dengfeng Chai'
        },
        {
          'id': 1343289779,
          'name': 'Wolfgang Förstner'
        },
        {
          'id': 1324039248,
          'name': 'Florent Lafarge'
        }
      ],
      'field': [
        {
          'id': 2021449421,
          'name': 'Probability density function'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026783567,
          'name': 'Stochastic geometry'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2032740529,
          'name': 'Point process'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017936520,
          'name': 'Graph cuts in computer vision'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2018721129,
          'name': 'Monte Carlo method'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 21
    },
    {
      'id': 451535855,
      'title': 'Image Tag Completion via Image-Specific and Tag-Specific Linear Sparse Reconstructions',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1361176412,
          'name': 'Zijia Lin'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1130136473,
          'name': 'Mingqing Hu'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1032509546,
          'name': 'Xiaojun Ye'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2005580323,
          'name': 'Concurrence'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 56,
      'reference_count': 16
    },
    {
      'id': 491025841,
      'title': 'Efficient 2D-to-3D Correspondence Filtering for Scalable 3D Object Recognition',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1039049178,
          'name': 'Qiang Hao'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        },
        {
          'id': 1381098672,
          'name': 'Yanwei Pang'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2043537062,
          'name': '3D single-object recognition'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2047278335,
          'name': 'Spurious relationship'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2040543323,
          'name': '2D to 3D conversion'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 27
    },
    {
      'id': 495356411,
      'title': 'Optical Computing System for Fast Non-uniform Image Deblurring',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1311917372,
          'name': 'Tao Yue'
        },
        {
          'id': 1462346539,
          'name': 'Jinli Suo'
        },
        {
          'id': 1145500222,
          'name': 'Xiangyang Ji'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2011181074,
          'name': 'Kernel (image processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002279732,
          'name': 'Optical computing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032763020,
          'name': 'Shake'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 27
    },
    {
      'id': 495535600,
      'title': 'Image Segmentation by Cascaded Region Agglomeration',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101166282,
          'abbr': 'TOYOTA-GLOBAL',
          'name': 'Toyota'
        }
      ],
      'author': [
        {
          'id': 1156247794,
          'name': 'Zhile Ren'
        },
        {
          'id': 1397102148,
          'name': 'Gregory Shakhnarovich'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014243555,
          'name': 'Minimum spanning tree-based segmentation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004497332,
          'name': 'Morphological gradient'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2000953899,
          'name': 'Range segmentation'
        },
        {
          'id': 2046248120,
          'name': 'Region growing'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 81,
      'reference_count': 26
    },
    {
      'id': 496302531,
      'title': 'Inductive Hashing on Manifolds',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        },
        {
          'id': 1036252985,
          'name': 'Zhenmin Tang'
        }
      ],
      'field': [
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 166,
      'reference_count': 35
    },
    {
      'id': 58388118,
      'title': 'Robust Estimation of Nonrigid Transformation for Point Set Registration',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1215444368,
          'name': 'Jiayi Ma'
        },
        {
          'id': 1215234163,
          'name': 'Ji Zhao'
        },
        {
          'id': 1326121431,
          'name': 'Jinwen Tian'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2000539458,
          'name': 'Point set registration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2009945168,
          'name': 'Hilbert space'
        },
        {
          'id': 2046405230,
          'name': 'Reproducing kernel Hilbert space'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2001568964,
          'name': 'Image registration'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007348363,
          'name': 'Sparse image'
        },
        {
          'id': 2004351181,
          'name': 'Shape context'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2043012749,
          'name': 'Robust statistics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 100,
      'reference_count': 27
    },
    {
      'id': 61998188,
      'title': 'Deep Learning Shape Priors for Object Segmentation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103301421,
          'abbr': 'FZU',
          'name': 'Fuzhou University'
        }
      ],
      'author': [
        {
          'id': 1064399496,
          'name': 'Fei Chen'
        },
        {
          'id': 1282930446,
          'name': 'Huimin Yu'
        },
        {
          'id': 1068439008,
          'name': 'Roland Hu'
        },
        {
          'id': 1449335849,
          'name': 'Xunxun Zeng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2006137252,
          'name': 'Active shape model'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041477979,
          'name': 'Shape analysis (digital geometry)'
        },
        {
          'id': 2047934002,
          'name': 'Boltzmann machine'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 48
    },
    {
      'id': 68052830,
      'title': 'Pixel-Level Hand Detection in Ego-centric Videos',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1187972211,
          'name': 'Cheng Li'
        },
        {
          'id': 1474780883,
          'name': 'Kris M. Kitani'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2032842853,
          'name': 'Global illumination'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 128,
      'reference_count': 28
    },
    {
      'id': 801939,
      'title': 'Discriminatively Trained And-Or Tree Models for Object Detection',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1404890702,
          'name': 'Xi Song'
        },
        {
          'id': 1088002310,
          'name': 'Tianfu Wu'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2019954370,
          'name': 'Directed graph'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2024326949,
          'name': 'And–or tree'
        },
        {
          'id': 2021556405,
          'name': 'Lattice (order)'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2031277625,
          'name': 'Parse tree'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 41,
      'reference_count': 25
    },
    {
      'id': 81195536,
      'title': 'Saliency Detection via Graph-Based Manifold Ranking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1278278891,
          'name': 'Chuan Yang'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025715469,
          'name': 'Background subtraction'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 910,
      'reference_count': 37
    },
    {
      'id': 92272431,
      'title': 'General Regression and Representation Model for Face Recognition',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1143500098,
          'name': 'Jianjun Qian'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 25
    },
    {
      'id': 95035433,
      'title': 'Optimized Product Quantization for Approximate Nearest Neighbor Search',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1007108791,
          'name': 'Tiezheng Ge'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1114225073,
          'name': 'Qifa Ke'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2039464793,
          'name': 'Quantization (physics)'
        },
        {
          'id': 2031426542,
          'name': 'Quantization (image processing)'
        },
        {
          'id': 2013285993,
          'name': 'Linde–Buzo–Gray algorithm'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2028189100,
          'name': 'Vector quantization'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2003527042,
          'name': 'Learning vector quantization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2133504402',
        'name': 'CVPR',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 186,
      'reference_count': 20
    },
    {
      'id': 101432045,
      'title': 'Salient object detection for searched web images via global saliency',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1257211451,
          'name': 'Peng Wang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1379805976,
          'name': 'Jie Feng'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        },
        {
          'id': 1056811471,
          'name': 'Shipeng Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2043057959,
          'name': 'Thumbnail'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 74,
      'reference_count': 33
    },
    {
      'id': 115027131,
      'title': 'Fan Shape Model for object detection',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        }
      ],
      'author': [
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1452206049,
          'name': 'Tianyang Ma'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1358872243,
          'name': 'Longin Jan Latecki'
        }
      ],
      'field': [
        {
          'id': 2001253077,
          'name': 'Leading-edge slats'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2021078998,
          'name': 'Adjacency list'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 23
    },
    {
      'id': 132813907,
      'title': 'What are good parts for hair shape modeling',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101386547,
          'abbr': 'HP',
          'name': 'Hewlett-Packard'
        }
      ],
      'author': [
        {
          'id': 1357272897,
          'name': 'Nan Wang'
        },
        {
          'id': 1128486384,
          'name': 'Haizhou Ai'
        },
        {
          'id': 1439075215,
          'name': 'Feng Tang'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2015017889,
          'name': 'Parameter identification problem'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019362237,
          'name': 'Statistic'
        },
        {
          'id': 2007775677,
          'name': 'Markov process'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 27
    },
    {
      'id': 14021770,
      'title': 'Image search results refinement via outlier detection using deep contexts',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1042859343,
          'name': 'Junyang Lu'
        },
        {
          'id': 1007151459,
          'name': 'Jiazhen Zhou'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        },
        {
          'id': 1264446421,
          'name': 'Xian-Sheng Hua'
        },
        {
          'id': 1056811471,
          'name': 'Shipeng Li'
        }
      ],
      'field': [
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010033551,
          'name': 'Text mining'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 23
    },
    {
      'id': 141752459,
      'title': 'l 2, 1 Regularized correntropy for robust feature selection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1020627459,
          'name': 'Ran He'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013839451,
          'name': 'Quadratic programming'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 90,
      'reference_count': 22
    },
    {
      'id': 152476213,
      'title': 'Omni-range spatial contexts for visual classification',
      'affiliation': [
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1201227050,
          'name': 'Mengdi Xu'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1379785310,
          'name': 'Pierre Moulin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2022539630,
          'name': 'Arbitrarily large'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012764013,
          'name': 'Bin'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048774318,
          'name': 'Convex function'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 28
    },
    {
      'id': 153470609,
      'title': 'Multi-scale dictionary for single image super-resolution',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1226140036,
          'name': 'Kaibing Zhang'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2047981673,
          'name': 'Hallucinate'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2002188700,
          'name': 'Kernel regression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 75,
      'reference_count': 18
    },
    {
      'id': 154806897,
      'title': 'Visual stem mapping and Geometric Tense coding for Augmented Visual Vocabulary',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101386547,
          'abbr': 'HP',
          'name': 'Hewlett-Packard'
        }
      ],
      'author': [
        {
          'id': 1223382402,
          'name': 'Ke Gao'
        },
        {
          'id': 1355307321,
          'name': 'Yongdong Zhang'
        },
        {
          'id': 1005014075,
          'name': 'Ping Luo'
        },
        {
          'id': 1301283836,
          'name': 'Wei Zhang'
        },
        {
          'id': 1209835342,
          'name': 'Junhai Xia'
        },
        {
          'id': 1361784665,
          'name': 'Shouxun Lin'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002913880,
          'name': 'Verb'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2010033551,
          'name': 'Text mining'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 18
    },
    {
      'id': 162252330,
      'title': 'Robust object tracking via sparsity-based collaborative model',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1450556865,
          'name': 'Wei Zhong'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 704,
      'reference_count': 31
    },
    {
      'id': 162301536,
      'title': 'Towards compact topical descriptors',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1401380662,
          'name': 'Jie Chen'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2045900009,
          'name': 'Image histogram'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2043354908,
          'name': 'GLOH'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007752821,
          'name': 'Biconvex optimization'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 33
    },
    {
      'id': 170681608,
      'title': 'Detecting texts of arbitrary orientations in natural images',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116922,
          'name': 'Algorithm design'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041743393,
          'name': 'Classification scheme'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 366,
      'reference_count': 31
    },
    {
      'id': 177202396,
      'title': 'Learning attention map from images',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1240470330,
          'name': 'Yao Lu'
        },
        {
          'id': 1039939827,
          'name': 'Wei Zhang'
        },
        {
          'id': 1403674360,
          'name': 'Cheng Jin'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000336464,
          'name': 'Fixation (visual)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 33
    },
    {
      'id': 182037534,
      'title': 'Covariance discriminative learning: A natural and efficient approach to image set classification',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1270956158,
          'name': 'Huimin Guo'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2010606662,
          'name': 'Covariance function'
        },
        {
          'id': 2034304287,
          'name': 'Estimation of covariance matrices'
        },
        {
          'id': 2044220123,
          'name': 'Matérn covariance function'
        },
        {
          'id': 2027527605,
          'name': 'Rational quadratic covariance function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 264,
      'reference_count': 31
    },
    {
      'id': 183269609,
      'title': 'Learning contour-fragment-based shape model with And-Or tree representation',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1260618775,
          'name': 'Xiaolong Wang'
        },
        {
          'id': 1375862671,
          'name': 'Wei Yang'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        }
      ],
      'field': [
        {
          'id': 2015808417,
          'name': 'Iterative learning control'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007989407,
          'name': 'Tree structure'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2006137252,
          'name': 'Active shape model'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031739240,
          'name': 'Decision tree model'
        },
        {
          'id': 2005038795,
          'name': 'Tree (data structure)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 26
    },
    {
      'id': 196247331,
      'title': 'Metric learning with two-dimensional smoothness for visual analysis',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1350530735,
          'name': 'Xinlei Chen'
        },
        {
          'id': 1153170896,
          'name': 'Zifei Tong'
        },
        {
          'id': 1058150719,
          'name': 'Haifeng Liu'
        },
        {
          'id': 1444957931,
          'name': 'Deng Cai'
        }
      ],
      'field': [
        {
          'id': 2015936120,
          'name': 'Spatial correlation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032759214,
          'name': 'Content-based image retrieval'
        },
        {
          'id': 2015335388,
          'name': 'Discretization'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2041758568,
          'name': 'Laplace operator'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 25
    },
    {
      'id': 210316276,
      'title': 'Scalable k-NN graph construction for visual descriptors',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1346075517,
          'name': 'Jing Wang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        },
        {
          'id': 1311627122,
          'name': 'Rui Gan'
        },
        {
          'id': 1056811471,
          'name': 'Shipeng Li'
        }
      ],
      'field': [
        {
          'id': 2030696514,
          'name': 'Null graph'
        },
        {
          'id': 2029735533,
          'name': 'Data point'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2040504686,
          'name': 'Graph (abstract data type)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2044286533,
          'name': 'Graph bandwidth'
        },
        {
          'id': 2015689527,
          'name': 'Clique-width'
        },
        {
          'id': 2011253300,
          'name': 'Modular decomposition'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 46
    },
    {
      'id': 211378217,
      'title': 'Graph-guided sparse reconstruction for region tagging',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1015841651,
          'name': 'Yahong Han'
        },
        {
          'id': 1374018797,
          'name': 'Fei Wu'
        },
        {
          'id': 1130645051,
          'name': 'Jian Shao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 23
    },
    {
      'id': 242528093,
      'title': '3D visual phrases for landmark recognition',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1039049178,
          'name': 'Qiang Hao'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        },
        {
          'id': 1381098672,
          'name': 'Yanwei Pang'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008771329,
          'name': 'Projective test'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044656016,
          'name': 'Phrase'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045181672,
          'name': 'Precision and recall'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 28
    },
    {
      'id': 24986882,
      'title': 'Street-to-shop: Cross-scenario clothing retrieval via parts alignment and auxiliary set',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1317796797,
          'name': 'Zheng Song'
        },
        {
          'id': 1248841738,
          'name': 'Guangcan Liu'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2027954625,
          'name': 'Clothing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 56,
      'reference_count': 18
    },
    {
      'id': 256615376,
      'title': 'Semi-coupled dictionary learning with applications to image super-resolution and photo-sketch synthesis',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1413782623,
          'name': 'Shenlong Wang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1345856729,
          'name': 'Yan Liang'
        },
        {
          'id': 1434292658,
          'name': 'Quan Pan'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2013926592,
          'name': 'Image conversion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 301,
      'reference_count': 29
    },
    {
      'id': 262667766,
      'title': 'A simple prior-free method for non-rigid structure-from-motion factorization',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1446146009,
          'name': 'Mingyi He'
        }
      ],
      'field': [
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2010083780,
          'name': 'Computational geometry'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2010571287,
          'name': 'Semidefinite programming'
        },
        {
          'id': 2002387869,
          'name': 'Linear system'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2037326220,
          'name': 'Linear least squares'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 61
    },
    {
      'id': 267243628,
      'title': 'A data-driven approach for facial expression synthesis in video',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        }
      ],
      'author': [
        {
          'id': 1015848213,
          'name': 'Kai Li'
        },
        {
          'id': 1098810408,
          'name': 'Feng Xu'
        },
        {
          'id': 1295811729,
          'name': 'Jue Wang'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        }
      ],
      'field': [
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043875830,
          'name': 'Computer animation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2004464020,
          'name': 'Computer facial animation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006790250,
          'name': 'Dijkstra\'s algorithm'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 29
    },
    {
      'id': 267862484,
      'title': 'Multi-pedestrian detection in crowded scenes: A global view',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2009047093,
          'name': 'Ranking SVM'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011607150,
          'name': 'Greedy algorithm'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 24
    },
    {
      'id': 291141734,
      'title': 'Weakly supervised sparse coding with geometric consistency pooling',
      'affiliation': [
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1121245329,
          'name': 'Liujuan Cao'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1018171187,
          'name': 'Yue Gao'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2028025525,
          'name': 'Semantic similarity'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 38
    },
    {
      'id': 301584157,
      'title': 'Learning ordinal discriminative features for age estimation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101307090,
          'abbr': 'NUIST',
          'name': 'Nanjing University of Information Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1076529138,
          'name': 'Changsheng Li'
        },
        {
          'id': 1358667323,
          'name': 'Qingshan Liu'
        },
        {
          'id': 1143495402,
          'name': 'Jing Liu'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2014072047,
          'name': 'Rank correlation'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 89,
      'reference_count': 30
    },
    {
      'id': 309415033,
      'title': 'Contour-based recognition',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        },
        {
          'id': 1181038210,
          'name': 'Yuhui Quan'
        },
        {
          'id': 1130245539,
          'name': 'Zhuming Zhang'
        },
        {
          'id': 1186862432,
          'name': 'Hui Ji'
        },
        {
          'id': 1242719811,
          'name': 'Cornelia Fermüller'
        },
        {
          'id': 1059380758,
          'name': 'Morimichi Nishigaki'
        },
        {
          'id': 1012001683,
          'name': 'Daniel DeMenthon'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021194508,
          'name': 'Combing'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2024301079,
          'name': 'Torque'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 20
    },
    {
      'id': 318587874,
      'title': 'Image sets alignment for Video-Based Face Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1249663582,
          'name': 'Zhen Cui'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1178371504,
          'name': 'Haihong Zhang'
        },
        {
          'id': 1154828074,
          'name': 'Shihong Lao'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2013839451,
          'name': 'Quadratic programming'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2036653928,
          'name': 'Principal angles'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 75,
      'reference_count': 36
    },
    {
      'id': 337499673,
      'title': 'Transfer re-identification: From person to set-based verification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010101173,
          'name': 'Small set'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 21
    },
    {
      'id': 345903447,
      'title': 'Context aware topic model for scene recognition',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103556481,
          'abbr': 'SIT',
          'name': 'Stevens Institute of Technology'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1306869427,
          'name': 'Zhenxing Niu'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2011877324,
          'name': 'Latent Dirichlet allocation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033757095,
          'name': 'Topic model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034077824,
          'name': 'Bayesian inference'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 80,
      'reference_count': 24
    },
    {
      'id': 350754816,
      'title': 'MAP-MRF inference based on extended junction tree representation',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1392150701,
          'name': 'Yun Zheng'
        },
        {
          'id': 1146434128,
          'name': 'Pei Chen'
        },
        {
          'id': 1269562039,
          'name': 'Jiang-Zhong Cao'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2010502260,
          'name': 'Local optimum'
        },
        {
          'id': 2040851902,
          'name': 'Linear programming relaxation'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2034116922,
          'name': 'Algorithm design'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2004984310,
          'name': 'Treewidth'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 24
    },
    {
      'id': 351140905,
      'title': 'Non-negative low rank and sparse graph for semi-supervised learning',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1385007257,
          'name': 'Liansheng Zhuang'
        },
        {
          'id': 1417864677,
          'name': 'Haoyuan Gao'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        },
        {
          'id': 1014403415,
          'name': 'Xin Zhang'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        }
      ],
      'field': [
        {
          'id': 2044851664,
          'name': 'Adjacency matrix'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006690686,
          'name': 'Dense graph'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 165,
      'reference_count': 21
    },
    {
      'id': 351731363,
      'title': 'PartBook for image parsing',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1047940084,
          'name': 'Kuiyuan Yang'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        },
        {
          'id': 1011179846,
          'name': 'Hong Jiang Zhang'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2031848198,
          'name': 'U-matrix'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2029522347,
          'name': 'Codebook'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 23
    },
    {
      'id': 356256944,
      'title': 'Fast approximate k-means via cluster closures',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1346075517,
          'name': 'Jing Wang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1114225073,
          'name': 'Qifa Ke'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1056811471,
          'name': 'Shipeng Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2029735533,
          'name': 'Data point'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2017093205,
          'name': 'k-medoids'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028767645,
          'name': 'Space partitioning'
        },
        {
          'id': 2046908382,
          'name': 'k-means clustering'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 64,
      'reference_count': 29
    },
    {
      'id': 35710822,
      'title': 'Video segmentation by tracing discontinuities in a trajectory embedding',
      'affiliation': [
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1145493025,
          'name': 'Katerina Fragkiadaki'
        },
        {
          'id': 1375509396,
          'name': 'Geng Zhang'
        },
        {
          'id': 1456916710,
          'name': 'Jianbo Shi'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000953899,
          'name': 'Range segmentation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 107,
      'reference_count': 26
    },
    {
      'id': 371584850,
      'title': 'Matrix completion by Truncated Nuclear Norm Regularization',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100974024,
          'abbr': 'UA',
          'name': 'University of Arizona'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1271464355,
          'name': 'Debing Zhang'
        },
        {
          'id': 1341411519,
          'name': 'Yao Hu'
        },
        {
          'id': 1382232381,
          'name': 'Jieping Ye'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        },
        {
          'id': 1370023928,
          'name': 'Xiaofei He'
        }
      ],
      'field': [
        {
          'id': 2021724463,
          'name': 'Missing data'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2018331948,
          'name': 'Singular value'
        },
        {
          'id': 2004564439,
          'name': 'Matrix completion'
        },
        {
          'id': 2033464047,
          'name': 'Low-rank approximation'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2008044200,
          'name': 'Mathematical analysis'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 60,
      'reference_count': 16
    },
    {
      'id': 375187769,
      'title': 'Robust visual tracking via multi-task sparse learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1152287350,
          'name': 'Narendra Ahuja'
        }
      ],
      'field': [
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2000585400,
          'name': 'Special case'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 458,
      'reference_count': 22
    },
    {
      'id': 378514285,
      'title': 'Discriminant image filter learning for face recognition with local binary pattern like representation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026693236,
          'name': 'Composite image filter'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 29
    },
    {
      'id': 38751389,
      'title': 'A New Convexity Measurement for 3D Meshes | NIST',
      'affiliation': [
        {
          'id': 2102015973,
          'abbr': 'NIST',
          'name': 'National Institute of Standards and Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1482713990,
          'name': 'Afzal Godil'
        },
        {
          'id': 1144931875,
          'name': 'Zhouhui Lian'
        }
      ],
      'field': [
        {
          'id': 2004998617,
          'name': 'NIST'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2006645328,
          'name': 'Convexity'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': 'https://www.nist.gov/publications/new-convexity-measurement-3d-meshes',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 399805957,
      'title': 'Consistent depth maps recovery from a trinocular video sequence',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104553663,
          'abbr': '',
          'name': 'Samsung'
        }
      ],
      'author': [
        {
          'id': 1253807513,
          'name': 'Wenzhuo Yang'
        },
        {
          'id': 1439429759,
          'name': 'Guofeng Zhang'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        },
        {
          'id': 1069839890,
          'name': 'Ji-Won Kim'
        },
        {
          'id': 1019049877,
          'name': 'Ho-Young Lee'
        }
      ],
      'field': [
        {
          'id': 2046466254,
          'name': 'Adaptive optics'
        },
        {
          'id': 2006582462,
          'name': 'Bundle'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2023853581,
          'name': 'Stereopsis'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 19
    },
    {
      'id': 410378833,
      'title': 'Adaptive figure-ground classification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1076936369,
          'name': 'Yisong Chen'
        },
        {
          'id': 1215408031,
          'name': 'Antoni B. Chan'
        },
        {
          'id': 1053734692,
          'name': 'Guoping Wang'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2029520636,
          'name': 'Statistical classification'
        },
        {
          'id': 2021599468,
          'name': 'Score'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 29
    },
    {
      'id': 411783696,
      'title': 'Sparse representation for blind image quality assessment',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1136150078,
          'name': 'Lihuo He'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2023361932,
          'name': 'Scene statistics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 91,
      'reference_count': 17
    },
    {
      'id': 418040952,
      'title': 'Unsupervised metric fusion by cross diffusion',
      'affiliation': [
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1374123895,
          'name': 'Bo Wang'
        },
        {
          'id': 1264285868,
          'name': 'Jiayan Jiang'
        },
        {
          'id': 1328098023,
          'name': 'Wei Wang'
        },
        {
          'id': 1262973957,
          'name': 'Zhi-Hua Zhou'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 100,
      'reference_count': 37
    },
    {
      'id': 425258037,
      'title': 'Multiple clustered instance learning for histopathology cancer image classification, segmentation and clustering',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1154796673,
          'name': 'Yan Xu'
        },
        {
          'id': 1404583400,
          'name': 'Jun-Yan Zhu'
        },
        {
          'id': 1376713499,
          'name': 'Eric Chang'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047636195,
          'name': 'Cancer'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038956318,
          'name': 'Medical imaging'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 28
    },
    {
      'id': 425799171,
      'title': 'What are we looking for: Towards statistical modeling of saccadic eye movements and visual saliency',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1082822706,
          'name': 'Xiaoshuai Sun'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        }
      ],
      'field': [
        {
          'id': 2019839618,
          'name': 'Projection pursuit'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019686854,
          'name': 'Saccadic masking'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2029595077,
          'name': 'Human eye'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043089337,
          'name': 'Saccadic suppression of image displacement'
        },
        {
          'id': 2029212302,
          'name': 'Statistical model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047185316,
          'name': 'Eye movement'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 24
    },
    {
      'id': 433210223,
      'title': 'A content-based video copy detection method with randomly projected binary features',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1474455939,
          'name': 'Chenxia Wu'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1225680359,
          'name': 'Jiemi Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2046455943,
          'name': 'Hamming distance'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2004930370,
          'name': 'Video copy detection'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044583116,
          'name': 'Random projection'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 19
    },
    {
      'id': 443645392,
      'title': 'Cross-based local multipoint filtering',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1368374079,
          'name': 'Jiangbo Lu'
        },
        {
          'id': 1159389582,
          'name': 'Keyang Shi'
        },
        {
          'id': 1386354323,
          'name': 'Dongbo Min'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1162486564,
          'name': 'Minh N. Do'
        }
      ],
      'field': [
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2047657425,
          'name': 'Piecewise linear function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 82,
      'reference_count': 20
    },
    {
      'id': 449634315,
      'title': 'Unsupervised object class discovery via saliency-guided multiple class learning',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1404583400,
          'name': 'Jun-Yan Zhu'
        },
        {
          'id': 1385296331,
          'name': 'Jiajun Wu'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1376713499,
          'name': 'Eric Chang'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2017679758,
          'name': 'Competitive learning'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044875838,
          'name': 'Pure Data'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 53,
      'reference_count': 38
    },
    {
      'id': 464133045,
      'title': 'On the dimensionality of video bricks under varying illumination',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1092843777,
          'name': 'Youdong Zhao'
        },
        {
          'id': 1404890702,
          'name': 'Xi Song'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        }
      ],
      'field': [
        {
          'id': 2025932237,
          'name': 'Foreground detection'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2008685983,
          'name': 'Linear model'
        },
        {
          'id': 2003227503,
          'name': 'Brick'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034486721,
          'name': 'Upper and lower bounds'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 22
    },
    {
      'id': 475678384,
      'title': 'Geometry constrained sparse coding for single image super-resolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1356856029,
          'name': 'Xiaoqiang Lu'
        },
        {
          'id': 1271895125,
          'name': 'Haoliang Yuan'
        },
        {
          'id': 1387275228,
          'name': 'Pingkun Yan'
        },
        {
          'id': 1016261701,
          'name': 'Yuan Yuan'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 22
    },
    {
      'id': 477689949,
      'title': 'Order determination and sparsity-regularized metric learning adaptive visual tracking',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1487787553,
          'name': 'Nan Jiang'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037963550,
          'name': 'Minimum description length'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2027810868,
          'name': 'Adaptive filter'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 18
    },
    {
      'id': 479744396,
      'title': 'Supervised hashing with kernels',
      'affiliation': [
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1218002805,
          'name': 'Jun Wang'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2012690106,
          'name': 'Open addressing'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2014123284,
          'name': 'Linear hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 738,
      'reference_count': 18
    },
    {
      'id': 484271660,
      'title': 'Online content-aware video condensation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1213721804,
          'name': 'Shikun Feng'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029110680,
          'name': 'Uncompressed video'
        },
        {
          'id': 2028552257,
          'name': 'Video compression picture types'
        },
        {
          'id': 2025153043,
          'name': 'Video capture'
        },
        {
          'id': 2046246198,
          'name': 'Video post-processing'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 20
    },
    {
      'id': 50151014,
      'title': 'QsRank: Query-sensitive hash code ranking for efficient ∊-neighbor search',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1218755902,
          'name': 'Xiao Zhang'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        },
        {
          'id': 1191827435,
          'name': 'Heung-Yeung Shum'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2030877868,
          'name': 'Hash list'
        },
        {
          'id': 2033262770,
          'name': 'Rolling hash'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029353294,
          'name': 'Primary clustering'
        },
        {
          'id': 2021631785,
          'name': 'Hash buster'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2015567075,
          'name': 'SWIFFT'
        },
        {
          'id': 2020670263,
          'name': 'Hash filter'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 20
    },
    {
      'id': 54115432,
      'title': 'Video stabilization with a depth camera',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1020679279,
          'name': 'Shuaicheng Liu'
        },
        {
          'id': 1421742713,
          'name': 'Yinting Wang'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1114109048,
          'name': 'Jiajun Bu'
        },
        {
          'id': 1056951125,
          'name': 'Ping Tan'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2017968033,
          'name': 'Camera auto-calibration'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2008119544,
          'name': 'Image stabilization'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 18
    },
    {
      'id': 55159809,
      'title': 'Exemplar-based human action pose correction and tagging',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1320382318,
          'name': 'Wei Shen'
        },
        {
          'id': 1245441549,
          'name': 'Ke Deng'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1024872377,
          'name': 'Tommer Leyvand'
        },
        {
          'id': 1212262694,
          'name': 'Baining Guo'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 47,
      'reference_count': 22
    },
    {
      'id': 6207104,
      'title': 'The scale of edges',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1164703402,
          'name': 'Xianming Liu'
        },
        {
          'id': 1288576539,
          'name': 'Changhu Wang'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2043626220,
          'name': 'Open problem'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010092225,
          'name': 'Isotropy'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 21
    },
    {
      'id': 64710242,
      'title': 'Robust Maximum Likelihood estimation by sparse bundle adjustment using the L 1 norm',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1453482599,
          'name': 'Zhijun Dai'
        },
        {
          'id': 1137295227,
          'name': 'Fengjun Zhang'
        },
        {
          'id': 1139771374,
          'name': 'Hongan Wang'
        }
      ],
      'field': [
        {
          'id': 2006582462,
          'name': 'Bundle'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2038496012,
          'name': 'Jacobian matrix and determinant'
        },
        {
          'id': 2000345757,
          'name': 'Decomposition method (constraint satisfaction)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2018533029,
          'name': 'Bundle adjustment'
        },
        {
          'id': 2047028141,
          'name': 'Norm (mathematics)'
        },
        {
          'id': 2015062024,
          'name': 'Interior point method'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 13
    },
    {
      'id': 68393626,
      'title': 'A-Optimal Non-negative Projection for image representation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1066428032,
          'name': 'Haifeng Liu'
        },
        {
          'id': 1431337545,
          'name': 'Zheng Yang'
        },
        {
          'id': 1043442721,
          'name': 'Zhaohui Wu'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2009246595,
          'name': 'External Data Representation'
        },
        {
          'id': 2045022011,
          'name': 'Data structure'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046713073,
          'name': 'Non-negative matrix factorization'
        },
        {
          'id': 2043012749,
          'name': 'Robust statistics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 24
    },
    {
      'id': 69515330,
      'title': 'Visual tracking via adaptive structural local sparse appearance model',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1382738526,
          'name': 'Xu Jia'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 839,
      'reference_count': 22
    },
    {
      'id': 72697897,
      'title': 'Edge-preserving photometric stereo via depth fusion',
      'affiliation': [
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1387418134,
          'name': 'Qing Zhang'
        },
        {
          'id': 1417334466,
          'name': 'Mao Ye'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        },
        {
          'id': 1408169598,
          'name': 'Bennett Wilburn'
        },
        {
          'id': 1282930446,
          'name': 'Huimin Yu'
        }
      ],
      'field': [
        {
          'id': 2023612680,
          'name': 'Sensor fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036141665,
          'name': 'Photometric stereo'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 29
    },
    {
      'id': 74805257,
      'title': 'Synthesizing oil painting surface geometry from a single photograph',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1116242468,
          'name': 'Wei Luo'
        },
        {
          'id': 1304203487,
          'name': 'Zheng Lu'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1388033676,
          'name': 'Ying-Qing Xu'
        },
        {
          'id': 1046651333,
          'name': 'Moshe Ben-Ezra'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1362764324,
          'name': 'Michael S. Brown'
        }
      ],
      'field': [
        {
          'id': 2036141665,
          'name': 'Photometric stereo'
        },
        {
          'id': 2025013130,
          'name': 'Normal mapping'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047564696,
          'name': 'Input/output'
        },
        {
          'id': 2018727238,
          'name': 'Oil painting'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011731904,
          'name': 'Painting'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 32
    },
    {
      'id': 75621791,
      'title': 'Sparse representation for face recognition based on discriminative low-rank dictionary learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1017261741,
          'name': 'Long Ma'
        },
        {
          'id': 1101975385,
          'name': 'Chunheng Wang'
        },
        {
          'id': 1126399395,
          'name': 'Baihua Xiao'
        },
        {
          'id': 1223373912,
          'name': 'Wen Zhou'
        }
      ],
      'field': [
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2004564439,
          'name': 'Matrix completion'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 148,
      'reference_count': 28
    },
    {
      'id': 76562657,
      'title': 'Robust tracking via weakly supervised ranking SVM',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1375891015,
          'name': 'Yancheng Bai'
        },
        {
          'id': 1086300120,
          'name': 'Ming Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2009047093,
          'name': 'Ranking SVM'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 83,
      'reference_count': 36
    },
    {
      'id': 91446129,
      'title': 'A new convexity measurement for 3D meshes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102015973,
          'abbr': 'NIST',
          'name': 'National Institute of Standards and Technology'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1144931875,
          'name': 'Zhouhui Lian'
        },
        {
          'id': 1482713990,
          'name': 'Afzal Godil'
        },
        {
          'id': 1293598313,
          'name': 'Paul L. Rosin'
        },
        {
          'id': 1496180847,
          'name': 'Xianfang Sun'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2036494929,
          'name': 'Intuition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2006645328,
          'name': 'Convexity'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 35
    },
    {
      'id': 99059150,
      'title': 'Fixed-rank representation for unsupervised visual learning',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102243180,
          'abbr': 'RI',
          'name': 'Robotics Institute'
        }
      ],
      'author': [
        {
          'id': 1369218000,
          'name': 'Risheng Liu'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1300722889,
          'name': 'Fernando De la Torre'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        }
      ],
      'field': [
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131838301',
        'name': 'CVPR',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 104,
      'reference_count': 33
    },
    {
      'id': 103917891,
      'title': 'Person re-identification by probabilistic relative distance comparison',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        }
      ],
      'field': [
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2027184823,
          'name': 'Sample size determination'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2022550578,
          'name': 'Distance education'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 424,
      'reference_count': 17
    },
    {
      'id': 110325902,
      'title': 'Sparse concept coding for visual analysis',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1444957931,
          'name': 'Deng Cai'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        },
        {
          'id': 1370023928,
          'name': 'Xiaofei He'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2046713073,
          'name': 'Non-negative matrix factorization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 24
    },
    {
      'id': 119716982,
      'title': 'High resolution multispectral video capture with a hybrid camera system',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1279375068,
          'name': 'Xun Cao'
        },
        {
          'id': 1348430863,
          'name': 'Xin Tong'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027632483,
          'name': 'Three-CCD camera'
        },
        {
          'id': 2029110680,
          'name': 'Uncompressed video'
        },
        {
          'id': 2025153043,
          'name': 'Video capture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046246198,
          'name': 'Video post-processing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030410974,
          'name': 'Motion compensation'
        },
        {
          'id': 2001099614,
          'name': 'Video camera'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2014162048,
          'name': 'Multiview Video Coding'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 22
    },
    {
      'id': 124783300,
      'title': 'Simulating human saccadic scanpaths on natural images',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1175836319,
          'name': 'Cheng Chen'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1372819545,
          'name': 'Tingting Jiang'
        },
        {
          'id': 1046303044,
          'name': 'Fang Fang'
        },
        {
          'id': 1063017494,
          'name': 'Yuan Yao'
        }
      ],
      'field': [
        {
          'id': 2007476143,
          'name': 'Saccade'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019686854,
          'name': 'Saccadic masking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2047185316,
          'name': 'Eye movement'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 43,
      'reference_count': 25
    },
    {
      'id': 127401784,
      'title': 'Image classification by non-negative sparse coding, low-rank and sparse decomposition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1075868260,
          'name': 'Chunjie Zhang'
        },
        {
          'id': 1143495402,
          'name': 'Jing Liu'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        },
        {
          'id': 1093126741,
          'name': 'Songde Ma'
        }
      ],
      'field': [
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 115,
      'reference_count': 36
    },
    {
      'id': 133296137,
      'title': 'Non-negative local coordinate factorization for image representation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1335043046,
          'name': 'Yan Chen'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        },
        {
          'id': 1370023928,
          'name': 'Xiaofei He'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2046713073,
          'name': 'Non-negative matrix factorization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 6
    },
    {
      'id': 143825145,
      'title': 'Biologically inspired template matching using scene context',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1028164167,
          'name': 'Changxin Gao'
        },
        {
          'id': 1248648678,
          'name': 'Nong Sang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008206327,
          'name': 'Change blindness'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2007507776,
          'name': 'Blindness'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2010033551,
          'name': 'Text mining'
        },
        {
          'id': 2008582367,
          'name': 'Phenomenon'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 21
    },
    {
      'id': 147560343,
      'title': 'Spatial-DiscLDA for visual recognition',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1306869427,
          'name': 'Zhenxing Niu'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2016016661,
          'name': 'LabelMe'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2040908446,
          'name': 'Approximate inference'
        },
        {
          'id': 2011877324,
          'name': 'Latent Dirichlet allocation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2033757095,
          'name': 'Topic model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030753631,
          'name': 'Probabilistic latent semantic analysis'
        },
        {
          'id': 2022854456,
          'name': 'Gibbs sampling'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 27
    },
    {
      'id': 147576477,
      'title': 'Hybrid generative-discriminative classification using posterior divergence',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1322630000,
          'name': 'Xiong Li'
        },
        {
          'id': 1381001156,
          'name': 'Tai Sing Lee'
        },
        {
          'id': 1074973158,
          'name': 'Yuncai Liu'
        }
      ],
      'field': [
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2002073950,
          'name': 'Expectation–maximization algorithm'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2047795701,
          'name': 'Linear classifier'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 27
    },
    {
      'id': 151181292,
      'title': 'Learning non-local range Markov Random field for image restoration',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1346009511,
          'name': 'Marshall F. Tappen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015860583,
          'name': 'Spatial filter'
        },
        {
          'id': 2034325238,
          'name': 'Filter bank'
        },
        {
          'id': 2003528380,
          'name': 'Clique'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007775677,
          'name': 'Markov process'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 32
    },
    {
      'id': 179306643,
      'title': 'Face illumination transfer through edge-preserving filters',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1054814271,
          'name': 'Mengmeng Chen'
        },
        {
          'id': 1493538103,
          'name': 'Xin Jin'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2006808376,
          'name': 'Lightness'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 18
    },
    {
      'id': 188008457,
      'title': 'Exploring relations of visual codes for image classification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1260597004,
          'name': 'Yongzhen Huang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1353883990,
          'name': 'Chong Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2010134313,
          'name': 'Extensional definition'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2029522347,
          'name': 'Codebook'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 18
    },
    {
      'id': 197549003,
      'title': 'TVParser: An automatic TV video parsing method',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1356892321,
          'name': 'Chao Liang'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2002647072,
          'name': 'Timestamp'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 28
    },
    {
      'id': 2199901,
      'title': 'Exploring aligned complementary image pair for blind motion deblurring',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1021597835,
          'name': 'Wen Li'
        },
        {
          'id': 1457898256,
          'name': 'Jun Zhang'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2008086083,
          'name': 'Ringing artifacts'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 25
    },
    {
      'id': 225876356,
      'title': 'Segment an image by looking into an image corpus',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1467929556,
          'name': 'Xiaobai Liu'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1092602214,
          'name': 'Hai Jin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 22
    },
    {
      'id': 226059600,
      'title': 'Camera calibration with lens distortion from low-rank textures',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1375633310,
          'name': 'Zhengdong Zhang'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2017968033,
          'name': 'Camera auto-calibration'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2021349405,
          'name': 'Distortion (optics)'
        },
        {
          'id': 2006193051,
          'name': 'Rank (linear algebra)'
        },
        {
          'id': 2005547078,
          'name': 'Camera resectioning'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 31
    },
    {
      'id': 236171662,
      'title': 'Rank-SIFT: Learning to rank repeatable local interest points',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1264200003,
          'name': 'Bing Li'
        },
        {
          'id': 1114339058,
          'name': 'Rong Xiao'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1435529302,
          'name': 'Bao-Liang Lu'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041308320,
          'name': 'sort'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038993484,
          'name': 'Rule-based system'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020399200,
          'name': 'Visual Objects'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 18
    },
    {
      'id': 241206481,
      'title': 'Hierarchical anatomical brain networks for MCI prediction by partial least square analysis',
      'affiliation': [
        {
          'id': 2104030429,
          'abbr': 'UNC',
          'name': 'University of North Carolina at Chapel Hill'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1130844234,
          'name': 'Luping Zhou'
        },
        {
          'id': 1485282944,
          'name': 'Yaping Wang'
        },
        {
          'id': 1404525966,
          'name': 'Yang Li'
        },
        {
          'id': 1498116035,
          'name': 'Pew Thian Yap'
        },
        {
          'id': 1277369288,
          'name': 'Dinggang Shen'
        },
        {
          'id': 1047145625,
          'name': 'Adni'
        }
      ],
      'field': [
        {
          'id': 2023832681,
          'name': 'Structural change'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 14
    },
    {
      'id': 255324724,
      'title': 'Nonparametric density estimation on a graph: Learning framework, fast approximation and application in image segmentation',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1096488246,
          'name': 'Zhiding Yu'
        },
        {
          'id': 1282453484,
          'name': 'Oscar C. Au'
        },
        {
          'id': 1454739938,
          'name': 'Ketan Tang'
        },
        {
          'id': 1358483092,
          'name': 'Chunjing Xu'
        }
      ],
      'field': [
        {
          'id': 2024906741,
          'name': 'Connected dominating set'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2016965152,
          'name': 'Connectivity'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2041409623,
          'name': 'Density estimation'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022074371,
          'name': 'Spanning tree'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2049895596,
          'name': 'Mean-shift'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 10
    },
    {
      'id': 264869107,
      'title': 'Salient coding for image classification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1260597004,
          'name': 'Yongzhen Huang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1225242930,
          'name': 'Yinan Yu'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008779518,
          'name': 'Bag-of-words model'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2029522347,
          'name': 'Codebook'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2046485539,
          'name': 'Large deviations theory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 107,
      'reference_count': 22
    },
    {
      'id': 26631967,
      'title': 'Intrinsic images using optimization',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1494454677,
          'name': 'Xiaoshan Yang'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047672818,
          'name': 'Instrumental and intrinsic value'
        },
        {
          'id': 2047846951,
          'name': 'A-weighting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001942329,
          'name': 'Reflectivity'
        },
        {
          'id': 2004352057,
          'name': 'Premise'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 20
    },
    {
      'id': 270191211,
      'title': 'Global contrast based salient region detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2103220118,
          'abbr': 'LEHIGH',
          'name': 'Lehigh University'
        }
      ],
      'author': [
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1044068937,
          'name': 'Guo-Xin Zhang'
        },
        {
          'id': 1117453600,
          'name': 'Niloy J. Mitra'
        },
        {
          'id': 1366939506,
          'name': 'Xiaolei Huang'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        }
      ],
      'field': [
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2043560954,
          'name': 'GrabCut'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 1849,
      'reference_count': 81
    },
    {
      'id': 271409714,
      'title': 'Style transfer matrix learning for writer adaptation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1279838029,
          'name': 'Xu-Yao Zhang'
        },
        {
          'id': 1429067054,
          'name': 'Cheng-Lin Liu'
        }
      ],
      'field': [
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2006887120,
          'name': 'Learning classifier system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003527042,
          'name': 'Learning vector quantization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022981540,
          'name': 'Handwriting recognition'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 24
    },
    {
      'id': 288890197,
      'title': 'Coupled information-theoretic encoding for face photo-sketch recognition',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1491718072,
          'name': 'Wei Zhang'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2007773467,
          'name': 'Sketch recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2032362380,
          'name': 'FERET database'
        },
        {
          'id': 2029520636,
          'name': 'Statistical classification'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 161,
      'reference_count': 35
    },
    {
      'id': 320066210,
      'title': 'Continuously tracking and see-through occlusion based on a new hybrid synthetic aperture imaging model',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1199302739,
          'name': 'Tao Yang'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1359855042,
          'name': 'Xiaomin Tong'
        },
        {
          'id': 1175387684,
          'name': 'Xiaoqiang Zhang'
        },
        {
          'id': 1323555715,
          'name': 'Rui Qi Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015063766,
          'name': 'Synthetic aperture radar'
        },
        {
          'id': 2038172366,
          'name': 'Silhouette'
        },
        {
          'id': 2048954615,
          'name': 'Aperture'
        },
        {
          'id': 2002588153,
          'name': 'Radar imaging'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041203790,
          'name': 'Computer graphics'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 26
    },
    {
      'id': 341960900,
      'title': 'Partial similarity based nonparametric scene parsing in certain environment',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1036716765,
          'name': 'Honghui Zhang'
        },
        {
          'id': 1053642085,
          'name': 'Tian Fang'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        },
        {
          'id': 1481958492,
          'name': 'Long Quan'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2028963219,
          'name': 'Nonparametric statistics'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 26
    },
    {
      'id': 348337469,
      'title': 'Instantly telling what happens in a video sequence using simple features',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1073767988,
          'name': 'Liang Wang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1372819545,
          'name': 'Tingting Jiang'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2046688259,
          'name': 'Simple Features'
        },
        {
          'id': 2037114451,
          'name': 'Contrast set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004061258,
          'name': 'Motion capture'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 19
    },
    {
      'id': 354316243,
      'title': 'A unified framework for locating and recognizing human actions',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1390490495,
          'name': 'Yuelei Xie'
        },
        {
          'id': 1467937951,
          'name': 'Hong Chang'
        },
        {
          'id': 1212050793,
          'name': 'Zhe Li'
        },
        {
          'id': 1409023118,
          'name': 'Luhong Liang'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        },
        {
          'id': 1149572625,
          'name': 'Debin Zhao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2018399408,
          'name': 'Preprocessor'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 24
    },
    {
      'id': 357285504,
      'title': 'A rank-order distance based clustering algorithm for face tagging',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1061494498,
          'name': 'Chunhui Zhu'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2001604607,
          'name': 'Canopy clustering algorithm'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027925582,
          'name': 'Sorting'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 53,
      'reference_count': 23
    },
    {
      'id': 372887699,
      'title': 'An effective document image deblurring algorithm',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1060362439,
          'name': 'Xiaogang Chen'
        },
        {
          'id': 1156673188,
          'name': 'Xiangjian He'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        },
        {
          'id': 1165204304,
          'name': 'Qiang Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2039013772,
          'name': 'Optical character recognition'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2048479462,
          'name': 'Digital document'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 30
    },
    {
      'id': 385780732,
      'title': 'Tracking low resolution objects by metric preservation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1487787553,
          'name': 'Nan Jiang'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1338135680,
          'name': 'Heng Su'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2018307763,
          'name': 'Visual servoing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 17
    },
    {
      'id': 402663888,
      'title': 'Nonnegative sparse coding for discriminative semi-supervised learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1020627459,
          'name': 'Ran He'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1341931364,
          'name': 'Bao-Gang Hu'
        },
        {
          'id': 1396536506,
          'name': 'Xiangwei Kong'
        }
      ],
      'field': [
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2006690686,
          'name': 'Dense graph'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 30
    },
    {
      'id': 40311687,
      'title': 'Sparsity-based image denoising via dictionary learning and structural clustering',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102522667,
          'abbr': 'WVU',
          'name': 'West Virginia University'
        }
      ],
      'author': [
        {
          'id': 1284606133,
          'name': 'Weisheng Dong'
        },
        {
          'id': 1173434986,
          'name': 'Xin Li'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1291914506,
          'name': 'Guangming Shi'
        }
      ],
      'field': [
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2047944143,
          'name': 'Basis pursuit denoising'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 276,
      'reference_count': 18
    },
    {
      'id': 40767902,
      'title': 'Visual saliency detection by spatially weighted dissimilarity',
      'affiliation': [
        {
          'id': 2101273003,
          'abbr': 'BJUT',
          'name': 'Beijing University of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        }
      ],
      'author': [
        {
          'id': 1274488162,
          'name': 'Lijuan Duan'
        },
        {
          'id': 1411143780,
          'name': 'Chunpeng Wu'
        },
        {
          'id': 1153514990,
          'name': 'Jun Miao'
        },
        {
          'id': 1420142893,
          'name': 'Laiyun Qing'
        },
        {
          'id': 1471200949,
          'name': 'Yu Fu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 173,
      'reference_count': 29
    },
    {
      'id': 412444735,
      'title': 'Aggregating gradient distributions into intensity orders: A novel local image descriptor',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1163323626,
          'name': 'Fuchao Wu'
        },
        {
          'id': 1420413515,
          'name': 'Zhanyi Hu'
        }
      ],
      'field': [
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2005911017,
          'name': 'Novelty'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 78,
      'reference_count': 28
    },
    {
      'id': 437879914,
      'title': 'A direct formulation for totally-corrective multi-class boosting',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1004136098,
          'name': 'Zhihui Hao'
        }
      ],
      'field': [
        {
          'id': 2038236017,
          'name': 'Column generation'
        },
        {
          'id': 2040612589,
          'name': 'BrownBoost'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011900019,
          'name': 'Gradient boosting'
        },
        {
          'id': 2019631421,
          'name': 'LPBoost'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 19
    },
    {
      'id': 446332178,
      'title': 'Efficient Euclidean distance transform using perpendicular bisector segmentation',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1464245681,
          'name': 'Jun Wang'
        },
        {
          'id': 1010741614,
          'name': 'Ying Tan'
        }
      ],
      'field': [
        {
          'id': 2031909882,
          'name': 'Binary image'
        },
        {
          'id': 2000823806,
          'name': 'Combinatorics'
        },
        {
          'id': 2047555135,
          'name': 'Distance from a point to a line'
        },
        {
          'id': 2034019277,
          'name': 'Euclidean distance matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036056183,
          'name': 'Distance from a point to a plane'
        },
        {
          'id': 2018186560,
          'name': 'Distance transform'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046697460,
          'name': 'Weighted Voronoi diagram'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2026302375,
          'name': 'Top-hat transform'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 20
    },
    {
      'id': 447693634,
      'title': 'Boosted local structured HOG-LBP for object localization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1225242930,
          'name': 'Yinan Yu'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 100,
      'reference_count': 25
    },
    {
      'id': 45294858,
      'title': 'Linearity of each channel pixel values from a surface in and out of shadows and its applications',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1404282428,
          'name': 'Jiandong Tian'
        },
        {
          'id': 1321471777,
          'name': 'Yandong Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020879486,
          'name': 'Linearity'
        },
        {
          'id': 2043567494,
          'name': 'Spectral power distribution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023813992,
          'name': 'Daylight'
        },
        {
          'id': 2041457668,
          'name': 'Sunlight'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044238634,
          'name': 'Diffuse sky radiation'
        },
        {
          'id': 2029730892,
          'name': 'Channel (digital image)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 22
    },
    {
      'id': 460817512,
      'title': 'Robust sparse coding for face recognition',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1376805014,
          'name': 'Meng Yang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2040449991,
          'name': 'Robust regression'
        },
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 419,
      'reference_count': 31
    },
    {
      'id': 47031370,
      'title': 'A real-time system for 3D recovery of dynamic scene with multiple RGBD imagers',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1170674581,
          'name': 'Duan Yong'
        },
        {
          'id': 1492869741,
          'name': 'Chen Lei'
        },
        {
          'id': 1130416707,
          'name': 'Wang Yucheng'
        },
        {
          'id': 1323375762,
          'name': 'Yang Min'
        },
        {
          'id': 1255868308,
          'name': 'Qin Xiameng'
        },
        {
          'id': 1322874605,
          'name': 'He Shaoyang'
        },
        {
          'id': 1282933234,
          'name': 'Jia Yunde'
        }
      ],
      'field': [
        {
          'id': 2004016088,
          'name': 'Real-time operating system'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2007276127,
          'name': 'Coordinate system'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2024753776,
          'name': 'Visual hull'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 19
    },
    {
      'id': 473291592,
      'title': 'Minimum error bounded efficient ℓ1 tracker with occlusion detection',
      'affiliation': [
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2101083496,
          'abbr': 'AFRL',
          'name': 'Air Force Research Laboratory'
        }
      ],
      'author': [
        {
          'id': 1077520302,
          'name': 'Xue Mei'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1009122519,
          'name': 'Yi Wu'
        },
        {
          'id': 1186529124,
          'name': 'Erik Blasch'
        },
        {
          'id': 1082740756,
          'name': 'Li Bai'
        }
      ],
      'field': [
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2037326220,
          'name': 'Linear least squares'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2011767737,
          'name': 'Bounded function'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2017171717,
          'name': 'Resampling'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 263,
      'reference_count': 25
    },
    {
      'id': 474560316,
      'title': 'Textured 3D face recognition using biological vision-based facial representation and optimized weighted sum fusion',
      'affiliation': [
        {
          'id': 2100121684,
          'abbr': 'ECL',
          'name': 'École centrale de Lyon'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1002940392,
          'name': 'Wael Ben Soltana'
        },
        {
          'id': 1405931785,
          'name': 'Mohsen Ardabilian'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1334676665,
          'name': 'Liming Chen'
        }
      ],
      'field': [
        {
          'id': 2030499373,
          'name': 'Geometric transformation'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 30
    },
    {
      'id': 475092323,
      'title': 'Feature context for image classification and object detection',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        }
      ],
      'author': [
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1358872243,
          'name': 'Longin Jan Latecki'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045900009,
          'name': 'Image histogram'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2012764013,
          'name': 'Bin'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004351181,
          'name': 'Shape context'
        },
        {
          'id': 2019434669,
          'name': 'Histogram matching'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 32
    },
    {
      'id': 476770891,
      'title': 'A novel supervised level set method for non-rigid object tracking',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1072890379,
          'name': 'Xin Sun'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1136796451,
          'name': 'Shengping Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020060870,
          'name': 'Level set method'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2018516098,
          'name': 'Curve fitting'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 22
    },
    {
      'id': 485674054,
      'title': 'Time and space efficient spectral clustering via column sampling',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1275848732,
          'name': 'Mu Li'
        },
        {
          'id': 1042622385,
          'name': 'Xiao-Chen Lian'
        },
        {
          'id': 1416261758,
          'name': 'James Tin-Yau Kwok'
        },
        {
          'id': 1435529302,
          'name': 'Bao-Liang Lu'
        }
      ],
      'field': [
        {
          'id': 2037814540,
          'name': 'Data stream clustering'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2014277208,
          'name': 'Clustering high-dimensional data'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035951257,
          'name': 'CURE data clustering algorithm'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001604607,
          'name': 'Canopy clustering algorithm'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 17
    },
    {
      'id': 487619531,
      'title': 'Action recognition by dense trajectories',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104422715,
          'abbr': 'INRIA',
          'name': 'French Institute for Research in Computer Science and Automation'
        }
      ],
      'author': [
        {
          'id': 1165600816,
          'name': 'Heng Wang'
        },
        {
          'id': 1370810371,
          'name': 'Alexander Kläser'
        },
        {
          'id': 1076363282,
          'name': 'Cordelia Schmid'
        },
        {
          'id': 1429067054,
          'name': 'Cheng-Lin Liu'
        }
      ],
      'field': [
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 1395,
      'reference_count': 37
    },
    {
      'id': 494979712,
      'title': 'A large-scale benchmark dataset for event recognition in surveillance video',
      'affiliation': [
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2103083837,
          'abbr': 'KITWARE',
          'name': 'Kitware'
        },
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2102526209,
          'abbr': 'UCI',
          'name': 'University of California, Irvine'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2104904263,
          'abbr': 'UCR',
          'name': 'University of California, Riverside'
        },
        {
          'id': 2101550898,
          'abbr': 'DARPA',
          'name': 'DARPA'
        }
      ],
      'author': [
        {
          'id': 1073383343,
          'name': 'Sangmin Oh'
        },
        {
          'id': 1050790064,
          'name': 'Anthony Hoogs'
        },
        {
          'id': 1318677360,
          'name': 'A. G. Amitha Perera'
        },
        {
          'id': 1076968270,
          'name': 'Naresh P. Cuntoor'
        },
        {
          'id': 1110280524,
          'name': 'Chia-Chih Chen'
        },
        {
          'id': 1445472741,
          'name': 'Jong Taek Lee'
        },
        {
          'id': 1452825264,
          'name': 'Saurajit Mukherjee'
        },
        {
          'id': 1241935393,
          'name': 'Jake K. Aggarwal'
        },
        {
          'id': 1186565532,
          'name': 'Hyungtae Lee'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        },
        {
          'id': 1429931860,
          'name': 'Eran Swears'
        },
        {
          'id': 1096537400,
          'name': 'Xiaoyang Wang'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        },
        {
          'id': 1492850917,
          'name': 'Kishore K. Reddy'
        },
        {
          'id': 1119028729,
          'name': 'Mubarak Shah'
        },
        {
          'id': 1117932635,
          'name': 'Carl Vondrick'
        },
        {
          'id': 1078481078,
          'name': 'Hamed Pirsiavash'
        },
        {
          'id': 1398431627,
          'name': 'Deva Ramanan'
        },
        {
          'id': 1481928859,
          'name': 'Jenny Yuen'
        },
        {
          'id': 1464617438,
          'name': 'Antonio Torralba'
        },
        {
          'id': 1330093859,
          'name': 'Bi Song'
        },
        {
          'id': 1069229258,
          'name': 'Anesco Fong'
        },
        {
          'id': 1434125005,
          'name': 'Amit K. Roy-Chowdhury'
        },
        {
          'id': 1044566623,
          'name': 'Mita Desai'
        }
      ],
      'field': [
        {
          'id': 2036641578,
          'name': 'CLIPS'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 297,
      'reference_count': 20
    },
    {
      'id': 63463219,
      'title': 'Learning image Vicept description via mixed-norm regularization for large scale semantic image search',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1266097533,
          'name': 'Liang Li'
        },
        {
          'id': 1266998431,
          'name': 'Shuqiang Jiang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 37
    },
    {
      'id': 69269385,
      'title': 'A robust method for vector field learning with application to mismatch removing',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1215234163,
          'name': 'Ji Zhao'
        },
        {
          'id': 1215444368,
          'name': 'Jiayi Ma'
        },
        {
          'id': 1326121431,
          'name': 'Jinwen Tian'
        },
        {
          'id': 1344214627,
          'name': 'Jie Ma'
        },
        {
          'id': 1211619738,
          'name': 'Dazhi Zhang'
        }
      ],
      'field': [
        {
          'id': 2002073950,
          'name': 'Expectation–maximization algorithm'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2046405230,
          'name': 'Reproducing kernel Hilbert space'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2031974094,
          'name': 'Vector field'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2023611578,
          'name': 'Maximum a posteriori estimation'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 19
    },
    {
      'id': 72849634,
      'title': 'Recovery of corrupted low-rank matrices via half-quadratic based nonconvex minimization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1020627459,
          'name': 'Ran He'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 38
    },
    {
      'id': 7486553,
      'title': 'Adaptive and discriminative metric differential tracking',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1487787553,
          'name': 'Nan Jiang'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2048464044,
          'name': 'Metric differential'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 21
    },
    {
      'id': 83770537,
      'title': 'Edgel index for large-scale sketch-based image search',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1164954371,
          'name': 'Yang Cao'
        },
        {
          'id': 1288576539,
          'name': 'Changhu Wang'
        },
        {
          'id': 1195526895,
          'name': 'Liqing Zhang'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2009050054,
          'name': 'Blossom algorithm'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2040637160,
          'name': 'Indexation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 152,
      'reference_count': 17
    },
    {
      'id': 96116127,
      'title': 'A non-convex relaxation approach to sparse dictionary learning',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1443702376,
          'name': 'Jianping Shi'
        },
        {
          'id': 1338922730,
          'name': 'Xiang Ren'
        },
        {
          'id': 1014338832,
          'name': 'Guang Dai'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1057547883,
          'name': 'Zhihua Zhang'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2045712947,
          'name': 'Online algorithm'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2023949645,
          'name': 'Adaptive learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2001087521,
          'name': 'Minimax'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2006645328,
          'name': 'Convexity'
        }
      ],
      'venue': {
        'id': '2130116924',
        'name': 'CVPR',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 22
    },
    {
      'id': 109731451,
      'title': 'Parallel graph-cuts by adaptive bottom-up merging',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1126420948,
          'name': 'Jiangyu Liu'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2006988398,
          'name': 'Concurrent computing'
        },
        {
          'id': 2049721966,
          'name': 'Synchronization'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032409045,
          'name': 'Disjoint sets'
        },
        {
          'id': 2004379144,
          'name': 'Top-down and bottom-up design'
        },
        {
          'id': 2002106245,
          'name': 'Distributed computing'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 25
    },
    {
      'id': 112935350,
      'title': 'Towards semantic embedding in visual vocabulary',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1082822706,
          'name': 'Xiaoshuai Sun'
        },
        {
          'id': 1404363237,
          'name': 'Bineng Zhong'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2007529156,
          'name': 'Hidden Markov random field'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028090408,
          'name': 'WordNet'
        },
        {
          'id': 2020762433,
          'name': 'Visual search'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029531426,
          'name': 'Bag-of-words model in computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 31
    },
    {
      'id': 119519531,
      'title': 'Efficient histogram-based sliding window',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1469536050,
          'name': 'Litian Tao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018145477,
          'name': 'Limiting factor'
        },
        {
          'id': 2037939214,
          'name': 'Adaptive histogram equalization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2045900009,
          'name': 'Image histogram'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2010145423,
          'name': 'Balanced histogram thresholding'
        },
        {
          'id': 2003825461,
          'name': 'Color normalization'
        },
        {
          'id': 2019434669,
          'name': 'Histogram matching'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 25
    },
    {
      'id': 140222057,
      'title': 'Adaptive generic learning for face recognition from a single sample per person',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1078611761,
          'name': 'Yu Su'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039086855,
          'name': 'Information processing'
        },
        {
          'id': 2016588810,
          'name': 'Scatter matrix'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 91,
      'reference_count': 34
    },
    {
      'id': 150553772,
      'title': 'RASL: Robust alignment by sparse and low-rank decomposition for linearly correlated images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1209135151,
          'name': 'Yigang Peng'
        },
        {
          'id': 1179346781,
          'name': 'Arvind Ganesh'
        },
        {
          'id': 1151630398,
          'name': 'John Wright'
        },
        {
          'id': 1117941031,
          'name': 'Wenli Xu'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033464047,
          'name': 'Low-rank approximation'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 235,
      'reference_count': 51
    },
    {
      'id': 160281380,
      'title': 'Learning weights for codebook in image classification and retrieval',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        }
      ],
      'author': [
        {
          'id': 1157554881,
          'name': 'Hongping Cai'
        },
        {
          'id': 1382990097,
          'name': 'Fei Yan'
        },
        {
          'id': 1103991961,
          'name': 'Krystian Mikolajczyk'
        }
      ],
      'field': [
        {
          'id': 2031848198,
          'name': 'U-matrix'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2029522347,
          'name': 'Codebook'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2013285993,
          'name': 'Linde–Buzo–Gray algorithm'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 17
    },
    {
      'id': 162591847,
      'title': 'Geometric properties of multiple reflections in catadioptric camera with two planar mirrors',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1233810335,
          'name': 'Xianghua Ying'
        },
        {
          'id': 1374411421,
          'name': 'Kun Peng'
        },
        {
          'id': 1101776908,
          'name': 'Ren Ren'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025519067,
          'name': 'Pinhole camera model'
        },
        {
          'id': 2009659264,
          'name': 'Catadioptric system'
        },
        {
          'id': 2008563812,
          'name': 'Lens (optics)'
        },
        {
          'id': 2020757927,
          'name': 'Pinhole camera'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2014824446,
          'name': 'Point reflection'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 30
    },
    {
      'id': 164869335,
      'title': 'Optimizing kd-trees for scalable visual descriptor indexing',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1011743156,
          'name': 'You Jia'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        },
        {
          'id': 1264446421,
          'name': 'Xian-Sheng Hua'
        }
      ],
      'field': [
        {
          'id': 2027345718,
          'name': 'Binary space partitioning'
        },
        {
          'id': 2037149918,
          'name': 'k-d tree'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028767645,
          'name': 'Space partitioning'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2040637160,
          'name': 'Indexation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 31
    },
    {
      'id': 165820312,
      'title': 'Self-calibrating photometric stereo',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1100550194,
          'name': 'Boxin Shi'
        },
        {
          'id': 1478878967,
          'name': 'Yasuyuki Matsushita'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1192418823,
          'name': 'Chao Xu'
        },
        {
          'id': 1056951125,
          'name': 'Ping Tan'
        }
      ],
      'field': [
        {
          'id': 2026022629,
          'name': 'Radiometry'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2036141665,
          'name': 'Photometric stereo'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029958136,
          'name': 'Photometry (optics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 67,
      'reference_count': 25
    },
    {
      'id': 173528375,
      'title': 'Spatial-bag-of-features',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1164954371,
          'name': 'Yang Cao'
        },
        {
          'id': 1288576539,
          'name': 'Changhu Wang'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1195526895,
          'name': 'Liqing Zhang'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2040637160,
          'name': 'Indexation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 167,
      'reference_count': 15
    },
    {
      'id': 207559107,
      'title': 'Convex shape decomposition',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        }
      ],
      'author': [
        {
          'id': 1467862135,
          'name': 'Hairong Liu'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1358872243,
          'name': 'Longin Jan Latecki'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2018476708,
          'name': 'Approximation theory'
        },
        {
          'id': 2029562301,
          'name': 'Integer programming'
        },
        {
          'id': 2000345757,
          'name': 'Decomposition method (constraint satisfaction)'
        },
        {
          'id': 2035705126,
          'name': 'Regular polygon'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2030578290,
          'name': 'Morse theory'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 67,
      'reference_count': 20
    },
    {
      'id': 209557854,
      'title': 'Bimodal gender recognition from face and fingerprint',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103578022,
          'abbr': 'UB',
          'name': 'University at Buffalo'
        }
      ],
      'author': [
        {
          'id': 1322630000,
          'name': 'Xiong Li'
        },
        {
          'id': 1116512226,
          'name': 'Xu Zhao'
        },
        {
          'id': 1239922094,
          'name': 'Yun Fu'
        },
        {
          'id': 1074973158,
          'name': 'Yuncai Liu'
        }
      ],
      'field': [
        {
          'id': 2008779518,
          'name': 'Bag-of-words model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2011877324,
          'name': 'Latent Dirichlet allocation'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2006950531,
          'name': 'Fingerprint'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016846535,
          'name': 'Fingerprint recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 31
    },
    {
      'id': 211241157,
      'title': 'Probabilistic models for supervised dictionary learning',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1042622385,
          'name': 'Xiao-Chen Lian'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1288576539,
          'name': 'Changhu Wang'
        },
        {
          'id': 1435529302,
          'name': 'Bao-Liang Lu'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2029531426,
          'name': 'Bag-of-words model in computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046908382,
          'name': 'k-means clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 23
    },
    {
      'id': 221612175,
      'title': 'AAM based face tracking with temporal matching and face segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1454860838,
          'name': 'Mingcai Zhou'
        },
        {
          'id': 1196361683,
          'name': 'Lin Liang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1091037930,
          'name': 'Yangsheng Wang'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045100285,
          'name': 'Facial motion capture'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 60,
      'reference_count': 22
    },
    {
      'id': 223765034,
      'title': 'Face recognition with learning-based descriptor',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1454179856,
          'name': 'Zhimin Cao'
        },
        {
          'id': 1053152779,
          'name': 'Qi Yin'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 310,
      'reference_count': 33
    },
    {
      'id': 224031363,
      'title': 'Nonparametric Label-to-Region by search',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102716279,
          'abbr': '',
          'name': 'Eastman Kodak Company'
        },
        {
          'id': 2103356563,
          'abbr': 'PANASONIC',
          'name': 'Panasonic'
        }
      ],
      'author': [
        {
          'id': 1467929556,
          'name': 'Xiaobai Liu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1277136953,
          'name': 'Zhongyang Huango'
        },
        {
          'id': 1092602214,
          'name': 'Hai Jin'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 27
    },
    {
      'id': 23102091,
      'title': 'Interest seam image',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103559873,
          'abbr': 'NOKIA',
          'name': 'Nokia'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1218755902,
          'name': 'Xiao Zhang'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        },
        {
          'id': 1191827435,
          'name': 'Heung-Yeung Shum'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2028160375,
          'name': 'Seam carving'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018606483,
          'name': 'Video content analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 20
    },
    {
      'id': 231660704,
      'title': 'Semantic context modeling with maximal margin Conditional Random Fields for automatic image annotation',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1314843626,
          'name': 'Yu Xiang'
        },
        {
          'id': 1495758995,
          'name': 'Xiangdong Zhou'
        },
        {
          'id': 1005079343,
          'name': 'Zuotao Liu'
        },
        {
          'id': 1334725215,
          'name': 'Tat-Seng Chua'
        },
        {
          'id': 1114237355,
          'name': 'Chong-Wah Ngo'
        }
      ],
      'field': [
        {
          'id': 2026978225,
          'name': 'Semantic computing'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2003425367,
          'name': 'Hinge loss'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 23
    },
    {
      'id': 250743012,
      'title': 'Online multiple instance learning with no regret',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1275848732,
          'name': 'Mu Li'
        },
        {
          'id': 1416261758,
          'name': 'James Tin-Yau Kwok'
        },
        {
          'id': 1435529302,
          'name': 'Bao-Liang Lu'
        }
      ],
      'field': [
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2045712947,
          'name': 'Online algorithm'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2014833089,
          'name': 'Regret'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 16
    },
    {
      'id': 254453056,
      'title': 'A swarm intelligence based searching strategy for articulated 3D human body tracking',
      'affiliation': [
        {
          'id': 2103743992,
          'abbr': 'WZU',
          'name': 'Wenzhou University'
        },
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2104876881,
          'abbr': 'BBK',
          'name': 'Birkbeck, University of London'
        }
      ],
      'author': [
        {
          'id': 1076470804,
          'name': 'Xiaoqin Zhang'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1366484158,
          'name': 'Xiangyang Wang'
        },
        {
          'id': 1486082966,
          'name': 'Yu Kong'
        },
        {
          'id': 1207591401,
          'name': 'Nianhua Xie'
        },
        {
          'id': 1401008304,
          'name': 'Hanzi Wang'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1242761004,
          'name': 'Stephen J. Maybank'
        }
      ],
      'field': [
        {
          'id': 2011621760,
          'name': 'Multi-swarm optimization'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027591417,
          'name': 'Swarm intelligence'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009229977,
          'name': 'Simulated annealing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042065377,
          'name': 'Particle swarm optimization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 21
    },
    {
      'id': 260443907,
      'title': 'A new texture descriptor using multifractal analysis in multi-orientation wavelet pyramid',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        },
        {
          'id': 1117641504,
          'name': 'Xiong Yang'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1186862432,
          'name': 'Hui Ji'
        }
      ],
      'field': [
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2041216465,
          'name': 'Texton'
        },
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2022371361,
          'name': 'Texture compression'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2013654683,
          'name': 'Texture filtering'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032570206,
          'name': 'Texture Descriptor'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 105,
      'reference_count': 28
    },
    {
      'id': 303692916,
      'title': 'Compact projection: Simple and efficient near neighbor search with practical memory requirements',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1047917228,
          'name': 'Kerui Min'
        },
        {
          'id': 1148222372,
          'name': 'Linjun Yang'
        },
        {
          'id': 1151630398,
          'name': 'John Wright'
        },
        {
          'id': 1341367658,
          'name': 'Lei Wu'
        },
        {
          'id': 1264446421,
          'name': 'Xian-Sheng Hua'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2003010881,
          'name': 'Memory management'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045022011,
          'name': 'Data structure'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040637160,
          'name': 'Indexation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2044583116,
          'name': 'Random projection'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 21
    },
    {
      'id': 322201521,
      'title': 'Ethnicity classification based on gait using multi-view fusion',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104904263,
          'abbr': 'UCR',
          'name': 'University of California, Riverside'
        }
      ],
      'author': [
        {
          'id': 1182860584,
          'name': 'De Zhang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1041144028,
          'name': 'Bir Bhanu'
        }
      ],
      'field': [
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010425717,
          'name': 'Soft biometrics'
        },
        {
          'id': 2013094599,
          'name': 'Gait'
        },
        {
          'id': 2009788292,
          'name': 'Multilinear principal component analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042918381,
          'name': 'Gait analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015804807,
          'name': 'Gait (human)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 21
    },
    {
      'id': 334052538,
      'title': 'Transductive segmentation of live video with non-stationary background',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        }
      ],
      'author': [
        {
          'id': 1396255688,
          'name': 'Fan Zhong'
        },
        {
          'id': 1093267435,
          'name': 'Xueying Qin'
        },
        {
          'id': 1302224891,
          'name': 'Qunsheng Peng'
        }
      ],
      'field': [
        {
          'id': 2014952153,
          'name': 'Transduction (machine learning)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019078694,
          'name': 'Local color'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2043986729,
          'name': 'Color model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 17
    },
    {
      'id': 337183796,
      'title': 'Efficient joint 2D and 3D palmprint matching with alignment refinement',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1241137423,
          'name': 'Wei Li'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        },
        {
          'id': 1014286814,
          'name': 'Guangming Lu'
        },
        {
          'id': 1207885002,
          'name': 'Jingqi Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 23
    },
    {
      'id': 346842061,
      'title': 'Line matching leveraged by point correspondences',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1163323626,
          'name': 'Fuchao Wu'
        },
        {
          'id': 1420413515,
          'name': 'Zhanyi Hu'
        }
      ],
      'field': [
        {
          'id': 2010083780,
          'name': 'Computational geometry'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2019362237,
          'name': 'Statistic'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 23
    },
    {
      'id': 347334502,
      'title': 'Removal of 3D facial expressions: A learning-based approach',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1402712917,
          'name': 'Gang Pan'
        },
        {
          'id': 1284034634,
          'name': 'Song Han'
        },
        {
          'id': 1043442721,
          'name': 'Zhaohui Wu'
        },
        {
          'id': 1434795179,
          'name': 'Yuting Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032364662,
          'name': 'Residue (complex analysis)'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2028618921,
          'name': 'Normal space'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041290141,
          'name': 'Poisson distribution'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 34
    },
    {
      'id': 374136090,
      'title': 'Visual tracking via incremental self-tuning particle filtering on the affine group',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1315198448,
          'name': 'Min Li'
        },
        {
          'id': 1038713465,
          'name': 'Wei Chen'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2031159560,
          'name': 'Affine group'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 23
    },
    {
      'id': 382570878,
      'title': 'Global and local isometry-invariant descriptor for 3D shape comparison and partial matching',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1165225772,
          'name': 'Huai-Yu Wu'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        },
        {
          'id': 1365240044,
          'name': 'Tao Luo'
        },
        {
          'id': 1094784348,
          'name': 'Xu-Lei Wang'
        },
        {
          'id': 1093126741,
          'name': 'Songde Ma'
        }
      ],
      'field': [
        {
          'id': 2048568588,
          'name': 'Heat kernel signature'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2031398059,
          'name': 'Isometry (Riemannian geometry)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040170460,
          'name': 'Harmonic analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019526347,
          'name': 'Feature data'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 30
    },
    {
      'id': 392764136,
      'title': 'An online approach: Learning-Semantic-Scene-by-Tracking and Tracking-by-Learning-Semantic-Scene',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        }
      ],
      'author': [
        {
          'id': 1124218438,
          'name': 'Xuan Song'
        },
        {
          'id': 1322338161,
          'name': 'Xiaowei Shao'
        },
        {
          'id': 1230185443,
          'name': 'Huijing Zhao'
        },
        {
          'id': 1347506819,
          'name': 'Jinshi Cui'
        },
        {
          'id': 1182426429,
          'name': 'Ryosuke Shibasaki'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2023949645,
          'name': 'Adaptive learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 32
    },
    {
      'id': 397642595,
      'title': 'The multiscale competitive code via sparse representation for palmprint verification',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1173131801,
          'name': 'Zhenhua Guo'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035842457,
          'name': 'Gaussian process'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2034325238,
          'name': 'Filter bank'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2043012749,
          'name': 'Robust statistics'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 32
    },
    {
      'id': 404384099,
      'title': 'Visual tracking via weakly supervised learning from multiple imperfect oracles',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1404363237,
          'name': 'Bineng Zhong'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1251189194,
          'name': 'Sheng Chen'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1481146811,
          'name': 'Xiaotong Yuan'
        },
        {
          'id': 1355802438,
          'name': 'Shaohui Liu'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 53
    },
    {
      'id': 410308932,
      'title': 'Novel observation model for probabilistic object tracking',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1218659253,
          'name': 'Dawei Liang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1253574523,
          'name': 'Hongxun Yao'
        },
        {
          'id': 1266998431,
          'name': 'Shuqiang Jiang'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2029148409,
          'name': 'Brute-force search'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045022011,
          'name': 'Data structure'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049895596,
          'name': 'Mean-shift'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 28
    },
    {
      'id': 41892700,
      'title': 'Cascaded L1-norm Minimization Learning (CLML) classifier for human detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1118056420,
          'name': 'Ran Xu'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2044580031,
          'name': 'VC dimension'
        },
        {
          'id': 2029562301,
          'name': 'Integer programming'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2043932775,
          'name': 'Penalty method'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 26
    },
    {
      'id': 421289975,
      'title': 'Modeling pixel process with scale invariant local patterns for background subtraction in complex scenes',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1259121145,
          'name': 'Guoying Zhao'
        },
        {
          'id': 1334230966,
          'name': 'Vili Kellokumpu'
        },
        {
          'id': 1390027563,
          'name': 'Matti Pietikäinen'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046856441,
          'name': 'Complex dynamics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2025715469,
          'name': 'Background subtraction'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 227,
      'reference_count': 11
    },
    {
      'id': 447266279,
      'title': 'Trajectory matching from unsynchronized videos',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2009050054,
          'name': 'Blossom algorithm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 29
    },
    {
      'id': 45355162,
      'title': 'Sparse representation using nonnegative curds and whey',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1007302207,
          'name': 'Yanan Liu'
        },
        {
          'id': 1374018797,
          'name': 'Fei Wu'
        },
        {
          'id': 1057547883,
          'name': 'Zhihua Zhang'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2005106420,
          'name': 'Caltech 101'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2046713073,
          'name': 'Non-negative matrix factorization'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 58,
      'reference_count': 21
    },
    {
      'id': 466247004,
      'title': 'Content-aware image resizing by quadratic programming',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101386547,
          'abbr': 'HP',
          'name': 'Hewlett-Packard'
        },
        {
          'id': 2104935498,
          'abbr': 'TECHNION',
          'name': 'Technion – Israel Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1248415123,
          'name': 'Renjie Chen'
        },
        {
          'id': 1019796855,
          'name': 'Daniel Freedman'
        },
        {
          'id': 1128833144,
          'name': 'Zachi Karni'
        },
        {
          'id': 1462511577,
          'name': 'Craig Gotsman'
        },
        {
          'id': 1297670722,
          'name': 'Ligang Liu'
        }
      ],
      'field': [
        {
          'id': 2041984668,
          'name': 'Line (geometry)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2047155301,
          'name': 'Magnification'
        },
        {
          'id': 2013839451,
          'name': 'Quadratic programming'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2028908245,
          'name': 'Resizing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 16
    },
    {
      'id': 469340279,
      'title': 'Direction field diffusion on cortical surface via graph cuts',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2100530516,
          'abbr': 'UGA',
          'name': 'University of Georgia'
        }
      ],
      'author': [
        {
          'id': 1105436384,
          'name': 'Gang Li'
        },
        {
          'id': 1218734856,
          'name': 'Lei Guo'
        },
        {
          'id': 1345723757,
          'name': 'Jingxin Nie'
        },
        {
          'id': 1324698963,
          'name': 'Kaiming Li'
        },
        {
          'id': 1005003126,
          'name': 'Tianming Liu'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2015875560,
          'name': 'Geometry'
        },
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2008044200,
          'name': 'Mathematical analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037115418,
          'name': 'Discontinuity (linguistics)'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049063287,
          'name': 'Slope field'
        },
        {
          'id': 2044935479,
          'name': 'Minimisation (psychology)'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 11
    },
    {
      'id': 486657086,
      'title': 'Rectilinear parsing of architecture in urban environment',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1357522517,
          'name': 'Peng Zhao'
        },
        {
          'id': 1053642085,
          'name': 'Tian Fang'
        },
        {
          'id': 1145939657,
          'name': 'Jiangxiong Xiao'
        },
        {
          'id': 1036716765,
          'name': 'Honghui Zhang'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        },
        {
          'id': 1481958492,
          'name': 'Long Quan'
        }
      ],
      'field': [
        {
          'id': 2010083780,
          'name': 'Computational geometry'
        },
        {
          'id': 2040902695,
          'name': 'Horizontal plane'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2010880536,
          'name': 'Roof'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017951869,
          'name': 'Vertical plane'
        },
        {
          'id': 2030474993,
          'name': 'Partition problem'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2046608995,
          'name': 'Facade'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 23
    },
    {
      'id': 50115771,
      'title': 'Context-constrained hallucination for image super-resolution',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1160376294,
          'name': 'Jiejie Zhu'
        },
        {
          'id': 1346009511,
          'name': 'Marshall F. Tappen'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025641305,
          'name': 'Hallucinated'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 80,
      'reference_count': 34
    },
    {
      'id': 64235062,
      'title': 'Abrupt motion tracking via adaptive stochastic approximation Monte Carlo sampling',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1415019661,
          'name': 'Xiuzhuang Zhou'
        },
        {
          'id': 1307253133,
          'name': 'Yao Lu'
        }
      ],
      'field': [
        {
          'id': 2018476708,
          'name': 'Approximation theory'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023666742,
          'name': 'Markov chain Monte Carlo'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2018721129,
          'name': 'Monte Carlo method'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2005202005,
          'name': 'Match moving'
        },
        {
          'id': 2001358914,
          'name': 'Stochastic approximation'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 21
    },
    {
      'id': 71697277,
      'title': 'Scalable face image retrieval with identity-based quantization and multi-reference re-ranking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1247406613,
          'name': 'Zhong Wu'
        },
        {
          'id': 1114225073,
          'name': 'Qifa Ke'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1191827435,
          'name': 'Heung-Yeung Shum'
        }
      ],
      'field': [
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008779518,
          'name': 'Bag-of-words model'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2039166637,
          'name': 'Inverted index'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 41,
      'reference_count': 39
    },
    {
      'id': 75000851,
      'title': 'Content-aware Ranking for visual search',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1236750869,
          'name': 'Bo Geng'
        },
        {
          'id': 1148222372,
          'name': 'Linjun Yang'
        },
        {
          'id': 1192418823,
          'name': 'Chao Xu'
        },
        {
          'id': 1264446421,
          'name': 'Xian-Sheng Hua'
        }
      ],
      'field': [
        {
          'id': 2020762433,
          'name': 'Visual search'
        },
        {
          'id': 2039299906,
          'name': 'Ranking (information retrieval)'
        },
        {
          'id': 2009047093,
          'name': 'Ranking SVM'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2013924117,
          'name': 'TRECVID'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 22
    },
    {
      'id': 85302222,
      'title': 'Geo-location estimation from two shadow trajectories',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1116567453,
          'name': 'Lin Wu'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002646815,
          'name': 'Geolocation'
        },
        {
          'id': 2001945532,
          'name': 'Longitude'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2005547078,
          'name': 'Camera resectioning'
        },
        {
          'id': 2042099302,
          'name': 'Geographic information system'
        },
        {
          'id': 2030175140,
          'name': 'Latitude'
        },
        {
          'id': 2042617794,
          'name': 'Analemmatic sundial'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        }
      ],
      'venue': {
        'id': '2131358366',
        'name': 'CVPR',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 18
    },
    {
      'id': 102848049,
      'title': 'Asymmetric Non-Local Neural Networks for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1392321334,
          'name': 'Zhen Zhu'
        },
        {
          'id': 1194860147,
          'name': 'Mengde Xu'
        },
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1229064520,
          'name': 'Tengteng Huang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zhu_Asymmetric_Non-Local_Neural_Networks_for_Semantic_Segmentation_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 110933443,
      'title': 'Point-Based Multi-View Stereo Network',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1352142742,
          'name': 'Rui Chen'
        },
        {
          'id': 1403103653,
          'name': 'Songfang Han'
        },
        {
          'id': 1283146361,
          'name': 'Jing Xu'
        },
        {
          'id': 1116347973,
          'name': 'Hao Su'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Chen_Point-Based_Multi-View_Stereo_Network_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 114816747,
      'title': 'Context-Aware Feature and Label Fusion for Facial Action Unit Intensity Estimation With Partially Labeled Data',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1073183237,
          'name': 'Yong Zhang'
        },
        {
          'id': 1000877857,
          'name': 'Haiyong Jiang'
        },
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1109440690,
          'name': 'Yanbo Fan'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Zhang_Context-Aware_Feature_and_Label_Fusion_for_Facial_Action_Unit_Intensity_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 118201879,
      'title': 'GCNet: Non-Local Networks Meet Squeeze-Excitation Networks and Beyond',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1184211769,
          'name': 'Yue Cao'
        },
        {
          'id': 1240086135,
          'name': 'Jiarui Xu'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        },
        {
          'id': 1359590642,
          'name': 'Fangyun Wei'
        },
        {
          'id': 1257943601,
          'name': 'Han Hu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027755292,
          'name': 'Excitation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019668106,
          'name': 'Electronic engineering'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCVW_2019/html/NeurArch/Cao_GCNet_Non-Local_Networks_Meet_Squeeze-Excitation_Networks_and_Beyond_ICCVW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 122711536,
      'title': 'Automatic and Robust Skull Registration Based on Discrete Uniformization',
      'affiliation': [
        {
          'id': 2103998608,
          'abbr': 'SBU',
          'name': 'Stony Brook University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1024152145,
          'name': 'Junli Zhao'
        },
        {
          'id': 1051714343,
          'name': 'Xin Qi'
        },
        {
          'id': 1493816224,
          'name': 'Chengfeng Wen'
        },
        {
          'id': 1010473345,
          'name': 'Na Lei'
        },
        {
          'id': 1023103330,
          'name': 'Xianfeng Gu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031757641,
          'name': 'Skull'
        },
        {
          'id': 2039836522,
          'name': 'Uniformization (set theory)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Zhao_Automatic_and_Robust_Skull_Registration_Based_on_Discrete_Uniformization_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 142816730,
      'title': 'Pixel2Mesh++: Multi-View 3D Mesh Generation via Deformation',
      'affiliation': [
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1351636193,
          'name': 'Chao Wen'
        },
        {
          'id': 1436560353,
          'name': 'Yinda Zhang'
        },
        {
          'id': 1476012931,
          'name': 'Zhuwen Li'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        }
      ],
      'field': [
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Wen_Pixel2Mesh_Multi-View_3D_Mesh_Generation_via_Deformation_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 15676025,
      'title': 'A System-Level Solution for Low-Power Object Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103850856,
          'abbr': 'DEAKIN',
          'name': 'Deakin University'
        }
      ],
      'author': [
        {
          'id': 1013630828,
          'name': 'Fanrong Li'
        },
        {
          'id': 1034909840,
          'name': 'Zitao Mo'
        },
        {
          'id': 1261785545,
          'name': 'Peisong Wang'
        },
        {
          'id': 1123086292,
          'name': 'Zejian Liu'
        },
        {
          'id': 1283286130,
          'name': 'Jiayun Zhang'
        },
        {
          'id': 1181424035,
          'name': 'Gang Li'
        },
        {
          'id': 1449587090,
          'name': 'Qinghao Hu'
        },
        {
          'id': 1122557775,
          'name': 'Xiangyu He'
        },
        {
          'id': 1238585749,
          'name': 'Cong Leng'
        },
        {
          'id': 1001424993,
          'name': 'Yang Zhang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCVW_2019/html/LPCV/Li_A_System-Level_Solution_for_Low-Power_Object_Detection_ICCVW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 158944871,
      'title': 'Domain Randomization and Pyramid Consistency: Simulation-to-Real Generalization without Accessing Target Domain Data.',
      'affiliation': [
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1049831114,
          'name': 'Xiangyu Yue'
        },
        {
          'id': 1434384413,
          'name': 'Yang Zhang'
        },
        {
          'id': 1156625101,
          'name': 'Sicheng Zhao'
        },
        {
          'id': 1248079013,
          'name': 'Alberto Sangiovanni Vincentelli'
        },
        {
          'id': 1021865461,
          'name': 'Kurt Keutzer'
        },
        {
          'id': 1478660115,
          'name': 'Boqing Gong'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024022689,
          'name': 'Randomization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Yue_Domain_Randomization_and_Pyramid_Consistency_Simulation-to-Real_Generalization_Without_Accessing_Target_ICCV_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 159613584,
      'title': 'Cascaded Parallel Filtering for Memory-Efficient Image-Based Localization',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1316730756,
          'name': 'Wentao Cheng'
        },
        {
          'id': 1364274232,
          'name': 'Weisi Lin'
        },
        {
          'id': 1252720774,
          'name': 'Kan Chen'
        },
        {
          'id': 1112786959,
          'name': 'Xinfeng Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Cheng_Cascaded_Parallel_Filtering_for_Memory-Efficient_Image-Based_Localization_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 174893916,
      'title': 'Mixed High-Order Attention Network for Person Re-Identification',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1261227821,
          'name': 'Binghui Chen'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1481827642,
          'name': 'Jiani Hu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Chen_Mixed_High-Order_Attention_Network_for_Person_Re-Identification_ICCV_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 185771083,
      'title': 'Learning Robust Facial Landmark Detection via Hierarchical Structured Ensemble',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1035756860,
          'name': 'Xu Zou'
        },
        {
          'id': 1101071010,
          'name': 'Sheng Zhong'
        },
        {
          'id': 1417540853,
          'name': 'Luxin Yan'
        },
        {
          'id': 1383182228,
          'name': 'Xiangyun Zhao'
        },
        {
          'id': 1168445268,
          'name': 'Jiahuan Zhou'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zou_Learning_Robust_Facial_Landmark_Detection_via_Hierarchical_Structured_Ensemble_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 18758829,
      'title': 'Learning Rich Features at High-Speed for Single-Shot Object Detection',
      'affiliation': [
        {
          'id': 2102407013,
          'abbr': 'AALTO',
          'name': 'Aalto University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        }
      ],
      'author': [
        {
          'id': 1091381200,
          'name': 'Tiancai Wang'
        },
        {
          'id': 1308203576,
          'name': 'Rao Muhammad Anwer'
        },
        {
          'id': 1318942605,
          'name': 'Hisham Cholakkal'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1381098672,
          'name': 'Yanwei Pang'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Wang_Learning_Rich_Features_at_High-Speed_for_Single-Shot_Object_Detection_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 19022647,
      'title': 'Learning Semantic-Specific Graph Representation for Multi-Label Image Recognition.',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102830209,
          'abbr': 'GDUFS',
          'name': 'Guangdong University of Foreign Studies'
        }
      ],
      'author': [
        {
          'id': 1298778203,
          'name': 'Tianshui Chen'
        },
        {
          'id': 1356890517,
          'name': 'Muxin Xu'
        },
        {
          'id': 1251085503,
          'name': 'Xiaolu Hui'
        },
        {
          'id': 1410171434,
          'name': 'Hefeng Wu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2040504686,
          'name': 'Graph (abstract data type)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Chen_Learning_Semantic-Specific_Graph_Representation_for_Multi-Label_Image_Recognition_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 20108207,
      'title': 'Pose-Guided Feature Alignment for Occluded Person Re-Identification',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101117222,
          'abbr': 'SCU',
          'name': 'Sichuan University'
        }
      ],
      'author': [
        {
          'id': 1382829097,
          'name': 'Jiaxu Miao'
        },
        {
          'id': 1403182937,
          'name': 'Yu Wu'
        },
        {
          'id': 1066319611,
          'name': 'Ping Liu'
        },
        {
          'id': 1101232855,
          'name': 'Yuhang Ding'
        },
        {
          'id': 1328181081,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Miao_Pose-Guided_Feature_Alignment_for_Occluded_Person_Re-Identification_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 2075206,
      'title': 'DeepICP: An End-to-End Deep Neural Network for 3D Point Cloud Registration.',
      'affiliation': [
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1426763388,
          'name': 'Weixin Lu'
        },
        {
          'id': 1080309975,
          'name': 'Guowei Wan'
        },
        {
          'id': 1064776743,
          'name': 'Yao Zhou'
        },
        {
          'id': 1050977109,
          'name': 'Xiangyu Fu'
        },
        {
          'id': 1479444284,
          'name': 'Pengfei Yuan'
        },
        {
          'id': 1346388744,
          'name': 'Shiyu Song'
        }
      ],
      'field': [
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Lu_DeepVCP_An_End-to-End_Deep_Neural_Network_for_Point_Cloud_Registration_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 2217952,
      'title': 'ACNet: Strengthening the Kernel Skeletons for Powerful CNN via Asymmetric Convolution Blocks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103901438,
          'abbr': '',
          'name': 'University of Warwick'
        }
      ],
      'author': [
        {
          'id': 1180593596,
          'name': 'Xiaohan Ding'
        },
        {
          'id': 1383996063,
          'name': 'Yuchen Guo'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1132375651,
          'name': 'Jungong Han'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Ding_ACNet_Strengthening_the_Kernel_Skeletons_for_Powerful_CNN_via_Asymmetric_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 223449983,
      'title': 'Integral Object Mining via Online Attention Accumulation',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1300528793,
          'name': 'Peng-Tao Jiang'
        },
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1478948706,
          'name': 'Yang Cao'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1305989134,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1150541753,
          'name': 'Hong-Kai Xiong'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Jiang_Integral_Object_Mining_via_Online_Attention_Accumulation_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 232896271,
      'title': 'SSAP: Single-Shot Instance Segmentation With Affinity Pyramid',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        }
      ],
      'author': [
        {
          'id': 1250727255,
          'name': 'Naiyu Gao'
        },
        {
          'id': 1128206675,
          'name': 'Yanhu Shan'
        },
        {
          'id': 1038495456,
          'name': 'Yupei Wang'
        },
        {
          'id': 1209252863,
          'name': 'Xin Zhao'
        },
        {
          'id': 1225242930,
          'name': 'Yinan Yu'
        },
        {
          'id': 1489506052,
          'name': 'Ming Yang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Gao_SSAP_Single-Shot_Instance_Segmentation_With_Affinity_Pyramid_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 240501094,
      'title': 'An Efficient Solution to the Homography-Based Relative Pose Problem With a Common Reference Direction',
      'affiliation': [
        {
          'id': 2103831083,
          'abbr': 'UQ',
          'name': 'University of Queensland'
        },
        {
          'id': 2104839199,
          'abbr': 'PSL',
          'name': 'PSL Research University'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1118988549,
          'name': 'Yaqing Ding'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        },
        {
          'id': 1360619020,
          'name': 'Jean Ponce'
        },
        {
          'id': 1023008501,
          'name': 'Hui Kong'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006018171,
          'name': 'Homography'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Ding_An_Efficient_Solution_to_the_Homography-Based_Relative_Pose_Problem_With_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 255798820,
      'title': 'Semi-Supervised Skin Detection by Network With Mutual Guidance',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103783453,
          'abbr': 'UMASS',
          'name': 'University of Massachusetts Amherst'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1028316131,
          'name': 'Yi He'
        },
        {
          'id': 1018885034,
          'name': 'Jiayuan Shi'
        },
        {
          'id': 1109263313,
          'name': 'Chuan Wang'
        },
        {
          'id': 1068168862,
          'name': 'Haibin Huang'
        },
        {
          'id': 1067010091,
          'name': 'Jiaming Liu'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1451131392,
          'name': 'Risheng Liu'
        },
        {
          'id': 1038841241,
          'name': 'Jue Wang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/He_Semi-Supervised_Skin_Detection_by_Network_With_Mutual_Guidance_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 259297163,
      'title': 'Searching for Accurate Binary Neural Architectures',
      'affiliation': [
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1497371499,
          'name': 'Mingzhu Shen'
        },
        {
          'id': 1270655683,
          'name': 'Kai Han'
        },
        {
          'id': 1293716913,
          'name': 'Chunjing Xu'
        },
        {
          'id': 1088346340,
          'name': 'Yunhe Wang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCVW_2019/papers/NeurArch/Shen_Searching_for_Accurate_Binary_Neural_Architectures_ICCVW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 271519349,
      'title': 'GSLAM: A General SLAM Framework and Benchmark.',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1011140917,
          'name': 'Yong Zhao'
        },
        {
          'id': 1072846561,
          'name': 'Shibiao Xu'
        },
        {
          'id': 1255865179,
          'name': 'Shuhui Bu'
        },
        {
          'id': 1038057485,
          'name': 'Hongkai Jiang'
        },
        {
          'id': 1489499804,
          'name': 'Pengcheng Han'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zhao_GSLAM_A_General_SLAM_Framework_and_Benchmark_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 272243421,
      'title': 'STM: SpatioTemporal and Motion Encoding for Action Recognition',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1418871098,
          'name': 'Boyuan Jiang'
        },
        {
          'id': 1027747091,
          'name': 'Mengmeng Wang'
        },
        {
          'id': 1253469388,
          'name': 'Weihao Gan'
        },
        {
          'id': 1004795249,
          'name': 'Wei Wu'
        },
        {
          'id': 1126332756,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Jiang_STM_SpatioTemporal_and_Motion_Encoding_for_Action_Recognition_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 274163172,
      'title': 'On-Device Image Classification with Proxyless Neural Architecture Search and Quantization-Aware Fine-Tuning',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1062036183,
          'name': 'Han Cai'
        },
        {
          'id': 1307264507,
          'name': 'Tianzhe Wang'
        },
        {
          'id': 1458588735,
          'name': 'Zhanghao Wu'
        },
        {
          'id': 1028386574,
          'name': 'Kuan Wang'
        },
        {
          'id': 1260768795,
          'name': 'Ji Lin'
        },
        {
          'id': 1049517621,
          'name': 'Song Han'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2026464504,
          'name': 'Fine-tuning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCVW_2019/papers/LPCV/Cai_On-Device_Image_Classification_with_Proxyless_Neural_Architecture_Search_and_Quantization-Aware_ICCVW_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 291948019,
      'title': 'A geometry-inspired decision-based attack',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        }
      ],
      'author': [
        {
          'id': 1246813939,
          'name': 'Yujia Liu'
        },
        {
          'id': 1084374479,
          'name': 'Seyed Mohsen Moosavi Dezfooli'
        },
        {
          'id': 1104399476,
          'name': 'Pascal Frossard'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'https://infoscience.epfl.ch/record/264954',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 30170064,
      'title': 'Anchor Diffusion for Unsupervised Video Object Segmentation',
      'affiliation': [
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1053524493,
          'name': 'Zhao Yang'
        },
        {
          'id': 1008814300,
          'name': 'Qiang Wang'
        },
        {
          'id': 1200036889,
          'name': 'Luca Bertinetto'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1325469744,
          'name': 'Song Bai'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        }
      ],
      'field': [
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Yang_Anchor_Diffusion_for_Unsupervised_Video_Object_Segmentation_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 305247730,
      'title': 'Domain Adaptation for Semantic Segmentation With Maximum Squares Loss',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1470663267,
          'name': 'Minghao Chen'
        },
        {
          'id': 1184831032,
          'name': 'Hongyang Xue'
        },
        {
          'id': 1444957931,
          'name': 'Deng Cai'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Chen_Domain_Adaptation_for_Semantic_Segmentation_With_Maximum_Squares_Loss_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 305855413,
      'title': 'A2J: Anchor-to-Joint Regression Network for 3D Articulated Pose Estimation from a Single Depth Image.',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100361274,
          'abbr': 'A*STAR',
          'name': 'Agency for Science, Technology and Research'
        },
        {
          'id': 2103578022,
          'abbr': 'UB',
          'name': 'University at Buffalo'
        }
      ],
      'author': [
        {
          'id': 1069424408,
          'name': 'Fu Xiong'
        },
        {
          'id': 1417000301,
          'name': 'Boshen Zhang'
        },
        {
          'id': 1345972902,
          'name': 'Yang Xiao'
        },
        {
          'id': 1216320010,
          'name': 'Zhiguo Cao'
        },
        {
          'id': 1097727720,
          'name': 'Taidong Yu'
        },
        {
          'id': 1442971782,
          'name': 'Joey Tianyi Zhou'
        },
        {
          'id': 1262920571,
          'name': 'Junsong Yuan'
        }
      ],
      'field': [
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Xiong_A2J_Anchor-to-Joint_Regression_Network_for_3D_Articulated_Pose_Estimation_From_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 30997604,
      'title': 'Crowd Counting With Deep Structured Scale Integration Network',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1061578980,
          'name': 'Lingbo Liu'
        },
        {
          'id': 1172299682,
          'name': 'Zhilin Qiu'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1155837536,
          'name': 'Shufan Liu'
        },
        {
          'id': 1255633702,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Liu_Crowd_Counting_With_Deep_Structured_Scale_Integration_Network_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 317973158,
      'title': 'Racial Faces in the Wild: Reducing Racial Bias by Information Maximization Adaptation Network',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1394920256,
          'name': 'Mei Wang'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1481827642,
          'name': 'Jiani Hu'
        },
        {
          'id': 1057619188,
          'name': 'Xunqiang Tao'
        },
        {
          'id': 1122453510,
          'name': 'Yaohai Huang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011384812,
          'name': 'Maximization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Wang_Racial_Faces_in_the_Wild_Reducing_Racial_Bias_by_Information_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 329774127,
      'title': 'StructureFlow: Image Inpainting via Structure-Aware Appearance Flow',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1263900715,
          'name': 'Yurui Ren'
        },
        {
          'id': 1300904342,
          'name': 'Xiaoming Yu'
        },
        {
          'id': 1153410985,
          'name': 'Ruonan Zhang'
        },
        {
          'id': 1039031537,
          'name': 'Thomas H. Li'
        },
        {
          'id': 1439974622,
          'name': 'Shan Liu'
        },
        {
          'id': 1444701965,
          'name': 'Ge Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Ren_StructureFlow_Image_Inpainting_via_Structure-Aware_Appearance_Flow_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 334777506,
      'title': 'Exploring Overall Contextual Information for Image Captioning in Human-Like Cognitive Style',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104829265,
          'abbr': '',
          'name': 'McGill University'
        }
      ],
      'author': [
        {
          'id': 1254089798,
          'name': 'Hongwei Ge'
        },
        {
          'id': 1351421922,
          'name': 'Zehang Yan'
        },
        {
          'id': 1080113651,
          'name': 'Kai Zhang'
        },
        {
          'id': 1082518203,
          'name': 'Mingde Zhao'
        },
        {
          'id': 1123670310,
          'name': 'Liang Sun'
        }
      ],
      'field': [
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015578889,
          'name': 'Cognitive style'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Ge_Exploring_Overall_Contextual_Information_for_Image_Captioning_in_Human-Like_Cognitive_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 338214004,
      'title': 'Larger Norm More Transferable: An Adaptive Feature Norm Approach for Unsupervised Domain Adaptation',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1173893498,
          'name': 'Ruijia Xu'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1266351474,
          'name': 'Jihan Yang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2014690638,
          'name': 'Norm (social)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Xu_Larger_Norm_More_Transferable_An_Adaptive_Feature_Norm_Approach_for_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 341259337,
      'title': 'Variational Few-Shot Learning',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1047653608,
          'name': 'Jian Zhang'
        },
        {
          'id': 1380400068,
          'name': 'Chenglong Zhao'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1423582006,
          'name': 'Minghao Xu'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Zhang_Variational_Few-Shot_Learning_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 352427607,
      'title': 'Progressive Differentiable Architecture Search: Bridging the Depth Gap Between Search and Evaluation',
      'affiliation': [
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1004205019,
          'name': 'Xin Chen'
        },
        {
          'id': 1183688778,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1429895415,
          'name': 'Jun Wu'
        },
        {
          'id': 1275256058,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000350581,
          'name': 'Bridging (networking)'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Chen_Progressive_Differentiable_Architecture_Search_Bridging_the_Depth_Gap_Between_Search_ICCV_2019_paper.html',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 358582158,
      'title': 'DF2Net: A Dense-Fine-Finer Network for Detailed 3D Face Reconstruction',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1189233835,
          'name': 'Xiaoxing Zeng'
        },
        {
          'id': 1423566696,
          'name': 'Xiaojiang Peng'
        },
        {
          'id': 1097486061,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zeng_DF2Net_A_Dense-Fine-Finer_Network_for_Detailed_3D_Face_Reconstruction_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 382352973,
      'title': 'SSF-DAN: Separated Semantic Feature Based Domain Adaptation Network for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1100236090,
          'name': 'Liang Du'
        },
        {
          'id': 1185822592,
          'name': 'Jingang Tan'
        },
        {
          'id': 1323987552,
          'name': 'Hongye Yang'
        },
        {
          'id': 1027573542,
          'name': 'Jianfeng Feng'
        },
        {
          'id': 1071618334,
          'name': 'Xiangyang Xue'
        },
        {
          'id': 1076369514,
          'name': 'Qibao Zheng'
        },
        {
          'id': 1133546340,
          'name': 'Xiaoqing Ye'
        },
        {
          'id': 1122541998,
          'name': 'Xiaolin Zhang'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026794874,
          'name': 'Semantic feature'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Du_SSF-DAN_Separated_Semantic_Feature_Based_Domain_Adaptation_Network_for_Semantic_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 38600795,
      'title': 'Knowledge Distillation via Route Constrained Optimization',
      'affiliation': [
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1460591163,
          'name': 'Xiao Jin'
        },
        {
          'id': 1350906229,
          'name': 'Baoyun Peng'
        },
        {
          'id': 1395344911,
          'name': 'Yichao Wu'
        },
        {
          'id': 1260288408,
          'name': 'Yu Liu'
        },
        {
          'id': 1062711000,
          'name': 'Jiaheng Liu'
        },
        {
          'id': 1038374316,
          'name': 'Ding Liang'
        },
        {
          'id': 1465824659,
          'name': 'Junjie Yan'
        },
        {
          'id': 1326828304,
          'name': 'Xiaolin Hu'
        }
      ],
      'field': [
        {
          'id': 2011992298,
          'name': 'Control engineering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038091395,
          'name': 'Constrained optimization'
        },
        {
          'id': 2010918187,
          'name': 'Distillation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Jin_Knowledge_Distillation_via_Route_Constrained_Optimization_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 401349348,
      'title': 'Multinomial Distribution Learning for Effective Neural Architecture Search',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1268885319,
          'name': 'Xiawu Zheng'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1205702503,
          'name': 'Lang Tang'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1381503284,
          'name': 'Jianzhuang Liu'
        },
        {
          'id': 1238691319,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006804888,
          'name': 'Multinomial distribution'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zheng_Multinomial_Distribution_Learning_for_Effective_Neural_Architecture_Search_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 408980075,
      'title': 'Vehicle Re-identification in Aerial Imagery: Dataset and Approach.',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1102511779,
          'name': 'Peng Wang'
        },
        {
          'id': 1442329421,
          'name': 'Bingliang Jiao'
        },
        {
          'id': 1000197848,
          'name': 'Lu Yang'
        },
        {
          'id': 1186287111,
          'name': 'Yifei Yang'
        },
        {
          'id': 1094710326,
          'name': 'Shizhou Zhang'
        },
        {
          'id': 1444713601,
          'name': 'Wei Wei'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Wang_Vehicle_Re-Identification_in_Aerial_Imagery_Dataset_and_Approach_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 411137899,
      'title': 'InstaBoost: Boosting Instance Segmentation via Probability Map Guided Copy-Pasting',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1268667380,
          'name': 'Hao-Shu Fang'
        },
        {
          'id': 1007061487,
          'name': 'Jianhua Sun'
        },
        {
          'id': 1363624033,
          'name': 'Runzhong Wang'
        },
        {
          'id': 1232701412,
          'name': 'Minghao Gou'
        },
        {
          'id': 1489183023,
          'name': 'Yonglu Li'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Fang_InstaBoost_Boosting_Instance_Segmentation_via_Probability_Map_Guided_Copy-Pasting_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 424350429,
      'title': 'DUP-Net: Denoiser and Upsampler Network for 3D Adversarial Point Clouds Defense',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1112935648,
          'name': 'Hang Zhou'
        },
        {
          'id': 1451860806,
          'name': 'Kejiang Chen'
        },
        {
          'id': 1109145351,
          'name': 'Weiming Zhang'
        },
        {
          'id': 1424878841,
          'name': 'Han Fang'
        },
        {
          'id': 1009644359,
          'name': 'Wenbo Zhou'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        }
      ],
      'field': [
        {
          'id': 2033757286,
          'name': 'dup'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zhou_DUP-Net_Denoiser_and_Upsampler_Network_for_3D_Adversarial_Point_Clouds_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 434413839,
      'title': 'Searching for MobileNetV3.',
      'affiliation': [
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100828562,
          'abbr': 'UC',
          'name': 'University of Cincinnati'
        }
      ],
      'author': [
        {
          'id': 1044328273,
          'name': 'Andrew Howard'
        },
        {
          'id': 1355386378,
          'name': 'Mark Sandler'
        },
        {
          'id': 1420006857,
          'name': 'Grace Chu'
        },
        {
          'id': 1161393822,
          'name': 'Liang-Chieh Chen'
        },
        {
          'id': 1115960749,
          'name': 'Bo Chen'
        },
        {
          'id': 1163002620,
          'name': 'Mingxing Tan'
        },
        {
          'id': 1169819393,
          'name': 'Weijun Wang'
        },
        {
          'id': 1314417696,
          'name': 'Yukun Zhu'
        },
        {
          'id': 1496001476,
          'name': 'Ruoming Pang'
        },
        {
          'id': 1041673884,
          'name': 'Vijay K. Vasudevan'
        },
        {
          'id': 1415089226,
          'name': 'Quoc V. Le'
        },
        {
          'id': 1407533177,
          'name': 'Hartwig Adam'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Howard_Searching_for_MobileNetV3_ICCV_2019_paper.pdf',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 436824871,
      'title': 'Face-to-Parameter Translation for Game Character Auto-Creation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1121642356,
          'name': 'Tianyang Shi'
        },
        {
          'id': 1010878240,
          'name': 'Yi Yuan'
        },
        {
          'id': 1242264254,
          'name': 'Changjie Fan'
        },
        {
          'id': 1203827968,
          'name': 'Zhengxia Zou'
        },
        {
          'id': 1459406952,
          'name': 'Zhenwei Shi'
        },
        {
          'id': 1444675629,
          'name': 'Yong Liu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Shi_Face-to-Parameter_Translation_for_Game_Character_Auto-Creation_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 439188467,
      'title': 'Employing Deep Composition-Object Relationships for Salient Object Detection',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2104488735,
          'abbr': 'LANCASTER',
          'name': 'Lancaster University'
        }
      ],
      'author': [
        {
          'id': 1002677317,
          'name': 'Yi Liu'
        },
        {
          'id': 1011838904,
          'name': 'Qiang Zhang'
        },
        {
          'id': 1007658933,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1153306339,
          'name': 'Jungong Han'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Liu_Employing_Deep_Part-Object_Relationships_for_Salient_Object_Detection_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 444317423,
      'title': 'Exploiting Temporal Consistency for Real-Time Video Depth Estimation',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1404616599,
          'name': 'Haokui Zhang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1056094667,
          'name': 'Ying Li'
        },
        {
          'id': 1440533854,
          'name': 'Yuanzhouhan Cao'
        },
        {
          'id': 1000284948,
          'name': 'Yu Liu'
        },
        {
          'id': 1336118820,
          'name': 'Youliang Yan'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zhang_Exploiting_Temporal_Consistency_for_Real-Time_Video_Depth_Estimation_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 457102867,
      'title': 'Spatio-Temporal Fusion Based Convolutional Sequence Learning for Lip Reading',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1343604324,
          'name': 'Xingxuan Zhang'
        },
        {
          'id': 1013779650,
          'name': 'Feng Cheng'
        },
        {
          'id': 1163842613,
          'name': 'Shilin Wang'
        }
      ],
      'field': [
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038401195,
          'name': 'Sequence learning'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zhang_Spatio-Temporal_Fusion_Based_Convolutional_Sequence_Learning_for_Lip_Reading_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 466788170,
      'title': 'FW-GAN: Flow-Navigated Warping GAN for Video Virtual Try-On',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1223804681,
          'name': 'Haoye Dong'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1465489388,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1196015352,
          'name': 'Bowen Wu'
        },
        {
          'id': 1185379684,
          'name': 'Bing-cheng Chen'
        },
        {
          'id': 1401140743,
          'name': 'Jian Yin'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032713836,
          'name': 'Flow (psychology)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Dong_FW-GAN_Flow-Navigated_Warping_GAN_for_Video_Virtual_Try-On_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 470975608,
      'title': 'A Delay Metric for Video Object Detection: What Average Precision Fails to Tell',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        }
      ],
      'author': [
        {
          'id': 1471610540,
          'name': 'Huizi Mao'
        },
        {
          'id': 1029350388,
          'name': 'Xiaodong Yang'
        },
        {
          'id': 1267437355,
          'name': 'William J. Dally'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Mao_A_Delay_Metric_for_Video_Object_Detection_What_Average_Precision_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 482218534,
      'title': 'Dynamic Context Correspondence Network for Semantic Alignment',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1355638921,
          'name': 'Shuaiyi Huang'
        },
        {
          'id': 1288328561,
          'name': 'Qiuyue Wang'
        },
        {
          'id': 1382107634,
          'name': 'Songyang Zhang'
        },
        {
          'id': 1393712452,
          'name': 'Shipeng Yan'
        },
        {
          'id': 1171646407,
          'name': 'Xuming He'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001940037,
          'name': 'Human–computer interaction'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Huang_Dynamic_Context_Correspondence_Network_for_Semantic_Alignment_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 494084378,
      'title': 'Symmetric Cross Entropy for Robust Learning With Noisy Labels',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104380003,
          'abbr': '',
          'name': 'University of Melbourne'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        }
      ],
      'author': [
        {
          'id': 1036063581,
          'name': 'Yisen Wang'
        },
        {
          'id': 1044809008,
          'name': 'Xingjun Ma'
        },
        {
          'id': 1465816701,
          'name': 'Zaiyi Chen'
        },
        {
          'id': 1042866044,
          'name': 'Yuan Luo'
        },
        {
          'id': 1029763871,
          'name': 'Jinfeng Yi'
        },
        {
          'id': 1460973562,
          'name': 'James Bailey'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024303337,
          'name': 'Cross entropy'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Wang_Symmetric_Cross_Entropy_for_Robust_Learning_With_Noisy_Labels_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 495087462,
      'title': 'EENA: Efficient Evolution of Neural Architecture.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1154565647,
          'name': 'Hui Zhu'
        },
        {
          'id': 1251637798,
          'name': 'Zhulin An'
        },
        {
          'id': 1208147185,
          'name': 'Chuanguang Yang'
        },
        {
          'id': 1148252699,
          'name': 'Kaiqiang Xu'
        },
        {
          'id': 1337497029,
          'name': 'Erhu Zhao'
        },
        {
          'id': 1072385181,
          'name': 'Yongjun Xu'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCVW_2019/html/NeurArch/Zhu_EENA_Efficient_Evolution_of_Neural_Architecture_ICCVW_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 53003880,
      'title': 'Zero-Shot Emotion Recognition via Affective Structural Embedding',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1326803950,
          'name': 'Chi Zhan'
        },
        {
          'id': 1086713113,
          'name': 'Dongyu She'
        },
        {
          'id': 1433750596,
          'name': 'Sicheng Zhao'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1003062086,
          'name': 'Jufeng Yang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000503217,
          'name': 'Emotion recognition'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2040935861,
          'name': 'Affect (psychology)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Zhan_Zero-Shot_Emotion_Recognition_via_Affective_Structural_Embedding_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 53708672,
      'title': 'Quasi-Globally Optimal and Efficient Vanishing Point Estimation in Manhattan World',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2104875451,
          'abbr': 'KAIST',
          'name': 'KAIST'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1005535113,
          'name': 'Haoang Li'
        },
        {
          'id': 1067402811,
          'name': 'Ji Zhao'
        },
        {
          'id': 1009023827,
          'name': 'Jean Charles Bazin'
        },
        {
          'id': 1035767921,
          'name': 'Wen. Chen'
        },
        {
          'id': 1014309848,
          'name': 'Zhe Liu'
        },
        {
          'id': 1157729448,
          'name': 'Yunhui Liu'
        }
      ],
      'field': [
        {
          'id': 2046361361,
          'name': 'Vanishing point'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/html/Li_Quasi-Globally_Optimal_and_Efficient_Vanishing_Point_Estimation_in_Manhattan_World_ICCV_2019_paper.html',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 53877530,
      'title': 'Prior-aware Neural Network for Partially-Supervised Multi-Organ Segmentation.',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2100957234,
          'abbr': 'SU',
          'name': 'Syracuse University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2103058886,
          'abbr': '',
          'name': 'Johns Hopkins University School of Medicine'
        }
      ],
      'author': [
        {
          'id': 1032751027,
          'name': 'Yuyin Zhou'
        },
        {
          'id': 1334549879,
          'name': 'Zhe Li'
        },
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1495270119,
          'name': 'Chong Wang'
        },
        {
          'id': 1450272173,
          'name': 'Xinlei Chen'
        },
        {
          'id': 1007815921,
          'name': 'Mei Han'
        },
        {
          'id': 1008402547,
          'name': 'Elliot K. Fishman'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Zhou_Prior-Aware_Neural_Network_for_Partially-Supervised_Multi-Organ_Segmentation_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 60665797,
      'title': 'Generative Adversarial Training for Weakly Supervised Cloud Matting',
      'affiliation': [
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1203827968,
          'name': 'Zhengxia Zou'
        },
        {
          'id': 1002852091,
          'name': 'Wenyuan Li'
        },
        {
          'id': 1121642356,
          'name': 'Tianyang Shi'
        },
        {
          'id': 1459406952,
          'name': 'Zhenwei Shi'
        },
        {
          'id': 1168477487,
          'name': 'Jieping Ye'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023495608,
          'name': 'Cloud computing'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Zou_Generative_Adversarial_Training_for_Weakly_Supervised_Cloud_Matting_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 70566279,
      'title': 'Few-Shot Image Recognition With Knowledge Transfer',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1369367947,
          'name': 'Zhimao Peng'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1322248147,
          'name': 'Junge Zhang'
        },
        {
          'id': 1002082398,
          'name': 'Yan Li'
        },
        {
          'id': 1240277240,
          'name': 'Guo-Jun Qi'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        }
      ],
      'field': [
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Peng_Few-Shot_Image_Recognition_With_Knowledge_Transfer_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 73185237,
      'title': 'Attention-Aware Polarity Sensitive Embedding for Affective Image Retrieval',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1069916404,
          'name': 'Xingxu Yao'
        },
        {
          'id': 1086713113,
          'name': 'Dongyu She'
        },
        {
          'id': 1433750596,
          'name': 'Sicheng Zhao'
        },
        {
          'id': 1007465320,
          'name': 'Jie Liang'
        },
        {
          'id': 1093937409,
          'name': 'Yu-Kun Lai'
        },
        {
          'id': 1003062086,
          'name': 'Jufeng Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040935861,
          'name': 'Affect (psychology)'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Yao_Attention-Aware_Polarity_Sensitive_Embedding_for_Affective_Image_Retrieval_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 74112198,
      'title': 'Occlusion Robust Face Recognition Based on Mask Learning With Pairwise Differential Siamese Network',
      'affiliation': [
        {
          'id': 2100931896,
          'abbr': 'UF',
          'name': 'University of Florida'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1215761500,
          'name': 'Lingxue Song'
        },
        {
          'id': 1232229134,
          'name': 'Dihong Gong'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1078468693,
          'name': 'Changsong Liu'
        },
        {
          'id': 1321789444,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Song_Occlusion_Robust_Face_Recognition_Based_on_Mask_Learning_With_Pairwise_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 85062734,
      'title': 'Differentiable Kernel Evolution',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1260288408,
          'name': 'Yu Liu'
        },
        {
          'id': 1366293443,
          'name': 'Jihao Liu'
        },
        {
          'id': 1492630588,
          'name': 'Ailing Zeng'
        },
        {
          'id': 1010995548,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134804772',
        'name': 'ICCV',
        'year': 2019
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2019/papers/Liu_Differentiable_Kernel_Evolution_ICCV_2019_paper.pdf',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 100458456,
      'title': 'Semi-Global Weighted Least Squares in Image Filtering',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104169518,
          'abbr': 'USST',
          'name': 'University of Shanghai for Science and Technology'
        },
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1060362439,
          'name': 'Xiaogang Chen'
        },
        {
          'id': 1452922799,
          'name': 'Chuanhua Shen'
        },
        {
          'id': 1054533873,
          'name': 'Zhi Liu'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2002387869,
          'name': 'Linear system'
        },
        {
          'id': 2033654860,
          'name': 'Magnitude (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 28
    },
    {
      'id': 104118561,
      'title': 'Egocentric Gesture Recognition Using Recurrent 3D Convolutional Neural Networks with Spatiotemporal Transformer Modules',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101307090,
          'abbr': 'NUIST',
          'name': 'Nanjing University of Information Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1021389235,
          'name': 'Congqi Cao'
        },
        {
          'id': 1011431260,
          'name': 'Yifan Zhang'
        },
        {
          'id': 1009122519,
          'name': 'Yi Wu'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028733384,
          'name': 'Natural user interface'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018094585,
          'name': 'Wearable technology'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 32
    },
    {
      'id': 105193338,
      'title': 'A Multimodal Deep Regression Bayesian Network for Affective Video Content Analyses',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1185367134,
          'name': 'Quan Gan'
        },
        {
          'id': 1445199645,
          'name': 'Shangfei Wang'
        },
        {
          'id': 1196642214,
          'name': 'Longfei Hao'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2032900044,
          'name': 'Bayesian network'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 33
    },
    {
      'id': 105357104,
      'title': 'Learning Multi-attention Convolutional Neural Network for Fine-Grained Image Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1362733542,
          'name': 'Heliang Zheng'
        },
        {
          'id': 1057895870,
          'name': 'Jianlong Fu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 65,
      'reference_count': 35
    },
    {
      'id': 108231491,
      'title': 'Learning High Dynamic Range from Outdoor Panoramas',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104434601,
          'abbr': 'ULAVAL',
          'name': 'Laval University'
        }
      ],
      'author': [
        {
          'id': 1105905545,
          'name': 'Jinsong Zhang'
        },
        {
          'id': 1362162100,
          'name': 'Jean-François Lalonde'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043955330,
          'name': 'Dynamic range'
        },
        {
          'id': 2013116504,
          'name': 'Omnidirectional camera'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002476364,
          'name': 'Ranging'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 36
    },
    {
      'id': 109603923,
      'title': 'Hierarchical Multimodal LSTM for Dense Visual-Semantic Embedding',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1306869427,
          'name': 'Zhenxing Niu'
        },
        {
          'id': 1256945730,
          'name': 'Mo Zhou'
        },
        {
          'id': 1238522754,
          'name': 'Le Wang'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 43,
      'reference_count': 34
    },
    {
      'id': 111375308,
      'title': 'Higher-Order Integration of Hierarchical Convolutional Activations for Fine-Grained Visual Categorization',
      'affiliation': [
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1236093116,
          'name': 'Sijia Cai'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2026815810,
          'name': 'Polynomial'
        },
        {
          'id': 2031785153,
          'name': 'Polynomial kernel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 46
    },
    {
      'id': 11278800,
      'title': 'MemNet: A Persistent Memory Network for Image Restoration',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1178629966,
          'name': 'Ying Tai'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        },
        {
          'id': 1304173056,
          'name': 'Xiaoming Liu'
        },
        {
          'id': 1290256705,
          'name': 'Chunyan Xu'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023949645,
          'name': 'Adaptive learning'
        },
        {
          'id': 2027634884,
          'name': 'Deblocking filter'
        },
        {
          'id': 2037707526,
          'name': 'Persistence (computer science)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 111,
      'reference_count': 41
    },
    {
      'id': 113252789,
      'title': 'An Innovative Salient Object Detection Using Center-Dark Channel Prior',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1052488511,
          'name': 'Chunbiao Zhu'
        },
        {
          'id': 1004025334,
          'name': 'Ge Li'
        },
        {
          'id': 1137900456,
          'name': 'Wenmin Wang'
        },
        {
          'id': 1053105704,
          'name': 'Ronggang Wang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2017_workshops/w22/html/Zhu_An_Innovative_Salient_ICCV_2017_paper.html',
      'citation_count': 0,
      'reference_count': 24
    },
    {
      'id': 113911926,
      'title': 'Unsupervised Domain Adaptation for Face Recognition in Unlabeled Videos',
      'affiliation': [
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1063709080,
          'name': 'Kihyuk Sohn'
        },
        {
          'id': 1146035678,
          'name': 'Sifei Liu'
        },
        {
          'id': 1438525052,
          'name': 'Guangyu Zhong'
        },
        {
          'id': 1112564879,
          'name': 'Xiang Yu'
        },
        {
          'id': 1114642236,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1402527788,
          'name': 'Manmohan Chandraker'
        }
      ],
      'field': [
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2000717699,
          'name': 'Knowledge engineering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 3,
      'reference_count': 40
    },
    {
      'id': 116622188,
      'title': 'Structured Images for RGB-D Action Recognition',
      'affiliation': [
        {
          'id': 2103150234,
          'abbr': 'ITU',
          'name': 'Information Technology University'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100460378,
          'abbr': 'UOW',
          'name': 'University of Wollongong'
        }
      ],
      'author': [
        {
          'id': 1289815539,
          'name': 'Pichao Wang'
        },
        {
          'id': 1311912262,
          'name': 'Shuang Wang'
        },
        {
          'id': 1476552787,
          'name': 'Zhimin Gao'
        },
        {
          'id': 1233692244,
          'name': 'Yonghong Hou'
        },
        {
          'id': 1338632600,
          'name': 'Wanqing Li'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 31
    },
    {
      'id': 118137787,
      'title': 'Self-Paced Kernel Estimation for Robust Blind Image Deblurring',
      'affiliation': [
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1192709942,
          'name': 'Dong Gong'
        },
        {
          'id': 1121483151,
          'name': 'Mingkui Tan'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2037601121,
          'name': 'Gaussian noise'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2039217836,
          'name': 'Heuristics'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 42
    },
    {
      'id': 118951961,
      'title': 'Jointly Recognizing Object Fluents and Tasks in Egocentric Videos',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1482713211,
          'name': 'Yang Liu'
        },
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012493528,
          'name': 'Hierarchical database model'
        },
        {
          'id': 2047932954,
          'name': 'Beam search'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 41
    },
    {
      'id': 123922229,
      'title': 'Hard-Aware Deeply Cascaded Embedding',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1261828056,
          'name': 'Yuhui Yuan'
        },
        {
          'id': 1047940084,
          'name': 'Kuiyuan Yang'
        },
        {
          'id': 1051372047,
          'name': 'Chao Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008010037,
          'name': 'Versa'
        },
        {
          'id': 2027184823,
          'name': 'Sample size determination'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 62,
      'reference_count': 43
    },
    {
      'id': 124558497,
      'title': 'Structure-Measure: A New Way to Evaluate Foreground Maps',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1235240356,
          'name': 'Deng-Ping Fan'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1189823451,
          'name': 'Yun Liu'
        },
        {
          'id': 1095651315,
          'name': 'Tao Li'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2005485485,
          'name': 'Saliency map'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008584860,
          'name': 'Human visual system model'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 7,
      'reference_count': 48
    },
    {
      'id': 124739231,
      'title': 'Multi-scale Deep Learning Architectures for Person Re-identification',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1032075244,
          'name': 'Xuelin Qian'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 31
    },
    {
      'id': 128464655,
      'title': 'CoupleNet: Coupling Global Structure with Local Parts for Object Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101307090,
          'abbr': 'NUIST',
          'name': 'Nanjing University of Information Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1369151897,
          'name': 'Yousong Zhu'
        },
        {
          'id': 1282575851,
          'name': 'Chaoyang Zhao'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        },
        {
          'id': 1424660038,
          'name': 'Xu Zhao'
        },
        {
          'id': 1009122519,
          'name': 'Yi Wu'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046442909,
          'name': 'ENCODE'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2042225087,
          'name': 'Subnetwork'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 6,
      'reference_count': 23
    },
    {
      'id': 130529590,
      'title': 'Flow-Guided Feature Aggregation for Video Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1368545847,
          'name': 'Xizhou Zhu'
        },
        {
          'id': 1016108510,
          'name': 'Yujie Wang'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 80,
      'reference_count': 41
    },
    {
      'id': 132965472,
      'title': 'Image2song: Song Retrieval via Bridging Image Content and Lyric Words',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        },
        {
          'id': 1088670356,
          'name': 'Di Hu'
        },
        {
          'id': 1356856029,
          'name': 'Xiaoqiang Lu'
        }
      ],
      'field': [
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2000350581,
          'name': 'Bridging (networking)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2012275282,
          'name': 'Social network'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 40
    },
    {
      'id': 133004358,
      'title': 'Performance Guaranteed Network Acceleration via High-Order Residual Quantization',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1189400893,
          'name': 'Zefan Li'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1317119711,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2013841216,
          'name': 'Acceleration'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2020215843,
          'name': 'Binary operation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2029612372,
          'name': 'Approximation error'
        },
        {
          'id': 2001815435,
          'name': 'Control theory'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 27
    },
    {
      'id': 135211296,
      'title': 'Online Multi-object Tracking Using CNN-Based Single Object Tracker with Spatial-Temporal Attention Mechanism',
      'affiliation': [
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1000853021,
          'name': 'Qi Chu'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1035353314,
          'name': 'Bin Liu'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 27,
      'reference_count': 46
    },
    {
      'id': 137796413,
      'title': 'Efficient Global 2D-3D Matching for Camera Localization in a Large-Scale 3D Map',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102557387,
          'abbr': '',
          'name': 'Vision Australia'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1077541119,
          'name': 'Liu Liu'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 0
    },
    {
      'id': 138464466,
      'title': 'Wavelet-SRNet: A Wavelet-Based CNN for Multi-scale Face Super Resolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1039426828,
          'name': 'Huaibo Huang'
        },
        {
          'id': 1020627459,
          'name': 'Ran He'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2037635684,
          'name': 'Scaling'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2004725592,
          'name': 'Wavelet packet decomposition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 34
    },
    {
      'id': 140414423,
      'title': 'Adversarial PoseNet: A Structure-Aware Convolutional Network for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1047159045,
          'name': 'Yu Chen'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1453321753,
          'name': 'Xiu-Shen Wei'
        },
        {
          'id': 1468852495,
          'name': 'Lingqiao Liu'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2047803603,
          'name': 'Body joints'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1705.html',
      'citation_count': 19,
      'reference_count': 40
    },
    {
      'id': 142823125,
      'title': 'Multimodal Gesture Recognition Based on the ResC3D Network',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1064767780,
          'name': 'Qiguang Miao'
        },
        {
          'id': 1307982449,
          'name': 'Yunan Li'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1089761783,
          'name': 'Zhenxin Ma'
        },
        {
          'id': 1393557858,
          'name': 'Xin Xu'
        },
        {
          'id': 1392958625,
          'name': 'Weikang Shi'
        },
        {
          'id': 1147264019,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010264609,
          'name': 'Color constancy'
        },
        {
          'id': 2017733928,
          'name': 'Median filter'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        },
        {
          'id': 2014122647,
          'name': 'Unification'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 28
    },
    {
      'id': 142843479,
      'title': 'Cross-View Asymmetric Metric Learning for Unsupervised Person Re-Identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1046260562,
          'name': 'Hong-Xing Yu'
        },
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2032409045,
          'name': 'Disjoint sets'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 35
    },
    {
      'id': 145157313,
      'title': 'Channel Pruning for Accelerating Very Deep Neural Networks',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1321820340,
          'name': 'Yihui He'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2048065051,
          'name': 'Lasso (statistics)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 255,
      'reference_count': 52
    },
    {
      'id': 145190129,
      'title': 'Multi-channel Weighted Nuclear Norm Minimization for Real Color Image Denoising',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1446421712,
          'name': 'Jun Xu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2013004239,
          'name': 'Colors of noise'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2010567281,
          'name': 'Grayscale'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 33
    },
    {
      'id': 145742092,
      'title': 'Pose-Driven Deep Convolutional Model for Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1424228217,
          'name': 'Chi Su'
        },
        {
          'id': 1230009131,
          'name': 'Jianing Li'
        },
        {
          'id': 1398975099,
          'name': 'Shiliang Zhang'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1317119711,
          'name': 'Wen Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2040012394,
          'name': 'Weighting'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 93,
      'reference_count': 32
    },
    {
      'id': 146331243,
      'title': 'A New Low-Light Image Enhancement Algorithm Using Camera Response Model',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1131338099,
          'name': 'Zhenqiang Ying'
        },
        {
          'id': 1444701965,
          'name': 'Ge Li'
        },
        {
          'id': 1263900715,
          'name': 'Yurui Ren'
        },
        {
          'id': 1053105704,
          'name': 'Ronggang Wang'
        },
        {
          'id': 1137900456,
          'name': 'Wenmin Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2017968033,
          'name': 'Camera auto-calibration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005547078,
          'name': 'Camera resectioning'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2006808376,
          'name': 'Lightness'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 20
    },
    {
      'id': 149529062,
      'title': 'RMPE: Regional Multi-person Pose Estimation',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1488644272,
          'name': 'Hao-Shu Fang'
        },
        {
          'id': 1378060791,
          'name': 'Shuqin Xie'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014430643,
          'name': 'Estimator'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://www.semanticscholar.org/paper/RMPE%3A-Regional-Multi-person-Pose-Estimation-Fang-Xie/56d3df5ce2ffb695728c091252087979be31f0c7',
      'citation_count': 32,
      'reference_count': 38
    },
    {
      'id': 150405267,
      'title': 'Pose-Driven Deep Convolutional Model for Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1424228217,
          'name': 'Chi Su'
        },
        {
          'id': 1230009131,
          'name': 'Jianing Li'
        },
        {
          'id': 1398975099,
          'name': 'Shiliang Zhang'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040012394,
          'name': 'Weighting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1709.html',
      'citation_count': 22,
      'reference_count': 54
    },
    {
      'id': 150606404,
      'title': 'End-to-End Visual Target Tracking in Multi-robot Systems Based on Deep Convolutional Neural Network',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1261519035,
          'name': 'Yawen Cui'
        },
        {
          'id': 1248032593,
          'name': 'Bo Zhang'
        },
        {
          'id': 1124728956,
          'name': 'Wenjing Yang'
        },
        {
          'id': 1225919162,
          'name': 'Zhiyuan Wang'
        },
        {
          'id': 1153979906,
          'name': 'Yin Li'
        },
        {
          'id': 1256465174,
          'name': 'Xiaodong Yi'
        },
        {
          'id': 1051917754,
          'name': 'Yuhua Tang'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2022524449,
          'name': 'Angular velocity'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2018683753,
          'name': 'GNSS applications'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2048044401,
          'name': 'Robot'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 1544356,
      'title': 'Rotation Invariant Local Binary Convolution Neural Networks',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2102459432,
          'abbr': 'UEA',
          'name': 'University of East Anglia'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1009662940,
          'name': 'Xin Zhang'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1021943169,
          'name': 'Yuxiang Xie'
        },
        {
          'id': 1223927465,
          'name': 'Jie Chen'
        },
        {
          'id': 1014458478,
          'name': 'Lingda Wu'
        },
        {
          'id': 1390027563,
          'name': 'Matti Pietikäinen'
        }
      ],
      'field': [
        {
          'id': 2002180148,
          'name': 'Circular convolution'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2048190627,
          'name': 'Active filter'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 160838972,
      'title': 'MemNet: A Persistent Memory Network for Image Restoration',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1178629966,
          'name': 'Ying Tai'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        },
        {
          'id': 1004435915,
          'name': 'Xiaoming Liu'
        },
        {
          'id': 1290256705,
          'name': 'Chunyan Xu'
        }
      ],
      'field': [
        {
          'id': 2023949645,
          'name': 'Adaptive learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2004645378,
          'name': 'Transform coding'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2027634884,
          'name': 'Deblocking filter'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1708.02209.pdf',
      'citation_count': 55,
      'reference_count': 40
    },
    {
      'id': 161408072,
      'title': 'Embedding 3D Geometric Features for Rigid Object Composition Segmentation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1403803510,
          'name': 'Yafei Song'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1149258320,
          'name': 'Jia Li'
        },
        {
          'id': 1203754355,
          'name': 'Qinping Zhao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008149693,
          'name': 'Conjecture'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 163429007,
      'title': 'Learning Discriminative Latent Attributes for Zero-Shot Classification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1013515220,
          'name': 'Huajie Jiang'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010861762,
          'name': 'Yi Yang'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014232538,
          'name': 'Popularity'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 43
    },
    {
      'id': 166615980,
      'title': 'An Innovative Salient Object Detection Using Center-Dark Channel Prior',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1052488511,
          'name': 'Chunbiao Zhu'
        },
        {
          'id': 1444701965,
          'name': 'Ge Li'
        },
        {
          'id': 1137900456,
          'name': 'Wenmin Wang'
        },
        {
          'id': 1053105704,
          'name': 'Ronggang Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 25
    },
    {
      'id': 170365988,
      'title': 'Transformed Low-Rank Model for Line Pattern Noise Removal',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1191486744,
          'name': 'Yi Chang'
        },
        {
          'id': 1001152243,
          'name': 'Luxin Yan'
        },
        {
          'id': 1101071010,
          'name': 'Sheng Zhong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011893552,
          'name': 'Streak'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 39
    },
    {
      'id': 177077343,
      'title': 'Beyond Face Rotation: Global and Local Perception GAN for Photorealistic and Identity Preserving Frontal View Synthesis',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1000379534,
          'name': 'Rui Huang'
        },
        {
          'id': 1179504793,
          'name': 'Shu Zhang'
        },
        {
          'id': 1271277686,
          'name': 'Tianyu Li'
        },
        {
          'id': 1020627459,
          'name': 'Ran He'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 129,
      'reference_count': 39
    },
    {
      'id': 177512476,
      'title': 'Joint Convolutional Analysis and Synthesis Sparse Representation for Single Image Layer Separation',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2043955330,
          'name': 'Dynamic range'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041313107,
          'name': 'Tone mapping'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 42
    },
    {
      'id': 178974840,
      'title': 'Semi-Global Weighted Least Squares in Image Filtering',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104169518,
          'abbr': 'USST',
          'name': 'University of Shanghai for Science and Technology'
        },
        {
          'id': 2102042807,
          'abbr': 'SHU',
          'name': 'Shanghai University'
        }
      ],
      'author': [
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1060362439,
          'name': 'Xiaogang Chen'
        },
        {
          'id': 1452922799,
          'name': 'Chuanhua Shen'
        },
        {
          'id': 1054533873,
          'name': 'Zhi Liu'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        }
      ],
      'field': [
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033654860,
          'name': 'Magnitude (mathematics)'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2002387869,
          'name': 'Linear system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1705.html',
      'citation_count': 1,
      'reference_count': 29
    },
    {
      'id': 186647978,
      'title': 'When Unsupervised Domain Adaptation Meets Tensor Representations',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1257968729,
          'name': 'Hao Lu'
        },
        {
          'id': 1386051449,
          'name': 'Lei Zhang'
        },
        {
          'id': 1216320010,
          'name': 'Zhiguo Cao'
        },
        {
          'id': 1444713601,
          'name': 'Wei Wei'
        },
        {
          'id': 1131102328,
          'name': 'Ke Xian'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029579503,
          'name': 'Vectorization (mathematics)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2013517689,
          'name': 'Stress (mechanics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 42
    },
    {
      'id': 191451914,
      'title': 'Detecting Faces Using Inside Cascaded Contextual CNN',
      'affiliation': [
        {
          'id': 2101276577,
          'abbr': 'NTU',
          'name': 'National Taiwan University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1094933525,
          'name': 'Kaipeng Zhang'
        },
        {
          'id': 1024645333,
          'name': 'Zhanpeng Zhang'
        },
        {
          'id': 1198143559,
          'name': 'Hao Wang'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 32
    },
    {
      'id': 194419119,
      'title': 'HashNet: Deep Learning to Hash by Continuation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1126860916,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1445179598,
          'name': 'Philip S. Yu'
        }
      ],
      'field': [
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033262770,
          'name': 'Rolling hash'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029353294,
          'name': 'Primary clustering'
        },
        {
          'id': 2015567075,
          'name': 'SWIFFT'
        },
        {
          'id': 2042182389,
          'name': 'Hash tree'
        },
        {
          'id': 2002478305,
          'name': 'Quadratic probing'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2020670263,
          'name': 'Hash filter'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 41
    },
    {
      'id': 196218928,
      'title': 'Factorized Bilinear Models for Image Recognition',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2102247394,
          'abbr': 'CIT',
          'name': 'California Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1127428292,
          'name': 'Yanghao Li'
        },
        {
          'id': 1133501095,
          'name': 'Naiyan Wang'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1420766327,
          'name': 'Xiaodi Hou'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2039252156,
          'name': 'Parametrization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2042592337,
          'name': 'Linear map'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 40
    },
    {
      'id': 1982061,
      'title': 'Deep Cropping via Attention Box Prediction and Aesthetics Assessment',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2032750659,
          'name': 'Cropping'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2037388878,
          'name': 'Aesthetics'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2022475402,
          'name': 'Limited availability'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 41
    },
    {
      'id': 200066816,
      'title': 'Continuous Gesture Recognition with Hand-Oriented Spatiotemporal Feature',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1056741177,
          'name': 'Zhipeng Liu'
        },
        {
          'id': 1067315396,
          'name': 'Xiujuan Chai'
        },
        {
          'id': 1212418738,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031276404,
          'name': 'Jaccard index'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 25
    },
    {
      'id': 202956597,
      'title': 'Decoder Network over Lightweight Reconstructed Feature for Fast Semantic Style Transfer',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1354266357,
          'name': 'Ming Lu'
        },
        {
          'id': 1263201152,
          'name': 'Hao Zhao'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1098810408,
          'name': 'Feng Xu'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1168982309,
          'name': 'Li Zhang'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000350581,
          'name': 'Bridging (networking)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 21
    },
    {
      'id': 208328023,
      'title': 'ThiNet: A Filter Level Pruning Method for Deep Neural Network Compression',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1320722949,
          'name': 'Jian-Hao Luo'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2046490491,
          'name': 'FLOPS'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2035276397,
          'name': 'Pruning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 154,
      'reference_count': 33
    },
    {
      'id': 208826592,
      'title': 'FoveaNet: Perspective-Aware Urban Scene Parsing',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2104935093,
          'abbr': 'UNITN',
          'name': 'University of Trento'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1394225671,
          'name': 'Xin Li'
        },
        {
          'id': 1403252987,
          'name': 'Zequn Jie'
        },
        {
          'id': 1291461363,
          'name': 'Wei Wang'
        },
        {
          'id': 1048604432,
          'name': 'Changsong Liu'
        },
        {
          'id': 1357326848,
          'name': 'Jimei Yang'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1256462433,
          'name': 'Zhe Lin'
        },
        {
          'id': 1262890268,
          'name': 'Qiang Chen'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        }
      ],
      'field': [
        {
          'id': 2006335577,
          'name': 'Perspective (graphical)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2036255561,
          'name': 'CRFS'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2024899112,
          'name': 'Perspective (geometry)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 4,
      'reference_count': 38
    },
    {
      'id': 211540306,
      'title': 'Recurrent Scale Approximation for Object Detection in CNN',
      'affiliation': [
        {
          'id': 2100086938,
          'abbr': 'LEIDENUNIV',
          'name': 'Leiden University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1058037770,
          'name': 'Yu Liu'
        },
        {
          'id': 1282242824,
          'name': 'Hongyang Li'
        },
        {
          'id': 1465824659,
          'name': 'Junjie Yan'
        },
        {
          'id': 1165172349,
          'name': 'Fangyin Wei'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 13,
      'reference_count': 37
    },
    {
      'id': 212239378,
      'title': 'ThiNet: A Filter Level Pruning Method for Deep Neural Network Compression',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1320722949,
          'name': 'Jian-Hao Luo'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        }
      ],
      'field': [
        {
          'id': 2046490491,
          'name': 'FLOPS'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2035276397,
          'name': 'Pruning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013841216,
          'name': 'Acceleration'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 53,
      'reference_count': 32
    },
    {
      'id': 213203245,
      'title': 'Predicting Human Activities Using Stochastic Grammar',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1417522252,
          'name': 'Siyuan Qi'
        },
        {
          'id': 1382605034,
          'name': 'Siyuan Huang'
        },
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2010593208,
          'name': 'Stochastic grammar'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2008262245,
          'name': 'Affordance'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046331716,
          'name': 'Grammar'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 0,
      'reference_count': 39
    },
    {
      'id': 216541434,
      'title': 'Deformable Convolutional Networks',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1402900587,
          'name': 'Haozhi Qi'
        },
        {
          'id': 1027853302,
          'name': 'Yuwen Xiong'
        },
        {
          'id': 1219021850,
          'name': 'Yi Li'
        },
        {
          'id': 1167790294,
          'name': 'Guodong Zhang'
        },
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2021767452,
          'name': 'Transformation geometry'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 404,
      'reference_count': 41
    },
    {
      'id': 217409862,
      'title': 'A Discriminative View of MRF Pre-processing Algorithms',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        }
      ],
      'author': [
        {
          'id': 1081170536,
          'name': 'Chen Wang'
        },
        {
          'id': 1432063854,
          'name': 'Charles Herrmann'
        },
        {
          'id': 1306993101,
          'name': 'Ramin Zabih'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040590228,
          'name': 'False positive paradox'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2047824847,
          'name': 'Random field'
        },
        {
          'id': 2022297451,
          'name': 'Dead-end elimination'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 40
    },
    {
      'id': 221158179,
      'title': 'Human Pose Estimation Using Global and Local Normalization',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1380189925,
          'name': 'Ke Sun'
        },
        {
          'id': 1171674693,
          'name': 'Cuiling Lan'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2026452782,
          'name': 'Left wrist'
        },
        {
          'id': 2021678227,
          'name': 'Spatial configuration'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2044851915,
          'name': 'Left shoulder'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 33
    },
    {
      'id': 222030508,
      'title': 'Tensor RPCA by Bayesian CP Factorization with Complex Noise',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1009461593,
          'name': 'Qiong Luo'
        },
        {
          'id': 1028968436,
          'name': 'Zhi Han'
        },
        {
          'id': 1284269681,
          'name': 'Xi\'ai Chen'
        },
        {
          'id': 1212993904,
          'name': 'Yao Wang'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1011093440,
          'name': 'Dong Liang'
        },
        {
          'id': 1321471777,
          'name': 'Yandong Tang'
        }
      ],
      'field': [
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 39
    },
    {
      'id': 224551371,
      'title': 'SVDNet for Pedestrian Retrieval',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1430625305,
          'name': 'Yifan Sun'
        },
        {
          'id': 1387078177,
          'name': 'Liang Zheng'
        },
        {
          'id': 1323261168,
          'name': 'Weijian Deng'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        }
      ],
      'field': [
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2029752579,
          'name': 'Vector decomposition'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038240472,
          'name': 'Weight'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035050575,
          'name': 'Orthogonality'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1703.05693.pdf',
      'citation_count': 62,
      'reference_count': 35
    },
    {
      'id': 224601215,
      'title': 'AOD-Net: All-in-One Dehazing Network',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100577709,
          'abbr': 'TAMU',
          'name': 'Texas A&M University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1266126406,
          'name': 'Boyi Li'
        },
        {
          'id': 1478530243,
          'name': 'Xiulian Peng'
        },
        {
          'id': 1474290517,
          'name': 'Zhangyang Wang'
        },
        {
          'id': 1050286348,
          'name': 'Jizheng Xu'
        },
        {
          'id': 1068797499,
          'name': 'Dan Feng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2037257956,
          'name': 'Scattering'
        },
        {
          'id': 2022031314,
          'name': 'Atmospheric model'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 88,
      'reference_count': 30
    },
    {
      'id': 224900084,
      'title': 'Adaptive Pooling in Multi-instance Learning for Web Video Annotation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1425172909,
          'name': 'Yizhou Zhou'
        },
        {
          'id': 1423798589,
          'name': 'Xiaoyan Sun'
        },
        {
          'id': 1011720102,
          'name': 'Zheng-Jun Zha'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 40
    },
    {
      'id': 225220574,
      'title': 'Learning Efficient Convolutional Networks through Network Slimming',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1434676857,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        },
        {
          'id': 1316790742,
          'name': 'Zhiqiang Shen'
        },
        {
          'id': 1070895922,
          'name': 'Gao Huang'
        },
        {
          'id': 1014148388,
          'name': 'Shoumeng Yan'
        },
        {
          'id': 1110227432,
          'name': 'Changshui Zhang'
        }
      ],
      'field': [
        {
          'id': 2016965578,
          'name': 'Computer engineering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2017552540,
          'name': 'Memory footprint'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2044959998,
          'name': 'Software deployment'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2042464581,
          'name': 'Software'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 52,
      'reference_count': 32
    },
    {
      'id': 225241763,
      'title': 'Object-Level Proposals',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1461741153,
          'name': 'Jianxiang Ma'
        },
        {
          'id': 1039374158,
          'name': 'Anlong Ming'
        },
        {
          'id': 1487074933,
          'name': 'Zilong Huang'
        },
        {
          'id': 1239442845,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1392625816,
          'name': 'Yu Zhou'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 30
    },
    {
      'id': 225439817,
      'title': 'Multi-channel Weighted Nuclear Norm Minimization for Real Color Image Denoising',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1446421712,
          'name': 'Jun Xu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1178567719,
          'name': 'David Zhang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        }
      ],
      'field': [
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2013004239,
          'name': 'Colors of noise'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2010567281,
          'name': 'Grayscale'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1705.html',
      'citation_count': 3,
      'reference_count': 32
    },
    {
      'id': 227208109,
      'title': 'A Revisit of Sparse Coding Based Anomaly Detection in Stacked RNN Framework',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1252850696,
          'name': 'Weixin Luo'
        },
        {
          'id': 1405436572,
          'name': 'Wen Liu'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 26
    },
    {
      'id': 232185000,
      'title': 'From Face Recognition to Kinship Verification: An Adaptation Approach',
      'affiliation': [
        {
          'id': 2101592826,
          'abbr': 'CQU',
          'name': 'Chongqing University'
        },
        {
          'id': 2102346228,
          'abbr': 'CSU',
          'name': 'Central South University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1447381263,
          'name': 'Qingyan Duan'
        },
        {
          'id': 1358133677,
          'name': 'Lei Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000272590,
          'name': 'Kinship'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 0
    },
    {
      'id': 236520659,
      'title': 'SurfaceNet: An End-to-End 3D Neural Network for Multiview Stereopsis',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2100789236,
          'abbr': '',
          'name': 'University of Bonn'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1177700671,
          'name': 'Mengqi Ji'
        },
        {
          'id': 1056716200,
          'name': 'Juergen Gall'
        },
        {
          'id': 1029876462,
          'name': 'Haitian Zheng'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2037875506,
          'name': 'Voxel'
        },
        {
          'id': 2023853581,
          'name': 'Stereopsis'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 1,
      'reference_count': 29
    },
    {
      'id': 23731296,
      'title': '3DCNN-DQN-RNN: A Deep Reinforcement Learning Framework for Semantic Parsing of Large-Scale 3D Point Clouds',
      'affiliation': [
        {
          'id': 2100081609,
          'abbr': 'BNU',
          'name': 'Beijing Normal University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1344207103,
          'name': 'Fangyu Liu'
        },
        {
          'id': 1302768614,
          'name': 'Shuaipeng Li'
        },
        {
          'id': 1402752976,
          'name': 'Liqiang Zhang'
        },
        {
          'id': 1080658957,
          'name': 'Chenghu Zhou'
        },
        {
          'id': 1243039858,
          'name': 'Rongtian Ye'
        },
        {
          'id': 1218441972,
          'name': 'Yuebin Wang'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 1,
      'reference_count': 32
    },
    {
      'id': 237508018,
      'title': 'Learning Long-Term Dependencies for Action Recognition with a Biologically-Inspired Deep Network',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1160459049,
          'name': 'Yemin Shi'
        },
        {
          'id': 1484177249,
          'name': 'Yonghong Tian'
        },
        {
          'id': 1306980273,
          'name': 'Yaowei Wang'
        },
        {
          'id': 1487211458,
          'name': 'Wei Zeng'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2026328187,
          'name': 'Feedforward neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2032550953,
          'name': 'Feed forward'
        },
        {
          'id': 2038401195,
          'name': 'Sequence learning'
        },
        {
          'id': 2014351305,
          'name': 'Long short term memory'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 39
    },
    {
      'id': 238258535,
      'title': 'UCT: Learning Unified Convolutional Networks for Real-Time Visual Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1168372245,
          'name': 'Guan Huang'
        },
        {
          'id': 1268828894,
          'name': 'Wei Zou'
        },
        {
          'id': 1319563912,
          'name': 'Dalong Du'
        },
        {
          'id': 1300040491,
          'name': 'Chang Huang'
        }
      ],
      'field': [
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 36
    },
    {
      'id': 242323448,
      'title': 'PanNet: A Deep Network Architecture for Pan-Sharpening',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1080047683,
          'name': 'Junfeng Yang'
        },
        {
          'id': 1083964890,
          'name': 'Xueyang Fu'
        },
        {
          'id': 1470264184,
          'name': 'Yuwen Hu'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        },
        {
          'id': 1005874265,
          'name': 'John W. Paisley'
        }
      ],
      'field': [
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2020237083,
          'name': 'Sharpening'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 24
    },
    {
      'id': 242601006,
      'title': 'Cross-View Asymmetric Metric Learning for Unsupervised Person Re-Identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1046260562,
          'name': 'Hong-Xing Yu'
        },
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2032409045,
          'name': 'Disjoint sets'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003914179,
          'name': 'Interference (wave propagation)'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2007611418,
          'name': 'Shared space'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 19,
      'reference_count': 36
    },
    {
      'id': 245054048,
      'title': 'Complex Event Detection by Identifying Reliable Shots from Untrimmed Videos',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1236747002,
          'name': 'Hehe Fan'
        },
        {
          'id': 1174238937,
          'name': 'Xiaojun Chang'
        },
        {
          'id': 1300355815,
          'name': 'De Cheng'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 28
    },
    {
      'id': 249231467,
      'title': 'Temporal Context Network for Activity Localization in Videos',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2101760029,
          'abbr': 'MERL',
          'name': 'Mitsubishi Electric Research Laboratories'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1145858558,
          'name': 'Xiyang Dai'
        },
        {
          'id': 1399034765,
          'name': 'Bharat Singh'
        },
        {
          'id': 1126051549,
          'name': 'Guyue Zhang'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        },
        {
          'id': 1350594211,
          'name': 'Yan Qiu Chen'
        }
      ],
      'field': [
        {
          'id': 2037377012,
          'name': 'Temporal scales'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001611344,
          'name': 'Temporal context'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1708.02349.pdf',
      'citation_count': 17,
      'reference_count': 34
    },
    {
      'id': 251687302,
      'title': 'RMPE: Regional Multi-person Pose Estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1488644272,
          'name': 'Hao-Shu Fang'
        },
        {
          'id': 1378060791,
          'name': 'Shuqin Xie'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014430643,
          'name': 'Estimator'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 81,
      'reference_count': 38
    },
    {
      'id': 255449454,
      'title': 'Manifold Constrained Low-Rank Decomposition',
      'affiliation': [
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101433684,
          'abbr': 'IIT',
          'name': 'Istituto Italiano di Tecnologia'
        }
      ],
      'author': [
        {
          'id': 1453935872,
          'name': 'Chen Chen'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1308131702,
          'name': 'Alessio Del Bue'
        },
        {
          'id': 1344208569,
          'name': 'Vittorio Murino'
        }
      ],
      'field': [
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2017_workshops/papers/w25/Chen_Manifold_Constrained_Low-Rank_ICCV_2017_paper.pdf',
      'citation_count': 0,
      'reference_count': 28
    },
    {
      'id': 25787630,
      'title': 'UDNet: Up-Down Network for Compact and Efficient Feature Representation in Image Super-Resolution',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1082179902,
          'name': 'Chang Chen'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        }
      ],
      'field': [
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 258194686,
      'title': 'Adversarial PoseNet: A Structure-Aware Convolutional Network for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1047159045,
          'name': 'Yu Chen'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1453321753,
          'name': 'Xiu-Shen Wei'
        },
        {
          'id': 1468852495,
          'name': 'Lingqiao Liu'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2042278253,
          'name': 'Articulated body pose estimation'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 74,
      'reference_count': 39
    },
    {
      'id': 259519323,
      'title': 'Detecting Smiles of Young Children via Deep Transfer Learning',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1010454429,
          'name': 'Yu Xia'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 21
    },
    {
      'id': 261528369,
      'title': 'Hierarchical Feature Degradation Based Blind Image Quality Assessment',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1060237063,
          'name': 'Jinjian Wu'
        },
        {
          'id': 1461107390,
          'name': 'Jichen Zeng'
        },
        {
          'id': 1186758695,
          'name': 'Yongxu Liu'
        },
        {
          'id': 1291914506,
          'name': 'Guangming Shi'
        },
        {
          'id': 1364274232,
          'name': 'Weisi Lin'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 18
    },
    {
      'id': 261754405,
      'title': 'RPAN: An End-to-End Recurrent Pose-Attention Network for Action Recognition in Videos',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1079223198,
          'name': 'Wenbin Du'
        },
        {
          'id': 1195612761,
          'name': 'Yali Wang'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2007370901,
          'name': 'Sharing attention'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 45
    },
    {
      'id': 263628356,
      'title': 'Integrating Boundary and Center Correlation Filters for Visual Tracking with Aspect Ratio Variation',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1386848118,
          'name': 'Feng Li'
        },
        {
          'id': 1467534421,
          'name': 'Yingjie Yao'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 21
    },
    {
      'id': 264420611,
      'title': 'Mutual Enhancement for Detection of Multiple Logos in Sports Videos',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100861992,
          'abbr': 'UAB',
          'name': 'University of Alabama at Birmingham'
        }
      ],
      'author': [
        {
          'id': 1317700962,
          'name': 'Yuan Liao'
        },
        {
          'id': 1401771490,
          'name': 'Xiaoqing Lu'
        },
        {
          'id': 1417228280,
          'name': 'Chengcui Zhang'
        },
        {
          'id': 1022259518,
          'name': 'Yongtao Wang'
        },
        {
          'id': 1234513491,
          'name': 'Zhi Tang'
        }
      ],
      'field': [
        {
          'id': 2049269054,
          'name': 'Homogeneous'
        },
        {
          'id': 2003933557,
          'name': 'Logo'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029483873,
          'name': 'Mutual exclusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2042151958,
          'name': 'Logos Bible Software'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 35
    },
    {
      'id': 264508957,
      'title': 'DSOD: Learning Deeply Supervised Object Detectors from Scratch',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1316790742,
          'name': 'Zhiqiang Shen'
        },
        {
          'id': 1434676857,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029715669,
          'name': 'Design elements and principles'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023124670,
          'name': 'Scratch'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 25,
      'reference_count': 16
    },
    {
      'id': 264764900,
      'title': 'Performance Guaranteed Network Acceleration via High-Order Residual Quantization',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1189400893,
          'name': 'Zefan Li'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1317119711,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2029612372,
          'name': 'Approximation error'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013841216,
          'name': 'Acceleration'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 5,
      'reference_count': 27
    },
    {
      'id': 265520139,
      'title': 'Transferring Objects: Joint Inference of Container and Human Pose',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2103783453,
          'abbr': 'UMASS',
          'name': 'University of Massachusetts Amherst'
        }
      ],
      'author': [
        {
          'id': 1096925035,
          'name': 'Hanqing Wang'
        },
        {
          'id': 1010690230,
          'name': 'Wei Liang'
        },
        {
          'id': 1215982135,
          'name': 'Lap-Fai Yu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2005310215,
          'name': 'Structured prediction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 274669854,
      'title': 'Focusing Attention: Towards Accurate Text Recognition in Natural Images',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1046738235,
          'name': 'Zhanzhan Cheng'
        },
        {
          'id': 1351963402,
          'name': 'Fan Bai'
        },
        {
          'id': 1145095773,
          'name': 'Yunlu Xu'
        },
        {
          'id': 1001052940,
          'name': 'Gang Zheng'
        },
        {
          'id': 1384883042,
          'name': 'Shiliang Pu'
        },
        {
          'id': 1494078016,
          'name': 'Shuigeng Zhou'
        }
      ],
      'field': [
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043909659,
          'name': 'residual neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2001020821,
          'name': 'Focusing attention'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1709.html',
      'citation_count': 16,
      'reference_count': 32
    },
    {
      'id': 274930081,
      'title': 'Video Scene Parsing with Predictive Feature Learning',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        }
      ],
      'author': [
        {
          'id': 1212923758,
          'name': 'Xiaojie Jin'
        },
        {
          'id': 1394225671,
          'name': 'Xin Li'
        },
        {
          'id': 1047018509,
          'name': 'Huaxin Xiao'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1256462433,
          'name': 'Zhe Lin'
        },
        {
          'id': 1357326848,
          'name': 'Jimei Yang'
        },
        {
          'id': 1019258178,
          'name': 'Yunpeng Chen'
        },
        {
          'id': 1463499896,
          'name': 'Jian Dong'
        },
        {
          'id': 1350047351,
          'name': 'Luoqi Liu'
        },
        {
          'id': 1273266408,
          'name': 'Zequn Jie'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2043868725,
          'name': 'Predictive learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2025526955,
          'name': 'Bottom-up parsing'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 41
    },
    {
      'id': 277976919,
      'title': 'A Two-Streamed Network for Estimating Fine-Scaled Depth Maps from Single RGB Images',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100789236,
          'abbr': '',
          'name': 'University of Bonn'
        }
      ],
      'author': [
        {
          'id': 1462352723,
          'name': 'Jun Li'
        },
        {
          'id': 1418018467,
          'name': 'Reinhard Klein'
        },
        {
          'id': 1439744053,
          'name': 'Angela Yao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 28
    },
    {
      'id': 280634010,
      'title': 'Attention-Aware Deep Reinforcement Learning for Video Face Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1223346775,
          'name': 'Yongming Rao'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039992481,
          'name': 'Markov decision process'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2007775677,
          'name': 'Markov process'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 45
    },
    {
      'id': 282763635,
      'title': '3DCNN-DQN-RNN: A Deep Reinforcement Learning Framework for Semantic Parsing of Large-Scale 3D Point Clouds',
      'affiliation': [
        {
          'id': 2100081609,
          'abbr': 'BNU',
          'name': 'Beijing Normal University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1344207103,
          'name': 'Fangyu Liu'
        },
        {
          'id': 1302768614,
          'name': 'Shuaipeng Li'
        },
        {
          'id': 1402752976,
          'name': 'Liqiang Zhang'
        },
        {
          'id': 1080658957,
          'name': 'Chenghu Zhou'
        },
        {
          'id': 1111732344,
          'name': 'Rongtian Ye'
        },
        {
          'id': 1218441972,
          'name': 'Yuebin Wang'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2005725091,
          'name': 'Raw data'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 23
    },
    {
      'id': 284406641,
      'title': 'Supervision by Fusion: Towards Unsupervised Learning of Deep Salient Object Detector',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        },
        {
          'id': 1000114020,
          'name': 'Yu Zhang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 46
    },
    {
      'id': 284956475,
      'title': 'Structured Attentions for Visual Question Answering',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1032991592,
          'name': 'Chen Zhu'
        },
        {
          'id': 1353743105,
          'name': 'Yanpeng Zhao'
        },
        {
          'id': 1355638921,
          'name': 'Shuaiyi Huang'
        },
        {
          'id': 1024583554,
          'name': 'Kewei Tu'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2013787702,
          'name': 'Belief propagation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 8,
      'reference_count': 44
    },
    {
      'id': 287184599,
      'title': 'Robust Object Tracking Based on Temporal and Spatial Deep Networks',
      'affiliation': [
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1442894463,
          'name': 'Zhu Teng'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1021140366,
          'name': 'Qiang Wang'
        },
        {
          'id': 1223231328,
          'name': 'Congyan Lang'
        },
        {
          'id': 1447362691,
          'name': 'Songhe Feng'
        },
        {
          'id': 1023806128,
          'name': 'Yi Jin'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 62
    },
    {
      'id': 290500324,
      'title': 'CoupleNet: Coupling Global Structure with Local Parts for Object Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101307090,
          'abbr': 'NUIST',
          'name': 'Nanjing University of Information Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1369151897,
          'name': 'Yousong Zhu'
        },
        {
          'id': 1282575851,
          'name': 'Chaoyang Zhao'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        },
        {
          'id': 1424660038,
          'name': 'Xu Zhao'
        },
        {
          'id': 1009122519,
          'name': 'Yi Wu'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        }
      ],
      'field': [
        {
          'id': 2042225087,
          'name': 'Subnetwork'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2039820285,
          'name': 'Coupling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 12
    },
    {
      'id': 292163742,
      'title': 'Deep Dual Learning for Semantic Image Segmentation',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1275825081,
          'name': 'Ping Luo'
        },
        {
          'id': 1177260794,
          'name': 'Guangrun Wang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006310969,
          'name': 'The Internet'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 21
    },
    {
      'id': 292387799,
      'title': 'Integrating Boundary and Center Correlation Filters for Visual Tracking with Aspect Ratio Variation',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1386848118,
          'name': 'Feng Li'
        },
        {
          'id': 1467534421,
          'name': 'Yingjie Yao'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1178567719,
          'name': 'David Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1114642236,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2017_workshops/papers/w28/Li_Integrating_Boundary_and_ICCV_2017_paper.pdf',
      'citation_count': 2,
      'reference_count': 38
    },
    {
      'id': 292608339,
      'title': 'Learning Discriminative Aggregation Network for Video-Based Face Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1223346775,
          'name': 'Yongming Rao'
        },
        {
          'id': 1260768795,
          'name': 'Ji Lin'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2036641578,
          'name': 'CLIPS'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 48
    },
    {
      'id': 295168697,
      'title': 'Structure-Measure: A New Way to Evaluate Foreground Maps',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1235240356,
          'name': 'Deng-Ping Fan'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1189823451,
          'name': 'Yun Liu'
        },
        {
          'id': 1001329335,
          'name': 'Tao Li'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008584860,
          'name': 'Human visual system model'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2005485485,
          'name': 'Saliency map'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 47
    },
    {
      'id': 295397498,
      'title': 'Beyond Face Rotation: Global and Local Perception GAN for Photorealistic and Identity Preserving Frontal View Synthesis',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1100378241,
          'name': 'Rui Huang'
        },
        {
          'id': 1179504793,
          'name': 'Shu Zhang'
        },
        {
          'id': 1271277686,
          'name': 'Tianyu Li'
        },
        {
          'id': 1020627459,
          'name': 'Ran He'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1704.html',
      'citation_count': 36,
      'reference_count': 41
    },
    {
      'id': 296894621,
      'title': 'Learning Spread-Out Local Feature Descriptors',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1461070388,
          'name': 'Xu Zhang'
        },
        {
          'id': 1379625839,
          'name': 'Felix X. Yu'
        },
        {
          'id': 1360550190,
          'name': 'Sanjiv Kumar'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2022133074,
          'name': 'Expressive power'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049810721,
          'name': 'Uniform distribution (continuous)'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 5,
      'reference_count': 25
    },
    {
      'id': 302857390,
      'title': 'Single Shot Text Detector with Regional Attention',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2100931896,
          'abbr': 'UF',
          'name': 'University of Florida'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102105050,
          'abbr': 'PNNL',
          'name': 'Pacific Northwest National Laboratory'
        }
      ],
      'author': [
        {
          'id': 1107548307,
          'name': 'Pan He'
        },
        {
          'id': 1173392213,
          'name': 'Weilin Huang'
        },
        {
          'id': 1451904479,
          'name': 'Tong He'
        },
        {
          'id': 1126327031,
          'name': 'Qile Zhu'
        },
        {
          'id': 1052584728,
          'name': 'Yu Qiao'
        },
        {
          'id': 1204148983,
          'name': 'Xiaolin Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2003914179,
          'name': 'Interference (wave propagation)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1709.html',
      'citation_count': 9,
      'reference_count': 36
    },
    {
      'id': 305280516,
      'title': 'CAD: Scale Invariant Framework for Real-Time Object Detection',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1074381803,
          'name': 'Huajun Zhou'
        },
        {
          'id': 1365593525,
          'name': 'Zechao Li'
        },
        {
          'id': 1142714386,
          'name': 'Chengcheng Ning'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2012362224,
          'name': 'Scale invariance'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 22
    },
    {
      'id': 309311816,
      'title': 'Learning Discriminative Data Fitting Functions for Blind Image Deblurring',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1118840716,
          'name': 'Jiangxin Dong'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2018516098,
          'name': 'Curve fitting'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 37
    },
    {
      'id': 311258320,
      'title': 'Centered Weight Normalization in Accelerating Training of Deep Neural Networks',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1300509348,
          'name': 'Lei Huang'
        },
        {
          'id': 1025575364,
          'name': 'Xianglong Liu'
        },
        {
          'id': 1073563786,
          'name': 'Yang Liu'
        },
        {
          'id': 1031446264,
          'name': 'Bo Lang'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2034891279,
          'name': 'Curvature'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 34
    },
    {
      'id': 311903556,
      'title': 'SurfaceNet: An End-to-End 3D Neural Network for Multiview Stereopsis',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2100789236,
          'abbr': '',
          'name': 'University of Bonn'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1177700671,
          'name': 'Mengqi Ji'
        },
        {
          'id': 1056716200,
          'name': 'Juergen Gall'
        },
        {
          'id': 1029876462,
          'name': 'Haitian Zheng'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2023853581,
          'name': 'Stereopsis'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2037875506,
          'name': 'Voxel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 29
    },
    {
      'id': 312195297,
      'title': 'Learning Feature Pyramids for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1375862671,
          'name': 'Wei Yang'
        },
        {
          'id': 1015191394,
          'name': 'Shuang Li'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 89,
      'reference_count': 63
    },
    {
      'id': 314138202,
      'title': 'VQS: Linking Segmentations to Questions and Answers for Supervised Attention in VQA and Question-Focused Semantic Segmentation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104085410,
          'abbr': 'SEU',
          'name': 'Southeast University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1402064840,
          'name': 'Yandong Li'
        },
        {
          'id': 1253889201,
          'name': 'Haoxiang Li'
        },
        {
          'id': 1410350428,
          'name': 'Chen Sun'
        },
        {
          'id': 1347218119,
          'name': 'Boqing Gong'
        }
      ],
      'field': [
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2012231735,
          'name': 'Oracle'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 3,
      'reference_count': 49
    },
    {
      'id': 314969991,
      'title': 'Single Image Action Recognition Using Semantic Body Composition Actions',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        }
      ],
      'author': [
        {
          'id': 1274336316,
          'name': 'Zhichen Zhao'
        },
        {
          'id': 1144842077,
          'name': 'Huimin Ma'
        },
        {
          'id': 1444358315,
          'name': 'Shaodi You'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036110403,
          'name': 'Action semantics'
        },
        {
          'id': 2027547384,
          'name': 'Existential quantification'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 31
    },
    {
      'id': 319136505,
      'title': 'Multimodal Gaussian Process Latent Variable Models with Harmonization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1272080636,
          'name': 'Guoli Song'
        },
        {
          'id': 1265461014,
          'name': 'Shuhui Wang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035842457,
          'name': 'Gaussian process'
        },
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2011150678,
          'name': 'Multimodal learning'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2041190030,
          'name': 'Hyperparameter'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 28
    },
    {
      'id': 320556634,
      'title': 'Generalized Orderless Pooling Performs Implicit Salient Matching',
      'affiliation': [
        {
          'id': 2104828842,
          'abbr': 'FSU',
          'name': 'University of Jena'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100035214,
          'abbr': 'LBNL',
          'name': 'Lawrence Berkeley National Laboratory'
        }
      ],
      'author': [
        {
          'id': 1251317784,
          'name': 'Marcel Simon'
        },
        {
          'id': 1400133953,
          'name': 'Yang Gao'
        },
        {
          'id': 1007869146,
          'name': 'Trevor Darrell'
        },
        {
          'id': 1175118433,
          'name': 'Joachim Denzler'
        },
        {
          'id': 1245760348,
          'name': 'Erik Rodner'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1705.html',
      'citation_count': 0,
      'reference_count': 35
    },
    {
      'id': 321500688,
      'title': 'Video Deblurring via Semantic Segmentation and Pixel-Wise Non-linear Kernel',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1224361079,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1114642236,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 9,
      'reference_count': 44
    },
    {
      'id': 32446545,
      'title': 'Focusing Attention: Towards Accurate Text Recognition in Natural Images',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1046738235,
          'name': 'Zhanzhan Cheng'
        },
        {
          'id': 1351963402,
          'name': 'Fan Bai'
        },
        {
          'id': 1145095773,
          'name': 'Yunlu Xu'
        },
        {
          'id': 1001052940,
          'name': 'Gang Zheng'
        },
        {
          'id': 1384883042,
          'name': 'Shiliang Pu'
        },
        {
          'id': 1494078016,
          'name': 'Shuigeng Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001020821,
          'name': 'Focusing attention'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 35,
      'reference_count': 32
    },
    {
      'id': 324642613,
      'title': 'Flow-Guided Feature Aggregation for Video Object Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1368545847,
          'name': 'Xizhou Zhu'
        },
        {
          'id': 1016108510,
          'name': 'Yujie Wang'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2032713836,
          'name': 'Flow (psychology)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1703.html',
      'citation_count': 14,
      'reference_count': 48
    },
    {
      'id': 324759146,
      'title': 'SegFlow: Joint Learning for Video Object Segmentation and Optical Flow',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1127761433,
          'name': 'Jingchun Cheng'
        },
        {
          'id': 1138046264,
          'name': 'Yi-Hsuan Tsai'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008010037,
          'name': 'Versa'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2030514377,
          'name': 'Flownet'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 62,
      'reference_count': 47
    },
    {
      'id': 32507077,
      'title': 'Jointly Attentive Spatial-Temporal Pooling Networks for Video-Based Person Re-identification',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1419465552,
          'name': 'Shuangjie Xu'
        },
        {
          'id': 1464066788,
          'name': 'Yu Cheng'
        },
        {
          'id': 1073099256,
          'name': 'Kang Gu'
        },
        {
          'id': 1162758188,
          'name': 'Yang Yang'
        },
        {
          'id': 1102368127,
          'name': 'Shiyu Chang'
        },
        {
          'id': 1467586349,
          'name': 'Pan Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 13,
      'reference_count': 35
    },
    {
      'id': 327887414,
      'title': 'Surface Registration via Foliation',
      'affiliation': [
        {
          'id': 2103998608,
          'abbr': 'SBU',
          'name': 'Stony Brook University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1409640222,
          'name': 'Xiaopeng Zheng'
        },
        {
          'id': 1215763994,
          'name': 'Chengfeng Wen'
        },
        {
          'id': 1010473345,
          'name': 'Na Lei'
        },
        {
          'id': 1266702858,
          'name': 'Ming Ma'
        },
        {
          'id': 1023103330,
          'name': 'Xianfeng Gu'
        }
      ],
      'field': [
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042676039,
          'name': 'Foliation (geology)'
        },
        {
          'id': 2026817921,
          'name': 'Network topology'
        },
        {
          'id': 2040215507,
          'name': 'Homeomorphism'
        },
        {
          'id': 2040170460,
          'name': 'Harmonic analysis'
        },
        {
          'id': 2009364628,
          'name': 'Tensor product'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2040948050,
          'name': 'Diffeomorphism'
        },
        {
          'id': 2026937781,
          'name': 'Topology'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 52
    },
    {
      'id': 329709197,
      'title': 'Recursive Spatial Transformer (ReST) for Alignment-Free Face Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1217421085,
          'name': 'Wanglong Wu'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1181479300,
          'name': 'Xin Liu'
        },
        {
          'id': 1010861762,
          'name': 'Yi Yang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2012198305,
          'name': 'Transformer'
        },
        {
          'id': 2016988064,
          'name': 'Large face'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 23
    },
    {
      'id': 331953301,
      'title': 'Deep Metric Learning with Angular Loss',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        }
      ],
      'author': [
        {
          'id': 1016126685,
          'name': 'Jian Wang'
        },
        {
          'id': 1266409900,
          'name': 'Feng Zhou'
        },
        {
          'id': 1256014321,
          'name': 'Shilei Wen'
        },
        {
          'id': 1056587154,
          'name': 'Xiao Liu'
        },
        {
          'id': 1130467706,
          'name': 'Yuanqing Lin'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2012362224,
          'name': 'Scale invariance'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032401552,
          'name': 'Abstraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 7,
      'reference_count': 36
    },
    {
      'id': 332621061,
      'title': 'Deep Adaptive Image Clustering',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1385671313,
          'name': 'Jianlong Chang'
        },
        {
          'id': 1167945012,
          'name': 'Lingfeng Wang'
        },
        {
          'id': 1296467528,
          'name': 'Gaofeng Meng'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1114885725,
          'name': 'Chunhong Pan'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2024565588,
          'name': 'Cosine Distance'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2023949645,
          'name': 'Adaptive learning'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 33
    },
    {
      'id': 334205691,
      'title': 'Results and Analysis of ChaLearn LAP Multi-modal Isolated and Continuous Gesture Recognition, and Real Versus Fake Expressed Emotions Challenges',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102976531,
          'abbr': 'UB',
          'name': 'University of Barcelona'
        },
        {
          'id': 2104588619,
          'abbr': 'UT',
          'name': 'University of Tartu'
        },
        {
          'id': 2103895481,
          'abbr': 'INAOE',
          'name': 'National Institute of Astrophysics, Optics and Electronics'
        },
        {
          'id': 2102662180,
          'abbr': 'UOC',
          'name': 'Open University of Catalonia'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1119293493,
          'name': 'Jun Wan'
        },
        {
          'id': 1011188708,
          'name': 'Sergio Escalera'
        },
        {
          'id': 1499717935,
          'name': 'Gholamreza Anbarjafari'
        },
        {
          'id': 1012327536,
          'name': 'Hugo Jair Escalante'
        },
        {
          'id': 1191330645,
          'name': 'Xavier Baró'
        },
        {
          'id': 1146896407,
          'name': 'Isabelle Guyon'
        },
        {
          'id': 1362012934,
          'name': 'Meysam Madadi'
        },
        {
          'id': 1365515457,
          'name': 'Jüri Allik'
        },
        {
          'id': 1190212564,
          'name': 'Jelena Gorbova'
        },
        {
          'id': 1066765949,
          'name': 'Chi Lin'
        },
        {
          'id': 1154172080,
          'name': 'Yiliang Xie'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031276404,
          'name': 'Jaccard index'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000503217,
          'name': 'Emotion recognition'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2016955869,
          'name': 'Expressed emotion'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 25
    },
    {
      'id': 335709932,
      'title': 'WordSup: Exploiting Word Annotations for Character Based Text Detection',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1023273385,
          'name': 'Chengquan Zhang'
        },
        {
          'id': 1015327411,
          'name': 'Yuxuan Luo'
        },
        {
          'id': 1459924665,
          'name': 'Yuzhuo Wang'
        },
        {
          'id': 1245474880,
          'name': 'Junyu Han'
        },
        {
          'id': 1109540728,
          'name': 'Errui Ding'
        }
      ],
      'field': [
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 9,
      'reference_count': 50
    },
    {
      'id': 337587180,
      'title': 'YOLSE: Egocentric Fingertip Detection from Single RGB Images',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1061481405,
          'name': 'Wenbin Wu'
        },
        {
          'id': 1219347127,
          'name': 'Chenyang Li'
        },
        {
          'id': 1200334603,
          'name': 'Zhuo Cheng'
        },
        {
          'id': 1366371321,
          'name': 'Xin Zhang'
        },
        {
          'id': 1276589951,
          'name': 'Lianwen Jin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2041467508,
          'name': 'Augmented reality'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000619510,
          'name': 'Wearable computer'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 13
    },
    {
      'id': 341060393,
      'title': 'Is Second-Order Information Helpful for Large-Scale Visual Recognition?',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1190238743,
          'name': 'Jiangtao Xie'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034304287,
          'name': 'Estimation of covariance matrices'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2010230189,
          'name': 'Covariance'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 36
    },
    {
      'id': 343058464,
      'title': 'Manifold Constrained Low-Rank Decomposition',
      'affiliation': [
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101433684,
          'abbr': 'IIT',
          'name': 'Istituto Italiano di Tecnologia'
        }
      ],
      'author': [
        {
          'id': 1081239144,
          'name': 'Chen Chen'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1308131702,
          'name': 'Alessio Del Bue'
        },
        {
          'id': 1344208569,
          'name': 'Vittorio Murino'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 3
    },
    {
      'id': 343864546,
      'title': 'Range Loss for Deep Face Recognition with Long-Tailed Training Data',
      'affiliation': [
        {
          'id': 2104749482,
          'abbr': 'SUBR',
          'name': 'Southern University and A&M College'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1001120881,
          'name': 'Xiao Zhang'
        },
        {
          'id': 1017462424,
          'name': 'Zhiyuan Fang'
        },
        {
          'id': 1369857672,
          'name': 'Yandong Wen'
        },
        {
          'id': 1346919780,
          'name': 'Zhifeng Li'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2016512757,
          'name': 'Intrapersonal communication'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 30
    },
    {
      'id': 351523864,
      'title': 'R-C3D: Region Convolutional 3D Network for Temporal Activity Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104904263,
          'abbr': 'UCR',
          'name': 'University of California, Riverside'
        },
        {
          'id': 2103091764,
          'abbr': 'BU',
          'name': 'Boston University'
        }
      ],
      'author': [
        {
          'id': 1026132491,
          'name': 'Huijuan Xu'
        },
        {
          'id': 1342572708,
          'name': 'Abir Das'
        },
        {
          'id': 1117109347,
          'name': 'Kate Saenko'
        }
      ],
      'field': [
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2016538099,
          'name': 'Temporal Regions'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017318404,
          'name': 'Frame rate'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2049876710,
          'name': 'General activity'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 84,
      'reference_count': 35
    },
    {
      'id': 352991007,
      'title': 'Learning to Super-Resolve Blurry Face and Text Images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101712668,
          'abbr': 'HARVARD',
          'name': 'Harvard University'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1411899365,
          'name': 'Xiangyu Xu'
        },
        {
          'id': 1330695574,
          'name': 'Deqing Sun'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1289921224,
          'name': 'Yu-Jin Zhang'
        },
        {
          'id': 1449629447,
          'name': 'Hanspeter Pfister'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 52
    },
    {
      'id': 357779114,
      'title': 'When Unsupervised Domain Adaptation Meets Tensor Representations',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1257968729,
          'name': 'Hao Lu'
        },
        {
          'id': 1386051449,
          'name': 'Lei Zhang'
        },
        {
          'id': 1216320010,
          'name': 'Zhiguo Cao'
        },
        {
          'id': 1444713601,
          'name': 'Wei Wei'
        },
        {
          'id': 1131102328,
          'name': 'Ke Xian'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2029579503,
          'name': 'Vectorization (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 3,
      'reference_count': 40
    },
    {
      'id': 359416196,
      'title': 'Structured Attentions for Visual Question Answering',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1032991592,
          'name': 'Chen Zhu'
        },
        {
          'id': 1353743105,
          'name': 'Yanpeng Zhao'
        },
        {
          'id': 1355638921,
          'name': 'Shuaiyi Huang'
        },
        {
          'id': 1024583554,
          'name': 'Kewei Tu'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039774280,
          'name': 'Approximation algorithm'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2013787702,
          'name': 'Belief propagation'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2047824847,
          'name': 'Random field'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2037798143,
          'name': 'Mean field theory'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 43
    },
    {
      'id': 366890872,
      'title': 'Online Video Object Detection Using Association LSTM',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1391997077,
          'name': 'Yongyi Lu'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        },
        {
          'id': 1212626136,
          'name': 'Chi-Keung Tang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 15
    },
    {
      'id': 374912380,
      'title': 'Deep Cropping via Attention Box Prediction and Aesthetics Assessment',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2032750659,
          'name': 'Cropping'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2037388878,
          'name': 'Aesthetics'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1710.html',
      'citation_count': 6,
      'reference_count': 46
    },
    {
      'id': 374951031,
      'title': 'Detailed Surface Geometry and Albedo Recovery from RGB-D Video under Natural Illumination',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        }
      ],
      'author': [
        {
          'id': 1402137958,
          'name': 'Xinxin Zuo'
        },
        {
          'id': 1066945238,
          'name': 'Sen Wang'
        },
        {
          'id': 1348130687,
          'name': 'Jiangbin Zheng'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002018009,
          'name': 'Normal'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036141665,
          'name': 'Photometric stereo'
        },
        {
          'id': 2003450942,
          'name': 'Image-based lighting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022543674,
          'name': 'Albedo'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 44
    },
    {
      'id': 375201034,
      'title': 'Exploiting Multi-grain Ranking Constraints for Precisely Searching Visually-similar Vehicles',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1013066198,
          'name': 'Ke Yan'
        },
        {
          'id': 1484177249,
          'name': 'Yonghong Tian'
        },
        {
          'id': 1306980273,
          'name': 'Yaowei Wang'
        },
        {
          'id': 1487211458,
          'name': 'Wei Zeng'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2015797049,
          'name': 'List ranking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026372859,
          'name': 'Permutation'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2004650574,
          'name': 'Vehicle search'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 32
    },
    {
      'id': 3768015,
      'title': 'Learning to Detect Fine-Grained Change Under Variant Imaging Conditions',
      'affiliation': [
        {
          'id': 2100990439,
          'abbr': 'SACH',
          'name': 'State Administration of Cultural Heritage'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1121407280,
          'name': 'Rui Huang'
        },
        {
          'id': 1096901309,
          'name': 'Wei Feng'
        },
        {
          'id': 1417888643,
          'name': 'Zezheng Wang'
        },
        {
          'id': 1113889703,
          'name': 'Mingyuan Fan'
        },
        {
          'id': 1119606354,
          'name': 'Liang Wan'
        },
        {
          'id': 1241260093,
          'name': 'Jizhou Sun'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2046466254,
          'name': 'Adaptive optics'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2011282698,
          'name': 'Change detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038996624,
          'name': 'Network model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 27
    },
    {
      'id': 377901884,
      'title': 'S^3FD: Single Shot Scale-Invariant Face Detector',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1108322389,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1477164535,
          'name': 'Hailin Shi'
        },
        {
          'id': 1080319340,
          'name': 'Xiaobo Wang'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2045640913,
          'name': 'False positive rate'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2012362224,
          'name': 'Scale invariance'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 68,
      'reference_count': 64
    },
    {
      'id': 379176350,
      'title': 'Deep Clustering via Joint Convolutional Autoencoder Embedding and Relative Entropy Minimization',
      'affiliation': [
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2102262321,
          'abbr': 'UTA',
          'name': 'University of Texas at Arlington'
        }
      ],
      'author': [
        {
          'id': 1327953326,
          'name': 'Kamran Ghasedi Dizaji'
        },
        {
          'id': 1454614176,
          'name': 'Amirhossein Herandi'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1210919005,
          'name': 'Weidong Cai'
        },
        {
          'id': 1282955999,
          'name': 'Heng Huang'
        }
      ],
      'field': [
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2014337830,
          'name': 'Fuzzy clustering'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031194188,
          'name': 'Constrained clustering'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001604607,
          'name': 'Canopy clustering algorithm'
        },
        {
          'id': 2035951257,
          'name': 'CURE data clustering algorithm'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 54
    },
    {
      'id': 383529989,
      'title': 'Learning Long-Term Dependencies for Action Recognition with a Biologically-Inspired Deep Network',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1160459049,
          'name': 'Yemin Shi'
        },
        {
          'id': 1484177249,
          'name': 'Yonghong Tian'
        },
        {
          'id': 1227320672,
          'name': 'Yaowei Wang'
        },
        {
          'id': 1005350796,
          'name': 'Wang Zeng'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        }
      ],
      'field': [
        {
          'id': 2026328187,
          'name': 'Feedforward neural network'
        },
        {
          'id': 2038401195,
          'name': 'Sequence learning'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2036252551,
          'name': 'Information flow (information theory)'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2032550953,
          'name': 'Feed forward'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://www.semanticscholar.org/paper/Learning-Long-Term-Dependencies-for-Action-with-a-Shi-Tian/6de18708218988b0558f6c2f27050bb4659155e4',
      'citation_count': 4,
      'reference_count': 42
    },
    {
      'id': 384019862,
      'title': 'The Visual Object Tracking VOT2017 Challenge Results',
      'affiliation': [
        {
          'id': 2100668856,
          'abbr': 'UL',
          'name': 'University of Ljubljana'
        },
        {
          'id': 2103081213,
          'abbr': 'BIRMINGHAM',
          'name': 'University of Birmingham'
        },
        {
          'id': 2101688584,
          'abbr': 'ČVUT',
          'name': 'Czech Technical University in Prague'
        },
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        },
        {
          'id': 2104173829,
          'abbr': 'AIT',
          'name': 'Austrian Institute of Technology'
        },
        {
          'id': 2101260139,
          'abbr': 'UAM',
          'name': 'Autonomous University of Madrid'
        },
        {
          'id': 2103804960,
          'abbr': 'UNIPARTHENOPE',
          'name': 'Parthenope University of Naples'
        },
        {
          'id': 2100320857,
          'abbr': 'UI',
          'name': 'University of Isfahan'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2104227910,
          'abbr': 'AUP',
          'name': 'University of Paris'
        },
        {
          'id': 2100755990,
          'abbr': 'METU',
          'name': 'Middle East Technical University'
        },
        {
          'id': 2103747449,
          'abbr': 'UPB',
          'name': 'Politehnica University of Bucharest'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100153474,
          'abbr': 'IIST',
          'name': 'Indian Institute of Space Science and Technology'
        },
        {
          'id': 2103355548,
          'abbr': 'ASELSAN',
          'name': 'ASELSAN'
        },
        {
          'id': 2101115422,
          'abbr': 'UNIFEI',
          'name': 'Universidade Federal de Itajubá'
        },
        {
          'id': 2101083496,
          'abbr': 'AFRL',
          'name': 'Air Force Research Laboratory'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100369085,
          'abbr': 'ETRI',
          'name': 'Electronics and Telecommunications Research Institute'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100495785,
          'abbr': 'MU',
          'name': 'University of Missouri'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        },
        {
          'id': 2103786024,
          'abbr': 'NIH',
          'name': 'National Institutes of Health'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2101332350,
          'abbr': 'IMT',
          'name': 'Institut Mines-Télécom'
        },
        {
          'id': 2100548198,
          'abbr': 'SUNY',
          'name': 'State University of New York System'
        },
        {
          'id': 2104875451,
          'abbr': 'KAIST',
          'name': 'KAIST'
        },
        {
          'id': 2100615659,
          'abbr': 'UNINA',
          'name': 'University of Naples Federico II'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        }
      ],
      'author': [
        {
          'id': 1107745972,
          'name': 'Matej Kristan'
        },
        {
          'id': 1134240655,
          'name': 'Aleš Leonardis'
        },
        {
          'id': 1431263697,
          'name': 'Jiri Matas'
        },
        {
          'id': 1075568432,
          'name': 'Michael Felsberg'
        },
        {
          'id': 1047308075,
          'name': 'Roman P. Pflugfelder'
        },
        {
          'id': 1078212722,
          'name': 'Luka Cehovin Zajc'
        },
        {
          'id': 1294574734,
          'name': 'Tomas Vojir'
        },
        {
          'id': 1337879504,
          'name': 'Gustav Häger'
        },
        {
          'id': 1054534655,
          'name': 'Alan Lukezic'
        },
        {
          'id': 1399367589,
          'name': 'Abdelrahman Eldesokey'
        },
        {
          'id': 1386039614,
          'name': 'Gustavo Fernández'
        },
        {
          'id': 1087633150,
          'name': 'Álvaro García-Martín'
        },
        {
          'id': 1333607900,
          'name': 'Andrej Muhič'
        },
        {
          'id': 1348199713,
          'name': 'Alfredo Petrosino'
        },
        {
          'id': 1260129741,
          'name': 'Alireza Memarmoghadam'
        },
        {
          'id': 1410113093,
          'name': 'Andrea Vedaldi'
        },
        {
          'id': 1030830813,
          'name': 'Antoine Manzanera'
        },
        {
          'id': 1417513482,
          'name': 'Antoine Tran'
        },
        {
          'id': 1018629502,
          'name': 'A. Aydin Alatan'
        },
        {
          'id': 1183386705,
          'name': 'Bogdan Mocanu'
        },
        {
          'id': 1245807374,
          'name': 'Boyu Chen'
        },
        {
          'id': 1300040491,
          'name': 'Chang Huang'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1319563912,
          'name': 'Dalong Du'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        },
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1400633902,
          'name': 'Deepak Mishra'
        },
        {
          'id': 1162202504,
          'name': 'Erhan Gundogdu'
        },
        {
          'id': 1109118722,
          'name': 'Erik Velasco-Salido'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1057066870,
          'name': 'Francesco Battistone'
        },
        {
          'id': 1189925693,
          'name': 'Gorthi R. K. Sai Subrahmanyam'
        },
        {
          'id': 1157646809,
          'name': 'Goutam Bhat'
        },
        {
          'id': 1168372245,
          'name': 'Guan Huang'
        },
        {
          'id': 1259959200,
          'name': 'Guilherme Sousa Bastos'
        },
        {
          'id': 1116826313,
          'name': 'Guna Seetharaman'
        },
        {
          'id': 1130494684,
          'name': 'Hongliang Zhang'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1400605048,
          'name': 'Isabela Drummond'
        },
        {
          'id': 1481506405,
          'name': 'Jack Valmadre'
        },
        {
          'id': 1061267541,
          'name': 'Jae-chan Jeong'
        },
        {
          'id': 1210431912,
          'name': 'Jaeil Cho'
        },
        {
          'id': 1442966360,
          'name': 'Jae-Yeong Lee'
        },
        {
          'id': 1464142665,
          'name': 'Jana Nosková'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1436742442,
          'name': 'Jin Gao'
        },
        {
          'id': 1012002231,
          'name': 'Jingyu Liu'
        },
        {
          'id': 1077169369,
          'name': 'Ji-Wan Kim'
        },
        {
          'id': 1088729312,
          'name': 'João F. Henriques'
        },
        {
          'id': 1290279024,
          'name': 'José M. Martínez'
        },
        {
          'id': 1264459532,
          'name': 'Junfei Zhuang'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1115592857,
          'name': 'Junyu Gao'
        },
        {
          'id': 1010883650,
          'name': 'Kai Chen'
        },
        {
          'id': 1230553618,
          'name': 'Kannappan Palaniappan'
        },
        {
          'id': 1113938605,
          'name': 'Karel Lebeda'
        },
        {
          'id': 1180185113,
          'name': 'Ke Gao'
        },
        {
          'id': 1474780883,
          'name': 'Kris M. Kitani'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        },
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1454505709,
          'name': 'Lingxiao Yang'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1200036889,
          'name': 'Luca Bertinetto'
        },
        {
          'id': 1263871855,
          'name': 'Mahdieh Poostchi'
        },
        {
          'id': 1425828879,
          'name': 'Martin Danelljan'
        },
        {
          'id': 1009388770,
          'name': 'Matthias Mueller'
        },
        {
          'id': 1130181595,
          'name': 'Mengdan Zhang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1060145613,
          'name': 'Nianhao Xie'
        },
        {
          'id': 1046619653,
          'name': 'Ning Wang'
        },
        {
          'id': 1066019707,
          'name': 'Ondrej Miksik'
        },
        {
          'id': 1320442640,
          'name': 'Payman Moallem'
        },
        {
          'id': 1056522621,
          'name': 'Pallavi Venugopal M'
        },
        {
          'id': 1276217973,
          'name': 'Pedro Senna'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        },
        {
          'id': 1001343316,
          'name': 'Qiang Wang'
        },
        {
          'id': 1309872154,
          'name': 'Qifeng Yu'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1075689828,
          'name': 'Rafael Martín-Nieto'
        },
        {
          'id': 1250623814,
          'name': 'Richard Bowden'
        },
        {
          'id': 1451131392,
          'name': 'Risheng Liu'
        },
        {
          'id': 1078624913,
          'name': 'Ruxandra Tapu'
        },
        {
          'id': 1338477300,
          'name': 'Simon Hadfield'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        },
        {
          'id': 1358016841,
          'name': 'Stuart Golodetz'
        },
        {
          'id': 1488349066,
          'name': 'Sunglok Choi'
        },
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1206932292,
          'name': 'Titus B. Zaharia'
        },
        {
          'id': 1051836821,
          'name': 'Vincenzo Santopietro'
        },
        {
          'id': 1268828894,
          'name': 'Wei Zou'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1075292303,
          'name': 'Wenbing Tao'
        },
        {
          'id': 1167205632,
          'name': 'Wenbo Li'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1444398649,
          'name': 'Xianguo Yu'
        },
        {
          'id': 1484089631,
          'name': 'Xiao Bian'
        },
        {
          'id': 1372846994,
          'name': 'Yang Li'
        },
        {
          'id': 1134585973,
          'name': 'Yifan Xing'
        },
        {
          'id': 1069952638,
          'name': 'Yingruo Fan'
        },
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1117963774,
          'name': 'Zhipeng Zhang'
        },
        {
          'id': 1144696453,
          'name': 'Zhiqun He'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://openaccess.thecvf.com/content_ICCV_2017_workshops/papers/w28/Kristan_The_Visual_Object_ICCV_2017_paper.pdf',
      'citation_count': 77,
      'reference_count': 0
    },
    {
      'id': 384636617,
      'title': 'A Two-Streamed Network for Estimating Fine-Scaled Depth Maps from Single RGB Images',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100789236,
          'abbr': '',
          'name': 'University of Bonn'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1462352723,
          'name': 'Jun Li'
        },
        {
          'id': 1418018467,
          'name': 'Reinhard Klein'
        },
        {
          'id': 1077867796,
          'name': 'Angela Yao'
        }
      ],
      'field': [
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/conf/iccv/iccv2017.html',
      'citation_count': 14,
      'reference_count': 29
    },
    {
      'id': 389696432,
      'title': 'Recurrent Scale Approximation for Object Detection in CNN',
      'affiliation': [
        {
          'id': 2100086938,
          'abbr': 'LEIDENUNIV',
          'name': 'Leiden University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1058037770,
          'name': 'Yu Liu'
        },
        {
          'id': 1282242824,
          'name': 'Hongyang Li'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1165172349,
          'name': 'Fangyin Wei'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 39
    },
    {
      'id': 389708701,
      'title': 'Multi-label Image Recognition by Recurrently Discovering Attentional Regions',
      'affiliation': [
        {
          'id': 2100988683,
          'abbr': 'SENSETIME',
          'name': 'SenseTime'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1248531455,
          'name': 'Zhouxia Wang'
        },
        {
          'id': 1298778203,
          'name': 'Tianshui Chen'
        },
        {
          'id': 1316453658,
          'name': 'Guanbin Li'
        },
        {
          'id': 1173893498,
          'name': 'Ruijia Xu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2012198305,
          'name': 'Transformer'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1711.02816.pdf',
      'citation_count': 16,
      'reference_count': 31
    },
    {
      'id': 390067076,
      'title': 'Soft Proposal Networks for Weakly Supervised Object Localization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1259889084,
          'name': 'Yi Zhu'
        },
        {
          'id': 1238435354,
          'name': 'Yanzhao Zhou'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1246182727,
          'name': 'Qiang Qiu'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003914179,
          'name': 'Interference (wave propagation)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1709.html',
      'citation_count': 8,
      'reference_count': 37
    },
    {
      'id': 391436007,
      'title': 'Domain-Adaptive Deep Network Compression',
      'affiliation': [
        {
          'id': 2100853613,
          'abbr': 'UAB',
          'name': 'Autonomous University of Barcelona'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        }
      ],
      'author': [
        {
          'id': 1027004566,
          'name': 'Marc Masana'
        },
        {
          'id': 1479818478,
          'name': 'Joost van de Weijer'
        },
        {
          'id': 1230607955,
          'name': 'Luis Herranz'
        },
        {
          'id': 1147701988,
          'name': 'Andrew D. Bagdanov'
        },
        {
          'id': 1120591348,
          'name': 'Jose M. Alvarez'
        }
      ],
      'field': [
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1709.html',
      'citation_count': 3,
      'reference_count': 30
    },
    {
      'id': 391676880,
      'title': 'Towards 3D Human Pose Estimation in the Wild: A Weakly-Supervised Approach',
      'affiliation': [
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1334636219,
          'name': 'Xingyi Zhou'
        },
        {
          'id': 1022255500,
          'name': 'Qixing Huang'
        },
        {
          'id': 1472779654,
          'name': 'Xiao Sun'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2009338504,
          'name': 'Neutral network'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 124,
      'reference_count': 36
    },
    {
      'id': 394281424,
      'title': 'Temporal Action Detection with Structured Segment Networks',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100606672,
          'abbr': 'UCB',
          'name': 'University of California, Berkeley'
        }
      ],
      'author': [
        {
          'id': 1162296009,
          'name': 'Yue Zhao'
        },
        {
          'id': 1422062208,
          'name': 'Yuanjun Xiong'
        },
        {
          'id': 1242240210,
          'name': 'Limin Wang'
        },
        {
          'id': 1028582823,
          'name': 'Zhirong Wu'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036616100,
          'name': 'Completeness (statistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 94,
      'reference_count': 58
    },
    {
      'id': 400091398,
      'title': 'Combining Exemplar-Based Approach and learning-Based Approach for Light Field Super-Resolution Using a Hybrid Imaging System',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1029876462,
          'name': 'Haitian Zheng'
        },
        {
          'id': 1246078217,
          'name': 'Minghao Guo'
        },
        {
          'id': 1098536622,
          'name': 'Haoqian Wang'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2031294066,
          'name': 'Parallax'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2021507899,
          'name': 'Light-field camera'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 14
    },
    {
      'id': 400459246,
      'title': 'Catadioptric HyperSpectral Light Field Imaging',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        }
      ],
      'author': [
        {
          'id': 1371268518,
          'name': 'Yujia Xue'
        },
        {
          'id': 1189355341,
          'name': 'Kang Zhu'
        },
        {
          'id': 1389292402,
          'name': 'Qiang Fu'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2009659264,
          'name': 'Catadioptric system'
        },
        {
          'id': 2027716576,
          'name': 'Spectral resolution'
        },
        {
          'id': 2034045612,
          'name': 'Radiance'
        },
        {
          'id': 2011808093,
          'name': 'Wavelength'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2021507899,
          'name': 'Light-field camera'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 26
    },
    {
      'id': 402288221,
      'title': 'Deep Direct Regression for Multi-oriented Scene Text Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1200521068,
          'name': 'Wenhao He'
        },
        {
          'id': 1279838029,
          'name': 'Xu-Yao Zhang'
        },
        {
          'id': 1382350923,
          'name': 'Fei Yin'
        },
        {
          'id': 1429067054,
          'name': 'Cheng-Lin Liu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2028120887,
          'name': 'Quadrilateral'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 22
    },
    {
      'id': 402297851,
      'title': 'SegFlow: Joint Learning for Video Object Segmentation and Optical Flow',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1127761433,
          'name': 'Jingchun Cheng'
        },
        {
          'id': 1138046264,
          'name': 'Yi-Hsuan Tsai'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1114642236,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030514377,
          'name': 'Flownet'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008010037,
          'name': 'Versa'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1709.html',
      'citation_count': 25,
      'reference_count': 46
    },
    {
      'id': 402486633,
      'title': 'Video Deblurring via Semantic Segmentation and Pixel-Wise Non-linear Kernel',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1224361079,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 44
    },
    {
      'id': 404019,
      'title': 'RGB-Infrared Cross-Modality Person Re-identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1046260562,
          'name': 'Hong-Xing Yu'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2048286749,
          'name': 'Infrared'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 0
    },
    {
      'id': 404861250,
      'title': 'RSRN: Rich Side-Output Residual Network for Medial Axis Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1347623422,
          'name': 'Chang Liu'
        },
        {
          'id': 1030826032,
          'name': 'Wei Ke'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2030655461,
          'name': 'Medial axis'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 8
    },
    {
      'id': 405256077,
      'title': 'Fusing Image and Segmentation Cues for Skeleton Extraction in the Wild',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1002478664,
          'name': 'Xiaolong Liu'
        },
        {
          'id': 1433542305,
          'name': 'Pengyuan Lyu'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        }
      ],
      'field': [
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025459996,
          'name': 'Decoding methods'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 24
    },
    {
      'id': 406001210,
      'title': 'A Stagewise Refinement Model for Detecting Salient Objects in Images',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1176287567,
          'name': 'Tiantian Wang'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1404440350,
          'name': 'Pingping Zhang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026328187,
          'name': 'Feedforward neural network'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 49
    },
    {
      'id': 410632859,
      'title': 'Surface Normals in the Wild',
      'affiliation': [
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102243180,
          'abbr': 'RI',
          'name': 'Robotics Institute'
        }
      ],
      'author': [
        {
          'id': 1053919999,
          'name': 'Weifeng Chen'
        },
        {
          'id': 1489647727,
          'name': 'Donglai Xiang'
        },
        {
          'id': 1389718805,
          'name': 'Jia Deng'
        }
      ],
      'field': [
        {
          'id': 2002018009,
          'name': 'Normal'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 31
    },
    {
      'id': 411202681,
      'title': 'Learning Uncertain Convolutional Features for Accurate Saliency Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1404440350,
          'name': 'Pingping Zhang'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1055501293,
          'name': 'Hongyu Wang'
        },
        {
          'id': 1310020299,
          'name': 'Baocai Yin'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        },
        {
          'id': 2038996624,
          'name': 'Network model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 48
    },
    {
      'id': 418363534,
      'title': 'Delving into Salient Object Subitizing and Detection',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1301454973,
          'name': 'Shengfeng He'
        },
        {
          'id': 1198093426,
          'name': 'Jianbo Jiao'
        },
        {
          'id': 1357125020,
          'name': 'Xiaodan Zhang'
        },
        {
          'id': 1402700358,
          'name': 'Guoqiang Han'
        },
        {
          'id': 1386718479,
          'name': 'Rynson W. H. Lau'
        }
      ],
      'field': [
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024878639,
          'name': 'Subitizing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008584860,
          'name': 'Human visual system model'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 38
    },
    {
      'id': 418687029,
      'title': 'Learning Dynamic Siamese Network for Visual Object Tracking',
      'affiliation': [
        {
          'id': 2100990439,
          'abbr': 'SACH',
          'name': 'State Administration of Cultural Heritage'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102246611,
          'abbr': 'USC',
          'name': 'University of South Carolina'
        }
      ],
      'author': [
        {
          'id': 1432647926,
          'name': 'Qing Guo'
        },
        {
          'id': 1096901309,
          'name': 'Wei Feng'
        },
        {
          'id': 1091776854,
          'name': 'Ce Zhou'
        },
        {
          'id': 1121407280,
          'name': 'Rui Huang'
        },
        {
          'id': 1119606354,
          'name': 'Liang Wan'
        },
        {
          'id': 1390053351,
          'name': 'Song Wang'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2007665589,
          'name': 'Competitor analysis'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 74,
      'reference_count': 34
    },
    {
      'id': 419708352,
      'title': 'S^3FD: Single Shot Scale-Invariant Face Detector',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1108322389,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1477164535,
          'name': 'Hailin Shi'
        },
        {
          'id': 1080319340,
          'name': 'Xiaobo Wang'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045640913,
          'name': 'False positive rate'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2012362224,
          'name': 'Scale invariance'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 0,
      'reference_count': 62
    },
    {
      'id': 422200728,
      'title': 'Adaptive Feeding: Achieving Fast and Accurate Detections by Adaptively Combining Object Detectors',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1328165765,
          'name': 'Hong-Yu Zhou'
        },
        {
          'id': 1107807353,
          'name': 'Bin-Bin Gao'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 35
    },
    {
      'id': 422371692,
      'title': 'A Joint Intrinsic-Extrinsic Prior Model for Retinex',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2100888578,
          'abbr': 'UM',
          'name': 'University of Macau'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1255155172,
          'name': 'Bolun Cai'
        },
        {
          'id': 1189132456,
          'name': 'Xianming Xu'
        },
        {
          'id': 1082392195,
          'name': 'Kailing Guo'
        },
        {
          'id': 1136612103,
          'name': 'Kui Jia'
        },
        {
          'id': 1003975322,
          'name': 'Bin Hu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047672818,
          'name': 'Instrumental and intrinsic value'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015276722,
          'name': 'Intrinsic and extrinsic properties (philosophy)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001942329,
          'name': 'Reflectivity'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2010264609,
          'name': 'Color constancy'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 35
    },
    {
      'id': 424117633,
      'title': 'Intrinsic 3D Dynamic Surface Tracking based on Dynamic Ricci Flow and Teichmüller Map',
      'affiliation': [
        {
          'id': 2102715800,
          'abbr': '青大',
          'name': 'Qingdao University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103998608,
          'abbr': 'SBU',
          'name': 'Stony Brook University'
        }
      ],
      'author': [
        {
          'id': 1289904974,
          'name': 'Xiaokang Yu'
        },
        {
          'id': 1010473345,
          'name': 'Na Lei'
        },
        {
          'id': 1266112357,
          'name': 'Yalin Wang'
        },
        {
          'id': 1023103330,
          'name': 'Xianfeng Gu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028120887,
          'name': 'Quadrilateral'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032839659,
          'name': 'Delaunay triangulation'
        },
        {
          'id': 2013716316,
          'name': 'Dilation (morphology)'
        },
        {
          'id': 2034891279,
          'name': 'Curvature'
        },
        {
          'id': 2036938399,
          'name': 'Ricci flow'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2048590101,
          'name': 'Conformal map'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 22
    },
    {
      'id': 424594962,
      'title': 'Towards Automatic Wild Animal Detection in Low Quality Camera-Trap Images Using Two-Channeled Perceiving Residual Pyramid Networks',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1314293435,
          'name': 'Chunbiao Zhu'
        },
        {
          'id': 1457355897,
          'name': 'Thomas H. Li'
        },
        {
          'id': 1444701965,
          'name': 'Ge Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040590228,
          'name': 'False positive paradox'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2014541145,
          'name': 'Camera trap'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 3
    },
    {
      'id': 427874871,
      'title': 'Deeply-Learned Composition-Aligned Representations for Person Re-identification',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1338175284,
          'name': 'Liming Zhao'
        },
        {
          'id': 1158327425,
          'name': 'Xi Li'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2033105456,
          'name': 'Human body'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 51,
      'reference_count': 59
    },
    {
      'id': 42917280,
      'title': 'Least Squares Generative Adversarial Networks',
      'affiliation': [
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1104437578,
          'name': 'Xudong Mao'
        },
        {
          'id': 1488101603,
          'name': 'Qing Li'
        },
        {
          'id': 1073983659,
          'name': 'Haoran Xie'
        },
        {
          'id': 1241005314,
          'name': 'Raymond Y. K. Lau'
        },
        {
          'id': 1471610490,
          'name': 'Zhen Wang'
        },
        {
          'id': 1233843508,
          'name': 'Stephen Paul Smolley'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2024303337,
          'name': 'Cross entropy'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2034493974,
          'name': 'Sigmoid function'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 578,
      'reference_count': 33
    },
    {
      'id': 437075238,
      'title': 'Class-Specific Reconstruction Transfer Learning via Sparse Low-Rank Constraint',
      'affiliation': [
        {
          'id': 2101592826,
          'abbr': 'CQU',
          'name': 'Chongqing University'
        },
        {
          'id': 2102488122,
          'abbr': 'MONASH',
          'name': 'Monash University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1079509224,
          'name': 'Shanshan Wang'
        },
        {
          'id': 1358133677,
          'name': 'Lei Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2022985840,
          'name': 'Transfer function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046405230,
          'name': 'Reproducing kernel Hilbert space'
        },
        {
          'id': 2045022011,
          'name': 'Data structure'
        },
        {
          'id': 2039134558,
          'name': 'Transfer matrix'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 440198133,
      'title': 'Ray Space Features for Plenoptic Structure-from-Motion',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        }
      ],
      'author': [
        {
          'id': 1335848112,
          'name': 'Yingliang Zhang'
        },
        {
          'id': 1311967549,
          'name': 'Peihong Yu'
        },
        {
          'id': 1233642173,
          'name': 'Wei Yang'
        },
        {
          'id': 1032364888,
          'name': 'Yuanxi Ma'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041984668,
          'name': 'Line (geometry)'
        },
        {
          'id': 2018533029,
          'name': 'Bundle adjustment'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 37
    },
    {
      'id': 4455312,
      'title': 'Scale-Adaptive Convolutions for Scene Parsing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1407660078,
          'name': 'Rui Zhang'
        },
        {
          'id': 1204181935,
          'name': 'Sheng Tang'
        },
        {
          'id': 1355307321,
          'name': 'Yongdong Zhang'
        },
        {
          'id': 1154435778,
          'name': 'Jintao Li'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2017547800,
          'name': 'Invisibility'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 33
    },
    {
      'id': 446940563,
      'title': 'High Performance Large Scale Face Recognition with Multi-cognition Softmax and Feature Retrieval',
      'affiliation': [
        {
          'id': 2103356563,
          'abbr': 'PANASONIC',
          'name': 'Panasonic'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1394008496,
          'name': 'Yan Xu'
        },
        {
          'id': 1325489102,
          'name': 'Yu Cheng'
        },
        {
          'id': 1144640029,
          'name': 'Jian Zhao'
        },
        {
          'id': 1264624665,
          'name': 'Zhecan Wang'
        },
        {
          'id': 1118341442,
          'name': 'Lin Xiong'
        },
        {
          'id': 1374119233,
          'name': 'Karlekar Jayashree'
        },
        {
          'id': 1061111773,
          'name': 'Hajime Tamura'
        },
        {
          'id': 1218751931,
          'name': 'Tomoyuki Kagaya'
        },
        {
          'id': 1465851410,
          'name': 'Sugiri Pranata'
        },
        {
          'id': 1026821132,
          'name': 'Shengmei Shen'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        }
      ],
      'field': [
        {
          'id': 2039393433,
          'name': 'Shuffling'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 13
    },
    {
      'id': 448412821,
      'title': 'Temporal Context Network for Activity Localization in Videos',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2101760029,
          'abbr': 'MERL',
          'name': 'Mitsubishi Electric Research Laboratories'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1145858558,
          'name': 'Xiyang Dai'
        },
        {
          'id': 1112350808,
          'name': 'Bharat Singh'
        },
        {
          'id': 1148692601,
          'name': 'Guyue Zhang'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        },
        {
          'id': 1350594211,
          'name': 'Yan Qiu Chen'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037377012,
          'name': 'Temporal scales'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001611344,
          'name': 'Temporal context'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 33
    },
    {
      'id': 451279911,
      'title': 'Learning Unified Embedding for Apparel Recognition',
      'affiliation': [
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1393924025,
          'name': 'Yang Song'
        },
        {
          'id': 1025073978,
          'name': 'Yuan Li'
        },
        {
          'id': 1070963283,
          'name': 'Bo Wu'
        },
        {
          'id': 1329990441,
          'name': 'Chao-Yeh Chen'
        },
        {
          'id': 1218755902,
          'name': 'Xiao Zhang'
        },
        {
          'id': 1407533177,
          'name': 'Hartwig Adam'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034082534,
          'name': 'Unified Model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 2
    },
    {
      'id': 451799265,
      'title': 'Look, Perceive and Segment: Finding the Salient Objects in Images via Two-stream Fixation-Semantic CNNs',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        },
        {
          'id': 2103652045,
          'abbr': 'IIMN',
          'name': 'International Institute of Minnesota'
        }
      ],
      'author': [
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1327455568,
          'name': 'Anlin Zheng'
        },
        {
          'id': 1149258320,
          'name': 'Jia Li'
        },
        {
          'id': 1313394467,
          'name': 'Feng Lu'
        }
      ],
      'field': [
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2017171351,
          'name': 'Semantic HTML'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 46
    },
    {
      'id': 452434910,
      'title': 'Amulet: Aggregating Multi-level Convolutional Features for Salient Object Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1404440350,
          'name': 'Pingping Zhang'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1055501293,
          'name': 'Hongyu Wang'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015809696,
          'name': 'Pillar'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 87,
      'reference_count': 51
    },
    {
      'id': 453607309,
      'title': 'Predicting Human Activities Using Stochastic Grammar',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1417522252,
          'name': 'Siyuan Qi'
        },
        {
          'id': 1382605034,
          'name': 'Siyuan Huang'
        },
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010593208,
          'name': 'Stochastic grammar'
        },
        {
          'id': 2008262245,
          'name': 'Affordance'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046331716,
          'name': 'Grammar'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 38
    },
    {
      'id': 45366619,
      'title': 'Deep Facial Action Unit Recognition from Partially Labeled Data',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1456599405,
          'name': 'Shan Wu'
        },
        {
          'id': 1445199645,
          'name': 'Shangfei Wang'
        },
        {
          'id': 1427292344,
          'name': 'Bowen Pan'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2039193354,
          'name': 'Restricted Boltzmann machine'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 25
    },
    {
      'id': 453904650,
      'title': 'CVAE-GAN: Fine-Grained Image Generation through Asymmetric Training',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1373420012,
          'name': 'Jianmin Bao'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 43
    },
    {
      'id': 458682180,
      'title': 'HSCNN: CNN-Based Hyperspectral Image Recovery from Spectrally Undersampled Projections',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1019333850,
          'name': 'Zhan Shi'
        },
        {
          'id': 1322974471,
          'name': 'Huiqun Li'
        },
        {
          'id': 1372850284,
          'name': 'Lizhi Wang'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2048710313,
          'name': 'Compressed sensing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 20
    },
    {
      'id': 461787354,
      'title': 'Adaptive Feeding: Achieving Fast and Accurate Detections by Adaptively Combining Object Detectors',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1328165765,
          'name': 'Hong-Yu Zhou'
        },
        {
          'id': 1107807353,
          'name': 'Bin-Bin Gao'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 2,
      'reference_count': 33
    },
    {
      'id': 468009924,
      'title': 'A Discriminative View of MRF Pre-processing Algorithms',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        }
      ],
      'author': [
        {
          'id': 1081170536,
          'name': 'Chen Wang'
        },
        {
          'id': 1432063854,
          'name': 'Charles Herrmann'
        },
        {
          'id': 1306993101,
          'name': 'Ramin Zabih'
        }
      ],
      'field': [
        {
          'id': 2040590228,
          'name': 'False positive paradox'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2018399408,
          'name': 'Preprocessor'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022297451,
          'name': 'Dead-end elimination'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 0,
      'reference_count': 41
    },
    {
      'id': 468880125,
      'title': 'From Groups to Co-Traveler Sets: Pair Matching Based Person Re-identification Framework',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1264314152,
          'name': 'Min Cao'
        },
        {
          'id': 1071077481,
          'name': 'Chen Chen'
        },
        {
          'id': 1063962263,
          'name': 'Xiyuan Hu'
        },
        {
          'id': 1178515377,
          'name': 'Silong Peng'
        }
      ],
      'field': [
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048529527,
          'name': 'Proof of concept'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046663815,
          'name': 'Rationality'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 0
    },
    {
      'id': 469434548,
      'title': 'Single Shot Text Detector with Regional Attention',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2100931896,
          'abbr': 'UF',
          'name': 'University of Florida'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1107548307,
          'name': 'Pan He'
        },
        {
          'id': 1054920101,
          'name': 'Weilin Huang'
        },
        {
          'id': 1451904479,
          'name': 'Tong He'
        },
        {
          'id': 1126327031,
          'name': 'Qile Zhu'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1043476445,
          'name': 'Xiaolin Li'
        }
      ],
      'field': [
        {
          'id': 2002552007,
          'name': 'Convolutional code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 38
    },
    {
      'id': 470059767,
      'title': 'Stepwise Metric Promotion for Unsupervised Video Person Re-identification',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1022209656,
          'name': 'Zimo Liu'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036083470,
          'name': 'Iterated function'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2007925444,
          'name': 'Reciprocal'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 0
    },
    {
      'id': 475782061,
      'title': 'Recurrent Topic-Transition GAN for Visual Paragraph Generation',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1103390613,
          'name': 'Zhiting Hu'
        },
        {
          'id': 1214006197,
          'name': 'Hao Zhang'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1192263557,
          'name': 'Eric P. Xing'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013982076,
          'name': 'Paragraph'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044963534,
          'name': 'Pragmatics'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2030024898,
          'name': 'Interpretability'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 38
    },
    {
      'id': 477783358,
      'title': 'VQS: Linking Segmentations to Questions and Answers for Supervised Attention in VQA and Question-Focused Semantic Segmentation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1259118616,
          'name': 'Yandong Li'
        },
        {
          'id': 1253889201,
          'name': 'Haoxiang Li'
        },
        {
          'id': 1410350428,
          'name': 'Chen Sun'
        },
        {
          'id': 1347218119,
          'name': 'Boqing Gong'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2012231735,
          'name': 'Oracle'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 51
    },
    {
      'id': 478207248,
      'title': 'Lattice Long Short-Term Memory for Human Action Recognition',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2101712668,
          'abbr': 'HARVARD',
          'name': 'Harvard University'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1254409537,
          'name': 'Lin Sun'
        },
        {
          'id': 1477244207,
          'name': 'Kui Jia'
        },
        {
          'id': 1230698718,
          'name': 'Kevin Chen'
        },
        {
          'id': 1173748546,
          'name': 'Dit Yan Yeung'
        },
        {
          'id': 1188683389,
          'name': 'Bertram E. Shi'
        },
        {
          'id': 1273458277,
          'name': 'Silvio Savarese'
        }
      ],
      'field': [
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2014351305,
          'name': 'Long short term memory'
        },
        {
          'id': 2021556405,
          'name': 'Lattice (order)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1708.03958.pdf',
      'citation_count': 8,
      'reference_count': 38
    },
    {
      'id': 480985306,
      'title': 'Channel Pruning for Accelerating Very Deep Neural Networks',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1342689546,
          'name': 'Yihui He'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2048065051,
          'name': 'Lasso (statistics)'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1707.html',
      'citation_count': 57,
      'reference_count': 49
    },
    {
      'id': 481211283,
      'title': 'Multi-scale Deep Learning Architectures for Person Re-identification',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1032075244,
          'name': 'Xuelin Qian'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1709.html',
      'citation_count': 7,
      'reference_count': 49
    },
    {
      'id': 482089715,
      'title': 'Jointly Attentive Spatial-Temporal Pooling Networks for Video-Based Person Re-identification',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        }
      ],
      'author': [
        {
          'id': 1419465552,
          'name': 'Shuangjie Xu'
        },
        {
          'id': 1464066788,
          'name': 'Yu Cheng'
        },
        {
          'id': 1073099256,
          'name': 'Kang Gu'
        },
        {
          'id': 1162758188,
          'name': 'Yang Yang'
        },
        {
          'id': 1031402329,
          'name': 'Shiyu Chang'
        },
        {
          'id': 1467586349,
          'name': 'Pan Zhou'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 16
    },
    {
      'id': 483196462,
      'title': 'DualNet: Learn Complementary Features for Image Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1112157554,
          'name': 'Saihui Hou'
        },
        {
          'id': 1004266194,
          'name': 'Xu Liu'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042225087,
          'name': 'Subnetwork'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040012394,
          'name': 'Weighting'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 30
    },
    {
      'id': 483270560,
      'title': 'Image2song: Song Retrieval via Bridging Image Content and Lyric Words',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        },
        {
          'id': 1088670356,
          'name': 'Di Hu'
        },
        {
          'id': 1356856029,
          'name': 'Xiaoqiang Lu'
        }
      ],
      'field': [
        {
          'id': 2002902869,
          'name': 'Perceptron'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000350581,
          'name': 'Bridging (networking)'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2012275282,
          'name': 'Social network'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 1,
      'reference_count': 44
    },
    {
      'id': 486487145,
      'title': 'Super-Trajectory for Video Segmentation',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1336211972,
          'name': 'Jianwen Xie'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        }
      ],
      'field': [
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029212302,
          'name': 'Statistical model'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022305872,
          'name': 'Video denoising'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 50
    },
    {
      'id': 487156214,
      'title': 'Deep Growing Learning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1360651767,
          'name': 'Guangcong Wang'
        },
        {
          'id': 1034272931,
          'name': 'Xiaohua Xie'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1195152842,
          'name': 'Jiaxuan Zhuo'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        },
        {
          'id': 2047942828,
          'name': 'Data modeling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 35
    },
    {
      'id': 488403310,
      'title': 'Video Scene Parsing with Predictive Feature Learning',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1212923758,
          'name': 'Xiaojie Jin'
        },
        {
          'id': 1394225671,
          'name': 'Xin Li'
        },
        {
          'id': 1047018509,
          'name': 'Huaxin Xiao'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1256462433,
          'name': 'Zhe Lin'
        },
        {
          'id': 1357326848,
          'name': 'Jimei Yang'
        },
        {
          'id': 1019258178,
          'name': 'Yunpeng Chen'
        },
        {
          'id': 1463499896,
          'name': 'Jian Dong'
        },
        {
          'id': 1350047351,
          'name': 'Luoqi Liu'
        },
        {
          'id': 1403252987,
          'name': 'Zequn Jie'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2043868725,
          'name': 'Predictive learning'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1612.html',
      'citation_count': 6,
      'reference_count': 47
    },
    {
      'id': 493913367,
      'title': 'Learning Spread-Out Local Feature Descriptors',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1461070388,
          'name': 'Xu Zhang'
        },
        {
          'id': 1379625839,
          'name': 'Felix X. Yu'
        },
        {
          'id': 1360550190,
          'name': 'Sanjiv Kumar'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2022133074,
          'name': 'Expressive power'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2049810721,
          'name': 'Uniform distribution (continuous)'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 23
    },
    {
      'id': 498152760,
      'title': 'Benchmarking Single-Image Reflection Removal Algorithms',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100362922,
          'abbr': 'AIST',
          'name': 'National Institute of Advanced Industrial Science and Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1458086827,
          'name': 'Renjie Wan'
        },
        {
          'id': 1100550194,
          'name': 'Boxin Shi'
        },
        {
          'id': 1164002571,
          'name': 'Ling-Yu Duan'
        },
        {
          'id': 1159618503,
          'name': 'Ah-Hwee Tan'
        },
        {
          'id': 1314678280,
          'name': 'Alex C. Kot'
        }
      ],
      'field': [
        {
          'id': 2006589979,
          'name': 'Visual computing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2025988546,
          'name': 'Benchmark (computing)'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2048954615,
          'name': 'Aperture'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 0
    },
    {
      'id': 498251978,
      'title': 'Should We Encode Rain Streaks in Video as Deterministic or Stochastic',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1215664862,
          'name': 'Wei Wei'
        },
        {
          'id': 1372123654,
          'name': 'Lixuan Yi'
        },
        {
          'id': 1097800340,
          'name': 'Qi Xie'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011893552,
          'name': 'Streak'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 38
    },
    {
      'id': 51625536,
      'title': 'Soft Proposal Networks for Weakly Supervised Object Localization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1259889084,
          'name': 'Yi Zhu'
        },
        {
          'id': 1238435354,
          'name': 'Yanzhao Zhou'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        },
        {
          'id': 1246182727,
          'name': 'Qiang Qiu'
        },
        {
          'id': 1164136418,
          'name': 'Jianbin Jiao'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 37
    },
    {
      'id': 52301847,
      'title': 'Unsupervised Domain Adaptation for Face Recognition in Unlabeled Videos',
      'affiliation': [
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1063709080,
          'name': 'Kihyuk Sohn'
        },
        {
          'id': 1313041809,
          'name': 'Sifei Liu'
        },
        {
          'id': 1438525052,
          'name': 'Guangyu Zhong'
        },
        {
          'id': 1386428696,
          'name': 'Xiang Yu'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1258594675,
          'name': 'Manmohan Chandraker'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 42
    },
    {
      'id': 53700876,
      'title': 'Monocular 3D Human Pose Estimation by Predicting Depth on Joints',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1393569436,
          'name': 'Bruce Xiaohan Nie'
        },
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2000979948,
          'name': 'Kinematics'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2007989407,
          'name': 'Tree structure'
        },
        {
          'id': 2018188840,
          'name': 'Human skeleton'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 37
    },
    {
      'id': 54388597,
      'title': 'Blind Image Deblurring with Outlier Handling',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1118840716,
          'name': 'Jiangxin Dong'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2005748191,
          'name': 'Function approximation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 30
    },
    {
      'id': 56051192,
      'title': 'Primary Video Object Segmentation via Complementary CNNs and Neighborhood Reversible Flow',
      'affiliation': [
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1149258320,
          'name': 'Jia Li'
        },
        {
          'id': 1327455568,
          'name': 'Anlin Zheng'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1065095520,
          'name': 'Bin Zhou'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2000871037,
          'name': 'Market segmentation'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 44
    },
    {
      'id': 57715322,
      'title': 'Multi-label Image Recognition by Recurrently Discovering Attentional Regions',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1267345673,
          'name': 'Zhouxia Wang'
        },
        {
          'id': 1298778203,
          'name': 'Tianshui Chen'
        },
        {
          'id': 1408992132,
          'name': 'Guanbin Li'
        },
        {
          'id': 1377537327,
          'name': 'Ruijia Xu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 31
    },
    {
      'id': 61324568,
      'title': 'Ensemble Diffusion for Retrieval',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1164775868,
          'name': 'Zhichao Zhou'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1358872243,
          'name': 'Longin Jan Latecki'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002594279,
          'name': 'Diffusion process'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 56
    },
    {
      'id': 61392516,
      'title': 'Temporal Action Detection with Structured Segment Networks',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1162296009,
          'name': 'Yue Zhao'
        },
        {
          'id': 1422062208,
          'name': 'Yuanjun Xiong'
        },
        {
          'id': 1425862507,
          'name': 'Limin Wang'
        },
        {
          'id': 1241291887,
          'name': 'Zhirong Wu'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2036616100,
          'name': 'Completeness (statistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1704.html',
      'citation_count': 37,
      'reference_count': 55
    },
    {
      'id': 63850644,
      'title': 'CVAE-GAN: Fine-Grained Image Generation through Asymmetric Training',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1373420012,
          'name': 'Jianmin Bao'
        },
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2024303337,
          'name': 'Cross entropy'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'https://arxiv.org/pdf/1703.10155.pdf',
      'citation_count': 8,
      'reference_count': 44
    },
    {
      'id': 64105469,
      'title': 'HashNet: Deep Learning to Hash by Continuation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103382811,
          'abbr': 'UIC',
          'name': 'University of Illinois at Chicago'
        }
      ],
      'author': [
        {
          'id': 1126860916,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1445179598,
          'name': 'Philip S. Yu'
        }
      ],
      'field': [
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1702.html',
      'citation_count': 20,
      'reference_count': 43
    },
    {
      'id': 66801585,
      'title': 'FoveaNet: Perspective-Aware Urban Scene Parsing',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104935093,
          'abbr': 'UNITN',
          'name': 'University of Trento'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1394225671,
          'name': 'Xin Li'
        },
        {
          'id': 1273266408,
          'name': 'Zequn Jie'
        },
        {
          'id': 1291461363,
          'name': 'Wei Wang'
        },
        {
          'id': 1048604432,
          'name': 'Changsong Liu'
        },
        {
          'id': 1357326848,
          'name': 'Jimei Yang'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1256462433,
          'name': 'Zhe Lin'
        },
        {
          'id': 1276541054,
          'name': 'Qiang Chen'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024899112,
          'name': 'Perspective (geometry)'
        },
        {
          'id': 2036255561,
          'name': 'CRFS'
        },
        {
          'id': 2006335577,
          'name': 'Perspective (graphical)'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 67691894,
      'title': 'Zero-Shot Learning Posed as a Missing Data Problem',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1113109398,
          'name': 'Bo Zhao'
        },
        {
          'id': 1398364745,
          'name': 'Botong Wu'
        },
        {
          'id': 1088002310,
          'name': 'Tianfu Wu'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014952153,
          'name': 'Transduction (machine learning)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2009775785,
          'name': 'Missing data problem'
        },
        {
          'id': 2034486721,
          'name': 'Upper and lower bounds'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 22
    },
    {
      'id': 6879807,
      'title': 'Infant Footprint Recognition',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1065398705,
          'name': 'Eryun Liu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2020334879,
          'name': 'Footprint'
        },
        {
          'id': 2037154616,
          'name': 'Biometrics'
        },
        {
          'id': 2016846535,
          'name': 'Fingerprint recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2001943867,
          'name': 'Minutiae'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 19
    },
    {
      'id': 72804096,
      'title': 'VegFru: A Domain-Specific Dataset for Fine-Grained Visual Categorization',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1112157554,
          'name': 'Saihui Hou'
        },
        {
          'id': 1161366457,
          'name': 'Yushan Feng'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 0
    },
    {
      'id': 74820963,
      'title': 'Learning Spatiotemporal Features Using 3DCNN and Convolutional LSTM for Gesture Recognition',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1006840602,
          'name': 'Liang Zhang'
        },
        {
          'id': 1224090501,
          'name': 'Guangming Zhu'
        },
        {
          'id': 1191781983,
          'name': 'Peiyi Shen'
        },
        {
          'id': 1175070411,
          'name': 'Juan Song'
        }
      ],
      'field': [
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 27
    },
    {
      'id': 75345640,
      'title': 'UCT: Learning Unified Convolutional Networks for Real-Time Visual Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1168372245,
          'name': 'Guan Huang'
        },
        {
          'id': 1268828894,
          'name': 'Wei Zou'
        },
        {
          'id': 1319563912,
          'name': 'Dalong Du'
        },
        {
          'id': 1300040491,
          'name': 'Chang Huang'
        }
      ],
      'field': [
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1711.html',
      'citation_count': 9,
      'reference_count': 46
    },
    {
      'id': 77396723,
      'title': 'Deeply-Learned Composition-Aligned Representations for Person Re-identification',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1338175284,
          'name': 'Liming Zhao'
        },
        {
          'id': 1158327425,
          'name': 'Xi Li'
        },
        {
          'id': 1459491764,
          'name': 'Yueting Zhuang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 107,
      'reference_count': 32
    },
    {
      'id': 80774897,
      'title': 'DSOD: Learning Deeply Supervised Object Detectors from Scratch',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1316790742,
          'name': 'Zhiqiang Shen'
        },
        {
          'id': 1434676857,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029715669,
          'name': 'Design elements and principles'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2023124670,
          'name': 'Scratch'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 81,
      'reference_count': 30
    },
    {
      'id': 82114032,
      'title': 'Online Multi-object Tracking Using CNN-Based Single Object Tracker with Spatial-Temporal Attention Mechanism',
      'affiliation': [
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1000853021,
          'name': 'Qi Chu'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1035353314,
          'name': 'Bin Liu'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://doi.ieeecomputersociety.org/10.1109/ICCV.2017.518',
      'citation_count': 11,
      'reference_count': 51
    },
    {
      'id': 83361987,
      'title': 'Learning Efficient Convolutional Networks through Network Slimming',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1434676857,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        },
        {
          'id': 1316790742,
          'name': 'Zhiqiang Shen'
        },
        {
          'id': 1366951731,
          'name': 'Gao Huang'
        },
        {
          'id': 1014148388,
          'name': 'Shoumeng Yan'
        },
        {
          'id': 1110227432,
          'name': 'Changshui Zhang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044959998,
          'name': 'Software deployment'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2017552540,
          'name': 'Memory footprint'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 109,
      'reference_count': 34
    },
    {
      'id': 83373293,
      'title': 'Cross-Modal Deep Variational Hashing',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1476100718,
          'name': 'Venice Erin Liong'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1432540011,
          'name': 'Yap-Peng Tan'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2042373531,
          'name': 'Posterior probability'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 38
    },
    {
      'id': 83458114,
      'title': 'SORT: Second-Order Response Transform for Visual Recognition',
      'affiliation': [
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1204480417,
          'name': 'Yan Wang'
        },
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1398227961,
          'name': 'Chenxi Liu'
        },
        {
          'id': 1189491755,
          'name': 'Siyuan Qiao'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009495827,
          'name': 'In-place algorithm'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048255180,
          'name': 'Proxmap sort'
        },
        {
          'id': 2022963558,
          'name': 'Tree sort'
        },
        {
          'id': 2041308320,
          'name': 'sort'
        },
        {
          'id': 2024005846,
          'name': 'Adaptive sort'
        },
        {
          'id': 2001779751,
          'name': 'Selection sort'
        },
        {
          'id': 2025185212,
          'name': 'Counting sort'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2032126681,
          'name': 'Sorting algorithm'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 60
    },
    {
      'id': 85935884,
      'title': 'Coherent Online Video Style Transfer',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1313251152,
          'name': 'Dongdong Chen'
        },
        {
          'id': 1092358509,
          'name': 'Jing Liao'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2029556817,
          'name': 'Optical filter'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037498765,
          'name': 'Flicker'
        },
        {
          'id': 2019148788,
          'name': 'Optical imaging'
        },
        {
          'id': 2007469624,
          'name': 'Stylized fact'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 43
    },
    {
      'id': 89878281,
      'title': 'LPSNet: A Novel Log Path Signature Feature Based Hand Gesture Recognition Framework',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1219347127,
          'name': 'Chenyang Li'
        },
        {
          'id': 1366371321,
          'name': 'Xin Zhang'
        },
        {
          'id': 1276589951,
          'name': 'Lianwen Jin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040810353,
          'name': 'Signature recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 17
    },
    {
      'id': 92361586,
      'title': 'Domain-Adaptive Deep Network Compression',
      'affiliation': [
        {
          'id': 2100853613,
          'abbr': 'UAB',
          'name': 'Autonomous University of Barcelona'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        }
      ],
      'author': [
        {
          'id': 1027004566,
          'name': 'Marc Masana'
        },
        {
          'id': 1479818478,
          'name': 'Joost van de Weijer'
        },
        {
          'id': 1230607955,
          'name': 'Luis Herranz'
        },
        {
          'id': 1430122677,
          'name': 'Andrew D. Bagdanov'
        },
        {
          'id': 1374278060,
          'name': 'Jose M. Alvarez'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 32
    },
    {
      'id': 92952857,
      'title': 'BodyFusion: Real-Time Capture of Human Motion and Surface Geometry Using a Single Depth Camera',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1415675733,
          'name': 'Tao Yu'
        },
        {
          'id': 1111748420,
          'name': 'Kaiwen Guo'
        },
        {
          'id': 1098810408,
          'name': 'Feng Xu'
        },
        {
          'id': 1003328915,
          'name': 'Yuan Dong'
        },
        {
          'id': 1007231757,
          'name': 'Zhaoqi Su'
        },
        {
          'id': 1346709451,
          'name': 'Jianhui Zhao'
        },
        {
          'id': 1352415104,
          'name': 'Jianguo Li'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        }
      ],
      'field': [
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039252156,
          'name': 'Parametrization'
        },
        {
          'id': 2015875560,
          'name': 'Geometry'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 43
    },
    {
      'id': 93659003,
      'title': 'Amulet: Aggregating Multi-level Convolutional Features for Salient Object Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1404440350,
          'name': 'Pingping Zhang'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1055501293,
          'name': 'Hongyu Wang'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 22,
      'reference_count': 50
    },
    {
      'id': 95546578,
      'title': 'View Adaptive Recurrent Neural Networks for High Performance Human Action Recognition from Skeleton Data',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1172150847,
          'name': 'Pengfei Zhang'
        },
        {
          'id': 1171674693,
          'name': 'Cuiling Lan'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        },
        {
          'id': 1221299331,
          'name': 'Jianru Xue'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2040731089,
          'name': 'Time delay neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003744115,
          'name': 'Logic gate'
        },
        {
          'id': 2000790668,
          'name': 'Viewpoints'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 51
    },
    {
      'id': 97635733,
      'title': 'Learning Uncertain Convolutional Features for Accurate Saliency Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1404440350,
          'name': 'Pingping Zhang'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1055501293,
          'name': 'Hongyu Wang'
        },
        {
          'id': 1310020299,
          'name': 'Baocai Yin'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2038996624,
          'name': 'Network model'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1708.html',
      'citation_count': 30,
      'reference_count': 47
    },
    {
      'id': 98018136,
      'title': 'SVDNet for Pedestrian Retrieval',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1430625305,
          'name': 'Yifan Sun'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1163501203,
          'name': 'Weijian Deng'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        }
      ],
      'field': [
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2029752579,
          'name': 'Vector decomposition'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2035050575,
          'name': 'Orthogonality'
        },
        {
          'id': 2040590825,
          'name': 'Decorrelation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134178413',
        'name': 'ICCV',
        'year': 2017
      },
      'source_url': '',
      'citation_count': 137,
      'reference_count': 24
    },
    {
      'id': 100440704,
      'title': 'Fast Orthogonal Projection Based on Kronecker Product',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1461070388,
          'name': 'Xu Zhang'
        },
        {
          'id': 1379625839,
          'name': 'Felix X. Yu'
        },
        {
          'id': 1153328503,
          'name': 'Ruiqi Guo'
        },
        {
          'id': 1360550190,
          'name': 'Sanjiv Kumar'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1469638638,
          'name': 'Shih-Fu Chang'
        }
      ],
      'field': [
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2048130129,
          'name': 'Kronecker product'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019703203,
          'name': 'Orthogonal matrix'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022139458,
          'name': 'Matrix multiplication'
        },
        {
          'id': 2036558582,
          'name': 'Kronecker delta'
        },
        {
          'id': 2035050575,
          'name': 'Orthogonality'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024356877,
          'name': 'Search problem'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 37
    },
    {
      'id': 112578409,
      'title': 'Look and Think Twice: Capturing Top-Down Visual Attention with Feedback Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1243327214,
          'name': 'Chunshui Cao'
        },
        {
          'id': 1422216204,
          'name': 'Xianming Liu'
        },
        {
          'id': 1240643992,
          'name': 'Yi Yang'
        },
        {
          'id': 1225242930,
          'name': 'Yinan Yu'
        },
        {
          'id': 1230735529,
          'name': 'Jiang Wang'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1260597004,
          'name': 'Yongzhen Huang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1472351558,
          'name': 'Chang Huang'
        },
        {
          'id': 1383270866,
          'name': 'Wei Xu'
        },
        {
          'id': 1398431627,
          'name': 'Deva Ramanan'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040731089,
          'name': 'Time delay neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2004465624,
          'name': 'Physical neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032550953,
          'name': 'Feed forward'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014905894,
          'name': 'Feedback loop'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 128,
      'reference_count': 38
    },
    {
      'id': 122657497,
      'title': 'Multi-Scale Learning for Low-Resolution Person Re-Identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1131472851,
          'name': 'Xiang Li'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1289750720,
          'name': 'Xiaojuan Wang'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010566870,
          'name': 'Component analysis'
        },
        {
          'id': 2030661317,
          'name': 'Image scaling'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007410355,
          'name': 'Discriminant'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 40
    },
    {
      'id': 127235599,
      'title': 'Relaxing from Vocabulary: Robust Weakly-Supervised Deep Learning for Vocabulary-Free Image Tagging',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1057895870,
          'name': 'Jianlong Fu'
        },
        {
          'id': 1043206621,
          'name': 'Yue Wu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        },
        {
          'id': 1048437175,
          'name': 'Hanqing Lu'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028090408,
          'name': 'WordNet'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010604280,
          'name': 'SCALE-UP'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        },
        {
          'id': 2004320156,
          'name': 'Relevance feedback'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 27
    },
    {
      'id': 128369763,
      'title': 'Beyond Tree Structure Models: A New Occlusion Aware Graphical Model for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1392506805,
          'name': 'Lianrui Fu'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2042278253,
          'name': 'Articulated body pose estimation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2007989407,
          'name': 'Tree structure'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 55
    },
    {
      'id': 129074792,
      'title': '3D Hand Pose Estimation Using Randomized Decision Forest with Segmentation Index Points',
      'affiliation': [
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1267194784,
          'name': 'Peiyi Li'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1158327425,
          'name': 'Xi Li'
        },
        {
          'id': 1278394780,
          'name': 'Chunyuan Liao'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2005038795,
          'name': 'Tree (data structure)'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2044405812,
          'name': 'Centroid'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 41,
      'reference_count': 31
    },
    {
      'id': 134305317,
      'title': 'Person Re-Identification with Correspondence Structure Learning',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100954550,
          'abbr': '',
          'name': 'Zhengzhou University'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1177042589,
          'name': 'Yang Shen'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1235062515,
          'name': 'Mingliang Xu'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 93,
      'reference_count': 31
    },
    {
      'id': 1437331,
      'title': 'Multi-View Complementary Hash Tables for Nearest Neighbor Search',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1025575364,
          'name': 'Xianglong Liu'
        },
        {
          'id': 1300509348,
          'name': 'Lei Huang'
        },
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1031446264,
          'name': 'Bo Lang'
        }
      ],
      'field': [
        {
          'id': 2040175213,
          'name': 'Universal hashing'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033262770,
          'name': 'Rolling hash'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2042182389,
          'name': 'Hash tree'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 40
    },
    {
      'id': 14737954,
      'title': 'Removing Rain from a Single Image via Discriminative Sparse Coding',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1417541561,
          'name': 'Yu Luo'
        },
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        },
        {
          'id': 1186862432,
          'name': 'Hui Ji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2017577192,
          'name': 'Blend modes'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020345778,
          'name': 'Mutually exclusive events'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 94,
      'reference_count': 27
    },
    {
      'id': 152934323,
      'title': 'Multi-Task Learning with Low Rank Attribute Embedding for Person Re-Identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1424228217,
          'name': 'Chi Su'
        },
        {
          'id': 1219049225,
          'name': 'Fan Yang'
        },
        {
          'id': 1398975099,
          'name': 'Shiliang Zhang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2034845953,
          'name': 'Multi-task learning'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015046636,
          'name': 'Variable and attribute'
        },
        {
          'id': 2025250989,
          'name': 'Attribute domain'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 113,
      'reference_count': 48
    },
    {
      'id': 156058900,
      'title': 'StereoSnakes: Contour Based Consistent Object Extraction for Stereo Images',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1483776745,
          'name': 'Ran Ju'
        },
        {
          'id': 1272023527,
          'name': 'Tongwei Ren'
        },
        {
          'id': 1149328961,
          'name': 'Gangshan Wu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044077131,
          'name': 'Stereo cameras'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2018261156,
          'name': 'Stereoscopy'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2048776180,
          'name': 'Image editing'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2006497253,
          'name': 'Population'
        },
        {
          'id': 2025684083,
          'name': 'Computer stereo vision'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 37
    },
    {
      'id': 158080427,
      'title': 'Confidence Preserving Machine for Facial Action Unit Detection',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        }
      ],
      'author': [
        {
          'id': 1162783045,
          'name': 'Jiabei Zeng'
        },
        {
          'id': 1357110762,
          'name': 'Wen-Sheng Chu'
        },
        {
          'id': 1300722889,
          'name': 'Fernando De la Torre'
        },
        {
          'id': 1442054127,
          'name': 'Jeffrey F. Cohn'
        },
        {
          'id': 1176567166,
          'name': 'Zhang Xiong'
        }
      ],
      'field': [
        {
          'id': 2047515366,
          'name': 'Decision boundary'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023319810,
          'name': 'Margin classifier'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 47
    },
    {
      'id': 162293111,
      'title': 'A Matrix Decomposition Perspective to Multiple Graph Matching',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        }
      ],
      'author': [
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1122096088,
          'name': 'Hongteng Xu'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1122454806,
          'name': 'Huanxi Liu'
        },
        {
          'id': 1036975246,
          'name': 'Stephen M. Chu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044851664,
          'name': 'Adjacency matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025353510,
          'name': 'Folded cube graph'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2011253300,
          'name': 'Modular decomposition'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2040634048,
          'name': 'Factor-critical graph'
        },
        {
          'id': 2016278136,
          'name': 'Voltage graph'
        },
        {
          'id': 2044286533,
          'name': 'Graph bandwidth'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 63
    },
    {
      'id': 162805759,
      'title': 'A Self-Paced Multiple-Instance Learning Framework for Co-Saliency Detection',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1466217065,
          'name': 'Dingwen Zhang'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1400774619,
          'name': 'Chao Li'
        },
        {
          'id': 1051370624,
          'name': 'Lu Jiang'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1451206475,
          'name': 'Junwei Han'
        }
      ],
      'field': [
        {
          'id': 2017679758,
          'name': 'Competitive learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048820006,
          'name': 'Robot learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026135480,
          'name': 'Instance-based learning'
        },
        {
          'id': 2010451535,
          'name': 'Active learning (machine learning)'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2034510601,
          'name': 'Stability (learning theory)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 48
    },
    {
      'id': 164679622,
      'title': 'Deep Colorization',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1207709764,
          'name': 'Zezhou Cheng'
        },
        {
          'id': 1324299713,
          'name': 'Qingxiong Yang'
        },
        {
          'id': 1036887077,
          'name': 'Bin Sheng'
        }
      ],
      'field': [
        {
          'id': 2006513855,
          'name': 'Bilateral filter'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2010567281,
          'name': 'Grayscale'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 124,
      'reference_count': 32
    },
    {
      'id': 170272163,
      'title': 'Learning Semi-Supervised Representation Towards a Unified Optimization Framework for Semi-Supervised Learning',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1067719877,
          'name': 'Chun-Guang Li'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 36
    },
    {
      'id': 17153818,
      'title': 'Multiple Feature Fusion via Weighted Entropy for Visual Tracking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1338596283,
          'name': 'Lin Ma'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1361061781,
          'name': 'Jianjiang Feng'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 39
    },
    {
      'id': 177727323,
      'title': 'Linearization to Nonlinear Learning for Visual Tracking',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1425189926,
          'name': 'Bo Ma'
        },
        {
          'id': 1388681940,
          'name': 'Hongwei Hu'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1108732568,
          'name': 'Yuping Zhang'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2032586025,
          'name': 'Linearization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043533591,
          'name': 'Iterative and incremental development'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2028755227,
          'name': 'Visual dictionary'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 36
    },
    {
      'id': 178710070,
      'title': 'Interactive Visual Hull Refinement for Specular and Transparent Object Surface Reconstruction',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        }
      ],
      'author': [
        {
          'id': 1402137958,
          'name': 'Xinxin Zuo'
        },
        {
          'id': 1166935168,
          'name': 'Chao Du'
        },
        {
          'id': 1066945238,
          'name': 'Sen Wang'
        },
        {
          'id': 1348130687,
          'name': 'Jiangbin Zheng'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2013078986,
          'name': 'Specular reflection'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2035705126,
          'name': 'Regular polygon'
        },
        {
          'id': 2016181585,
          'name': '3D modeling'
        },
        {
          'id': 2024753776,
          'name': 'Visual hull'
        },
        {
          'id': 2043698679,
          'name': 'Carving'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 34
    },
    {
      'id': 202969694,
      'title': 'Robust Visual Tracking by Exploiting the Historical Tracker Snapshots',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1003250156,
          'name': 'Jiatong Li'
        },
        {
          'id': 1416117648,
          'name': 'Zhibin Hong'
        },
        {
          'id': 1305947545,
          'name': 'Baojun Zhao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2035434896,
          'name': 'Snapshot (computer storage)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 30
    },
    {
      'id': 209360031,
      'title': 'Learning Binary Codes for Maximum Inner Product Search',
      'affiliation': [
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100496658,
          'abbr': 'UNCC',
          'name': 'University of North Carolina at Charlotte'
        }
      ],
      'author': [
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1108454146,
          'name': 'Shaoting Zhang'
        },
        {
          'id': 1287232414,
          'name': 'Yang Yang'
        },
        {
          'id': 1168663224,
          'name': 'Heng Tao Shen'
        }
      ],
      'field': [
        {
          'id': 2028776128,
          'name': 'Block code'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2031946290,
          'name': 'Self-balancing binary search tree'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2043242479,
          'name': 'Uniform binary search'
        },
        {
          'id': 2005725091,
          'name': 'Raw data'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 83,
      'reference_count': 42
    },
    {
      'id': 216213000,
      'title': 'Scotopic Visual Recognition',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102247394,
          'abbr': 'CIT',
          'name': 'California Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1115960749,
          'name': 'Bo Chen'
        },
        {
          'id': 1369706316,
          'name': 'Pietro Perona'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010152203,
          'name': 'Scotopic vision'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2036679242,
          'name': 'Word error rate'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 15
    },
    {
      'id': 236513585,
      'title': 'Visual Tracking with Fully Convolutional Networks',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 420,
      'reference_count': 44
    },
    {
      'id': 244694306,
      'title': 'Box Aggregation for Proposal Decimation: Last Mile of Object Detection',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1015055898,
          'name': 'Shu Liu'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2029478972,
          'name': 'Decimation'
        },
        {
          'id': 2019989909,
          'name': 'Last mile'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 26
    },
    {
      'id': 24761685,
      'title': 'Two Birds, One Stone: Jointly Learning Binary Code for Large-Scale Face Image Retrieval and Attributes Prediction',
      'affiliation': [
        {
          'id': 2102522667,
          'abbr': 'WVU',
          'name': 'West Virginia University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1150911725,
          'name': 'Yan Li'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1407582576,
          'name': 'Haomiao Liu'
        },
        {
          'id': 1013515220,
          'name': 'Huajie Jiang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 39
    },
    {
      'id': 25148562,
      'title': 'Contour Flow: Middle-Level Motion Estimation by Combining Motion Segmentation and Contour Alignment',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1101207455,
          'name': 'Huijun Di'
        },
        {
          'id': 1038152807,
          'name': 'Qingxuan Shi'
        },
        {
          'id': 1102172942,
          'name': 'Feng Lv'
        },
        {
          'id': 1011100460,
          'name': 'Ming Qin'
        },
        {
          'id': 1307253133,
          'name': 'Yao Lu'
        }
      ],
      'field': [
        {
          'id': 2027392888,
          'name': 'Branching (version control)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2009875918,
          'name': 'Motion field'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 31
    },
    {
      'id': 254774308,
      'title': 'External Patch Prior Guided Internal Clustering for Image Denoising',
      'affiliation': [
        {
          'id': 2103301421,
          'abbr': 'FZU',
          'name': 'Fuzhou University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1358764128,
          'name': 'Fei Chen'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1282930446,
          'name': 'Huimin Yu'
        }
      ],
      'field': [
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2023693789,
          'name': 'Inverse problem'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022305872,
          'name': 'Video denoising'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 52,
      'reference_count': 34
    },
    {
      'id': 259915742,
      'title': 'Unsupervised Extraction of Video Highlights via Robust Recurrent Auto-Encoders',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1035002874,
          'name': 'Huan Yang'
        },
        {
          'id': 1178704657,
          'name': 'Baoyuan Wang'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        },
        {
          'id': 1425694010,
          'name': 'David P. Wipf'
        },
        {
          'id': 1358545007,
          'name': 'Minyi Guo'
        },
        {
          'id': 1212262694,
          'name': 'Baining Guo'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011565621,
          'name': 'Social media'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014232538,
          'name': 'Popularity'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2014351305,
          'name': 'Long short term memory'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 64,
      'reference_count': 32
    },
    {
      'id': 266641601,
      'title': 'Simultaneous Local Binary Feature Learning and Encoding for Face Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1476100718,
          'name': 'Venice Erin Liong'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029522347,
          'name': 'Codebook'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 57
    },
    {
      'id': 270785664,
      'title': 'Object Extraction from Bounding Box Prior with Double Sparse Reconstruction',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1470521509,
          'name': 'Lingzheng Dai'
        },
        {
          'id': 1145565877,
          'name': 'Jundi Ding'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        },
        {
          'id': 1421826754,
          'name': 'Fanlong Zhang'
        },
        {
          'id': 1488392078,
          'name': 'Junxia Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 21
    },
    {
      'id': 271084060,
      'title': 'MeshStereo: A Global Stereo Model with Mesh Alignment Regularization for View Interpolation',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1318990977,
          'name': 'Chi Zhang'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1487923076,
          'name': 'Yanhua Cheng'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2005819271,
          'name': 'Triangle mesh'
        },
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2000198985,
          'name': 'Triangulation (social science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2025684083,
          'name': 'Computer stereo vision'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 62,
      'reference_count': 24
    },
    {
      'id': 276114955,
      'title': 'Low-Rank Tensor Constrained Multiview Subspace Clustering',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1179117743,
          'name': 'Changqing Zhang'
        },
        {
          'id': 1357204816,
          'name': 'Huazhu Fu'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1413841384,
          'name': 'Guangcan Liu'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049037711,
          'name': 'Augmented Lagrangian method'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2038646650,
          'name': 'Multilinear subspace learning'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 37
    },
    {
      'id': 279599727,
      'title': 'Discriminative Low-Rank Tracking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1450716152,
          'name': 'Yao Sui'
        },
        {
          'id': 1151338377,
          'name': 'Yafei Tang'
        },
        {
          'id': 1168982309,
          'name': 'Li Zhang'
        }
      ],
      'field': [
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 41
    },
    {
      'id': 286518200,
      'title': 'A Data-Driven Metric for Comprehensive Evaluation of Saliency Models',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1149258320,
          'name': 'Jia Li'
        },
        {
          'id': 1376919782,
          'name': 'Changqun Xia'
        },
        {
          'id': 1403803510,
          'name': 'Yafei Song'
        },
        {
          'id': 1010433324,
          'name': 'Shu Fang'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2047973581,
          'name': 'Data-driven'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 39
    },
    {
      'id': 292939573,
      'title': 'Visual Tracking via Nonnegative Regularization Multiple Locality Coding',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104347708,
          'abbr': 'CHALMERS',
          'name': 'Chalmers University of Technology'
        }
      ],
      'author': [
        {
          'id': 1420480187,
          'name': 'Fanghui Liu'
        },
        {
          'id': 1005623309,
          'name': 'Tao Zhou'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        },
        {
          'id': 1371266837,
          'name': 'Irene Yu-Hua Gu'
        }
      ],
      'field': [
        {
          'id': 2015842062,
          'name': 'Locality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2047028141,
          'name': 'Norm (mathematics)'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 29
    },
    {
      'id': 293534478,
      'title': 'Learning Parametric Distributions for Image Super-Resolution: Where Patch Matching Meets Sparse Coding',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1074417670,
          'name': 'Yongbo Li'
        },
        {
          'id': 1284606133,
          'name': 'Weisheng Dong'
        },
        {
          'id': 1291914506,
          'name': 'Guangming Shi'
        },
        {
          'id': 1234751771,
          'name': 'Xuemei Xie'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031909882,
          'name': 'Binary image'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 20
    },
    {
      'id': 294800366,
      'title': 'Low-Rank Tensor Approximation with Laplacian Scale Mixture Modeling for Multiframe Image Denoising',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102522667,
          'abbr': 'WVU',
          'name': 'West Virginia University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1284606133,
          'name': 'Weisheng Dong'
        },
        {
          'id': 1066962172,
          'name': 'Guangyu Li'
        },
        {
          'id': 1291914506,
          'name': 'Guangming Shi'
        },
        {
          'id': 1173434986,
          'name': 'Xin Li'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2041758568,
          'name': 'Laplace operator'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 22
    },
    {
      'id': 301242934,
      'title': 'Deep Spatial Pyramid Ensemble for Cultural Event Recognition',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1453321753,
          'name': 'Xiu-Shen Wei'
        },
        {
          'id': 1107807353,
          'name': 'Bin-Bin Gao'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        }
      ],
      'field': [
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011288419,
          'name': 'Digital signal processing'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 23
    },
    {
      'id': 306214503,
      'title': 'Conditional High-Order Boltzmann Machine: A Supervised Learning Model for Relation Learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014234673,
          'name': 'Online machine learning'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010451535,
          'name': 'Active learning (machine learning)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047795701,
          'name': 'Linear classifier'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034510601,
          'name': 'Stability (learning theory)'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2006887120,
          'name': 'Learning classifier system'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 45
    },
    {
      'id': 307450006,
      'title': 'Bi-Shifting Auto-Encoder for Unsupervised Domain Adaptation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2011902121,
          'name': 'Image sensor'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 36
    },
    {
      'id': 311618438,
      'title': 'Learning to Predict Saliency on Face Images',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1118664104,
          'name': 'Mai Xu'
        },
        {
          'id': 1477250630,
          'name': 'Yun Ren'
        },
        {
          'id': 1287578436,
          'name': 'Zulin Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 28
    },
    {
      'id': 311965776,
      'title': 'A Spatio-Temporal Appearance Representation for Viceo-Based Pedestrian Re-Identification',
      'affiliation': [
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1376209746,
          'name': 'Kan Liu'
        },
        {
          'id': 1296981365,
          'name': 'Bingpeng Ma'
        },
        {
          'id': 1392480485,
          'name': 'Wei Zhang'
        },
        {
          'id': 1171296994,
          'name': 'Rui Huang'
        }
      ],
      'field': [
        {
          'id': 2000790668,
          'name': 'Viewpoints'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 74,
      'reference_count': 33
    },
    {
      'id': 321866870,
      'title': 'A Novel Sparsity Measure for Tensor Recovery',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101657738,
          'abbr': 'LCU',
          'name': 'Liaocheng University'
        }
      ],
      'author': [
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1226227065,
          'name': 'Xu Kong'
        },
        {
          'id': 1097800340,
          'name': 'Qi Xie'
        },
        {
          'id': 1045904497,
          'name': 'Wenfei Cao'
        },
        {
          'id': 1212993904,
          'name': 'Yao Wang'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        }
      ],
      'field': [
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2036558582,
          'name': 'Kronecker delta'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 53
    },
    {
      'id': 328827580,
      'title': 'Pan-Sharpening with a Hyper-Laplacian Penalty',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1036461107,
          'name': 'Yiyong Jiang'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        },
        {
          'id': 1047270814,
          'name': 'Delu Zeng'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1005874265,
          'name': 'John W. Paisley'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2032805116,
          'name': 'Sub-pixel resolution'
        },
        {
          'id': 2027716576,
          'name': 'Spectral resolution'
        },
        {
          'id': 2041758568,
          'name': 'Laplace operator'
        },
        {
          'id': 2003093565,
          'name': 'Fourier transform'
        },
        {
          'id': 2020237083,
          'name': 'Sharpening'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 29
    },
    {
      'id': 333356582,
      'title': 'Dynamic Texture Recognition via Orthogonal Tensor Dictionary Learning',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1181038210,
          'name': 'Yuhui Quan'
        },
        {
          'id': 1220653455,
          'name': 'Yan Huang'
        },
        {
          'id': 1186862432,
          'name': 'Hui Ji'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2021030056,
          'name': 'Spacetime'
        },
        {
          'id': 2035050575,
          'name': 'Orthogonality'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 50
    },
    {
      'id': 339391646,
      'title': 'Scalable Person Re-identification: A Benchmark',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1454407302,
          'name': 'Liyue Shen'
        },
        {
          'id': 1299899041,
          'name': 'Lu Tian'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2009605270,
          'name': 'VIPeR'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 524,
      'reference_count': 41
    },
    {
      'id': 345385620,
      'title': 'Personalized Age Progression with Aging Dictionary',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1140220159,
          'name': 'Xiangbo Shu'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1249782652,
          'name': 'Hanjiang Lai'
        },
        {
          'id': 1350047351,
          'name': 'Luoqi Liu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027478313,
          'name': 'Age progression'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 37
    },
    {
      'id': 347894807,
      'title': 'RIDE: Reversal Invariant Descriptor Enhancement',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043378590,
          'name': 'Orientation (computer vision)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 57
    },
    {
      'id': 348911761,
      'title': 'Piecewise Flat Embedding for Image Segmentation',
      'affiliation': [
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1485462380,
          'name': 'Yizhou Yu'
        },
        {
          'id': 1357802392,
          'name': 'Chaowei Fang'
        },
        {
          'id': 1061710418,
          'name': 'Zicheng Liao'
        }
      ],
      'field': [
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2036076047,
          'name': 'Linkless embedding'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 60
    },
    {
      'id': 352944630,
      'title': 'Sparse Subspace Clustering for Incomplete Images',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1166411341,
          'name': 'Xiao Wen'
        },
        {
          'id': 1376960313,
          'name': 'Linbo Qiao'
        },
        {
          'id': 1373595740,
          'name': 'Shiqian Ma'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1328958645,
          'name': 'Hong Cheng'
        }
      ],
      'field': [
        {
          'id': 2000809816,
          'name': 'Total variation denoising'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 26
    },
    {
      'id': 355361747,
      'title': 'Mode-Seeking on Hypergraphs for Robust Geometric Model Fitting',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1401008304,
          'name': 'Hanzi Wang'
        },
        {
          'id': 1188458451,
          'name': 'Guobao Xiao'
        },
        {
          'id': 1223048328,
          'name': 'Yan Yan'
        },
        {
          'id': 1333605997,
          'name': 'David Suter'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029735533,
          'name': 'Data point'
        },
        {
          'id': 2002436320,
          'name': 'Hypergraph'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2045308702,
          'name': 'Constraint graph'
        },
        {
          'id': 2037853101,
          'name': 'Mode (statistics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2000499954,
          'name': 'Geometric modeling'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 21
    },
    {
      'id': 355817754,
      'title': 'Robust Non-rigid Motion Tracking and Surface Reconstruction Using L0 Regularization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1111748420,
          'name': 'Kaiwen Guo'
        },
        {
          'id': 1098810408,
          'name': 'Feng Xu'
        },
        {
          'id': 1181011943,
          'name': 'Yangang Wang'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2016313978,
          'name': 'Surface reconstruction'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2018274698,
          'name': 'Tracking error'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2005202005,
          'name': 'Match moving'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 35
    },
    {
      'id': 357328162,
      'title': 'MMSS: Multi-modal Sharable and Specific Feature Learning for RGB-D Object Recognition',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1382569636,
          'name': 'Anran Wang'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1424297808,
          'name': 'Tat-Jen Cham'
        }
      ],
      'field': [
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036494929,
          'name': 'Intuition'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 35
    },
    {
      'id': 358608731,
      'title': 'Saliency Detection Using Quaternion Sparse Reconstruction',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1045817961,
          'name': 'Yi Zeng'
        },
        {
          'id': 1124030868,
          'name': 'Yi Xu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2000652851,
          'name': 'Color histogram'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2029730892,
          'name': 'Channel (digital image)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 30
    },
    {
      'id': 360608426,
      'title': 'Local Subspace Collaborative Tracking',
      'affiliation': [
        {
          'id': 2103743992,
          'abbr': 'WZU',
          'name': 'Wenzhou University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1338596283,
          'name': 'Lin Ma'
        },
        {
          'id': 1076470804,
          'name': 'Xiaoqin Zhang'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036032756,
          'name': 'Tangent'
        },
        {
          'id': 2003522973,
          'name': 'Random subspace method'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 34
    },
    {
      'id': 363448640,
      'title': 'Query Adaptive Similarity Measure for RGB-D Object Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1487923076,
          'name': 'Yanhua Cheng'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1318990977,
          'name': 'Chi Zhang'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1209252863,
          'name': 'Xin Zhao'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043537062,
          'name': '3D single-object recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014250958,
          'name': 'Depth perception'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 29
    },
    {
      'id': 365487054,
      'title': 'Improving Image Restoration with Soft-Rounding',
      'affiliation': [
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1483429238,
          'name': 'Xing Mei'
        },
        {
          'id': 1432492473,
          'name': 'Honggang Qi'
        },
        {
          'id': 1341931364,
          'name': 'Bao-Gang Hu'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2028029017,
          'name': 'Regularized least squares'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2043932775,
          'name': 'Penalty method'
        },
        {
          'id': 2018501765,
          'name': 'Barcode'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2044692108,
          'name': 'Rounding'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 30
    },
    {
      'id': 373471355,
      'title': 'Square Localization for Efficient and Accurate Object Detection',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        },
        {
          'id': 1391997077,
          'name': 'Yongyi Lu'
        },
        {
          'id': 1012393522,
          'name': 'Hao. Chen'
        },
        {
          'id': 1212626136,
          'name': 'Chi-Keung Tang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023746547,
          'name': 'Aspect ratio (image)'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004161278,
          'name': 'Binary search algorithm'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 22
    },
    {
      'id': 374599751,
      'title': 'Similarity Gaussian Process Latent Variable Model for Multi-modal Data Analysis',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1272080636,
          'name': 'Guoli Song'
        },
        {
          'id': 1265461014,
          'name': 'Shuhui Wang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2035842457,
          'name': 'Gaussian process'
        },
        {
          'id': 2046578037,
          'name': 'Latent variable model'
        },
        {
          'id': 2030753631,
          'name': 'Probabilistic latent semantic analysis'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2010727398,
          'name': 'Latent class model'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 27
    },
    {
      'id': 374792237,
      'title': 'Multiple Granularity Descriptors for Fine-Grained Categorization',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2104027711,
          'abbr': '',
          'name': 'New York University Shanghai'
        }
      ],
      'author': [
        {
          'id': 1169194408,
          'name': 'Dequan Wang'
        },
        {
          'id': 1316790742,
          'name': 'Zhiqiang Shen'
        },
        {
          'id': 1049338296,
          'name': 'Jie Shao'
        },
        {
          'id': 1039939827,
          'name': 'Wei Zhang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        },
        {
          'id': 1350894404,
          'name': 'Zheng Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 36
    },
    {
      'id': 375321653,
      'title': 'Top Rank Supervised Binary Coding for Visual Search',
      'affiliation': [
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        }
      ],
      'author': [
        {
          'id': 1094488426,
          'name': 'Dongjin Song'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1342769626,
          'name': 'David A. Meyer'
        },
        {
          'id': 1005841685,
          'name': 'John R. Smith'
        }
      ],
      'field': [
        {
          'id': 2005832358,
          'name': 'Stochastic gradient descent'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2025428103,
          'name': 'Huffman coding'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035961396,
          'name': 'Coding theory'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021665374,
          'name': 'Context-adaptive binary arithmetic coding'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 32
    },
    {
      'id': 375479523,
      'title': 'Video Matting via Sparse and Low-Rank Representation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1383637716,
          'name': 'Dongqing Zou'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1064822622,
          'name': 'Guangying Cao'
        },
        {
          'id': 1048568178,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 35
    },
    {
      'id': 379665108,
      'title': 'Conformal and Low-Rank Sparse Representation for Image Restoration',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1434502322,
          'name': 'Jianwei Li'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1383637716,
          'name': 'Dongqing Zou'
        },
        {
          'id': 1380265923,
          'name': 'Bo Gao'
        },
        {
          'id': 1176365342,
          'name': 'Wei Teng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2045022011,
          'name': 'Data structure'
        },
        {
          'id': 2048590101,
          'name': 'Conformal map'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 36
    },
    {
      'id': 402165093,
      'title': 'Fine-Grained Change Detection of Misaligned Scenes with Varied Illuminations',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1096901309,
          'name': 'Wei Feng'
        },
        {
          'id': 1351293465,
          'name': 'Fei-Peng Tian'
        },
        {
          'id': 1431546386,
          'name': 'Qian Zhang'
        },
        {
          'id': 1051355147,
          'name': 'Nan Zhang'
        },
        {
          'id': 1119606354,
          'name': 'Liang Wan'
        },
        {
          'id': 1241260093,
          'name': 'Jizhou Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011282698,
          'name': 'Change detection'
        },
        {
          'id': 2003450942,
          'name': 'Image-based lighting'
        },
        {
          'id': 2004433121,
          'name': 'Relocation'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 30
    },
    {
      'id': 404111917,
      'title': 'Human Parsing with Contextualized Convolutional Neural Network',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1290256705,
          'name': 'Chunyan Xu'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1224129051,
          'name': 'Jianchao Yang'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2001050252,
          'name': 'Neighborhood context'
        },
        {
          'id': 2033899647,
          'name': 'Voting'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 89,
      'reference_count': 30
    },
    {
      'id': 413577768,
      'title': 'Towards Computational Baby Learning: A Weakly-Supervised Approach for Object Detection',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1354534685,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1235764616,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1350047351,
          'name': 'Luoqi Liu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 81,
      'reference_count': 43
    },
    {
      'id': 413577877,
      'title': 'Relaxed Multiple-Instance SVM with Application to Object Discovery',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1378785300,
          'name': 'Zhuotun Zhu'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026800590,
          'name': 'Gradient descent'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 39
    },
    {
      'id': 424673405,
      'title': 'Automatic Concept Discovery from Parallel Text and Visual Corpora',
      'affiliation': [
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        }
      ],
      'author': [
        {
          'id': 1410350428,
          'name': 'Chen Sun'
        },
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1112319534,
          'name': 'Ramakant Nevatia'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 53,
      'reference_count': 48
    },
    {
      'id': 42919284,
      'title': 'Extraction of Virtual Baselines from Distorted Document Images Using Curvilinear Projection',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1296467528,
          'name': 'Gaofeng Meng'
        },
        {
          'id': 1003011634,
          'name': 'Zuming Huang'
        },
        {
          'id': 1241142768,
          'name': 'Yonghong Song'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1114885725,
          'name': 'Chunhong Pan'
        }
      ],
      'field': [
        {
          'id': 2027089070,
          'name': 'Baseline (configuration management)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2034934163,
          'name': 'Curvilinear coordinates'
        },
        {
          'id': 2016797905,
          'name': 'Parallel'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 28
    },
    {
      'id': 434327762,
      'title': 'Adaptively Unified Semi-Supervised Dictionary Learning with Active Points',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1080319340,
          'name': 'Xiaobo Wang'
        },
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2000956888,
          'name': 'Test data'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 25
    },
    {
      'id': 435064069,
      'title': 'Deep Label Distribution Learning for Apparent Age Estimation',
      'affiliation': [
        {
          'id': 2104085410,
          'abbr': 'SEU',
          'name': 'Southeast University'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1238942844,
          'name': 'Xu Yang'
        },
        {
          'id': 1107807353,
          'name': 'Bin-Bin Gao'
        },
        {
          'id': 1178587074,
          'name': 'Chao Xing'
        },
        {
          'id': 1027546274,
          'name': 'Zeng-Wei Huo'
        },
        {
          'id': 1453321753,
          'name': 'Xiu-Shen Wei'
        },
        {
          'id': 1098843161,
          'name': 'Ying Zhou'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1356713651,
          'name': 'Xin Geng'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041011304,
          'name': 'Apparent age'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 18
    },
    {
      'id': 436540946,
      'title': 'Convolutional Sparse Coding for Image Super-Resolution',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1219372957,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1097800340,
          'name': 'Qi Xie'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 120,
      'reference_count': 35
    },
    {
      'id': 446800974,
      'title': 'Learning Discriminative Reconstructions for Unsupervised Outlier Removal',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1104529640,
          'name': 'Yan Xia'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042314525,
          'name': 'Noisy data'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 29
    },
    {
      'id': 451978091,
      'title': 'Augmenting Strong Supervision Using Web Data for Fine-Grained Categorization',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1108691835,
          'name': 'Zhe Xu'
        },
        {
          'id': 1308105996,
          'name': 'Shaoli Huang'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2031569861,
          'name': 'Performance improvement'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 35
    },
    {
      'id': 458957952,
      'title': 'Partial Person Re-Identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1131472851,
          'name': 'Xiang Li'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 34
    },
    {
      'id': 460525435,
      'title': '3D Fragment Reassembly Using Integrated Template Guidance and Fracture-Region Matching',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101478610,
          'abbr': 'LSU',
          'name': 'Louisiana State University'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1010961365,
          'name': 'Kang Zhang'
        },
        {
          'id': 1428867388,
          'name': 'Wuyi Yu'
        },
        {
          'id': 1135996943,
          'name': 'Mary H. Manhein'
        },
        {
          'id': 1190641000,
          'name': 'Warren N. Waggenspack'
        },
        {
          'id': 1053072002,
          'name': 'Xin Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 37
    },
    {
      'id': 469320206,
      'title': 'Illumination Robust Color Naming via Label Propagation',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1386612941,
          'name': 'Yuanliu Liu'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1471304424,
          'name': 'Badong Chen'
        },
        {
          'id': 1221299331,
          'name': 'Jianru Xue'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2020489969,
          'name': 'HSL and HSV'
        },
        {
          'id': 2000652851,
          'name': 'Color histogram'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026167168,
          'name': 'Color quantization'
        },
        {
          'id': 2003825461,
          'name': 'Color normalization'
        },
        {
          'id': 2018889831,
          'name': 'Color balance'
        },
        {
          'id': 2011943155,
          'name': 'False color'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 28
    },
    {
      'id': 471907725,
      'title': 'Video Super-Resolution via Deep Draft-Ensemble Learning',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1308454511,
          'name': 'Renjie Liao'
        },
        {
          'id': 1474323680,
          'name': 'Xin Tao'
        },
        {
          'id': 1227076135,
          'name': 'Ruiyu Li'
        },
        {
          'id': 1329825635,
          'name': 'Ziyang Ma'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2015539801,
          'name': 'Ensemble learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032550953,
          'name': 'Feed forward'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 71,
      'reference_count': 20
    },
    {
      'id': 476826458,
      'title': 'Low-Rank Matrix Factorization under General Mixture Noise Distributions',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1254211009,
          'name': 'Xiangyong Cao'
        },
        {
          'id': 1094329976,
          'name': 'Yang Chen'
        },
        {
          'id': 1430322413,
          'name': 'Qian Zhao'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1212993904,
          'name': 'Yao Wang'
        },
        {
          'id': 1002162013,
          'name': 'Dong Wang'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002073950,
          'name': 'Expectation–maximization algorithm'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2033464047,
          'name': 'Low-rank approximation'
        },
        {
          'id': 2037601121,
          'name': 'Gaussian noise'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2047028141,
          'name': 'Norm (mathematics)'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 43
    },
    {
      'id': 483104714,
      'title': 'Co-Interest Person Detection from Multiple Wearable Camera Videos',
      'affiliation': [
        {
          'id': 2102246611,
          'abbr': 'USC',
          'name': 'University of South Carolina'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1385665334,
          'name': 'Yuewei Lin'
        },
        {
          'id': 1177145473,
          'name': 'Kareem Abdelfatah'
        },
        {
          'id': 1371399143,
          'name': 'Youjie Zhou'
        },
        {
          'id': 1295858555,
          'name': 'Xiaochuan Fan'
        },
        {
          'id': 1197509688,
          'name': 'Hongkai Yu'
        },
        {
          'id': 1135110895,
          'name': 'Hui Qian'
        },
        {
          'id': 1390053351,
          'name': 'Song Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039447983,
          'name': 'Data collection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000619510,
          'name': 'Wearable computer'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': 'https://cse.sc.edu/~songwang/document/iccv15.pdf',
      'citation_count': 1,
      'reference_count': 36
    },
    {
      'id': 483969162,
      'title': 'Cross-Domain Image Retrieval with a Dual Attribute-Aware Ranking Network',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1199662535,
          'name': 'Junshi Huang'
        },
        {
          'id': 1063600234,
          'name': 'Rogério Schmidt Feris'
        },
        {
          'id': 1276541054,
          'name': 'Qiang Chen'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 112,
      'reference_count': 50
    },
    {
      'id': 486752624,
      'title': 'Deformable 3D Fusion: From Partial Dynamic 3D Observations to Complete 4D Models',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104258153,
          'abbr': 'NICTA',
          'name': 'NICTA'
        }
      ],
      'author': [
        {
          'id': 1235834455,
          'name': 'Weipeng Xu'
        },
        {
          'id': 1341663681,
          'name': 'Mathieu Salzmann'
        },
        {
          'id': 1129052117,
          'name': 'Yongtian Wang'
        },
        {
          'id': 1013915283,
          'name': 'Yue Liu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045712947,
          'name': 'Online algorithm'
        },
        {
          'id': 2006137252,
          'name': 'Active shape model'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 42
    },
    {
      'id': 49528606,
      'title': 'Hierarchical Convolutional Features for Visual Tracking',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1053338084,
          'name': 'Chao Ma'
        },
        {
          'id': 1484879248,
          'name': 'Jia-Bin Huang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 530,
      'reference_count': 40
    },
    {
      'id': 52999369,
      'title': 'Task-Driven Feature Pooling for Image Classification',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1140713477,
          'name': 'Guo-Sen Xie'
        },
        {
          'id': 1279838029,
          'name': 'Xu-Yao Zhang'
        },
        {
          'id': 1140220159,
          'name': 'Xiangbo Shu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1429067054,
          'name': 'Cheng-Lin Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 43
    },
    {
      'id': 60743974,
      'title': 'Patch Group Based Nonlocal Self-Similarity Prior Learning for Image Denoising',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1446421712,
          'name': 'Jun Xu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042800996,
          'name': 'Noise measurement'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000338234,
          'name': 'Non-local means'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        },
        {
          'id': 2029117650,
          'name': 'Self-similarity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 91,
      'reference_count': 34
    },
    {
      'id': 61617604,
      'title': 'Robust Matrix Regression for Illumination and Occlusion Tolerant Face Recognition',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1165446266,
          'name': 'Jianchun Xie'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        },
        {
          'id': 1143500098,
          'name': 'Jianjun Qian'
        },
        {
          'id': 1178629966,
          'name': 'Ying Tai'
        }
      ],
      'field': [
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002813706,
          'name': 'Linear programming'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 23
    },
    {
      'id': 64988986,
      'title': 'Unsupervised Trajectory Clustering via Adaptive Multi-kernel-Based Shrinkage',
      'affiliation': [
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1122096088,
          'name': 'Hongteng Xu'
        },
        {
          'id': 1153201916,
          'name': 'Yang Zhou'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        }
      ],
      'field': [
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2040176042,
          'name': 'Shrinkage'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 33
    },
    {
      'id': 74169612,
      'title': 'Co-Interest Person Detection from Multiple Wearable Camera Videos',
      'affiliation': [
        {
          'id': 2102246611,
          'abbr': 'USC',
          'name': 'University of South Carolina'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1385665334,
          'name': 'Yuewei Lin'
        },
        {
          'id': 1177145473,
          'name': 'Kareem Abdelfatah'
        },
        {
          'id': 1371399143,
          'name': 'Youjie Zhou'
        },
        {
          'id': 1295858555,
          'name': 'Xiaochuan Fan'
        },
        {
          'id': 1197509688,
          'name': 'Hongkai Yu'
        },
        {
          'id': 1135110895,
          'name': 'Hui Qian'
        },
        {
          'id': 1390053351,
          'name': 'Song Wang'
        }
      ],
      'field': [
        {
          'id': 2039447983,
          'name': 'Data collection'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2000619510,
          'name': 'Wearable computer'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030406334,
          'name': 'Multimedia'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 35
    },
    {
      'id': 74538490,
      'title': 'Delving Deep into Rectifiers: Surpassing Human-Level Performance on ImageNet Classification',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1038906976,
          'name': 'Shaoqing Ren'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000384351,
          'name': 'Rectifier'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042893011,
          'name': 'Vanishing gradient problem'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028036357,
          'name': 'Rectifier (neural networks)'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 3618,
      'reference_count': 40
    },
    {
      'id': 77552027,
      'title': 'Contour Guided Hierarchical Model for Shape Matching',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1016172729,
          'name': 'Yuanqi Su'
        },
        {
          'id': 1065458831,
          'name': 'Yuehu Liu'
        },
        {
          'id': 1097238753,
          'name': 'Bonan Cuan'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2034727396,
          'name': 'STAR model'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2012493528,
          'name': 'Hierarchical database model'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 27
    },
    {
      'id': 89595104,
      'title': 'Unsupervised Synchrony Discovery in Human Interaction',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103284627,
          'abbr': 'UM',
          'name': 'University of Miami'
        }
      ],
      'author': [
        {
          'id': 1357110762,
          'name': 'Wen-Sheng Chu'
        },
        {
          'id': 1162783045,
          'name': 'Jiabei Zeng'
        },
        {
          'id': 1300722889,
          'name': 'Fernando De la Torre'
        },
        {
          'id': 1442054127,
          'name': 'Jeffrey F. Cohn'
        },
        {
          'id': 1194448795,
          'name': 'Daniel S. Messinger'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035516905,
          'name': 'Social relation'
        },
        {
          'id': 2029148409,
          'name': 'Brute-force search'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000625043,
          'name': 'Social environment'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2031300126,
          'name': 'Interpersonal communication'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 42
    },
    {
      'id': 89763009,
      'title': 'Image Saliency Detection with Sparse Representation of Learnt Texture Atoms',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1157400121,
          'name': 'Lai Jiang'
        },
        {
          'id': 1118664104,
          'name': 'Mai Xu'
        },
        {
          'id': 1324286610,
          'name': 'Zhaoting Ye'
        },
        {
          'id': 1287578436,
          'name': 'Zulin Wang'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 28
    },
    {
      'id': 96419543,
      'title': 'Automatic Thumbnail Generation Based on Visual Representativeness and Foreground Recognizability',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1469997256,
          'name': 'Jingwei Huang'
        },
        {
          'id': 1220714408,
          'name': 'Huarong Chen'
        },
        {
          'id': 1249542802,
          'name': 'Bin Wang'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043057959,
          'name': 'Thumbnail'
        },
        {
          'id': 2004484553,
          'name': 'Representativeness heuristic'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2032750659,
          'name': 'Cropping'
        }
      ],
      'venue': {
        'id': '2131868317',
        'name': 'ICCV',
        'year': 2015
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 34
    },
    {
      'id': 105758260,
      'title': 'An Adaptive Query Prototype Modeling Method for Image Search Reranking',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1297694238,
          'name': 'Hong Lu'
        },
        {
          'id': 1162925731,
          'name': 'Guobao Jiang'
        },
        {
          'id': 1445513248,
          'name': 'Bohong Yang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2039299906,
          'name': 'Ranking (information retrieval)'
        },
        {
          'id': 2008071310,
          'name': 'Query expansion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002673071,
          'name': 'Query language'
        },
        {
          'id': 2039688799,
          'name': 'Query optimization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023618696,
          'name': 'Web query classification'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2016992697,
          'name': 'Sargable'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021080722,
          'name': 'Web search query'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 29
    },
    {
      'id': 110599212,
      'title': 'Complementary Projection Hashing',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100597028,
          'abbr': 'BAIDU',
          'name': 'Baidu'
        }
      ],
      'author': [
        {
          'id': 1415829757,
          'name': 'Zhongming Jin'
        },
        {
          'id': 1341411519,
          'name': 'Yao Hu'
        },
        {
          'id': 1487785514,
          'name': 'Yue Lin'
        },
        {
          'id': 1271464355,
          'name': 'Debing Zhang'
        },
        {
          'id': 1166161583,
          'name': 'Shiding Lin'
        },
        {
          'id': 1444957931,
          'name': 'Deng Cai'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2025394644,
          'name': 'Tabulation hashing'
        },
        {
          'id': 2017191986,
          'name': 'Hopscotch hashing'
        },
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004577253,
          'name': '2-choice hashing'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 29
    },
    {
      'id': 11240883,
      'title': 'TriSI: A distinctive local surface descriptor for 3D modeling and object recognition',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1206681019,
          'name': 'Yulan Guo'
        },
        {
          'id': 1363194940,
          'name': 'Ferdous Ahmed Sohel'
        },
        {
          'id': 1236467485,
          'name': 'Mohammed Bennamoun'
        },
        {
          'id': 1026163384,
          'name': 'Min Lu'
        },
        {
          'id': 1384795503,
          'name': 'Jianwei Wan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2001568964,
          'name': 'Image registration'
        },
        {
          'id': 2018076242,
          'name': 'Local reference frame'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2016181585,
          'name': '3D modeling'
        },
        {
          'id': 2029300038,
          'name': 'Exponential map (Riemannian geometry)'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 0
    },
    {
      'id': 117958396,
      'title': 'Joint Inverted Indexing',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1104529640,
          'name': 'Yan Xia'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2045022011,
          'name': 'Data structure'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2044714245,
          'name': 'Search engine indexing'
        },
        {
          'id': 2028328836,
          'name': 'Code word'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045763819,
          'name': 'Database index'
        },
        {
          'id': 2007989407,
          'name': 'Tree structure'
        },
        {
          'id': 2039166637,
          'name': 'Inverted index'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 43,
      'reference_count': 34
    },
    {
      'id': 130133030,
      'title': 'Modeling 4D Human-Object Interactions for Event and Object Recognition',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1319862769,
          'name': 'Yibiao Zhao'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045050007,
          'name': 'Co-occurrence'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2043537062,
          'name': '3D single-object recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2024156039,
          'name': 'Interaction model'
        },
        {
          'id': 2047932954,
          'name': 'Beam search'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 71,
      'reference_count': 24
    },
    {
      'id': 143648574,
      'title': 'The Visual Object Tracking VOT2013 Challenge Results',
      'affiliation': [
        {
          'id': 2100668856,
          'abbr': 'UL',
          'name': 'University of Ljubljana'
        },
        {
          'id': 2104173829,
          'abbr': 'AIT',
          'name': 'Austrian Institute of Technology'
        },
        {
          'id': 2103081213,
          'abbr': 'BIRMINGHAM',
          'name': 'University of Birmingham'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101543683,
          'abbr': 'DST',
          'name': 'Defence Science and Technology Organisation'
        },
        {
          'id': 2104188899,
          'abbr': 'SUT',
          'name': 'Sharif University of Technology'
        },
        {
          'id': 2101043676,
          'abbr': 'NU',
          'name': 'Nile University'
        },
        {
          'id': 2103356563,
          'abbr': 'PANASONIC',
          'name': 'Panasonic'
        },
        {
          'id': 2104342132,
          'abbr': 'UNISA',
          'name': 'University of South Australia'
        },
        {
          'id': 2102903983,
          'abbr': 'UC',
          'name': 'University of Canberra'
        },
        {
          'id': 2100534300,
          'abbr': 'UM',
          'name': 'University of Malaya'
        },
        {
          'id': 2101174606,
          'abbr': 'UWE',
          'name': 'University of the West of England'
        },
        {
          'id': 2103788407,
          'abbr': 'İYTE',
          'name': 'İzmir Institute of Technology'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        },
        {
          'id': 2103804960,
          'abbr': 'UNIPARTHENOPE',
          'name': 'Parthenope University of Naples'
        },
        {
          'id': 2104239060,
          'abbr': 'KUL',
          'name': 'Kingston University'
        },
        {
          'id': 2103118419,
          'abbr': '',
          'name': 'Hitotsubashi University'
        }
      ],
      'author': [
        {
          'id': 1107745972,
          'name': 'Matej Kristan'
        },
        {
          'id': 1047308075,
          'name': 'Roman P. Pflugfelder'
        },
        {
          'id': 1016285491,
          'name': 'Ale u s Leonardis'
        },
        {
          'id': 1431263697,
          'name': 'Jiri Matas'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        },
        {
          'id': 1255681859,
          'name': 'Luka Cehovin'
        },
        {
          'id': 1352959755,
          'name': 'Georg Nebehay'
        },
        {
          'id': 1386039614,
          'name': 'Gustavo Fernández'
        },
        {
          'id': 1123696029,
          'name': 'Tomá Vojir'
        },
        {
          'id': 1435608440,
          'name': 'Adam Gatt'
        },
        {
          'id': 1160636559,
          'name': 'Ahmad Khajenezhad'
        },
        {
          'id': 1090528168,
          'name': 'Ahmed Salahledin'
        },
        {
          'id': 1002453431,
          'name': 'Ali Soltani-Farani'
        },
        {
          'id': 1430628910,
          'name': 'Ali Zarezade'
        },
        {
          'id': 1413413617,
          'name': 'Alfredo Petrosino'
        },
        {
          'id': 1219043656,
          'name': 'Anthony Milton'
        },
        {
          'id': 1496100378,
          'name': 'Behzad Bozorgtabar'
        },
        {
          'id': 1277847200,
          'name': 'Bo Li'
        },
        {
          'id': 1347708574,
          'name': 'Chee Seng Chan'
        },
        {
          'id': 1077085359,
          'name': 'Cherkeng Heng'
        },
        {
          'id': 1323383252,
          'name': 'Dale Ward'
        },
        {
          'id': 1145901644,
          'name': 'David Kearney'
        },
        {
          'id': 1498104617,
          'name': 'Dorothy Monekosso'
        },
        {
          'id': 1458097009,
          'name': 'Hakki Can Karaimer'
        },
        {
          'id': 1475678940,
          'name': 'Hamid R. Rabiee'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1436742442,
          'name': 'Jin Gao'
        },
        {
          'id': 1099072263,
          'name': 'Jingjing Xiao'
        },
        {
          'id': 1196929416,
          'name': 'Junge Zhang'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1113938605,
          'name': 'Karel Lebeda'
        },
        {
          'id': 1009766419,
          'name': 'Lijun Cao'
        },
        {
          'id': 1011506590,
          'name': 'Mario Edoardo Maresca'
        },
        {
          'id': 1026766080,
          'name': 'Mei Kuan Lim'
        },
        {
          'id': 1229928898,
          'name': 'Mohamed El Helw'
        },
        {
          'id': 1075568432,
          'name': 'Michael Felsberg'
        },
        {
          'id': 1453040407,
          'name': 'Paolo Remagnino'
        },
        {
          'id': 1250623814,
          'name': 'Richard Bowden'
        },
        {
          'id': 1104733030,
          'name': 'Roland Goecke'
        },
        {
          'id': 1456424710,
          'name': 'Rustam Stolkin'
        },
        {
          'id': 1197623463,
          'name': 'Samantha Yueying Lim'
        },
        {
          'id': 1347216018,
          'name': 'Sara Maher'
        },
        {
          'id': 1144424838,
          'name': 'Sebastien Poullot'
        },
        {
          'id': 1305231885,
          'name': 'Sebastien Wong'
        },
        {
          'id': 1294416137,
          'name': 'Shin\'Ichi Satoh'
        },
        {
          'id': 1054261251,
          'name': 'Weihua Chen'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1049184937,
          'name': 'Xiaoqin Zhang'
        },
        {
          'id': 1372846994,
          'name': 'Yang Li'
        },
        {
          'id': 1266279690,
          'name': 'Zhiheng Niu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2037131590,
          'name': 'Size change'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': 'http://votchallenge.net/vot2013/Download/vot_2013_paper.pdf',
      'citation_count': 120,
      'reference_count': 52
    },
    {
      'id': 157502831,
      'title': 'Model Recommendation with Virtual Probes for Egocentric Hand Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1187972211,
          'name': 'Cheng Li'
        },
        {
          'id': 1474780883,
          'name': 'Kris M. Kitani'
        }
      ],
      'field': [
        {
          'id': 2033199914,
          'name': 'Recommender system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 49,
      'reference_count': 19
    },
    {
      'id': 165137287,
      'title': 'Understanding High-Level Semantics by Modeling Traffic Patterns',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1337915127,
          'name': 'Hongyi Zhang'
        },
        {
          'id': 1212257794,
          'name': 'Andreas Geiger'
        },
        {
          'id': 1174742793,
          'name': 'Raquel Urtasun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023361932,
          'name': 'Scene statistics'
        },
        {
          'id': 2020132065,
          'name': 'Small number'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 32
    },
    {
      'id': 177525157,
      'title': 'Joint Optimization for Consistent Multiple Graph Matching',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        }
      ],
      'author': [
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1110689233,
          'name': 'Yu Tian'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1134501631,
          'name': 'Stephen M. Chu'
        }
      ],
      'field': [
        {
          'id': 2025353510,
          'name': 'Folded cube graph'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2040634048,
          'name': 'Factor-critical graph'
        },
        {
          'id': 2016278136,
          'name': 'Voltage graph'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2045499782,
          'name': 'Distance-hereditary graph'
        },
        {
          'id': 2044947287,
          'name': 'Butterfly graph'
        },
        {
          'id': 2020460128,
          'name': 'String graph'
        },
        {
          'id': 2046002229,
          'name': 'Simplex graph'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 47,
      'reference_count': 25
    },
    {
      'id': 17814374,
      'title': 'Human Re-identification by Matching Compositional Template with Cluster Sampling',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1079584817,
          'name': 'Yuanlu Xu'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1467929556,
          'name': 'Xiaobai Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008560764,
          'name': 'Cluster sampling'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2009050054,
          'name': 'Blossom algorithm'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023666742,
          'name': 'Markov chain Monte Carlo'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2018721129,
          'name': 'Monte Carlo method'
        },
        {
          'id': 2007775677,
          'name': 'Markov process'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 24
    },
    {
      'id': 190049500,
      'title': 'Learning to Predict Gaze in Egocentric Video',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1059831137,
          'name': 'Yin Li'
        },
        {
          'id': 1293279736,
          'name': 'Alireza Fathi'
        },
        {
          'id': 1074242694,
          'name': 'James M. Rehg'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041467086,
          'name': 'Gaze'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 124,
      'reference_count': 27
    },
    {
      'id': 20172628,
      'title': 'Semantically-Based Human Scanpath Estimation with HMMs',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1311367087,
          'name': 'Huiying Liu'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1480516861,
          'name': 'Wen Li'
        },
        {
          'id': 1032301784,
          'name': 'Min Xu'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2033570010,
          'name': 'Cauchy distribution'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043801070,
          'name': 'Lévy flight'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2011838053,
          'name': 'Attraction'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 33
    },
    {
      'id': 207038027,
      'title': 'Modeling Occlusion by Discriminative AND-OR Structures',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1173230308,
          'name': 'Bo Li'
        },
        {
          'id': 1065721166,
          'name': 'Wenze Hu'
        },
        {
          'id': 1088002310,
          'name': 'Tianfu Wu'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 35
    },
    {
      'id': 22676989,
      'title': 'Robust Matrix Factorization with Unknown Noise',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1300722889,
          'name': 'Fernando De la Torre'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2006061681,
          'name': 'Additive white Gaussian noise'
        },
        {
          'id': 2037601121,
          'name': 'Gaussian noise'
        },
        {
          'id': 2034167769,
          'name': 'Gradient noise'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003070857,
          'name': 'Value noise'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025715469,
          'name': 'Background subtraction'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 69,
      'reference_count': 44
    },
    {
      'id': 23256940,
      'title': 'Saliency Cut in Stereo Images',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1341395979,
          'name': 'Jianteng Peng'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2044077131,
          'name': 'Stereo cameras'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025684083,
          'name': 'Computer stereo vision'
        },
        {
          'id': 2023853581,
          'name': 'Stereopsis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016967103,
          'name': 'Left and right'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 20
    },
    {
      'id': 236438643,
      'title': 'Fast Neighborhood Graph Search Using Cartesian Concatenation',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1346075517,
          'name': 'Jing Wang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1311627122,
          'name': 'Rui Gan'
        },
        {
          'id': 1056811471,
          'name': 'Shipeng Li'
        },
        {
          'id': 1212262694,
          'name': 'Baining Guo'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020446020,
          'name': 'Random geometric graph'
        },
        {
          'id': 2047562386,
          'name': 'Bridge (graph theory)'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000360927,
          'name': 'Nearest neighbor graph'
        },
        {
          'id': 2044286533,
          'name': 'Graph bandwidth'
        },
        {
          'id': 2037674894,
          'name': 'Search algorithm'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2035244343,
          'name': 'Strength of a graph'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 50
    },
    {
      'id': 24349971,
      'title': 'Extensive Facial Landmark Localization with Coarse-to-Fine Convolutional Network Cascade',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1083441339,
          'name': 'Erjin Zhou'
        },
        {
          'id': 1270567884,
          'name': 'Haoqiang Fan'
        },
        {
          'id': 1454179856,
          'name': 'Zhimin Cao'
        },
        {
          'id': 1314532880,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1053152779,
          'name': 'Qi Yin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 186,
      'reference_count': 14
    },
    {
      'id': 244530229,
      'title': 'Log-Euclidean Kernels for Sparse Representation and Dictionary Learning',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101581325,
          'abbr': 'HLJU',
          'name': 'Heilongjiang University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2024511208,
          'name': 'Riemannian manifold'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2043375780,
          'name': 'Inner product space'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2004101032,
          'name': 'Euclidean space'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2021974904,
          'name': 'Differential geometry'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 78,
      'reference_count': 36
    },
    {
      'id': 252640570,
      'title': 'A Method of Perceptual-Based Shape Decomposition',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1108750670,
          'name': 'Chang Ma'
        },
        {
          'id': 1380723218,
          'name': 'Zhongqian Dong'
        },
        {
          'id': 1372819545,
          'name': 'Tingting Jiang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000345757,
          'name': 'Decomposition method (constraint satisfaction)'
        },
        {
          'id': 2036485406,
          'name': 'Quadratic equation'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2013839451,
          'name': 'Quadratic programming'
        },
        {
          'id': 2019179373,
          'name': 'Maxima and minima'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2006645328,
          'name': 'Convexity'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 23
    },
    {
      'id': 258579336,
      'title': 'Saliency Detection via Dense and Sparse Reconstruction',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1229516554,
          'name': 'Xiaohui Li'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035842457,
          'name': 'Gaussian process'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001928736,
          'name': 'Bayes\' theorem'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2048841513,
          'name': 'Background noise'
        },
        {
          'id': 2045181672,
          'name': 'Precision and recall'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 367,
      'reference_count': 24
    },
    {
      'id': 260216542,
      'title': 'Learning Hash Codes with Listwise Supervision',
      'affiliation': [
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1218002805,
          'name': 'Jun Wang'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1301417276,
          'name': 'Andy Sun'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        }
      ],
      'field': [
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2030877868,
          'name': 'Hash list'
        },
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2033262770,
          'name': 'Rolling hash'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2038636016,
          'name': 'K-independent hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 90,
      'reference_count': 26
    },
    {
      'id': 26030391,
      'title': 'Constructing Adaptive Complex Cells for Robust Visual Tracking',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104724480,
          'abbr': 'Kyodai',
          'name': 'Kyoto University'
        }
      ],
      'author': [
        {
          'id': 1037882621,
          'name': 'Dapeng Chen'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1448998456,
          'name': 'Yang Wu'
        },
        {
          'id': 1375509396,
          'name': 'Geng Zhang'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035144924,
          'name': 'Complex cell'
        },
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 34
    },
    {
      'id': 280042948,
      'title': 'Coupling Alignments with Recognition for Still-to-Video Face Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1030210329,
          'name': 'Zhiwu Huang'
        },
        {
          'id': 1415357977,
          'name': 'Xiaowei Zhao'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2043537062,
          'name': '3D single-object recognition'
        },
        {
          'id': 2041111693,
          'name': 'Lagrange multiplier'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 19
    },
    {
      'id': 284628065,
      'title': 'Linear Sequence Discriminant Analysis: A Model-Based Dimensionality Reduction Method for Vector Sequences',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1058956845,
          'name': 'Bing Su'
        },
        {
          'id': 1234300559,
          'name': 'Xiaoqing Ding'
        }
      ],
      'field': [
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2000585400,
          'name': 'Special case'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048015713,
          'name': 'Eigendecomposition of a matrix'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 32
    },
    {
      'id': 28927663,
      'title': 'Learning Discriminative Composition Detectors for Image Classification and Cosegmentation',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102044357,
          'abbr': 'ENS',
          'name': 'École Normale Supérieure'
        }
      ],
      'author': [
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1461311619,
          'name': 'Jean Ponce'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 84,
      'reference_count': 42
    },
    {
      'id': 299003264,
      'title': 'SYM-FISH: A Symmetry-Aware Flip Invariant Sketch Histogram Shape Descriptor',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1111205108,
          'name': 'Hua Zhang'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2037552790,
          'name': 'Flip'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004351181,
          'name': 'Shape context'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 24
    },
    {
      'id': 302007640,
      'title': 'NEIL: Extracting Visual Knowledge from Web Data',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        }
      ],
      'author': [
        {
          'id': 1350530735,
          'name': 'Xinlei Chen'
        },
        {
          'id': 1441187167,
          'name': 'Abhinav Shrivastava'
        },
        {
          'id': 1305493357,
          'name': 'Abhinav Gupta'
        }
      ],
      'field': [
        {
          'id': 2044172385,
          'name': 'Knowledge base'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031634033,
          'name': 'Computer program'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033516654,
          'name': 'Knowledge acquisition'
        },
        {
          'id': 2007939929,
          'name': 'Ontology'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2046794490,
          'name': 'Data mining'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006310969,
          'name': 'The Internet'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 315,
      'reference_count': 39
    },
    {
      'id': 325931499,
      'title': 'Cosegmentation and Cosketch by Unsupervised Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1128144622,
          'name': 'Ying Nian Wu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2000871037,
          'name': 'Market segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 22
    },
    {
      'id': 326198614,
      'title': 'Recognising Human-Object Interaction via Exemplar Based Modelling',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1225752713,
          'name': 'Jian-Fang Hu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        }
      ],
      'field': [
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 24
    },
    {
      'id': 332372167,
      'title': 'Capturing Global Semantic Relationships for Facial Action Unit Recognition',
      'affiliation': [
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1111238404,
          'name': 'Ziheng Wang'
        },
        {
          'id': 1448298421,
          'name': 'Yongqiang Li'
        },
        {
          'id': 1445199645,
          'name': 'Shangfei Wang'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2012493528,
          'name': 'Hierarchical database model'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2039193354,
          'name': 'Restricted Boltzmann machine'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 26
    },
    {
      'id': 338648466,
      'title': 'Cross-View Action Recognition over Heterogeneous Feature Spaces',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1077354491,
          'name': 'Xinxiao Wu'
        },
        {
          'id': 1084255051,
          'name': 'Han Wang'
        },
        {
          'id': 1004347371,
          'name': 'Cuiwei Liu'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028963219,
          'name': 'Nonparametric statistics'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 18
    },
    {
      'id': 349169555,
      'title': 'Transfer Feature Learning with Joint Distribution Adaptation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103382811,
          'abbr': 'UIC',
          'name': 'University of Illinois at Chicago'
        }
      ],
      'author': [
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        },
        {
          'id': 1135870272,
          'name': 'Guiguang Ding'
        },
        {
          'id': 1458814224,
          'name': 'Jiaguang Sun'
        },
        {
          'id': 1445179598,
          'name': 'Philip S. Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038489855,
          'name': 'Dimensionality reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2001975124,
          'name': 'Joint probability distribution'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027215556,
          'name': 'Marginal distribution'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049454762,
          'name': 'Conditional probability distribution'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 279,
      'reference_count': 26
    },
    {
      'id': 368549822,
      'title': 'POP: Person Re-identification Post-rank Optimisation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1278193202,
          'name': 'Chunxiao Liu'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1200371104,
          'name': 'Guijin Wang'
        }
      ],
      'field': [
        {
          'id': 2020762433,
          'name': 'Visual search'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029148409,
          'name': 'Brute-force search'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2009605270,
          'name': 'VIPeR'
        },
        {
          'id': 2031569861,
          'name': 'Performance improvement'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 68,
      'reference_count': 28
    },
    {
      'id': 3719418,
      'title': 'Correntropy Induced L2 Graph for Robust Subspace Clustering',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1081691569,
          'name': 'Canyi Lu'
        },
        {
          'id': 1035372006,
          'name': 'Jinhui Tang'
        },
        {
          'id': 1379170123,
          'name': 'Min Lin'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        }
      ],
      'field': [
        {
          'id': 2023730139,
          'name': 'k-medians clustering'
        },
        {
          'id': 2023150563,
          'name': 'Correlation clustering'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2035951257,
          'name': 'CURE data clustering algorithm'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 24
    },
    {
      'id': 394170900,
      'title': 'Concurrent Action Detection with Structural Prediction',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1035418430,
          'name': 'Ping Wei'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        },
        {
          'id': 1319862769,
          'name': 'Yibiao Zhao'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2015400051,
          'name': 'Temporal logic'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037674894,
          'name': 'Search algorithm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046834706,
          'name': 'Wavelet'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011548508,
          'name': 'Unary operation'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 50,
      'reference_count': 22
    },
    {
      'id': 398094782,
      'title': 'Multi-view 3D Reconstruction from Uncalibrated Radially-Symmetric Cameras',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1145193766,
          'name': 'Jae-Hak Kim'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1469959957,
          'name': 'Xin Du'
        },
        {
          'id': 1228756977,
          'name': 'Jonghyuk Kim'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2013870048,
          'name': 'Camera matrix'
        },
        {
          'id': 2017968033,
          'name': 'Camera auto-calibration'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2049342884,
          'name': 'Triangulation (computer vision)'
        },
        {
          'id': 2005547078,
          'name': 'Camera resectioning'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2025519067,
          'name': 'Pinhole camera model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048582641,
          'name': 'Continuation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 29
    },
    {
      'id': 404517970,
      'title': 'Content-Aware Rotation',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1027626291,
          'name': 'Kaiming He'
        },
        {
          'id': 1338290084,
          'name': 'Huiwen Chang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2048776180,
          'name': 'Image editing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2032750659,
          'name': 'Cropping'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 28
    },
    {
      'id': 408182014,
      'title': 'Perceptual Fidelity Aware Mean Squared Error',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1430612842,
          'name': 'Wufeng Xue'
        },
        {
          'id': 1242928838,
          'name': 'Xuanqin Mou'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2001153861,
          'name': 'Mean squared error'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 28
    },
    {
      'id': 427816176,
      'title': 'Robust Trajectory Clustering for Motion Segmentation',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1376907722,
          'name': 'Feng Shi'
        },
        {
          'id': 1388410166,
          'name': 'Zhong Zhou'
        },
        {
          'id': 1210329754,
          'name': 'Jiangjian Xiao'
        },
        {
          'id': 1041697628,
          'name': 'Wei Wu'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035024680,
          'name': 'Discrete cosine transform'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 17
    },
    {
      'id': 428688065,
      'title': 'Human Attribute Recognition by Rich Appearance Dictionary',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1425930056,
          'name': 'Jungseock Joo'
        },
        {
          'id': 1114017858,
          'name': 'Shuo Wang'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 22
    },
    {
      'id': 444552994,
      'title': 'Hierarchical Composition Matching for Fine-Grained Visual Categorization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1188079795,
          'name': 'Richang Hong'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2007206616,
          'name': 'Flowchart'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 30
    },
    {
      'id': 45233343,
      'title': 'Action Recognition with Actons',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        }
      ],
      'author': [
        {
          'id': 1065629795,
          'name': 'Jun Zhu'
        },
        {
          'id': 1178704657,
          'name': 'Baoyuan Wang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 73,
      'reference_count': 37
    },
    {
      'id': 46915317,
      'title': 'Video Based Children\'s Social Behavior Classification in Peer-Play Scenarios',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2102042269,
          'abbr': 'SU',
          'name': 'Stanford University'
        }
      ],
      'author': [
        {
          'id': 1046915081,
          'name': 'Lu Tian'
        },
        {
          'id': 1130765662,
          'name': 'Dingrui Duan'
        },
        {
          'id': 1347506819,
          'name': 'Jinshi Cui'
        },
        {
          'id': 1225066035,
          'name': 'Li Wang'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        },
        {
          'id': 1129531076,
          'name': 'Hamid K. Aghajan'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027086225,
          'name': 'Parallel play'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 0
    },
    {
      'id': 470573579,
      'title': 'Unsupervised Random Forest Manifold Alignment for Lipreading',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100984406,
          'abbr': 'IMPERIAL',
          'name': 'Imperial College London'
        }
      ],
      'author': [
        {
          'id': 1464072527,
          'name': 'Yuru Pei'
        },
        {
          'id': 1216774416,
          'name': 'Tae-Kyun Kim'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036641578,
          'name': 'CLIPS'
        },
        {
          'id': 2018882205,
          'name': 'Manifold alignment'
        },
        {
          'id': 2033349180,
          'name': 'Random forest'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 60,
      'reference_count': 27
    },
    {
      'id': 474368591,
      'title': 'Saliency Detection via Absorbing Markov Chain',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1303496803,
          'name': 'Bowen Jiang'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1278278891,
          'name': 'Chuan Yang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2024822583,
          'name': 'Markov model'
        },
        {
          'id': 2015090385,
          'name': 'Discrete phase-type distribution'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2007775677,
          'name': 'Markov process'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021386343,
          'name': 'Absorbing Markov chain'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2003882441,
          'name': 'Spatial distribution'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 320,
      'reference_count': 35
    },
    {
      'id': 479243592,
      'title': 'Constant Time Weighted Median Filtering for Stereo Matching and Beyond',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100888578,
          'abbr': 'UM',
          'name': 'University of Macau'
        }
      ],
      'author': [
        {
          'id': 1329825635,
          'name': 'Ziyang Ma'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1290762362,
          'name': 'Enhua Wu'
        }
      ],
      'field': [
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2017733928,
          'name': 'Median filter'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046633279,
          'name': 'Weighted median'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 139,
      'reference_count': 34
    },
    {
      'id': 483133555,
      'title': 'Learning Graph Matching: Oriented to Category Modeling from Cluttered Scenes',
      'affiliation': [
        {
          'id': 2100269561,
          'abbr': 'UT',
          'name': 'University of Tokyo'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1430985511,
          'name': 'Quanshi Zhang'
        },
        {
          'id': 1124218438,
          'name': 'Xuan Song'
        },
        {
          'id': 1322338161,
          'name': 'Xiaowei Shao'
        },
        {
          'id': 1230185443,
          'name': 'Huijing Zhao'
        },
        {
          'id': 1182426429,
          'name': 'Ryosuke Shibasaki'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040504686,
          'name': 'Graph (abstract data type)'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 33
    },
    {
      'id': 486094163,
      'title': 'Robust Non-parametric Data Fitting for Correspondence Modeling',
      'affiliation': [
        {
          'id': 2100582848,
          'abbr': 'OBU',
          'name': 'Oxford Brookes University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        }
      ],
      'author': [
        {
          'id': 1008180239,
          'name': 'Wen-Yan Lin'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1319345273,
          'name': 'Shuai Zheng'
        },
        {
          'id': 1368374079,
          'name': 'Jiangbo Lu'
        },
        {
          'id': 1474814898,
          'name': 'Nigel Crook'
        }
      ],
      'field': [
        {
          'id': 2004635131,
          'name': 'Piecewise'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2028963219,
          'name': 'Nonparametric statistics'
        },
        {
          'id': 2039388380,
          'name': 'Panning (camera)'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2018516098,
          'name': 'Curve fitting'
        },
        {
          'id': 2031249941,
          'name': 'Spline (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 34
    },
    {
      'id': 493256417,
      'title': 'A Novel Earth Mover\'s Distance Methodology for Image Matching with Gaussian Mixture Models',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101581325,
          'abbr': 'HLJU',
          'name': 'Heilongjiang University'
        }
      ],
      'author': [
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        },
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2035842457,
          'name': 'Gaussian process'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2049479510,
          'name': 'Riemannian geometry'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2026312680,
          'name': 'Earth mover\'s distance'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017117862,
          'name': 'Information geometry'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 39,
      'reference_count': 32
    },
    {
      'id': 497688130,
      'title': 'Visual Reranking through Weakly Supervised Multi-graph Learning',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1000328586,
          'name': 'Cheng Deng'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        }
      ],
      'field': [
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038735250,
          'name': 'Image fusion'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 37
    },
    {
      'id': 498290624,
      'title': 'A Generalized Iterated Shrinkage Algorithm for Non-convex Sparse Coding',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2036083470,
          'name': 'Iterated function'
        },
        {
          'id': 2046112833,
          'name': 'Iteratively reweighted least squares'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007802918,
          'name': 'Lookup table'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 107,
      'reference_count': 43
    },
    {
      'id': 50824127,
      'title': 'Correlation Adaptive Subspace Segmentation by Trace Lasso',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1081691569,
          'name': 'Canyi Lu'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029020277,
          'name': 'Sparse matrix'
        },
        {
          'id': 2000700521,
          'name': 'Block matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2048065051,
          'name': 'Lasso (statistics)'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 108,
      'reference_count': 28
    },
    {
      'id': 68634648,
      'title': 'Efficient Salient Region Detection with Soft Image Abstraction',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101045883,
          'abbr': 'CSIR',
          'name': 'Council of Scientific and Industrial Research'
        },
        {
          'id': 2100582848,
          'abbr': 'OBU',
          'name': 'Oxford Brookes University'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        }
      ],
      'author': [
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1347007120,
          'name': 'Jonathan Warrell'
        },
        {
          'id': 1008180239,
          'name': 'Wen-Yan Lin'
        },
        {
          'id': 1319345273,
          'name': 'Shuai Zheng'
        },
        {
          'id': 1059016399,
          'name': 'Vibhav Vineet'
        },
        {
          'id': 1474814898,
          'name': 'Nigel Crook'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040500557,
          'name': 'Corner detection'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2018324713,
          'name': 'Scale space'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 343,
      'reference_count': 53
    },
    {
      'id': 760024,
      'title': 'Go-ICP: Solving 3D Registration Efficiently and Globally Optimally',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        }
      ],
      'field': [
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2001568964,
          'name': 'Image registration'
        },
        {
          'id': 2034486721,
          'name': 'Upper and lower bounds'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2019179373,
          'name': 'Maxima and minima'
        },
        {
          'id': 2047013050,
          'name': 'Iterative closest point'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130473787',
        'name': 'ICCV',
        'year': 2013
      },
      'source_url': '',
      'citation_count': 123,
      'reference_count': 35
    },
    {
      'id': 100252164,
      'title': 'Dynamic texture classification using dynamic fractal analysis',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1038629864,
          'name': 'Yong Xu'
        },
        {
          'id': 1181038210,
          'name': 'Yuhui Quan'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1186862432,
          'name': 'Hui Ji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036526479,
          'name': 'Fractal analysis'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2014681326,
          'name': 'Stochastic process'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004631569,
          'name': 'Fractal'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 63,
      'reference_count': 30
    },
    {
      'id': 108672158,
      'title': 'Superpixel tracking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1347149219,
          'name': 'Shu Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1219049225,
          'name': 'Fan Yang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2002310247,
          'name': 'Maximum likelihood'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 379,
      'reference_count': 23
    },
    {
      'id': 127388339,
      'title': 'Unsupervised learning of event AND-OR grammar and semantics from video',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1230401029,
          'name': 'Zhangzhang Si'
        },
        {
          'id': 1100917440,
          'name': 'Mingtao Pei'
        },
        {
          'id': 1336277459,
          'name': 'Benjamin Z. Yao'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2010593208,
          'name': 'Stochastic grammar'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043158358,
          'name': 'Attribute grammar'
        },
        {
          'id': 2027840070,
          'name': 'Synchronous context-free grammar'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024403018,
          'name': 'Information projection'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2046331716,
          'name': 'Grammar'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 70,
      'reference_count': 17
    },
    {
      'id': 129414228,
      'title': 'An X-T slice based method for action recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1288053077,
          'name': 'Yanhu Shan'
        },
        {
          'id': 1192322382,
          'name': 'Shiquan Wang'
        },
        {
          'id': 1070491497,
          'name': 'Zhang Zhang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2038017848,
          'name': 'Feature (machine learning)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2019370071,
          'name': 'Mel-frequency cepstrum'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 26
    },
    {
      'id': 151499827,
      'title': 'Centralized sparse representation for image restoration',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1284606133,
          'name': 'Weisheng Dong'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1291914506,
          'name': 'Guangming Shi'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 157,
      'reference_count': 29
    },
    {
      'id': 171699373,
      'title': 'Face recognition based on non-corresponding region matching',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1160287200,
          'name': 'Annan Li'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034379321,
          'name': 'Three-dimensional face recognition'
        },
        {
          'id': 2021457664,
          'name': 'Canonical correlation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 30
    },
    {
      'id': 173715037,
      'title': 'Multi-class semi-supervised SVMs with Positiveness Exclusive Regularization',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1467929556,
          'name': 'Xiaobai Liu'
        },
        {
          'id': 1481146811,
          'name': 'Xiaotong Yuan'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1092602214,
          'name': 'Hai Jin'
        }
      ],
      'field': [
        {
          'id': 2049789161,
          'name': 'Manifold regularization'
        },
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2031693541,
          'name': 'Regularization perspectives on support vector machines'
        },
        {
          'id': 2002491204,
          'name': 'Multiclass classification'
        },
        {
          'id': 2034845953,
          'name': 'Multi-task learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 25
    },
    {
      'id': 205519531,
      'title': 'Local Intensity Order Pattern for feature description',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1462635307,
          'name': 'Zhenhua Wang'
        },
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1163323626,
          'name': 'Fuchao Wu'
        }
      ],
      'field': [
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2040637160,
          'name': 'Indexation'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 207,
      'reference_count': 24
    },
    {
      'id': 21052644,
      'title': 'Minimum near-convex decomposition for robust shape representation',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1390587269,
          'name': 'Zhou Ren'
        },
        {
          'id': 1262920571,
          'name': 'Junsong Yuan'
        },
        {
          'id': 1471528796,
          'name': 'Chunyuan Li'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        }
      ],
      'field': [
        {
          'id': 2040757760,
          'name': 'Heuristic (computer science)'
        },
        {
          'id': 2045241704,
          'name': 'Branch and bound'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2010076166,
          'name': 'Combinatorial optimization'
        },
        {
          'id': 2005133827,
          'name': 'Discrete optimization'
        },
        {
          'id': 2000345757,
          'name': 'Decomposition method (constraint satisfaction)'
        },
        {
          'id': 2037296273,
          'name': 'Robust optimization'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2004328858,
          'name': 'Subgradient method'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 18
    },
    {
      'id': 216458086,
      'title': 'Fisher Discrimination Dictionary Learning for sparse representation',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1366770608,
          'name': 'Meng Meng Yang'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041743393,
          'name': 'Classification scheme'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 598,
      'reference_count': 31
    },
    {
      'id': 227108503,
      'title': 'Simultaneous multi-body stereo and segmentation',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1439429759,
          'name': 'Guofeng Zhang'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2023853581,
          'name': 'Stereopsis'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 32
    },
    {
      'id': 229307959,
      'title': 'Structure context of local features in realistic human action recognition',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1370497493,
          'name': 'Qiuxia Wu'
        },
        {
          'id': 1340101893,
          'name': 'Shiyang Lu'
        },
        {
          'id': 1217553554,
          'name': 'Zhiyong Wang'
        },
        {
          'id': 1022176301,
          'name': 'Feiqi Deng'
        },
        {
          'id': 1040198230,
          'name': 'Wenxiong Kang'
        },
        {
          'id': 1468763200,
          'name': 'David Dagan Feng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004351181,
          'name': 'Shape context'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 25
    },
    {
      'id': 233122730,
      'title': 'Close the loop: Joint blind image restoration and recognition with sparse representation prior',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2104505433,
          'abbr': 'ARL',
          'name': 'United States Army Research Laboratory'
        }
      ],
      'author': [
        {
          'id': 1219158789,
          'name': 'Haichao Zhang'
        },
        {
          'id': 1224129051,
          'name': 'Jianchao Yang'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1307906244,
          'name': 'Nasser M. Nasrabadi'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 111,
      'reference_count': 20
    },
    {
      'id': 244813532,
      'title': 'Automatic salient object extraction with contextual cue',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103556481,
          'abbr': 'SIT',
          'name': 'Stevens Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1238522754,
          'name': 'Le Wang'
        },
        {
          'id': 1221299331,
          'name': 'Jianru Xue'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2013922311,
          'name': 'Bootstrapping'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 82,
      'reference_count': 26
    },
    {
      'id': 281790358,
      'title': 'Treat samples differently: Object tracking with semi-supervised online CovBoost',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1136073280,
          'name': 'Guorong Li'
        },
        {
          'id': 1370887323,
          'name': 'Lei Qin'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1408497499,
          'name': 'Junbiao Pang'
        },
        {
          'id': 1266998431,
          'name': 'Shuqiang Jiang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 25
    },
    {
      'id': 31973590,
      'title': 'Correlative multi-label multi-instance image annotation',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100496658,
          'abbr': 'UNCC',
          'name': 'University of North Carolina at Charlotte'
        }
      ],
      'author': [
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        },
        {
          'id': 1039939827,
          'name': 'Wei Zhang'
        },
        {
          'id': 1112775450,
          'name': 'Jing Zhang'
        },
        {
          'id': 1041046604,
          'name': 'Bin Wu'
        },
        {
          'id': 1307536994,
          'name': 'Jianping Fan'
        },
        {
          'id': 1240470330,
          'name': 'Yao Lu'
        }
      ],
      'field': [
        {
          'id': 2023591364,
          'name': 'Correlative'
        },
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 34
    },
    {
      'id': 320281851,
      'title': 'Salient object detection by composition',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101456381,
          'abbr': '',
          'name': 'Microsoft Research Asia (China)'
        },
        {
          'id': 2102790949,
          'abbr': '',
          'name': 'Search Technologies'
        }
      ],
      'author': [
        {
          'id': 1379805976,
          'name': 'Jie Feng'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1179111366,
          'name': 'Litian Tao'
        },
        {
          'id': 1051372047,
          'name': 'Chao Zhang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 124,
      'reference_count': 34
    },
    {
      'id': 332061339,
      'title': 'A surveillance video analysis and storage scheme for scalable synopsis browsing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1082259639,
          'name': 'Shizheng Wang'
        },
        {
          'id': 1426032199,
          'name': 'Jianwei Yang'
        },
        {
          'id': 1067486252,
          'name': 'Yanyun Zhao'
        },
        {
          'id': 1324715703,
          'name': 'Anni Cai'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2035749923,
          'name': 'Smacker video'
        },
        {
          'id': 2020288432,
          'name': 'Storage efficiency'
        },
        {
          'id': 2029110680,
          'name': 'Uncompressed video'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2028552257,
          'name': 'Video compression picture types'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2030406334,
          'name': 'Multimedia'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2014162048,
          'name': 'Multiview Video Coding'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 21
    },
    {
      'id': 342191984,
      'title': 'Structure-sensitive superpixels via geodesic distance',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101456381,
          'abbr': '',
          'name': 'Microsoft Research Asia (China)'
        }
      ],
      'author': [
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1257211451,
          'name': 'Peng Wang'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1311627122,
          'name': 'Rui Gan'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005070888,
          'name': 'Homogeneity (statistics)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032671424,
          'name': 'Geometric flow'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 56
    },
    {
      'id': 349011653,
      'title': 'Robust object tracking via online learning of adaptive appearance manifold',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1348078683,
          'name': 'Jianwei Ding'
        },
        {
          'id': 1260597004,
          'name': 'Yongzhen Huang'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        },
        {
          'id': 2018978572,
          'name': 'Incremental learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 19
    },
    {
      'id': 356077018,
      'title': 'Learning to predict the perceived visual quality of photos',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1188767194,
          'name': 'Ou Wu'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1466571977,
          'name': 'Jun Gao'
        }
      ],
      'field': [
        {
          'id': 2026310943,
          'name': 'Scalar (physics)'
        },
        {
          'id': 2020479440,
          'name': 'Regression analysis'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2026465963,
          'name': 'Categorical variable'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2034510601,
          'name': 'Stability (learning theory)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 14
    },
    {
      'id': 373405265,
      'title': 'On building an accurate stereo matching system on graphics hardware',
      'affiliation': [
        {
          'id': 2104553663,
          'abbr': '',
          'name': 'Samsung'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1483429238,
          'name': 'Xing Mei'
        },
        {
          'id': 1228085735,
          'name': 'Xun Sun'
        },
        {
          'id': 1261202138,
          'name': 'Mingcai Zhou'
        },
        {
          'id': 1288288579,
          'name': 'Shaohui Jiao'
        },
        {
          'id': 1395824258,
          'name': 'Haitao Wang'
        },
        {
          'id': 1493506431,
          'name': 'Xiaopeng Zhang'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000550506,
          'name': 'Graphics processing unit'
        },
        {
          'id': 2026360789,
          'name': 'CUDA'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008620806,
          'name': 'Graphics hardware'
        },
        {
          'id': 2031107549,
          'name': 'Implementation'
        },
        {
          'id': 2026544321,
          'name': 'Scan line'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 296,
      'reference_count': 25
    },
    {
      'id': 374850458,
      'title': 'Inferring social roles in long timespan video sequence',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1073651679,
          'name': 'Jiangen Zhang'
        },
        {
          'id': 1065721166,
          'name': 'Wenze Hu'
        },
        {
          'id': 1336277459,
          'name': 'Benjamin Z. Yao'
        },
        {
          'id': 1129052117,
          'name': 'Yongtian Wang'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2044197586,
          'name': 'Earley parser'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2038361473,
          'name': 'Visitor pattern'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046794490,
          'name': 'Data mining'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2034077824,
          'name': 'Bayesian inference'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        },
        {
          'id': 2022875756,
          'name': 'Rule-based machine translation'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 16
    },
    {
      'id': 384146413,
      'title': 'The eleventh IEEE international workshop on visual surveillance',
      'affiliation': [
        {
          'id': 2104239060,
          'abbr': 'KUL',
          'name': 'Kingston University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1284774130,
          'name': 'James Orwell'
        },
        {
          'id': 1144458667,
          'name': 'Steve J. Maybank'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035827266,
          'name': 'Eleventh'
        },
        {
          'id': 2030406334,
          'name': 'Multimedia'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 0
    },
    {
      'id': 388973690,
      'title': 'Parsing video events with goal inference and intent prediction',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1100917440,
          'name': 'Mingtao Pei'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045698071,
          'name': 'Multi-agent system'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2005723959,
          'name': 'Context-sensitive grammar'
        },
        {
          'id': 2004379144,
          'name': 'Top-down and bottom-up design'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        },
        {
          'id': 2011140625,
          'name': 'Hidden Markov model'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 94,
      'reference_count': 19
    },
    {
      'id': 392453803,
      'title': 'Salient Object Detection using concavity context',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1240470330,
          'name': 'Yao Lu'
        },
        {
          'id': 1039939827,
          'name': 'Wei Zhang'
        },
        {
          'id': 1297694238,
          'name': 'Hong Lu'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 64,
      'reference_count': 31
    },
    {
      'id': 395706040,
      'title': 'Multi-observation visual recognition via joint dynamic sparse representation',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104505433,
          'abbr': 'ARL',
          'name': 'United States Army Research Laboratory'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1219158789,
          'name': 'Haichao Zhang'
        },
        {
          'id': 1307906244,
          'name': 'Nasser M. Nasrabadi'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040757760,
          'name': 'Heuristic (computer science)'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2035434896,
          'name': 'Snapshot (computer storage)'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 12
    },
    {
      'id': 395717591,
      'title': 'Detecting individual in crowd with moving feature\'s structure consistency',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1131673103,
          'name': 'Yuanhao Yu'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2001143093,
          'name': 'Crowds'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005588265,
          'name': 'Entire human body'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 18
    },
    {
      'id': 396582448,
      'title': 'Joint reconstruction of 3D shape and non-rigid motion in a region-growing framework',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1037113684,
          'name': 'Ye Liu'
        },
        {
          'id': 1350594211,
          'name': 'Yan Qiu Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2009875918,
          'name': 'Motion field'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2017575560,
          'name': 'Estimation theory'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042114604,
          'name': 'Solid modeling'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2046248120,
          'name': 'Region growing'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 24
    },
    {
      'id': 404851172,
      'title': 'Graph mode-based contextual kernels for robust SVM tracking',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1158327425,
          'name': 'Xi Li'
        },
        {
          'id': 1111383680,
          'name': 'Anthony R. Dick'
        },
        {
          'id': 1401008304,
          'name': 'Hanzi Wang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038530156,
          'name': 'Graph theory'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2012736205,
          'name': 'Interaction information'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 25
    },
    {
      'id': 426554348,
      'title': 'Video Primal Sketch: A generic middle-level representation of video',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1028968436,
          'name': 'Zhi Han'
        },
        {
          'id': 1074722114,
          'name': 'Zongben Xu'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 24
    },
    {
      'id': 427193780,
      'title': 'Computing importance of 2D contour parts by reconstructability',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1286475599,
          'name': 'Ge Guo'
        },
        {
          'id': 1092929485,
          'name': 'Yizhou Wang'
        },
        {
          'id': 1372819545,
          'name': 'Tingting Jiang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2035951041,
          'name': 'Conditional entropy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041477979,
          'name': 'Shape analysis (digital geometry)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2048443545,
          'name': 'Uniqueness'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 30
    },
    {
      'id': 464767565,
      'title': 'Spectral learning of latent semantics for action recognition',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1332242526,
          'name': 'Zhiwu Lu'
        },
        {
          'id': 1111804441,
          'name': 'Yuxin Peng'
        },
        {
          'id': 1105054515,
          'name': 'Horace Ho-Shing Ip'
        }
      ],
      'field': [
        {
          'id': 2011320204,
          'name': 'Latent semantic analysis'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2019192226,
          'name': 'Spectral clustering'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2010372103,
          'name': 'Semantic gap'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030753631,
          'name': 'Probabilistic latent semantic analysis'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 29
    },
    {
      'id': 464850554,
      'title': 'Who Blocks Who: Simultaneous clothing segmentation for grouping images',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1357272897,
          'name': 'Nan Wang'
        },
        {
          'id': 1128486384,
          'name': 'Haizhou Ai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039877701,
          'name': 'Decision tree'
        },
        {
          'id': 2007775677,
          'name': 'Markov process'
        },
        {
          'id': 2041203790,
          'name': 'Computer graphics'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023516028,
          'name': 'Markov chain'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 50,
      'reference_count': 21
    },
    {
      'id': 468576148,
      'title': 'Sparse representation or collaborative representation: Which helps face recognition?',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1376805014,
          'name': 'Meng Yang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        }
      ],
      'field': [
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2028029017,
          'name': 'Regularized least squares'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2041743393,
          'name': 'Classification scheme'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 1177,
      'reference_count': 28
    },
    {
      'id': 481395804,
      'title': 'A linear subspace learning approach via sparse coding',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1180699841,
          'name': 'Qinghua Hu'
        },
        {
          'id': 1134961831,
          'name': 'David Zhang'
        }
      ],
      'field': [
        {
          'id': 2018347265,
          'name': 'Principal component analysis'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2014277208,
          'name': 'Clustering high-dimensional data'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008291660,
          'name': 'Projection (linear algebra)'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 26
    },
    {
      'id': 491410681,
      'title': 'Sorted Random Projections for robust texture classification',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2103632264,
          'abbr': 'UW',
          'name': 'University of Waterloo'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1144112292,
          'name': 'Paul W. Fieguth'
        },
        {
          'id': 1286465620,
          'name': 'Gangyao Kuang'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008779518,
          'name': 'Bag-of-words model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2002767584,
          'name': 'Histogram'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2044583116,
          'name': 'Random projection'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 24
    },
    {
      'id': 70264531,
      'title': 'Robust consistent correspondence between 3D non-rigid shapes based on “Dual Shape-DNA”',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1165225772,
          'name': 'Huai-Yu Wu'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2032968910,
          'name': 'Mesh generation'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2040170460,
          'name': 'Harmonic analysis'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2026937781,
          'name': 'Topology'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 44
    },
    {
      'id': 90877297,
      'title': 'Complementary hashing for approximate nearest neighbor search',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101456381,
          'abbr': '',
          'name': 'Microsoft Research Asia (China)'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1069972505,
          'name': 'Hao Xu'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1028564166,
          'name': 'Zhu Li'
        },
        {
          'id': 1378262340,
          'name': 'Gang Zeng'
        },
        {
          'id': 1152407536,
          'name': 'Shipeng Li'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        }
      ],
      'field': [
        {
          'id': 2011463706,
          'name': 'Double hashing'
        },
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2017191986,
          'name': 'Hopscotch hashing'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2001501526,
          'name': 'Dynamic perfect hashing'
        },
        {
          'id': 2038761329,
          'name': 'Feature hashing'
        },
        {
          'id': 2014123284,
          'name': 'Linear hashing'
        },
        {
          'id': 2012690106,
          'name': 'Open addressing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 118,
      'reference_count': 23
    },
    {
      'id': 93915948,
      'title': 'An automatic assembly and completion framework for fragmented skulls',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2101478610,
          'abbr': 'LSU',
          'name': 'Louisiana State University'
        }
      ],
      'author': [
        {
          'id': 1380474317,
          'name': 'Zhao Yin'
        },
        {
          'id': 1001005600,
          'name': 'Li Wei'
        },
        {
          'id': 1053072002,
          'name': 'Xin Li'
        },
        {
          'id': 1135996943,
          'name': 'Mary H. Manhein'
        }
      ],
      'field': [
        {
          'id': 2016825219,
          'name': 'Facial reconstruction'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2031757641,
          'name': 'Skull'
        },
        {
          'id': 2031402505,
          'name': 'Iterative reconstruction'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130773697',
        'name': 'ICCV',
        'year': 2011
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 21
    },
    {
      'id': 100497996,
      'title': 'SRDA: Generating Instance Segmentation Annotation via Scanning, Reasoning and Domain Adaptation',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1433214137,
          'name': 'Wenqiang Xu'
        },
        {
          'id': 1489183023,
          'name': 'Yonglu Li'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1801.08839',
      'citation_count': 3,
      'reference_count': 39
    },
    {
      'id': 1047782,
      'title': 'Deep Fashion Analysis with Feature Map Upsampling and Landmark-Driven Attention.',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1171950629,
          'name': 'Jingyuan Liu'
        },
        {
          'id': 1297694238,
          'name': 'Hong Lu'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007%2F978-3-030-11015-4_4',
      'citation_count': 3,
      'reference_count': 17
    },
    {
      'id': 104889857,
      'title': 'Zoom-Net: Mining Deep Feature Interactions for Visual Relationship Recognition',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1409513638,
          'name': 'Guojun Yin'
        },
        {
          'id': 1099193953,
          'name': 'Lu Sheng'
        },
        {
          'id': 1035353314,
          'name': 'Bin Liu'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1004481198,
          'name': 'Jing Shao'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        }
      ],
      'field': [
        {
          'id': 2045952829,
          'name': 'Predicate (grammar)'
        },
        {
          'id': 2001048872,
          'name': 'Zoom'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Guojun_Yin_Zoom-Net_Mining_Deep_ECCV_2018_paper.pdf',
      'citation_count': 14,
      'reference_count': 50
    },
    {
      'id': 105520726,
      'title': 'Adaptively Transforming Graph Matching.',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1077833492,
          'name': 'Fudong Wang'
        },
        {
          'id': 1202705676,
          'name': 'Nan Xue'
        },
        {
          'id': 1236168372,
          'name': 'Yipeng Zhang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1496072311,
          'name': 'Gui-Song Xia'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2021030056,
          'name': 'Spacetime'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2027339083,
          'name': 'Quadratic assignment problem'
        },
        {
          'id': 2011548508,
          'name': 'Unary operation'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Fudong_Wang_Adaptively_Transforming_Graph_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 41
    },
    {
      'id': 107533307,
      'title': 'Fast Light Field Reconstruction with Deep Coarse-to-Fine Modeling of Spatial-Angular Clues',
      'affiliation': [
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1330263690,
          'name': 'Henry Wing Fung Yeung'
        },
        {
          'id': 1215098602,
          'name': 'Junhui Hou'
        },
        {
          'id': 1130132659,
          'name': 'Jie Chen'
        },
        {
          'id': 1261352549,
          'name': 'Yuk Ying Chung'
        },
        {
          'id': 1022721784,
          'name': 'Xiaoming Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Henry_W._F._Yeung_Fast_Light_Field_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 29
    },
    {
      'id': 110507828,
      'title': 'Stroke Controllable Fast Style Transfer with Adaptive Receptive Fields',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1219671846,
          'name': 'Yongcheng Jing'
        },
        {
          'id': 1073563786,
          'name': 'Yang Liu'
        },
        {
          'id': 1493220987,
          'name': 'Yezhou Yang'
        },
        {
          'id': 1341913761,
          'name': 'Zunlei Feng'
        },
        {
          'id': 1485462380,
          'name': 'Yizhou Yu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2007469624,
          'name': 'Stylized fact'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002134091,
          'name': 'Stroke'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 40
    },
    {
      'id': 112777979,
      'title': 'VisDrone-DET2018: The Vision Meets Drone Object Detection in Image Challenge Results',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        },
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2100577709,
          'abbr': 'TAMU',
          'name': 'Texas A&M University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2103624135,
          'abbr': 'KU',
          'name': 'University of Kansas'
        },
        {
          'id': 2101927265,
          'abbr': 'JU',
          'name': 'Jiangnan University'
        },
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104407070,
          'abbr': 'IITSYSTEM',
          'name': 'Indian Institutes of Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104591464,
          'abbr': 'KIT',
          'name': 'Karlsruhe Institute of Technology'
        },
        {
          'id': 2101592826,
          'abbr': 'CQU',
          'name': 'Chongqing University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100944012,
          'abbr': 'NITT',
          'name': 'National Institute of Technology, Tiruchirappalli'
        },
        {
          'id': 2102361083,
          'abbr': '',
          'name': 'University of Ottawa'
        },
        {
          'id': 2100302441,
          'abbr': 'OUC',
          'name': 'Ocean University of China'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1484089631,
          'name': 'Xiao Bian'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1180699841,
          'name': 'Qinghua Hu'
        },
        {
          'id': 1432501184,
          'name': 'Qinqin Nie'
        },
        {
          'id': 1482215150,
          'name': 'Hao Cheng'
        },
        {
          'id': 1281790522,
          'name': 'Chenfeng Liu'
        },
        {
          'id': 1355714571,
          'name': 'Xiaoyu Liu'
        },
        {
          'id': 1476829622,
          'name': 'Wenya Ma'
        },
        {
          'id': 1105702711,
          'name': 'Haotian Wu'
        },
        {
          'id': 1063479420,
          'name': 'Lianjie Wang'
        },
        {
          'id': 1068590739,
          'name': 'Arne Schumann'
        },
        {
          'id': 1093984203,
          'name': 'Chase Brown'
        },
        {
          'id': 1058311947,
          'name': 'Chen Qian'
        },
        {
          'id': 1413918566,
          'name': 'Chengzheng Li'
        },
        {
          'id': 1312771884,
          'name': 'Dongdong Li'
        },
        {
          'id': 1357331676,
          'name': 'Emmanouil Michail'
        },
        {
          'id': 1100530949,
          'name': 'Fan Zhang'
        },
        {
          'id': 1297033137,
          'name': 'Feng Ni'
        },
        {
          'id': 1018594595,
          'name': 'Feng Zhu'
        },
        {
          'id': 1053910801,
          'name': 'Guanghui Wang'
        },
        {
          'id': 1498605759,
          'name': 'Haipeng Zhang'
        },
        {
          'id': 1485834090,
          'name': 'Han Deng'
        },
        {
          'id': 1128353694,
          'name': 'Hao Liu'
        },
        {
          'id': 1269515935,
          'name': 'Haoran Wang'
        },
        {
          'id': 1041390979,
          'name': 'Heqian Qiu'
        },
        {
          'id': 1432492473,
          'name': 'Honggang Qi'
        },
        {
          'id': 1209822350,
          'name': 'Honghui Shi'
        },
        {
          'id': 1254505060,
          'name': 'Hongliang Li'
        },
        {
          'id': 1313955372,
          'name': 'Hongyu Xu'
        },
        {
          'id': 1263847120,
          'name': 'Hu Lin'
        },
        {
          'id': 1383187379,
          'name': 'Ioannis Kompatsiaris'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        },
        {
          'id': 1138384790,
          'name': 'Jianqiang Wang'
        },
        {
          'id': 1319732941,
          'name': 'Jianxiu Yang'
        },
        {
          'id': 1107148318,
          'name': 'Jingkai Zhou'
        },
        {
          'id': 1025902510,
          'name': 'Juanping Zhao'
        },
        {
          'id': 1260397282,
          'name': 'K. J. Joseph'
        },
        {
          'id': 1495307565,
          'name': 'Kaiwen Duan'
        },
        {
          'id': 1269565913,
          'name': 'Karthik Suresh'
        },
        {
          'id': 1194953100,
          'name': 'Bo Ke'
        },
        {
          'id': 1288637843,
          'name': 'Ke Wang'
        },
        {
          'id': 1433987988,
          'name': 'Konstantinos Avgerinakis'
        },
        {
          'id': 1072169187,
          'name': 'Lars Wilko Sommer'
        },
        {
          'id': 1371519148,
          'name': 'Lei Zhang'
        },
        {
          'id': 1263554649,
          'name': 'Li Yang'
        },
        {
          'id': 1178518221,
          'name': 'Lin Cheng'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1350923833,
          'name': 'Liyu Lu'
        },
        {
          'id': 1294161884,
          'name': 'Lu Ding'
        },
        {
          'id': 1153959738,
          'name': 'Minyu Huang'
        },
        {
          'id': 1294137540,
          'name': 'Naveen Kumar Vedurupaka'
        },
        {
          'id': 1398938122,
          'name': 'Nehal Mamgain'
        },
        {
          'id': 1007156867,
          'name': 'Nitin Bansal'
        },
        {
          'id': 1444293670,
          'name': 'Oliver Acatay'
        },
        {
          'id': 1045596348,
          'name': 'Panagiotis Giannakeris'
        },
        {
          'id': 1028440711,
          'name': 'Qian Wang'
        },
        {
          'id': 1373975680,
          'name': 'Qijie Zhao'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1353891505,
          'name': 'Qiong Liu'
        },
        {
          'id': 1135175987,
          'name': 'Qishang Cheng'
        },
        {
          'id': 1386366177,
          'name': 'Qiuchen Sun'
        },
        {
          'id': 1095620199,
          'name': 'Robert Laganière'
        },
        {
          'id': 1082561180,
          'name': 'Sheng Jiang'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1199460125,
          'name': 'Shubo Wei'
        },
        {
          'id': 1337326228,
          'name': 'Siwei Wang'
        },
        {
          'id': 1244454774,
          'name': 'Stefanos Vrochidis'
        },
        {
          'id': 1250395464,
          'name': 'Sujuan Wang'
        },
        {
          'id': 1001683303,
          'name': 'Tiaojio Lee'
        },
        {
          'id': 1201390281,
          'name': 'Usman Sajid'
        },
        {
          'id': 1198518603,
          'name': 'Vineeth Nallure Balasubramanian'
        },
        {
          'id': 1038389562,
          'name': 'Wei Li'
        },
        {
          'id': 1392480485,
          'name': 'Wei Zhang'
        },
        {
          'id': 1132443230,
          'name': 'Weikun Wu'
        },
        {
          'id': 1006600973,
          'name': 'Wenchi Ma'
        },
        {
          'id': 1073503292,
          'name': 'Wenrui He'
        },
        {
          'id': 1138819477,
          'name': 'Wenzhe Yang'
        },
        {
          'id': 1043118709,
          'name': 'Xiaoyu Chen'
        },
        {
          'id': 1254953496,
          'name': 'Xin Sun'
        },
        {
          'id': 1109566165,
          'name': 'Xinbin Luo'
        },
        {
          'id': 1075011556,
          'name': 'Xintao Lian'
        },
        {
          'id': 1313603932,
          'name': 'Xiufang Li'
        },
        {
          'id': 1445310476,
          'name': 'Yangliu Kuai'
        },
        {
          'id': 1014730232,
          'name': 'Yali Li'
        },
        {
          'id': 1401714836,
          'name': 'Yi Luo'
        },
        {
          'id': 1011431260,
          'name': 'Yifan Zhang'
        },
        {
          'id': 1454803651,
          'name': 'Yiling Liu'
        },
        {
          'id': 1056094667,
          'name': 'Ying Li'
        },
        {
          'id': 1254750512,
          'name': 'Yong Wang'
        },
        {
          'id': 1022259518,
          'name': 'Yongtao Wang'
        },
        {
          'id': 1492616688,
          'name': 'Yuanwei Wu'
        },
        {
          'id': 1387110095,
          'name': 'Yue Fan'
        },
        {
          'id': 1305989134,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1421406788,
          'name': 'Yuqin Zhang'
        },
        {
          'id': 1166138436,
          'name': 'Zexin Wang'
        },
        {
          'id': 1474290517,
          'name': 'Zhangyang Wang'
        },
        {
          'id': 1243169801,
          'name': 'Zhaoyue Xia'
        },
        {
          'id': 1249663582,
          'name': 'Zhen Cui'
        },
        {
          'id': 1379302799,
          'name': 'Zhenwei He'
        },
        {
          'id': 1382625275,
          'name': 'Zhipeng Deng'
        },
        {
          'id': 1460662953,
          'name': 'Zhiyao Guo'
        },
        {
          'id': 1473790383,
          'name': 'Zichen Song'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008498025,
          'name': 'Drone'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025635652,
          'name': 'Truck'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_27',
      'citation_count': 4,
      'reference_count': 45
    },
    {
      'id': 113740374,
      'title': 'Unsupervised Person Re-identification by Deep Learning Tracklet Association',
      'affiliation': [
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1118700829,
          'name': 'Minxian Li'
        },
        {
          'id': 1274171319,
          'name': 'Xiatian Zhu'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046020565,
          'name': 'Labelling'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Minxian_Li_Unsupervised_Person_Re-identification_ECCV_2018_paper.pdf',
      'citation_count': 30,
      'reference_count': 58
    },
    {
      'id': 119373796,
      'title': 'Structured Siamese Network for Real-Time Visual Tracking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1000342130,
          'name': 'Yunhua Zhang'
        },
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1019616468,
          'name': 'Jinqing Qi'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1451407143,
          'name': 'Mengyang Feng'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yunhua_Zhang_Structured_Siamese_Network_ECCV_2018_paper.pdf',
      'citation_count': 24,
      'reference_count': 38
    },
    {
      'id': 119559854,
      'title': 'DeepIM: Deep Iterative Matching for 6D Pose Estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104054782,
          'abbr': 'UW',
          'name': 'University of Washington'
        }
      ],
      'author': [
        {
          'id': 1219021850,
          'name': 'Yi Li'
        },
        {
          'id': 1400982522,
          'name': 'Gu Wang'
        },
        {
          'id': 1145500222,
          'name': 'Xiangyang Ji'
        },
        {
          'id': 1314843626,
          'name': 'Yu Xiang'
        },
        {
          'id': 1335707375,
          'name': 'Dieter Fox'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 52
    },
    {
      'id': 119976937,
      'title': 'Seamless Color Mapping for 3D Reconstruction with Consumer-Grade Scanning Devices',
      'affiliation': [
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1216513150,
          'name': 'Bin Wang'
        },
        {
          'id': 1329143542,
          'name': 'Pan Pan'
        },
        {
          'id': 1363842678,
          'name': 'Qinjie Xiao'
        },
        {
          'id': 1163557884,
          'name': 'Likang Luo'
        },
        {
          'id': 1395550214,
          'name': 'Xiaofeng Ren'
        },
        {
          'id': 1424995837,
          'name': 'Rong Jin'
        },
        {
          'id': 1233848010,
          'name': 'Xiaogang Jin'
        }
      ],
      'field': [
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2037510253,
          'name': 'Texture mapping'
        },
        {
          'id': 2037918317,
          'name': 'Immersion (virtual reality)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027460845,
          'name': 'Virtual reality'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034306437,
          'name': 'Color mapping'
        },
        {
          'id': 2019078694,
          'name': 'Local color'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11009-3_39',
      'citation_count': 0,
      'reference_count': 27
    },
    {
      'id': 121779445,
      'title': 'Instance-level Human Parsing via Composition Grouping Network',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1045211755,
          'name': 'Ke Gong'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1033212554,
          'name': 'Yicheng Li'
        },
        {
          'id': 1200756431,
          'name': 'Yimin Chen'
        },
        {
          'id': 1361654017,
          'name': 'Ming Yang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046528467,
          'name': 'Intermediate language'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1808.00157',
      'citation_count': 16,
      'reference_count': 41
    },
    {
      'id': 12546482,
      'title': 'Less Is More: Picking Informative Frames for Video Captioning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1134958413,
          'name': 'Yangyu Chen'
        },
        {
          'id': 1265461014,
          'name': 'Shuhui Wang'
        },
        {
          'id': 1354296024,
          'name': 'Weigang Zhang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2037491297,
          'name': 'Best practice'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 48
    },
    {
      'id': 126639458,
      'title': 'A Joint Generative Model for Zero-Shot Learning.',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2103202347,
          'abbr': 'SCU',
          'name': 'Soochow University (Taiwan)'
        }
      ],
      'author': [
        {
          'id': 1067193344,
          'name': 'Rui Gao'
        },
        {
          'id': 1279106498,
          'name': 'Xingsong Hou'
        },
        {
          'id': 1478991474,
          'name': 'Jie Qin'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1037247309,
          'name': 'Fan Zhu'
        },
        {
          'id': 1101094851,
          'name': 'Zhao Zhang'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007%2F978-3-030-11018-5_50',
      'citation_count': 0,
      'reference_count': 42
    },
    {
      'id': 12686569,
      'title': 'Improving Deep Visual Representation for Person Re-identification by Global and Local Image-language Association',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1037882621,
          'name': 'Dapeng Chen'
        },
        {
          'id': 1099230719,
          'name': 'Hongsheng Li'
        },
        {
          'id': 1453170817,
          'name': 'Xihui Liu'
        },
        {
          'id': 1124727419,
          'name': 'Yantao Shen'
        },
        {
          'id': 1495848024,
          'name': 'Jing Shao'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026777793,
          'name': 'Noun phrase'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2001570318,
          'name': 'Association scheme'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Dapeng_Chen_Improving_Deep_Visual_ECCV_2018_paper.pdf',
      'citation_count': 13,
      'reference_count': 67
    },
    {
      'id': 127353905,
      'title': 'Learning Class Prototypes via Structure Alignment for Zero-Shot Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1013515220,
          'name': 'Huajie Jiang'
        },
        {
          'id': 1142627729,
          'name': 'Ruiping Wang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014846356,
          'name': 'Visual space'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048254713,
          'name': 'Structural alignment'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Huajie_Jiang_Learning_Class_Prototypes_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 40
    },
    {
      'id': 127736697,
      'title': 'Deep Variational Metric Learning',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1037813009,
          'name': 'Xudong Lin'
        },
        {
          'id': 1495536695,
          'name': 'Yueqi Duan'
        },
        {
          'id': 1110143082,
          'name': 'Qiyuan Dong'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xudong_Lin_Deep_Variational_Metric_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 31
    },
    {
      'id': 134810361,
      'title': 'Towards a Better Match in Siamese Network Based Visual Object Tracker',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1193966662,
          'name': 'Anfeng He'
        },
        {
          'id': 1469454660,
          'name': 'Chong Luo'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2003673557,
          'name': 'Masking (art)'
        },
        {
          'id': 2044730675,
          'name': 'Easily distracted'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1809.01368',
      'citation_count': 7,
      'reference_count': 30
    },
    {
      'id': 138350177,
      'title': 'Skeleton-Based Action Recognition with Spatial Reasoning and Temporal Stack Learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1102824081,
          'name': 'Chenyang Si'
        },
        {
          'id': 1192378153,
          'name': 'Ya Jing'
        },
        {
          'id': 1105028092,
          'name': 'Wei Wang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015253593,
          'name': 'Spatial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 34
    },
    {
      'id': 138693794,
      'title': 'Scale Aggregation Network for Accurate and Efficient Crowd Counting',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        }
      ],
      'author': [
        {
          'id': 1433185538,
          'name': 'Xinkun Cao'
        },
        {
          'id': 1046648506,
          'name': 'Zhipeng Wang'
        },
        {
          'id': 1067486252,
          'name': 'Yanyun Zhao'
        },
        {
          'id': 1204909747,
          'name': 'Fei Su'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2019362237,
          'name': 'Statistic'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2027601801,
          'name': 'Euclidean geometry'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xinkun_Cao_Scale_Aggregation_Network_ECCV_2018_paper.pdf',
      'citation_count': 35,
      'reference_count': 37
    },
    {
      'id': 140482910,
      'title': 'Exploring Visual Relationship for Image Captioning',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1462137118,
          'name': 'Yingwei Pan'
        },
        {
          'id': 1403845832,
          'name': 'Yehao Li'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2014351305,
          'name': 'Long short term memory'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ting_Yao_Exploring_Visual_Relationship_ECCV_2018_paper.pdf',
      'citation_count': 68,
      'reference_count': 40
    },
    {
      'id': 14107928,
      'title': 'Semi-dense 3D Reconstruction with a Stereo Event Camera',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2103176799,
          'abbr': 'UZH',
          'name': 'University of Zurich'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1219889864,
          'name': 'Yi Zhou'
        },
        {
          'id': 1179330004,
          'name': 'Guillermo Gallego'
        },
        {
          'id': 1475238605,
          'name': 'Henri Rebecq'
        },
        {
          'id': 1216477918,
          'name': 'Laurent Kneip'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1221042472,
          'name': 'Davide Scaramuzza'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2045982084,
          'name': 'Latency (engineering)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2021183717,
          'name': 'Simultaneous localization and mapping'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yi_Zhou_Semi-Dense_3D_Reconstruction_ECCV_2018_paper.pdf',
      'citation_count': 12,
      'reference_count': 41
    },
    {
      'id': 141354121,
      'title': 'Cross-Modal Hamming Hashing',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1027988051,
          'name': 'Yue Cao'
        },
        {
          'id': 1148092358,
          'name': 'Bin Liu'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007459778,
          'name': 'Hamming space'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2046455943,
          'name': 'Hamming distance'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2029642874,
          'name': 'Exponential distribution'
        },
        {
          'id': 2028889101,
          'name': 'Modal'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yue_Cao_Cross-Modal_Hamming_Hashing_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 44
    },
    {
      'id': 142460596,
      'title': 'DeepKSPD: Learning Kernel-Matrix-Based SPD Representation For Fine-Grained Image Recognition',
      'affiliation': [
        {
          'id': 2100460378,
          'abbr': 'UOW',
          'name': 'University of Wollongong'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1373278054,
          'name': 'Melih Engin'
        },
        {
          'id': 1384396648,
          'name': 'Lei Wang'
        },
        {
          'id': 1155171364,
          'name': 'Luping Zhou'
        },
        {
          'id': 1130844234,
          'name': 'Luping Zhou'
        },
        {
          'id': 1162571328,
          'name': 'Xinwang Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014149080,
          'name': 'Operator theory'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2049479510,
          'name': 'Riemannian geometry'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2010047798,
          'name': 'Numerical stability'
        },
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1711.html',
      'citation_count': 4,
      'reference_count': 27
    },
    {
      'id': 144844753,
      'title': 'Deep Attention Neural Tensor Network for Visual Question Answering',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1416228231,
          'name': 'Yalong Bai'
        },
        {
          'id': 1057895870,
          'name': 'Jianlong Fu'
        },
        {
          'id': 1418848072,
          'name': 'Tiejun Zhao'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2009364628,
          'name': 'Tensor product'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yalong_Bai_Deep_Attention_Neural_ECCV_2018_paper.pdf',
      'citation_count': 14,
      'reference_count': 23
    },
    {
      'id': 145184551,
      'title': 'Large Scale Urban Scene Modeling from MVS Meshes',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1235124205,
          'name': 'Lingjie Zhu'
        },
        {
          'id': 1364720438,
          'name': 'Shuhan Shen'
        },
        {
          'id': 1432038680,
          'name': 'Xiang Gao'
        },
        {
          'id': 1420413515,
          'name': 'Zhanyi Hu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2004058443,
          'name': 'Line segment'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Lingjie_Zhu_Large_Scale_Urban_ECCV_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 33
    },
    {
      'id': 146130191,
      'title': 'stagNet: An Attentive Semantic RNN for Group Activity Recognition',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1246457806,
          'name': 'Mengshi Qi'
        },
        {
          'id': 1478991474,
          'name': 'Jie Qin'
        },
        {
          'id': 1158763441,
          'name': 'Annan Li'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Mengshi_Qi_stagNet_An_Attentive_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 47
    },
    {
      'id': 149324732,
      'title': 'Learning Warped Guidance for Blind Face Restoration',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        }
      ],
      'author': [
        {
          'id': 1016223703,
          'name': 'Xiaoming Li'
        },
        {
          'id': 1166707258,
          'name': 'Ming Liu'
        },
        {
          'id': 1301609442,
          'name': 'Yuting Ye'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2000809816,
          'name': 'Total variation denoising'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiaoming_Li_Learning_Warped_Guidance_ECCV_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 50
    },
    {
      'id': 151976922,
      'title': 'Human-Centric Visual Relation Segmentation Using Mask R-CNN and VTransE.',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1321466122,
          'name': 'Fan Yu'
        },
        {
          'id': 1076148849,
          'name': 'Xin Tan'
        },
        {
          'id': 1272023527,
          'name': 'Tongwei Ren'
        },
        {
          'id': 1149328961,
          'name': 'Gangshan Wu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11012-3_44',
      'citation_count': 0,
      'reference_count': 11
    },
    {
      'id': 152924271,
      'title': 'Object-centered image stitching',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1432063854,
          'name': 'Charles Herrmann'
        },
        {
          'id': 1081170536,
          'name': 'Chen Wang'
        },
        {
          'id': 1239032261,
          'name': 'Richard Strong Bowen'
        },
        {
          'id': 1029535146,
          'name': 'Emil Keyder'
        },
        {
          'id': 1306993101,
          'name': 'Ramin Zabih'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015841962,
          'name': 'Image stitching'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Charles_Herrmann_Object-centered_image_stitching_ECCV_2018_paper.html',
      'citation_count': 1,
      'reference_count': 26
    },
    {
      'id': 15467794,
      'title': 'Rethinking the Form of Latent States in Image Captioning',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1199268427,
          'name': 'Bo Dai'
        },
        {
          'id': 1076540120,
          'name': 'Deming Ye'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2015842062,
          'name': 'Locality'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Bo_Dai_Rethinking_the_Form_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 33
    },
    {
      'id': 155221642,
      'title': 'TextSnake: A Flexible Representation for Detecting Text of Arbitrary Shapes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1073341844,
          'name': 'Shangbang Long'
        },
        {
          'id': 1305713858,
          'name': 'Jiaqiang Ruan'
        },
        {
          'id': 1450796944,
          'name': 'Wenjie Zhang'
        },
        {
          'id': 1016581233,
          'name': 'Xin He'
        },
        {
          'id': 1214777185,
          'name': 'Wenhao Wu'
        },
        {
          'id': 1021762675,
          'name': 'Cong Yao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 50
    },
    {
      'id': 156796352,
      'title': 'Joint 3D Face Reconstruction and Dense Alignment with Position Map Regression Network',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1184673234,
          'name': 'Yao Feng'
        },
        {
          'id': 1014301787,
          'name': 'Fan Wu'
        },
        {
          'id': 1052816179,
          'name': 'Xiaohu Shao'
        },
        {
          'id': 1037810470,
          'name': 'Yanfeng Wang'
        },
        {
          'id': 1446397686,
          'name': 'Xi Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042991472,
          'name': 'Map regression'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 68
    },
    {
      'id': 15856853,
      'title': 'Open-World Stereo Video Matching with Deep RNN',
      'affiliation': [
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1374135088,
          'name': 'Yiran Zhong'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        }
      ],
      'field': [
        {
          'id': 2005356592,
          'name': 'Memorization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yiran_Zhong_Open-World_Stereo_Video_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 24
    },
    {
      'id': 15863695,
      'title': 'SegStereo: Exploiting Semantic Information for Disparity Estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1467222094,
          'name': 'Guorun Yang'
        },
        {
          'id': 1462664535,
          'name': 'Hengshuang Zhao'
        },
        {
          'id': 1443702376,
          'name': 'Jianping Shi'
        },
        {
          'id': 1364679732,
          'name': 'Zhidong Deng'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026794874,
          'name': 'Semantic feature'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Guorun_Yang_SegStereo_Exploiting_Semantic_ECCV_2018_paper.pdf',
      'citation_count': 34,
      'reference_count': 42
    },
    {
      'id': 160496792,
      'title': 'Multi-Scale Structure-Aware Network for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100548198,
          'abbr': 'SUNY',
          'name': 'State University of New York System'
        }
      ],
      'author': [
        {
          'id': 1407402917,
          'name': 'Lipeng Ke'
        },
        {
          'id': 1138929517,
          'name': 'Ming-Ching Chang'
        },
        {
          'id': 1432492473,
          'name': 'Honggang Qi'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2003673557,
          'name': 'Masking (art)'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 25
    },
    {
      'id': 161414758,
      'title': 'Decouple Learning for Parameterized Image Operators',
      'affiliation': [
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        },
        {
          'id': 2104388247,
          'abbr': 'BFA',
          'name': 'Beijing Film Academy'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1133951074,
          'name': 'Qingnan Fan'
        },
        {
          'id': 1313251152,
          'name': 'Dongdong Chen'
        },
        {
          'id': 1342019393,
          'name': 'Lu Yuan'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        },
        {
          'id': 1243709913,
          'name': 'Baoquan Chen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020825559,
          'name': 'Smoothing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Qingnan_Fan_Learning_to_Learn_ECCV_2018_paper.html',
      'citation_count': 8,
      'reference_count': 47
    },
    {
      'id': 16649507,
      'title': 'Joint 3D Face Reconstruction and Dense Alignment with Position Map Regression Network',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1184673234,
          'name': 'Yao Feng'
        },
        {
          'id': 1014301787,
          'name': 'Fan Wu'
        },
        {
          'id': 1052816179,
          'name': 'Xiaohu Shao'
        },
        {
          'id': 1037810470,
          'name': 'Yanfeng Wang'
        },
        {
          'id': 1446397686,
          'name': 'Xi Zhou'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042991472,
          'name': 'Map regression'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yao_Feng_Joint_3D_Face_ECCV_2018_paper.pdf',
      'citation_count': 16,
      'reference_count': 63
    },
    {
      'id': 172638458,
      'title': 'Knowing Where to Look? Analysis on Attention of Visual Question Answering System',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1241137423,
          'name': 'Wei Li'
        },
        {
          'id': 1385342897,
          'name': 'Zehuan Yuan'
        },
        {
          'id': 1075170905,
          'name': 'Xiangzhong Fang'
        },
        {
          'id': 1247318969,
          'name': 'Changhu Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006037874,
          'name': 'Data science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1810.html',
      'citation_count': 2,
      'reference_count': 14
    },
    {
      'id': 175400555,
      'title': 'Efficient Semantic Scene Completion Network with Spatial Group Convolution',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1054479440,
          'name': 'Jiahui Zhang'
        },
        {
          'id': 1263201152,
          'name': 'Hao Zhao'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1168982309,
          'name': 'Li Zhang'
        },
        {
          'id': 1337885509,
          'name': 'Hongen Liao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037875506,
          'name': 'Voxel'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Jiahui_Zhang_Efficient_Semantic_Scene_ECCV_2018_paper.html',
      'citation_count': 5,
      'reference_count': 39
    },
    {
      'id': 1756686,
      'title': 'Learning 3D Keypoint Descriptors for Non-rigid Shape Matching',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1115927456,
          'name': 'Hanyu Wang'
        },
        {
          'id': 1228700627,
          'name': 'Jianwei Guo'
        },
        {
          'id': 1032675923,
          'name': 'Dong-Ming Yan'
        },
        {
          'id': 1059126742,
          'name': 'Weize Quan'
        },
        {
          'id': 1493506431,
          'name': 'Xiaopeng Zhang'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038082814,
          'name': 'Point of interest'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Hanyu_Wang_Learning_3D_Keypoint_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 49
    },
    {
      'id': 176042986,
      'title': 'CrossNet: An End-to-End Reference-Based Super Resolution Network Using Cross-Scale Warping',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1029876462,
          'name': 'Haitian Zheng'
        },
        {
          'id': 1177700671,
          'name': 'Mengqi Ji'
        },
        {
          'id': 1098536622,
          'name': 'Haoqian Wang'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041433144,
          'name': 'Grid'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2046491851,
          'name': 'Image Share'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1807.10547',
      'citation_count': 4,
      'reference_count': 45
    },
    {
      'id': 18062120,
      'title': 'Face Recognition with Contrastive Convolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1492892089,
          'name': 'Chunrui Han'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1464445414,
          'name': 'Shuzhe Wu'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Chunrui_Han_Face_Recognition_with_ECCV_2018_paper.pdf',
      'citation_count': 8,
      'reference_count': 33
    },
    {
      'id': 181363580,
      'title': 'Joint Representation and Truncated Inference Learning for Correlation Filter based Tracking',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1467534421,
          'name': 'Yingjie Yao'
        },
        {
          'id': 1343560243,
          'name': 'Xiaohe Wu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2002583245,
          'name': 'Truncate'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yingjie_Yao_Joint_Representation_and_ECCV_2018_paper.pdf',
      'citation_count': 8,
      'reference_count': 34
    },
    {
      'id': 184776372,
      'title': 'Acquisition of Localization Confidence for Accurate Object Detection',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1349136676,
          'name': 'Borui Jiang'
        },
        {
          'id': 1207423736,
          'name': 'Ruixuan Luo'
        },
        {
          'id': 1396601583,
          'name': 'Jiayuan Mao'
        },
        {
          'id': 1125500898,
          'name': 'Tete Xiao'
        },
        {
          'id': 1314532880,
          'name': 'Yuning Jiang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1807.11590',
      'citation_count': 52,
      'reference_count': 30
    },
    {
      'id': 185074749,
      'title': 'BSN: Boundary Sensitive Network for Temporal Action Proposal Generation',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1005834509,
          'name': 'Tianwei Lin'
        },
        {
          'id': 1116512226,
          'name': 'Xu Zhao'
        },
        {
          'id': 1312929841,
          'name': 'Haisheng Su'
        },
        {
          'id': 1279861386,
          'name': 'Chongjing Wang'
        },
        {
          'id': 1331384629,
          'name': 'Ming Yang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Tianwei_Lin_BSN_Boundary_Sensitive_ECCV_2018_paper.pdf',
      'citation_count': 15,
      'reference_count': 27
    },
    {
      'id': 187987638,
      'title': 'Receptive Field Block Net for Accurate and Fast Object Detection',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1037258992,
          'name': 'Songtao Liu'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2046381799,
          'name': 'Eccentricity (behavior)'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1711.html',
      'citation_count': 20,
      'reference_count': 33
    },
    {
      'id': 189656164,
      'title': 'Towards End-to-End License Plate Detection and Recognition: A Large Dataset and Baseline.',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1182168806,
          'name': 'Zhenbo Xu'
        },
        {
          'id': 1322114111,
          'name': 'Wei Yang'
        },
        {
          'id': 1224754586,
          'name': 'Ajin Meng'
        },
        {
          'id': 1047196066,
          'name': 'Nanxue Lu'
        },
        {
          'id': 1357717161,
          'name': 'Huan Huang'
        },
        {
          'id': 1432408477,
          'name': 'Changchun Ying'
        },
        {
          'id': 1076124830,
          'name': 'Liusheng Huang'
        }
      ],
      'field': [
        {
          'id': 2023757099,
          'name': 'License'
        },
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2038996624,
          'name': 'Network model'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zhenbo_Xu_Towards_End-to-End_License_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 35
    },
    {
      'id': 190044290,
      'title': '3D Recurrent Neural Networks with Context Fusion for Point Cloud Semantic Segmentation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101684301,
          'abbr': 'OU',
          'name': 'Open University'
        }
      ],
      'author': [
        {
          'id': 1133546340,
          'name': 'Xiaoqing Ye'
        },
        {
          'id': 1249956405,
          'name': 'Jiamao Li'
        },
        {
          'id': 1374079661,
          'name': 'Hexiao Huang'
        },
        {
          'id': 1118369650,
          'name': 'Liang Du'
        },
        {
          'id': 1122541998,
          'name': 'Xiaolin Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048251823,
          'name': 'Open research'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2037197408,
          'name': 'Pointwise'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiaoqing_Ye_3D_Recurrent_Neural_ECCV_2018_paper.pdf',
      'citation_count': 27,
      'reference_count': 34
    },
    {
      'id': 190161096,
      'title': 'Single Image Water Hazard Detection Using FCN with Reflection Attention Units',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1033501293,
          'name': 'Xiaofeng Han'
        },
        {
          'id': 1038386891,
          'name': 'Chuong V. Nguyen'
        },
        {
          'id': 1444358315,
          'name': 'Shaodi You'
        },
        {
          'id': 1103484507,
          'name': 'Jianfeng Lu'
        }
      ],
      'field': [
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2022330850,
          'name': 'Viewing angle'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048103201,
          'name': 'Refraction'
        },
        {
          'id': 2025952013,
          'name': 'Polarizer'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045181672,
          'name': 'Precision and recall'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiaofeng_Han_Single_Image_Water_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 25
    },
    {
      'id': 193130989,
      'title': 'Deep Multi-task Learning to Recognise Subtle Facial Expressions of Mental States',
      'affiliation': [
        {
          'id': 2102990488,
          'abbr': 'QUB',
          'name': 'Queen\'s University Belfast'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2100780330,
          'abbr': 'ED',
          'name': 'University of Edinburgh'
        }
      ],
      'author': [
        {
          'id': 1429317998,
          'name': 'Guosheng Hu'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1004250691,
          'name': 'Yang Yuan'
        },
        {
          'id': 1096502289,
          'name': 'Zehao Yu'
        },
        {
          'id': 1243325018,
          'name': 'Yang Hua'
        },
        {
          'id': 1419855735,
          'name': 'Zhihong Zhang'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1165410717,
          'name': 'Neil Robertson'
        },
        {
          'id': 1274491871,
          'name': 'Yongxin Yang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034845953,
          'name': 'Multi-task learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2026573466,
          'name': 'Cognition'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 54
    },
    {
      'id': 19453192,
      'title': 'Deep Reinforcement Learning with Iterative Shift for Visual Tracking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1219558905,
          'name': 'Liangliang Ren'
        },
        {
          'id': 1068878246,
          'name': 'Xin Yuan'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1489506052,
          'name': 'Ming Yang'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Liangliang_Ren_Deep_Reinforcement_Learning_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 46
    },
    {
      'id': 196722862,
      'title': 'Joint Task-Recursive Learning for Semantic Segmentation and Depth Estimation',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1323385679,
          'name': 'Zhenyu Zhang'
        },
        {
          'id': 1249663582,
          'name': 'Zhen Cui'
        },
        {
          'id': 1290256705,
          'name': 'Chunyan Xu'
        },
        {
          'id': 1273266408,
          'name': 'Zequn Jie'
        },
        {
          'id': 1204869756,
          'name': 'Xiang Li'
        },
        {
          'id': 1440993810,
          'name': 'Jian Yang'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2018324713,
          'name': 'Scale space'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zhenyu_Zhang_Joint_Task-Recursive_Learning_ECCV_2018_paper.pdf',
      'citation_count': 8,
      'reference_count': 52
    },
    {
      'id': 200048028,
      'title': 'Does Haze Removal Help CNN-Based Image Classification?',
      'affiliation': [
        {
          'id': 2101359516,
          'abbr': 'BJTU',
          'name': 'Beijing Jiaotong University'
        },
        {
          'id': 2102246611,
          'abbr': 'USC',
          'name': 'University of South Carolina'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1153148640,
          'name': 'Yanting Pei'
        },
        {
          'id': 1281268475,
          'name': 'Yaping Huang'
        },
        {
          'id': 1195778333,
          'name': 'Qi Zou'
        },
        {
          'id': 1026643049,
          'name': 'Yuhang Lu'
        },
        {
          'id': 1390053351,
          'name': 'Song Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2025222731,
          'name': 'Haze'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yanting_Pei_Does_Haze_Removal_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 31
    },
    {
      'id': 201494358,
      'title': 'Deep Residual Attention Network for Spectral Image Super-Resolution',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1019333850,
          'name': 'Zhan Shi'
        },
        {
          'id': 1082179902,
          'name': 'Chang Chen'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1011720102,
          'name': 'Zheng-Jun Zha'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2019094366,
          'name': 'Temporal resolution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025371135,
          'name': 'Spectral imaging'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2027716576,
          'name': 'Spectral resolution'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_14',
      'citation_count': 4,
      'reference_count': 54
    },
    {
      'id': 201701644,
      'title': 'Deep Regression Tracking with Shrinkage Loss',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1202864959,
          'name': 'Xiankai Lu'
        },
        {
          'id': 1053338084,
          'name': 'Chao Ma'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1322506618,
          'name': 'Ian D. Reid'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2039760485,
          'name': 'Gaussian function'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2035580128,
          'name': 'Bottleneck'
        },
        {
          'id': 2040176042,
          'name': 'Shrinkage'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Xiankai_Lu_Deep_Regression_Tracking_ECCV_2018_paper.html',
      'citation_count': 18,
      'reference_count': 60
    },
    {
      'id': 204156705,
      'title': 'BiSeNet: Bilateral Segmentation Network for Real-Time Semantic Segmentation',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1255703345,
          'name': 'Changqian Yu'
        },
        {
          'id': 1189593101,
          'name': 'Jingbo Wang'
        },
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1028164167,
          'name': 'Changxin Gao'
        },
        {
          'id': 1003104441,
          'name': 'Gang Yu'
        },
        {
          'id': 1248648678,
          'name': 'Nong Sang'
        }
      ],
      'field': [
        {
          'id': 2028018560,
          'name': 'Spatial analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2022417328,
          'name': 'Compromise'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2005083615,
          'name': 'STRIDE'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1808.00897',
      'citation_count': 81,
      'reference_count': 39
    },
    {
      'id': 208109984,
      'title': 'Egocentric Activity Prediction via Event Modulated Attention',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1177042589,
          'name': 'Yang Shen'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1189400893,
          'name': 'Zefan Li'
        },
        {
          'id': 1493917619,
          'name': 'Ning Zhuang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044004772,
          'name': 'Asynchronous communication'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yang_Shen_Egocentric_Activity_Prediction_ECCV_2018_paper.pdf',
      'citation_count': 6,
      'reference_count': 27
    },
    {
      'id': 210308975,
      'title': 'Attention-GAN for Object Transfiguration in Wild Images',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1230304390,
          'name': 'Xinyuan Chen'
        },
        {
          'id': 1055051106,
          'name': 'Chang Xu'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 40
    },
    {
      'id': 214354207,
      'title': 'Generative Adversarial Network with Spatial Attention for Face Attribute Editing',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1308945101,
          'name': 'Gang Zhang'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2021357823,
          'name': 'Alternation (linguistics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Gang_Zhang_Generative_Adversarial_Network_ECCV_2018_paper.pdf',
      'citation_count': 18,
      'reference_count': 21
    },
    {
      'id': 21711759,
      'title': 'PIRM2018 Challenge on Spectral Image Super-Resolution: Methods and Results',
      'affiliation': [
        {
          'id': 2103850856,
          'abbr': 'DEAKIN',
          'name': 'Deakin University'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2101795417,
          'abbr': 'ISU',
          'name': 'Iowa State University'
        }
      ],
      'author': [
        {
          'id': 1257143319,
          'name': 'Mehrdad Shoeiby'
        },
        {
          'id': 1020923082,
          'name': 'Antonio Robles-Kelly'
        },
        {
          'id': 1089908655,
          'name': 'Radu Timofte'
        },
        {
          'id': 1390052761,
          'name': 'Ruofan Zhou'
        },
        {
          'id': 1437989938,
          'name': 'Fayez Lahoud'
        },
        {
          'id': 1122609547,
          'name': 'Sabine Süsstrunk'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1019333850,
          'name': 'Zhan Shi'
        },
        {
          'id': 1082179902,
          'name': 'Chang Chen'
        },
        {
          'id': 1430736488,
          'name': 'Dong Liu'
        },
        {
          'id': 1011720102,
          'name': 'Zheng-Jun Zha'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        },
        {
          'id': 1045421762,
          'name': 'Kaixuan Wei'
        },
        {
          'id': 1445823841,
          'name': 'Tao Zhang'
        },
        {
          'id': 1372850284,
          'name': 'Lizhi Wang'
        },
        {
          'id': 1192145103,
          'name': 'Ying Fu'
        },
        {
          'id': 1463746242,
          'name': 'Koushik Nagasubramanian'
        },
        {
          'id': 1329861627,
          'name': 'Asheesh K. Singh'
        },
        {
          'id': 1226351432,
          'name': 'Arti Singh'
        },
        {
          'id': 1483006346,
          'name': 'Soumik Sarkar'
        },
        {
          'id': 1120110568,
          'name': 'Baskar Ganapathysubramanian'
        }
      ],
      'field': [
        {
          'id': 2021997764,
          'name': 'Multispectral image'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_22',
      'citation_count': 6,
      'reference_count': 38
    },
    {
      'id': 217397070,
      'title': 'Saliency Detection in 360$$^\\circ $$ Videos',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1114348800,
          'name': 'Ziheng Zhang'
        },
        {
          'id': 1373263498,
          'name': 'Yanyu Xu'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2010733876,
          'name': 'Equirectangular projection'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-01234-2_30',
      'citation_count': 5,
      'reference_count': 29
    },
    {
      'id': 221010840,
      'title': 'Switchable Temporal Propagation Network',
      'affiliation': [
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1146035678,
          'name': 'Sifei Liu'
        },
        {
          'id': 1438525052,
          'name': 'Guangyu Zhong'
        },
        {
          'id': 1040166795,
          'name': 'Guangyu Zhong'
        },
        {
          'id': 1418158603,
          'name': 'Shalini De Mello'
        },
        {
          'id': 1470875387,
          'name': 'Jinwei Gu'
        },
        {
          'id': 1355772749,
          'name': 'Varun Jampani'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1360497703,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1098313743,
          'name': 'Jan Kautz'
        }
      ],
      'field': [
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2043955330,
          'name': 'Dynamic range'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018284600,
          'name': 'Transformation matrix'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Sifei_Liu_Switchable_Temporal_Propagation_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 30
    },
    {
      'id': 224728714,
      'title': 'Facial Expression Recognition with Inconsistently Annotated Datasets',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1162783045,
          'name': 'Jiabei Zeng'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Jiabei_Zeng_Facial_Expression_Recognition_ECCV_2018_paper.html',
      'citation_count': 9,
      'reference_count': 29
    },
    {
      'id': 226834756,
      'title': 'Perception-Preserving Convolutional Networks for Image Enhancement on Smartphones',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1427107741,
          'name': 'Zheng Hui'
        },
        {
          'id': 1263516969,
          'name': 'Xiumei Wang'
        },
        {
          'id': 1328697623,
          'name': 'Lirui Deng'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027898053,
          'name': 'Observational error'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2016704618,
          'name': 'Knowledge transfer'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_13',
      'citation_count': 3,
      'reference_count': 31
    },
    {
      'id': 227609695,
      'title': 'Domain Transfer Through Deep Activation Matching',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        }
      ],
      'author': [
        {
          'id': 1230016822,
          'name': 'Haoshuo Huang'
        },
        {
          'id': 1022255500,
          'name': 'Qixing Huang'
        },
        {
          'id': 1109458301,
          'name': 'Philipp Krähenbühl'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2029479506,
          'name': 'Covariate'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Haoshuo_Huang_Domain_transfer_through_ECCV_2018_paper.html',
      'citation_count': 18,
      'reference_count': 43
    },
    {
      'id': 22940963,
      'title': 'HybridFusion: Real-Time Performance Capture Using a Single Depth Sensor and Sparse IMUs',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104799642,
          'abbr': 'USC',
          'name': 'University of Southern California'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1416105486,
          'name': 'Zerong Zheng'
        },
        {
          'id': 1415675733,
          'name': 'Tao Yu'
        },
        {
          'id': 1347646526,
          'name': 'Hao Li'
        },
        {
          'id': 1111748420,
          'name': 'Kaiwen Guo'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1021644562,
          'name': 'Lu Fang'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        }
      ],
      'field': [
        {
          'id': 2019436259,
          'name': 'Inertial frame of reference'
        },
        {
          'id': 2035788587,
          'name': 'Inertial measurement unit'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004061258,
          'name': 'Motion capture'
        },
        {
          'id': 2005202005,
          'name': 'Match moving'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zerong_Zheng_HybridFusion_Real-Time_Performance_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 48
    },
    {
      'id': 235256612,
      'title': 'Deep Feature Pyramid Reconfiguration for Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1242912686,
          'name': 'Tao Kong'
        },
        {
          'id': 1233632450,
          'name': 'Fuchun Sun'
        },
        {
          'id': 1467293440,
          'name': 'Wenbing Huang'
        },
        {
          'id': 1108094283,
          'name': 'Huaping Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2030118170,
          'name': 'Control reconfiguration'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Tao_Kong_Deep_Feature_Pyramid_ECCV_2018_paper.pdf',
      'citation_count': 20,
      'reference_count': 46
    },
    {
      'id': 239191480,
      'title': 'Deblurring Natural Image Using Super-Gaussian Fields',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1383369244,
          'name': 'Yuhang Liu'
        },
        {
          'id': 1026133965,
          'name': 'Wenyong Dong'
        },
        {
          'id': 1192709942,
          'name': 'Dong Gong'
        },
        {
          'id': 1386051449,
          'name': 'Lei Zhang'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2009553560,
          'name': 'Limiting'
        },
        {
          'id': 2036697007,
          'name': 'Gaussian'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2013468352,
          'name': 'Partition function (statistical mechanics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yuhang_Liu_Deblurring_Natural_Image_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 47
    },
    {
      'id': 23970103,
      'title': 'Collaborative Deep Reinforcement Learning for Multi-object Tracking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1219558905,
          'name': 'Liangliang Ren'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1245744765,
          'name': 'Zifeng Wang'
        },
        {
          'id': 1238691319,
          'name': 'Qi Tian'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Liangliang_Ren_Collaborative_Deep_Reinforcement_ECCV_2018_paper.pdf',
      'citation_count': 7,
      'reference_count': 42
    },
    {
      'id': 240490833,
      'title': 'Beyond Composition Models: Person Retrieval with Refined Composition Pooling (and A Strong Convolutional Baseline)',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1430625305,
          'name': 'Yifan Sun'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2014430643,
          'name': 'Estimator'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yifan_Sun_Beyond_Part_Models_ECCV_2018_paper.pdf',
      'citation_count': 64,
      'reference_count': 38
    },
    {
      'id': 243634235,
      'title': 'Occlusion-Aware R-CNN: Detecting Pedestrians in a Crowd',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        }
      ],
      'author': [
        {
          'id': 1108322389,
          'name': 'Shifeng Zhang'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1484089631,
          'name': 'Xiao Bian'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024001284,
          'name': 'Region of interest'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2034070231,
          'name': 'Occlusion'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1807.08407',
      'citation_count': 16,
      'reference_count': 71
    },
    {
      'id': 24444509,
      'title': 'Non-local NetVLAD Encoding for Video Classification',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1476548999,
          'name': 'Yongyi Tang'
        },
        {
          'id': 1317973782,
          'name': 'Xing Zhang'
        },
        {
          'id': 1115160058,
          'name': 'Jingwen Wang'
        },
        {
          'id': 1432031677,
          'name': 'Shaoxiang Chen'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007%2F978-3-030-11018-5_20',
      'citation_count': 7,
      'reference_count': 19
    },
    {
      'id': 245638213,
      'title': 'Training Binary Weight Networks via Semi-Binary Decomposition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1449587090,
          'name': 'Qinghao Hu'
        },
        {
          'id': 1004057226,
          'name': 'Gang Li'
        },
        {
          'id': 1261785545,
          'name': 'Peisong Wang'
        },
        {
          'id': 1011431260,
          'name': 'Yifan Zhang'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2016376566,
          'name': 'Field-programmable gate array'
        },
        {
          'id': 2000663246,
          'name': 'Logical matrix'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2022139458,
          'name': 'Matrix multiplication'
        },
        {
          'id': 2000345757,
          'name': 'Decomposition method (constraint satisfaction)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008497329,
          'name': 'Diagonal matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Qinghao_Hu_Training_Binary_Weight_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 31
    },
    {
      'id': 245750684,
      'title': 'Dense Semantic and Topological Correspondence of 3D Faces without Landmarks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1323992119,
          'name': 'Zhenfeng Fan'
        },
        {
          'id': 1063962263,
          'name': 'Xiyuan Hu'
        },
        {
          'id': 1071077481,
          'name': 'Chen Chen'
        },
        {
          'id': 1178515377,
          'name': 'Silong Peng'
        }
      ],
      'field': [
        {
          'id': 2000539458,
          'name': 'Point set registration'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2004525820,
          'name': 'Minification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002387869,
          'name': 'Linear system'
        },
        {
          'id': 2047013050,
          'name': 'Iterative closest point'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2026937781,
          'name': 'Topology'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zhenfeng_Fan_Dense_Semantic_and_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 47
    },
    {
      'id': 247634953,
      'title': 'Hard-Aware Point-to-Set Deep Metric for Person Re-identification',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1333212436,
          'name': 'Rui Yu'
        },
        {
          'id': 1418741886,
          'name': 'Zhiyong Dou'
        },
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1376978113,
          'name': 'Zhaoxiang Zhang'
        },
        {
          'id': 1000696743,
          'name': 'Yongchao Xu'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040776147,
          'name': 'Generality'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2005090858,
          'name': 'Specific weight'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Rui_Yu_Hard-Aware_Point-to-Set_Deep_ECCV_2018_paper.pdf',
      'citation_count': 7,
      'reference_count': 52
    },
    {
      'id': 247789712,
      'title': 'Switchable Temporal Propagation Network',
      'affiliation': [
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1313041809,
          'name': 'Sifei Liu'
        },
        {
          'id': 1438525052,
          'name': 'Guangyu Zhong'
        },
        {
          'id': 1418158603,
          'name': 'Shalini De Mello'
        },
        {
          'id': 1470875387,
          'name': 'Jinwei Gu'
        },
        {
          'id': 1355772749,
          'name': 'Varun Jampani'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1098313743,
          'name': 'Jan Kautz'
        }
      ],
      'field': [
        {
          'id': 2006842663,
          'name': 'Redundancy (engineering)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043955330,
          'name': 'Dynamic range'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046268343,
          'name': 'Video processing'
        },
        {
          'id': 2018284600,
          'name': 'Transformation matrix'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 31
    },
    {
      'id': 248310481,
      'title': 'Evaluating Capability of Deep Neural Networks for Image Classification via Information Plane',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1478935516,
          'name': 'Hao Cheng'
        },
        {
          'id': 1406842602,
          'name': 'Dongze Lian'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        },
        {
          'id': 1370114195,
          'name': 'Yanlin Geng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2028333178,
          'name': 'Mutual information'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035720922,
          'name': 'Information bottleneck method'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://www.semanticscholar.org/paper/Evaluating-Capability-of-Deep-Neural-Networks-for-Cheng-Lian/d8501ee7eb6555c880be705034934ac265326c25',
      'citation_count': 2,
      'reference_count': 12
    },
    {
      'id': 252238322,
      'title': 'Attention-GAN for Object Transfiguration in Wild Images',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1094923135,
          'name': 'Xinyuan Chen'
        },
        {
          'id': 1003564174,
          'name': 'Xinyuan Chen'
        },
        {
          'id': 1230304390,
          'name': 'Xinyuan Chen'
        },
        {
          'id': 1055051106,
          'name': 'Chang Xu'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Xinyuan_Chen_Attention-GAN_for_Object_ECCV_2018_paper.html',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 253064057,
      'title': 'DeepIM: Deep Iterative Matching for 6D Pose Estimation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104054782,
          'abbr': 'UW',
          'name': 'University of Washington'
        }
      ],
      'author': [
        {
          'id': 1219021850,
          'name': 'Yi Li'
        },
        {
          'id': 1400982522,
          'name': 'Gu Wang'
        },
        {
          'id': 1145500222,
          'name': 'Xiangyang Ji'
        },
        {
          'id': 1314843626,
          'name': 'Yu Xiang'
        },
        {
          'id': 1335707375,
          'name': 'Dieter Fox'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048044401,
          'name': 'Robot'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2027460845,
          'name': 'Virtual reality'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/pdf/1804.00175.pdf',
      'citation_count': 9,
      'reference_count': 29
    },
    {
      'id': 253650950,
      'title': 'PlaneMatch: Patch Coplanarity Prediction for Robust RGB-D Reconstruction',
      'affiliation': [
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100355666,
          'abbr': 'TUM',
          'name': 'Technische Universität München'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1152748673,
          'name': 'Yifei Shi'
        },
        {
          'id': 1048406954,
          'name': 'Kai Xu'
        },
        {
          'id': 1119221049,
          'name': 'Matthias NieBner'
        },
        {
          'id': 1397627091,
          'name': 'Szymon Rusinkiewicz'
        },
        {
          'id': 1080296189,
          'name': 'Thomas A. Funkhouser'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001255713,
          'name': 'Coplanarity'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 46
    },
    {
      'id': 254043655,
      'title': 'End-to-end View Synthesis for Light Field Imaging with Pseudo 4DCNN',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1441479148,
          'name': 'Yunlong Wang'
        },
        {
          'id': 1121256807,
          'name': 'Fei Liu'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1229419345,
          'name': 'Guangqi Hou'
        },
        {
          'id': 1418569517,
          'name': 'Zhenan Sun'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2032112486,
          'name': 'Microlens'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019213422,
          'name': 'View synthesis'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2009660747,
          'name': 'Angular resolution'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yunlong_Wang_End-to-end_View_Synthesis_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 26
    },
    {
      'id': 254176814,
      'title': 'Eigendecomposition-free Training of Deep Networks with Zero Eigenvalue-based Losses',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103795399,
          'abbr': 'UVIC',
          'name': 'University of Victoria'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        }
      ],
      'author': [
        {
          'id': 1099928993,
          'name': 'Zheng Dang'
        },
        {
          'id': 1261926558,
          'name': 'Kwang Moo Yi'
        },
        {
          'id': 1296834306,
          'name': 'Yinlin Hu'
        },
        {
          'id': 1402927702,
          'name': 'Fei Wang'
        },
        {
          'id': 1116814108,
          'name': 'Pascal Fua'
        },
        {
          'id': 1341663681,
          'name': 'Mathieu Salzmann'
        }
      ],
      'field': [
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2021743058,
          'name': 'Eigenvalues and eigenvectors'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2002387869,
          'name': 'Linear system'
        },
        {
          'id': 2048015713,
          'name': 'Eigendecomposition of a matrix'
        },
        {
          'id': 2002757781,
          'name': 'Essential matrix'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 37
    },
    {
      'id': 255310944,
      'title': 'SpiderCNN: Deep Learning on Point Sets with Parameterized Convolutional Filters',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1050310061,
          'name': 'Yifan Xu'
        },
        {
          'id': 1236947280,
          'name': 'Yifan Xu'
        },
        {
          'id': 1169350614,
          'name': 'Tianqi Fan'
        },
        {
          'id': 1172678325,
          'name': 'Mingye Xu'
        },
        {
          'id': 1361192932,
          'name': 'Long Zeng'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2023617212,
          'name': 'Taylor series'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yifan_Xu_SpiderCNN_Deep_Learning_ECCV_2018_paper.pdf',
      'citation_count': 25,
      'reference_count': 18
    },
    {
      'id': 257133987,
      'title': 'Sub-GAN: An Unsupervised Generative Model via Subspaces',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1448634214,
          'name': 'Jie Liang'
        },
        {
          'id': 1003062086,
          'name': 'Jufeng Yang'
        },
        {
          'id': 1407814657,
          'name': 'Hsin-Ying Lee'
        },
        {
          'id': 1169182352,
          'name': 'Kai Wang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2026132149,
          'name': 'Complex data type'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jie_Liang_Sub-GAN_An_Unsupervised_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 47
    },
    {
      'id': 257320851,
      'title': 'Spatial-Temporal Attention Res-TCN for Skeleton-Based Dynamic Hand Gesture Recognition',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102274338,
          'abbr': 'UCL',
          'name': 'University College London'
        },
        {
          'id': 2104005819,
          'abbr': '',
          'name': 'University of Kent'
        }
      ],
      'author': [
        {
          'id': 1041443999,
          'name': 'Jingxuan Hou'
        },
        {
          'id': 1200371104,
          'name': 'Guijin Wang'
        },
        {
          'id': 1372905836,
          'name': 'Xinghao Chen'
        },
        {
          'id': 1054493770,
          'name': 'Jing-Hao Xue'
        },
        {
          'id': 1122384698,
          'name': 'Rui Zhu'
        },
        {
          'id': 1495831707,
          'name': 'Huazhong Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2022295869,
          'name': 'Gesture recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007%2F978-3-030-11024-6_18',
      'citation_count': 5,
      'reference_count': 29
    },
    {
      'id': 257351365,
      'title': 'An Adversarial Approach to Hard Triplet Generation',
      'affiliation': [
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1161731938,
          'name': 'Yiru Zhao'
        },
        {
          'id': 1415829757,
          'name': 'Zhongming Jin'
        },
        {
          'id': 1441884006,
          'name': 'Guo-jun Qi'
        },
        {
          'id': 1467680309,
          'name': 'Hongtao Lu'
        },
        {
          'id': 1202510511,
          'name': 'Xian-Sheng Hua'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yiru_Zhao_A_Principled_Approach_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 45
    },
    {
      'id': 257872726,
      'title': 'Learning Region Features for Object Detection',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1038945336,
          'name': 'Jiayuan Gu'
        },
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1324567904,
          'name': 'Liwei Wang'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jiayuan_Gu_Learning_Region_Features_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 17
    },
    {
      'id': 259616648,
      'title': 'SkipNet: Learning Dynamic Routing in Convolutional Networks',
      'affiliation': [
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1480533871,
          'name': 'Xin Wang'
        },
        {
          'id': 1018134350,
          'name': 'Fisher Yu'
        },
        {
          'id': 1178854016,
          'name': 'Zi-Yi Dou'
        },
        {
          'id': 1255937776,
          'name': 'Trevor Darrell'
        },
        {
          'id': 1394283141,
          'name': 'Joseph E. Gonzalez'
        }
      ],
      'field': [
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2020114278,
          'name': 'Adaptive routing'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 29
    },
    {
      'id': 259810158,
      'title': 'Faces as Lighting Probes via Unsupervised Deep Highlight Extraction',
      'affiliation': [
        {
          'id': 2103565249,
          'abbr': 'SFU',
          'name': 'Simon Fraser University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1291436752,
          'name': 'Renjiao Yi'
        },
        {
          'id': 1002557479,
          'name': 'Renjiao Yi'
        },
        {
          'id': 1290408917,
          'name': 'Chenyang Zhu'
        },
        {
          'id': 1075838592,
          'name': 'Chenyang Zhu'
        },
        {
          'id': 1056951125,
          'name': 'Ping Tan'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2026221349,
          'name': 'Chromaticity'
        },
        {
          'id': 2043351799,
          'name': 'Tracing'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2029566666,
          'name': 'Reflection mapping'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2014435714,
          'name': 'Basis function'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Renjiao_Yi_Faces_as_Lighting_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 51
    },
    {
      'id': 263720967,
      'title': 'Shift-Net: Image Inpainting via Deep Feature Rearrangement',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1268849647,
          'name': 'Zhaoyi Yan'
        },
        {
          'id': 1016223703,
          'name': 'Xiaoming Li'
        },
        {
          'id': 1028298673,
          'name': 'Mu Li'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2036693710,
          'name': 'Filling-in'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 49
    },
    {
      'id': 26655776,
      'title': 'VQA-E: Explaining, Elaborating, and Enhancing Your Answers for Visual Questions',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1056755133,
          'name': 'Qing Li'
        },
        {
          'id': 1085954604,
          'name': 'Qingyi Tao'
        },
        {
          'id': 1157646157,
          'name': 'Qingyi Tao'
        },
        {
          'id': 1389745141,
          'name': 'Shafiq R. Joty'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2047328649,
          'name': 'Clear Margin'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Qing_Li_VQA-E_Explaining_Elaborating_ECCV_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 31
    },
    {
      'id': 270929981,
      'title': 'Faces as Lighting Probes via Unsupervised Deep Highlight Extraction',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2103565249,
          'abbr': 'SFU',
          'name': 'Simon Fraser University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1002557479,
          'name': 'Renjiao Yi'
        },
        {
          'id': 1075838592,
          'name': 'Chenyang Zhu'
        },
        {
          'id': 1056951125,
          'name': 'Ping Tan'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029566666,
          'name': 'Reflection mapping'
        },
        {
          'id': 2026221349,
          'name': 'Chromaticity'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2043351799,
          'name': 'Tracing'
        },
        {
          'id': 2003897792,
          'name': 'Reflection (physics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 53
    },
    {
      'id': 271669772,
      'title': 'Composition-Activated Deep Reinforcement Learning for Action Prediction',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1347590099,
          'name': 'Lei Chen'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1386207395,
          'name': 'Zhanjie Song'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-01219-9_26',
      'citation_count': 2,
      'reference_count': 45
    },
    {
      'id': 273733271,
      'title': 'Learning to Navigate for Fine-grained Classification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1033630193,
          'name': 'Ze Yang'
        },
        {
          'id': 1439489240,
          'name': 'Tiange Luo'
        },
        {
          'id': 1020292543,
          'name': 'Dong Wang'
        },
        {
          'id': 1034696464,
          'name': 'Zhiqiang Hu'
        },
        {
          'id': 1224663467,
          'name': 'Jun Gao'
        },
        {
          'id': 1324567904,
          'name': 'Liwei Wang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Ze_Yang_Learning_to_Navigate_ECCV_2018_paper.html',
      'citation_count': 15,
      'reference_count': 49
    },
    {
      'id': 276095154,
      'title': 'Learning Region Features for Object Detection',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1387196134,
          'name': 'Jiayuan Gu'
        },
        {
          'id': 1257943601,
          'name': 'Han Hu'
        },
        {
          'id': 1324567904,
          'name': 'Liwei Wang'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 20
    },
    {
      'id': 282623056,
      'title': 'Grassmann Pooling as Compact Homogeneous Bilinear Pooling for Fine-Grained Visual Classification',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1149114015,
          'name': 'Xing Wei'
        },
        {
          'id': 1285507897,
          'name': 'Yue Zhang'
        },
        {
          'id': 1136791764,
          'name': 'Yihong Gong'
        },
        {
          'id': 1346819320,
          'name': 'Jiawei Zhang'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2019703203,
          'name': 'Orthogonal matrix'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2008582367,
          'name': 'Phenomenon'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xing_Wei_Grassmann_Pooling_for_ECCV_2018_paper.pdf',
      'citation_count': 7,
      'reference_count': 40
    },
    {
      'id': 284648405,
      'title': 'Pose Partition Networks for Multi-person Pose Estimation',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1311615180,
          'name': 'Xuecheng Nie'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2013752717,
          'name': 'Backbone network'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2045590784,
          'name': 'Graph partition'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xuecheng_Nie_Pose_Partition_Networks_ECCV_2018_paper.pdf',
      'citation_count': 7,
      'reference_count': 25
    },
    {
      'id': 292366914,
      'title': 'DDRNet: Depth Map Denoising and Refinement for Consumer Depth Cameras Using Cascaded CNNs.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102209992,
          'abbr': 'FACEBOOK',
          'name': 'Facebook'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1188336466,
          'name': 'Shi Yan'
        },
        {
          'id': 1138124451,
          'name': 'Chenglei Wu'
        },
        {
          'id': 1084692333,
          'name': 'Lizhen Wang'
        },
        {
          'id': 1098810408,
          'name': 'Feng Xu'
        },
        {
          'id': 1007400122,
          'name': 'Liang An'
        },
        {
          'id': 1111748420,
          'name': 'Kaiwen Guo'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        }
      ],
      'field': [
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2018657276,
          'name': 'Rendering equation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Shi_Yan_DDRNet_Depth_Map_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 46
    },
    {
      'id': 295171188,
      'title': 'PIRM Challenge on Perceptual Image Enhancement on Smartphones: Report',
      'affiliation': [
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2104875451,
          'abbr': 'KAIST',
          'name': 'KAIST'
        },
        {
          'id': 2103301421,
          'abbr': 'FZU',
          'name': 'Fuzhou University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103264004,
          'abbr': 'IITM',
          'name': 'Indian Institute of Technology Madras'
        }
      ],
      'author': [
        {
          'id': 1383472136,
          'name': 'Andrey Ignatov'
        },
        {
          'id': 1089908655,
          'name': 'Radu Timofte'
        },
        {
          'id': 1049414553,
          'name': 'Thang Vu'
        },
        {
          'id': 1078678095,
          'name': 'Tung Minh Luu'
        },
        {
          'id': 1469460107,
          'name': 'Trung X. Pham'
        },
        {
          'id': 1147959859,
          'name': 'Cao Van Nguyen'
        },
        {
          'id': 1469216843,
          'name': 'Yongwoo Kim'
        },
        {
          'id': 1172188493,
          'name': 'Jae-Seok Choi'
        },
        {
          'id': 1363123282,
          'name': 'Munchurl Kim'
        },
        {
          'id': 1018208838,
          'name': 'Jie Huang'
        },
        {
          'id': 1244810604,
          'name': 'Jiewen Ran'
        },
        {
          'id': 1000537986,
          'name': 'Chen Xing'
        },
        {
          'id': 1214756611,
          'name': 'Xingguang Zhou'
        },
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1105311119,
          'name': 'Mingrui Geng'
        },
        {
          'id': 1249847103,
          'name': 'Yawei Li'
        },
        {
          'id': 1392176907,
          'name': 'Eirikur Agustsson'
        },
        {
          'id': 1183589541,
          'name': 'Shuhang Gu'
        },
        {
          'id': 1061117953,
          'name': 'Luc Van Gool'
        },
        {
          'id': 1257663631,
          'name': 'Etienne de Stoutz'
        },
        {
          'id': 1340799608,
          'name': 'Nikolay Kobyshev'
        },
        {
          'id': 1484306687,
          'name': 'Kehui Nie'
        },
        {
          'id': 1296762266,
          'name': 'Yan Zhao'
        },
        {
          'id': 1006410003,
          'name': 'Gen Li'
        },
        {
          'id': 1375620865,
          'name': 'Tong Tong'
        },
        {
          'id': 1170813778,
          'name': 'Qinquan Gao'
        },
        {
          'id': 1060396888,
          'name': 'Liu Hanwen'
        },
        {
          'id': 1124493742,
          'name': 'Pablo Navarrete Michelini'
        },
        {
          'id': 1000821725,
          'name': 'Zhu Dan'
        },
        {
          'id': 1439671278,
          'name': 'Hu Fengshuo'
        },
        {
          'id': 1427107741,
          'name': 'Zheng Hui'
        },
        {
          'id': 1263516969,
          'name': 'Xiumei Wang'
        },
        {
          'id': 1328697623,
          'name': 'Lirui Deng'
        },
        {
          'id': 1099260356,
          'name': 'Rang Meng'
        },
        {
          'id': 1210744408,
          'name': 'Jinghui Qin'
        },
        {
          'id': 1195736920,
          'name': 'Yukai Shi'
        },
        {
          'id': 1186691259,
          'name': 'Wushao Wen'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1092147948,
          'name': 'Ruicheng Feng'
        },
        {
          'id': 1152255939,
          'name': 'Shixiang Wu'
        },
        {
          'id': 1130918340,
          'name': 'Chao Dong'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1369236958,
          'name': 'Subeesh Vasu'
        },
        {
          'id': 1024765433,
          'name': 'Nimisha Thekke Madam'
        },
        {
          'id': 1226884708,
          'name': 'Praveen Kandula'
        },
        {
          'id': 1382661856,
          'name': 'A. N. Rajagopalan'
        },
        {
          'id': 1241566079,
          'name': 'Jie Liu'
        },
        {
          'id': 1232110530,
          'name': 'Cheolkon Jung'
        }
      ],
      'field': [
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037571790,
          'name': 'Android (operating system)'
        },
        {
          'id': 2045937712,
          'name': 'Bicubic interpolation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/pdf/1810.01641',
      'citation_count': 14,
      'reference_count': 34
    },
    {
      'id': 297195329,
      'title': 'Recurrent Tubelet Proposal and Recognition Networks for Action Detection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1106914389,
          'name': 'Dong Li'
        },
        {
          'id': 1348886811,
          'name': 'Zhaofan Qiu'
        },
        {
          'id': 1328946252,
          'name': 'Qi Dai'
        },
        {
          'id': 1079344244,
          'name': 'Ting Yao'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2001611344,
          'name': 'Temporal context'
        },
        {
          'id': 2007665589,
          'name': 'Competitor analysis'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Dong_Li_Recurrent_Tubelet_Proposal_ECCV_2018_paper.html',
      'citation_count': 21,
      'reference_count': 37
    },
    {
      'id': 298420907,
      'title': 'Rendering Portraitures from Monocular Camera and Beyond',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1411899365,
          'name': 'Xiangyu Xu'
        },
        {
          'id': 1330695574,
          'name': 'Deqing Sun'
        },
        {
          'id': 1313041809,
          'name': 'Sifei Liu'
        },
        {
          'id': 1248438389,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1289921224,
          'name': 'Yu-Jin Zhang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1000062846,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2037410910,
          'name': 'Photography'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024086463,
          'name': 'Rendering (computer graphics)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiangyu_Xu_Rendering_Portraitures_from_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 34
    },
    {
      'id': 298780980,
      'title': 'Receptive Field Block Net for Accurate and Fast Object Detection',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1037258992,
          'name': 'Songtao Liu'
        },
        {
          'id': 1243668617,
          'name': 'Di Huang'
        },
        {
          'id': 1288875305,
          'name': 'Yunhong Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 41
    },
    {
      'id': 303562972,
      'title': 'Dual-Agent Deep Reinforcement Learning for Deformable Face Tracking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1466003999,
          'name': 'Minghao Guo'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2045100285,
          'name': 'Facial motion capture'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039992481,
          'name': 'Markov decision process'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2034077824,
          'name': 'Bayesian inference'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Minghao_Guo_Dual-Agent_Deep_Reinforcement_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 34
    },
    {
      'id': 303894746,
      'title': 'Learning Data Terms for Non-blind Deblurring',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2100514771,
          'abbr': 'GUET',
          'name': 'Guilin University of Electronic Technology'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        }
      ],
      'author': [
        {
          'id': 1118840716,
          'name': 'Jiangxin Dong'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1330695574,
          'name': 'Deqing Sun'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2018516098,
          'name': 'Curve fitting'
        },
        {
          'id': 2040176042,
          'name': 'Shrinkage'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jiangxin_Dong_Learning_Data_Terms_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 27
    },
    {
      'id': 304252412,
      'title': 'PM-GANs: Discriminative Representation Learning for Action Recognition Using Partial-Modalities',
      'affiliation': [
        {
          'id': 2101631867,
          'abbr': 'CQUPT',
          'name': 'Chongqing University of Posts and Telecommunications'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1353306520,
          'name': 'Lan Wang'
        },
        {
          'id': 1310082457,
          'name': 'Chenqiang Gao'
        },
        {
          'id': 1466649819,
          'name': 'Luyu Yang'
        },
        {
          'id': 1214775484,
          'name': 'Yue Zhao'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021724463,
          'name': 'Missing data'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 62
    },
    {
      'id': 308156630,
      'title': 'Interaction-Aware Spatio-Temporal Pyramid Attention Networks for Action Classification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1198092842,
          'name': 'Yang Du'
        },
        {
          'id': 1215326337,
          'name': 'Chunfeng Yuan'
        },
        {
          'id': 1138746327,
          'name': 'Bing Li'
        },
        {
          'id': 1028815581,
          'name': 'Lili Zhao'
        },
        {
          'id': 1242857901,
          'name': 'Yangxi Li'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1808.01106v1',
      'citation_count': 6,
      'reference_count': 55
    },
    {
      'id': 31322272,
      'title': 'Adding Attentiveness to the Neurons in Recurrent Neural Networks',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1172150847,
          'name': 'Pengfei Zhang'
        },
        {
          'id': 1221299331,
          'name': 'Jianru Xue'
        },
        {
          'id': 1171674693,
          'name': 'Cuiling Lan'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        },
        {
          'id': 1189196498,
          'name': 'Zhanning Gao'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019090288,
          'name': 'Base unit (measurement)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2029025612,
          'name': 'Network layer'
        },
        {
          'id': 2004524944,
          'name': 'Modulation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 46
    },
    {
      'id': 315136940,
      'title': 'Learning Compression from Limited Unlabeled Data',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1122557775,
          'name': 'Xiangyu He'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        }
      ],
      'field': [
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2018518904,
          'name': 'Fold (higher-order function)'
        },
        {
          'id': 2048229811,
          'name': 'Conjugate prior'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029025337,
          'name': 'Retraining'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiangyu_He_Learning_Compression_from_ECCV_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 30
    },
    {
      'id': 316922376,
      'title': 'ELEGANT: Exchanging Latent Encodings with GAN for Transferring Multiple Face Attributes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1476934349,
          'name': 'Taihong Xiao'
        },
        {
          'id': 1229725611,
          'name': 'Jiapeng Hong'
        },
        {
          'id': 1445619584,
          'name': 'Jinwen Ma'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 33
    },
    {
      'id': 318021027,
      'title': 'Appearance-Based Gaze Estimation via Evaluation-Guided Asymmetric Regression',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102440343,
          'abbr': 'MPG',
          'name': 'Max Planck Society'
        }
      ],
      'author': [
        {
          'id': 1272895379,
          'name': 'Yihua Cheng'
        },
        {
          'id': 1313394467,
          'name': 'Feng Lu'
        },
        {
          'id': 1410061901,
          'name': 'Xucong Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2041467086,
          'name': 'Gaze'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2047770604,
          'name': 'Intelligent decision support system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000994941,
          'name': 'Gaze directions'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yihua_Cheng_Appearance-Based_Gaze_Estimation_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 34
    },
    {
      'id': 320357447,
      'title': 'Real-time \'Actor-Critic\' Tracking',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        }
      ],
      'author': [
        {
          'id': 1245807374,
          'name': 'Boyu Chen'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1360041657,
          'name': 'Peixia Li'
        },
        {
          'id': 1128155957,
          'name': 'Shuang Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Boyu_Chen_Real-time_Actor-Critic_Tracking_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 51
    },
    {
      'id': 322643373,
      'title': 'Eigendecomposition-free Training of Deep Networks with Zero Eigenvalue-based Losses',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103795399,
          'abbr': 'UVIC',
          'name': 'University of Victoria'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        }
      ],
      'author': [
        {
          'id': 1099928993,
          'name': 'Zheng Dang'
        },
        {
          'id': 1261926558,
          'name': 'Kwang Moo Yi'
        },
        {
          'id': 1296834306,
          'name': 'Yinlin Hu'
        },
        {
          'id': 1332466848,
          'name': 'Fei Wang'
        },
        {
          'id': 1116814108,
          'name': 'Pascal Fua'
        },
        {
          'id': 1341663681,
          'name': 'Mathieu Salzmann'
        }
      ],
      'field': [
        {
          'id': 2048015713,
          'name': 'Eigendecomposition of a matrix'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2043628113,
          'name': 'Singular value decomposition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021743058,
          'name': 'Eigenvalues and eigenvectors'
        },
        {
          'id': 2002387869,
          'name': 'Linear system'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002757781,
          'name': 'Essential matrix'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://infoscience.epfl.ch/record/256426',
      'citation_count': 2,
      'reference_count': 36
    },
    {
      'id': 32864946,
      'title': 'ESRGAN: Enhanced Super-Resolution Generative Adversarial Networks',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1158326101,
          'name': 'Xintao Wang'
        },
        {
          'id': 1274028524,
          'name': 'Ke Yu'
        },
        {
          'id': 1152255939,
          'name': 'Shixiang Wu'
        },
        {
          'id': 1023317089,
          'name': 'Jinjin Gu'
        },
        {
          'id': 1482940828,
          'name': 'Yihao Liu'
        },
        {
          'id': 1130918340,
          'name': 'Chao Dong'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2045807287,
          'name': 'Brightness'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2025641305,
          'name': 'Hallucinated'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1809.00219',
      'citation_count': 94,
      'reference_count': 45
    },
    {
      'id': 329778713,
      'title': 'VisDrone-SOT2018: The Vision Meets Drone Single-Object Tracking Challenge Results',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        },
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2104342132,
          'abbr': 'UNISA',
          'name': 'University of South Australia'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2102731498,
          'abbr': 'SNU',
          'name': 'Seoul National University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100860636,
          'abbr': 'SAU',
          'name': 'Shenyang Aerospace University'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2100358192,
          'abbr': 'CAUC ',
          'name': 'Civil Aviation University of China'
        },
        {
          'id': 2104553663,
          'abbr': '',
          'name': 'Samsung'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104488735,
          'abbr': 'LANCASTER',
          'name': 'Lancaster University'
        },
        {
          'id': 2101307090,
          'abbr': 'NUIST',
          'name': 'Nanjing University of Information Science and Technology'
        },
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        },
        {
          'id': 2104591464,
          'abbr': 'KIT',
          'name': 'Karlsruhe Institute of Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102361083,
          'abbr': '',
          'name': 'University of Ottawa'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1484089631,
          'name': 'Xiao Bian'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1180699841,
          'name': 'Qinghua Hu'
        },
        {
          'id': 1281790522,
          'name': 'Chenfeng Liu'
        },
        {
          'id': 1482215150,
          'name': 'Hao Cheng'
        },
        {
          'id': 1355714571,
          'name': 'Xiaoyu Liu'
        },
        {
          'id': 1476829622,
          'name': 'Wenya Ma'
        },
        {
          'id': 1432501184,
          'name': 'Qinqin Nie'
        },
        {
          'id': 1105702711,
          'name': 'Haotian Wu'
        },
        {
          'id': 1063479420,
          'name': 'Lianjie Wang'
        },
        {
          'id': 1098379337,
          'name': 'Asanka G. Perera'
        },
        {
          'id': 1083144891,
          'name': 'Baochang Zhang'
        },
        {
          'id': 1422642764,
          'name': 'Byeongho Heo'
        },
        {
          'id': 1298198486,
          'name': 'Chunlei Liu'
        },
        {
          'id': 1312771884,
          'name': 'Dongdong Li'
        },
        {
          'id': 1357331676,
          'name': 'Emmanouil Michail'
        },
        {
          'id': 1404119239,
          'name': 'Hanlin Chen'
        },
        {
          'id': 1128353694,
          'name': 'Hao Liu'
        },
        {
          'id': 1218797507,
          'name': 'Haojie Li'
        },
        {
          'id': 1383187379,
          'name': 'Ioannis Kompatsiaris'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        },
        {
          'id': 1147596070,
          'name': 'Jiaqing Fan'
        },
        {
          'id': 1011148756,
          'name': 'Jie Zhang'
        },
        {
          'id': 1150266006,
          'name': 'Jin Young Choi'
        },
        {
          'id': 1317158178,
          'name': 'Jing Li'
        },
        {
          'id': 1460374200,
          'name': 'Jinyu Yang'
        },
        {
          'id': 1329788752,
          'name': 'Jong-Won Choi'
        },
        {
          'id': 1025902510,
          'name': 'Juanping Zhao'
        },
        {
          'id': 1153306339,
          'name': 'Jungong Han'
        },
        {
          'id': 1244385036,
          'name': 'Kaihua Zhang'
        },
        {
          'id': 1495307565,
          'name': 'Kaiwen Duan'
        },
        {
          'id': 1199459080,
          'name': 'Ke Song'
        },
        {
          'id': 1433987988,
          'name': 'Konstantinos Avgerinakis'
        },
        {
          'id': 1243621449,
          'name': 'Kyuewang Lee'
        },
        {
          'id': 1294161884,
          'name': 'Lu Ding'
        },
        {
          'id': 1440814268,
          'name': 'Martin Lauer'
        },
        {
          'id': 1045596348,
          'name': 'Panagiotis Giannakeris'
        },
        {
          'id': 1399406105,
          'name': 'Peizhen Zhang'
        },
        {
          'id': 1021140366,
          'name': 'Qiang Wang'
        },
        {
          'id': 1136712973,
          'name': 'Qianqian Xu'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1358667323,
          'name': 'Qingshan Liu'
        },
        {
          'id': 1095620199,
          'name': 'Robert Laganière'
        },
        {
          'id': 1343332916,
          'name': 'Ruixin Zhang'
        },
        {
          'id': 1279991476,
          'name': 'Sangdoo Yun'
        },
        {
          'id': 1218815722,
          'name': 'Shengyin Zhu'
        },
        {
          'id': 1165439448,
          'name': 'Sihang Wu'
        },
        {
          'id': 1244454774,
          'name': 'Stefanos Vrochidis'
        },
        {
          'id': 1309668880,
          'name': 'Wei Tian'
        },
        {
          'id': 1392480485,
          'name': 'Wei Zhang'
        },
        {
          'id': 1282995003,
          'name': 'Weidong Chen'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1382894261,
          'name': 'Wenhao Wang'
        },
        {
          'id': 1032036874,
          'name': 'Wenhua Zhang'
        },
        {
          'id': 1359962388,
          'name': 'Wenrui Ding'
        },
        {
          'id': 1113332109,
          'name': 'Xiaohao He'
        },
        {
          'id': 1352506107,
          'name': 'Xiaotong Li'
        },
        {
          'id': 1002860213,
          'name': 'Xin Zhang'
        },
        {
          'id': 1109566165,
          'name': 'Xinbin Luo'
        },
        {
          'id': 1335626069,
          'name': 'Xixi Hu'
        },
        {
          'id': 1280595816,
          'name': 'Yang Meng'
        },
        {
          'id': 1445310476,
          'name': 'Yangliu Kuai'
        },
        {
          'id': 1067486252,
          'name': 'Yanyun Zhao'
        },
        {
          'id': 1112249157,
          'name': 'Yaxuan Li'
        },
        {
          'id': 1253227196,
          'name': 'Yifan Yang'
        },
        {
          'id': 1011431260,
          'name': 'Yifan Zhang'
        },
        {
          'id': 1254750512,
          'name': 'Yong Wang'
        },
        {
          'id': 1188685537,
          'name': 'Yuankai Qi'
        },
        {
          'id': 1382625275,
          'name': 'Zhipeng Deng'
        },
        {
          'id': 1144696453,
          'name': 'Zhiqun He'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008498025,
          'name': 'Drone'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_28',
      'citation_count': 2,
      'reference_count': 67
    },
    {
      'id': 334565804,
      'title': 'RCAA: Relational Context-Aware Agents for Person Search',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1174238937,
          'name': 'Xiaojun Chang'
        },
        {
          'id': 1279795230,
          'name': 'Po-Yao Huang'
        },
        {
          'id': 1208409061,
          'name': 'Yi-Dong Shen'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiaojun_Chang_RCAA_Relational_Context-Aware_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 52
    },
    {
      'id': 335928424,
      'title': 'Graininess-Aware Deep Feature Learning for Pedestrian Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1174690502,
          'name': 'Chunze Lin'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Chunze_Lin_Graininess-Aware_Deep_Feature_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 46
    },
    {
      'id': 337199719,
      'title': 'Video Object Segmentation by Learning Location-Sensitive Embeddings',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1489512136,
          'name': 'Hai Ci'
        },
        {
          'id': 1335216092,
          'name': 'Chunyu Wang'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Hai_Ci_Video_Object_Segmentation_ECCV_2018_paper.pdf',
      'citation_count': 16,
      'reference_count': 26
    },
    {
      'id': 338784889,
      'title': 'HBE: Hand Branch Ensemble Network for Real-Time 3D Hand Pose Estimation',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1124369293,
          'name': 'Yidan Zhou'
        },
        {
          'id': 1066726879,
          'name': 'Jian Lu'
        },
        {
          'id': 1219598212,
          'name': 'Kuo Du'
        },
        {
          'id': 1421517312,
          'name': 'Xiangbo Lin'
        },
        {
          'id': 1359392906,
          'name': 'Yi Sun'
        },
        {
          'id': 1355670895,
          'name': 'Xiaohong Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2032023715,
          'name': 'Hand joints'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2017318404,
          'name': 'Frame rate'
        },
        {
          'id': 2007341314,
          'name': 'Thumb'
        },
        {
          'id': 2006879698,
          'name': 'Index finger'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yidan_Zhou_HBE_Hand_Branch_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 34
    },
    {
      'id': 340334534,
      'title': 'FishEyeRecNet: A Multi-context Collaborative Deep Network for Fisheye Image Rectification',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103556481,
          'abbr': 'SIT',
          'name': 'Stevens Institute of Technology'
        },
        {
          'id': 2103644482,
          'abbr': 'HDU',
          'name': 'Hangzhou Dianzi University'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        }
      ],
      'author': [
        {
          'id': 1219895982,
          'name': 'Xiaoqing Yin'
        },
        {
          'id': 1151290883,
          'name': 'Xinchao Wang'
        },
        {
          'id': 1035695133,
          'name': 'Jun Yu'
        },
        {
          'id': 1433493238,
          'name': 'Maojun Zhang'
        },
        {
          'id': 1116814108,
          'name': 'Pascal Fua'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2021101066,
          'name': 'Image rectification'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025838069,
          'name': 'Rectification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018399408,
          'name': 'Preprocessor'
        },
        {
          'id': 2020757927,
          'name': 'Pinhole camera'
        },
        {
          'id': 2008563812,
          'name': 'Lens (optics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 24
    },
    {
      'id': 341552597,
      'title': 'SpiderCNN: Deep Learning on Point Sets with Parameterized Convolutional Filters',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1236947280,
          'name': 'Yifan Xu'
        },
        {
          'id': 1169350614,
          'name': 'Tianqi Fan'
        },
        {
          'id': 1172678325,
          'name': 'Mingye Xu'
        },
        {
          'id': 1361192932,
          'name': 'Long Zeng'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030627881,
          'name': 'Step function'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 22
    },
    {
      'id': 342409336,
      'title': 'Pyramid Dilated Deeper ConvLSTM for Video Salient Object Detection',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        }
      ],
      'author': [
        {
          'id': 1016014144,
          'name': 'Hongmei Song'
        },
        {
          'id': 1057011443,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1394770802,
          'name': 'Sanyuan Zhao'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1002783027,
          'name': 'Kin-Man Lam'
        }
      ],
      'field': [
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036252551,
          'name': 'Information flow (information theory)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Hongmei_Song_Pseudo_Pyramid_Deeper_ECCV_2018_paper.pdf',
      'citation_count': 35,
      'reference_count': 50
    },
    {
      'id': 344011162,
      'title': 'DeepKSPD: Learning Kernel-Matrix-Based SPD Representation For Fine-Grained Image Recognition',
      'affiliation': [
        {
          'id': 2100460378,
          'abbr': 'UOW',
          'name': 'University of Wollongong'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1373278054,
          'name': 'Melih Engin'
        },
        {
          'id': 1384396648,
          'name': 'Lei Wang'
        },
        {
          'id': 1130844234,
          'name': 'Luping Zhou'
        },
        {
          'id': 1162571328,
          'name': 'Xinwang Liu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025315957,
          'name': 'Covariance matrix'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2014149080,
          'name': 'Operator theory'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2049479510,
          'name': 'Riemannian geometry'
        },
        {
          'id': 2010047798,
          'name': 'Numerical stability'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 31
    },
    {
      'id': 344702070,
      'title': 'Recurrent Squeeze-and-Excitation Context Aggregation Net for Single Image Deraining',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1098118883,
          'name': 'Xia Li'
        },
        {
          'id': 1199970772,
          'name': 'Jianlong Wu'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1422060233,
          'name': 'Hong Liu'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2020211709,
          'name': 'Transparency (graphic)'
        },
        {
          'id': 2027755292,
          'name': 'Excitation'
        },
        {
          'id': 2011893552,
          'name': 'Streak'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/pdf/1807.05698',
      'citation_count': 14,
      'reference_count': 34
    },
    {
      'id': 345124502,
      'title': 'Range Scaling Global U-Net for Perceptual Image Enhancement on Mobile Devices.',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1018208838,
          'name': 'Jie Huang'
        },
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1105311119,
          'name': 'Mingrui Geng'
        },
        {
          'id': 1244810604,
          'name': 'Jiewen Ran'
        },
        {
          'id': 1214756611,
          'name': 'Xingguang Zhou'
        },
        {
          'id': 1000537986,
          'name': 'Chen Xing'
        },
        {
          'id': 1241200837,
          'name': 'Pengfei Wan'
        },
        {
          'id': 1145500222,
          'name': 'Xiangyang Ji'
        }
      ],
      'field': [
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_15',
      'citation_count': 4,
      'reference_count': 26
    },
    {
      'id': 348869642,
      'title': 'Bi-GANs-ST for Perceptual Image Super-Resolution.',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1296374343,
          'name': 'Xiaotong Luo'
        },
        {
          'id': 1448795566,
          'name': 'Rong Chen'
        },
        {
          'id': 1157583500,
          'name': 'Yuan Xie'
        },
        {
          'id': 1309298089,
          'name': 'Yanyun Qu'
        },
        {
          'id': 1111389283,
          'name': 'Cuihua Li'
        }
      ],
      'field': [
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015183468,
          'name': 'Weight Perception'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2001153861,
          'name': 'Mean squared error'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1811.00367',
      'citation_count': 4,
      'reference_count': 25
    },
    {
      'id': 350630144,
      'title': 'Quantization Mimic: Towards Very Tiny CNN for Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1336572487,
          'name': 'Yi Wei'
        },
        {
          'id': 1402806659,
          'name': 'Xinyu Pan'
        },
        {
          'id': 1436337105,
          'name': 'Hongwei Qin'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013841216,
          'name': 'Acceleration'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2039464793,
          'name': 'Quantization (physics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 41
    },
    {
      'id': 350641301,
      'title': 'ELEGANT: Exchanging Latent Encodings with GAN for Transferring Multiple Face Attributes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1476934349,
          'name': 'Taihong Xiao'
        },
        {
          'id': 1229725611,
          'name': 'Jiapeng Hong'
        },
        {
          'id': 1445619584,
          'name': 'Jinwen Ma'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Taihong_Xiao_ELEGANT_Exchanging_Latent_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 26
    },
    {
      'id': 355120361,
      'title': 'Relaxation-Free Deep Hashing via Policy Gradient',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1068878246,
          'name': 'Xin Yuan'
        },
        {
          'id': 1219558905,
          'name': 'Liangliang Ren'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1004690129,
          'name': 'Jie Zhou'
        }
      ],
      'field': [
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005133827,
          'name': 'Discrete optimization'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xin_Yuan_Towards_Optimal_Deep_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 43
    },
    {
      'id': 355314848,
      'title': 'Fine-Grained Visual Categorization Using Meta-learning Optimization with Sample Selection of Auxiliary Data',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1116195762,
          'name': 'Yabin Zhang'
        },
        {
          'id': 1133121266,
          'name': 'Hui Tang'
        },
        {
          'id': 1477244207,
          'name': 'Kui Jia'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yabin_Zhang_Fine-Grained_Visual_Categorization_ECCV_2018_paper.pdf',
      'citation_count': 7,
      'reference_count': 32
    },
    {
      'id': 356547908,
      'title': 'Integral Human Pose Regression',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        }
      ],
      'author': [
        {
          'id': 1472779654,
          'name': 'Xiao Sun'
        },
        {
          'id': 1013708909,
          'name': 'Bin Xiao'
        },
        {
          'id': 1165172349,
          'name': 'Fangyin Wei'
        },
        {
          'id': 1158233654,
          'name': 'Shuang Liang'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2029498710,
          'name': 'Spite'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 61
    },
    {
      'id': 357581736,
      'title': 'Learning to Predict Crisp Boundaries',
      'affiliation': [
        {
          'id': 2102346228,
          'abbr': 'CSU',
          'name': 'Central South University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1076676967,
          'name': 'Ruoxi Deng'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1177367431,
          'name': 'Shengjun Liu'
        },
        {
          'id': 1292274470,
          'name': 'Huibing Wang'
        },
        {
          'id': 1021570019,
          'name': 'Xinru Liu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2023473352,
          'name': 'Edge detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ruoxi_Deng_Learning_to_Predict_ECCV_2018_paper.pdf',
      'citation_count': 9,
      'reference_count': 45
    },
    {
      'id': 358947652,
      'title': 'Deep Domain Generalization via Conditional Invariant Adversarial Networks',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1427454096,
          'name': 'Ya Li'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1126804779,
          'name': 'Mingming Gong'
        },
        {
          'id': 1342575784,
          'name': 'Yajing Liu'
        },
        {
          'id': 1356544795,
          'name': 'Tongliang Liu'
        },
        {
          'id': 1471003264,
          'name': 'Kun Zhang'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027215556,
          'name': 'Marginal distribution'
        },
        {
          'id': 2001975124,
          'name': 'Joint probability distribution'
        },
        {
          'id': 2042592337,
          'name': 'Linear map'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2049454762,
          'name': 'Conditional probability distribution'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ya_Li_Deep_Domain_Generalization_ECCV_2018_paper.pdf',
      'citation_count': 17,
      'reference_count': 30
    },
    {
      'id': 359114570,
      'title': 'A Segmentation-Aware Deep Fusion Network for Compressed Sensing MRI',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1365122479,
          'name': 'Zhiwen Fan'
        },
        {
          'id': 1437382708,
          'name': 'Liyan Sun'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1127615383,
          'name': 'Congbo Cai'
        },
        {
          'id': 1005874265,
          'name': 'John W. Paisley'
        }
      ],
      'field': [
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2048710313,
          'name': 'Compressed sensing'
        },
        {
          'id': 2023693789,
          'name': 'Inverse problem'
        },
        {
          'id': 2039879149,
          'name': 'Computational photography'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2012530238,
          'name': 'Brain segmentation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 32
    },
    {
      'id': 362471223,
      'title': 'Attention-Aware Deep Adversarial Hashing for Cross-Modal Retrieval',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1166509991,
          'name': 'Xi Zhang'
        },
        {
          'id': 1249782652,
          'name': 'Hanjiang Lai'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xi_Zhang_Attention-aware_Deep_Adversarial_ECCV_2018_paper.pdf',
      'citation_count': 13,
      'reference_count': 31
    },
    {
      'id': 372584077,
      'title': 'Towards Human-Level License Plate Recognition',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1194471485,
          'name': 'Jiafan Zhuang'
        },
        {
          'id': 1112157554,
          'name': 'Saihui Hou'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1011720102,
          'name': 'Zheng-Jun Zha'
        }
      ],
      'field': [
        {
          'id': 2023757099,
          'name': 'License'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2016407401,
          'name': 'Intelligent transportation system'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jiafan_Zhuang_Towards_Human-Level_License_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 29
    },
    {
      'id': 373281010,
      'title': 'The Unmanned Aerial Vehicle Benchmark: Object Detection and Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1188685537,
          'name': 'Yuankai Qi'
        },
        {
          'id': 1213027068,
          'name': 'Hongyang Yu'
        },
        {
          'id': 1253227196,
          'name': 'Yifan Yang'
        },
        {
          'id': 1495307565,
          'name': 'Kaiwen Duan'
        },
        {
          'id': 1136073280,
          'name': 'Guorong Li'
        },
        {
          'id': 1354296024,
          'name': 'Weigang Zhang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1238691319,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2007984297,
          'name': 'Vehicle category'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Dawei_Du_The_Unmanned_Aerial_ECCV_2018_paper.pdf',
      'citation_count': 6,
      'reference_count': 49
    },
    {
      'id': 374430948,
      'title': 'VQA-E: Explaining, Elaborating, and Enhancing Your Answers for Visual Questions',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101519558,
          'abbr': 'NVIDIA',
          'name': 'Nvidia'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2104590982,
          'abbr': 'UR',
          'name': 'University of Rochester'
        }
      ],
      'author': [
        {
          'id': 1178760828,
          'name': 'Qing Li'
        },
        {
          'id': 1085954604,
          'name': 'Qingyi Tao'
        },
        {
          'id': 1389745141,
          'name': 'Shafiq R. Joty'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        },
        {
          'id': 1312657021,
          'name': 'Jiebo Luo'
        }
      ],
      'field': [
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047328649,
          'name': 'Clear Margin'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 35
    },
    {
      'id': 377439691,
      'title': 'Learning Human-Object Interactions by Graph Parsing Neural Networks',
      'affiliation': [
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1417522252,
          'name': 'Siyuan Qi'
        },
        {
          'id': 1143159782,
          'name': 'Wenguan Wang'
        },
        {
          'id': 1113359429,
          'name': 'Baoxiong Jia'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2044851664,
          'name': 'Adjacency matrix'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012430506,
          'name': 'Message passing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Siyuan_Qi_Learning_Human-Object_Interactions_ECCV_2018_paper.pdf',
      'citation_count': 30,
      'reference_count': 49
    },
    {
      'id': 385106446,
      'title': 'Non-local NetVLAD Encoding for Video Classification',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1476548999,
          'name': 'Yongyi Tang'
        },
        {
          'id': 1317973782,
          'name': 'Xing Zhang'
        },
        {
          'id': 1115160058,
          'name': 'Jingwen Wang'
        },
        {
          'id': 1432031677,
          'name': 'Shaoxiang Chen'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007%2F978-3-030-11018-5_20',
      'citation_count': 0,
      'reference_count': 21
    },
    {
      'id': 385650109,
      'title': 'Correcting the Triplet Selection Bias for Triplet Loss',
      'affiliation': [
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2100077207,
          'abbr': 'PITT',
          'name': 'University of Pittsburgh'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1492314444,
          'name': 'Baosheng Yu'
        },
        {
          'id': 1356544795,
          'name': 'Tongliang Liu'
        },
        {
          'id': 1126804779,
          'name': 'Mingming Gong'
        },
        {
          'id': 1496509492,
          'name': 'Changxing Ding'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2014500768,
          'name': 'Selection bias'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2019179373,
          'name': 'Maxima and minima'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-01231-1_5',
      'citation_count': 10,
      'reference_count': 43
    },
    {
      'id': 387580456,
      'title': 'PSDF Fusion: Probabilistic Signed Distance Function for On-the-fly 3D Data Fusion and Scene Reconstruction.',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1179061630,
          'name': 'Wei Dong'
        },
        {
          'id': 1113648885,
          'name': 'Qiuyuan Wang'
        },
        {
          'id': 1239688213,
          'name': 'Xin Wang'
        },
        {
          'id': 1432932083,
          'name': 'Hongbin Zha'
        }
      ],
      'field': [
        {
          'id': 2009246595,
          'name': 'External Data Representation'
        },
        {
          'id': 2001372067,
          'name': 'Signed distance function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017473472,
          'name': 'Parametric statistics'
        },
        {
          'id': 2023612680,
          'name': 'Sensor fusion'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2037490412,
          'name': 'Surfel'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2032968910,
          'name': 'Mesh generation'
        },
        {
          'id': 2017367786,
          'name': 'Data quality'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Wei_Dong_Probabilistic_Signed_Distance_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 35
    },
    {
      'id': 389194390,
      'title': 'Single Image Highlight Removal with a Sparse and Low-Rank Reflection Model',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100633262,
          'abbr': 'NJUCM',
          'name': 'Nanjing University of Chinese Medicine'
        }
      ],
      'author': [
        {
          'id': 1091529171,
          'name': 'Jie Guo'
        },
        {
          'id': 1033070111,
          'name': 'Zuojian Zhou'
        },
        {
          'id': 1425862507,
          'name': 'Limin Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041111693,
          'name': 'Lagrange multiplier'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2013822762,
          'name': 'Specular highlight'
        },
        {
          'id': 2049663528,
          'name': 'Diffuse reflection'
        },
        {
          'id': 2040012394,
          'name': 'Weighting'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jie_Guo_Single_Image_Highlight_ECCV_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 47
    },
    {
      'id': 39430835,
      'title': 'Find and Focus: Retrieve and Localize Video Events with Natural Language Queries',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1155169079,
          'name': 'Dian Shao'
        },
        {
          'id': 1049254588,
          'name': 'Yu Xiong'
        },
        {
          'id': 1162296009,
          'name': 'Yue Zhao'
        },
        {
          'id': 1448461873,
          'name': 'Qingqiu Huang'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2013982076,
          'name': 'Paragraph'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022133074,
          'name': 'Expressive power'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013383778,
          'name': 'FIFO (computing and electronics)'
        },
        {
          'id': 2017661787,
          'name': 'Sentence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Dian_SHAO_Find_and_Focus_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 40
    },
    {
      'id': 394578047,
      'title': 'TS 2 C: Tight Box Mining with Surrounding Segmentation Context for Weakly Supervised Object Detection',
      'affiliation': [
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1305989134,
          'name': 'Yunchao Wei'
        },
        {
          'id': 1394963746,
          'name': 'Zhiqiang Shen'
        },
        {
          'id': 1338289384,
          'name': 'Bowen Cheng'
        },
        {
          'id': 1209822350,
          'name': 'Honghui Shi'
        },
        {
          'id': 1059029129,
          'name': 'Jinjun Xiong'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036616100,
          'name': 'Completeness (statistics)'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yunchao_Wei_TS2C_Tight_Box_ECCV_2018_paper.pdf',
      'citation_count': 22,
      'reference_count': 45
    },
    {
      'id': 396224406,
      'title': 'DetNet: Design Backbone for Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1213309621,
          'name': 'Zeming Li'
        },
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1416154934,
          'name': 'Gang Yu'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1436299266,
          'name': 'Yangdong Deng'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2013752717,
          'name': 'Backbone network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zeming_Li_DetNet_Design_Backbone_ECCV_2018_paper.pdf',
      'citation_count': 22,
      'reference_count': 26
    },
    {
      'id': 397801759,
      'title': 'Bi-Real Net: Enhancing the Performance of 1-Bit CNNs with Improved Representational Capability and Advanced Training Algorithm',
      'affiliation': [
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1067994098,
          'name': 'Zechun Liu'
        },
        {
          'id': 1032149282,
          'name': 'Baoyuan Wu'
        },
        {
          'id': 1176702019,
          'name': 'Wenhan Luo'
        },
        {
          'id': 1061178820,
          'name': 'Xin Yang'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1049038079,
          'name': 'Kwang-Ting Cheng'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2015032460,
          'name': 'XNOR gate'
        },
        {
          'id': 2027663998,
          'name': 'Performance gap'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2013291395,
          'name': 'Sign function'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1808.00278',
      'citation_count': 21,
      'reference_count': 33
    },
    {
      'id': 400241790,
      'title': 'Unified Perceptual Parsing for Scene Understanding',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1125500898,
          'name': 'Tete Xiao'
        },
        {
          'id': 1118603461,
          'name': 'Yingcheng Liu'
        },
        {
          'id': 1436314989,
          'name': 'Bolei Zhou'
        },
        {
          'id': 1314532880,
          'name': 'Yuning Jiang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034848742,
          'name': 'Machine vision'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1807.10221',
      'citation_count': 40,
      'reference_count': 43
    },
    {
      'id': 401362117,
      'title': 'Geometric Constrained Joint Lane Segmentation and Lane Boundary Detection',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1339323840,
          'name': 'Jie Zhang'
        },
        {
          'id': 1124030868,
          'name': 'Yi Xu'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1220039133,
          'name': 'Zhenyu Duan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2036226268,
          'name': 'Advanced driver assistance systems'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jie_Zhang_Geometric_Constrained_Joint_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 34
    },
    {
      'id': 403108649,
      'title': 'Person Search in Videos with One Portrait Through Visual and Temporal Links',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1448461873,
          'name': 'Qingqiu Huang'
        },
        {
          'id': 1225487178,
          'name': 'Wentao Liu'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024435654,
          'name': 'Portrait'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2006041051,
          'name': 'Law enforcement'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Qingqiu_Huang_Person_Search_in_ECCV_2018_paper.pdf',
      'citation_count': 6,
      'reference_count': 47
    },
    {
      'id': 403307869,
      'title': 'Deep Adaptive Attention for Joint Facial Action Unit Detection and Face Alignment',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100414475,
          'abbr': 'ECNU',
          'name': 'East China Normal University'
        }
      ],
      'author': [
        {
          'id': 1260287888,
          'name': 'Zhiwen Shao'
        },
        {
          'id': 1352922340,
          'name': 'Zhilei Liu'
        },
        {
          'id': 1326402389,
          'name': 'Jianfei Cai'
        },
        {
          'id': 1125486885,
          'name': 'Lizhuang Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 32
    },
    {
      'id': 404083258,
      'title': 'Affinity Derivation and Graph Merge for Instance Segmentation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1024530535,
          'name': 'Yiding Liu'
        },
        {
          'id': 1249270743,
          'name': 'Siyu Yang'
        },
        {
          'id': 1324191676,
          'name': 'Bin Li'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1050286348,
          'name': 'Jizheng Xu'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1060816602,
          'name': 'Yan Lu'
        }
      ],
      'field': [
        {
          'id': 2044086055,
          'name': 'Vertex (geometry)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011350140,
          'name': 'Derivation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2042767248,
          'name': 'Test set'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2035517495,
          'name': 'Merge algorithm'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1811.10870',
      'citation_count': 9,
      'reference_count': 49
    },
    {
      'id': 405210675,
      'title': 'Lifelong Learning via Progressive Distillation and Retrospection',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1112157554,
          'name': 'Saihui Hou'
        },
        {
          'id': 1402806659,
          'name': 'Xinyu Pan'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1163651403,
          'name': 'Dahua Lin'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010918187,
          'name': 'Distillation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003407433,
          'name': 'Lifelong learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Saihui_Hou_Progressive_Lifelong_Learning_ECCV_2018_paper.pdf',
      'citation_count': 9,
      'reference_count': 23
    },
    {
      'id': 407927073,
      'title': 'Incomplete Multi-view Clustering via Graph Regularized Matrix Factorization',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103831083,
          'abbr': 'UQ',
          'name': 'University of Queensland'
        }
      ],
      'author': [
        {
          'id': 1434360973,
          'name': 'Jie Wen'
        },
        {
          'id': 1092264287,
          'name': 'Zheng Zhang'
        },
        {
          'id': 1169123174,
          'name': 'Yong Xu'
        },
        {
          'id': 1499986527,
          'name': 'Zuofeng Zhong'
        }
      ],
      'field': [
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2042481170,
          'name': 'Graph embedding'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2026750366,
          'name': 'Compact space'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1809.05998',
      'citation_count': 3,
      'reference_count': 28
    },
    {
      'id': 408860269,
      'title': 'Partial Adversarial Domain Adaptation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1126860916,
          'name': 'Zhangjie Cao'
        },
        {
          'id': 1056820689,
          'name': 'Lijia Ma'
        },
        {
          'id': 1049051023,
          'name': 'Mingsheng Long'
        },
        {
          'id': 1431228885,
          'name': 'Jianmin Wang'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2036439149,
          'name': 'Adversary'
        },
        {
          'id': 2031926264,
          'name': 'Big data'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012454591,
          'name': 'Negative transfer'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2001087521,
          'name': 'Minimax'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1808.04205',
      'citation_count': 29,
      'reference_count': 31
    },
    {
      'id': 410784585,
      'title': 'Macro-Micro Adversarial Network for Human Parsing',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2101979648,
          'abbr': 'SUTD',
          'name': 'Singapore University of Technology and Design'
        }
      ],
      'author': [
        {
          'id': 1288722613,
          'name': 'Yawei Luo'
        },
        {
          'id': 1051842533,
          'name': 'Zhedong Zheng'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1435687262,
          'name': 'Tao Guan'
        },
        {
          'id': 1483834528,
          'name': 'Junqing Yu'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2000523001,
          'name': 'Macro'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yawei_Luo_Macro-Micro_Adversarial_Network_ECCV_2018_paper.pdf',
      'citation_count': 15,
      'reference_count': 39
    },
    {
      'id': 410867179,
      'title': 'Learning to Dodge A Bullet: Concyclic View Morphing via Deep Learning',
      'affiliation': [
        {
          'id': 2101478610,
          'abbr': 'LSU',
          'name': 'Louisiana State University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1196590850,
          'name': 'Shi Jin'
        },
        {
          'id': 1311186772,
          'name': 'Ruiynag Liu'
        },
        {
          'id': 1309146481,
          'name': 'Yu Ji'
        },
        {
          'id': 1308188660,
          'name': 'Jinwei Ye'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2038412597,
          'name': 'Image-based modeling and rendering'
        },
        {
          'id': 2016462171,
          'name': 'Interpolation'
        },
        {
          'id': 2025838069,
          'name': 'Rectification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009875918,
          'name': 'Motion field'
        },
        {
          'id': 2007597257,
          'name': 'Morphing'
        },
        {
          'id': 2048904105,
          'name': 'Illusion'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/shi_jin_Learning_to_Dodge_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 40
    },
    {
      'id': 411260608,
      'title': 'SketchyScene: Richly-Annotated Scene Sketches',
      'affiliation': [
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100080364,
          'abbr': 'SDU',
          'name': 'Shandong University'
        },
        {
          'id': 2103565249,
          'abbr': 'SFU',
          'name': 'Simon Fraser University'
        }
      ],
      'author': [
        {
          'id': 1173318305,
          'name': 'Changqing Zou'
        },
        {
          'id': 1218616496,
          'name': 'Qian Yu'
        },
        {
          'id': 1378875839,
          'name': 'Ruofei Du'
        },
        {
          'id': 1276268612,
          'name': 'Haoran Mo'
        },
        {
          'id': 1276350016,
          'name': 'Yi-Zhe Song'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1314490861,
          'name': 'Chengying Gao'
        },
        {
          'id': 1243709913,
          'name': 'Baoquan Chen'
        },
        {
          'id': 1126750079,
          'name': 'Hao Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040290192,
          'name': 'Computational model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2011269610,
          'name': 'Extensibility'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        },
        {
          'id': 2004356190,
          'name': 'Composition (visual arts)'
        },
        {
          'id': 2002903134,
          'name': 'Crowdsourcing'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Changqing_Zou_SketchyScene_Richly-Annotated_Scene_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 42
    },
    {
      'id': 41197711,
      'title': 'Multi-Scale Spatially-Asymmetric Recalibration for Image Classification',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1024760921,
          'name': 'Yan Wang'
        },
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1189491755,
          'name': 'Siyuan Qiao'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2047846951,
          'name': 'A-weighting'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 44
    },
    {
      'id': 413560838,
      'title': 'Universal Sketch Perceptual Grouping',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2100780330,
          'abbr': 'ED',
          'name': 'University of Edinburgh'
        }
      ],
      'author': [
        {
          'id': 1291380510,
          'name': 'Ke Li'
        },
        {
          'id': 1329943217,
          'name': 'Kaiyue Pang'
        },
        {
          'id': 1196774786,
          'name': 'Jifei Song'
        },
        {
          'id': 1276350016,
          'name': 'Yi-Zhe Song'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        }
      ],
      'field': [
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2015316182,
          'name': 'Generalization'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ke_LI_Universal_Sketch_Perceptual_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 28
    },
    {
      'id': 41533994,
      'title': 'EC-Net: An Edge-Aware Point Set Consolidation Network',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2104372185,
          'abbr': 'TAU',
          'name': 'Tel Aviv University'
        }
      ],
      'author': [
        {
          'id': 1255302839,
          'name': 'Lequan Yu'
        },
        {
          'id': 1298020015,
          'name': 'Xianzhi Li'
        },
        {
          'id': 1440473805,
          'name': 'Chi-Wing Fu'
        },
        {
          'id': 1000830364,
          'name': 'Daniel Cohen Or'
        },
        {
          'id': 1098027502,
          'name': 'Pheng-Ann Heng'
        }
      ],
      'field': [
        {
          'id': 2022254564,
          'name': 'Real point'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2022829212,
          'name': 'Consolidation (soil)'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1807.06010',
      'citation_count': 8,
      'reference_count': 39
    },
    {
      'id': 417357158,
      'title': 'SOD-MTGAN: Small Object Detection via Multi-Task Generative Adversarial Network',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1041138396,
          'name': 'Yancheng Bai'
        },
        {
          'id': 1235033683,
          'name': 'Yongqiang Zhang'
        },
        {
          'id': 1061600911,
          'name': 'Mingli Ding'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yongqiang_Zhang_SOD-MTGAN_Small_Object_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 31
    },
    {
      'id': 418935649,
      'title': 'Learning Warped Guidance for Blind Face Restoration',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104578578,
          'abbr': 'UKY',
          'name': 'University of Kentucky'
        }
      ],
      'author': [
        {
          'id': 1016223703,
          'name': 'Xiaoming Li'
        },
        {
          'id': 1166707258,
          'name': 'Ming Liu'
        },
        {
          'id': 1301609442,
          'name': 'Yuting Ye'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1306929173,
          'name': 'Ruigang Yang'
        }
      ],
      'field': [
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2030799690,
          'name': 'Scale factor'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049540304,
          'name': 'Image warping'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2000809816,
          'name': 'Total variation denoising'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 57
    },
    {
      'id': 42038834,
      'title': 'Selective Zero-Shot Classification with Augmented Attributes',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1274764510,
          'name': 'Jie Song'
        },
        {
          'id': 1493466798,
          'name': 'Chengchao Shen'
        },
        {
          'id': 1339425959,
          'name': 'Jie Lei'
        },
        {
          'id': 1415908175,
          'name': 'Anxiang Zeng'
        },
        {
          'id': 1438764439,
          'name': 'Kairi Ou'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2008599270,
          'name': 'Vocabulary'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jie_Song_Selective_Zero-Shot_Classification_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 422740933,
      'title': 'Visual Tracking via Spatially Aligned Correlation Filters Network',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101579272,
          'abbr': 'LONDON',
          'name': 'University of London'
        }
      ],
      'author': [
        {
          'id': 1130181595,
          'name': 'Mengdan Zhang'
        },
        {
          'id': 1021140366,
          'name': 'Qiang Wang'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1436742442,
          'name': 'Jin Gao'
        },
        {
          'id': 1230691821,
          'name': 'Peixi Peng'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1242761004,
          'name': 'Stephen J. Maybank'
        }
      ],
      'field': [
        {
          'id': 2023746547,
          'name': 'Aspect ratio (image)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2008176819,
          'name': 'Periodic graph (geometry)'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021767452,
          'name': 'Transformation geometry'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/mengdan_zhang_Visual_Tracking_via_ECCV_2018_paper.pdf',
      'citation_count': 3,
      'reference_count': 42
    },
    {
      'id': 423670942,
      'title': 'Learning Efficient Single-Stage Pedestrian Detectors by Asymptotic Localization Fitting',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1182795802,
          'name': 'Weidong Hu'
        },
        {
          'id': 1026314022,
          'name': 'Xuezhi Liang'
        },
        {
          'id': 1239952208,
          'name': 'Xiao Chen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2033739827,
          'name': 'Stack (abstract data type)'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Wei_Liu_Learning_Efficient_Single-stage_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 30
    },
    {
      'id': 424515445,
      'title': 'Linear Span Network for Object Skeleton Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1347623422,
          'name': 'Chang Liu'
        },
        {
          'id': 1030826032,
          'name': 'Wei Ke'
        },
        {
          'id': 1339602057,
          'name': 'Fei Qin'
        },
        {
          'id': 1340439886,
          'name': 'Qixiang Ye'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2003367322,
          'name': 'Linear span'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Chang_Liu_Linear_Span_Network_ECCV_2018_paper.html',
      'citation_count': 2,
      'reference_count': 25
    },
    {
      'id': 424636923,
      'title': 'Salient Objects in Clutter: Bringing Salient Object Detection to the Foreground',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1235240356,
          'name': 'Deng-Ping Fan'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1479658590,
          'name': 'Jiang-Jiang Liu'
        },
        {
          'id': 1062748276,
          'name': 'Shanghua Gao'
        },
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 47
    },
    {
      'id': 425424313,
      'title': 'Dynamic Filtering with Large Sampling Field for ConvNets',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        }
      ],
      'author': [
        {
          'id': 1243312962,
          'name': 'Jialin Wu'
        },
        {
          'id': 1457938826,
          'name': 'Dai Li'
        },
        {
          'id': 1020552187,
          'name': 'Yu Yang'
        },
        {
          'id': 1333854152,
          'name': 'Chandrajit L. Bajaj'
        },
        {
          'id': 1145500222,
          'name': 'Xiangyang Ji'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jialin_Wu_Dynamic_Sampling_Convolutional_ECCV_2018_paper.pdf',
      'citation_count': 6,
      'reference_count': 21
    },
    {
      'id': 428367601,
      'title': 'Quaternion Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101188987,
          'abbr': 'DUKE',
          'name': 'Duke University'
        }
      ],
      'author': [
        {
          'id': 1411019042,
          'name': 'Xuanyu Zhu'
        },
        {
          'id': 1124030868,
          'name': 'Yi Xu'
        },
        {
          'id': 1417697056,
          'name': 'Hongteng Xu'
        },
        {
          'id': 1496488033,
          'name': 'Changjian Chen'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2013705826,
          'name': 'Quaternion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Xuanyu_Zhu_Quaternion_Convolutional_Neural_ECCV_2018_paper.html',
      'citation_count': 8,
      'reference_count': 39
    },
    {
      'id': 431763880,
      'title': 'Joint Camera Spectral Sensitivity Selection and Hyperspectral Image Recovery',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102805074,
          'abbr': 'NII',
          'name': 'National Institute of Informatics'
        }
      ],
      'author': [
        {
          'id': 1192145103,
          'name': 'Ying Fu'
        },
        {
          'id': 1445823841,
          'name': 'Tao Zhang'
        },
        {
          'id': 1285220304,
          'name': 'Yinqiang Zheng'
        },
        {
          'id': 1334782672,
          'name': 'Debing Zhang'
        },
        {
          'id': 1194539518,
          'name': 'Hua Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000528911,
          'name': 'Spectral sensitivity'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ying_Fu_Joint_Camera_Spectral_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 52
    },
    {
      'id': 43748347,
      'title': 'Pixel2Mesh: Generating 3D Mesh Models from Single RGB Images',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1136492835,
          'name': 'Nanyang Wang'
        },
        {
          'id': 1436560353,
          'name': 'Yinda Zhang'
        },
        {
          'id': 1476012931,
          'name': 'Zhuwen Li'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1108871892,
          'name': 'Wei Liu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        }
      ],
      'field': [
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2005819271,
          'name': 'Triangle mesh'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Nanyang_Wang_Pixel2Mesh_Generating_3D_ECCV_2018_paper.pdf',
      'citation_count': 18,
      'reference_count': 30
    },
    {
      'id': 438079896,
      'title': 'Weakly Supervised Region Proposal Network and Object Detection',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2102262321,
          'abbr': 'UTA',
          'name': 'University of Texas at Arlington'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1289566418,
          'name': 'Peng Tang'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1048228120,
          'name': 'Angtian Wang'
        },
        {
          'id': 1116468298,
          'name': 'Yongluan Yan'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1132819407,
          'name': 'Junzhou Huang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Peng_Tang_Weakly_Supervised_Region_ECCV_2018_paper.pdf',
      'citation_count': 23,
      'reference_count': 44
    },
    {
      'id': 440013618,
      'title': 'PPF-FoldNet: Unsupervised Learning of Rotation Invariant 3D Local Descriptors',
      'affiliation': [
        {
          'id': 2100355666,
          'abbr': 'TUM',
          'name': 'Technische Universität München'
        },
        {
          'id': 2103100482,
          'abbr': 'SIEMENS',
          'name': 'Siemens'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1254148618,
          'name': 'Haowen Deng'
        },
        {
          'id': 1061854107,
          'name': 'Tolga Birdal'
        },
        {
          'id': 1444393592,
          'name': 'Slobodan Ilic'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040109696,
          'name': 'Invariant (physics)'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2018076242,
          'name': 'Local reference frame'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Tolga_Birdal_PPF-FoldNet_Unsupervised_Learning_ECCV_2018_paper.pdf',
      'citation_count': 16,
      'reference_count': 50
    },
    {
      'id': 447748777,
      'title': 'The Unmanned Aerial Vehicle Benchmark: Object Detection and Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        }
      ],
      'author': [
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1188685537,
          'name': 'Yuankai Qi'
        },
        {
          'id': 1213027068,
          'name': 'Hongyang Yu'
        },
        {
          'id': 1253227196,
          'name': 'Yifan Yang'
        },
        {
          'id': 1495307565,
          'name': 'Kaiwen Duan'
        },
        {
          'id': 1136073280,
          'name': 'Guorong Li'
        },
        {
          'id': 1354296024,
          'name': 'Weigang Zhang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1238691319,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2007984297,
          'name': 'Vehicle category'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 48
    },
    {
      'id': 447753172,
      'title': 'PM-GANs: Discriminative Representation Learning for Action Recognition Using Partial-Modalities',
      'affiliation': [
        {
          'id': 2101631867,
          'abbr': 'CQUPT',
          'name': 'Chongqing University of Posts and Telecommunications'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1353306520,
          'name': 'Lan Wang'
        },
        {
          'id': 1310082457,
          'name': 'Chenqiang Gao'
        },
        {
          'id': 1187268948,
          'name': 'Luyu Yang'
        },
        {
          'id': 1214775484,
          'name': 'Yue Zhao'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        }
      ],
      'field': [
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2021724463,
          'name': 'Missing data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Lan_Wang_PM-GANs_Discriminative_Representation_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 61
    },
    {
      'id': 448560492,
      'title': 'Hierarchical Bilinear Pooling for Fine-Grained Visual Recognition',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1087146590,
          'name': 'Chaojian Yu'
        },
        {
          'id': 1117628576,
          'name': 'Xinyi Zhao'
        },
        {
          'id': 1204387652,
          'name': 'Qi Zheng'
        },
        {
          'id': 1494369662,
          'name': 'Peng Zhang'
        },
        {
          'id': 1269583289,
          'name': 'Xinge You'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Chaojian_Yu_Hierarchical_Bilinear_Pooling_ECCV_2018_paper.pdf',
      'citation_count': 9,
      'reference_count': 32
    },
    {
      'id': 451969353,
      'title': 'Monocular Depth Estimation with Affinity, Vertical Pooling, and Label Enhancement',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1285666283,
          'name': 'Yukang Gan'
        },
        {
          'id': 1411899365,
          'name': 'Xiangyu Xu'
        },
        {
          'id': 1311099785,
          'name': 'Wenxiu Sun'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2044619755,
          'name': 'Vertical direction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/YuKang_Gan_Monocular_Depth_Estimation_ECCV_2018_paper.pdf',
      'citation_count': 6,
      'reference_count': 29
    },
    {
      'id': 452277439,
      'title': 'Bidirectional Feature Pyramid Network with Recurrent Attention Residual Modules for Shadow Detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1414534225,
          'name': 'Lei Zhu'
        },
        {
          'id': 1050862964,
          'name': 'Zijun Deng'
        },
        {
          'id': 1245660649,
          'name': 'Xiaowei Hu'
        },
        {
          'id': 1440473805,
          'name': 'Chi-Wing Fu'
        },
        {
          'id': 1465317163,
          'name': 'Xuemiao Xu'
        },
        {
          'id': 1014874264,
          'name': 'Jing Qin'
        },
        {
          'id': 1098027502,
          'name': 'Pheng-Ann Heng'
        }
      ],
      'field': [
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025093314,
          'name': 'Network planning and design'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036679242,
          'name': 'Word error rate'
        },
        {
          'id': 2033552958,
          'name': 'Shadow'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Lei_Zhu_Bi-directional_Feature_Pyramid_ECCV_2018_paper.pdf',
      'citation_count': 8,
      'reference_count': 34
    },
    {
      'id': 452817680,
      'title': 'Shift-Net: Image Inpainting via Deep Feature Rearrangement',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1268849647,
          'name': 'Zhaoyi Yan'
        },
        {
          'id': 1016223703,
          'name': 'Xiaoming Li'
        },
        {
          'id': 1028298673,
          'name': 'Mu Li'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2036693710,
          'name': 'Filling-in'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zhaoyi_Yan_Shift-Net_Image_Inpainting_ECCV_2018_paper.pdf',
      'citation_count': 8,
      'reference_count': 41
    },
    {
      'id': 453487176,
      'title': 'Efficient Global Point Cloud Registration by Matching Rotation Invariant Features Through Translation Search',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1160926442,
          'name': 'Yinlong Liu'
        },
        {
          'id': 1081170536,
          'name': 'Chen Wang'
        },
        {
          'id': 1136753230,
          'name': 'Zhijian Song'
        },
        {
          'id': 1106120380,
          'name': 'Manning Wang'
        }
      ],
      'field': [
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2043940226,
          'name': 'Parameter space'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014248115,
          'name': 'Time complexity'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2018276327,
          'name': 'Exponential function'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yinlong_Liu_Efficient_Global_Point_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 18
    },
    {
      'id': 455756444,
      'title': 'Transductive Semi-Supervised Deep Learning using Min-Max Features',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2102262321,
          'abbr': 'UTA',
          'name': 'University of Texas at Arlington'
        }
      ],
      'author': [
        {
          'id': 1013960924,
          'name': 'Weiwei Shi'
        },
        {
          'id': 1136791764,
          'name': 'Yihong Gong'
        },
        {
          'id': 1230388090,
          'name': 'Chris H. Q. Ding'
        },
        {
          'id': 1229460967,
          'name': 'Zhiheng Ma'
        },
        {
          'id': 1328891991,
          'name': 'Xiaoyu Tao'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2014952153,
          'name': 'Transduction (machine learning)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Weiwei_Shi_Transductive_Semi-Supervised_Deep_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 41
    },
    {
      'id': 457827249,
      'title': 'Beyond Composition Models: Person Retrieval with Refined Composition Pooling (and A Strong Convolutional Baseline)',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1430625305,
          'name': 'Yifan Sun'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2014430643,
          'name': 'Estimator'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 56,
      'reference_count': 41
    },
    {
      'id': 459076852,
      'title': 'PlaneMatch: Patch Coplanarity Prediction for Robust RGB-D Reconstruction',
      'affiliation': [
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100355666,
          'abbr': 'TUM',
          'name': 'Technische Universität München'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1076413979,
          'name': 'Yifei Shi'
        },
        {
          'id': 1152748673,
          'name': 'Yifei Shi'
        },
        {
          'id': 1048406954,
          'name': 'Kai Xu'
        },
        {
          'id': 1275772888,
          'name': 'Kai Xu'
        },
        {
          'id': 1119221049,
          'name': 'Matthias NieBner'
        },
        {
          'id': 1397627091,
          'name': 'Szymon Rusinkiewicz'
        },
        {
          'id': 1080296189,
          'name': 'Thomas A. Funkhouser'
        },
        {
          'id': 1480600619,
          'name': 'Thomas A. Funkhouser'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2001255713,
          'name': 'Coplanarity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013797118,
          'name': 'Planar'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yifei_Shi_PlaneMatch_Patch_Coplanarity_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 41
    },
    {
      'id': 462539760,
      'title': 'Generalizing a Person Retrieval Model Hetero- and Homogeneously',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1277244399,
          'name': 'Zhun Zhong'
        },
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1438105862,
          'name': 'Shaozi Li'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        }
      ],
      'field': [
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2022354964,
          'name': 'Social connectedness'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2049269054,
          'name': 'Homogeneous'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015316182,
          'name': 'Generalization'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zhun_Zhong_Generalizing_A_Person_ECCV_2018_paper.pdf',
      'citation_count': 47,
      'reference_count': 43
    },
    {
      'id': 462947165,
      'title': 'Fully Motion-Aware Network for Video Object Detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1050109697,
          'name': 'Shiyao Wang'
        },
        {
          'id': 1316541660,
          'name': 'Yucong Zhou'
        },
        {
          'id': 1126332756,
          'name': 'Junjie Yan'
        },
        {
          'id': 1364679732,
          'name': 'Zhidong Deng'
        }
      ],
      'field': [
        {
          'id': 2033317388,
          'name': 'Mobile ad hoc network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Shiyao_Wang_Fully_Motion-Aware_Network_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 28
    },
    {
      'id': 463077425,
      'title': 'Deep Cross-Modal Projection Learning for Image-Text Matching',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1056841273,
          'name': 'Ying Zhang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021769982,
          'name': 'Kullback–Leibler divergence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2026750366,
          'name': 'Compact space'
        },
        {
          'id': 2039296753,
          'name': 'Vector projection'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ying_Zhang_Deep_Cross-Modal_Projection_ECCV_2018_paper.pdf',
      'citation_count': 15,
      'reference_count': 44
    },
    {
      'id': 465368113,
      'title': 'Pixel2Mesh: Generating 3D Mesh Models from Single RGB Images',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100819817,
          'abbr': 'PRINCETON',
          'name': 'Princeton University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1136492835,
          'name': 'Nanyang Wang'
        },
        {
          'id': 1436560353,
          'name': 'Yinda Zhang'
        },
        {
          'id': 1476012931,
          'name': 'Zhuwen Li'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        }
      ],
      'field': [
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2005819271,
          'name': 'Triangle mesh'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047803559,
          'name': 'Polygon mesh'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 61,
      'reference_count': 30
    },
    {
      'id': 465874292,
      'title': 'Distractor-aware Siamese Networks for Visual Object Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1021140366,
          'name': 'Qiang Wang'
        },
        {
          'id': 1197729198,
          'name': 'Bo Li'
        },
        {
          'id': 1004795249,
          'name': 'Wei Wu'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018978572,
          'name': 'Incremental learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/pdf/1808.06048',
      'citation_count': 70,
      'reference_count': 40
    },
    {
      'id': 468586780,
      'title': 'Pairwise Body-Composition Attention for Recognizing Human-Object Interactions',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1488644272,
          'name': 'Hao-Shu Fang'
        },
        {
          'id': 1428207452,
          'name': 'Jinkun Cao'
        },
        {
          'id': 1074915033,
          'name': 'Yu-Wing Tai'
        },
        {
          'id': 1355268010,
          'name': 'Cewu Lu'
        }
      ],
      'field': [
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019247027,
          'name': 'Entire body region'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2033105456,
          'name': 'Human body'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Haoshu_Fang_Pairwise_Body-Part_Attention_ECCV_2018_paper.pdf',
      'citation_count': 6,
      'reference_count': 46
    },
    {
      'id': 46893661,
      'title': 'Salient Objects in Clutter: Bringing Salient Object Detection to the Foreground',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1235240356,
          'name': 'Deng-Ping Fan'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1479658590,
          'name': 'Jiang-Jiang Liu'
        },
        {
          'id': 1062748276,
          'name': 'Shanghua Gao'
        },
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1226019481,
          'name': 'Ali Borji'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Deng-Ping_Fan_Salient_Objects_in_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 47
    },
    {
      'id': 469961782,
      'title': 'The Sixth Visual Object Tracking VOT2018 Challenge Results',
      'affiliation': [
        {
          'id': 2100668856,
          'abbr': 'UL',
          'name': 'University of Ljubljana'
        },
        {
          'id': 2103081213,
          'abbr': 'BIRMINGHAM',
          'name': 'University of Birmingham'
        },
        {
          'id': 2101688584,
          'abbr': 'ČVUT',
          'name': 'Czech Technical University in Prague'
        },
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        },
        {
          'id': 2101698563,
          'abbr': 'TUW',
          'name': 'Vienna University of Technology'
        },
        {
          'id': 2104173829,
          'abbr': 'AIT',
          'name': 'Austrian Institute of Technology'
        },
        {
          'id': 2101260139,
          'abbr': 'UAM',
          'name': 'Autonomous University of Madrid'
        },
        {
          'id': 2100755990,
          'abbr': 'METU',
          'name': 'Middle East Technical University'
        },
        {
          'id': 2100853613,
          'abbr': 'UAB',
          'name': 'Autonomous University of Barcelona'
        },
        {
          'id': 2100615659,
          'abbr': 'UNINA',
          'name': 'University of Naples Federico II'
        },
        {
          'id': 2100320857,
          'abbr': 'UI',
          'name': 'University of Isfahan'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101206894,
          'abbr': 'UvA',
          'name': 'University of Amsterdam'
        },
        {
          'id': 2104342132,
          'abbr': 'UNISA',
          'name': 'University of South Australia'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2104875451,
          'abbr': 'KAIST',
          'name': 'KAIST'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2103285119,
          'abbr': 'POSTECH ',
          'name': 'Pohang University of Science and Technology'
        },
        {
          'id': 2100153474,
          'abbr': 'IIST',
          'name': 'Indian Institute of Space Science and Technology'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101115422,
          'abbr': 'UNIFEI',
          'name': 'Universidade Federal de Itajubá'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2102405609,
          'abbr': 'TUGRAZ',
          'name': 'Graz University of Technology'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        },
        {
          'id': 2102731498,
          'abbr': 'SNU',
          'name': 'Seoul National University'
        },
        {
          'id': 2100358192,
          'abbr': 'CAUC ',
          'name': 'Civil Aviation University of China'
        },
        {
          'id': 2103299767,
          'abbr': 'UPM',
          'name': 'Technical University of Madrid'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2104486317,
          'abbr': 'MUN',
          'name': 'Memorial University of Newfoundland'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2100204380,
          'abbr': 'NCUT',
          'name': 'North China University of Technology'
        },
        {
          'id': 2101927265,
          'abbr': 'JU',
          'name': 'Jiangnan University'
        },
        {
          'id': 2100984406,
          'abbr': 'IMPERIAL',
          'name': 'Imperial College London'
        },
        {
          'id': 2102719857,
          'abbr': 'NAU',
          'name': 'Nanjing Audit University'
        },
        {
          'id': 2103061621,
          'abbr': 'IU',
          'name': 'Indiana University'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1107745972,
          'name': 'Matej Kristan'
        },
        {
          'id': 1134240655,
          'name': 'Aleš Leonardis'
        },
        {
          'id': 1431263697,
          'name': 'Jiri Matas'
        },
        {
          'id': 1075568432,
          'name': 'Michael Felsberg'
        },
        {
          'id': 1155718471,
          'name': 'Roman Pflugfelder'
        },
        {
          'id': 1078212722,
          'name': 'Luka Cehovin Zajc'
        },
        {
          'id': 1294574734,
          'name': 'Tomas Vojir'
        },
        {
          'id': 1157646809,
          'name': 'Goutam Bhat'
        },
        {
          'id': 1054534655,
          'name': 'Alan Lukezic'
        },
        {
          'id': 1399367589,
          'name': 'Abdelrahman Eldesokey'
        },
        {
          'id': 1386039614,
          'name': 'Gustavo Fernández'
        },
        {
          'id': 1087633150,
          'name': 'Álvaro García-Martín'
        },
        {
          'id': 1168947827,
          'name': 'Álvaro Iglesias-Arias'
        },
        {
          'id': 1018629502,
          'name': 'A. Aydin Alatan'
        },
        {
          'id': 1291465279,
          'name': 'Abel Gonzalez-Garcia'
        },
        {
          'id': 1348199713,
          'name': 'Alfredo Petrosino'
        },
        {
          'id': 1260129741,
          'name': 'Alireza Memarmoghadam'
        },
        {
          'id': 1410113093,
          'name': 'Andrea Vedaldi'
        },
        {
          'id': 1333607900,
          'name': 'Andrej Muhič'
        },
        {
          'id': 1118033376,
          'name': 'Anfeng He'
        },
        {
          'id': 1334390875,
          'name': 'Arnold W. M. Smeulders'
        },
        {
          'id': 1098379337,
          'name': 'Asanka G. Perera'
        },
        {
          'id': 1173230308,
          'name': 'Bo Li'
        },
        {
          'id': 1245807374,
          'name': 'Boyu Chen'
        },
        {
          'id': 1060500490,
          'name': 'Changick Kim'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1476766303,
          'name': 'Changzhen Xiong'
        },
        {
          'id': 1166342548,
          'name': 'Cheng Tian'
        },
        {
          'id': 1469454660,
          'name': 'Chong Luo'
        },
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1154932337,
          'name': 'Cong Hao'
        },
        {
          'id': 1311763212,
          'name': 'Daijin Kim'
        },
        {
          'id': 1400633902,
          'name': 'Deepak Mishra'
        },
        {
          'id': 1202515373,
          'name': 'Deming Chen'
        },
        {
          'id': 1455667347,
          'name': 'Dong Wang'
        },
        {
          'id': 1280122386,
          'name': 'Dongyoon Wee'
        },
        {
          'id': 1133932209,
          'name': 'Efstratios Gavves'
        },
        {
          'id': 1130112633,
          'name': 'Erhan Gundogdu'
        },
        {
          'id': 1109118722,
          'name': 'Erik Velasco-Salido'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1075787074,
          'name': 'Fan Yang'
        },
        {
          'id': 1126384094,
          'name': 'Fei Zhao'
        },
        {
          'id': 1386848118,
          'name': 'Feng Li'
        },
        {
          'id': 1057066870,
          'name': 'Francesco Battistone'
        },
        {
          'id': 1403504512,
          'name': 'George De Ath'
        },
        {
          'id': 1189925693,
          'name': 'Gorthi R. K. Sai Subrahmanyam'
        },
        {
          'id': 1259959200,
          'name': 'Guilherme Sousa Bastos'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1404402661,
          'name': 'Hamed Kiani Galoogahi'
        },
        {
          'id': 1308669756,
          'name': 'Hankyeol Lee'
        },
        {
          'id': 1218797507,
          'name': 'Haojie Li'
        },
        {
          'id': 1390326497,
          'name': 'Haojie Zhao'
        },
        {
          'id': 1202186204,
          'name': 'Heng Fan'
        },
        {
          'id': 1061609348,
          'name': 'Honggang Zhang'
        },
        {
          'id': 1469524958,
          'name': 'Horst Possegger'
        },
        {
          'id': 1361554105,
          'name': 'Houqiang Li'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1321128472,
          'name': 'Hui-Zhi'
        },
        {
          'id': 1118860557,
          'name': 'Huiyun Li'
        },
        {
          'id': 1442182309,
          'name': 'Hyemin Lee'
        },
        {
          'id': 1475834989,
          'name': 'Hyung Jin Chang'
        },
        {
          'id': 1400605048,
          'name': 'Isabela Drummond'
        },
        {
          'id': 1481506405,
          'name': 'Jack Valmadre'
        },
        {
          'id': 1049074205,
          'name': 'Jaime Spencer Martin'
        },
        {
          'id': 1291311652,
          'name': 'Javaan Chahl'
        },
        {
          'id': 1150266006,
          'name': 'Jin Young Choi'
        },
        {
          'id': 1317158178,
          'name': 'Jing Li'
        },
        {
          'id': 1379962770,
          'name': 'Jinqiao Wang'
        },
        {
          'id': 1019616468,
          'name': 'Jinqing Qi'
        },
        {
          'id': 1349060239,
          'name': 'JinYoung Sung'
        },
        {
          'id': 1456286572,
          'name': 'Joakim Johnander'
        },
        {
          'id': 1088729312,
          'name': 'João F. Henriques'
        },
        {
          'id': 1197680241,
          'name': 'Jong Won Choi'
        },
        {
          'id': 1479818478,
          'name': 'Joost van de Weijer'
        },
        {
          'id': 1054818870,
          'name': 'Jorge Rodríguez Herranz'
        },
        {
          'id': 1290279024,
          'name': 'José M. Martínez'
        },
        {
          'id': 1057279694,
          'name': 'Josef Kittler'
        },
        {
          'id': 1264459532,
          'name': 'Junfei Zhuang'
        },
        {
          'id': 1115592857,
          'name': 'Junyu Gao'
        },
        {
          'id': 1205931201,
          'name': 'Klemen Grm'
        },
        {
          'id': 1167634559,
          'name': 'Lichao Zhang'
        },
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1454505709,
          'name': 'Lingxiao Yang'
        },
        {
          'id': 1107674270,
          'name': 'Litu Rout'
        },
        {
          'id': 1008377095,
          'name': 'Liu Si'
        },
        {
          'id': 1200036889,
          'name': 'Luca Bertinetto'
        },
        {
          'id': 1171124254,
          'name': 'Lutao Chu'
        },
        {
          'id': 1075169404,
          'name': 'Manqiang Che'
        },
        {
          'id': 1011506590,
          'name': 'Mario Edoardo Maresca'
        },
        {
          'id': 1425828879,
          'name': 'Martin Danelljan'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        },
        {
          'id': 1236289424,
          'name': 'Mohamed H. Abdelpakey'
        },
        {
          'id': 1130944979,
          'name': 'Mohamed S. Shehata'
        },
        {
          'id': 1094673657,
          'name': 'Myunggu Kang'
        },
        {
          'id': 1460099540,
          'name': 'Namhoon Lee'
        },
        {
          'id': 1046619653,
          'name': 'Ning Wang'
        },
        {
          'id': 1066019707,
          'name': 'Ondrej Miksik'
        },
        {
          'id': 1320442640,
          'name': 'Payman Moallem'
        },
        {
          'id': 1274837318,
          'name': 'Pablo Vicente-Moñivar'
        },
        {
          'id': 1276217973,
          'name': 'Pedro Senna'
        },
        {
          'id': 1360041657,
          'name': 'Peixia Li'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        },
        {
          'id': 1066653139,
          'name': 'Priya Mariam Raju'
        },
        {
          'id': 1346816392,
          'name': 'Qian Ruihe'
        },
        {
          'id': 1001343316,
          'name': 'Qiang Wang'
        },
        {
          'id': 1005486571,
          'name': 'Qin Zhou'
        },
        {
          'id': 1193214517,
          'name': 'Qing Guo'
        },
        {
          'id': 1075689828,
          'name': 'Rafael Martín-Nieto'
        },
        {
          'id': 1259216693,
          'name': 'Rama Krishna Gorthi'
        },
        {
          'id': 1075094626,
          'name': 'Ran Tao'
        },
        {
          'id': 1250623814,
          'name': 'Richard Bowden'
        },
        {
          'id': 1099118203,
          'name': 'Richard M. Everson'
        },
        {
          'id': 1443320493,
          'name': 'Runling Wang'
        },
        {
          'id': 1279991476,
          'name': 'Sangdoo Yun'
        },
        {
          'id': 1405350818,
          'name': 'Seokeon Choi'
        },
        {
          'id': 1254042232,
          'name': 'Sergio Vivas'
        },
        {
          'id': 1267979119,
          'name': 'Shuai Bai'
        },
        {
          'id': 1136157814,
          'name': 'Shuangping Huang'
        },
        {
          'id': 1165439448,
          'name': 'Sihang Wu'
        },
        {
          'id': 1338477300,
          'name': 'Simon Hadfield'
        },
        {
          'id': 1430576646,
          'name': 'Siwen Wang'
        },
        {
          'id': 1358016841,
          'name': 'Stuart Golodetz'
        },
        {
          'id': 1281343226,
          'name': 'Tang Ming'
        },
        {
          'id': 1202092621,
          'name': 'Tianyang Xu'
        },
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1453524743,
          'name': 'Tobias Fischer'
        },
        {
          'id': 1051836821,
          'name': 'Vincenzo Santopietro'
        },
        {
          'id': 1344893294,
          'name': 'Vitomir Štruc'
        },
        {
          'id': 1000229780,
          'name': 'Wang Wei'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1096901309,
          'name': 'Wei Feng'
        },
        {
          'id': 1004795249,
          'name': 'Wei Wu'
        },
        {
          'id': 1268828894,
          'name': 'Wei Zou'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1425399648,
          'name': 'Wengang Zhou'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        },
        {
          'id': 1363690899,
          'name': 'Xiaofan Zhang'
        },
        {
          'id': 1343560243,
          'name': 'Xiaohe Wu'
        },
        {
          'id': 1121241658,
          'name': 'Xiaojun Wu'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1150911725,
          'name': 'Yan Li'
        },
        {
          'id': 1007176324,
          'name': 'Yan Lu'
        },
        {
          'id': 1005501304,
          'name': 'Yee Wei Law'
        },
        {
          'id': 1020755317,
          'name': 'Yi Wu'
        },
        {
          'id': 1428225376,
          'name': 'Yiannis Demiris'
        },
        {
          'id': 1252659855,
          'name': 'Yicai Yang'
        },
        {
          'id': 1187722762,
          'name': 'Yifan Jiao'
        },
        {
          'id': 1198010728,
          'name': 'Yuhong Li'
        },
        {
          'id': 1000342130,
          'name': 'Yunhua Zhang'
        },
        {
          'id': 1277013739,
          'name': 'Yuxuan Sun'
        },
        {
          'id': 1210492454,
          'name': 'Zheng Zhang'
        },
        {
          'id': 1159216338,
          'name': 'Zheng Zhu'
        },
        {
          'id': 1211255047,
          'name': 'Zhen-Hua Feng'
        },
        {
          'id': 1051582778,
          'name': 'Zhihui Wang'
        },
        {
          'id': 1144696453,
          'name': 'Zhiqun He'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2017161760,
          'name': 'Benchmarking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11009-3_1',
      'citation_count': 47,
      'reference_count': 90
    },
    {
      'id': 47064839,
      'title': 'Multiple Connected Residual Network for Image Enhancement on Smartphones',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1241566079,
          'name': 'Jie Liu'
        },
        {
          'id': 1232110530,
          'name': 'Cheolkon Jung'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_12',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 471029486,
      'title': 'Learning Spectral Transform Network on 3D Surface for Non-rigid Shape Analysis',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1489586456,
          'name': 'Ruixuan Yu'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        },
        {
          'id': 1069699576,
          'name': 'Huibin Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2038080214,
          'name': 'Power function'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2041477979,
          'name': 'Shape analysis (digital geometry)'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1810.08950',
      'citation_count': 0,
      'reference_count': 45
    },
    {
      'id': 471805067,
      'title': 'ExFuse: Enhancing Feature Fusion for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1352168399,
          'name': 'Zhenli Zhang'
        },
        {
          'id': 1040991163,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        },
        {
          'id': 1000062846,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zhenli_Zhang_ExFuse_Enhancing_Feature_ECCV_2018_paper.pdf',
      'citation_count': 30,
      'reference_count': 39
    },
    {
      'id': 474414211,
      'title': 'DeepVS: A Deep Learning Based Video Saliency Prediction Approach',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1157400121,
          'name': 'Lai Jiang'
        },
        {
          'id': 1118664104,
          'name': 'Mai Xu'
        },
        {
          'id': 1250537850,
          'name': 'Tie Liu'
        },
        {
          'id': 1243392553,
          'name': 'Minglang Qiao'
        },
        {
          'id': 1287578436,
          'name': 'Zulin Wang'
        }
      ],
      'field': [
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2040758323,
          'name': 'Subnet'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2041307558,
          'name': 'Moving parts'
        },
        {
          'id': 2044009394,
          'name': 'Fixation (psychology)'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 67
    },
    {
      'id': 474429336,
      'title': 'Exploiting Vector Fields for Geometric Rectification of Distorted Document Images',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1296467528,
          'name': 'Gaofeng Meng'
        },
        {
          'id': 1016172729,
          'name': 'Yuanqi Su'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1114885725,
          'name': 'Chunhong Pan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031974094,
          'name': 'Vector field'
        },
        {
          'id': 2025838069,
          'name': 'Rectification'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046033212,
          'name': 'Ordinary differential equation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000149701,
          'name': 'Flattening'
        },
        {
          'id': 2020869662,
          'name': 'Cylinder'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2011074173,
          'name': 'Euler method'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Gaofeng_Meng_Exploiting_Vector_Fields_ECCV_2018_paper.html',
      'citation_count': 0,
      'reference_count': 27
    },
    {
      'id': 475627997,
      'title': 'Deep Kalman Filtering Network for Video Compression Artifact Reduction',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2104054782,
          'abbr': 'UW',
          'name': 'University of Washington'
        }
      ],
      'author': [
        {
          'id': 1438739039,
          'name': 'Guo Lu'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1168997702,
          'name': 'Xiaoyun Zhang'
        },
        {
          'id': 1278281228,
          'name': 'Zhiyong Gao'
        },
        {
          'id': 1312339543,
          'name': 'Ming-Ting Sun'
        }
      ],
      'field': [
        {
          'id': 2040502106,
          'name': 'Lossy compression'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2036626715,
          'name': 'Strong prior'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023893789,
          'name': 'Kalman filter'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007419952,
          'name': 'Data compression'
        },
        {
          'id': 2010620100,
          'name': 'Compression artifact'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Guo_Lu_Deep_Kalman_Filtering_ECCV_2018_paper.pdf',
      'citation_count': 10,
      'reference_count': 36
    },
    {
      'id': 479245760,
      'title': 'Mancs: A Multi-task Attentional Network with Curriculum Sampling for Person Re-Identification',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1121850164,
          'name': 'Cheng Wang'
        },
        {
          'id': 1407917600,
          'name': 'Qian Zhang'
        },
        {
          'id': 1197066356,
          'name': 'Chang Huang'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006251094,
          'name': 'Curriculum'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2034845953,
          'name': 'Multi-task learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Cheng_Wang_Mancs_A_Multi-task_ECCV_2018_paper.pdf',
      'citation_count': 33,
      'reference_count': 35
    },
    {
      'id': 479878767,
      'title': 'Proximal Dehaze-Net: A Prior Learning-Based Deep Network for Single Image Dehazing',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1049941057,
          'name': 'Dong Yang'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Dong_Yang_Proximal_Dehaze-Net_A_ECCV_2018_paper.pdf',
      'citation_count': 11,
      'reference_count': 37
    },
    {
      'id': 480119829,
      'title': 'Knowing Where to Look? Analysis on Attention of Visual Question Answering System',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1241137423,
          'name': 'Wei Li'
        },
        {
          'id': 1385342897,
          'name': 'Zehuan Yuan'
        },
        {
          'id': 1075170905,
          'name': 'Xiangzhong Fang'
        },
        {
          'id': 1247318969,
          'name': 'Changhu Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006037874,
          'name': 'Data science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2012712199,
          'name': 'Question answering'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1810.03821',
      'citation_count': 0,
      'reference_count': 12
    },
    {
      'id': 482621947,
      'title': 'Multi-Scale Structure-Aware Network for Human Pose Estimation',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100548198,
          'abbr': 'SUNY',
          'name': 'State University of New York System'
        }
      ],
      'author': [
        {
          'id': 1407402917,
          'name': 'Lipeng Ke'
        },
        {
          'id': 1138929517,
          'name': 'Ming-Ching Chang'
        },
        {
          'id': 1432492473,
          'name': 'Honggang Qi'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        }
      ],
      'field': [
        {
          'id': 2003673557,
          'name': 'Masking (art)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Lipeng_Ke_Multi-Scale_Structure-Aware_Network_ECCV_2018_paper.pdf',
      'citation_count': 8,
      'reference_count': 25
    },
    {
      'id': 483460176,
      'title': 'Person Search via a Mask-Guided Two-Stream CNN Model',
      'affiliation': [
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1358748087,
          'name': 'Di Chen'
        },
        {
          'id': 1415996572,
          'name': 'Shanshan Zhang'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1287663891,
          'name': 'Jian Yang'
        },
        {
          'id': 1178629966,
          'name': 'Ying Tai'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Di_Chen_Person_Search_via_ECCV_2018_paper.pdf',
      'citation_count': 17,
      'reference_count': 53
    },
    {
      'id': 485346018,
      'title': 'Cross-Modal Ranking with Soft Consistency and Noisy Labels for Robust RGB-T Tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1152602515,
          'name': 'Chenglong Li'
        },
        {
          'id': 1383369609,
          'name': 'Chengli Zhu'
        },
        {
          'id': 1216337417,
          'name': 'Yan Huang'
        },
        {
          'id': 1483002092,
          'name': 'Jin Tang'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2037248571,
          'name': 'Structured support vector machine'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Chenglong_Li_Cross-Modal_Ranking_with_ECCV_2018_paper.pdf',
      'citation_count': 6,
      'reference_count': 28
    },
    {
      'id': 486198584,
      'title': 'Pose-Normalized Image Generation for Person Re-identification',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2102156196,
          'abbr': 'NAIST',
          'name': 'Nara Institute of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1032075244,
          'name': 'Xuelin Qian'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1464427039,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1444961241,
          'name': 'Wenxuan Wang'
        },
        {
          'id': 1343358001,
          'name': 'Jie Qiu'
        },
        {
          'id': 1448998456,
          'name': 'Yang Wu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xuelin_Qian_Pose-Normalized_Image_Generation_ECCV_2018_paper.pdf',
      'citation_count': 16,
      'reference_count': 48
    },
    {
      'id': 488118120,
      'title': 'Weakly-Supervised Video Summarization Using Variational Encoder-Decoder and Web Prior',
      'affiliation': [
        {
          'id': 2101566148,
          'abbr': 'ALIBABAGROUP',
          'name': 'Alibaba Group'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103904358,
          'abbr': 'UMD',
          'name': 'University of Maryland, College Park'
        }
      ],
      'author': [
        {
          'id': 1236093116,
          'name': 'Sijia Cai'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1482937057,
          'name': 'Larry S. Davis'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2040554689,
          'name': 'Source code'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030342359,
          'name': 'Automatic summarization'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2000444311,
          'name': 'Semantic matching'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Sijia_Cai_Weakly-supervised_Video_Summarization_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 41
    },
    {
      'id': 490516556,
      'title': 'Unsupervised Image-to-Image Translation with Stacked Cycle-Consistent Adversarial Networks',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1214099888,
          'name': 'Minjun Li'
        },
        {
          'id': 1074505593,
          'name': 'Haozhi Huang'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1053600132,
          'name': 'Tong Zhang'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2003950936,
          'name': 'Image translation'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/pdf/1807.08536',
      'citation_count': 8,
      'reference_count': 34
    },
    {
      'id': 491277033,
      'title': 'Less Is More: Picking Informative Frames for Video Captioning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1134958413,
          'name': 'Yangyu Chen'
        },
        {
          'id': 1265461014,
          'name': 'Shuhui Wang'
        },
        {
          'id': 1354296024,
          'name': 'Weigang Zhang'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yangyu_Chen_Less_is_More_ECCV_2018_paper.pdf',
      'citation_count': 8,
      'reference_count': 45
    },
    {
      'id': 494556661,
      'title': 'VisDrone-VDT2018: The Vision Meets Drone Video Detection and Tracking Challenge Results',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        },
        {
          'id': 2103779695,
          'abbr': 'GE',
          'name': 'General Electric'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2101260139,
          'abbr': 'UAM',
          'name': 'Autonomous University of Madrid'
        },
        {
          'id': 2101700076,
          'abbr': 'TUB',
          'name': 'Technical University of Berlin'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100495785,
          'abbr': 'MU',
          'name': 'University of Missouri'
        },
        {
          'id': 2101859488,
          'abbr': 'NRL',
          'name': 'United States Naval Research Laboratory'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2104591464,
          'abbr': 'KIT',
          'name': 'Karlsruhe Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1484089631,
          'name': 'Xiao Bian'
        },
        {
          'id': 1037339858,
          'name': 'Haibin Ling'
        },
        {
          'id': 1180699841,
          'name': 'Qinghua Hu'
        },
        {
          'id': 1105702711,
          'name': 'Haotian Wu'
        },
        {
          'id': 1432501184,
          'name': 'Qinqin Nie'
        },
        {
          'id': 1482215150,
          'name': 'Hao Cheng'
        },
        {
          'id': 1281790522,
          'name': 'Chenfeng Liu'
        },
        {
          'id': 1355714571,
          'name': 'Xiaoyu Liu'
        },
        {
          'id': 1476829622,
          'name': 'Wenya Ma'
        },
        {
          'id': 1063479420,
          'name': 'Lianjie Wang'
        },
        {
          'id': 1068590739,
          'name': 'Arne Schumann'
        },
        {
          'id': 1390958192,
          'name': 'Dan Wang'
        },
        {
          'id': 1392821776,
          'name': 'Diego Ortego'
        },
        {
          'id': 1233603047,
          'name': 'Elena Luna'
        },
        {
          'id': 1357331676,
          'name': 'Emmanouil Michail'
        },
        {
          'id': 1250131148,
          'name': 'Erik Bochinski'
        },
        {
          'id': 1297033137,
          'name': 'Feng Ni'
        },
        {
          'id': 1004906475,
          'name': 'Filiz Bunyak'
        },
        {
          'id': 1230013839,
          'name': 'Gege Zhang'
        },
        {
          'id': 1414316340,
          'name': 'Guna Seetharaman'
        },
        {
          'id': 1136073280,
          'name': 'Guorong Li'
        },
        {
          'id': 1213027068,
          'name': 'Hongyang Yu'
        },
        {
          'id': 1383187379,
          'name': 'Ioannis Kompatsiaris'
        },
        {
          'id': 1369741603,
          'name': 'Jianfei Zhao'
        },
        {
          'id': 1336464945,
          'name': 'Jie Gao'
        },
        {
          'id': 1290279024,
          'name': 'José M. Martínez'
        },
        {
          'id': 1274607376,
          'name': 'Juan C. San Miguel'
        },
        {
          'id': 1230553618,
          'name': 'Kannappan Palaniappan'
        },
        {
          'id': 1433987988,
          'name': 'Konstantinos Avgerinakis'
        },
        {
          'id': 1072169187,
          'name': 'Lars Wilko Sommer'
        },
        {
          'id': 1440814268,
          'name': 'Martin Lauer'
        },
        {
          'id': 1379735834,
          'name': 'Mengkun Liu'
        },
        {
          'id': 1420793924,
          'name': 'Noor M. Al-Shakarji'
        },
        {
          'id': 1444293670,
          'name': 'Oliver Acatay'
        },
        {
          'id': 1045596348,
          'name': 'Panagiotis Giannakeris'
        },
        {
          'id': 1373975680,
          'name': 'Qijie Zhao'
        },
        {
          'id': 1387808977,
          'name': 'Qinghua Ma'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1244454774,
          'name': 'Stefanos Vrochidis'
        },
        {
          'id': 1140087426,
          'name': 'Thomas Sikora'
        },
        {
          'id': 1210751912,
          'name': 'Tobias Senst'
        },
        {
          'id': 1001971648,
          'name': 'Wei Song'
        },
        {
          'id': 1309668880,
          'name': 'Wei Tian'
        },
        {
          'id': 1032036874,
          'name': 'Wenhua Zhang'
        },
        {
          'id': 1067486252,
          'name': 'Yanyun Zhao'
        },
        {
          'id': 1155697890,
          'name': 'Yidong Bai'
        },
        {
          'id': 1374549588,
          'name': 'Yinan Wu'
        },
        {
          'id': 1022259518,
          'name': 'Yongtao Wang'
        },
        {
          'id': 1426738385,
          'name': 'Yuxuan Li'
        },
        {
          'id': 1096496087,
          'name': 'Zhaoliang Pi'
        },
        {
          'id': 1111776981,
          'name': 'Zhiming Ma'
        }
      ],
      'field': [
        {
          'id': 2008498025,
          'name': 'Drone'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2007313503,
          'name': 'Aerial photography'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-030-11021-5_29',
      'citation_count': 4,
      'reference_count': 54
    },
    {
      'id': 499545124,
      'title': 'Integral Human Pose Regression',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        }
      ],
      'author': [
        {
          'id': 1049646961,
          'name': 'Xiao Wei Sun'
        },
        {
          'id': 1013708909,
          'name': 'Bin Xiao'
        },
        {
          'id': 1165172349,
          'name': 'Fangyin Wei'
        },
        {
          'id': 1158233654,
          'name': 'Shuang Liang'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        },
        {
          'id': 2029089363,
          'name': 'Differentiable function'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiao_Sun_Integral_Human_Pose_ECCV_2018_paper.pdf',
      'citation_count': 23,
      'reference_count': 36
    },
    {
      'id': 5154697,
      'title': 'Stroke Controllable Fast Style Transfer with Adaptive Receptive Fields',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1219671846,
          'name': 'Yongcheng Jing'
        },
        {
          'id': 1106986497,
          'name': 'Yang Liu'
        },
        {
          'id': 1493220987,
          'name': 'Yezhou Yang'
        },
        {
          'id': 1341913761,
          'name': 'Zunlei Feng'
        },
        {
          'id': 1017160858,
          'name': 'Yizhou Yu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        }
      ],
      'field': [
        {
          'id': 2007469624,
          'name': 'Stylized fact'
        },
        {
          'id': 2030237245,
          'name': 'Convergence (routing)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2002134091,
          'name': 'Stroke'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yongcheng_Jing_Stroke_Controllable_Fast_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 39
    },
    {
      'id': 53522289,
      'title': 'Dynamic Conditional Networks for Few-Shot Learning',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1272920548,
          'name': 'Fang Zhao'
        },
        {
          'id': 1144640029,
          'name': 'Jian Zhao'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2034325238,
          'name': 'Filter bank'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2044656016,
          'name': 'Phrase'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/html/Fang_Zhao_Dynamic_Conditional_Networks_ECCV_2018_paper.html',
      'citation_count': 3,
      'reference_count': 34
    },
    {
      'id': 55715323,
      'title': 'Associating Inter-image Salient Instances for Weakly Supervised Semantic Segmentation',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2104603104,
          'abbr': 'CARDIFF',
          'name': 'Cardiff University'
        }
      ],
      'author': [
        {
          'id': 1277383896,
          'name': 'Ruochen Fan'
        },
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1003104441,
          'name': 'Gang Yu'
        },
        {
          'id': 1465322937,
          'name': 'Ralph Robert Martin'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000350581,
          'name': 'Bridging (networking)'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2045590784,
          'name': 'Graph partition'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ruochen_Fan_Associating_Inter-Image_Salient_ECCV_2018_paper.pdf',
      'citation_count': 7,
      'reference_count': 40
    },
    {
      'id': 55972142,
      'title': 'Saliency Detection in 360° Videos',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        }
      ],
      'author': [
        {
          'id': 1114348800,
          'name': 'Ziheng Zhang'
        },
        {
          'id': 1373263498,
          'name': 'Yanyu Xu'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ziheng_Zhang_Saliency_Detection_in_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 0
    },
    {
      'id': 56968530,
      'title': 'Stereo Computation for a Single Mixture Image',
      'affiliation': [
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1374135088,
          'name': 'Yiran Zhong'
        },
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2013492896,
          'name': 'Anaglyph 3D'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yiran_Zhong_Stereo_Computation_for_ECCV_2018_paper.pdf',
      'citation_count': 0,
      'reference_count': 28
    },
    {
      'id': 57052126,
      'title': 'Recurrent Fusion Network for Image Captioning',
      'affiliation': [
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1243936135,
          'name': 'Wenhao Jiang'
        },
        {
          'id': 1051842176,
          'name': 'Lin Ma'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1053600132,
          'name': 'Tong Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045506214,
          'name': 'Natural language'
        },
        {
          'id': 2042726860,
          'name': 'ResNet'
        },
        {
          'id': 2010870932,
          'name': 'Encoder'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2009806164,
          'name': 'Closed captioning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Wenhao_Jiang_Recurrent_Fusion_Network_ECCV_2018_paper.pdf',
      'citation_count': 22,
      'reference_count': 53
    },
    {
      'id': 57995110,
      'title': 'Efficient Semantic Scene Completion Network with Spatial Group Convolution',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101782593,
          'abbr': 'INTEL',
          'name': 'Intel'
        }
      ],
      'author': [
        {
          'id': 1054479440,
          'name': 'Jiahui Zhang'
        },
        {
          'id': 1263201152,
          'name': 'Hao Zhao'
        },
        {
          'id': 1050093779,
          'name': 'Anbang Yao'
        },
        {
          'id': 1296095501,
          'name': 'Yurong Chen'
        },
        {
          'id': 1168982309,
          'name': 'Li Zhang'
        },
        {
          'id': 1337885509,
          'name': 'Hongen Liao'
        }
      ],
      'field': [
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037875506,
          'name': 'Voxel'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Jiahui_Zhang_Efficient_Semantic_Scene_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 39
    },
    {
      'id': 58871925,
      'title': 'Joint Map and Symmetry Synchronization',
      'affiliation': [
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1257430018,
          'name': 'Yifan Sun'
        },
        {
          'id': 1081146375,
          'name': 'Zhenxiao Liang'
        },
        {
          'id': 1315403600,
          'name': 'Xiangru Huang'
        },
        {
          'id': 1022255500,
          'name': 'Qixing Huang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2049721966,
          'name': 'Synchronization'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2010399227,
          'name': 'Symmetry group'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020399200,
          'name': 'Visual Objects'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Qixing_Huang_Joint_Map_and_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 64
    },
    {
      'id': 5953446,
      'title': 'Bi-GANs-ST for Perceptual Image Super-resolution',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1296374343,
          'name': 'Xiaotong Luo'
        },
        {
          'id': 1448795566,
          'name': 'Rong Chen'
        },
        {
          'id': 1157583500,
          'name': 'Yuan Xie'
        },
        {
          'id': 1309298089,
          'name': 'Yanyun Qu'
        },
        {
          'id': 1111389283,
          'name': 'Cuihua Li'
        }
      ],
      'field': [
        {
          'id': 2015183468,
          'name': 'Weight Perception'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2001153861,
          'name': 'Mean squared error'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCVW_2018/papers/11133/Luo_Bi-GANs-ST_for_Perceptual_Image_Super-resolution_ECCVW_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 28
    },
    {
      'id': 60225365,
      'title': 'ExFuse: Enhancing Feature Fusion for Semantic Segmentation',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1352168399,
          'name': 'Zhenli Zhang'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1066467598,
          'name': 'Chao Peng'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 40
    },
    {
      'id': 60578822,
      'title': 'Deep Bilinear Learning for RGB-D Action Recognition',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2103751681,
          'abbr': 'DUNDEE',
          'name': 'University of Dundee'
        }
      ],
      'author': [
        {
          'id': 1225752713,
          'name': 'Jian-Fang Hu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1127728795,
          'name': 'Jiahui Pan'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        },
        {
          'id': 1255955000,
          'name': 'Jianguo Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2028333178,
          'name': 'Mutual information'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2023853871,
          'name': 'Bilinear interpolation'
        },
        {
          'id': 2005999770,
          'name': 'Cube'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/HU_Jian-Fang_Deep_Bilinear_Learning_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 42
    },
    {
      'id': 63908871,
      'title': 'AGIL: Learning Attention from Human for Visuomotor Tasks',
      'affiliation': [
        {
          'id': 2101950482,
          'abbr': 'UTEXAS',
          'name': 'University of Texas at Austin'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1017877316,
          'name': 'Ruohan Zhang'
        },
        {
          'id': 1148963489,
          'name': 'Zhuode Liu'
        },
        {
          'id': 1498839413,
          'name': 'Luxin Zhang'
        },
        {
          'id': 1114215888,
          'name': 'Jake Whritner'
        },
        {
          'id': 1129346650,
          'name': 'Karl Muller'
        },
        {
          'id': 1045515463,
          'name': 'Mary M. Hayhoe'
        },
        {
          'id': 1156350212,
          'name': 'Dana H. Ballard'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041467086,
          'name': 'Gaze'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005914640,
          'name': 'Imitation'
        },
        {
          'id': 2046784632,
          'name': 'Intelligent agent'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 44
    },
    {
      'id': 71362638,
      'title': 'A Segmentation-Aware Deep Fusion Network for Compressed Sensing MRI',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1365122479,
          'name': 'Zhiwen Fan'
        },
        {
          'id': 1437382708,
          'name': 'Liyan Sun'
        },
        {
          'id': 1164048111,
          'name': 'Xinghao Ding'
        },
        {
          'id': 1022219264,
          'name': 'Yue Huang'
        },
        {
          'id': 1127615383,
          'name': 'Congbo Cai'
        },
        {
          'id': 1005874265,
          'name': 'John W. Paisley'
        }
      ],
      'field': [
        {
          'id': 2048710313,
          'name': 'Compressed sensing'
        },
        {
          'id': 2039879149,
          'name': 'Computational photography'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020106911,
          'name': 'Fuse (electrical)'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2023693789,
          'name': 'Inverse problem'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2012530238,
          'name': 'Brain segmentation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zhiwen_Fan_A_Segmentation-aware_Deep_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 31
    },
    {
      'id': 74310980,
      'title': 'Semi-supervised Generative Adversarial Hashing for Image Retrieval.',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        }
      ],
      'author': [
        {
          'id': 1020379734,
          'name': 'Guan’an Wang'
        },
        {
          'id': 1449587090,
          'name': 'Qinghao Hu'
        },
        {
          'id': 1034367359,
          'name': 'Jian Cheng'
        },
        {
          'id': 1366943664,
          'name': 'Zengguang Hou'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2036439149,
          'name': 'Adversary'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2028025525,
          'name': 'Semantic similarity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2001087521,
          'name': 'Minimax'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Guanan_Wang_Semi-Supervised_Generative_Adversarial_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 30
    },
    {
      'id': 77576195,
      'title': 'Goal-Oriented Visual Question Generation via Intermediate Rewards',
      'affiliation': [
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2104872700,
          'abbr': 'NUST',
          'name': 'Nanjing University of Science and Technology'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1069432341,
          'name': 'Junjie Zhang'
        },
        {
          'id': 1103669667,
          'name': 'Qi Wu'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1047653608,
          'name': 'Jian Zhang'
        },
        {
          'id': 1103484507,
          'name': 'Jianfeng Lu'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011305082,
          'name': 'Goal orientation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Junjie_Zhang_Goal-Oriented_Visual_Question_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 24
    },
    {
      'id': 78177245,
      'title': 'BSN: Boundary Sensitive Network for Temporal Action Proposal Generation',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1005834509,
          'name': 'Tianwei Lin'
        },
        {
          'id': 1116512226,
          'name': 'Xu Zhao'
        },
        {
          'id': 1312929841,
          'name': 'Haisheng Su'
        },
        {
          'id': 1279861386,
          'name': 'Chongjing Wang'
        },
        {
          'id': 1331384629,
          'name': 'Ming Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 11,
      'reference_count': 46
    },
    {
      'id': 79632915,
      'title': 'Deep Boosting for Image Denoising',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1082179902,
          'name': 'Chang Chen'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1330686287,
          'name': 'Xinmei Tian'
        },
        {
          'id': 1003263715,
          'name': 'Feng Wu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Chang_Chen_Deep_Boosting_for_ECCV_2018_paper.pdf',
      'citation_count': 5,
      'reference_count': 37
    },
    {
      'id': 80720252,
      'title': 'Adversarial Open-World Person Re-Identification',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1131472851,
          'name': 'Xiang Li'
        },
        {
          'id': 1016262538,
          'name': 'Ancong Wu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2035658071,
          'name': 'Adversarial system'
        },
        {
          'id': 2032195789,
          'name': 'Discriminator'
        },
        {
          'id': 2036310149,
          'name': 'Extractor'
        },
        {
          'id': 2001392051,
          'name': 'Discriminative learning'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xiang_Li_Adversarial_Open-World_Person_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 37
    },
    {
      'id': 82821690,
      'title': 'Pose-Normalized Image Generation for Person Re-identification',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2102156196,
          'abbr': 'NAIST',
          'name': 'Nara Institute of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1032075244,
          'name': 'Xuelin Qian'
        },
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1444961241,
          'name': 'Wenxuan Wang'
        },
        {
          'id': 1343358001,
          'name': 'Jie Qiu'
        },
        {
          'id': 1448998456,
          'name': 'Yang Wu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 47
    },
    {
      'id': 88651062,
      'title': 'Incremental Multi-graph Matching via Diversity and Randomness Based Graph Clustering',
      'affiliation': [
        {
          'id': 2104793659,
          'abbr': 'ASU',
          'name': 'Arizona State University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102136657,
          'abbr': 'TENCENT',
          'name': 'Tencent'
        }
      ],
      'author': [
        {
          'id': 1025138269,
          'name': 'Tianshu Yu'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1360566008,
          'name': 'Baoxin Li'
        }
      ],
      'field': [
        {
          'id': 2014044392,
          'name': 'Clustering coefficient'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2049629684,
          'name': 'Intersection graph'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2049589594,
          'name': 'Randomness'
        },
        {
          'id': 2034176497,
          'name': 'Determinantal point process'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Tianshu_Yu_Incremental_Multi-graph_Matching_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 43
    },
    {
      'id': 89203237,
      'title': 'Highly-Economized Multi-view Binary Compression for Scalable Image Clustering',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103831083,
          'abbr': 'UQ',
          'name': 'University of Queensland'
        },
        {
          'id': 2101685002,
          'abbr': 'ETHZ',
          'name': 'ETH Zurich'
        },
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1092264287,
          'name': 'Zheng Zhang'
        },
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1478991474,
          'name': 'Jie Qin'
        },
        {
          'id': 1037247309,
          'name': 'Fan Zhu'
        },
        {
          'id': 1080407059,
          'name': 'Fumin Shen'
        },
        {
          'id': 1169123174,
          'name': 'Yong Xu'
        },
        {
          'id': 1460299166,
          'name': 'Ling Shao'
        },
        {
          'id': 1168663224,
          'name': 'Heng Tao Shen'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2005133827,
          'name': 'Discrete optimization'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2015118243,
          'name': 'Compression (physics)'
        },
        {
          'id': 2017552540,
          'name': 'Memory footprint'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Zheng_Zhang_Highly-Economized_Multi-View_Binary_ECCV_2018_paper.pdf',
      'citation_count': 4,
      'reference_count': 46
    },
    {
      'id': 92716518,
      'title': 'Multi-Scale Spatially-Asymmetric Recalibration for Image Classification',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102472718,
          'abbr': 'JHU',
          'name': 'Johns Hopkins University'
        }
      ],
      'author': [
        {
          'id': 1024760921,
          'name': 'Yan Wang'
        },
        {
          'id': 1497143435,
          'name': 'Lingxi Xie'
        },
        {
          'id': 1189491755,
          'name': 'Siyuan Qiao'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1495896431,
          'name': 'Alan L. Yuille'
        }
      ],
      'field': [
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047846951,
          'name': 'A-weighting'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yan_Wang_Spatial_Pyramid_Calibration_ECCV_2018_paper.pdf',
      'citation_count': 1,
      'reference_count': 41
    },
    {
      'id': 92982146,
      'title': 'Mask TextSpotter: An End-to-End Trainable Neural Network for Spotting Text with Arbitrary Shapes',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1433542305,
          'name': 'Pengyuan Lyu'
        },
        {
          'id': 1168135169,
          'name': 'Minghui Liao'
        },
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1214777185,
          'name': 'Wenhao Wu'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022892673,
          'name': 'Spotting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045909850,
          'name': 'End-to-end principle'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 59
    },
    {
      'id': 94367098,
      'title': 'ShuffleNet V2: Practical Guidelines for Efficient CNN Architecture Design',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1236124176,
          'name': 'Ningning Ma'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1144784103,
          'name': 'Hai-Tao Zheng'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025093314,
          'name': 'Network planning and design'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        },
        {
          'id': 2046490491,
          'name': 'FLOPS'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'https://arxiv.org/abs/1807.11164',
      'citation_count': 149,
      'reference_count': 38
    },
    {
      'id': 95969842,
      'title': 'Online Multi-Object Tracking with Dual Matching Attention Networks',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        }
      ],
      'author': [
        {
          'id': 1216109597,
          'name': 'Ji Zhu'
        },
        {
          'id': 1061593427,
          'name': 'Hua Yang'
        },
        {
          'id': 1393164687,
          'name': 'Nian Liu'
        },
        {
          'id': 1303507826,
          'name': 'Min Young Kim'
        },
        {
          'id': 1110446462,
          'name': 'Wenjun Zhang'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030938995,
          'name': 'Online and offline'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Ji_Zhu_Online_Multi-Object_Tracking_ECCV_2018_paper.pdf',
      'citation_count': 22,
      'reference_count': 60
    },
    {
      'id': 97463024,
      'title': 'Triplet Loss in Siamese Network for Object Tracking',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1000641760,
          'name': 'Xingping Dong'
        },
        {
          'id': 1189335714,
          'name': 'Jianbing Shen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Xingping_Dong_Triplet_Loss_with_ECCV_2018_paper.pdf',
      'citation_count': 23,
      'reference_count': 33
    },
    {
      'id': 97637277,
      'title': 'SkipNet: Learning Dynamic Routing in Convolutional Networks',
      'affiliation': [
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1480533871,
          'name': 'Xin Wang'
        },
        {
          'id': 1044762960,
          'name': 'Fisher Yu'
        },
        {
          'id': 1178854016,
          'name': 'Zi-Yi Dou'
        },
        {
          'id': 1370404621,
          'name': 'Trevor Darrell'
        },
        {
          'id': 1030796287,
          'name': 'Joseph E. Gonzalez'
        }
      ],
      'field': [
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047293058,
          'name': 'Reinforcement learning'
        },
        {
          'id': 2020114278,
          'name': 'Adaptive routing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://dblp.uni-trier.de/db/journals/corr/corr1711.html',
      'citation_count': 11,
      'reference_count': 26
    },
    {
      'id': 97715806,
      'title': 'Generative Semantic Manipulation with Mask-Contrasting GAN',
      'affiliation': [
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1214006197,
          'name': 'Hao Zhang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1192263557,
          'name': 'Eric P. Xing'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000790668,
          'name': 'Viewpoints'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2019199550,
          'name': 'Generative grammar'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 42
    },
    {
      'id': 98230529,
      'title': 'Learning to Reconstruct High-Quality 3D Shapes with Cascaded Fully Convolutional Networks',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100960288,
          'abbr': 'RWTH',
          'name': 'RWTH Aachen University'
        }
      ],
      'author': [
        {
          'id': 1449028813,
          'name': 'Yan-Pei Cao'
        },
        {
          'id': 1208760581,
          'name': 'Zheng-Ning Liu'
        },
        {
          'id': 1354948862,
          'name': 'Zheng-Fei Kuang'
        },
        {
          'id': 1415700021,
          'name': 'Leif Kobbelt'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021778545,
          'name': 'Fidelity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2130173583',
        'name': 'ECCV',
        'year': 2018
      },
      'source_url': 'http://openaccess.thecvf.com/content_ECCV_2018/papers/Yan-Pei_Cao_Learning_to_Reconstruct_ECCV_2018_paper.pdf',
      'citation_count': 2,
      'reference_count': 62
    },
    {
      'id': 1081346,
      'title': 'Superpixel-Based Two-View Deterministic Fitting for Multiple-Structure Data',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1188458451,
          'name': 'Guobao Xiao'
        },
        {
          'id': 1401008304,
          'name': 'Hanzi Wang'
        },
        {
          'id': 1223048328,
          'name': 'Yan Yan'
        },
        {
          'id': 1333605997,
          'name': 'David Suter'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015182454,
          'name': 'Model selection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2031314536,
          'name': 'Deterministic algorithm'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2000499954,
          'name': 'Geometric modeling'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 26
    },
    {
      'id': 110790297,
      'title': 'Real-Time Visual Tracking: Promoting the Robustness of Correlation Filter Learning',
      'affiliation': [
        {
          'id': 2103624135,
          'abbr': 'KU',
          'name': 'University of Kansas'
        },
        {
          'id': 2103091764,
          'abbr': 'BU',
          'name': 'Boston University'
        },
        {
          'id': 2102658611,
          'abbr': 'CHINAUNICOM',
          'name': 'China Unicom'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1450716152,
          'name': 'Yao Sui'
        },
        {
          'id': 1410274510,
          'name': 'Ziming Zhang'
        },
        {
          'id': 1053910801,
          'name': 'Guanghui Wang'
        },
        {
          'id': 1151338377,
          'name': 'Yafei Tang'
        },
        {
          'id': 1168982309,
          'name': 'Li Zhang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002472480,
          'name': 'Overfitting'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 43
    },
    {
      'id': 11212636,
      'title': 'A Benchmark for Automatic Visual Classification of Clinical Skin Disease Images',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        }
      ],
      'author': [
        {
          'id': 1464422618,
          'name': 'Xiaoxiao Sun'
        },
        {
          'id': 1003062086,
          'name': 'Jufeng Yang'
        },
        {
          'id': 1280963379,
          'name': 'Ming Sun'
        },
        {
          'id': 1169182352,
          'name': 'Kai Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002620626,
          'name': 'Computer-aided diagnosis'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030620901,
          'name': 'Disease'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025406521,
          'name': 'Disease classification'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 44
    },
    {
      'id': 12134543,
      'title': 'Marker-Less 3D Human Motion Capture with Monocular Image Sequence and Height-Maps',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1189072485,
          'name': 'Yu Du'
        },
        {
          'id': 1065680742,
          'name': 'Yongkang Wong'
        },
        {
          'id': 1217381705,
          'name': 'Yonghao Liu'
        },
        {
          'id': 1145327285,
          'name': 'Feilin Han'
        },
        {
          'id': 1288796463,
          'name': 'Yilin Gui'
        },
        {
          'id': 1161384832,
          'name': 'Zhen Wang'
        },
        {
          'id': 1052034367,
          'name': 'Mohan S. Kankanhalli'
        },
        {
          'id': 1122845272,
          'name': 'Weidong Geng'
        }
      ],
      'field': [
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004061258,
          'name': 'Motion capture'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 51,
      'reference_count': 45
    },
    {
      'id': 125593878,
      'title': 'Semantic Co-segmentation in Videos',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1138046264,
          'name': 'Yi-Hsuan Tsai'
        },
        {
          'id': 1438525052,
          'name': 'Guangyu Zhong'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2044171927,
          'name': 'Submodular set function'
        },
        {
          'id': 2012047219,
          'name': 'Semantics'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2027847959,
          'name': 'Property (programming)'
        },
        {
          'id': 2000871037,
          'name': 'Market segmentation'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 37
    },
    {
      'id': 12867912,
      'title': 'Unsupervised Deep Domain Adaptation for Pedestrian Detection',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2103303185,
          'abbr': 'UTWENTE',
          'name': 'University of Twente'
        }
      ],
      'author': [
        {
          'id': 1424861093,
          'name': 'Lihang Liu'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1091720650,
          'name': 'Lisheng Wu'
        },
        {
          'id': 1308120888,
          'name': 'Yong Yu'
        },
        {
          'id': 1496002253,
          'name': 'Michael Ying Yang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025316474,
          'name': 'Data Noise'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039238611,
          'name': 'Reuse'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018704791,
          'name': 'Domain adaptation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2039736172,
          'name': 'Pedestrian'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 22
    },
    {
      'id': 149051353,
      'title': 'Depth Map Super-Resolution by Deep Multi-Scale Guidance',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1000542780,
          'name': 'Tak-Wai Hui'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2005383798,
          'name': 'Upsampling'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 34
    },
    {
      'id': 152044813,
      'title': 'MARS: A Video Benchmark for Large-Scale Person Re-Identification',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1067976057,
          'name': 'Liang Zheng'
        },
        {
          'id': 1341989499,
          'name': 'Zhi Bie'
        },
        {
          'id': 1430625305,
          'name': 'Yifan Sun'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1424228217,
          'name': 'Chi Su'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040492217,
          'name': 'Motion analysis'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023124670,
          'name': 'Scratch'
        },
        {
          'id': 2049727272,
          'name': 'Mars Exploration Program'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 175,
      'reference_count': 44
    },
    {
      'id': 160655957,
      'title': 'Image Quality Assessment Using Similar Scene as Reference',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1375126759,
          'name': 'Yudong Liang'
        },
        {
          'id': 1072625952,
          'name': 'Jinjun Wang'
        },
        {
          'id': 1483150317,
          'name': 'Xingyu Wan'
        },
        {
          'id': 1136791764,
          'name': 'Yihong Gong'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019756680,
          'name': 'Structural similarity'
        },
        {
          'id': 2028502729,
          'name': 'Naturalness'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2009553560,
          'name': 'Limiting'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 23
    },
    {
      'id': 166701036,
      'title': 'Instance-Sensitive Fully Convolutional Networks',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1093647874,
          'name': 'Jifeng Dai'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1219021850,
          'name': 'Yi Li'
        },
        {
          'id': 1038906976,
          'name': 'Shaoqing Ren'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2010101173,
          'name': 'Small set'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2004268715,
          'name': 'Coco'
        },
        {
          'id': 2000871037,
          'name': 'Market segmentation'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 127,
      'reference_count': 30
    },
    {
      'id': 168028314,
      'title': 'Instance-Level Coupled Subspace Learning for Fine-Grained Sketch-Based Image Retrieval',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1165012984,
          'name': 'Peng Xu'
        },
        {
          'id': 1127495675,
          'name': 'Qiyue Yin'
        },
        {
          'id': 1025602439,
          'name': 'Yonggang Qi'
        },
        {
          'id': 1276350016,
          'name': 'Yi-Zhe Song'
        },
        {
          'id': 1376499826,
          'name': 'Zhanyu Ma'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2000350581,
          'name': 'Bridging (networking)'
        },
        {
          'id': 2034187438,
          'name': 'Feature engineering'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 29
    },
    {
      'id': 168124920,
      'title': 'Relay Backpropagation for Effective Learning of Deep Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1490545157,
          'name': 'Li Shen'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        }
      ],
      'field': [
        {
          'id': 2035077055,
          'name': 'Backpropagation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2009074217,
          'name': 'Relay'
        },
        {
          'id': 2031569861,
          'name': 'Performance improvement'
        },
        {
          'id': 2007568885,
          'name': 'Network architecture'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 43,
      'reference_count': 25
    },
    {
      'id': 16814278,
      'title': 'LSTM-CF: Unifying Context Modeling and Fusion with LSTMs for RGB-D Scene Labeling',
      'affiliation': [
        {
          'id': 2104807919,
          'abbr': 'HKU',
          'name': 'University of Hong Kong'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1145199802,
          'name': 'Zhen Li'
        },
        {
          'id': 1285666283,
          'name': 'Yukang Gan'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1485462380,
          'name': 'Yizhou Yu'
        },
        {
          'id': 1103323841,
          'name': 'Hui Cheng'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044619755,
          'name': 'Vertical direction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2020432855,
          'name': 'Fusion'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041282544,
          'name': 'Perceptual robotics'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 41
    },
    {
      'id': 173458710,
      'title': '3D Mask Face Anti-spoofing with Remote Photoplethysmography',
      'affiliation': [
        {
          'id': 2100566354,
          'abbr': 'HKBU',
          'name': 'Hong Kong Baptist University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1281074902,
          'name': 'Siqi Liu'
        },
        {
          'id': 1185720546,
          'name': 'Pong Chi Yuen'
        },
        {
          'id': 1136796451,
          'name': 'Shengping Zhang'
        },
        {
          'id': 1259121145,
          'name': 'Guoying Zhao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004753996,
          'name': 'Signal strength'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2031104290,
          'name': 'Heartbeat'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2028022761,
          'name': 'Spoofing attack'
        },
        {
          'id': 2022159621,
          'name': 'Photoplethysmogram'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 27
    },
    {
      'id': 191131989,
      'title': 'Graph Based Skeleton Motion Representation and Similarity Measurement for Action Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1151840534,
          'name': 'Pei Wang'
        },
        {
          'id': 1215326337,
          'name': 'Chunfeng Yuan'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1138746327,
          'name': 'Bing Li'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        }
      ],
      'field': [
        {
          'id': 2027361226,
          'name': 'Graph kernel'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 38
    },
    {
      'id': 19272861,
      'title': 'Deep Kinematic Pose Regression',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        }
      ],
      'author': [
        {
          'id': 1334636219,
          'name': 'Xingyi Zhou'
        },
        {
          'id': 1472779654,
          'name': 'Xiao Sun'
        },
        {
          'id': 1039939827,
          'name': 'Wei Zhang'
        },
        {
          'id': 1158233654,
          'name': 'Shuang Liang'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2042278253,
          'name': 'Articulated body pose estimation'
        },
        {
          'id': 2000308165,
          'name': '3D pose estimation'
        },
        {
          'id': 2044658123,
          'name': 'Object model'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2000979948,
          'name': 'Kinematics'
        },
        {
          'id': 2041230390,
          'name': 'Parameterized complexity'
        },
        {
          'id': 2000499954,
          'name': 'Geometric modeling'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 84,
      'reference_count': 33
    },
    {
      'id': 206909886,
      'title': 'POI: Multiple Object Tracking with High Performance Detection and Appearance Feature',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        }
      ],
      'author': [
        {
          'id': 1214043042,
          'name': 'Fengwei Yu'
        },
        {
          'id': 1167205632,
          'name': 'Wenbo Li'
        },
        {
          'id': 1454878353,
          'name': 'Quanquan Li'
        },
        {
          'id': 1058037770,
          'name': 'Yu Liu'
        },
        {
          'id': 1339784326,
          'name': 'Xiaohua Shi'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2030938995,
          'name': 'Online and offline'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 18
    },
    {
      'id': 214941144,
      'title': 'Cluster Sparsity Field for Hyperspectral Imagery Denoising',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1386051449,
          'name': 'Lei Zhang'
        },
        {
          'id': 1444713601,
          'name': 'Wei Wei'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        }
      ],
      'field': [
        {
          'id': 2038959866,
          'name': 'Noise reduction'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2019547936,
          'name': 'Hyperspectral imaging'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 30
    },
    {
      'id': 218787498,
      'title': 'Saliency Detection with Recurrent Fully Convolutional Networks',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1233338721,
          'name': 'Linzhao Wang'
        },
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1404440350,
          'name': 'Pingping Zhang'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005485485,
          'name': 'Saliency map'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 164,
      'reference_count': 37
    },
    {
      'id': 220302171,
      'title': 'Scene Text Detection with Adaptive Line Clustering',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1428593017,
          'name': 'Xinxu Qiao'
        },
        {
          'id': 1171814533,
          'name': 'He Zhu'
        },
        {
          'id': 1109246004,
          'name': 'Weiping Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020165775,
          'name': 'Average cost'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2019954370,
          'name': 'Directed graph'
        },
        {
          'id': 2034180937,
          'name': 'Recall'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 24
    },
    {
      'id': 222864656,
      'title': 'HFS: Hierarchical Feature Selection for Efficient Image Segmentation',
      'affiliation': [
        {
          'id': 2104598311,
          'abbr': 'NKU',
          'name': 'Nankai University'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100878779,
          'abbr': 'UCSD',
          'name': 'University of California, San Diego'
        }
      ],
      'author': [
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1189823451,
          'name': 'Yun Liu'
        },
        {
          'id': 1016629633,
          'name': 'Qibin Hou'
        },
        {
          'id': 1405162603,
          'name': 'JiaWang Bian'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        },
        {
          'id': 1026267296,
          'name': 'Shi-Min Hu'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045853961,
          'name': 'Segmentation-based object categorization'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2031107549,
          'name': 'Implementation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 49
    },
    {
      'id': 226901835,
      'title': 'Webly-Supervised Video Recognition by Mutually Voting for Relevant Web Images and Web Video Frames',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101741005,
          'abbr': 'GOOGLE',
          'name': 'Google'
        },
        {
          'id': 2102529982,
          'abbr': '',
          'name': 'Amazon.com'
        },
        {
          'id': 2104321403,
          'abbr': 'UCF',
          'name': 'University of Central Florida'
        }
      ],
      'author': [
        {
          'id': 1015377614,
          'name': 'Chuang Gan'
        },
        {
          'id': 1410350428,
          'name': 'Chen Sun'
        },
        {
          'id': 1043685870,
          'name': 'Lixin Duan'
        },
        {
          'id': 1347218119,
          'name': 'Boqing Gong'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033899647,
          'name': 'Voting'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2021454478,
          'name': 'Search engine'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 45,
      'reference_count': 46
    },
    {
      'id': 231048504,
      'title': 'Embedding Deep Metric for Person Re-identification: A Study Against Large Variations',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1477164535,
          'name': 'Hailin Shi'
        },
        {
          'id': 1036026566,
          'name': 'Yang Yang'
        },
        {
          'id': 1340134892,
          'name': 'Xiangyu Zhu'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2000396998,
          'name': 'Nonlinear dimensionality reduction'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 118,
      'reference_count': 41
    },
    {
      'id': 231225162,
      'title': 'A Siamese Long Short-Term Memory Architecture for Human Re-identification',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104277550,
          'abbr': 'USYD',
          'name': 'University of Sydney'
        }
      ],
      'author': [
        {
          'id': 1325366124,
          'name': 'Rahul Rama Varior'
        },
        {
          'id': 1223415641,
          'name': 'Bing Shuai'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2005356592,
          'name': 'Memorization'
        },
        {
          'id': 2018212508,
          'name': 'Visualization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2014351305,
          'name': 'Long short term memory'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 109,
      'reference_count': 67
    },
    {
      'id': 231527543,
      'title': 'Single Image Dehazing via Multi-scale Convolutional Neural Networks',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1248438389,
          'name': 'Wenqi Ren'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1111205108,
          'name': 'Hua Zhang'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 182,
      'reference_count': 40
    },
    {
      'id': 241823233,
      'title': 'Kernelized Subspace Ranking for Saliency Detection',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1176287567,
          'name': 'Tiantian Wang'
        },
        {
          'id': 1473487462,
          'name': 'Lihe Zhang'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1019616468,
          'name': 'Jinqing Qi'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2002006924,
          'name': 'Curse of dimensionality'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2005485485,
          'name': 'Saliency map'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 28,
      'reference_count': 61
    },
    {
      'id': 271538754,
      'title': 'Superpixel-Based Two-View Deterministic Fitting for Multiple-Structure Data',
      'affiliation': [
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1188458451,
          'name': 'Guobao Xiao'
        },
        {
          'id': 1401008304,
          'name': 'Hanzi Wang'
        },
        {
          'id': 1223048328,
          'name': 'Yan Yan'
        },
        {
          'id': 1333605997,
          'name': 'David Suter'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2015182454,
          'name': 'Model selection'
        },
        {
          'id': 2000499954,
          'name': 'Geometric modeling'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2031314536,
          'name': 'Deterministic algorithm'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-319-46466-4_31',
      'citation_count': 0,
      'reference_count': 26
    },
    {
      'id': 272997385,
      'title': 'Peak-Piloted Deep Network for Facial Expression Recognition',
      'affiliation': [
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1255880369,
          'name': 'Xiangyun Zhao'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1350047351,
          'name': 'Luoqi Liu'
        },
        {
          'id': 1156493250,
          'name': 'Teng Li'
        },
        {
          'id': 1150424276,
          'name': 'Yugang Han'
        },
        {
          'id': 1020692730,
          'name': 'Nuno Vasconcelos'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 37,
      'reference_count': 37
    },
    {
      'id': 275973439,
      'title': 'Building Dual-Domain Representations for Compression Artifacts Reduction',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1076495158,
          'name': 'Jun Guo'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049860491,
          'name': 'JPEG'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2035024680,
          'name': 'Discrete cosine transform'
        },
        {
          'id': 2010620100,
          'name': 'Compression artifact'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 50,
      'reference_count': 41
    },
    {
      'id': 284437099,
      'title': 'Iterative Reference Driven Metric Learning for Signer Independent Isolated Sign Language Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1088951560,
          'name': 'Fang Yin'
        },
        {
          'id': 1067315396,
          'name': 'Xiujuan Chai'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015795312,
          'name': 'Sign language'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 35
    },
    {
      'id': 292798159,
      'title': 'Accelerating Convolutional Neural Networks with Dominant Convolutional Kernel and Knowledge Pre-regression',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1216147727,
          'name': 'Zhenyang Wang'
        },
        {
          'id': 1364679732,
          'name': 'Zhidong Deng'
        },
        {
          'id': 1050109697,
          'name': 'Shiyao Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2024303337,
          'name': 'Cross entropy'
        },
        {
          'id': 2046295717,
          'name': 'Euclidean distance'
        },
        {
          'id': 2013129490,
          'name': 'MNIST database'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 25
    },
    {
      'id': 300172361,
      'title': 'Smooth Neighborhood Structure Mining on Multiple Affinity Graphs with Applications to Context-Sensitive Similarity',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2104530915,
          'abbr': 'CEE',
          'name': 'Center for Excellence in Education'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1300660086,
          'name': 'Song Bai'
        },
        {
          'id': 1271396267,
          'name': 'Shaoyan Sun'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1376978113,
          'name': 'Zhaoxiang Zhang'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042061408,
          'name': 'Structure mining'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2002594279,
          'name': 'Diffusion process'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 42
    },
    {
      'id': 300587676,
      'title': 'Deep Attributes Driven Multi-camera Person Re-identification',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102491248,
          'abbr': 'UTSA',
          'name': 'University of Texas at San Antonio'
        }
      ],
      'author': [
        {
          'id': 1424228217,
          'name': 'Chi Su'
        },
        {
          'id': 1398975099,
          'name': 'Shiliang Zhang'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        },
        {
          'id': 1413685145,
          'name': 'Qi Tian'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024565588,
          'name': 'Cosine Distance'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2042148111,
          'name': 'Modular design'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 113,
      'reference_count': 48
    },
    {
      'id': 300891051,
      'title': 'Robust Text Detection with Vertically-Regressed Proposal Network',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1489647727,
          'name': 'Donglai Xiang'
        },
        {
          'id': 1466754983,
          'name': 'Qiang Guo'
        },
        {
          'id': 1003220425,
          'name': 'Yan Xia'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2044619755,
          'name': 'Vertical direction'
        },
        {
          'id': 2037997839,
          'name': 'Receptive field'
        },
        {
          'id': 2027547384,
          'name': 'Existential quantification'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 16
    },
    {
      'id': 303127717,
      'title': 'The Thermal Infrared Visual Object Tracking VOT-TIR2016 Challenge Results',
      'affiliation': [
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        },
        {
          'id': 2100668856,
          'abbr': 'UL',
          'name': 'University of Ljubljana'
        },
        {
          'id': 2101688584,
          'abbr': 'ČVUT',
          'name': 'Czech Technical University in Prague'
        },
        {
          'id': 2103081213,
          'abbr': 'BIRMINGHAM',
          'name': 'University of Birmingham'
        },
        {
          'id': 2104173829,
          'abbr': 'AIT',
          'name': 'Austrian Institute of Technology'
        },
        {
          'id': 2103804960,
          'abbr': 'UNIPARTHENOPE',
          'name': 'Parthenope University of Naples'
        },
        {
          'id': 2101260139,
          'abbr': 'UAM',
          'name': 'Autonomous University of Madrid'
        },
        {
          'id': 2102361083,
          'abbr': '',
          'name': 'University of Ottawa'
        },
        {
          'id': 2100762232,
          'abbr': 'NTUU \'KPI\'',
          'name': 'Kyiv Polytechnic Institute'
        },
        {
          'id': 2104646275,
          'abbr': 'HACETTEPE',
          'name': 'Hacettepe University'
        },
        {
          'id': 2103285119,
          'abbr': 'POSTECH ',
          'name': 'Pohang University of Science and Technology'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2100495785,
          'abbr': 'MU',
          'name': 'University of Missouri'
        },
        {
          'id': 2101859488,
          'abbr': 'NRL',
          'name': 'United States Naval Research Laboratory'
        },
        {
          'id': 2102405609,
          'abbr': 'TUGRAZ',
          'name': 'Graz University of Technology'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        },
        {
          'id': 2100984406,
          'abbr': 'IMPERIAL',
          'name': 'Imperial College London'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103220118,
          'abbr': 'LEHIGH',
          'name': 'Lehigh University'
        }
      ],
      'author': [
        {
          'id': 1075568432,
          'name': 'Michael Felsberg'
        },
        {
          'id': 1107745972,
          'name': 'Matej Kristan'
        },
        {
          'id': 1431263697,
          'name': 'Jiri Matas'
        },
        {
          'id': 1134240655,
          'name': 'Aleš Leonardis'
        },
        {
          'id': 1047308075,
          'name': 'Roman P. Pflugfelder'
        },
        {
          'id': 1337879504,
          'name': 'Gustav Häger'
        },
        {
          'id': 1460167801,
          'name': 'Amanda Berg'
        },
        {
          'id': 1399367589,
          'name': 'Abdelrahman Eldesokey'
        },
        {
          'id': 1055061847,
          'name': 'Jörgen Ahlberg'
        },
        {
          'id': 1255681859,
          'name': 'Luka Cehovin'
        },
        {
          'id': 1294574734,
          'name': 'Tomas Vojir'
        },
        {
          'id': 1054534655,
          'name': 'Alan Lukezic'
        },
        {
          'id': 1386039614,
          'name': 'Gustavo Fernández'
        },
        {
          'id': 1348199713,
          'name': 'Alfredo Petrosino'
        },
        {
          'id': 1087633150,
          'name': 'Álvaro García-Martín'
        },
        {
          'id': 1236172815,
          'name': 'Andres Solis-Montero'
        },
        {
          'id': 1428869752,
          'name': 'Anton Varfolomieiev'
        },
        {
          'id': 1200582412,
          'name': 'Aykut Erdem'
        },
        {
          'id': 1162855666,
          'name': 'Bohyung Han'
        },
        {
          'id': 1337761921,
          'name': 'Chang-Ming Chang'
        },
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1451950057,
          'name': 'Erkut Erdem'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        },
        {
          'id': 1126384094,
          'name': 'Fei Zhao'
        },
        {
          'id': 1004906475,
          'name': 'Filiz Bunyak'
        },
        {
          'id': 1057066870,
          'name': 'Francesco Battistone'
        },
        {
          'id': 1103457050,
          'name': 'Gao Zhu'
        },
        {
          'id': 1414316340,
          'name': 'Guna Seetharaman'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1432492473,
          'name': 'Honggang Qi'
        },
        {
          'id': 1335277203,
          'name': 'Horst Bischof'
        },
        {
          'id': 1469524958,
          'name': 'Horst Possegger'
        },
        {
          'id': 1285448333,
          'name': 'Hyeonseob Nam'
        },
        {
          'id': 1481506405,
          'name': 'Jack Valmadre'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1324903682,
          'name': 'Jiayi Feng'
        },
        {
          'id': 1118545955,
          'name': 'Jochen Lang'
        },
        {
          'id': 1290279024,
          'name': 'José M. Martínez'
        },
        {
          'id': 1230553618,
          'name': 'Kannappan Palaniappan'
        },
        {
          'id': 1113938605,
          'name': 'Karel Lebeda'
        },
        {
          'id': 1180185113,
          'name': 'Ke Gao'
        },
        {
          'id': 1103991961,
          'name': 'Krystian Mikolajczyk'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1200036889,
          'name': 'Luca Bertinetto'
        },
        {
          'id': 1263871855,
          'name': 'Mahdieh Poostchi'
        },
        {
          'id': 1011506590,
          'name': 'Mario Edoardo Maresca'
        },
        {
          'id': 1425828879,
          'name': 'Martin Danelljan'
        },
        {
          'id': 1418426842,
          'name': 'Michael Arens'
        },
        {
          'id': 1086300120,
          'name': 'Ming Tang'
        },
        {
          'id': 1054614822,
          'name': 'Mooyeol Baek'
        },
        {
          'id': 1041574577,
          'name': 'Nana Fan'
        },
        {
          'id': 1420793924,
          'name': 'Noor M. Al-Shakarji'
        },
        {
          'id': 1066019707,
          'name': 'Ondrej Miksik'
        },
        {
          'id': 1188985275,
          'name': 'Osman Akin'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1075689828,
          'name': 'Rafael Martín-Nieto'
        },
        {
          'id': 1326480457,
          'name': 'Rengarajan Pelapur'
        },
        {
          'id': 1250623814,
          'name': 'Richard Bowden'
        },
        {
          'id': 1095620199,
          'name': 'Robert Laganière'
        },
        {
          'id': 1322761263,
          'name': 'Sebastian B. Krah'
        },
        {
          'id': 1341150884,
          'name': 'Shengkun Li'
        },
        {
          'id': 1143704762,
          'name': 'Shizeng Yao'
        },
        {
          'id': 1338477300,
          'name': 'Simon Hadfield'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        },
        {
          'id': 1376417948,
          'name': 'Stefan Becker'
        },
        {
          'id': 1358016841,
          'name': 'Stuart Golodetz'
        },
        {
          'id': 1135609818,
          'name': 'Tao-Hu'
        },
        {
          'id': 1290641162,
          'name': 'Thomas Mauthner'
        },
        {
          'id': 1051836821,
          'name': 'Vincenzo Santopietro'
        },
        {
          'id': 1167205632,
          'name': 'Wenbo Li'
        },
        {
          'id': 1081862130,
          'name': 'Wolfgang Hübner'
        },
        {
          'id': 1272395941,
          'name': 'Xin Li'
        },
        {
          'id': 1372846994,
          'name': 'Yang Li'
        },
        {
          'id': 1250039658,
          'name': 'Zhan Xu'
        },
        {
          'id': 1050073877,
          'name': 'Zhenyu He'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2048286749,
          'name': 'Infrared'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 51
    },
    {
      'id': 30894369,
      'title': 'Face Detection with End-to-End Integration of a ConvNet and a 3D Model',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104096522,
          'abbr': 'NCSU',
          'name': 'North Carolina State University'
        }
      ],
      'author': [
        {
          'id': 1041465431,
          'name': 'Yunzhu Li'
        },
        {
          'id': 1273959581,
          'name': 'Benyuan Sun'
        },
        {
          'id': 1088002310,
          'name': 'Tianfu Wu'
        },
        {
          'id': 1363803539,
          'name': 'Yizhou Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2034845953,
          'name': 'Multi-task learning'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2047203901,
          'name': 'Softmax function'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 79,
      'reference_count': 42
    },
    {
      'id': 311823489,
      'title': 'Deep Bimodal Regression for Apparent Personality Analysis',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1144918603,
          'name': 'Chen-Lin Zhang'
        },
        {
          'id': 1061819962,
          'name': 'Hao Zhang'
        },
        {
          'id': 1453321753,
          'name': 'Xiu-Shen Wei'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        }
      ],
      'field': [
        {
          'id': 2043756322,
          'name': 'Sensory cue'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041787513,
          'name': 'Modalities'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2004320427,
          'name': 'Personality'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 18,
      'reference_count': 15
    },
    {
      'id': 314472058,
      'title': 'Semantic Object Parsing with Graph LSTM',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1476676700,
          'name': 'Xiaohui Shen'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029586126,
          'name': 'Topological graph theory'
        },
        {
          'id': 2041433144,
          'name': 'Grid'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2042198013,
          'name': 'Graph database'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2049586271,
          'name': 'Diagonal'
        },
        {
          'id': 2004563257,
          'name': 'Spatial relation'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 101,
      'reference_count': 47
    },
    {
      'id': 32168315,
      'title': 'Multi-label Active Learning Based on Maximum Correntropy Criterion: Towards Robust and Discriminative Labeling',
      'affiliation': [
        {
          'id': 2102914960,
          'abbr': 'WHU',
          'name': 'Wuhan University'
        },
        {
          'id': 2104380003,
          'abbr': '',
          'name': 'University of Melbourne'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1156856197,
          'name': 'Zengmao Wang'
        },
        {
          'id': 1466871084,
          'name': 'Bo Du'
        },
        {
          'id': 1487407661,
          'name': 'Lefei Zhang'
        },
        {
          'id': 1131819447,
          'name': 'Liangpei Zhang'
        },
        {
          'id': 1456157074,
          'name': 'Meng Fang'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        }
      ],
      'field': [
        {
          'id': 2004484553,
          'name': 'Representativeness heuristic'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2010417696,
          'name': 'Active learning'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 43
    },
    {
      'id': 323685746,
      'title': 'Unsupervised Visual Representation Learning by Graph-Based Consistent Constraints',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        }
      ],
      'author': [
        {
          'id': 1081657789,
          'name': 'Dong Li'
        },
        {
          'id': 1126504332,
          'name': 'Wei-Chih Hung'
        },
        {
          'id': 1484879248,
          'name': 'Jia-Bin Huang'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        },
        {
          'id': 1152287350,
          'name': 'Narendra Ahuja'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2017679758,
          'name': 'Competitive learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2018178311,
          'name': 'Wake-sleep algorithm'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2023578727,
          'name': 'Self-organizing map'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 45
    },
    {
      'id': 330739148,
      'title': 'Tracking Persons-of-Interest via Adaptive Discriminative Features',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2101355250,
          'abbr': 'HANYANG',
          'name': 'Hanyang University'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1126786635,
          'name': 'Shun Zhang'
        },
        {
          'id': 1136791764,
          'name': 'Yihong Gong'
        },
        {
          'id': 1484879248,
          'name': 'Jia-Bin Huang'
        },
        {
          'id': 1229893089,
          'name': 'Jongwoo Lim'
        },
        {
          'id': 1072625952,
          'name': 'Jinjun Wang'
        },
        {
          'id': 1152287350,
          'name': 'Narendra Ahuja'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2002561005,
          'name': 'Hierarchical clustering'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2049662528,
          'name': 'Hungarian algorithm'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 45
    },
    {
      'id': 332082299,
      'title': 'Fast Face Sketch Synthesis via KD-Tree Search',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1243317844,
          'name': 'Yuqian Zhang'
        },
        {
          'id': 1209904738,
          'name': 'Nannan Wang'
        },
        {
          'id': 1150403679,
          'name': 'Shengchuan Zhang'
        },
        {
          'id': 1242168937,
          'name': 'Jie Li'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2031952916,
          'name': 'Local search (optimization)'
        },
        {
          'id': 2037149918,
          'name': 'k-d tree'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2014248115,
          'name': 'Time complexity'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2011607150,
          'name': 'Greedy algorithm'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 28
    },
    {
      'id': 335305950,
      'title': 'The Visual Object Tracking VOT2016 Challenge Results',
      'affiliation': [
        {
          'id': 2100668856,
          'abbr': 'UL',
          'name': 'University of Ljubljana'
        },
        {
          'id': 2103081213,
          'abbr': 'BIRMINGHAM',
          'name': 'University of Birmingham'
        },
        {
          'id': 2101688584,
          'abbr': 'ČVUT',
          'name': 'Czech Technical University in Prague'
        },
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        },
        {
          'id': 2104173829,
          'abbr': 'AIT',
          'name': 'Austrian Institute of Technology'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2103804960,
          'abbr': 'UNIPARTHENOPE',
          'name': 'Parthenope University of Naples'
        },
        {
          'id': 2100320857,
          'abbr': 'UI',
          'name': 'University of Isfahan'
        },
        {
          'id': 2101260139,
          'abbr': 'UAM',
          'name': 'Autonomous University of Madrid'
        },
        {
          'id': 2102361083,
          'abbr': '',
          'name': 'University of Ottawa'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2100566354,
          'abbr': 'HKBU',
          'name': 'Hong Kong Baptist University'
        },
        {
          'id': 2100762232,
          'abbr': 'NTUU \'KPI\'',
          'name': 'Kyiv Polytechnic Institute'
        },
        {
          'id': 2100755990,
          'abbr': 'METU',
          'name': 'Middle East Technical University'
        },
        {
          'id': 2104646275,
          'abbr': 'HACETTEPE',
          'name': 'Hacettepe University'
        },
        {
          'id': 2102739311,
          'abbr': 'KAUST',
          'name': 'King Abdullah University of Science and Technology'
        },
        {
          'id': 2103285119,
          'abbr': 'POSTECH ',
          'name': 'Pohang University of Science and Technology'
        },
        {
          'id': 2104900100,
          'abbr': 'NOTTINGHAM',
          'name': 'University of Nottingham'
        },
        {
          'id': 2103738096,
          'abbr': 'ALBANY',
          'name': 'University at Albany, SUNY'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2100153474,
          'abbr': 'IIST',
          'name': 'Indian Institute of Space Science and Technology'
        },
        {
          'id': 2101129866,
          'abbr': 'HKUST',
          'name': 'Hong Kong University of Science and Technology'
        },
        {
          'id': 2103355548,
          'abbr': 'ASELSAN',
          'name': 'ASELSAN'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2100495785,
          'abbr': 'MU',
          'name': 'University of Missouri'
        },
        {
          'id': 2102151147,
          'abbr': ')',
          'name': 'University of Verona'
        },
        {
          'id': 2101115422,
          'abbr': 'UNIFEI',
          'name': 'Universidade Federal de Itajubá'
        },
        {
          'id': 2101859488,
          'abbr': 'NRL',
          'name': 'United States Naval Research Laboratory'
        },
        {
          'id': 2104562951,
          'abbr': 'MARQUETTE',
          'name': 'Marquette University'
        },
        {
          'id': 2102405609,
          'abbr': 'TUGRAZ',
          'name': 'Graz University of Technology'
        },
        {
          'id': 2100984406,
          'abbr': 'IMPERIAL',
          'name': 'Imperial College London'
        },
        {
          'id': 2100369085,
          'abbr': 'ETRI',
          'name': 'Electronics and Telecommunications Research Institute'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2103220118,
          'abbr': 'LEHIGH',
          'name': 'Lehigh University'
        }
      ],
      'author': [
        {
          'id': 1107745972,
          'name': 'Matej Kristan'
        },
        {
          'id': 1134240655,
          'name': 'Aleš Leonardis'
        },
        {
          'id': 1431263697,
          'name': 'Jiri Matas'
        },
        {
          'id': 1075568432,
          'name': 'Michael Felsberg'
        },
        {
          'id': 1047308075,
          'name': 'Roman P. Pflugfelder'
        },
        {
          'id': 1255681859,
          'name': 'Luka Cehovin'
        },
        {
          'id': 1294574734,
          'name': 'Tomas Vojir'
        },
        {
          'id': 1337879504,
          'name': 'Gustav Häger'
        },
        {
          'id': 1054534655,
          'name': 'Alan Lukezic'
        },
        {
          'id': 1386039614,
          'name': 'Gustavo Fernández'
        },
        {
          'id': 1305493357,
          'name': 'Abhinav Gupta'
        },
        {
          'id': 1348199713,
          'name': 'Alfredo Petrosino'
        },
        {
          'id': 1260129741,
          'name': 'Alireza Memarmoghadam'
        },
        {
          'id': 1087633150,
          'name': 'Álvaro García-Martín'
        },
        {
          'id': 1236172815,
          'name': 'Andres Solis-Montero'
        },
        {
          'id': 1410113093,
          'name': 'Andrea Vedaldi'
        },
        {
          'id': 1312715155,
          'name': 'Andreas Robinson'
        },
        {
          'id': 1089729476,
          'name': 'Andy Jinhua Ma'
        },
        {
          'id': 1428869752,
          'name': 'Anton Varfolomieiev'
        },
        {
          'id': 1018629502,
          'name': 'A. Aydin Alatan'
        },
        {
          'id': 1200582412,
          'name': 'Aykut Erdem'
        },
        {
          'id': 1220115804,
          'name': 'Bernard Ghanem'
        },
        {
          'id': 1012766944,
          'name': 'Bin Liu'
        },
        {
          'id': 1162855666,
          'name': 'Bohyung Han'
        },
        {
          'id': 1479105645,
          'name': 'Brais Martinez'
        },
        {
          'id': 1337761921,
          'name': 'Chang-Ming Chang'
        },
        {
          'id': 1321247108,
          'name': 'Changsheng Xu'
        },
        {
          'id': 1337006810,
          'name': 'Chong Sun'
        },
        {
          'id': 1311763212,
          'name': 'Daijin Kim'
        },
        {
          'id': 1037882621,
          'name': 'Dapeng Chen'
        },
        {
          'id': 1154527231,
          'name': 'Dawei Du'
        },
        {
          'id': 1400633902,
          'name': 'Deepak Mishra'
        },
        {
          'id': 1173748546,
          'name': 'Dit Yan Yeung'
        },
        {
          'id': 1162202504,
          'name': 'Erhan Gundogdu'
        },
        {
          'id': 1451950057,
          'name': 'Erkut Erdem'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1082008849,
          'name': 'Fatih Porikli'
        },
        {
          'id': 1126384094,
          'name': 'Fei Zhao'
        },
        {
          'id': 1004906475,
          'name': 'Filiz Bunyak'
        },
        {
          'id': 1057066870,
          'name': 'Francesco Battistone'
        },
        {
          'id': 1103457050,
          'name': 'Gao Zhu'
        },
        {
          'id': 1139783968,
          'name': 'Giorgio Roffo'
        },
        {
          'id': 1189925693,
          'name': 'Gorthi R. K. Sai Subrahmanyam'
        },
        {
          'id': 1259959200,
          'name': 'Guilherme Sousa Bastos'
        },
        {
          'id': 1414316340,
          'name': 'Guna Seetharaman'
        },
        {
          'id': 1037210780,
          'name': 'Henry Medeiros'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1432492473,
          'name': 'Honggang Qi'
        },
        {
          'id': 1335277203,
          'name': 'Horst Bischof'
        },
        {
          'id': 1469524958,
          'name': 'Horst Possegger'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1442182309,
          'name': 'Hyemin Lee'
        },
        {
          'id': 1285448333,
          'name': 'Hyeonseob Nam'
        },
        {
          'id': 1351317906,
          'name': 'Hyung Jin Chang'
        },
        {
          'id': 1400605048,
          'name': 'Isabela Drummond'
        },
        {
          'id': 1481506405,
          'name': 'Jack Valmadre'
        },
        {
          'id': 1061267541,
          'name': 'Jae-chan Jeong'
        },
        {
          'id': 1210431912,
          'name': 'Jaeil Cho'
        },
        {
          'id': 1442966360,
          'name': 'Jae-Yeong Lee'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1324903682,
          'name': 'Jiayi Feng'
        },
        {
          'id': 1436742442,
          'name': 'Jin Gao'
        },
        {
          'id': 1128142903,
          'name': 'Jin Young Choi'
        },
        {
          'id': 1099072263,
          'name': 'Jingjing Xiao'
        },
        {
          'id': 1077169369,
          'name': 'Ji-Wan Kim'
        },
        {
          'id': 1260371523,
          'name': 'Jiyeoup Jeong'
        },
        {
          'id': 1088729312,
          'name': 'João F. Henriques'
        },
        {
          'id': 1118545955,
          'name': 'Jochen Lang'
        },
        {
          'id': 1197680241,
          'name': 'Jong Won Choi'
        },
        {
          'id': 1290279024,
          'name': 'José M. Martínez'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1115592857,
          'name': 'Junyu Gao'
        },
        {
          'id': 1230553618,
          'name': 'Kannappan Palaniappan'
        },
        {
          'id': 1113938605,
          'name': 'Karel Lebeda'
        },
        {
          'id': 1180185113,
          'name': 'Ke Gao'
        },
        {
          'id': 1103991961,
          'name': 'Krystian Mikolajczyk'
        },
        {
          'id': 1154777690,
          'name': 'Lei Qin'
        },
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1200036889,
          'name': 'Luca Bertinetto'
        },
        {
          'id': 1275066821,
          'name': 'Madan Kumar Rapuru'
        },
        {
          'id': 1263871855,
          'name': 'Mahdieh Poostchi'
        },
        {
          'id': 1011506590,
          'name': 'Mario Edoardo Maresca'
        },
        {
          'id': 1425828879,
          'name': 'Martin Danelljan'
        },
        {
          'id': 1009388770,
          'name': 'Matthias Mueller'
        },
        {
          'id': 1130181595,
          'name': 'Mengdan Zhang'
        },
        {
          'id': 1418426842,
          'name': 'Michael Arens'
        },
        {
          'id': 1246699087,
          'name': 'Michel F. Valstar'
        },
        {
          'id': 1086300120,
          'name': 'Ming Tang'
        },
        {
          'id': 1054614822,
          'name': 'Mooyeol Baek'
        },
        {
          'id': 1052749271,
          'name': 'Muhammad Haris Khan'
        },
        {
          'id': 1133501095,
          'name': 'Naiyan Wang'
        },
        {
          'id': 1041574577,
          'name': 'Nana Fan'
        },
        {
          'id': 1420793924,
          'name': 'Noor M. Al-Shakarji'
        },
        {
          'id': 1066019707,
          'name': 'Ondrej Miksik'
        },
        {
          'id': 1188985275,
          'name': 'Osman Akin'
        },
        {
          'id': 1320442640,
          'name': 'Payman Moallem'
        },
        {
          'id': 1276217973,
          'name': 'Pedro Senna'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        },
        {
          'id': 1185720546,
          'name': 'Pong Chi Yuen'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1075689828,
          'name': 'Rafael Martín-Nieto'
        },
        {
          'id': 1326480457,
          'name': 'Rengarajan Pelapur'
        },
        {
          'id': 1250623814,
          'name': 'Richard Bowden'
        },
        {
          'id': 1095620199,
          'name': 'Robert Laganière'
        },
        {
          'id': 1456424710,
          'name': 'Rustam Stolkin'
        },
        {
          'id': 1499621426,
          'name': 'Ryan Walsh'
        },
        {
          'id': 1322761263,
          'name': 'Sebastian B. Krah'
        },
        {
          'id': 1341150884,
          'name': 'Shengkun Li'
        },
        {
          'id': 1136796451,
          'name': 'Shengping Zhang'
        },
        {
          'id': 1143704762,
          'name': 'Shizeng Yao'
        },
        {
          'id': 1338477300,
          'name': 'Simon Hadfield'
        },
        {
          'id': 1142133235,
          'name': 'Simone Melzi'
        },
        {
          'id': 1492493700,
          'name': 'Siwei Lyu'
        },
        {
          'id': 1099279934,
          'name': 'Siyi Li'
        },
        {
          'id': 1376417948,
          'name': 'Stefan Becker'
        },
        {
          'id': 1358016841,
          'name': 'Stuart Golodetz'
        },
        {
          'id': 1107072291,
          'name': 'Sumithra Kakanuru'
        },
        {
          'id': 1488349066,
          'name': 'Sunglok Choi'
        },
        {
          'id': 1135609818,
          'name': 'Tao-Hu'
        },
        {
          'id': 1290641162,
          'name': 'Thomas Mauthner'
        },
        {
          'id': 1021002894,
          'name': 'Tianzhu Zhang'
        },
        {
          'id': 1078143281,
          'name': 'Tony P. Pridmore'
        },
        {
          'id': 1051836821,
          'name': 'Vincenzo Santopietro'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1167205632,
          'name': 'Wenbo Li'
        },
        {
          'id': 1081862130,
          'name': 'Wolfgang Hübner'
        },
        {
          'id': 1311571481,
          'name': 'Xiangyuan Lan'
        },
        {
          'id': 1240415016,
          'name': 'Xiaomeng Wang'
        },
        {
          'id': 1272395941,
          'name': 'Xin Li'
        },
        {
          'id': 1372846994,
          'name': 'Yang Li'
        },
        {
          'id': 1428225376,
          'name': 'Yiannis Demiris'
        },
        {
          'id': 1175228459,
          'name': 'Yifan Wang'
        },
        {
          'id': 1188685537,
          'name': 'Yuankai Qi'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1062468822,
          'name': 'Zexiong Cai'
        },
        {
          'id': 1250039658,
          'name': 'Zhan Xu'
        },
        {
          'id': 1050073877,
          'name': 'Zhenyu He'
        },
        {
          'id': 1215331942,
          'name': 'Zhizhen Chi'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': 'https://link.springer.com/chapter/10.1007%2F978-3-319-48881-3_54',
      'citation_count': 210,
      'reference_count': 106
    },
    {
      'id': 338199956,
      'title': 'Deep Networks with Stochastic Depth',
      'affiliation': [
        {
          'id': 2103818986,
          'abbr': 'CU',
          'name': 'Cornell University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1366951731,
          'name': 'Gao Huang'
        },
        {
          'id': 1343171790,
          'name': 'Yu Sun'
        },
        {
          'id': 1434676857,
          'name': 'Zhuang Liu'
        },
        {
          'id': 1104695543,
          'name': 'Daniel Sedra'
        },
        {
          'id': 1159231599,
          'name': 'Kilian Q. Weinberger'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2047438241,
          'name': 'Expressivity'
        },
        {
          'id': 2039589914,
          'name': 'Identity function'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 425,
      'reference_count': 31
    },
    {
      'id': 346107226,
      'title': 'Bi-Level Multi-column Convolutional Neural Networks for Facial Landmark Point Detection',
      'affiliation': [
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1373263498,
          'name': 'Yanyu Xu'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        },
        {
          'id': 2022863278,
          'name': 'Landmark point'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2019068603,
          'name': 'Ground truth'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 40
    },
    {
      'id': 347309563,
      'title': 'Person Re-identification via Recurrent Feature Aggregation',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        }
      ],
      'author': [
        {
          'id': 1289303631,
          'name': 'Yichao Yan'
        },
        {
          'id': 1100742744,
          'name': 'Bingbing Ni'
        },
        {
          'id': 1087264042,
          'name': 'Zhichao Song'
        },
        {
          'id': 1053338084,
          'name': 'Chao Ma'
        },
        {
          'id': 1037136854,
          'name': 'Yan Yan'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2002647072,
          'name': 'Timestamp'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2014351305,
          'name': 'Long short term memory'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 52,
      'reference_count': 38
    },
    {
      'id': 355353201,
      'title': 'Saliency Detection via Combining Region-Level and Pixel-Level Predictions with CNNs',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1466716924,
          'name': 'Youbao Tang'
        },
        {
          'id': 1326824858,
          'name': 'Xiangqian Wu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029738923,
          'name': 'Feature learning'
        },
        {
          'id': 2007845818,
          'name': 'Kadir–Brady saliency detector'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 24,
      'reference_count': 56
    },
    {
      'id': 386151670,
      'title': 'Face Recognition Using a Unified 3D Morphable Model',
      'affiliation': [
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        },
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2102990488,
          'abbr': 'QUB',
          'name': 'Queen\'s University Belfast'
        }
      ],
      'author': [
        {
          'id': 1053669992,
          'name': 'Guosheng Hu'
        },
        {
          'id': 1382990097,
          'name': 'Fei Yan'
        },
        {
          'id': 1318915048,
          'name': 'Chi-Ho Chan'
        },
        {
          'id': 1383176905,
          'name': 'Weihong Deng'
        },
        {
          'id': 1393657172,
          'name': 'William J. Christmas'
        },
        {
          'id': 1057279694,
          'name': 'Josef Kittler'
        },
        {
          'id': 1165410717,
          'name': 'Neil Robertson'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037510253,
          'name': 'Texture mapping'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2020879486,
          'name': 'Linearity'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 31
    },
    {
      'id': 386551692,
      'title': 'MARLow: A Joint Multiplanar Autoregressive and Low-Rank Approach for Image Completion',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1195423785,
          'name': 'Mading Li'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1423798589,
          'name': 'Xiaoyan Sun'
        },
        {
          'id': 1273935622,
          'name': 'Zongming Guo'
        }
      ],
      'field': [
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2004525820,
          'name': 'Minification'
        },
        {
          'id': 2001599554,
          'name': 'Autoregressive model'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': 'https://link.springer.com/chapter/10.1007/978-3-319-46478-7_50',
      'citation_count': 0,
      'reference_count': 28
    },
    {
      'id': 426138180,
      'title': 'Detecting Text in Natural Image with Connectionist Text Proposal Network',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1321815528,
          'name': 'Zhi Tian'
        },
        {
          'id': 1054920101,
          'name': 'Weilin Huang'
        },
        {
          'id': 1451904479,
          'name': 'Tong He'
        },
        {
          'id': 1107548307,
          'name': 'Pan He'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2016740561,
          'name': 'Connectionism'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 176,
      'reference_count': 33
    },
    {
      'id': 432109598,
      'title': 'Distractor-Supported Single Target Tracking in Extremely Cluttered Scenes',
      'affiliation': [
        {
          'id': 2103081213,
          'abbr': 'BIRMINGHAM',
          'name': 'University of Birmingham'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        }
      ],
      'author': [
        {
          'id': 1099072263,
          'name': 'Jingjing Xiao'
        },
        {
          'id': 1376960313,
          'name': 'Linbo Qiao'
        },
        {
          'id': 1456424710,
          'name': 'Rustam Stolkin'
        },
        {
          'id': 1134240655,
          'name': 'Aleš Leonardis'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 33
    },
    {
      'id': 43941842,
      'title': 'A Deep Learning-Based Approach to Progressive Vehicle Re-identification for Urban Surveillance',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1083107883,
          'name': 'Xinchen Liu'
        },
        {
          'id': 1334736635,
          'name': 'Wu Liu'
        },
        {
          'id': 1460871297,
          'name': 'Tao Mei'
        },
        {
          'id': 1099807678,
          'name': 'Huadong Ma'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023757099,
          'name': 'License'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2021185547,
          'name': 'Object Class'
        },
        {
          'id': 2034702142,
          'name': 'High recurrence rate'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 27
    },
    {
      'id': 441757325,
      'title': 'Search-Based Depth Estimation via Coupled Dictionary Learning with Large-Margin Structure Inference',
      'affiliation': [
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1112225105,
          'name': 'Yan Zhang'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        },
        {
          'id': 1023661815,
          'name': 'Xiaopeng Fan'
        },
        {
          'id': 1026941031,
          'name': 'Yan Wang'
        },
        {
          'id': 1159522967,
          'name': 'Feng Guo'
        },
        {
          'id': 1328597976,
          'name': 'Yueming Gao'
        },
        {
          'id': 1149572625,
          'name': 'Debin Zhao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 37
    },
    {
      'id': 458192143,
      'title': 'MARLow: A Joint Multiplanar Autoregressive and Low-Rank Approach for Image Completion',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1180626922,
          'name': 'Madingg Li'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        },
        {
          'id': 1178686476,
          'name': 'Zhiwei Xiong'
        },
        {
          'id': 1423798589,
          'name': 'Xiaoyan Sun'
        },
        {
          'id': 1273935622,
          'name': 'Zongming Guo'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2001599554,
          'name': 'Autoregressive model'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 29
    },
    {
      'id': 47627519,
      'title': 'Pattern Mining Saliency',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1434461379,
          'name': 'Yuqiu Kong'
        },
        {
          'id': 1313562640,
          'name': 'Lijun Wang'
        },
        {
          'id': 1022348556,
          'name': 'Xiuping Liu'
        },
        {
          'id': 1149392765,
          'name': 'Huchuan Lu'
        },
        {
          'id': 1420967034,
          'name': 'Xiang Ruan'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2004353831,
          'name': 'Random walk'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 33
    },
    {
      'id': 482258695,
      'title': 'Deep Cascaded Bi-Network for Face Hallucination',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1174696236,
          'name': 'Shizhan Zhu'
        },
        {
          'id': 1313041809,
          'name': 'Sifei Liu'
        },
        {
          'id': 1228111644,
          'name': 'Chen Change Loy'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2021678227,
          'name': 'Spatial configuration'
        },
        {
          'id': 2023135446,
          'name': 'Hallucinating'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2004352027,
          'name': 'Face hallucination'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 51
    },
    {
      'id': 490181445,
      'title': 'Learning Recursive Filters for Low-Level Vision via a Hybrid Neural Network',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1313041809,
          'name': 'Sifei Liu'
        },
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2040731089,
          'name': 'Time delay neural network'
        },
        {
          'id': 2026783426,
          'name': 'Hybrid neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047525826,
          'name': 'Recursion'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2006984519,
          'name': 'Finite impulse response'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2026693236,
          'name': 'Composite image filter'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 48,
      'reference_count': 36
    },
    {
      'id': 491116789,
      'title': 'A Cluster Sampling Method for Image Matting via Sparse Coding',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1112133011,
          'name': 'Xiaoxue Feng'
        },
        {
          'id': 1072611418,
          'name': 'Xiaohui Liang'
        },
        {
          'id': 1144314496,
          'name': 'Zili Zhang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008560764,
          'name': 'Cluster sampling'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023756774,
          'name': 'Cluster (physics)'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 26
    },
    {
      'id': 494197057,
      'title': 'Shape Augmented Regression for 3D Face Alignment',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101045008,
          'abbr': 'RPI',
          'name': 'Rensselaer Polytechnic Institute'
        }
      ],
      'author': [
        {
          'id': 1406000932,
          'name': 'Chao Gou'
        },
        {
          'id': 1367076216,
          'name': 'Yue Wu'
        },
        {
          'id': 1435035852,
          'name': 'Fei-Yue Wang'
        },
        {
          'id': 1406519944,
          'name': 'Qiang Ji'
        }
      ],
      'field': [
        {
          'id': 2038393284,
          'name': 'Large head'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026037073,
          'name': 'Regression'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 25
    },
    {
      'id': 494304448,
      'title': 'Novel Coplanar Line-Points Invariants for Robust Line Matching Across Views',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        }
      ],
      'author': [
        {
          'id': 1028320618,
          'name': 'Qi Jia'
        },
        {
          'id': 1111474868,
          'name': 'Xinkai Gao'
        },
        {
          'id': 1174210858,
          'name': 'Xin Fan'
        },
        {
          'id': 1227964737,
          'name': 'Zhongxuan Luo'
        },
        {
          'id': 1345134113,
          'name': 'Haojie Li'
        },
        {
          'id': 1046485070,
          'name': 'Ziyao Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009050054,
          'name': 'Blossom algorithm'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2006018171,
          'name': 'Homography'
        },
        {
          'id': 2024086463,
          'name': 'Rendering (computer graphics)'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2047094576,
          'name': 'Characteristic number'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 18
    },
    {
      'id': 49804899,
      'title': 'Online Human Action Detection Using Joint Classification-Regression Recurrent Neural Networks',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1127428292,
          'name': 'Yanghao Li'
        },
        {
          'id': 1171674693,
          'name': 'Cuiling Lan'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1448351141,
          'name': 'Wenjun Zeng'
        },
        {
          'id': 1215326337,
          'name': 'Chunfeng Yuan'
        },
        {
          'id': 1006458988,
          'name': 'Jiaying Liu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2042225087,
          'name': 'Subnetwork'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        },
        {
          'id': 2002093614,
          'name': 'On the fly'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 40,
      'reference_count': 30
    },
    {
      'id': 499275379,
      'title': 'Learning Image Matching by Simply Watching Video',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2100187957,
          'abbr': 'CSIRO',
          'name': 'Commonwealth Scientific and Industrial Research Organisation'
        }
      ],
      'author': [
        {
          'id': 1218065812,
          'name': 'Gucan Long'
        },
        {
          'id': 1099683443,
          'name': 'Laurent Kneip'
        },
        {
          'id': 1374278060,
          'name': 'Jose M. Alvarez'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1311053258,
          'name': 'Xiaohu Zhang'
        },
        {
          'id': 1288598170,
          'name': 'Qifeng Yu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046363833,
          'name': 'Motion interpolation'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2016541624,
          'name': 'Speech coding'
        },
        {
          'id': 2036507853,
          'name': 'Coherence (physics)'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 42
    },
    {
      'id': 6199856,
      'title': 'Is Faster R-CNN Doing Well for Pedestrian Detection?',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1497055755,
          'name': 'Liliang Zhang'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1365597444,
          'name': 'Xiaodan Liang'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        }
      ],
      'field': [
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2013922311,
          'name': 'Bootstrapping'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 215,
      'reference_count': 32
    },
    {
      'id': 64293216,
      'title': 'A Novel Tiny Object Recognition Algorithm Based on Unit Statistical Curvature Feature',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1294495559,
          'name': 'Yimei Kang'
        },
        {
          'id': 1154687297,
          'name': 'Xiang Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003914179,
          'name': 'Interference (wave propagation)'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2045637833,
          'name': 'Image texture'
        },
        {
          'id': 2034891279,
          'name': 'Curvature'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2007911277,
          'name': 'Algorithm'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 21
    },
    {
      'id': 66521434,
      'title': 'Joint Learning of Semantic and Latent Attributes',
      'affiliation': [
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1178660196,
          'name': 'Peixi Peng'
        },
        {
          'id': 1484177249,
          'name': 'Yonghong Tian'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1306980273,
          'name': 'Yaowei Wang'
        },
        {
          'id': 1059480396,
          'name': 'Tiejun Huang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022050995,
          'name': 'Semantic property'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2007294235,
          'name': 'Latent semantic indexing'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2030753631,
          'name': 'Probabilistic latent semantic analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 52
    },
    {
      'id': 7003154,
      'title': 'Hierarchical Dynamic Parsing and Encoding for Action Recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104098839,
          'abbr': 'NU',
          'name': 'Northwestern University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1204542489,
          'name': 'Bing Su'
        },
        {
          'id': 1028139797,
          'name': 'Jiahuan Zhou'
        },
        {
          'id': 1234300559,
          'name': 'Xiaoqing Ding'
        },
        {
          'id': 1242443977,
          'name': 'Hao Wang'
        },
        {
          'id': 1433782035,
          'name': 'Ying Wu'
        }
      ],
      'field': [
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015031404,
          'name': 'Speech recognition'
        },
        {
          'id': 2016625739,
          'name': 'Olympic sports'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2003271894,
          'name': 'Generic Action'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2027761271,
          'name': 'Gesture'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 37
    },
    {
      'id': 72105737,
      'title': 'Evaluation of LBP and Deep Texture Descriptors with a New Robustness Benchmark',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2103632264,
          'abbr': 'UW',
          'name': 'University of Waterloo'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1418975531,
          'name': 'Li Liu'
        },
        {
          'id': 1144112292,
          'name': 'Paul W. Fieguth'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1390027563,
          'name': 'Matti Pietikäinen'
        },
        {
          'id': 1216838484,
          'name': 'Dewen Hu'
        }
      ],
      'field': [
        {
          'id': 2039208857,
          'name': 'Testing protocols'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 12,
      'reference_count': 49
    },
    {
      'id': 73145141,
      'title': 'Face Recognition from Multiple Stylistic Sketches: Scenarios, Datasets, and Evaluation',
      'affiliation': [
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1348205655,
          'name': 'Chunlei Peng'
        },
        {
          'id': 1209904738,
          'name': 'Nannan Wang'
        },
        {
          'id': 1216343128,
          'name': 'Xinbo Gao'
        },
        {
          'id': 1242168937,
          'name': 'Jie Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2011055921,
          'name': 'Natural language processing'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2006041051,
          'name': 'Law enforcement'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 40
    },
    {
      'id': 78882795,
      'title': 'Template-Free 3D Reconstruction of Poorly-Textured Nonrigid Surfaces',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        }
      ],
      'author': [
        {
          'id': 1499725988,
          'name': 'Xuan Wang'
        },
        {
          'id': 1341663681,
          'name': 'Mathieu Salzmann'
        },
        {
          'id': 1332466848,
          'name': 'Fei Wang'
        },
        {
          'id': 1024923320,
          'name': 'Jizhong Zhao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014696058,
          'name': 'Monocular'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2035601555,
          'name': 'Isometry'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2001568964,
          'name': 'Image registration'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 36
    },
    {
      'id': 79362509,
      'title': 'Real-Time RGB-D Activity Prediction by Soft Regression',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1225752713,
          'name': 'Jian-Fang Hu'
        },
        {
          'id': 1156171683,
          'name': 'Wei-Shi Zheng'
        },
        {
          'id': 1238929751,
          'name': 'Lianyang Ma'
        },
        {
          'id': 1121747721,
          'name': 'Gang Wang'
        },
        {
          'id': 1164053552,
          'name': 'Jian-Huang Lai'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2039301360,
          'name': 'Communication channel'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 43
    },
    {
      'id': 87540914,
      'title': 'Efficient and robust semi-supervised learning over a sparse-regularized graph',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101483039,
          'abbr': 'MST',
          'name': 'Missouri University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1017076425,
          'name': 'Hang Su'
        },
        {
          'id': 1119063561,
          'name': 'Jun Zhu'
        },
        {
          'id': 1331788745,
          'name': 'Zhaozheng Yin'
        },
        {
          'id': 1129943524,
          'name': 'Yinpeng Dong'
        },
        {
          'id': 1325456581,
          'name': 'Bo Zhang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2043168940,
          'name': 'Open set'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2040504686,
          'name': 'Graph (abstract data type)'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 20
    },
    {
      'id': 88037667,
      'title': 'Robust Facial Landmark Detection via Recurrent Attentive-Refinement Networks',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1232268390,
          'name': 'Shengtao Xiao'
        },
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1334513300,
          'name': 'Junliang Xing'
        },
        {
          'id': 1249782652,
          'name': 'Hanjiang Lai'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        },
        {
          'id': 1117175455,
          'name': 'Ashraf A. Kassim'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2028939717,
          'name': 'Landmark'
        },
        {
          'id': 2029493401,
          'name': 'Recurrent neural network'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 78,
      'reference_count': 28
    },
    {
      'id': 9346718,
      'title': 'Online Heterogeneous Transfer Learning by Weighted Offline and Online Classifiers',
      'affiliation': [
        {
          'id': 2103486486,
          'abbr': 'SCUT',
          'name': 'South China University of Technology'
        }
      ],
      'author': [
        {
          'id': 1263144301,
          'name': 'Yuguang Yan'
        },
        {
          'id': 1421026305,
          'name': 'Qingyao Wu'
        },
        {
          'id': 1121483151,
          'name': 'Mingkui Tan'
        },
        {
          'id': 1017653365,
          'name': 'Huaqing Min'
        }
      ],
      'field': [
        {
          'id': 2002842550,
          'name': 'Web page'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2002393057,
          'name': 'Labeled data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2012275282,
          'name': 'Social network'
        },
        {
          'id': 2040012394,
          'name': 'Weighting'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132949077',
        'name': 'ECCV',
        'year': 2016
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 11
    },
    {
      'id': 100085396,
      'title': 'Single-Image Super-Resolution: A Benchmark',
      'affiliation': [
        {
          'id': 2100271398,
          'abbr': 'UCM',
          'name': 'University of California, Merced'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1016489988,
          'name': 'Chih-Yuan Yang'
        },
        {
          'id': 1053338084,
          'name': 'Chao Ma'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2046557386,
          'name': 'Benchmark (surveying)'
        },
        {
          'id': 2020874323,
          'name': 'Image quality'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2040512229,
          'name': 'Quantitative Evaluations'
        },
        {
          'id': 2009572186,
          'name': 'Visual perception'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 169,
      'reference_count': 50
    },
    {
      'id': 104569870,
      'title': 'Recovering Scene Geometry under Wavy Fluid via Distortion and Defocus Analysis',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2100833262,
          'abbr': 'CU',
          'name': 'Columbia University'
        }
      ],
      'author': [
        {
          'id': 1241053157,
          'name': 'Mingjie Zhang'
        },
        {
          'id': 1383289547,
          'name': 'Xing Lin'
        },
        {
          'id': 1057128406,
          'name': 'Mohit Gupta'
        },
        {
          'id': 1462346539,
          'name': 'Jinli Suo'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2015875560,
          'name': 'Geometry'
        },
        {
          'id': 2048103201,
          'name': 'Refraction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048954615,
          'name': 'Aperture'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 39
    },
    {
      'id': 106154765,
      'title': 'The Visual Object Tracking VOT2014 Challenge Results',
      'affiliation': [
        {
          'id': 2100668856,
          'abbr': 'UL',
          'name': 'University of Ljubljana'
        },
        {
          'id': 2104173829,
          'abbr': 'AIT',
          'name': 'Austrian Institute of Technology'
        },
        {
          'id': 2103081213,
          'abbr': 'BIRMINGHAM',
          'name': 'University of Birmingham'
        },
        {
          'id': 2101688584,
          'abbr': 'ČVUT',
          'name': 'Czech Technical University in Prague'
        },
        {
          'id': 2103804960,
          'abbr': 'UNIPARTHENOPE',
          'name': 'Parthenope University of Naples'
        },
        {
          'id': 2103356563,
          'abbr': 'PANASONIC',
          'name': 'Panasonic'
        },
        {
          'id': 2103285119,
          'abbr': 'POSTECH ',
          'name': 'Pohang University of Science and Technology'
        },
        {
          'id': 2101331022,
          'abbr': 'LiU',
          'name': 'Linköping University'
        },
        {
          'id': 2102405609,
          'abbr': 'TUGRAZ',
          'name': 'Graz University of Technology'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2102731498,
          'abbr': 'SNU',
          'name': 'Seoul National University'
        },
        {
          'id': 2100369085,
          'abbr': 'ETRI',
          'name': 'Electronics and Telecommunications Research Institute'
        },
        {
          'id': 2102131632,
          'abbr': 'UC',
          'name': 'University of Coimbra'
        },
        {
          'id': 2100853613,
          'abbr': 'UAB',
          'name': 'Autonomous University of Barcelona'
        },
        {
          'id': 2101097184,
          'abbr': 'SURREY',
          'name': 'University of Surrey'
        },
        {
          'id': 2101650456,
          'abbr': 'EPFL',
          'name': 'École Polytechnique Fédérale de Lausanne'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101885056,
          'abbr': 'OX',
          'name': 'University of Oxford'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1107745972,
          'name': 'Matej Kristan'
        },
        {
          'id': 1047308075,
          'name': 'Roman P. Pflugfelder'
        },
        {
          'id': 1134240655,
          'name': 'Aleš Leonardis'
        },
        {
          'id': 1431263697,
          'name': 'Jiri Matas'
        },
        {
          'id': 1255681859,
          'name': 'Luka Cehovin'
        },
        {
          'id': 1352959755,
          'name': 'Georg Nebehay'
        },
        {
          'id': 1294574734,
          'name': 'Tomas Vojir'
        },
        {
          'id': 1386039614,
          'name': 'Gustavo Fernández'
        },
        {
          'id': 1054534655,
          'name': 'Alan Lukezic'
        },
        {
          'id': 1139027243,
          'name': 'Aleksandar Dimitriev'
        },
        {
          'id': 1348199713,
          'name': 'Alfredo Petrosino'
        },
        {
          'id': 1393485780,
          'name': 'Amir Saffari'
        },
        {
          'id': 1277847200,
          'name': 'Bo Li'
        },
        {
          'id': 1162855666,
          'name': 'Bohyung Han'
        },
        {
          'id': 1077085359,
          'name': 'Cherkeng Heng'
        },
        {
          'id': 1145218452,
          'name': 'Christophe Garcia'
        },
        {
          'id': 1194576029,
          'name': 'Dominik Pangersic'
        },
        {
          'id': 1337879504,
          'name': 'Gustav Häger'
        },
        {
          'id': 1223377116,
          'name': 'Fahad Shahbaz Khan'
        },
        {
          'id': 1479616541,
          'name': 'Franci Oven'
        },
        {
          'id': 1469524958,
          'name': 'Horst Possegger'
        },
        {
          'id': 1335277203,
          'name': 'Horst Bischof'
        },
        {
          'id': 1285448333,
          'name': 'Hyeonseob Nam'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1367968910,
          'name': 'Jijia Li'
        },
        {
          'id': 1150266006,
          'name': 'Jin Young Choi'
        },
        {
          'id': 1014796927,
          'name': 'Jin Woo Choi'
        },
        {
          'id': 1088729312,
          'name': 'João F. Henriques'
        },
        {
          'id': 1479818478,
          'name': 'Joost van de Weijer'
        },
        {
          'id': 1080174066,
          'name': 'Jorge Batista'
        },
        {
          'id': 1113938605,
          'name': 'Karel Lebeda'
        },
        {
          'id': 1187248790,
          'name': 'Kristoffer Öfjäll'
        },
        {
          'id': 1162647698,
          'name': 'Kwang Moo Yi'
        },
        {
          'id': 1154777690,
          'name': 'Lei Qin'
        },
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1011506590,
          'name': 'Mario Edoardo Maresca'
        },
        {
          'id': 1425828879,
          'name': 'Martin Danelljan'
        },
        {
          'id': 1075568432,
          'name': 'Michael Felsberg'
        },
        {
          'id': 1080947747,
          'name': 'Ming-Ming Cheng'
        },
        {
          'id': 1255973019,
          'name': 'Philip H. S. Torr'
        },
        {
          'id': 1072900154,
          'name': 'Qingming Huang'
        },
        {
          'id': 1250623814,
          'name': 'Richard Bowden'
        },
        {
          'id': 1160448965,
          'name': 'Sam Hare'
        },
        {
          'id': 1197623463,
          'name': 'Samantha Yueying Lim'
        },
        {
          'id': 1338007892,
          'name': 'Seunghoon Hong'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1338477300,
          'name': 'Simon Hadfield'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        },
        {
          'id': 1059149830,
          'name': 'Stefan Duffner'
        },
        {
          'id': 1358016841,
          'name': 'Stuart Golodetz'
        },
        {
          'id': 1290641162,
          'name': 'Thomas Mauthner'
        },
        {
          'id': 1059016399,
          'name': 'Vibhav Vineet'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1372846994,
          'name': 'Yang Li'
        },
        {
          'id': 1188685537,
          'name': 'Yuankai Qi'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1402558821,
          'name': 'Zhi Heng Niu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 233,
      'reference_count': 68
    },
    {
      'id': 109133558,
      'title': 'Joint Cascade Face Detection and Alignment',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1038906976,
          'name': 'Shaoqing Ren'
        },
        {
          'id': 1339626607,
          'name': 'Yichen Wei'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 226,
      'reference_count': 34
    },
    {
      'id': 119197095,
      'title': 'Quantifying Micro-expressions with Constraint Local Model and Local Binary Pattern',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103743992,
          'abbr': 'WZU',
          'name': 'Wenzhou University'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1161570011,
          'name': 'Wen-Jing Yan'
        },
        {
          'id': 1161279822,
          'name': 'Su-Jing Wang'
        },
        {
          'id': 1253314764,
          'name': 'Yu-Hsin Chen'
        },
        {
          'id': 1259121145,
          'name': 'Guoying Zhao'
        },
        {
          'id': 1223946664,
          'name': 'Xiaolan Fu'
        }
      ],
      'field': [
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 31
    },
    {
      'id': 127841615,
      'title': 'Modeling Video Dynamics with Deep Dynencoder',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1358230042,
          'name': 'Xing Yan'
        },
        {
          'id': 1467937951,
          'name': 'Hong Chang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 30
    },
    {
      'id': 13851597,
      'title': 'Efficient Sparsity Estimation via Marginal-Lasso Coding',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1022061247,
          'name': 'Tzu-Yi Hung'
        },
        {
          'id': 1378338880,
          'name': 'Jiwen Lu'
        },
        {
          'id': 1432540011,
          'name': 'Yap-Peng Tan'
        },
        {
          'id': 1095966646,
          'name': 'Shenghua Gao'
        }
      ],
      'field': [
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2044145401,
          'name': 'Neural coding'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2048065051,
          'name': 'Lasso (statistics)'
        },
        {
          'id': 2003744952,
          'name': 'Regularization (mathematics)'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 0,
      'reference_count': 31
    },
    {
      'id': 14532067,
      'title': 'All-In-Focus Synthetic Aperture Imaging',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        },
        {
          'id': 2102274338,
          'abbr': 'UCL',
          'name': 'University College London'
        }
      ],
      'author': [
        {
          'id': 1199302739,
          'name': 'Tao Yang'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        },
        {
          'id': 1338784775,
          'name': 'Jing Li'
        },
        {
          'id': 1290502311,
          'name': 'Wenguang Ma'
        },
        {
          'id': 1359855042,
          'name': 'Xiaomin Tong'
        },
        {
          'id': 1436529281,
          'name': 'Rui Yu'
        },
        {
          'id': 1319145491,
          'name': 'Lingyan Ran'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2034227702,
          'name': 'Light field'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008327062,
          'name': 'Correctness'
        },
        {
          'id': 2015063766,
          'name': 'Synthetic aperture radar'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 20
    },
    {
      'id': 163075590,
      'title': 'Efficient Color Constancy with Local Surface Reflectance Statistics',
      'affiliation': [
        {
          'id': 2104910093,
          'abbr': 'UESTC',
          'name': 'University of Electronic Science and Technology of China'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1080914620,
          'name': 'Shaobing Gao'
        },
        {
          'id': 1200947071,
          'name': 'Wangwang Han'
        },
        {
          'id': 1342451619,
          'name': 'Kaifu Yang'
        },
        {
          'id': 1112626262,
          'name': 'Chao-Yi Li'
        },
        {
          'id': 1290462026,
          'name': 'Yongjie Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2001942329,
          'name': 'Reflectivity'
        },
        {
          'id': 2030056119,
          'name': 'Standard illuminant'
        },
        {
          'id': 2042109458,
          'name': 'Free parameter'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034573784,
          'name': 'Image formation'
        },
        {
          'id': 2010264609,
          'name': 'Color constancy'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2036114611,
          'name': 'Achromatic lens'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 36
    },
    {
      'id': 163399470,
      'title': 'As-Rigid-As-Possible Stereo under Second Order Smoothness Priors',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1318990977,
          'name': 'Chi Zhang'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1292328104,
          'name': 'Rui Cai'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        },
        {
          'id': 1454002885,
          'name': 'Yong Rui'
        }
      ],
      'field': [
        {
          'id': 2039980544,
          'name': 'Mixture model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2024487057,
          'name': 'Order of magnitude'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2041758568,
          'name': 'Laplace operator'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 29
    },
    {
      'id': 164115979,
      'title': 'A Non-local Method for Robust Noisy Image Completion',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1080068037,
          'name': 'Wei Li'
        },
        {
          'id': 1372325689,
          'name': 'Lei Zhao'
        },
        {
          'id': 1295080973,
          'name': 'Duanqing Xu'
        },
        {
          'id': 1070966279,
          'name': 'Dongming Lu'
        }
      ],
      'field': [
        {
          'id': 2004564439,
          'name': 'Matrix completion'
        },
        {
          'id': 2021724463,
          'name': 'Missing data'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 32
    },
    {
      'id': 168820928,
      'title': 'Weakly Supervised Object Localization with Latent Category Learning',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1353883990,
          'name': 'Chong Wang'
        },
        {
          'id': 1331261075,
          'name': 'Weiqiang Ren'
        },
        {
          'id': 1205914897,
          'name': 'Kaiqi Huang'
        },
        {
          'id': 1320177331,
          'name': 'Tieniu Tan'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011320204,
          'name': 'Latent semantic analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2015562028,
          'name': 'Unsupervised learning'
        },
        {
          'id': 2041368339,
          'name': 'Concept learning'
        },
        {
          'id': 2030753631,
          'name': 'Probabilistic latent semantic analysis'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 150,
      'reference_count': 37
    },
    {
      'id': 169459380,
      'title': 'Human Detection Using Learned Composition Alphabet and Pose Dictionary',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2103817757,
          'abbr': 'TEMPLE',
          'name': 'Temple University'
        }
      ],
      'author': [
        {
          'id': 1042193939,
          'name': 'Cong Yao'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1358872243,
          'name': 'Longin Jan Latecki'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017299362,
          'name': 'Data model'
        },
        {
          'id': 2045085977,
          'name': 'Grapheme'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        },
        {
          'id': 2017763283,
          'name': 'Analogy'
        },
        {
          'id': 2009254097,
          'name': 'Alphabet'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 49
    },
    {
      'id': 178992547,
      'title': 'Efficient Online Spatio-Temporal Filtering for Video Event Detection',
      'affiliation': [
        {
          'id': 2100509799,
          'abbr': 'UM',
          'name': 'University of Michigan'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1223004123,
          'name': 'Xinchen Yan'
        },
        {
          'id': 1262920571,
          'name': 'Junsong Yuan'
        },
        {
          'id': 1030989975,
          'name': 'Hui Liang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2047673250,
          'name': 'Computational complexity theory'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2021886685,
          'name': 'Filter (signal processing)'
        },
        {
          'id': 2034589600,
          'name': 'Batch processing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2010616972,
          'name': 'False alarm'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 1,
      'reference_count': 19
    },
    {
      'id': 186173104,
      'title': 'Activity Group Localization by Modeling the Relations among Participants',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1364834329,
          'name': 'Lei Sun'
        },
        {
          'id': 1128486384,
          'name': 'Haizhou Ai'
        },
        {
          'id': 1154828074,
          'name': 'Shihong Lao'
        }
      ],
      'field': [
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2012493528,
          'name': 'Hierarchical database model'
        },
        {
          'id': 2022938311,
          'name': 'Complete graph'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 26
    },
    {
      'id': 192200015,
      'title': 'Finding Coherent Motions and Semantic Regions in Crowd Scenes: A Diffusion and Clustering Approach',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1265241627,
          'name': 'Weiyue Wang'
        },
        {
          'id': 1182756938,
          'name': 'Weiyao Lin'
        },
        {
          'id': 1259207021,
          'name': 'Yuanzhe Chen'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        },
        {
          'id': 1146560862,
          'name': 'Jingdong Wang'
        },
        {
          'id': 1036887077,
          'name': 'Bin Sheng'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2026302417,
          'name': 'Thermal energy'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2001725001,
          'name': 'Optical flow'
        },
        {
          'id': 2003059411,
          'name': 'Motion vector'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 30
    },
    {
      'id': 196836561,
      'title': 'Affine Subspace Representation for Feature Description',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1462635307,
          'name': 'Zhenhua Wang'
        },
        {
          'id': 1369546191,
          'name': 'Bin Fan'
        },
        {
          'id': 1163323626,
          'name': 'Fuchao Wu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2009231198,
          'name': 'Affine hull'
        },
        {
          'id': 2029969645,
          'name': 'Affine transformation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2026938429,
          'name': 'Affine shape adaptation'
        },
        {
          'id': 2011938359,
          'name': 'Affine combination'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2016777268,
          'name': 'Affine space'
        },
        {
          'id': 2018154717,
          'name': 'Harris affine region detector'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 25
    },
    {
      'id': 217525034,
      'title': 'Optimal essential matrix estimation via inlier-set maximization',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1467057144,
          'name': 'Jiaolong Yang'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        }
      ],
      'field': [
        {
          'id': 2045241704,
          'name': 'Branch and bound'
        },
        {
          'id': 2039252156,
          'name': 'Parametrization'
        },
        {
          'id': 2023541576,
          'name': 'Essential manifold'
        },
        {
          'id': 2002116501,
          'name': 'Cardinality'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2002757781,
          'name': 'Essential matrix'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 34
    },
    {
      'id': 219370596,
      'title': 'Description-Discrimination Collaborative Tracking',
      'affiliation': [
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        },
        {
          'id': 2103556481,
          'abbr': 'SIT',
          'name': 'Stevens Institute of Technology'
        },
        {
          'id': 2104724480,
          'abbr': 'Kyodai',
          'name': 'Kyoto University'
        }
      ],
      'author': [
        {
          'id': 1037882621,
          'name': 'Dapeng Chen'
        },
        {
          'id': 1012340849,
          'name': 'Zejian Yuan'
        },
        {
          'id': 1185670369,
          'name': 'Gang Hua'
        },
        {
          'id': 1448998456,
          'name': 'Yang Wu'
        },
        {
          'id': 1214207310,
          'name': 'Nanning Zheng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2044658123,
          'name': 'Object model'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 35
    },
    {
      'id': 23716186,
      'title': 'Stacked Deformable Composition Model with Shape Regression for Object Composition Localization',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1036026566,
          'name': 'Yang Yang'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2016134371,
          'name': 'Coordinate descent'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2017763283,
          'name': 'Analogy'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 51
    },
    {
      'id': 251537338,
      'title': 'Deblurring Face Images with Exemplars',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1468076007,
          'name': 'Jinshan Pan'
        },
        {
          'id': 1240803967,
          'name': 'Zhe Hu'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        },
        {
          'id': 1440247301,
          'name': 'Ming-Hsuan Yang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008086083,
          'name': 'Ringing artifacts'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2023611578,
          'name': 'Maximum a posteriori estimation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 32
    },
    {
      'id': 266262986,
      'title': 'Object Detection and Viewpoint Estimation with Auto-masking Neural Network',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2102862730,
          'abbr': 'HUAWEI',
          'name': 'Huawei'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1468919049,
          'name': 'Linjie Yang'
        },
        {
          'id': 1381503284,
          'name': 'Jianzhuang Liu'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2040731089,
          'name': 'Time delay neural network'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003673557,
          'name': 'Masking (art)'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045245616,
          'name': 'Artificial neural network'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2003425056,
          'name': 'Architecture'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 16,
      'reference_count': 32
    },
    {
      'id': 266436028,
      'title': 'Support Vector Guided Dictionary Learning',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        },
        {
          'id': 2100348776,
          'abbr': 'XIDIAN',
          'name': 'Xidian University'
        }
      ],
      'author': [
        {
          'id': 1236093116,
          'name': 'Sijia Cai'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1280977602,
          'name': 'Xiangchu Feng'
        },
        {
          'id': 1186649446,
          'name': 'Ping Wang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039252156,
          'name': 'Parametrization'
        },
        {
          'id': 2026731675,
          'name': 'Logistic regression'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2005649475,
          'name': 'Coding (social sciences)'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2005020849,
          'name': 'Square (algebra)'
        },
        {
          'id': 2000585400,
          'name': 'Special case'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 62,
      'reference_count': 44
    },
    {
      'id': 26976107,
      'title': 'Coarse-to-Fine Auto-Encoder Networks (CFAN) for Real-Time Face Alignment',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1391838793,
          'name': 'Jie Jennifer Zhang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2022749334,
          'name': 'Face perception'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 213,
      'reference_count': 38
    },
    {
      'id': 276791529,
      'title': 'Image Retrieval and Ranking via Consistently Reconstructing Multi-attribute Queries',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1111205108,
          'name': 'Hua Zhang'
        },
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1052945935,
          'name': 'Si Liu'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        }
      ],
      'field': [
        {
          'id': 2039299906,
          'name': 'Ranking (information retrieval)'
        },
        {
          'id': 2009047093,
          'name': 'Ranking SVM'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008071310,
          'name': 'Query expansion'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2023618696,
          'name': 'Web query classification'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2039688799,
          'name': 'Query optimization'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 28
    },
    {
      'id': 280322738,
      'title': 'Crowd Tracking with Dynamic Evolution of Group Structures',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1472316569,
          'name': 'Feng Zhu'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        },
        {
          'id': 1058545649,
          'name': 'Nenghai Yu'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2007989407,
          'name': 'Tree structure'
        },
        {
          'id': 2032937572,
          'name': 'Social force model'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 38
    },
    {
      'id': 315718992,
      'title': 'Graduated Consistency-Regularized Optimization for Multi-graph Matching',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        },
        {
          'id': 2100414475,
          'abbr': 'ECNU',
          'name': 'East China Normal University'
        }
      ],
      'author': [
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1059831137,
          'name': 'Yin Li'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1036975246,
          'name': 'Stephen M. Chu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2032413546,
          'name': '3-dimensional matching'
        },
        {
          'id': 2027339083,
          'name': 'Quadratic assignment problem'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2009050054,
          'name': 'Blossom algorithm'
        },
        {
          'id': 2005169804,
          'name': 'Optimal matching'
        },
        {
          'id': 2041894794,
          'name': 'Local consistency'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2036542848,
          'name': 'Permutation matrix'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 54
    },
    {
      'id': 321947993,
      'title': 'Unsupervised Video Adaptation for Parsing Human Motion',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2103831083,
          'abbr': 'UQ',
          'name': 'University of Queensland'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1250548774,
          'name': 'Haoquan Shen'
        },
        {
          'id': 1256961584,
          'name': 'Shoou-I Yu'
        },
        {
          'id': 1288527372,
          'name': 'Yi Yang'
        },
        {
          'id': 1155518401,
          'name': 'Deyu Meng'
        },
        {
          'id': 1274950981,
          'name': 'Alexander G. Hauptmann'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008016065,
          'name': 'STREAMS'
        },
        {
          'id': 2006310969,
          'name': 'The Internet'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 13,
      'reference_count': 34
    },
    {
      'id': 32403945,
      'title': 'Robust Scene Text Detection with Convolution Neural Network Induced MSER Trees',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1054920101,
          'name': 'Weilin Huang'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2029935910,
          'name': 'Sliding window protocol'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2015109844,
          'name': 'Maximally stable extremal regions'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 192,
      'reference_count': 37
    },
    {
      'id': 324585792,
      'title': 'Hybrid Image Deblurring by Fusing Edge and Power Spectrum Information',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104094761,
          'abbr': 'ADOBE',
          'name': 'Adobe Systems'
        }
      ],
      'author': [
        {
          'id': 1019303489,
          'name': 'Tao Yue'
        },
        {
          'id': 1022250926,
          'name': 'Sunghyun Cho'
        },
        {
          'id': 1295811729,
          'name': 'Jue Wang'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001859329,
          'name': 'Spectral density'
        },
        {
          'id': 2045758093,
          'name': 'Blind deconvolution'
        },
        {
          'id': 2045422121,
          'name': 'Hybrid image'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2024945996,
          'name': 'Kernel density estimation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 28
    },
    {
      'id': 328658384,
      'title': 'VCDB: A Large-Scale Database for Partial Copy Detection in Videos',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1079230029,
          'name': 'Yudong Jiang'
        },
        {
          'id': 1295552061,
          'name': 'Jiajun Wang'
        }
      ],
      'field': [
        {
          'id': 2035990448,
          'name': 'Database'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045463576,
          'name': 'Information retrieval'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2046794490,
          'name': 'Data mining'
        },
        {
          'id': 2004930370,
          'name': 'Video copy detection'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 24
    },
    {
      'id': 336216541,
      'title': 'Deep Network Cascade for Image Super-resolution',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104613919,
          'abbr': 'HQU',
          'name': 'Huaqiao University'
        }
      ],
      'author': [
        {
          'id': 1249663582,
          'name': 'Zhen Cui'
        },
        {
          'id': 1467937951,
          'name': 'Hong Chang'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1404363237,
          'name': 'Bineng Zhong'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2030799690,
          'name': 'Scale factor'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043529127,
          'name': 'Cascade'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2023541170,
          'name': 'Autoencoder'
        },
        {
          'id': 2048063115,
          'name': 'Layer by layer'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 122,
      'reference_count': 37
    },
    {
      'id': 33740937,
      'title': 'Integrating Context and Occlusion for Car Detection by Hierarchical And-Or Model',
      'affiliation': [
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1173230308,
          'name': 'Bo Li'
        },
        {
          'id': 1088002310,
          'name': 'Tianfu Wu'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2014816707,
          'name': 'Bounding overwatch'
        },
        {
          'id': 2048563069,
          'name': 'Parking lot'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2033482100,
          'name': 'Directed acyclic graph'
        },
        {
          'id': 2028619937,
          'name': 'Heuristic'
        },
        {
          'id': 2014873883,
          'name': 'CAD'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 54,
      'reference_count': 32
    },
    {
      'id': 341452101,
      'title': 'Shrinkage Expansion Adaptive Metric Learning',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2102163663,
          'abbr': 'HIT',
          'name': 'Harbin Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1077196757,
          'name': 'Qilong Wang'
        },
        {
          'id': 1026677355,
          'name': 'Wangmeng Zuo'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1164546280,
          'name': 'Peihua Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2040176042,
          'name': 'Shrinkage'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2021454603,
          'name': 'restrict'
        },
        {
          'id': 2034486721,
          'name': 'Upper and lower bounds'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 41
    },
    {
      'id': 347206177,
      'title': 'Robust Foreground Detection Using Smoothness and Arbitrariness Constraints',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2100872051,
          'abbr': 'TUC',
          'name': 'Tianjin University of Commerce'
        },
        {
          'id': 2101979065,
          'abbr': 'SHANGHAITECH',
          'name': 'ShanghaiTech University'
        }
      ],
      'author': [
        {
          'id': 1349860041,
          'name': 'Xiaojie Guo'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1208040936,
          'name': 'Liang Yang'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        },
        {
          'id': 1088618246,
          'name': 'Yi Ma'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025932237,
          'name': 'Foreground detection'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2046326353,
          'name': 'Arbitrariness'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2018992595,
          'name': 'Robust principal component analysis'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 31,
      'reference_count': 31
    },
    {
      'id': 351936258,
      'title': 'Which Looks Like Which: Exploring Inter-class Relationships in Fine-Grained Visual Categorization',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        }
      ],
      'author': [
        {
          'id': 1216232896,
          'name': 'Jian Pu'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1218002805,
          'name': 'Jun Wang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 47
    },
    {
      'id': 352564544,
      'title': 'Efficient k-Support Matrix Pursuit',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        },
        {
          'id': 2100699160,
          'abbr': 'SCNU',
          'name': 'South China Normal University'
        }
      ],
      'author': [
        {
          'id': 1249782652,
          'name': 'Hanjiang Lai'
        },
        {
          'id': 1130600180,
          'name': 'Yan Pan'
        },
        {
          'id': 1081691569,
          'name': 'Canyi Lu'
        },
        {
          'id': 1058067922,
          'name': 'Yong Tang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2020653018,
          'name': 'Matrix norm'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2004161278,
          'name': 'Binary search algorithm'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2047028141,
          'name': 'Norm (mathematics)'
        },
        {
          'id': 2021169092,
          'name': 'Basis pursuit'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 24
    },
    {
      'id': 355574182,
      'title': 'Accurate Intrinsic Calibration of Depth Camera with Cuboids',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1299205715,
          'name': 'Bingwen Jin'
        },
        {
          'id': 1319847680,
          'name': 'Hao Lei'
        },
        {
          'id': 1122845272,
          'name': 'Weidong Geng'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2017968033,
          'name': 'Camera auto-calibration'
        },
        {
          'id': 2047672818,
          'name': 'Instrumental and intrinsic value'
        },
        {
          'id': 2003501260,
          'name': 'Cuboid'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048325277,
          'name': 'Calibration'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 28
    },
    {
      'id': 377464008,
      'title': 'Person Re-identification by Video Ranking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        }
      ],
      'author': [
        {
          'id': 1435211294,
          'name': 'Taiqing Wang'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1274171319,
          'name': 'Xiatian Zhu'
        },
        {
          'id': 1060952188,
          'name': 'Shengjin Wang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2013094599,
          'name': 'Gait'
        },
        {
          'id': 2026778166,
          'name': 'Dynamic time warping'
        },
        {
          'id': 2017696983,
          'name': 'Visual appearance'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 155,
      'reference_count': 48
    },
    {
      'id': 379100867,
      'title': 'Spatial Pyramid Pooling in Deep Convolutional Networks for Visual Recognition',
      'affiliation': [
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1484636813,
          'name': 'Xiangyu Zhang'
        },
        {
          'id': 1038906976,
          'name': 'Shaoqing Ren'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2010624293,
          'name': 'Pyramid'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 1350,
      'reference_count': 29
    },
    {
      'id': 384259506,
      'title': 'A Novel Topic-Level Random Walk Framework for Scene Image Co-segmentation',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2100534300,
          'abbr': 'UM',
          'name': 'University of Malaya'
        }
      ],
      'author': [
        {
          'id': 1369437093,
          'name': 'Zehuan Yuan'
        },
        {
          'id': 1149898936,
          'name': 'Tong Lu'
        },
        {
          'id': 1333707850,
          'name': 'Palaiahnakote Shivakumara'
        }
      ],
      'field': [
        {
          'id': 2016016661,
          'name': 'LabelMe'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2004353831,
          'name': 'Random walk'
        },
        {
          'id': 2046760301,
          'name': 'Link analysis'
        },
        {
          'id': 2023356265,
          'name': 'Scalability'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2033899647,
          'name': 'Voting'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 29
    },
    {
      'id': 38794730,
      'title': 'Intrinsic Face Image Decomposition with Human Face Priors',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1032681086,
          'name': 'Chen Li'
        },
        {
          'id': 1266662130,
          'name': 'Kun Zhou'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013078986,
          'name': 'Specular reflection'
        },
        {
          'id': 2047672818,
          'name': 'Instrumental and intrinsic value'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2001942329,
          'name': 'Reflectivity'
        },
        {
          'id': 2048776180,
          'name': 'Image editing'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 39
    },
    {
      'id': 391387580,
      'title': 'A Scale Adaptive Kernel Correlation Filter Tracker with Feature Integration',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1372846994,
          'name': 'Yang Li'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        }
      ],
      'field': [
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2014570814,
          'name': 'Kernel adaptive filter'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2006902982,
          'name': 'Kanade–Lucas–Tomasi feature tracker'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 303,
      'reference_count': 35
    },
    {
      'id': 393762730,
      'title': 'Architectural Style Classification Using Multinomial Latent Logistic Regression',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2103774576,
          'abbr': 'MUST',
          'name': 'Macau University of Science and Technology'
        }
      ],
      'author': [
        {
          'id': 1108691835,
          'name': 'Zhe Xu'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1435132672,
          'name': 'Junjie Wu'
        },
        {
          'id': 1140773423,
          'name': 'Ah Chung Tsoi'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030569583,
          'name': 'Architectural style'
        },
        {
          'id': 2026731675,
          'name': 'Logistic regression'
        },
        {
          'id': 2039733172,
          'name': 'Probabilistic analysis of algorithms'
        },
        {
          'id': 2025622715,
          'name': 'Probabilistic logic'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2020050314,
          'name': 'Territoriality'
        },
        {
          'id': 2006804888,
          'name': 'Multinomial distribution'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 19
    },
    {
      'id': 394235681,
      'title': 'Video Action Detection with Relational Dynamic-Poselets',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1242240210,
          'name': 'Limin Wang'
        },
        {
          'id': 1256598280,
          'name': 'Yu Qiao'
        },
        {
          'id': 1005956598,
          'name': 'Xiaoou Tang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2036202317,
          'name': 'Relational model'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2037248571,
          'name': 'Structured support vector machine'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2036497831,
          'name': 'Dynamic programming'
        },
        {
          'id': 2042466130,
          'name': 'Skeleton (computer programming)'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 100,
      'reference_count': 34
    },
    {
      'id': 395981789,
      'title': 'Optimizing Ranking Measures for Compact Binary Code Learning',
      'affiliation': [
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        },
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        }
      ],
      'author': [
        {
          'id': 1139106464,
          'name': 'Guosheng Lin'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1013319107,
          'name': 'Jianxin Wu'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038236017,
          'name': 'Column generation'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2040776147,
          'name': 'Generality'
        },
        {
          'id': 2034388435,
          'name': 'Laplacian matrix'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2014870206,
          'name': 'Image retrieval'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 27
    },
    {
      'id': 402434141,
      'title': 'Well Begun Is Half Done: Generating High-Quality Seeds for Automatic Image Dataset Construction from Web',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1104529640,
          'name': 'Yan Xia'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2011721487,
          'name': 'Thresholding'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 22
    },
    {
      'id': 411837571,
      'title': 'RGBD salient object detection: A benchmark and algorithms',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101465915,
          'abbr': 'XMU',
          'name': 'Xiamen University'
        }
      ],
      'author': [
        {
          'id': 1048739089,
          'name': 'Houwen Peng'
        },
        {
          'id': 1138746327,
          'name': 'Bing Li'
        },
        {
          'id': 1097717451,
          'name': 'Weihua Xiong'
        },
        {
          'id': 1306700701,
          'name': 'Weiming Hu'
        },
        {
          'id': 1166231301,
          'name': 'Rongrong Ji'
        }
      ],
      'field': [
        {
          'id': 2040290192,
          'name': 'Computational model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032810069,
          'name': 'Salience (neuroscience)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2034848742,
          'name': 'Machine vision'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 116,
      'reference_count': 43
    },
    {
      'id': 425412252,
      'title': 'Graph Cuts for Supervised Binary Coding',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1007108791,
          'name': 'Tiezheng Ge'
        },
        {
          'id': 1415386142,
          'name': 'Kaiming He'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2038348321,
          'name': 'Hash function'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2045595295,
          'name': 'Binary code'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2005133827,
          'name': 'Discrete optimization'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2045426877,
          'name': 'Cut'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 42,
      'reference_count': 38
    },
    {
      'id': 458460043,
      'title': 'Nonrigid Surface Registration and Completion from RGBD Images',
      'affiliation': [
        {
          'id': 2104258153,
          'abbr': 'NICTA',
          'name': 'NICTA'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1235834455,
          'name': 'Weipeng Xu'
        },
        {
          'id': 1341663681,
          'name': 'Mathieu Salzmann'
        },
        {
          'id': 1129052117,
          'name': 'Yongtian Wang'
        },
        {
          'id': 1013915283,
          'name': 'Yue Liu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2021724463,
          'name': 'Missing data'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2044697708,
          'name': 'Graphical model'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 40
    },
    {
      'id': 471566594,
      'title': 'Salient Color Names for Person Re-identification',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100915375,
          'abbr': '',
          'name': 'University of California'
        }
      ],
      'author': [
        {
          'id': 1036026566,
          'name': 'Yang Yang'
        },
        {
          'id': 1068978706,
          'name': 'Jimei Yang'
        },
        {
          'id': 1227154729,
          'name': 'Junjie Yan'
        },
        {
          'id': 1058877621,
          'name': 'Shengcai Liao'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2026167168,
          'name': 'Color quantization'
        },
        {
          'id': 2020489969,
          'name': 'HSL and HSV'
        },
        {
          'id': 2011172054,
          'name': 'Color term'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2003825461,
          'name': 'Color normalization'
        },
        {
          'id': 2032929446,
          'name': 'Salient'
        },
        {
          'id': 2035799977,
          'name': 'Web colors'
        },
        {
          'id': 2047486484,
          'name': 'Color space'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 228,
      'reference_count': 34
    },
    {
      'id': 476021659,
      'title': 'Face Recognition by 3D Registration for the Visually Impaired Using a RGB-D Sensor',
      'affiliation': [
        {
          'id': 2100585202,
          'abbr': 'CCNY',
          'name': 'City College of New York'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104350366,
          'abbr': 'GC',
          'name': 'The Graduate Center, CUNY'
        }
      ],
      'author': [
        {
          'id': 1459886958,
          'name': 'Wei Li'
        },
        {
          'id': 1027296391,
          'name': 'Xudong Li'
        },
        {
          'id': 1385935478,
          'name': 'Martin Goldberg'
        },
        {
          'id': 1186325489,
          'name': 'Zhigang Zhu'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2021101066,
          'name': 'Image rectification'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2042846089,
          'name': 'RGB color model'
        },
        {
          'id': 2002762073,
          'name': 'Facial expression'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 18
    },
    {
      'id': 477523474,
      'title': 'Interestingness Prediction by Robust Learning to Rank',
      'affiliation': [
        {
          'id': 2101887875,
          'abbr': 'QMUL',
          'name': 'Queen Mary University of London'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        }
      ],
      'author': [
        {
          'id': 1462580441,
          'name': 'Yanwei Fu'
        },
        {
          'id': 1475872645,
          'name': 'Timothy M. Hospedales'
        },
        {
          'id': 1103116865,
          'name': 'Tao Xiang'
        },
        {
          'id': 1461255479,
          'name': 'Shaogang Gong'
        },
        {
          'id': 1063017494,
          'name': 'Yuan Yao'
        }
      ],
      'field': [
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2049966883,
          'name': 'Anomaly detection'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045549416,
          'name': 'Outlier'
        },
        {
          'id': 2041643691,
          'name': 'Learning to rank'
        },
        {
          'id': 2002903134,
          'name': 'Crowdsourcing'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 43,
      'reference_count': 44
    },
    {
      'id': 81537304,
      'title': 'Deep Learning of Scene-Specific Classifier for Pedestrian Detection',
      'affiliation': [
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1382464502,
          'name': 'Xingyu Zeng'
        },
        {
          'id': 1493057738,
          'name': 'Wanli Ouyang'
        },
        {
          'id': 1118348550,
          'name': 'Meng Wang'
        },
        {
          'id': 1324598433,
          'name': 'Xiaogang Wang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2048782684,
          'name': 'Deep learning'
        },
        {
          'id': 2024449552,
          'name': 'Convolutional neural network'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2031609914,
          'name': 'Detector'
        },
        {
          'id': 2004986237,
          'name': 'Transfer of learning'
        },
        {
          'id': 2027215556,
          'name': 'Marginal distribution'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2028109644,
          'name': 'Pedestrian detection'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 56,
      'reference_count': 38
    },
    {
      'id': 85310441,
      'title': 'Activity Recognition in Still Images with Transductive Non-negative Matrix Factorization',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2101711270,
          'abbr': 'UTS',
          'name': 'University of Technology, Sydney'
        }
      ],
      'author': [
        {
          'id': 1294847016,
          'name': 'Naiyang Guan'
        },
        {
          'id': 1058164088,
          'name': 'Dacheng Tao'
        },
        {
          'id': 1269608949,
          'name': 'Long Lan'
        },
        {
          'id': 1247490740,
          'name': 'Zhigang Luo'
        },
        {
          'id': 1201899971,
          'name': 'Xuejun Yang'
        }
      ],
      'field': [
        {
          'id': 2014952153,
          'name': 'Transduction (machine learning)'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2039661791,
          'name': 'Multiplicative function'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013729031,
          'name': 'Standard test image'
        },
        {
          'id': 2008210462,
          'name': 'Concatenation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2046713073,
          'name': 'Non-negative matrix factorization'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 19
    },
    {
      'id': 94337884,
      'title': 'Self-Explanatory Sparse Representation for Image Classification',
      'affiliation': [
        {
          'id': 2103391570,
          'abbr': 'CUP',
          'name': 'China University of Petroleum'
        },
        {
          'id': 2101453748,
          'abbr': 'CMU',
          'name': 'Carnegie Mellon University'
        },
        {
          'id': 2100171207,
          'abbr': 'PURDUE',
          'name': 'Purdue University'
        },
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1043445037,
          'name': 'Bao-Di Liu'
        },
        {
          'id': 1392518903,
          'name': 'Yu-Xiong Wang'
        },
        {
          'id': 1358472306,
          'name': 'Bin Shen'
        },
        {
          'id': 1289921224,
          'name': 'Yu-Jin Zhang'
        },
        {
          'id': 1212502319,
          'name': 'Martial Hebert'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2009945168,
          'name': 'Hilbert space'
        },
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2024991133,
          'name': 'K-SVD'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2004101032,
          'name': 'Euclidean space'
        },
        {
          'id': 2046405230,
          'name': 'Reproducing kernel Hilbert space'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 32,
      'reference_count': 43
    },
    {
      'id': 94351044,
      'title': 'Micro-Expression Recognition Using Robust Principal Component Analysis and Local Spatiotemporal Directional Features',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104206252,
          'abbr': '吉大',
          'name': 'Jilin University'
        },
        {
          'id': 2103743992,
          'abbr': 'WZU',
          'name': 'Wenzhou University'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1161279822,
          'name': 'Su-Jing Wang'
        },
        {
          'id': 1161570011,
          'name': 'Wen-Jing Yan'
        },
        {
          'id': 1259121145,
          'name': 'Guoying Zhao'
        },
        {
          'id': 1223946664,
          'name': 'Xiaolan Fu'
        },
        {
          'id': 1345206875,
          'name': 'Chunguang Zhou'
        }
      ],
      'field': [
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2012955293,
          'name': 'Facial Action Coding System'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048476053,
          'name': 'Pulse (signal processing)'
        },
        {
          'id': 2018992595,
          'name': 'Robust principal component analysis'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2134166456',
        'name': 'ECCV',
        'year': 2014
      },
      'source_url': '',
      'citation_count': 33,
      'reference_count': 24
    },
    {
      'id': 12970117,
      'title': 'Performance capture of interacting characters with handheld kinects',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2102440343,
          'abbr': 'MPG',
          'name': 'Max Planck Society'
        }
      ],
      'author': [
        {
          'id': 1147022676,
          'name': 'Genzhi Ye'
        },
        {
          'id': 1294946560,
          'name': 'Yebin Liu'
        },
        {
          'id': 1158147747,
          'name': 'Nils Hasler'
        },
        {
          'id': 1145500222,
          'name': 'Xiangyang Ji'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1021239134,
          'name': 'Christian Theobalt'
        }
      ],
      'field': [
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2014814070,
          'name': 'Pose'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2004061258,
          'name': 'Motion capture'
        },
        {
          'id': 2036083682,
          'name': 'Point cloud'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 65,
      'reference_count': 34
    },
    {
      'id': 146859078,
      'title': 'Supervised geodesic propagation for semantic label transfer',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1142003552,
          'name': 'Qing Li'
        },
        {
          'id': 1403803510,
          'name': 'Yafei Song'
        },
        {
          'id': 1493538103,
          'name': 'Xin Jin'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2018186560,
          'name': 'Distance transform'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 14,
      'reference_count': 30
    },
    {
      'id': 148085435,
      'title': 'Beyond spatial pyramids: a new feature extraction framework with dense spatial sampling for image classification',
      'affiliation': [
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        },
        {
          'id': 2101456381,
          'abbr': '',
          'name': 'Microsoft Research Asia (China)'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1113359600,
          'name': 'Shengye Yan'
        },
        {
          'id': 1137403710,
          'name': 'Xinxing Xu'
        },
        {
          'id': 1057254950,
          'name': 'Dong Xu'
        },
        {
          'id': 1137314360,
          'name': 'Stephen Lin'
        },
        {
          'id': 1245723952,
          'name': 'Xuelong Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031562899,
          'name': 'Pyramid (image processing)'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2000945043,
          'name': 'Multiple kernel learning'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2005047949,
          'name': 'Scale-invariant feature transform'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 46
    },
    {
      'id': 149781850,
      'title': 'Multi-scale patch based collaborative representation for face recognition with margin distribution optimization',
      'affiliation': [
        {
          'id': 2103116222,
          'abbr': 'HKPU',
          'name': 'Hong Kong Polytechnic University'
        },
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1167737319,
          'name': 'Pengfei Zhu'
        },
        {
          'id': 1368776169,
          'name': 'Lei Zhang'
        },
        {
          'id': 1180699841,
          'name': 'Qinghua Hu'
        },
        {
          'id': 1014545635,
          'name': 'Simon C. K. Shiu'
        }
      ],
      'field': [
        {
          'id': 2027184823,
          'name': 'Sample size determination'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015539801,
          'name': 'Ensemble learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 127,
      'reference_count': 42
    },
    {
      'id': 157898523,
      'title': 'A theoretical analysis of camera response functions in image deblurring',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104837592,
          'abbr': 'UD',
          'name': 'University of Delaware'
        }
      ],
      'author': [
        {
          'id': 1060362439,
          'name': 'Xiaogang Chen'
        },
        {
          'id': 1321476008,
          'name': 'Feng Li'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        },
        {
          'id': 1468164369,
          'name': 'Jingyi Yu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2047979949,
          'name': 'Deblurring'
        },
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008086083,
          'name': 'Ringing artifacts'
        },
        {
          'id': 2030284605,
          'name': 'Deconvolution'
        },
        {
          'id': 2000443954,
          'name': 'Motion blur'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2031740461,
          'name': 'Convolution'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 28
    },
    {
      'id': 168329281,
      'title': 'Separability oriented preprocessing for illumination-insensitive face recognition',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1217280926,
          'name': 'Hu Han'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        },
        {
          'id': 1154828074,
          'name': 'Shihong Lao'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2018399408,
          'name': 'Preprocessor'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 32
    },
    {
      'id': 174904322,
      'title': 'Automatic tracking of a large number of moving targets in 3d',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1037113684,
          'name': 'Ye Liu'
        },
        {
          'id': 1079493566,
          'name': 'Hui Li'
        },
        {
          'id': 1350594211,
          'name': 'Yan Qiu Chen'
        }
      ],
      'field': [
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2021582169,
          'name': 'Initialization'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026778197,
          'name': 'Tracking system'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2014809128,
          'name': 'Remainder'
        },
        {
          'id': 2042421035,
          'name': 'Particle filter'
        },
        {
          'id': 2019892823,
          'name': 'Image resolution'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 20,
      'reference_count': 22
    },
    {
      'id': 183323560,
      'title': 'Action recognition using subtensor constraint',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1343789332,
          'name': 'Qiguang Liu'
        },
        {
          'id': 1048401631,
          'name': 'Xiaochun Cao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026778166,
          'name': 'Dynamic time warping'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2004421153,
          'name': 'Fundamental matrix (computer vision)'
        },
        {
          'id': 2013539988,
          'name': 'Trifocal tensor'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 4,
      'reference_count': 34
    },
    {
      'id': 198938450,
      'title': 'Learning human interaction by interactive phrases',
      'affiliation': [
        {
          'id': 2100548198,
          'abbr': 'SUNY',
          'name': 'State University of New York System'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102508462,
          'abbr': 'NU',
          'name': 'Northeastern University'
        }
      ],
      'author': [
        {
          'id': 1486082966,
          'name': 'Yu Kong'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        },
        {
          'id': 1239922094,
          'name': 'Yun Fu'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037303125,
          'name': 'Ambiguity'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2012493528,
          'name': 'Hierarchical database model'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 72,
      'reference_count': 25
    },
    {
      'id': 203861935,
      'title': 'Joint Face Alignment: Rescue Bad Alignments with Good Ones by Regularized Re-fitting',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1415357977,
          'name': 'Xiaowei Zhao'
        },
        {
          'id': 1067315396,
          'name': 'Xiujuan Chai'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006137252,
          'name': 'Active shape model'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 6,
      'reference_count': 33
    },
    {
      'id': 207965362,
      'title': 'Auto-grouped sparse representation for visual analysis',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101105301,
          'abbr': 'RU',
          'name': 'Rutgers University'
        },
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1080923802,
          'name': 'Jiashi Feng'
        },
        {
          'id': 1481146811,
          'name': 'Xiaotong Yuan'
        },
        {
          'id': 1142288040,
          'name': 'Zilei Wang'
        },
        {
          'id': 1268898520,
          'name': 'Huan Xu'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2026308272,
          'name': 'Feature vector'
        },
        {
          'id': 2007166572,
          'name': 'Contextual image classification'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 32
    },
    {
      'id': 20855398,
      'title': 'Multi-view discriminant analysis',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1466871651,
          'name': 'Meina Kan'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1178371504,
          'name': 'Haihong Zhang'
        },
        {
          'id': 1154828074,
          'name': 'Shihong Lao'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2007924723,
          'name': 'Rayleigh quotient'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2048015713,
          'name': 'Eigendecomposition of a matrix'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2007410355,
          'name': 'Discriminant'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 97,
      'reference_count': 23
    },
    {
      'id': 211087799,
      'title': 'Group tracking: exploring mutual relations for multiple object tracking',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1103310797,
          'name': 'Genquan Duan'
        },
        {
          'id': 1128486384,
          'name': 'Haizhou Ai'
        },
        {
          'id': 1259923520,
          'name': 'Song Cao'
        },
        {
          'id': 1154828074,
          'name': 'Shihong Lao'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2045712947,
          'name': 'Online algorithm'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 17,
      'reference_count': 23
    },
    {
      'id': 257537702,
      'title': 'Frequency analysis of transient light transport with applications in bare sensor imaging',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2104904426,
          'abbr': 'MIT',
          'name': 'Massachusetts Institute of Technology'
        },
        {
          'id': 2101712668,
          'abbr': 'HARVARD',
          'name': 'Harvard University'
        },
        {
          'id': 2100321929,
          'abbr': 'UTORONTO',
          'name': 'University of Toronto'
        }
      ],
      'author': [
        {
          'id': 1472284003,
          'name': 'Di Wu'
        },
        {
          'id': 1017674813,
          'name': 'Gordon Wetzstein'
        },
        {
          'id': 1335239912,
          'name': 'Christopher Barsi'
        },
        {
          'id': 1436577195,
          'name': 'Thomas Willwacher'
        },
        {
          'id': 1371420187,
          'name': 'Matthew O’Toole'
        },
        {
          'id': 1447816233,
          'name': 'Nikhil Naik'
        },
        {
          'id': 1358312897,
          'name': 'Qionghai Dai'
        },
        {
          'id': 1480451490,
          'name': 'Kyros Kutulakos'
        },
        {
          'id': 1126743907,
          'name': 'Ramesh Raskar'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032635142,
          'name': 'Fourier analysis'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2016120260,
          'name': 'Information transfer'
        },
        {
          'id': 2001998762,
          'name': 'Optimal design'
        },
        {
          'id': 2011079278,
          'name': 'Frequency analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2038108916,
          'name': 'Expression (mathematics)'
        },
        {
          'id': 2035117423,
          'name': 'Information capture'
        },
        {
          'id': 2019668106,
          'name': 'Electronic engineering'
        },
        {
          'id': 2039512801,
          'name': 'Frequency domain'
        },
        {
          'id': 2043765452,
          'name': 'Time of flight'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 21,
      'reference_count': 24
    },
    {
      'id': 261312180,
      'title': 'On the convergence of graph matching: graduated assignment revisited',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        },
        {
          'id': 2102344316,
          'abbr': 'GT',
          'name': 'Georgia Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1110689233,
          'name': 'Yu Tian'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1287713904,
          'name': 'Hequan Zhang'
        },
        {
          'id': 1444257548,
          'name': 'Ya Zhang'
        },
        {
          'id': 1048203945,
          'name': 'Xiaokang Yang'
        },
        {
          'id': 1177963971,
          'name': 'Hongyuan Zha'
        }
      ],
      'field': [
        {
          'id': 2013839451,
          'name': 'Quadratic programming'
        },
        {
          'id': 2017543523,
          'name': 'Fixed point'
        },
        {
          'id': 2007118954,
          'name': 'Matching (graph theory)'
        },
        {
          'id': 2027339083,
          'name': 'Quadratic assignment problem'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2049662528,
          'name': 'Hungarian algorithm'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2014784539,
          'name': 'Integer'
        },
        {
          'id': 2046485487,
          'name': 'Solver'
        },
        {
          'id': 2011548508,
          'name': 'Unary operation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 13
    },
    {
      'id': 268881013,
      'title': 'Free hand-drawn sketch segmentation',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2101456381,
          'abbr': '',
          'name': 'Microsoft Research Asia (China)'
        }
      ],
      'author': [
        {
          'id': 1316955440,
          'name': 'Zhenbang Sun'
        },
        {
          'id': 1427171163,
          'name': 'Changhu Wang'
        },
        {
          'id': 1195526895,
          'name': 'Liqing Zhang'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041216077,
          'name': 'Perception'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2025995203,
          'name': 'Perceptual system'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2016781885,
          'name': 'Sketch'
        },
        {
          'id': 2007773467,
          'name': 'Sketch recognition'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2013528349,
          'name': 'Sketch-based modeling'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 20
    },
    {
      'id': 282546051,
      'title': 'Depth recovery using an adaptive color-guided auto-regressive model',
      'affiliation': [
        {
          'id': 2102416410,
          'abbr': 'TJU',
          'name': 'Tianjin University'
        }
      ],
      'author': [
        {
          'id': 1185676299,
          'name': 'Jingyu Yang'
        },
        {
          'id': 1491386403,
          'name': 'Xinchen Ye'
        },
        {
          'id': 1014581051,
          'name': 'Kun Li'
        },
        {
          'id': 1039900156,
          'name': 'Chunping Hou'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2031325749,
          'name': 'Color image'
        },
        {
          'id': 2041192252,
          'name': 'Depth map'
        },
        {
          'id': 2001599554,
          'name': 'Autoregressive model'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 38,
      'reference_count': 24
    },
    {
      'id': 368016156,
      'title': 'Two-view underwater structure and motion for cameras under flat refractive interfaces',
      'affiliation': [
        {
          'id': 2103191953,
          'abbr': 'NUDT',
          'name': 'National University of Defense Technology'
        },
        {
          'id': 2101307619,
          'abbr': 'UALBERTA',
          'name': 'University of Alberta'
        }
      ],
      'author': [
        {
          'id': 1330152426,
          'name': 'Lai Kang'
        },
        {
          'id': 1012222320,
          'name': 'Lingda Wu'
        },
        {
          'id': 1201664960,
          'name': 'Yee-Hong Yang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2048103201,
          'name': 'Refraction'
        },
        {
          'id': 2021349405,
          'name': 'Distortion (optics)'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025183204,
          'name': 'Real image'
        },
        {
          'id': 2029862013,
          'name': 'Underwater'
        },
        {
          'id': 2049542856,
          'name': 'Ellipse'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 26
    },
    {
      'id': 371610584,
      'title': 'A dictionary learning approach for classification: separating the particularity and the commonality',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1023764309,
          'name': 'Shu Kong'
        },
        {
          'id': 1286482841,
          'name': 'Donghui Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2049056451,
          'name': 'Numerical digit'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 145,
      'reference_count': 26
    },
    {
      'id': 395797369,
      'title': 'Online spatio-temporal structural context learning for visual tracking',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1279812779,
          'name': 'Longyin Wen'
        },
        {
          'id': 1257917058,
          'name': 'Zhaowei Cai'
        },
        {
          'id': 1389185795,
          'name': 'Zhen Lei'
        },
        {
          'id': 1229347807,
          'name': 'Dong Yi'
        },
        {
          'id': 1133674438,
          'name': 'Stan Z. Li'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2040473395,
          'name': 'BitTorrent tracker'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2001611344,
          'name': 'Temporal context'
        },
        {
          'id': 2043267583,
          'name': 'Spatial contextual awareness'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 43,
      'reference_count': 30
    },
    {
      'id': 417411438,
      'title': 'Robust and efficient subspace segmentation via least squares regression',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2101544856,
          'abbr': 'HFUT',
          'name': 'Hefei University of Technology'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        },
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        }
      ],
      'author': [
        {
          'id': 1081691569,
          'name': 'Canyi Lu'
        },
        {
          'id': 1176643012,
          'name': 'Hai Min'
        },
        {
          'id': 1059900529,
          'name': 'Zhong-Qiu Zhao'
        },
        {
          'id': 1031587327,
          'name': 'Lin Zhu'
        },
        {
          'id': 1393186695,
          'name': 'De-Shuang Huang'
        },
        {
          'id': 1444424930,
          'name': 'Shuicheng Yan'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2035166965,
          'name': 'Linear subspace'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2041676571,
          'name': 'Least squares'
        },
        {
          'id': 2020378691,
          'name': 'Sparse approximation'
        },
        {
          'id': 2000700521,
          'name': 'Block matrix'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2018504468,
          'name': 'Sampling (statistics)'
        },
        {
          'id': 2010148100,
          'name': 'Matrix (mathematics)'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 199,
      'reference_count': 28
    },
    {
      'id': 427892352,
      'title': 'Pairwise rotation invariant co-occurrence local binary pattern',
      'affiliation': [
        {
          'id': 2101621751,
          'abbr': 'BUPT',
          'name': 'Beijing University of Posts and Telecommunications'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1354020470,
          'name': 'Xianbiao Qi'
        },
        {
          'id': 1114339058,
          'name': 'Rong Xiao'
        },
        {
          'id': 1372368328,
          'name': 'Jun Guo'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024083836,
          'name': 'Local binary patterns'
        },
        {
          'id': 2045050007,
          'name': 'Co-occurrence'
        },
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2034891279,
          'name': 'Curvature'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 34,
      'reference_count': 24
    },
    {
      'id': 430560333,
      'title': 'Labeling images by integrating sparse multiple distance learning and semantic context modeling',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2100874370,
          'abbr': 'TONGJI',
          'name': 'Tongji University'
        }
      ],
      'author': [
        {
          'id': 1183708042,
          'name': 'Chuanjun Ji'
        },
        {
          'id': 1495758995,
          'name': 'Xiangdong Zhou'
        },
        {
          'id': 1116286950,
          'name': 'Lan Lin'
        },
        {
          'id': 1016034053,
          'name': 'Weidong Yang'
        }
      ],
      'field': [
        {
          'id': 2032100694,
          'name': 'Automatic image annotation'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2026731675,
          'name': 'Logistic regression'
        },
        {
          'id': 2023719590,
          'name': 'Conditional random field'
        },
        {
          'id': 2034082534,
          'name': 'Unified Model'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2014112726,
          'name': 'Data set'
        },
        {
          'id': 2009787767,
          'name': 'Context model'
        },
        {
          'id': 2039151869,
          'name': 'Annotation'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 24
    },
    {
      'id': 431239828,
      'title': 'Image guided tone mapping with locally nonlinear model',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1095838096,
          'name': 'Huxiang Gu'
        },
        {
          'id': 1000170669,
          'name': 'Ying Wang'
        },
        {
          'id': 1056053175,
          'name': 'Shiming Xiang'
        },
        {
          'id': 1296467528,
          'name': 'Gaofeng Meng'
        },
        {
          'id': 1114885725,
          'name': 'Chunhong Pan'
        }
      ],
      'field': [
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2007279912,
          'name': 'High dynamic range'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2041313107,
          'name': 'Tone mapping'
        },
        {
          'id': 2038357850,
          'name': 'Optimization problem'
        },
        {
          'id': 2037635684,
          'name': 'Scaling'
        },
        {
          'id': 2011226169,
          'name': 'Gamma correction'
        },
        {
          'id': 2017778768,
          'name': 'Image compression'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 2,
      'reference_count': 25
    },
    {
      'id': 443527913,
      'title': 'Bayesian face revisited: a joint formulation',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1010330436,
          'name': 'Dong Chen'
        },
        {
          'id': 1350732524,
          'name': 'Xudong Cao'
        },
        {
          'id': 1415038293,
          'name': 'Liwei Wang'
        },
        {
          'id': 1325049578,
          'name': 'Fang Wen'
        },
        {
          'id': 1149625111,
          'name': 'Jian Sun'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2046152500,
          'name': 'Computation'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2034769815,
          'name': 'Mahalanobis distance'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        },
        {
          'id': 2036679242,
          'name': 'Word error rate'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 291,
      'reference_count': 29
    },
    {
      'id': 454016330,
      'title': 'View-Invariant action recognition using latent kernelized structural SVM',
      'affiliation': [
        {
          'id': 2104846471,
          'abbr': 'BIT',
          'name': 'Beijing Institute of Technology'
        }
      ],
      'author': [
        {
          'id': 1077354491,
          'name': 'Xinxiao Wu'
        },
        {
          'id': 1259702947,
          'name': 'Yunde Jia'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006127887,
          'name': 'Kernel (linear algebra)'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2045716810,
          'name': 'Correlation'
        },
        {
          'id': 2031337889,
          'name': 'Invariant (mathematics)'
        },
        {
          'id': 2020528314,
          'name': 'Nonlinear system'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2027444057,
          'name': 'Latent variable'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 36
    },
    {
      'id': 468458071,
      'title': 'A novel fast method for L ∞ problems in multiview geometry',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1453482599,
          'name': 'Zhijun Dai'
        },
        {
          'id': 1248194326,
          'name': 'Yihong Wu'
        },
        {
          'id': 1137295227,
          'name': 'Fengjun Zhang'
        },
        {
          'id': 1139771374,
          'name': 'Hongan Wang'
        }
      ],
      'field': [
        {
          'id': 2015875560,
          'name': 'Geometry'
        },
        {
          'id': 2014135151,
          'name': 'Algebraic solution'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2008571803,
          'name': 'Boundary (topology)'
        },
        {
          'id': 2018073104,
          'name': 'Convex geometry'
        },
        {
          'id': 2035705126,
          'name': 'Regular polygon'
        },
        {
          'id': 2009959965,
          'name': 'Fractional programming'
        },
        {
          'id': 2025865676,
          'name': 'Feasible region'
        },
        {
          'id': 2015062024,
          'name': 'Interior point method'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 31
    },
    {
      'id': 47218337,
      'title': 'A novel material-aware feature descriptor for volumetric image registration in diffusion tensor space',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2103998608,
          'abbr': 'SBU',
          'name': 'Stony Brook University'
        }
      ],
      'author': [
        {
          'id': 1240465038,
          'name': 'Shuai Li'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        },
        {
          'id': 1171777714,
          'name': 'Shengfa Wang'
        },
        {
          'id': 1238335460,
          'name': 'Tingbo Hou'
        },
        {
          'id': 1279911762,
          'name': 'Aimin Hao'
        },
        {
          'id': 1100966291,
          'name': 'Hong Qin'
        }
      ],
      'field': [
        {
          'id': 2041779889,
          'name': 'Wavelet transform'
        },
        {
          'id': 2030379103,
          'name': 'Structure tensor'
        },
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2038602766,
          'name': 'Diffusion MRI'
        },
        {
          'id': 2015709908,
          'name': 'Hessian matrix'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2001568964,
          'name': 'Image registration'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 3,
      'reference_count': 23
    },
    {
      'id': 472592169,
      'title': '3D reconstruction of dynamic scenes with multiple handheld cameras',
      'affiliation': [
        {
          'id': 2101824551,
          'abbr': 'NUS',
          'name': 'National University of Singapore'
        },
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1248492844,
          'name': 'Hanqing Jiang'
        },
        {
          'id': 1098068505,
          'name': 'Haomin Liu'
        },
        {
          'id': 1056951125,
          'name': 'Ping Tan'
        },
        {
          'id': 1439429759,
          'name': 'Guofeng Zhang'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        }
      ],
      'field': [
        {
          'id': 2026824493,
          'name': 'Mobile device'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2005146251,
          'name': 'Energy minimization'
        },
        {
          'id': 2046341557,
          'name': '3D reconstruction'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 24
    },
    {
      'id': 476671968,
      'title': 'Robust tracking with weighted online structured learning',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102982017,
          'abbr': 'ADELAIDE',
          'name': 'University of Adelaide'
        }
      ],
      'author': [
        {
          'id': 1244572793,
          'name': 'Rui Yao'
        },
        {
          'id': 1202827849,
          'name': 'Qinfeng Shi'
        },
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1020579257,
          'name': 'Anton van den Hengel'
        }
      ],
      'field': [
        {
          'id': 2045712947,
          'name': 'Online algorithm'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2005514528,
          'name': 'Reservoir sampling'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2037154616,
          'name': 'Biometrics'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2041203790,
          'name': 'Computer graphics'
        },
        {
          'id': 2014833089,
          'name': 'Regret'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2005310215,
          'name': 'Structured prediction'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 23
    },
    {
      'id': 496498471,
      'title': 'A convolutional treelets binary feature approach to fast keypoint recognition',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1474455939,
          'name': 'Chenxia Wu'
        },
        {
          'id': 1478895444,
          'name': 'Jianke Zhu'
        },
        {
          'id': 1225680359,
          'name': 'Jiemi Zhang'
        },
        {
          'id': 1397597581,
          'name': 'Chun Chen'
        },
        {
          'id': 1444957931,
          'name': 'Deng Cai'
        }
      ],
      'field': [
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2039119291,
          'name': 'Hash table'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2032238006,
          'name': 'Synthetic data'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2011698482,
          'name': 'Nearest neighbor search'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 8,
      'reference_count': 26
    },
    {
      'id': 59102548,
      'title': 'Two-granularity tracking: mediating trajectory and detection graphs for tracking under occlusions',
      'affiliation': [
        {
          'id': 2100387546,
          'abbr': 'UPENN',
          'name': 'University of Pennsylvania'
        },
        {
          'id': 2101117560,
          'abbr': 'XJTU',
          'name': 'Xi\'an Jiaotong University'
        }
      ],
      'author': [
        {
          'id': 1145493025,
          'name': 'Katerina Fragkiadaki'
        },
        {
          'id': 1141801936,
          'name': 'Weiyu Zhang'
        },
        {
          'id': 1375509396,
          'name': 'Geng Zhang'
        },
        {
          'id': 1456916710,
          'name': 'Jianbo Shi'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003012323,
          'name': 'Clutter'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2030514514,
          'name': 'Visibility'
        },
        {
          'id': 2043610215,
          'name': 'Encoding (memory)'
        },
        {
          'id': 2020324381,
          'name': 'Segmentation'
        },
        {
          'id': 2012153044,
          'name': 'Minimum bounding box'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 36,
      'reference_count': 33
    },
    {
      'id': 66418423,
      'title': 'Trajectory-Based modeling of human actions with motion reference points',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2104625384,
          'abbr': 'IBM',
          'name': 'IBM'
        },
        {
          'id': 2104262835,
          'abbr': 'CITYU',
          'name': 'City University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1328946252,
          'name': 'Qi Dai'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        },
        {
          'id': 1022003668,
          'name': 'Wei Liu'
        },
        {
          'id': 1114237355,
          'name': 'Chong-Wah Ngo'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2049333142,
          'name': 'Trajectory'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2008119544,
          'name': 'Image stabilization'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 169,
      'reference_count': 43
    },
    {
      'id': 80396659,
      'title': 'Learning hybrid part filters for scene recognition',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        }
      ],
      'author': [
        {
          'id': 1035239387,
          'name': 'Yingbin Zheng'
        },
        {
          'id': 1003227403,
          'name': 'Yu-Gang Jiang'
        },
        {
          'id': 1472591613,
          'name': 'Xiangyang Xue'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2021185547,
          'name': 'Object Class'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2047455936,
          'name': 'Pooling'
        },
        {
          'id': 2040213239,
          'name': 'Merge (version control)'
        },
        {
          'id': 2032340209,
          'name': 'Hierarchy'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 35
    },
    {
      'id': 99273833,
      'title': 'Morphable displacement field based image matching for face recognition across pose',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1497130500,
          'name': 'Shaoxin Li'
        },
        {
          'id': 1181479300,
          'name': 'Xin Liu'
        },
        {
          'id': 1067315396,
          'name': 'Xiujuan Chai'
        },
        {
          'id': 1178371504,
          'name': 'Haihong Zhang'
        },
        {
          'id': 1154828074,
          'name': 'Shihong Lao'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041894794,
          'name': 'Local consistency'
        },
        {
          'id': 2013839451,
          'name': 'Quadratic programming'
        },
        {
          'id': 2027529037,
          'name': 'Residual'
        },
        {
          'id': 2036183433,
          'name': 'Virtual image'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2025810538,
          'name': 'Displacement field'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2012597000,
          'name': 'Convex combination'
        }
      ],
      'venue': {
        'id': '2132982499',
        'name': 'ECCV',
        'year': 2012
      },
      'source_url': '',
      'citation_count': 57,
      'reference_count': 23
    },
    {
      'id': 149402424,
      'title': 'A structural filter approach to human detection',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        },
        {
          'id': 2101515501,
          'abbr': 'OMRON',
          'name': 'Omron'
        }
      ],
      'author': [
        {
          'id': 1103310797,
          'name': 'Genquan Duan'
        },
        {
          'id': 1128486384,
          'name': 'Haizhou Ai'
        },
        {
          'id': 1154828074,
          'name': 'Shihong Lao'
        }
      ],
      'field': [
        {
          'id': 2013982076,
          'name': 'Paragraph'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2032097272,
          'name': 'Classifier (linguistics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 29,
      'reference_count': 23
    },
    {
      'id': 149805274,
      'title': 'Learning shape detector by quantizing curve segments with multiple distance metrics',
      'affiliation': [
        {
          'id': 2102381148,
          'abbr': 'SYSU',
          'name': 'Sun Yat-sen University'
        }
      ],
      'author': [
        {
          'id': 1275825081,
          'name': 'Ping Luo'
        },
        {
          'id': 1167619800,
          'name': 'Liang Lin'
        },
        {
          'id': 1141092681,
          'name': 'Hongyang Chao'
        }
      ],
      'field': [
        {
          'id': 2016239190,
          'name': 'Metric (mathematics)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2024403018,
          'name': 'Information projection'
        },
        {
          'id': 2010150933,
          'name': 'Metric space'
        },
        {
          'id': 2006137252,
          'name': 'Active shape model'
        },
        {
          'id': 2011604083,
          'name': 'Generative model'
        },
        {
          'id': 2037616038,
          'name': 'Geodesic'
        },
        {
          'id': 2040861063,
          'name': 'A-equivalence'
        },
        {
          'id': 2033609603,
          'name': 'Manifold'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 27
    },
    {
      'id': 178018778,
      'title': 'Efficient non-consecutive feature tracking for structure-from-motion',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1439429759,
          'name': 'Guofeng Zhang'
        },
        {
          'id': 1315101139,
          'name': 'Zilong Dong'
        },
        {
          'id': 1402340194,
          'name': 'Jiaya Jia'
        },
        {
          'id': 1053639792,
          'name': 'Tien-Tsin Wong'
        },
        {
          'id': 1314273770,
          'name': 'Hujun Bao'
        }
      ],
      'field': [
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2044888366,
          'name': 'Effective method'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2037535833,
          'name': 'Feature detection (computer vision)'
        },
        {
          'id': 2046787875,
          'name': 'Feature (computer vision)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2015296692,
          'name': 'Image noise'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 19,
      'reference_count': 29
    },
    {
      'id': 192153632,
      'title': 'Learning PDEs for image restoration via optimal control',
      'affiliation': [
        {
          'id': 2104235635,
          'abbr': 'DUT',
          'name': 'Dalian University of Technology'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        },
        {
          'id': 2103216859,
          'abbr': 'CUHK',
          'name': 'The Chinese University of Hong Kong'
        }
      ],
      'author': [
        {
          'id': 1369218000,
          'name': 'Risheng Liu'
        },
        {
          'id': 1198064854,
          'name': 'Zhouchen Lin'
        },
        {
          'id': 1491718072,
          'name': 'Wei Zhang'
        },
        {
          'id': 1158512303,
          'name': 'Zhixun Su'
        }
      ],
      'field': [
        {
          'id': 2046921511,
          'name': 'Image restoration'
        },
        {
          'id': 2000809816,
          'name': 'Total variation denoising'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2034432816,
          'name': 'Inpainting'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2047564696,
          'name': 'Input/output'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2006011408,
          'name': 'Optimal control'
        },
        {
          'id': 2004976466,
          'name': 'Linear combination'
        },
        {
          'id': 2014037026,
          'name': 'Image processing'
        },
        {
          'id': 2025242424,
          'name': 'Partial differential equation'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 30,
      'reference_count': 25
    },
    {
      'id': 193307329,
      'title': 'Towards optimal naive bayes nearest neighbor',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2104227910,
          'abbr': 'AUP',
          'name': 'University of Paris'
        }
      ],
      'author': [
        {
          'id': 1397973511,
          'name': 'Régis Behmo'
        },
        {
          'id': 1363962386,
          'name': 'Paul Marcombes'
        },
        {
          'id': 1000387213,
          'name': 'Arnak S. Dalalyan'
        },
        {
          'id': 1453782942,
          'name': 'Véronique Prinet'
        }
      ],
      'field': [
        {
          'id': 2043866067,
          'name': 'Naive Bayes classifier'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003425367,
          'name': 'Hinge loss'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2017422348,
          'name': 'Quantization (signal processing)'
        },
        {
          'id': 2015462233,
          'name': 'Locality-sensitive hashing'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2014980802,
          'name': 'Probability measure'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2015414493,
          'name': 'k-nearest neighbors algorithm'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 64,
      'reference_count': 28
    },
    {
      'id': 194931482,
      'title': 'Non-local kernel regression for image and video restoration',
      'affiliation': [
        {
          'id': 2103331221,
          'abbr': 'UIUC',
          'name': 'University of Illinois at Urbana–Champaign'
        },
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        }
      ],
      'author': [
        {
          'id': 1219158789,
          'name': 'Haichao Zhang'
        },
        {
          'id': 1224129051,
          'name': 'Jianchao Yang'
        },
        {
          'id': 1095858316,
          'name': 'Yanning Zhang'
        },
        {
          'id': 1479117620,
          'name': 'Thomas S. Huang'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2045937712,
          'name': 'Bicubic interpolation'
        },
        {
          'id': 2029309449,
          'name': 'Superresolution'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        },
        {
          'id': 2002188700,
          'name': 'Kernel regression'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 81,
      'reference_count': 29
    },
    {
      'id': 211980237,
      'title': 'Discriminative nonorthogonal binary subspace tracking',
      'affiliation': [
        {
          'id': 2101930586,
          'abbr': 'NJU',
          'name': 'Nanjing University'
        },
        {
          'id': 2101386547,
          'abbr': 'HP',
          'name': 'Hewlett-Packard'
        },
        {
          'id': 2103680939,
          'abbr': 'UCSC',
          'name': 'University of California, Santa Cruz'
        }
      ],
      'author': [
        {
          'id': 1080244815,
          'name': 'Ang Li'
        },
        {
          'id': 1045809490,
          'name': 'Feng Tang'
        },
        {
          'id': 1020005752,
          'name': 'Yanwen Guo'
        },
        {
          'id': 1460576365,
          'name': 'Hai Tao'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2026785877,
          'name': 'Eye tracking'
        },
        {
          'id': 2031918806,
          'name': 'Template matching'
        },
        {
          'id': 2043029098,
          'name': 'Discriminative model'
        },
        {
          'id': 2009864598,
          'name': 'Inference'
        },
        {
          'id': 2030369583,
          'name': 'Video tracking'
        },
        {
          'id': 2040345886,
          'name': 'Subspace topology'
        },
        {
          'id': 2022630621,
          'name': 'Feature extraction'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 10,
      'reference_count': 23
    },
    {
      'id': 224910800,
      'title': 'Effective and efficient image copy detection based on GPU',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1366806948,
          'name': 'Hongtao Xie'
        },
        {
          'id': 1223382402,
          'name': 'Ke Gao'
        },
        {
          'id': 1355307321,
          'name': 'Yongdong Zhang'
        },
        {
          'id': 1154435778,
          'name': 'Jintao Li'
        },
        {
          'id': 1198158489,
          'name': 'Yizhi Liu'
        },
        {
          'id': 1024056268,
          'name': 'Huamin Ren'
        }
      ],
      'field': [
        {
          'id': 2026360789,
          'name': 'CUDA'
        },
        {
          'id': 2034329168,
          'name': 'Parallel computing'
        },
        {
          'id': 2003679505,
          'name': 'Speedup'
        },
        {
          'id': 2022607753,
          'name': 'Hamming code'
        },
        {
          'id': 2002062176,
          'name': 'Central processing unit'
        },
        {
          'id': 2036206763,
          'name': 'Embedding'
        },
        {
          'id': 2022834597,
          'name': 'Ordinal number'
        },
        {
          'id': 2004471139,
          'name': 'Graphics'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2008178123,
          'name': 'Theoretical computer science'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 16
    },
    {
      'id': 263986475,
      'title': 'Co-transduction for shape retrieval',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1374123895,
          'name': 'Bo Wang'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2029738928,
          'name': 'Semi-supervised learning'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2003930427,
          'name': 'Similarity (geometry)'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2022918828,
          'name': 'Similarity measure'
        },
        {
          'id': 2015693736,
          'name': 'Ranking'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 44,
      'reference_count': 28
    },
    {
      'id': 284770948,
      'title': 'Face image relighting using locally constrained global optimization',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1470618407,
          'name': 'Jiansheng Chen'
        },
        {
          'id': 1388511371,
          'name': 'Guangda Su'
        },
        {
          'id': 1396213947,
          'name': 'Jinping He'
        },
        {
          'id': 1415666285,
          'name': 'Shenglan Ben'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2049269054,
          'name': 'Homogeneous'
        },
        {
          'id': 2034045612,
          'name': 'Radiance'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2019861330,
          'name': 'Active appearance model'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 25
    },
    {
      'id': 321636223,
      'title': 'A close-form iterative algorithm for depth inferring from a single image',
      'affiliation': [
        {
          'id': 2104852976,
          'abbr': 'USTC',
          'name': 'University of Science and Technology of China'
        }
      ],
      'author': [
        {
          'id': 1004356455,
          'name': 'Yang Cao'
        },
        {
          'id': 1104529640,
          'name': 'Yan Xia'
        },
        {
          'id': 1462166738,
          'name': 'Zengfu Wang'
        }
      ],
      'field': [
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034116032,
          'name': 'Image segmentation'
        },
        {
          'id': 2020623612,
          'name': 'Supervised learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2024772551,
          'name': 'Markov random field'
        },
        {
          'id': 2014250958,
          'name': 'Depth perception'
        },
        {
          'id': 2043533591,
          'name': 'Iterative and incremental development'
        },
        {
          'id': 2045829334,
          'name': 'Scale-space segmentation'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2016159137,
          'name': 'Graph'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 5,
      'reference_count': 24
    },
    {
      'id': 326718354,
      'title': 'LACBoost and FisherBoost: optimally building cascade classifiers',
      'affiliation': [
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        },
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        }
      ],
      'author': [
        {
          'id': 1123092853,
          'name': 'Chunhua Shen'
        },
        {
          'id': 1366501403,
          'name': 'Peng Wang'
        },
        {
          'id': 1109679411,
          'name': 'Hanxi Li'
        }
      ],
      'field': [
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2038236017,
          'name': 'Column generation'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2049677030,
          'name': 'Feature selection'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008353598,
          'name': 'Boosting (machine learning)'
        },
        {
          'id': 2023456994,
          'name': 'Face detection'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        },
        {
          'id': 2000276300,
          'name': 'Linear discriminant analysis'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 15,
      'reference_count': 17
    },
    {
      'id': 333294052,
      'title': 'A globally optimal approach for 3D elastic motion estimation from stereo sequences',
      'affiliation': [
        {
          'id': 2100751172,
          'abbr': 'THU',
          'name': 'Tsinghua University'
        }
      ],
      'author': [
        {
          'id': 1338406030,
          'name': 'Qifan Wang'
        },
        {
          'id': 1088250227,
          'name': 'Linmi Tao'
        },
        {
          'id': 1039882930,
          'name': 'Huijun Di'
        }
      ],
      'field': [
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2018995267,
          'name': 'Global optimization'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2046339509,
          'name': 'Motion estimation'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2039117410,
          'name': 'Structure from motion'
        },
        {
          'id': 2009849311,
          'name': 'Smoothness'
        },
        {
          'id': 2015551145,
          'name': 'Deformation (mechanics)'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 7,
      'reference_count': 26
    },
    {
      'id': 349063500,
      'title': 'Max-margin dictionary learning for multiclass image categorization',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        },
        {
          'id': 2100445279,
          'abbr': 'MICROSOFT',
          'name': 'Microsoft'
        }
      ],
      'author': [
        {
          'id': 1042622385,
          'name': 'Xiao-Chen Lian'
        },
        {
          'id': 1116675314,
          'name': 'Zhiwei Li'
        },
        {
          'id': 1435529302,
          'name': 'Bao-Liang Lu'
        },
        {
          'id': 1149757947,
          'name': 'Lei Zhang'
        }
      ],
      'field': [
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2034216088,
          'name': 'Binary number'
        },
        {
          'id': 2003425367,
          'name': 'Hinge loss'
        },
        {
          'id': 2045785672,
          'name': 'Support vector machine'
        },
        {
          'id': 2009809369,
          'name': 'Binary classification'
        },
        {
          'id': 2028755227,
          'name': 'Visual dictionary'
        },
        {
          'id': 2008671033,
          'name': 'Categorization'
        },
        {
          'id': 2007696422,
          'name': 'Visual Word'
        },
        {
          'id': 2029531426,
          'name': 'Bag-of-words model in computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 78,
      'reference_count': 30
    },
    {
      'id': 395935345,
      'title': 'Middle-Level representation for human activities recognition: the role of spatio-temporal relationships',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2102285164,
          'abbr': 'NTU',
          'name': 'Nanyang Technological University'
        }
      ],
      'author': [
        {
          'id': 1107147997,
          'name': 'Fei Yuan'
        },
        {
          'id': 1453782942,
          'name': 'Véronique Prinet'
        },
        {
          'id': 1262920571,
          'name': 'Junsong Yuan'
        }
      ],
      'field': [
        {
          'id': 2011633730,
          'name': 'Pairwise comparison'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2026043784,
          'name': 'Activity recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2003404264,
          'name': 'Cluster analysis'
        },
        {
          'id': 2041307558,
          'name': 'Moving parts'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2026734839,
          'name': 'Exploit'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 25,
      'reference_count': 33
    },
    {
      'id': 420291232,
      'title': 'Learning artistic lighting template from portrait photographs',
      'affiliation': [
        {
          'id': 2102848858,
          'abbr': 'BUAA',
          'name': 'Beihang University'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1493538103,
          'name': 'Xin Jin'
        },
        {
          'id': 1393402384,
          'name': 'Mingtian Zhao'
        },
        {
          'id': 1054199131,
          'name': 'Xiaowu Chen'
        },
        {
          'id': 1115113533,
          'name': 'Qinping Zhao'
        },
        {
          'id': 1206561156,
          'name': 'Song-Chun Zhu'
        }
      ],
      'field': [
        {
          'id': 2024435654,
          'name': 'Portrait'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2027651673,
          'name': 'Computer graphics (images)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2013537828,
          'name': 'Cross-validation'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2039220679,
          'name': 'Studio'
        },
        {
          'id': 2014862885,
          'name': 'Portrait photography'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 21
    },
    {
      'id': 439511368,
      'title': 'Optimum subspace learning and error correction for tensors',
      'affiliation': [
        {
          'id': 2103723062,
          'abbr': 'SJTU',
          'name': 'Shanghai Jiao Tong University'
        }
      ],
      'author': [
        {
          'id': 1059831137,
          'name': 'Yin Li'
        },
        {
          'id': 1338576504,
          'name': 'Junchi Yan'
        },
        {
          'id': 1462038031,
          'name': 'Yue Zhou'
        },
        {
          'id': 1073635081,
          'name': 'Jie Yang'
        }
      ],
      'field': [
        {
          'id': 2016134371,
          'name': 'Coordinate descent'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2025719862,
          'name': 'Tensor'
        },
        {
          'id': 2004564439,
          'name': 'Matrix completion'
        },
        {
          'id': 2017639414,
          'name': 'Cartesian tensor'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2038646650,
          'name': 'Multilinear subspace learning'
        },
        {
          'id': 2022175790,
          'name': 'Tensor (intrinsic definition)'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2018992595,
          'name': 'Robust principal component analysis'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        },
        {
          'id': 2042436427,
          'name': 'Convex optimization'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 46,
      'reference_count': 34
    },
    {
      'id': 46010052,
      'title': 'Chrono-gait image: a novel temporal template for gait recognition',
      'affiliation': [
        {
          'id': 2103229542,
          'abbr': 'FUDAN',
          'name': 'Fudan University'
        },
        {
          'id': 2103048394,
          'abbr': 'PKU',
          'name': 'Peking University'
        },
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1081170536,
          'name': 'Chen Wang'
        },
        {
          'id': 1159117154,
          'name': 'Junping Zhang'
        },
        {
          'id': 1216232896,
          'name': 'Jian Pu'
        },
        {
          'id': 1042248024,
          'name': 'Xiaoru Yuan'
        },
        {
          'id': 1430460893,
          'name': 'Liang Wang'
        }
      ],
      'field': [
        {
          'id': 2005987546,
          'name': 'Compositing'
        },
        {
          'id': 2040759283,
          'name': 'Distortion'
        },
        {
          'id': 2046442909,
          'name': 'ENCODE'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2013094599,
          'name': 'Gait'
        },
        {
          'id': 2030941587,
          'name': 'Template'
        },
        {
          'id': 2034306437,
          'name': 'Color mapping'
        },
        {
          'id': 2004117069,
          'name': 'Color Mapping Function'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 59,
      'reference_count': 25
    },
    {
      'id': 472206616,
      'title': 'Lighting aware preprocessing for face recognition across varying illumination',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        }
      ],
      'author': [
        {
          'id': 1217280926,
          'name': 'Hu Han'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1420142893,
          'name': 'Laiyun Qing'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2029913063,
          'name': 'Training set'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2006413608,
          'name': 'Normalization (statistics)'
        },
        {
          'id': 2003450942,
          'name': 'Image-based lighting'
        },
        {
          'id': 2017408157,
          'name': 'Facial recognition system'
        },
        {
          'id': 2018399408,
          'name': 'Preprocessor'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 26,
      'reference_count': 36
    },
    {
      'id': 48997432,
      'title': 'Object recognition using junctions',
      'affiliation': [
        {
          'id': 2100453429,
          'abbr': 'HUST',
          'name': 'Huazhong University of Science and Technology'
        },
        {
          'id': 2104713689,
          'abbr': 'UCLA',
          'name': 'University of California, Los Angeles'
        }
      ],
      'author': [
        {
          'id': 1374123895,
          'name': 'Bo Wang'
        },
        {
          'id': 1361411154,
          'name': 'Xiang Bai'
        },
        {
          'id': 1417865925,
          'name': 'Xinggang Wang'
        },
        {
          'id': 1317983596,
          'name': 'Wenyu Liu'
        },
        {
          'id': 1287899724,
          'name': 'Zhuowen Tu'
        }
      ],
      'field': [
        {
          'id': 2040500557,
          'name': 'Corner detection'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2037694234,
          'name': 'Viola–Jones object detection framework'
        },
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2043537062,
          'name': '3D single-object recognition'
        },
        {
          'id': 2003753257,
          'name': 'Object-class detection'
        },
        {
          'id': 2021185547,
          'name': 'Object Class'
        },
        {
          'id': 2003540010,
          'name': 'Operator (computer programming)'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2008146045,
          'name': 'Cognitive neuroscience of visual object recognition'
        },
        {
          'id': 2008306208,
          'name': 'Pattern recognition'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 28
    },
    {
      'id': 492507342,
      'title': 'what is the chance of happening: a new way to predict where people look',
      'affiliation': [
        {
          'id': 2101552222,
          'abbr': 'ZJU',
          'name': 'Zhejiang University'
        }
      ],
      'author': [
        {
          'id': 1493220987,
          'name': 'Yezhou Yang'
        },
        {
          'id': 1142948728,
          'name': 'Mingli Song'
        },
        {
          'id': 1462578989,
          'name': 'Na Li'
        },
        {
          'id': 1114109048,
          'name': 'Jiajun Bu'
        },
        {
          'id': 1397597581,
          'name': 'Chun Chen'
        }
      ],
      'field': [
        {
          'id': 2041998069,
          'name': 'Computer vision'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2043179862,
          'name': 'Probability distribution'
        },
        {
          'id': 2008584860,
          'name': 'Human visual system model'
        },
        {
          'id': 2035707172,
          'name': 'Independent component analysis'
        },
        {
          'id': 2030591755,
          'name': 'Computer science'
        },
        {
          'id': 2027847847,
          'name': 'Prior probability'
        },
        {
          'id': 2005485485,
          'name': 'Saliency map'
        },
        {
          'id': 2043626220,
          'name': 'Open problem'
        },
        {
          'id': 2036671373,
          'name': 'Bayesian probability'
        },
        {
          'id': 2027129335,
          'name': 'Pixel'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 22,
      'reference_count': 22
    },
    {
      'id': 76849600,
      'title': 'Element-wise factorization for N-View projective reconstruction',
      'affiliation': [
        {
          'id': 2103293601,
          'abbr': 'NPU',
          'name': 'Northwestern Polytechnical University'
        },
        {
          'id': 2102835744,
          'abbr': 'ANU',
          'name': 'Australian National University'
        }
      ],
      'author': [
        {
          'id': 1041172214,
          'name': 'Yuchao Dai'
        },
        {
          'id': 1081295786,
          'name': 'Hongdong Li'
        },
        {
          'id': 1446146009,
          'name': 'Mingyi He'
        }
      ],
      'field': [
        {
          'id': 2047961495,
          'name': 'Matrix decomposition'
        },
        {
          'id': 2017172679,
          'name': 'Iterative method'
        },
        {
          'id': 2017004039,
          'name': 'Incomplete LU factorization'
        },
        {
          'id': 2031916494,
          'name': 'Quadratic sieve'
        },
        {
          'id': 2045988432,
          'name': 'Dixon\'s factorization method'
        },
        {
          'id': 2043336058,
          'name': 'Congruence of squares'
        },
        {
          'id': 2033735366,
          'name': 'Mathematical optimization'
        },
        {
          'id': 2044566924,
          'name': 'Factorization'
        },
        {
          'id': 2020444711,
          'name': 'Incomplete Cholesky factorization'
        },
        {
          'id': 2041018797,
          'name': 'Discrete mathematics'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 23,
      'reference_count': 30
    },
    {
      'id': 90253980,
      'title': 'Spatial-temporal granularity-tunable gradients partition (STGGP) descriptors for human detection',
      'affiliation': [
        {
          'id': 2101067073,
          'abbr': 'CAS',
          'name': 'Chinese Academy of Sciences'
        },
        {
          'id': 2100320297,
          'abbr': 'OULU',
          'name': 'University of Oulu'
        }
      ],
      'author': [
        {
          'id': 1183072352,
          'name': 'Yazhou Liu'
        },
        {
          'id': 1411222308,
          'name': 'Shiguang Shan'
        },
        {
          'id': 1010053663,
          'name': 'Xilin Chen'
        },
        {
          'id': 1414977030,
          'name': 'Janne Heikkilä'
        },
        {
          'id': 1187423013,
          'name': 'Wen Gao'
        },
        {
          'id': 1390027563,
          'name': 'Matti Pietikäinen'
        }
      ],
      'field': [
        {
          'id': 2006416770,
          'name': 'Granularity'
        },
        {
          'id': 2000925549,
          'name': 'Machine learning'
        },
        {
          'id': 2024852807,
          'name': 'Partition (number theory)'
        },
        {
          'id': 2024909610,
          'name': 'Domain specificity'
        },
        {
          'id': 2025692176,
          'name': 'Artificial intelligence'
        },
        {
          'id': 2033623845,
          'name': 'Robustness (computer science)'
        },
        {
          'id': 2021493302,
          'name': 'Parsing'
        },
        {
          'id': 2007639387,
          'name': 'Object detection'
        },
        {
          'id': 2000460721,
          'name': 'Mathematics'
        }
      ],
      'venue': {
        'id': '2131903148',
        'name': 'ECCV',
        'year': 2010
      },
      'source_url': '',
      'citation_count': 9,
      'reference_count': 33
    }
  ]
}